import { Injectable, Input } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { ExportDigitalAssetsGridViewComponent } from '../components/export-digital-assets-grid-view/export-digital-assets-grid-view.component';
import { first, map, mergeMap } from 'rxjs';
import { PdfDigitalAssetsService } from './pdf-digital-assets.service';

@Injectable({
  providedIn: 'root'
})
export class ExportDigitalAssetsListConfigService implements ConfigService<any> {
  topFilter = true;
  @Input()
  isManagement: boolean;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);

  constructor(protected digitalAssetsService: PdfDigitalAssetsService) {}

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ExportDigitalAssetsGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimConceptGrid'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getSortOptions(): ListSortOption<any>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: any) {
    return item.id;
  }

  getSearchTerms(item: any) {
    return [item.id, item.name];
  }

  getItems(): Observable<any[]> {
    return this.digitalAssetsObjectsSubject.pipe(
      // filter(ids => !!ids),
      mergeMap(ids => forkJoin([of(ids), this.digitalAssetsService.getDigitalAssets(true).pipe(first())])),
      map(([filterIds, digitalAssets]) => {
        if (filterIds) {
          // Filter by Ids
          return digitalAssets.filter(d => filterIds.includes(d.id));
        }
        return digitalAssets;
      })
    );
  }
}
