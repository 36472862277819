import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { OpenPurchaseOrdersActionConfigService } from '../../../services/open-purchase-orders-action-config/open-purchase-orders-action-config.service';
import { OpenPurchaseOrders } from '../../../services/open-purchase-orders/open-purchase-orders.service';

@Component({
  selector: 'lib-open-purchase-orders-details',
  templateUrl: './open-purchase-orders-details.component.html',
  styleUrls: ['./open-purchase-orders-details.component.css']
})
export class OpenPurchaseOrdersDetailsComponent implements OnInit {
  orderId: string;
  purchaseOrder: OpenPurchaseOrders;
  public baseUrl: string;
  public documentList: any[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected backendInterceptor: BackendInterceptor,
    protected metadataService: MetadataService,
    protected http: HttpClient,
    public configService: OpenPurchaseOrdersActionConfigService
  ) {
    this.baseUrl = this.backendInterceptor.getBaseUrl();
  }

  ngOnInit(): void {
    this.orderId = this.route.snapshot.paramMap.get('orderId');
    if (!this.orderId) {
      this.router.navigate(['../'], { relativeTo: this.route });
    }
    this.http
      .get<any>('json/purchase-orders/' + this.orderId)
      .pipe(first())
      .subscribe(element => {
        const laRecords = this.metadataService.getMetadataTableList('LA');

        this.purchaseOrder = element as OpenPurchaseOrders;
        const matched = laRecords.find(e => {
          return e.LNR === this.purchaseOrder.deliveryCustomerId;
        });

        this.purchaseOrder.extra.deliveryCustomer = this.purchaseOrder.extra.deliveryCustomer || {};
        this.purchaseOrder.extra.deliveryCustomer.extra = this.purchaseOrder.extra.deliveryCustomer.extra || {};
        this.purchaseOrder.extra.deliveryCustomer.extra.LA = matched;
      });
  }

  navigateToOrder(comment: string) {
    const orderId = comment.substring(30, 36).trim();
    const target = '/orders/' + orderId;
    this.router.navigate([target]);
  }
}
