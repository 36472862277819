import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, switchMap } from 'rxjs';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ActivityInternalService {
  private activityInternalSubjects: BehaviorSubject<ActivityInternal[]>;

  constructor(protected http: HttpClient) {}

  getById(id): Observable<ActivityInternal> {
    const url = `json/activity-internal/${id}`;
    return this.http.get<ActivityInternal>(url);
  }

  getAll(nocache?: boolean): Observable<ActivityInternal[]> {
    if (!this.activityInternalSubjects) {
      this.activityInternalSubjects = new BehaviorSubject<ActivityInternal[]>([]);
      return this.updateActivityInternalSubjectsSubject(nocache);
    }
    return this.activityInternalSubjects.asObservable();
  }

  updateActivityInternalSubjectsSubject(nocache?: boolean): Observable<ActivityInternal[]> {
    if (!this.activityInternalSubjects) {
      this.activityInternalSubjects = new BehaviorSubject<ActivityInternal[]>([]);
    }
    return this.internalGetAll(nocache).pipe(
      tap((data: ActivityInternal[]) => this.activityInternalSubjects.next(data)),
      switchMap(_ => this.activityInternalSubjects.asObservable())
    );
  }

  protected internalGetAll(nocache?: boolean): Observable<ActivityInternal[]> {
    const url = `json/activity-internal`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<ActivityInternal[]>(url, { headers });
  }

  postActivityInternal(activityInternalTicket: ActivityInternal): Observable<ActivityInternal> {
    const url = `json/activity-internal`;
    return this.http
      .post<ActivityInternal>(url, activityInternalTicket)
      .pipe(tap(_ => this.updateActivityInternalSubjectsSubject(true).subscribe()));
  }

  postMultipleActivityInternal(activityInternalTicket: MultipleActivityInternal): Observable<ActivityInternal> {
    const url = `json/activity-internal/create-multiples`;
    return this.http
      .post<ActivityInternal>(url, activityInternalTicket)
      .pipe(tap(_ => this.updateActivityInternalSubjectsSubject(true).subscribe()));
  }

  uploadDocuments(fd: FormData, timestamp): Observable<any[]> {
    return this.http.post<any[]>(`import/documents/commentAttachment/${timestamp}`, fd);
  }

  deleteActivityInternal(id: string) {
    const url = `json/activity-internal/${id}`;
    return this.http.delete<ActivityInternal>(url).pipe(tap(_ => this.updateActivityInternalSubjectsSubject(true).subscribe()));
  }

  deleteDocuments(id: string): Observable<any[]> {
    return this.http.delete<any[]>(`json/documents/${id}`);
  }

  getDocumentDownloadUrl(id: string) {
    return `gung-document-archive/document/${id}`;
  }
}

export interface MultipleActivityInternal {
  customersIds: string[];
  activity: ActivityInternal;
}

export interface ActivityInternal {
  comments: ActivityInternalComment[];
  customerId: string;
  customerName: string;
  customerResponsible: string;
  date: string;
  activityDate: string;
  activityEndDate?: string;
  id: string;
  orderId: string;
  status: string;
  type: string;
  contacts: string;
  extra?: { [s: string]: any } | null;
}

export interface ActivityInternalComment {
  attachments: any[];
  comment: string;
  date: string;
  user: string;
}
