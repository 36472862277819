import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterListComponent } from 'gung-list';
import { ExportPdfOrderProductListConfigService } from '../../services/export-pdf-order-product-list-config.service';

@Component({
  selector: 'lib-export-pdf-product-list',
  template: '<lib-filter-list #productList [configService]="listConfig"></lib-filter-list>'
})
export class ExportPdfProductListComponent<T> implements OnInit {
  constructor(public listConfig: ExportPdfOrderProductListConfigService, public route: ActivatedRoute) {}
  @ViewChild('productList') productList: FilterListComponent<T>;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const pdfId = params.get('id');

      if (pdfId != null) {
        this.listConfig.exportPdfSubject.next(pdfId);
      } else {
        this.listConfig.exportPdfSubject.next(null);
      }
    });
  }
}
