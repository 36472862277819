import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { mergeMap } from 'rxjs';
import { Product } from '../../../models';
import { EdiInvoice } from '../../../models/edi';
import { EdiInvoiceDetailConfigService } from '../../../services/edi-invoice-detail-config/edi-invoice-detail-config.service';
import { EdiInvoicesActionConfigService } from '../../../services/edi-invoices-action-config/edi-invoices-action-config.service';
import { EdiService } from '../../../services/edi/edi.service';
import { ProductService } from '../../../services/products/product.service';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-edi-invoice-detail',
  templateUrl: './edi-invoice-detail.component.html',
  styleUrls: ['./edi-invoice-detail.component.css']
})
export class EdiInvoiceDetailComponent implements OnInit {
  invoiceId = null;
  invoiceData: EdiInvoice;
  orderId: string;

  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: EdiInvoice) => item.extra._images
    },
    {
      header: 'ID',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.ids.gungId
    },
    {
      header: 'QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.quantities.actualQuantity
    },
    {
      header: 'GROSS_UNIT_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.prices?.actualUnitGrossPrice?.value
    },
    {
      header: 'DISCOUNT_PERCENT',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.prices?.actualDiscountPercent || 0
    },
    {
      header: 'DISCOUNT_AMOUNT',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.prices.actualTotalDiscountAmount?.value
    },
    {
      header: 'NET_UNIT_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.prices.actualUnitNetPrice?.value
    },
    {
      header: 'TOTAL',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiInvoice) => item.prices.actualTotalNetPrice?.value
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    public configService: EdiInvoiceDetailConfigService,
    public actionConfig: EdiInvoicesActionConfigService,
    protected productService: ProductService,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.invoiceId = data.id;
      this.ediService
        .getInvoice(this.invoiceId)
        .pipe(
          mergeMap(result => {
            let ids = [];
            if (result?.data?.items?.length > 0) {
              ids = result.data.items.map(i => i.ids.gungId);
            }
            return forkJoin([of(result), ids ? this.productService.getProductsByIds(ids) : of(null)]);
          })
        )
        .subscribe(([invoiceData, products]) => {
          this.invoiceData = invoiceData.data;
          if (products) {
            this.invoiceData.items.forEach(item => {
              item.extra._images = (products as Product[]).find(p => p.id === item.ids.gungId)?.extra.images;
              return item;
            });
          }
        });
    });
  }
}
