import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-order-terms',
  templateUrl: './order-terms.component.html',
  styleUrl: './order-terms.component.scss'
})
export class OrderTermsComponent extends CheckoutStepComponent implements OnInit{
  form: FormGroup;

  constructor(protected formBuilder: FormBuilder) {
    super();
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.initForm();
  }

  getLabel(): string {
    return 'TERMS_AND_CONDITIONS';
  }

  protected initForm() {
    this.form = this.formBuilder.group({
      termsOfPayment: this.formBuilder.control(this.checkout.extra.gme.termsOfPayment || ''),
      termsOfDelivery: this.formBuilder.control(this.checkout.extra.gme.termsOfDelivery || ''),
      deliveryMethod: this.formBuilder.control(this.checkout.extra.gme.deliveryMethod || ''),
      /* salesperson: this.formBuilder.control(this.checkout.extra.gme.salesperson || '') */
    });
  }

  protected handleNextButtonClicked(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  protected assignFormValues(formGroup: FormGroup) {
    const rawValues = formGroup.getRawValue();

    this.checkout.extra = {
      ...this.checkout.extra,
      gme: {
        ...this.checkout.extra.gme,
        ...rawValues
      }
    };
  }
}
