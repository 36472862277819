<div *ngIf="!view3DModel; else show3DModel"  class="row">
  <div class="col-2" *ngIf="(!!images && (images.length + videos?.length) > 1) && !!vertical && !minimal">
    <div class="scrolling-images-vertical">
      <a class="scroll-up" (click)="scrollImagesUp()" *ngIf="(images.length + videos.length) > 4">
        <i class="fa fa-2x fa-caret-up"></i>
      </a>
      <div class="scroll-container d-flex" [ngStyle]="{ 'max-height.px': getVerticalMaxHeight() }" #scrollContainer>
        <div *ngFor="let image of images; let i = index">
          <img
            (click)="select(i)"
            class="img-fluid"
            [ngStyle]="{
              'max-width.px': highRes ? thumbnailMaxHeight : '',
              'max-height.px': highRes ? thumbnailMaxWidth : ''
            }"
            [src]="(image.extra.s3UriEncoded || image.s3Uri) | gungImageUrl
                :'type:thumbnail'
                :'format:'+imageFormat
                :'etag:'+image.s3ETag
                :'fill:'+imageColorFill
                :'size:'+imageSizeThumbnail
                :'orientation:'+orientation
            "
            alt="{{ image.description }}"
          />
        </div>
        <div
          class="d-flex w-100 justify-content-center align-items-center video-background cursor-pointer"
          *ngFor="let video of videos; let i = index"
          (click)="select(images.length + i)"
        >
          <i class="fas fa-play-circle mb-3"></i>
        </div>
      </div>
      <a class="scroll-down" (click)="scrollImagesDown()" *ngIf="(images.length + videos.length) > 4">
        <i class="fa fa-2x fa-caret-down"></i>
      </a>
    </div>
  </div>
  <div class="mb-2" [ngClass]="!!vertical ? 'col-10' : 'col-12'">
    <ng-container *ngIf="images as images; else noimage">
      <div class="text-center">
        <ngb-carousel
          #myCarousel
          [interval]="-1"
          [showNavigationIndicators]="!!minimal"
          [showNavigationArrows]="!!minimal || !!showNavigationArrows"
        >
          <ng-template ngbSlide *ngFor="let image of images; let i = index" [id]="imageIdString + i">
            <div class="square">
              <div class="content">
                <div class="d-flex justify-content-center align-items-flex-start" *ngIf="zoom">
                  <lib-ngx-image-zoom
                    class="img-fluid"
                    [thumbImage]="(image.extra.s3UriEncoded || image.s3Uri)| gungImageUrl
                        :'type:detail'
                        :'size:'+imageSize
                        :'format:'+imageFormat
                        :'etag:'+image.s3ETag
                        :'fill:'+(imageFill ? imageColorFill : '')
                        :'orientation:'+orientation
                    "
                    [fullImage]="(image.extra.s3UriEncoded || image.s3Uri)| gungImageUrl
                        :'type:high'
                        :'size:'+imageSizeZoom
                        :'format:'+imageFormat
                        :'etag:'+image.s3ETag
                        :'fill:'+(imageFill ? imageColorFill : '')
                        :'orientation:'+orientation
                    "
                  >
                  </lib-ngx-image-zoom>
                </div>
                <div *ngIf="!zoom">
                  <img
                    *ngIf="!enableFullImage"
                    class="img-fluid mx-auto d-block cursor-pointer"
                    [class.cursor-pointer]="openInternal"
                    [ngStyle]="{
                      'width.px': highRes ? imageMaxWidth : '',
                      'max-height.px': highRes ? imageMaxHeight : ''
                    }"
                    [src]="(image.extra.s3UriEncoded || image.s3Uri) | gungImageUrl
                        :'type:detail'
                        :'size:'+imageSize
                        :'format:'+imageFormat
                        :'etag:'+image.s3ETag
                        :'fill:'+(imageFill ? imageColorFill : '')
                        :'orientation:'+orientation
                    "
                    alt="{{ image.description }}"
                    (click)="openInternal ? openImageModal(image) : undefined"
                  />
                  <img
                    *ngIf="enableFullImage"
                    class="img-fluid mx-auto d-block"
                    [ngStyle]="{
                      'width.px': highRes ? imageMaxWidth : '',
                      'max-height.px': highRes ? imageMaxHeight : ''
                    }"
                    [src]="(image.extra.s3UriEncoded || image.s3Uri) | gungImageUrl"
                    alt="{{ image.description }}"
                  />
                </div>
                <div class="d-flex justify-content-center align-items-flex-start" *ngIf="openExternal">
                  <a
                    [href]="(image.extra.s3UriEncoded || image.s3Uri) | gungImageUrl
                        :'type:high'
                        :'size:'+imageSizeZoom
                        :'format:'+imageFormat
                        :'etag:'+image.s3ETag
                        :'fill:'+(imageFill ? imageColorFill : '')
                        :'orientation:'+orientation
                    "
                    target="_blank"
                  >
                    <i class="fas fa-search-plus d-block"></i>
                  </a>
                </div>
                <div class="d-flex justify-content-center align-items-flex-start" *ngIf="openInternal">
                  <a (click)="openImageModal(image)">
                    <i class="fas fa-search-plus d-block"></i>
                  </a>
                </div>
              </div>
            </div>
          </ng-template>
          <!-- VIDEO -->
          <ng-template #videoSlide *ngFor="let video of videos; let i = index" ngbSlide [id]="imageIdString + (images.length + i)">
            <ng-template #videoYoutube [ngIf]="video.provider === 'youtube'">
              <iframe
                [id]="imageIdString + (images.length + i)"
                width="100%"
                height="315"
                [src]="video.embed"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </ng-template>
            <ng-template #videoVimeo [ngIf]="video.provider === 'vimeo'">
              <div style="padding: 56.25% 0 0 0; position: relative">
                <iframe
                  [id]="imageIdString + (images.length + i)"
                  [src]="video.embed"
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                >
                </iframe>
              </div>
              <script src="https://player.vimeo.com/api/player.js"></script>
            </ng-template>
            <ng-template #videoDirect [ngIf]="video.provider === 'direct'">
              <div [innerHtml]="video.embed | safeHtml"></div>
            </ng-template>
          </ng-template>
        </ngb-carousel>
      </div>
    </ng-container>
    <ng-template #noimage>
      <img
        class="img-fluid w-100"
        [src]="'images/no-image.jpg' | gungImageUrl"
        alt="NO IMAGE"
        class="img-fluid mx-auto d-block"
      />
    </ng-template>
  </div>
  <div class="col-12" *ngIf="(!!images && (images.length + videos?.length) > 1) && !vertical && !minimal">
    <div class="scrolling-images">
      <a class="scroll-left" (click)="scrollImagesLeft()" *ngIf="(images.length + videos.length) > 4">
        <i class="fa fa-2x fa-caret-left"></i>
      </a>
      <div class="scroll-container row" #scrollContainer [ngClass]="{ 'justify-content-center': images.length < 4 }">
        <div
          class="col-3 d-flex justify-content-center align-items-center"
          *ngFor="let image of images; let i = index"
          [ngClass]="{ 'pr-0': i == images.length - 1 }"
        >
          <img
            (click)="select(i)"
            class="img-fluid"
            [ngStyle]="{
              'max-width.px': highRes ? thumbnailMaxHeight : '',
              'max-height.px': highRes ? thumbnailMaxWidth : ''
            }"
            [src]="(image.extra.s3UriEncoded || image.s3Uri) | gungImageUrl
                :'type:thumbnail'
                :'format:'+imageFormat
                :'size:'+imageSizeThumbnail
                :'etag:'+image.s3ETag
                :'fill:'+(imageFill ? imageColorFill : '')
                :'orientation:'+orientation
            "
            alt="{{ image.description }}"
          />
        </div>
        <div
          class="col-3 d-flex justify-content-center align-items-center video-background thumbnail-container cursor-pointer"
          *ngFor="let video of videos; let i = index"
          (click)="select(images.length + i)"
          >
          <img [src]="'https://img.youtube.com/vi/' + video.code + '/maxresdefault.jpg'" alt="Video Thumbnail" />
          <i class="fas fa-play-circle"></i>
        </div>
      </div>
      <a class="scroll-right" (click)="scrollImagesRight()" *ngIf="(images.length + videos.length) > 4">
        <i class="fa fa-2x fa-caret-right"></i>
      </a>
    </div>
  </div>
  <div *ngIf="model3dFile" class="col-12 d-flex justify-content-center">
    <a (click)="view3DModel = !view3DModel" class="d-flex align-items-center" translate>
      <i class="fab fa-unity fa-2x"></i>&nbsp;<span translate>VIEW_PRODUCT_AS_3D_MODEL</span>
    </a>
  </div>
</div>

<ng-template #show3DModel>
  <div class="row">
    <div class="col-12 mb-2 text-center">
      <lib-gung-viewer3d-wrapper
        #gungViewer3dComponent
        [modelFile]="model3dFile"
        width="100%"
        height="400px"
        [initLoad]="true"
      ></lib-gung-viewer3d-wrapper>
    </div>
    <div *ngIf="model3dFile" class="col-12 d-flex justify-content-center">
      <a (click)="view3DModel = !view3DModel" class="d-flex align-items-center" translate>
        <i class="fa-solid fa-images fa-lg"></i>&nbsp;<span translate>VIEW_PRODUCT_IMAGES</span>
      </a>
    </div>
  </div>
</ng-template>
