import { Component, EventEmitter, forwardRef, Injector, Input, OnInit, Output } from '@angular/core';
import { NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { BaseInputDirective } from '../base-input/base-input.directive';

export interface PasswordRequirement {
  minCharacters: number; // Minimum charecters
  maxCharacters: number; // Maximum charecters
  especialCharacters: number; // Number of especial charecters
  lowercaseCharacters: number; // Number of lowercase charecters
  uppercaseCharacters: number; // Number of uppercase charecters
  numberCharacters: number; // Number of number chararecters
}

@Component({
  selector: 'lib-gung-password-input',
  templateUrl: './gung-password-input.component.html',
  styleUrls: ['./gung-password-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungPasswordInputComponent),
      multi: true
    }
  ]
})
export class GungPasswordInputComponent extends BaseInputDirective<string> implements OnInit {
  @Output() validPassword = new EventEmitter<boolean>(false);

  @Input()
  public extraInputClass = '';

  @Input()
  passwordRequirement: PasswordRequirement;
  passwordMessage: string;
  showPassword = false;

  @Input()
  enablePasswordRequirement = true;

  @Input()
  enableShowPassword = true;

  @Input()
  autocomplete = true;

  ngControl: NgControl;

  constructor(protected translateService: TranslateService, protected injector: Injector) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.ngControl = this.injector.get(NgControl);
  }

  public checkPassword(password: string) {
    this.validPassword.emit(false);
    if (!this.enablePasswordRequirement || !this.passwordRequirement) {
      this.validPassword.emit(true);
      return;
    }
    this.passwordMessage = '';
    if (!!this.passwordRequirement.minCharacters && password.length < this.passwordRequirement.minCharacters) {
      // this.passwordMessage += '<br>- Minimum characters ' + this.passwordRequirement.minCharacters;
      const text = this.translateService.instant('MINIMUM_CHARACTERS', {
        value: this.passwordRequirement.minCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    if (!!this.passwordRequirement.maxCharacters && password.length > this.passwordRequirement.maxCharacters) {
      // this.passwordMessage += '<br>- Maximum characters ' + this.passwordRequirement.maxCharacters;
      const text = this.translateService.instant('MAXIMUM_CHARACTERS', {
        value: this.passwordRequirement.maxCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    const formatEspecialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    if (
      !!this.passwordRequirement.especialCharacters &&
      (password.match(formatEspecialCharacters) || []).length < this.passwordRequirement.especialCharacters
    ) {
      // this.passwordMessage += '<br>- Especial characters ' + this.passwordRequirement.especialCharacters;
      const text = this.translateService.instant('ESPECIAL_CHARACTERS', {
        value: this.passwordRequirement.especialCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    const formatLowercaseCharacters = /[a-z]/g;
    if (
      !!this.passwordRequirement.lowercaseCharacters &&
      (password.match(formatLowercaseCharacters) || []).length < this.passwordRequirement.lowercaseCharacters
    ) {
      // this.passwordMessage += '<br>- Lowercase characters ' + this.passwordRequirement.lowercaseCharacters;
      const text = this.translateService.instant('LOWERCASE_CHARACTERS', {
        value: this.passwordRequirement.lowercaseCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    const formatUppercaseCharacters = /[A-Z]/g;
    if (
      !!this.passwordRequirement.uppercaseCharacters &&
      (password.match(formatUppercaseCharacters) || []).length < this.passwordRequirement.uppercaseCharacters
    ) {
      // this.passwordMessage += '<br>- Uppercase characters ' + this.passwordRequirement.uppercaseCharacters;
      const text = this.translateService.instant('UPPERCASE_CHARACTERS', {
        value: this.passwordRequirement.uppercaseCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    const formatNumberCharacters = /[0-9]/g;
    if (
      !!this.passwordRequirement.numberCharacters &&
      (password.match(formatNumberCharacters) || []).length < this.passwordRequirement.numberCharacters
    ) {
      // this.passwordMessage += '<br>- Number characters ' + this.passwordRequirement.numberCharacters;
      const text = this.translateService.instant('NUMBER_CHARACTERS', {
        value: this.passwordRequirement.numberCharacters
      });
      this.passwordMessage += '<br>- ' + text;
    }
    if (this.passwordMessage !== '') {
      const text = this.translateService.instant('PASSWORD_REQUIREMENT');
      this.passwordMessage = '<b>' + text + '</b>' + this.passwordMessage;
    } else {
      this.validPassword.emit(true);
    }
  }
}
