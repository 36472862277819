import { Injectable } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { CartService } from '../cart/cart.service';
import { OrderService } from '../orders/order.service';
import { OrderRow } from '../../models/orderRow';
import { ProductService } from '../products/product.service';
import { first, map, switchMap } from 'rxjs';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { GungStringConverterService } from '../gung-string-converter.service';

@Injectable({
  providedIn: 'root'
})
export class OrderToBasketService {
  constructor(
    protected cartService: CartService,
    protected orderService: OrderService,
    protected productService: ProductService,
    protected modalService: GungModalService,
    protected translationService: TranslateService
  ) {}

  protected addRow(row: OrderRow) {
    const extra = this.buildExtraToCopy(row);
    this.cartService.addToCart(row.productId, row.quantity, undefined, undefined, undefined, extra);
  }

  // In some cases we will need to copy properties from the row into the cart to get correct behaviour.
  // This function is supposed to be the function that brings those values out from the rows, and builds the extra
  // to add to the new cart rows.
  protected buildExtraToCopy(row: OrderRow): { [s: string]: any } | undefined {
    // TODO When we move the product configuration stuff from Lex to standard, use the below helper service instead
    // productConfigurationCartRowService.isProductConfiguration()
    const result = {};
    if (!!row.extra.productConfigurationSelectedOptions) {
      // We want to bind the productConfigurationSelectedOptions in order to be able to correctly create those
      // configurations again when we copy the carts.
      result['productConfigurationSelectedOptions'] = row.extra.productConfigurationSelectedOptions;
    }

    if (Object.keys(result).length == 0) {
      // Return undefined when we have no keys only to keep behaviour similar to how it was before this change.
      return undefined;
    } else {
      return result;
    }
  }

  public getExcludedProductIds(): string[] {
    return [];
  }

  public async addOrderToBasket(orderId: string): Promise<void> {
    const excluded = this.getExcludedProductIds();
    console.log('addOrderToBasket [excluded]', excluded);
    // check if each product in the order is blocked
    // add the product to the cart only when is not blocked
    this.orderService
      .getOrder(orderId)
      .pipe(
        first(),
        switchMap(order =>
          forkJoin({
            order: of(order),
            blockedProducts: this.productService.getBlockedProducts(order.rows.map(row => row.productId)).pipe(first())
          })
        ),
        map(({ order, blockedProducts }) => {

          this.modalService
            .openConfirmYesNoModal(undefined, this.translationService.instant('LOAD_ORDER_CART_CONFIRMATION'), {
              size: 'sm'
            })
            .then(result => {
              const blocked: string[] = [];
              const orderRows: OrderRow[] = [];
              if (result) {
                this.cartService.clearCart();

                for (const productId of Object.keys(blockedProducts)) {
                  // Enable exclusion of specific product ids, such as freight products
                  if (excluded.includes(productId)) {
                    continue;
                  }

                  if (!blockedProducts[productId]) {
                    // CHANGED BECAUSE PRODUCT ID KEEP / and . on rows
                    /* const orderRow = order.rows.find(
                      row => GungStringConverterService.toGungString(row.productId) === productId
                    ); */
                    const orderRow = order.rows.find(
                      row => row.productId === productId
                    );
                    if (!!orderRow) {
                      // this.addRow(orderRow);
                      orderRows.push(orderRow);
                    }
                  } else {
                    blocked.push(productId);
                  }
                }
              }
              if (blocked.length > 0) {
                this.modalService
                  .openConfirmYesNoModal(
                    this.translationService.instant('EXCLUDED_PRODUCTS'),
                    this.translationService.instant('EXCLUDED_PRODUCTS_MESSAGE', { products: blocked.join(', ') }),
                    null,
                    'OK',
                    null
                  )
                  .then(proceed => {
                    if (proceed) {
                      orderRows.forEach(orderRow => this.addRow(orderRow));
                    }
                  });
              } else {
                orderRows.forEach(orderRow => this.addRow(orderRow));
              }
            });
        })
      )
      .toPromise();
  }
}
