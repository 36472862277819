import { Component, OnInit } from '@angular/core';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-create-availability-condition-modal',
  templateUrl: './create-availability-condition-modal.component.html',
  styleUrls: ['./create-availability-condition-modal.component.css']
})
export class CreateAvailabilityConditionModalComponent implements OnInit {
  name = '';
  expressionString = '';
  delegate: GungModalService;

  constructor() {}

  ngOnInit() {}

  close() {
    this.delegate.modalRef.get(CreateAvailabilityConditionModalComponent.name).close();
  }

  saveCondition() {
    this.delegate.modalRef.get(CreateAvailabilityConditionModalComponent.name).close({
      name: this.name,
      expressionString: this.expressionString,
      params: []
    });
  }
}
