import {
  Component,
  OnInit,
  OnDestroy,
  OnChanges,
  ComponentFactory,
  QueryList,
  Type,
  ViewChildren,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  ViewContainerRef,
  AfterViewInit,
  EventEmitter
} from '@angular/core';
import { CustomHostDirective, ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models/product';
import { PriceService } from '../../services/price/price.service';
import { CustomerProductPrice } from '../../models/price';
import { Availability } from '../../models/availability';
import { catchError, first, mergeMap, tap } from 'rxjs';
import { forkJoin, of, Subscription, Subject } from 'rxjs';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { AuthService } from '../../services/auth/auth.service';
import { GungFlow } from '../../state/flow/types';
import { ProductListV2ConfigService } from '../../services/product-list-v2-config/product-list-v2-config.service';

export interface GridViewProductCardV2 {
  product: Product;
  price: CustomerProductPrice;
  availability: Availability;
  isSales: boolean;
  isAnonymous: boolean;
  includeAvailability: boolean;
}
@Component({
  selector: 'lib-product-grid-view-v2',
  templateUrl: './product-grid-view-v2.component.html',
  styleUrls: ['./product-grid-view-v2.component.css']
})
export class ProductGridViewV2Component
  extends ListItemRendererComponent<Product[]>
  implements OnInit, OnDestroy, OnChanges {
  @ViewChildren(CustomHostDirective)
  private viewChildren: QueryList<CustomHostDirective>;

  public listItemRenderer: Type<ListItemRendererComponent<GridViewProductCardV2>>;

  protected keyedMapData: { [id: string]: GridViewProductCardV2 } = {};
  protected subscriptions: Subscription[] = [];
  protected flowSubscriptions: Subscription;

  public includeAvailability = false;
  public isSales = false;
  public isAnonymous = true;
  public mappedData: GridViewProductCardV2[] = [];

  protected mappedDataChanged: Subject<void> = new Subject<void>();
  protected unsubscribe: Subject<void> = new Subject();

  protected currentFlow: GungFlow;
  protected oldFlow: GungFlow;
  renderedIds: { [s: string]: string } = {};
  errorMessage = 'ERROR: ';
  findError = false;
  private componentFactory: ComponentFactory<ListItemRendererComponent<GridViewProductCardV2>>;

  private viewInitiated = false;
  public parentClassCss: string
  public childColClassCss: { [classCss: string]: boolean } = {
    'col-': false,
    'col-sm-': false,
    'col-md-': false,
    'col-lg-': false,
    'col-xl-': false,
  };

  protected savedLocationLoaded: boolean = false;

  isLoading = true;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected productListV2Config: ProductListV2ConfigService
  ) {
    super();
  }

  public ngOnInit() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales =
          roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;
        this.isAnonymous = roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
      });
    this.flowSubscriptions = this.gungFlowService.getSelectedFlow().subscribe(flow => (this.currentFlow = flow));
    this.subscribeToProducts();
    
    this.mappedDataChanged.subscribe(res => {
      this.renderItemComponents();
    });
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    const children = this.viewChildren.toArray();
    if (!this.mappedData || this.mappedData.length === 0) {
      this.renderedIds = {};
      return;
    }
    for (let i = 0; i < this.mappedData.length; i++) {
      if (!this.renderedIds[this.mappedData[i].product.id]) {
        // Dont rerender items that are already rendered
        this.renderItemComponent(this.mappedData[i], children[i].viewContainerRef);
      }
    }

    this.changeDetectorRef.detectChanges();
    this.renderedIds = {};
    this.data.map(d => {
      this.renderedIds[d.id] = d.id;
      return d.id;
    });
    this.isLoading = false;
    if (!this.savedLocationLoaded) {
      this.renderFinished?.emit();
      this.savedLocationLoaded = true;
    }
  }

  renderItemComponent(item: GridViewProductCardV2, containerRef: ViewContainerRef) {
    containerRef.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.productListV2Config.getProductGridCardComponent(item)
    );
    const componentRef = containerRef.createComponent(factory);
    // this gives the data to the card
    const typed = componentRef.instance as ListItemRendererComponent<GridViewProductCardV2>;
    typed.data = item;
  }

  public ngOnChanges() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.gungFlowService.getSelectedFlow().pipe(first()).subscribe(flow => {
      this.currentFlow = flow;
      this.subscribeToProducts()
    });
    
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.flowSubscriptions.unsubscribe();

    this.mappedDataChanged.complete();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    this.isLoading = true;
    if (!this.oldFlow || !this.currentFlow || this.oldFlow.id !== this.currentFlow.id) {
      // remove cached data;
      this.keyedMapData = {};
      this.renderedIds = {};
      this.oldFlow = this.currentFlow;
    }
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }

    const subscription = forkJoin([
      of(this.data),
      this.priceService.getCurrentCustomerPrices(newIds).pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ])
      .pipe(first())
      .subscribe(data => {
        const productData = data[0];
        const prices = data[1];
        let avs = [];
        this.includeAvailability = data[2].useAvailabilities || data[2].requireAvailability;
        if (this.includeAvailability) {
          avs = newIds.map(id => this.getProductAvailability(this.data.find(product => product.id === id)));
        }

        this.keyedMapData = {
          ...this.keyedMapData,
          ...newIds.reduce((acc, curr) => {
            const product = productData.filter(p => p && p.id === curr)[0];

            if (!product) {
              this.findError = true;
              this.errorMessage += 'No product found. ';
              throw new Error('No product found');
            }

            const price = prices.filter(p => p && p.productId === curr)[0];
            if (!price) {
              this.findError = true;
              this.errorMessage += 'No price found.';
              throw new Error('No price found');
            }

            const av = avs.filter(p => p && p.productId === curr)[0];
            if (!av && this.includeAvailability) {
              this.findError = true;
              this.errorMessage += 'No availability found. ';
              throw new Error('No availability found');
            }

            const item: GridViewProductCardV2 = this.mapItem(curr, product, price, av);
            return {
              ...acc,
              [curr]: item
            };
          }, {})
        };
        this.readMappedDataFromCache();
      });
    this.subscriptions.push(subscription);
  }

  protected getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }

  protected readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);

    this.mappedDataChanged.next();
  }

  protected mapItem(
    id: string,
    product: Product,
    price: CustomerProductPrice,
    availability: Availability
  ): GridViewProductCardV2 {
    const isSales = this.isSales;
    const isAnonymous = this.isAnonymous;
    const includeAvailability = this.includeAvailability;
    return {
      product,
      price,
      availability,
      isSales,
      isAnonymous,
      includeAvailability
    };
  }

  public trackByFn(index: number, item: any) {
    if (!!item.product) {
      return item.product.id;
    } else {
      return index;
    }
  }
}
