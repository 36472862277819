export function printDocument(body: any, filename: string): void {
  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([body], { type: 'application/pdf' });

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  const navigator = window.navigator as any;
  if (navigator && navigator.msSaveOrOpenBlob) {
    navigator.msSaveOrOpenBlob(newBlob, filename);
    return;
  }

  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob);

  // Print the pdf
  const iframe = document.createElement('iframe'); //load content in an iframe to print later
  document.body.appendChild(iframe);

  iframe.style.display = 'none';
  iframe.src = data;
  iframe.onload = function () {
    setTimeout(function () {
      iframe.focus();
      iframe.contentWindow.print();
    }, 1);
  };
}
