import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { PimService } from '../../../services/pim/pim.service';

@Component({
  selector: 'lib-pim-upload-excel-modal',
  templateUrl: './pim-upload-excel-modal.component.html',
  styleUrls: ['./pim-upload-excel-modal.component.css']
})
export class PimUploadExcelModalComponent implements OnInit {
  uploading = false;
  error = false;
  success = false;
  uploadForm: FormGroup;

  constructor(
    protected formBuilder: FormBuilder,
    protected activeModal: NgbActiveModal,
    protected pimService: PimService
  ) {}

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({ importExcel: [''] });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('importExcel').setValue(file);
    }
  }

  onSubmit() {
    this.uploading = true;

    const formData = new FormData();
    formData.append('file', this.uploadForm.get('importExcel').value);

    this.pimService
      .postPimExcelDocument(formData)
      .pipe(first())
      .subscribe(
        result => {
          this.success = true;
        },
        error => {
          this.error = true;
        },
        () => (this.uploading = false)
      );
  }

  close(): void {
    this.activeModal.dismiss();
  }
}
