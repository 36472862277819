<div *ngIf="!isloading; else loading">
  <div class="table-responsive">
    <table class="table table-striped table-sticky">
      <thead>
        <tr>
          <th class="left-collumn">
            <strong translate>COLOR_SIZE</strong>
          </th>
          <th *ngFor="let secondaryDimension of product.secondaryDimension" style="text-align: center">
            {{ secondaryDimension.name }}
          </th>
          <th>
            <strong translate>TOTAL</strong>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let primaryDimension of primaryDimensionFilteredByFlowRequiredAvailability;
            index as rowIndex;
            trackBy: trackByFn
          "
        >
          <td class="left-collumn">
            <div class="primary-dimension">
              <div>{{ primaryDimension.id }}</div>
              <div>{{ primaryDimension.name }}</div>
            </div>
            <div *ngIf="rowIndex !== primaryDimensionFilteredByFlowRequiredAvailability.length - 1">
              <span class="pr-1" *ngIf="rowIndex !== primaryDimensionFilteredByFlowRequiredAvailability.length - 2">
                <button
                  type="button"
                  class="fa fa-angle-double-down"
                  tabindex="-1"
                  [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ALL')"
                  (click)="copyDown('all', rowIndex)"
                ></button>
              </span>
              <button
                type="button"
                class="fa fa-angle-down"
                tabindex="-1"
                [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ONCE')"
                (click)="copyDown('once', rowIndex)"
              ></button>
            </div>
          </td>
          <td *ngFor="let secondaryDimension of product.secondaryDimension" class="wrapper-input">
            <lib-product-matrix-wrapper-input-element
              [id]="matrixInputMap.get(product.modelId + primaryDimension.id + secondaryDimension.id).id"
              [productPartialId]="productPartialId"
              [product]="
                matrixInputMap.get(product.modelId + primaryDimension.id + secondaryDimension.id).product || product
              "
              [availability]="
                matrixInputMap.get(product.modelId + primaryDimension.id + secondaryDimension.id).availability
              "
              [readOnly]="matrixInputMap.get(product.modelId + primaryDimension.id + secondaryDimension.id).readOnly"
              [rowOrder]="rowIndex"
              (quantityUpdated)="setTotalQuantity($event)"
              (selectedId)="selectedId.emit($event)"
              (focusNextOutput)="focusNext($event, product.modelId, primaryDimension.id, secondaryDimension.id)"
            >
            </lib-product-matrix-wrapper-input-element>
          </td>
          <td class="summary-input">
            <lib-product-matrix-summary-element
              [simplifiedAvailability]="matrixSummaryPrimaryDimensionMap.get(primaryDimension.id).availability"
              [product]="
                matrixInputMap.get(product.modelId + primaryDimension.id + product.secondaryDimension[0].id).product ||
                product
              "
              [value]="matrixSummaryPrimaryDimensionMap.get(primaryDimension.id).value"
            >
            </lib-product-matrix-summary-element>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <th class="left-collumn">
            <strong translate>TOTAL</strong>
          </th>
          <th *ngFor="let secondaryDimension of product.secondaryDimension">
            <lib-product-matrix-summary-element
              [simplifiedAvailability]="matrixSummarySecondaryDimensionMap.get(secondaryDimension.id).availability"
              [product]="matrixSummarySecondaryDimensionMap.get(secondaryDimension.id).product || product"
              [value]="matrixSummarySecondaryDimensionMap.get(secondaryDimension.id).value"
            >
            </lib-product-matrix-summary-element>
          </th>
          <th>
            <lib-product-matrix-summary-element
              [simplifiedAvailability]="matrixSummaryAllMap.get('').availability"
              [product]="matrixSummarySecondaryDimensionMap.get(product.secondaryDimension[0].id).product || product"
              [value]="matrixSummaryAllMap.get('').value"
            ></lib-product-matrix-summary-element>
          </th>
        </tr>
      </tfoot>
    </table>
  </div>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-3x"></i>
</ng-template>
