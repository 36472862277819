import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PdfDocument } from '../../models/pdf-document';
import { DynamicPdfConfigService } from '../../services/dynamic-pdf-config.service';
import { DynamicPdfService } from '../../services/dynamic-pdf.service';

@Component({
  selector: 'lib-dynamic-product-export-list',
  templateUrl: './dynamic-product-export-list.component.html',
  styleUrls: ['./dynamic-product-export-list.component.scss']
})
export class DynamicProductExportListComponent implements OnInit {
  showProductList = false;
  showDigitalAssets = false;
  id = null;
  pdfDocument: PdfDocument;
  renderPage = false;

  constructor(
    protected route: ActivatedRoute,
    protected dynamicPdfService: DynamicPdfService,
    public dynamicPdfConfigService: DynamicPdfConfigService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.id = params.id;
      if (params.id) {
        this.dynamicPdfService.getPdf(params.id).subscribe(pdf => {
          this.pdfDocument = pdf;
          this.renderPage = true;
        });
      } else {
        this.renderPage = true;
      }
    });
  }

  options() {}
  openOptionsProductList() {
    if (this.showDigitalAssets) {
      this.showDigitalAssets = !this.showDigitalAssets;
    }
    this.showProductList = !this.showProductList;
  }

  openOptionsDigitalAssets() {
    if (this.showProductList) {
      this.showProductList = !this.showProductList;
    }
    this.showDigitalAssets = !this.showDigitalAssets;
  }
}
