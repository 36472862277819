<div class="form-group">
  <label [for]="inputId" *ngIf="!suppressLabel">
    {{ translateTag | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="input-group">
    <input
      type="{{ showPassword ? 'text' : 'password' }}"
      [class]="extraInputClass"
      [ngClass]="{ 'form-control-plaintext': renderStatic, 'form-control': !renderStatic }"
      [readonly]="renderStatic"
      [id]="inputId"
      [name]="inputId"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      [(ngModel)]="value"
      (ngModelChange)="checkPassword($event)"
      [autocomplete]="autocomplete"
    />
    <!-- [ngModelOptions]="{updateOn: 'blur'}" -->
    <div class="input-group-append" *ngIf="enableShowPassword">
      <button class="btn btn-outline-secondary" type="button" id="button-addon2" (click)="showPassword = !showPassword">
        <i class="fas" [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"></i>
      </button>
    </div>
  </div>
</div>
<div class="alert alert-danger" role="alert" *ngIf="enablePasswordRequirement && !!passwordMessage">
  <span [innerHtml]="passwordMessage | safeHtml"></span>
</div>
