import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GeneratedZip, ImageZipDownloadService } from '../../../services/image-zip-download/image-zip-download.service';

@Component({
  selector: 'lib-download-multiple-files',
  templateUrl: './download-multiple-files.component.html',
  styleUrls: ['./download-multiple-files.component.css']
})
export class DownloadMultipleFilesComponent implements OnInit {
  @Input()
  orderId: string;

  @Input()
  type: string;

  isLoading = true;
  title: string;
  htmlContent: string;
  hideFooter: boolean;
  isError = false;

  zip: GeneratedZip[];

  constructor(public activeModal: NgbActiveModal, protected imageZipDownloadService: ImageZipDownloadService) {}

  ngOnInit(): void {
    this.imageZipDownloadService.getMultipleZipFiles(this.orderId, this.type).subscribe(
      response => {
        this.zip = response.files;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.isError = true;
      }
    );
  }
}
