import { EdiGungMessages } from './../../../models/edi';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { EdiGlnNumbersListConfigService } from '../../../services/edi-gln-numbers-list-config/edi-gln-numbers-list-config.service';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiInvoiceModalComponent } from '../edi-invoice-modal/edi-invoice-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';

@Component({
  selector: 'lib-edi-gln-numbers',
  templateUrl: './edi-gln-numbers.component.html'
  // template: ''
})
export class EdiGlnNumbersComponent implements OnInit {
  @ViewChild('list', { static: false }) list: FilterListComponent<EdiGungMessages>;

  constructor(
    public configService: EdiGlnNumbersListConfigService,
    protected ediService: EdiService,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  ngOnInit() {}

  sendSingleInvoice() {
    const modalRef = this.modalService.open(EdiInvoiceModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.componentInstance.data = { invoiceNumber: null, orderNumberModel: null };
    modalRef.result.then(
      result => {
        this.gungModalService
          .openConfirmYesNoModal(
            undefined,
            'Sucessfully created EDI message for invoice ',
            { size: 'sm' },
            'OK',
            undefined
          )
          .then(_ => {});
        this.ediService.generateInvoice(result.invoiceNumber, result.orderNumberModel).subscribe(
          (response: any) => {
            const msg = 'Sucessfully created EDI message for invoice ' + response.invoiceNumber;
            this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', undefined).then(_ => {});
          },
          error => {
            const msg = error.data.message;
          }
        );
      },
      reason => {}
    );
  }

  downloadEanXlsx() {
    const url = this.backendInterceptor.getBaseUrl() + '/download/edi/ean-numbers';
    const win = window.open(url, '_blank');
    win.focus();
  }
}
