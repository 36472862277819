import { Component, OnInit } from '@angular/core';
import { forkJoin, of } from 'rxjs';
import { first } from 'rxjs';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { CartService } from './../../../../services/cart/cart.service';
import { ProductService } from './../../../../services/products/product.service';
import { GungModalService } from './../../../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-remove-blocked-products-step',
  templateUrl: './remove-blocked-products-step.component.html',
  styleUrls: ['./remove-blocked-products-step.component.css']
})
export class RemoveBlockedProductsStepComponent extends CheckoutStepComponent implements OnInit {
  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected modalService: GungModalService,
    protected translateService: TranslateService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.checkBlockedProducts();
  }

  protected checkBlockedProducts(): void {
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.cartService
        .getCurrentCart()
        .pipe(first())
        .subscribe(currentCartRows => {
          const productIds = currentCartRows.map(cartRow => cartRow.productId);

          forkJoin({
            cartRows: of(currentCartRows),
            blockedProducts: this.productService.getBlockedProducts(productIds).pipe(first())
          }).subscribe(({ cartRows, blockedProducts }) => {
            const blockedProductsIds: string[] = [];
            const rowsToRemove: any[] = [];
            for (const productId of Object.keys(blockedProducts)) {
              // in case product is blocked then remove it from the cart
              if (blockedProducts[productId]) {
                const cartRow = cartRows.find(cr => cr.productId === productId);
                if (!!cartRow) {
                  rowsToRemove.push(cartRow);
                  blockedProductsIds.push(productId);
                }
              }
            }
            this.cartService.bulkRemoveRows(rowsToRemove);

            if (blockedProductsIds.length > 0) {
              this.modalService
                .openConfirmYesNoModal(
                  this.translateService.instant('BLOCKED_PRODUCTS'),
                  this.translateService.instant('BLOCKED_PRODUCTS_MESSAGE', {
                    products: blockedProductsIds.join(', ')
                  }),
                  null,
                  'OK',
                  null
                )
                .then(_ => {});
            }

            this.stepDone.emit(this.checkout);
          });
        });
    });
  }

  isVisible() {
    return false;
  }
}
