import { QuantityPayload } from '../actions';
import { getRowFromRows, filterOutRow } from './common';
import { CartRow } from '../types';
import { mergeDeep } from '../../../utils/merge-utils';

export const replaceReducer = (current: CartRow[], payload: QuantityPayload[]): CartRow[] => {
  return mapQuantityPayload(payload);
};

const mapQuantityPayload = (payload: QuantityPayload[]): CartRow[]=> {
  const date = new Date();
  const result = [];
  payload.forEach(p => result.push({
    productId: p.productId,
    qty: p.qty,
    timeAdded: date,
    timeUpdated: date,
    extra: p.extra || {},
    rownr: p.rownr,
    targetStockId: p.targetStockId,
    productPartialId: p.productPartialId 
  }));
  return result;
};
