import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';

@Component({
  selector: 'lib-template-item-editor-modal',
  templateUrl: './template-item-editor-modal.component.html',
  styleUrls: ['./template-item-editor-modal.component.css']
})
export class TemplateItemEditorModalComponent {
  // @Input() item: any;
  @Input('item')
  set item(value) {
    const item = JSON.parse(value);
    this.submitForm.patchValue(item);
  }

  @Input() isActuator;
  @Input() 
  public cloneItem: boolean;

  submitForm: FormGroup;

  constructor(protected formBuilder: FormBuilder, public ngbActiveModal: NgbActiveModal) {
    this.setForm();
  }

  setForm() {
    this.submitForm = this.formBuilder.group({
      active: this.formBuilder.control(false),
      ignoreErrors: this.formBuilder.control(false),
      title: this.formBuilder.control(''),
      targetPath: this.formBuilder.control(''),
      expression: this.formBuilder.control('')
    });
  }

  onSubmit() {
    if (!this.submitForm.valid) {
      this.submitForm.markAllAsTouched();
      return;
    }
    const item = this.submitForm.getRawValue();
    this.submitForm.reset();
    this.ngbActiveModal.close(JSON.stringify(item));
  }
}
