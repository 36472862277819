<div class="pt-2">
  <div class="row form-group" *ngFor="let setting of settings; trackBy: trackByFn">
    <div class="col-12">
      <label for="{{ setting.label }}" class="control-label" translate>{{ setting.label }}</label>
      <input
        class="form-control"
        type="text"
        name="{{ setting.label }}"
        id="{{ setting.label }}"
        [value]="setting?.value"
        (blur)="setValue($event.target.value, setting)"
      />
      <!-- [(ngModel)]="curentGungFlow.orderParams[setting.key][setting.innerKey]" -->
    </div>
  </div>
</div>
