<div class="row pb-3 d-none d-md-flex" *ngIf="!!mappedData">
  <div class="col-9 text-right">
    <div class="row">
      <div class="col">
        <span translate>REQUEST_DELIVERY_DATE</span>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <button type="button" class="btn btn-link p-0" (click)="restoreMinDate()">
          (<span class="small" translate>RESTORE_AVAILABLE_DATES</span>)
        </button>
      </div>
    </div>
  </div>
  <div class="col-2">
    <lib-delivery-date-picker
      class="request-delivery-date"
      label=""
      [initialDate]="deliveryDate"
      (selectedDate)="dateSelected($event)"
    ></lib-delivery-date-picker>
  </div>
  <div class="col-1"></div>
</div>
<div class="row pb-2 d-none d-md-flex">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-lg-block col-12 col-md-6 col-lg-3"></div>
  <div class="d-none d-lg-block col-4 col-lg-2">
    <b translate>REQUESTED_QUANTITY</b>
  </div>
  <div class="d-none d-lg-block col-4 col-lg-2">
    <b translate>UNIT_PRICE</b>
  </div>
  <div class="vhr d-none d-lg-block col-4 col-lg-1">
    <b translate>TOTAL_PRICE</b>
  </div>
  <div class="d-none d-xl-block col-4 col-lg-3 col-xl-2">
    <b translate>DELIVERY</b>
  </div>
  <div class="col-1"></div>
</div>
<span *ngFor="let mappedDataRow of mappedData; trackBy: trackByFn">
  <div class="row pt-1 pb-1 align-items-center">
    <div
      class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
      [routerLink]="['/product/', mappedDataRow.product.id]"
    >
      <img
        class="img-fluid"
        *ngIf="mappedDataRow.product.extra.images && mappedDataRow.product.extra.images.length > 0"
        [src]="mappedDataRow.product.extra.images[0].s3Uri
          | gungImageUrl
            :'type:list'
            :'etag:'+mappedDataRow.product.extra.images[0].s3ETag
        "
        alt=""
      />
    </div>

    <div
      class="col-8 col-lg-3 text-truncate navigate-to-product-details"
      [routerLink]="['/product/', mappedDataRow.product.id]"
    >
      <div>
        <b>{{ mappedDataRow.product.id }}</b> - {{ mappedDataRow.product.name }}
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0 col-request-quantity">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none align-items-center">
          <b translate>REQUESTED_QUANTITY</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          <lib-buy-btn
            [id]="mappedDataRow.product.id"
            [product]="mappedDataRow.product"
            [checkoutCart]="true"
          ></lib-buy-btn>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none">
          <b translate>UNIT_PRICE</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          <b>
            {{
              mappedDataRow.price.cartRowUnitPrice.value
                | gungCurrency: mappedDataRow.price.cartRowUnitPrice.currencyCode:'symbol-narrow':'1.2-2'
            }}
            <span *ngIf="mappedDataRow.price.cartRowDiscountPercent !== 0"
              >(-{{ mappedDataRow.price.cartRowDiscountPercent }}%)</span
            >
          </b>
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-1 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-lg-none">
          <b translate>TOTAL_PRICE</b>
        </div>
        <div class="col-8 col-lg-12 d-flex">
          {{
            mappedDataRow.price.cartRowTotalPriceInclRowDiscount.value
              | gungCurrency: mappedDataRow.price.cartRowTotalPriceInclRowDiscount.currencyCode
          }}
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-8 col-lg-3 col-xl-2 offset-sm-3 offset-lg-0">
      <div class="row pt-1">
        <div class="col-4 d-flex d-xl-none">
          <b translate>DELIVERY</b>
        </div>
        <div class="col-8 col-xl-12 d-flex">
          <div class="input-group">
            <lib-delivery-date-picker
              class="request-delivery-date"
              label=""
              [initialDate]="
                mappedDataRow.deliveryDate ? mappedDataRow.deliveryDate : formatNgbDate(mappedDataRow.minDate)
              "
              (selectedDate)="updateDeliveryDate(mappedDataRow, $event)"
              [currentselectedDate]="mappedDataRow.deliveryDate"
            ></lib-delivery-date-picker>
          </div>
        </div>
      </div>
    </div>

    <div
      class="col-2 col-md-1 order-first order-lg-0 offset-11 offset-lg-0 d-flex align-items-center col-delete-button"
    >
      <button class="btn btn-light delete-button" (click)="removeRow(mappedDataRow.cartRow)">
        <i class="fal fa-minus-circle"></i>
      </button>
    </div>
  </div>

  <div class="row hr"></div>
</span>
