<div *ngIf="errors">
  <!-- <div class="row">
        <div class="col-9">
            <h3 translate>ERRORS</h3>
        </div>
        <div class="col-3">
            <button class="btn btn-sm btn-primary" (click)="validateAgain()" translate>VALIDATE</button>
        </div>
    </div> -->
  <table class="table table-striped">
    <thead>
      <tr>
        <th class="col-2" translate>TYPE</th>
        <th class="col-4" translate>MESSAGE</th>
        <th class="col-1" translate>SOURCE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let error of errors">
        <td>
          {{ error.type }}
        </td>
        <td>
          {{ error.error }}
        </td>
        <td>
          {{ error.validator }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
