import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DiscountCodesListConfigService } from '../../services/discount-codes-list-config/discount-codes-list-config.service';
import { DiscountCodesService } from '../../services/discount-codes.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-discount-codes',
  templateUrl: './discount-codes.component.html',
  styleUrls: ['./discount-codes.component.css']
})
export class DiscountCodesComponent implements OnInit {
  constructor(
    public discountCodesListConfigService: DiscountCodesListConfigService,
    protected gungModalService: GungModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected discountCodesService: DiscountCodesService
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const code = params.get('code');
      if (code) {
        this.discountCodesService
          .getDiscountCode(code, true)
          .subscribe(discountCode => this.openDiscountCodeModal(discountCode));
      }
    });
  }

  openDiscountCodeModal(discountCode?) {
    this.gungModalService
      .openDiscountCodesModal(discountCode)
      .then(
        result => {
          // console.log('openDiscountCodeModal', result);
        },
        reason => {}
      )
      .finally(() => {
        if (this.route.snapshot.paramMap.has('code')) {
          this.router.navigate(['../'], { relativeTo: this.route });
        }
      });
  }
}
