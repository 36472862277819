import { Injectable } from '@angular/core';
import { AppState } from '../../state/state.module';
import { Store } from '@ngrx/store';
import { Metadata, TableRecord } from '../../state/metadata/types';
import { Observable, empty } from 'rxjs';
import { map, tap, filter, first } from 'rxjs';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class MetadataService {
  protected metadata: Metadata = {};

  constructor(protected store: Store<AppState>) {
    this.getMetadata().subscribe(d => (this.metadata = d));
  }

  protected getMetadata(): Observable<Metadata> {
    return this.store.select(state => state.metadata.metadata);
  }

  public getMetadataDirect(): Metadata {
    return this.metadata;
  }
  getMetadataCompleted(): Observable<void> {
    return this.store
      .select(state => state.metadata.metadata)
      .pipe(
        filter(metadata => !!metadata && Object.keys(metadata).length > 0),
        map(_ => {
          return;
        }),
        first()
      );
  }

  getMetadataTableList(tableName: string): [TableRecord] {
    try {
      return this.metadata[tableName].list;
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetaDataTableMap(tableName: string): { [key: string]: TableRecord } {
    try {
      return this.metadata[tableName].map;
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataValue(tableName: string, valueName: string, key: string): string | undefined {
    return this.getMetadataValueInternal(this.metadata, tableName, valueName, key);
  }

  getMetadataValue$(tableName: string, valueName: string, key: string): Observable<string> {
    return this.getMetadata().pipe(
      tap(metadata => (this.metadata = metadata)),
      map(metadata => this.getMetadataValueInternal(metadata, tableName, valueName, key))
    );
  }

  protected getMetadataValueInternal(
    metadata: Metadata,
    tableName: string,
    valueName: string,
    key: string
  ): string | undefined {
    try {
      return this.resolve(valueName, metadata[tableName].map[key]);
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataPost(tableName: string, key: string): any {
    return this.getMetadataPostInternal(this.metadata, tableName, key);
  }

  getMetadataPost$(tableName: string, key: string): Observable<any> {
    return this.getMetadata().pipe(
      tap(metadata => (this.metadata = metadata)),
      map(metadata => this.getMetadataPostInternal(metadata, tableName, key))
    );
  }

  protected getMetadataPostInternal(metadata: Metadata, tableName: string, key: string): any {
    try {
      return metadata[tableName].map[key];
    } catch (ex) {
      // empty catch clause, returns undefined
    }
    return undefined;
  }

  getMetadataValues(tableName: string, keyField: string, valueField?: string): { [key: string]: TableRecord | string } {
    return this.getMetadataValuesInternal(this.metadata, tableName, keyField, valueField);
  }

  getMetaValueWithLang(tableName: string, key: string, currentLang: string, langKey = 'i18n'): string {
    if (!key) {
      return undefined;
    }
    if (!this.metadata[tableName]) {
      return undefined;
    }

    const metaElement = this.metadata[tableName].list.find(element => element.id === key);
    if (!metaElement) {
      return undefined;
    }

    // Fallback to english
    currentLang = currentLang || 'en';
    if (!metaElement?.[langKey]?.[currentLang]?.name) {
      // Fallback to name
      return metaElement.name;
    }

    return metaElement[langKey][currentLang].name;
  }

  getMetadataValuesAsArray(
    tableName: string,
    keyField: string,
    valueField?: string
  ): { key: string; value: TableRecord | string }[] {
    return this.toKeyValueArray(this.getMetadataValues(tableName, keyField, valueField));
  }

  getMetadataValues$(
    tableName: string,
    keyField: string,
    valueField?: string
  ): Observable<{ [key: string]: TableRecord | string }> {
    return this.getMetadata().pipe(
      map(metadata => this.getMetadataValuesInternal(metadata, tableName, keyField, valueField))
    );
  }

  getMetadataValuesAsArray$(
    tableName: string,
    keyField: string,
    valueField?: string
  ): Observable<{ key: string; value: TableRecord | string }[]> {
    return this.getMetadataValues$(tableName, keyField, valueField).pipe(map(data => this.toKeyValueArray(data)));
  }

  protected toKeyValueArray(data: {
    [key: string]: TableRecord | string;
  }): { key: string; value: TableRecord | string }[] {
    const keys = Object.keys(data);
    return keys.map(key => ({ key, value: data[key] }));
  }

  protected getMetadataValuesInternal(
    metadata: Metadata,
    tableName: string,
    keyField: string,
    valueField?: string
  ): { [key: string]: TableRecord | string } {
    if (!metadata[tableName] || !metadata[tableName].list) {
      return {};
    }
    const res = metadata[tableName].list.reduce(
      (prev, curr) => ({
        ...prev,
        [this.resolve(keyField, curr)]: valueField && valueField !== '' ? this.resolve(valueField, curr) : curr
      }),
      {}
    );
    return res;
  }

  private resolve(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    const res = properties.reduce((prev, curr) => prev && prev[curr], obj);
    return res;
  }

  public formValidatorMetadata(table, metaId, metaDisplay): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const meta = this.getMetadataValue(table, metaDisplay, value);
      if (meta === undefined) {
        return { FORM_INVALID_METADATA_VALUE: true };
      }
      return null;
    };
  }
}
