import { Component } from '@angular/core';
import { Product } from '../../models';
import { FastsearchItemRendererComponent } from 'gung-list';
import { map, switchMap, take } from 'rxjs';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'lib-product-fastsearch-detail',
  templateUrl: './product-fastsearch-detail.component.html',
  styleUrls: ['./product-fastsearch-detail.component.css']
})
export class ProductFastsearchDetailComponent extends FastsearchItemRendererComponent<Product> {
  constructor() {
    super();
  }
}
