<h3 translate class="page-title text-center">PIM_DIMENSIONS</h3>
<div class="row">
  <div class="col-sm-6 col-lg-2">
    <button type="button" class="btn btn-secondary" (click)="createDimension()" translate>CREATE</button>
  </div>
  <div class="col-sm-6 col-lg-10"></div>
  <div class="col-12">
    <lib-filter-list [configService]="pimDimensionListConfigService"></lib-filter-list>
  </div>
</div>
