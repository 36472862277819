import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';

import { first, map, switchMap, catchError } from 'rxjs';
import { SelectedCustomerService } from './selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesService {
  constructor(protected http: HttpClient, protected selectedCustomerService: SelectedCustomerService) {}

  public getIsDiscountCodeValid(discountCode: string): Observable<boolean> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      map(cust => cust.id),
      switchMap(companyId => this.internalGetValidDiscountCode(discountCode, companyId)),
      catchError(_ => of(false)),
      map(val => !!val)
    );
  }

  public getDiscountCode(discountCode: string, nocache?: boolean): Observable<DiscountCode> {
    return this.internalGetDiscountCode(discountCode, nocache);
  }

  protected internalGetDiscountCode(discountCode: string, nocache?: boolean): Observable<DiscountCode> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<DiscountCode>(`json/discount-codes/${discountCode}`, { headers });
  }
  protected internalGetValidDiscountCode(discountCode: string, companyId: string): Observable<DiscountCode> {
    return this.http.get<DiscountCode>(`json/discount-codes/${discountCode}/${companyId}/get-valid`);
  }
}

export interface DiscountCode {
  code: string;
  description: string;
  extra: { [key: string]: any };
  usedByCompanyForOrderMap: { [company: string]: string[] };
}
