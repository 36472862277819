import { Component, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { PimConcept } from '../../../models/pim';

@Component({
  selector: 'lib-brands-list',
  templateUrl: './brands-list.component.html',
  styleUrls: ['./brands-list.component.css']
})
export class BrandsListComponent extends ListItemRendererComponent<PimConcept[]> implements AfterViewInit {
  constructor(protected router: Router) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  goToEditBrand(brand) {
    if (brand && brand.id) {
      this.router.navigate(['/admin/related-brands/' + brand.id]);
    }
  }
}
