import { Component, OnInit, OnDestroy } from '@angular/core';
import { OrderAnalyticsConfigService } from '../../../services/order-analytics-config/order-analytics-config.service';
import { Subject, takeUntil } from 'rxjs';
import { StandardStatisticsData } from '../../../models/statistics';
import { OrderAnalyticsProductListConfigService } from '../../../services/order-analytics-product-list-config.service';

@Component({
  selector: 'lib-order-analytics-standalone',
  templateUrl: './order-analytics-standalone.component.html',
  styleUrls: ['./order-analytics-standalone.component.css']
})
export class OrderAnalyticsStandaloneComponent implements OnInit, OnDestroy {
  data: StandardStatisticsData[];
  protected unsubscribe: Subject<void> = new Subject();
  selectedGraph: OrderAnalyticsGraphList;
  graphList: OrderAnalyticsGraphList[] = [
    {
      id: '1',
      translationKey: 'YEAR',
      groupBy: 'orderCreationDateYear',
      heading: 'YEAR',
      headerRows: ['YEAR', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '2',
      translationKey: 'MONTH',
      groupBy: 'orderCreationDateYearMonth',
      heading: 'MONTH',
      headerRows: ['MONTH', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '3',
      translationKey: 'PRODUCT_CATEGORIES_LAST_12_MONTHS',
      groupBy: this.orderAnalyticsConfigService.useCategoryDescription
        ? 'productCategoryDescription'
        : 'productCategoryCode',
      heading: 'PRODUCT_CATEGORY',
      headerRows: ['PRODUCT_CATEGORY', 'SALES_AMOUNT', 'QTY'],
      hideVerticalGridLines: true
    }
  ];

  constructor(
    protected orderAnalyticsConfigService: OrderAnalyticsConfigService,
    public orderAnalyticsProductListConfigService: OrderAnalyticsProductListConfigService
  ) { }

  ngOnInit(): void {
    this.orderAnalyticsConfigService
      .getItems()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(data => {
        this.data = data;
        if (this.orderAnalyticsConfigService.useDefaultGraphList) {
          this.graphList = this.orderAnalyticsConfigService.defaultGraphList;
        }
        if (this.graphList && this.graphList.length > 0) {
          const graphListItem = this.graphList[0];
          this.changeGraph(graphListItem.id);
        }
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  changeGraph(id: string) {
    const foundGraph = this.graphList.find(g => g.id === id);
    if (!!foundGraph) {
      this.selectedGraph = foundGraph;
    }
  }
}

export interface OrderAnalyticsGraphList {
  id: string
  translationKey: string,
  groupBy: string,
  heading: string,
  headerRows: string[],
  hideVerticalGridLines?: boolean
  showProductAnalyticsList?: boolean
}
