<div *ngFor="let option of options">
  <div class="form-group">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        [class]="option.class"
        [id]="option.id"
        [name]="option.label"
        [checked]="option.value"
        (change)="option.value = !option.value"
      />
      <label class="form-check-label" [for]="option.id" translate>{{ option.label }}</label>
    </div>
  </div>
</div>
