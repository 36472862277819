import { Pipe, PipeTransform } from '@angular/core';
import { GungImageUrlService } from '../../services/gung-image-url/gung-image-url.service';

@Pipe({
  name: 'gungImageUrl'
})
export class GungImageUrlPipe implements PipeTransform {

  constructor(
    protected gungImageUrlService: GungImageUrlService
  ) {}

  transform(value: string, ...args: string[]): string {
    return this.gungImageUrlService.getPipeUrl(value, args);
  }

}

@Pipe({
  name: 'gungDownloadUrl'
})
export class GungDownloadUrlPipe implements PipeTransform {

  constructor(
    protected gungImageUrlService: GungImageUrlService
  ) {}

  transform(value: string, ...args: string[]): string {
    return this.gungImageUrlService.getPipeDownloadUrl(value, args);
  }
}
