<h3 class="page-title text-center" translate>AVAILABILITY_CONDITIONS_HEADER</h3>
<p class="text-center" translate>AVAILABILITY_CONDITION_EXPLANATION</p>

<div class="row" *ngIf="conditions">
  <div class="col-xs-12 table-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>ACTIVE</th>
          <th translate>NAME</th>
          <th width="40%" translate>VALUES_TO_EXCLUDE</th>
          <th></th>
        </tr>
      </thead>
      <tbody *ngFor="let condition of conditions; trackBy: trackByFn">
        <tr>
          <td style="vertical-align: top">
            <input type="checkbox" [(ngModel)]="condition.active" />
          </td>

          <td style="vertical-align: top">
            {{ condition.name }}
          </td>

          <td style="vertical-align: top" width="40%">
            <div class="pb-2" *ngFor="let param of condition.params; index as paramIndex; trackBy: trackByFn">
              <div class="input-group">
                <input class="form-control" type="text" [(ngModel)]="condition.params[paramIndex]" />
                <a
                  (click)="condition.params.splice(paramIndex, 1)"
                  class="clickable input-group-append remove-condition-param"
                >
                  <span class="fa fa-times"></span>
                </a>
              </div>
            </div>
          </td>

          <td style="vertical-align: top">
            <div class="btn-group btn-group-sm pull-right">
              <button class="btn btn-primary" (click)="condition.params.push('')" translate>ADD_CONDITION_VALUE</button>

              <button *ngIf="isActuator" class="btn btn-danger" (click)="deleteCondition(condition)" translate>
                DELETE_CONDITION
              </button>

              <button
                class="btn btn-success"
                (click)="saveCondition(condition)"
                [disabled]="saving[condition.id]"
                translate
              >
                SAVE_CONDITION
              </button>
            </div>
          </td>
        </tr>
        <tr *ngIf="isActuator">
          <td colspan="4">
            <input class="form-control" type="text" [(ngModel)]="condition.expressionString" />
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-block btn-primary" *ngIf="isActuator" (click)="createCondition()" translate>
      CREATE_CONDITION
    </button>
  </div>
</div>
