import { Injectable } from '@angular/core';
import {
  ConfigService,
  ConfigBaseFilter,
  ListSortOption,
  ListLayout,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { PimDimensionsCardListComponent } from '../../components/pim-dimensions/pim-dimensions-card-list/pim-dimensions-card-list.component';
import { PimDimension } from '../../models/pim';
import { PimDimensionsService } from '../pim-dimensions/pim-dimensions.service';

class PimDimensionTypeFilter extends SimpleConfigBaseFilter<PimDimension> {
  getOptionIds(item: PimDimension): string[] {
    return [item.type];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'TYPE';
  }
}

@Injectable({
  providedIn: 'root'
})
export class PimDimensionsListConfigService implements ConfigService<PimDimension> {
  topFilter = true;

  constructor(protected pimDimensionsService: PimDimensionsService) {}

  getFilters(): ConfigBaseFilter<PimDimension>[] {
    return [new PimDimensionTypeFilter()];
  }

  getSortOptions(): ListSortOption<PimDimension>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: PimDimension) {
    return item.id;
  }

  getSearchTerms(item: PimDimension) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getLayouts(): ListLayout<PimDimension>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PimDimensionsCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimDimensionsCardListComponent'
      }
    ];
  }

  getItems(): Observable<PimDimension[]> {
    return this.pimDimensionsService.getPimDimensions();
  }
}
