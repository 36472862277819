<div class="modal-header">
  <h4 class="modal-title" translate>GENERATE_DESPATCH_ADVICE</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <!-- <div class="col-12">
      <lib-gung-text-input-horizontal renderStatic="true" [(ngModel)]="data.orderContextId" [gungTranslate]="'ORDER_NUMBER '" [placeholder]="'ORDER_NUMBER ' | translate"></lib-gung-text-input-horizontal>
    </div> -->
    <small>
      <b translate>NOT_DELIVERED_ORDERS</b> -
      <span translate>DESPATCH_ADVICE_INFO2</span>
    </small>
    <span>{{ data.orderIds.join(',') }}</span>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light mr-auto" aria-label="Close" (click)="activeModal.close()" translate>
    CANCEL
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="onSend()" translate>SEND</button>
</div>
