import { forkJoin } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ExportCoverSheetsService } from '../../services/export-cover-sheets/export-cover-sheets.service';
import { ExportCoverSheet, CoverImage } from '../../models/export-cover-sheets';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NewCoverSheetModalComponent } from './new-cover-sheet-modal/new-cover-sheet-modal.component';
import { UpdateCoverSheetImagesModalComponent } from './update-cover-sheet-images-modal/update-cover-sheet-images-modal.component';

@Component({
  selector: 'lib-export-cover-sheets',
  templateUrl: './export-cover-sheets.component.html',
  styleUrls: ['./export-cover-sheets.component.css']
})
export class ExportCoverSheetsComponent implements OnInit {
  sheets: ExportCoverSheet[] = [];
  loader: boolean;
  unsaved: boolean;

  constructor(
    protected exportCoverSheetsService: ExportCoverSheetsService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected modalService: NgbModal
  ) {
    this.loader = true;
  }

  ngOnInit() {
    this.exportCoverSheetsService.getExportCoverSheets(true).subscribe((sheets: ExportCoverSheet[]) => {
      this.sheets = sheets.sort((a, b) => (a.index < b.index ? -1 : 1));
      this.loader = false;
    });
  }

  public onEditImagesClick(index: number) {
    const element = this.sheets[index];
    const modalRef = this.modalService.open(UpdateCoverSheetImagesModalComponent, { size: 'lg', backdrop: 'static', keyboard: true });
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(element));
    modalRef.result.then(
      (result) => {
        if (result === null || result.image === null) {
          this.sheets = this.sheets.filter(s => s.id !== element.id);
        } else {
          this.sheets[index] = result;
        }
      },
      reason => {}
    );
  }


  drop(event: CdkDragDrop<string[]>) {
    this.unsaved = true;
    moveItemInArray(this.sheets, event.previousIndex, event.currentIndex);
  }

  onSave() {
    const listHttpPut = [];
    for (const [index, sheet] of this.sheets.entries()) {
      listHttpPut.push(this.exportCoverSheetsService.putExportCoverSheet({ ...sheet, index }));
    }
    forkJoin(listHttpPut).subscribe(() => (this.unsaved = false));
  }

  onDelete(id: string) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CONFIRM_DELETION', { value: '' }) + '?', {
        size: 'sm'
      })
      .then(
        result => {
          if (result) {
            this.exportCoverSheetsService
              .deleteExportCoverSheet(id)
              .subscribe(() => (this.sheets = this.sheets.filter(s => s.id !== id)));
          }
        },
        reason => {}
      );
  }

  onCreate() {
    const modalRef = this.modalService.open(NewCoverSheetModalComponent, { size: 'lg' });

    modalRef.result.then(
      result => {
        if (result) {
          // CREATED
          this.sheets.push(result);
        }
      },
      reason => {}
    );
  }
}
