<div class="row" *ngIf="whiteboards.length == 0">
  <div class="col">
    <span class="no-whiteboards-found" translate>NO_WHITEBOARDS_FOUND</span>
  </div>
</div>

<div class="row">
  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 whiteboard-entry" *ngFor="let whiteboard of whiteboards">
    <div class="card whiteboard-card">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-column mb-3">
          <span class="name">{{ whiteboard.name }}</span>
        </div>
      </div>
      <div class="align-items-center">
        <lib-action-btn [item]="whiteboard" [config]="actionButtonConfig" [blockBtns]="blockBtns"></lib-action-btn>
      </div>
    </div>
  </div>
</div>
