import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { PimMetadataCardListComponent } from '../../components/pim-metadata/pim-metadata-card-list/pim-metadata-card-list.component';
import { PimMetadata, PimMetadataService } from '../pim-metadata/pim-metadata.service';

export class PimMetadataFilter extends SimpleConfigBaseFilter<PimMetadata> {
  constructor() {
    super();
  }

  getName(): string {
    return 'SEGMENT';
  }

  getOptionIds(item: PimMetadata): string[] {
    return [item.segment];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PimMetadataListConfigService implements ConfigService<PimMetadata> {
  topFilter = true;

  constructor(protected pimMetadataService: PimMetadataService) {}

  getFilters(): ConfigBaseFilter<PimMetadata>[] {
    return [new PimMetadataFilter()];
  }

  getSortOptions(): ListSortOption<PimMetadata>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: PimMetadata) {
    return item.metadataId;
  }

  getSearchTerms(item: PimMetadata) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getLayouts(): ListLayout<PimMetadata>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PimMetadataCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimMetadataCardListComponent'
      }
    ];
  }

  getItems(): Observable<PimMetadata[]> {
    return this.pimMetadataService.getPimMetadata(); // getPimMetadataInternal();
  }
}
