import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';
import { UsersService } from './../../../../../lib/services/users/users.service';
import { UserGroup } from '../user-details-groups/user-details-groups.component';
import { HttpClient } from '@angular/common/http';
import { first, of, switchMap } from 'rxjs';
import { BackendFeatureService } from '../../../../services/backend-feature.service';
import { PasswordPolicyService } from '../../../../services/password-policy.service';

@Component({
  selector: 'lib-user-details-user',
  templateUrl: './user-details-user.component.html',
  styleUrls: ['./user-details-user.component.css']
})
export class UserDetailsUserComponent implements OnInit {
  @Output() validPasswordChanged = new EventEmitter<boolean>();
  
  @Input()
  currentUser: User;

  userRoles: any[];

  passwordRequirements: any;

  multiStocksIds: string[] = [];

  public activeGroupsKey = 'activeGroups';

  userGroups: UserGroup[] = [];

  validPassword = true;

  private passwordKey = 'password';

  password = '';

  constructor(
    protected usersService: UsersService,
    protected http: HttpClient,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService
  ) {}

  ngOnInit() {
    const featureId = 'passwordPolicies';

    this.backendFeatureService
      .isActivated(featureId)
      .pipe(
        first(),
        switchMap(isActivated => {
          if (isActivated) {
            return this.passwordPolicyService.getPasswordRequirements();
          } else {
            return of({});
          }
        })
      )
      .subscribe(requirements => {
        this.passwordRequirements = requirements;
      });

    this.usersService.getDefaultUserRoles().subscribe(roles => {
      this.userRoles = roles;
      for (const role of this.currentUser.roles) {
        const roleDefinedFromService = this.userRoles.find(x => x.id === role);
        if (roleDefinedFromService !== undefined) {
          roleDefinedFromService.selected = true;
        } else {
          // User has a role on it which does not exist in usersService, add it here to allow removal of the role
          this.userRoles.push({ selected: true, id: role });
        }
      }
    });

    this.http.get<any>('json/system/GUNG').subscribe(systemSettings => {
      const settingsGroups = systemSettings.extra.userGroups || [];
      const tmp: UserGroup[] = [];
      for (const key of Object.keys(this.currentUser[this.activeGroupsKey])) {
        tmp.push({
          key,
          selected: this.currentUser[this.activeGroupsKey][key]
        });
      }

      (settingsGroups as string[]).forEach(settingsGroup => {
        const userGroup = tmp.find(group => group.key === settingsGroup);
        if (!userGroup) {
          tmp.push({
            key: settingsGroup,
            selected: false
          });
        }
      });
      this.userGroups = tmp.sort((a,b) => a.key.localeCompare(b.key));
    });

    this.multiStocksIds = this.currentUser.managedMultistockIds;
  }

  isValid(event) {
    this.validPassword = event;
    this.setPassword();
    this.validPasswordChanged.emit(this.validPassword);
  }

  setPassword(): void {
    if (!!this.password && this.validPassword) {
      this.currentUser[this.passwordKey] = this.password;
    }
  }

  toogleRole(id: string, selected: boolean): void {
    this.userRoles.find(role => role.id === id).selected = !selected;
    // if was selected then remove it roles collection
    if (selected) {
      const indexOf = this.currentUser.roles.indexOf(id);
      this.currentUser.roles.splice(indexOf, 1);
    } else {
      this.currentUser.roles.push(id);
    }
  }

  add(ele: HTMLInputElement): void {
    if (!ele.value) {
      return;
    }
    this.multiStocksIds.push(ele.value);
    ele.value = null;
    this.update();
  }

  update(): void {
    this.currentUser.managedMultistockIds = this.multiStocksIds.filter(Boolean);
  }

  remove(index: number): void {
    this.multiStocksIds.splice(index, 1);
    this.update();
  }

  trackByFn(index: number, item: any): any {
    return index;
  }

  toogleGroup(key: string, selected: boolean): void {
    this.userGroups.find(group => group.key === key).selected = !selected;
    this.currentUser[this.activeGroupsKey][key] = !selected;
  }

  getUserGroups(): UserGroup[] {
    return Object.keys(this.currentUser[this.activeGroupsKey])
      .filter(g => this.currentUser[this.activeGroupsKey][g])
      .map(group => ({ key: group, selected: this.currentUser[this.activeGroupsKey][group] }));
  }
}
