import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';
import {
  AvailabilityConditionsService,
  AvailabilityOrderRowCondition
} from '../../services/availability-conditions/availability-conditions.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { AuthService } from './../../services/auth/auth.service';

@Component({
  selector: 'lib-availability-conditions',
  templateUrl: './availability-conditions.component.html',
  styleUrls: ['./availability-conditions.component.css']
})
export class AvailabilityConditionsComponent implements OnInit {
  isActuator = false;
  conditions: AvailabilityOrderRowCondition[];
  saving: { [conditionId: string]: boolean } = {};

  constructor(
    protected availabilityConditionsService: AvailabilityConditionsService,
    protected authService: AuthService,
    protected gungModalService: GungModalService
  ) {}

  ngOnInit() {
    forkJoin({
      conditions: this.availabilityConditionsService.getAllConditions().pipe(first()),
      currentUser: this.authService.getCurrentUser().pipe(first())
    }).subscribe(({ conditions, currentUser }) => {
      this.conditions = conditions;
      this.isActuator = currentUser.roles.indexOf('ACTUATOR') >= 0;
    });
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  saveCondition(condition: AvailabilityOrderRowCondition): void {
    condition.params = condition.params.filter(param => !!param);

    if (!!condition.id) {
      this.saving[condition.id] = true;
    }

    this.availabilityConditionsService
      .saveCondition(condition)
      .pipe(first())
      .subscribe((availabilityOrderRowCondition: AvailabilityOrderRowCondition) => {
        if (!!this.saving[availabilityOrderRowCondition.id]) {
          delete this.saving[availabilityOrderRowCondition.id];
        }
      });
  }

  deleteCondition(condition: AvailabilityOrderRowCondition): void {
    this.availabilityConditionsService.deleteCondition(condition.id).subscribe(() => {
      const index = this.conditions.findIndex(c => c.id === condition.id);
      if (index > -1) {
        this.conditions.splice(index, 1);
      }
    });
  }

  createCondition() {
    this.gungModalService.openCreateAvailabilityConditionModal().then(
      (condition: AvailabilityOrderRowCondition) => {
        if (!!condition) {
          this.availabilityConditionsService.saveCondition(condition).subscribe(availabilityOrderRowCondition => {
            this.conditions.push(availabilityOrderRowCondition);
          });
        }
      },
      reason => {}
    );
  }
}
