import { GungBase } from './base';

export interface Customer extends GungBase {
  customerType?: CustomerType;
  flowToPricelistMapping?: { [s: string]: string },
  extra: {
    [s: string]: any,
    gme?: CustomerGungMappedEntity
  }
}

export interface CustomerGungMappedEntity {
  displayId: string,
  displayName: string,
  organizationNumber: string,
  currencyCode: string,
  salesperson: string,
  address: CustomerGungMappedEntityAddress,
  billingAddress: CustomerGungMappedEntityAddress,
  deliveryAddresses: CustomerGungMappedEntityAddress[]
}

export interface CustomerGungMappedEntityAddress {
  gln: string,
  name: string,
  address1: string,
  address2: string,
  zipCode: string,
  city: string,
  countryCode: string,
  email: string,
  phoneNo: string
}

export enum CustomerType {
  CUSTOMER = 'CUSTOMER',
  PROSPECT = 'PROSPECT'
}
