import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CustomPagesService {
  constructor(protected http: HttpClient) {}

  public getMyCustomPages(): Observable<CustomPage[]> {
    return this.http.get<CustomPage[]>('json/pages/for-user');
  }
}

export interface CustomPage {
  id: string;
  name: string;
  html: string;
}
