<div class="modal-header">
  <div>
    <h5 *ngIf="isCreate" class="modal-title" id="modal-basic-title" translate>NEW_COMPANY_TEMPLATE</h5>
    <h5 *ngIf="isEdit" class="modal-title" id="modal-basic-title">{{ 'EDIT' | translate }} - {{ template.name }}</h5>
  </div>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div *ngIf="form" class="modal-body" [formGroup]="form">
  <lib-gung-text-input
    gungTranslate="NAME"
    formControlName="name"
  ></lib-gung-text-input>
  
  <div *ngIf="userGroups" class="form-group mt-3">
    <label translate>ALLOWED_USER_GROUPS</label>
    <div class="d-flex flex-row flex-wrap user-groups-gap">
      <div *ngFor="let group of userGroups" class="form-check">
        <input
          [id]="group.id"
          class="form-check-input"
          type="checkbox"
          [ngModel]="group.selected"
          [ngModelOptions]="{standalone: true}"
          (ngModelChange)="onChangeUserGroupCheckbox(group.id, $event)">
        <label [for]="group.id" class="form-check-label">{{ group.name | translate }}</label>
      </div>
    </div>
  </div>

  <div class="alert alert-info">
    <span translate>COMPANY_TEMPLATES_USER_GROUPS_DISCLAMER</span>
  </div>

  <hr>
  
  <div *ngFor="let field of companyTemplateErpFields" class="form-group">
    <div [ngSwitch]="field.inputType">
      <div *ngSwitchCase="'MetaSelectInputComponent'">
        <lib-meta-select-input
          [gungTranslate]="field.label"
          [formControl]="form.get('extra.' + field.path)"
          [table]="field.metaTable"
          [metaId]="field.metaId"
          [metaDisplay]="field.metaDisplay"
        ></lib-meta-select-input>
      </div>
      <div *ngSwitchCase="'GungTextInputComponent'">
        <lib-gung-text-input
          [gungTranslate]="field.label"
          [formControl]="form.get('extra.' + field.path)"
          placeholder="{{ field.placeholder | translate }}"
        ></lib-gung-text-input>
      </div>
      <div *ngSwitchCase="'GungNumberInputComponent'">
        <lib-gung-number-input
          [gungTranslate]="field.label"
          [formControl]="form.get('extra.' + field.path)"
          placeholder="{{ field.placeholder | translate }}"
          [outputAsString]="field.outputAsString"
          [minValue]="field.minValue"
          [maxValue]="field.maxValue"
        ></lib-gung-number-input>
      </div>
      <div *ngSwitchCase="'GungSelectOptionInputComponent'">
        <lib-gung-select-option-input
          [gungTranslate]="field.label"
          [optionsList]="erpFieldsAuxData?.[field.auxDataName] || []"
          [formControl]="form.get('extra.' + field.path)"
        ></lib-gung-select-option-input>
      </div>
      <div *ngSwitchDefault>
        <span>{{ 'INVALID_INPUT_TYPE' | translate }}: {{ field.inputType }}</span>
      </div>
   </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-outline-secondary" (click)="close()" translate>
    CLOSE
  </button>
  <button type="button" class="btn btn-sm btn-primary" (click)="save()">
    {{ isCreate ? ('CREATE' | translate) : ('EDIT' | translate) }}
  </button>
</div>
