import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  SimpleChange,
  SimpleChanges,
  Type,
  ViewContainerRef
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';

type UserOutputs = Record<string, (event: any) => void>;
type UserInputs = Record<string, any>;
type ComponentInputs = ComponentFactory<any>['inputs'];
type ComponentOutputs = ComponentFactory<any>['outputs'];

// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
function assertNotNullOrUndefined<T>(value: T): asserts value is NonNullable<T> {
  if (value === null || value === undefined) {
    throw new Error(`cannot be undefined or null.`);
  }
}

@Directive({
  selector: '[libCustomHostDynamic]'
})
export class CustomHostDynamicDirective implements OnDestroy, OnChanges {
  @Input('libCustomHostDynamic') component!: Type<any>;
  @Input() outputs?: UserOutputs = {};
  @Input() inputs?: UserInputs = {};
  @Input() injector?: Injector;

  private subscription = new Subject<void>();
  private componentFactory?: ComponentFactory<any>;
  private componentRef?: ComponentRef<any>;

  constructor(private viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges(changes: SimpleChanges): void {
    // ensure component is defined
    assertNotNullOrUndefined(this.component);

    let componentChanges: Record<string, SimpleChange>;
    const shouldCreateNewComponent =
      changes.component?.previousValue !== changes.component?.currentValue ||
      changes.injector?.previousValue !== changes.injector?.currentValue;

    if (shouldCreateNewComponent) {
      this.destroyComponent();
      this.createComponent();
      componentChanges = this.makeComponentChanges(changes.inputs, true);
    }
    componentChanges ??= this.makeComponentChanges(changes.inputs, false);

    assertNotNullOrUndefined(this.componentFactory);
    assertNotNullOrUndefined(this.componentRef);

    this.validateOutputs(this.componentFactory.outputs, this.outputs ?? {}, this.componentRef.instance);
    this.validateInputs(this.componentFactory.inputs, this.inputs ?? {});

    if (changes.inputs) {
      this.bindInputs(this.componentFactory.inputs, this.inputs ?? {}, this.componentRef.instance);
    }

    if (changes.outputs) {
      this.subscription.next(); // to remove old subscription
      this.bindOutputs(this.componentFactory.outputs, this.outputs ?? {}, this.componentRef.instance);
    }

    if ((this.componentRef.instance as OnChanges).ngOnChanges) {
      this.componentRef.instance.ngOnChanges(componentChanges);
    }
  }

  ngOnDestroy(): void {
    this.destroyComponent();
    this.subscription.next();
    this.subscription.complete();
  }

  /**
   * Validate if the inputs exist in the component
   */
  private validateInputs(componentInputs: ComponentInputs, userInputs: UserInputs) {
    const userInputsKeys = Object.keys(userInputs);
    userInputsKeys.forEach(userInputKey => {
      const componentHaveThatInput = componentInputs.some(
        componentInput => componentInput.templateName === userInputKey
      );
      if (!componentHaveThatInput) {
        throw new Error(`Input ${userInputKey} is not ${this.component.name} input.`);
      }
    });
  }

  /**
   * Validate if the component outputs are EventEmitter and check if the outputs exists
   */
  private validateOutputs(componentOutputs: ComponentOutputs, userOutputs: UserOutputs, componentInstance: any) {
    componentOutputs.forEach(output => {
      if (!(componentInstance[output.propName] instanceof EventEmitter)) {
        throw new Error(`Output ${output.propName} must be a typeof EventEmitter`);
      }
    });

    const outputsKeys = Object.keys(userOutputs);
    outputsKeys.forEach(key => {
      const componentHaveThatOutput = componentOutputs.some(output => output.templateName === key);
      if (!componentHaveThatOutput) {
        throw new Error(`Output ${key} is not ${this.component.name} output.`);
      }
      if (!(userOutputs[key] instanceof Function)) {
        throw new Error(`Output ${key} must be a function`);
      }
    });
  }

  /**
   * Set inputs from component with input past in the dynamic component
   */
  private bindInputs(componentInputs: ComponentInputs, userInputs: UserInputs, componentInstance: any) {
    componentInputs.forEach(input => {
      const inputValue = userInputs[input.templateName];
      if (typeof userInputs[input.templateName] !== 'undefined') {
        // REVIEW THIS: maybe they want the variable undefined
        componentInstance[input.propName] = inputValue;
      }
    });
  }

  /**
   * Set outputs from component with output past in the dynamic component
   */
  private bindOutputs(componentOutputs: ComponentInputs, userOutputs: UserInputs, componentInstance: any) {
    componentOutputs.forEach(output => {
      (componentInstance[output.propName] as EventEmitter<any>).pipe(takeUntil(this.subscription)).subscribe(event => {
        const handler = userOutputs[output.templateName];
        if (handler) {
          // in case the output has not been provided at all
          handler(event);
        }
      });
    });
  }

  /**
   * Create the dynamic component
   */
  private createComponent() {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.component);
    this.componentRef = this.viewContainerRef.createComponent<any>(this.componentFactory, 0, this.injector);
  }

  /**
   * Destroy to create new
   */
  private destroyComponent() {
    this.componentRef?.destroy();
    this.viewContainerRef.clear();
  }

  /**
   * Compare the changes
   */
  private makeComponentChanges(inputsChange: SimpleChange, firstChange: boolean): Record<string, SimpleChange> {
    const previuosInputs = inputsChange?.previousValue ?? {};
    const currentInputs = inputsChange?.currentValue ?? {};
    const changesInput = Object.keys(currentInputs).reduce((changes, inputName) => {
      const currentInputValue = currentInputs[inputName];
      const previuosInputValue = previuosInputs[inputName];
      if (currentInputValue !== previuosInputValue) {
        changes[inputName] = new SimpleChange(
          firstChange ? undefined : previuosInputValue,
          currentInputValue,
          firstChange
        );
      }
      return changes;
    }, {} as Record<string, SimpleChange>);
    return changesInput;
  }
}
