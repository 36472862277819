import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ActionButton, ActionButtonConfigService, CommonModalService } from 'gung-common';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs';
import { PdfExportEditModalComponent } from '../components/pdf-export-edit-modal/pdf-export-edit-modal.component';
import { PdfDocument } from '../models/pdf-document';
import { PdfAuthService } from './pdf-auth.service';
import { DynamicPdfService } from './dynamic-pdf.service';
import { PdfDownloadIndicatorService } from './pdf-download-indicator.service';
import { PdfProductExportService } from './pdf-product-export.service';
import { PdfGeneratorService } from './pdf-generator.service';

@Injectable({
  providedIn: 'root'
})
export class PdfManagementActionButtonConfigService implements ActionButtonConfigService<PdfDocument> {
  constructor(
    protected authService: PdfAuthService,
    protected router: Router,
    protected modalService: NgbModal,
    protected gungModalService: CommonModalService,
    protected translateService: TranslateService,
    protected dynamicPdfService: DynamicPdfService,
    protected productExportService: PdfProductExportService,
    protected pdfDownloadIndicatorService: PdfDownloadIndicatorService /*protected router: Router,*/,
    protected pdfGeneratorService: PdfGeneratorService
  ) {}

  getButtons(item: PdfDocument): Observable<ActionButton<PdfDocument>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        const actions = [];
        actions.push(
          {
            title: 'LOAD_PDF',
            displayAsButton: true,
            disable: item.readOnly,
            action: (pdfDocument: PdfDocument) => {
              this.router.navigate(['export-pdf']).then(w => {
                this.router.navigate(['export-pdf', pdfDocument.id]).then(w2 => {
                  this.modalService.dismissAll();
                });
              });
            }
          },
          {
            title: 'CREATE_ORDER',
            displayAsButton: true,
            action: (pdfDocument: PdfDocument) => {
              this.router.navigate(['products-export-pdf', pdfDocument.id]).then(w => {
                this.modalService.dismissAll();
              });
            }
          }
        );

        if (roles.includes('ADMIN') || roles.includes('SALES')) {
          actions.push(
            {
              title: 'EDIT',
              displayAsButton: false,
              action: (pdfDocument: PdfDocument) => {
                const modal = this.modalService.open(PdfExportEditModalComponent);
                modal.componentInstance.pdfDocument = pdfDocument;
              }
            },
            {
              title: 'COPY',
              displayAsButton: false,
              action: (pdfDocument: PdfDocument) => {
                const modal = this.modalService.open(PdfExportEditModalComponent);
                modal.componentInstance.pdfDocument = pdfDocument;
                modal.componentInstance.isCreate = true;
              }
            }
          );
        }
        if (roles.includes('ADMIN')) {
          actions.push({
            title: 'DELETE',
            displayAsButton: false,
            action: (pdfDocument: PdfDocument) => {
              this.gungModalService
                .openConfirmYesNoModal(undefined, this.translateService.instant('DELETE_PDF_CONFIRM'), { size: 'sm' })
                .then(
                  result => {
                    if (result) {
                      this.dynamicPdfService
                        .deletePdf(pdfDocument.id)
                        .pipe(first())
                        .subscribe(_ => {});
                    }
                  },
                  reason => {}
                );
            }
          });
        }
        actions.push({
          title: 'EXPORT_DYNAMIC_PDF',
          action: (pdfDocument: PdfDocument) => {
            this.pdfGeneratorService.generatePdf(pdfDocument.id);
            return of();
          }
        });
        actions.push({
          title: 'PRODUCT_EXPORT_XLSX',
          action: (pdfDocument: PdfDocument) => {
            const itemList = this.convertPdfExportToProductList(pdfDocument);
            this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
            return of();
          }
        });
        actions.push({
          title: 'PRODUCT_EXPORT_IMG',
          action: (pdfDocument: PdfDocument) => {
            const itemList = this.convertPdfExportToProductList(pdfDocument);
            this.productExportService.exportImg(itemList).pipe(first()).subscribe();
            return of();
          }
        });

        if (roles.includes('SALES')) {
          actions.push({
            title: 'PRODUCT_EXPORT_CSV',
            action: (pdfDocument: PdfDocument) => {
              const itemList = this.convertPdfExportToProductList(pdfDocument);
              this.productExportService.exportCsv(itemList).pipe(first()).subscribe();
              return of();
            }
          });

          actions.push({
            title: 'PRODUCT_EXPORT_LINESHEET',
            action: (pdfDocument: PdfDocument) => {
              const itemList = this.convertPdfExportToProductList(pdfDocument);
              this.productExportService.exportLinesheet(itemList).pipe(first()).subscribe();
              return of();
            }
          });
          actions.push({
            title: 'PRODUCT_EXPORT_PRICAT',
            action: (pdfDocument: PdfDocument) => {
              const itemList = this.convertPdfExportToProductList(pdfDocument);
              this.productExportService.exportPricat(itemList).pipe(first()).subscribe();
              return of();
            }
          });
          actions.push({
            title: 'PRODUCT_EXPORT_PRODUCT_STOCK',
            action: (pdfDocument: PdfDocument) => {
              const itemList = this.convertPdfExportToProductList(pdfDocument);
              this.productExportService.exportProductStock(itemList).pipe(first()).subscribe();
              return of();
            }
          });
        }
        return actions;
      })
    );
  }

  private convertPdfExportToProductList(pdfDocument: PdfDocument) {
    const items = [];
    pdfDocument.pages.forEach(page => {
      for (const i of page.items) {
        if (i.data.variantIds) {
          for (const v of i.data.variantIds) {
            items.push({ id: v });
          }
        } else if (i.data.productId) {
          items.push({ id: i.data.productId });
        }
      }
    });
    return items;
  }
}
