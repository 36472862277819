import { Injectable } from '@angular/core';
import { Cart } from '../../models/cart';
import { CartExportService } from '../cart-export/cart-export.service';
import { PdfDownloadIndicatorService } from '../pdf-download-indicator.service';
import { first } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SavedQuoteTableService {
  shouldMailQuotePdf = true;
  usePuppeteerEngine = false;

  constructor(
    protected cartExportService: CartExportService,
    protected pdfDownloadIndicatorService: PdfDownloadIndicatorService
  ) {}

  /*
    exportSavedQuote(
    cart: Cart,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    useV2?: boolean*/

  public downloadQuotePdf(cart: Cart): void {
    if (this.shouldMailQuotePdf) {
      this.cartExportService
        .exportSavedQuote(
          cart,
          /* template */ null,
          /* additionalVariables */ null,
          /* additionalParams */ null,
          /* useV2 */ true
        )
        .pipe(first())
        .subscribe();
    } else {
      this.pdfDownloadIndicatorService.setDownloading({ downloading: true, data: null });
      this.cartExportService
        .exportSavedQuoteBytes(cart, cart.name, undefined, undefined, undefined, undefined, this.usePuppeteerEngine)
        .subscribe(x => {
          this.pdfDownloadIndicatorService.setDownloading({ downloading: false, data: null });
        });
    }
  }
}
