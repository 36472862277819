<div *ngIf="isOnlyDisplayFlow; else buyButton">
  <div class="input-group">
    <button class="btn btn-block btn-sm btn-primary" tabindex="-1" [routerLink]="[goToDetailUrl()]" translate>
      DETAILS
    </button>
  </div>
</div>

<ng-template #buyButton>
  <div class="input-group">
    <input
      type="number"
      #input
      class="form-control text-center mr-2"
      [(ngModel)]="addQuantity"
      (ngModelChange)="updateAddQuantity($event, input)"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [step]="stepAmount"
      [min]="minimumOrderQuantity"
    />
    <button
      class="btn btn-primary form-control"
      tabindex="-1"
      (click)="addWithQuantity(); $event.preventDefault(); $event.stopPropagation()"
      translate
    >
      ADD_TO_CART
    </button>
  </div>
</ng-template>
