<div class="entity-tables">
  <lib-gung-tabset justify="fill">
    <lib-gung-tab [title]="glnAddress.title | translate" *ngFor="let glnAddress of glnAddressList">
      <ng-template libGungTabContent>
        <div class="gln">
          <div class="table-label">GLN</div>
          <div class="table-data">{{ glnAddress.gln }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>COMPANY</div>
          <div class="table-data">{{ glnAddress.name }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>CUSTOMER_ID</div>
          <div class="table-data">{{ glnAddress.customerId }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>CUSTOMER_NAME</div>
          <div class="table-data">{{ glnAddress.customerName }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>ADDRESS</div>
          <div class="table-data">{{ glnAddress.address1 }} {{ glnAddress.address2 }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>POSTAL_CODE</div>
          <div class="table-data">{{ glnAddress.zipCode }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>CITY</div>
          <div class="table-data">{{ glnAddress.city }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>COUNTRY</div>
          <div class="table-data">{{ glnAddress.country }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>PHONE</div>
          <div class="table-data">{{ glnAddress.phone }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>VAT_NUMBER</div>
          <div class="table-data">{{ glnAddress.vatNumber }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>ORG_NUMBER</div>
          <div class="table-data">{{ glnAddress.orgNumber }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>BANK_NAME</div>
          <div class="table-data">{{ glnAddress.bankName }}</div>
        </div>
        <div class="line-data">
          <div class="table-label" translate>BANK_ACCOUNT</div>
          <div class="table-data">{{ glnAddress.bankAccountNumber }}</div>
        </div>
      </ng-template>
    </lib-gung-tab>
  </lib-gung-tabset>
</div>
