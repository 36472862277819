import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PipeService {
  constructor() {}

  transformCurrency(
    transformed: string | null,
    input: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): string | null {
    return transformed;
  }

  preprocessCurrency(value: any): number | string {
    return value;
  }

  overrideDisplay(currencyCode: string, display: string | boolean): string | boolean {
    return display;
  }

  overrideLocale(currencyCode: string, locale: string) {
    return locale;
  }
}
