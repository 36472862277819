import { Component, OnDestroy, OnInit } from '@angular/core';
import { Product, ProductMultiDimension } from './../../../../../models/product';
import { Subject, first, forkJoin, map, mergeMap, of, switchMap, takeUntil } from 'rxjs';
import { CartService } from './../../../../../services/cart/cart.service';
import { ProductService } from './../../../../../services/products/product.service';
import { ProductMatrixModalService } from './../../../../../services/product-matrix-modal/product-matrix-modal.service';
import { CartRow } from './../../../../../state/cart/types';
import { TranslateService } from '@ngx-translate/core';
import { isMultiDimensionProduct } from './../../../../../utils/product-utils';
import { mergeDeep } from './../../../../../utils/merge-utils';
import { CartListCheckoutConfigService } from './../../../../../services/cart-list-checkout-config/cart-list-checkout-config.service';

export interface CartRowEdit {
  cartRow: CartRow;
  editExtra: any;
  product: ProductMultiDimension;
  isMultiDimensional: boolean;
}

@Component({
  selector: 'lib-checkout-cart-discount-matrix-modal',
  templateUrl: './checkout-cart-discount-matrix-modal.component.html',
  styleUrls: ['./checkout-cart-discount-matrix-modal.component.css']
})
export class CheckoutCartDiscountMatrixModalComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  productId: string;
  product: Product;
  delegate: ProductMatrixModalService;

  cartRows: CartRowEdit[];

  isLoading = true;

  priceField = this.cartListCheckoutConfig.changePriceField;

  discountField = this.cartListCheckoutConfig.changeDiscountField;

  constructor(
    protected cartService: CartService,
    protected productService: ProductService,
    protected translateService: TranslateService,
    public cartListCheckoutConfig: CartListCheckoutConfigService,
  ) {}

  ngOnInit() {
    this.productService
      .getProduct(this.productId)
      .pipe(
        first(),
        mergeMap(product => {
          this.product = product;
          return this.cartService.getCurrentCart().pipe(
            takeUntil(this.unsubscribe),
            map(cartRows => {
              return cartRows.filter(row => row.productId.indexOf(product.id) === 0);
            }),
            mergeMap(cartRows => {
              const products = cartRows.map(row => {
                return this.productService.getProduct(row.productId).pipe(first());
              });

              return forkJoin(products).pipe(
                first(),
                switchMap(resolvedProducts => {
                  return of([cartRows, resolvedProducts] as [CartRow[], Product[]]);
                })
              );
            })
          );
        })
      )
      .subscribe(([rows, products]) => {
        this.cartRows = rows
          .map((row, i) => {
            return this.createRowEdit(row, products[i]);
          })
          .sort((v1, v2) => {
            const v1Id = v1.product.id;
            const v2Id = v2.product.id;
            if (v1Id > v2Id) {
              return 1;
            } else if (v1Id < v2Id) {
              return -1;
            } else {
              return 0;
            }
          });
        this.isLoading = false;
      });
  }

  protected createRowEdit(row: CartRow, product: Product): CartRowEdit {
    const price = this.getObjectPropertyValue(row.extra,this.priceField);
    const discount = this.getObjectPropertyValue(row.extra,this.discountField);
    return {
      cartRow: row,
      editExtra: {...JSON.parse(JSON.stringify(row.extra)), _setPrice: price, _setDiscount: discount },
      product: product as ProductMultiDimension,
      isMultiDimensional: isMultiDimensionProduct(product)
    };
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  copyDown(operation: string, currentRowIndex: number): void {
    this.copyRowsDown(operation, currentRowIndex, this.cartRows);
  }

  private copyRowsDown(operation: string, currentRowIndex: number, rows: CartRowEdit[]): void {
    if (operation === 'once') {
      const editRow = rows[currentRowIndex + 1];
      editRow.editExtra = JSON.parse(JSON.stringify(rows[currentRowIndex].editExtra));
    } else if (operation === 'all') {
      for (let i = currentRowIndex + 1; i < rows.length; i++) {
        const editRow = rows[i];
        editRow.editExtra = JSON.parse(JSON.stringify(rows[currentRowIndex].editExtra));
      }
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  closeMatrix() {
    this.delegate.modalRef.get(CheckoutCartDiscountMatrixModalComponent.name).close('ok');
  }

  applyAndCloseMatrix() {
    let bulkExtraOps = [];
    this.cartRows.forEach(row => {
      let newExtra = {}
      row.editExtra._setPrice = row.editExtra._setPrice || undefined;
      row.editExtra._setDiscount = row.editExtra._setDiscount ?? undefined;
      this.setObjectPropertyValue(newExtra, this.priceField, row.editExtra._setPrice);
      this.setObjectPropertyValue(newExtra, this.discountField, row.editExtra._setDiscount);
      bulkExtraOps.push({
        productId: row.cartRow.productId,
        extra: newExtra,
        targetStockId: row.cartRow.targetStockId,
        productPartialId: row.cartRow.productPartialId
      });
    });
    this.cartService.bulkSetExtra(bulkExtraOps);
    this.closeMatrix();
  }

  setObjectPropertyValue(obj, propertyString, value) {
    const properties = propertyString.split('.');
    const lastPropertyIndex = properties.length - 1;
    properties.reduce((result, property, index) => {
      if (index === lastPropertyIndex) {
        result[property] = value; // Set the value of the final property
      } else if (property in result) {
        return result[property]; // Access the property
      } else {
        result[property] = {}; // Create nested objects if the property doesn't exist
        return result[property];
      }
    }, obj);
  }

  getObjectPropertyValue(obj, propertyString) {
    const properties = propertyString.split('.'); 
  
    return properties.reduce((result, property) => {
      if (result && property in result) {
        return result[property]; // Access the property
      }
      return undefined; // Property not found
    }, obj);
  }
}