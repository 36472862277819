import { EventEmitter, Injectable, Type } from '@angular/core';
import { QuickorderProductDetailsComponent } from '../../components/quickorder/quickorder-product-details/quickorder-product-details.component';
import { Product } from '../../models';
import { Assortment } from '../assortment.service';

@Injectable({
  providedIn: 'root'
})
export class QuickorderService {
  constructor() {}

  getProductDetailsComponent(): Type<QuickorderProduct> {
    return QuickorderProductDetailsComponent;
  }
}

export interface QuickorderProduct {
  product: Product;
  assortment: Assortment;
  updateQuantity: EventEmitter<number>;
}
