import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Product } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class MergedI18nService {
  constructor(protected translateService: TranslateService) {}

  getMergedI18n(product: Product): Record<string, string> {
    if (!product.extra.i18n) {
      return;
    }
    const currentLanguage = this.translateService.currentLang;
    const backupLanguage = this.getBackupLanguage(currentLanguage);

    const activeI18n = Object.assign({}, product.extra.i18n[currentLanguage]);
    const backupI18n = Object.assign({}, product.extra.i18n[backupLanguage]);

    Object.keys(backupI18n).forEach(key => {
      if (!(key in activeI18n)) {
        activeI18n[key] = backupI18n[key];
      }
    });

    return activeI18n;
  }

  protected getBackupLanguage(primaryLanguage: string): string {
    if (primaryLanguage === 'en') {
      return 'se';
    } else {
      return 'en';
    }
  }
}
