import { CartRow } from '../types';

export const filterOutRow = (
  row: CartRow,
  productId: string,
  targetStockId?: string,
  productPartialId?: string
): boolean => {
  return !(row.productId === productId 
    && row.targetStockId === targetStockId 
    && (row.productPartialId || '') === (productPartialId || ''));
};

export const getRowFromRows = (
  rows: CartRow[],
  productId: string,
  targetStockId?: string,
  productPartialId?: string
): CartRow | undefined =>
  rows.find(
    r =>
      r.productId === productId &&
      r.targetStockId === targetStockId &&
      (r.productPartialId || '') === (productPartialId || '')
  );

export const getIndexOfRow = (
  rows: CartRow[],
  productId: string,
  targetStockid?: string,
  productPartialId?: string
): number | undefined =>
  rows.findIndex(
    r =>
      r.productId === productId &&
      r.targetStockId === targetStockid &&
      (r.productPartialId || '') === (productPartialId || '')
  );
