import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OptionsListMulti } from 'gung-common';
import { PimDimension } from '../../../models/pim';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { PimDimensionsService } from '../../../services/pim-dimensions/pim-dimensions.service';
import { PimMetadataService } from '../../../services/pim-metadata/pim-metadata.service';
import { TableRecord } from '../../../state/metadata/types';

@Component({
  selector: 'lib-pim-dimensions-modal',
  templateUrl: './pim-dimensions-modal.component.html',
  styleUrls: ['./pim-dimensions-modal.component.css']
})
export class PimDimensionsModalComponent implements OnInit {
  isLoading = true;

  @Input()
  pimDimension: PimDimension;

  form: FormGroup;
  isSubmitted: boolean;

  translateLangs: string[];

  constructor(
    protected pimDimensionsService: PimDimensionsService,
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected pimMetadataService: PimMetadataService
  ) {}

  ngOnInit(): void {
    this.translateLangs = this.pimMetadataService.translateLanguages;
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      extra: this.formBuilder.group({}),
      i18n: this.getFormI18n(),
      id: this.formBuilder.control(this.pimDimension?.id || '', [Validators.required]),
      type: this.formBuilder.control(this.pimDimension?.type || '', []),
      name: this.formBuilder.control(this.pimDimension?.name || '', [])
    });
    this.isLoading = false;
  }

  getFormI18n(): FormGroup {
    const langs = {};
    for (const lang of this.translateLangs) {
      langs[lang] = this.formBuilder.group({
        description: this.formBuilder.control(this.pimDimension?.i18n?.[lang]?.description || '')
      });
    }
    return this.formBuilder.group(langs);
  }

  onSave() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formRaw = this.form.getRawValue();
    this.form.disable();
    this.isSubmitted = true;

    const dimension: PimDimension = {
      ...this.pimDimension,
      ...formRaw
    };

    this.pimDimensionsService.postPimDimensions(dimension).subscribe(_ => {
      this.activeModal.close(JSON.stringify(dimension));
    });
  }
}
