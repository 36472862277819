import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-edi-error-messages',
  templateUrl: './edi-error-messages.component.html',
  styleUrls: ['./edi-error-messages.component.css']
})
export class EdiErrorMessagesComponent implements OnInit {
  @Input()
  errors: any[];

  constructor() {}

  ngOnInit(): void {}
}
