import { Observable, of } from 'rxjs';

export abstract class PdfProductService {
  abstract getProduct(id): Observable<any>;

  abstract getListProducts(): Observable<any>;

  abstract getProductsByIds(itemIds): Observable<any>;

  abstract getFullProductsByIds(itemIds): Observable<any>;
}
