import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
  Optional,
  OnDestroy
} from '@angular/core';
import { DeliveryDateService } from '../../services/delivery-date/delivery-date.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { CalendarDate } from '../../models/calender-date';
import { isSameDay, isDate, compareAsc } from 'date-fns';
import { GungError } from './../../models/gung-error';
import { Subject } from 'rxjs';
import { GungModalService } from './../../services/gung-modal/gung-modal.service';
import { takeUntil } from 'rxjs';
import { CartRow } from '../../state/cart/types';

@Component({
  selector: 'lib-delivery-date-picker-beta',
  templateUrl: './delivery-date-picker-beta.component.html',
  styleUrls: ['./delivery-date-picker-beta.component.css']
})
export class DeliveryDatePickerBetaComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public label = 'DELIVERY_DATE';

  @Input()
  public suppressLabel = false;

  @Input()
  protected deliveryMethod: string;

  @Input()
  protected rows?: CartRow[];

  @Input()
  public initialDate?: Date | string;

  @Input('currentselectedDate')
  set _currentselectedDate(value: Date | string) {
    if (!value) {
      return;
    }
    this.currentselectedDate = value;
    if (this.currentselectedDate && !isDate(this.currentselectedDate)) {
      this.currentselectedDate = new Date(this.currentselectedDate);
    }
    const valueDate = this.currentselectedDate as Date;
    // this.ngModel = new NgbDate(valueDate.getFullYear(), valueDate.getMonth() + 1, valueDate.getDate());
    this.initialDate = valueDate;
    this.updateDeliveryDates();
  }
  public currentselectedDate?: Date | string;

  @Input()
  public minInitialDate?: Date | string;

  @Input()
  public maxDate?: Date | string;

  @Input()
  tooltip?: string;

  @Output()
  protected selectedDate = new EventEmitter<CalendarDate>();

  @Output()
  protected changedModel = new EventEmitter<boolean>();

  @Input()
  disabled?: boolean;

  @Input()
  public labelBold = false;

  @Input()
  public customClass?: string;

  public loaded = false;
  public anyError = false;
  private gungError: GungError;
  public firstDateAvailable: CalendarDate;
  public firstDatePickerDate: NgbDateStruct;
  public maxDatePickerDate: NgbDate;
  public showWeekNumbers = false;

  public ngModel?: NgbDateStruct;

  protected dates: CalendarDate[];

  protected unsubscribe: Subject<void> = new Subject();

  markDisabled: (date: NgbDate, _current: { year: number; month: number }) => boolean;

  constructor(
    protected deliveryDateService: DeliveryDateService,
    public dateUtilService: DateUtilService,
    protected gungModalService: GungModalService
  ) {
    this.markDisabled = this._markDisabled.bind(this);
    this.showWeekNumbers = deliveryDateService.showWeekNumbers;
  }

  ngOnInit() {
    if (this.initialDate && !isDate(this.initialDate)) {
      this.initialDate = new Date(this.initialDate);
    }
    if (this.minInitialDate && !isDate(this.minInitialDate)) {
      this.minInitialDate = this.dateUtilService.parseDate(this.minInitialDate);
    }
    this.updateDeliveryDates();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.deliveryMethod) {
      console.log('changes.deliveryMethod', changes.deliveryMethod);
    }
    if (changes.deliveryMethod /** && !changes.deliveryMethod.firstChange **/) {
      this.updateDeliveryDates();
    }
  }

  protected updateDeliveryDates() {
    this.deliveryDateService
      .getDeliveryDates(this.deliveryMethod, this.rows)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dates => {
        this.dates = dates;
        this.firstDateAvailable = this.dates.find(date => {
          return date.valid;
        });
        if (this.firstDateAvailable) {
          const firstDatePickerDate = new NgbDate(
            this.firstDateAvailable.date.getFullYear(),
            this.firstDateAvailable.date.getMonth() + 1,
            this.firstDateAvailable.date.getDate()
          );
          this.firstDatePickerDate = this.toNgbDateStruct(firstDatePickerDate);

          let dateModel: NgbDate;
          if (
            this.initialDate &&
            compareAsc(this.initialDate as Date, this.firstDateAvailable.date) >= 0 &&
            (!this.minInitialDate || compareAsc(this.initialDate as Date, this.minInitialDate as Date) >= 0)
          ) {
            const initialDate = this.initialDate as Date;
            const findFirstAvDate = this.dates.find(date => {
              return date.valid && compareAsc(date.date, initialDate as Date) >= 0;
            });
            if (findFirstAvDate) {
              dateModel = new NgbDate(
                findFirstAvDate.date.getFullYear(),
                findFirstAvDate.date.getMonth() + 1,
                findFirstAvDate.date.getDate()
              );
            }
            if (this.minInitialDate && compareAsc(this.minInitialDate as Date, this.firstDateAvailable.date) >= 0) {
              const minInitialDate = this.minInitialDate as Date;
              const firstAvCalendarDate = this.dates.find(date => {
                return date.valid && compareAsc(date.date, minInitialDate as Date) >= 0;
              });
              const firstAvDate = new NgbDate(
                firstAvCalendarDate.date.getFullYear(),
                firstAvCalendarDate.date.getMonth() + 1,
                firstAvCalendarDate.date.getDate()
              );
              this.firstDatePickerDate = this.toNgbDateStruct(firstAvDate);
            }
          } else if (
            this.minInitialDate &&
            compareAsc(this.minInitialDate as Date, this.firstDateAvailable.date) >= 0
          ) {
            const minInitialDate = this.minInitialDate as Date;
            dateModel = new NgbDate(
              minInitialDate.getFullYear(),
              minInitialDate.getMonth() + 1,
              minInitialDate.getDate()
            );
            this.firstDatePickerDate = this.toNgbDateStruct(dateModel);
          } else {
            dateModel = NgbDate.from(this.firstDatePickerDate);
          }

          if (this.ngModel) {
            const model = NgbDate.from(this.ngModel);
            if (model.before(this.firstDatePickerDate)) {
              dateModel = NgbDate.from(this.firstDatePickerDate);
              this.dateSelected(dateModel);
            } else {
              const modelDate = this.dateUtilService.parseDate(model);
              const findFirstAvDate = this.dates.find(date => {
                return date.valid && compareAsc(date.date, modelDate) >= 0;
              });
              if (findFirstAvDate) {
                dateModel = new NgbDate(
                  findFirstAvDate.date.getFullYear(),
                  findFirstAvDate.date.getMonth() + 1,
                  findFirstAvDate.date.getDate()
                );
              }
            }
          }
          this.ngModel = dateModel;
          this.dateSelected(dateModel);
          this.loaded = true;
        } else {
          this.setError('ERROR_DELIVERY_DATE_UNDEFINED');
        }

        if (this.maxDate) {
          const maxDate = this.maxDate as Date;
          const dateModel = new NgbDate(maxDate.getFullYear(), maxDate.getMonth() + 1, maxDate.getDate());
          this.maxDatePickerDate = dateModel;
        }
      });
  }

  toNgbDateStruct(dateObj: Date | NgbDate | CalendarDate): NgbDateStruct {
    const date = this.dateUtilService.parseDate(dateObj);
    return { year: date.getFullYear(), month: date.getMonth() + 1, day: date.getDate() };
  }

  // true if the date should be marked as disabled
  _markDisabled(date: NgbDate, _current: { year: number; month: number }) {
    const foundDate = this.dates
      // first filter out all the invalid dates
      .filter(d => d.valid)
      .map(d => d.date)
      // find a matching date with the one to check
      .filter(
        d =>
          d.getFullYear() === date.year &&
          // js date object is zero indexed
          d.getMonth() + 1 === date.month &&
          d.getDate() === date.day
      );

    // return true if the date wasn't found, false if it was.
    return foundDate.length === 0;
  }

  dateSelected(event: NgbDate) {
    const date = this.dateUtilService.createDateFromNgbDate(event);
    const calendarDate = this.dates.find(
      dateElement =>
        isSameDay(dateElement.date, date) /*  this.dateUtilService.compareDates(dateElement.date, date) === 0 */
    );
    this.selectedDate.emit(calendarDate);
  }

  private setError(i18nKeyMessage: string): void {
    this.loaded = false;
    this.anyError = true;
    this.gungError = {
      i18nKeyMessage,
      name: '',
      message: ''
    };

    this.openErrorModal();
  }

  openErrorModal(): void {
    this.gungModalService.openGungErrorHandler(this.gungError);
  }

  modelChanged() {
    this.changedModel.emit(true);
  }
}
