import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-init-checkout-object',
  templateUrl: './init-checkout-object.component.html',
  styleUrls: ['./init-checkout-object.component.css']
})
export class InitCheckoutObjectComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected selectedCustomerService: SelectedCustomerService) {
    super();
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(() => {
      this.selectedCustomerService
        .getSelectedCustomer()
        .pipe(first())
        .subscribe(customer => {
          if (customer == null) {
            this.stepError.emit({
              message: 'Could not find a selected customer',
              step: 'InitCheckoutObjectComponent',
              error: new Error()
            });
            return;
          }
          this.stepDone.emit({
            deliveryCustomerId: customer.id,
            extra: {},
            rows: []
          });
        });
    });
  }

  public isVisible() {
    return false;
  }
}
