import { EdiGungMessages } from './../../models/edi';
import { Injectable } from '@angular/core';
import {
  ConfigService,
  ListSortOption,
  ConfigBaseFilter,
  ListLayout,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { forkJoin, Observable, of } from 'rxjs';
import { EdiMessagesTableViewComponent } from '../../components/edi/edi-messages-table-view/edi-messages-table-view.component';
import { EdiService } from '../edi/edi.service';
import { catchError, first, map, mergeMap, switchMap } from 'rxjs';
import { OrderService } from '../orders/order.service';
import { Order } from '../../models/order';

export class EdiMessageDirectionFilter extends SimpleConfigBaseFilter<EdiGungMessages> {
  getName(): string {
    return 'DIRECTION';
  }

  getOptionIds(item: EdiGungMessages): string[] {
    return [item.direction + ''];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class EdiMessageStatusFilter extends SimpleConfigBaseFilter<EdiGungMessages> {
  getName(): string {
    return 'STATUS';
  }

  getOptionIds(item: EdiGungMessages): string[] {
    return [item.status];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class EdiMessageTypeFilter extends SimpleConfigBaseFilter<EdiGungMessages> {
  getName(): string {
    return 'MESSAGE_TYPE';
  }

  getOptionIds(item: EdiGungMessages): string[] {
    if (!item.messageType) {
      return ['UNDEFINED'];
    }
    return ['EDI_ITEM_' + item.messageType];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EdiMessagesListConfigService implements ConfigService<EdiGungMessages> {
  constructor(protected ediService: EdiService, protected orderService: OrderService) {}

  getSortOptions(): ListSortOption<EdiGungMessages>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: EdiGungMessages) {
    return item.id;
  }

  getSearchTerms(item: EdiGungMessages) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getFilters(): ConfigBaseFilter<EdiGungMessages>[] {
    return [new EdiMessageTypeFilter(), new EdiMessageDirectionFilter(), new EdiMessageStatusFilter()];
  }

  getLayouts(): ListLayout<EdiGungMessages>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => EdiMessagesTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<EdiGungMessages[]> {
    return this.ediService.getGungMessages().pipe(
      mergeMap(result => {
        const requests: Observable<any>[] = [];
        result.forEach(item => {
          if (item.localFileName) {
            item.shortFileName = item.localFileName.substring(item.localFileName.lastIndexOf('\\') + 1);
          }
          item.isOrder = item.messageType === 'ORDERS';
          item.isInvoice = item.messageType === 'INVOICE';
          item.isOrderResponse = item.messageType === 'ORDRSP';
          item.isDespatchAdvice = item.messageType === 'DESADV';

          if (item.isOrder && item.orderIds && item.orderIds.length > 0) {
            item.orderNumberModel = item.orderIds[0];
            item.deliveredOrderIds = [];
            item.notDeliveredOrderIds = [];
            item.selectedOrderIdsForDespatchAdvice = [];
            for (const orderId of item.orderIds) {
              requests.push(
                this.orderService.getOrder(orderId).pipe(
                  first(),
                  catchError(err => of({ isError: true, error: err }))
                )
              );
            }
          }
        });
        requests.unshift(of(result));
        return forkJoin(requests);
      }),
      switchMap(data => {
        const result = data.shift();
        const orders = data;
        (result as EdiGungMessages[]).forEach(item => {
          if (item.isOrder && item.orderIds && item.orderIds.length > 0) {
            for (const orderId of item.orderIds) {
              const order = (orders as Order[]).find(o => o.id === orderId);
              if (order && order.extra && order.extra.OGA && order.extra.OGA.LEF === '5') {
                item.deliveredOrderIds.push(order.id);
              } else {
                item.notDeliveredOrderIds.push(orderId);
              }
            }
          }
        });
        return of(result);
      })
    );
  }
}
