import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { User } from '../../state/auth/types';
import { Customer } from '../../models';
import { BackendTemplate } from '../../components/custom-page/custom-page-interface/template-component';
@Injectable({
  providedIn: 'root'
})
export class HomeNavigationConfigService {
  adminLink = ['/sales-dashboard'];
  salesLink = ['/sales-dashboard'];
  multiCustomersUserLink = ['/delivery-location'];
  singleCustomerUserLink = ['/categories'];
  anonymousLink = ['/products'];
  supplierBuyerLink = ['/supplier-portal'];
  public dynamicCustomPageByRoles = false;
  homePageName = 'home';

  constructor(
    protected authService: AuthService,
    protected router: Router
  ) {
  }

  setAdminHomeLink(link: string[]) {
    this.adminLink = link;
  }

  setSalesHomeLink(link: string[]) {
    this.salesLink = link;
  }

  setMultiCustomersUserLink(link: string[]) {
    this.multiCustomersUserLink = link;
  }

  setSingleCustomerUserLink(link: string[]) {
    this.singleCustomerUserLink = link;
  }

  setAnonymousHomeLink(link: string[]) {
    this.anonymousLink = link;
  }

  getHomeLink(user: User, customers?: Customer[], customPages?: BackendTemplate[]): string[] {
    if (this.dynamicCustomPageByRoles && customPages?.length > 0) {
      const activeGroups = Object.keys(user.activeGroups).filter(ag => !!user.activeGroups[ag]);
      const pagesByGroup = customPages.filter(p => activeGroups.filter(ag => p.activeForUserGroups?.includes(ag)).length > 0);
      const homePage = pagesByGroup.find(p => p.pageName === this.homePageName);
      if (homePage) {
        return ['page', homePage.pageName];
      }
    }
    if (user.roles.includes('SUPPLIER') || user.roles.includes('BUYER')) {
      return this.supplierBuyerLink;
    } else if (user.roles.includes('ADMIN')) {
      return this.adminLink;
    } else if (user.roles.includes('SALES')) {
      return this.salesLink;
    } else if (user.roles.includes('USER')) {
      if (customers && customers.length > 1) {
        return this.multiCustomersUserLink;
      } else {
        return this.singleCustomerUserLink;
      }
    } else if (user.roles.includes('ANONYMOUS')) {
      return this.anonymousLink;
    }
  }
}
