import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { forkJoin, Observable, of } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, mergeMap, tap } from 'rxjs';
import { Product } from '../../../models';
import { Order } from '../../../models/order';
import { OrderRow } from '../../../models/orderRow';
import { OrderService } from '../../../services/orders/order.service';
import { ProductService } from '../../../services/products/product.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { EdiModalAddCampaignProductsComponent } from '../edi-modal/edi-modal-add-campaign-products/edi-modal-add-campaign-products.component';
import { EdiModalChangeDeliveryDateComponent } from '../edi-modal/edi-modal-change-delivery-date/edi-modal-change-delivery-date.component';
import { EdiModalReleaseOrdersComponent } from '../edi-modal/edi-modal-release-orders/edi-modal-release-orders.component';

interface OrderExtended extends Order {
  dateCreated?: Date;
}

@Component({
  selector: 'lib-edi-joint-order-summary',
  templateUrl: './edi-joint-order-summary.component.html',
  styleUrls: ['./edi-joint-order-summary.component.css']
})
export class EdiJointOrderSummaryComponent implements OnInit {
  private cachedData: { [id: string]: { product: Product } } = {};
  loading = true;
  queryParamOrders: string;

  listOrders: OrderExtended[] = [];

  selectedOrder: OrderExtended;
  selectedOrders: OrderExtended[] = [];
  selectedOrdersRows: OrderRow[] = [];
  selectedOrdersId: string[] = [];
  selectedOrdersCustomer: string[] = [];

  formatter = (order: OrderExtended) =>
    order
      ? order.id +
        ' - ' +
        order.extra?.deliveryCustomer?.name +
        (order.dateCreated ? ' (' + format(new Date(order.dateCreated), 'yyyy-MM-dd') + ')' : '')
      : '';

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term =>
        this.listOrders
          .filter((order: OrderExtended) => new RegExp(term.replace(/([.?*+^$[\]\\(){}|-])/g, ''), 'mi').test(order.id))
          .slice(0, 10)
      )
    );

  constructor(
    protected route: ActivatedRoute,
    protected orderService: OrderService,
    protected productService: ProductService,
    protected selectedCustomerService: SelectedCustomerService,
    protected ngbModal: NgbModal
  ) {}

  ngOnInit(): void {
    this.queryParamOrders = this.route.snapshot.queryParamMap.get('orderId');
    if (this.queryParamOrders) {
      const orderIds = this.queryParamOrders.split(',');
      forkJoin(orderIds.map(id => this.orderService.getOrder(id))).subscribe(orders =>
        orders.forEach(o => this.selectOrder(o))
      );
    }
    this.getAllOrders();
  }

  getAllOrders() {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        mergeMap(customer => {
          if (customer != null) {
            return this.orderService.getOrders(customer.id);
          } else {
            return of([]);
          }
        })
      )
      .subscribe(orders => {
        this.listOrders = orders;
        this.loading = false;
      });
  }

  addOrder({ item }) {
    const order: OrderExtended = item;
    if (order?.id) {
      this.loading = true;
      this.orderService.getOrder(order.id).subscribe(o => {
        this.selectOrder(o);
        this.selectedOrder = undefined;
        this.loading = false;
      });
    }
  }

  selectOrder(order: Order) {
    this.selectedOrders.push(order);
    this.selectedOrdersId.push(order.id);
    this.selectedOrdersId = [...new Set(this.selectedOrdersId)];
    this.selectedOrdersCustomer.push(order.extra?.deliveryCustomer?.name);
    this.selectedOrdersCustomer = [...new Set(this.selectedOrdersCustomer)];
    this.selectedOrdersRows = [];
    this.selectedOrders.map(oo => oo.rows).forEach(rows => this.selectedOrdersRows.push(...rows));
    this.cacheProducts(this.selectedOrdersRows);
  }

  cacheProducts(ordersRows: OrderRow[]) {
    const cachedIds = Object.keys(this.cachedData);
    const toGetIds = ordersRows.map(c => c.productId).filter(id => !cachedIds.includes(id));

    if (toGetIds && toGetIds.length > 0) {
      this.productService
        .getProductsByIds(toGetIds)
        .pipe(first())
        .subscribe(products => {
          this.cachedData = {
            ...this.cachedData,
            ...toGetIds
              .map(id => ({
                product: products.find(p => p.id === id)
              }))
              .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
          };
          this.mapProductsToRow(ordersRows, this.cachedData);
        });
    }
  }

  mapProductsToRow(ordersRows: OrderRow[], cachedData: { [id: string]: { product: Product } }) {
    ordersRows.forEach(row => (row.extra.product = cachedData[row.productId].product));
  }

  releaseOrders(row: OrderRow) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true
      // size: 'lg',
    };
    const modalRef = this.ngbModal.open(EdiModalReleaseOrdersComponent, option);
    modalRef.componentInstance.row = row;
    modalRef.result.then(res => {
      console.log('releaseOrders', res);
    });
  }

  changeDeliveryDate(row: OrderRow) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true
      // size: 'lg',
    };
    const modalRef = this.ngbModal.open(EdiModalChangeDeliveryDateComponent, option);
    modalRef.componentInstance.row = row;
    modalRef.result.then(res => {
      console.log('changeDeliveryDate', res);
    });
  }

  addCampaignProducts(row: OrderRow) {
    const option: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true,
      centered: true
      // size: 'lg',
    };
    const modalRef = this.ngbModal.open(EdiModalAddCampaignProductsComponent, option);
    modalRef.componentInstance.row = row;
    modalRef.result.then(res => {
      console.log('addCampaignProducts', res);
    });
  }
}
