<label for="typeahead-related-products">
  <h5 translate>RELATED_PRODUCTS</h5>
</label>
<input
  id="typeahead-related-products"
  type="text"
  class="form-control"
  [resultFormatter]="formatterRelatedProducts"
  [(ngModel)]="modelRelatedProduct"
  [ngbTypeahead]="searchRelatedProducts"
  (selectItem)="onRelatedProductSelect($event)"
/>
<div class="row" *ngIf="pimProduct.extra.pim.relatedProducts">
  <div class="col-xs-12 col-sm-6 pt-2" *ngFor="let relatedProductId of pimProduct.extra.pim.relatedProducts.split(',')">
    <div class="input-group">
      <input type="text" disabled="disabled" class="form-control input-sm" [value]="relatedProductId" />
      <button type="button" class="btn btn-danger" (click)="removeRelatedProduct(relatedProductId)" translate>
        DELETE
      </button>
    </div>
  </div>
</div>

<label for="typeahead-included-brands">
  <h5 translate>INCLUDED_BRANDS</h5>
</label>
<input
  id="typeahead-included-brands"
  type="text"
  class="form-control"
  [resultFormatter]="formatterIncludedBrands"
  [(ngModel)]="modelIncludedBrand"
  [ngbTypeahead]="searchIncludedBrands"
  (selectItem)="onIncludedBrandsSelect($event)"
/>
<div class="row" *ngIf="pimProduct.extra.pim.concepts">
  <div class="col-xs-12 col-sm-6 pt-2" *ngFor="let conceptId of pimProduct.extra.pim.concepts">
    <div class="input-group">
      <input type="text" disabled="disabled" class="form-control input-sm" [value]="conceptId" />
      <button type="button" class="btn btn-danger" (click)="removeIncludedBrands(conceptId)" translate>DELETE</button>
    </div>
  </div>
</div>
