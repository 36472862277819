<div class="gung-stepper">
  <div #stepperTop class="stepper-control-top">
    <div [ngClass]="{ active: selectedIndex >= i }" class="stepper-indicator" *ngFor="let step of steps; let i = index">
      <div class="step-label-top" translate>
        {{ step.label }}
      </div>
    </div>
  </div>
  <div class="stepper-content">
    <ng-container [ngTemplateOutlet]="selected.content"></ng-container>
  </div>

  <!-- <div class="stepper-control-bottom">
    <button type="button" [disabled]="selectedIndex === 0" class="btn stepper-button" cdkStepperPrevious>prev</button>
    <div class="stepper-indicator-group" *ngIf="bottomIndicator">
      <div [ngClass]="{'active' : selectedIndex === i}" class="stepper-indicator"
        *ngFor="let step of steps; let i = index;">
        {{i + 1}}
      </div>
    </div>
    <button type="button" [disabled]="selectedIndex === (steps.length - 1)" class="btn stepper-button" cdkStepperNext>next</button>
  </div> -->
</div>
