import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from '../../../state/auth/types';
import { DeleteUserModalComponent } from '../../../components/users/delete-user-modal/delete-user-modal.component';
import { first } from 'rxjs';
import { UsersService } from '../users.service';
import { GungModalService } from '../../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class UserActionConfigService implements ActionButtonConfigService<User> {
  base = [
    {
      title: 'EDIT_USER',
      action: (item: User) =>
        this.router.navigate(['/admin/users-details/' + item.username], { queryParamsHandling: '' })
    },
    { title: 'COPY_USER', action: (item: User) => this.copyUser(item) },
    { title: 'DELETE_USER', action: (item: User) => this.deleteUser(item.username) }
  ];

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected usersService: UsersService,
    protected gungModalService: GungModalService
  ) { }

  getButtons(item: User): Observable<ActionButton<User>[]> {
    return new Observable<ActionButton<User>[]>(subscriber => {
      subscriber.next(this.base);
      subscriber.complete();
    });
  }

  deleteUser(username: string): void {
    const modalRef = this.modalService.open(DeleteUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.username = username;
    modalRef.result.then(
      (result: string) => {
        if (result === 'YES') {
          this.usersService
            .deleteUser(username)
            .pipe(first())
            .subscribe(res => {
              // this.data.splice(this.data.findIndex(user => user.username === username), 1);
            });
        }
      },
      reason => { }
    );
  }

  copyUser(user: User): void {
    const userToCreateFrom = {
      ...user,
      username: '',
      name: '',
      email: '',
      password: ''
    };
    this.gungModalService.createUser(userToCreateFrom, true).then(
      (u: User) => {
        if (!!u) {
          this.router.navigate(['/admin/users-details/' + u.username], { queryParamsHandling: '' });
        }
      },
      reason => { }
    );
  }
}
