import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-whiteboard-management-modal',
  templateUrl: './whiteboard-management-modal.component.html',
  styleUrls: ['./whiteboard-management-modal.component.css']
})
export class WhiteboardManagementModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
