<div class="pt-4" *ngIf="!loading; else loading">
  <canvas
    baseChart
    *ngIf="!tableOnly"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [type]="'bar'"
  >
  </canvas>

  <div class="table-responsive">
    <table class="table">
      <thead>
        <tr>
          <th *ngFor="let rh of tableHeaderRowData">{{ rh | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let rowData of tableRowData">
          <td *ngFor="let data of rowData">
            <span *ngIf="isNotNumber(data); else displayNumber">{{ data | translate }}</span>
            <ng-template #displayNumber>{{ data == 0 ? '-' : (data | number : '1.0-2') }}</ng-template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<div class="text-center dashboard-loading-spinner" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
