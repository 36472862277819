import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Product } from '../../models';
import { isMultiDimensionProduct } from '../../utils/product-utils';
import { BaseGridCardV2Component } from '../base-grid-card-v2/base-grid-card-v2.component';
import { LocationConfigService } from './../../services/location-config/location-config.service';

@Component({
  selector: 'lib-product-grid-card-v2',
  templateUrl: './product-grid-card-v2.component.html',
  styleUrls: ['./product-grid-card-v2.component.scss']
})
export class ProductGridCardV2Component extends BaseGridCardV2Component {
  adjustedScroll = false;

  constructor(protected router: Router, protected locationConfigService: LocationConfigService) {
    super();
  }

  getIsMultiDimensionProduct(product: Product): boolean {
    return isMultiDimensionProduct(product);
  }

  goToDetail(product: Product): void {
    if (this.data.product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      this.router.navigate(['/product/', product.id]);
    }
  }
}
