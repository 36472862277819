<h3 translate class="page-title text-center">RELATED_BRANDS</h3>
<div class="row">
  <div class="col-12 text-right">
    <div class="top-brand-btn">
      <button type="button" class="btn btn-primary text-uppercase" [routerLink]="['/admin/related-brands/new-brand']">
        {{ 'CREATE_NEW_BRAND' | translate }}
      </button>
    </div>
    <!-- <div class="top-brand-btn">
            <button type="button" class="btn btn-primary text-uppercase" [routerLink]="['/admin/related-brands/images']">
                {{'IMAGE_UPLOAD' | translate}}
            </button>
        </div> -->
  </div>
</div>
<div class="row">
  <div class="col-12">
    <lib-filter-list [configService]="brandsListConfigService"></lib-filter-list>
  </div>
</div>
