<div *ngIf="isOnlyDisplayFlow || isAnonymous; else buyButton">
  <div class="input-group">
    <button class="btn btn-block btn-sm btn-primary" tabindex="-1" [routerLink]="[goToDetailUrl()]" translate>
      DETAILS
    </button>
  </div>
</div>

<ng-template #buyButton>
  <div class="input-group" *ngIf="currentQty > 0; else btn">
    <div class="input-group-prepend" *ngIf="!disabled">
      <button
        [disabled]="disabledSub"
        class="btn btn-primary btn-sm"
        tabindex="-1"
        (click)="sub(); $event.preventDefault(); $event.stopPropagation()"
      >
        <i class="fas fa-minus"></i>
      </button>
    </div>
    <input
      #inputEle
      type="number"
      class="form-control form-control-sm text-center"
      [ngModel]="formatValue(currentQty)"
      (ngModelChange)="set($event, inputEle)"
      (click)="$event.preventDefault(); $event.stopPropagation()"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [disabled]="disabled"
    />
    <div class="input-group-append" *ngIf="!disabled">
      <button
        class="btn btn-sm btn-primary"
        tabindex="-1"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
        [disabled]="disabledAdd"
      >
        <i class="fas fa-plus"></i>
      </button>
    </div>
  </div>

  <ng-template #btn>
    <div class="input-group">
      <!-- ORDER was not a good translate tag here because it has other usages-->
      <button
        [disabled]="disabled || disabledAdd"
        class="btn btn-block btn-sm btn-primary"
        tabindex="-1"
        (click)="add(); $event.preventDefault(); $event.stopPropagation()"
        translate
      >
        ADD_TO_CART
      </button>
    </div>
  </ng-template>
</ng-template>
