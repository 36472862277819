import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-edi-modal-release-orders',
  templateUrl: './edi-modal-release-orders.component.html',
  styleUrls: ['./edi-modal-release-orders.component.css']
})
export class EdiModalReleaseOrdersComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
