import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExportDigitalAssetsRouterService } from '../../services/export-digital-assets-router.service';
import { ListItemRendererComponent } from 'gung-list';
import { DigitalAsset, PdfDigitalAssetsService } from '../../services/pdf-digital-assets.service';

@Component({
  selector: 'lib-export-digital-assets-grid-view',
  templateUrl: './export-digital-assets-grid-view.component.html',
  styleUrls: ['./export-digital-assets-grid-view.component.css']
})
export class ExportDigitalAssetsGridViewComponent
  extends ListItemRendererComponent<DigitalAsset[]>
  implements OnInit, OnChanges
{
  parentId: string;
  selectedDigitalAsset: DigitalAsset;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected digitalAssetsService: PdfDigitalAssetsService,
    protected exportDigitalAssetsRouterService: ExportDigitalAssetsRouterService
  ) {
    super();
  }

  ngOnInit() {
    this.parentId = undefined;
    if (this.route.snapshot.url.length > 1) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 2].path;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  openDigitalAsset(digitalAsset) {
    this.exportDigitalAssetsRouterService.currentDigitalAsset.next(':digitalasset-' + digitalAsset.id);
  }
}
