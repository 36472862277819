import { EventEmitter, Injectable, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { Observable } from 'rxjs';
import { first } from 'rxjs';
import { DeleteUserModalComponent } from '../../components/users/delete-user-modal/delete-user-modal.component';
import { UpdateUserModalComponent } from '../../components/users/update-user-modal/update-user-modal.component';
import { User } from '../../state/auth/types';
import { UsersService } from '../users/users.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerUserActionConfigService implements ActionButtonConfigService<User> {
  @Output()
  updateEvent = new EventEmitter<User>();

  @Output()
  deleteEvent = new EventEmitter<string>();

  base = [
    { title: 'EDIT_USER', action: (item: User) => this.updateUser(item) },
    { title: 'DELETE_USER', action: (item: User) => this.deleteUser(item.username) }
  ];

  constructor(protected modalService: NgbModal, protected usersService: UsersService) {}

  getButtons(item: User): Observable<ActionButton<User>[]> {
    return new Observable<ActionButton<User>[]>(subscriber => {
      subscriber.next(this.base);
      subscriber.complete();
    });
  }

  updateUser(user: User): void {
    const modalRef = this.modalService.open(UpdateUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.userToUpdate = user;
    modalRef.result.then(
      (u: User) => {
        this.updateEvent.emit(u);
      },
      reason => {}
    );
  }

  deleteUser(username: string): void {
    const modalRef = this.modalService.open(DeleteUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.username = username;
    modalRef.result.then(
      (result: string) => {
        if (result === 'YES') {
          this.usersService
            .deleteUser(username)
            .pipe(first())
            .subscribe(res => {
              this.deleteEvent.emit(username);
            });
        }
      },
      reason => {}
    );
  }
}
