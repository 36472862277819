import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ReportCentralReport } from '../models/report-central';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ReportCentralReportService {
  baseUrl = `json/report-central/generated-reports`;

  constructor(protected http: HttpClient) {}

  getAllForUser(): Observable<ReportCentralReport[]> {
    const headers: { [key: string]: string | string[] } = {
      maxAge: '-1'
    };

    return this.http.get<ReportCentralReport[]>(this.baseUrl, { headers });
  }

  getByIds(ids: string[]): Observable<ReportCentralReport[]> {
    const headers: { [key: string]: string | string[] } = {
      maxAge: '-1'
    };
    return this.http.get<ReportCentralReport[]>(`${this.baseUrl}/by-ids?ids=${ids.join(',')}`, { headers });
  }

  private exportOrderStatisticsUrl: string = "json/report-central/generate-order-statistics-excel";
  private exportInvoiceStatisticsUrl: string = "json/report-central/generate-invoice-statistics-excel" ;

  exportOrderStatistics(customerId: string): Observable<ReportCentralReport> {
    const headers: { [key: string]: string | string[] } = {};
    return this.http.post<any>(`${this.exportOrderStatisticsUrl}/${customerId}`, { headers });
  }

  exportInvoiceStatistics(customerId: string): Observable<ReportCentralReport> {
    const headers: { [key: string]: string | string[] } = {};
    return this.http.post<any>(`${this.exportInvoiceStatisticsUrl}/${customerId}`, { headers });
  }

}
