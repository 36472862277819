import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { Observable, Subject } from 'rxjs';
import {  ActuatorEnvironmentProperty, ActuatorEnvironmentService } from '../actuator-environment/actuator-environment.service';
import { ActuatorEnvironmentListComponent } from '../../components/actuator/actuator-environment/actuator-environment-list/actuator-environment-list.component';


export class ActuatorEnvironmentPropertySourceNameFilter extends SimpleConfigBaseFilter<ActuatorEnvironmentProperty> {
  getName(): string {
    return 'PROPERTY_SOURCE_NAME';
  }

  getOptionIds(item: ActuatorEnvironmentProperty): string[] {
    const keys = Object.keys(item);
    if (keys.length > 0) {
      return [item[keys[0]].propertySources || 'UNDEFINED'];
    }
    return ['UNDEFINED'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActuatorEnvironmentListConfigService implements ConfigService<ActuatorEnvironmentProperty> {

  constructor(protected actuatorEnvironmentService: ActuatorEnvironmentService) {}

  getFilters(): ConfigBaseFilter<ActuatorEnvironmentProperty>[] {
    return [
      new ActuatorEnvironmentPropertySourceNameFilter()
    ];
  }

  getSortOptions(): ListSortOption<ActuatorEnvironmentProperty>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: ActuatorEnvironmentProperty) {
    const keys = Object.keys(item);
    if (keys.length > 0) {
      return keys[0];
    }
    return '';
  }

  getSearchTerms(item: ActuatorEnvironmentProperty) {
    const keys = Object.keys(item);
    if (keys.length > 0) {
      return [keys[0], item[keys[0]].value.toString()];
    }
    return [''];
  }

  getSearchGroupCss() {
    return '';
  }

  getLayouts(): ListLayout<ActuatorEnvironmentProperty>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ActuatorEnvironmentListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<ActuatorEnvironmentProperty[]> {
    return this.actuatorEnvironmentService.getAllDistinctProperties();
  }
}
