import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { Router } from '@angular/router';
import { first } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-create-gung-flow-modal',
  templateUrl: './create-gung-flow-modal.component.html',
  styleUrls: ['./create-gung-flow-modal.component.css']
})
export class CreateGungFlowModalComponent implements OnInit {
  flowId = '';
  error: any;

  constructor(
    protected router: Router,
    public activeModal: NgbActiveModal,
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {}

  create(): void {
    this.error = null;
    


    if (!this.flowId) {
      this.error = { message: this.translateService.instant('NEWFLOW_ID_UNDEFINED') };
      return;
    }
    const lettersOrNumbersPattern = /^[a-zA-Z0-9-_]+$/;
    if(!lettersOrNumbersPattern.test(this.flowId)){
      this.error = { message: this.translateService.instant('ONLY_LETTERS_OR_NUMBERS') };
      return;
    }
    this.gungFlowService
      .createGungFlow({ id: this.flowId })
      .pipe(first())
      .subscribe(
        gungFlow => {
          this.activeModal.close();
          this.router.navigate(['/admin/gung-flow-details/' + gungFlow.id]);
        },
        httpErrorResponse => {
          this.error = httpErrorResponse.error;
        }
      );
  }
}
