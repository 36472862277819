<div class="table-responsive">
  <table
    class="table"
    mat-table
    [dataSource]="dataSource"
    matSort
  >
    <!--ProductId-->
    <ng-container matColumnDef="productName">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        style="vertical-align: middle; text-align: left"
      >
        <span translate>PRODUCT_NAME</span>
      </th>

      <td style="vertical-align: middle; text-align: left" mat-cell *matCellDef="let element">
        <div>
          {{ element.productName }}
        </div>
        <div>
          <small>{{ element.productId }}</small>
        </div>
      </td>
    </ng-container>

    <!--ProductId-->
    <ng-container matColumnDef="salesAmount">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        style="vertical-align: middle; text-align: left"
      >
        <span translate>SALES_AMOUNT</span>
      </th>

      <td style="vertical-align: middle; text-align: left" mat-cell *matCellDef="let element">
        {{ element.salesAmount == 0 ? '-' : (element.salesAmount | number : '1.0-2') }}
      </td>
    </ng-container>

    <!--ProductId-->
    <ng-container matColumnDef="quantity">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        style="vertical-align: middle; text-align: left"
      >
        <span translate>QUANTITY</span>
      </th>

      <td style="vertical-align: middle; text-align: left" mat-cell *matCellDef="let element">
        {{ element.quantity == 0 ? '-' : (element.quantity | number : '1.0-2') }}
      </td>
    </ng-container>

    <!--ProductId-->
    <ng-container matColumnDef="numberOfOrders">
      <th
        mat-header-cell
        mat-sort-header
        *matHeaderCellDef
        style="vertical-align: middle; text-align: left"
      >
        <span translate>NUMBER_OF_ORDERS</span>
      </th>

      <td style="vertical-align: middle; text-align: left" mat-cell *matCellDef="let element">
        {{ element.numberOfOrders == 0 ? '-' : (element.numberOfOrders | number : '1.0-2') }}
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
