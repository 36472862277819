import { Component, OnDestroy, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth/auth.service';
import { Subject, first, switchMap, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-mockroles-warning',
  templateUrl: './mockroles-warning.component.html',
  styleUrls: ['./mockroles-warning.component.css']
})
export class MockrolesWarningComponent implements OnInit, OnDestroy {
  public isMockRolesEnabled: boolean = false;
  protected unsubscribe: Subject<void> = new Subject<void>();

  constructor(protected authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getCurrentUser().pipe(takeUntil(this.unsubscribe)).subscribe(user => this.isMockRolesEnabled = user?.roles?.includes('ACTUATOR') && this.authService.mockRolesModeEnabled())
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
