import { Injectable } from '@angular/core';
import { ImportLinesheet } from '../../models/linesheet';
import { User } from '../../state/auth/types';
import { CartService } from '../cart/cart.service';

@Injectable({
  providedIn: 'root'
})
export class LinesheetImportSupportService {
  constructor(protected cartService: CartService) {}

  loadProductsToCart(
    uploadFormat: string,
    managedMultistockIds: string[],
    result: ImportLinesheet,
    replaceMode: string
  ) {
    for (const product of result.rows) {
      if (!!product && product.productId.includes('_GUNGDOT_')) {
        product.productId = product.productId.replace('_GUNGDOT_', '.');
      }
    }
    console.log('LinesheetImportSupportService', uploadFormat);
    if (
      uploadFormat === 'COLUMN_FORMAT' ||
      (uploadFormat === 'MATRIX_FORMAT' && replaceMode === 'REPLACE_CURRENT_ORDER')
    ) {
      this.cartService.clearCart();
    }

    const targetStockId = managedMultistockIds[0] || '*';
    const rows = result.rows.map(row => {
      return {
        productId: row.productId,
        qty: row.quantity,
        targetStockId
      };
    });

    this.cartService.bulkAddToCart(rows);
  }
}
