import AdyenCheckout from '@adyen/adyen-web';
import { CoreOptions } from '@adyen/adyen-web/dist/types/core/types';
import { Component, EventEmitter, Inject, Input, OnInit, Optional, Output } from '@angular/core';
import { AdyenPaymentLinkResponse, AdyenService } from '../../services/adyen/adyen.service';
import { catchError, filter, first, of, repeat, takeWhile } from 'rxjs';
import { OnPaymentCompletedData } from '@adyen/adyen-web/dist/types/components/types';
import { CheckoutObject } from '../../models/checkout';

interface AdyenPaymentLinkRequest {
  currency: string;
  countryCode: string;
  description: string;
  shopperLocale: string;
  shopperReference: string;
  order: CheckoutObject;
}

@Component({
  selector: 'lib-adyen-payment',
  templateUrl: './adyen-payment.component.html',
  styleUrls: ['./adyen-payment.component.css']
})
export class AdyenPaymentComponent implements OnInit {
  @Input() checkout: any;
  @Input() payByLink: boolean;
  @Input() allowCancellationOfLink: boolean;
  @Output() onPaymentCompleted = new EventEmitter<AdyenPaymentCompletedEvent>();
  errorMessage: string;
  loading = false;
  protected paymentLink: AdyenPaymentLinkResponse;

  public shouldContinuePolling: boolean = true;
  public isPolling: boolean = false;
  public currentStatus: string = '';
  public currentStatusPercentage: number = 0;

  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected adyenService: AdyenService
  ) { }

  ngOnInit(): void {
    this.loading = true;
    this.adyenService.decorateCheckout(this.checkout).pipe(first()).subscribe(checkout => {
      this.checkout = checkout;
      if (this.payByLink) {
        this.createPaymentLink();
      } else {
        this.createPaymentSession();
      }
    });
  }

  private createPaymentSession() {
    this.adyenService
      .createSession(this.checkout)
      .pipe(first())
      .subscribe({
        next: this.handleSessionCreated,
        error: e => {
          console.error(e);
          this.errorMessage = 'COULD_NOT_CREATE_ADYEN_SESSION';
          this.loading = false;
        }
      });
  }

  private createPaymentLink() {
    // TODO - add Voyado ID here for cellbes
    this.adyenService
      .createPaymentLink({ order: this.checkout } as AdyenPaymentLinkRequest)
      .pipe(first())
      .subscribe({
        next: this.handlePaymentLinkCreated,
        error: e => {
          console.error(e);
          this.errorMessage = 'COULD_NOT_CREATE_ADYEN_PAYMENT_LINK';
          this.loading = false;
        }
      });
  }

  private handleSessionCreated = session => {
    this.loading = false;
    let adyenConfig: CoreOptions = {
      environment: this.environment.adyenEnvironment,
      clientKey: this.environment.adyenClientKey,
      session: session,
      onPaymentCompleted: (data, element?) =>
        this.onPaymentCompleted.emit({ data: data, orderId: session.reference, element: element })
    };

    AdyenCheckout(adyenConfig).then(checkoutObject => {
      checkoutObject.create('dropin').mount('#adyen-container');
    });
  };

  private handlePaymentLinkCreated = (paymentLink: AdyenPaymentLinkResponse) => {
    this.loading = false;
    this.paymentLink = paymentLink;
    this.pollForPaymentLinkUpdates();
  };

  private pollForPaymentLinkUpdates() {
    // Check link status on `json/adyen/link/${this.paymentLink.id}`. When status is 'COMPLETED' we can start polling for order
    // Get finalized order from `json/adyen-order/${this.paymentLink.reference}`
    // Mock AdyenPaymentCompletedEvent and emit it when we have an order id. event.data.resultCode needs to be 'Authorised'
    // this.onPaymentCompleted.emit({ data: '', orderId: '', element: '' })

    this.isPolling = true;
    this.currentStatus = 'POLLING_PAYMENT_STATUS';
    this.currentStatusPercentage = 33;
    this.shouldContinuePolling = true;

    this.adyenService.pollLinkStatus(this.paymentLink.id).pipe(
      catchError(() => of(undefined)),
      repeat({ delay: 3000 }),
      takeWhile(res => this.shouldContinuePolling && res?.status !== 'COMPLETED', true),
      filter(res => !!res && res.status === 'COMPLETED')
    ).subscribe(resultLink => {
      if (!!resultLink) {
        this.currentStatus = 'POLLING_ORDER_STATUS';
        this.currentStatusPercentage = 66;
        this.paymentLink = resultLink;
        this.adyenService.pollOrderStatus(this.paymentLink.reference).pipe(
          catchError(() => of(undefined)),
          repeat({ delay: 3000 }),
          takeWhile(res => this.shouldContinuePolling && !res, true),
          filter(res => !!res)
        ).subscribe(res => {
          if (!!res) {
            this.isPolling = false;
            this.currentStatus = 'COMPLETED';
            this.currentStatusPercentage = 100;
            setTimeout(() => {
              this.onPaymentCompleted.emit({ data: { resultCode: 'Authorised', sessionData: undefined, sessionResult: undefined }, orderId: this.paymentLink.reference, element: undefined });
            }, 500);
          }
        });
      }
    });
  }

  public cancelPaymentLink() {
    this.errorMessage = '';
    this.adyenService.cancelPaymentLink(this.paymentLink.id).pipe(first()).subscribe({
      next: res => {
        this.isPolling = false;
        this.shouldContinuePolling = false;
        this.currentStatus = 'LINK_CANCELLED'
        this.currentStatusPercentage = 100;
        if (!!res) {
          this.paymentLink = res;
        }
      },
      error: e => {
        console.error(e);
        this.errorMessage = 'COULD_NOT_CANCEL_ADYEN_PAYMENT_LINK';
      }
    });
  }
}

export interface AdyenPaymentCompletedEvent {
  data: OnPaymentCompletedData;
  orderId: string;
  element?: any;
}
