import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, of, switchMap, first, catchError } from 'rxjs';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { CartRow } from '../../state/cart/types';
import { CartService } from '../cart/cart.service';
import { CartListComponent } from '../../components/cart-list/cart-list.component';
import { BaseViewConfigService, ProductViewType } from '../base-view-config/base-view-config.service';
import { ProductAssortmentParentService } from '../product-assortment-parent/product-assortment-parent.service';

@Injectable({
  providedIn: 'root'
})
export class CartConfigService implements ConfigService<CartRow> {
  cachedPaths: { [productId: string]: string[] } = {};

  constructor(
    protected cartService: CartService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) { }

  getItems(): Observable<CartRow[]> {
    if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
      return this.cartService.getCurrentCart().pipe(
        switchMap(cart => {
          let request: Observable<any> = of(null);
          let cachedIds = Object.keys(this.cachedPaths);
          if (cart.map(c => c.productId).filter(c => !cachedIds.includes(c)).length > 0) {
            request = this.productAssortmentParentService.postProductAssortmentParentByProductIds(cart.map(c => c.productId)).pipe(first(), catchError(err => of(null)));
          }
          return forkJoin({
            cart: of(structuredClone(cart)),
            parentMap: request
          })
        }
        ),
        map(({ cart, parentMap }) => {
          for (const row of cart) {
            if (parentMap?.[row.productId]) {
              row.extra._path = parentMap[row.productId].map(p => p.id);
              row.extra._path.shift();
              row.extra._path.unshift('/articles');
              row.extra._path.push(row.productId);
              this.cachedPaths[row.productId] = row.extra._path;
            }
          }
          return cart;
        })
      );
    }
    return this.cartService.getCurrentCart();
  }

  getFilters(): ConfigBaseFilter<CartRow>[] {
    return [];
  }

  getSortOptions(): ListSortOption<CartRow>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [-1];
  }

  getLayouts(): ListLayout<CartRow>[] {
    return [
      {
        getIconClass: () => '',
        getName: () => 'Cart grid',
        getListItemComponent: () => CartListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent
      }
    ];
  }

  getSearchTerms(item: CartRow): string[] {
    return [item.productId];
  }

  getItemId(item: CartRow): string {
    return item.productId;
  }

  getSearchGroupCss() {
    return 'd-none';
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
