import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Assortment } from '../../services/assortment.service';
import { ProductListV2ConfigService } from '../../services/product-list-v2-config/product-list-v2-config.service';

@Component({
  selector: 'lib-product-list-banner',
  templateUrl: './product-list-banner.component.html',
  styleUrls: ['./product-list-banner.component.css']
})
export class ProductListBannerComponent implements OnInit, OnChanges {
  currentLang = this.translateService.currentLang || 'se';

  constructor(protected productListConfigService: ProductListV2ConfigService, protected translateService: TranslateService) {}
  public style = {};
  @Input()
  assortment: Assortment;

  public showAssortmentBannerText: boolean = this.productListConfigService.showAssortmentBannerText();

  ngOnInit(): void {}
  ngOnChanges(): void {
    this.initBannerStyle();
  }

  initBannerStyle(): any {
    this.style = this.productListConfigService.getAssortmentBannerStyle(this.assortment);
  }
}
