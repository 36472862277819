import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { Product, ProductExtended } from '../../models';
import { Availability } from '../../models/availability';
import { CustomerProductPrice } from '../../models/price';
import { AssortmentTreeImageSelectionService } from '../../services/assortment-tree-image-selection/assortment-tree-image-selection.service';
import { AuthService } from '../../services/auth/auth.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { PriceService } from '../../services/price/price.service';
import { GridViewProductCard, ProductGridViewComponent } from '../product-grid-view/product-grid-view.component';
import { GungAnonymousConfigService } from '../../services/gung-anonymous-config/gung-anonymous-config.service';

export interface GridViewProductCardExtended extends GridViewProductCard {
  product: ProductExtended;
}

@Component({
  selector: 'lib-concept-detail-product-list-view',
  templateUrl: './concept-detail-product-list-view.component.html',
  styleUrls: ['./concept-detail-product-list-view.component.css']
})
export class ConceptDetailProductListViewComponent
  extends ProductGridViewComponent
  implements ListItemRendererComponent<Product[]>
{
  public mappedData: GridViewProductCardExtended[] = [];

  selectedImageUrl: string;
  public tooltipEnabled: boolean;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected assortmentTreeImageSelectionService: AssortmentTreeImageSelectionService,
    protected router: Router,
    protected gungAnonymousConfigService: GungAnonymousConfigService
  ) {
    super(priceService, gungFlowService, authService, gungAnonymousConfigService);
  }

  public trackByFn(index: number) {
    return index;
  }

  protected subscribeToProducts() {
    super.subscribeToProducts();

    this.tooltipEnabled = this.assortmentTreeImageSelectionService.tooltipEnabled;
    this.assortmentTreeImageSelectionService.getSelectedImageUrl().subscribe(res => (this.selectedImageUrl = res));
  }

  protected mapItem(
    id: string,
    product: ProductExtended,
    price: CustomerProductPrice,
    availability: Availability
  ): GridViewProductCardExtended {
    // Product package size
    if (
      product.extra.ar &&
      product.extra.ar.q_jis_fast_pakke_strl &&
      product.extra.ar.artfsgforp &&
      Number(product.extra.ar.artfsgforp) > 1
    ) {
      product.packageSize = Number(product.extra.ar.artfsgforp);
    }
    return {
      product,
      price,
      availability
    };
  }

  goToDetail(product: Product): void {
    if (product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      this.router.navigate(['/product/', product.id]);
    }
  }
}
