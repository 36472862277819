import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin } from 'rxjs';
import { first, map } from 'rxjs';
import { endOfDay, endOfMonth, format, startOfDay, startOfMonth, startOfWeek, startOfYear, subMonths, subWeeks, subYears } from 'date-fns';
import {
  EntityAmountComparison,
  MonthlyChartSummary,
  OffersOpenExpired,
  SalesSummaryEntity,
  SalesSummaryGroup,
  ThisYearLastYearComparison,
  Timespan
} from '../types';
@Injectable({
  providedIn: 'root'
})
export class DashboardKpiService {
  protected baseUrl = 'json/dashboard';

  protected baseUrlOffers = 'json/dashboard/offers'

  constructor(protected http: HttpClient) {}

  public getActiveCustomersThisYearComparison(preConditions: any = null): Observable<EntityAmountComparison> {
    const url = `${this.baseUrl}/active-customers-this-year`;
    if (preConditions) {
      return this.http.post<EntityAmountComparison>(url, preConditions);
    } else {
      return this.http.get<EntityAmountComparison>(url);
    }
  }

  public getActiveProductsThisYearComparison(preConditions: any = null): Observable<EntityAmountComparison> {
    const url = `${this.baseUrl}/active-products-this-year`;
    if (preConditions) {
      return this.http.post<EntityAmountComparison>(url, preConditions);
    } else {
      return this.http.get<EntityAmountComparison>(url);
    }
  }

  public getCurrentMonthSalesComparison(preConditions: any = null): Observable<ThisYearLastYearComparison> {
    const url = `${this.baseUrl}/current-month-sales-comparison`;
    if (preConditions) {
      return this.http.post<ThisYearLastYearComparison>(url, preConditions);
    } else {
      return this.http.get<ThisYearLastYearComparison>(url);
    }
  }

  public getCurrentMonthSalesAmountComparison(preConditions: any = null): Observable<ThisYearLastYearComparison> {
    const url = `${this.baseUrl}/current-month-sales-amount-comparison`;
    if (preConditions) {
      return this.http.post<ThisYearLastYearComparison>(url, preConditions);
    } else {
      return this.http.get<ThisYearLastYearComparison>(url);
    }
  }

  public getSalesSummary(
    entity: SalesSummaryEntity,
    fromDate: Date,
    toDate: Date = null,
    preConditions: any = null
  ): Observable<SalesSummaryGroup[]> {
    // In case there is a final date defined
    // then retrieve the data for both dates as starting dates
    // then subtract the values for after the toDate

    if (!!toDate) {
      return forkJoin({
        olderSalesSummaryGroups: this.getSalesSummary(entity, fromDate, null, preConditions).pipe(first()),
        recentSalesSummaryGroups: this.getSalesSummary(entity, toDate, null, preConditions).pipe(first())
      }).pipe(
        first(),
        map(({ olderSalesSummaryGroups, recentSalesSummaryGroups }) => {
          // go through all olderSalesSummaryGroup and subsctrat the quantities from recentSalesSummaryGroups
          // In Case the totalQty are equal in both older and recent
          // that means that the invoice were made on-or-after the toDate (only in the period of the recent one)
          // those should not be added to the return array
          const toReturn: SalesSummaryGroup[] = [];
          const olderSalesSummaryGroupsCopy: SalesSummaryGroup[] = JSON.parse(JSON.stringify(olderSalesSummaryGroups));

          for (const olderSalesSummaryGroup of olderSalesSummaryGroupsCopy) {
            const recentSalesSummaryGroup = recentSalesSummaryGroups.find(
              summary => summary.id === olderSalesSummaryGroup.id
            );

            // in case product bought on-or-after toDate check
            //      THEN if the totalQty is different and add if diff
            // otherwise in not found in recent it means it was bought only in period fromDate until toDate
            if (!!recentSalesSummaryGroup) {
              if (recentSalesSummaryGroup.totalQuantity !== olderSalesSummaryGroup.totalQuantity) {
                olderSalesSummaryGroup.totalAmount -= recentSalesSummaryGroup.totalAmount;
                olderSalesSummaryGroup.totalQuantity -= recentSalesSummaryGroup.totalQuantity;
                toReturn.push(olderSalesSummaryGroup);
              }
            } else {
              toReturn.push(olderSalesSummaryGroup);
            }
          }
          return toReturn;
        })
      );
    }

    const url = `${this.baseUrl}/sales-summary`;
    if (preConditions) {
      return this.http.post<SalesSummaryGroup[]>(url, preConditions, {
        params: {
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          entity: SalesSummaryEntity[entity]
        }
      });
    } else {
      return this.http.get<SalesSummaryGroup[]>(url, {
        params: {
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          entity: SalesSummaryEntity[entity]
        }
      });
    }
  }

  public getOfferSummary(
    entity: SalesSummaryEntity,
    fromDate: Date,
    toDate: Date = null,
    preConditions: any = null
  ): Observable<SalesSummaryGroup[]> {
    // In case there is a final date defined
    // then retrieve the data for both dates as starting dates
    // then subtract the values for after the toDate

    if (!!toDate) {
      return forkJoin({
        olderSalesSummaryGroups: this.getOfferSummary(entity, fromDate, null, preConditions).pipe(first()),
        recentSalesSummaryGroups: this.getOfferSummary(entity, toDate, null, preConditions).pipe(first())
      }).pipe(
        first(),
        map(({ olderSalesSummaryGroups, recentSalesSummaryGroups }) => {
          // go through all olderSalesSummaryGroup and subsctrat the quantities from recentSalesSummaryGroups
          // In Case the totalQty are equal in both older and recent
          // that means that the invoice were made on-or-after the toDate (only in the period of the recent one)
          // those should not be added to the return array
          const toReturn: SalesSummaryGroup[] = [];
          const olderSalesSummaryGroupsCopy: SalesSummaryGroup[] = JSON.parse(JSON.stringify(olderSalesSummaryGroups));

          for (const olderSalesSummaryGroup of olderSalesSummaryGroupsCopy) {
            const recentSalesSummaryGroup = recentSalesSummaryGroups.find(
              summary => summary.id === olderSalesSummaryGroup.id
            );

            // in case product bought on-or-after toDate check
            //      THEN if the totalQty is different and add if diff
            // otherwise in not found in recent it means it was bought only in period fromDate until toDate
            if (!!recentSalesSummaryGroup) {
              if (recentSalesSummaryGroup.totalQuantity !== olderSalesSummaryGroup.totalQuantity) {
                olderSalesSummaryGroup.totalAmount -= recentSalesSummaryGroup.totalAmount;
                olderSalesSummaryGroup.totalQuantity -= recentSalesSummaryGroup.totalQuantity;
                toReturn.push(olderSalesSummaryGroup);
              }
            } else {
              toReturn.push(olderSalesSummaryGroup);
            }
          }
          return toReturn;
        })
      );
    }

    const url = `${this.baseUrlOffers}/sales-summary`;
    if (preConditions) {
      return this.http.post<SalesSummaryGroup[]>(url, preConditions, {
        params: {
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          entity: SalesSummaryEntity[entity]
        }
      });
    } else {
      return this.http.get<SalesSummaryGroup[]>(url, {
        params: {
          fromDate: format(fromDate, 'yyyy-MM-dd'),
          entity: SalesSummaryEntity[entity]
        }
      });
    }
  }

  getStatisticsOffersAmount(fromDate: Date): Observable<OffersOpenExpired> {
    const url = `${this.baseUrlOffers}/numbers-by-type`;
    if (fromDate) {
      return this.http.get<OffersOpenExpired>(url, {
        params: {
          fromDate: format(fromDate, 'yyyy-MM-dd')
        }
      });
    }
  }

  public getMonthlyChartSummary(preConditions: any = null): Observable<MonthlyChartSummary> {
    const url = `${this.baseUrl}/monthly-chart-comparison`;

    if (preConditions) {
      return this.http.post<MonthlyChartSummary>(url, preConditions);
    } else {
      return this.http.get<MonthlyChartSummary>(url);
    }
  }

  public getMonthlyOffersChartSummary(preConditions: any = null): Observable<MonthlyChartSummary> {
    const url = `${this.baseUrlOffers}/monthly-chart-comparison`;

    if (preConditions) {
      return this.http.post<MonthlyChartSummary>(url, preConditions);
    } else {
      return this.http.get<MonthlyChartSummary>(url);
    }
  }

  public getDateFromTimespan(timespan: Timespan): {
    fromDate: Date;
    toDate?: Date;
  } {
    // this week, the beginning of this week,
    // this month, the first day of the current month,
    // this year, the first day of the current year
    // last week, the week before the current week
    // last month, the month before the current month
    // last year, the year before the current year
    switch (timespan) {
      case Timespan.Year:
        return { fromDate: startOfYear(new Date()) };

      case Timespan.Month:
        return { fromDate: startOfMonth(new Date()) };

      case Timespan.Week:
        return { fromDate: startOfWeek(new Date()) };

      case Timespan.LastYear:
        return {
          fromDate: startOfYear(subYears(new Date(), 1)),
          toDate: startOfYear(new Date()) // day after last pretended beacuse this day will excluded (look at this getSalesSummary)
        };

      case Timespan.LastMonth:
        return {
          fromDate: startOfMonth(subMonths(new Date(), 1)),
          toDate: startOfMonth(new Date())
        };

      case Timespan.LastWeek:
        return {
          fromDate: startOfWeek(subWeeks(new Date(), 1)),
          toDate: startOfWeek(new Date())
        };
      case Timespan.Today:
        return {
          fromDate: startOfDay(new Date()),
          toDate: endOfDay(new Date())
        };
      case Timespan.TodayWeek:
        return {
          fromDate: subWeeks(new Date(), 1),
          toDate: new Date()
        };
      case Timespan.TodayLastWeek:
        return {
          fromDate: subWeeks(new Date(), 2),
          toDate: subWeeks(new Date(), 1)
        };
      case Timespan.TodayMonth:
        return {
          fromDate: subMonths(new Date(), 1),
          toDate: new Date()
        };
      case Timespan.TodayLastMonth:
        return {
          fromDate: subMonths(new Date(), 2),
          toDate: subMonths(new Date(), 1)
        };
      case Timespan.TodayYear:
        return {
          fromDate: subYears(new Date(), 1),
          toDate: new Date()
        };
      case Timespan.TodayLastYear:
        return {
          fromDate: subYears(new Date(), 2),
          toDate: subYears(new Date(), 1)
        };
      case Timespan.MonthLastYear:
        return {
          fromDate: startOfMonth(subYears(new Date(), 1)),
          toDate: endOfMonth(subYears(new Date(), 1))
        };
    }
  }

  getDashboardTotals():Observable<any>{
    const url = `${this.baseUrl}/totals`;
    return this.http.get<any>(url);
  }
}
