<table class="table">
  <thead>
    <th>Pricelist</th>
    <th translate>RRP</th>
    <th translate>WSP</th>
  </thead>
  <tbody>
    <tr *ngFor="let item of pimProduct.prices | keyvalue : keyAsNumberAsc">
      <td style="vertical-align: middle">
        {{ item.key }} <span *ngIf="pricelists.has(item.key)">- {{ pricelists.get(item.key)?.name }}</span>
      </td>
      <td>
        <lib-gung-number-input [suppressLabel]="true" [(ngModel)]="item.value.rrp"> </lib-gung-number-input>
      </td>
      <td>
        <lib-gung-number-input [suppressLabel]="true" [(ngModel)]="item.value.wsp"> </lib-gung-number-input>
      </td>
    </tr>
  </tbody>
</table>
