import { Injectable } from '@angular/core';

export interface LocationPosition {
  path: string;
  scrollX: number;
  scrollY: number;
  tab?: string;
}

export interface PreviousLayout {
  path: string;
  layoutIndex: number;
}

@Injectable({
  providedIn: 'root'
})
export class LocationConfigService {
  private locationPositions: LocationPosition[] = [];
  private previousLayout: PreviousLayout[] = [];

  // if enabled will store the locationPosition
  protected isEnabled = true;

  constructor() {}

  add(locationPosition: LocationPosition): void {
    if (!this.isEnabled) {
      return;
    }

    const index = this.locationPositions.findIndex(lp => lp.path === locationPosition.path);
    if (index > -1) {
      this.locationPositions.splice(index, 1);
    }

    this.locationPositions.push(locationPosition);
  }

  get(path: string): LocationPosition {
    return this.locationPositions.find(locationPosition => locationPosition.path === path);
  }

  remove(locationPosition: LocationPosition): void {
    const index = this.locationPositions.findIndex(lp => lp.path === locationPosition.path);
    if (index > -1) {
      this.locationPositions.splice(index, 1);
    }
  }

  addLayout(previousLayout: PreviousLayout): void {
    if (!this.isEnabled) {
      return;
    }

    const index = this.previousLayout.findIndex(lp => lp.path === previousLayout.path);
    if (index > -1) {
      this.previousLayout.splice(index, 1);
    }

    this.previousLayout.push(previousLayout);
  }

  getLayout(path: string): PreviousLayout {
    return this.previousLayout.find(previousLayout => previousLayout.path === path);
  }

  removeLayout(previousLayout: PreviousLayout): void {
    const index = this.previousLayout.findIndex(lp => previousLayout && lp.path === previousLayout.path);
    if (index > -1) {
      this.previousLayout.splice(index, 1);
    }
  }
}
