import { Component, OnInit } from '@angular/core';
import { BackendInterceptor } from '../../services/backend-interceptor/backend-interceptor.service';

@Component({
  selector: 'lib-assortment-management',
  templateUrl: './assortment-management.component.html',
  styleUrls: ['./assortment-management.component.css']
})
export class AssortmentManagementComponent implements OnInit {
  backendBaseUrl = this.backendInterceptor.getBaseUrl();

  constructor(protected backendInterceptor: BackendInterceptor) {}

  ngOnInit(): void {}
}
