import { Component, OnInit, OnChanges, OnDestroy, AfterViewInit } from '@angular/core';
import { Subscription, forkJoin, of } from 'rxjs';
import { first, map } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import { CartRow } from '../../state/cart/types';
import { Product } from '../../models/product';
import { CartRowPrice } from '../../models/price';
import { ProductService } from '../../services/products/product.service';
import { CartService } from '../../services/cart/cart.service';
import { PriceService } from '../../services/price/price.service';
import { format, parse } from 'date-fns';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Availability } from '../../models/availability';
import { AvailabilityService } from '../../services/availability/availability.service';
import { DateUtilService } from 'gung-common';

export interface CartListRow {
  cartRow: CartRow;
  product: Product;
  price: CartRowPrice;
  deliveryDate?: string;
  minDate?: NgbDate;
  availability?: Availability; 
}

@Component({
  selector: 'lib-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.css']
})
export class CartListComponent extends ListItemRendererComponent<CartRow[]> implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  protected cachedData: { [id: string]: { product: Product; price: CartRowPrice; availability: Availability } } = {};
  protected subscriptions: Subscription[] = [];

  public mappedData: CartListRow[];

  deliveryDate = new Date();

  constructor(
    protected productService: ProductService,
    protected cartService: CartService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    protected dateUtilService: DateUtilService
  ) {
    super();
  }

  ngOnInit() {
    this.updateData();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges() {
    this.subscriptions.forEach(s => s.unsubscribe());

    this.updateData();
  }

  public ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  updateData() {
    const cachedIds = Object.keys(this.cachedData);
    const toGetIds = this.data.map(c => c.productId).filter(id => !cachedIds.includes(id));

    const sub = forkJoin({
      products: this.productService.getProductsByIds(toGetIds).pipe(first()),
      prices: this.priceService.getCartRowPricesObservable(this.data).pipe(first()),
      cartRows: of(this.data),
      availabilities: this.availabilityService.getAvailabilities(toGetIds, undefined, true).pipe(
        first(),
        map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
      )
    })
      .pipe(first())
      .subscribe(({ products, prices, cartRows, availabilities }) => {
        this.cachedData = {
          ...this.cachedData,
          ...toGetIds
            .map(id => ({
              product: products.find(p => p.id === id),
              price: prices.find(p => p.productId === id),
              availability: availabilities.find(av => av.productId === id)
            }))
            .reduce((acc, curr) => ({ ...acc, [curr.product.id]: curr }), {})
        };

        const cachedProducts = Object.values(this.cachedData).map(d => d.product);
        const cachedPrices = prices;
        const cachedAvs = Object.values(this.cachedData).map(d => d.availability);
        this.mappedData = this.mapData(cachedProducts, cachedPrices, cartRows, cachedAvs);
      });

    this.subscriptions.push(sub);
  }

  public trackByFn(index: number, item: CartListRow) {
    if (!!item.product) {
      if (!!item.cartRow.productPartialId) {
        return item.product.id + '_' + item.cartRow.productPartialId;
      }
      return item.product.id;
    } else {
      return index;
    }
  }

  public removeRow(row: CartRow) {
    const index = this.mappedData.findIndex(mappedDataRow => mappedDataRow.cartRow.productId === row.productId);
    if (index > -1) {
      this.mappedData.splice(index, 1);
      this.cartService.removeRow(row);
    }
  }

  public mapData(
    products: Product[],
    prices: CartRowPrice[],
    cartRows: CartRow[],
    availabilities: Availability[]
  ): CartListRow[] {
    return cartRows.map(cr => {
      const product = products.find(p => p.id === cr.productId);
      const price = prices.find(p => p.productId === cr.productId);
      const av = availabilities.find(a => a.productId === cr.productId);
      const minDateJs: Date = this.getMinDate(av);
      let deliveryDate;
      if (cr.extra && cr.extra.orp && cr.extra.orp.ordberlevdat) {
        const setDate = new Date(cr.extra.orp.ordberlevdat);
        deliveryDate = format(setDate, 'yyyy-MM-dd');
      }
      return {
        cartRow: cr,
        product,
        price,
        minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        deliveryDate
      };
    });
  }

  getMinDate(av: Availability): Date {
    if (!av) {
      return new Date();
    }
    if (av.currentAvailability > 0) {
      return new Date();
    }
    if (av.maxFutureStock === 0) {
      return new Date();
    }
    const valids = Object.keys(av.extra.availabilities).filter(entry => av.extra.availabilities[entry] > 0);
    const dateFormat = 'yyMMdd';
    return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
  }

  dateSelected(event) {
    const date = event.date;

    const bulkExtraOps = [];
    for (const row of this.mappedData) {
      let rowDate = (row.cartRow.extra.orp && row.cartRow.extra.orp.ordberlevdat) || row.deliveryDate || row.minDate;
      if (typeof rowDate === 'string') {
        rowDate = parse(rowDate, 'yyyy-MM-dd', new Date());
      } else if (rowDate instanceof NgbDate) {
        rowDate = new Date(rowDate.year, rowDate.month - 1, rowDate.day);
      }

      if (rowDate < date) {
        const dateFortmated = format(date, 'yyyy-MM-dd');
        row.deliveryDate = dateFortmated;
        bulkExtraOps.push({
          productId: row.cartRow.productId,
          extra: {
            orp: {
              ordberlevdat: dateFortmated
            }
          },
          targetStockId: row.cartRow.targetStockId,
          productPartialId: row.cartRow.productPartialId
        });
      }
    }
    this.cartService.bulkSetExtra(bulkExtraOps);
    this.updateData();
  }

  restoreMinDate() {
    for (const row of this.mappedData) {
      const rowDate = new Date(row.minDate.year, row.minDate.month - 1, row.minDate.day);

      this.cartService.removeExtraField(row.cartRow.productId, 'orp.ordberlevdat', row.cartRow.targetStockId);
      const dateFortmated = format(rowDate, 'yyyy-MM-dd');
      row.deliveryDate = dateFortmated;
      this.cartService.setExtra(
        row.cartRow.productId,
        {
          orp: {
            ordberlevdat: dateFortmated
          }
        },
        row.cartRow.targetStockId,
        row.cartRow.productPartialId
      );
    }
    this.deliveryDate = new Date();
  }

  formatNgbDate(date: NgbDate) {
    const dObj = this.dateUtilService.createDateFromNgbDate(date);
    return format(dObj, 'yyyy-MM-dd');
  }

  updateDeliveryDate(row: CartListRow, selectedDeliveryDate) {
    const oldVal = row.deliveryDate || row.minDate;

    const deliveryDate = format(selectedDeliveryDate.date, 'yyyy-MM-dd');

    if (oldVal === deliveryDate) {
      return;
    }

    if (!deliveryDate) {
      this.cartService.removeExtraField(row.cartRow.productId, 'orp.ordberlevdat', row.cartRow.targetStockId);
      return;
    }

    this.cartService.setExtra(
      row.cartRow.productId,
      {
        orp: {
          ordberlevdat: deliveryDate
        }
      },
      row.cartRow.targetStockId,
      row.cartRow.productPartialId
    );
  }
}
