import { EdiGlnNumbers } from './../../models/edi';
import { Injectable } from '@angular/core';
import {
  ConfigService,
  ListSortOption,
  ConfigBaseFilter,
  ListLayout,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { EdiGlnNumbersTableViewComponent } from '../../components/edi/edi-gln-numbers-table-view/edi-gln-numbers-table-view.component';
import { EdiService } from '../edi/edi.service';

export class GlnFilter extends SimpleConfigBaseFilter<EdiGlnNumbers> {
  getName(): string {
    return 'GLN';
  }

  getOptionIds(item: EdiGlnNumbers): string[] {
    return [item.gln + ''];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class CompanyIdFilter extends SimpleConfigBaseFilter<EdiGlnNumbers> {
  getName(): string {
    return 'COMPANY_ID';
  }

  getOptionIds(item: EdiGlnNumbers): string[] {
    return [item.companyId];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class B24NameFilter extends SimpleConfigBaseFilter<EdiGlnNumbers> {
  getName(): string {
    return 'B24_NAME';
  }

  getOptionIds(item: EdiGlnNumbers): string[] {
    return [item.b24.name];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class B24IntegrationsFilter extends SimpleConfigBaseFilter<EdiGlnNumbers> {
  getName(): string {
    return 'B24_INTEGRATIONS';
  }

  getOptionIds(item: EdiGlnNumbers): string[] {
    const result = [];
    if (!item.b24) {
      result.push('UNDEFINED');
      return result;
    }
    if (item.b24.invoices) {
      result.push('INVOICES');
    }
    if (item.b24.pricats) {
      result.push('PRICATS');
    }
    if (item.b24.orders) {
      result.push('ORDERS');
    }
    // if (item.b24.orderRespones) { result.push('ITEM_B24_ORDERRESPONSES'); }
    if (item.b24.despatchAdvices) {
      result.push('DESPATCHADVICES');
    }

    return result;
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EdiGlnNumbersListConfigService implements ConfigService<EdiGlnNumbers> {
  constructor(protected ediService: EdiService) {}

  getSortOptions(): ListSortOption<EdiGlnNumbers>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: EdiGlnNumbers) {
    return item.id;
  }

  getSearchTerms(item: EdiGlnNumbers) {
    return [item.gln + '', item.glnBuyer + '', item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getFilters(): ConfigBaseFilter<EdiGlnNumbers>[] {
    return [new GlnFilter(), new CompanyIdFilter(), new B24NameFilter(), new B24IntegrationsFilter()];
  }

  getLayouts(): ListLayout<EdiGlnNumbers>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => EdiGlnNumbersTableViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<EdiGlnNumbers[]> {
    return this.ediService.getGlnNumbers();
  }
}
