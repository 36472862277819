import { Injectable } from '@angular/core';
import { of, Observable } from 'rxjs';
import { Pim } from '../../models/pim';

@Injectable({
  providedIn: 'root'
})
export class PimProductImagesConfigService {
  public showInSegments = false;
  public imageSegments = ['showInB2B', 'showInB2C'];

  constructor() {}

  /*
    Default configuration
  */
  public getExtraOptions(pimProduct: Pim): Observable<{ [key: string]: string[] }> {
    return of({});
  }
  public getCategoryOptions(pimProduct: Pim): Observable<string[]> {
    return of(['product', 'weblook', 'sketch', 'fabric']);
  }
}
