import { ChangeDetectorRef, Component, ComponentFactoryResolver, OnInit } from '@angular/core';
import { FastsearchLayoutComponent } from '../fastsearch-layout/fastsearch-layout.component';

@Component({
  selector: 'lib-inline-search-layout',
  templateUrl: './inline-search-layout.component.html',
  styleUrls: ['./inline-search-layout.component.css']
})
export class InlineSearchLayoutComponent<T> extends FastsearchLayoutComponent<T> {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    const children = this.viewChildren.toArray();
    if (!this.items || this.items.length === 0) {
      return;
    }
    for (let i = 0; i < this.items.length; i++) {
      this.renderListComponent(this.items[i], children[i].viewContainerRef);
    }
    this.changeDetectorRef.detectChanges();
  }
}
