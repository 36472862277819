<div class="row" *ngIf="inactiveUserNotificationConfiguration">
  <div class="col">
    <div class="d-flex justify-content-between align-items-baseline">
      <div>
        <h5 class="mb-0" translate>INACTIVE_USERS_NOTIFICATION</h5>
        <span class="font-italic small mb-2 d-inline-block" translate>INACTIVE_USERS_NOTIFICATION_DESCRIPTION</span>
      </div>


      <button
        class="btn btn-default"
        style="font-size: 20px;"
        (click)="save()"
      >
        <i ngbTooltip="{{'SAVE' | translate}}" class="fas fa-save"></i>
      </button>
    </div>

    <div class="row">
      <div class="col-sm-12 col-md-6 col-lg-3">
        <lib-list-input
          gungTranslate="EMAIL_RECIPIENTS"
          placeholder="EMAIL"
          [input]="inactiveUserNotificationConfiguration.notificationRecipients"
          (inputUpdated)="inactiveUserNotificationConfiguration.notificationRecipients = $event"
        ></lib-list-input>
      </div>
      <div class="col-sm-12 col-md-6 col-lg-3">
          <lib-gung-number-input
            gungTranslate="INACTIVE_DAYS_THRESHOLD"
            [(ngModel)]="inactiveUserNotificationConfiguration.inactiveDaysThreshold"
            [minValue]="0"
          ></lib-gung-number-input>
      </div>
    </div>

  </div>
</div>
