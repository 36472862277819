<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>SELECT_DATES</h4>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-6">
      <label for="fromDate" class="control-label" translate>FROM_DATE</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy/MM/dd" name="d1" [(ngModel)]="fromDate" ngbDatepicker
          [maxDate]="toDate" #d1="ngbDatepicker" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d1.toggle()" type="button">
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="col-6">
      <label for="toDate" class="control-label" translate>TO_DATE</label>
      <div class="input-group">
        <input class="form-control" placeholder="yyyy/MM/dd" name="d2" [(ngModel)]="toDate" ngbDatepicker
          [maxDate]="maxDate" [minDate]="fromDate" #d2="ngbDatepicker" />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="d2.toggle()" type="button">
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>
  </div>

</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-primary btn-default" (click)="confirm()" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>