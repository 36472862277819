import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { DiscountCodeV2 } from '../../../../services/discount-codes-v2/discount-codes-v2.service';

@Component({
  selector: 'lib-assign-discount-code',
  template: '',
  styles: []
})
export class AssignDiscountCodeComponent extends CheckoutStepComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      if (this.checkout.extra._discountCode) {
        this.handleDiscountCode(this.checkout.extra._discountCode);
      }
      this.stepDone.emit(this.checkout);
    });
  }

  protected handleDiscountCode(discountCode: DiscountCodeV2) {
    // console.log('discountCode', discountCode);
    for (const row of this.checkout.rows) {
      // console.log('row', row);
    }
  }

  public isVisible() {
    return false;
  }
}
