import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LinesheetService {
  constructor(protected http: HttpClient) {}

  public linesheetsExcelToJson(formData: FormData) {
    const url = `import/linesheets/excel-to-json`;
    return this.http.post<any>(url, formData);
  }

  public cartsMatchProducts(assortment: string, flow: string, formData: FormData) {
    const url = `json/carts/match-products?eanSearchAssortment=${assortment}&flowId=${flow}`;
    return this.http.post<any>(url, formData);
  }

  public priceListOptions() {
    const url = `json/pricelist/options`;
    return this.http.get<any>(url);
  }

  public priceModelOptions() {
    const url = `json/pricemodel/options`;
    return this.http.get<any>(url);
  }

  public exportLinesheetPDF(formData: FormData) {
    const url = `export/linesheet/pdf`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf'
      }),
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(url, formData, httpOptions);
  }

  public exportLinesheetCustomerPDF(formData: FormData) {
    const url = `export/linesheet-customer/pdf`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/pdf'
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, formData, httpOptions);
  }

  public exportLineSheetExcel(formData: FormData) {
    const url = `export/linesheet/excel`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.post<any>(url, formData);
  }

  public getDefaultCoverXLSM() {
    const url = `/templates/excel/default-cover.xlsm`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as 'json'
    };
    return this.http.get<any>(url);
  }
}
