import { AuthState } from './types';
import { Action } from '@ngrx/store';
import { ActionTypes, UserReceived } from './actions';

export const initialState: AuthState = {
  authenticated: false,
  user: null,
  badLogin: false,
  internalServerError: false,
  userDisabled: false
};

export function reducers(state: AuthState = initialState, action: Action): AuthState {
  switch (action.type) {
    case ActionTypes.UserReceived: {
      const { payload } = action as UserReceived;
      return {
        ...state,
        authenticated: payload.roles.length > 0,
        user: payload,
        badLogin: false
      };
    }
    case ActionTypes.BadLogin: {
      return {
        ...state,
        badLogin: true
      };
    }
    case ActionTypes.UserDisabled: {
      return {
        ...state,
        badLogin: true,
        userDisabled: true
      };
    }
    case ActionTypes.InternalServerError: {
      return {
        ...state,
        badLogin: true,
        internalServerError: true
      };
    }
    case ActionTypes.BackendDown: {
      return {
        ...state,
        badLogin: true
      };
    }
    case ActionTypes.UserNotReceived: {
      return {
        ...state,
        authenticated: false,
        user: null,
        badLogin: false
      };
    }
    default: {
      return state;
    }
  }
}
