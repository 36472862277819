import { Component, Input, OnInit, Optional } from '@angular/core';
import { CommonModalService } from '../../../services/common-modal/common-modal.service';

@Component({
  selector: 'lib-note-modal',
  templateUrl: './note-modal.component.html',
  styleUrls: ['./note-modal.component.css']
})
export class NoteModalComponent implements OnInit {
  note: string;
  delegate: CommonModalService;
  componentId: string;

  @Optional()
  @Input()
  maxLength: number

  constructor() {}

  ngOnInit() {
    this.componentId = NoteModalComponent.name;
  }

  close() {
    this.delegate.modalRef.get(this.componentId).close();
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close({ note: this.note });
  }

}
