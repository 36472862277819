import { Component, EventEmitter, Input, OnChanges, OnInit, Optional, Output, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { ActionButton } from '../../models/action-button/action-button';
import { ActionButtonConfigService } from '../../services/action-button-config/action-button-config.service';

@Component({
  selector: 'lib-action-btn',
  templateUrl: './action-btn.component.html',
  styleUrls: ['./action-btn.component.css']
})
export class ActionBtnComponent<T> implements OnInit, OnChanges {
  buttons: Observable<ActionButton<T>[]>;
  individualButtons: Observable<ActionButton<T>[]>;

  @Input()
  item: T;

  @Output() itemChange = new EventEmitter<T>();

  @Input()
  config: ActionButtonConfigService<T>;

  @Input() blockBtns = false;

  @Input()
  title: string = 'ACTIONS';

  @Input()
  placement: string = 'bottom-right';

  @Input()
  btnClass: string;

  ngOnChanges(changes: SimpleChanges) {
    this.ngOnInit();
    // You can also use categoryId.previousValue and
    // categoryId.firstChange for comparing old and new values
  }
  ngOnInit() {
    this.buttons = this.config.getButtons(this.item, this.itemChange).pipe(
      map(buttons => {
        return buttons.filter((button, idx, array) => !button.displayAsButton && array.length !== 1);
      })
    );
    this.individualButtons = this.config.getButtons(this.item, this.itemChange).pipe(
      map(buttons => {
        return buttons.filter((button, idx, array) => button.displayAsButton || array.length === 1);
      })
    );
  }
}
