import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EdiIncomingDespatchAdvicesActionConfigService } from '../../../services/edi-incoming-despatch-advices-action-config/edi-incoming-despatch-advices-action-config.service';

@Component({
  selector: 'lib-edi-incoming-despatch-card-list',
  templateUrl: './edi-incoming-despatch-card-list.component.html',
  styleUrls: ['./edi-incoming-despatch-card-list.component.css']
})
export class EdiIncomingDespatchCardListComponent extends ListItemRendererComponent<any[]> implements OnInit, AfterViewInit {
  constructor(public actionConfigService: EdiIncomingDespatchAdvicesActionConfigService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }
}
