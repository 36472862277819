<div class="row" *ngIf="purchaseOrder">
  <div class="col-12 d-flex">
    <h1><span translate>PURCHASE_ORDER</span> {{ purchaseOrder.id }}</h1>
    <lib-action-btn [item]="purchaseOrder" [config]="configService" class="mb-3 ml-auto mt-auto"></lib-action-btn>
  </div>
</div>
<div class="row" *ngIf="purchaseOrder">
  <div class="col-12">
    <lib-gung-accordion>
      <lib-gung-accordion-item [collapsed]="false">
        <ng-template libGungAccordionHeader>{{ 'SUPPLIER_ADDRESS' | translate }}</ng-template>
        <ng-template libGungAccordionBody>
          <div class="row">
            <div class="col-12 col-md-6">
              <lib-gung-text-input-horizontal
                renderStatic="true"
                gungTranslate="NAME"
                [(ngModel)]="purchaseOrder.extra.deliveryCustomer?.extra.LA.NAM"
              >
              </lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                renderStatic="true"
                gungTranslate="ADDRESS1"
                [(ngModel)]="purchaseOrder.extra.deliveryCustomer?.extra.LA.AD1"
              >
              </lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                renderStatic="true"
                gungTranslate="ADDRESS2"
                [(ngModel)]="purchaseOrder.extra.deliveryCustomer?.extra.LA.AD2"
              >
              </lib-gung-text-input-horizontal>
            </div>
            <div class="col-12 col-md-6">
              <lib-gung-text-input-horizontal
                renderStatic="true"
                gungTranslate="CITY"
                [(ngModel)]="purchaseOrder.extra.deliveryCustomer?.extra.LA.ORT"
              >
              </lib-gung-text-input-horizontal>
              <lib-gung-text-input-horizontal
                renderStatic="true"
                gungTranslate="COUNTRY"
                [(ngModel)]="purchaseOrder.extra.deliveryCustomer?.extra.LA.LND"
              >
              </lib-gung-text-input-horizontal>
            </div>
          </div>
        </ng-template>
      </lib-gung-accordion-item>        
    </lib-gung-accordion>
  </div>
</div>
<div class="row">
  <div class="col-12 table-responsive gung-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>Line</th>
          <th translate>Product</th>
          <th translate>QUANTITY</th>
          <th translate>UNIT_PRICE</th>
          <th translate>TOTAL_PRICE</th>
          <th translate>DELIVERY_DATE</th>
        </tr>
      </thead>
      <tbody *ngIf="purchaseOrder">
        <tr *ngFor="let row of purchaseOrder.rows">
          <td [attr.data-label]="'PRODUCT_ID' | translate">
            {{ row.rownr }}
          </td>
          <td [attr.data-label]="'NAME' | translate">
            <!-- GET NAME HERE IF NOT EXIST ON ARTBESKR -->
            <a [routerLink]="['/product/', row.productId]">{{ row.extra.IGR.BEN }}{{ row.extra.IGR.BNX }}</a
            ><br />
            <a [routerLink]="['/product/', row.productId]">{{ row.productId }}</a>

            <div class="row" *ngIf="row.extra._gemoOrderRelations">
              <div class="col-12" *ngFor="let lineComment of row.extra._gemoOrderRelations">
                <a (click)="navigateToOrder(lineComment)">{{ lineComment }}</a>
              </div>
            </div>
          </td>
          <td [attr.data-label]="'QUANTITY' | translate">
            {{ row.quantity | number: '1.0-0' }}
          </td>
          <td [attr.data-label]="'UNIT_PRICE' | translate">
            {{ row.extra.IGR.PRI | number: '1.2-2' }}
          </td>
          <td [attr.data-label]="'TOTAL_PRICE' | translate">
            {{ row.quantity * row.extra.IGR.PRI | number: '1.2-2' }}
          </td>
          <td [attr.data-label]="'TOTAL_PRICE' | translate">
            {{ row.extra.IGR.LDT }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
