<div class="pt-2">
  <div class="row form-group" *ngFor="let setting of settings; trackBy: trackByFn">
    <div class="col-1 pt-3">
      <label class="d-flex align-items-center">
        <input
          type="radio" name="{{setting.label}}_key" id="{{setting.label}}_$in" value="_$in"
          [attr.checked]="setting.entry.key == '_$in' ? 'checked' : undefined"
          (change)="keyChange($event.target.value, setting)"
        />
        <span translate>INCLUDE</span>
      </label>
      <label class="d-flex align-items-center">
        <input
          type="radio" name="{{setting.label}}_key" id="{{setting.label}}_$nin" value="_$nin"
          [attr.checked]="setting.entry.key == '_$nin' ? 'checked' : undefined"
          (change)="keyChange($event.target.value, setting)"
        />
        <span translate>EXCLUDE</span>
      </label>
    </div>
    <div class="col-11">
      <label for="{{ setting.label }}" class="control-label" translate>{{ setting.label }}</label>
      <input
        class="form-control"
        type="text"
        name="{{ setting.label }}"
        id="{{ setting.label }}"
        [value]="setting.entry.value"
        (blur)="setValue($event.target.value, setting)"
      />
    </div>
  </div>
</div>
