import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UploadProgressService } from '../../services/upload-progress/upload-progress.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-progress-bar',
  templateUrl: './progress-bar.component.html',
  styleUrl: './progress-bar.component.scss'
})
export class ProgressBarComponent implements OnInit, OnDestroy {
  private unsubscribe: Subject<void> = new Subject();

  @Input()
  progress: number;

  @Input()
  showPercentage: boolean = true;

  @Input()
  uploadFileName: string;

  @Input()
  unsubscribeProgress: boolean

  constructor(
    protected uploadProgressService: UploadProgressService
  ) {

  }

  ngOnInit(): void {
    if (this.uploadFileName) {
      this.uploadProgressService.getProgress(this.uploadFileName).pipe(takeUntil(this.unsubscribe)).subscribe(progress => {
        this.progress = progress;
        if (this.unsubscribeProgress && progress >= 100) {
          this.uploadProgressService.removeProgress(this.uploadFileName);
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    if (this.uploadFileName) {
      this.uploadProgressService.removeProgress(this.uploadFileName);
    }
  }
}
