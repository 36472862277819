<div class="alert alert-info mt-1" role="alert">
  <h6>{{ 'TIP' | translate }}</h6>
  <ul>
    <li>{{ 'IMPORT_USERS_FILE_TYPE' | translate }}</li>
    <li>{{ 'IMPORT_USERS_FILE_NAME' | translate }}</li>
    <li>
      <a class="btn btn-outline-primary" target="_blank" href="{{ backendBaseUrl }}import/users-template">{{
        'DOWNLOAD_TEMPLATE' | translate
      }}</a>
    </li>
  </ul>
</div>

<div class="form-group">
  <form [formGroup]="uploadForm">
    <div>
      <input
        type="file"
        accept=".xls, .xlsx"
        name="importUsersDocument"
        [placeholder]="'SELECT' | translate"
        (change)="onFileSelect($event)"
      />
    </div>
  </form>
</div>

<div class="row pb-4">
  <div class="col-12">
    <div class="load text-center" *ngIf="loading">
      <span>IMPORTING_DATA</span><br />
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </div>
    <button type="button" *ngIf="!loading" class="btn btn-primary btn-block" (click)="onSubmit()">
      {{ 'IMPORT' | translate }}
    </button>
  </div>
</div>

<div class="alert alert-success mt-1" role="alert" *ngIf="success">
  <h5>{{ 'SUCCESS' | translate }}</h5>
  <h6>{{ 'NUMBER_OF_USERS_CREATED' | translate: { resultCount: success['created'].length } }}</h6>
  <h6>{{ 'NUMBER_OF_USERS_UPDATED' | translate: { resultCount: success['updated'].length } }}</h6>
</div>

<div class="alert alert-danger mt-1" role="alert" *ngIf="error">
  <h5>{{ 'ERROR' | translate }}</h5>
  <h6>{{ error.message }}</h6>
</div>
