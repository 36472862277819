import { Component, Input, OnInit } from '@angular/core';
import { Product } from '../../../models';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsBookmark } from '../../../models/products-bookmark';

@Component({
  selector: 'lib-products-bookmark-management-modal',
  templateUrl: './products-bookmark-management-modal.component.html',
  styleUrls: ['./products-bookmark-management-modal.component.css']
})
export class ProductsBookmarkManagementModalComponent implements OnInit {
  @Input()
  selectedProducts: Product[];

  bookmarks: ProductsBookmark[] = [];

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
