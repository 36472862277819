<div class="row">
  <div class="col-12 text-center">
    <h3>{{ 'UPLOAD_EDI_ORDER_XML' | translate | uppercase }}</h3>
  </div>
  <div class="col-12">
    <span>Use this to import an xml file from B24.</span>
  </div>
</div>
<div class="row">
  <div class="col-md-6 text-md-right">
    <label for="file-upload" translate>SELECT_FILES</label>
  </div>
  <div class="col-md-6">
    <input
      #inputFile
      type="file"
      class="_custom-file-input"
      id="file-upload"
      aria-describedby="file-upload"
      [multiple]="false"
      [disabled]="loading ? true : null"
    />
    <!-- (change)="onFileSelect($event)" -->
  </div>
</div>
<div class="row">
  <div class="col-12 text-right">
    <button
      type="button"
      class="btn btn-primary px-5"
      (click)="uploadFile(inputFile)"
      [disabled]="loading ? true : null"
      translate
    >
      UPLOAD <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
    </button>
  </div>
</div>
