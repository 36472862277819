import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface AvailabilityOrderRowCondition {
  active: boolean;
  expressionString: string;
  id: string;
  name: string;
  params: string[];
}

@Injectable({
  providedIn: 'root'
})
export class AvailabilityConditionsService {
  constructor(protected http: HttpClient) {}

  getAllConditions(): Observable<AvailabilityOrderRowCondition[]> {
    const url = 'json/availability-order-row-conditions';
    const headers: { [s: string]: string | string[] } = {
      maxAge: '-1'
    };

    return this.http.get<AvailabilityOrderRowCondition[]>(url, headers);
  }

  saveCondition(condition: AvailabilityOrderRowCondition): Observable<AvailabilityOrderRowCondition> {
    return this.http.put<any>('json/availability-order-row-conditions', condition);
  }

  deleteCondition(conditionId: string): Observable<any> {
    const url = 'json/availability-order-row-conditions/' + conditionId;
    return this.http.delete<any>(url);
  }
}
