<div class="edi-invoice" *ngIf="orderResponseData">
  <div class="invoice-header">
    <div class="header-title">
      <h3>
        <span translate="ORDER_RESPONSE"></span>: {{ orderResponseData.id }}
        <span style="float: right"><lib-edi-send-status [message]="orderResponseData"></lib-edi-send-status></span>
      </h3>
    </div>
    <div class="row">
      <div class="col">
        <lib-action-btn class="float-right mb-2" [(item)]="orderResponseData" [config]="actionConfig"></lib-action-btn>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-7">
        <div class="row">
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="id" translate>SUPPLIER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="id"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences.supplierOrder.id"
                [disabled]="configService.disable.customerNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="buyer-order-number" translate>BUYER_ORDER_NUMBER</label>
              <input
                class="form-control"
                name="buyer-order-number"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences?.buyerOrder.id"
                [disabled]="configService.disable.supplierOrderNumber"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="order-date" translate>ORDER_DATE</label>
              <input
                class="form-control"
                name="order-date"
                type="text"
                [(ngModel)]="orderResponseData?.documentReferences.supplierOrder.date"
                [disabled]="configService.disable.orderDate"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="order-type" translate>ORDER_TYPE</label>
              <input
                class="form-control"
                name="order-type"
                type="text"
                [(ngModel)]="orderResponseData.orderType"
                [disabled]="configService.disable.orderType"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>DELIVERY_DATE</label>
              <input
                class="form-control"
                name="delivery-date"
                type="text"
                [(ngModel)]="orderResponseData.deliveryDate"
                [disabled]="configService.disable.deliveryDate"
              />
            </div>
          </div>
          <div class="col-md-4" *ngIf="!!orderResponseData.timestamp">
            <div class="form-group">
              <label class="control-label" for="delivery-date" translate>TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [(ngModel)]="orderResponseData.timestamp"
                [disabled]="true"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="order-status" translate>GUNG_EDI_MESSAGE_SEND_STATUS</label>
              <input
                class="form-control"
                name="order-status"
                type="text"
                [(ngModel)]="orderResponseData.gungEdiMessageSendStatus"
                [disabled]="configService.disable.orderStatus"
              />
            </div>
          </div>
          <div class="col-md-4">
            <div class="form-group">
              <label class="control-label" for="" translate>DELIVERED_TIMESTAMP</label>
              <input
                class="form-control"
                name="timestamp"
                type="text"
                [ngModel]="orderResponseData?.sentTimestamp ? (orderResponseData.sentTimestamp | date : dateUtilService.dateFormat) : ''"
                [disabled]="true"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5">
        <lib-edi-gln-number-tabs [ediMessage]="orderResponseData"></lib-edi-gln-number-tabs>
      </div>
    </div>
  </div>
  <lib-edi-detail-error-display [ediMessage]="orderResponseData"></lib-edi-detail-error-display>
  <div class="invoice-itens">
    <div class="header-title" translate>ORDER_LINES</div>
    <!-- <div class="row">
            <div class="col-12 table-responsive gung-responsive">
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th translate>ITEMID</th>
                            <th translate>QUANTITY</th>
                            <th translate>ORDERED_QUANTITY</th>
                            <th translate>UNIT_NET_PRICE</th>
                            <th translate>UNIT_GROSS_PRICE</th>
                            <th translate>DELIVERY_DATE</th>
                            <th translate>TOTAL_NET_PRICE</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of orderResponseData.items">
                            <td [attr.data-label]="'ITEMID' | translate">
                                {{item.ids.gungId}}
                            </td>
                            <td [attr.data-label]="'QUANTITY' | translate">
                                {{item.quantities?.actualQuantity}}
                            </td>
                            <td [attr.data-label]="'ORDERED_QUANTITY' | translate">
                                {{item.quantities?.expectedQuantity}}
                            </td>
                            <td [attr.data-label]="'UNIT_NET_PRICE' | translate">
                                {{item.prices?.actualUnitNetPrice?.value}} {{item.prices?.actualUnitNetPrice?.currency}}
                            </td>
                            <td [attr.data-label]="'UNIT_GROSS_PRICE' | translate">
                                {{item.prices?.actualUnitGrossPrice?.value}}
                                {{item.prices?.actualUnitGrossPrice?.currency}}
                            </td>
                            <td [attr.data-label]="'DELIVERY_DATE' | translate">
                                {{item.documentReferences.supplierOrder?.date}}
                            </td>
                            <td [attr.data-label]="'TOTAL_NET_PRICE' | translate">
                                {{item.prices?.actualTotalNetPrice?.value}}
                                {{item.prices?.actualTotalNetPrice?.currency}}
                            </td>

                        </tr>
                    </tbody>
                </table>
            </div>
        </div> -->
    <!-- NEW TABLE COMPNENT -->
    <lib-edi-row-table [data]="orderResponseData.items" [columns]="itemsColumns"></lib-edi-row-table>
  </div>
  <div class="order-response-errors">
    <div class="header-title" translate>ERROR</div>
    <lib-edi-error-messages [errors]="[]"></lib-edi-error-messages>
  </div>
</div>
