import { Component, OnInit, Input } from '@angular/core';
import { CartRow } from '../../state/cart/types';
import { PriceService } from '../../services/price/price.service';
import { CustomerProductPrice } from '../../models/price';
import { ProductService } from '../../services/products/product.service';
import { Product } from '../../models';
import { forkJoin } from 'rxjs';
import { match } from 'minimatch';
import { first } from 'rxjs';

@Component({
  selector: 'lib-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  @Input()
  receipt: CartRow[];

  rows: {
    id: string;
    name: string;
    price: number;
    quantity: number;
    currency: string;
  }[] = [];
  grandTotal = 0;

  currencyCode: string;

  constructor(protected priceService: PriceService, protected productService: ProductService) {}

  initData() {
    const ids = this.receipt ? this.receipt.map(x => x.productId) : [];
    if (ids.length === 0) {
      return;
    }

    const cartQtys = this.receipt.reduce((prev, curr) => ({ ...prev, [curr.productId]: curr.qty }), {});
    forkJoin([
      this.priceService.getCurrentCustomerPrices(ids).pipe(first()),
      this.productService.getProductsByIds(ids).pipe(first())
    ]).subscribe(d => {
      const prices = d[0];
      const products = d[1];

      this.grandTotal = prices
        .map(p => p.customerNetPrice.value * cartQtys[p.productId])
        .reduce((sum, curr) => sum + curr, 0);
      this.currencyCode = prices[0].customerNetPrice.currencyCode;
      this.rows = this.receipt.map(row => {
        const [product] = products.filter(p => p.id === row.productId);
        const [price] = prices.filter(p => p.productId === row.productId);
        if (!product || !price) {
          throw new Error('Product or price was not found for row ' + row.productId);
        }
        return {
          id: row.productId,
          name: product.name,
          price: price.customerNetPrice.value,
          quantity: row.qty,
          currency: price.customerNetPrice.currencyCode
        };
      });
    });
  }

  ngOnInit() {
    this.initData();
  }
}
