import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PimDimensionsListConfigService } from '../../../services/pim-dimensions-list-config/pim-dimensions-list-config.service';
import { PimDimensionCreateModalComponent } from '../pim-dimension-create-modal/pim-dimension-create-modal.component';

@Component({
  selector: 'lib-pim-dimensions-wrapper',
  templateUrl: './pim-dimensions-wrapper.component.html',
  styleUrls: ['./pim-dimensions-wrapper.component.css']
})
export class PimDimensionsWrapperComponent {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  constructor(public pimDimensionListConfigService: PimDimensionsListConfigService, protected ngbModal: NgbModal) {}

  createDimension() {
    const id = PimDimensionCreateModalComponent.name;
    const ref = this.ngbModal.open(PimDimensionCreateModalComponent, {});
    ref.result.then(
      result => {},
      reason => {}
    );

    this.modalRef.set(id, ref);
  }
}
