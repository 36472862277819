import { RemoveExtraPayload } from '../actions';
import { CartRow } from '../types';
import { filterOutRow, getRowFromRows } from './common';

const isObj = (data: any) => typeof data === 'object' && data !== null;

const withoutDotField = (val: { [s: string]: any }, remaining: string[]): { [s: string]: any } => {
  const [firstField, ...others] = remaining;
  if (others.length > 0) {
    const fieldVal = val[firstField];
    if (!isObj(fieldVal)) {
      // early return if subfield is not an object, this is a silent error handle
      return val;
    }
    // recurse down through the fields
    const withoutField = withoutDotField(fieldVal, others);
    if (Object.keys(withoutField).length === 0) {
      // subfield is empty, return without it
      const { [firstField]: __, ...toReturn } = val;
      return toReturn;
    }
    return {
      ...val,
      [firstField]: withoutField
    };
  }
  // this is the last iteration, pluck the field and return val without field
  const { [firstField]: _, ...rest } = val;
  return rest;
};

const getExtraWithoutField = (row: CartRow, dotSeparatedField: string): { [s: string]: any } =>
  row.extra ? withoutDotField(row.extra, dotSeparatedField.split('.')) : {};

export const removeExtraReducer = (cartRows: CartRow[], payload: RemoveExtraPayload): CartRow[] => {
  const currentDate = new Date();
  const oldRow = getRowFromRows(cartRows, payload.productId, payload.targetStockId, payload.productPartialId);
  const newRow = {
    ...oldRow,
    extra: getExtraWithoutField(oldRow, payload.value),
    timeUpdated: currentDate
  };
  const d = [
    ...cartRows.filter(r => filterOutRow(r, payload.productId, payload.targetStockId, payload.productPartialId)),
    newRow
  ];
  return d;
};
