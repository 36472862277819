import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, OnDestroy } from '@angular/core';
import { RenderFilter } from '../types';
import { Observable, observable, Subject } from 'rxjs';
import { debounceTime, first } from 'rxjs';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';

@Component({
  selector: 'lib-filter-list-side-filter',
  templateUrl: './filter-list-side-filter.component.html',
  styleUrls: ['./filter-list-side-filter.component.scss']
})
export class FilterListSideFilterComponent implements OnInit, OnChanges {
  @Input()
  public renderFilter: RenderFilter;

  @Output()
  selectValue = new EventEmitter<string>();

  @Output()
  deselectValue = new EventEmitter<string>();

  @Output()
  selectValueRange = new EventEmitter<string[]>();

  @Output()
  public selectSubValue = new EventEmitter<{option: string, subOption: string}>();

  @Output()
  public deselectSubValue = new EventEmitter<{option: string, subOption: string}>();

  @Input()
  class?: string;

  public hasSelection: boolean;
  public amountSelected: number;

  public minValue: string;
  public maxValue: string;

  renderFilterValueList: any;
  searchString: string;

  public maxRange: { max: number; min: number };

  ngOnInit(): void {
    if (this.renderFilter.type === 'RangeConfigBaseFilter') {
      const selectedRangeFilter = this.renderFilter.valueList.filter(v => v.selected && !isNaN(parseFloat(v.valueId)));
      if (selectedRangeFilter.length > 0) {
        this.minValue = Math.min(...selectedRangeFilter.map(v => parseFloat(v.valueId))).toString();
        this.maxValue = Math.max(...selectedRangeFilter.map(v => parseFloat(v.valueId))).toString();
      }
      // Get maxRange of all values
      const min = Math.floor(
        Math.min(
          ...this.renderFilter.valueList
            .filter(v => v.valueId && v.valueId.trim() !== '' && !isNaN(v.valueId as any))
            .map(v => parseFloat(v.valueId))
        )
      );
      const max = Math.ceil(
        Math.max(
          ...this.renderFilter.valueList
            .filter(v => v.valueId && v.valueId.trim() !== '' && !isNaN(v.valueId as any))
            .map(v => parseFloat(v.valueId))
        )
      );
      this.maxRange = { max, min };
    }

    this.renderFilterValueList = this.renderFilter.valueList;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.renderFilter.type === 'RangeConfigBaseFilter') {
      return;
    }
    this.amountSelected = 0;
    this.renderFilter.valueList.forEach(val => {
      if (val.selected) {
        this.amountSelected++;
      }
    });

    this.hasSelection = this.amountSelected > 0;
  }

  updateHasSelection() {
    this.hasSelection = this.renderFilter.valueList.findIndex(option => option.selected) > -1;
  }

  changeRange(newMinValue = this.minValue, newMaxValue = this.maxValue) {
    if (
      this.renderFilter.type !== 'RangeConfigBaseFilter' ||
      (!newMinValue && !newMaxValue) ||
      (newMinValue && newMinValue.trim().localeCompare('') !== 0 && isNaN(parseFloat(newMinValue))) ||
      (newMaxValue && newMaxValue.trim().localeCompare('') !== 0 && isNaN(parseFloat(newMaxValue)))
    ) {
      return;
    }
    const minValue = newMinValue;
    const maxValue = newMaxValue;
    this.selectValueRange.emit([minValue, maxValue]);
  }

  setSearch(search: string): void {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.renderFilterValueList = this.renderFilter.valueList;
      return;
    }
    this.renderFilterValueList = this.filterBySearchTerm(search, this.renderFilter.valueList);
  }

  filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = [item.valueName];
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }

  selectedRangeChanged(event) {
    this.changeRange(event.min + '', event.max + '');
  }

  setRangeValues(event) {
    if ((event[0], event[1])) {
      this.selectValueRange.emit([event[0], event[1]]);
    }
  }
}
