import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject, ReplaySubject, Subject } from 'rxjs';
import { delay, tap, map, switchMap } from 'rxjs';
import { CustomerContact } from '../../models/customerContact';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactService {
  private contactsSubjects: { [customerId: string]: Subject<CustomerContact[]> } = {};

  constructor(protected http: HttpClient) {}

  /**
   * Gets all contacts from every customer.
   */
  getAllContacts(): Observable<CustomerContact[]> {
    const url = `json/customer-contacts`;
    return this.http.get<CustomerContact[]>(url);
  }

  /**
   * Gets all contacts for a specific customer.
   * @param customerId - The customer which contacts we want to get.
   */
  getCustomerContacts(customerId: string): Observable<CustomerContact[]> {
    if (!this.contactsSubjects[customerId]) {
      this.contactsSubjects[customerId] = new ReplaySubject<CustomerContact[]>();
      this.updateCustomerContactsSubject(customerId);
    }
    return this.contactsSubjects[customerId].asObservable();
  }

  /**
   *  Gets all contacts for a specific customer.
   *   @param customerId - The customer which contacts we want to get.
   */
  protected internalGetCustomerContactsFromUrl(customerId: string): Observable<CustomerContact[]> {
    const url = `json/customer/${customerId}/contacts`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<CustomerContact[]>(url, { headers });
  }
  /**
   * Gets a specific contact from a customer.
   * @param customerId - The id of the customer we want to find a contact from.
   * @param contactId  - The id of the contact we want to find.
   */
  getCustomerContact(customerId: string, contactId: string): Observable<CustomerContact> {
    const url = `json/customer/${customerId}/contacts/${contactId}`;
    return this.http.get<CustomerContact>(url);
  }

  updateCustomerContact(customerId: string, contact: CustomerContact): Observable<CustomerContact[]> {
    const url = `json/customer/${customerId}/contacts`;
    return this.http.put<CustomerContact[]>(url, contact).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  addCustomerContact(customerId: string, contact: CustomerContact): Observable<CustomerContact[]> {
    const url = `json/customer/${customerId}/contacts`;
    return this.http.post<CustomerContact[]>(url, contact).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  addCustomerContacts(customerId: string, contacts: CustomerContact[]): Observable<CustomerContact[]> {
    const url = `json/customer/${customerId}/contacts-batch`;
    return this.http.post<CustomerContact[]>(url, contacts).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  updateCustomerContactsSubject(customerId: string): void {
    this.internalGetCustomerContactsFromUrl(customerId).subscribe((cc: CustomerContact[]) => {
      if (this.contactsSubjects[customerId]) {
        this.contactsSubjects[customerId].next(cc);
      }
    });
  }

  getCustomerContactsFromSubject(customerId: string): Observable<CustomerContact[]> {
    return this.getCustomerContacts(customerId);
  }

  /** GUNG CONTACT */

  addCustomerContactGung(customerId: string, contact: CustomerContact): Observable<CustomerContact[]> {
    const url = `json/contacts`;
    return this.http.post<CustomerContact[]>(url, contact).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }
}
