import { SupplierState } from './types';
import { Action } from '@ngrx/store';
import { SupplierActionTypes, SupplierSelected } from './actions';

const initialState: SupplierState = {
  selectedSupplier: undefined,
  doneLoading: false
};

export function reducers(state: SupplierState = initialState, action: Action): SupplierState {
  switch (action.type) {
    case SupplierActionTypes.SupplierSelected: {
      const { payload } = action as SupplierSelected;
      return {
        ...state,
        selectedSupplier: payload,
        doneLoading: true
      };
    }
    case SupplierActionTypes.ClearSelectedSupplier: {
      return {
        ...state,
        selectedSupplier: null
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
