import { Injectable } from '@angular/core';
import { Observable, Subject, timer, ReplaySubject, forkJoin, of } from 'rxjs';
import { first, map, debounce, switchMap } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { HttpClient } from '@angular/common/http';
import { ProductService } from '../products/product.service';
import { Product } from '../../models/product';
import { GungFlowService } from '../gung-flow/gung-flow.service';

@Injectable({
  providedIn: 'root'
})
export class FavouriteProductsService {
  favouriteSubject: Subject<{ [key: string]: boolean }>;

  constructor(
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected productService: ProductService,
    protected http: HttpClient
  ) {}

  initFavouriteProduct(): void {
    this.retrivePersistedUserFavourites();
  }

  protected initFavouriteProductIds(): void {
    this.favouriteSubject = new ReplaySubject<{ [key: string]: boolean }>(1);
    this.retrivePersistedUserFavourites()
      .pipe(
        first(),
        debounce(() => timer(100))
      )
      .subscribe(ids => {
        this.favouriteSubject.next(ids);
      });
  }

  protected retrivePersistedUserFavourites(noCache?: boolean): Observable<{ [key: string]: boolean }> {
    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    // in order to avoid having favourites products which doe not exist
    // for example, the product was added to favourites list but later removed
    return this.http.get<{ [key: string]: boolean }>('json/favourite-products', { headers }).pipe(
      first(),
      switchMap(favourites =>
        forkJoin({
          favourites: of(favourites),
          products: this.productService
            .getFullProductsByIds(Object.keys(favourites).filter(id => favourites[id]))
            .pipe(first())
        })
      ),
      map(({ favourites, products }) =>
        products.filter(product => !!product).reduce((acc, curr) => ({ ...acc, [curr.id]: favourites[curr.id] }), {})
      )
    );
  }

  public getFavourites(): Observable<{}> {
    if (!this.favouriteSubject) {
      this.initFavouriteProductIds();
    }
    return this.favouriteSubject.asObservable();
  }

  public toggleFavourite(id: string): void {
    if (!this.favouriteSubject) {
      this.initFavouriteProductIds();
    }

    this.getFavourites()
      .pipe(
        first(),
        switchMap(favourites => {
          favourites[id] = !favourites[id];
          return this.http.post<any>('json/favourite-products', { favouriteMap: favourites });
        })
      )
      .subscribe(res => {
        this.favouriteSubject.next(res.favourites);
      });
  }

  public toggleFavourites(ids: string[]): void {
    if (!this.favouriteSubject) {
      this.initFavouriteProductIds();
    }

    this.getFavourites()
      .pipe(
        first(),
        switchMap(favourites => {
          for (const id of ids) {
            favourites[id] = !favourites[id];
            if (!favourites[id]) {
              delete favourites[id];
            }
          }
          return this.http.post<any>('json/favourite-products', { favouriteMap: favourites });
        })
      )
      .subscribe(res => {
        this.favouriteSubject.next(res.favourites);
      });
  }

  public isFavourite(id: string): Observable<boolean> {
    if (!this.favouriteSubject) {
      this.initFavouriteProductIds();
    }
    return this.getFavourites().pipe(
      map((favourites: string[]) => {
        if (!favourites.hasOwnProperty(id)) {
          return false;
        }
        // const isFavourite = favourites.findIndex(d => d === id) !== -1;
        const isFavourite = favourites[id];
        return isFavourite;
      })
    );
  }

  public getFavouritesByFlow(): Observable<Product[]> {
    return this.gungFlowService.getSelectedFlow().pipe(
      switchMap(flow => {
        return this.getFavourites().pipe(
          switchMap(favourites => {
            const ids: Array<string> = Object.keys(favourites).filter(id => favourites[id]);
            const filteredIds = ids.filter(id => {
              return favourites[id];
            });
            return this.productService.getProductsByIds(filteredIds).pipe();
          })
        );
      })
    );
  }
}
