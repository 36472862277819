import { Injectable } from '@angular/core';

import { from } from 'rxjs';
import { map } from 'rxjs';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { CanDeactivateComponent } from '../../components/can-deactivate.component';

@Injectable()
export class CanDeactivateGuard  {
  constructor(protected gungModalService: GungModalService) {}

  canDeactivate(component: CanDeactivateComponent) {
    if (!component.canDeactivate()) {
      return from(
        this.gungModalService.openConfirmYesNoModal('UNSAVED_CHANGES_HEADING', 'UNSAVED_CHANGES_CONTENT')
      ).pipe(
        map(result => {
          return !!result;
        })
      );
    }

    return true;
  }
}
