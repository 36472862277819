import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { first, map, switchMap, catchError, tap } from 'rxjs';
import { SelectedCustomerService } from './../selected-customer/selected-customer.service';
import { DiscountCode, DiscountCodesService } from '../discount-codes.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesV2Service {
  baseUrl = 'json/discount-codes-v2';
  baseV1Url = 'json/discount-codes';

  public writeExpressions: DiscountCodeWriteExpressionTemplate[] = [];

  private discountCodesV2Subject: BehaviorSubject<DiscountCodeV2[]>;

  constructor(protected http: HttpClient, protected selectedCustomerService: SelectedCustomerService) {}

  public getIsDiscountCodeValid(discountCode: string): Observable<boolean> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      map(cust => cust.id),
      switchMap(companyId => this.internalGetValidDiscountCode(discountCode, companyId)),
      catchError(_ => of(false)),
      map(val => !!val)
    );
  }

  public getDiscountCode(discountCode: string): Observable<DiscountCodeV2> {
    return this.internalGetDiscountCode(discountCode);
  }

  protected internalGetDiscountCode(discountCode: string): Observable<DiscountCodeV2> {
    return this.http.get<DiscountCodeV2>(`${this.baseUrl}/${discountCode}`);
  }
  protected internalGetValidDiscountCode(discountCode: string, companyId: string): Observable<DiscountCodeV2> {
    return this.http.get<DiscountCodeV2>(`${this.baseV1Url}/${discountCode}/${companyId}/get-valid`);
  }

  public getAllDiscounCode(nocache?: boolean): Observable<DiscountCodeV2[]> {
    if (!this.discountCodesV2Subject) {
      this.discountCodesV2Subject = new BehaviorSubject<DiscountCodeV2[]>([]);
      this.updateCustomerUsersSubject(nocache);
    }
    return this.discountCodesV2Subject.asObservable();
  }

  updateCustomerUsersSubject(nocache?: boolean): void {
    this.internalGetAllDiscounCode(nocache).subscribe((dc: DiscountCodeV2[]) => this.discountCodesV2Subject.next(dc));
  }

  protected internalGetAllDiscounCode(nocache?: boolean): Observable<DiscountCodeV2[]> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<DiscountCodeV2[]>(`${this.baseUrl}`, { headers });
  }

  public postDiscountCodes(discountCode: DiscountCodeV2): Observable<DiscountCodeV2> {
    const url = `${this.baseUrl}`;
    return this.http.post<DiscountCodeV2>(url, discountCode).pipe(tap(_ => this.updateCustomerUsersSubject(true)));
  }

  public putDiscountCodes(discountCode: DiscountCodeV2): Observable<DiscountCodeV2> {
    const url = `${this.baseUrl}`;
    return this.http.put<DiscountCodeV2>(url, discountCode).pipe(tap(_ => this.updateCustomerUsersSubject(true)));
  }

  public deleteDiscountCodes(code: string): Observable<void> {
    const url = `${this.baseUrl}/${code}`;
    return this.http.delete<void>(url).pipe(tap(_ => this.updateCustomerUsersSubject(true)));
  }
}

export interface DiscountCodeV2 /* extends DiscountCode */ {
  code: string;
  description: string;
  // extra: {[key: string]: any}; // Deprecated
  // usedByCompanyForOrderMap: {[company: string]: string[]}; // Deprecated
  validFrom: Date;
  validUntil: Date;
  createdDate: Date;
  maxMultipleUses: number;
  discountAmount: number;
  orderRowDiscount: boolean;
  writeExpression: string;
  discountCodeConditions?: DiscountCodeConditions;
  addsNewRow?: boolean;
  productId?: string;
}

export interface DiscountCodeWriteExpressionTemplate {
  expression: string;
  orderRowDiscount: boolean;
  description: string;
  addsNewRow?: boolean;
}
export interface DiscountCodeConditions {
  logicalConditions: LogicalConditions[];
  logicalOperator: string;
  type: string;
}

export interface LogicalConditions {
  conditionName: string;
  expressionString: string;
  expressionValues: string[];
  expressionValuesAllowed: boolean;
  type: string;
}
