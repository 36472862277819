<div class="row">
  <div class="col">
    <lib-order-confirm-configuration></lib-order-confirm-configuration>
  </div>
</div>

<div class="row">
  <div class="col">
    <lib-inactive-users-notification-configuration></lib-inactive-users-notification-configuration>
  </div>
</div>
