<div class="row">
  <div *ngFor="let product of data" class="col-3 col-lg-2 col-md-3 col-sm-6 mb-3">
    <div class="card product-card product-card-container">
      <div class="card-body">
        <div class="image-wrapper text-center">
          <img
            class="img-fluid"
            *ngIf="product.extra && product.extra.images && product.extra.images[0]"
            [src]="product.extra.images[0].s3Uri | gungImageUrl
              :'type:thumbnail'
              :'orientation:portrait'
              :'etag:'+product.extra.images[0].s3ETag
          "
            alt=""
          />
        </div>

        <div class="product-text">
          <div>{{ product.id }}</div>
          <div class="text-truncate" translate>{{ product.name }}</div>
        </div>

        <lib-item-selection-button [item]="product"></lib-item-selection-button>
      </div>
    </div>
  </div>
</div>
