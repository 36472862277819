import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UploadProgressService {

  protected uploadProgress: { [fileName: string]: { value: number, subject: Subject<number> } } = {};

  constructor() { }

  setProgress(fileName: string, progress: number) {
    if (!this.uploadProgress[fileName]) {
      this.uploadProgress[fileName] = {
        value: 0,
        subject: new BehaviorSubject(0)
      };
    }

    this.uploadProgress[fileName].value = progress;
    this.uploadProgress[fileName].subject.next(progress);
  }

  getProgress(fileName: string): Observable<number> {
    if (!this.uploadProgress[fileName]) {
      this.uploadProgress[fileName] = {
        value: 0,
        subject: new BehaviorSubject(0)
      };
    }

    return this.uploadProgress[fileName].subject.asObservable();
  }

  removeProgress(fileName: string) {
    if (this.uploadProgress[fileName]) {
      if (this.uploadProgress[fileName].value < 100) {
        this.uploadProgress[fileName].value = 100;
        this.uploadProgress[fileName].subject.next(100);
      }
      this.uploadProgress[fileName].subject.complete();
      delete this.uploadProgress[fileName];
    }
  }
}
