import { Component, ViewChild, Input } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { OrderListConfigService } from '../../services/order-list-config/order-list-config.service';
import { Order } from '../../models/order';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-order-list',
  template: `<lib-filter-list #orderList [configService]="orderListConfigService"></lib-filter-list>`,
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent {
  constructor(public orderListConfigService: OrderListConfigService, route: ActivatedRoute) {
    const customerId = route.snapshot.params.customerId;

    if (customerId != null) {
      orderListConfigService.setCustomerId(customerId);
    } else {
      orderListConfigService.setCustomerId(null);
    }
  }
  @ViewChild('orderList') orderList: FilterListComponent<Order>;
}
