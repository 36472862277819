import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError } from 'rxjs/internal/operators/catchError';
import { Observable } from 'rxjs/internal/Observable';
import { throwError } from 'rxjs/internal/observable/throwError';

@Injectable({
  providedIn: 'root'
})
export class ImageZipDownloadService {
  downloadAllProductImages = false;
  private baseUrl = 'download/order-zip-multiple/';
  constructor(protected http: HttpClient) {}

  getMultipleZipFiles(onr: string, type?: string): Observable<GenerateZipResponse> {
    let url = this.baseUrl + onr;
    if (type == 'documents') {
      url += '?type=documents';
    }
    if (this.downloadAllProductImages) {
      url += '?useFilters=false';
    }
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    return this.http.get<GenerateZipResponse>(url, { headers }).pipe(
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      })
    );
  }
}

export interface GenerateZipResponse {
  files: GeneratedZip[];
}

export interface GeneratedZip {
  s3Uri: string;
  url: string;
  filename: string;
}
