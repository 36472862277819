import { Component, OnInit } from '@angular/core';
import { CommonModalService } from '../../../services/common-modal/common-modal.service';

@Component({
  selector: 'lib-gung-error-handler-modal',
  templateUrl: './gung-error-handler-modal.component.html',
  styleUrls: ['./gung-error-handler-modal.component.css']
})
export class GungErrorHandlerModalComponent implements OnInit {
  delegate: CommonModalService;
  error: GungError;
  componentId: string;

  constructor() {}

  ngOnInit() {
    this.componentId = GungErrorHandlerModalComponent.name;
  }
}

export interface GungError extends Error {
  i18nKeyMessage: string;
  i18nKeyTitle?: string;
}
