@if(form){
    <div class="row">
        <div class="col-12 col-md-6">
          <div [formGroup]="form">
            <lib-meta-select-input
              formControlName="termsOfPayment"
              table="paymentTerms"
              metaId="code"
              metaDisplay="name"
              gungTranslate="PAYMENT_TERMS"
            >
            </lib-meta-select-input>
          </div>
          <div [formGroup]="form">
            <lib-meta-select-input
              formControlName="termsOfDelivery"
              table="deliveryTerms"
              metaId="code"
              metaDisplay="name"
              gungTranslate="DELIVERY_TERMS"
            >
            </lib-meta-select-input>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div [formGroup]="form">
            <lib-meta-select-input
              formControlName="deliveryMethod"
              table="deliveryMethods"
              metaId="code"
              metaDisplay="name"
              gungTranslate="DELIVERY_METHOD"
            />
      
          </div>
          <!-- <div [formGroup]="form">
            <lib-meta-select-input formControlName="salesperson" table="salj" metaId="salesperson" metaDisplay="saljarenamn">
            </lib-meta-select-input>
          </div> -->
      
        </div>
      </div>
}@else{
    <div translate> LOADING </div>
}
