<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>BASIC</h4>
    <lib-gung-text-input-horizontal gungTranslate="PIM_I18N.SE.TITLE" [(ngModel)]="pimProduct.i18n.se.title">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal gungTranslate="PIM_I18N.EN.TITLE" [(ngModel)]="pimProduct.i18n.en.title">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.SE.SUBTITLE"
      [(ngModel)]="pimProduct.i18n.se.subTitle"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.EN.SUBTITLE"
      [(ngModel)]="pimProduct.i18n.en.subTitle"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_SEQUENCE"
      [(ngModel)]="pimProduct.extra.pim.sequence"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12 col-md-6">
    <h4 translate>DESCRIPTION</h4>
    <div class="form-group">
      <label for="i18n.se.description" class="control-label" translate>PIM_I18N.SE.DESCRIPTION</label>
      <textarea
        rows="5"
        id="i18n.se.description"
        name="i18n.se.description"
        class="form-control"
        [(ngModel)]="pimProduct.i18n.se.description"
      ></textarea>
    </div>
    <div class="form-group">
      <label for="i18n.en.description" class="control-label" translate>PIM_I18N.EN.DESCRIPTION</label>
      <textarea
        rows="5"
        id="i18n.en.description"
        name="i18n.en.description"
        class="form-control"
        [(ngModel)]="pimProduct.i18n.en.description"
      ></textarea>
    </div>
  </div>
</div>
