import { Component, OnChanges, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { DigitalAsset } from '../../services/pdf-digital-assets.service';

@Component({
  selector: 'lib-pdf-digital-assets-folder-grid-view',
  templateUrl: './pdf-digital-assets-folder-grid-view.component.html',
  styleUrls: ['./pdf-digital-assets-folder-grid-view.component.css']
})
export class PdfDigitalAssetsFolderGridViewComponent
  extends ListItemRendererComponent<DigitalAsset[]>
  implements OnInit, OnChanges
{
  parentId: string;

  digitalAsset: DigitalAsset;

  constructor(protected router: Router, protected route: ActivatedRoute) {
    super();
  }

  ngOnInit() {
    this.parentId = undefined;
    if (this.route.snapshot.url.length > 0) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }
}
