import { Injectable } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BehaviorSubject, Observable, of, Subject, Subscription, takeUntil } from 'rxjs';
import { Location } from '@angular/common';
import { ConfigService } from '../types';

@Injectable({
  providedIn: 'root'
})
export class FilterListRoutingService {
  protected unsubscribe: Subject<void> = new Subject();
  protected queryParamsSubscription: Subscription;

  protected queryParamSnapshot: Params;
  protected queryParamSubject: BehaviorSubject<Record<string, any>>;

  protected previousConfig: ConfigService<any>;
  protected previousUrl: string;

  constructor(protected route: ActivatedRoute, protected router: Router, protected location: Location) {
    this.createSubscription();
  }

  public createSubscription() {
    if (!this.queryParamSubject) {
      this.queryParamSubject = new BehaviorSubject<Record<string, any>>({});
      this.queryParamSubject.pipe(takeUntil(this.unsubscribe)).subscribe(qpm => {
        this.queryParamSnapshot = qpm;
      });
    }

    if (!this.queryParamsSubscription || this.queryParamsSubscription.closed) {
      this.queryParamsSubscription = this.route.queryParams.pipe(takeUntil(this.unsubscribe)).subscribe(qpm => {
        this.queryParamSubject.next(qpm);
      });
    }
  }

  public unsubscribeToRoute() {
    // Require refresh of subscriptions after an unsubscribe to fetch values from url when navigating with browser
    // history forward. If not we would not load from the query parameters in the url.
    if (this.previousConfig && this.previousUrl) {
      this.clearQueryParams();
      // this.queryParamsSubscription.unsubscribe();
    }
  }

  public setQueryParamSubject(qp: Params) {
    this.queryParamSubject.next(qp);
  }

  public getQueryParams(config: ConfigService<any>, forceGet?: boolean): Observable<Record<string, any>> {
    // New list on same page (lets say different tab)
    if (this.previousConfig !== config && this.previousUrl === this.router.url && !forceGet) {
      this.setQueryParams({
        filters: undefined,
        search: undefined,
        limit: undefined,
        page: undefined
      });
    }
    this.previousConfig = config;
    this.previousUrl = this.router.url;

    return this.queryParamSubject;
  }

  public queryParamsSnapshot(): Record<string, any> {
    return this.queryParamSnapshot;
  }

  public setQueryParams(queryParams: Record<string, any>): void {
    const newParams = {
      ...this.queryParamSnapshot,
      ...queryParams
    };

    const urlTree = this.router.createUrlTree([], {
      relativeTo: null,
      queryParams: newParams,
      queryParamsHandling: 'merge',
      preserveFragment: false
    });
    if (this.location.path() !== urlTree.toString()) {
      this.location.replaceState(urlTree.toString());
      this.queryParamSubject.next(newParams);
    } else if (this.queryParamSnapshot !== queryParams
      && Object.keys(this.queryParamSnapshot).filter(k => this.queryParamSnapshot[k]).length !== Object.keys(queryParams).filter(k => queryParams[k]).length
      && Object.keys(newParams).filter(k => newParams[k]).filter(k => newParams[k] !== this.queryParamSnapshot[k]).length > 0
    ) {
      // FIX when filter-list.component give us the queryParams
      this.queryParamSubject.next(newParams);
    }
  }

  public clearQueryParams(): void {
    /*
    We have both of these calls for a purpose. It's a hackish solution to solve two separate issues. Without the direct
    call on the subject, we won't clear filters when we are on the same page. For example if we are on the customer
    details and have an order list and an invoice list, if we search in the orders, we need to search to be cleared
    when moving to the invoice list.

    The other call is needed because by calling the query param subject directly, we are not updating the current
    location, this causes issues when doing workarounds to get popup modals that contains list that are placed in a list
    to return to the previous filter state. Example of this is on Imazo in their product list when displaying a concept.
     */
    this.queryParamSubject.next({
      filters: undefined,
      search: undefined,
      limit: undefined,
      page: undefined
    });
    this.setQueryParams({
      filters: undefined,
      search: undefined,
      limit: undefined,
      page: undefined
    });
  }
}
