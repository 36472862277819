<div *ngIf="data; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>KEY</th>
        <th translate>VALUE</th>
        <th translate>PROPERTY_SOURCE</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let property of data">
        <tr *ngIf="property && (property | keyvalue)?.[0] as prop">
          <td [attr.data-label]="'KEY' | translate">
            {{ prop.key }}
          </td>
          <td [attr.data-label]="'VALUE' | translate">
            {{ prop.value.value }}
          </td>
          <td [attr.data-label]="'PROPERTY_SOURCE' | translate">
            {{ prop.value.propertySources }}
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
