import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { switchMap } from 'rxjs';

@Component({
  selector: 'lib-edi-order-bulk-edit',
  templateUrl: './edi-order-bulk-edit.component.html',
  styleUrls: ['./edi-order-bulk-edit.component.css']
})
export class EdiOrderBulkEditComponent implements OnInit {
  @Input()
  ediOrdersId: string[] = [];

  constructor(protected router: Router, protected route: ActivatedRoute) {
    const ediOrders = this.router.getCurrentNavigation()?.extras?.state?.data;
    if (ediOrders) {
      this.ediOrdersId = ediOrders.map(o => o.id);
    }
  }

  ngOnInit(): void {
    this.route.paramMap.pipe().subscribe(data => {
      console.log('data', data);
    });
  }
}
