import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { EdiMessageBase, GungEdiAddressInfo, GLNRecord } from '../../../models/edi';
import { EdiService } from '../../../services/edi/edi.service';

interface GungEdiAddressInfoWithData extends GungEdiAddressInfo {
  gln?: number; // This does not exist in the backend but is used in frontend for usability
  title?: string;
  customerId?: string;
  customerName?: string;
}
@Component({
  selector: 'lib-edi-gln-number-tabs',
  templateUrl: './edi-gln-number-tabs.component.html',
  styleUrls: ['./edi-gln-number-tabs.component.css']
})
export class EdiGlnNumberTabsComponent implements OnInit {
  glnAddressList: GungEdiAddressInfoWithData[] = [];
  @Input()
  ediMessage: EdiMessageBase;

  constructor(protected ediService: EdiService) {}

  ngOnInit(): void {
    if (!!this.ediMessage.supplierGln) {
      this.ediService.getGLNRecord(this.ediMessage.supplierGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.supplierAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.supplierGln;
        temp.title = 'SUPPLIER';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.senderGln) {
      this.ediService.getGLNRecord(this.ediMessage.senderGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.senderAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.senderGln;
        temp.title = 'SENDER';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.consigneeGln) {
      this.ediService.getGLNRecord(this.ediMessage.consigneeGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.consigneeAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.consigneeGln;
        temp.title = 'CONSIGNEE';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.deliveryGln) {
      this.ediService.getGLNRecord(this.ediMessage.deliveryGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.deliveryAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.deliveryGln;
        temp.title = 'DELIVERY';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.buyerGln) {
      this.ediService.getGLNRecord(this.ediMessage.buyerGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.buyerAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.buyerGln;
        temp.title = 'BUYER';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.recipientGln) {
      this.ediService.getGLNRecord(this.ediMessage.recipientGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.recipientAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.recipientGln;
        temp.title = 'RECIPIENT';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
    if (!!this.ediMessage.invoiceeGln) {
      this.ediService.getGLNRecord(this.ediMessage.invoiceeGln).pipe(first()).subscribe(glnRecord => {
        const temp = this.ediMessage.invoiceeAddress as GungEdiAddressInfoWithData;
        temp.gln = this.ediMessage.invoiceeGln;
        temp.title = 'INVOICEE';
        temp.customerId = glnRecord.companyId;
        temp.customerName = glnRecord.b24?.name;
        this.glnAddressList.push(temp);
      });
    }
  }
}
