import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { Product } from '../../../models';
import { Assortment } from '../../../services/assortment.service';
import { QuickorderProduct, QuickorderService } from '../../../services/quickorder/quickorder.service';
import { QuickorderProductDetailsComponent } from '../quickorder-product-details/quickorder-product-details.component';

@Component({
  selector: 'lib-quickorder-listing-product',
  template: '<ng-template libCustomHost></ng-template>',
  styles: []
})
export class QuickorderListingProductComponent implements OnInit, OnDestroy {
  _product: Product;
  @Input()
  set product(product: Product) {
    if (!product) {
      this.clearComponent();
      return;
    }
    this._product = product;
    this.loadComponent();
  }
  get product() {
    return this._product;
  }

  @Input()
  assortment: Assortment;

  @Output()
  updateQuantity: EventEmitter<number>;

  @ViewChild(CustomHostDirective, { static: true }) libCustomHost!: CustomHostDirective;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected quickorderService: QuickorderService
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  loadComponent() {
    const component = this.quickorderService.getProductDetailsComponent();
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.libCustomHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent<QuickorderProduct>(componentFactory);
    componentRef.instance.product = this.product;
    componentRef.instance.assortment = this.assortment;
    componentRef.instance.updateQuantity = this.updateQuantity;
  }

  clearComponent() {
    const viewContainerRef = this.libCustomHost.viewContainerRef;
    viewContainerRef.clear();
  }
}
