import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { TranslationManagementListConfigService } from '../../services/translation-management-list-config/translation-management-list-config.service';

@Component({
  selector: 'lib-translation-management',
  templateUrl: './translation-management.component.html',
  styleUrls: ['./translation-management.component.scss']
})
export class TranslationManagementComponent extends ListItemRendererComponent<any[]> implements OnInit, AfterViewInit {
  languages: Set<string> = new Set<string>();

  constructor(public config: TranslationManagementListConfigService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
