import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { filter, map, switchMap, tap } from 'rxjs';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { ActionTypes, UserReceived } from '../auth/actions';
import { ClearSelectedFlow, FlowSelected, GetDefaultFlow, GungFlowActionTypes, SelectFlow } from './actions';

@Injectable()
export class GungFlowEffects {
  constructor(private actions$: Actions, private gungFlowService: GungFlowService) {}

  selectFlow$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === GungFlowActionTypes.SelectFlow),
      map(action => action as SelectFlow),
      map(action => action.payload.flowId),
      switchMap(id => this.gungFlowService.getGungFlow(id)),
      map(flow => new FlowSelected(flow))
    )
  );

  getDefaultFlow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(GungFlowActionTypes.GetDefaultFlow),
      switchMap(_ => {
        return this.gungFlowService.getDefaultGungFlow();
      }),
      map(flow => (flow !== null ? new FlowSelected(flow) : new ClearSelectedFlow()))
    )
  );

  getFlowSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => action.type === GungFlowActionTypes.FlowSelected),
        map(action => action as FlowSelected),
        map(action => action.payload),
        tap(flow => {
          if(flow){
            localStorage.setItem('selectedFlowId', flow.id)
          }
        })
      ),
    { dispatch: false }
  );

  userReceived$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.UserReceived),
      map(action => action as UserReceived),
      map(() => {
        let flowId: string | null = null;

        const flowIdFromLocalstorage = localStorage.getItem('selectedFlowId');
        if (flowIdFromLocalstorage != null) {
          flowId = flowIdFromLocalstorage;
        }
        return flowId;
      }),
      switchMap(flowId => {
        if (!flowId) {
          return of(new GetDefaultFlow());
        }
        return this.gungFlowService
          .isGungFlowAllowedForUser(flowId)
          .pipe(map(allowed => (allowed ? new SelectFlow({ flowId }) : new GetDefaultFlow())));
      })
    )
  );
}
