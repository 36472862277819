<div class="modal-header">
  <h4 class="modal-title" translate>{{ discountCode?.code ? 'DISCOUNT_CODE_EDIT' : 'DISCOUNT_CODE_CREATE' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem>
      <a ngbNavLink>FIELDS</a>
      <ng-template ngbNavContent>
        <form [formGroup]="submitForm">
          <!-- FIELDS -->
          <div class="row">
            <div class="col-12">
              <lib-gung-text-input [gungTranslate]="'CODE'" formControlName="code"></lib-gung-text-input>
            </div>
          </div>
          <!-- <div class="row" *ngIf="!!discountCode?.code">
                    <div class="col-12">
                      <lib-gung-text-input [renderStatic]="!!discountCode?.code" [gungTranslate]="'CREATED_DATE'" [value]="submitForm.get('createdDate').value | date"></lib-gung-text-input>
                    </div>
                  </div> -->
          <div class="row" *ngIf="!!discountCode?.code">
            <div class="col-12">
              <div class="form-group">
                <label for="description" class="control-label" translate>CREATED_DATE</label>
                <input
                  class="form-control-plaintext"
                  type="text"
                  disabled
                  value="{{ submitForm.get('createdDate').value | date : dateUtilService.dateFormat }}"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label for="description" class="control-label" translate>DESCRIPTION</label>
                <textarea
                  class="form-control"
                  type="text"
                  name="description"
                  id="description"
                  formControlName="description"
                  rows="5"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="WRITE_EXPRESSION" translate>DISCOUNT_APPLICATION</label>
                <select
                  class="form-control"
                  #writeExpression
                  id="WRITE_EXPRESSION"
                  name="WRITE_EXPRESSION"
                  formControlName="writeExpression"
                  (change)="writeExpressionChange(writeExpression.value)"
                >
                  <option *ngFor="let expression of writeExpressions" [value]="expression.expression" translate>
                    {{ expression.description }}
                  </option>
                </select>
                <div
                  *ngIf="
                    submitForm.get('writeExpression').invalid &&
                    (submitForm.get('writeExpression').dirty || submitForm.get('writeExpression').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="maxMultipleUses" translate>MAX_MULTIPLE_USES</label>
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  id="maxMultipleUses"
                  formControlName="maxMultipleUses"
                />
                <div
                  *ngIf="
                    submitForm.get('maxMultipleUses').invalid &&
                    (submitForm.get('maxMultipleUses').dirty || submitForm.get('maxMultipleUses').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="meeting-time" translate>VALID_FROM</label>
                <input
                  class="form-control"
                  type="datetime-local"
                  id="meeting-time"
                  #validFrom
                  name="meeting-time"
                  min="{{ minDate.toISOString().slice(0, -8) }}"
                  formControlName="validFrom"
                />
                <div
                  *ngIf="
                    submitForm.get('validFrom').invalid &&
                    (submitForm.get('validFrom').dirty || submitForm.get('validFrom').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="meeting-time" translate>VALID_UNTIL</label>
                <input
                  class="form-control"
                  type="datetime-local"
                  id="meeting-time"
                  name="meeting-time"
                  min="{{ minDate.toISOString().slice(0, -8) }}"
                  formControlName="validUntil"
                />
                <div
                  *ngIf="
                    submitForm.get('validUntil').invalid &&
                    (submitForm.get('validUntil').dirty || submitForm.get('validUntil').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group">
                <label class="w-100" for="discountAmount" translate>DISCOUNT_AMOUNT</label>
                <input
                  class="form-control"
                  type="number"
                  min="0"
                  id="discountAmount"
                  formControlName="discountAmount"
                />
                <div
                  *ngIf="
                    submitForm.get('discountAmount').invalid &&
                    (submitForm.get('discountAmount').dirty || submitForm.get('discountAmount').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-12">
              <lib-gung-checkbox-input-horizontal gungTranslate="ADDS_NEW_ROW" formControlName="addsNewRow">
              </lib-gung-checkbox-input-horizontal>
            </div>
          </div>
          <div class="row" *ngIf="submitForm.get('productId')">
            <div class="col-12">
              <div class="form-group">
                <lib-gung-text-input [gungTranslate]="'PRODUCT_ID'" formControlName="productId"></lib-gung-text-input>
                <div
                  *ngIf="
                    submitForm.get('productId').invalid &&
                    (submitForm.get('productId').dirty || submitForm.get('productId').touched)
                  "
                  class="alert alert-danger p-1"
                >
                  <span translate>FORM_ERROR_REQUIRED</span>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="contact" translate>CONTACT</label>
                        <div formArrayName="contacts">
                          <div *ngFor="let contact of contacts.controls; let i=index">
                            <div class="d-flex mb-2"><!-- [formGroupName]="i" -- >
                              <input class="form-control" type="text" [formControlName]="i">
                              <button type="button" class="btn btn-danger align-self-end" (click)="removeContact(i)"><i class="fas fa-times-circle"></i></button>
                            </div>
                          </div>
                        </div>
                        <button type="button" class="btn btn-primary d-block" (click)="addContact()"><span translate>ADD_CONTACT</span><i class="fas fa-plus-circle ml-1"></i></button>
                      </div>
                    </div>
                  </div> -->
          <!-- <pre>{{submitForm.getRawValue() | json}}</pre> -->
        </form>
      </ng-template>
    </ng-container>
    <!--
            <ng-container ngbNavItem>
              <a ngbNavLink>DISCOUNT</a>
              <ng-template ngbNavContent>

                <form [formGroup]="submitForm">
                  <button type="button" class="btn btn-primary" data-toggle="button" (click)="discountBool.click()" translate>
                      ORDER_ROW_DISCOUNT_BOOL
                      <input type="checkbox" formControlName="orderRowDiscount"
                        (click)="enableDiscountCodeConditions($event.srcElement.checked, discountCodeMongo)" #discountBool>
                  </button>
                  <fieldset [attr.disabled]="disableDiscountConditions ? 'disabled' : undefined">
                  <div formGroupName="discountCodeConditions" *ngIf="submitForm.get('orderRowDiscount').value">
                    <div class="row">
                      <div class="col-12">
                        <label class="w-100" for="MATCHING" translate>MATCHING</label>
                        <select class="form-control" id="MATCHING" name="MATCHING" formControlName="logicalOperator">
                          <option value="OR" translate>MATCH_AT_LEAST_ONE_CONDITION</option>
                          <option value="AND" translate>MATCH_ALL_CONDITIONS</option>
                        </select>
                      </div>
                    </div>
                    <div formArrayName="logicalConditions">
                      <div *ngFor="let logicalCondition of logicalConditions.controls; let i=index" [formGroupName]="i">
                        <div class="row">
                          <div class="col-12">
                            <label class="w-100" for="SELECT_CONDITION" translate>SELECT_CONDITION</label>
                            <select class="form-control" id="SELECT_CONDITION" name="SELECT_CONDITION" formControlName="expressionString">
                              <option *ngFor="let condition of conditionExpressions" [value]="condition.conditionName">{{condition.conditionName}}</option>
                            </select>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <lib-gung-text-input [gungTranslate]="'DESCRIPTION'" formControlName="conditionName"></lib-gung-text-input>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="description" class="control-label" translate>IMPORT_MULTIPLE_VALUES</label>
                              <textarea class="form-control" type="text" name="description" id="description" formControlName="type" rows="5"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button type="button" class="btn btn-primary" translate>IMPORT_MULTIPLE_VALUES</button>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <label for="description" class="control-label" translate>IMPORT_MULTIPLE_VALUES</label>
                            <div class="row">
                              <div class="col-12 col-md-3 d-flex">
                                <input type="text">
                                <button type="button" class="btn btn-danger"><i class="fas fa-minus-circle"></i></button>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <div class="form-group">
                              <label for="expressionString" class="control-label" translate>IMPORT_MULTIPLE_VALUES</label>
                              <textarea class="form-control" type="text" name="expressionString" id="expressionString" formControlName="expressionString" rows="5"></textarea>
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-12">
                            <button type="button" class="btn btn-danger" (click)="deleteLogicalConditions(i)" translate>REMOVE_CONDITION</button>
                          </div>
                        </div>
                      </div>
                      <button type="button" class="btn btn-primary" (click)="addLogicalConditions()" translate>ADD_CONDITION</button>
                    </div>
                  </div>
                </fieldset>
                </form>
              </ng-template>
            </ng-container>
            -->
    <ng-container ngbNavItem *ngIf="isActuator">
      <a ngbNavLink>MONGO</a>
      <ng-template ngbNavContent>
        <!-- MONGO OBJECT -->
        <textarea
          class="w-100"
          rows="15"
          [ngModel]="discountCodeMongo | json"
          [ngModelOptions]="{ updateOn: 'blur' }"
          (ngModelChange)="saveMongoObject($event)"
        ></textarea>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cance click')" translate>CANCEL</button>
  <button type="button" class="btn btn-primary" [disabled]="disabled ? true : null" (click)="onSave()" translate>
    SAVE
  </button>
</div>
