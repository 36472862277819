import { Injectable } from '@angular/core';
import { Observable, from, of } from 'rxjs';

import { HttpBackend, HttpClient } from '@angular/common/http';
import { catchError } from 'rxjs';
import { GungDownloadUrl } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class TranslationLocalService {
  private httpWithoutInterceptors: HttpClient;

  constructor(protected http: HttpClient, protected handler: HttpBackend) {
    this.httpWithoutInterceptors = new HttpClient(handler);
  }

  getTranslations(lang: string): Observable<any> {
    return from(this.getMergedTranslations(lang));
  }

  protected async getMergedTranslations(lang: string): Promise<any> {
    const core = await this.getCoreTranslations(lang);
    const custLocal = await this.getCustTranslations(lang);

    let custRemote;
    try {
      custRemote = await this.getRemoteCustTranslations(lang);
    } catch (e) {
      console.log('Backend does not support remote customer translations. Update backend to enable this feature.');
      custRemote = {};
    }

    return {
      ...core,
      ...custLocal,
      ...custRemote
    };
  }

  protected async getCoreTranslations(lang: string): Promise<any> {
    // This fetches both remote core and remote cust translations.
    const url = GungDownloadUrl(`_gung_i18n/${lang}.json`);
    return this.httpWithoutInterceptors.get(url).pipe(catchError(error => { console.error('Core Translation - error', error); return of({}) })).toPromise();
  }

  protected async getCustTranslations(lang: string): Promise<any> {
    // DEPRECATED - Don't overwrite this, instead add through the UI.
    return {};
  }

  private async getRemoteCustTranslations(lang: string): Promise<any> {
    // This fetches both remote core and remote cust translations.
    const url = `public/json/i18n/customer/${lang}`;
    return this.http.get(url).toPromise();
  }
}
