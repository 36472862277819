import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { S3Image } from '../../services/s3-upload/s3-upload.service';
import { BaseInputDirective } from '../base-input/base-input.directive';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-gung-input-file',
  templateUrl: './gung-input-file.component.html',
  styleUrls: ['./gung-input-file.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungInputFileComponent),
      multi: true
    }
  ]
})
export class GungInputFileComponent extends BaseInputDirective<File | File[]> implements OnInit, OnDestroy {
  @Input()
  public extraInputClass = '';

  @Input()
  public labelBold = false;

  @Input()
  public enableDragDrop = false;

  @Input()
  public enablePreview = false;

  @Input()
  public multiple = false;

  @Input()
  public accept?: string;

  @Input()
  uploadBtn = 'SELECT_FILES';

  // public objectFileImage: File;
  public objectFileImagePreview: S3Image;

  @Output()
  onFileSelect: EventEmitter<File[]> = new EventEmitter<File[]>();

  private unsubscribe: Subject<void> = new Subject();
  @Input() resetEventEmitter: EventEmitter<any>;
  @ViewChild('fileInput')
  public fileInput;

  _uploading: boolean;
  @Input()
  set uploading(value: boolean) {
    this._uploading = value;
    this.disabled = value;
  }
  get uploading() {
    return this._uploading;
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
    this.resetEventEmitter?.pipe(takeUntil(this.unsubscribe)).subscribe(_ => {
      this.fileInput.nativeElement.value = '';
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event) {
    // console.log('Drop', event);
    event.preventDefault();
    event.stopPropagation();
    event.target.style.opacity = 1;
    const files: File[] = event.target.files || event.dataTransfer.files;
    this.value = files;
    this.previewImage(files);
    this.onFileSelect.emit(files);
    if (this.enableDragDrop) {
      // event.target.value = '';
    }
  }

  previewImage(files: File[]) {
    if (!files || files.length === 0) {
      return;
    }
    for (const file of files) {
      const mimeType = file.type;
      if (mimeType.match(/image\/*/) == null) {
        console.log('Only images are supported.');
        // return;
      }

      const reader = new FileReader();
      const imagePath = file;
      reader.readAsDataURL(file);
      reader.onload = _event => {
        const fileDA: S3Image = {
          category: 'Product Image',
          description: null,
          i18n: null,
          index: null,
          pimDimensionIds: null,
          s3ETag: null,
          s3Uri: reader.result as string,
          s3lastModifiedDate: new Date(file.lastModified),
          id: null,
          name: file.name,
          extra: null
        };
        this.objectFileImagePreview = fileDA;
      };
    }
  }

}
