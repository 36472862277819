import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EdiDespatchAdvice } from '../../../models/edi';
import { EdiDispatchAdvicesActionConfigService } from '../../../services/edi-dispatch-advices-action-config/edi-dispatch-advices-action-config.service';
import { EdiDispatchAdviceDetailConfigService } from '../../../services/edi-dispatch-detail-config/edi-dispatch-detail-config.service';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-edi-dispatch-advice-detail',
  templateUrl: './edi-dispatch-advice-detail.component.html',
  styleUrls: ['./edi-dispatch-advice-detail.component.css']
})
export class EdiDispatchAdviceDetailComponent implements OnInit {
  dispatchAdviceId: string;
  dispatchAdviceData: any;
  orderId: string;

  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: EdiDespatchAdvice) => item?.extra?.images
    },
    {
      header: 'ITEM_IDS',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiDespatchAdvice) => {
        let str = '';
        if (item.ids.gungId) {
          str += this.translateService.instant('ID', {}) + ': ' + item.ids.gungId;
        }
        if (item.ids.ean13) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('EAN', {}) + ': ' + item.ids.ean13;
        }
        if (item.ids.upca) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('UPCA', {}) + ': ' + item.ids.upca;
        }
        if (item.ids.buyerId) {
          if (!!str) {
            str += '\n';
          }
          str += this.translateService.instant('BUYER_ID', {}) + ': ' + item.ids.buyerId;
        }
        return str;
      },
      customClass: 'formatted'
    },
    {
      header: 'NAME',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiDespatchAdvice) => item.name
    },
    {
      header: 'PACKAGE_SSCC',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiDespatchAdvice) => {
        let str = '';
        for (const parcel of item.parcels) {
          if (!!str) {
            str += '\n';
          }
          str += parcel.ginIdentifier + ' : ' + this.translateService.instant('QTY', {}) + ' ' + parcel.quantity;
        }
        return str;
      },
      customClass: 'formatted'
    },
    {
      header: 'ORDER_NO',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiDespatchAdvice) => item.documentReferences?.buyerOrder?.id
    },
    {
      header: 'QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiDespatchAdvice) => item.quantities?.actualQuantity
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    public configService: EdiDispatchAdviceDetailConfigService,
    public actionConfig: EdiDispatchAdvicesActionConfigService,
    protected translateService: TranslateService,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.dispatchAdviceId = data.id;
      this.ediService.getDispatchAdvice(this.dispatchAdviceId).subscribe(dispatchAdviceData => {
        this.dispatchAdviceData = dispatchAdviceData.data;
      });
    });
  }
}
