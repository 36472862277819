import { Component, OnInit } from '@angular/core';
import { TranslationManagementListConfigService } from '../../services/translation-management-list-config/translation-management-list-config.service';

@Component({
  selector: 'lib-translation-management-list',
  templateUrl: './translation-management-list.component.html',
  styleUrls: ['./translation-management-list.component.css']
})
export class TranslationManagementListComponent implements OnInit {
  constructor(public translationManagementListConfigService: TranslationManagementListConfigService) {}

  ngOnInit(): void {}
}
