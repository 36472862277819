import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-load-saved-cart-modal',
  templateUrl: './load-saved-cart-modal.component.html',
  styleUrls: ['./load-saved-cart-modal.component.css']
})
export class LoadSavedCartModalComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
