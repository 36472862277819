import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';

@Component({
  selector: 'lib-cart-total-step',
  templateUrl: './cart-total-step.component.html',
  styleUrls: ['./cart-total-step.component.css']
})
export class CartTotalStepComponent extends CheckoutStepComponent implements OnInit {
  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.subscribe(_ => this.stepDone.emit(this.checkout));
  }

  getLabel() {
    return 'CART';
  }
}
