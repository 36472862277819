import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { BaseGridCardV2Component } from '../base-grid-card-v2/base-grid-card-v2.component';
import { Router } from '@angular/router';
import { Product } from '../../models';
import { LocationConfigService } from '../../services/location-config/location-config.service';

@Component({
  selector: 'lib-concept-grid-card-v2',
  templateUrl: './concept-grid-card-v2.component.html',
  styleUrls: ['./concept-grid-card-v2.component.css']
})
export class ConceptGridCardV2Component extends BaseGridCardV2Component implements OnInit, AfterViewInit {
  adjustedScroll = false;
  constructor(protected locationConfigService: LocationConfigService, protected router: Router) {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  ngAfterViewInit() {

  }

  goToDetail(product: Product): void {
    if (this.data.product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      this.router.navigate(['/product/', product.id]);
    }
  }
}
