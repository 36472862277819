import { Component, OnInit, ViewChild, forwardRef } from '@angular/core';
import { CustomerListConfigService } from '../../services/customer-list-config/customer-list-config.service';
import { Customer } from '../../models';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { CustomerSelectionService } from '../../services/customer-selection/customer-selection.service';

@Component({
  selector: 'lib-customer-list',
  template:
    '<h3 translate class="page-title text-center">MY_CUSTOMERS</h3><lib-filter-list #customerExportList [configService]="customerListConfigService"></lib-filter-list>',
  styleUrls: ['./customer-list.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => CustomerSelectionService)
    }
  ]
})
export class CustomerListComponent {
  constructor(public customerListConfigService: CustomerListConfigService) {}
}
