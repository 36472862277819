import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';

@Injectable({
  providedIn: 'root'
})
export class BarcodeScannerConfigService {
  apiKey =
    'DLS2eyJoYW5kc2hha2VDb2RlIjoiMTAxNTE5OTM1LVRYbFhaV0pRY205cVgyUmljZyIsIm9yZ2FuaXphdGlvbklEIjoiMTAxNTE5OTM1IiwiY2hlY2tDb2RlIjo0MDAxMjI0ODl9';
  pauseScanningTime = 1500;
  enablePauseScanningOnRead = false;
  barcodeConfig = {
    playSoundOnScan: true,
    vibrateOnScan: true,
  };

  constructor(protected ngbModal: NgbModal, protected baseViewConfigService: BaseViewConfigService) {}

  pauseScanning(barcodePicker, pauseTime: number = this.pauseScanningTime) {
    if (!this.enablePauseScanningOnRead || barcodePicker.isScanningPaused()) {
      return;
    }
    barcodePicker.pauseScanning();
    setTimeout(() => {
      barcodePicker.resumeScanning();
    }, pauseTime);
  }


  getBarcodeConfig() {
    return this.barcodeConfig;
  }

  openQuickCheckoutModal(modalOptions?: NgbModalOptions): Promise<any> {
    const modalOption: NgbModalOptions = {
      backdrop: true || 'static',
      size: 'lg',
      keyboard: true,
      ...modalOptions
    };

    const ref = this.ngbModal.open(this.baseViewConfigService.getBarcodeQuickCheckoutComponent(), modalOption);
    ref.componentInstance.delegate = this;
    return ref.result;
  }
}
