import { Action } from '@ngrx/store';
import { CartRowIdentification, CartRow } from './types';

export enum ActionTypes {
  Add = '[Cart] Add',
  BulkAdd = '[Cart] BulkAdd',
  Replace = '[Cart] Replace',
  Subtract = '[Cart] Subtract from row',
  SetQuantity = '[Cart] Set quantity for row',
  BulkSetQuantity = '[Cart] Set quantities for rows',
  Remove = '[Cart] Remove row from cart',
  BulkRemove = '[Cart] Remove rows from cart',
  Clear = '[Cart] Clear',
  LoadCart = '[Cart] Load carts',
  CartLoaded = '[Cart] Cart was loaded',
  ClearZeroQuantities = '[Cart] Clear zero quantity rows',
  SetExtra = '[Cart] Set extra',
  BulkSetExtra = '[Cart] Bulk set extra',
  RemoveExtra = '[Cart] Remove extra from row',
  SetProductPartialId = '[Cart] Set productPartialId for row',
  CartReorder = '[Cart] Cart Reorder'
}

export interface QuantityPayload extends CartRowIdentification {
  qty: number;
  position?: number; // Use to set position in the cart (default push to the end)
  rownr?: number; // To send specific row number to ERP
  extra?: { [s: string]: any };
  replace?: boolean;
}

export interface RemoveExtraPayload extends CartRowIdentification {
  value: string;
}

export interface ExtraPayload extends CartRowIdentification {
  extra: { [s: string]: any };
  replace?: boolean;
}

export class BulkSetExtra implements Action {
  type = ActionTypes.BulkSetExtra;
  constructor(public payload: ExtraPayload[]) {}
}

export class RemoveExtra implements Action {
  type: string = ActionTypes.RemoveExtra;
  constructor(public payload: RemoveExtraPayload) {}
}

export class Add implements Action {
  type: string = ActionTypes.Add;
  constructor(public payload: QuantityPayload) {}
}

export class BulkAdd implements Action {
  type: string = ActionTypes.BulkAdd;
  constructor(public payload: QuantityPayload[]) {}
}

export class Replace implements Action {
  type: string = ActionTypes.Replace;
  constructor(public payload: QuantityPayload[]) {}
}

export class Subtract implements Action {
  type: string = ActionTypes.Subtract;
  constructor(public payload: QuantityPayload) {}
}

export class SetQuantity implements Action {
  type = ActionTypes.SetQuantity;
  constructor(public payload: QuantityPayload) {}
}

export class BulkSetQuantity implements Action {
  type = ActionTypes.BulkSetQuantity;
  constructor(public payload: QuantityPayload[]) {}
}

export class Remove implements Action {
  type = ActionTypes.Remove;
  constructor(public payload: CartRowIdentification) {}
}

export class BulkRemove implements Action {
  type = ActionTypes.BulkRemove;
  constructor(public payload: QuantityPayload[]) {}
}

export class Clear implements Action {
  type: string = ActionTypes.Clear;
}

export class ClearZeroQuantities implements Action {
  type: string = ActionTypes.ClearZeroQuantities;
}

export class LoadCart implements Action {
  type: string = ActionTypes.LoadCart;
  constructor(public payload: { cartKey: string }) {}
}

export class CartLoaded implements Action {
  type: string = ActionTypes.CartLoaded;
  constructor(public payload: CartRow[]) {}
}

export class SetExtra implements Action {
  type: string = ActionTypes.SetExtra;
  constructor(public payload: ExtraPayload) {}
}

export interface ProductPartialIdPayload extends CartRowIdentification {
  productPartialId: string;
}

export class SetProductPartialId implements Action {
  type = ActionTypes.SetProductPartialId;
  constructor(public payload: ProductPartialIdPayload) {}
}

export class CartReorder implements Action {
  type: string = ActionTypes.CartReorder;
  constructor(public payload: { productId: string; targetStockId?: string; productPartialId?: string }[]) {}
}
