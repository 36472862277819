import { Injectable } from '@angular/core';
import { SelectionAction, ExportSelection } from 'gung-list';
import { Observable, of } from 'rxjs';
import { Product } from '../../models/product';
import { WhiteboardGridConfigService } from './whiteboard-grid-config.service';
import { WhiteboardSelectionService } from './whiteboard-selection.service';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardProductActionConfigService {
  constructor(
    protected whiteboardGridConfigService: WhiteboardGridConfigService,
    protected selectionService: WhiteboardSelectionService
  ) {}

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return of([
      {
        label: 'EXPORT_TO_WHITEBOARD',
        performAction: (selection: ExportSelection<Product>) => {
          const itemIds = Object.keys(selection.selectedItems);
          this.whiteboardGridConfigService.addProductToGrid(itemIds);
          this.selectionService.clearSelection();
          return of();
        }
      }
    ]);
  }
}
