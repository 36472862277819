import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GungFlow } from './../../../../state/flow/types';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungImageBaseUrl } from 'gung-common';

@Component({
  selector: 'lib-gung-flow-details-html',
  templateUrl: './gung-flow-details-html.component.html',
  styleUrls: ['./gung-flow-details-html.component.css']
})
export class GungFlowDetailsHtmlComponent implements OnInit {
  imageUpload: File;
  isLoading = false;

  constructor(protected customPagesService: CustomPageConfigService) {}
  @Input()
  curentGungFlow: any;

  ngOnInit(): void {}

  onFileChange(event) {
    this.imageUpload = event.target.files && event.target.files[0];
    if (this.imageUpload) {
      const imageObject = {
        index: 0,
        description: 'custompage image - ' + new Date().getTime(),
        category: 'custompage image',
        s3Uri: 'images/no-image.jpg'
      };
      this.isLoading = true;
      const formData = new FormData();
      formData.append('productImage', JSON.stringify(imageObject));
      formData.append('file', this.imageUpload);
      this.customPagesService.uploadImageS3(formData).subscribe(image => {
        this.curentGungFlow.extra.backgroundImage = GungImageBaseUrl + '/' + image.s3Uri;
        this.isLoading = false;
      });
    }
  }
}
