import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { gungAddRemoveSpinner } from '../../../../utils/gung-utils';
import { EdiOrder, EdiOrderItem } from '../../../../models/edi';
import { EdiService } from '../../../../services/edi/edi.service';

@Component({
  selector: 'lib-edi-modal-change-line',
  templateUrl: './edi-modal-change-line.component.html',
  styleUrls: ['./edi-modal-change-line.component.css']
})
export class EdiModalChangeLineComponent implements OnInit {
  @Input()
  ediOrderId: string;

  @Input()
  ediOrderItem: EdiOrderItem;

  @Input()
  index: number;

  validate: boolean;

  constructor(public activeModal: NgbActiveModal, protected ediService: EdiService) {}

  ngOnInit(): void {
    console.log('EdiModalChangeLineComponent [init]', this.ediOrderItem);
  }

  save(element) {
    console.log('EdiModalChangeLineComponent [save]', this.ediOrderItem);
    gungAddRemoveSpinner(element.target);
    this.ediService.postModifyIds(this.ediOrderId, this.index, this.ediOrderItem).subscribe(
      (response: any) => {
        this.activeModal.close({ response, validate: this.validate });
      },
      error => {
        // this.activeModal.close({error});
        gungAddRemoveSpinner(element.target);
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }
}
