import { ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CartService } from '../../../services/cart/cart.service';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { PriceService } from '../../../services/price/price.service';
import { SaveCartModalConfigService } from '../../../services/save-cart-modal-config/save-cart-modal-config.service';
import { CartRow } from '../../../state/cart/types';
import { first } from 'rxjs';
import { ProductService } from '../../../services/products/product.service';
import { Product } from '../../../models';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Availability } from '../../../models/availability';
import { TranslateService } from '@ngx-translate/core';
import { BarcodeScannerConfigService } from '../../../services/barcode-scanner-config/barcode-scanner-config.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { BaseViewConfigService } from '../../../services/base-view-config/base-view-config.service';

@Component({
  selector: 'lib-products-in-cart',
  templateUrl: './products-in-cart.component.html',
  styleUrls: ['./products-in-cart.component.css']
})
export class ProductsInCartComponent implements OnInit, OnDestroy {
  @Input() allProducts;

  @Input() fullscreen = false;

  @Input() stockIds = [];

  barcodeProductDetailsComponent = this.baseViewConfigService.getBarcodeProductDetailsComponent();
  productsInCart: CartRow[];
  protected subs: Subscription[] = [];
  cartContents: NavbarCartModel;
  public cartTotalItems = 0;
  public cartTotalRows = 0;
  porductPrices = [];
  totalInfo = false;
  showProductDetails = false;
  productDetails: Product;
  amountFurniture: number;
  amountOther: number;
  shippingCost: number;
  tags = [1, 2];
  availabilityDetails: Availability;
  currentLang: string;

  public modalRef: NgbModalRef;

  constructor(
    protected cartService: CartService,
    protected navbarCartService: NavbarCartService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected priceService: PriceService,
    protected productService: ProductService,
    protected modalService: NgbModal,
    protected translateService: TranslateService,
    protected barcodeScannerConfigService: BarcodeScannerConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected gungModalService: GungModalService,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.cartService.getCurrentCart().subscribe(productsInCart => {
      this.amountFurniture = 0;
      const idsToGetPrice = [];
      for (const productInCart of productsInCart) {
        const index = this.porductPrices.findIndex(p => p.productId === productInCart.productId);
        if (index < 0) {
          idsToGetPrice.push(productInCart.productId);
        }
      }

      this.productsInCart = JSON.parse(JSON.stringify(productsInCart)).reverse();
      this.productsInCart.map(p => {
        p.extra.product = this.allProducts.find(allP => allP.id === p.productId);
      });
      
      this.priceService
        .getCurrentCustomerPrices(idsToGetPrice)
        .pipe(first())
        .subscribe(prices => {
          this.productsInCart.map(p => {
            p.extra.price = prices.find(price => price.productId === p.productId);
          });
          this.calculateTotals();
        });
    });
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
  }

  removeProduct(row) {
    this.cartService.removeRow(row);
  }

  clearCart() {
    this.gungModalService.openConfirmYesNoModal(null, 'CLEAR_CART_QUESTION', { size: 'sm' }).then(result => {
      if (result) {
        this.cartService.clearCart();
      }
    });
  }

  saveCart(): void {
    this.saveCartModalConfigService.openSaveCartModal();
  }

  seeDetails(id, availability) {
    this.productService.getProduct(id).subscribe(product => {
      this.productDetails = product;
      this.showProductDetails = true;
      this.availabilityDetails = availability;
    });
  }

  quickCheckout() {
    this.barcodeScannerConfigService.openQuickCheckoutModal().then(
      result => {},
      reason => {}
    );
  }

  calculateTotals() {
    const sub = this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
      this.cartTotalRows = contents.rows.length;
      this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
    });

    this.subs.push(sub);
  }
}
