import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdiGlnNumbers } from '../../../models/edi';

@Component({
  selector: 'lib-edi-gln-numbers-edit-modal',
  templateUrl: './edi-gln-numbers-edit-modal.component.html',
  styleUrls: ['./edi-gln-numbers-edit-modal.component.css']
})
export class EdiGlnNumbersEditModalComponent implements OnInit {
  @Input() data: EdiGlnNumbers;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}

  onSave() {
    this.activeModal.close(JSON.stringify(this.data));
  }
}
