import { Component, OnInit, Input, Type } from '@angular/core';
import { CheckoutStepComponent } from '../checkout/steps/base/checkout-step.component';

@Component({
  selector: 'lib-step-indicator',
  templateUrl: './step-indicator.component.html',
  styleUrls: ['./step-indicator.component.scss']
})
export class StepIndicatorComponent implements OnInit {
  @Input()
  currentIndex: number;

  @Input()
  steps: Type<CheckoutStepComponent>[];

  @Input()
  stepIndexVisible: boolean[];

  public stepIndicators: StepIndicator[] = [];

  constructor() {}

  ngOnInit() {
    this.stepIndicators = this.steps
      .filter((_, i) => this.stepIndexVisible[i])
      .map(s => ({
        label: s.prototype.getLabel()
      }));
  }
}

interface StepIndicator {
  label: string;
}
