import { Optional } from '@angular/core';
import { Injectable } from '@angular/core';
import { Angulartics2 } from 'angulartics2';
import { CheckoutObject } from '../../models/checkout';
import { Observable } from 'rxjs';
import { TotalObject } from '../../models/cart';

@Injectable({
  providedIn: 'root'
})
export class GungGoogleTagManagerService {
  cartTotals: TotalObject;

  constructor(@Optional() protected angulartics2: Angulartics2) {}

  orderSubmitted(checkout: CheckoutObject, orderContextId: string): void {
    if (!this.angulartics2 || !orderContextId) {
      return;
    }

    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
    this.angulartics2.eventTrack.next({
      action: 'purchase',
      properties: {
        event: 'purchase',
        value: {
          ecommerce: {
            transaction_id: orderContextId,
            items: checkout.rows.map(r => {
              if (r.extra?.googleAnalytics?.hasDiscount) {
                return {
                  item_id: r.id,
                  item_name: r.extra.googleAnalytics?.name,
                  quantity: r.quantity,
                  discount: r.extra.googleAnalytics.discount,
                  currency: r.extra.googleAnalytics.currency,
                  price: r.extra.googleAnalytics.price
                };
              } else {
                return {
                  item_id: r.id,
                  item_name: r.extra.googleAnalytics?.name,
                  quantity: r.quantity,
                  price: r.extra.googleAnalytics?.price || undefined
                };
              }
            }),
            value: this.cartTotals?.totalValue,
            currency: this.cartTotals?.totalCurrency
          }
        }
      }
    });

    // OLD WAY BELOW
    /*
    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#purchase
    this.angulartics2.eventTrack.next({
      action: 'purchase',
      properties: {
        event: 'purchase',
        value: {
          ecommerce: {
            transaction_id: orderContextId,
            items: checkout.rows.map(r => ({
              item_id: r.id,
              quantity: r.quantity
            }))
          }
        }
      }
    });*/
  }

  cartRowAdded(productId: string, quantity: number, productName: string): void {
    if (!this.angulartics2) {
      return;
    }
    console.log('productName', productName);

    // https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtm#add_to_cart
    this.angulartics2.eventTrack.next({
      action: 'add_to_cart',
      properties: {
        event: 'add_to_cart',
        value: {
          ecommerce: {
            // The value and currency can be added, but don't have time for that at the moment.
            // currency: '',
            // value: 0.0,
            items: [
              {
                item_id: productId,
                quantity: quantity,
                name: productName
              }
            ]
          }
        }
      }
    });
  }

  subscribeTocartTotals(cartTotalsObservable: Observable<TotalObject>): void {
    cartTotalsObservable.subscribe(total => (this.cartTotals = total));
  }
}
