import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-text-input',
  templateUrl: './gung-text-input.component.html',
  styleUrls: ['./gung-text-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungTextInputComponent),
      multi: true
    }
  ]
})
export class GungTextInputComponent extends BaseInputDirective<string> {
  @Input()
  public extraInputClass = '';

  @Input()
  public labelBold = false;

  @Input()
  public footnote = false;
}
