import { Injectable, Input } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { DigitalAsset, DigitalAssetFile, DigitalAssetsService } from '../digital-assets/digital-assets.service';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DownloadService } from '../download/download.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { MetadataService } from '../metadata/metadata.service';
import { AuthService } from '../auth/auth.service';
import { first, map, mergeMap, switchMap } from 'rxjs';
import { DigitalAssetsGridViewComponent } from '../../components/digital-assets/digital-assets-grid-view/digital-assets-grid-view.component';
import {
  DigitalAssetsCommaSplitFilter,
  DigitalAssetsFilter
} from '../digital-assets-list-config/digital-assets-list-config.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalAssetsSearchListConfigService implements ConfigService<DigitalAsset> {
  topFilter = true;
  searchDigitalAssetsIds = [];

  @Input()
  isManagement: boolean;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);
  searchDigitalAssetsObjectsSubject: Subject<any> = new BehaviorSubject(null);
  searchDigital: string;

  constructor(
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected authService: AuthService,
    protected downloadService: DownloadService,
    protected digitalAssetsService: DigitalAssetsService
  ) {}

  getFilters(): ConfigBaseFilter<DigitalAsset>[] {
    return [
      new DigitalAssetsFilter('BRAND', 'brand', this.metadataService, 'TA40', 'TX1'),
      new DigitalAssetsFilter('SEASON', 'season'),
      new DigitalAssetsCommaSplitFilter('TAGS', 'tags')
    ];
  }

  getSortOptions(): ListSortOption<DigitalAsset>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: DigitalAsset) {
    return item.id;
  }

  getSearchTerms(item: DigitalAsset) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<DigitalAsset>[] {
    // if (this.isManagement) {
    //   return [{
    //     getIconClass: () => '',
    //     getListItemComponent: () => DigitalAssetsManagementGridViewComponent,
    //     getListLayoutComponent: () => ListLayoutMultipleComponent,
    //     getName: () => 'PimConceptGrid'
    //   }];
    // }
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => DigitalAssetsGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimConceptGrid'
      }
    ];
  }

  getItems(search): Observable<DigitalAsset[]> {
    if (typeof search === 'string' || search instanceof String) {
      this.searchDigital = search.toLowerCase();
    }
    return this.searchDigitalAssetsObjectsSubject.pipe(
      mergeMap(digitalA => this.digitalAssetsObjectsSubject),
      // filter(ids => !!ids),
      mergeMap(ids => forkJoin([of(ids), this.digitalAssetsService.getDigitalAssets(true).pipe(first())])),
      map(([filterIds, digitalAssets]) => {
        return digitalAssets.filter(d => d.name.toLowerCase().includes(this.searchDigital) && !d.extra.type);
      }),
      map(digitalAssets => {
        return digitalAssets.sort((a, b) => {
          if (!a.extra?.sequence && !b.extra?.sequence) {
            return 0;
          }
          if (a.extra?.sequence && !b.extra?.sequence) {
            return -1;
          }
          if (b.extra?.sequence && !a.extra?.sequence) {
            return 1;
          }
          if (Number(a.extra.sequence) > Number(b.extra.sequence)) {
            return -1;
          }
          if (Number(a.extra.sequence) < Number(b.extra.sequence)) {
            return 1;
          }
          return 0;
        });
      })
    );
  }

  getSelectionActions(): Observable<SelectionAction<DigitalAsset>[]> {
    if (this.isManagement) {
      return of();
    }
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        return of([
          {
            label: 'EXPORT_ASSETS',
            performAction: (selection: ExportSelection<DigitalAsset>) => {
              const itemList = Object.values(selection.selectedItems);
              this.digitalAssetsService.sendDigitalAssetsFiles(itemList, this.gungModalService, user.email);
              return of();
            }
          }
        ]);
      })
    );
  }

  getSelectionActionsButtonTitle(): string {
    if (this.isManagement) {
      return null;
    }
    return 'ACTIONS';
  }

  getNothingFoundTranslateTag() {
    if (this.isManagement) {
      return null;
    }
    return 'NOTHING_FOUND_CONTACT_GUNG';
  }

  getSearchGroupCss() {
    return 'd-none';
  }
}
