<div class="card">
  <div class="card-body">
    <div class="card-title d-flex justify-content-between align-content-center">
      <a [href]="'orders/' + data.key">
        <h5>{{ data.key }}</h5>
      </a>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col-5">
          <lib-gung-text-input [gungTranslate]="'ROWS'" [renderStatic]="true" [ngModel]="data.count">
          </lib-gung-text-input>
        </div>
        <div class="col-5">
          <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.elements[0].orderCreationDate">
          </lib-gung-date-output>
        </div>
        <div class="col-2 display-bottom">
          <button
            [id]="'show_btn_' + data.key"
            class="btn-link btn-icon btn-expand-text display-bottom show-hide"
            (click)="expandInfo = !expandInfo"
          >
            <i class="fas fa-angle-down"></i>
          </button>
        </div>
        <div class="col-12" *ngIf="expandInfo">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>ROW_NR</th>
                <th translate>PRODUCT</th>
                <th translate>DELIVERY_DATE</th>
                <th translate>DELIVERY_STATUS</th>
                <th translate>PRICE</th>
                <th translate>QUANTITY</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let element of data.elements">
                <td>
                  {{ element.orderRowNumber }}
                </td>
                <td>{{ element.productId }} - {{ element.productName }}</td>
                <td>
                  {{ element.orderDeliveryDate | date: dateUtilService.dateFormat }}
                </td>
                <td>
                  {{ element.orderRowDeliveryStatusDescription }}
                </td>
                <td>
                  {{ element.orderRowTotalValueCurrency | gungCurrency: '':'code' }}
                </td>
                <td>
                  {{ element.orderRowTotalQuantity }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
