<div>
  <div class="row">
    <div class="col-12"></div>
  </div>
  <div class="row">
    <div class="col-12">
      <lib-filter-list *ngIf="!!assortmentId" [configService]="pimConceptListConfigService"></lib-filter-list>
    </div>
  </div>
</div>
