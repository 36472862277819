import { Inject, Injectable, Optional } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable, first, throwError } from 'rxjs';
import { catchError } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';
import { OAuthService } from 'angular-oauth2-oidc';

@Injectable({
  providedIn: 'root'
})
export class BackendInterceptor implements HttpInterceptor {

  loginModalOpen: boolean = false;

  constructor(
    protected modalService: NgbModal,
    @Optional()
    protected oauthService: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {}


  protected env: object = {}; // = environment;
  getBaseUrl(): string {
    return 'http://localhost/';
  }
  public getEnvironmentProperty(prop: string): any {
    if (prop && this.env && Object.keys(this.env).includes(prop)) {
      return this.env[prop];
    }
    return null;
  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // In some cases we want to skip the redirect to a base url entirely.
    if (this.shouldSkipBaseUrlRedirect(req)) {
      return next.handle(req).pipe(
        catchError(err => {
          return throwError(err);
        })
      );
    }

    // If oauth2 is enabled, and we have an access token, use that instead of default.
    if (this.environment?.oauth2Enabled && this.oauthService.getAccessToken()) {
      return this.redirectOauth2(req, next);
    }

    // Default behaviour, use the base url.
    let apiReq = req.clone({ url: `${this.getBaseUrl()}${req.url}`, withCredentials: true });
    if (req.url.startsWith('./')) {
      apiReq = req.clone({ url: `${req.url}`, withCredentials: true });
    }
    return next.handle(apiReq).pipe(
      catchError(err => {
        if (err.status === 401) {
          // Request login
          if (!this.loginModalOpen) {
            this.showLoginModal();
          }
        }
        return throwError(err);
      })
    );
  }

  private shouldSkipBaseUrlRedirect(req: HttpRequest<any>): boolean {
    // If we don't have an environment, we can't skip the redirect
    if (!this.environment) {
      return false;
    }

    const skipRedirectUrls = this.environment.skipBaseUrlRedirectUrls || [];
    return skipRedirectUrls.find(u => req.url.startsWith(u));
  }

  private redirectOauth2(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.oauthService.getAccessToken();

    // Clone the request and replace the original headers with
    // cloned headers, updated with the authorization.
    const authReq = req.clone({
      url: `${this.getBaseUrl()}${req.url}`,
      headers: req.headers.set('Authorization', 'Bearer ' + authToken).set('X-Requested-With', 'XMLHttpRequest')
    });

    return next.handle(authReq).pipe(
      catchError(err => {
        return throwError(err);
      })
    );
  }

  protected showLoginModal(refreshPage: boolean = true) {
    this.loginModalOpen = true;
    this.modalService
      .open(LoginModalComponent, { size: 'lg', backdrop: 'static', keyboard: false })
      .closed.pipe(first())
      .subscribe(_ => {
        this.loginModalOpen = false;
        if (refreshPage) {
          location.reload();
        }
      });
  }
}
