import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs';
import { AuthService } from './auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class OrderConfirmationContactsService {
  constructor(protected authService: AuthService) {}

  getOrderConfirmationContacts(): Observable<OrderConfirmationContact[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      map(user => ({
        name: user.name,
        email: user.email
      })),
      map(contact => [contact])
    );
  }
}

export interface OrderConfirmationContact {
  name: string;
  email: string;
}
