import { Injectable, Input, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { format } from 'date-fns';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { first, switchMap, tap } from 'rxjs';
import { ItemProperties } from '../../components/assortment-tree-navigation/assortment-tree-navigation.component';
import { PimConceptGridViewComponent } from '../../components/pim-concept/pim-concept-grid-view/pim-concept-grid-view.component';
import { GungBase } from '../../models';
import { AuthService } from '../auth/auth.service';
import { DownloadService } from '../download/download.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductService } from '../products/product.service';

export interface PimConcept extends GungBase {
  productType?: string;
  proxyId?: any;
  subProductIds?: string[];
  image?: any;
}

class ItemTypeFilterPathField extends SimpleConfigBaseFilter<PimConcept> {
  private itemValue;

  constructor(
    protected filterTitle: string,
    protected path: string,
    protected notFoundString: string,
    protected metadataService?: MetadataService,
    protected tableName?: string,
    protected valueName?: string,
    protected multiValues?: boolean,
    protected isRange?: boolean,
    protected typeValue?: string
  ) {
    super();
    this.type = this.isRange ? 'RangeConfigBaseFilter' : 'SimpleConfigBaseFilter';
  }

  getName(): string {
    return this.filterTitle;
  }

  getOptionIds(item: PimConcept): string[] {
    this.itemValue = undefined;
    if (item.hasOwnProperty('product')) {
      // Concept detail Filter
      item = (item as any).product;
    }
    if (!item) {
      return [this.notFoundString];
    }

    let valuesAsArray = [];
    const splitPath = ['extra', ...this.path.split('.')];

    const reducedValue = this.getReduceValueObject(splitPath, item);

    if (!reducedValue) {
      return [this.notFoundString];
    } else if (!this.multiValues) {
      if (reducedValue.code) {
        // nested values (used in sorting)
        valuesAsArray = [reducedValue.code];
      } else {
        valuesAsArray = [reducedValue];
      }
    } else {
      valuesAsArray = reducedValue;
    }
    this.itemValue = reducedValue;

    const mappedValues = valuesAsArray.map(value => {
      if (!value) {
        return this.notFoundString;
      } else {
        if (this.valueName && typeof value === 'object' && Object.keys(value).indexOf(this.valueName) >= 0) {
          return value[this.valueName];
        } else if (this.typeValue === 'date') {
          const valueDate = new Date(value);
          return format(valueDate, 'yyyy-MM-dd');
        } else {
          return value;
        }
      }
    });
    return [...new Set<any>(mappedValues)];
  }

  getOptionName(key: string): string {
    if (!key) {
      return '';
    }
    if (!this.tableName || !this.valueName) {
      return key;
    }
    if (
      this.itemValue &&
      this.valueName &&
      typeof this.itemValue === 'object' &&
      Object.keys(this.itemValue).indexOf(this.valueName) >= 0
    ) {
      return this.itemValue[this.valueName];
    }
    return this.metadataService.getMetadataValue(this.tableName, this.valueName, key);
  }

  getSortingIndex(): string {
    if (this.itemValue && this.itemValue.properties) {
      return this.itemValue.properties.sorting; // sorting index
    }
    return;
  }

  getReduceValueObject(splitPath, item): any {
    const reducedValue = splitPath.reduce((oldValue, currentValue) => {
      if (!oldValue) {
        return oldValue;
      }
      if (oldValue[currentValue]) {
        return oldValue[currentValue];
      } else {
        return null;
      }
    }, item);
    if (
      !reducedValue ||
      (typeof reducedValue === 'object' &&
        Object.keys(reducedValue).length === 0 &&
        reducedValue.constructor === Object)
    ) {
      // Empty Object found
      return null;
    }
    return reducedValue;
  }
}

class PimNumberFilter extends SimpleConfigBaseFilter<PimConcept> {
  constructor(protected filterTitle: string, protected field: string, protected multiValues?: boolean) {
    super();
  }

  getName(): string {
    return this.filterTitle;
  }

  getOptionIds(item: PimConcept): string[] {
    let ids = ['' + item.extra.pim[this.field]];
    if (this.multiValues) {
      if (Array.isArray(item.extra.pim[this.field])) {
        ids = (item.extra.pim[this.field] as any[]).map(value => '' + value);
      }
    }
    return ids;
  }

  getOptionName(key: string): string {
    return key;
  }

  sort = (a, b) => parseFloat(a) - parseFloat(b);
}

@Directive()
@Injectable({
  providedIn: 'root'
})
export class PimConceptListConfigService implements ConfigService<PimConcept> {
  topFilter = true;

  @Input()
  assortmentId: string;

  @Input()
  itemProperties: ItemProperties[];

  @Input()
  selectedIds: string[];

  constructor(
    protected productService: ProductService,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected downloadService: DownloadService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService
  ) {}

  getFilters(): ConfigBaseFilter<PimConcept>[] {
    const filters = [];
    const multiValues = false; // type === 'PRODUCTS_GROUPED'
    for (const property of this.itemProperties) {
      if (property.isFilter) {
        const isRange = property.filterType && property.filterType === 'RANGE';
        const field = property.path.split('.')[1];
        const translationKey = property.translationKey;
        // Check if translation exists
        const undefinedString = 'NO_' + translationKey + '_DEFINED';
        const undefinedTranslation =
          this.translateService.instant(undefinedString) === undefinedString ? 'UNDEFINED' : undefinedString;
        if (property.type === 'number') {
          filters.push(new PimNumberFilter(translationKey, field, multiValues));
        } else if (property.type === 'metadata') {
          if (property.metaReference) {
            filters.push(
              new ItemTypeFilterPathField(
                translationKey,
                property.path,
                undefinedTranslation,
                this.metadataService,
                property.metaReference.table,
                property.metaReference.field,
                multiValues,
                isRange
              )
            );
          } else if (property.metadata && property.metadata.split('.').length === 2) {
            const splitmeta = property.metadata.split('.');
            const metaTable = splitmeta[0];
            const metaField = splitmeta[1];
            filters.push(
              new ItemTypeFilterPathField(
                translationKey,
                property.path,
                undefinedTranslation,
                this.metadataService,
                metaTable,
                metaField,
                multiValues,
                isRange
              )
            );
          } else {
            filters.push(
              new ItemTypeFilterPathField(
                translationKey,
                property.path,
                undefinedTranslation,
                this.metadataService,
                property.metadata,
                'description',
                multiValues,
                isRange
              )
            );
          }
        } else {
          filters.push(
            new ItemTypeFilterPathField(
              translationKey,
              property.path,
              undefinedTranslation,
              null,
              null,
              null,
              multiValues,
              isRange,
              property.type
            )
          );
        }
      }
    }
    return filters;
  }

  getSortOptions(): ListSortOption<PimConcept>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: PimConcept) {
    return item.id;
  }

  getSearchTerms(item: PimConcept) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<PimConcept>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PimConceptGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimConceptGrid'
      }
    ];
  }

  getItems(): Observable<PimConcept[]> {
    return this.productService.getProductsByAssortmentUnfiltered(this.assortmentId);
  }

  getSelectionActions(): Observable<SelectionAction<PimConcept>[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        return of([
          {
            label: 'EXPORT_IMG',
            performAction: (selection: ExportSelection<PimConcept>) => {
              const itemList = Object.values(selection.selectedItems);
              if (itemList.length === 0) {
                return;
              }
              const imageS3Uri: string[] = [];
              itemList.forEach(concept => {
                if (concept.extra.images && concept.extra.images.length > 0) {
                  imageS3Uri.push(...concept.extra.images.map(image => image.s3Uri));
                }
              });
              // don't remove - prevents image size cap of being reached
              // const imageUrls = imageS3Uri.map(s3Uri => 'fit-in/5000x5000/filters:quality(100):background_color(white)/' + s3Uri);
              const imageUrls = imageS3Uri.map(s3Uri => s3Uri); // for urls without fit-in we will use cdn2.gung.io that does not use scaling function, hence no image size cap.
              this.gungModalService.openConfirmExportModal(user.email || '').then(({ email }) => {
                if (email && (email as string).trim().length > 0) {
                  // CALL SERVICE TO SEND EMAIL
                  this.downloadService.postGenerateImageZipFromUrlList(email, imageUrls).subscribe();
                }
              });
              return of();
            }
          }
        ]);
      })
    );
  }

  getSelectionActionsButtonTitle(): string {
    return 'ACTIONS';
  }
}
