import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../models/pim';
import { PimService } from '../../../../services/pim/pim.service';
import { first } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'lib-pim-product-proxy-id',
  templateUrl: './pim-product-proxy-id.component.html',
  styleUrls: ['./pim-product-proxy-id.component.css']
})
export class PimProductProxyIdComponent implements OnInit {
  variantsToSave: Set<string> = new Set<string>();

  @Input()
  onParentSave: Subject<void>;

  @Input()
  pimProduct: Pim;

  pimProducts: Pim[];

  isActuator = false;

  constructor(protected pimService: PimService, protected authService: AuthService) {}

  onProxyIdChanged(productId: string) {
    this.variantsToSave.add(productId);
  }

  ngOnInit(): void {
    this.pimService
      .getPimProductsFromMasterId(this.pimProduct.masterId)
      .pipe(first())
      .subscribe(products => {
        this.pimProducts = products.sort((a, b) => {
          if (a.id > b.id) {
            return 1;
          } else if (a.id === b.id) {
            return 0;
          } else {
            return -1;
          }
        });
      });
    this.authService.getCurrentUser().subscribe(user => {
      this.isActuator = user.roles.indexOf('ACTUATOR') > -1;
    });

    this.onParentSave.subscribe(_ => {
      this.saveChangedVariants();
    });
  }

  saveChangedVariants() {
    const productsToSave: Pim[] = this.pimProducts.filter(p => this.variantsToSave.has(p.id));
    if (productsToSave.length <= 0) {
      return;
    }

    this.pimService.postPimProductsBatch(productsToSave).subscribe(p => {});
  }
}
