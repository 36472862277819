<div class="container">
  <div class="row pb-4">
    <div class="col-12 d-flex justify-content-between">
      <div>
        <button *ngIf="listUser === false" (click)="listUser = true" class="btn btn-outline-primary ml-2">
          {{ 'BACK' | translate }}
        </button>
      </div>
      <h2 class="mt-0" translate>USERS</h2>
      <div class="btn-group">
        <div ngbDropdown placement="bottom-right" class="d-inline-block mr-2">
          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle translate>ACTIONS</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
            <button ngbDropdownItem (click)="openCreateUser()">{{ 'ADD_USER' | translate }}</button>
            <button *ngIf="listUser === true" ngbDropdownItem (click)="listUser = false">
              {{ 'IMPORT' | translate }}
            </button>
            <a ngbDropdownItem target="_blank" href="{{ backendBaseUrl }}download/user-activity-excel">{{
              'DOWNLOAD_LIST_LATEST_LOGIN' | translate
            }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <ng-container *ngIf="listUser; else importUser">
        <lib-filter-list [configService]="usersListConfigService"></lib-filter-list>
      </ng-container>

      <ng-template #importUser>
        <lib-import-users></lib-import-users>
      </ng-template>
    </div>
  </div>
</div>
