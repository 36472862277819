import { Inject, Injectable, Optional } from '@angular/core';
import { PimTemplateProperties } from 'gung-list';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { gungComparatorHelper, gungGetPropertyOfProduct } from '../../utils/gung-utils';
import { MetadataService } from '../metadata/metadata.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ColumnSortSelectionService {

  public enabledColumnSort = this.environment?.enabledColumnSort || false;

  private selectedColumnSort: Subject<PimTemplateProperties> = new BehaviorSubject<PimTemplateProperties>(null);
  private selectedPathSort: Subject<{ path: string, sort: 1 | -1 }> = new BehaviorSubject<{ path: string, sort: 1 | -1 }>({ path: null, sort: null }); // General use need to implment in the list config service
  public currentLang = this.translateService.currentLang;

  constructor(
    @Optional()
    @Inject('environment')
    protected environment,
    @Optional()
    public metadataService: MetadataService,
    @Optional()
    protected translateService: TranslateService
  ) { }

  public setSelectedColumnSort(headerKey: PimTemplateProperties) {
    this.selectedColumnSort.next(headerKey);
  }

  public getSelectedColumnSort(): Observable<PimTemplateProperties> {
    return this.selectedColumnSort.asObservable();
  }

  public clearSelectedColumnSort() {
    this.selectedColumnSort.next(null);
    this.selectedPathSort.next(null);
  }

  public sortItemsByColumn(data: any[], headerKey: PimTemplateProperties, noExtraAdditionPath: boolean = false): any[] {
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    const metadata = headerKey.type === 'metadata';
    return data.sort((a, b) => {
      const aValue2 = metadata ? gungGetPropertyOfProduct(headerKey, a, this.currentLang, this.metadataService) : this.getValue(headerKey.path, a, noExtraAdditionPath);
      const bValue2 = metadata ? gungGetPropertyOfProduct(headerKey, b, this.currentLang, this.metadataService) : this.getValue(headerKey.path, b, noExtraAdditionPath);
      return gungComparatorHelper(aValue2, bValue2, ascSort);
      const aValue = this.getValue(headerKey.path, a);
      const bValue = this.getValue(headerKey.path, b);
      if (!aValue && !bValue) {
        return 0;
      }
      if (!aValue) {
        return -1 * ascSort;
      }
      if (!bValue) {
        return 1 * ascSort;
      }
      let aNumber;
      let bNumber;
      if (typeof aValue === 'number') {
        aNumber = aValue;
      }
      if (typeof bValue === 'number') {
        bNumber = bValue;
      }
      if (!aNumber && !isNaN(aValue.replace(',', '.'))) {
        aNumber = Number(aValue.replace(',', '.'));
      }
      if (!bNumber && !isNaN(bValue.replace(',', '.'))) {
        bNumber = Number(bValue.replace(',', '.'));
      }
      // Split string, check if number
      if (!aNumber && !bNumber) {
        const aFirstString = aValue.split(' ')?.[0];
        const bFirstString = bValue.split(' ')?.[0];
        if (!isNaN(aFirstString.replace(',', '.')) && !isNaN(bFirstString.replace(',', '.'))) {
          aNumber = Number(aFirstString.replace(',', '.'));
          bNumber = Number(bFirstString.replace(',', '.'));
        }
      }
      // Number
      if (aNumber && bNumber) {
        if (Number(aNumber) < Number(bNumber)) {
          return -1 * ascSort;
        }
        if (Number(bNumber) < Number(aNumber)) {
          return 1 * ascSort;
        }
        return 0;
      }
      // String
      return aValue.localeCompare(bValue) * ascSort;
    });
  }

  protected getValue(path, item, noExtraAdditionPath: boolean = false) {
    if (path === 'id' || path === 'name') {
      return item[path];
    }
    if (path === 'availabilities') {
      const keys = Object.keys(item.extra.availabilities);
      if (!keys || keys.length === 0) {
        return 0;
      }
      return item.extra.availabilities[keys[0]].currentAvailability;
    }
    if (path === 'price') {
      // TODO: need the price on the row data from the endpoint
      // item.price.customerGrossPrice.value
    }
    const splitPath = noExtraAdditionPath ? path.split('.') : ['extra', ...path.split('.')];

    const reducedValue = splitPath.reduce((oldValue, currentValue) => {
      if (!oldValue) {
        return oldValue;
      }
      if (oldValue[currentValue]) {
        return oldValue[currentValue];
      } else {
        return null;
      }
    }, item);
    if (
      !reducedValue ||
      (typeof reducedValue === 'object' &&
        Object.keys(reducedValue).length === 0 &&
        reducedValue.constructor === Object)
    ) {
      // Empty Object found
      return null;
    }
    if (Array.isArray(reducedValue)) {
      const valueArray = reducedValue.map(v => v.description).join(', ');
      return valueArray;
    }
    return reducedValue;
  }

  public setSelectedPathSort(path: { path: string, sort: 1 | -1 }) {
    this.selectedPathSort.next(path);
  }

  public getSelectedPathSort(): Observable<{ path: string, sort: 1 | -1 }> {
    return this.selectedPathSort.asObservable();
  }
}

export class ProductNamePimTemplateProperties implements PimTemplateProperties {
  isDisplay = undefined;
  isDisplayGrid = undefined;
  isDisplayList = undefined;
  isDisplayDetails = undefined;
  isFilter = undefined;
  path = 'name';
  translationKey = undefined;
  type = undefined;
  metaReference = undefined;
  metadata = undefined;
  sort = undefined;
};

export class ProductIdPimTemplateProperties implements PimTemplateProperties {
  isDisplay = undefined;
  isDisplayGrid = undefined;
  isDisplayList = undefined;
  isDisplayDetails = undefined;
  isFilter = undefined;
  path = 'id';
  translationKey = undefined;
  type = undefined;
  metaReference = undefined;
  metadata = undefined;
  sort = undefined;
};

export class AvailabilityPimTemplateProperties implements PimTemplateProperties {
  isDisplay = undefined;
  isDisplayGrid = undefined;
  isDisplayList = undefined;
  isDisplayDetails = undefined;
  isFilter = undefined;
  path = 'availabilities';
  translationKey = undefined;
  type = undefined;
  metaReference = undefined;
  metadata = undefined;
  sort = undefined;
};

// TODO: not working
export class PricePimTemplateProperties implements PimTemplateProperties {
  isDisplay = undefined;
  isDisplayGrid = undefined;
  isDisplayList = undefined;
  isDisplayDetails = undefined;
  isFilter = undefined;
  path = 'price.customerGrossPrice.value'; // price if from service and not from product
  translationKey = undefined;
  type = undefined;
  metaReference = undefined;
  metadata = undefined;
  sort = undefined;
};
