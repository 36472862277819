import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Customer } from '../../models';
import { ListItemRendererComponent } from 'gung-list';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { Router } from '@angular/router';
import { CustomerActionConfigService } from '../../services/customer-action-config.service';

@Component({
  selector: 'lib-customer-list-card',
  templateUrl: './customer-list-card.component.html',
  styleUrls: ['./customer-list-card.component.css']
})
export class CustomerListCardComponent extends ListItemRendererComponent<Customer> implements OnInit, AfterViewInit {
  constructor(
    public selectCustomerService: SelectedCustomerService,
    protected router: Router,
    public actionButtonsConfig?: CustomerActionConfigService
  ) {
    super();
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  selectCustomer(id) {
    this.router.navigate(['customers', id]);
    this.selectCustomerService.selectCustomer(id);
  }

  selectCustomerWithoutNavigate(id) {
    this.selectCustomerService.selectCustomer(id);
  }
}
