import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { EdiGungMessages } from '../../../models/edi';
import { EdiMessagesListConfigService } from '../../../services/edi-messages-list-config/edi-messages-list-config.service';

@Component({
  selector: 'lib-edi-messages',
  template:
    '<h3 class="d-block page-title text-center" translate>EDI - Messages</h3><lib-filter-list #list [configService]="configService"></lib-filter-list>'
})
export class EdiMessagesComponent implements OnInit {
  @ViewChild('list', { static: false }) list: FilterListComponent<EdiGungMessages>;

  constructor(public configService: EdiMessagesListConfigService) {}

  ngOnInit() {}
}
