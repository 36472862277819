
<div [hidden]="isLoading" class="row" [ngClass]="{ 'pt-4 pb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData" class="col-6 col-md-4 col-lg-3 mb-2" [ngClass]="childColClassCss">
    <ng-template libCustomHost></ng-template>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
<div [hidden]="!isLoading" class="text-center py-3">
  <i class="fas fa-spinner fa-spin fa-5x"></i>
</div> 

