<div class="container">
  <div class="row border-between">
    <div class="col user-col-min">
      <h5 translate>USER_DETAILS</h5>
      <div class="pt-3">
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="USERNAME"
              [(ngModel)]="currentUser.username"
              [renderStatic]="true"
              [placeholder]="'USERNAME' | translate"
            >
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input gungTranslate="NAME" [(ngModel)]="currentUser.name" [placeholder]="'NAME' | translate">
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="ASSORTMENT"
              [(ngModel)]="currentUser.assortment"
              [placeholder]="'ASSORTMENT' | translate"
            ></lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-password-input
              gungTranslate="PASSWORD"
              [placeholder]="'PASSWORD' | translate"
              [passwordRequirement]="passwordRequirements"
              [(ngModel)]="password"
              (validPassword)="isValid($event)"
              autocomplete="new-password"
            >
            </lib-gung-password-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="EMAIL"
              [(ngModel)]="currentUser.email"
              [placeholder]="'EMAIL' | translate"
            >
            </lib-gung-text-input>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pb-2">
            <lib-gung-text-input
              gungTranslate="PHONE"
              [(ngModel)]="currentUser.phone"
              [placeholder]="'PHONE' | translate"
            >
            </lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>

    <div class="col user-col-min">
      <h5 translate>ROLES</h5>
      <div class="pt-3 row">
        <div class="col-12">
          <div
            class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
            *ngFor="let role of userRoles"
          >
            <label class="form-check-label" [for]="role.id">
              {{ role.id | translate | titlecase }}
            </label>
            <input
              type="checkbox"
              class="scale12"
              (change)="toogleRole(role.id, role.selected)"
              [id]="role.id"
              [checked]="role.selected"
            />
          </div>
        </div>
      </div>

      <h5 translate>GROUPS</h5>
      <div class="row">
        <div class="col-12">
          <h6 class="mt-3">{{ 'EDIT_USER_GROUPS_DESCRIPTION' | translate }}</h6>
        </div>
        <div class="col-12" *ngIf="userGroups && userGroups.length > 0">
          <div
            class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
            *ngFor="let group of userGroups"
          >
            <label class="form-check-label" [for]="group.key">
              {{ group.key | translate | titlecase }}
            </label>
            <input
              type="checkbox"
              class="scale12"
              (change)="toogleGroup(group.key, group.selected)"
              [id]="group.key"
              [checked]="group.selected"
            />
          </div>
        </div>
      </div>
    </div>

    <div class="col user-col-min">
      <h5 translate>MULTI_STOCKS</h5>
      <div class="pt-3">
        <div class="row">
          <div class="col-12">
            <div
              class="form-check pt-1 d-flex align-items-center justify-content-between"
              *ngFor="let multiStocksId of multiStocksIds; let i = index; trackBy: trackByFn"
            >
              <label class="form-check-label">
                {{ multiStocksId }}
              </label>
              <button type="button" class="btn btn-link p-1" (click)="remove(i)"><i class="fas fa-times"></i></button>
            </div>
          </div>
        </div>

        <div class="row pb-4">
          <div class="col">
            <input type="text" class="form-control form-control-sm" placeholder="0" #value />
          </div>
          <div class="col-6 col-md-3">
            <button type="button" class="btn btn-primary btn-block btn-sm" (click)="add(value)">
              {{ 'ADD' | translate }}
            </button>
          </div>
        </div>
        <div *ngIf="currentUser.active !== undefined">
          <h5 translate>OPTIONS</h5>
          <div class="row">
            <div class="col-12">
              <div class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between">
                <label class="form-check-label" for="ACTIVE">
                  {{ 'ACTIVE' | translate }}
                </label>
                <input type="checkbox" class="scale12" [(ngModel)]="currentUser.active" id="ACTIVE" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
