<h3 class="text-center" translate>EDIT_CONFIGURATIONS</h3>

<div class="d-flex flex-wrap">
  <div *ngFor="let config of configurations; let i = index" style="width: 33%" class="p-2">

    <lib-gung-json-editor
      [gungTranslate]="config.name || config.id"

      [ngModel]="configurationsStrings[i]"
      (onSave)="save($event)"
    ></lib-gung-json-editor>

  </div>

</div>

