<div class="modal-content" *ngIf="savedCart; else loading">
  <div class="modal-header">
    <h4 translate>
     ADD
    </h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>PRODUCT_NAME</th>
                <th translate>QUANTITY</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of editRows; let rowIndex = index">
                <td width="40%">
                  <lib-product-select-dropdown
                    (productSelected)="selectProduct($event, row)" [formatterFunction]="productSearchFormatter"></lib-product-select-dropdown>
                </td>
                <td>
                  <input *ngIf="productSelected" class="form-control" type="number" [(ngModel)]="row.qty"
                    (ngModelChange)="setQuantity($event, row)" /> 
                </td>
              
                <td width="5%">
                  <i class="fa fa-trash-alt clickable" (click)="removeRow(rowIndex)"></i>
                </td>
              </tr>
              <tr>
                <td colspan="5">
                  <button class="btn btn-primary btn-block" (click)="addNewRow()" translate>ADD_ROW</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="submitRows()" translate>
      CLOSE
    </button>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>