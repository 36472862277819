import {
  AfterViewInit,
  Component,
  ComponentFactoryResolver,
  OnInit,
  QueryList,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CustomHostDirective } from 'gung-list';
import { Product } from '../../models';
import { AssortmentService } from '../../services/assortment.service';
import { AuthService } from '../../services/auth/auth.service';
import { AvailabilityService } from '../../services/availability/availability.service';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { CartService } from '../../services/cart/cart.service';
import { CloudPimPropertiesHelperService } from '../../services/cloud-pim-properties-helper/cloud-pim-properties-helper.service';
import { ConceptDetailProductListV2ConfigService } from '../../services/concept-detail-product-list-v2-config/concept-detail-product-list-v2-config.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { MergedI18nService } from '../../services/mergedi18n/merged-i18n.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { PriceService } from '../../services/price/price.service';
import { ProductMatrixModalService } from '../../services/product-matrix-modal/product-matrix-modal.service';
import { ProductService } from '../../services/products/product.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { ConceptDetailV2TopDetailsComponent } from '../concept-detail-v2-top-details/concept-detail-v2-top-details.component';
import { ProductDetailV2Component } from '../product-detail-v2/product-detail-v2.component';

export interface ProductConcept extends Product {
  name: string;
  productType: string;
  proxyId: any;
  subProductIds: string[];
}

@Component({
  selector: 'lib-concept-detail-v2',
  templateUrl: './concept-detail-v2.component.html',
  styleUrls: ['./concept-detail-v2.component.css']
})
export class ConceptDetailV2Component extends ProductDetailV2Component implements AfterViewInit, OnInit {
  @ViewChildren(CustomHostDirective)
  private viewChildren: QueryList<CustomHostDirective>;

  product: ProductConcept;
  public showConceptDetails: boolean;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected productMatrixModalService: ProductMatrixModalService,
    protected mergedI18nService: MergedI18nService,
    protected selectedCustomerService: SelectedCustomerService,
    protected modalService: NgbModal,
    protected cartService: CartService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    protected baseViewConfigService: BaseViewConfigService,
    protected cloudPimPropertyHelper: CloudPimPropertiesHelperService,
    public configService: ConceptDetailProductListV2ConfigService,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      cloudPimPropertyHelper,
      gungFlowService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.showConceptDetails = !!this.baseViewConfigService?.getConceptDetailV2ProductListViewComponent?.();
    if (!!this.viewChildren && !!this.viewChildren.first) {
      this.renderItemComponent(this.viewChildren.first.viewContainerRef);
    }
  }
  ngAfterViewInit(): void {
    /* 
      pushed to oninit to remove error If doesn't work in some project try uncomment this
    */
    if (!!this.viewChildren && !!this.viewChildren.first) {
      this.renderItemComponent(this.viewChildren.first.viewContainerRef);
    }
  }
  protected mapData(product, availability, price, user, flow) {
    this.configService.conceptProduct = product;
    super.mapData(product, availability, price, user, flow);
    if (!!this.viewChildren && !!this.viewChildren.first) {
      this.renderItemComponent(this.viewChildren.first.viewContainerRef);
    }
  }

  renderItemComponent(containerRef: ViewContainerRef) {
    containerRef.clear();
    const factory = this.componentFactoryResolver.resolveComponentFactory(
      this.baseViewConfigService.getConceptDetailTopDetailsComponent()
    );
    const componentRef = containerRef.createComponent(factory);
    const typed = componentRef.instance as ConceptDetailV2TopDetailsComponent; // maybe have any here?
    typed.concept = this.product;
  }
}
