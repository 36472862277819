<div *ngIf="loaded; else loading" class="contianer">
    <div class="row">
        <div class="col-2">
            <lib-gung-checkbox-input-horizontal gungTranslate="ENABLE" [(ngModel)]="myCompanyFooter.enabled" (ngModelChange)="enable($event)"/>
        </div>
    </div>
    @if(myCompanyFooter.enabled) {
        <div class="row">
            <div class="col-12 mb-2 d-flex align-items-end justify-content-between">
                <div class="d-flex align-items-end">
                    <lib-gung-text-input gungTranslate="BACKGROUND" [(ngModel)]="myCompanyFooter.backgroundColor" />
                    <i
                      class="fas fa-square fa-3x ml-2 border rounded"
                      (click)="colorPicker1.click()"
                      [ngStyle]="{ color: myCompanyFooter.backgroundColor }"
                    ></i>
                    <input #colorPicker1 type="color" [(ngModel)]="myCompanyFooter.backgroundColor" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                    <lib-gung-number-input gungTranslate="COLSPAN" [(ngModel)]="colspan" stepAmount="1" minValue="1" maxValue="4" extraFormClass="mb-0"/>
                    <button type="button" class="btn btn-primary" (click)="addColumn(colspan)" translate>ADD_COLUMN</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <h3 class="mb-5" translate>PC_VIEW</h3>
                <lib-footer [myCompanyFooter]="myCompanyFooter" edit="true"></lib-footer>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <hr>
                <h3 class="mb-5" translate>MOBILE_VIEW</h3>
                <lib-footer [myCompanyFooter]="myCompanyFooter" edit="true" mobileView="true"></lib-footer>
            </div>
        </div>
    }
</div>
<ng-template #loading>
    <div class="text-center w-100">
        <i class="fa fa-spinner fa-spin fa-3x"></i>
        <br>LOADING...
    </div>
</ng-template>