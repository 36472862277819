import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { Whiteboard } from '../../types';
import { WhiteboardService } from '../../services/whiteboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth/auth.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { WhiteboardManagementActionButtonConfigService } from '../../services/whiteboard-management-action-button-config.service';

@Component({
  selector: 'lib-whiteboard-management',
  templateUrl: './whiteboard-management.component.html',
  styleUrls: ['./whiteboard-management.component.scss']
})
export class WhiteboardManagementComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  whiteboards: Whiteboard[] = [];
  isAdmin = false;
  blockBtns = true;

  constructor(
    protected whiteboardService: WhiteboardService,
    protected authService: AuthService,
    public actionButtonConfig: WhiteboardManagementActionButtonConfigService
  ) {}

  ngOnInit(): void {
    this.authService
      .getRoles()
      .pipe(
        first(),
        tap(roles => (this.isAdmin = roles.includes('ADMIN')))
      )
      .subscribe(_ => {});

    this.whiteboardService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(whiteboards => (this.whiteboards = whiteboards));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
