<div *ngIf="data">
  <div class="row" *ngFor="let ediOrder of data; index as i; trackBy: trackByFn">
    <div class="col-12 card-body">
      <div class="row">
        <div class="col-12 card-title">
          <h5 class="d-inline cursor-pointer">
            <a [routerLink]="ediOrder.id">
              <i class="fas fa-eye pr-1" style="font-size: larger"></i>
              {{ ediOrder.documentReferences.buyerOrder.id }}
              <span *ngIf="!!ediOrder.erpIds">- {{ ediOrder.erpIds }}</span> -
              <lib-edi-send-status [message]="ediOrder"></lib-edi-send-status>
            </a>
          </h5>
          <div class="float-right">
            <lib-action-btn [(item)]="data[i]" [config]="actionConfigService"></lib-action-btn>
          </div>
        </div>
        <!-- <div class="col-12 col-md-4">
            <lib-gung-text-input renderStatic="true" gungTranslate="ORDER_DATE" [(ngModel)]="ediOrder.documentReferences.buyerOrder.datee" class="label-bold"></lib-gung-text-input>
          </div> -->
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="ORDER_TYPE"
            [(ngModel)]="ediOrder.orderType"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="ORDER_ID"
            [(ngModel)]="ediOrder.documentReferences.supplierOrder.id"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="BUYER_ORDER_ID"
            [(ngModel)]="ediOrder.documentReferences.buyerOrder.id"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="VALIDATION"
            [(ngModel)]="ediOrder.validationState"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="CONFIG"
            [(ngModel)]="ediOrder.ediConfigId"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="DELIVERY_GLN"
            [(ngModel)]="ediOrder.deliveryGln"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="CONSIGNEE_GLN"
            [(ngModel)]="ediOrder.consigneeGln"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="BUYER_GLN"
            [(ngModel)]="ediOrder.buyerGln"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="DELIVERY_DATE"
            [(ngModel)]="ediOrder.deliveryDate"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
        <div class="col-12 col-md-4" *ngIf="!!ediOrder.timestamp">
          <lib-gung-text-input
            renderStatic="true"
            gungTranslate="TIMESTAMP"
            [(ngModel)]="ediOrder.timestamp"
            class="label-bold"
          ></lib-gung-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
