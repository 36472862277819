<div *ngIf="!isLoading; else loading">
    <div class="modal-header">
      <div>
        <h3 translate>
          DETAILS
        </h3>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col" translate>FIELDS</th>
                    <th scope="col" translate>VALUES</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let row of data">
                    <th scope="row">
                      <span class="text-nowrap" translate>{{row.name}}</span>
                    </th>
                    <td>
                        <ng-container [ngSwitch]="row.type">
                            <ng-template *ngSwitchCase="ColumnRowTableType.INPUTCHECKBOX" [ngTemplateOutlet]="checkboxTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: item, row }">
                            </ng-template>
                            <!-- <ng-template *ngSwitchCase="ColumnRowTableType.NUMBER" [ngTemplateOutlet]="numberTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template>
                            <ng-template *ngSwitchCase="ColumnRowTableType.PRICE" [ngTemplateOutlet]="priceTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template> -->
                            <ng-template *ngSwitchCase="ColumnRowTableType.TEXT" [ngTemplateOutlet]="textTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: item, row }">
                            </ng-template>
                            <ng-template *ngSwitchCase="ColumnRowTableType.INPUTTEXT" [ngTemplateOutlet]="inputTextTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: item, row }">
                            </ng-template>
                            <!-- <ng-template *ngSwitchCase="ColumnRowTableType.DATE" [ngTemplateOutlet]="dateTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template>
                            <ng-template *ngSwitchCase="ColumnRowTableType.INPUTNUMBER" [ngTemplateOutlet]="inputNumberTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template>
                            <ng-template *ngSwitchCase="ColumnRowTableType.INPUTDATE" [ngTemplateOutlet]="inputDateTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template>
                            <ng-template *ngSwitchCase="ColumnRowTableType.ACTION" [ngTemplateOutlet]="actionTemplate" 
                              [ngTemplateOutletContext]="{ $implicit: row, column }">
                            </ng-template> -->
                        </ng-container>
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
  
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn btn-primary text-uppercase" (click)="activeModal.close(item)" translate>
            SAVE
        </button>
        <button type="button" class="btn btn-sm btn btn-outline-primary text-uppercase" (click)="activeModal.dismiss('Cancel click')" translate>
            CLOSE
        </button>
    </div>
</div>

<ng-template #loading> LOADING... </ng-template>

<ng-template #checkboxTemplate let-item let-row="row">
    <lib-gung-checkbox-input-horizontal
        suppressLabel="true"
        [ngModel]="getValues(item, row.path)"
        (ngModelChange)="setValue(item, row.path, $event)"
        class="d-flex justify-content-start align-items-center"
    ></lib-gung-checkbox-input-horizontal>
</ng-template>

<ng-template #textTemplate let-item let-row="row">
    {{ getValues(item, row.path) }}
</ng-template>

<ng-template #inputTextTemplate let-item let-row="row">
    <lib-gung-text-input
        suppressLabel="true"
        [ngModel]="getValues(item, row.path)"
        (ngModelChange)="setValue(item, row.path, $event)"
        extraInputClass="form-control-sm"
    ></lib-gung-text-input>
    <!-- [extraInputClass]="row.extra.errors && !getValues(row, row.path) ? 'form-control-sm border border-danger' : 'form-control-sm'" -->
</ng-template>
  