<textarea
  class="form-control"
  type="text"
  rows="20"
  (ngModelChange)="getValidatedJSON($event)"
  [(ngModel)]="systemSettingsString"
></textarea>
<div *ngIf="error">
  <p translate>{{ statusMessage }}</p>
</div>
<br />
<button [disabled]="error" (click)="save()" translate><span translate>SAVE</span></button>
