import { MetadataState } from './types';
import { Action } from '@ngrx/store';
import { ActionTypes, MetadataReceived } from './actions';

const initialState: MetadataState = {
  metadata: {}
};
export function reducers(state: MetadataState = initialState, action: Action): MetadataState {
  switch (action.type) {
    case ActionTypes.MetadataReceived: {
      const { payload } = action as MetadataReceived;
      return {
        ...state,
        metadata: payload
      };
    }
    default: {
      return state;
    }
  }
}
