import { Component, OnInit } from '@angular/core';
import { ConfigurationsService } from '../../services/configurations.service';
import { TranslateService } from '@ngx-translate/core';
import { GungNotificationService } from 'gung-common';
import { catchError, first, of } from 'rxjs';

@Component({
  selector: 'lib-inactive-users-notification-configuration',
  templateUrl: './inactive-users-notification-configuration.component.html',
  styleUrls: ['./inactive-users-notification-configuration.component.css']
})
export class InactiveUsersNotificationConfigurationComponent implements OnInit {
  private readonly configurationId = 'InactiveUsersNotificationJobConfig';

  inactiveUserNotificationConfiguration: InactiveUserNotificationConfiguration;

  constructor(
    protected configurationService: ConfigurationsService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.configurationService
      .getConfigurationsByIds([this.configurationId])
      .pipe(first())
      .subscribe(configuration => {
        if (!configuration) {
          return;
        }

        this.inactiveUserNotificationConfiguration = configuration[0];
      });
  }

  save(): void {
    this.configurationService
      .updateConfiguration(this.inactiveUserNotificationConfiguration)
      .pipe(
        first(),
        catchError((err, caught) => {
          this.gungNotificationService.notify(
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            this.translateService.instant('ERROR_SAVING_INACTIVE_USERS_NOTIFICATION_CONFIGURATION_MESSAGE'),
            'error'
          );
          return of(null);
        })
      )
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.gungNotificationService.notify(
          this.translateService.instant('CONFIGURATION_SAVED'),
          this.translateService.instant('INACTIVE_USERS_NOTIFICATION_CONFIGURATION_SAVED_MESSAGE'),
          'success'
        );
      });
  }
}

interface InactiveUserNotificationConfiguration {
  notificationRecipients: string[];
  inactiveDaysThreshold: number;
}
