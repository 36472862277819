<div *ngIf="!isLoading; else loading">
    <div class="modal-header">
      <div>
        <h3 translate>
          COMMENT
        </h3>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
  
    <div class="modal-body">
        <div class="comments bg-light m-2 p-1">
            <div
              class="row"
              *ngFor="let comment of comments; let index = index"
              [class.justify-content-end]="currentUser.username === comment.userId"
            >
              <div
                class="col-10 rounded m-2"
                [ngClass]="{ response: currentUser.username !== comment.userId, answer: currentUser.username === comment.userId }"
              >
                <div class="row">
                  <div class="col-6 text-left font-weight-bold">
                    {{ comment.userId }}
                  </div>
                  <div class="col-6 text-right small">
                    {{ comment.date | date : dateUtilService.dateFormat }}
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 text-justify">
                    {{ comment.comment }}
                  </div>

                </div>
                <div class="row mt-2 mb-2">
                  <div class="col-12 text-right">
                    <span *ngFor="let attachment of comment.documents" [title]="attachment" class="mx-2 cursor-pointer">
                        <a [href]="attachment | gungDownloadUrl">
                            <i *ngIf="!isImage(attachment); else showThumbnail" class="fas fa-download"></i>
                            <ng-template #showThumbnail>
                              <span class="thumbnail-container">
                                <img [src]="attachment | gungDownloadUrl" class="thumbnail">
                              </span>
                            </ng-template>
                        </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
        </div>
        <div class="row my-2 justify-content-end" *ngIf="documents?.length > 0">
            <div class="col-12">
                <h5 translate>DOCUMENTS</h5>
            </div>
            <div class="col-auto">
                <ul>
                    <li *ngFor="let attachment of documents" [title]="attachment.s3Uri" class="mx-2 cursor-pointer">
                        <a [href]="attachment | gungDownloadUrl">
                            <span>
                                {{attachment.s3Uri.slice(attachment.s3Uri.lastIndexOf('/') + 1 )}}
                            </span>
                            <i *ngIf="!isImage(attachment); else showThumbnail" class="fas fa-download"></i>
                            <ng-template #showThumbnail>
                              <span class="thumbnail-container">
                                <img [src]="attachment | gungDownloadUrl" class="thumbnail">
                              </span>
                            </ng-template>
                        </a>
                    </li>
                </ul>
            </div>
          </div>
        <div>
            <lib-gung-text-area-input
                gungTranslate="ADD_COMMENT"
                [(ngModel)]="newComment.comment"
                [rowsNumber]="3"
              ></lib-gung-text-area-input>
        </div>
    </div>
  
    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn btn-primary text-uppercase" (click)="save()" translate>
            SAVE
        </button>
        <button type="button" class="btn btn-sm btn btn-outline-primary text-uppercase" (click)="activeModal.dismiss('Cancel click')" translate>
            CLOSE
        </button>
    </div>
</div>

<ng-template #loading> LOADING... </ng-template>