import { AfterViewInit, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnInit, Type, ViewChild } from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { GungViewer3dComponent } from '../gung-viewer3d/gung-viewer3d.component';

@Component({
  selector: 'lib-gung-viewer3d-wrapper',
  template: `<ng-template libCustomHost></ng-template>`,
  styleUrls: ['./gung-viewer3d-wrapper.component.css']
})
export class GungViewer3dWrapperComponent implements OnInit, AfterViewInit {

  @Input()
  public modelFile: string[];

  @Input()
  public width = '800px';

  @Input()
  public height = '600px';

  @Input()
  public initLoad = true;

  @Input()
  public isExpanded = false;

  @Input()
  public showMoreOptions = false;

  @ViewChild(CustomHostDirective)
  viewChild: CustomHostDirective;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected baseViewConfigService: BaseViewConfigService
  ) { }

  ngOnInit(): void {

  }

  ngAfterViewInit() {
    if (this.viewChild) {
      // Clear views already rendered from previous navigation
      const containerRef = this.viewChild.viewContainerRef;
      containerRef.clear();
    }
    this.renderLayout(this.baseViewConfigService.getViewer3d());
  }

  renderLayout(viewComponent: any) {
    if (!this.viewChild) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(viewComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent: GungViewer3dComponent = componentRef.instance as any;
    typedComponent.modelFile = this.modelFile;
    typedComponent.width = this.width;
    typedComponent.height = this.height;
    typedComponent.initLoad = this.initLoad;
    typedComponent.isExpanded = this.isExpanded;
    typedComponent.showMoreOptions = this.showMoreOptions;

    this.changeDetectorRef.detectChanges();
  }

}
