import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { User } from './../../../state/auth/types';
import { UsersService } from '../../../services/users/users.service';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeleteUserModalComponent } from '../delete-user-modal/delete-user-modal.component';
import { first } from 'rxjs';
import { UserActionConfigService } from '../../../services/users/user-action-config/user-action-config.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-user-table',
  templateUrl: './user-table.component.html',
  styleUrls: ['./user-table.component.css']
})
export class UserTableComponent extends ListItemRendererComponent<User[]> implements OnInit, AfterViewInit {
  constructor(
    protected usersService: UsersService,
    public router: Router,
    protected modalService: NgbModal,
    public userAction: UserActionConfigService,
    protected gungModalService: GungModalService
  ) {
    super();
  }

  public ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  copyUser(username: string): void {
    const userToCreateFrom = {
      ...this.data.find(user => user.username === username),
      username: '',
      name: '',
      email: '',
      password: ''
    };

    this.gungModalService.createUser(userToCreateFrom, true).then(
      (user: User) => {
        if (!!user) {
          this.router.navigate(['/admin/users-details/' + user.username]);
        }
      },
      reason => {}
    );
  }

  editUser(username: string): void {
    this.router.navigate(['/admin/users-details/' + username]);
  }

  deleteUser(username: string): void {
    const modalRef = this.modalService.open(DeleteUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.username = username;
    modalRef.result.then(
      (result: string) => {
        if (result === 'YES') {
          this.usersService
            .deleteUser(username)
            .pipe(first())
            .subscribe(res => {
              this.data.splice(
                this.data.findIndex(user => user.username === username),
                1
              );
            });
        }
      },
      reason => {}
    );
  }
}
