import { SelectionService, SelectionAction, ExportSelection } from '../../types';
import { Observable, Subject, BehaviorSubject, observable } from 'rxjs';
import { map } from 'rxjs';

export abstract class AbstractSelectionService<T> implements SelectionService<T> {
  selectionsEnabled = false;

  selection: ExportSelection<T> = {
    selectedItems: {},
    selectedItemCount: 0
  };

  selectionSubject: BehaviorSubject<ExportSelection<T>> = new BehaviorSubject<ExportSelection<T>>(this.selection);
  selection$: Observable<ExportSelection<T>> = this.selectionSubject.asObservable();
  abstract getItemId(item: T): string;

  public getSelection(): Observable<ExportSelection<T>> {
    return this.selection$;
  }

  isItemSelected(item: T): Observable<boolean> {
    const itemId = this.getItemId(item);
    return this.getSelection().pipe(
      map(() => {
        const isSelected = this.isItemSelectedInternal(itemId);
        return isSelected;
      })
    );
  }

  select(items: T | T[]): void {
    if (Array.isArray(items)) {
      items.forEach(item => {
        const itemId = this.getItemId(item);
        this.selection.selectedItems[itemId] = item;
      });
    } else {
      const itemId = this.getItemId(items);
      this.selection.selectedItems[itemId] = items;
    }
    this.updateSubject();
  }

  deselect(items: T | T[]): void {
    if (Array.isArray(items)) {
      items.forEach(item => {
        const itemId = this.getItemId(item);
        delete this.selection.selectedItems[itemId];
      });
    } else {
      const itemId = this.getItemId(items);
      delete this.selection.selectedItems[itemId];
    }
    this.updateSubject();
  }

  clearSelection(): void {
    this.selection.selectedItems = {};
    this.updateSubject();
  }

  setSelectionsEnabled(selectionsEnabled: boolean): void {
    this.selectionsEnabled = selectionsEnabled;
  }
  getSelectionsEnabled(): boolean {
    return this.selectionsEnabled;
  }

  private updateSubject() {
    this.selection.selectedItemCount = Object.keys(this.selection.selectedItems).length;
    this.selectionSubject.next(this.selection);
  }

  private isItemSelectedInternal(itemId: string): boolean {
    return !!this.selection.selectedItems[itemId];
  }
}
