import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GungSlider } from 'gung-list';
import { BackendInterceptor } from '../../../../services/backend-interceptor/backend-interceptor.service';
import { GungFlow } from './../../../../state/flow/types';

@Component({
  selector: 'lib-gung-flow-details-drops',
  templateUrl: './gung-flow-details-drops.component.html',
  styleUrls: ['./gung-flow-details-drops.component.scss']
})
export class GunglowDetailsDropsComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;

  public baseUrl = this.backendInterceptor.getBaseUrl();
  uploading = false;

  constructor(protected backendInterceptor: BackendInterceptor, protected datePipe: DatePipe) {}

  ngOnInit(): void {
    console.log(this.curentGungFlow);
  }

  dateSelected(event, drop) {
    const date = event.date;
    const dString: string = this.datePipe.transform(date, 'yyyy-MM-dd');
    drop.firstDeliveryDate = dString;
  }

  addDrop() {
    const dropIndex = this.curentGungFlow.drops.length + 1;
    this.curentGungFlow.drops.push({
      id: 'D' + dropIndex,
      name: 'Drop ' + dropIndex,
      firstDeliveryDate: this.datePipe.transform(new Date(), 'yyyy-MM-dd'),
      productConditions: undefined
    });
  }
}
