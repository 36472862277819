import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Customer } from '../../models';
import { CustomerService } from '../../services/customers/customer.service';
import { first } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { NavbarConfigService } from '../../services/navbar-config/navbar-config.service';
import { NavigationConfig } from '../../models/navigation-config';

@Component({
  selector: 'lib-customer-details',
  templateUrl: './customer-details.component.html',
  styleUrls: ['./customer-details.component.css']
})
export class CustomerDetailsComponent implements OnInit {
  customer: Customer;
  isUser = false;
  isSalesOrAdmin = false;
  showActivities = this.navbarConfigService.enableCustomerActivities;

  public enableOffers = this.environment?.enableOffers;

  constructor(
    protected route: ActivatedRoute,
    protected customerService: CustomerService,
    protected authService: AuthService,
    protected navbarConfigService: NavbarConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
  ) {}

  ngOnInit() {
    const routeParams = this.route.snapshot.params;
    if (routeParams.customerId != null) {
      this.customerService
        .getCustomer(routeParams.customerId)
        .pipe(first())
        .subscribe(customer => {
          this.customer = customer;
        });
    }

    this.authService
    .getCurrentUser()
    .pipe(first())
    .subscribe(user => {
      if (user.roles.includes('USER') && user.roles.length === 1) {
        this.isUser = true;
      }
      this.isSalesOrAdmin = user.roles.indexOf('ADMIN') !== -1 || user.roles.indexOf('SALES') !== -1;

    });
  }
}
