import { AfterViewInit, Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { ProductService } from '../../../../services/products/product.service';
import { DeliveryDateService } from '../../../../services/delivery-date/delivery-date.service';
import { AvailabilityService } from '../../../../services/availability/availability.service';
import { Product } from '../../../../models/product';
import { CheckoutObject } from '../../../../models/checkout';
import { Availability } from '../../../../models/availability';
import { TimeoutError, catchError, debounce, first, map, mergeMap, switchMap, throwError, timeout } from 'rxjs';
import { Observable, from, of, forkJoin, timer } from 'rxjs';
import { ValidationResult } from '../../../../models/quantity-validation';
import { CheckoutQuantityValidationService } from '../../../../services/quantity-validation/checkout-quantity-validation.service';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonModalService } from 'gung-common';

@Component({
  selector: 'lib-quantity-validation-step',
  templateUrl: './quantity-validation-step.component.html',
  styleUrls: ['./quantity-validation-step.component.css']
})
export class QuantityValidationStepComponent extends CheckoutStepComponent implements OnInit, AfterViewInit {
  modalReference: any;
  errorTimeout: boolean = false;

  constructor(
    protected translateService: TranslateService,
    protected availabilityService: AvailabilityService,
    protected productService: ProductService,
    protected checkoutQuantityValidationService: CheckoutQuantityValidationService,
    protected deliveryDateService: DeliveryDateService,
    protected modalService: NgbModal,
    protected commonModalService: CommonModalService
  ) {
    super();
  }

  @ViewChild('quantityValidationModal') quantityValidationModal;

  closeModal: string;

  protected VALIDATION_DONE = 'VALIDATION_DONE';
  validationResults: ValidationResult[] = [];

  ngAfterViewInit() {
    // Validate on init and continue if ok
    // Open a modal to inform user this may take a while

    for (const row of this.checkout.rows) {
      delete row.extra._deliveryPlan;
    }

    this.validate().pipe(first()).subscribe(result => {
        this.validationResults = result;

        this.onNextBtnClicked
          .pipe(
            map(e => {
              return e;
            })
          )
          .subscribe(_ => this.handleNextButtonClicked());

        if (result.length === 0) {
          this.stepDone.emit(this.checkout);
        }
      });
  }

  ngOnInit() {
    super.ngOnInit();
  }

  triggerModal() {
    this.modalReference = this.modalService.open(this.quantityValidationModal, {
      backdrop: 'static',
      size: 'xl',
      keyboard: true,
      ariaLabelledBy: 'modal-basic-title'
    });

    this.modalReference.result.then(
      res => {
        if (res === this.VALIDATION_DONE) {
        } else {
          this.closeModal = `Closed with: ${res}`;
          console.log(`Closed with: ${res}`);
          this.stepPrevious.emit(this.checkout);
        }
      },
      res => {
        console.log(`Dismissed ${this.getDismissReason(res)}`);
        this.closeModal = `Dismissed ${this.getDismissReason(res)}`;
        this.stepPrevious.emit(this.checkout);
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  protected handleNextButtonClicked(): void {
    // Apply selected actions before next attemmt
    this.validationResults.forEach(element => {
      if (element.selectedAction) {
        element.selectedAction.callback();
      }
    });

    // Then validate again
    this.validate()
      .pipe(
        first(),
        map(result => {
          this.validationResults = result;
          if (result.length === 0) {
            this.stepDone.emit(this.checkout);
          }
        })
      )
      .subscribe();
  }

  protected validate(): Observable<ValidationResult[]> {
    this.triggerModal();

    const source = from([this.checkout.rows]);
    return source.pipe(
      first(),
      mergeMap(rows => {
        const rowsWithStock = rows.filter(row => row.targetStockId);
        const stockId = rowsWithStock.length > 0 ? rowsWithStock[0].targetStockId : undefined;
        return forkJoin({
          rows: of(rows),
          availabilities: this.availabilityService.getAvailabilities(
            rows.map(e => e.id),
            stockId,
            true
          ),
          products: this.productService.getFullProductsByIds(rows.map(e => e.id)),
          calendarValidDeliveryDates: this.deliveryDateService.getDeliveryDatesForCheckout(this.checkout)
        });
      }),
      switchMap(data => {
        const productIdToProductMap = new Map<string, Product>();
        const productIdToAvailabilityMap = new Map<string, Availability>();

        data.availabilities.forEach(element => {
          productIdToAvailabilityMap[element.productId] = element;
        });

        data.products.forEach(element => {
          productIdToProductMap[element.id] = element;
        });

        const invalidValidations = data.rows
          .map(row =>
            this.checkoutQuantityValidationService.getQtyValidationResult({
              checkout: this.checkout,
              row,
              product: productIdToProductMap[row.id],
              availability: productIdToAvailabilityMap[row.id],
              calendarValidDeliveryDates: data.calendarValidDeliveryDates
            })
          )
          .filter(validationResult => !!validationResult) // Remove empty
          .filter(validationResult => !validationResult.valid); // Remove valid

        return of(invalidValidations);
      }),
      map(e => {
        setTimeout(() => {
          this.modalReference.close(this.VALIDATION_DONE);
        }, 3000);

        return e;
      }),
      catchError((error) => {
        if (error/*  instanceof TimeoutError */) { // Handle 'timeout over' error
          this.modalReference.close();
          this.errorTimeout = true;
          this.commonModalService.openConfirmYesNoModal('ERROR', 'The request/calculation couldn’t be handled in time. Please contact Gung support.', {}, 'CLOSE', null).then(() => {});
          return throwError(() => new Error('Timeout Exception'));
        }
        // Return other errors
        return throwError(() => new Error(error));
      })
    );
  }

  getLabel() {
    return 'VALIDATION';
  }

  public getBtnTexts(checkout: CheckoutObject): { next: string | void; prev: string | void } | void {
    return {
      next: 'TRY_AGAIN',
      prev: null
    };
  }
}
