import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';

@Component({
  selector: 'lib-open-order-card-list',
  templateUrl: './open-order-card-list.component.html',
  styleUrls: ['./open-order-card-list.component.css']
})
export class OpenOrderCardListComponent extends ListItemRendererComponent<Order> implements AfterViewInit {
  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
