import { Component, OnInit, Output, EventEmitter, AfterViewInit, ElementRef, OnDestroy } from '@angular/core';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { Customer } from '../../models';
import { AuthService } from '../../services/auth/auth.service';
import { User } from '../../state/auth/types';
import { CartService } from '../../services/cart/cart.service';
import { CartRow } from '../../state/cart/types';
import { first, switchMap, takeUntil, filter, tap } from 'rxjs';
import { forkJoin, Subject, fromEventPattern } from 'rxjs';
import { NavbarConfigService, NavGroup, NavLink } from '../../services/navbar-config/navbar-config.service';
import { Router, NavigationEnd } from '@angular/router';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { GlobalSearchConfigService } from '../../services/global-search-config.service';

@Component({
  selector: 'lib-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Output()
  navbarHeight = new EventEmitter<number>(true);

  @Output()
  sidenavToggle = new EventEmitter<void>();

  @Output()
  topMenuExpanded = new EventEmitter<boolean>();

  selectedCustomer?: Customer;
  user?: User;
  cart?: CartRow[];
  authenticated: boolean;
  isCartVisible: boolean;
  isOnlyDisplayFlow = false;
  isCollapsed: boolean;
  isCustomerCollapsed: boolean;
  isTopMenuExpanded = false;
  topMenuExpandedNumber: number;
  // screen width <576px
  isSmallScreen = false;
  selectedTopMainMenuIndex = 0;
  public isSupplierBuyer = false;

  unsubscribe: Subject<boolean> = new Subject<boolean>();

  baseCollapseClasses: { [className: string]: boolean } = {
    show: false
  };

  protected signedIn = false;
  isSales: boolean;
  isAnonymous = true;
  devMode = localStorage.getItem('dev') || false;

  mainMenu: NavGroup;
  rightMenus: NavGroup[] = [];
  leftMenus: NavGroup[] = [];
  topInformation: NavLink;

  protected change: Subject<boolean> = new Subject();
  currentFlowName: string;
  isAssortmentBased = false;

  public showFlowsAsDropdown = true; // change in NAVBARCONFIG
  public showLeftMenu = false;

  public logoUrl = this.navbarConfigService.getLogoUrl();

  public isNaN = (n: number) => isNaN(n);

  public currentLeftMenuHeader: string;

  public enableNavbarGlobalSearch = this.navbarConfigService.enableNavbarGlobalSearch;
  public isGlobalSearchOpen: boolean = false;

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService,
    protected cartService: CartService,
    protected elementRef: ElementRef,
    protected navbarConfigService: NavbarConfigService,
    protected gungFlowService: GungFlowService,
    protected router: Router,
    protected globalSearchConfig: GlobalSearchConfigService
  ) { }

  ngAfterViewInit() {
    this.navbarHeight.emit(this.elementRef.nativeElement.offsetHeight);
  }

  ngOnInit() {
    this.change
      .asObservable()
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => {
          return this.authService.getIsAuthenticated().pipe(
            tap(authed => (this.signedIn = authed)),
            filter(authenticated => !!authenticated),
            tap(authenticated => (this.authenticated = authenticated)),
            switchMap(() =>
              forkJoin([
                this.authService.getRoles().pipe(
                  filter(roles => !!roles),
                  first()
                ),
                this.selectedCustomerService.getSelectedCustomer().pipe(
                  filter(customer => this.devMode ? true : !!customer),
                  first()
                ),
                this.navbarConfigService.getLogoUrlObservable().pipe(first())
              ])
            )
          );
        })
      )
      .subscribe(([roles, customer, logoUrl]) => {
        this.isSupplierBuyer = roles.includes('SUPPLIER') || roles.includes('BUYER')
        this.logoUrl = logoUrl;
        this.selectedCustomer = customer;
        if (roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1) {
          this.isSales = true;
        } else {
          this.isSales = false;
        }

        if (roles.indexOf('ANONYMOUS') < 0) {
          this.isAnonymous = false;
        }

        this.navbarConfigService
          .getLeftMenus()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(links => {
            this.leftMenus = links
            if (this.leftMenus?.length > 0) {
              this.currentLeftMenuHeader = links[0].heading;
            }
          });

        this.navbarConfigService
          .getTopInformation()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(links => (this.topInformation = links));

        this.navbarConfigService
          .getRightMenus()
          .pipe(takeUntil(this.unsubscribe))
          .subscribe(links => (this.rightMenus = links));
      });

    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => this.change.next(true));

    this.cartService
      .getCurrentCart()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(cart => (this.cart = cart));

    this.navbarConfigService
      .showCart()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(showCart => {
        this.isCartVisible = showCart;
      });

    this.isCollapsed = true;
    this.isCustomerCollapsed = true;

    // When going to another path If top menu is extended
    // Then allways change its state unless for small screens (here the top menu is expanded/collapsed when click on it)
    this.router.events
      .pipe(
        takeUntil(this.unsubscribe),
        filter(event => event instanceof NavigationEnd)
      )
      .subscribe(event => {
        if (!this.isSmallScreen) {
          this.collapseTopMenu();
        }
      });

    this.gungFlowService
      .getSelectedFlow()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(flow => {
        this.currentFlowName = flow?.name;
        this.isOnlyDisplayFlow = !!flow && !!flow.displayOnly;
        this.collapseTopMenu();
      });

    // Evaluate the screen width
    // For small screen
    // the top menu will contain only one main menu expanded
    // the remaing will be kept collapsed
    const mediaQuery = matchMedia('screen and (max-width: 575px)');
    this.isSmallScreen = mediaQuery.matches;

    const mediaObserver = fromEventPattern<MediaQueryListEvent>(
      mediaQuery.addListener.bind(mediaQuery),
      mediaQuery.removeListener.bind(mediaQuery)
    );

    mediaObserver.pipe(takeUntil(this.unsubscribe)).subscribe(result => {
      this.isSmallScreen = result.matches;
    });

    this.globalSearchConfig.closeSubject.pipe(takeUntil(this.unsubscribe)).subscribe(_ => this.isGlobalSearchOpen ? this.isGlobalSearchOpen = false : undefined);

    this.showFlowsAsDropdown = this.navbarConfigService.showFlowsAsDropdown;
    this.isAssortmentBased = this.navbarConfigService.isAssortmentBased;
  }

  ngOnDestroy() {
    this.navbarHeight.emit(0);
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  logout() {
    this.authService.logout();
  }

  // public isSignedIn(): boolean {
  //   return this.signedIn;
  // }

  // hasOneOfRoles(desiredRoles: string[]): boolean {
  //   const role = desiredRoles.find(roleFromList =>
  //     this.user.roles.indexOf(roleFromList) > -1
  //   );

  //   return !!role;
  // }

  // public toggleMobileMenu() {
  //   this.baseCollapseClasses.show = !this.baseCollapseClasses.show;
  //   this.isCollapsed = this.baseCollapseClasses.show;
  // }

  sidenavToggleEvent() {
    this.sidenavToggle.emit();

    this.collapseTopMenu();
  }

  toggleTopMenuV2(leftMenuIndex?: number) {
    if (this.topMenuExpandedNumber === leftMenuIndex) {
      leftMenuIndex = undefined;
    }
    // CLOSE ALL BEFORE
    const allSubMenus: NodeListOf<Element> = document.querySelectorAll("[id='gung-mega-top-menu']");
    Array.from(allSubMenus, subMenu => {
      subMenu.classList.remove('gung-mega-top-menu-expanded');
      subMenu.classList.add('gung-mega-top-menu-collapsed');
    });
    this.isTopMenuExpanded = false;
    this.topMenuExpandedNumber = undefined;
    if (leftMenuIndex === undefined) {
      return;
    }
    // OPEN MENU
    const menu = document.getElementById('nav-item-' + leftMenuIndex);
    if (leftMenuIndex && (!menu || !menu.children['gung-mega-top-menu'])) {
      this.toggleTopMenu();
      return;
    }
    const element = menu.children['gung-mega-top-menu'];
    if (this.isTopMenuExpanded) {
      element.classList.remove('gung-mega-top-menu-expanded');
      element.classList.add('gung-mega-top-menu-collapsed');
    } else {
      element.classList.add('gung-mega-top-menu-expanded');
      element.classList.remove('gung-mega-top-menu-collapsed');
      this.topMenuExpandedNumber = leftMenuIndex;
    }

    this.isTopMenuExpanded = !this.isTopMenuExpanded;

    this.topMenuExpanded.emit(this.isTopMenuExpanded);
  }

  toggleTopMenu() {
    if (this.isTopMenuExpanded) {
      document.getElementById('gung-mega-top-menu').classList.remove('gung-mega-top-menu-expanded');
      document.getElementById('gung-mega-top-menu').classList.add('gung-mega-top-menu-collapsed');
    } else {
      document.getElementById('gung-mega-top-menu').classList.add('gung-mega-top-menu-expanded');
      document.getElementById('gung-mega-top-menu').classList.remove('gung-mega-top-menu-collapsed');
    }

    this.isTopMenuExpanded = !this.isTopMenuExpanded;

    this.topMenuExpanded.emit(this.isTopMenuExpanded);
  }

  collapseTopMenu() {
    if (this.isTopMenuExpanded) {
      this.toggleTopMenuV2();
    }
  }

  toggleShowLeftMenu() {
    this.showLeftMenu = !this.showLeftMenu;
  }
}

export interface SimpleRoute {
  heading: string;
  route: string;
}
