import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { CommonModalService } from 'gung-common';
import { BehaviorSubject, Observable, first, switchMap } from 'rxjs';
import { map, tap } from 'rxjs';
import { GungBase } from '../../models';
import { SystemSettings } from '../../models/system-setting';
import { SelectFlow } from '../../state/flow/actions';
import { GungFlow } from '../../state/flow/types';
import { AppState } from '../../state/state.module';

@Injectable({
  providedIn: 'root'
})
export class GungFlowService {
  flowchange: BehaviorSubject<GungFlow[]>;
  adminFlows: BehaviorSubject<GungFlow[]>;
  public isMultiDropFlowsEnabled = false;

  constructor(
    protected http: HttpClient,
    protected store: Store<AppState>,
    protected commonModalService: CommonModalService
  ) {}

  internalGetGungFlows(): Observable<GungFlow[]> {
    const url = 'json/gung-flows';
    const headers = { maxAge: '-1' };
    return this.http.get<GungFlow[]>(url, { headers }).pipe(map(flows => this.sortFlowsBySortingPriority(flows)));
  }

  getGungFlows(force = false): Observable<GungFlow[]> {
    if (!this.flowchange) {
      return this.internalGetGungFlows().pipe(
        first(),
        switchMap(flows => {
          this.flowchange = new BehaviorSubject<GungFlow[]>(flows);
          return this.flowchange.asObservable();
        })
      );
    }

    if (force) {
      return this.internalGetGungFlows().pipe(
        first(),
        switchMap(flows => {
          this.flowchange.next(flows);
          return this.flowchange.asObservable();
        })
      );
    }

    return this.flowchange.asObservable();
  }

  sortFlowsBySortingPriority(flows: GungFlow[]): GungFlow[] {
    return flows.sort((a, b) => {
      // SORT by flow sorting Priority
      const aSort = a.extra && a.extra.sortingPriority;
      const bSort = b.extra && b.extra.sortingPriority;
      if ((!bSort && aSort) || (aSort && bSort && Number(aSort) > Number(bSort))) {
        return -1;
      } else if ((!aSort && bSort) || (aSort && bSort && Number(aSort) < Number(bSort))) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  getGungAdminFlows(): Observable<GungFlow[]> {
    const url = 'json/gung-flows/admin/flows';
    const headers = { maxAge: '-1' };

    return this.http.get<GungFlow[]>(url, { headers }).pipe(map(flows => this.sortFlowsBySortingPriority(flows)));
  }

  getGungFlow(flowId: string, noCache = false): Observable<GungFlow> {
    const url = `json/gung-flows/${flowId}`;
    let headers;

    if (noCache) {
      headers = { maxAge: '-1' };
    }
    return this.http.get<GungFlow>(url, { headers });
  }

  createGungFlow(gungFlow: any): Observable<GungFlow> {
    return this.http.post<GungFlow>('json/gung-flows', gungFlow);
  }

  updateGungFlow(gungFlow: GungFlow): Observable<GungFlow> {
    return this.http.put<GungFlow>('json/gung-flows', gungFlow);
  }

  deleteGungFlow(gungFlow: GungFlow): Observable<GungFlow> {
    return this.http.delete<GungFlow>('json/gung-flows/' + gungFlow.id);
  }

  getDefaultGungFlow(): Observable<GungFlow> {
    return this.getGungFlows().pipe(
      map(flows => {
        const defaultFilter = flows.find(flow => flow.isDefault);
        if (defaultFilter) {
          return defaultFilter;
        } else if (flows.length > 0) {
          return flows[0];
        } else {
          return null;
        }
      })
    );
  }

  isGungFlowAllowedForUser(flowId: string): Observable<boolean> {
    return this.getGungFlows().pipe(
      map(flows => {
        return !!flows.find(flow => flow.id === flowId);
      })
    );
  }

  getSelectedFlow(): Observable<GungFlow> {
    return this.store.select(state => state.gungFlow.selectedFlow);
  }

  selectFlow(flowId: string): void {
    this.isGungFlowAllowedForUser(flowId).subscribe({
      next: allowed => {
        if (allowed) {
          this.store.dispatch(
            new SelectFlow({
              flowId
            })
          );
        } else {
          this.commonModalService.openConfirmYesNoModal(
            'RESTRICTED_FLOW',
            'FLOW_NOT_ALLOWED_FOR_USER',
            undefined,
            'OK',
            null
          );
        }
      },
      error: error => {
        // If for some reasone we have some error in cecking the flow
        this.store.dispatch(
          new SelectFlow({
            flowId
          })
        );
      }
    });
  }

  getSystemGung(): Observable<SystemSettings> {
    const url = `json/system/GUNG`;

    return this.http.get<SystemSettings>(url);
  }

  updateSystemGung(body: SystemSettings): Observable<SystemSettings> {
    const url = `json/system/GUNG`;

    return this.http.put<SystemSettings>(url, body);
  }
}
