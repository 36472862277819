<div class="form-group">
  <label [for]="inputId" *ngIf="!suppressLabel" [ngClass]="{ 'font-weight-bold': labelBold }">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <textarea
    type="text"
    [class]="extraInputClass"
    [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
    [readonly]="renderStatic"
    [id]="inputId"
    [name]="inputId"
    [required]="required"
    [disabled]="disabled"
    [placeholder]="placeholder | translate"
    [rows]="rowsNumber"
    [maxlength]="maxlength"
    [ngStyle]="{ resize: blockResize ? 'none' : undefined }"
    [(ngModel)]="value"
  >
  </textarea>
</div>
