import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, first } from 'rxjs';
import { GungBase } from '../../models';

export interface PimMetadata extends GungBase {
  active: boolean;
  i18n: { [s: string]: any } | null;
  metadataId: string;
  order: string;
  segment: string;
}

export interface GungSystem {
  extra: GungSystemExtra;
  orderParam: { [s: string]: any } | null;
  preOrderDefinition: { [s: string]: any } | null;
  qualifier: string;
}

export interface GungSystemExtra {
  flowOrderConditions: { [s: string]: any } | null;
  flowProductConditions: { [s: string]: any } | null;
  invoiceStatisticProjectionDefinition: { [s: string]: any } | null;
  metadataSegments: string[];
  userGroups: string[];
}

@Injectable({
  providedIn: 'root'
})
export class PimMetadataService {
  public translateLanguages = [];
  public translateField: string = 'name';

  protected baseUrl = 'json/pim/meta-data';
  private pimMetadataSubject: BehaviorSubject<PimMetadata[]>;

  constructor(protected http: HttpClient) {}

  public getPimMetadata(nocache?: boolean): Observable<PimMetadata[]> {
    const url = `${this.baseUrl}/elements`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<PimMetadata[]>(url, { headers });
  }

  public postPimMetadata(pimMetadata: PimMetadata): Observable<PimMetadata> {
    const url = `${this.baseUrl}`;

    return this.http.post<PimMetadata>(url, pimMetadata).pipe(tap(_ => this.updatePimMetadataSubject(true)));
  }

  public putPimMetadata(pimMetadata: PimMetadata): Observable<PimMetadata> {
    const url = `${this.baseUrl}`;
    return this.http.put<PimMetadata>(url, pimMetadata).pipe(tap(_ => this.updatePimMetadataSubject(true)));
  }

  public deletePimMetadata(pimMetadataId: string): Observable<PimMetadata> {
    const url = `${this.baseUrl}/${pimMetadataId}`;
    return this.http.delete<PimMetadata>(url).pipe(tap(_ => this.updatePimMetadataSubject(true)));
  }

  // List in internal Subject

  public getPimMetadataInternal(nocache?: boolean): Observable<PimMetadata[]> {
    if (!this.pimMetadataSubject) {
      this.pimMetadataSubject = new BehaviorSubject<PimMetadata[]>([]);
      this.updatePimMetadataSubject(nocache);
    }
    return this.pimMetadataSubject.asObservable();
  }

  updatePimMetadataSubject(nocache?: boolean): void {
    if (!this.pimMetadataSubject) {
      return;
    }
    this.getPimMetadata(nocache)
      .pipe(first())
      .subscribe((data: PimMetadata[]) => this.pimMetadataSubject.next(data));
  }

  // System
  public getSystem(system: string): Observable<GungSystem> {
    const url = `json/system/${system}`;
    return this.http.get<GungSystem>(url);
  }
}
