<div class="col-12 table-responsive gung-responsive" *ngIf="!!ediMessage.stackTraceMap">
  <table class="table table-striped">
    <tbody>
      <tr *ngFor="let error of ediMessage.stackTraceMap | keyvalue">
        <td>
          <pre>{{ error.value }}</pre>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div class="col-12 table-responsive gung-responsive" *ngIf="ediMessage.validationState == 'VALIDATED_WITH_ERRORS'">
  <div class="row">
    <div class="col-9">
      <h3>
        {{ ediMessage.validationState | translate }}
      </h3>
    </div>
  </div>

  <table class="table table-striped">
    <thead>
      <tr>
        <th class="col-2">Type</th>
        <th class="col-4">Message</th>
        <th class="col-1">Source</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let error of ediMessage.errors">
        <td>
          {{ error.type }}
        </td>
        <td>
          {{ error.error }}
        </td>
        <td>
          {{ error.validator }}
        </td>
      </tr>
    </tbody>
  </table>
</div>
