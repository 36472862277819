<div class="row mt-4 mb-4">
  <div *ngFor="let data of mappedData; last as isLast" class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3">
    <!-- <div class="card product-card product-card-container" id="p-id-{{data.product.id}}" (mousedown)="dragProduct($event, data.product.id)" (mouseup)="stopDragProduct($event, data.product.id)"> -->
    <div
      class="card product-card product-card-container"
      id="p-id-{{ data.product.id }}"
      draggable="true"
      (dragstart)="onDragStart($event, data.product)"
    >
      <div class="card-body">
        <div class="fav-pacs clearfix">
          <span class="badge badge-pill badge-primary float-left" *ngIf="data.product.extra._channelsMap?.PACS === 'Y'"
            >PACS</span
          >
        </div>

        <div class="image-wrapper text-center">
          <img
            class="img-fluid"
            draggable="false"
            [src]="data.product.extra.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+data.product.extra.images[0].s3ETag
              :'orientation:portrait'
            "
            alt="{{ data.product.extra.images[0].description }}"
          />
        </div>

        <div class="product-text">
          <div class="row text-muted small text-left">
            <div class="col-auto mr-auto">
              <span>{{ data.product.id }}</span
              ><span *ngIf="data?.product?.primaryDimension?.[0]?.name">
                - {{ data.product.primaryDimension[0].name }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col text-left text-truncate">
              <span translate>{{ data.product.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
