import { Component, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { PimTemplateProperties } from 'gung-list';
import { ProductExtended } from '../../models';
import { Availability } from '../../models/availability';
import { CustomerProductPrice } from '../../models/price';
import { AuthService } from '../../services/auth/auth.service';
import { AvailabilityPimTemplateProperties, ColumnSortSelectionService, ProductIdPimTemplateProperties } from '../../services/column-sort-selection/column-sort-selection.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { PriceService } from '../../services/price/price.service';
import { GridViewProductCardExtended } from '../concept-detail-product-list-view/concept-detail-product-list-view.component';
import { ProductGridViewComponent } from '../product-grid-view/product-grid-view.component';
import { GungAnonymousConfigService } from '../../services/gung-anonymous-config/gung-anonymous-config.service';

@Component({
  selector: 'lib-concept-detail-v2-product-list-view',
  templateUrl: './concept-detail-v2-product-list-view.component.html',
  styleUrls: ['./concept-detail-v2-product-list-view.component.css']
})
export class ConceptDetailV2ProductListViewComponent extends ProductGridViewComponent implements OnInit {

  public isEnabledColumnSort = this.columnSortSelectionService?.enabledColumnSort;

  public productIdPimTemplateProperties = new ProductIdPimTemplateProperties();

  public availabilityPimTemplateProperties = new AvailabilityPimTemplateProperties();

  public isArticles: boolean = false;

  constructor(
    protected priceService: PriceService,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected router: Router,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    @Optional() protected columnSortSelectionService: ColumnSortSelectionService
  ) {
    super(priceService, gungFlowService, authService, gungAnonymousConfigService);
    this.isArticles = this.router.url.includes('/articles');
  }
  public ngOnInit() {
    super.ngOnInit();
  }
  public trackByFn(index: number) {
    return index;
  }

  protected mapItem(
    id: string,
    product: ProductExtended,
    price: CustomerProductPrice,
    availability: Availability
  ): GridViewProductCardExtended {
    return {
      product,
      price,
      availability
    };
  }

  sortColumn(headerKey: PimTemplateProperties) {
    if (!this.isEnabledColumnSort) {
      return;
    }
    const ascSort = !headerKey.sort || headerKey.sort === 'asc' ? -1 : 1;
    this.clearSortColumns();
    headerKey.sort = ascSort === 1 ? 'asc' : 'desc';
    this.columnSortSelectionService.setSelectedColumnSort(headerKey);
  }

  clearSortColumns() {
    // clear all other columns sorts
    for (const c of this.dynamicColumns) {
      c.sort = undefined;
    }
    this.availabilityPimTemplateProperties.sort = undefined;
    this.productIdPimTemplateProperties.sort = undefined;
    this.columnSortSelectionService.clearSelectedColumnSort();
  }
}
