<div class="form-group {{ customClass }}" *ngIf="loaded; else loading">
    <label *ngIf="label && !suppressLabel" for="dp" class="control-label" [ngClass]="{ 'font-weight-bold': labelBold }">
      {{ label | translate }}
      <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
      <span *ngIf="footnote" class="ml-1">*</span>
    </label>
    <div class="input-group">
      <input
        class="form-control input-date invisible position-absolute w-0"
        placeholder="{{dateUtilService.dateFormat}}"
        name="dp"
        ngbDatepicker
        #d="ngbDatepicker"
        [minDate]="minInitialDate"
        [maxDate]="maxDatePickerDate"
        [startDate]="value"
        [(ngModel)]="value"
        [readonly]="true"
        [disabled]="disabled"
        (dateSelect)="dateSelected($event)"
        [placement]="placement"
				[positionTarget]="positionTarget"
        [firstDayOfWeek]="firstDayOfWeek"
      />
      <input
        class="form-control input-date {{extraInputClass}}"
        placeholder="{{dateUtilService.dateFormat}}"
        (click)="!renderStatic ? d.toggle() : undefined"
        [value]="value ? (dateUtilService.createDateFromNgbDate(value) | date: dateUtilService.dateFormat) : ''"
        readonly
        [disabled]="disabled"
      />
      <div class="input-group-append" *ngIf="!renderStatic">
        <button class="btn btn-outline-secondary calendar {{extraBtnClass}}" (click)="d.toggle()" type="button" [disabled]="disabled">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
  
  <ng-template #loading>
    <div *ngIf="!anyError; else showError">LOADING...</div>
    <ng-template #showError>
      <button class="btn btn-sm btn-danger btn-default" (click)="openErrorModal()" translate>ERROR</button>
    </ng-template>
  </ng-template>
  