import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { Observable } from 'rxjs';
import { ExportDragProductService } from './export-drag-product.service';
import { ProductDragGridComponent } from '../components/product-drag-grid/product-drag-grid.component';
import { PdfProductService } from './pdf-product.service';

@Injectable({
  providedIn: 'root'
})
export class ExportDragConfigService implements ConfigService<any> {
  topFilter = true;
  onPages = [];
  enableSaveSearch = true;
  digitalAssets = false;

  constructor(
    protected productService: PdfProductService,
    protected exportDragProductService: ExportDragProductService
  ) {}

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => ProductDragGridComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getBatchSizes(): number[] {
    return [6];
  }

  getItems(): Observable<any[]> {
    // needs to be change when we get pages from backend
    this.exportDragProductService.productsGrid.next({ 0: [] });
    /*  return this.exportDragProductService.getGrids().pipe( */
    return this.productService.getListProducts();
  }

  getItemId(item: any) {
    return item.id;
  }

  getSearchTerms(item: any) {
    return [item.id, item.name];
  }

  getSortOptions(): ListSortOption<any>[] {
    return [];
  }
}
