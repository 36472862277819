import { Component } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { CustomerListConfigService } from '../../../services/customer-list-config/customer-list-config.service';
import { EdiOrderListConfigService } from '../../../services/edi-order-list-config/edi-order-list-config.service';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';
/* import { EdiOrderListConfigService } from '../../../services/edi-order-list-config/edi-order-list-config.service'; */

@Component({
  selector: 'lib-edi-list-orders',
  template: `<h3 class="d-block page-title text-center" translate>EDI - Orders</h3>
    <lib-filter-list [configService]="ediOrderListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-list-orders.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class EdiListOrdersComponent {
  constructor(public ediOrderListConfigService: EdiOrderListConfigService) {}
}
