import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { CompanyTemplateErpFields } from '../models/company-template';

@Injectable({
  providedIn: 'root'
})
export class CompanyTemplatesConfigService {
  protected companyTemplateErpFields: CompanyTemplateErpFields[] = [];

  getCompanyTemplateErpFields(): Observable<CompanyTemplateErpFields[]> {
    return of(this.companyTemplateErpFields);
  }
}

// Test Fields //
/*protected testCompanyTemplateErpFields: CompanyTemplateErpFields[] = [
  // Text Input //
  {
    path: 'test.salespersonCode',
    label: 'SALES_CODE_IN_ERP',
    inputType: 'GungTextInputComponent',
    placeholder: 'SALES_CODE_IN_ERP'
  },

  // Number Input //
  {
    path: 'test.InvoiceDiscount',
    label: 'DISCOUNT %',
    inputType: 'GungNumberInputComponent',
    placeholder: '0',
    minValue: 0,
    maxValue: 100,
    outputAsString: true
  },

  // Metadata Select //
  {
    path: 'test.countryRegionCode',
    label: 'COUNTRY',
    inputType: 'MetaSelectInputComponent',
    metaTable: 'countriesRegions',
    metaId: 'code',
    metaDisplay: 'displayName',
    metaShowId: true
  },

  // Select with custom data //
  {
    path: 'test.PriceList',
    label: 'PRICELIST',
    inputType: 'GungSelectOptionInputComponent',
    auxDataName: 'priceListOptions',
    data: () => {
      return this.priceService.getPriceListOptions()
    }
  }
];*/