<div>
  <div class="row">
    <div class="col-12 min-height-600 p-1" (click)="edit ? openModal('top') : navigateTo(data.elements?.top.link)">
      <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.top?.image) + ')'">
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.top?.text) + ' !important'">
          {{ getText(data.elements?.top?.text) }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      class="col-xs-12 col-sm-4 p-1 min-height-250"
      (click)="edit ? openModal('middleOne') : navigateTo(data.elements?.middleOne.link)"
    >
      <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.middleOne?.image) + ')'">
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.middleOne?.text) + ' !important'">
          {{ getText(data.elements?.middleOne?.text) }}
        </div>
      </div>
    </div>
    <div
      class="col-xs-12 col-sm-4 p-1 min-height-250"
      (click)="edit ? openModal('middleTwo') : navigateTo(data.elements?.middleTwo.link)"
    >
      <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.middleTwo?.image) + ')'">
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.middleTwo?.text) + ' !important'">
          {{ getText(data.elements?.middleTwo?.text) }}
        </div>
      </div>
    </div>
    <div
      class="col-xs-12 col-sm-4 p-1 min-height-250"
      (click)="edit ? openModal('middleThree') : navigateTo(data.elements?.middleThree.link)"
    >
      <div
        class="w-100 h-100 bgimg"
        [style.backgroundImage]="'url(' + getImage(data.elements?.middleThree?.image) + ')'"
      >
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.middleThree?.text) + ' !important'">
          {{ getText(data.elements?.middleThree?.text) }}
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div
      class="col-xs-12 col-sm-6 p-1 min-height-600"
      (click)="edit ? openModal('bottomOne') : navigateTo(data.elements?.bottomOne.link)"
    >
      <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.bottomOne?.image) + ')'">
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.bottomOne?.text) + ' !important'">
          {{ getText(data.elements?.bottomOne?.text) }}
        </div>
      </div>
    </div>

    <div
      class="col-xs-12 col-sm-6 p-1 min-height-600"
      (click)="edit ? openModal('bottomTwo') : navigateTo(data.elements?.bottomTwo.link)"
    >
      <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.bottomTwo?.image) + ')'">
        <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate
          [style.color]="'' + getTextColor(data.elements?.bottomTwo?.text) + ' !important'">
          {{ getText(data.elements?.bottomTwo?.text) }}
        </div>
      </div>
    </div>
  </div>
</div>
