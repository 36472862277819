<div class="row" *ngIf="allShopify; else loading">
  <div class="col-12">
    <div class="form-check float-right mb-2">
      <input
        class="form-check-input mr-2"
        type="checkbox"
        [(ngModel)]="showAll"
        (ngModelChange)="changeList()"
        id="defaultCheck1"
      />
      <label class="form-check-label" for="defaultCheck1" translate> SHOW_ALL_ITEMS </label>
    </div>
  </div>
  <div class="col-12">
    <nav ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="active">
      <ng-container [ngbNavItem]="shopify.id" *ngFor="let shopify of listShopifyConfig">
        <a ngbNavLink (click)="changeEditor(shopify.id)"
          >{{ shopify.id }}
          <button
            *ngIf="shopify.id === active"
            type="button"
            class="btn btn-danger btn-sm ml-1"
            (click)="$event.stopPropagation(); $event.preventDefault(); removeConfig(shopify.id)"
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
        </a>
        <ng-template ngbNavContent>
          <textarea
            class="form-control"
            type="text"
            rows="20"
            (ngModelChange)="updateObject($event, shopify.id)"
            [(ngModel)]="configEditor"
            [ngModelOptions]="{ updateOn: 'blur' }"
          ></textarea>
        </ng-template>
      </ng-container>
      <ng-container ngbNavIte>
        <button type="button" class="btn btn-success" (click)="add()"><i class="fa-solid fa-plus"></i></button>
      </ng-container>
      <ng-container ngbNavIte>
        <button type="button" class="btn btn-primary ml-auto" (click)="save()">SAVE</button>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
  <div class="col-12">
    <span class="h5 mt-10">Upload image to Shopify</span>
    <div>
      <div>
        <div class="label-input" translate>UPLOAD_IMAGE(S)</div>
        <lib-gung-input-file
          accept=".jpg,.jpeg,.png"
          [multiple]="true"
          [enableDragDrop]="true"
          (onFileSelect)="submitImageFiles($event)"
        ></lib-gung-input-file>
      </div>
    </div>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
