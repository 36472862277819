import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-ean-replace-modal',
  templateUrl: './ean-replace-modal.component.html',
  styleUrls: ['./ean-replace-modal.component.css']
})
export class EanReplaceModalComponent implements OnInit {
  eanForm: FormGroup;
  componentId: string;

  columnCount: number = 0;

  get eanList() {
    return this.eanForm.get('eanList') as FormArray;
  }

  selectedItemIdList: string[];

  constructor (
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    const eanFieldForm = this.formBuilder.group({
      oldEan: '' ,
      newEan: '',
    });

    this.eanForm = this.formBuilder.group({
      eanList: this.formBuilder.array([eanFieldForm])
    });

    this.columnCount = 1;
  }

  addEan() {
    const eanFieldForm = this.formBuilder.group({
      oldEan: '' ,
      newEan: ''
    });

    this.columnCount = this.columnCount +1;
    this.eanList.push(eanFieldForm);
  }

  close() {
    this.activeModal.dismiss('Closed');
  }

  submitData(){
    let eanFromData = this.eanForm.value;
    const eanList = eanFromData.eanList.filter(item => (item.oldEan && item.newEan));
    this.activeModal.close({ eanList: eanList });
  }

  remove(index: number){
    this.eanList.removeAt(index);
    this.columnCount = this.columnCount -1;
  }
}
