import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungTextInputComponent } from '../gung-text-input/gung-text-input.component';

@Component({
  selector: 'lib-gung-text-input-horizontal',
  templateUrl: './gung-text-input-horizontal.component.html',
  styleUrls: ['./gung-text-input-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungTextInputHorizontalComponent),
      multi: true
    }
  ]
})
export class GungTextInputHorizontalComponent extends GungTextInputComponent {
  @Input()
  public labelClass: string | string[];
}
