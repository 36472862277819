import { Component, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OptionsListMulti } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { ExportDigitalAssetsRouterService } from '../../services/export-digital-assets-router.service';
import { DigitalAsset } from '../../services/pdf-digital-assets.service';

@Component({
  selector: 'lib-export-digital-assets-folder-grid-view',
  templateUrl: './export-digital-assets-folder-grid-view.component.html',
  styleUrls: ['./export-digital-assets-folder-grid-view.component.css']
})
export class ExportDigitalAssetsFolderGridViewComponent
  extends ListItemRendererComponent<DigitalAsset[]>
  implements OnInit, OnChanges
{
  parentId: string;

  digitalAsset: DigitalAsset;
  public userGroups: OptionsListMulti[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected exportDigitalAssetsRouterService: ExportDigitalAssetsRouterService
  ) {
    super();
  }

  ngOnInit() {
    this.parentId = undefined;
    if (this.route.snapshot.url.length > 0) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  goInto(digitalAssetId) {
    this.exportDigitalAssetsRouterService.currentDigitalAsset.next(digitalAssetId);
  }
}
