<div class="form-group" [ngClass]="{ row: !!horizontal }">
  <label
    [for]="inputId"
    *ngIf="!suppressLabel"
    class="col-form-label"
    [ngClass]="{ 'col-12 col-sm-4': !!horizontal, 'font-weight-bold': !!labelBold, labelClass: !!labelClass }"
  >
    {{ translateTag | translate: translateParams }}
    <i *ngIf="!!tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div [ngClass]="{ col: !!horizontal }">
    <angular-editor
      #editor
      [placeholder]="placeholder | translate"
      [(ngModel)]="value"
      [config]="angularEditorConfig"
      [name]="inputId"
      [id]="inputId"
      [ngClass]="{ extraInputClass: !!extraInputClass }"
      [disabled]="disabled"
      [required]="required"
      style="height: auto"
      (blur)="onTouched()"
    ></angular-editor>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
