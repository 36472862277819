import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router, UrlSegment } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { catchError, filter, first, map, of, tap } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { gungGetPropertyOfObject } from 'gung-common';
import { AssortmentService } from './assortment.service';
import { ProductService } from './products/product.service';
import { SelectedCustomerService } from './selected-customer/selected-customer.service';
import { NavigationConfig } from '../models/navigation-config';

@Injectable({
  providedIn: 'root'
})
export class GungTitleService {
  public  gungDefaultTitle: string;
  protected gungTitleVar: { [s: string]: any } = {};

  constructor(
    protected router: Router,
    protected titleService: Title,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected productService: ProductService,
    protected selectedCustomerService: SelectedCustomerService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    selectedCustomerService.getSelectedCustomer().subscribe(selectedCustomer => {
      if (!this.environment?.disableStandardGungTitle) {
        this.gungTitleVar['selectedCustomer'] = selectedCustomer;
        this.setGungTitle(this.gungTitleVar.gungTitle);
      }
    });
  }

  public getDefaultTitle(): string {
    return this.environment?.websiteTitle || this.environment?.['projectName'] || this.titleService.getTitle();
  }

  public initGungTitleService(): void {
    if (this.environment?.disableStandardGungTitle) {
      return;
    }
    if (!this.gungDefaultTitle) {
      this.gungDefaultTitle = this.getDefaultTitle();
    }
    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => {
        let route: ActivatedRoute = this.router.routerState.root;
        let routeTitle = '';
        while (route!.firstChild) {
          route = route.firstChild;
        }
        return route.snapshot;
      })
    ).subscribe((route) => {
      const gungTitle = route?.data?.gungTitle;
      this.gungTitleVar['gungTitle'] = gungTitle;
      this.gungTitleVar['route'] = route;
      if (!!route.data?.extraFetch) {
        this.handleExtraFetch(route, gungTitle);
      } else {
        this.setGungTitle(gungTitle);
      }
    });
  }

  protected handleExtraFetch(route: ActivatedRouteSnapshot, title: string): void {
    const extraFetch: { [s: string]: string } = route.data.extraFetch;

    if (!!extraFetch?.['assortment']) {
      const paramKey: string = 'assortment';
      const paramName: string = extraFetch[paramKey];
      this.assortmentService.getAssortment(route.params[paramName]).pipe(
        first(),
        catchError(_ => of(undefined)),
        tap(res => this.gungTitleVar[paramKey] = res)
      ).subscribe(_ => this.setGungTitle(title));
    }

    if (!!extraFetch?.['product']) {
      const paramKey: string = 'product';
      const paramName: string = extraFetch[paramKey];
      this.productService.getProduct(route.params[paramName]).pipe(
        first(),
        catchError(_ => of(undefined)),
        tap(res => this.gungTitleVar[paramKey] = res)
      ).subscribe(_ => this.setGungTitle(title));
    }

    if (!!extraFetch.assortmentTree) {
      const url: UrlSegment[] = route.url;
      return;
    }

    this.setGungTitle(title);
  }

  protected setGungTitle(gungTitle: string) {
    if (!gungTitle) {
      // Set tile
      setTimeout(() => {
        this.titleService.setTitle(this.gungDefaultTitle);
      }, 500);
      return;
    }
    let title = gungTitle;
    const tags = title.match(/<GUNG>.*?<\/GUNG>/g) || [];
    const translateTags = title.match(/<GUNGTRANSLATE>.*?<\/GUNGTRANSLATE>/g) || [];

    // Replace dynamic tags
    tags.forEach((tag: string) => {
      const dynamicKey = tag.slice('<GUNG>'.length, -'</GUNG>'.length); // Remove tags
      const dynamicValue = gungGetPropertyOfObject(dynamicKey, this.gungTitleVar);
      if (dynamicValue !== undefined && dynamicValue !== null) {
        title = title.replace(new RegExp(tag, 'g'), dynamicValue);
      } else {
        title = title.replace(new RegExp(tag, 'g'), '');
      }
    });

    // Replace translate tags
    translateTags.forEach((tag: string) => {
      const translateKey = tag.slice('<GUNGTRANSLATE>'.length, -'</GUNGTRANSLATE>'.length); // Remove tags
      const translateValue = this.translateService.instant(translateKey);
      if (translateValue !== undefined && translateValue !== null) {
        title = title.replace(new RegExp(tag, 'g'), translateValue);
      }
    });

    // Set tile
    setTimeout(() => {
      if (title) {
        this.titleService.setTitle(this.translateService.instant(title) + ' - ' + this.gungDefaultTitle);
      } else {
        this.titleService.setTitle(this.gungDefaultTitle);
      }
    }, 500);
  }
}
