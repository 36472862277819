import { Component, Input, OnInit } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';

@Component({
  selector: 'lib-user-details-order-params',
  templateUrl: './user-details-order-params.component.html',
  styleUrls: ['./user-details-order-params.component.css']
})
export class UserDetailsOrderParamsComponent implements OnInit {
  @Input()
  currentUser: User;

  private extraKey = 'extra';
  orderParamsTextHolder = '';
  parseError = {
    hasError: false,
    errorText: ''
  };

  constructor() {}

  ngOnInit() {
    if (!!this.currentUser[this.extraKey] && !!this.currentUser[this.extraKey].orderParams) {
      this.orderParamsTextHolder = JSON.stringify(this.currentUser[this.extraKey].orderParams, undefined, 4);
    }
  }

  validate(): void {
    try {
      if (!this.currentUser[this.extraKey]) {
        this.currentUser[this.extraKey] = {};
      }

      this.parseError.hasError = false;
      this.currentUser[this.extraKey].orderParams = JSON.parse(this.orderParamsTextHolder);
    } catch (error) {
      this.parseError.hasError = true;
      this.parseError.errorText = error.message;
    }
  }
}
