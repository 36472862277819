import { Component, OnInit } from '@angular/core';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { formatISO } from 'date-fns';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { first } from 'rxjs';

@Component({
  selector: 'lib-gung-data-export-pricat-confirm-modal',
  templateUrl: './gung-data-export-pricat-confirm-modal.component.html',
  styleUrls: ['./gung-data-export-pricat-confirm-modal.component.css']
})
export class GungDataExportPricatConfirmModalComponent implements OnInit {
  email: string = '';
  definitionId: string = '';
  preOrderToDate: Date = undefined;
  validFromDate: Date = undefined;
  validToDate: Date = undefined;
  supplementaryValidShipDateFrom: Date = undefined;
  supplementaryValidShipDateTo: Date = undefined;

  definitions: { id: string; name: string }[] = [];
  get confirmBtnLabel(): string {
    return this.isValid() ? 'OK' : 'FILL_ALL_REQUIRED_FIELDS';
  }

  delegate: GungModalService;
  componentId: string;

  constructor(protected http: HttpClient) { }

  ngOnInit() {
    this.componentId = GungDataExportPricatConfirmModalComponent.name;

    this.getPricatDefinitions()
      .pipe(first())
      .subscribe((definitions: any[]) => {
        this.definitions = definitions.map(definition => {
          return {
            id: definition.id,
            name: definition.name
          };
        });

        if (this.definitions.length > 0) {
          this.definitionId = this.definitions[0].id;
        }
      });
  }

  confirm() {
    this.delegate.modalRef.get(this.componentId).close({
      email: this.email,
      definitionId: this.definitionId,
      params: {
        preOrderToDate: this.formatDate(this.preOrderToDate),
        validFromDate: this.formatDate(this.validFromDate),
        validToDate: this.formatDate(this.validToDate),
        supplementaryValidShipDateFrom: this.formatDate(this.supplementaryValidShipDateFrom),
        supplementaryValidShipDateTo: this.formatDate(this.supplementaryValidShipDateTo)
      }
    });
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).close({ email: '' });
  }

  isValid(): boolean {
    return !!this.email && !!this.definitionId && !!this.preOrderToDate && !!this.validFromDate && !!this.validToDate;
  }

  protected formatDate(date: Date): string {
    return formatISO(date);
  }

  getPricatDefinitions(): Observable<any[]> {
    const url = `json/pricat-definitions`;
    const headers = { maxAge: '-1' };
    return this.http.get<any[]>(url, { headers });
  }
}
