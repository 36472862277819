import { Injectable } from '@angular/core';
import { isSameDay, isBefore } from 'date-fns';
import { DocumentTemplatesService } from 'gung-common';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { ActivityInternalTodoTableComponent } from '../../components/activity-internal-todo/activity-internal-todo-table/activity-internal-todo-table.component';
import { ActivityInternalTableComponent } from '../../components/activity-internal/activity-internal-table/activity-internal-table.component';
import { ActivityInternalExportService } from '../activity-internal-export/activity-internal-export.service';
import { ActivityInternalListConfigService } from '../activity-internal-list-config/activity-internal-list-config.service';
import { ActivityInternal, ActivityInternalService } from '../activity-internal/activity-internal.service';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityInternalTodoListConfigService extends ActivityInternalListConfigService {
  constructor(
    protected activityInternalService: ActivityInternalService,
    protected metadataService: MetadataService,
    documentTemplatesService: DocumentTemplatesService,
    activityInternalExportService: ActivityInternalExportService
  ) {
    super(activityInternalService, metadataService, documentTemplatesService, activityInternalExportService);
  }

  getItems(): Observable<ActivityInternal[]> {
    return this.activityInternalService.getAll().pipe(
      map(result => {
        return result.filter(r => {
          const todayDate = new Date();
          const activityDate = new Date(r.activityDate);

          if (isSameDay(activityDate, todayDate) || isBefore(activityDate, todayDate)) {
            return true;
          }
        });
      }),
      map(result =>
        result.sort((a, b) => {
          if (new Date(a.activityDate) > new Date(b.activityDate)) {
            return 1;
          }
          if (new Date(a.activityDate) < new Date(b.activityDate)) {
            return -1;
          }
          return 0;
        })
      )
    );
  }

  getLayouts(): ListLayout<ActivityInternal>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ActivityInternalTodoTableComponent as any,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }
}
