import { EdiService } from './../edi/edi.service';
import { EdiGungMessages } from './../../models/edi';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EdiInvoiceModalComponent } from '../../components/edi/edi-invoice-modal/edi-invoice-modal.component';
import { EdiDespatchAdviceModalComponent } from '../../components/edi/edi-despatch-advice-modal/edi-despatch-advice-modal.component';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService, JsonViewModal } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class EdiMessageActionConfigService implements ActionButtonConfigService<EdiGungMessages> {
  base = [
    {
      title: 'DOWNLOAD',
      action: (item: EdiGungMessages) => {
        const url = this.backendInterceptor.getBaseUrl() + '/admin/download/edi/xml/' + item.id;
        const win = window.open(url, '_blank');
        win.focus();
      }
    },
    {
      title: 'VIEW_JSON',
      action: (item: EdiGungMessages) => {
        const jsonView: JsonViewModal = {
          title: 'JSON overview',
          json: item
        };
        this.gungModalService.openJSONModal(jsonView, {
          ariaLabelledBy: 'modal-basic-title',
          size: 'lg'
        });
      }
    }
  ];

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected ediService: EdiService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  getButtons(itemEdiGungMessages: EdiGungMessages): Observable<ActionButton<EdiGungMessages>[]> {
    const buttons = [];
    if (!!itemEdiGungMessages.isOrder) {
      buttons.push({
        title: 'RESEND_ORDER_CONTEXT',
        action: (item: EdiGungMessages) => {
          this.ediService.resendOrderContext(item.orderContextId).subscribe(
            response => {
              const msg = 'Successfully resent order context ' + item.orderContextId;
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            },
            error => {
              const msg = 'An error occured when resending order context ' + item.orderContextId;
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            }
          );
        }
      });
    }
    if (!!itemEdiGungMessages.isOrder || !!itemEdiGungMessages.isOrderResponse) {
      buttons.push({
        title: 'GENERATE_ORDER_RESPONSE',
        action: (item: EdiGungMessages) => {
          this.ediService.generateOrderResponse(item.orderContextId).subscribe(
            response => {
              const msg =
                'Successfully generated order response for order ' +
                item.orderContextId +
                ' - it will be uploaded to B24 in a few minutes ' +
                item.orderContextId;
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            },
            error => {
              const msg = 'An error occured when generating order response for order ' + item.orderContextId;
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            }
          );
        }
      });
    }
    if (!!itemEdiGungMessages.isOrder) {
      buttons.push({
        title: 'GENERATE_EDI_INVOICE',
        action: (item: EdiGungMessages) => {
          const modalRef = this.modalService.open(EdiInvoiceModalComponent, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg',
            backdrop: 'static'
          });
          modalRef.componentInstance.data = item;
          modalRef.result.then(
            result => {
              this.ediService.generateInvoice(item.invoiceNumber, item.orderNumberModel).subscribe(
                (response: any) => {
                  const msg =
                    'Successfully generated invoice for order ' +
                    item.orderIds[0] +
                    ' - it will be uploaded to B24 in a few minutes ' +
                    item.orderNumberModel;
                  this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
                },
                error => {
                  const msg = 'An error occured when generating invoice for order ' + item.orderNumberModel;
                  this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
                }
              );
            },
            reason => {}
          );
        }
      });
    }
    if (!!itemEdiGungMessages.isOrder) {
      buttons.push({
        title: 'GENERATE_DESPATCH_ADVICE',
        action: (item: EdiGungMessages) => {
          const modalRef = this.modalService.open(EdiDespatchAdviceModalComponent, {
            ariaLabelledBy: 'modal-basic-title',
            size: 'lg',
            backdrop: 'static'
          });
          modalRef.componentInstance.data = item;
          modalRef.result.then(
            result => {
              this.ediService.generateDespatchAdvices(item.selectedOrderIdsForDespatchAdvice, item.id).subscribe(
                (response: any) => {
                  const msg =
                    'Successfully generated despatch advice for order ' +
                    item.selectedOrderIdsForDespatchAdvice +
                    ' - it will be uploaded to B24 in a few minutes ' +
                    result.selectedOrderIdsForDespatchAdvice;
                  this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
                },
                error => {
                  const msg =
                    'An error occured when generating despatch advice for order ' +
                    item.selectedOrderIdsForDespatchAdvice;
                  this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
                }
              );
            },
            reason => {}
          );
        }
      });
    }
    if (!!itemEdiGungMessages.isInvoice) {
      buttons.push({
        title: 'GENERATE_INVOICE',
        action: (item: EdiGungMessages) => {
          if (!item.invoiceNumber || item.invoiceNumber.length === 0) {
            const msg = 'Error! The Invoicenumber is missing on the Gung Invoice';
            this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
          }
          if (!item.supplierOrderNumber || item.supplierOrderNumber.length === 0) {
            const msg = 'Error! The Garp ordernumber is missing on the Gung Invoice';
            this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
          }
          this.ediService.generateInvoice(item.invoiceNumber, item.orderIds[0]).subscribe(
            (response: any) => {
              const msg =
                'Successfully generated invoice for order ' +
                item.supplierOrderNumber +
                ' - it will be uploaded to B24 in a few minutes (invoice number ' +
                item.invoiceNumber +
                ')';
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            },
            error => {
              const msg = 'An error occured when generating invoice for order ' + item.supplierOrderNumber;
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            }
          );
        }
      });
    }
    if (!!itemEdiGungMessages.isDespatchAdvice) {
      buttons.push({
        title: 'GENERATE_DESPATCH_ADVICE_AGAIN',
        action: (item: EdiGungMessages) => {
          this.ediService.generateDespatchAdviceFromAdviceMessage(item).subscribe(
            (response: any) => {
              const msg =
                'Successfully generated despatch advice for order ' +
                item.orderIds[0] +
                ' - it will be uploaded to B24 in a few minutes ' +
                item.orderIds[0];
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            },
            error => {
              const msg = 'An error occured when generating despatch advice for order ' + item.orderIds[0];
              this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
            }
          );
        }
      });
    }
    buttons.push(...this.base);
    return new Observable<ActionButton<EdiGungMessages>[]>(subscriber => {
      subscriber.next(buttons);
      subscriber.complete();
    });
  }
}
