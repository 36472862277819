import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Availability } from '../../../models/availability';
import { parse } from 'date-fns';
import { Product } from '../../../models';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-availability-modal',
  templateUrl: './availability-modal.component.html',
  styleUrls: ['./availability-modal.component.css']
})
export class AvailabilityModalComponent implements OnInit{
  product: Product;
  availability: Availability;
  customerOrders: any[] = [];
  purchaseOrders: any[] = [];

  constructor(public activeModal: NgbActiveModal, public dateUtilService: DateUtilService) {}

  ngOnInit(): void {
    if(this.availability?.extra?.transactions){
      this.customerOrders = this.availability?.extra?.transactions.filter(o => {
        return o.transactionType === 'CO'; //Customer order
      });
      this.purchaseOrders = this.availability?.extra?.transactions.filter(o => {
        return o.transactionType === 'PO'; //Customer order
      });
    }
  }

  parseDate(date: string): Date {
    return parse(date, 'yyMMdd', new Date());
  }

  parseFloat(value): number {
    return parseFloat(value);
  }
}
