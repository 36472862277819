<div class="modal-header" *ngIf="loaded">
  <h4 class="modal-title mt-3"><span translate>ACTIVITY</span> {{ data.id }}</h4>
  <div *ngIf="isAdminOrSales" ngbDropdown placement="bottom-left" class="d-inline-block dropup mt-3 ml-auto">
    <button class="btn btn-outline-primary export-area-buttons" id="dropdownBasic1" ngbDropdownToggle translate>
      ACTIONS
    </button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
      <button
        class="export-area-buttons"
        ngbDropdownItem
        *ngFor="let button of actions | async"
        (click)="button.performAction(null)"
        translate
      >
        {{ button.label }}
      </button>
    </div>
  </div>
  <button type="button" class="close m-0" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body" *ngIf="data">
  <div class="info bg-light m-2 p-1">
    <div class="row">
      <div class="col-6">
        <label translate>ACTIVITY_STATUS</label>
      </div>
      <div class="col-5">
        <!-- <span>{{data.status}}</span> -->
        {{
          metadataService.getMetadataValue('ACTIVITYINTERNALSTATUS', 'name', data.status + '@ACTIVITYINTERNALSTATUS')
        }}
      </div>
      <div class="col-1">
        <button
          *ngIf="isAdminOrSales"
          type="button"
          class="btn btn-sm btn-light mr-1"
          (click)="editActivityInternal(data)"
        >
          <i class="fas fa-edit"></i>
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label translate>DATE_CREATED</label>
      </div>
      <div class="col-6">
        <span>{{ data.date | date : dateUtilService.dateFormat }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label translate>CUSTOMER</label>
      </div>
      <div class="col-6">
        <span>{{ data.customerId }} - {{ data.customerName }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label translate>CUSTOMER_RESPONSIBLE</label>
      </div>
      <div class="col-6">
        <lib-gung-meta-output
          [value]="data.customerResponsible"
          [gungTranslate]="'CUSTOMER_RESPONSIBLE'"
          [suppressLabel]="true"
          [metadataTable]="activityInternalCustomerResponsibleService.metadataTable"
          [metaOptionName]="activityInternalCustomerResponsibleService.metadataName"
          [showIfNotFound]="true"
          [showOnlyText]="true"
        ></lib-gung-meta-output>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label translate>ACTIVITY_DATE</label>
      </div>
      <div class="col-6">
        <span>{{ data.activityDate | date : dateUtilService.dateFormat + ' HH:mm' }}</span>
      </div>
    </div>
    <div class="row" *ngIf="contact">
      <div class="col-6">
        <label translate>CONTACT</label>
      </div>
      <div class="col-6">
        <span>{{ contact.name }} - {{ contact.email }}</span>
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <label translate>ACTIVITY_TYPE</label>
      </div>
      <div class="col-6">
        <!-- <span>{{data.type}}</span> -->
        {{ metadataService.getMetadataValue('ACTIVITYINTERNALTYPE', 'name', data.type + '@ACTIVITYINTERNALTYPE') }}
      </div>
    </div>
  </div>
  <div class="comments bg-light m-2 p-1">
    <div
      class="row"
      *ngFor="let comment of data.comments; let index = index"
      [class.justify-content-end]="currentUser.name === comment.user"
    >
      <div
        class="col-10 rounded m-2"
        [ngClass]="{ response: currentUser.name !== comment.user, answer: currentUser.name === comment.user }"
      >
        <div class="row">
          <div class="col-6 text-left">
            {{ comment.user }}
          </div>
          <div class="col-6 text-right">
            {{ comment.date | date : dateUtilService.dateFormat }}
          </div>
        </div>
        <div class="row">
          <div *ngIf="!commentEditMap[index]; else showEdit" class="col-12">
            {{ comment.comment }}
          </div>
          <ng-template #showEdit>
            <div class="col-12">
              <lib-gung-text-area-input
                gungTranslate="EDIT_COMMENT"
                [(ngModel)]="data.comments[index].comment"
                [rowsNumber]="5"
              ></lib-gung-text-area-input>
            </div>
            <div class="col-12">
              <button class="btn btn-primary mr-2" (click)="saveExisting(); toggleEdit(index);" translate>SAVE</button>
              <button class="btn btn-primary" (click)="saveExisting(); toggleEdit(index);" translate>CANCEL</button>
            </div>
          </ng-template>
        </div>
        <div class="row mt-2 mb-2">
          <div class="col-12 text-right">
            <span *ngFor="let attachment of comment.attachments" [title]="attachment" class="mx-2 cursor-pointer">
              <i *ngIf="!!attachment && !isImage(attachment); else showThumbnail" class="fas fa-download" (click)="downloadFile(attachment)"></i>
              <ng-template #showThumbnail>
                <span *ngIf="!!attachment" class="thumbnail-container">
                  <img [src]="attachment | gungDownloadUrl" class="thumbnail">
                  <span class="thumbnail-cover" (click)="downloadFile(attachment)">
                    <i class="fas fa-download"></i>
                  </span>
                </span>
              </ng-template>
            </span>
            <span *ngIf="!commentEditMap.includes(true) && (currentUser.name === comment.user || isAdminOrSales)" class="mx-2 cursor-pointer">
              <i class="fas fa-edit" (click)="toggleEdit(index)"></i>
            </span>
            <span *ngIf="!commentEditMap.includes(true) && (currentUser.name === comment.user || isAdminOrSales)" class="mx-2 cursor-pointer">
              <i class="fas fa-trash" (click)="removeComment(index)"></i>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="comment bg-light m-2 p-1">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="comment" class="control-label" translate>COMMENT</label>
          <textarea
            class="form-control"
            type="text"
            name="comment"
            id="comment"
            [(ngModel)]="comment"
            rows="5"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label class="w-100" for="file-upload" translate>ATTACH_FILES</label>
          <input
            #inputFile
            type="file"
            class=""
            id="file-upload"
            aria-describedby="file-upload"
            [multiple]="multipleFiles"
            (change)="onFileSelect($event.target.files)"
            [disabled]="disabled ? true : null"
          />
        </div>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cance click')" translate>CLOSE</button>
  <button type="button" class="btn btn-primary" [disabled]="disabled ? true : null" (click)="onSave($event)" translate>
    ADD_COMMENT
  </button>
</div>
