import { Component, OnInit } from '@angular/core';
import { TopProductsListConfigService } from '../../services/top-products-list-config/top-products-list-config.service';

@Component({
  selector: 'lib-top-sold-articles',
  templateUrl: './top-sold-articles.component.html',
  styleUrls: ['./top-sold-articles.component.css']
})
export class TopSoldArticlesComponent implements OnInit {
  constructor(public topProductListConfig: TopProductsListConfigService) {}

  ngOnInit(): void {}
}
