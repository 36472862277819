import { Injectable } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormUtilService {
  constructor() {}

  validEmailExpression = /^.+@.+\.[\w-]{2,4}$/;
  public emailValidators: ValidatorFn[] = [Validators.email, this.customEmailValidator(this.validEmailExpression)];

  resetForm(form: FormGroup): void {
    // Method to reset forms regardless of many subgroups it has
    this.resetFormFields(form);
    form.enable();
  }

  resetFormFields(form: FormGroup): void {
    Object.keys(form.controls).forEach(key => {
      const abstractControl = form.get(key);
      // If the control is an instance of FormGroup i.e a nested FormGroup
      // then recursively call this same method passing it
      // the FormGroup so we can get to the form controls in it
      if (abstractControl instanceof FormGroup) {
        this.resetFormFields(abstractControl);
        // If the control is not a FormGroup then we know it's a FormControl
      } else {
        abstractControl.setValue('');
        abstractControl.markAsPristine();
        abstractControl.markAsUntouched();
      }
    });
  }

  validatorRequiredUponCondition(predicate: () => boolean): ValidationErrors | null {
    // Applies Validators Required if it meets condition
    return formControl => {
      if (!formControl.parent) {
        return null;
      }
      if (predicate()) {
        return Validators.required(formControl);
      }
      return null;
    };
  }

  customEmailValidator(regexp: RegExp):  ValidatorFn {
    return (control:AbstractControl):  ValidationErrors|  null  => {
      if(!regexp.test(control.value)){
        return {
          'FORM_ERROR_EMAIL':  true
        }
      }
      return  null;
    }
  }
}
