import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { ActuatorEnvironmentProperty } from '../../../../services/actuator-environment/actuator-environment.service';

@Component({
  selector: 'lib-actuator-environment-list',
  templateUrl: './actuator-environment-list.component.html',
  styleUrls: ['./actuator-environment-list.component.css']
})
export class ActuatorEnvironmentListComponent extends ListItemRendererComponent<ActuatorEnvironmentProperty[]> implements AfterViewInit {
  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
