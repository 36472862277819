import { Component, OnInit } from '@angular/core';
import { ConfigurationsService } from '../../../services/configurations.service';
import { first, map } from 'rxjs';

@Component({
  selector: 'lib-configurations-editor',
  templateUrl: './configurations-editor.component.html',
  styleUrls: ['./configurations-editor.component.css']
})
export class ConfigurationsEditorComponent implements OnInit {
  loading;
  configurations: any[];
  configurationsStrings: string[];

  constructor(protected configurationsService: ConfigurationsService) {}

  ngOnInit(): void {
    this.loading = true;
    this.configurationsService
      .getConfigurations()
      .pipe(first())
      .subscribe(configurations => {
        this.configurations = configurations;

        this.configurationsStrings = this.configurations.map(d => JSON.stringify(d, undefined, 4));
        this.loading = false;
      });
  }

  save(configuration) {
    this.configurationsService
      .updateConfiguration(configuration)
      .pipe(first())
      .subscribe(_ => alert(`${configuration.id} saved.`));
  }
}
