<div class="row" *ngIf="orderData">
  <div class="header-title col-12">
    <div>
      <h3>
        <span translate="ORDER_MESSAGE"></span>: {{ orderData.id }}
        <span style="float: right">
          <lib-edi-send-status [message]="orderData"></lib-edi-send-status>
        </span>
      </h3>
    </div>
  </div>
  <div class="col-12">
    <lib-action-btn class="float-right mb-2" [(item)]="orderData" [config]="orderActionConfigService"> </lib-action-btn>
  </div>
  <div class="col">
    <lib-gung-tabset #ngbTab="gungTabset" justify="fill">
      <lib-gung-tab [title]="'EDI_ORDER_DETAILS' | translate">
        <ng-template libGungTabContent>
          <div class="row pt-4 px-2" *ngIf="orderData.erpIds">
            <div class="col-12 info-head">
              <div class="row">
                <div class="col">
                  <strong>THIS RESULTED IN ORDER IDS :</strong> 
                  <a *ngFor="let erpId of orderData.erpIds" [routerLink]="['/orders', erpId]" class="ml-1">{{erpId}}</a>
                </div>
                <div class="col-auto" *ngIf="!!orderData.timestamp">
                  <span class="float-right"><strong translate>TIMESTAMP</strong> {{ orderData.timestamp }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row pt-4">
            <div
              class="col-12 table-responsive gung-responsive"
              *ngIf="
                orderData.gungEdiMessageSendStatus == 'ErrorWhileSending' ||
                orderData.gungEdiMessageSendStatus == 'ErrorReported'
              "
            >
              <div class="row">
                <div class="col-9">
                  <h3>Errors occured when trying to create order</h3>
                </div>
              </div>

              <div class="alert alert-warning">Contact: support&#64;gung.se</div>
              <table class="table table-striped">
                <tbody>
                  <tr *ngFor="let error of orderData.stackTraceMap | keyvalue">
                    <td>
                      <pre>{{ error.value }}</pre>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div
              class="col-12 table-responsive gung-responsive"
              *ngIf="orderData.validationState == 'VALIDATED_WITH_ERRORS'"
            >
              <div class="row">
                <div class="col-9">
                  <h3>
                    {{ orderData.validationState | translate }}
                  </h3>
                </div>
              </div>

              <table class="table table-striped">
                <thead>
                  <tr>
                    <th class="col-2">Type</th>
                    <th class="col-4">Message</th>
                    <th class="col-1">Source</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let error of orderData.errors">
                    <td>
                      {{ error.type }}
                    </td>
                    <td>
                      {{ error.error }}
                    </td>
                    <td>
                      {{ error.validator }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="col-12">
              <lib-gung-tabset #ngbTab="gungTabset" justify="fill">
                <lib-gung-tab *ngIf="!!orderData.consigneeGln">
                  <ng-template libGungTabTitle
                    >{{ 'CONSIGNEE' | translate }} : {{ orderData.consigneeGln }}
                  </ng-template>
                  <ng-template libGungTabContent>
                    <lib-edi-gln-details-table [glnNumber]="orderData.consigneeGln" [addressFromMessage]="orderData.consigneeAddress"> </lib-edi-gln-details-table>
                  </ng-template>
                </lib-gung-tab>
                <lib-gung-tab *ngIf="!!orderData.deliveryGln">
                  <ng-template libGungTabTitle
                    >{{ 'DELIVERY_POINT' | translate }} : {{ orderData.deliveryGln }}</ng-template
                  >
                  <ng-template libGungTabContent>
                    <lib-edi-gln-details-table [glnNumber]="orderData.deliveryGln" [addressFromMessage]="orderData.deliveryAddress"> </lib-edi-gln-details-table>
                  </ng-template>
                </lib-gung-tab>
                <lib-gung-tab *ngIf="!!orderData.buyerGln">
                  <ng-template libGungTabTitle>{{ 'BUYER' | translate }} : {{ orderData.buyerGln }} </ng-template>
                  <ng-template libGungTabContent>
                    <lib-edi-gln-details-table [glnNumber]="orderData.buyerGln" [addressFromMessage]="orderData.buyerAddress"> </lib-edi-gln-details-table>
                  </ng-template>
                </lib-gung-tab>
              </lib-gung-tabset>
            </div>
            <h3 translate="ORDER_LINES"></h3>
            <div class="col-12 table-responsive gung-responsive">
              <table class="table table-hover">
                <thead>
                  <tr>
                    <th translate>PRODUCT</th>
                    <th translate>QUANTITY</th>
                    <th translate>PRICE</th>
                    <th translate>DELIVERY_DATE</th>
                  </tr>
                </thead>
                <tbody *ngFor="let item of orderData.items; let idx = index">
                  <tr [class.bg-cancelled]="item.changes.cancelled || item.changes.ignored">
                    <td [attr.data-label]="'ITEM ID' | translate">
                      <div *ngIf="item.name" class="row">
                        <label class="col-4">NAME: </label><b>{{ item.name }}</b>
                      </div>
                      <div *ngIf="item.ids.gungId" class="row">
                        <label class="col-4">ID: </label><b>{{ item.ids.gungId }}</b>
                      </div>
                      <div *ngIf="item.ids.ean13" class="row">
                        <label class="col-4">EAN13: </label>{{ item.ids.ean13 }}
                      </div>
                      <div *ngIf="item.ids.upca" class="row">
                        <label class="col-4">UPCA: </label>{{ item.ids.upca }}
                      </div>
                      <div *ngIf="item.ids.buyerId" class="row">
                        <label class="col-4">BUYER: </label>{{ item.ids.buyerId }}
                      </div>
                    </td>
                    <td [attr.data-label]="'QUANTITY' | translate">
                      <div *ngIf="item.quantities.expectedQuantity" class="row">
                        <label class="col-4">EXP:&nbsp;</label><b>{{ item.quantities.expectedQuantity }}</b>
                      </div>
                    </td>
                    <td [attr.data-label]="'PRICE' | translate">
                      <div *ngIf="item.prices.expectedUnitNetPrice" class="row">
                        <label class="col-4">EXPECTED:&nbsp;</label><b>{{ item.prices.expectedUnitNetPrice.value }}</b>
                      </div>
                      <div *ngIf="item.prices.actualUnitNetPrice" class="row">
                        <label class="col-4">ACTUAL:&nbsp;</label><b>{{ item.prices.actualUnitNetPrice.value }}</b>
                      </div>
                    </td>
                    <td [attr.data-label]="'DELIVERY_DATE' | translate">
                      {{ item.deliveryDate }}
                      <span *ngIf="item.changes.cancelled || item.changes.ignored" class="d-block">
                        <i class="fa-solid fa-triangle-exclamation text-danger mr-1"></i>
                        <span translate>CANCELED</span>
                      </span>
                    </td>
                  </tr>
                  <tr *ngIf="(item.errors | keyvalue).length > 0">
                    <td colspan="5" style="border-left: 10px solid red; padding-left: 0px">
                      <table class="table">
                        <thead>
                          <tr>
                            <th class="col-2">Type</th>
                            <th class="col-6">Message</th>
                            <th class="col-2">Source</th>
                            <th class="col-2">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let error of item.errors">
                            <td>
                              {{ error.type }}
                            </td>
                            <td>
                              {{ error.error }}
                            </td>
                            <td>
                              {{ error.validator }}
                            </td>
                            <td class="col-2">
                              <div class="col-12">
                                <button
                                  class="btn btn-sm btn-block btn-primary"
                                  (click)="cancelLineAndRevalidate(item, idx)"
                                  translate
                                >
                                  CANCEL_LINE
                                </button>
                                <button
                                  class="btn btn-sm btn-block btn-secondary"
                                  (click)="changeLineAndRevalidate(item, idx)"
                                  translate
                                >
                                  CHANGE_ITEM_ID
                                </button>
                                <button
                                  class="btn btn-sm btn-block btn-secondary"
                                  (click)="changeQuantityAndRevalidate(item, idx)"
                                  *ngIf="error.validator === 'EdiQuantityValidator'"
                                  translate
                                >
                                  CHANGE_QUANTITY
                                </button>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--
                            <div class="col-12">
                                <lib-edi-row-table [data]="orderData.items" [columns]="itemsColumns">
                                </lib-edi-row-table>
                            </div>
                            -->
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'ORDER_RESPONSES' | translate" *ngIf="orderData.erpIds">
        <ng-template libGungTabContent>
          <div class="row pt-4" *ngIf="relations">
            <div class="col-12">
              <div class="info-head"><strong>THIS RESULTED IN ORDER IDS :</strong> 
                <a *ngFor="let erpId of orderData.erpIds" [routerLink]="['/orders', erpId]" class="ml-1">{{erpId}}</a>
              </div>
            </div>
            <div class="col-12 mt-3" *ngIf="ordrspToGenerate.length > 0">
              <h5 class="text-center" translate>ORDERS_IDS_READY_TO_GENERATE</h5>
              <div class="row">
                <div class="col-12">
                  <table class="table table-striped">
                    <thead>
                      <tr>
                        <th translate>ORDER_ID</th>
                        <th class="text-right" translate>TOOLS</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let orderId of ordrspToGenerate">
                        <td>
                          {{ orderId }}
                        </td>
                        <td class="text-right">
                          <button
                            (click)="sendOrderResponseByOrderId(orderId)"
                            class="btn btn-sm btn-primary"
                            translate
                          >
                            GENERATE_MESSAGE
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="col-12 mt-3">
              <h5 class="text-center" translate>ORDER_RESPONSES</h5>
              <div class="row" *ngFor="let orderId of relations.erpOrderIds">
                <div class="col-12 card-body" *ngFor="let item of ordrspMap[orderId]; index as i">
                  <div class="row">
                    <div class="col-12 card-title">
                      <h5 class="d-inline">
                        <a [routerLink]="['/admin/edi/order-responses/', item.id]">
                          {{ item.id }}
                          <i class="fas fa-external-link-alt"></i>
                        </a>
                      </h5>
                      <div class="float-right">
                        <lib-action-btn
                          [(item)]="ordrspMap[orderId][i]"
                          [config]="orderResponseActionConfigService"
                        ></lib-action-btn>
                      </div>
                    </div>
                    <div class="col-12 col-md-4">
                      <lib-gung-text-input
                        renderStatic="true"
                        gungTranslate="STATUS"
                        [(ngModel)]="item.gungEdiMessageSendStatus"
                        class="label-bold"
                      >
                      </lib-gung-text-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <lib-gung-text-input
                        renderStatic="true"
                        gungTranslate="VALIDATION_STATE"
                        [(ngModel)]="item.validationState"
                        class="label-bold"
                      >
                      </lib-gung-text-input>
                    </div>
                    <div class="col-12 col-md-4">
                      <lib-gung-text-input
                        renderStatic="true"
                        gungTranslate="MESSAGE_DATE"
                        [(ngModel)]="item.documentReferences.despatchAdvice.date"
                        class="label-bold"
                      ></lib-gung-text-input>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'RELATED_EDI_DESADV' | translate" *ngIf="orderData.erpIds">
        <ng-template libGungTabContent>
          <div class="row pt-4" *ngIf="relations">
            <div class="col-12">
              <div class="info-head"><strong>THIS RESULTED IN ORDER IDS :</strong> 
                <a *ngFor="let erpId of orderData.erpIds" [routerLink]="['/orders', erpId]" class="ml-1">{{erpId}}</a>
              </div>
            </div>
            <div class="col-12">
              <p *ngIf="relations.erpDesadvIds.length == 0" translate>NO_DESPATCHES_FOUND</p>
              <div class="mt-3" *ngIf="despachtsToGenerate.length > 0">
                <h5 class="text-center" translate>DESPATCHES_IDS_READY_TO_GENERATE</h5>
                <div class="row">
                  <div class="col-12">
                    <table class="table table-striped">
                      <thead>
                        <tr>
                          <th translate>DESPATCH_ID</th>
                          <th class="text-right" translate>TOOLS</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let desadvId of despachtsToGenerate">
                          <td>
                            {{ desadvId }}
                          </td>
                          <td class="text-right">
                            <button (click)="sendDesadvByDesadvId(desadvId)" class="btn btn-sm btn-primary" translate>
                              GENERATE_MESSAGE
                            </button>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <h5 class="text-center" translate>DESPATCHES</h5>
                <div class="row" *ngFor="let desadvId of relations.erpDesadvIds">
                  <div class="col-12 card-body" *ngFor="let item of desadvMap[desadvId]; index as i">
                    <div class="row">
                      <div class="col-12 card-title">
                        <h5 class="d-inline">
                          <a [routerLink]="['/admin/edi/dispatch-advices/', item.id]">
                            {{ item.id }}
                            <i class="fas fa-external-link-alt"></i>
                          </a>
                        </h5>
                        <div class="float-right">
                          <lib-action-btn
                            [(item)]="desadvMap[desadvId][i]"
                            [config]="dispatchActionConfigService"
                          ></lib-action-btn>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="STATUS"
                          [(ngModel)]="item.gungEdiMessageSendStatus"
                          class="label-bold"
                        >
                        </lib-gung-text-input>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="VALIDATION_STATE"
                          [(ngModel)]="item.validationState"
                          class="label-bold"
                        ></lib-gung-text-input>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="MESSAGE_DATE"
                          [(ngModel)]="item.documentReferences.despatchAdvice.date"
                          class="label-bold"
                        ></lib-gung-text-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'RELATED_EDI_INVOIC' | translate" *ngIf="orderData.erpIds">
        <ng-template libGungTabContent>
          <div class="row pt-4" *ngIf="relations">
            <div class="col-12">
              <div class="info-head"><strong>THIS RESULTED IN ORDER IDS :</strong> 
                <a *ngFor="let erpId of orderData.erpIds" [routerLink]="['/orders', erpId]" class="ml-1">{{erpId}}</a>
              </div>
            </div>
            <div class="col-12">
              <p *ngIf="relations.erpInvoiceIds.length == 0" translate>NO_INVOICES_FOUND</p>
              <div class="row" *ngIf="relations.erpInvoiceIds.length > 0">
                <div class="col-12 mt-3" *ngIf="invoicesToGenerate.length > 0">
                  <h5 class="text-center" translate>INVOICES_IDS_READY_TO_GENERATE</h5>
                  <div class="row">
                    <div class="col-12">
                      <table class="table table-striped">
                        <thead>
                          <tr>
                            <th translate>INVOICE_ID</th>
                            <th class="text-right" translate>TOOLS</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let invoiceId of invoicesToGenerate">
                            <td>
                              {{ invoiceId }}
                            </td>
                            <td class="text-right">
                              <button
                                (click)="sendInvoiceByInvoiceId(invoiceId)"
                                class="btn btn-sm btn-primary"
                                translate
                              >
                                GENERATE_MESSAGE
                              </button>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 mt-4">
                <h5 class="text-center" translate>INVOICES</h5>
                <div class="row" *ngFor="let invoiceId of relations.erpInvoiceIds">
                  <div class="col-12 card-body" *ngFor="let item of invoiceMap[invoiceId]; index as i">
                    <div class="row">
                      <div class="col-12 card-title">
                        <h5 class="d-inline">
                          <a [routerLink]="['/admin/edi/invoices/', item.id]">
                            {{ item.id }}
                            <i class="fas fa-external-link-alt"></i>
                          </a>
                        </h5>
                        <div class="float-right">
                          <lib-action-btn
                            [(item)]="invoiceMap[invoiceId][i]"
                            [config]="invoicesActionConfigService"
                          ></lib-action-btn>
                        </div>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="STATUS"
                          [(ngModel)]="item.gungEdiMessageSendStatus"
                          class="label-bold"
                        >
                        </lib-gung-text-input>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="VALIDATION_STATE"
                          [(ngModel)]="item.validationState"
                          class="label-bold"
                        ></lib-gung-text-input>
                      </div>
                      <div class="col-12 col-md-4">
                        <lib-gung-text-input
                          renderStatic="true"
                          gungTranslate="MESSAGE_DATE"
                          [(ngModel)]="item.documentReferences.invoice.date"
                          class="label-bold"
                        ></lib-gung-text-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <!--
          <lib-gung-tab [title]="'EDI_RAW_DATA' | translate">
            <ng-template libGungTabContent>
              <div class="row pt-4">
                <div class="col-12">
                    <pre style="background: black; color: orange;">{{orderData|json}}</pre>
                </div>
              </div>
            </ng-template>
          </lib-gung-tab>
          -->
    </lib-gung-tabset>
  </div>
</div>

<!--
<div class="edi-order">
    <div class="detail-order clearfix">
        <div class="left-detail">
            <div class="order-n-label" translate>ORDER_DETAILS</div>
            <div class="order-id">{{orderData.id}}</div>
        </div>
        <div class="right-detail">
            <div class="right-title">SCHU UND SPORTHAUS</div>
            <div class="row bk-gray m-0">
                <div class="col-6" translate>STATUS</div>
                <div class="col-6" translate>{{orderData.gungEdiMessageSendStatus}}</div>
            </div>
            <div class="row">
                <div class="col-6" translate>MESSAGE_IN_OUT</div>
                <div class="col-6" translate>{{orderData.gungEdiMessageSendStatus}}</div>
            </div>
            <div class="row bk-gray m-0">
                <div class="col-6" translate>ORDER_HISTORY</div>
            </div>
            <div class="actions">
                <div class="btn-group">
                    <div ngbDropdown placement="bottom-right" class="d-inline-block mr-2">
                        <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle
                            translate>ORDER_ACTIONS</button>
                        <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
                            <button ngbDropdownItem translate (click)="sendToErp()">SEND_TO_ERP</button>
                            <button ngbDropdownItem translate (click)="sendOrderResponse()">SEND_ORDER_RESPONSE</button>
                            <button ngbDropdownItem translate (click)="sendInvoices()">SEND_INVOICES</button>
                        </div>
                    </div>
                </div>
                <div class="btn-group">
                    <button class="btn btn-outline-primary" translate>SAVE</button>
                </div>
            </div>
        </div>
    </div>
    <div class="order-header">
        <div class="header-title">Header</div>
        <div class="row">
            <div class="col-lg-7">
                <div class="row">
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>CUSTOMER_ORDER_NUMBER</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>ORDER_DATE</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.date" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>CURRENCY</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>SUPPLIER_ORDER_NUMBER</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>DELIVERY_DATE</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>ORDER_STATUS</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.gungEdiMessageSendStatus" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name">B24 <span translate>ORDER_NUMBER</span></label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>EARLIEST_DELIVERY_DATE</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>ORDER_TYPE</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.orderType" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>CONTRACT_ORDER_NO</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>LATEST_DELIVERY_DATE</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>DISCOUNT</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="form-group">
                            <label class="control-label" for="name" translate>ORDER_CHANGE_NUMBER</label>
                            <input class="form-control" name="name" type="text"
                                [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                        </div>
                    </div>

                </div>

            </div>
            <div class="col-lg-5">
                <div class="entity-tables">
                    <lib-gung-tabset justify="fill">
                        <lib-gung-tab [title]="'SUPPLIER' | translate">
                            <ng-template libGungTabContent>
                                <div class="gln">
                                    <div class="gln-label">GLN</div>
                                    <div class="gln-number">{{orderData.supplierGln}}</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>COMPANY</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>ADDRESS</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>POSTAL_CODE</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>STATE</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>COUNTRY</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>Tel.</div>
                                    <div class="table-data">?</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>EMAIL</div>
                                    <div class="table-data">?</div>
                                </div>
                            </ng-template>
                        </lib-gung-tab>
                        <lib-gung-tab [title]="'BUYER' | translate">
                            <ng-template libGungTabContent>
                                <div class="gln">
                                    <div class="gln-label">GLN</div>
                                    <div class="gln-number">{{orderData.deliveryGln}}</div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>COMPANY</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>ADDRESS</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>POSTAL_CODE</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>STATE</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>COUNTRY</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>Tel.</div>
                                    <div class="table-data"></div>
                                </div>
                                <div class="line-data">
                                    <div class="table-label" translate>EMAIL</div>
                                    <div class="table-data"></div>
                                </div>
                            </ng-template>
                        </lib-gung-tab>
                        <lib-gung-tab [title]="'CONSIGNEE' | translate">
                            <ng-template libGungTabContent>
                                ?
                            </ng-template>
                        </lib-gung-tab>
                    </lib-gung-tabset>
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label class="control-label" for="name" translate>CUSTOMER_REFERENCE</label>
                    <input class="form-control" name="name" type="text"
                        [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label class="control-label" for="name" translate>CUSTOMER_ORDER_NOTES</label>
                    <input class="form-control" name="name" type="text"
                        [(ngModel)]="orderData.documentReferences.buyerOrder.id" disabled />
                </div>
            </div>
        </div>
    </div>
    <div class="order-itens">
        <div class="col-12 table-responsive gung-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th translate>ITEM ID</th>
                        <th translate>QUANTITY</th>
                        <th translate>CONF. QTY</th>
                        <th translate>PRICE</th>
                        <th translate>CONF. PRICE</th>
                        <th translate>DELIVERY_DATE</th>
                        <th translate>STATUS</th>
                        <th translate>LAST_UPDATED</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of orderData.items">
                        <td [attr.data-label]="'ITEM ID' | translate">
                            {{item.ids.gungId}}
                        </td>
                        <td [attr.data-label]="'QUANTITY' | translate">
                            {{item.quantities.expectedQuantity}}
                        </td>
                        <td [attr.data-label]="'CONF. QTY' | translate">
                            ?
                        </td>
                        <td [attr.data-label]="'PRICE' | translate">
                            ?
                        </td>
                        <td [attr.data-label]="'CONF. PRICE' | translate">
                            ?
                        </td>
                        <td [attr.data-label]="'DELIVERY_DATE' | translate">
                            ?
                        </td>
                        <td [attr.data-label]="'STATUS' | translate">
                            ?
                        </td>
                        <td [attr.data-label]="'LAST_UPDATED' | translate">
                            ?
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

-->
