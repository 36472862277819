<div *ngIf="!isLoading; else loading" class="h-100 d-flex flex-column">
  <div class="modal-header">
    <!-- <div>
      <h3>
        {{ product.name }} <span class="small">{{ product.id }}</span>
      </h3>
    </div> -->
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row mb-3 h-100">
        <div class="table-responsive">
            <table class="table table-striped">
                <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col" translate>STATUS</th>
                      <th scope="col" translate>PO</th>
                      <th scope="col" translate>SUPPLIER</th>
                      <th scope="col" translate>BUYER</th>
                      <th scope="col" translate>ARTICLE</th>
                      <th scope="col" translate>PCS</th>
                      <th scope="col" translate>ETD</th>
                      <th scope="col" translate>ETA</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row" translate>ORIGINAL</th>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.poStatus}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.documentNo}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.radNr}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.restNr}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.no}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.quantity}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.requestDate}}</td>
                      <td>{{originalSupplierPurchaseOrder.extra.gungPurchaseLine.requestDate}}</td>
                    </tr>
                    <tr>
                      <th scope="row" translate>NEW</th>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.poStatus}}</td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.documentNo}}</td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.radNr}}</td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.restNr}}</td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.no}}</td>
                      <td>
                        <input type="number" #eleInputQty
                            placeholder="0"
                            max="{{originalQty - 1}}"
                            min="1"
                            [(ngModel)]="newInputQty"
                            (ngModelChange)="inputChanged($event)">
                      </td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.requestDate}}</td>
                      <td>{{newSupplierPurchaseOrder.extra.gungPurchaseLine.requestDate}}</td>
                    </tr>
                  </tbody>
            </table>
          </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn btn-outline-primary text-uppercase" (click)="activeModal.dismiss('Cancel click')" translate>
        CANCEL
      </button>
    <button type="button" class="btn btn-sm btn-primary text-uppercase" (click)="split()">
      {{ 'SPLIT' | translate }}
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
