import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { EdiDispatchCardListComponent } from '../../components/edi/edi-dispatch-card-list/edi-dispatch-card-list.component';
import {EdiDespatchAdvice, EdiInvoice} from '../../models/edi';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { EdiService } from '../edi/edi.service';
import {format} from "date-fns";
import { gungComparatorHelper } from 'gung-common';

export class InvoiceStatusFilter extends SimpleConfigBaseFilter<EdiDespatchAdvice> {
  constructor() {
    super();
  }
  getName(): string {
    return 'EDI_MESSAGE_SEND_STATUS';
  }
  getOptionIds(item: EdiDespatchAdvice): string[] {
    return [item.gungEdiMessageSendStatus || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ValidationFilter extends SimpleConfigBaseFilter<EdiDespatchAdvice> {
  constructor() {
    super();
  }
  getName(): string {
    return 'VALIDATION_STATE';
  }
  getOptionIds(item: EdiDespatchAdvice): string[] {
    return [item.validationState || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ConfigIdFilter extends SimpleConfigBaseFilter<EdiDespatchAdvice> {
  constructor() {
    super();
  }
  getName(): string {
    return 'CONFIG_ID';
  }
  getOptionIds(item: EdiDespatchAdvice): string[] {
    return [item.ediConfigId || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class EdiDispatchDateFilter extends SimpleConfigBaseFilter<EdiDespatchAdvice> {
  constructor() {
    super();
  }
  getName(): string {
    return 'DISPATCH_ADVICE_DATE';
  }
  getOptionIds(item: EdiDespatchAdvice): string[] {
    let date = item.deliveryDate?new Date(item.deliveryDate):new Date()
    return [format(date, 'yyyy-MM-dd')];
  }
  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EdiDispatchAdvicesListConfigService implements ConfigService<EdiDespatchAdvice> {
  constructor(protected ediService: EdiService, protected baseViewConfigService: BaseViewConfigService) {}

  getItems(): Observable<EdiDespatchAdvice[]> {
    return this.ediService.getDispatchAdvices().pipe(
      /*map(data =>
        data.sort((a, b) => {
          const aSort = a?.documentReferences?.despatchAdvice?.date && new Date(a.documentReferences.despatchAdvice.date);
          const bSort = b?.documentReferences?.despatchAdvice?.date && new Date(b.documentReferences.despatchAdvice.date);
          if ((!bSort && aSort) || (aSort && bSort && aSort > bSort)) {
            return -1;
          } else if ((!aSort && bSort) || (aSort && bSort && aSort < bSort)) {
            return 1;
          } else {
            return 0;
          }
        })
      )*/
      map(items => items.sort((a, b) => gungComparatorHelper([a.timestamp, a.id], [b.timestamp, b.id], -1)))
    );
  }

  getFilters(): ConfigBaseFilter<EdiDespatchAdvice>[] {
    return [new ValidationFilter(), new InvoiceStatusFilter(), new ConfigIdFilter(), new EdiDispatchDateFilter()];
  }

  getSortOptions(): ListSortOption<EdiDespatchAdvice>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<EdiDespatchAdvice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getEdiDispatchCardListComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Edi dispatch advices grid'
      }
    ];
  }

  getSearchTerms(item: EdiDespatchAdvice): string[] {
    const generalSearchTerms = [
      item.documentReferences?.supplierOrder?.id,
      item.documentReferences?.buyerOrder?.id,
      item.documentReferences?.invoice?.id,
      item.documentReferences?.invoiceOcr?.id,
      item.documentReferences?.despatchAdvice?.id,
      item.documentReferences?.parcel?.id
    ].filter(s => !!s);
    return [item.id, item.consigneeGln?.toString(), item.buyerGln?.toString()].concat(generalSearchTerms);
  }

  getItemId(item: EdiDespatchAdvice): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
