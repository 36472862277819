<div class="row">
  <div class="col-12">
    <form
      *ngIf="!alertMessage || alertMessage.type !== 'success'"
      class="form mt-2 mb-3"
      [formGroup]="accountForm"
      (ngSubmit)="onSubmit()"
    >
      <lib-gung-text-input
        gungTranslate="COMPANY_NAME"
        formControlName="companyName"
        placeholder="{{ 'ENTER_COMPANY_NAME' | translate }}"
        ngbAutofocus
      ></lib-gung-text-input>
      <lib-gung-text-input
        gungTranslate="CUSTOMER_ID"
        formControlName="companyId"
        placeholder="{{ 'NOT_REQUIRED' | translate }}"
      ></lib-gung-text-input>
      <div class="form-group row">
        <div class="col">
          <label for="companyAddressId" class="col-form-label" translate>ADDRESS</label>
          <textarea
            class="form-control"
            type="text"
            name="companyAddress"
            id="companyAddressId"
            formControlName="companyAddress"
            placeholder="{{ 'ENTER_ADDRESS' | translate }}"
          ></textarea>
        </div>
      </div>
      <lib-gung-text-input
        gungTranslate="VAT_NO"
        formControlName="companyOrganisationId"
        placeholder="{{ 'ENTER_VAT' | translate }}"
      ></lib-gung-text-input>
      <lib-gung-text-input
        gungTranslate="COUNTRY"
        formControlName="companyCountry"
        placeholder="{{ 'ENTER_COUNTRY' | translate }}"
      ></lib-gung-text-input>
      <lib-gung-text-input
        gungTranslate="CONTACT"
        formControlName="companyContactName"
        placeholder="{{ 'ENTER_CONTACT' | translate }}"
      ></lib-gung-text-input>
      <lib-gung-text-input
        gungTranslate="EMAIL"
        formControlName="companyContactEmail"
        placeholder="{{ 'ENTER_EMAIL' | translate }}"
      ></lib-gung-text-input>
      <div
        *ngIf="
          getFormControl('companyContactEmail').invalid &&
          (getFormControl('companyContactEmail').dirty || getFormControl('companyContactEmail').touched) &&
          getFormControl('companyContactEmail').errors.email
        "
        class="alert alert-danger"
      >
        <span translate>NOT_VALID_EMAIL</span>!
      </div>
      <lib-gung-text-input
        gungTranslate="PHONE"
        formControlName="companyContactPhone"
        placeholder="{{ 'ENTER_PHONE' | translate }}"
      ></lib-gung-text-input>
      <div *ngIf="alertMessage" class="alert alert-danger mt-2 text-center" role="alert">
        <span>{{ alertMessage.message | translate }}</span>
      </div>
      <button class="btn btn-primary mt-2 w-100" type="submit" [disabled]="loader ? true : null">
        <span>{{ getButtonText(accountForm.valid || loader) }}</span>
        <i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
      </button>
    </form>
    <div
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="alert alert-success mt-2 text-center"
      role="alert"
    >
      <span>{{ alertMessage.message | translate }}</span>
    </div>
    <button
      *ngIf="alertMessage && alertMessage.type === 'success'"
      class="btn btn-primary mt-2 w-100"
      type="button"
      (click)="alertMessage = null"
    >
      <span translate>CREATE_NEW</span>
    </button>
    <a class="login-instead-text" (click)="$event.stopPropagation(); returnToLogin.emit()" translate>LOGIN_INSTEAD</a>
  </div>
</div>
