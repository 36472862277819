import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService, OptionsList, S3Item, S3UploadService } from 'gung-common';
import { forkJoin, Observable, of, OperatorFunction, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, first, map, switchMap, takeUntil } from 'rxjs/operators';
import { Customer } from '../../../models';
import { CustomerContact } from '../../../models/customerContact';
import {
  ActivityInternal,
  ActivityInternalService
} from '../../../services/activity-internal/activity-internal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerContactService } from '../../../services/customer-contacts/customer-contact.service';
import { CustomerService } from '../../../services/customers/customer.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { User } from '../../../state/auth/types';
import { ActivityInternalCustomerResponsibleService } from '../../../services/activity-internal-customer-responsible.service';

@Component({
  selector: 'lib-activity-internal-create-modal',
  templateUrl: './activity-internal-create-modal.component.html',
  styleUrls: ['./activity-internal-create-modal.component.css']
})
export class ActivityInternalCreateModalComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  activitySelectedCustomerId: Subject<string> = new Subject();

  @Input()
  activity: ActivityInternal;

  @Input()
  customerId: string;

  @Input()
  selectedCustomers: Customer[] = [];

  @Input()
  selectedDate: Date;

  submitForm: FormGroup;
  filesToSubmit: File[] = [];

  currentCustomer: Customer;
  allCustomers: Customer[] = [];
  user: User;

  public multipleFiles = true;
  public disabled = false;

  minDate = new Date();
  public minDatePickerDate: NgbDateStruct = this.dateUtilService.createNgbDateFromDate(this.minDate);
  initStatus = '';
  errorEnableMessage = false;
  errorMessage: string;

  loaded: boolean;

  public modelSearchCustomer: any;
  public contactSearch: any;

  customerContacts: CustomerContact[];

  resultFormatter = (item: CustomerContact) => item.name + (item.email ? (' - ' + item.email): '');

  // Customers
  formatterCustomer = (customer: Customer) => (customer ? customer.id + ' ' + customer.name : '');

  searchCustomer = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term =>
        this.allCustomers
          .filter(customer => new RegExp(term, 'mi').test(customer.id) || new RegExp(term, 'mi').test(customer.name))
          .slice(0, 10)
      )
    );

  customersResponsible: OptionsList[] = [];

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected activityInternalService: ActivityInternalService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerContactService: CustomerContactService,
    protected customerService: CustomerService,
    public dateUtilService: DateUtilService,
    protected s3UploadService: S3UploadService,
    protected activityInternalCustomerResponsibleService: ActivityInternalCustomerResponsibleService
  ) {}

  ngOnInit() {
    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.customerId
        ? this.customerService.getCustomer(this.customerId).pipe(first())
        : this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.customerService.getCustomers().pipe(first()),
      this.activityInternalCustomerResponsibleService.getCustomersResponsible().pipe(first()),
      this.activityInternalCustomerResponsibleService.getDefaultCustomerResponsible(this.selectedCustomers?.length > 0)
    ]).subscribe(([user, customer, customers, customersResponsible, responsible]) => {
      this.user = user;
      this.currentCustomer = customer;
      this.modelSearchCustomer = customer;
      this.allCustomers = customers;
      this.customersResponsible = customersResponsible;

      const status = this.metadataService.getMetadataTableList('ACTIVITYINTERNALSTATUS');
      if (!!status) {
        this.initStatus = status.slice().sort((a, b) => (a.id < b.id ? -1 : 1))[0].id;
      } else {
        this.initStatus = undefined;
      }

      this.initForm(user, customer, responsible);

      this.activitySelectedCustomerId
        .pipe(
          takeUntil(this.unsubscribe),
          switchMap(id => this.customerContactService.getCustomerContacts(id))
        )
        .subscribe(contacts => {
          this.customerContacts = contacts;
          this.loaded = true;
        });
      this.activitySelectedCustomerId.next(this.activity?.customerId || customer.id);
    });
  }

  initForm(user: User, customer: Customer, responsible: string) {
    if (!this.selectedDate) {
      this.selectedDate = new Date();
    }

    this.submitForm = this.formBuilder.group({
      customer: this.formBuilder.control(this.activity?.customerName || customer.name, {
        validators: [Validators.required]
      }),
      customerResponsible: this.formBuilder.control(
        this.activity?.customerResponsible || responsible
      ),
      comment: this.formBuilder.control({
        value: (this.activity?.comments && this.activity?.comments[0].comment) || '',
        disabled: this.activity ? true : false
      }),
      activityDate: this.formBuilder.control(
        this.activity?.activityDate
          ? new Date(this.activity.activityDate).toISOString()
          : this.selectedDate.toISOString(),
        { validators: [Validators.required] }
      ),
      activityEndDate: this.formBuilder.control(
        this.activity?.activityEndDate
          ? new Date(this.activity.activityEndDate).toISOString()
          : new Date().toISOString(),
        { validators: [Validators.required] }
      ),
      contacts: this.formBuilder.control(this.activity?.contacts || ''),
      type: this.formBuilder.control(this.activity?.type || '', { validators: [Validators.required] }),
      status: this.formBuilder.control(this.activity?.status || this.initStatus, { validators: [Validators.required] })
    });

    this.submitForm.get('customerResponsible').valueChanges.pipe(
      takeUntil(this.unsubscribe)
    ).subscribe(data => {
      console.log('data', data);
    })
  }

  get contacts() {
    return this.submitForm.get('contacts') as FormArray;
  }

  public onFileSelect(files: FileList) {
    this.filesToSubmit = [];
    for (let file = 0; file < files.length; file++) {
      this.filesToSubmit.push(files.item(file));
    }
  }

  updateDate(date: Date) {
    this.submitForm.get('activityDate').setValue(date);
  }

  onSave() {
    if (this.submitForm.invalid) {
      this.submitForm.markAllAsTouched();
      // FILL_ALL_REQUIRED_FIELDS
      return;
    }
    this.disabled = true;
    const formRaw = this.submitForm.getRawValue();

    const ts = new Date();
    const activityResult = {
      ...formRaw,
      customerId: this.activity?.customerId || this.currentCustomer.id,
      date: this.activity?.date || ts,
      activityDate: new Date(formRaw.activityDate),
      activityEndDate: new Date(formRaw.activityEndDate),
      comments: this.activity?.comments || [
        {
          user: this.user.username,
          date: ts,
          attachments: [],
          comment: formRaw.comment
        }
      ]
    };

    // Update
    if (this.activity?.id) {
      activityResult.id = this.activity.id;
    }

    let observables: Observable<S3Item>[] = [];

    if (this.filesToSubmit.length > 0) {
      this.filesToSubmit.forEach(doc => {
        observables.push(this.s3UploadService.postDocument(doc));
      });
    } else {
      observables.push(of({} as S3Item));
    }

    forkJoin(observables)
      .pipe(
        first(),
        switchMap(documents => {
          for (const element of documents) {
            activityResult.comments[0].attachments.push(element.s3Uri);
          }

          return this.activityInternalService.postActivityInternal(activityResult);
        })
      )
      .subscribe({
        next: activity => {
          this.disabled = false;
          this.activeModal.close(JSON.stringify(activity));
        },
        error: (error: any) => {
          this.disabled = false;
        }
      });
  }

  onSaveMultiple() {
    if (this.submitForm.invalid) {
      this.submitForm.markAllAsTouched();
      // FILL_ALL_REQUIRED_FIELDS
      return;
    }
    this.disabled = true;
    const formRaw = this.submitForm.getRawValue();

    const ts = new Date();
    const activityResult = {
      customersIds: this.selectedCustomers.map(customer => customer.id),
      activity: {
        ...formRaw,
        customer: "",
        date: this.activity?.date || ts,
        activityDate: new Date(formRaw.activityDate),
        activityEndDate: new Date(formRaw.activityEndDate),
        comments: this.activity?.comments || [
          {
            user: this.user.username,
            date: ts,
            attachments: [],
            comment: formRaw.comment
          }
        ]
      }
    };

    let observables: Observable<S3Item>[] = [];

    if (this.filesToSubmit.length > 0) {
      this.filesToSubmit.forEach(doc => {
        observables.push(this.s3UploadService.postDocument(doc));
      });
    } else {
      observables.push(of({} as S3Item));
    }

    forkJoin(observables)
      .pipe(
        first(),
        switchMap(documents => {
          for (const element of documents) {
            activityResult.activity.comments[0].attachments.push(element.s3Uri);
          }

          return this.activityInternalService.postMultipleActivityInternal(activityResult);
        })
      )
      .subscribe({
        next: activity => {
          this.disabled = false;
          this.activeModal.close(JSON.stringify(activity));
        },
        error: (error: any) => {
          this.disabled = false;
          if(error.status === 404) {
            this.errorEnableMessage = true;
          } else {
            this.errorMessage = error.message;
          }
        }
      });
  }

  resetContact() {
    this.contactSearch = undefined;
    this.submitForm.get('contacts').reset('');
    this.submitForm.get('contacts').markAsPristine();
    this.submitForm.get('contacts').markAsUntouched();
  }

  trackByFn(index: number, item: any) {
    return index;
  }

  search: OperatorFunction<string, readonly CustomerContact[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        this.customerContacts
          .filter(contact => new RegExp(term, 'mi').test(contact.name) || new RegExp(term, 'mi').test(contact.email))
          .slice(0, 10)
      )
    );
  // Contacts
  selectItem({ item: itemObject, preventDefault }, target) {
    const item: CustomerContact = itemObject;
    this.submitForm.get('contacts').setValue(item.id);
  }

  focusout({ target }, modelSearch: string) {
    if (!modelSearch) {
      target.value = '';
      this.resetContact();
    }
  }

  openContacts(e: Event): void {
    e.stopPropagation();
    setTimeout(() => {
      const inputEvent: Event = new Event('input');
      e.target.dispatchEvent(inputEvent);
    }, 0);
  }

  // Customer
  changeCustomer(value: string) {
    if (value.localeCompare('') === 0 && this.submitForm.get('customer').value) {
      this.submitForm.get('customer').setValue('');
    }
  }

  selectItemCustomer({ item: itemObject, preventDefault }, target) {
    const item: Customer = itemObject;
    this.currentCustomer = item;
    this.submitForm.get('customer').setValue(item.name);
    this.activitySelectedCustomerId.next(item.id);
    this.resetContact();
  }

  focusoutCustomer({ target }, modelSearch: string) {
    if (!modelSearch) {
      target.value = '';
    }
  }

  removeCustomer(customerId: string): void {
    const index = this.selectedCustomers.findIndex(customer => customer.id === customerId);
    if (index > -1) {
      this.selectedCustomers.splice(index, 1);
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
