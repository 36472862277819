import { Component, OnInit } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format } from 'date-fns';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-select-interval-orders-dates-modal',
  templateUrl: './select-interval-orders-dates-modal.component.html',
  styleUrls: ['./select-interval-orders-dates-modal.component.css']
})
export class SelectIntervalOrdersDatesModalComponent implements OnInit {
  delegate: GungModalService;
  componentId: string;

  todayDate = new Date();
  maxDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;

  ngOnInit() {
    this.componentId = SelectIntervalOrdersDatesModalComponent.name;

    //Set predefault dates
    const date = new Date();
    const earlierDate = new Date(date.setMonth(date.getMonth() - 3));
    this.maxDate = new NgbDate(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, this.todayDate.getDate());
    this.fromDate = new NgbDate(earlierDate.getFullYear(), earlierDate.getMonth() + 1, earlierDate.getDate());
    this.toDate = new NgbDate(this.todayDate.getFullYear(), this.todayDate.getMonth() + 1, this.todayDate.getDate());
  }

  confirm() {
    this.delegate.modalRef
      .get(this.componentId)
      .close({
        fromDate: format(new Date(this.fromDate.year, this.fromDate.month - 1, this.fromDate.day), 'yyyy-MM-dd'),
        toDate: format(new Date(this.toDate.year, this.toDate.month - 1, this.toDate.day), 'yyyy-MM-dd')
      });
  }
}
