import { HttpClient } from '@angular/common/http';
import { select } from '@ngrx/store';
import { Component, OnInit, Input } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';

export interface UserGroup {
  key: string;
  selected: boolean;
}

@Component({
  selector: 'lib-user-details-groups',
  templateUrl: './user-details-groups.component.html',
  styleUrls: ['./user-details-groups.component.css']
})
export class UserDetailsGroupsComponent implements OnInit {
  public activeGroupsKey = 'activeGroups';

  @Input()
  currentUser: User;
  userGroups: UserGroup[] = [];

  constructor(private http: HttpClient) {}

  ngOnInit() {
    this.http.get<any>('json/system/GUNG').subscribe(systemSettings => {
      const settingsGroups = systemSettings.extra.userGroups || [];
      const tmp: UserGroup[] = [];
      for (const key of Object.keys(this.currentUser[this.activeGroupsKey])) {
        tmp.push({
          key,
          selected: this.currentUser[this.activeGroupsKey][key]
        });
      }

      (settingsGroups as string[]).forEach(settingsGroup => {
        const userGroup = tmp.find(group => group.key === settingsGroup);
        if (!userGroup) {
          tmp.push({
            key: settingsGroup,
            selected: false
          });
        }
      });
      this.userGroups = tmp;
    });
  }

  toogleGroup(key: string, selected: boolean): void {
    this.userGroups.find(group => group.key === key).selected = !selected;
    this.currentUser[this.activeGroupsKey][key] = !selected;
  }

  getUserGroups(): UserGroup[] {
    return Object.keys(this.currentUser[this.activeGroupsKey])
      .filter(g => this.currentUser[this.activeGroupsKey][g])
      .map(group => ({ key: group, selected: this.currentUser[this.activeGroupsKey][group] }));
  }
}
