import { Component, forwardRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { Product } from '../../models';
import { BackendInterceptor } from '../../services/backend-interceptor/backend-interceptor.service';
import { ProductListConfigService } from '../../services/product-list-config/product-list-config.service';
import { ProductSelectionService } from '../../services/product-selection-service/product-selection.service';

@Component({
  selector: 'lib-product-list',
  template:
    '<lib-filter-list #productList [configService]="productListConfigService" [keepFilterOpen]="keepFilterOpen"></lib-filter-list>',
  styleUrls: ['./product-list.component.scss'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => ProductSelectionService)
    }
  ]
})
export class ProductListComponent implements OnInit {
  reloaded: boolean;
  keepFilterOpen =
    this.backendInterceptor.getEnvironmentProperty('keepFilterOpen') || this.productListConfigService.keepFilterOpen;

  constructor(
    public productListConfigService: ProductListConfigService,
    public route: ActivatedRoute,
    protected backendInterceptor: BackendInterceptor
  ) {}
  @ViewChild('productList') productList: FilterListComponent<Product>;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const assortmentId = params.get('assortmentId');

      if (assortmentId != null) {
        this.productListConfigService.assortmentSubject.next(assortmentId);
      } else {
        this.productListConfigService.assortmentSubject.next(null);
      }
    });
  }
}
