import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ProductsBookmark } from '../../models/products-bookmark';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ProductsBookmarkService {
  constructor(protected http: HttpClient) {}

  private productsBookmarkSubject: BehaviorSubject<ProductsBookmark[]>;
  baseUrl = 'json/products-bookmarks';

  public getOne(id: string): Observable<ProductsBookmark> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<ProductsBookmark>(url);
  }

  public getAll(nocache?: boolean): Observable<ProductsBookmark[]> {
    if (!this.productsBookmarkSubject) {
      this.productsBookmarkSubject = new BehaviorSubject<ProductsBookmark[]>([]);
      this.updateSubject(nocache);
    }
    return this.productsBookmarkSubject.asObservable();
  }

  public create(bookmark: ProductsBookmark): Observable<ProductsBookmark> {
    return this.http.post<ProductsBookmark>(this.baseUrl, bookmark).pipe(tap(_ => this.updateSubject(true)));
  }

  public deleteById(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url).pipe(tap(_ => this.updateSubject(true)));
  }

  updateSubject(nocache?: boolean): void {
    this.internalGetAll(nocache).subscribe(pb => this.productsBookmarkSubject.next(pb));
  }

  protected internalGetAll(nocache?: boolean): Observable<ProductsBookmark[]> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<ProductsBookmark[]>(this.baseUrl, { headers });
  }
}
