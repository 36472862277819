import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PimMetadata, PimMetadataService } from '../../../services/pim-metadata/pim-metadata.service';

@Component({
  selector: 'lib-pim-metadata-modal',
  templateUrl: './pim-metadata-modal.component.html',
  styleUrls: ['./pim-metadata-modal.component.css']
})
export class PimMetadataModalComponent implements OnInit {
  isLoading = true;

  @Input()
  metadata: PimMetadata;

  @Input()
  edit: boolean;

  form: FormGroup;
  isSubmitted: boolean;
  segments: string[];

  translateLangs: string[];
  translateField: string;

  constructor(
    protected pimMetadataService: PimMetadataService,
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit(): void {
    this.translateLangs = this.pimMetadataService.translateLanguages;
    this.translateField = this.pimMetadataService.translateField;
    this.pimMetadataService.getSystem('GUNG').subscribe(system => {
      this.segments = system.extra.metadataSegments;
    });
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      extra: this.formBuilder.group({}),
      i18n: this.getFormI18n(),
      id: this.formBuilder.control({ value: this.metadata?.id || '', disabled: this.edit }, [Validators.required]),
      name: this.formBuilder.control(this.metadata?.name || '', [Validators.required]),
      segment: this.formBuilder.control(this.metadata?.segment || '', [Validators.required])
    });
    this.isLoading = false;
  }

  getFormI18n(): FormGroup {
    const langs = {};
    for (const lang of this.translateLangs) {
      langs[lang] = this.formBuilder.group({
        [this.translateField]: this.formBuilder.control(this.metadata?.i18n?.[lang]?.[this.translateField] || '')
      });
    }
    return this.formBuilder.group(langs);
  }

  onSave() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formRaw = this.form.getRawValue();
    this.form.disable();
    this.isSubmitted = true;

    const metadata: PimMetadata = {
      ...formRaw,
      active: this?.metadata?.active || false,
      metadataId: this?.metadata?.metadataId,
      order: this?.metadata?.order || null,
      extra: this?.metadata?.extra || {}
    };

    if (this.edit) {
      this.pimMetadataService.putPimMetadata(metadata).subscribe(_ => {});
    } else {
      delete metadata.metadataId;
      this.pimMetadataService.postPimMetadata(metadata).subscribe(_ => {});
    }
    this.activeModal.close(JSON.stringify(metadata));
  }
}
