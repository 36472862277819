import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs';
import { GungBase } from '../../models';
import { Customer } from '../../models/customer';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public enableCreateCustomer = false;

  private customerList: BehaviorSubject<Customer[]>;

  constructor(protected http: HttpClient) {}

  getCustomers(noCache?: boolean): Observable<Customer[]> {
    const url = 'json/customers';

    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }
    return this.http.get<Customer[]>(url, { headers });
  }

  updateCustomerListSubject(): void {
    this.getCustomers(true).subscribe(customers => this.customerList.next(customers));
  }

  getAllCustomersSubject(): Observable<Customer[]> {
    if (!this.customerList) {
      this.customerList = new BehaviorSubject<Customer[]>([]);
      this.updateCustomerListSubject();
    }
    return this.customerList.asObservable();
  }

  getCustomerAllowed(customerId): Observable<boolean> {
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<boolean>(`json/customers/${customerId}/allowed`, { headers });
  }

  getDefaultCustomer(noCache?: boolean): Observable<Customer> {
    return this.getCustomer('GUNG_DEFAULT', noCache);
  }

  getCustomer(customerId: string, noCache?: boolean): Observable<Customer> {
    const url = 'json/customers/' + customerId.replace('/', '_GUNGSLASH_');
    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    return this.http.get<Customer>(url, { headers });
  }

  createNewCustomer(customer: Customer) {
    const url = 'json/customers/create';
    return this.http.post<Customer>(url, customer).pipe(tap(() => {
      if (!this.customerList) {
        this.customerList = new BehaviorSubject<Customer[]>([]);
      }
      this.updateCustomerListSubject();
    }));
  }

  updateCustomer(customer: Customer) {
    const url = 'json/customers/' + customer.id.replace('/', '_GUNGSLASH_');
    return this.http.put<Customer>(url, customer);
  }

  getCustomerCount(noCache?: boolean): Observable<number> {
    const url = 'json/customers/user/customer-count';
    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    return this.http.get<number>(url, { headers });
  }

  updateCustomerDeliveryLocations(customerId: string, deliveryLocation: GungBase): Observable<GungBase[]> {
    const url = `json/customer/${customerId}/delivery-locations`;
    return this.http.put<GungBase[]>(url, deliveryLocation);
  }

  createNewCustomerDeliveryLocations(customerId: string, deliveryLocation: GungBase) {
    const url = 'json/customer/${customerId}/delivery-locations';
    return this.http.post<Customer[]>(url, deliveryLocation);
  }

  checkVat(vat: string): Observable<any> {
    const url = 'json/vat/is-valid/' + vat;
		return this.http.get<any>(url);
  }
}