import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { GungProductCrossReference } from '../../models/gung-product-cross-reference';
import { AuthService } from '../../services/auth/auth.service';
import { PdfToCartSavedMappingsService } from '../../services/pdf-to-cart-saved-mappings.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { Product } from '../../models';

@Component({
  selector: 'lib-pdf-to-cart-saved-mappings',
  templateUrl: './pdf-to-cart-saved-mappings.component.html',
  styleUrls: ['./pdf-to-cart-saved-mappings.component.css']
})
export class PdfToCartSavedMappingsComponent implements OnInit {
  currentCustomerId: string;
  selectedCustomerMappings: GungProductCrossReference[] = [];
  allMappings: GungProductCrossReference[] = [];
  isAdmin: boolean;
  showAllCustomersMappings = false;

  constructor(
    protected pdfToCartSavedMappingsService: PdfToCartSavedMappingsService,
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isAdmin = !!roles.find(r => ['ADMIN', 'ACTUATOR'].includes(r));
        if (this.isAdmin) {
          this.getAllMappings();
        }
      });
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.currentCustomerId = customer.id;

        this.getMappings(this.currentCustomerId);
      });
  }

  private getMappings(customerId: string) {
    this.pdfToCartSavedMappingsService
      .getMappingsForCustomer(customerId)
      .pipe(first())
      .subscribe(mappings => (this.selectedCustomerMappings = mappings));
  }

  private getAllMappings() {
    this.pdfToCartSavedMappingsService
      .getAllMappings()
      .pipe(first())
      .subscribe(mappings => (this.allMappings = mappings));
  }

  saveMappingChanges(mapping: GungProductCrossReference) {
    // TODO - error handling
    const newMapping = mapping.extra.new;
    if (mapping.extra.new) {
      delete mapping.extra.new;
    }
    this.pdfToCartSavedMappingsService
      .saveCrossReference(mapping)
      .pipe(first())
      .subscribe(savedMapping => {
        alert('Saved');
        if (newMapping && this.currentCustomerId === savedMapping.referenceTypeId) {
          const indexAllMappings = this.allMappings.indexOf(mapping);
          const indexSelectedCustomerMappings = this.selectedCustomerMappings.indexOf(mapping);
          if (this.isAdmin && indexAllMappings > -1) {
            this.allMappings.splice(indexAllMappings, 1, savedMapping);
          }
          if (indexSelectedCustomerMappings > -1) {
            this.selectedCustomerMappings.splice(indexSelectedCustomerMappings, 1, savedMapping);
          }
        }
      });
  }

  addNewMapping() {
    const newMapping: GungProductCrossReference = {
      id: null,
      name: 'FRONTEND_CREATED_MAPPING',
      extra: { new: true },
      productId: '',
      referenceId: '',
      referenceType: this.pdfToCartSavedMappingsService.getReferenceType(),
      referenceTypeId: this.currentCustomerId
    };
    if (this.showAllCustomersMappings) {
      this.allMappings.push(newMapping);
    } else {
      this.selectedCustomerMappings.push(newMapping);
    }
  }

  deleteMapping(mapping: GungProductCrossReference) {
    if (
      confirm(`Delete mapping ${mapping.referenceId} - ${mapping.productId} for customer ${mapping.referenceTypeId}?`)
    ) {
      this.pdfToCartSavedMappingsService.deleteMapping(mapping.id).subscribe(deletedMapping => {
        const indexAllMappings = this.allMappings.findIndex(m => m.id === deletedMapping.id);
        const indexCustomerMappings = this.selectedCustomerMappings.findIndex(m => m.id === deletedMapping.id);
        if (indexAllMappings >= 0) {
          this.allMappings.splice(indexAllMappings, 1);
        }
        if (indexCustomerMappings >= 0) {
          this.selectedCustomerMappings.splice(indexAllMappings, 1);
        }
      });
    }
  }

  selectProduct(product: Product, mapping: GungProductCrossReference) {
    mapping.productId = product.id;
  }
}
