import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface DownloadingStatus {
  downloading: boolean;
  data: any;
}

@Injectable({
  providedIn: 'root'
})
export class PdfDownloadIndicatorService {
  private downloadingSubject: BehaviorSubject<DownloadingStatus> = new BehaviorSubject<DownloadingStatus>({
    downloading: false,
    data: null
  });

  constructor() {}

  isDownloading(): Observable<DownloadingStatus> {
    return this.downloadingSubject;
  }

  setDownloading(downloading: DownloadingStatus): void {
    this.downloadingSubject.next(downloading);
  }
}
