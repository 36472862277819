<div class="row">
  <div class="col text-right">
    <button type="button" class="btn btn-outline-primary" (click)="openModalUploadDocuments()" translate>
      UPLOAD_MULTIPLE_DOCUMENTS
    </button>
  </div>
</div>
<ng-container *ngFor="let document of pimProduct?.documents; let idx = index">
  <div class="row my-2">
    <div class="col-12 col-md-9 d-flex align-items-center">
      <h3>{{ document.description || ('NO_DESCRIPTION' | translate) }}</h3>
    </div>
    <div class="col-12 col-md-3 d-flex justify-content-end">
      <div class="btn-group" data-toggle="buttons">
        <a
          class="btn btn-primary"
          translate
          [href]="document | gungDownloadUrl"
          target="_blank"
          >DOWNLOAD</a
        >
        <button
          type="button"
          class="btn btn-outline-secondary"
          [disabled]="idx === 0"
          (click)="moveElement(idx, pimProduct.documents, 'up')"
        >
          <i class="fas fa-arrow-up"></i>
        </button>
        <button
          type="button"
          class="btn btn-outline-secondary"
          [disabled]="idx === pimProduct.documents.length - 1"
          (click)="moveElement(idx, pimProduct.documents, 'down')"
        >
          <i class="fas fa-arrow-down"></i>
        </button>
        <button
          type="button"
          class="btn btn-danger"
          (click)="moveElement(idx, pimProduct.documents, 'remove')"
          translate
        >
          DELETE
        </button>
      </div>
    </div>
  </div>
  <div class="row pb-3 border-bottom">
    <div class="col-12">
      <lib-gung-text-input-horizontal gungTranslate="DOCUMENT_DESCRIPTION" [(ngModel)]="document.description">
      </lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal gungTranslate="DOCUMENT_CATEGORY" [(ngModel)]="document.category">
      </lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal gungTranslate="TAG" [(ngModel)]="document.extra.tag">
      </lib-gung-text-input-horizontal>
      <div class="row" *ngIf="pimProduct.dimensions?.colors">
        <div class="col-12">
          <button
            *ngFor="let pimDimension of pimProduct.dimensions.colors"
            type="button"
            class="btn btn-outline-secondary mx-1"
            [ngClass]="{ active: document.pimDimensionIds.includes(pimDimension.id) }"
            (click)="toggleArray(pimDimension.id, document.pimDimensionIds)"
          >
            {{ pimDimension.dimensionId + ' - ' + pimDimension.name }}
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-container>
