<div class="modal-content" *ngIf="concept; else loading">
  <div class="modal-header">
    <h4 translate>
      {{ concept?.name }} <small>- {{ concept?.id }}</small>
    </h4>
  </div>
  <div class="modal-body">
    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>ID</th>
            <th>Name</th>
            <th>AVAILABILITY</th>
            <th><span translate>PRICE</span></th>
            <th></th>
            <th style="width: 200px"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of mappedData">
            <td>
              <div class="image-wrapper" [routerLink]="['/product/', data.product?.id]">
                <img class="img-fluid" [src]="data.product.extra.images[0].s3Uri | gungImageUrl
                    :'type:thumbnail'
                    :'etag:'+data.product.extra.images[0].s3ETag
                  "
                  alt="{{ data.product.extra.images[0].description }}" />
              </div>
            </td>
            <td>
              <span class="pointer" [routerLink]="['/product/', data.product?.id]">{{ data.product.id }}</span>
            </td>
            <td>
              <span class="pointer" [routerLink]="['/product/', data.product?.id]">{{ data.product.name }}</span>
            </td>
            <td>
              <lib-availability [availability]="data.availability" [isSales]="isSales"></lib-availability>
            </td>
            <td>
              <div class="col-auto mr-auto">
                <span *ngIf="data.price" translate>
                  {{
                  data.price.customerGrossPrice.value
                  | gungCurrency: data.price.customerGrossPrice.currencyCode:'code'
                  }}
                </span>
              </div>
              <div class="col-auto text-muted">
                <span *ngIf="data.price && data.price.recommendedRetailPrice">
                  {{ 'RRP' | translate }}
                  {{
                  data.price.recommendedRetailPrice.value
                  | gungCurrency: data.price.recommendedRetailPrice.currencyCode:'code'
                  }}
                </span>
              </div>
            </td>
            <td>
              <lib-favourite-product-icon [id]="data.product.id"></lib-favourite-product-icon>
            </td>
            <td style="min-width: 150px">
              <lib-buy-btn [id]="data.product.id" [product]="data.product" [minimumOrderQuantity]="data.packageSize"
                [stepAmount]="data.packageSize" [availability]="data.availability"></lib-buy-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')" translate>
      CLOSE
    </button>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>