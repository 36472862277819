<div class="row" [ngClass]="{ 'mt-4 mb-4': mappedData && mappedData.length > 0 }">
  <div *ngFor="let data of mappedData" class="col-6 col-md-4 col-lg-{{ itemsPerRow }} mb-2">
    <div class="card product-card h-100">
      <div class="card-body">
        <div [routerLink]="['/product/', data.product.id]">
          <lib-image-carousel-minimal
            [images]="data.product.extra.images"
            imageClass="image-wrapper"
          ></lib-image-carousel-minimal>
        </div>
        <div class="product-text pb-1">
          <div>{{ data.product.id }}</div>
          <div class="text-truncate" translate>{{ data.product.name }}</div>
          <div translate *ngIf="data.price" class="font-weight-bold">
            <lib-price [price]="data.price.customerNetPrice.value" [currency]="data.price.customerNetPrice.currencyCode"></lib-price>
          </div>
          <lib-availability [availability]="data.availability" [isSales]="isSales" *ngIf="includeAvailability">
          </lib-availability>
          <div class="product-card-button">
            <lib-buy-btn [id]="data.product.id" [product]="data.product"></lib-buy-btn>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
