import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { TranslateService } from '@ngx-translate/core';
import { AngularEditorConfigService } from '../../services/angular-editor-config/angular-editor-config.service';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-html-editor',
  templateUrl: './gung-html-editor.component.html',
  styleUrls: ['./gung-html-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungHtmlEditorComponent),
      multi: true
    }
  ]
})
export class GungHtmlEditorComponent extends BaseInputDirective<string> {
  @ViewChild('editor') editorComponent;

  @Input()
  public extraInputClass?: string;

  @Input()
  public labelClass?: string;

  @Input()
  public labelBold = false;

  @Input()
  public horizontal = false;

  @Input()
  public angularEditorConfig: AngularEditorConfig = this.angularEditorConfigService.getDefaultEditorConfig();

  constructor(
    protected translateService: TranslateService,
    protected angularEditorConfigService: AngularEditorConfigService
  ) {
    super();
    if (this.labelBold) {
      this.labelClass = (this.labelClass || '') + ' font-weight-bold';
    }
  }
}
