import { Component, OnInit } from '@angular/core';
import { BuyBtnComponent } from '../buy-btn/buy-btn.component';

@Component({
  selector: 'lib-buy-btn-input',
  templateUrl: './buy-btn-input.component.html',
  styleUrls: ['./buy-btn-input.component.scss']
})
export class BuyBtnInputComponent extends BuyBtnComponent implements OnInit {
  addQuantity: number;

  ngOnInit(): void {
    super.ngOnInit();
    this.addQuantity = this.minimumOrderQuantity || 1;
    if (!this.id) {
      this.id = this.product.id;
    }
  }

  addWithQuantity() {
    this.cartService.addToCart(this.id, this.addQuantity, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product?.name);
    this.addQuantity = this.stepAmount || 1;
  }

  updateAddQuantity(change: number, input: any) {
    let validated: number;
    if (change < this.minimumOrderQuantity) {
      validated = this.minimumOrderQuantity;
    } else if (this.stepAmount === 1 || change % this.stepAmount === 0) {
      validated = change;
    } else {
      validated = change + this.stepAmount - (change % this.stepAmount);
    }
    this.addQuantity = validated;
    if (input.value !== validated) {
      input.value = validated;
    }
  }
}
