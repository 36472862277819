<div>
  <h3 translate class="page-title text-center">ACTIVITIES</h3>
  <div class="row">
    <div class="col-12 text-right">
      <button type="button" class="btn btn-primary mr-2" *ngIf="showList" (click)="openCreateModal()" translate>ACTIVITY_CREATE_NEW</button>

      <button type="button" class="btn btn-primary" [ngClass]="{'active': showList}" (click)="showList = true"><i class="fa-regular fa-list"></i></button>
      <button type="button" class="btn btn-primary" [ngClass]="{'active': !showList}" (click)="showList = false"><i class="fal fa-calendar-alt"></i></button>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-12">
      <lib-filter-list *ngIf="showList; else showCalendar" #list [configService]="configService"></lib-filter-list>
    </div>
  </div>
</div>

<ng-template #showCalendar>
  <lib-activity-internal-gung-calendar></lib-activity-internal-gung-calendar>
</ng-template>