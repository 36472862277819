<div class="pt-1" *ngFor="let gungFlow of data">
  <hr />
  <div class="row">
    <div class="col-12 col-md-6">
      <h5>{{ gungFlow.name }}</h5>
    </div>
    <div class="col-12 col-md-6">
      <div class="btn-group btn-group-justified w-100">
        <a [routerLink]="'/admin/gung-flow-details/' + gungFlow.id" class="w-100 border-0" style="color: unset;">
          <button type="button" class="btn btn-sm btn-default btn-primary w-100">
            {{ 'DETAILS' | translate }}
          </button>
        </a>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6 col-xs-12">
      <div class="row pt-1">
        <div class="col-4">
          {{ 'GUNGFLOW_ID' | translate }}
        </div>
        <div class="col-8">
          {{ gungFlow.id }}
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-4">
          {{ 'GUNGFLOW_IS_DEFAULT' | translate }}
        </div>
        <div class="col-8">
          {{ (gungFlow.isDefault ? 'TRUE' : 'FALSE') | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
