<div class="pt-3">
  <div class="row">
    <div class="col-md-6">
      <textarea
        (keyup)="validate()"
        class="form-control input-sm"
        rows="20"
        cols="40"
        [(ngModel)]="orderParamsTextHolder"
      ></textarea>
    </div>
    <div class="col-md-6">
      <pre
        class="jsonviewer"
        *ngIf="currentUser['extra']"
      ><code>{{ currentUser['extra'].orderParams | json }}</code></pre>
      <div class="alert alert-warning" *ngIf="parseError.hasError">{{ parseError.errorText }}</div>
    </div>
  </div>
</div>
