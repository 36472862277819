import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EdiRespondeOrder } from '../../../models/edi';
import { EdiRespondeOrderResponseActionConfigService } from '../../../services/edi-order-response-action-config/edi-order-response-action-config.service';
import { EdiOrderResponseDetailConfigService } from '../../../services/edi-order-response-detail-config/edi-order-response-detail-config.service';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiRowTable, EdiRowTableType } from '../edi-row-table/edi-row-table.component';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-edi-order-response-detail',
  templateUrl: './edi-order-response-detail.component.html',
  styleUrls: ['./edi-order-response-detail.component.css']
})
export class EdiOrderResponseDetailComponent implements OnInit {
  orderResponseId: string;
  orderResponseData: any;
  orderId: string;
  itemsColumns: EdiRowTable[] = [
    {
      header: 'IMAGE',
      type: EdiRowTableType.IMAGE,
      getValue: (item: EdiRespondeOrder) => item?.extra?.images
    },
    {
      header: 'ID',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) => item.ids?.gungId
    },{
      header: 'EXPECTED_QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) => item.quantities?.expectedQuantity
    },
    {
      header: 'ACTUAL_QUANTITY',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) => item.quantities?.actualQuantity
    },
    {
      header: 'UNIT_NET_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) =>
        (item.prices?.actualUnitNetPrice?.value || '') + ' ' + (item.prices?.actualUnitNetPrice?.currency || '')
    },
    {
      header: 'UNIT_GROSS_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) =>
        (item.prices?.actualUnitGrossPrice?.value || '') + ' ' + (item.prices?.actualUnitGrossPrice?.currency || '')
    },
    {
      header: 'DELIVERY_DATE',
      type: EdiRowTableType.DATE,
      getValue: (item: EdiRespondeOrder) => item.deliveryDate
    },
    {
      header: 'TOTAL_NET_PRICE',
      type: EdiRowTableType.TEXT,
      getValue: (item: EdiRespondeOrder) =>
        (item.prices?.actualTotalNetPrice?.value || '') + ' ' + (item.prices?.actualTotalNetPrice?.currency || '')
    }
  ];

  constructor(
    protected route: ActivatedRoute,
    protected ediService: EdiService,
    public configService: EdiOrderResponseDetailConfigService,
    public actionConfig: EdiRespondeOrderResponseActionConfigService,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.orderResponseId = data.id;
      this.ediService.getOrderResponse(this.orderResponseId).subscribe(orderResponseData => {
        this.orderResponseData = orderResponseData.data;
      });
    });
  }
}
