<div class="table-responsive gung-responsive">
  <table class="table table-borderless table-striped table-hover">
    <thead>
      <tr>
        <th scope="col" translate>ORDER_ID</th>
        <th scope="col" translate>ORDER_STATUS</th>
        <th scope="col" translate>SUPPLIER_NAME</th>
        <th scope="col" translate>ORDER_DATE</th>
        <th scope="col" translate>TOTAL</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let order of data" [routerLink]="[order.id]" class="cursor-pointer">
        <td [attr.data-label]="'ORDER_ID' | translate">{{ order.id }}</td>
        <td [attr.data-label]="'ORDER_STATUS' | translate">
          {{ getStatusTranslationTag(order.extra.IGA.LEF) | translate }}
        </td>
        <td [attr.data-label]="'SUPPLIER_NAME' | translate">
          <span>{{ order.deliveryCustomerId }} - {{ order.extra._gemoSupplierName }}</span>
        </td>
        <td [attr.data-label]="'ORDER_DATE' | translate">
          <span *ngIf="order.extra.IGA.ODT">
            {{ parseDate(order.extra.IGA.ODT) | date: dateUtilService.dateFormat }}
          </span>
        </td>
        <td [attr.data-label]="'TOTAL' | translate">
          <lib-price [price]="getTotalValue(order)" [currency]="order.extra.IGA.VAL"></lib-price>
        </td>
        <td>
          <lib-action-btn
            [item]="order"
            [config]="configService"
            (click)="$event.preventDefault(); $event.stopPropagation()"
          ></lib-action-btn>
        </td>
      </tr>
    </tbody>
  </table>
</div>
