<div class="gung-stepper">
  <div class="stepper-control-top">
    <div
      class="stepper-indicator"
      *ngFor="let step of stepIndicators; let i = index"
      [ngClass]="{ active: currentIndex >= i }"
    >
      <div class="step-label-top" translate>{{ step.label }}</div>
    </div>
  </div>
  <div class="offset"></div>
</div>
