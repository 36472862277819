<div class="selection-bar" #selectionBar>
  <div class="container p-3">
    <div class="row">
      <div class="align-items-center col-12 col-md-auto d-flex">
        <span translate>SELECTED_ITEM_COUNT</span>: <div id="items-selected"> {{ selection.selectedItemCount }} </div>
        <button class="btn btn-outline-secondary ml-4" (click)="toggleSelectedItems()">
          <span translate *ngIf="selectedToggle">SHOW_ALL_ITEMS</span>
          <span translate *ngIf="!selectedToggle">SHOW_SELECTED_ITEMS</span>
        </button>
      </div>
      <div class="col d-flex justify-content-end flex-wrap">
        <div ngbDropdown placement="top-right" class="d-inline-block dropup" *ngIf="filteredItems">
          <button class="btn btn-primary export-area-buttons" id="dropdownSelection" ngbDropdownToggle translate>
            SELECTION_ALTERNATIVES
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownSelection" class="dropdown-container dropdown-container-max-height">
            <button class="export-area-buttons" ngbDropdownItem (click)="selectAllFilteredItems()">
              <span translate *ngIf="filteredItems?.length !== allItems?.length">SELECT_ALL_FILTERED_ITEMS</span>
              <span translate *ngIf="filteredItems?.length === allItems?.length">SELECT_ALL_ITEMS</span>
            </button>
            <button class="export-area-buttons" ngbDropdownItem (click)="deselectAllFilteredItems()" translate>
              DESELECT_ALL_FILTERED_ITEMS
            </button>
            <button class="export-area-buttons" ngbDropdownItem (click)="clearSelection()" translate>
              DESELECT_ALL_ITEMS
            </button>
            <button
              class="export-area-buttons"
              ngbDropdownItem
              *ngFor="let button of getSelectionMarkingActions()"
              (click)="button.performAction(selection)"
              translate
            >
              {{ button.label }}
            </button>
          </div>
        </div>
        <div ngbDropdown placement="top-right" class="d-inline-block dropup">
          <button class="btn btn-outline-primary export-area-buttons" id="dropdownBasic1" ngbDropdownToggle translate>
            {{ actionButtonTitle }}
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container dropdown-container-max-height">
            <button
              class="export-area-buttons"
              ngbDropdownItem
              *ngFor="let button of getActions()"
              (click)="button.performAction(selection)"
              translate
            >
              {{ button.label }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
