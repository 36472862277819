<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CHECKOUT</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div>
    <label for="deliveryStartDate" class="control-label" translate>DELIVERY_DATE</label>
    <div class="input-group">
      <input
        class="form-control input-date"
        placeholder="yyyy-mm-dd"
        name="deliveryStartDate"
        id="deliveryStartDate"
        ngbDatepicker
        #deliveryStartDatePicker="ngbDatepicker"
        container="body"
        [value]="today | date: 'yyyy-MM-dd'"
        [minDate]="getDeliveryDateMin()"
        (dateSelect)="updateDate($event)"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="deliveryStartDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="mt-3" translate>SEND_ORDER_QUESTION</div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light mr-auto" aria-label="Close" (click)="activeModal.close()" translate>
    CANCEL
  </button>
  <button
    type="button"
    class="btn btn-primary ml-auto"
    (click)="sendCheckout()"
    [disabled]="sendingOrder || loading"
    translate
  >
    YES
  </button>
</div>
