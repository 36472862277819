<div class="card product-card">
  <div class="card-body">
    <div class="image-wrapper">
      <img src="{{ data.imageUrlSmall }}" />
    </div>
    <div class="product-details">
      <div class="product-id">
        {{ data.id }}
      </div>
      <div class="product-name">
        {{ data.description }}
      </div>
      <lib-buy-btn id="{{ data.id }}" [product]="data"></lib-buy-btn>
    </div>
  </div>
</div>
