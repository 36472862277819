import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, map, switchMap } from 'rxjs';
import { ProductGridViewComponent } from '../../components/product-grid-view/product-grid-view.component';
import { Product } from '../../models/product';
import { AuthService } from '../auth/auth.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductService } from '../products/product.service';
import { CloudPimFiltersService } from '../cloud-pim-filters/cloud-pim-filters.service';
import { CurrentAssortmentService } from '../base-view-config/base-view-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductListConfigService implements ConfigService<Product> {
  /*
   This variable and setter is called in the product-list-component to set
   the assortmentId to the id in the route params. It did not work to get the routeparams
   from this service.

  assortmentId: string;
  setAssortmentId(assortmentId: string) {
    this.assortmentId = assortmentId;
  } */

  assortmentSubject: Subject<string> = new BehaviorSubject(null);
  displayProductsOnVariantLevel = false;
  topFilter = false;
  keepFilterOpen = false;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected gungFlowService: GungFlowService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected currentAssortmentService: CurrentAssortmentService
  ) {}

  getFilters(): ConfigBaseFilter<Product>[] {
    return  this.cloudPimFiltersService.getFilters(this.currentAssortmentService.currentAssortment);
  }

  getSortOptions(): ListSortOption<Product>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return this.assortmentSubject.pipe(
      switchMap((assortment: string) => {
        if (assortment == null) {
          return this.authService.getCurrentUser().pipe(
            filter(user => !!user),
            switchMap(user => this.fetchProducts(user && user.assortment))
          );
        }
        return this.fetchProducts(assortment);
      })
    );
  }

  protected fetchProducts(assortmentId: string): Observable<Product[]> {
    if (this.displayProductsOnVariantLevel === true) {
      return this.productService.getProductsByAssortmentExpanded(assortmentId).pipe(
        map(products => {
          return this.productSortService.sortProducts(products);
        })
      );
    } else {
      return this.productService.getProductsByAssortment(assortmentId).pipe(
        map(products => {
          return this.productSortService.sortProducts(products);
        })
      );
    }
  }

  getItemId(item: Product) {
    return item.id;
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }

  getCurrentFlow(): Observable<any> {
    return this.gungFlowService.getSelectedFlow();
  }
}
export class ProductNameFilter extends SimpleConfigBaseFilter<Product> {
  getOptionIds(item: Product): string[] {
    return [item.name];
  }
  getOptionName(key: string): string {
    return key;
  }
  getName(): string {
    return 'Product name filter';
  }
}
