<div class="row" *ngIf="data">
  <div *ngFor="let digitalAsset of data" class="col-12 col-sm-6 col-md-4 div-card">
    <div class="card border h-100 middle inside-center pointer bg-secondary" (click)="goInto(digitalAsset.id)">
      <img
        *ngIf="digitalAsset.images && digitalAsset.images[0] as image; else noimage"
        class="card-img-top img-fluid w-100 inside-center-image rounded-circle p-3 mh-100"
        [src]="image.s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+image.s3ETag
        "
        alt="{{ image.description }}"
        class="img-fluid mx-auto d-block"
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100 inside-center-image rounded-circle p-3 mh-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
      <div class="card-body inside-center-text image-text w-100 text-uppercase">
        <div class="inside-content text-center">
          <i class="far fa-folders" *ngIf="digitalAsset.extra.content === 'folder'"></i>
          <i class="fal fa-images" *ngIf="digitalAsset.extra.content === 'digitalAsset'"></i>
        </div>
        <h5 class="card-title text-center mt-2">{{ digitalAsset.name }}</h5>
        <!-- <p class="card-text">{{digitalAsset.id}}</p> -->
      </div>
    </div>
  </div>
</div>
