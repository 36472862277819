<div class="front-page">
  <img
    class="front-img no-show-preview"
    height="1225px"
    [src]="'didriksons/_lithium/gung-pim/images/comment/1637090670498_print.jpg' | gungImageUrl
      :'type:thumbnail'
    "
  />
  <img
    class="front-img no-print show-image-front"
    [src]="'didriksons/_lithium/gung-pim/images/comment/1637090670498_print.jpg' | gungImageUrl
      :'type:thumbnail'
    "
  />
</div>

<div class="print-page">
  <mat-sidenav-container>
    <mat-sidenav
      #sidenav
      mode="over"
      [opened]="sidenavOptions.open"
      [fixedInViewport]="sidenavOptions.fixed"
      [fixedTopGap]="navbarConfigService.hideNavbar ? 0 : sidenavOptions.top"
      [fixedBottomGap]="sidenavOptions.bottom"
    >
      <!-- Actions -->
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header class="mt-3">
          <mat-panel-title translate> ACTIONS </mat-panel-title>
          <mat-panel-description>
            <!-- This is a summary of the content -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <lib-whiteboard-actions></lib-whiteboard-actions>
      </mat-expansion-panel>
      <!-- Options -->
      <mat-expansion-panel expanded="true">
        <mat-expansion-panel-header>
          <mat-panel-title translate> OPTIONS </mat-panel-title>
          <mat-panel-description>
            <!-- This is a summary of the content -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <lib-whiteboard-options></lib-whiteboard-options>
      </mat-expansion-panel>
      <!-- Product list -->
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title translate> PRODUCTS </mat-panel-title>
          <mat-panel-description>
            <!-- This is a summary of the content -->
          </mat-panel-description>
        </mat-expansion-panel-header>
        <lib-whiteboard-product-list></lib-whiteboard-product-list>
      </mat-expansion-panel>
    </mat-sidenav>
    <mat-sidenav-content role="main">
      <a mat-icon-button (click)="sidenav.toggle()" class="drawer-button no-print"><i class="fas fa-bars"></i></a>

      <nav ngbNav #nav="ngbNav" class="nav-tab no-print">
        <ng-container [ngbNavItem]="i" *ngFor="let sheet of configService.sheets; let i = index">
          <a ngbNavLink translate (click)="configService.currentSheetIndex = i">
            {{ 'Sheet' }} {{ i + 1 }}
            <span
              class="close position-relative pl-2 font-weight-light"
              *ngIf="!(i == 0 && configService.sheets.length == 1)"
              (click)="
                $event.preventDefault();
                $event.stopImmediatePropagation();
                configService.removeSheet(i);
                nav.select(configService.currentSheetIndex)
              "
              >×</span
            >
          </a>
          <ng-template ngbNavContent>
            <div style="height: calc(90vh)" id="gridster-div" [style.height.px]="scrollHeight">
              <gridster [options]="configService.getGridOptions()" style="background: #ffffff">
                <!-- This should probably be its own component, did not have time during implementation do extract it though.  -->
                <gridster-item [item]="item" *ngFor="let item of sheet.items">
                  <div class="remove-button">
                    <!-- The reason for the (mousedown) can be read about here: https://github.com/tiberiuzuld/angular-gridster2/issues/516 -->
                    <i
                      class="far fa-minus-square"
                      (mousedown)="$event.preventDefault(); $event.stopPropagation()"
                      (click)="removeItem(item)"
                    ></i>
                  </div>
                  <div *ngIf="this.products && this.products[item.productId]">
                    <div class="img-fluid">
                      <img
                        draggable="false"
                        [src]="products[item.productId].extra.images[0].s3Uri | gungImageUrl
                          :'type:thumbnail'
                          :'etag:'+products[item.productId].extra.images[0].s3ETag
                          :'size:800x1100'  
                        "
                        alt="{{ products[item.productId].extra.images[0].description }}"
                        class="img-fluid"
                      />
                    </div>
                    <div class="widget-product-text mt-1">
                      <div class="row text-muted small text-left">
                        <ng-container *ngFor="let option of this.whiteboardOptionsService.options">
                          <div class="col-12 mr-auto" *ngIf="option.value">
                            <span class="badge bg-light text-dark">{{ products[item.productId][option.path] }}</span>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </gridster-item>
              </gridster>
            </div>
          </ng-template>
        </ng-container>
        <ng-container class="nav-item">
          <a class="nav-link" (click)="$event.preventDefault(); configService.createNewSheet()">...</a>
        </ng-container>
        <ng-container class="nav-item" class="ml-auto">
          <a class="nav-link ml-auto" (click)="$event.preventDefault(); printWhiteboard('gridster-div')"
            ><span translate>PRINT</span><i class="fas fa-print ml-1"></i
          ></a>
        </ng-container>
      </nav>
      <div [ngbNavOutlet]="nav"></div>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
