<div class="form-group">
  <label class="control-label" translate>{{gungTranslate}}</label>

  <div class="input-group mb-2" *ngFor="let item of input; let i = index; trackBy: trackByFn">
    <div class="input-group-prepend">
      <button class="btn btn-secondary" (click)="remove(i)">-</button>
    </div>
    <input
      type="text"
      class="form-control"
      [attr.placeholder]="placeholder | translate"
      [(ngModel)]="input[i]"
      (ngModelChange)="emit()"
      [disabled]="disabled"
    />
  </div>
  <div class="input-group">
    <button [disabled]="disabled" class="btn btn-block btn-primary" (click)="add()">+</button>
  </div>
</div>
