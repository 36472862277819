<div class="modal-header">
  <h3 translate>DOWNLOAD_FILE</h3>

  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div *ngIf="isLoading" class="progress mt-10">
        <div
          class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
          role="progressbar"
          aria-valuenow="75"
          aria-valuemin="0"
          aria-valuemax="100"
          style="width: 75%"
        ></div>
      </div>
      <div *ngIf="isError" style="display: inline-block">
        <i class="fa-solid fa-circle-exclamation mr-2 warningCircle"></i>
        <p class="errorText">ERROR_WHEN_DOWNLOADING_FILES</p>
      </div>
      <table>
        <tr *ngFor="let file of zip; let i = index">
          <td>
            <a href="{{ file.url }}" target="_blank" class="btn btn-primary active" translate
              >DOWNLOAD_FILE <span> {{ i + 1 }}</span></a
            >
          </td>
        </tr>
      </table>
    </div>
  </div>
</div>

<!-- <ng-template #loading> LOADING... </ng-template> -->
