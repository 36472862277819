import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { Whiteboard } from '../types';
import { ProductsBookmark } from '../../models/products-bookmark';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardService {
  constructor(protected http: HttpClient) {}

  private whiteboardsSubject: BehaviorSubject<Whiteboard[]>;
  private baseUrl = 'json/whiteboards';

  public getOne(id: string): Observable<Whiteboard> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.get<Whiteboard>(url);
  }

  public getAll(nocache?: boolean): Observable<Whiteboard[]> {
    if (!this.whiteboardsSubject) {
      this.whiteboardsSubject = new BehaviorSubject<Whiteboard[]>([]);
      this.updateSubject(nocache);
    }
    return this.whiteboardsSubject.asObservable();
  }

  public delete(id: string): Observable<void> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<void>(url).pipe(tap(_ => this.updateSubject(true)));
  }

  public create(whiteboard: Whiteboard): Observable<Whiteboard> {
    const url = `${this.baseUrl}`;
    return this.http.post<Whiteboard>(url, whiteboard).pipe(tap(_ => this.updateSubject(true)));
  }

  public initializeSession(): Observable<void> {
    const url = `${this.baseUrl}/usage/session`;
    return this.http.get<void>(url);
  }

  updateSubject(nocache?: boolean): void {
    this.internalGetAll(nocache).subscribe(whiteboards => this.whiteboardsSubject.next(whiteboards));
  }

  protected internalGetAll(nocache?: boolean): Observable<Whiteboard[]> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    const url = `${this.baseUrl}`;
    return this.http.get<Whiteboard[]>(url, { headers });
  }
}
