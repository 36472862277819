import {
  AfterContentChecked,
  Component,
  ContentChildren,
  Directive,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewEncapsulation
} from '@angular/core';
let nextId = 0;

@Directive({ selector: 'ng-template[libGungTabTitle]' })
export class GungTabTitleDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

@Directive({ selector: 'ng-template[libGungTabContent]' })
export class GungTabContentDirective {
  constructor(public templateRef: TemplateRef<any>) {}
}

// eslint-disable-next-line @angular-eslint/directive-selector
@Directive({ selector: 'lib-gung-tab' })
export class GungTabDirective implements AfterContentChecked {
  @Input() id = `gung-tab-${nextId++}`;

  @Input() title: string;

  @Input() disabled = false;

  titleTpl: GungTabTitleDirective | null;
  contentTpl: GungTabContentDirective | null;

  @ContentChildren(GungTabTitleDirective, { descendants: false }) titleTpls: QueryList<GungTabTitleDirective>;
  @ContentChildren(GungTabContentDirective, { descendants: false }) contentTpls: QueryList<GungTabContentDirective>;

  ngAfterContentChecked() {
    // We are using @ContentChildren instead of @ContentChild as in the Angular version being used
    // only @ContentChildren allows us to specify the {descendants: false} option.
    // Without {descendants: false} we are hitting bugs described in:
    // https://github.com/ng-bootstrap/ng-bootstrap/issues/2240
    this.titleTpl = this.titleTpls.first;
    this.contentTpl = this.contentTpls.first;
  }
}

export interface GungTabChangeEvent {
  activeId: string;

  nextId: string;

  preventDefault: () => void;
}

@Component({
  selector: 'lib-gung-tabset',
  exportAs: 'gungTabset',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './gung-tabs.component.html',
  styleUrls: ['./gung-tabs.component.scss']
})
export class GungTabsetComponent implements AfterContentChecked {
  justifyClass: string;

  @ContentChildren(GungTabDirective) tabs: QueryList<GungTabDirective>;

  /**
   * The identifier of the tab that should be opened **initially**.
   *
   * For subsequent tab switches use the `.select()` method and the `(tabChange)` event.
   */
  // @Input() activeId: string;
  _activeId: string;
  @Input()
  set activeId(val: string) {
    this.activeIdChange.emit(val);
    this._activeId = val;
  }
  get activeId() {
    return this._activeId;
  }
  @Output()
  activeIdChange: EventEmitter<string> = new EventEmitter<string>();

  /**
   * If `true`, non-visible tabs content will be removed from DOM. Otherwise it will just be hidden.
   */
  @Input() destroyOnHide = true;

  /**
   * The horizontal alignment of the tabs with flexbox utilities.
   */
  @Input()
  set justify(className: 'start' | 'center' | 'end' | 'fill' | 'justified') {
    if (className === 'fill' || className === 'justified') {
      this.justifyClass = `nav-${className}`;
    } else {
      this.justifyClass = `justify-content-${className}`;
    }
  }

  /**
   * The orientation of the tabset.
   */
  @Input() orientation: 'horizontal' | 'vertical';

  /**
   * A tab change event emitted right before the tab change happens.
   *
   */
  @Output() tabChange = new EventEmitter<GungTabChangeEvent>();

  constructor() {
    this.justify = 'start';
    this.orientation = 'horizontal';
  }

  /**
   * Selects the tab with the given id and shows its associated content panel.
   *
   * Any other tab that was previously selected becomes unselected and its associated pane is removed from DOM or
   * hidden depending on the `destroyOnHide` value.
   */
  select(tabId: string) {
    const selectedTab = this._getTabById(tabId);
    if (selectedTab && !selectedTab.disabled && this.activeId !== selectedTab.id) {
      let defaultPrevented = false;

      this.tabChange.emit({
        activeId: this.activeId,
        nextId: selectedTab.id,
        preventDefault: () => {
          defaultPrevented = true;
        }
      });

      if (!defaultPrevented) {
        this.activeId = selectedTab.id;
      }
    }
  }

  ngAfterContentChecked() {
    // auto-correct activeId that might have been set incorrectly as input
    const activeTab = this._getTabById(this.activeId);
    this.activeId = activeTab ? activeTab.id : this.tabs.length ? this.tabs.first.id : (null as any);
  }

  private _getTabById(id: string): GungTabDirective {
    const tabsWithId: GungTabDirective[] = this.tabs.filter(tab => tab.id === id);
    return tabsWithId.length ? tabsWithId[0] : (null as any);
  }
}
