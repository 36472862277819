import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { first } from 'rxjs';
import { GungFlow } from './../../../state/flow/types';

@Component({
  selector: 'lib-gung-flow-details',
  templateUrl: './gung-flow-details.component.html',
  styleUrls: ['./gung-flow-details.component.css']
})
export class GungFlowDetailsComponent implements OnInit {
  isMultidropEnabled = false;
  curentGungFlow: GungFlow;

  constructor(protected route: ActivatedRoute, protected router: Router, protected gungFlowService: GungFlowService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.gungFlowService
        .getGungFlow(params.get('flowId'), true)
        .pipe(first())
        .subscribe(gungFlow => {
          this.curentGungFlow = JSON.parse(JSON.stringify(gungFlow));
        });
    });
    this.isMultidropEnabled = this.gungFlowService.isMultiDropFlowsEnabled;
  }

  cancelChanges(): void {
    this.router.navigate(['/admin/gung-flows']);
  }

  saveCurrentUser(): void {
    this.gungFlowService
      .updateGungFlow(this.curentGungFlow)
      .pipe(first())
      .subscribe(gungFlow => {
        this.router.navigate(['/admin/gung-flows']);
        this.gungFlowService.getGungFlows(true).subscribe();
      });
  }

  deleteFlow(): void {
    const message = 'Delete flow?';
    if (confirm(message)) {
      this.gungFlowService
        .deleteGungFlow(this.curentGungFlow)
        .pipe(first())
        .subscribe(gungFlow => {
          this.router.navigate(['/admin/gung-flows']);
          this.gungFlowService.getGungFlows(true).subscribe();
        });
    }
  }
}
