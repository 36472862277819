import { Component, OnInit } from '@angular/core';
import { PdfExportListConfigService } from './services/pdf-export-list-config.service';

@Component({
  selector: 'lib-gung-dynamic-pdf',
  template: '<lib-filter-list #productList [configService]="pdfExportListConfigService"></lib-filter-list>',
  styles: []
})
export class GungDynamicPdfComponent implements OnInit {
  constructor(public pdfExportListConfigService: PdfExportListConfigService) {}

  ngOnInit(): void {}
}
