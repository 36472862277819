import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CartModule } from './cart/cart.module';
import { StoreModule } from '@ngrx/store';
import { CartState } from './cart/types';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { AuthModule } from './auth/auth.module';
import { EffectsModule } from '@ngrx/effects';
import { AuthState } from './auth/types';
import { CustomerModule } from './customer/customer.module';
import { CustomerState } from './customer/types';
import { MetadataModule } from './metadata/metadata.module';
import { MetadataState } from './metadata/types';
import { FlowModule } from './flow/flow.module';
import { GungFlowState } from './flow/types';
import { SupplierState } from './supplier/types';
import { SupplierModule } from './supplier/supplier.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false
        }
      }
    ),
    EffectsModule.forRoot([]),
    CartModule,
    AuthModule,
    CustomerModule,
    SupplierModule,
    MetadataModule,
    FlowModule,
    StoreDevtoolsModule.instrument({
      name: 'DevTools - statemodule',
      maxAge: 25, // Retains last 25 states
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
      trace: true, //  If set to true, will include stack trace for every dispatched action, so you can see it in trace tab jumping directly to that part of code
      traceLimit: 75 // maximum stack trace frames to be stored (in case trace option was provided as true)
    })
  ]
})
export class StateModule {}

export interface AppState {
  cart: CartState;
  auth: AuthState;
  customer: CustomerState;
  supplier: SupplierState;
  metadata: MetadataState;
  gungFlow: GungFlowState;
}
