import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UsersService } from '../../../services/users/users.service';
import { User } from '../../../state/auth/types';

interface Alert {
  type: string;
  message: string;
  invalidFields?: string[];
}

@Component({
  selector: 'lib-update-user-modal',
  templateUrl: './update-user-modal.component.html',
  styleUrls: ['./update-user-modal.component.css']
})
export class UpdateUserModalComponent implements OnInit {
  userForm: FormGroup;
  alertMessage: Alert;
  loading = false;
  userToUpdate: User;

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected usersService: UsersService
  ) {}

  ngOnInit() {
    this.userForm = this.formBuilder.group({
      username: this.formBuilder.control((this.userToUpdate && this.userToUpdate.username) || '', [
        Validators.required
      ]),
      name: this.formBuilder.control((this.userToUpdate && this.userToUpdate.name) || '', []),
      email: this.formBuilder.control((this.userToUpdate && this.userToUpdate.email) || '', [
        Validators.required,
        Validators.email
      ]),
      phone: this.formBuilder.control((this.userToUpdate && this.userToUpdate.phone) || ''),
      managedCompanyIds: this.formBuilder.control((this.userToUpdate && this.userToUpdate.managedCompanyIds) || ''),
      assortment: this.formBuilder.control(this.userToUpdate && this.userToUpdate.assortment)
    });
  }

  onUpdate() {
    if (!this.userForm.valid) {
      const invalidFields = [];
      for (const key of Object.keys(this.userForm.controls)) {
        const field = this.userForm.controls[key];
        if (!field.valid) {
          invalidFields.push(key.toUpperCase());
        }
      }
      this.alertMessage = {
        type: 'danger',
        message: 'FORM_CONTAINS_ERROR',
        invalidFields
      };
      return;
    }

    this.loading = true;

    const userForm: User = {
      ...this.userToUpdate,
      ...this.userForm.getRawValue()
    };

    this.usersService.updateUser(userForm).subscribe(
      user => {
        this.loading = false;
        this.userForm.reset();
        this.activeModal.close(userForm);
      },
      error => {
        this.loading = false;
        this.alertMessage = {
          type: 'danger',
          message: error.error.message
        };
      }
    );
  }
}
