import { Injectable } from '@angular/core';
import { SelectionAction, ExportSelection } from 'gung-list';
import { Observable, first, map, of } from 'rxjs';
import { Customer } from '../../models';
import { AuthService } from '../auth/auth.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { NavbarConfigService } from '../navbar-config/navbar-config.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectionActionConfigService {
  enableCustomerActivities = this.navbarConfigService.enableCustomerActivities;

  constructor(
    protected authService: AuthService,
    protected navbarConfigService: NavbarConfigService,
    protected gungModalService: GungModalService
  ) { }

  getSelectionActions(): Observable<SelectionAction<Customer>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        let userActions: SelectionAction<Customer>[] = [];
        if (this.enableCustomerActivities) {
          userActions.push({
            label: 'CREATE_ACTIVITIES',
            performAction: (selection: ExportSelection<Customer>) => {
              const selectedCustomers: Customer[] = Object.values(selection.selectedItems);
              if(selectedCustomers.length > 1) {
                this.gungModalService.openCreateActivityModal(undefined, undefined, undefined, selectedCustomers).then(
                  result => {},
                  reason => {
                    console.log(`Dismissed ${reason}`);
                  }
                );
              } else {
                this.gungModalService.openCreateActivityModal(undefined, undefined, selectedCustomers[0].id).then(
                  result => {},
                  reason => {
                    console.log(`Dismissed ${reason}`);
                  }
                );
              }

              return of();
            }
          });
        }
        return userActions;
      })
    );
  }
}
