import { Injectable } from '@angular/core';
import { gungComparatorHelper } from 'gung-common';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { EdiInvoiceCardListComponent } from '../../components/edi/edi-invoice-card-list/edi-invoice-card-list.component';
import { EdiOrderResponseCardListComponent } from '../../components/edi/edi-order-response-card-list/edi-order-response-card-list.component';
import { EdiRespondeOrder } from '../../models/edi';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { EdiService } from '../edi/edi.service';

export class InvoiceStatusFilter extends SimpleConfigBaseFilter<EdiRespondeOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'INVOICE_STATUS';
  }
  getOptionIds(item: EdiRespondeOrder): string[] {
    return [item.gungEdiMessageSendStatus || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ValidationFilter extends SimpleConfigBaseFilter<EdiRespondeOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'VALIDATION_STATE';
  }
  getOptionIds(item: EdiRespondeOrder): string[] {
    return [item.validationState || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class ConfigIdFilter extends SimpleConfigBaseFilter<EdiRespondeOrder> {
  constructor() {
    super();
  }
  getName(): string {
    return 'CONFIG_ID';
  }
  getOptionIds(item: EdiRespondeOrder): string[] {
    return [item.ediConfigId || 'UNDEFINED'];
  }
  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class EdiOrderResponseListConfigService implements ConfigService<EdiRespondeOrder> {
  constructor(protected ediService: EdiService, protected baseViewConfigService: BaseViewConfigService) {}

  getItems(): Observable<EdiRespondeOrder[]> {
    return this.ediService.getOrderResponses().pipe(
      // map(data =>
      //   data.sort((a, b) => {
      //     const aSort = a?.documentReferences?.supplierOrder?.date && new Date(a.documentReferences.supplierOrder.date);
      //     const bSort = b?.documentReferences?.supplierOrder?.date && new Date(b.documentReferences.supplierOrder.date);
      //     if ((!bSort && aSort) || (aSort && bSort && aSort > bSort)) {
      //       return -1;
      //     } else if ((!aSort && bSort) || (aSort && bSort && aSort < bSort)) {
      //       return 1;
      //     } else {
      //       return 0;
      //     }
      //   })
      // )
      map(items => items.sort((a, b) => gungComparatorHelper([a.timestamp, a.id], [b.timestamp, b.id], -1)))
    );
  }

  getFilters(): ConfigBaseFilter<EdiRespondeOrder>[] {
    return [new ValidationFilter(), new InvoiceStatusFilter(), new ConfigIdFilter()];
  }

  getSortOptions(): ListSortOption<EdiRespondeOrder>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<EdiRespondeOrder>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getEdiOrderResponseCardListComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Edi Invoices grid'
      }
    ];
  }

  getSearchTerms(item: EdiRespondeOrder): string[] {
    const generalSearchTerms = [
      item.documentReferences?.supplierOrder?.id,
      item.documentReferences?.buyerOrder?.id,
      item.documentReferences?.invoice?.id,
      item.documentReferences?.invoiceOcr?.id,
      item.documentReferences?.despatchAdvice?.id,
      item.documentReferences?.parcel?.id
    ].filter(s => !!s);
    if (item.erpIds) {
      return [item.id, item.consigneeGln?.toString(), item.buyerGln?.toString()]
        .concat(item.erpIds)
        .concat(generalSearchTerms);
    }
    return [item.id, item.buyerGln?.toString(), item.consigneeGln?.toString()].concat(generalSearchTerms);
  }

  getItemId(item: EdiRespondeOrder): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
