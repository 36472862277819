import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FavouriteProductListConfigService } from '../../services/favourite-product-list-config/favourite-product-list-config.service';
import { ProductListComponent } from '../product-list/product-list.component';

@Component({
  selector: 'lib-favourite-product-list',
  templateUrl: './favourite-product-list.component.html',
  styleUrls: ['./favourite-product-list.component.css']
})
export class FavouriteProductListComponent implements OnInit {
  constructor(public favouriteProductListConfig: FavouriteProductListConfigService, protected route: ActivatedRoute) {}

  ngOnInit() {}
}
