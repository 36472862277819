import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { reducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { MetadataEffects } from './effects';
import { metadataFeatureName } from './types';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature(metadataFeatureName, reducers),
    EffectsModule.forFeature([MetadataEffects])
  ]
})
export class MetadataModule {}
