import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { first, map, mergeMap } from 'rxjs';
import { PdfDigitalAssetsFolderGridViewComponent } from '../components/pdf-digital-assets-folder-grid-view/pdf-digital-assets-folder-grid-view.component';
import { PdfDigitalAssetsService } from './pdf-digital-assets.service';

@Injectable({
  providedIn: 'root'
})
export class ExportDragDigitalAssetsConfigService implements ConfigService<any> {
  topFilter = true;
  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);

  constructor(protected digitalAssetsService: PdfDigitalAssetsService) {}

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => PdfDigitalAssetsFolderGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'DigitalAssetsGrid'
      }
    ];
  }

  getBatchSizes(): number[] {
    return [6];
  }

  getItems(): Observable<any> {
    return this.digitalAssetsObjectsSubject.pipe(
      // filter(ids => !!ids),
      mergeMap(ids => forkJoin([of(ids), this.digitalAssetsService.getDigitalAssets(true).pipe(first())])),
      map(([filterIds, digitalAssets]) => {
        if (filterIds) {
          // Filter by Ids
          return digitalAssets.filter(d => filterIds.includes(d.id));
        }
        return digitalAssets;
      })
    );
  }

  getItemId(item: any) {
    return item.id;
  }

  getSearchTerms(item: any) {
    return [item.id, item.name];
  }

  getSortOptions(): ListSortOption<any>[] {
    // TODO: implement
    return [];
  }
}
