<div class="container" *ngIf="!!context && !error">
  <div class="mb-4">
    <h2 class="text-center">
      <span translate>ORDER_TRANSACTION_SUBMITED</span>
    </h2>
    <p class="text-center">
      <span translate>Transaction ID</span>:
      <a [href]="'orders/' + item" *ngFor="let item of context.erpOrderIds; last as isLast"
        >{{ item }}<span *ngIf="!isLast">, </span></a
      >
    </p>
    <p class="text-center" translate>THANK_YOU_EMAIL_CONFIRMATION</p>
  </div>
</div>
<div class="container" *ngIf="error">
  <div class="mb-4">
    <p class="text-center">ERROR_OCCURED</p>
  </div>
</div>
