<h3 translate class="page-title text-center">CUSTOM_PAGES_MENU_TITLE</h3>

<!-- Hide it from non Admin -->
<div class="row" *ngIf="!isAdmin">
  <div class="col-12">
    <span translate>EDITOR_FOR_ADMIN</span>
  </div>
</div>

<!-- Start -->
<div>
  <!-- Alerts -->
  <div class="row mt-2 mb-2">
    <div class="col">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="[alertMessage.typeClass]"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-3">
      <h6 translate>CUSTOM_PAGES_MENU_TITLE</h6>
    </div>
  </div>

  <div class="row">
    <div class="col-2">
      <!-- ALLOWED CUSTOM PAGES -->
      <button
        class="pageCard btn btn-primary"
        *ngFor="let option of customPageList; index as i"
        (click)="setSelectedPage(option.pageName)"
      >
        <span class="float-left">
          <i
            [ngClass]="{
              'fa-check-square': option.pageName === selectedPage,
              'fa-square': option.pageName !== selectedPage
            }"
            class="far"
          ></i>
          <span style="padding-left: 10px">{{ option.pageName }}</span>
        </span>
        <span class="float-right">
          <i
            *ngIf="editedId.includes(option.pageName)"
            class="fas fa-exclamation-triangle"
            title="{{ 'UNSAVED_CHANGES' | translate }}"
          ></i>
          <i
            *ngIf="enableCreateCustomPage"
            class="fas fa-trash-alt"
            (click)="$event.preventDefault(); $event.stopPropagation(); deleteCustomPage(option, $event)"
          ></i>
        </span>
      </button>
    </div>
    <div class="col-10">
      <div class="row">
        <!-- CREATE CUSTOM PAGES -->
        <div class="col-2">
          <div *ngIf="enableCreateCustomPage">
            <button
              type="button"
              class="btn btn-primary createBtn"
              (click)="$event.target.disabled = true; createTemplate()"
            >
              <span><i class="fas fa-plus"></i></span>
            </button>
          </div>
        </div>

        <div class="col-6">
          <!--Dropdowns-->
          <div class="comboBoxes" *ngIf="selectedPage">
            <!-- DEFAULT TEMPLATES SELECTION -->
            <div *ngIf="templateList.length > 0">
              <div class="form-group row">
                <label [ngClass]="labelClass" class="col-form-label" for="templates" translate>TEMPLATES</label>
                <div class="col">
                  <select
                    name="templates"
                    id="templates"
                    class="form-control form-select"
                    [(ngModel)]="selectedTemplate"
                    (change)="changeTemplate($event)"
                  >
                    <option [value]="option.id" *ngFor="let option of templateList">
                      {{ option.name | translate }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div *ngIf="settingsGroups && settingsGroups.length >= 0">
              <!-- Groups selection -->
              <lib-gung-input-multi-select-horizontal
                label="GROUPS"
                gungTranslate="GROUPS"
                [previewEmpty]="previewEmpty"
                [optionsList]="userGroups"
                (selectedValues)="setSelectedValues($event)"
              >
              </lib-gung-input-multi-select-horizontal>
              <div *ngIf="noGroups" class="alert alert-warning" role="alert">
                <i class="fa-solid fa-triangle-exclamation mr-1"></i><span translate>WILL_NOT_SHOW_TO_ANY_GROUP</span>
              </div>
            </div>
          </div>
        </div>

        <!-- Save/Reset buttons -->
        <div class="col-4">
          <button
            type="button"
            class="btn btn-primary float-right"
            (click)="onSave()"
            [disabled]="loader ? true : null"
          >
            <span translate>SAVE</span><i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
          </button>
          <button
            type="button"
            class="btn btn-outline-primary float-right mx-2"
            (click)="getTemplateData(true)"
            [disabled]="loader ? true : null"
          >
            <span translate>RESET</span><i *ngIf="loader" class="fas fa-spinner fa-spin ml-2"></i>
          </button>
        </div>
      </div>

      <!-- Templates -->
      <div class="row pt-2">
        <div class="col-12">
          <!-- SHOW TEMPLATE TO EDIT -->
          <!-- <lib-home-default-template #previewFrame id="previewFrame" [data]="dataTemplate" (dataChange)="setData($event)" [edit]="true"></lib-home-default-template> -->
          <ng-container #viewChild libCustomPageDirective></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading> LOADING... </ng-template>
