import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CustomPageEditorModalComponent } from '../custom-page-editor-modal/custom-page-editor-modal.component';

@Component({
  selector: 'lib-custom-page-html-editor',
  templateUrl: './custom-page-html-editor.component.html',
  styleUrls: ['./custom-page-html-editor.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CustomPageHtmlEditorComponent extends CustomPageEditorModalComponent {
  mapData() {
    this.imageUpload = this.data.map(() => undefined);

    this.translateLangs = this.languageConfigService
      .getLanguages()
      .filter(l => l.enabled)
      .map(l => l.short);

    for (let data of this.data) {
      data.text = data.text || { enabled: true, text: ''};
      data.text.i18n = data.text.i18n || {};

      for (const lang of this.translateLangs) {
        if (!data.text.i18n[lang]) {
          data.text.i18n[lang] = { text: data?.text?.text || '' };
        }
      }
    }
  }
}
