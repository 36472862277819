import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth/auth.service';
import { Whiteboard } from '../../types';
import { WhiteboardService } from '../../services/whiteboard.service';
import { first } from 'rxjs/operators';
import { OptionsList } from 'gung-common';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-whiteboard-save-modal',
  templateUrl: './whiteboard-save-modal.component.html',
  styleUrls: ['./whiteboard-save-modal.component.css']
})
export class WhiteboardSaveModalComponent implements OnInit {
  whiteboardOptions: OptionsList[];
  alertMessage: Alert;
  submited = false;

  selectedWhiteboardId: string;

  whiteboardForm: FormGroup;
  whiteboard: Whiteboard = {} as Whiteboard;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected authService: AuthService,
    protected whiteboardService: WhiteboardService
  ) {}

  ngOnInit(): void {
    this.whiteboardService
      .getAll()
      .pipe(first())
      .subscribe(whiteboards => {
        this.whiteboardOptions = whiteboards.map(w => {
          return {
            id: w.id,
            name: w.name
          };
        });

        this.initForm();
      });
  }

  initForm() {
    this.whiteboardForm = this.formBuilder.group({
      name: this.formBuilder.control(this.whiteboard.name, [])
    });
  }

  whiteboardSelected(whiteboardId: string) {
    this.selectedWhiteboardId = whiteboardId;
  }

  onSave() {
    if (!this.whiteboardForm.valid) {
      this.whiteboardForm.markAllAsTouched();
      return;
    }

    const formRaw = this.whiteboardForm.getRawValue();
    this.whiteboardForm.disable();

    this.whiteboard = {
      ...this.whiteboard,
      name: formRaw.name
    } as Whiteboard;

    if (!!this.selectedWhiteboardId) {
      const whiteboardOption = this.whiteboardOptions.find(w => w.id === this.selectedWhiteboardId);
      if (!!whiteboardOption) {
        this.whiteboard.id = whiteboardOption.id;
        this.whiteboard.name = whiteboardOption.name;
      }
    }

    this.whiteboardService.create(this.whiteboard).subscribe(
      whiteboard => {
        this.alertMessage = { type: 'success', message: 'WHITEBOARD_SAVED' };
        this.submited = true;
      },
      () => {
        this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
        this.submited = true;
      }
    );
  }
}
