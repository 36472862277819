import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parse } from 'date-fns';
import { Product } from '../../../models';
import { Availability } from '../../../models/availability';

@Component({
  selector: 'lib-barcode-product-details',
  templateUrl: './barcode-product-details.component.html',
  styleUrls: ['./barcode-product-details.component.css']
})
export class BarcodeProductDetailsComponent implements OnInit {
  @Input() product: Product;

  @Input() availability: Availability;

  currentLang;
  indexImage = 0;
  activePanels: object = {};

  constructor(protected translateService: TranslateService) {
    this.currentLang = this.translateService.currentLang;
  }

  ngOnInit(): void {
    this.activePanels['ngb-panel-0'] = true;
    this.activePanels['stock-delivery'] = true;
    console.log(this.availability);
    if (this.availability.extra.availabilities) {
      const avs = [];
      for (const key in this.availability.extra.availabilities) {
        if (Object.prototype.hasOwnProperty.call(this.availability.extra.availabilities, key)) {
          const element = this.availability.extra.availabilities[key];
          avs.push({ date: parse(key, 'yyMMdd', new Date()), stock: element });
        }
      }
      this.availability.extra._av = avs;
    }
    console.log(this.product);
  }

  changeImage(change) {
    const length = this.product.extra.images.length;
    let newIndex = this.indexImage;
    if (change === '-') {
      newIndex = newIndex - 1;
      if (newIndex < 0) {
        newIndex = length - 1;
      }
    } else {
      newIndex = newIndex + 1;
      if (newIndex === length) {
        newIndex = 0;
      }
    }
    this.indexImage = newIndex;
  }

  handleAccordionToggle(event): void {
    this.activePanels[event.panelId] = event.nextState;
  }
}
