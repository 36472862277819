<div
  ngbDropdown
  display="dynamic"
  placement="bottom-right"
  #myDrop="ngbDropdown"
  routerLink="/checkout"
  [routerLinkActive]="'active'"
  (mouseenter)="$event.stopPropagation(); myDrop.open()"
  (mouseleave)="$event.stopPropagation(); myDrop.close()"
  (click)="myDrop.close()"
>
  <div class="d-block cart-button-wrapper text-center">
    <a class="cart-button" role="button" id="cartIcon" ngbDropdownAnchor>
      <ng-container [ngSwitch]="showType">
        <ng-template [ngSwitchCase]="'BagShopping'">
          <i [ngClass]="cartContents ? 'fa-regular fa-bag-shopping fa-lg' : 'fa fa-spinner fa-pulse fa-fw'"></i>
          <span *ngIf="!!cartContents" class="badge {{badgeColorClass}} rounded-circle">{{ cartContents.rows.length }}</span>
          <div class="nav-bar-icon-title" [ngClass]="{'global-search-enabled': enableNavbarGlobalSearch}">
            <span>{{ 'CART' | translate }}</span>
          </div>
        </ng-template>
        <ng-template [ngSwitchDefault]>
          <i [ngClass]="cartContents ? 'fas fa-shopping-cart' : 'fa fa-spinner fa-pulse fa-fw'"></i>
          <span *ngIf="!!cartContents" class="badge {{badgeColorClass}} rounded-circle cart-icon-badge">{{ cartContents.rows.length }}</span>
          <div class="nav-bar-icon-title" [ngClass]="{'global-search-enabled': enableNavbarGlobalSearch}">
            <span>{{ 'CART' | translate }}</span>
          </div>
        </ng-template>
      </ng-container>
    </a>
    <div ngbDropdownMenu aria-labelledby="cartIcon">
      <div class="cart-content container" *ngIf="!!cartContents">
        <div class="row">
          <div class="col-12">
            <h6 class="font-weight-bold" translate>CART_SUBTOTAL</h6>
            {{ 'CART_ARTICLE_SKU_TOTAL' | translate: { article: cartTotalRows, sku: cartTotalItems } }}
            <!-- <table class="table table-sm table-borderless">
              <tbody>
                <tr *ngFor="let row of cartContents.rows">
                  <td>
                    <a routerLink="/product/{{ row.cartRow.productId }}">{{ row.cartRow.productId }}</a>
                  </td>
                  <td>
                    x{{ row.cartRow.qty }}
                  </td>
                  <td class="text-right">
                    {{ row.price.cartRowUnitPriceInclRowDiscount.value | gungCurrency: row.price.cartRowUnitPriceInclRowDiscount.currencyCode }}
                  </td>
                </tr>
              </tbody>
            </table> -->
          </div>
        </div>

        <hr style="width: 96%; margin-left: 2%" />

        <h5 class="pt-1 text-right w-100">
          {{ 'TOTAL' | translate }}
          <lib-price [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price>
        </h5>

        <div class="row pt-2">
          <div class="col-12 pb-1">
            <button
              type="button"
              class="btn btn-sm btn-primary w-100 navbarcart-btn-checkout"
              routerLink="/checkout"
              (click)="$event.preventDefault(); $event.stopPropagation()"
              translate
            >
              CHECKOUT
            </button>
          </div>

          <div class="col-12 pb-1">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary w-100"
              (click)="$event.preventDefault(); $event.stopPropagation(); clearCart()"
              translate
            >
              CLEAR_CART
            </button>
          </div>

          <div class="col-12 pb-1">
            <button
              type="button"
              class="btn btn-sm btn-outline-secondary w-100 pl-0"
              (click)="$event.preventDefault(); $event.stopPropagation(); saveCart()"
              translate
            >
              SAVE_CART
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
