import { AuthService } from './../../services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first, mergeMap } from 'rxjs';
import { Observable, of, forkJoin } from 'rxjs';
import { Customer } from '../../models';
import { CustomerService } from '../../services/customers/customer.service';
import { User } from '../../state/auth/types';
import { HomeNavigationConfigService } from '../../services/home-navigation-config/home-navigation-config.service';
import { CustomPageConfigService } from '../../services/custom-page/custom-page-config.service';
import { BackendTemplate } from '../custom-page/custom-page-interface/template-component';

@Component({
  selector: 'lib-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected customerService: CustomerService,
    protected homeNavigationConfigService: HomeNavigationConfigService,
    protected customPageConfigService: CustomPageConfigService
  ) { }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          const requests: { [s: string]: Observable<any> } = { user: of(user) };
          // get customers only for users
          if (!(user.roles.includes('SALES') || user.roles.includes('ADMIN')) && user.roles.includes('USER')) {
            requests.customers = this.customerService.getCustomers().pipe(first());
          }
          if (this.homeNavigationConfigService.dynamicCustomPageByRoles) {
            requests.customPages = this.customPageConfigService.getTemplates().pipe(first());
          }
          return forkJoin(requests);
        })
      )
      .subscribe(({ user, customers, customPages }) => {
        this.navigateTo(user, customers, customPages);
      });
  }

  navigateTo(user: User, customers?: Customer[], customPages?: BackendTemplate[]) {
    this.router.navigate(this.homeNavigationConfigService.getHomeLink(user, customers, customPages));
    /*
    if (user.roles.includes('SALES') || user.roles.includes('ADMIN')) {
      this.router.navigate(['/sales-dashboard']);
    } else if (user.roles.includes('USER')) {
      if (customers && (customers as Customer[]).length > 1) {
        this.router.navigate(['/delivery-location']);
      } else {
        this.router.navigate(['/categories']);
      }
    } else if (user.roles.includes('ANONYMOUS')) {
      this.router.navigate(['/products']);
    }
    */
  }
}
