import { Component, Input, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { GLNRecord, GungEdiAddressInfo } from '../../../models/edi';
import { EdiService } from '../../../services/edi/edi.service';

@Component({
  selector: 'lib-edi-gln-details-table',
  templateUrl: './edi-gln-details-table.component.html',
  styleUrls: ['./edi-gln-details-table.component.css']
})
export class EdiGlnDetailsTableComponent implements OnInit {
  @Input() glnNumber: string;
  @Input() addressFromMessage: GungEdiAddressInfo;

  glnRecord: GLNRecord;
  constructor(protected ediService: EdiService) {}

  ngOnInit(): void {
    this.ediService.getGLNRecord(this.glnNumber).pipe(first()).subscribe(glnRecord => {
      this.glnRecord = glnRecord;
    });
  }
}
