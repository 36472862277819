<div class="table-responsive-md gung-responsive" *ngIf="mappedData; else loading">
  <table class="table table-striped" *ngIf="mappedData && mappedData.length > 0">
    <thead class="thead-sticky">
      <tr>
        <th 
          (click)="sortColumn(productIdPimTemplateProperties)"
          [class.pointer]="isEnabledColumnSort"
          translate>PRODUCT
          <i
            *ngIf="productIdPimTemplateProperties.sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': productIdPimTemplateProperties.sort === 'asc',
              'fa-sort-amount-up': productIdPimTemplateProperties.sort === 'desc'
            }"
          ></i>
        </th>
        <th>
          <!--place holder-->
        </th>
        <th
          *ngFor="let headerKey of dynamicColumns; let i = index"
          class="text-center text-nowrap"
          [class.pointer]="isEnabledColumnSort"
          [class.d-none]="!headerKey.isDisplay && !headerKey.isDisplayList && !headerKey.isDisplayGrid"
          (click)="sortColumn(dynamicColumns[i])"
        >
          {{ headerKey.translationKey | translate }}
          <i
            *ngIf="headerKey.sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': headerKey.sort === 'asc',
              'fa-sort-amount-up': headerKey.sort === 'desc'
            }"
          ></i>
        </th>
        <th
          (click)="sortColumn(availabilityPimTemplateProperties)"
          class="text-nowrap"
          [class.pointer]="isEnabledColumnSort"
          translate
        >
          AVAILABILITY
          <i
            *ngIf="availabilityPimTemplateProperties.sort"
            class="fas"
            [ngClass]="{
              'fa-sort-amount-down': availabilityPimTemplateProperties.sort === 'asc',
              'fa-sort-amount-up': availabilityPimTemplateProperties.sort === 'desc'
            }"
          ></i>
        </th>
        <th class="buy-btn-width-150" translate>PRICE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData; index as i">
        <td class="product-img-td">
          <img
            class="img-fluid"
            [src]="item.product.extra.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+item.product.extra.images[0].s3ETag
            "
            alt="{{ item.product.extra.images[0].description }}"
          />
        </td>
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [routerLink]="isArticles ? [item.product?.id] : ['/product/', item.product?.id]" class="product-link">
            <span class="d-block font-weight-bold text-nowrap">{{ item.product?.id }}</span>
            {{ item.product?.name }}
          </a>
        </td>
        <td
          *ngFor="let value of item.product.dynamicCollumnsValues; let i = index"
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          class="text-md-center text-nowrap"
          [class.d-none]="
            !dynamicColumns[i].isDisplay && !dynamicColumns[i].isDisplayList && !dynamicColumns[i].isDisplayGrid
          "
        >
          <span>{{ value }}</span>
        </td>
        <td>
          <lib-availability [availability]="item.availability" [isSales]="isSales"></lib-availability>
        </td>
        <td [attr.data-label]="'PRICE' | translate">
          <span *ngIf="item.price" class="d-block text-center">
            <lib-price [price]="item.price" [product]="item.product" type="GROSS" [priceLevelsDisplayType]="'COMPACT_LIST'"></lib-price>
            <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
          </span>
          <lib-buy-button-wrapper
            name="buy-btn-package-size"
            class="height-30"
            [id]="item.product?.id"
            [product]="item.product"
            [minimumOrderQuantity]="item.product?.packageSize"
            [stepAmount]="item.product?.packageSize"
          ></lib-buy-button-wrapper>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i *ngIf="!findError" class="fa fa-spinner fa-spin fa-7x"></i>
  <div *ngIf="findError" class="mb-2 text-center">
    {{ errorMessage }}
  </div>
</ng-template>
