import { Component, Input, OnInit } from '@angular/core';
import { GridViewProductCardV2 } from '../product-grid-view-v2/product-grid-view-v2.component';

@Component({
  selector: 'lib-base-grid-card-v2',
  templateUrl: './base-grid-card-v2.component.html',
  styleUrls: ['./base-grid-card-v2.component.css']
})
export class BaseGridCardV2Component implements OnInit {
  @Input()
  data: GridViewProductCardV2;

  public isAnonymous = true;
  public isSales = false;
  public includeAvailability = false;
  constructor() {}

  ngOnInit(): void {
    this.isAnonymous = this.data.isAnonymous;
    this.isSales = this.data.isSales;
    this.includeAvailability = this.data.includeAvailability;
  }
}
