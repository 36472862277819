import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { BehaviorSubject, first, forkJoin, mergeMap, switchMap } from 'rxjs';
import { catchError, map, Observable, of, tap, throwError } from 'rxjs';
import { NavigationConfig } from '../../models/navigation-config';
import { Supplier, SupplierPurchaseComment, SupplierPurchaseOrder, SupplierPurchaseOrderHistory } from '../../models/supplier';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class SupplierService {

  protected cachedPurchaseOrdersByStatus: {[s: string]: BehaviorSubject<SupplierPurchaseOrder[]>} = {}

  constructor(
    protected http: HttpClient,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig,
    protected authService: AuthService
    ) {}

  getSuppliers(noCache?: boolean): Observable<Supplier[]> {
    const url = 'json/suppliers';

    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }
    return this.http.get<Supplier[]>(url, { headers }).pipe(
      map(data => data ?? []), // Sometimes the endpoint return null
      catchError((error: any) => {
        console.error(`${url}`, error)
        return of([]);
      })
    );
  }

  getSupplierAllowed(customerId: string): Observable<boolean> {
    // return of(true);
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<boolean>(`json/suppliers/${customerId}/allowed`, { headers });
  }

  getDefaultSupplier(_noCache?: boolean): Observable<Supplier> {
    if (!this.environment?.enableSupplierPortal) {
      return of(null);
    }
    return this.getSuppliers(true).pipe(
      mergeMap(suppliers => forkJoin([
        of(suppliers),
        this.authService.getCurrentUser().pipe(first())
      ])),
      map(([suppliers, currentUser]) => {
        if (suppliers?.length > 0) {
          if (currentUser.managedSupplierIds?.length > 0 && currentUser.managedSupplierIds[0] !== 'GUNG_ALL') {
            return suppliers.find(s => s.id === currentUser.managedSupplierIds[0]);
          }
          return suppliers[0];
        }
        return null;
      }),
    );
    //    return this.getSupplier('GUNG_DEFAULT', noCache);
  }

  getSupplier(supplierId: string, noCache?: boolean): Observable<Supplier> {
    const url = 'json/suppliers/' + supplierId;
    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    return this.http.get<Supplier>(url, { headers });
  }

  getSupplierOrders(id: string): Observable<any> {
    const url = `json/supplier-order/${id}`;
    return this.http.get<any>(url);
  }

  getSupplierContacts(id: string): Observable<any> {
    const url = `json/suppliers/${id}/contacts`;
    return this.http.get<any>(url);
  }

  sendConfrimation(orderId: string, emailRecipients: string[]) {
    return this.http.post('/json/supplier-order/send-confirmation', { emailRecipients, orderId });
  }

  getPurchaseOrder(status: string, supplierId: string, noCache?: boolean): Observable<SupplierPurchaseOrder[]> {
    if (!this.cachedPurchaseOrdersByStatus[status]) {
      this.cachedPurchaseOrdersByStatus[status] = new BehaviorSubject<SupplierPurchaseOrder[]>([]);
      return this.internalGetPurchaseOrder(status, supplierId, noCache);
    }
    if (noCache) {
      return this.internalGetPurchaseOrder(status, supplierId, noCache);
    }

    return this.cachedPurchaseOrdersByStatus[status];
  }

  internalGetPurchaseOrder(status: string, supplierId: string, noCache?: boolean): Observable<SupplierPurchaseOrder[]> {
    const url = `json/supplier-portal/purchase-orders?supplierId=${supplierId}&status=${status}`;

    const headers = {
      maxAge: noCache ? '-1' : '300'
    };

    return this.http.get<any>(url, { headers: headers }).pipe(
      tap(data => this.cachedPurchaseOrdersByStatus[status]?.next(data)),
      switchMap(_ => this.cachedPurchaseOrdersByStatus[status])
    );
  }

  getBuyerPurchaseOrder(noCache?: boolean): Observable<SupplierPurchaseOrder[]> {
    const url = `json/supplier-portal/purchase-orders`;

    const headers = {
      maxAge: noCache ? '-1' : '300'
    };

    return this.http.get<any>(url, { headers: headers });
  }

  postPurchaseOrderSplit(rowId: string, newQuantity: number): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/${rowId}/split?quantity=${newQuantity}`;
    return this.http.post<SupplierPurchaseOrder>(url, { }).pipe(
      mergeMap(data => {
        if (data) {
          return forkJoin([
            of(data),
            this.getPurchaseOrder(data.status.toString(), data.supplierId, true).pipe(first())
          ]);
        }
        return forkJoin([
          of(data)
        ]);
      }),
      map(([data]) => data)
    );
  }

  postPurchaseOrderComment(rowId: string, comment: SupplierPurchaseComment): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/${rowId}/comments`;
    return this.http.post<SupplierPurchaseOrder>(url, comment);
  }


  getPurchaseOrderHistory(id: string): Observable<SupplierPurchaseOrderHistory[]> {
    const url = `json/supplier-portal/purchase-orders/log/${id}`;
    return this.http.get<any>(url);
  }

  getPurchaseOrderHistoryMap(ids: string[]): Observable<SupplierPurchaseOrderHistory[]> {
    const url = `json/supplier-portal/purchase-orders/log`;
    return this.http.post<any>(url,ids);
  }

  patchPurchaseOrder(id: string, patchValue: GungPatchValue[]): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/${id}`;
    return this.http.patch<any>(url, patchValue).pipe(
      mergeMap(data => {
        if (data) {
          return forkJoin([
            of(data),
            this.getPurchaseOrder(data.status.toString(), data.supplierId, true).pipe(first())
          ]);
        }
        return forkJoin([
          of(data)
        ]);
      }),
      map(([data]) => data)
    );
  }

  patchMultiplePurchaseOrder(data: {id: string, patchDtoList: GungPatchValue[]}[]): Observable<SupplierPurchaseOrder[]> {
    const url = `json/supplier-portal/purchase-orders`;
    return this.http.patch<any>(url, data).pipe(
      mergeMap(data => {
        if (data?.length > 0) {
          return forkJoin([
            of(data),
            this.getPurchaseOrder(data[0].status.toString(), data[0].supplierId, true).pipe(first())
          ]);
        }
        return forkJoin([
          of(data)
        ]);
      }),
      map(([data]) => data)
    );
  }

  getDocumentById(id: string): Observable<SupplierPortalDocument> {
    const url = `json/supplier-portal/purchase-orders/documents/${id}`;
    return this.http.get<any>(url);
  }

  getDocumentByRowId(rowId: string): Observable<SupplierPortalDocument[]> {
    const url = `json/supplier-portal/purchase-orders/documents/by-row-id/${rowId}`;
    return this.http.get<any>(url);
  }

  postDocument(document: SupplierPortalDocument): Observable<SupplierPortalDocument> {
    const url = `json/supplier-portal/purchase-orders/documents`;
    return this.http.post<SupplierPortalDocument>(url, document);
  }

  getSupplierPurchaseOrderStatus(supplierId: string): Observable<any> {
    const url = 'json/supplier-portal/purchase-orders/status/' + supplierId;
    const headers: { [key: string]: string | string[] } = {};
    return this.http.get<any>(url, { headers });
  }

  readComments(ids: string[]): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/comments/read`;
    return this.http.post<SupplierPurchaseOrder>(url,ids);
  }
  readDocuments( ids: string[]): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/documents/status/read`;
    return this.http.post<SupplierPurchaseOrder>(url, ids);
  }

  sendRejectMessage(ids: string[]): Observable<SupplierPurchaseOrder> {
    const url = `json/supplier-portal/purchase-orders/documents/status/read`;
    return this.http.post<SupplierPurchaseOrder>(url, ids);
  }

  getDocumentByRowIds(rowIds: string[]): Observable<SupplierPortalDocument> {
    const url = `json/supplier-portal/purchase-orders/documents/by-row-ids`;
    return this.http.post<SupplierPortalDocument>(url, rowIds);
  }
}

export interface GungPatchValue {
  op: 'replace';
  path: string;
  value: any;
}

export interface SupplierPortalDocument {
  id?: string;
  s3Uri: string;
  s3Etag: string;
  connectedIds: string[];
  uploadDate?: Date;
  uploadOrderStatus?: number;
  read?: boolean;
  userId?: string;
}
