import { Component, OnInit, } from '@angular/core';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { SaveCartModalConfigService } from '../../../services/save-cart-modal-config/save-cart-modal-config.service';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-gung-navbar-cart',
  templateUrl: './gung-navbar-cart.component.html',
  styleUrl: './gung-navbar-cart.component.scss'
})
export class GungNavbarCartComponent implements OnInit {

  cartContentsSubject = new Subject<NavbarCartModel>();
  cartContents: NavbarCartModel;
  
  cartTotalRows = 0;
  cartTotalItems = 0;

  constructor(
    protected gungModalService: GungModalService,
    protected navbarCartService: NavbarCartService,
    protected saveCartModalConfigService: SaveCartModalConfigService
  ){

  }

  ngOnInit(): void {
    this.cartContentsSubject.subscribe(contents => {
      this.cartContents = contents;
      this.calculateTotals();
    })
  }

 

  calculateTotals(): void{
    this.cartTotalRows = this.cartContents.rows.length;
    this.cartTotalItems = this.cartContents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
  }

  clearCart() {
    this.gungModalService.openConfirmYesNoModal(undefined, 'CLEAR_CART_CONFIRM', { size: 'sm' }).then(
      result => {
        if (result) {
          this.navbarCartService.clearCart();
        }
      },
      reason => { }
    );
  }

  saveCart(): void {
    this.saveCartModalConfigService.openSaveCartModal();
  }
}
