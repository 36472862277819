import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { StandardStatisticsData } from '../../../models/statistics';
import { ListItemRendererComponent, PimTemplateProperties } from 'gung-list';
import { ColumnSortSelectionService } from '../../../services/column-sort-selection/column-sort-selection.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

interface MappedData {
  productId: string,
  productName: string,
  salesAmount: number,
  quantity: number,
  numberOfOrders: number
}

@Component({
  selector: 'lib-order-analytics-product-table',
  templateUrl: './order-analytics-product-table.component.html',
  styleUrls: ['./order-analytics-product-table.component.css']
})
export class OrderAnalyticsProductTableComponent extends ListItemRendererComponent<StandardStatisticsData[]> implements OnInit, AfterViewInit {
  public displayedColumns: string[] = ['productName', 'salesAmount', 'quantity', 'numberOfOrders'];
  public mappedData: MappedData[] = [];
  public dataSource;

  @ViewChild(MatSort) sort: MatSort

  ngOnInit(): void {
    this.mountTableData(this.data);
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
    this.renderFinished?.emit();
  }

  protected mountTableData(data: StandardStatisticsData[]): void {
    const productOrderIdMap: { [s: string]: number } = {};

    for (const row of data) {
      productOrderIdMap[row.productId] = (productOrderIdMap[row?.productId] || 0) + 1;

      const tableDataFoundIndex = this.mappedData.findIndex(td => td.productId === row.productId);

      if (tableDataFoundIndex > -1) {
        this.mappedData[tableDataFoundIndex].quantity += row.orderRowTotalQuantity;
        this.mappedData[tableDataFoundIndex].salesAmount += row.orderRowTotalValueCurrency;
      } else {
        this.mappedData.push({
          productId: row.productId,
          productName: row.productName,
          salesAmount: row.orderRowTotalValueCurrency || 0,
          quantity: row.orderRowTotalQuantity || 0,
          numberOfOrders: 0
        });
      }
    }

    for (const data of this.mappedData) {
      data.numberOfOrders = productOrderIdMap[data.productId] || 0;
    }

    this.dataSource = new MatTableDataSource(this.mappedData);
  }
}
