import { Component, EventEmitter, Inject, Input, Optional, Output, Type } from '@angular/core';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { PriceComponent, PriceLevelsDisplayType } from '../price/price.component';
import { Product } from '../../models';
import { CustomerProductPrice } from '../../models/price';

@Component({
  selector: 'lib-price',
  template: `
    <ng-template
      [libCustomHostDynamic]="priceComponent"
      [inputs]="{
        price,
        type,
        display,
        displayOnlyPrice,
        currency,
        useStaffedPrice,
        displayPriceLevels,
        priceLevelsDisplayType,
        currentQty,
        product
      }"
      [outputs]="{
        staffedPrice: staffedPriceEmit.bind(this)
      }"
    ></ng-template>
  `,
  styles: []
})
export class PriceWrapperComponent {
  
  public priceComponent: Type<PriceComponent> = this.baseViewConfigService.getPriceComponent();

  @Input() price: CustomerProductPrice | string;

  @Input()
  type?: 'RRP' | 'NET' | 'GROSS' | 'STAFFED' | string;

  @Input()
  display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean;

  @Input()
  displayOnlyPrice?: boolean;

  @Input() currency: string;

  @Output()
  staffedPrice = new EventEmitter<boolean>();

  @Input()
  useStaffedPrice: boolean;

  @Input()
  displayPriceLevels: boolean = this.environment?.displayPriceLevels || false;

  // Controls the type of layout of the price levels
  @Input()
  priceLevelsDisplayType: string = PriceLevelsDisplayType.HIDDEN;

  // To display the current price level and staffed price it will use this quantity instead of the current quantity in cart if set
  @Input()
  currentQty: number = undefined;

  @Optional()
  @Input()
  product: Product;

  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    @Optional() @Inject('environment') protected environment
  ) {}

  staffedPriceEmit(event) {
    this.staffedPrice.emit(event);
  }
}
