import { Component, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models';
import { OrderStatistic, TopProductsStatistics } from '../../models/order-statistics';
import { ProductService } from '../../services/products/product.service';

@Component({
  selector: 'lib-top-product-grid',
  templateUrl: './top-product-grid.component.html',
  styleUrls: ['./top-product-grid.component.css']
})
export class TopProductGridComponent extends ListItemRendererComponent<OrderStatistic[]> implements OnInit, OnChanges, AfterViewInit {
  productsInfo: Product[] = [];

  constructor(protected productService: ProductService) {
    super();
  }

  ngOnInit() {
    this.updateData();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges() {
    this.updateData();
  }

  updateData() {}
}
