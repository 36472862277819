'use strict';

var $ = require('../internals/export');
var isRegisteredSymbol = require('../internals/symbol-is-registered');

// `Symbol.isRegisteredSymbol` method
// https://tc39.es/proposal-symbol-predicates/#sec-symbol-isregisteredsymbol
$({
  target: 'Symbol',
  stat: true
}, {
  isRegisteredSymbol: isRegisteredSymbol
});