import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ExportSelection,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { forkJoin, Observable, of } from 'rxjs';
import { PimGridViewComponent } from '../../components/pim/pim-grid-view/pim-grid-view.component';
import { first, tap, switchMap, mergeMap } from 'rxjs';
import { PimService } from './../pim/pim.service';
import { Pim } from '../../models/pim';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { AuthService } from '../auth/auth.service';

export class PimTypeFilter extends SimpleConfigBaseFilter<Pim> {
  constructor() {
    super();
  }

  getName(): string {
    return 'TYPE';
  }

  getOptionIds(item: Pim): string[] {
    return [item.type];
  }
  getOptionName(key: string): string {
    return key;
  }
}

export class PimImageCountFilter extends SimpleConfigBaseFilter<Pim> {
  constructor() {
    super();
  }

  getName(): string {
    return 'IMAGE_COUNT';
  }

  getOptionIds(item: Pim): string[] {
    const images = (item.images && item.images.filter(i => i.s3Uri && i.s3Uri.indexOf('no-image') === -1)) || [];
    return [images.length.toString()];
  }
  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PimListConfigService implements ConfigService<Pim> {
  constructor(
    protected pimService: PimService,
    protected gungModalService: GungModalService,
    protected authService: AuthService
  ) {}

  getFilters(): ConfigBaseFilter<Pim>[] {
    return [new PimTypeFilter(), new PimImageCountFilter()];
  }

  getSortOptions(): ListSortOption<Pim>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: Pim) {
    return item.id;
  }

  getSearchTerms(item: Pim) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<Pim>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PimGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimGrid'
      }
    ];
  }

  getItems(): Observable<Pim[]> {
    return this.pimService.getPimProducts().pipe(first());
  }

  getSelectionActions(): Observable<SelectionAction<Pim>[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => forkJoin([of(user), this.pimService.getPimDataTemplate().pipe(first())])),
      switchMap(([user, pimDataTemplate]) => {
        const actions: SelectionAction<Pim>[] = [];
        pimDataTemplate.forEach(template => {
          if (template.active) {
            const action = {
              label: template.name,
              performAction: (selection: ExportSelection<Pim>) => {
                const itemList = Object.values(selection.selectedItems);
                const ids = itemList.map(i => i.id);
                this.gungModalService.openConfirmExportModal(user.email || '').then(({ email }) => {
                  if (email && (email as string).trim().length > 0) {
                    this.pimService.generatePimDataTemplateWithProductsIds(ids, email, template.id).subscribe();
                  }
                });
                return of();
              }
            };
            actions.push(action);
          }
        });
        // actions.push({
        //   label: 'SYNC_TO_ERP',
        //   performAction: (selection: ExportSelection<Pim>) => {
        //     const itemList = Object.values(selection.selectedItems);
        //     const ids = itemList.map(i => i.id);
        //     this.pimService.syncToErp(ids).subscribe();
        //     return of();
        //   }
        // });
        return of(actions);
      })
    );
  }

  getSelectionActionsButtonTitle(): string {
    return 'ACTIONS';
  }
}
