import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PdfMatchingResponse, PDFTableArea } from '../../models/pdf-to-cart';

@Injectable({
  providedIn: 'root'
})
export class PdfReaderService {
  baseUrl = 'json/parse/pdf';

  constructor(protected http: HttpClient) {}
  submitOrderPdf(fileToSubmit: File): Observable<string> {
    const formData: FormData = new FormData();
    formData.append('file', fileToSubmit, fileToSubmit.name);
    return this.http.post(this.baseUrl + '/upload', formData, { responseType: 'text' });
  }

  parsePdfTable(
    s3Key: string,
    pageNumber: number,
    tableLocation: PDFTableArea,
    currentCustomerId: string
  ): Observable<PdfMatchingResponse> {
    const params = { s3Key, pageNumber, tableLocation, customerId: currentCustomerId };
    return this.http.post<PdfMatchingResponse>(this.baseUrl + '/table', params);
  }

  runMatchersWithManualIndices(alteredResponse: PdfMatchingResponse): Observable<PdfMatchingResponse> {
    return this.http.post<PdfMatchingResponse>(this.baseUrl + '/run-matchers', alteredResponse);
  }
}
