import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Type,
  ViewChildren,
  QueryList,
  ComponentFactory,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewContainerRef,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { ListItemRendererComponent, ListLayoutComponent } from '../types';
import { CustomHostDirective } from '../custom-host/custom-host.directive';

@Component({
  selector: 'lib-list-layout-single',
  templateUrl: './list-layout-single.component.html',
  styleUrls: ['./list-layout-single.component.css']
})
export class ListLayoutSingleComponent<T> extends ListLayoutComponent<T> implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  public renderItems: Observable<T[]>;

  @Input()
  public listItemRenderer: Type<ListItemRendererComponent<T | T[]>>;

  @ViewChildren(CustomHostDirective)
  private viewChildren: QueryList<CustomHostDirective>;

  private componentFactory: ComponentFactory<ListItemRendererComponent<T>>;

  private viewInitiated = false;

  public items: T[];
  private subscription: Subscription;

  private unsubscribe: Subject<void> = new Subject<void>();
  private count: number = 0;

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super();
  }
  ngOnInit() {
    this.subscription = this.renderItems.subscribe(ps => {
      this.items = ps;
      if (!!this.viewInitiated) {
        this.renderItemComponents();
      }
    });
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.listItemRenderer as Type<ListItemRendererComponent<T>>
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    this.count = 0;
    const children = this.viewChildren.toArray();
    if (!this.items || this.items.length === 0) {
      return;
    }
    for (let i = 0; i < this.items.length; i++) {
      this.renderItemComponent(this.items[i], children[i].viewContainerRef);
    }
    this.changeDetectorRef.detectChanges();
  }

  renderItemComponent(item: T, containerRef: ViewContainerRef) {
    containerRef.clear();
    const componentRef = containerRef.createComponent(this.componentFactory);
    const typed = componentRef.instance as ListItemRendererComponent<T>;
    typed.renderFinished = new EventEmitter<void>();
    typed.renderFinished.pipe(takeUntil(this.unsubscribe)).subscribe(() => {
      this.count++;
      if (this.items.length === this.count) {
        this.renderFinished?.emit();
      }
    });
    typed.data = item;
  }

  ngAfterViewInit(): void {
    this.viewInitiated = true;
    this.renderItemComponents();
  }
}
