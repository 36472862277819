import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class CartKeyService {
  constructor(private selectedCustomerService: SelectedCustomerService, private gungFlowService: GungFlowService) {}

  getCurrentCartKey(): Observable<string> {
    return forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      first(),
      filter(([customer, gungFlow]) => !!customer && !!gungFlow),
      map(([customer, gungFlow]) => {
        return `cart_${customer.id}_${gungFlow.id}`;
      })
    );
  }
}
