<div class="in-cart-container">
  <div class="row mt-2" *ngIf="productsInCart && productsInCart.length > 0 && !showProductDetails">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table">
          <tbody>
            <tr *ngFor="let productInCart of productsInCart">
              <td>
                <table class="table inside-table">
                  <tr>
                    <td
                      rowspan="2"
                      (click)="
                        seeDetails(
                          productInCart.productId,
                          productInCart.extra.product.extra.availabilities[stockIds[0]]
                        )
                      "
                      class="cursor-pointer"
                    >
                      <div class="p-id">
                        {{ productInCart.productId }}
                      </div>
                      <div class="image-p-cart">
                        <img
                          *ngIf="
                            productInCart.extra &&
                              productInCart.extra.product &&
                              productInCart.extra.product.extra.images &&
                              productInCart.extra.product.extra.images[0] as image;
                            else noimage
                          "
                          class=""
                          [src]="image.s3Uri
                            | gungImageUrl
                              :'type:thumbnail'
                              :'etag:'+image.s3ETag
                              :'background_color:f8f8f8'
                          "
                          alt=""
                        />
                        <ng-template #noimage>
                          <img
                            class="card-img-top img-fluid w-100"
                            [src]="'images/no-image.jpg' | gungImageUrl:'type:thumbnail'"
                            alt="NO IMAGE"
                            class="mx-auto d-block"
                          />
                        </ng-template>
                      </div>
                    </td>
                    <td>
                      <div
                        class="p-name cursor-pointer"
                        (click)="
                          seeDetails(
                            productInCart.productId,
                            productInCart.extra.product.extra.availabilities[stockIds[0]]
                          )
                        "
                      >
                        <span
                          *ngIf="
                            productInCart.extra.product &&
                              productInCart.extra.product.extra &&
                              productInCart.extra.product.extra.i18n &&
                              productInCart.extra.product.extra.i18n[currentLang] &&
                              productInCart.extra.product.extra.i18n[currentLang].name;
                            else defaultName
                          "
                        >
                          {{ productInCart.extra.product.extra.i18n[currentLang].name }}
                        </span>
                        <ng-template #defaultName>{{ productInCart.extra.product.name }}</ng-template>
                      </div>
                      <div
                        *ngIf="
                          productInCart.extra.product &&
                          productInCart.extra.product.extra &&
                          productInCart.extra.product.extra.i18n &&
                          productInCart.extra.product.extra.i18n[currentLang] &&
                          productInCart.extra.product.extra.i18n[currentLang].color
                        "
                      >
                        {{ productInCart.extra.product.extra.i18n[currentLang].color }}
                      </div>
                    </td>
                    <td class="text-right">
                      <div class="p-price" *ngIf="productInCart.extra.price">
                        <lib-price [price]="productInCart.extra.price"></lib-price>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" class="text-right">
                      <div class="float-right pl-3">
                        <i class="fas fa-times click-icon" (click)="removeProduct(productInCart)"></i>
                      </div>

                      <div class="float-right buy-btn-td">
                        <lib-buy-btn [id]="productInCart.productId" [product]="productInCart.extra.product"></lib-buy-btn>
                      </div>
                      <div
                        class="float-right px-3"
                        *ngIf="productInCart.extra &&
                          productInCart.extra.product &&
                          productInCart.extra.product.extra.availabilities &&
                          productInCart.extra.product.extra.availabilities[stockIds[0]]
                        "
                      >
                        <lib-availability
                          [isSales]="true"
                          [availability]="productInCart.extra.product.extra.availabilities[stockIds[0]]"
                        >
                        </lib-availability>
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 order-md-2"></div>
    <!-- <div class="col-12 col-md-3" *ngIf="!fullscreen">
            <button type="button" class="btn btn-primary w-100 btn-sm mt-1" [routerLink]="['/checkout']" routerLinkActive="router-link-active" ><span translate >CHECKOUT</span></button>
            <button type="button" class="btn btn-outline-primary btn-sm w-100 mt-2" (click)="clearCart()"><span translate >CLEAR_CART</span></button>
        </div> -->
  </div>
  <div *ngIf="showProductDetails" class="product-detail">
    <div class="close-detail" (click)="showProductDetails = !showProductDetails"><i class="fas fa-times"></i></div>
    <!-- <lib-barcode-product-details [product]="productDetails" [availability]="availabilityDetails">
        </lib-barcode-product-details> -->
    <ng-template
      [libCustomHostDynamic]="barcodeProductDetailsComponent"
      [inputs]="{
        product: productDetails,
        availability: availabilityDetails
      }"
      [outputs]="{}"
    >
    </ng-template>
  </div>
</div>
<div class="barcode-footer">
  <div class="total-cart" *ngIf="cartContents">
    <div class="row" (click)="totalInfo = !totalInfo" *ngIf="!totalInfo">
      <div class="col" translate>
        <span class="mr-2"
          ><strong><i class="fas fa-angle-up"></i></strong
        ></span>
        <span translate>TOTAL</span>
      </div>
      <div class="col text-right">
        <lib-price [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price>
      </div>
    </div>
    <div class="row" *ngIf="totalInfo" (click)="totalInfo = !totalInfo">
      <div class="col-12" translate>
        <span class="mr-2"
          ><strong><i class="fas fa-angle-down"></i></strong
        ></span>
        <span translate>TOTAL</span>
      </div>
      <div class="col-12">
        <div class="pt-3" translate>
          <strong translate>AMOUNT</strong>
        </div>
        <div class="pt-3">
          <span translate>AMOUNT_TOTAL</span>:
          <lib-price [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price> <br />
          <span translate>SHIPPING_COST</span>: According to agreement
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex">
    <button type="button" class="btn btn-primary border-divide" (click)="saveCart()">
      <i class="far fa-save"></i>
    </button>
    <button type="button" class="btn btn-outline-primary" (click)="clearCart()"><i class="far fa-eraser"></i></button>
    <button type="button" class="btn btn-primary" (click)="quickCheckout()">
      <i class="far fa-shopping-cart"></i>
    </button>
  </div>
</div>
