import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { parse, isValid } from 'date-fns';
import { first, switchMap } from 'rxjs';
import { Availability } from './../../../../models/availability';
import { ProductMatrixModalService } from './../../../../services/product-matrix-modal/product-matrix-modal.service';
import { AvailabilityService } from './../../../../services/availability/availability.service';
import { AuthService } from './../../../../services/auth/auth.service';
import { DateUtilService } from 'gung-common';
import { BackendInterceptor } from '../../../../services/backend-interceptor/backend-interceptor.service';

@Component({
  selector: 'lib-product-matrix-element-availability-modal',
  templateUrl: './product-matrix-element-availability-modal.component.html',
  styleUrls: ['./product-matrix-element-availability-modal.component.css']
})
export class ProductMatrixElementAvailabilityModalComponent implements OnInit {
  availability: Availability;
  productId: string;
  delegate: ProductMatrixModalService;
  restrictedView: boolean;
  breakDowns: { value: any; date: Date }[] = [];

  showAvTransactionsInModal = this.backendInterceptor.getEnvironmentProperty('showAvailabilitiesTransactions') || false;
  constructor(
    protected translateService: TranslateService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    public dateUtilService: DateUtilService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  ngOnInit() {
    if (!this.availability) {
      this.authService
        .getCurrentUser()
        .pipe(
          first(),
          switchMap(user =>
            this.availabilityService.getAvailability(this.productId, user.managedMultistockIds[0], true).pipe(first())
          )
        )
        .subscribe(availability => {
          this.availability = availability;
          this.setBreakDowns();
        });
    } else {
      this.setBreakDowns();
    }
  }

  protected setBreakDowns(): void {
    Object.keys(this.availability.extra.availabilities)
      .filter(key => !!key)
      .forEach(key => {
        const breakDownDate = parse(key, 'yyMMdd', new Date());

        if (isValid(breakDownDate)) {
          let breakDownValue = '';
          if (parseInt(this.availability.extra.availabilities[key], 10) === 0) {
            breakDownValue = this.translateService.instant('NO_AVAILABILITY');
          } else {
            breakDownValue = this.availability.extra.availabilities[key] + ' ' + this.translateService.instant('PCS');
          }

          this.breakDowns.push({ value: breakDownValue, date: breakDownDate });
        }
      });
  }

  close() {
    this.delegate.modalRef.get(ProductMatrixElementAvailabilityModalComponent.name).close('Closed');
  }
}
