<div class="row" *ngIf="pimProduct">
  <div class="col-auto font-weight-bolder text-truncate" style="font-size: larger">
    <p>{{ pimProduct.name }} - {{ pimProduct.id }}</p>
  </div>
  <div class="col d-flex justify-content-end">
    <button type="button" class="btn btn-primary" (click)="onSave()" [disabled]="isLoading ? true : null" translate>
      SAVE<i *ngIf="isLoading" class="fas fa-spinner fa-spin"></i>
    </button>
    <button
      type="button"
      class="btn btn-danger ml-1"
      [disabled]="isLoading ? true : null"
      (click)="onDelete()"
      translate
    >
      DELETE
    </button>
  </div>
  <div class="col-12 mt-4">
    <input type="checkbox" [(ngModel)]="pimProduct.erpSyncEnabled" />
    <span class="ml-2" translate>SYNC_WITH_ERP</span>
  </div>
</div>

<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink translate>BASIC_INFO</a>
    <ng-template ngbNavContent>
      <lib-pim-product-basic-info [pimProduct]="pimProduct"></lib-pim-product-basic-info>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem *ngIf="enableShopify">
    <a ngbNavLink translate>SHOPIFY</a>
    <ng-template ngbNavContent>
      <lib-pim-product-shopify [pimProduct]="pimProduct" [languages]="shopifyLanguages"></lib-pim-product-shopify>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>ATTRIBUTES</a>
    <ng-template ngbNavContent>
      <lib-pim-product-attributes [pimProduct]="pimProduct"></lib-pim-product-attributes>
    </ng-template>
  </ng-container>
  <ng-container [ngbNavItem]>
    <a ngbNavLink translate>IMAGES</a>
    <ng-template ngbNavContent>
      <lib-pim-product-images [pimProduct]="pimProduct"></lib-pim-product-images>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>FILES</a>
    <ng-template ngbNavContent>
      <lib-pim-product-documents [pimProduct]="pimProduct"></lib-pim-product-documents>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>RELATED_PRODUCTS</a>
    <ng-template ngbNavContent>
      <lib-pim-product-related-products
        [pimProduct]="pimProduct"
        [pimProducts]="pimProducts"
        [pimConcepts]="pimConcepts"
      >
      </lib-pim-product-related-products>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>VARIANTS</a>
    <ng-template ngbNavContent>
      <lib-pim-product-variants [pimProductVariants]="pimProductVariants"></lib-pim-product-variants>
    </ng-template>
  </ng-container>
</nav>

<div *ngIf="pimProduct; else loading" [ngbNavOutlet]="nav" class="mt-2"></div>
<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
