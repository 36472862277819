<div ngbDropdown *ngIf="availableFlows && availableFlows.length > 1">
  <button class="btn btn-secondary" ngbDropdownToggle [disabled]="disabled">
    <span translate>FLOW</span>: <span class="mr-1">{{ selectedFlow.name | translate }}</span>
  </button>
  <div ngbDropdownMenu>
    <button *ngFor="let flow of availableFlows" class="btn" ngbDropdownItem (click)="selectFlow(flow.id)">
      <span>{{ flow.name | translate }}</span>
    </button>
  </div>
</div>
