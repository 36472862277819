import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdiGungMessages } from '../../../models/edi';

@Component({
  selector: 'lib-edi-despatch-advice-modal',
  templateUrl: './edi-despatch-advice-modal.component.html',
  styleUrls: ['./edi-despatch-advice-modal.component.css']
})
export class EdiDespatchAdviceModalComponent {
  @Input() data: EdiGungMessages;

  constructor(public activeModal: NgbActiveModal) {}

  onSend() {
    this.activeModal.close(JSON.stringify(this.data));
  }
}
