import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-items-changes-update-modal',
  templateUrl: './items-changes-update-modal.component.html',
  styleUrls: ['./items-changes-update-modal.component.css']
})
export class ItemsChangesUpdateModalComponent implements OnInit {
  itemListForm: FormGroup;
  componentId: string;

  columnCount: number = 0;

  productIds: string = '';

  get changeItemList() {
    return this.itemListForm.get('itemList') as FormArray;
  }

  selectedItemIdList: string[];

  constructor (
    public activeModal: NgbActiveModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    const idFieldForm = this.formBuilder.group({
      id: '' ,
      skipValidation: false,
      cancelled: false
    });

    this.itemListForm = this.formBuilder.group({
      itemList: this.formBuilder.array([])
    });

    this.columnCount = 1;
  }

  addId() {
    const idFieldForm = this.formBuilder.group({
      id: '',
      skipValidation: false,
      cancelled: false
    });

    this.columnCount = this.columnCount + 1;
    this.changeItemList.push(idFieldForm);
  }

  close() {
    this.activeModal.dismiss('Closed');
  }

  submitData(){
    let idFromData = this.itemListForm.value;
    const changItemList = idFromData.itemList.filter(item => (item.id));
    this.activeModal.close({ changItemList: changItemList });
  }

  remove(index: number){
    this.changeItemList.removeAt(index)
    this.columnCount = this.columnCount -1;
  }

  copyToList(){
    const ids = this.productIds.split("\n");
    ids.forEach(id=>{
      const idFieldForm = this.formBuilder.group({
        id: id,
        skipValidation: false,
        cancelled: false
      });

      this.columnCount = this.columnCount + 1;
      this.changeItemList.push(idFieldForm);
    })
  }

  selectAllSkipItem(event){
    this.changeItemList.controls?.forEach(control=>{
      control.get('skipValidation').setValue(event.target.checked)
    });
  }

  selectAllCancelledItem(event){
    this.changeItemList.controls?.forEach(control=>{
      control.get('cancelled').setValue(event.target.checked)
    });
  }
}
