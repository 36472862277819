import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { defer, forkJoin, Observable, of } from 'rxjs';
import { catchError, first, switchMap, tap } from 'rxjs';
import { PimService } from '../../../../services/pim/pim.service';
import { Pim } from '../../../../models/pim';
import { PimUploadModalComponent } from '../../pim-upload-modal/pim-upload-modal.component';
import { PimProductImagesConfigService } from '../../../../services/pim/pim-product-images-config.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'lib-pim-product-images',
  templateUrl: './pim-product-images.component.html',
  styleUrls: ['./pim-product-images.component.css']
})
export class PimProductImagesComponent implements OnInit {
  showInOptions: string[];
  showInButtons: boolean;

  @Input()
  pimProduct: Pim;

  modalOptions: NgbModalOptions = {
    backdrop: true || 'static',
    size: 'lg',
    keyboard: true
  };

  modalRef: NgbModalRef;
  extraOptions: { [key: string]: string[] };
  categoryOptions: string[];

  constructor(
    protected modalService: NgbModal,
    protected pimService: PimService,
    protected config: PimProductImagesConfigService
  ) {}

  ngOnInit() {
    this.showInButtons = this.config.showInSegments;
    this.showInOptions = this.config.imageSegments;

    this.config
      .getExtraOptions(this.pimProduct)
      .pipe(first())
      .subscribe(extraOptions => {
        this.extraOptions = extraOptions;
      });
    this.config
      .getCategoryOptions(this.pimProduct)
      .pipe(first())
      .subscribe(categoryOptions => {
        this.categoryOptions = categoryOptions;
      });
  }

  toggleOption(image: any, key: string, value: string) {
    image.extra = image.extra || {};
    if (image.extra[key] === value) {
      delete image.extra[key];
    } else {
      image.extra[key] = value;
    }
  }
  openModalUploadImages() {
    this.modalRef = this.modalService.open(PimUploadModalComponent, this.modalOptions);
    this.modalRef.componentInstance.uploadType = 'image';
    this.modalRef.componentInstance.fnUpload = (
      files: File[],
      uploadedFiles: { total: number; completed: number; failed: number; initialized: number; error: [] },
      updateProgressBar
    ) => this.uploadImage(files, uploadedFiles, updateProgressBar);
    this.modalRef.result.then(_ => {});
  }

  toggleArray(ele: any, arr: any[]) {
    const idx = arr.findIndex(a => a === ele);
    if (idx !== -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(ele);
    }
  }

  moveElement(idx, arr: any[], pos: string) {
    const ele = arr.splice(idx, 1)[0];
    switch (pos) {
      case 'up':
        arr.splice(idx - 1, 0, ele);
        break;
      case 'down':
        arr.splice(idx + 1, 0, ele);
        break;
      case 'primary':
        arr.splice(0, 0, ele);
        break;
    }
  }

  protected uploadImage(
    uploadFiles,
    uploadedFiles: { total: number; completed: number; failed: number; initialized: number; error: [] },
    updateProgressBar
  ): Observable<boolean> {
    const productImage = {
      index: 0,
      description: '',
      category: 'Product Image',
      s3Uri: 'images/no-image.jpg',
      pimDimensionIds: []
    };
    const files: File[] = uploadFiles;
    const listHttpPost = [];
    let imgCount = this.pimProduct.images.length;

    for (const file of files) {
      uploadedFiles.total++;
      const formData = new FormData();
      formData.append('productImage', JSON.stringify(productImage));
      formData.append('file', file);
      // listHttpPost.push(this.pimService.postPimImage(this.pimProduct.id, ++imgCount, formData).pipe(catchError(error => of({ isError: true, error }))));
      listHttpPost.push(
        defer(() => {
          uploadedFiles.initialized++;
          updateProgressBar();
          return this.pimService.postPimImage(this.pimProduct.id, ++imgCount, formData).pipe(
            tap(data => {
              const image: any = data;
              if (image.s3Uri) {
                uploadedFiles.completed += 1;
              } else {
                uploadedFiles.failed += 1;
              }
              updateProgressBar();
            }),
            catchError(error => of({ isError: true, error }))
          );
        })
      );
    }
    return forkJoin(listHttpPost).pipe(
      switchMap(data => {
        data.forEach((ele: any, idx) => {
          if (!ele.isError) {
            this.pimProduct.images.push(ele);
          }
        });
        return of(true);
      })
    );
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.pimProduct.images, event.previousIndex, event.currentIndex);
  }
}
