import { Component, OnInit, OnDestroy } from '@angular/core';
import { CartTotalsService } from '../../services/cart-totals/cart-totals.service';
import { Subscription } from 'rxjs';
import { TotalObject } from '../../models/cart';

@Component({
  selector: 'lib-cart-summary',
  templateUrl: './cart-summary.component.html',
  styleUrls: ['./cart-summary.component.css']
})
export class CartSummaryComponent implements OnInit, OnDestroy {
  totals: TotalObject = { totalQuantity: 0, totalValue: 0, totalRows: 0 };
  protected cartTotalsSubscription: Subscription;

  constructor(protected cartTotalsService: CartTotalsService) {}

  ngOnInit() {
    this.cartTotalsSubscription = this.cartTotalsService.getTotals().subscribe(totalsObject => {
      this.totals = totalsObject;
      return totalsObject;
    });
  }

  ngOnDestroy() {
    if (!!this.cartTotalsSubscription) {
      this.cartTotalsSubscription.unsubscribe();
    }
  }
}
