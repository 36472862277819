import { Component, OnInit } from '@angular/core';
import { CustomerContactListConfigService } from '../../services/customer-contact-list-config/customer-contact-list-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-customer-contact-list',
  template: `<lib-filter-list [configService]="customerContactListConfigService"></lib-filter-list>`,
  styleUrls: ['./customer-contact-list.component.css']
})
export class CustomerContactListComponent {
  constructor(public customerContactListConfigService: CustomerContactListConfigService, route: ActivatedRoute) {
    customerContactListConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
