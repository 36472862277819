import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { gungJsonValidator, gungParseJsonToFortmatedString } from 'gung-common';
import { GungError } from '../../../models/gung-error';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { GungShopifyConfig, ShopifyService } from '../../../services/shopify/shopify.service';

@Component({
  selector: 'lib-actuator-shopify',
  templateUrl: './actuator-shopify.component.html',
  styleUrls: ['./actuator-shopify.component.css']
})
export class ActuatorShopifyComponent implements OnInit {
  uploading = false;
  showAll = false;
  listShopifyConfig: GungShopifyConfig[];
  allShopify: GungShopifyConfig[];
  activeShopify: GungShopifyConfig[];
  configEditor: string;

  active: string;

  constructor(
    protected shopifyService: ShopifyService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.shopifyService.getAllShopifyConfig().subscribe(data => {
      this.allShopify = data;
      this.changeList(this.active);
    });
    this.shopifyService.getActiveShopifyConfig().subscribe(data => {
      this.activeShopify = data;
      this.changeList(this.active);
    });
  }

  save() {
    const result = this.gungJsonValidator(this.configEditor);
    if (result.error) {
      var err = new Error(result.error);
      const error: GungError = {
        message: err.message,
        name: err.message,
        i18nKeyMessage: err.message
      };
      this.gungModalService.openGungErrorHandler(error);
      return;
    }
    this.shopifyService.putShopifyConfig(result.json).subscribe(res => {
      // this.changeEditor(res.id);
    });
  }

  gungJsonValidator(obj) {
    return gungJsonValidator(obj);
  }

  gungParseJsonToFortmatedString(obj) {
    return gungParseJsonToFortmatedString(obj);
  }

  removeConfig(id) {
    this.gungModalService
      .openConfirmYesNoModal('REMOVE', this.translateService.instant('CONFIRM_DELETION', { value: id }))
      .then(result => {
        if (result) {
          this.shopifyService.deleteShopifyConfig(id).subscribe(data => {
            const idx = this.listShopifyConfig.findIndex(shopify => shopify.id === id);
            this.listShopifyConfig.splice(idx, 1);
            this.changeList();
          });
        }
      });
  }

  add() {
    const newConfig: GungShopifyConfig = {
      id: 'gung-' + new Date().getTime(),
      extra: {},
      name: ''
    };
    this.listShopifyConfig.push(newConfig);
    // this.changeEditor(newConfig.id);
  }

  changeList(active?: string) {
    this.listShopifyConfig = this.showAll ? this.allShopify : this.activeShopify;
    if (this.listShopifyConfig?.length > 0) {
      this.active = active || this.listShopifyConfig[0].id;
      this.changeEditor(this.active);
    }
  }

  changeEditor(id) {
    const shopifyConfig = this.listShopifyConfig.find(shopify => shopify.id === id);
    this.configEditor = this.gungParseJsonToFortmatedString(shopifyConfig);
  }

  updateObject(json: string, id: string) {
    // const res = this.gungJsonValidator(json);
    // if (res.json) {
    //   const idx = this.listShopifyConfig.findIndex(shopify => shopify.id === this.active);
    //   this.listShopifyConfig.splice(idx, 1, res.json);
    // }
  }

  submitImageFiles(files: FileList) {
    if (files.length === 0) {
      return;
    }
    let uploadCount = 0;
    this.uploading = true;
    for (let i = 0; i < files.length; i++) {
      const fileToUpload = files.item(i);
      this.shopifyService.uploadImageToShopify(fileToUpload).subscribe(res => {
        uploadCount++;
        if (uploadCount === files.length) {
          this.uploading = false;
        }
      });
    }
  }
}
