<lib-gung-accordion #acc="gungAccordion">
  @for (cart of data; track cart.id;let i = $index) {
    <lib-gung-accordion-item [id]="cart.id" [customHeader]="true">
      <ng-template libGungAccordionHeader>
        <div class="cart-header">
          <div class="d-flex flex-direction-row align-items-center col-12">
            <button class="btn btn-link text-dark col-4">
              {{ cart.name }} - {{ dateUtilService.parseDate(cart.creationDate) | date: dateUtilService.dateFormat }}
            </button>
  
            <button
              class="btn btn-link ml-auto col-2"
              (click)="donwloadQuotePDF(cart);"
              translate
            >
              DOWNLOAD_QUOTE_PDF
            </button>
  
            <button              
              class="btn btn-link col-2"
              (click)="loadCart(cart);"
              translate
            >
              LOAD_QUOTE
            </button>
  
            <button  class="btn btn-link col-2" (click)="showSavedCart(cart);fetchProductData(cart)" translate>
              SHOW_DETAILS
            </button>
  
            <button
              
              class="btn btn-link col-2"
              (click)="deleteCart(cart);"
            >
              <i class="fas fa-trash-alt"></i>
            </button>
          </div>
        </div>
      </ng-template>
      <ng-template libGungAccordionBody>
        <div class="row pb-3 pl-1 pr-1" *ngIf="cart.extra.description">
          <div class="col-12">
            <span class="font-weight-bold" translate>DESCRIPTION</span>
          </div>
          <div class="col-12">
            {{ cart.extra.description }}
          </div>
        </div>
  
        <div class="table-responsive" *ngIf="mapData[cart.id]; else loading">
          <table class="table table-sm table-borderless table-striped">
            <thead>
              <tr>
                <th><span translate>ARTICLE_NUMBER</span></th>
                <th><span translate>NAME</span></th>
                <th><span translate>QTY</span></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of mapData[cart.id]">
                <td>
                  <a [routerLink]="['/product/', row.product.id]">
                    {{ row.product.id }}
                  </a>
                </td>
                <td>
                  {{ row.product.name }}
                </td>
                <td>
                  {{ row.qty }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ng-template>
    </lib-gung-accordion-item>     
  }   
</lib-gung-accordion>


<ng-template #loading>
  <div class="row">
    <div class="col-12 m-2 p-2 text-center">
      <i class="fa fa-spinner fa-spin-lg"></i>
    </div>
  </div>
</ng-template>
