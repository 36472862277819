import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  QueryList,
  Type,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomHostDirective } from 'gung-list';
import { LoginConfigService } from '../../../services/login-config/login-config.service';

@Component({
  selector: 'lib-login-wrapper',
  templateUrl: './login-wrapper.component.html',
  styleUrls: ['./login-wrapper.component.scss']
})
export class LoginWrapperComponent implements OnInit, AfterViewInit {
  @ViewChildren(CustomHostDirective)
  viewChild: QueryList<CustomHostDirective>;

  loginType: string;
  showNavbar: boolean;

  containerStyles: { [styleName: string]: any } = { ['margin-top']: '52px' };

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    protected loginConfigService: LoginConfigService
  ) {
    this.loginType = route.snapshot.data.login;
    if (this.loginType === 'NavbarLogin') {
      this.showNavbar = true;
    }
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (this.viewChild && this.viewChild.length !== 0) {
      // Clear views already rendered from previous navigation
      const containerRef = this.viewChild.last.viewContainerRef;
      containerRef.clear();
    }

    this.renderLayout(this.loginConfigService.getLoginComponent(this.loginType));
  }

  renderLayout(layoutComponent: Type<any>): void {
    if (!this.viewChild || this.viewChild.length === 0) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    containerRef.createComponent(factory);
    this.changeDetectorRef.detectChanges();
  }

  setContainerMarginTop(margin: number) {
    this.containerStyles['margin-top'] = margin + 'px';
  }
}
