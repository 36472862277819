import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, catchError, map, Observable, of, ReplaySubject, Subject, switchMap, tap } from 'rxjs';
import { GungBase } from '../../models';

export interface GungShopifyConfig extends GungBase {
  [s: string]: any;
}

@Injectable({
  providedIn: 'root'
})
export class ShopifyService {
  baseUrl = 'json/shopify/configurations';
  protected cachedAll: BehaviorSubject<GungShopifyConfig[]>;
  protected cachedActive: BehaviorSubject<GungShopifyConfig[]>;

  constructor(protected http: HttpClient) {}

  getAllShopifyConfig(noCache?: boolean): Observable<GungShopifyConfig[]> {
    if (noCache || !this.cachedAll) {
      return this.internalGetAllShopifyConfig(noCache);
    }
    return this.cachedAll.asObservable();
  }

  private internalGetAllShopifyConfig(noCache?: boolean): Observable<GungShopifyConfig[]> {
    const url = `${this.baseUrl}/all`;

    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    return this.http.get<GungShopifyConfig[]>(url, { headers }).pipe(
      // map(data => data ?? []), // Sometimes the endpoint return null
      catchError((error: any) => {
        console.error(`${url}`, error);
        return of([]);
      }),
      switchMap(data => {
        if (!this.cachedAll) {
          this.cachedAll = new BehaviorSubject<GungShopifyConfig[]>(data);
        } else {
          this.cachedAll.next(data);
        }
        return this.cachedAll;
      })
    );
  }

  getActiveShopifyConfig(noCache?: boolean): Observable<GungShopifyConfig[]> {
    if (!this.cachedActive || noCache) {
      return this.internalGetActiveShopifyConfig(noCache);
    }

    return this.cachedActive.asObservable();
  }

  private internalGetActiveShopifyConfig(noCache?: boolean): Observable<GungShopifyConfig[]> {
    const url = `${this.baseUrl}/active`;

    const headers: { [key: string]: string | string[] } = {};
    if (noCache) {
      headers.maxAge = '-1';
    }

    return this.http.get<GungShopifyConfig[]>(url, { headers }).pipe(
      // map(data => data ?? []), // Sometimes the endpoint return null
      catchError((error: any) => {
        console.error(`${url}`, error);
        return of([]);
      }),
      switchMap(data => {
        if (!this.cachedActive) {
          this.cachedActive = new BehaviorSubject<GungShopifyConfig[]>(data);
        } else {
          this.cachedActive.next(data);
        }
        return this.cachedActive;
      })
    );
  }

  postShopifyConfig(shopifyConfig: GungShopifyConfig): Observable<GungShopifyConfig> {
    const url = `${this.baseUrl}`;
    return this.http.post<GungShopifyConfig>(url, shopifyConfig).pipe(
      tap(_ => {
        this.internalGetActiveShopifyConfig(true).subscribe(_ => {});
        this.internalGetAllShopifyConfig(true).subscribe(_ => {});
      })
    );
  }

  putShopifyConfig(shopifyConfig: GungShopifyConfig): Observable<GungShopifyConfig> {
    const url = `${this.baseUrl}/${shopifyConfig.id}`;
    return this.http.put<GungShopifyConfig>(url, shopifyConfig).pipe(
      tap(_ => {
        this.internalGetActiveShopifyConfig(true).subscribe(_ => {});
        this.internalGetAllShopifyConfig(true).subscribe(_ => {});
      })
    );
  }

  deleteShopifyConfig(id: string): Observable<GungShopifyConfig> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<GungShopifyConfig>(url).pipe(
      tap(_ => {
        this.internalGetActiveShopifyConfig(true).subscribe(_ => {});
        this.internalGetAllShopifyConfig(true).subscribe(_ => {});
      })
    );
  }

  uploadImageToShopify(images: File): Observable<any> {
    const url = 'json/shopify/upload-image';

    const formData = new FormData();
    formData.append('config', this.cachedActive.value[0].id);
    formData.append('file', images);
    formData.append('fileName', images.name);

    return this.http.post<any>(url, formData);
  }

  /*
   

    @RequestMapping(method = RequestMethod.DELETE, value = "/{id}")
    @PreAuthorize("hasAnyRole('ADMIN')")
    public void delete(@PathVariable String id, Principal principal) {
        boolean canUpdate = isCanUpdate(principal);
        if (!canUpdate) return;

        ShopifyConfiguration shopifyConfiguration = shopifyConfigurationRepository.findById(id).orElse(null);
        if (shopifyConfiguration == null) {
            return;
        }
        shopifyConfigurationRepository.deleteById(id);
    }
  */
}
