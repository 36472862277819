import { Injectable, isDevMode } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GungLoggerService {

  newWindow: Window;
  showLogs = false;

  constructor(
  ) {
    if (this.showLogs) {
      this.initWindow();
    }
  }

  initWindow() {
    if (this.showLogs && !this.newWindow) {
      this.newWindow = printConsoleLog('INIT new window');
    } else if (this.newWindow && !this.showLogs) {
      this.newWindow.close();
      this.newWindow = undefined;
    }
  }

  public setShowLogs(showLogs: boolean) {
    if (isDevMode()) {
      return;
    }
    // this.showLogs = (showLogs && !this.showLogs) ? confirm("Enable GUNG LOGGER?") : showLogs;
    this.showLogs = showLogs;
    this.initWindow();
  }

  public printConsoleLog(message: any, object?: any) {
    if (!this.showLogs) {
      return;
    }
    if (object) {
      this.newWindow = printConsoleLog(object, this.newWindow, false);
    }
    this.newWindow = printConsoleLog(message, this.newWindow);
    
  }
}

/**
 * Print log to a new side window
 * @param newWindow 
 */
 export function printConsoleLog(message: any, newWindow?: Window, printDateBefore = true): Window {
  if (!newWindow) {
    newWindow = window.open("");
    newWindow.document.body.insertAdjacentHTML('beforeend',
      '<button onClick="document.getElementById(\'log\').innerHTML=\'\'" style="float: right;">CLEAR</button><pre id="log"></pre>'
    );
  }
  const logger = newWindow?.document.getElementById('log');
  if (logger) {
    if (!message) {
      logger.insertAdjacentHTML('afterbegin', 'No Message \n');
      return newWindow;
    }
    const messageCopy = JSON.parse(JSON.stringify(message));
    console.info('printConsoleLog', messageCopy);
    if (typeof messageCopy === 'string') {
      logger.insertAdjacentHTML('afterbegin', '' + messageCopy + '\n');
    }
    if (typeof messageCopy === 'object') {
      // logger.insertAdjacentHTML('afterbegin', '' + (JSON && JSON.stringify ? JSON.stringify(message) : message) + '\n');
      logger.insertAdjacentHTML('afterbegin', '<pre>' + stringify_object(messageCopy) + '<pre>\n');
    }
    if (printDateBefore) {
      logger.insertAdjacentHTML('afterbegin', '' + (new Date()) + '\n');
    }
  }
  return newWindow;
  
}

export function stringify_object(object, depth=0, max_depth=2) {
  // change max_depth to see more levels, for a touch event, 2 is good
  if (depth > max_depth)
    return 'Object';
  if (!object) {
    return 'No Object';
  }
  const obj = {};
  for (let key in object) {
    let value;
    try {
      value = object?.[key];
    } catch (e) {
      this.newWindow = printConsoleLog(e, this.newWindow, false);
      this.newWindow = printConsoleLog('GungLogger - tryCatch - object.[key]', this.newWindow);
      continue;
    }
    if (value instanceof Node)
      // specify which properties you want to see from the node
      // value = {id: value.id};
      value = {id: (value as any).id, nodeName: value.nodeName};
    else if (value instanceof Window)
      value = 'Window';
    else if (value instanceof Object)
      value = stringify_object(value, depth+1, max_depth);

    obj[key] = value;
  }

  return depth? obj: JSON.stringify(obj, undefined, 2);
}