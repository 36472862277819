import { Injectable, Optional } from '@angular/core';
import { Observable, forkJoin, of, BehaviorSubject } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { CartService } from '../cart/cart.service';
import { TotalObject } from '../../models/cart';
import { CartRow, CartRowIdentification } from '../../state/cart/types';
import { PriceService } from '../price/price.service';
import { CartRowPrice } from '../../models/price';
import { GungGoogleTagManagerService } from '../google-tag-manager/gung-google-tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class CartTotalsService {
  devMode = localStorage.getItem('dev') || false;

  private totalsSubject = new BehaviorSubject<TotalObject>({
    totalQuantity: 0,
    totalRows: 0,
    totalValue: 0
  });

  private totalRowsSubject = new BehaviorSubject<CartRowTotal[]>([]);

  public totalRowsObservable = this.totalRowsSubject.asObservable();

  public totalsObservable = this.totalsSubject.asObservable();
  private initiated = false;

  private rowsInitiated = false;

  constructor(
    protected cartService: CartService,
    protected priceService: PriceService,
    @Optional() protected googleTagManagerService: GungGoogleTagManagerService
  ) {}

  init(): void {
    this.cartService
      .getCurrentCart()
      .pipe(
        switchMap(rows => forkJoin([of(rows), this.priceService.getCartRowPricesObservable(rows).pipe(first())])),
        switchMap(([rows, prices]) => this.mapData(rows, prices))
      )
      .subscribe(totals => {
        this.totalsSubject.next(totals);
      });
    if (this.googleTagManagerService) {
      this.googleTagManagerService.subscribeTocartTotals(this.totalsObservable);
    }
  }

  initTotalRows(): void {
    this.cartService
      .getCurrentCart()
      .pipe(
        switchMap(rows =>
          forkJoin({
            rows: of(rows),
            prices: this.priceService.getCartRowPricesObservable(rows).pipe(first())
          })
        ),
        switchMap(({ prices, rows }) =>
          forkJoin(
            rows.map(row =>
              this.mapRow(
                row,
                prices.find(
                  price => price.productId === row.productId && price.productPartialId === row.productPartialId
                )
              )
            )
          )
        )
      )
      .subscribe(data => this.totalRowsSubject.next(data));
  }

  mapRow(row: CartRow, price: CartRowPrice): Observable<CartRowTotal> {
    const data: CartRowTotal = {
      productId: row.productId,
      productPartialId: row.productPartialId,
      targetStockId: row.targetStockId,
      total: price.cartRowTotalPriceInclRowDiscount.value,
      pricePerUnit: price.cartRowUnitPriceInclRowDiscount.value,
      quantity: 0
    };
    return of(data);
  }

  mapData(rows: CartRow[], prices: CartRowPrice[]): Observable<TotalObject> {
    return of(
      rows.reduce(
        (prev, curr): TotalObject => ({
          totalRows: prev.totalRows + 1,
          totalQuantity: prev.totalQuantity + curr.qty,
          totalValue:
            prev.totalValue +
            prices.find(price => {
              if (!!curr.productPartialId) {
                return curr.productId === price.productId && curr.productPartialId === price.productPartialId;
              }
              return curr.productId === price.productId;
            }).cartRowTotalPriceInclRowDiscount.value,
          totalCurrency: prices.find(price => curr.productId === price.productId).customerNetPrice.currencyCode,
          extra: {}
        }),
        {
          totalRows: 0,
          totalQuantity: 0,
          totalValue: 0,
          totalCurrency: '',
          extra: {}
        }
      )
    );
  }

  getRowTotals(): Observable<CartRowTotal[]> {
    if (!this.rowsInitiated) {
      this.initTotalRows();
      this.rowsInitiated = true;
    }
    return this.totalRowsObservable;
  }

  getTotals(): Observable<TotalObject> {
    if (!this.initiated) {
      this.init();
      this.initiated = true;
    }
    return this.totalsObservable;
  }
}

export interface CartRowTotal extends CartRowIdentification {
  total: number;
  pricePerUnit: number;
  quantity: number;
  extra?: { [s: string]: any } | null;
}
