import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  AfterViewInit,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import { FastSearchLayout, FastSearchConfigService } from '../types';
import { interval, Observable, Subject } from 'rxjs';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import { debounce, first, takeUntil } from 'rxjs';
import { FastsearchLayoutComponent } from '../fastsearch-layout/fastsearch-layout.component';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';

@Component({
  selector: 'lib-fastsearch[configService]',
  templateUrl: './fastsearch.component.html',
  styleUrls: ['./fastsearch.component.css']
})
export class FastsearchComponent<T> implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  protected configService: FastSearchConfigService<T>;

  @ViewChild(CustomHostDirective, { static: true })
  private viewChild: CustomHostDirective;

  searchTerm = '';
  searchTermForm: FormControl;

  public totalItems = new Subject<number>();
  public resultsLimit = 10;

  public renderItems = new Subject<T[]>();
  public renderItems$: Observable<T[]> = this.renderItems.asObservable();

  private unsubscribe: Subject<void> = new Subject();
  public searching = false;

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected detector: ChangeDetectorRef,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.searchTermForm = new FormControl(this.searchTerm);
    this.searchTermForm.valueChanges.pipe(debounce(() => interval(250))).subscribe(valueChanged => {
      this.search(valueChanged);
    });
    this.searchTermForm.setValue('');
    this.route.queryParamMap.subscribe(qp => this.readQueryParams(qp));
  }

  search(term: string) {
    this.searching = true;
    this.configService
      .getItems([term], 0, this.resultsLimit)
      .pipe(first())
      .subscribe(response => {
        this.searching = false;
        this.totalItems.next(response.totalItems);
        this.renderItems.next(response.items);
      });
  }

  clearSearch() {
    this.searchTerm = '';
    this.search(this.searchTerm);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.renderLayout(this.configService.getLayout());
  }

  renderLayout(layout: FastSearchLayout<T>) {
    this.detector.detectChanges();

    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();

    const componentFactory = this.resolver.resolveComponentFactory(layout.getLayout());
    const componentRef = containerRef.createComponent(componentFactory);
    const typedComponent = componentRef.instance as FastsearchLayoutComponent<T>;
    typedComponent.itemListRenderer = layout.getListItemComponent();
    typedComponent.itemDetailRenderer = layout.getDetailItemComponent();
    typedComponent.renderItems = this.renderItems;
    this.detector.detectChanges();
  }

  readQueryParams(queryParams: ParamMap) {
    const searchTerm = queryParams.get('search');
    if (searchTerm) {
      this.searchTerm = searchTerm;
      this.searchTermForm.setValue(searchTerm);
      // this.search(searchTerm);
      this.router.navigate([], {
        queryParams: {
          search: null
        },
        queryParamsHandling: 'merge'
      });
    }
  }
}
