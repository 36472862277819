import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, firstValueFrom } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '../../components/login-modal/login-modal.component';

export interface LoginCheckResponse {
  loggedIn: boolean;
  timeout: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected http: HttpClient,
    protected modalService: NgbModal
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const url = state.url;
    const roles = next.data.rolesAllowed;
    return this.checkLogin(url, roles);
  }

  protected async checkRoles(rolesAllowed: string[]): Promise<boolean> {
    const userRoles = await this.authService.getRoles().pipe(first()).toPromise();
    return userRoles.filter(role => rolesAllowed.indexOf(role) > -1).length > 0;
  }

  protected async checkLogin(redirectUrl: string, rolesAllowed?: string[]): Promise<boolean> {
    const authenticated1 = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = '-1';
    return this.http
      .get<LoginCheckResponse>('user/login-check', { headers })
      .toPromise()
      .then(async loginCheckResponse => {
        // console.log(loginCheckResponse);
        if (authenticated1 && loginCheckResponse.loggedIn === false) {
          await this.modalService.open(LoginModalComponent, { size: 'lg', backdrop: 'static', keyboard: false }).result;
        }
        const authenticated = await this.authService.getIsAuthenticated().pipe(first()).toPromise();
        if (!authenticated) {
          this.router.navigate(['/login'], { queryParams: { redirectUrl } });
          return false;
        }

        const isAnonymous = await firstValueFrom(this.authService.getHasRoles('ANONYMOUS').pipe(first()));

        if (!rolesAllowed) {
          return true;
        }

        const isAllowed = await this.checkRoles(rolesAllowed);
        if (!isAllowed || isAnonymous) {
          this.router.navigate(['/unauthorized']);
          return false;
        }

        return true;
      });
  }
}
