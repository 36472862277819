import { Component, OnInit, Input } from '@angular/core';
import { CartService } from '../../../services/cart/cart.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-order-submit-error',
  templateUrl: './order-submit-error.component.html',
  styleUrls: ['./order-submit-error.component.css']
})
export class OrderSubmitErrorComponent implements OnInit {
  @Input()
  error: any;

  errorDetails: string;

  constructor(protected gungModalService: GungModalService, protected cartService: CartService) {}

  ngOnInit() {
    if (this.error.gungMessage.includes('{"error"')) {
      const errorData = JSON.parse(
        this.error.gungMessage.substring(
          this.error.gungMessage.indexOf('{'),
          this.error.gungMessage.lastIndexOf('}') + 1
        )
      );
      this.errorDetails = errorData.error.message;
    }
  }

  clearCart() {
    this.gungModalService.openConfirmYesNoModal(undefined, 'CLEAR_CART_CONFIRM', { size: 'sm' }).then(
      result => {
        if (result) {
          this.cartService.clearCart();
        }
      },
      reason => {}
    );
  }
}
