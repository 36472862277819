import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute, UrlSegment } from '@angular/router';
import { AbstractSelectionService } from 'gung-list';
import { forkJoin, of, Subject, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, first, mergeMap, switchMap, takeUntil } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { DigitalAssetsFolderListConfigService } from '../../../services/digital-assets-folder-list-config/digital-assets-folder-list-config.service';
import { DigitalAssetsListConfigService } from '../../../services/digital-assets-list-config/digital-assets-list-config.service';
import { DigitalAssetsSearchListConfigService } from '../../../services/digital-assets-search/digital-assets-search-list-config.service';
import { DigitalAsset, DigitalAssetsService } from '../../../services/digital-assets/digital-assets.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';
import { GungFlow } from '../../../state/flow/types';

@Component({
  selector: 'lib-digital-assets-wrapper',
  templateUrl: './digital-assets-wrapper.component.html',
  styleUrls: ['./digital-assets-wrapper.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class DigitalAssetsWrapperComponent implements OnInit, OnDestroy {
  isCreateNew = false;
  isManagement = false;
  isFolder = false;
  isEdit = false;
  isAdmin = false;
  productList = false;

  selectedDigitalAsset: DigitalAsset;

  subscription: Subscription;

  breadcrumbs;

  digitalAssetsSearch: string;

  isSearching = false;

  digitalAssets: DigitalAsset[];
  flows: GungFlow[];

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected authService: AuthService,
    protected digitalAssetsService: DigitalAssetsService,
    public digitalAssetsListConfigService: DigitalAssetsListConfigService,
    public digitalAssetsFolderListConfigService: DigitalAssetsFolderListConfigService,
    public digitalAssetsSearchListConfigService: DigitalAssetsSearchListConfigService,
    protected gungFlowService: GungFlowService
  ) {}

  ngOnInit() {
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => (this.isAdmin = roles.indexOf('ADMIN') >= 0));
    this.isManagement = this.router.url.startsWith('/digital-assets/management');
    this.digitalAssetsListConfigService.isManagement = this.isManagement;
    const showFlowsAsDigitalAssets = this.digitalAssetsService.showFlowsAsDigitalAssets;

    this.route.url.subscribe((segments: UrlSegment[]) => {
      const newCreated = this.route.snapshot.queryParams.new;
      forkJoin([
        this.digitalAssetsService.getDigitalAssets(!!newCreated).pipe(first()),
        showFlowsAsDigitalAssets ? this.gungFlowService.getGungFlows().pipe(first()) : of(null)
      ]).subscribe(([digitalAssets, flows]) => {
        if (!!newCreated) {
          window.history.replaceState(null, '', window.location.pathname);
          // window.history.pushState("object or string", "Title", "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
        }

        // this.digitalAssetsService.getDigitalAssets().pipe(
        //   switchMap(digitalAssets => forkJoin([
        //     of(digitalAssets),
        //     (showFlowsAsDigitalAssets && !this.flows) ? this.gungFlowService.getGungFlows().pipe(first()) : of(this.flows)
        //   ]))
        // ).subscribe(([digitalAssets, flows]) => {
        this.digitalAssets = digitalAssets;
        this.flows = flows;
        if (this.subscription) {
          this.subscription.unsubscribe();
        }
        this.isCreateNew = false;
        this.isFolder = false;
        this.isEdit = false;
        const id = segments.length > 0 ? segments[segments.length - 1].path : null;
        if (id === 'management') {
          this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next(this.digitalAssets.map(d => d.id));
          this.digitalAssetsListConfigService.digitalAssetsObjectsSubject.next(this.digitalAssets.map(d => d.id));
          this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next(this.digitalAssets.map(d => d.id));
        } else {
          this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next([]);
          this.digitalAssetsListConfigService.digitalAssetsObjectsSubject.next([]);
          this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next([]);
        }
        if (!id) {
          // First
          this.productList = false;
          this.digitalAssetsFolderListConfigService.firstLoad = true;
          this.calculateBreadcrumb(segments, digitalAssets);
          const filteredDigitalAssets = digitalAssets
            .filter(d => d.extra.type === 'folder') // First level only show folders
            .filter(d => !d.extra.parentId || d.extra.parentId === '');
          this.isFolder = true;
          if (showFlowsAsDigitalAssets) {
            this.productList = false;
            filteredDigitalAssets.push(
              ...flows
                .filter(f => f.extra.digitalAssets)
                .map(f => {
                  const d: DigitalAsset = {
                    dateLastUpdate: null,
                    i18n: {},
                    images: [],
                    documents: [],
                    id: f.id,
                    name: f.name,
                    extra: { flow: true }
                  };
                  return d;
                })
            );
            this.calculateBreadcrumb(segments, filteredDigitalAssets);
          }
          this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next(
            filteredDigitalAssets.map(d => d.id)
          );
        } else if (id?.startsWith('gungflow-')) {
          const flowId = id.substr(9);
          this.gungFlowService.selectFlow(flowId);
          const unsubscribe: Subject<void> = new Subject();
          this.gungFlowService
            .getSelectedFlow()
            .pipe(takeUntil(unsubscribe))
            .subscribe(flow => {
              if (flowId.localeCompare(flow.id) === 0) {
                unsubscribe.next();
                unsubscribe.complete();
                // NOTE: comment this two lines if you don't want to move to the product listing when selecting flow
                this.router.navigate(['product-images'], { relativeTo: this.route });
                return;
              }
            });
        } else if (id === 'new') {
          // Create new DigitalAsset
          if (!this.isManagement || !this.isAdmin) {
            this.router.navigate(['../'], { relativeTo: this.route });
            return;
          }
          this.isCreateNew = true;
        } else if (id === 'product-images') {
          this.productList = true;
          this.calculateBreadcrumb(segments, digitalAssets);
        } else {
          // Show DigitalAsset ot children DigitalAsset
          this.productList = false;
          this.digitalAssetsFolderListConfigService.firstLoad = false;
          this.calculateBreadcrumb(segments, digitalAssets);
          const digitalAsset = digitalAssets.find(d => d.id === id);
          if (!digitalAsset) {
            return;
          }
          if (digitalAsset.extra.type === 'folder') {
            const children = digitalAssets.filter(d => d.extra.parentId === id);
            if (digitalAsset.extra.content === 'folder') {
              this.isFolder = true;
              this.digitalAssetsFolderListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
              this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
            } else {
              this.digitalAssetsListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
              this.digitalAssetsSearchListConfigService.digitalAssetsObjectsSubject.next(children.map(d => d.id));
            }
          } else {
            this.isEdit = this.isManagement;
            this.isSearching = false;
            this.selectedDigitalAsset = digitalAsset;
          }
        }
      });
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  calculateBreadcrumb(segments: UrlSegment[], digitalAssets: DigitalAsset[]) {
    this.breadcrumbs = [];
    let url = this.router.url.startsWith('/digital-assets/management')
      ? '/digital-assets/management'
      : '/digital-assets';
    let urlParts = [url + '/'];
    this.breadcrumbs.push({ label: 'DIGITAL_ASSETS', urlParts });
    for (const segment of segments) {
      if (segment.path.startsWith('gungflow-')) {
        const flowId = segment.path.substr(9);
        const flow = this.flows.find(d => d.id === flowId);
        this.gungFlowService
          .getSelectedFlow()
          .pipe(first())
          .subscribe(selectedFlow => {
            if (selectedFlow.id !== flowId) {
              this.gungFlowService.selectFlow(flowId);
            }
          });
        urlParts = [url + '/', segment.path];
        this.breadcrumbs.push({ label: flow.name || flowId, urlParts });
        url += '/' + segment.path;
        continue;
      }
      if (segment.path === 'product-images') {
        urlParts = [url + '/', segment.path];
        this.breadcrumbs.push({ label: 'PRODUCT_IMAGES', urlParts });
        url += '/' + segment.path;
        continue;
      }
      const digitalAsset = digitalAssets.find(d => d.id === segment.path);
      if (!digitalAsset) {
        continue;
      }
      urlParts = [url + '/', segment.path];
      this.breadcrumbs.push({ label: digitalAsset.name, urlParts });
      url += '/' + segment.path;
    }
  }

  searchChange(search) {
    if (search) {
      this.isSearching = true;
    } else {
      this.isSearching = false;
    }
    this.digitalAssetsSearchListConfigService.getItems(search);
    this.digitalAssetsSearchListConfigService.searchDigitalAssetsObjectsSubject.next(search);
  }
}
