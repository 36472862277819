import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { MongoAssortment } from '../../models/mongo-assortment';
import { MongoAssortmentService } from '../../services/mongo-assortment/mongo-assortment.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { takeUntil } from 'rxjs/operators';
import { MongoAssortmentActionButtonConfigService } from '../../services/mongo-assortment-action-button-config/mongo-assortment-action-button-config.service';
import { MongoAssortmentCreateModalComponent } from '../mongo-assortment-create-modal/mongo-assortment-create-modal.component';

@Component({
  selector: 'lib-mongo-assortment-management',
  templateUrl: './mongo-assortment-management.component.html',
  styleUrls: ['./mongo-assortment-management.component.scss']
})
export class MongoAssortmentManagementComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  assortments: MongoAssortment[] = [];

  constructor(
    protected mongoAssortmentService: MongoAssortmentService,
    public actionButtonConfig: MongoAssortmentActionButtonConfigService,
    protected modalService: NgbModal
  ) {}

  ngOnInit(): void {
    this.mongoAssortmentService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(assortments => (this.assortments = assortments));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openCreateModal(update?: boolean): void {
    const modal = this.modalService.open(MongoAssortmentCreateModalComponent);
    modal.componentInstance.isUpdate = update;
  }
}
