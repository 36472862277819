import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { first } from 'rxjs';
import { AuthService } from '../../../../services/auth/auth.service';
import { mergeDeep } from '../../../../utils/merge-utils';

@Component({
  selector: 'lib-assign-user-setting',
  templateUrl: './assign-user-setting.component.html',
  styleUrls: ['./assign-user-setting.component.css']
})
export class AssignUserSettingComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected authService: AuthService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          if (user.extra?.orderParams) {
            const merged = mergeDeep(this.checkout.extra, user.extra.orderParams);
            this.stepDone.emit({
              ...this.checkout,
              extra: {
                ...merged
              }
            });
          } else {
            this.stepDone.emit(this.checkout);
          }
        });
    });
  }

  public isVisible() {
    return false;
  }
}
