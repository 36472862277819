import { Component } from '@angular/core';

@Component({
  selector: 'lib-admin-configuration-wrapper',
  templateUrl: './admin-configuration-wrapper.component.html',
  styleUrls: ['./admin-configuration-wrapper.component.css']
})
export class AdminConfigurationWrapperComponent {
  private readonly configurationIds = ['OrderConfirmDbConfig'];
}
