<div class="modal-header">
  <h4 *ngIf="!sendSingleInvoice" class="modal-title" translate>GENERATE_EDI_INVOICE</h4>
  <h4 *ngIf="sendSingleInvoice" class="modal-title" translate>SEND_SINGLE_EDI_INVOICE</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <span translate>THIS_WILL_FORCE_SEND_EDI_INVOICE</span>
    </div>
    <div class="col-12">
      <lib-gung-text-input-horizontal
        [renderStatic]="!sendSingleInvoice"
        [(ngModel)]="data.orderNumberModel"
        [gungTranslate]="'ORDER_NUMBER'"
        [placeholder]="'ORDER_NUMBER' | translate"
      ></lib-gung-text-input-horizontal>
    </div>
    <div class="col-12">
      <lib-gung-text-input-horizontal
        [(ngModel)]="data.invoiceNumber"
        [gungTranslate]="'INVOICE_NUMBER'"
        [placeholder]="'INVOICE_NUMBER' | translate"
      ></lib-gung-text-input-horizontal>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light mr-auto" aria-label="Close" (click)="activeModal.close()" translate>
    {{ sendSingleInvoice ? 'CLOSE' : 'CANCEL' }}
  </button>
  <button type="button" class="btn btn-outline-dark" (click)="onSend()" translate>SEND</button>
</div>
