import { Component } from '@angular/core';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { AssortmentService } from '../../../../services/assortment.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungNoImageUrl } from 'gung-common';

// DEFAULT DATA EXAMPLE USED WHEN NO DATA
const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    topImage: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    topButton: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageOne: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s100'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageTwo: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s200'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageThree: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s300'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageFour: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s400'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageFive: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s500'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageSix: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s600'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageSeven: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s700'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageEight: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s800'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    assortmentImageNine: {
      link: {
        enabled: true,
        internal: true,
        url: '/categories/s900'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 420x280
      },
      text: {
        enabled: true,
        text: ''
      }
    }
  }
};

@Component({
  selector: 'lib-home-assortment-template',
  templateUrl: './home-assortment-template.component.html',
  styleUrls: ['./home-assortment-template.component.css']
})
export class HomeAssortmentTemplateComponent extends CustomPageTemplateComponent {
  protected defaultNoImage = GungNoImageUrl; // 420x280

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }
}
