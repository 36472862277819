import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListSortOption } from 'gung-list';
import { Product } from '../../models';
import { ProductService } from '../../services/products/product.service';
import { AuthService } from '../../services/auth/auth.service';
import { ProductExportService } from '../../services/product-export/product-export.service';
import { ProductSortService } from '../../services/product-sort/product-sort.service';
import { ProductListConfigService } from '../../services/product-list-config/product-list-config.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { WhiteboardService } from './whiteboard.service';
import { Whiteboard } from '../types';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardOrderProductListConfigService implements ConfigService<Product> {
  whiteboardSubject: Subject<string> = new BehaviorSubject(null);
  topFilter = false;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productExportService: ProductExportService,
    protected productSortService: ProductSortService,
    protected productListConfigService: ProductListConfigService,
    protected whiteboardService: WhiteboardService
  ) {
    this.topFilter = this.productListConfigService.topFilter;
  }

  getItems(): Observable<Product[]> {
    return this.whiteboardSubject.pipe(
      switchMap((whiteboardId: string) => {
        if (!whiteboardId) {
          return;
        }

        return this.whiteboardService.getOne(whiteboardId);
      }),
      switchMap((whiteboard: Whiteboard) => {
        if (!whiteboard) {
          return;
        }

        const itemIds: string[] = [];
        whiteboard.sheets.forEach(sheet => {
          itemIds.push(...sheet.items.map(i => i.productId));
        });
        return this.productService.getProductsByIds(itemIds);
      })
    );
  }

  getBatchSizes(): number[] | undefined {
    return this.productListConfigService.getBatchSizes();
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.productListConfigService.getFilters();
  }

  getItemId(item: Product): string {
    return this.productListConfigService.getItemId(item);
  }

  getLayouts(): ListLayout<Product>[] {
    return this.productListConfigService.getLayouts();
  }

  getSearchTerms(item: Product): string[] {
    return this.productListConfigService.getSearchTerms(item);
  }

  getSortOptions(): ListSortOption<Product>[] {
    return this.productListConfigService.getSortOptions();
  }
}
