import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Activity } from '../../models/activity';
import { tap } from 'rxjs';
import { MetadataService } from '../metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  protected activitySubjects: { [customerId: string]: BehaviorSubject<Activity[]> } = {};

  constructor(protected http: HttpClient, protected metadataService: MetadataService) {}

  public getAllActivitys(): Observable<Activity[]> {
    const url = `json/customer/activities`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<Activity[]>(url, { headers });
  }

  protected internalGetActivitysFromUrl(customerId: string): Observable<Activity[]> {
    const url = `json/customer/${customerId}/activities`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<Activity[]>(url, { headers });
  }

  updateActivitysSubject(customerId: string): void {
    this.internalGetActivitysFromUrl(customerId).subscribe((a: Activity[]) =>
      this.activitySubjects[customerId].next(a)
    );
  }

  getActivitys(customerId: string): Observable<Activity[]> {
    if (!this.activitySubjects[customerId]) {
      this.activitySubjects[customerId] = new BehaviorSubject<Activity[]>([]);
      this.updateActivitysSubject(customerId);
    }
    return this.activitySubjects[customerId].asObservable();
  }

  createActivity(customerId: string, activity: Activity): Observable<Activity | void> {
    const url = `json/customer/${customerId}/activities`;
    return this.http.post<Activity | void>(url, activity).pipe(
      tap(
        // Log the result or error
        data => {
          if (this.activitySubjects[customerId]) {
            this.updateActivitysSubject(customerId);
          }
        },
        error => console.log('ERROR', error)
      )
    );
  }

  updateActivity(customerId: string, activity: Activity): Observable<Activity | void> {
    const url = `json/customer/${customerId}/activities`;
    return this.http.put<Activity | void>(url, activity).pipe(
      tap(
        // Log the result or error
        data => this.updateActivitysSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  removeActivity(customerId: string, activity: Activity): Observable<boolean | void> {
    const url = `json/customer/${customerId}/activities/${activity.id}`;
    return this.http.delete<boolean | void>(url).pipe(
      tap(
        // Log the result or error
        data => this.updateActivitysSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  getMeetingType(): Observable<any> {
    const meetingType = [
      {
        id: 1,
        name: 'Scheduled sales meeting'
      },
      {
        id: 2,
        name: 'Spontaneous sales meeting'
      },
      {
        id: 3,
        name: 'Service and development'
      }
    ];

    return of(meetingType);
  }

  getSystem(): Observable<any> {
    const listSystem = [
      'AdLite',
      'FreshAccessories',
      'Multivo',
      'Next',
      'RollerTrack',
      'RotoShelf',
      'Sigma',
      'SoftPushTrays',
      'Turnloader'
    ];
    const systems = this.metadataService
      .getMetadataTableList('q_system')
      .filter(m => listSystem.includes(m.q_systemid))
      .map(m => ({ q_systemid: m.q_systemid, q_systemdescription: m.q_systemdescription }));

    return of(systems);
  }

  getItemGroup(): Observable<any> {
    const meetingType = [
      {
        id: 1,
        name: 'Multivo™ Lift & slide'
      },
      {
        id: 2,
        name: 'Multivo™ Max'
      }
    ];

    return of(meetingType);
  }

  getSalesCycle(): Observable<any> {
    const salesCycle = [
      {
        id: 1,
        name: 'Beverages'
      }
    ];

    return of(salesCycle);
  }
}
