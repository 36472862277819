import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { first, map, of, switchMap } from 'rxjs';
import { PasswordResetService } from '../../services/password-reset.service';
import { BackendFeatureService } from '../../services/backend-feature.service';
import { PasswordPolicyService } from '../../services/password-policy.service';

@Component({
  selector: 'lib-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  resetPasswordForm = this.formBuilder.group({
    password: ['', Validators.required],
    passwordConfirm: ['', Validators.required]
  });

  passwordRequirements: any;
  isValidPassword = false;

  token = '';

  passwordSet = false;

  constructor(
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected passwordResetService: PasswordResetService,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService
  ) {}

  ngOnInit() {
    const featureId = 'passwordPolicies';

    this.backendFeatureService
      .isActivated(featureId)
      .pipe(
        first(),
        switchMap(isActivated => {
          if (isActivated) {
            return this.passwordPolicyService.getPasswordRequirements();
          } else {
            return of({});
          }
        })
      )
      .subscribe(requirements => {
        this.passwordRequirements = requirements;
      });

    this.route.params.pipe(map(params => params.token as string)).subscribe(token => (this.token = token));
  }

  isValid(event) {
    this.isValidPassword = event;
  }

  onSubmit() {
    if (!this.resetPasswordForm.valid || !this.isValidPassword) {
      return;
    }
    const pw1 = this.resetPasswordForm.controls.password.value;
    const pw2 = this.resetPasswordForm.controls.passwordConfirm.value;
    if (pw1 !== pw2) {
      this.resetPasswordForm.setErrors({
        noMatch: true
      });
      return;
    } else {
      this.resetPasswordForm.setErrors(null);
    }
    this.resetPasswordForm.disable();
    this.passwordResetService.updatePassword(this.token, pw1).subscribe(res => {
      if (!res) {
        this.resetPasswordForm.enable();
        this.resetPasswordForm.setErrors({
          errorSettingPw: true
        });
        return;
      }
      this.passwordSet = true;
    });
  }
}
