<div class="row pb-2 align-items-center" *ngIf="!!product && !!price">
  <div
    class="col-3 col-lg-1 d-flex align-items-center navigate-to-product-details"
    [routerLink]="['/product/', product.id]"
  >
    <img
      class="img-fluid"
      [src]="product.extra.images[0].s3Uri
        | gungImageUrl
          :'type:list'
          :'etag:'+product.extra.images[0].s3ETag
      "
      alt=""
    />
  </div>

  <div class="col-8 col-lg-3 text-truncate navigate-to-product-details" [routerLink]="['/product/', product.id]">
    <div>
      <b>{{ product.id }}</b> - {{ product.name }}
    </div>
  </div>

  <div class="col-12 col-sm-8 col-lg-3 offset-sm-3 offset-lg-0 col-request-quantity">
    <div class="row pt-1">
      <div class="col-4 d-flex d-lg-none">
        <b translate>REQUESTED_QUANTITY</b>
      </div>
      <div class="col-8 col-lg-12 d-flex">
        <lib-buy-btn [id]="data.productId" [product]="product" [checkoutCart]="true"></lib-buy-btn>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0">
    <div class="row pt-1">
      <div class="col-4 d-flex d-lg-none">
        <b translate>UNIT_PRICE</b>
      </div>
      <div class="col-8 col-lg-12 d-flex">
        <b>
          {{ price.cartRowUnitPrice.value | gungCurrency: price.cartRowUnitPrice.currencyCode:'symbol-narrow':'1.2-2' }}
          <span *ngIf="price.cartRowDiscountPercent !== 0">(-{{ price.cartRowDiscountPercent }}%)</span>
        </b>
      </div>
    </div>
  </div>

  <div class="col-12 col-sm-8 col-lg-2 offset-sm-3 offset-lg-0">
    <div class="row pt-1">
      <div class="col-4 d-flex d-lg-none">
        <b translate>TOTAL_PRICE</b>
      </div>
      <div class="col-8 col-lg-12 d-flex">
        {{
          price.cartRowTotalPriceInclRowDiscount.value
            | gungCurrency: price.cartRowTotalPriceInclRowDiscount.currencyCode
        }}
      </div>
    </div>
  </div>

  <div class="col-2 col-md-1 order-first order-lg-0 offset-11 offset-lg-0 d-flex align-items-center col-delete-button">
    <button class="btn btn-light delete-button" (click)="removeRow()">
      <i class="fal fa-minus-circle"></i>
    </button>
  </div>
</div>

<div class="row hr"></div>
