import { Injectable } from '@angular/core';
import { OptionsList } from 'gung-common';
import { Observable, first, forkJoin, of, switchMap } from 'rxjs';
import { MetadataService } from './metadata/metadata.service';
import { AuthService } from './auth/auth.service';
import { SelectedCustomerService } from './selected-customer/selected-customer.service';
import { gungGetPropertyOfObject } from '../utils/gung-utils';

@Injectable({
  providedIn: 'root'
})
export class ActivityInternalCustomerResponsibleService {
  public metadataTable: string = ''; // Metadata table id
  public metadataId: string = ''; // Metadata entry id path
  public metadataName: string = ''; // Metadata entry name path
  userCustomerResponsiblePath: string = ''; // Path to customer responsible in user object
  useCustomerSalesperson: boolean = false; // Use customer responsible from customer instead of user
  customerCustomerResponsiblePath: string = ''; // Path to customer responsible in customer object
  // Leave above empty if project doesn't have salesperson metadata //

  constructor(
    protected metadataService: MetadataService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService
  ) { }

  getCustomersResponsible(): Observable<OptionsList[]> {
    const customersResponsibleList = this.metadataService.getMetadataTableList(this.metadataTable);
    const result: OptionsList[] = [];
    if (!customersResponsibleList) {
      return of(result);
    }
    
    for (const c of customersResponsibleList) {
      if (!c[this.metadataName] || !c[this.metadataId]) {
        continue;
      }
      result.push({
        id: c[this.metadataId],
        name: c[this.metadataName]
      });
    }
    return of(result);
  }

  getDefaultCustomerResponsible(isMultiple?: boolean): Observable<string> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => forkJoin({
        user: of(user),
        selectedCustomer: this.selectedCustomerService.getSelectedCustomer().pipe(first())
      })),
      switchMap(({user, selectedCustomer}) => {
        if (this.useCustomerSalesperson && !isMultiple) {
          const customerResponsible = gungGetPropertyOfObject(this.customerCustomerResponsiblePath, selectedCustomer)
          if (!!customerResponsible) {
            return of(customerResponsible);
          }
        }

        const customerResponsible = gungGetPropertyOfObject(this.userCustomerResponsiblePath, user)
        if (!!customerResponsible) {
          return of(customerResponsible);
        }

        return of(user.username);
    }))
  }
}
