import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import {
  EdiDespatchAdvice,
  EdiGlnNumbers,
  EdiGungMessages,
  EdiInvoice,
  EdiOrder,
  EdiOrderItem,
  EdiOrderMessageRelations,
  EdiRespondeOrder,
  GungEdimessageType,
  EdiMessageBase,
  GungEdiMessageSendStatus,
  GLNRecord,
  EdiPurchaseOrder,
  EdiIncomingDespatchAdvice
} from '../../models/edi';

@Injectable({
  providedIn: 'root'
})
export class EdiService {
  protected baseUrl = 'json/edi-b24';

  constructor(protected http: HttpClient) {}

  getGlnNumbers(nocache?: boolean): Observable<EdiGlnNumbers[]> {
    const url = `${this.baseUrl}/gln-numbers`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<any[]>(url, { headers });
  }

  postGlnNumbers(id: string, data: EdiGlnNumbers, nocache?: boolean): Observable<EdiGlnNumbers> {
    const url = `${this.baseUrl}/gln-numbers/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.post<EdiGlnNumbers>(url, data);
  }

  getGungMessages(nocache?: boolean): Observable<EdiGungMessages[]> {
    const url = `${this.baseUrl}/gung-messages`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<any[]>(url, { headers });
  }

  resendOrderContext(orderContextId) {
    const url = `json/actuator/edi/resend-order?contextId=${orderContextId}`;
    return this.http.get<any[]>(url);
  }

  generateOrderResponse(orderContextId) {
    const url = `json/actuator/edi/generate-order-response?contextId=${orderContextId}&force=true`;
    return this.http.get<any[]>(url);
  }

  generateInvoice(invoiceNumber, orderNumberModel) {
    const url = `json/actuator/edi/generate-invoice?invoiceId=${invoiceNumber}&orderId=${orderNumberModel}&force=true`;
    return this.http.get<any[]>(url);
  }

  generateDespatchAdvices(selectedOrderIdsForDespatchAdvice, ediMessageId) {
    const url = `json/actuator/edi/generate-despatch-advice-from-gung-edi-order-id?orderIds=${selectedOrderIdsForDespatchAdvice}&ediMessageId=${ediMessageId}`;
    return this.http.get<any[]>(url);
  }

  generateDespatchAdviceFromAdviceMessage(ediMessage: EdiGungMessages) {
    const url = `json/actuator/edi/generate-despatch-advice-from-gung-edi-despatch-advice-id?ediMessageId=${ediMessage.id}`;
    return this.http.get<any[]>(url);
  }

  importEdiOrderXml(formData: FormData) {
    const url = `/json/edi-orders/generate`;
    return this.http.post<null>(url, formData);
  }

  getOrders(): Observable<EdiOrder[]> {
    const url = `json/edi/v2/order/messages`;
    return this.http.get<EdiOrder[]>(url);
  }

  getPurchasedOrders(): Observable<EdiOrder[]> {
    const url = `json/edi/v2/purchase-order/messages`;
    return this.http.get<EdiOrder[]>(url);
  }

  getPurchasedOrder(id): Observable<EdiOrder> {
    const url = `json/edi/v2/purchase-order/messages/by-message-id?messageId=${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      map((response: any) => {
        // we need to convert buyerGln to string can be bigger than maxintjs
        const modifiedString = response.replace(/("buyerGln":)(\d+)/, '"buyerGln":"$2"');
        return JSON.parse(modifiedString) as EdiOrder;
      })
    );
  }

  getOrder(id): Observable<EdiOrder> {
    const url = `json/gung/edi/orders/${id}`;
    const headers = new HttpHeaders().set('Content-Type', 'text/plain; charset=utf-8');
    return this.http.get(url, { headers, responseType: 'text' }).pipe(
      map((response: any) => {
        // we need to convert buyerGln to string can be bigger than maxintjs
        const modifiedString = response.replace(/("buyerGln":)(\d+)/, '"buyerGln":"$2"');
        return JSON.parse(modifiedString) as EdiOrder;
      })
    );
  }

  getInvoices(): Observable<EdiInvoice[]> {
    const url = `json/edi/v2/invoice/messages`;
    return this.http.get<EdiInvoice[]>(url);
  }

  getInvoice(id): Observable<any> {
    const url = `json/gung/edi/invoices/${id}`;
    return this.http.get<any>(url);
  }

  getDispatchAdvices(): Observable<EdiDespatchAdvice[]> {
    //  const url = `json/gung/edi/despatch-advices`;
    const url = `json/edi/v2/despatch/messages`;
    return this.http.get<any>(url);
  }

  getDispatchAdvice(id): Observable<EdiDespatchAdvice> {
    const url = `json/gung/edi/despatch-advices/${id}`;
    return this.http.get<any>(url);
  }

  getIncomingDespatchAdvices(): Observable<EdiDespatchAdvice[]> {
    const url = `json/edi/v2/incoming-despatch/messages`;
    return this.http.get<any>(url);
  }

  getIncomingDespatchAdvice(id): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/incoming-despatch/${id}`;
    return this.http.get<any>(url);
  }

  getOrderResponses(): Observable<EdiRespondeOrder[]> {
    const url = `json/edi/v2/order-response/messages`;
    return this.http.get<any>(url);
  }

  getOrderResponse(id): Observable<any> {
    const url = `json/gung/edi/order-responses/${id}`;
    return this.http.get<any>(url);
  }

  getIncomingOrderResponses(): Observable<EdiRespondeOrder[]> {
    const url = `json/edi/v2/incoming-order-response/messages`;
    return this.http.get<any>(url);
  }

  getIncomingOrderResponse(id): Observable<any> {
    const url = `json/edi/v2/incoming-order-response/${id}`;
    return this.http.get<any>(url);
  }

  sendToErp(id: string, data: { data: EdiOrder }): Observable<EdiOrder> {
    const url = `json/gung/edi/orders/post-to-erp?id=${id}`;
    return this.http.post<EdiOrder>(url, data);
  }

  sendOrderResponse(id, data) {
    const url = `json/gung/edi/order-responses/generate?orderMessageId=${id}`;
    return this.http.post<EdiOrder>(url, data);
  }

  getInvoiceMessagesBy(id): Observable<any> {
    const url = `json/gung/edi/invoices/${id}`;
    return this.http.get<any>(url);
  }

  getOrderMessageRelations(messageId: string): Observable<EdiOrderMessageRelations> {
    const url = `json/edi/v2/relations/ordermessage-relations/by-message-id-get?messageId=${messageId}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiOrderMessageRelations>(url, { headers });
  }

  getOrderResponseMessagesValidate(id: string): Observable<EdiRespondeOrder> {
    const url = `json/edi/v2/order-response/validate?id=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiRespondeOrder>(url);
  }

  getMessageValidate(id: string, messageType: GungEdimessageType): Observable<EdiMessageBase> {
    if (messageType === GungEdimessageType.ORDER) {
      return this.getOrderMessagesRevalidateByMessageId(id);
    } else if (messageType === GungEdimessageType.ORDER_RESPONSE) {
      return this.getOrderResponseMessagesValidate(id);
    } else if (messageType === GungEdimessageType.INVOICE) {
      return this.getInvoiceMessagesValidate(id);
    } else if (messageType === GungEdimessageType.DESPATCH_ADVICE) {
      return this.getDespatchMessagesValidate(id);
    } else if (messageType === GungEdimessageType.INCOMING_DESPATCH_ADVICE) {
      return this.getIncomingDespatchMessagesValidate(id);
    } else if (messageType === GungEdimessageType.PURCHASE_ORDER) {
      return this.getPurchasedOrderMessagesValidate(id);
    }
  }
  getInvoiceMessagesValidate(id: string): Observable<EdiInvoice> {
    const url = `json/edi/v2/invoice/validate?id=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiInvoice>(url, { headers });
  }

  downloadMessage(id, type): Observable<any> {
    const url = `json/edi/v2/actuator/download-message?id=${id}&messageType=${type}`;
    return this.http.get<any>(url);
  }

  getOrderMessagesRevalidateByMessageId(id: string): Observable<EdiOrder> {
    const url = `json/edi/v2/order/messages/revalidate-by-message-id?messageId=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiOrder>(url, { headers });
  }

  postOrderMessageToErp(id: string, ediOrderItem: EdiOrder): Observable<EdiOrder> {
    const url = `json/edi/v2/order/messages/post-order-message-to-erp?messageId=${id}`;
    return this.http.post<EdiOrder>(url, ediOrderItem);
  }

  getDespatchMessagesValidate(id: string): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/despatch/validate?id=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiDespatchAdvice>(url, { headers });
  }

  getIncomingDespatchMessagesValidate(id: string): Observable<EdiIncomingDespatchAdvice> {
    const url = `json/edi/v2/incoming-despatch/validate?id=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiIncomingDespatchAdvice>(url, { headers });
  }

  postModifyIds(id: string, index: number, ediOrderItem: EdiOrderItem): Observable<EdiOrder> {
    // json/edi/v2/order/messages/items/modify-ids
    const url = `json/edi/v2/order/messages/items/modify-ids?messageId=${id}&index=${index}`;
    return this.http.post<EdiOrder>(url, ediOrderItem);
  }

  postModifyQuantities(id: string, index: number, ediOrderItem: EdiOrderItem): Observable<EdiOrder> {
    const url = `json/edi/v2/order/messages/items/modify-quantities?messageId=${id}&index=${index}`;
    return this.http.post<EdiOrder>(url, ediOrderItem);
  }

  postSetItemStatusIgnored(id: string, index: number, ediOrderItem: EdiOrderItem): Observable<EdiOrder> {
    // json/edi/v2/order/messages/items/set-item-status-ignored
    const url = `json/edi/v2/order/messages/items/set-item-status-ignored?messageId=${id}&index=${index}`;
    return this.http.post<EdiOrder>(url, ediOrderItem);
  }
  getPurchasedOrderMessagesValidate(id: string): Observable<EdiPurchaseOrder> {
    const url = `json/edi/v2/purchase-order/messages/revalidate-by-message-id?messageId=${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiPurchaseOrder>(url, { headers });
  }

  getMessageTransformAndSend(id: string, messageType: GungEdimessageType) {
    if (messageType === GungEdimessageType.ORDER) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.ORDER_RESPONSE) {
      return this.getOrderResponseTransformAndSend(id);
    } else if (messageType === GungEdimessageType.INVOICE) {
      return this.getInvoiceTransformAndSend(id);
    } else if (messageType === GungEdimessageType.DESPATCH_ADVICE) {
      return this.getDespatchTransformAndSend(id);
    } else if (messageType === GungEdimessageType.INCOMING_DESPATCH_ADVICE) {
      throw new Error('Incoming Despatch Advice is not implemented for re generate');
    } else if (messageType === GungEdimessageType.PURCHASE_ORDER) {
      return this.getPurchaseOrderTransformAndSend(id);
    }
  }

  getMessagePostToERP(id: string, messageType: GungEdimessageType) {
    if (messageType === GungEdimessageType.ORDER) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.ORDER_RESPONSE) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.INVOICE) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.DESPATCH_ADVICE) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.INCOMING_DESPATCH_ADVICE) {
      return this.getIncomingDespatchAdvicePostToERP(id);
    } else if (messageType === GungEdimessageType.PURCHASE_ORDER) {
      throw new Error('Order is not implemented for re generate');
    }
  }

  getInvoiceTransformAndSend(id: string): Observable<EdiInvoice> {
    const url = `json/edi/v2/invoice/transform-and-send?id=${id}`;
    return this.http.post<EdiInvoice>(url, undefined);
  }

  getOrderResponseTransformAndSend(id: string): Observable<EdiRespondeOrder> {
    const url = `json/edi/v2/order-response/transform-and-send?id=${id}`;
    return this.http.post<EdiRespondeOrder>(url, undefined);
  }

  getDespatchTransformAndSend(id: string): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/despatch/transform-and-send?id=${id}`;
    return this.http.post<EdiDespatchAdvice>(url, undefined);
  }

  getPurchaseOrderTransformAndSend(id: string): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/purchase-order/transform-and-send?id=${id}`;
    return this.http.post<EdiDespatchAdvice>(url, undefined);
  }

  getIncomingDespatchAdvicePostToERP(id: string): Observable<EdiIncomingDespatchAdvice> {
    const url = `json/edi/v2/incoming-despatch/post-to-erp?id=${id}`;
    return this.http.get<EdiIncomingDespatchAdvice>(url);
  }

  postInvoiceGenerateByGungId(id: string, ediOrderItem: EdiInvoice, sampleDecorators: boolean): Observable<EdiInvoice> {
    const url = `json/edi/v2/invoice/generate-by-gung-id?id=${id}&sampleDecorators=${sampleDecorators}`;
    return this.http.post<EdiInvoice>(url, ediOrderItem);
  }
  postGenerateEdiMessageByGungId(
    id: string,
    ediMessage: EdiMessageBase,
    messageType: GungEdimessageType,
    sampleDecorators: boolean
  ): Observable<EdiMessageBase> {
    if (messageType === GungEdimessageType.ORDER) {
      throw new Error('Order is not implemented for re generate');
    } else if (messageType === GungEdimessageType.ORDER_RESPONSE) {
      return this.postOrderResponseGenerateByGungId(id, ediMessage as EdiRespondeOrder, sampleDecorators);
    } else if (messageType === GungEdimessageType.INVOICE) {
      return this.postInvoiceGenerateByGungId(id, ediMessage as EdiInvoice, sampleDecorators);
    } else if (messageType === GungEdimessageType.DESPATCH_ADVICE) {
      return this.postDespatchGenerateByGungId(id, ediMessage as EdiDespatchAdvice, sampleDecorators);
    } else if (messageType === GungEdimessageType.PURCHASE_ORDER) {
      return this.postPurchasedOrderGenerateByGungId(id, ediMessage as EdiPurchaseOrder, sampleDecorators);
    }
  }

  postOrderResponseGenerateByGungId(
    id: string,
    ediOrderItem: EdiRespondeOrder,
    sampleDecorators: boolean
  ): Observable<EdiRespondeOrder> {
    const url = `json/edi/v2/order-response/generate-by-gung-id?id=${id}&sampleDecorators=${sampleDecorators}`;
    return this.http.post<EdiRespondeOrder>(url, ediOrderItem);
  }

  postDespatchGenerateByGungId(
    id: string,
    ediOrderItem: EdiDespatchAdvice,
    sampleDecorators: boolean
  ): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/despatch/generate-by-gung-id?id=${id}&sampleDecorators=${sampleDecorators}`;
    return this.http.post<EdiDespatchAdvice>(url, ediOrderItem);
  }

  postPurchasedOrderGenerateByGungId(
    id: string,
    ediOrderItem: EdiDespatchAdvice,
    sampleDecorators: boolean
  ): Observable<EdiDespatchAdvice> {
    const url = `json/edi/v2/purchase-order/generate-by-gung-id?id=${id}&sampleDecorators=${sampleDecorators}`;
    return this.http.post<EdiDespatchAdvice>(url, ediOrderItem);
  }

  getMessageByErpOrderId(id: string): Observable<EdiRespondeOrder[]> {
    const url = `json/edi/v2/order/messages-by-erp-order-id?orderId=${id}`;
    return this.http.get<EdiRespondeOrder[]>(url);
  }

  setMessageStatus(
    id: string,
    messageType: GungEdimessageType,
    sendStatus: GungEdiMessageSendStatus
  ): Observable<EdiMessageBase[]> {
    const url = `json/edi/v2/actuator/set-message-status?id=${id}&messageType=${messageType}&sendStatus=${sendStatus}`;
    return this.http.post<EdiMessageBase[]>(url, {});
  }

  getMessageById(id: string, messageType: GungEdimessageType): Observable<EdiMessageBase> {
    const url = `json/edi/v2/actuator/get-message-by-id?id=${id}&messageType=${messageType}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');
    return this.http.get<EdiMessageBase>(url, { headers });
  }

  getGLNRecord(glnNumber): Observable<GLNRecord> {
    return this.http.get<GLNRecord>('json/edi/v2/gln-numbers/by-gln-and-primary?glnNumber=' + glnNumber);
  }
}
