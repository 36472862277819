<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CONFIRM</h4>
  <button type="button" class="close" aria-label="Close" (click)="cancel()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group pb-2">
      <label for="template" class="control-label" translate>SELECT_TEMPLATE</label>
      <select name="template" id="template" class="form-control form-select" [(ngModel)]="definitionId" ngbAutofocus>
        <option *ngFor="let definition of definitions" [value]="definition.id" translate>{{ definition.name }}</option>
      </select>
    </div>

    <div class="form-group pb-2">
      <label for="name" class="control-label" translate>ENTER_EMAIL_TO_SEND_EXPORT</label>
      <input class="form-control" type="text" name="name" id="name" [(ngModel)]="email" ngbAutofocus />
    </div>

    <lib-gung-date-picker
      [currentselectedDate]="preOrderToDate"
      (selectedDate)="preOrderToDate = $event"
      label="CONTEXT_PARAMS_PREORDERTODATE"
    ></lib-gung-date-picker>

    <lib-gung-date-picker
      [currentselectedDate]="validFromDate"
      (selectedDate)="validFromDate = $event"
      label="CONTEXT_PARAMS_VALIDFROMDATE"
    ></lib-gung-date-picker>

    <lib-gung-date-picker
      [currentselectedDate]="validToDate"
      (selectedDate)="validToDate = $event"
      label="CONTEXT_PARAMS_VALIDTODATE"
    ></lib-gung-date-picker>

    <lib-gung-date-picker
      [currentselectedDate]="supplementaryValidShipDateFrom"
      (selectedDate)="supplementaryValidShipDateFrom = $event"
      label="CONTEXT_PARAMS_SUPPLEMENTARYVALIDSHIPDATEFROM"
    ></lib-gung-date-picker>

    <lib-gung-date-picker
      [currentselectedDate]="supplementaryValidShipDateTo"
      (selectedDate)="supplementaryValidShipDateTo = $event"
      label="CONTEXT_PARAMS_SUPPLEMENTARYVALIDSHIPDATETO"
    ></lib-gung-date-picker>

    <!-- Updated to use standard components -->
    <!--<div class="form-group pb-2">
      <label for="preOrderToDate" class="control-label" translate>CONTEXT_PARAMS_PREORDERTODATE</label>
      <div class="input-group">
        <input
          class="form-control input-date"
          placeholder="yyyy-mm-dd"
          name="preOrderToDate"
          id="preOrderToDate"
          ngbDatepicker
          #preOrderToDatePicker="ngbDatepicker"
          container="body"
          [(ngModel)]="preOrderToDate"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="preOrderToDatePicker.toggle()" type="button">
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group pb-2">
      <label for="validFromDate" class="control-label" translate>CONTEXT_PARAMS_VALIDFROMDATE</label>
      <div class="input-group">
        <input
          class="form-control input-date"
          placeholder="yyyy-mm-dd"
          name="validFromDate"
          id="validFromDate"
          ngbDatepicker
          #validFromDatePicker="ngbDatepicker"
          container="body"
          [(ngModel)]="validFromDate"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="validFromDatePicker.toggle()" type="button">
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group pb-2">
      <label for="validToDate" class="control-label" translate>CONTEXT_PARAMS_VALIDTODATE</label>
      <div class="input-group">
        <input
          class="form-control input-date"
          placeholder="yyyy-mm-dd"
          name="validToDate"
          id="validToDate"
          ngbDatepicker
          #validToDatePicker="ngbDatepicker"
          container="body"
          [(ngModel)]="validToDate"
        />
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="validToDatePicker.toggle()" type="button">
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group pb-2">
      <label for="supplementaryValidShipDateFrom" class="control-label" translate
        >CONTEXT_PARAMS_SUPPLEMENTARYVALIDSHIPDATEFROM</label
      >
      <div class="input-group">
        <input
          class="form-control input-date"
          placeholder="yyyy-mm-dd"
          name="supplementaryValidShipDateFrom"
          id="supplementaryValidShipDateFrom"
          ngbDatepicker
          #supplementaryValidShipDateFromDatePicker="ngbDatepicker"
          container="body"
          [(ngModel)]="supplementaryValidShipDateFrom"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="supplementaryValidShipDateFromDatePicker.toggle()"
            type="button"
          >
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>

    <div class="form-group pb-2">
      <label for="supplementaryValidShipDateTo" class="control-label" translate
        >CONTEXT_PARAMS_SUPPLEMENTARYVALIDSHIPDATETO</label
      >
      <div class="input-group">
        <input
          class="form-control input-date"
          placeholder="yyyy-mm-dd"
          name="supplementaryValidShipDateTo"
          id="supplementaryValidShipDateTo"
          ngbDatepicker
          #supplementaryValidShipDateToDatePicker="ngbDatepicker"
          container="body"
          [(ngModel)]="supplementaryValidShipDateTo"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-secondary calendar"
            (click)="supplementaryValidShipDateToDatePicker.toggle()"
            type="button"
          >
            <i class="fal fa-calendar-alt"></i>
          </button>
        </div>
      </div>
    </div>-->

    <div class="alert alert-info" role="alert" *ngIf="isValid()">
      <span translate>EXPORT_DATA_FEEDBACK</span>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="btn-group btn-group-justified w-100">
    <button class="btn btn-secondary" (click)="cancel()" translate>CANCEL</button>
    <button class="btn btn-primary" [disabled]="!isValid()" (click)="confirm()" translate>{{ confirmBtnLabel }}</button>
  </div>
</div>
