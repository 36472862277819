import { Component, Input, OnInit } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { forkJoin, map, Observable, ReplaySubject, Subject } from 'rxjs';
import { ProductService } from '../../../services/products/product.service';

export interface EdiRowTable {
  header: string;
  getValue: (item: any, index?: number) => any; // string | number | Date
  type: EdiRowTableType;
  customClass?: string;
  value?: string | number | Date;
  data?: any;
  secondaryColumn?: EdiRowTable[];
}

export enum EdiRowTableType {
  TEXT,
  NUMBER,
  DATE,
  HTML,
  IMAGE,
  ACTION,
  TABLE
}

@Component({
  selector: 'lib-edi-row-table',
  templateUrl: './edi-row-table.component.html',
  styleUrls: ['./edi-row-table.component.css']
})
export class EdiRowTableComponent implements OnInit {
  table: EdiRowTable[][];
  EdiRowTableType = EdiRowTableType;

  secondaryTable: { columns: EdiRowTable[]; data: any[] }[] = [];

  @Input()
  data: any[];

  @Input()
  columns: EdiRowTable[];

  @Input()
  customClass?: string;

  // V2 Angular Material Table
  showV2 = false; // FC: Don't change this
  displayedColumns: string[];
  dataSource;

  constructor(public dateUtilService: DateUtilService, protected productService: ProductService) {}

  ngOnInit(): void {
    this.generateTable();
  }

  generateTable() {
    const table = []; // Table to present
    const requestResult: Subject<{ id: string; result: any }[]> = new ReplaySubject<{ id: string; result: any }[]>(); // Return te result from the requests
    const requestExtra: { id: string; value: string[]; request: (input: any) => Observable<any> }[] = []; // Extra request when necessary
    for (let index = 0; index < this.data.length; index++) {
      const item = this.data[index];
      const row: EdiRowTable[] = []; // Columns to display on each row
      for (let subIndex = 0; subIndex < this.columns.length; subIndex++) {
        const column = this.columns[subIndex];
        if (column.type === EdiRowTableType.TABLE) {
          // New table after the row
          this.secondaryTable.push({ columns: column.secondaryColumn, data: column.getValue(item, index) });
          this.columns.splice(subIndex, 1);
          continue;
        }
        if (column.type === EdiRowTableType.ACTION) {
          // Action buttons
          const columnRowAction: EdiRowTable = {
            ...column,
            value: column.getValue(item, index),
            data: item
          };
          row.push(columnRowAction);
          continue;
        }
        if (column.type === EdiRowTableType.IMAGE) {
          // Image
          const img = column.getValue(item, index);
          if (!img && item.ids?.gungId) {
            // No Image found try to request product by id
            const columnRowImage: EdiRowTable = {
              ...column,
              data: item
            };
            const request = requestExtra.find(r => r.id === 'getProductsByIds'); // Check if the request already exists
            if (request) {
              request.value.push(item.ids.gungId);
            } else {
              requestExtra.push({
                id: 'getProductsByIds',
                value: [item.ids.gungId],
                request: ids => this.productService.getProductsByIds(ids)
              });
            }
            requestResult.subscribe(allResult => {
              // When the request result exist add the image to the column
              const result = allResult.find(r => r.id === 'getProductsByIds')?.result;
              const product = result.find(p => p.id === item.ids.gungId);
              const imgProduct = columnRowImage.getValue(product);
              columnRowImage.value = imgProduct;
            });
            row.push(columnRowImage);
            continue;
          }
        }
        // Other type column
        const columnRow: EdiRowTable = {
          ...column,
          value: column.getValue(item, index)
        };
        row.push(columnRow);
      }
      table.push(row);
    }
    // CALL EXTRA REQUESTS
    if (requestExtra.length > 0) {
      const requests = requestExtra.map(r => r.request(r.value));
      forkJoin(requests)
        .pipe(
          map(result => {
            const mappedResult = [];
            for (let indexResult = 0; indexResult < result.length; indexResult++) {
              const element = result[indexResult];
              // Add ID to map each result to the request
              mappedResult.push({ id: requestExtra[indexResult].id, result: element });
            }
            requestResult.next(mappedResult);
          })
        )
        .subscribe(_ => {});
    }
    this.table = table;
    // V2 Angular Material Table
    if (this.showV2) {
      this.displayedColumns = this.columns.map(c => c.header);
      this.dataSource = this.table.map(row => {
        const newRow = {};
        for (const column of row) {
          newRow[column.header] = column.value;
        }
        return newRow;
      });
      console.log('displayedColumns', this.displayedColumns);
      console.log('dataSource', this.dataSource);
    }
  }
}
