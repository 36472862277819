import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, switchMap } from 'rxjs';
import { filter, first, map, mergeMap, tap } from 'rxjs';
import { ProductConcept } from '../../components/concept-detail-v2/concept-detail-v2.component';
import { GungBase } from '../../models';
import { User } from '../../state/auth/types';
import { Assortment } from '../assortment.service';
import { AuthService } from '../auth/auth.service';

export interface ProductAssortmentParentPathTreeMap {
  [s: string]: ProductAssortmentParentPath[];
}

export interface ProductAssortmentParentPath extends GungBase {
  sortIndex: number;
}

export interface SearchAssortments {
  assortments: { assortment: Assortment; path: GungBase[] }[];
  concepts: { concept: ProductConcept; path: GungBase[] }[];
}

@Injectable({
  providedIn: 'root'
})
export class ProductAssortmentParentService {

  constructor(protected http: HttpClient, protected authService: AuthService) {}

  protected productAssortmentParent: BehaviorSubject<ProductAssortmentParentPathTreeMap>;

  public getProductAssortmentParent(
    assortmentId: string,
    noCache?: boolean
  ): Observable<ProductAssortmentParentPathTreeMap> {
    if (this.productAssortmentParent && !noCache) {
      return this.productAssortmentParent;
    }
    this.productAssortmentParent = new BehaviorSubject(null);

    const url = `json/feature/product-assortment-parent/${assortmentId}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '7200');
    return this.http
      .get<ProductAssortmentParentPathTreeMap>(url, { headers })
      .pipe(tap(data => this.productAssortmentParent.next(data)));
  }

  public getProductAssortmentParentPathByCustomerAssortment(
    noCache?: boolean
  ): Observable<ProductAssortmentParentPathTreeMap> {
    if (this.productAssortmentParent && !noCache) {
      return this.productAssortmentParent;
    } else {
      return this.authService.getCurrentUser().pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          return this.getProductAssortmentParent(user.assortment).pipe(first());
        })
      );
    }
  }

  public getProductAssortmentParentPathById(productId: string, noCache?: boolean): Observable<string[]> {
    let request;
    if (this.productAssortmentParent && !noCache) {
      request = of(this.productAssortmentParent);
    } else {
      request = this.authService.getCurrentUser().pipe(
        filter(user => !!user),
        first(),
        mergeMap(user => {
          return this.getProductAssortmentParent(user.assortment).pipe(first());
        })
      );
    }

    return request.pipe(
      map(productAssortmentParent => {
        if (productAssortmentParent) {
          const currentProductAssortmentParent = productAssortmentParent[productId];
          if (currentProductAssortmentParent) {
            return currentProductAssortmentParent.map(path => path.id);
          }
        }
      })
    );
  }

  public getProductAssortmentParentByProductIds(productIds: string[], assortmentId: string) {
    const ids = productIds.join(',');
    const url = `json/feature/product-assortment-parent-by-product-ids/${assortmentId}?productIds=${ids}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '7200');
    return this.http.get<ProductAssortmentParentPathTreeMap>(url, { headers });
  }

  public postProductAssortmentParentByProductIds(productIds: string[], assortmentId?: string) {
    if (assortmentId) {
      const url = `json/feature/product-assortment-parent-by-product-ids/${assortmentId}`;
      return this.http.post<ProductAssortmentParentPathTreeMap>(url, productIds);
    }
    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      first(),
      mergeMap(user => {
        const url = `json/feature/product-assortment-parent-by-product-ids/${user.assortment}`;
        return this.http.post<ProductAssortmentParentPathTreeMap>(url, productIds);
      })
    );
  }

  getSearchAssortments(searchTerm: string, assortmentId?: string): Observable<SearchAssortments> {
    let requestAssortment;
    if (!!assortmentId) {
      requestAssortment = of({ assortment: assortmentId });
    } else {
      requestAssortment = this.authService.getCurrentUser().pipe(first());
    }
    return requestAssortment.pipe(
      switchMap((user: User) => {
        const url = `json/feature/search-assortments/${user.assortment}?query=${searchTerm}`;
        let headers = new HttpHeaders();
        headers = headers.set('maxAge', '1800');
        return this.http.get<SearchAssortments>(url, { headers });
      })
    );
  }
}
