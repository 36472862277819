import { Assortment, AssortmentService } from './../../../../services/assortment.service';
import { User } from './../../../../state/auth/types';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-user-details-subassortment',
  templateUrl: './user-details-subassortment.component.html',
  styleUrls: ['./user-details-subassortment.component.css']
})
export class UserDetailsSubassortmentComponent implements OnInit {
  @Input()
  currentUser: User;

  remainingAssortment: Assortment[] = [];
  includedAssortment: Assortment[] = [];

  constructor(protected assortmentService: AssortmentService) {}

  ngOnInit() {
    this.assortmentService.getAssortmentNoFilter(this.currentUser.assortment).subscribe(currentAssortment => {
      this.remainingAssortment = currentAssortment.children.filter(
        assortment => !this.currentUser.managedAssortmentIds.includes(assortment.id)
      );
      this.includedAssortment = currentAssortment.children.filter(assortment =>
        this.currentUser.managedAssortmentIds.includes(assortment.id)
      );
    });
  }

  assortmentManageAdd(assortment: Assortment) {
    this.currentUser.managedAssortmentIds.push(assortment.id);
    this.includedAssortment.push(assortment);
    this.remainingAssortment = this.remainingAssortment.filter(a => a.id !== assortment.id);
  }

  assortmentManageRem(assortment: Assortment) {
    this.remainingAssortment.push(assortment);
    this.currentUser.managedAssortmentIds = this.currentUser.managedAssortmentIds.filter(a => a !== assortment.id);
    this.includedAssortment = this.includedAssortment.filter(a => a.id !== assortment.id);
  }
}
