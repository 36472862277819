import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { GungBase } from '../../models';
import { TranslateService } from '@ngx-translate/core';

export interface RequestAccount extends GungBase {
  companyName: string;
	companyId: string;
	companyAddress: string;
	companyOrganisationId: string;
	companyCountry: string;
	companyContactName: string;
	companyContactEmail: string;
	companyContactPhone: string;

  processedByUserId?: string;
	processed?: boolean;
	rejected?: boolean;
	creationDate?: Date;
}
@Component({
  selector: 'lib-request-account',
  templateUrl: './request-account.component.html',
  styleUrls: ['./request-account.component.css']
})
export class RequestAccountComponent implements OnInit {
  @Output()
  returnToLogin = new EventEmitter<void>();

  accountForm: FormGroup;
  loader = false;
  alertMessage: {
    type: string;
    message: string;
  };

  constructor(protected formBuilder: FormBuilder, protected httpClient: HttpClient, protected translateService: TranslateService) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.accountForm = this.formBuilder.group({
      companyName: this.formBuilder.control('', [Validators.required]),
      companyId: this.formBuilder.control('', []),
      companyAddress: this.formBuilder.control('', [Validators.required]),
      companyOrganisationId: this.formBuilder.control('', [Validators.required]),
      companyCountry: this.formBuilder.control('', [Validators.required]),
      companyContactName: this.formBuilder.control('', [Validators.required]),
      companyContactEmail: this.formBuilder.control('', [Validators.required, Validators.email]),
      companyContactPhone: this.formBuilder.control('', [Validators.required])
    });
  }

  getFormControl(value): AbstractControl {
    return this.accountForm.get(value);
  }

  getButtonText(test: boolean) {
    return this.translateService.instant(test ? 'REQUEST_ACCOUNT' : 'REQUEST_INVALID_FORM');
  }

  onSubmit() {
    this.alertMessage = null;

    if (this.accountForm.invalid) {
      this.accountForm.markAllAsTouched();
      return;
    }

    this.accountForm.disable();
    this.loader = true;
    this.sendRequestAccount(this.accountForm.getRawValue());
  }

  sendRequestAccount(account: RequestAccount): void {
    const url = 'json/request-account';
    this.httpClient.post<any>(url, account).subscribe(
      response => {
        this.accountForm.enable();
        this.accountForm.reset();
        this.initForm();
        this.alertMessage = {
          type: 'success',
          message: 'ACCOUNT_REQUEST_SUCCESS'
        };
        this.loader = false;
      },
      error => {
        this.alertMessage = {
          type: 'danger',
          message: 'ACCOUNT_REQUEST_FAIL'
        };
        this.accountForm.enable();
        this.loader = false;
      }
    );
  }
}
