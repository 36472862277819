import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs';
import { Customer } from '../../../../models/customer';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';

@Component({
  selector: 'lib-delivery-locations',
  templateUrl: './delivery-locations.component.html',
  styleUrl: './delivery-locations.component.scss'
})
export class DeliveryLocationsComponent extends CheckoutStepComponent implements OnInit {
  form: FormGroup;
  selectedCustomer: Customer;
  alert: { message: string; type: string };

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected formBuilder: FormBuilder
  ) {
    super();
  }

  ngOnInit(): void {
    this.onNextBtnClicked.subscribe(_ => this.handleNextButtonClicked());
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(selectedCustomer => {
        this.selectedCustomer = selectedCustomer;
        this.initForm();
      });
  }

  public getLabel(): string {
    return 'DELIVERY_LOCATION';
  }

  initForm() {
    this.form = this.formBuilder.group({
      deliveryAddress: this.formBuilder.group({
        name: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.name || this.selectedCustomer.extra?.gme?.address?.name || ''
        ),
        address1: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.address1 || this.selectedCustomer.extra?.gme?.address?.address1 || ''
        ),
        address2: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.address2 || this.selectedCustomer.extra?.gme?.address?.address2 || ''
        ),
        phoneNo: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.phoneNo || this.selectedCustomer.extra?.gme?.address?.phoneNo || '',
          [Validators.pattern(/^[^a-zA-Z]*$/)]
        ),
        zipCode: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.zipCode || this.selectedCustomer.extra?.gme?.address?.zipCode || ''
        ),
        city: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.city || this.selectedCustomer.extra?.gme?.address?.city || ''
        ),
        countryCode: this.formBuilder.control(
          this.checkout.extra?.gme?.deliveryAddress?.countryCode ||
            this.selectedCustomer.extra?.gme?.address?.countryCode ||
            ''
        ),
      }),
      message: this.formBuilder.control(this.checkout.extra?.gme?.deliveryComment || ''),
      referenceNo: this.formBuilder.control(this.checkout.extra?.gme?.externalReference)
    });
  }

  protected handleNextButtonClicked(): void {
    if (this.form && this.form.invalid) {
      this.form.markAllAsTouched();
      this.alert = {
        message: 'FORM_CONTAINS_ERROR',
        type: 'warning'
      };
      return;
    }

    this.assignFormValues(this.form);
    this.stepDone.emit(this.checkout);
  }

  assignFormValues(form: FormGroup) {
    const rawFormData = form.getRawValue();
    console.log('rawFormData', rawFormData);
    this.checkout.extra = {
      ...this.checkout.extra,
      gme: {
        ...this.checkout.extra.gme,
        ...rawFormData
      }
    };
    
  }
}
