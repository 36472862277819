<div class="row contrast-container product-detail" *ngIf="product != null; else loading">
  <div class="col-xs-12 col-sm-6">
    <lib-image-carousel *ngIf="product" [openExternal]="false" [zoom]="false" [images]="product.extra.images">
    </lib-image-carousel>
  </div>
  <div class="col-xs-12 col-sm-6">
    <h1>{{ product.name }} - <span *ngIf="!isStandardErp">{{ product.id }}</span><span *ngIf="isStandardErp">{{ product.extra?.gme?.displayId }}</span></h1>
    <p>{{ product.description }}</p>

    <div class="row">
      <div class="col-12">
        <i class="fas fa-box"></i>&nbsp;
        <lib-availability [availability]="availability" [isSales]="isSales" [product]="product"></lib-availability>
      </div>
      <div class="col">
        <h4>
          <!-- <span>{{ 'NET_PRICE' | translate }}</span>: -->
          <strong>
            <lib-price [price]="price" type="customerNetPrice"></lib-price>
          </strong>
        </h4>
      </div>
    </div>

    <div class="product-card-button">
      <lib-buy-button-wrapper [product]="product" [availability]="availability"></lib-buy-button-wrapper>
    </div>

    <lib-gung-accordion>
      <lib-gung-accordion-item *ngIf="hasPimPropertiesToShow">
        <ng-template libGungAccordionHeader>{{ 'PRODUCT_DETAILS' | translate }}</ng-template>
        <ng-template libGungAccordionBody>
          <lib-cloud-pim-product-fields [product]="product"></lib-cloud-pim-product-fields>
        </ng-template>
      </lib-gung-accordion-item>
      <lib-gung-accordion-item *ngIf="visibleDocuments?.length > 0">
        <ng-template libGungAccordionHeader>{{ 'DOCUMENTS' | translate }}</ng-template>
        <ng-template libGungAccordionBody>
          <div class="table-responsive">
            <table class="table table-hover table-borderless table-striped table-sm">
              <thead>
                <tr>
                  <th translate>CATEGORY</th>
                  <th class="text-right" translate>DOWNLOAD_DOCUMENT</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let document of this.visibleDocuments">
                  <td>
                    {{ document.i18n[currentLang]?.description || document.category }}
                  </td>
                  <td class="text-right">
                    <a
                      target="_blank"
                      [href]="document | gungDownloadUrl"
                    >
                      <span translate>{{ document?.s3Uri?.split('/')?.reverse()[0] || 'DOCUMENT' }}</span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </ng-template>
      </lib-gung-accordion-item>         
    </lib-gung-accordion>

  </div>
</div>

<div class="row">
  <div class="col-12">
    <div *ngIf="product?.extra.pim?.relatedProducts">
      <h3 class="mb-0 mt-3" translate>RELATED_PRODUCTS</h3>
      <lib-product-list-by-ids [productIds]="product?.extra.pim?.relatedProducts"></lib-product-list-by-ids>
    </div>
  </div>
</div>

<ng-template #cloudPimFields>
  <lib-cloud-pim-product-fields [product]="product"></lib-cloud-pim-product-fields>
</ng-template>
<ng-template #nan></ng-template>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
