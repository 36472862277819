import { Component, Inject, Input, Optional } from '@angular/core';
import { AdyenService } from '../../services/adyen/adyen.service';
import { Router } from '@angular/router';
import { CheckoutStepComponent } from '../checkout/steps/base/checkout-step.component';
import { CheckoutObject } from '../../models/checkout';
import { AdyenPaymentCompletedEvent } from '../adyen-payment/adyen-payment.component';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'lib-adyen-payment-step',
  templateUrl: './adyen-payment-step.component.html',
  styleUrls: ['./adyen-payment-step.component.scss']
})
export class AdyenPaymentStepComponent extends CheckoutStepComponent {
  @Input()
  public checkout: CheckoutObject;
  payByLink: boolean = false;

  constructor(
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any },
    protected adyenService: AdyenService,
    protected router: Router,
    protected cartService: CartService
  ) {
    super();
  }

  ngOnInit(): void {
    // When we don't pay with adyen , we don't need to do anything
    if (!this.adyenService.isEnabled() || !this.adyenService.shouldUseAdyenStep(this.checkout)) {
      this.stepDone.emit(this.checkout);
      return;
    }
    this.payByLink = this.adyenService.shouldUsePayByLink(this.checkout);
  }

  protected onPaymentCompleted(event: AdyenPaymentCompletedEvent): void {
    if (event.data.resultCode === 'Authorised') {
      this.cartService.clearCart();
      this.router.navigateByUrl('payment-successful-adyen/' + event.orderId);
    } else {
      this.router.navigateByUrl('payment-failure-adyen');
    }
  }

  public isExternalPaymentStep(): boolean {
    return true;
  }
}
