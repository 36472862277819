import { Injectable } from '@angular/core';
import { Product } from '../../models';
import { gungComparatorHelper } from '../../utils/gung-utils';

@Injectable({
  providedIn: 'root'
})
export class ProductSortService {
  constructor() {}

  sortProducts(products: Product[]): Product[] {
    return products.sort((a, b) => gungComparatorHelper(a.extra?.pim?.sequence, b.extra?.pim?.sequence, -1))
  }
}
