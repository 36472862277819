import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OptionsList } from 'gung-common';
import { PimDimension } from '../../../models/pim';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { PimDimensionsService } from '../../../services/pim-dimensions/pim-dimensions.service';
import { PimMetadataService } from '../../../services/pim-metadata/pim-metadata.service';

@Component({
  selector: 'lib-pim-dimension-create-modal',
  templateUrl: './pim-dimension-create-modal.component.html',
  styleUrls: []
})
export class PimDimensionCreateModalComponent implements OnInit {
  isLoading = true;

  form: FormGroup;
  isSubmitted: boolean;

  translateLangs: string[];

  typeOptions: OptionsList[] = [];

  constructor(
    protected pimDimensionsService: PimDimensionsService,
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected pimMetadataService: PimMetadataService
  ) {}

  ngOnInit(): void {
    this.translateLangs = this.pimMetadataService.translateLanguages;
    this.pimDimensionsService.getPimDimensions().subscribe(dimensions => {
      const types = [...new Set(dimensions.map(d => d.type))];
      console.log(types);
      this.typeOptions = types.map(t => ({ id: t, name: t }));
      this.initForm();
    });
  }

  initForm() {
    this.form = this.formBuilder.group({
      extra: this.formBuilder.group({}),
      i18n: this.getFormI18n(),
      id: this.formBuilder.control('', [Validators.required]),
      name: this.formBuilder.control('', []),
      type: this.formBuilder.control('', [Validators.required])
    });
    this.isLoading = false;
  }

  getFormI18n(): FormGroup {
    const langs = {};
    for (const lang of this.translateLangs) {
      langs[lang] = this.formBuilder.group({
        description: this.formBuilder.control('')
      });
    }
    return this.formBuilder.group(langs);
  }

  onSave() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formRaw = this.form.getRawValue();
    console.log(this.form);
    this.form.disable();
    this.isSubmitted = true;
    const dimension: PimDimension = {
      ...formRaw,
      id: `${formRaw.id}@${formRaw.type}`,
      dimensionId: formRaw.id,
      name: formRaw.name,
      i18n: formRaw.i18n,
      type: formRaw.type
    };

    this.pimDimensionsService.postPimDimensions(dimension).subscribe(_ => {
      this.activeModal.close(JSON.stringify(dimension));
    });
  }
}
