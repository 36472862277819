import { Inject, Injectable, Optional } from '@angular/core';
import { NavbarConfigService } from './navbar-config/navbar-config.service';
import { NavigationConfig } from '../models/navigation-config';

@Injectable({
  providedIn: 'root'
})
export class HasLocalFeatureOverrideService {
  constructor(
    private navbarConfigService: NavbarConfigService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {}

  /**
   * This method exists to be a bridge between the old, locally configured features flags, and the new
   * backend owned feature flags. In order to not break any existing customers that has flagged locally,
   * we can add that feature here to allow it to override the old ones.
   * @param featureId the feature id we want to check if there exists an local override.
   */
  public hasLocalOverride(featureId: string): boolean {
    if (featureId === 'gung-activities-internal') {
      return this.navbarConfigService.enableActivityInternal;
    }

    if (featureId === 'gung-dynamic-pdf') {
      return this.environment.enableExportPdf;
    }

    if (featureId === 'gung-barcode-scanner') {
      return this.navbarConfigService.enableBarcodeScanner;
    }

    if (featureId === 'gung-warranty-claims') {
      return this.environment?.enableWarrantyClaims !== undefined ? this.environment?.enableWarrantyClaims : false;
    }

    if (featureId === 'gung-feature-digitalassets') {
      return this.environment?.enableDigitalAssets || false;
    }

    if (featureId === 'gung-discount-codes') {
      return this.navbarConfigService?.enableDiscountCodesManage;
    }

    return undefined;
  }
}
