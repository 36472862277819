<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>MANAGE_ASSORTMENTS</h4>
  <button type="button" class="close" aria-label="close" (click)="activeModal.dismiss('cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col">
      <lib-mongo-assortment-management></lib-mongo-assortment-management>
    </div>
  </div>
</div>

<div class="modal-footer"></div>
