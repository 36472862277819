import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { ExportDragProductService } from '../../services/export-drag-product.service';
import { PdfProductService } from '../../services/pdf-product.service';

@Component({
  selector: 'lib-export-product-template-modal',
  templateUrl: './export-product-template-modal.component.html',
  styleUrls: ['./export-product-template-modal.component.css']
})
export class ExportProductTemplateModalComponent implements OnInit {
  @Input()
  page: string;

  @Input()
  product;

  templates;

  selectedTemplateId: string;

  selectedTemplate;

  productsVariants = [];
  primaryVariantId = '';

  serviceProduct;
  flow: any;
  selectedCustomer: any;
  language: string;

  constructor(
    protected exportDragProductService: ExportDragProductService,
    public activeModal: NgbActiveModal,
    protected productService: PdfProductService
  ) {}

  ngOnInit(): void {
    if (this.product.fragment) {
      const index = this.templates.findIndex(t => t.template.fragment === this.product.fragment);
      if (index > -1) {
        this.changeTemplate();
        this.selectedTemplateId = this.templates[index].id;
        this.selectedTemplate = this.templates[index];
        if (this.selectedTemplate.template.isMultidimensional) {
          this.productsVariants.map(v => {
            v.selected = this.product.data.variantIds.includes(this.serviceProduct.modelId + v.id) ? true : false;
          });
          this.primaryVariantId = this.product.data.primaryVariantId;
        }
      }
    } else {
      this.selectedTemplateId = this.templates[0].id;
      this.selectedTemplate = this.templates[0];
    }
  }

  applyToProduct() {
    const pickTemplate = JSON.parse(JSON.stringify(this.templates.find(t => t.id === this.selectedTemplateId)));
    if (pickTemplate.template.isMultidimensional) {
      pickTemplate.template.data.variantIds = this.productsVariants
        .filter(v => v.selected)
        .map(v => this.serviceProduct.modelId + v.id);
      pickTemplate.template.data.primaryVariantId = this.primaryVariantId;
    } else {
      pickTemplate.template.data.variantIds = this.product.data.variantIds;
      pickTemplate.template.data.primaryVariantId = this.product.data.primaryVariantId
    }
    pickTemplate.template.data.productId = this.serviceProduct.id;
    pickTemplate.template.data.modelId = this.serviceProduct.modelId;
    pickTemplate.template.data.language = this.language;
    pickTemplate.productId = this.serviceProduct.id;
    if (pickTemplate.template.data.flowId) {
      pickTemplate.template.data.flowId = this.flow.id;
    }
    
    if (pickTemplate.template.data.customerId) {
      pickTemplate.template.data.customerId = this.selectedCustomer.id;
    }
    this.activeModal.close(pickTemplate);
  }

  changeTemplate() {
    this.productsVariants = [];
    const productsVariants = [];
    this.selectedTemplate = this.templates.find(t => t.id === this.selectedTemplateId);
    const productToGet = this.product.data?.productId || this.product.data?.variantIds[0];
    this.productService.getProduct(productToGet).pipe(first()).subscribe(product => {
      this.serviceProduct = product as any;
      this.productService.getProduct(this.serviceProduct.modelId).pipe(first()).subscribe(model => {
        for (const v of model.primaryDimension) {
          const variant = {
            name: v.name,
            id: v.id,
            selected:
              this.product.data.variantIds && this.product.data.variantIds.includes(model.id + v.id) ? true : false
          };
          productsVariants.push(variant);
        }
        this.productService.getProductsByIds(productsVariants.map(p => model.id + p.id)).pipe(first()).subscribe(productsBlock => {
          this.productsVariants = productsVariants.filter(variant => productsBlock.map(p => p.id).indexOf(model.id + variant.id) > -1);
          this.primaryVariantId = this.product.data?.productId && productsBlock?.find(p => p.id === this.product.data?.productId)?.primaryDimension[0].id || productsBlock?.length > 0 && productsBlock[0].primaryDimension[0].id;
        })
      });
    });
  }
}
