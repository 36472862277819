import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { CartRow } from '../../state/cart/types';
import { CartLogoApplicatorModalService } from '../../services/cart-logo-applicator/cart-logo-applicator-modal.service';
import { LogoApplication } from '../../models/logo-application';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'lib-cart-logo-applicator-modal',
  templateUrl: './cart-logo-applicator-modal.component.html',
  styleUrls: ['./cart-logo-applicator-modal.component.scss']
})
export class CartLogoApplicatorModalComponent implements OnInit {
  unsubscribe: Subject<boolean> = new Subject<boolean>();

  cartRow: CartRow;
  logoApplications: {};
  matchingLogoApplications: LogoApplication[] = [];

  delegate: CartLogoApplicatorModalService;

  isLoading = true;

  page = 1;
  pageSize = 3;

  constructor(protected cartService: CartService) {
    this.isLoading = false;
  }

  ngOnInit() {
    // TODO Instead of passing this we should fetch from endpoint in backend.
    // Current implementation due to time constraints.
    for (const key in this.logoApplications) {
      if (this.cartRow.productId.indexOf(key) === 0) {
        this.matchingLogoApplications.push(...this.logoApplications[key]);
      }
    }

    if (!this.cartRow.extra.appliedLogos) {
      this.cartRow.extra.appliedLogos = {};
    }
    this.matchingLogoApplications.forEach(application => {
      if (!this.cartRow.extra.appliedLogos[application.id]) {
        this.cartRow.extra.appliedLogos[application.id] = 0;
      }
    });
  }

  sub(application, amount) {
    if (this.cartRow.extra.appliedLogos[application.id] - amount >= 0) {
      this.cartRow.extra.appliedLogos[application.id] = this.cartRow.extra.appliedLogos[application.id] - amount;
    } else {
      this.cartRow.extra.appliedLogos[application.id] = 0;
    }
  }

  add(application, amount) {
    if (this.getRemainingRowQuantity() - amount >= 0) {
      this.cartRow.extra.appliedLogos[application.id] = this.cartRow.extra.appliedLogos[application.id] + amount;
    }
  }

  getRemainingRowQuantity() {
    let quantity = this.cartRow.qty;
    for (const key in this.cartRow.extra.appliedLogos) {
      if (this.cartRow.extra.appliedLogos.hasOwnProperty(key)) {
        quantity -= this.cartRow.extra.appliedLogos[key];
      }
    }
    return quantity;
  }

  inputChanged(application) {
    if (this.getRemainingRowQuantity() < 0) {
      const newValue = this.cartRow.extra.appliedLogos[application.id] - Math.abs(this.getRemainingRowQuantity());
      delete this.cartRow.extra.appliedLogos[application.id];
      this.cartRow.extra.appliedLogos[application.id] = newValue;
    }
  }

  close() {
    this.delegate.modalRef.get(CartLogoApplicatorModalComponent.name).close('ok');
  }

  save() {
    this.cartService.setExtra(this.cartRow.productId, this.cartRow.extra);
  }
}
