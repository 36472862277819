<div class="row mb-2" *ngIf="!minimize">
  <h3 class="col-12 page-title text-center" translate>EDI</h3>
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="gln-numbers">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can browse your EDI customers and change their settings"
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage GLN numbers</h5>
        <p class="card-text" *ngIf="!minimize">Here you can browse your EDI customers and change their settings</p>
      </div>
    </a>
  </div>

  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="import-xml">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Import EDI orders with XML-format directly into the ERP via Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Import EDI orders</h5>
        <p class="card-text" *ngIf="!minimize">Import EDI orders with XML-format directly into the ERP via Gung.</p>
      </div>
    </a>
  </div>
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="orders">
      <div class="card-body" [ngClass]="{ 'p-0': minimize }" title="Lists all EDI messages for overview and management">
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage orders</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can manage you orders and send them to ERP or change potential order failures
        </p>
      </div>
    </a>
  </div>
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="purchased-orders">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="  Here you can manage you purchase orders and send them to suppliers or resend orders with new updates"
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage Purchase Orders</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can manage you purchase orders and send them to suppliers or resend orders with new updates
        </p>
      </div>
    </a>
  </div>
  <!-- <div class="col-12 col-sm-4 mb-2">
    <div class="card border h-100" routerLink="gln-numbers">
      <div class="card-body" [ngClass]="{'p-0': minimize}" title="Here you can review deliveries that has been done and send delivery confirmations to GLN recivers.">
        <h5 class="card-title text-center" [ngClass]="{'my-2': minimize}">Manage deliveries</h5>
        <p class="card-text" *ngIf="!minimize">Here you can review deliveries that has been done and send delivery confirmations to GLN recivers.</p>
      </div>
    </div>
  </div> -->
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="order-responses">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can review and manage order responses on orders that has been reveived through Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage order responses</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can review and manage order responses on orders that has been reveived through Gung.
        </p>
      </div>
    </a>
  </div>
  <div class="col-12 col-sm-4 mb-2" *ngIf="incomingOrderResponsesEnabled">
    <a class="card border h-100" routerLink="incoming-order-responses">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can review and manage order responses on orders that has been reveived through Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage incoming order responses</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can review and manage incoming order responses that has been reveived through Gung.
        </p>
      </div>
    </a>
  </div>
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="invoices">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can review and manage invoices for orders that has been received through Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Manage invoice</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can review and manage invoices for orders that has been received through Gung.
        </p>
      </div>
    </a>
  </div>
  <!-- <div class="col-12 col-sm-4 mb-2">
    <div class="card border h-100" routerLink="joint-order-summary">
      <div class="card-body" [ngClass]="{'p-0': minimize}" title="Here you can review and manage invoices for orders that has been received through Gung.">
        <h5 class="card-title text-center" [ngClass]="{'my-2': minimize}">Joint order summary</h5>
        <p class="card-text" *ngIf="!minimize">Detail Page & List</p>
      </div>
    </div>
  </div> -->
  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="incoming-despatch-advices">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can review and manage despatch advice for orders that has been received from your suppliers through Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Despatch advice - Suppliers</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can review and manage despatch advice for orders that has been received from your suppliers through
          Gung.
        </p>
      </div>
    </a>
  </div>

  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="dispatch-advices">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can review and manage despatch advice for orders that has been received through Gung."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">Despatch advice</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can review and manage despatch advice for orders that has been received through Gung.
        </p>
      </div>
    </a>
  </div>

  <div class="col-12 col-sm-4 mb-2">
    <a class="card border h-100" routerLink="/admin/ean/ean-repository">
      <div
        class="card-body"
        [ngClass]="{ 'p-0': minimize }"
        title="Here you can see the EAN Repository that Gung has synced from your ERP."
      >
        <h5 class="card-title text-center" [ngClass]="{ 'my-2': minimize }">EAN Repository</h5>
        <p class="card-text" *ngIf="!minimize">
          Here you can see the EAN Repository that Gung has synced from your ERP.
        </p>
      </div>
    </a>
  </div>
</div>
<router-outlet></router-outlet>
