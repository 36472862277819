import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs';
import { EdiInvoice, GungEdimessageType } from '../../models/edi';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';
import { AuthService } from '../auth/auth.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { EdiMessageBaseActionConfigService } from '../edi-message-base-action-config/edi-message-base-action-config.service';
import { EdiService } from '../edi/edi.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService, GungNotificationService } from 'gung-common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EdiInvoicesActionConfigService extends EdiMessageBaseActionConfigService {
  [x: string]: any;

  constructor(
    protected ediService: EdiService,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected backend: BackendInterceptor,
    protected router: Router,
    protected http: HttpClient,
    protected gungNotificationService: GungNotificationService
  ) {
    super(ediService, gungModalService, authService, backend, router, http, gungNotificationService);
  }

  protected getMessageType(): GungEdimessageType {
    return GungEdimessageType.INVOICE;
  }
}
