import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ComponentFactoryResolver,
  Type,
  AfterViewInit,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import {
  PaginationConfigService,
  ListItemRendererComponent,
  RenderFilter,
  ListLayoutComponent,
  SearchRequest
} from '../types';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import { Observable, Subject, Subscription, BehaviorSubject, interval } from 'rxjs';
import { FilterListService } from '../services/filter-list.service';
import { debounce, first, switchMap } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ListLayout } from '../types';

@Component({
  selector: 'lib-pagination-list[configService]',
  templateUrl: './pagination-list.component.html',
  styleUrls: ['./pagination-list.component.css']
})
export class PaginationListComponent<T> implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  private configService: PaginationConfigService<T>;

  @ViewChild(CustomHostDirective, { static: true })
  private viewChild: CustomHostDirective;

  @Output()
  loaded = new EventEmitter<boolean>();

  subscriptions: Subscription[] = [];

  private searchRequest: Subject<SearchRequest> = new Subject<SearchRequest>();
  public searching = false;

  public searchTerms: Subject<string[]> = new Subject<string[]>();
  public terms = [];

  public searchSkip: Subject<number> = new Subject<number>();
  public skip = 0;

  public searchLimit: BehaviorSubject<number> = new BehaviorSubject<number>(24);
  public limit = 0;

  private assortment = 'sROT';
  private customerId: string;

  public layouts: ListLayout<T>[];
  public renderFilters: RenderFilter[];

  public shouldDisplayOverlay: boolean;
  public batchSizes: number[];
  public batchSize: number;

  public items: T[];
  public itemsCount: number;
  public itemsStep: number;
  public filteredItems: T[];

  public renderItemsSubject = new Subject<T[]>();
  public renderItems: Observable<T[]> = this.renderItemsSubject.asObservable();
  private loadingVar = true;

  set loading(value: boolean) {
    this.loadingVar = value;
    this.loaded.emit(!value);
  }

  get loading() {
    return this.loadingVar;
  }

  searchButtonEnabled = false;
  searchButtonText = 'SEARCH';
  searchString: string;

  loadSearch = false;
  loadBatch = false;

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    private componentFactoryResolver: ComponentFactoryResolver,
    public changeDetectorRef: ChangeDetectorRef,
    private filterListService: FilterListService<T>
  ) {}

  setSearch(searchString: string) {
    const stringArray = searchString.split(' ');
    this.terms = stringArray;
    this.refreshRouterQuery();
    this.searchTerms.next(stringArray);
  }

  setSearchString(searchString: string) {
    this.searchString = searchString;
  }

  setSkip(value: number) {
    this.skip += value;
    if (this.skip < 0) {
      this.skip = 0;
    }
    if (this.skip > this.itemsCount - this.limit) {
      this.skip = this.itemsCount - this.limit;
    }
    this.refreshRouterQuery();
    this.searchSkip.next(this.skip);
  }

  refreshRouterQuery() {
    this.router.navigate([], {
      relativeTo: this.route,
      queryParams: { products: this.skip, limit: this.limit, terms: this.terms },
      queryParamsHandling: 'merge'
    });
  }

  setLimit(value: number) {
    this.searchSkip.next(0);
    this.limit = value;
    this.batchSize = value;
    this.refreshRouterQuery();
    this.searchLimit.next(value);
  }

  updateRequest() {
    this.searching = true;
    this.searchRequest.next({
      terms: this.terms,
      limit: this.limit,
      skip: this.skip,
      assortment: this.assortment,
      customerId: this.customerId
    });
  }

  ngOnInit(): void {
    if (this.configService.getSearchButtonEnabled) {
      this.searchButtonEnabled = this.configService.getSearchButtonEnabled();
    }
    if (this.configService.getSearchButtonText) {
      this.searchButtonText = this.configService.getSearchButtonText();
    }

    this.searching = true;
    this.route.queryParams.pipe(first()).subscribe(params => {
      if (params.terms) {
        this.setSearch(params.terms);
        this.searchString = params.terms;
      }
      if (params.limit) {
        this.limit = Number(params.limit);
        this.setLimit(this.limit);
      }
      if (params.products) {
        this.skip = Number(params.products);
      }
      this.loadSearch = true;
      this.loadBatch = true;
    });
    const requestSubscription = this.searchRequest
      .asObservable()
      .pipe(switchMap(request => this.configService.getPagedItems(request)))
      .subscribe(response => {
        this.searching = false;
        this.items = response.items;
        this.itemsCount = response.totalItems;
        this.itemsStep = response.skipped;
        this.renderItemsSubject.next(this.items);
      });

    const searchSubscription = this.searchTerms
      .asObservable()
      .pipe(debounce(() => interval(250)))
      .subscribe(v => {
        this.terms = v;
        this.updateRequest();
      });

    const skipSubscription = this.searchSkip
      .asObservable()
      .pipe(debounce(() => interval(250)))
      .subscribe(v => {
        this.skip = v;
        this.updateRequest();
      });

    const limitSubscription = this.searchLimit
      .asObservable()
      .pipe(debounce(() => interval(250)))
      .subscribe(v => {
        this.limit = v;
        this.updateRequest();
      });

    const setupSubscription = this.configService.getItems().subscribe(ps => {
      this.items = ps;
      this.loading = false;
      this.shouldDisplayOverlay = false;
      this.renderFilters = this.filterListService.getInitializedFilters(this.items, this.configService);
      this.batchSizes = this.configService.getBatchSizes() ? this.configService.getBatchSizes() : [12];
      this.layouts = this.configService.getLayouts();
      if (!this.limit) {
        this.limit = this.batchSizes[0];
      }
      this.batchSize = this.batchSizes[0];
    });

    this.subscriptions.push(
      requestSubscription,
      searchSubscription,
      skipSubscription,
      limitSubscription,
      setupSubscription
    );
  }

  ngAfterViewInit(): void {
    const layouts = this.configService.getLayouts();
    const toRender = layouts[0];
    this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  changeLayout(index: number) {
    this.renderLayout(this.layouts[index].getListLayoutComponent(), this.layouts[index].getListItemComponent());
  }

  renderLayout(
    layoutComponent: Type<ListLayoutComponent<T>>,
    itemComponent: Type<ListItemRendererComponent<T | T[]>>
  ): void {
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as ListLayoutComponent<T>;
    typedComponent.listItemRenderer = itemComponent;
    typedComponent.renderItems = this.renderItems;
    this.changeDetectorRef.detectChanges();
  }

  getLoadMoreCss(): string {
    if (!!this.configService.getLoadMoreCss) {
      return this.configService.getLoadMoreCss();
    } else {
      return 'btn-primary btn-block';
    }
  }

  getFilterGroupCss(): string {
    if (!!this.configService.getFilterGroupCss) {
      return this.configService.getFilterGroupCss();
    } else {
      return '';
    }
  }

  getNothingFoundTranslateTag(): string {
    if (!!this.configService.getNothingFoundTranslateTag) {
      return this.configService.getNothingFoundTranslateTag();
    } else {
      return 'NOTHING_FOUND_CONTACT_GUNG';
    }
  }

  getSearchGroupCss(): string {
    if (!!this.configService.getSearchGroupCss) {
      return this.configService.getSearchGroupCss();
    } else {
      return '';
    }
  }
}
