import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OptionsListMulti } from 'gung-common';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { MetadataService } from '../../services/metadata/metadata.service';

@Component({
  selector: 'lib-meta-data-checkbox-input',
  templateUrl: './meta-data-checkbox-input.component.html',
  styleUrls: ['./meta-data-checkbox-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaDataCheckboxInputComponent),
      multi: true
    }
  ]
})
export class MetaDataCheckboxInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  public labelClass: string | string[];

  @Input()
  private table: string;

  @Input()
  private metaId: string;

  @Input()
  private metaDisplay: string;

  @Input()
  public label: string;

  @Input()
  public suppressLabel?: boolean;

  @Input()
  public renderStatic?: boolean;

  @Input()
  public showId?: boolean;

  @Input()
  public outputType?: string;

  @Input()
  public previewField?: string;

  @Input()
  public tooltip?: string;

  @Input()
  public horizontal? = true;

  selectedValue: string;

  id: string;

  disabled = false;

  @Input()
  selectedOptions: any;
  @Output()
  selectedOptionsChange = new EventEmitter<any>();

  metaOptions$: Observable<{ key: string; value: string }[]>;

  metadataOptions: OptionsListMulti[];

  onChange = (_: any) => {};

  get selected() {
    return this.selectedValue;
  }

  set selected(val: string) {
    this.selectedValue = val;
    this.onChange(this.selectedValue);
  }

  writeValue(val: any): void {
    if (val !== undefined) {
      this.selected = val;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor(private metadataService: MetadataService, private languageService: TranslateService) {}

  ngOnInit() {
    const lang = this.languageService.currentLang;

    const translateKey = 'i18n';

    this.id = `uuid$${uuidv4()}`;
    this.metadataService.getMetadataValues$(this.table, this.metaId).subscribe(metadata => {
      this.metadataOptions = this.metadataOptions || [];
      Object.keys(metadata)
        .sort((a, b) => a.localeCompare(b))
        .forEach(key => {
          const metaPost = metadata[key];
          let metaOption: OptionsListMulti;
          if (metaPost[translateKey] && metaPost[translateKey][lang]) {
            metaOption = {
              id: metaPost[this.metaId],
              name: metaPost[translateKey][lang][this.metaDisplay]
                ? metaPost[translateKey][lang][this.metaDisplay]
                : metaPost[this.metaDisplay],
              selected: false
            };
          } else {
            metaOption = {
              id: metaPost[this.metaId],
              name: metaPost[this.metaDisplay],
              selected: false
            };
          }
          this.metadataOptions.push(metaOption);
        });
      if (!this.selectedOptions) {
        return;
      }
      this.metadataOptions.forEach(element => {
        if (this.outputType && this.outputType === 'string') {
          const spliitedSelectedOptions = this.selectedOptions.split(',');
          if (spliitedSelectedOptions && spliitedSelectedOptions.indexOf(element.id) >= 0) {
            element.selected = true;
          }
        } else {
          if (this.selectedOptions && this.selectedOptions[element.id]) {
            element.selected = true;
          }
        }
      });
    });
  }

  setSelectedValues(event: string[]) {
    // { 'SUEDE': true, 'LEATHER': false }
    // suitable.material { 'MATERIAL1': true, 'MATERIAL2': false }
    let payloadMetadata; // = this.metadataOptions.map(m => ( m.key: m.selected ));
    if (this.outputType && this.outputType === 'string') {
      payloadMetadata = event.join(',');
    } else {
      this.metadataOptions.forEach(element => {
        const option = event.find(o => o === element.id);
        if (option) {
          payloadMetadata = {
            ...payloadMetadata,
            [element.id]: true
          };
        } else {
          payloadMetadata = {
            ...payloadMetadata,
            [element.id]: false
          };
        }
      });
    }
    this.selectedOptionsChange.emit(payloadMetadata);
  }
}
