import { AfterViewInit, ChangeDetectorRef, Component, ComponentRef, ElementRef, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { GungSettingsBaseViewComponent } from '../gung-settings-base-view/gung-settings-base-view.component';
import { Router } from '@angular/router';
import { GungSettingsConfigService, GungSettingsMenuItem } from '../../services/gung-settings-config.service';
import { first } from 'rxjs';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-gung-settings',
  templateUrl: './gung-settings.component.html',
  styleUrls: ['./gung-settings.component.css']
})
export class GungSettingsComponent implements AfterViewInit, OnDestroy {
  @ViewChild('hostView', { read: ViewContainerRef }) hostView;
  protected currentView: ComponentRef<GungSettingsBaseViewComponent>;

  public gungSettingsMenu: GungSettingsMenuItem[] = [];
  public selectedIndex: number = -1;

  public sideMenuOpen: boolean = false;

  constructor(
    protected router: Router,
    protected gungSettingsConfigService: GungSettingsConfigService,
    protected _elementRef: ElementRef,
    protected gungModalService: GungModalService,
    private changeDetectorRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit(): void {
    this.hostView.clear();

    this.gungSettingsConfigService.getGungSettingsMenu().pipe(first()).subscribe(menu => {
      this.gungSettingsMenu = menu;

      if (this.gungSettingsMenu.length > 0) {
        // Settings available, open first menu item
        this.selectMenuItem(this.getFirstEnabledLinkIndex());
      } else {
        // No settings available, go to homepage
        this.router.navigate(['']);
      }
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy(): void { }

  public selectMenuItem(index: number): void {
    if (!!this.currentView?.instance?.unsavedChanges) {
      this.gungModalService.openConfirmYesNoModal('UNSAVED_CHANGES_HEADING', 'UNSAVED_CHANGES_CONTENT', undefined, 'CONTINUE', 'CANCEL').then(
        result => {
          if (!!result) {
            this.changeView(index);
          }
        },
        reject => { }
      )
    } else {
      this.changeView(index);
    }
  }

  protected changeView(index: number): void {
    this.hostView.clear();
    if (!!this.gungSettingsMenu?.[index]?.componentView) {
      this.selectedIndex = index;
      this.currentView = this.hostView.createComponent(this.gungSettingsMenu[index].componentView);

      if (this.sideMenuOpen) {
        this.closeSideMenu();
      }
    } else {
      this.selectedIndex = -1;
    }
  }

  protected getFirstEnabledLinkIndex(): number {
    return this.gungSettingsMenu.findIndex(i => !i.hide && !i.disabled);
  }

  public toggleSideMenu(): void {
    this.sideMenuOpen = !this.sideMenuOpen;
  }

  public closeSideMenu(): void {
    this.sideMenuOpen = false;
  }
}
