import { Injectable } from '@angular/core';
import { SimpleConfigBaseFilter } from 'gung-list';
import { format, isDate, parseISO } from 'date-fns';
import { MetadataService } from '../../services/metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class CreationDateFilterService extends SimpleConfigBaseFilter<any> {
  constructor(protected metadataService: MetadataService) {
    super();
  }

  creationDateFormatter(item: any) {
    try {
      const formattedDate = format(this.parseDate(item.creationDate), 'yyyy-MM');
      return formattedDate;
    } catch {
      return 'OTHER';
    }
  }

  getOptionIds(item: any): string[] {
    const date = this.creationDateFormatter(item);
    return [date];
  }

  getOptionName(key: string): string {
    return key;
  }

  getName(): string {
    return 'CREATION_DATE_FILTER';
  }

  private parseDate(value: any): Date {
    if (!value) {
      return null;
    }

    if (isDate(value)) {
      return value;
    }

    return parseISO(value + '');
  }
}
