<div class="pt-4" *ngIf="!loading; else loading">
  <canvas
    baseChart
    *ngIf="!tableOnly"
    [datasets]="barChartData"
    [labels]="barChartLabels"
    [options]="barChartOptions"
    [legend]="barChartLegend"
    [type]="'bar'"
  >
  </canvas>

  <div *ngIf="heading === 'PRODUCTS'" class="input-group">
    <input
      type="text"
      class="form-control"
      placeholder="{{ 'SEARCH_BAR' | translate }}"
      (keyup)="searchData.next($event)"
      aria-label="Search"
    />
    <div class="input-group-append">
      <span class="input-group-text">
        <i class="fa fa-search" aria-hidden="true"></i>
      </span>
    </div>
  </div>
  <div class="pt-4">
    <div class="table-responsive">
      <table
        class="table table-striped"
        mat-table
        [dataSource]="dataSource"
        matSort
        matSortActive="value"
        matSortDirection="asc"
        matSortStart="desc"
        matSortDisableClear
      >
        <!--ProductId-->
        <ng-container matColumnDef="productId">
          <th
            disabled
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            style="vertical-align: middle; text-align: left"
          >
            <span translate>PRODUCT_ID</span>
          </th>
          <td style="vertical-align: middle; text-align: left" mat-cell *matCellDef="let element">
            <span *ngIf="!!element.elements[0].productModelId; else productId">{{
              element.elements[0].productModelId
            }}</span>
            <ng-template #productId>
              <span *ngIf="!!element.elements[0].productId">{{ element.elements[0].productId }}</span>
            </ng-template>
          </td>
        </ng-container>

        <!--KEY-->
        <ng-container matColumnDef="key">
          <th mat-header-cell mat-sort-header *matHeaderCellDef style="vertical-align: middle">
            {{ heading | translate }}
          </th>
          <td style="vertical-align: middle" mat-cell *matCellDef="let element">
            <a *ngIf="groupBy === 'orderId'" [href]="'orders/' + element.key">{{ element.key }}</a>
            <span *ngIf="groupBy !== 'orderId'">{{ element.key }}</span>
          </td>
        </ng-container>

        <!--TOTAL SALES-->
        <ng-container matColumnDef="value">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            arrowPosition="before"
            style="vertical-align: middle; text-align: right"
          >
            <span translate>ORDER_ANALYTICS_SALES</span>
          </th>
          <td style="vertical-align: middle; text-align: right" mat-cell *matCellDef="let element">
            {{ element.value | gungCurrency: '':'code' }}
          </td>
        </ng-container>

        <!--QUANTITY-->
        <ng-container matColumnDef="count">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            arrowPosition="before"
            style="vertical-align: middle; text-align: right"
          >
            <span translate>QUANTITY</span>
          </th>
          <td style="vertical-align: middle; text-align: left; text-align: right" mat-cell *matCellDef="let element">
            {{ element.count }}
          </td>
        </ng-container>

        <!--Orders-->
        <ng-container *ngIf="groupBy !== 'orderId'" matColumnDef="orderCount">
          <th
            mat-header-cell
            mat-sort-header
            *matHeaderCellDef
            arrowPosition="before"
            style="vertical-align: middle; text-align: right"
          >
            <span translate>ORDERS</span>
          </th>
          <td style="vertical-align: middle; text-align: right" mat-cell *matCellDef="let element">
            {{ element.orderCount }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </div>

    <h2 translate>Total</h2>
    <div class="table-responsive">
      <table class="table" style="border: none">
        <thead>
          <tr>
            <th></th>
            <th translate>ORDER_ANALYTICS_SALES</th>
            <th translate>QUANTITY</th>
            <th translate>ORDERS</th>
          </tr>
        </thead>
        <tbody>
          <tr style="border-bottom: 2px solid black">
            <td style="padding: 0 12 0 24px; text-align: right" translate>TOTAL</td>
            <td style="text-align: right">{{ summaryTotals.value | gungCurrency: '':'code' }}</td>
            <td style="text-align: right">{{ summaryTotals.count }}</td>
            <td style="text-align: right" *ngIf="groupBy !== 'orderId'">{{ summaryTotals.orderCount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="text-center dashboard-loading-spinner" *ngIf="loading">
  <div class="spinner-border" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div>
