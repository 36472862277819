<div class="row">
  <div class="col-12 col-md-4">
    <h4 translate>DELIVERY_ADDRESS</h4>
    <div class="info-area">
      @if (checkout.extra.gme.deliveryAddress.name) {
        <span class="d-block"> <b translate>NAME</b>: {{ checkout.extra.gme.deliveryAddress.name }} </span>
      }
      @if (checkout.extra.gme.deliveryAddress.address1) {
        <span class="d-block"> <b translate>ADDRESS</b>: {{ checkout.extra.gme.deliveryAddress.address1 }} </span>
      }
      @if (checkout.extra.gme.deliveryAddress.address2) {
        <span class="d-block"> <b translate>ADDRESS</b>: {{ checkout.extra.gme.deliveryAddress.address2 }} </span>
      }
      @if (checkout.extra.gme.deliveryAddress.city) {
        <span class="d-block"> <b translate>CITY</b>: {{ checkout.extra.gme.deliveryAddress.city }} </span>
      }
      @if (checkout.extra.gme.deliveryAddress.zipCode) {
        <span class="d-block"> <b translate>ZIP</b>: {{ checkout.extra.gme.deliveryAddress.zipCode }} </span>
      }
      @if (checkout.extra.gme.deliveryAddress.countryCode) {
        <span class="d-block">
          <b translate>COUNTRYCODE</b>: {{ checkout.extra.gme.deliveryAddress.countryCode }}
        </span>
      }
    </div>
  </div>
  <div class="col-12 col-md-4">
    <h4 translate>INVOICE_ADDRESS</h4>
    <div class="info-area">
      @if (checkout.extra.gme.billingAddress.name) {
        <span class="d-block"> <b translate>NAME</b>: {{ checkout.extra.gme.deliveryAddress.name }} </span>
      }
      @if (checkout.extra.gme.billingAddress.address1) {
        <span class="d-block"> <b translate>ADDRESS</b>: {{ checkout.extra.gme.deliveryAddress.address1 }} </span>
      }
      @if (checkout.extra.gme.billingAddress.address2) {
        <span class="d-block"> <b translate>ADDRESS</b>: {{ checkout.extra.gme.deliveryAddress.address2 }} </span>
      }
      @if (checkout.extra.gme.billingAddress.city) {
        <span class="d-block"> <b translate>CITY</b>: {{ checkout.extra.gme.deliveryAddress.city }} </span>
      }
      @if (checkout.extra.gme.billingAddress.zipCode) {
        <span class="d-block"> <b translate>ZIP</b>: {{ checkout.extra.gme.deliveryAddress.zipCode }} </span>
      }
      @if (checkout.extra.gme.billingAddress.countryCode) {
        <span class="d-block">
          <b translate>COUNTRYCODE</b>: {{ checkout.extra.gme.deliveryAddress.countryCode }}
        </span>
      }
    </div>
  </div>
  <div class="col-12 col-md-4">
    <h4 translate>CONDITIONS</h4>
    <div class="info-area">
      @if (checkout.extra.gme.termsOfPayment) {
        <span class="d-block">
          <b translate>PAYMENT_TERMS</b>:
          <lib-gung-meta-output
            suppressLabel="true"
            [value]="checkout.extra.gme.termsOfPayment"
            metadataTable="paymentTerms"
            metaOptionName="name"
          />
        </span>
      }

      @if (checkout.extra.gme.termsOfDelivery) {
        <span class="d-block">
          <b translate>DELIVERY_TERMS</b>:
          <lib-gung-meta-output
            suppressLabel="true"
            [value]="checkout.extra.gme.termsOfDelivery"
            metadataTable="deliveryTerms"
            metaOptionName="name"
          />
        </span>
      }

      @if (checkout.extra.gme.deliveryMethod) {
        <span class="d-block">
          <b translate>DELIVERY_METHOD</b>:
          <lib-gung-meta-output
            suppressLabel="true"
            [value]="checkout.extra.gme.deliveryMethod"
            metadataTable="deliveryMethods"
            metaOptionName="name"
          />
        </span>
      }
    </div>
  </div>
</div>

@if(productsMap && pricesMap){
  <div>
    <h4 translate>ROWS</h4>
    @if(!isloading){
      <div class="table-responsive gung-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>DESCRIPTION</th>
              <th translate>QUANTITY</th>
              <th translate>UNIT_PRICE</th>
              <th translate>DISCOUNT</th>
              <th translate>TOTAL</th>
            </tr>
          </thead>
          <tbody>
            @for(sumaryRow of cartSummaryTableData.rows; track sumaryRow.checkoutRow.id; let i = $index){
              <tr>
                <td class="table-no-label">
                  <div class="row">
                    <div class="col-4 col-lg-2">
                      <img
                        *ngIf="
                          productsMap[sumaryRow.checkoutRow.id].extra.images &&
                          productsMap[sumaryRow.checkoutRow.id].extra.images.length > 0
                        "
                        [src]="productsMap[sumaryRow.checkoutRow.id].extra.images[0].s3Uri | gungImageUrl
                          :'type:list'
                          :'etag:'+productsMap[sumaryRow.checkoutRow.id].extra.images[0].s3ETag
                        "
                        alt="{{ productsMap[sumaryRow.checkoutRow.id].extra.images[0].description }}"
                        class="img-fluid" style="max-width: 64px;"
                      />
                    </div>
      
                    <div class="col-8 col-lg-3">
                      <div class="table-product-name">
                        <strong>{{ productsMap[sumaryRow.checkoutRow.id].name }}</strong>
                      </div>
                      <div class="table-product-name-id">
                        <div *ngIf="sumaryRow.isMultiDimension">{{ sumaryRow.primaryDimensionName }}</div>
                        <div>{{ sumaryRow.productId }}</div>
                      </div>
                    </div>
      
                    <div class="col-auto" *ngIf="sumaryRow.isMultiDimension">
                      <table class="table table-borderless table-condensed table-sm table-summary-size-qty">
                        <tbody>
                          <tr class="small">
                            <td>
                              <b>{{ 'SIZE' | translate }}</b>
                            </td>
                            <td class="text-center" *ngFor="let secondaryDimension of sumaryRow.secondaryDimension">
                              {{ secondaryDimension.size }}
                            </td>
                          </tr>
                          <tr class="small">
                            <td>
                              <b>{{ 'QUANTITY' | translate }}</b>
                            </td>
                            <td class="text-center" *ngFor="let secondaryDimension of sumaryRow.secondaryDimension">
                              {{ secondaryDimension.qty }}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </td>
      
                <td [attr.data-label]="'QUANTITY' | translate">
                  {{ sumaryRow.totals.qty }}
                </td>
      
                <td [attr.data-label]="'UNIT_PRICE' | translate">
                  <span *ngIf="pricesMap">
                    {{
                      pricesMap[sumaryRow.checkoutRow.id].cartRowUnitPrice.value
                        | gungCurrency: pricesMap[sumaryRow.checkoutRow.id].cartRowUnitPrice.currencyCode:'code'
                    }}
                  </span>
                </td>
      
                <td [attr.data-label]="'DISCOUNT' | translate">
                  <span *ngIf="pricesMap">
                    {{
                      -1 * pricesMap[sumaryRow.checkoutRow.id].cartRowDiscountAmountTotal.value
                        | gungCurrency: pricesMap[sumaryRow.checkoutRow.id].cartRowUnitPrice.currencyCode:'code'
                    }}
                  </span>
                </td>
      
                <td [attr.data-label]="'TOTAL' | translate">
                  <span *ngIf="pricesMap">
                    {{ sumaryRow.totals.price.value | gungCurrency: sumaryRow.totals.price.currencyCode:'code' }}
                  </span>
                </td>
              </tr>
            }
            
          </tbody>
          <tfoot>
            <tr>
              <th class="gung-r-display-n" style="text-align: right">
                <strong translate>TOTALS_TOTALITEMS</strong>
              </th>
              <th [attr.data-label]="'TOTALS_TOTALITEMS' | translate">
                {{ cartSummaryTableData.totals.qty }}
              </th>
              <th class="gung-r-display-n" style="text-align: right">
                <strong> {{ 'TOTAL_VALUE' | translate }}:</strong>
              </th>
              <th [attr.data-label]="'TOTAL_VALUE' | translate">
                {{
                  cartSummaryTableData.totals.price.value
                    | gungCurrency: cartSummaryTableData.totals.price.currencyCode:'code'
                }}
              </th>
              <th></th>
            </tr>
          </tfoot>
        </table>
      </div>
    }@else{
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    }
    
  </div>
}


