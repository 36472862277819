import { defer, forkJoin, Observable, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbProgressbarConfig } from '@ng-bootstrap/ng-bootstrap';
import { PimService } from '../../../services/pim/pim.service';
import { catchError, tap } from 'rxjs';

@Component({
  selector: 'lib-pim-upload-modal',
  templateUrl: './pim-upload-modal.component.html',
  styleUrls: ['./pim-upload-modal.component.css']
})
export class PimUploadModalComponent implements OnInit {
  @Input()
  uploadType: 'document' | 'image'

  @Input()
  fnUpload;

  public uploadedFiles = {
    total: 0,
    completed: 0,
    failed: 0,
    initialized: 0,
    error: []
  };

  files: File[] = [];
  uploading: boolean;
  valueProgressbar = 0;

  constructor(protected pimService: PimService, public activeModal: NgbActiveModal, config: NgbProgressbarConfig) {
    // customize default values of progress bars used by this component tree
    config.max = 100;
    config.striped = true;
    config.animated = true;
    config.type = 'success';
    config.height = '20px';
  }

  ngOnInit() {}

  onUpload() {
    if (this.files.length === 0) {
      return;
    }
    this.uploading = true;
    this.uploadedFiles = {
      total: 0,
      completed: 0,
      failed: 0,
      initialized: 0,
      error: []
    };
    if (this.fnUpload) {
      this.fnUpload(this.files, this.uploadedFiles, () => this.updateProgressBar()).subscribe(next => {
        this.uploading = false;
        this.valueProgressbar = 0;
        this.activeModal.close();
      });
      return;
    }
    const files: File[] = this.files;
    const now = new Date().toISOString();
    const listHttpPost = [];
    this.valueProgressbar = 0;
    for (const file of files) {
      this.uploadedFiles.total++;
      const formData = new FormData();
      formData.append('timeStamp', now);
      formData.append('file', file);
      if (this.uploadType === 'image') {
        listHttpPost.push(
          defer(() => {
            this.uploadedFiles.initialized++;
            this.updateProgressBar();
            return this.pimService.postPimImageBatch(formData).pipe(
              tap(data => {
                if (data.uploadSuccessful) {
                  this.uploadedFiles.completed += 1;
                } else {
                  this.uploadedFiles.failed += 1;
                }
                this.updateProgressBar();
              }),
              catchError(error => of({ isError: true, error }))
            );
          })
        );
      } else {
        // document
        listHttpPost.push(
          defer(() => {
            this.uploadedFiles.initialized++;
            this.updateProgressBar();
            return this.pimService.postPimDocumentBatch(formData).pipe(
              tap(data => {
                if (data.uploadSuccessful) {
                  this.uploadedFiles.completed += 1;
                } else {
                  this.uploadedFiles.failed += 1;
                }
                this.updateProgressBar();
              }),
              catchError(error => of({ isError: true, error }))
            );
          })
        );
      }
    }
    forkJoin(listHttpPost).subscribe(
      next => {
        /* this.uploadedFiles.completed = next.filter( (n: any) => n.uploadSuccessful).length;
        this.uploadedFiles.failed = next.filter( (n: any) => !n.uploadSuccessful).length; */

        next.forEach((ele: any, idx) => {
          if (!ele.uploadSuccessful) {
            this.uploadedFiles.error.push(this.files[idx].name);
          }
        });
        this.valueProgressbar = 0;
        this.uploading = false;
      },
      error => {
        console.log('error', error);
        this.uploading = false;
        this.valueProgressbar = 0;
      }
    );
  }

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event) {
    // console.log('Drop', event);
    event.preventDefault();
    event.stopPropagation();
    event.target.style.opacity = 1;
    const files = event.target.files || event.dataTransfer.files;
    if (files.length > 0) {
      this.files = files;
      // this.uploadedFiles.total = this.files.length;
    }
  }

  updateProgressBar() {
    if (this.uploadedFiles.initialized !== 0) {
      const initializedPercent = 100 * 0.1;
      const initialized = (this.uploadedFiles.initialized * initializedPercent) / initializedPercent;
      this.valueProgressbar =
        ((this.uploadedFiles.completed + this.uploadedFiles.failed) * (100 - initializedPercent)) /
          this.uploadedFiles.total +
        initialized;
      return;
    }
    this.valueProgressbar =
      ((this.uploadedFiles.completed + this.uploadedFiles.failed) * 100) / this.uploadedFiles.total;
  }
}
