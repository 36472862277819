import { Component, Input } from '@angular/core';

@Component({
  selector: 'gung-button',
  templateUrl: './gung-button.component.html',
  styleUrl: './gung-button.component.scss'
})
export class GungButtonComponent {
  @Input() classes: string;
  
  @Input() label: string;

  @Input() icon: string = null;

  @Input() loading = false;

  @Input() onClick: () => void;

  @Input() disable = false;

  handleClick() {
    if (!this.loading && this.onClick) {
      this.onClick();
    }
  }
}
