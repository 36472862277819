import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class S3UploadService {
  protected baseUrl = 'import';

  constructor(protected http: HttpClient) {}

  public postImage(image: File, imgPath?: string): Observable<S3Image> {
    const url = `${this.baseUrl}/s3-image`;
    const formData = new FormData();
    formData.append('file', image);
    formData.append('productImage', JSON.stringify(image));
    if (!!imgPath) {
      formData.append('imagePath', imgPath);
    }
    return this.http.post<S3Image>(url, formData);
  }

  public postDocument(document: File): Observable<S3Item> {
    const url = `${this.baseUrl}/s3-document`;
    const formData = new FormData();
    formData.append('file', document);
    formData.append('productDocument', JSON.stringify(document));
    return this.http.post<S3Item>(url, formData);
  }
}

export interface S3Item {
  id: string;
  name: string;
  extra: { [s: string]: any } | null;
  s3Uri: string;
  s3ETag: string;
  description: string;
  category: string;
  i18n: { [language: string]: any };
  pimDimensionIds: string[];
  fullImageUrl?: string
}

export interface S3Image extends S3Item {
  index: number;
  s3lastModifiedDate: Date;
}
