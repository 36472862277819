import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-list-management-modal',
  templateUrl: './list-management-modal.component.html',
  styleUrls: ['./list-management-modal.component.css']
})
export class ListManagementModalComponent implements OnInit {

  @Input()
  currentList: any[];
  
  @Input()
  modalTitle: string;
  
  @Input()
  inputLabel: string;

  public value: string;
  public list: any[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
    this.list = this.currentList.slice();
  }

  add(value: String) {
    this.list.push(value);
    this.value = value = undefined;
  }

  remove(idx: number) {
    this.list.splice(idx, 1);
  }
}
