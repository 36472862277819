<table class="table">
  <thead>
    <tr>
      <th translate>COLOR_NUMBER</th>
      <th translate>COLOR</th>
      <th translate>COLOR_TAGS</th>
      <th translate>SYNC_TO_SHOPIFY</th>
      <th translate>OUTLET</th>
      <th translate>NEW</th>
      <th *ngFor="let lang of languages">{{ 'VARIANT_DESCRIPTION' | translate }} - {{ lang.toUpperCase() }}</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let variant of pimProduct.dimensions.colors">
      <td>
        {{ variant.dimensionId }}
      </td>
      <td>
        {{ variant.name }}
      </td>
      <td>
        <lib-meta-data-checkbox-input
          [(selectedOptions)]="pimProduct.extra.pim.colors[variant.dimensionId].colorTagsV2"
          outputType="string"
          metaId="id"
          table="COLORTAGS"
          [suppressLabel]="true"
          metaDisplay="name"
          previewField="name"
          class="gung-select-input-sm"
        >
        </lib-meta-data-checkbox-input>
      </td>
      <!-- <td *ngFor="let lang of languages">
                <div class="d-flex flex-wrap">
                    <label *ngFor="let color of getMetadataList('COLOR_' + lang.toUpperCase())" class="mr-1">
                        <input type="checkbox" [(ngModel)]="pimProduct.extra.pim.colors[variant.dimensionId].i18n[lang].colortags[color.name]"> {{color.name}}
                    </label>
                </div>
            </td> -->
      <td class="text-center">
        <input
          type="checkbox"
          [disabled]="!pimProduct.extra.pim.buyerGroups.SHOPIFY"
          [(ngModel)]="pimProduct.extra.pim.colors[variant.dimensionId].shopifySync"
        />
      </td>
      <td class="text-center">
        <input
          type="checkbox"
          [disabled]="!pimProduct.extra.pim.buyerGroups.SHOPIFY"
          [(ngModel)]="pimProduct.extra.pim.colors[variant.dimensionId].isOutlet"
        />
      </td>
      <td class="text-center">
        <input
          type="checkbox"
          [disabled]="!pimProduct.extra.pim.buyerGroups.SHOPIFY"
          [(ngModel)]="pimProduct.extra.pim.colors[variant.dimensionId].isNew"
        />
      </td>
      <td *ngFor="let lang of languages">
        <lib-gung-text-area-input-horizontal
          [suppressLabel]="true"
          [(ngModel)]="pimProduct.extra.pim.colors[variant.dimensionId].i18n[lang].description"
        ></lib-gung-text-area-input-horizontal>
      </td>
    </tr>
  </tbody>
</table>
