<div *ngIf="form; else loading">
  <div class="row">
    <div class="col-10">
      <h4 *ngIf="digitalAsset && digitalAsset.id">{{ digitalAsset.name }}</h4>
    </div>
    <div class="col-2 text-right">
      <button
        type="button"
        class="btn btn-danger"
        *ngIf="digitalAsset && digitalAsset.id"
        (click)="onDelete(digitalAsset)"
        [disabled]="isLoading ? true : null"
        translate
      >
        DELETE
      </button>
      <button
        type="button"
        class="btn btn-success"
        (click)="onSave(digitalAsset)"
        [disabled]="isLoading ? true : null"
        translate
      >
        {{ digitalAsset && digitalAsset.id ? 'SAVE' : 'CREATE' }}
      </button>
      <i *ngIf="isLoading" class="fa fa-spinner fa-spin fa-lg"></i>
    </div>
  </div>

  <div class="row" [formGroup]="form">
    <div class="col-6">
      <lib-gung-text-input [gungTranslate]="'NAME'" [placeholder]="'' | translate" [formControl]="form.get('name')">
      </lib-gung-text-input>
      <lib-gung-input-multi-select
        *ngIf="userGroups"
        label="GROUPS"
        [optionsList]="userGroups"
        (selectedValues)="setSelectedValues($event)"
      ></lib-gung-input-multi-select>
    </div>
    <div class="col-6">
      <lib-gung-text-input [gungTranslate]="'TAGS'" [placeholder]="'' | translate" [formControl]="form.get('tags')">
      </lib-gung-text-input>
      <lib-gung-number-input [gungTranslate]="'SORTING_PRIORITY'" [formControl]="form.get('sequence')">
      </lib-gung-number-input>
    </div>
  </div>
  <div class="row" [formGroup]="form" *ngIf="digitalAssetsService.useLinks">
    <div class="col-12">
      <h4 translate>LINKS</h4>
      <ng-container formArrayName="links">
        <lib-gung-text-input
          *ngFor="let link of form.get('links')?.controls; let idx = index"
          [formControl]="link"
          suppressLabel="true"
          gungTranslate="DIGITAL_ASSETS_VIDEO_URLS"
          placeholder="e.g https://www.dropbox.com/sh/, https://www.youtube.com/watch?v= etc."
          ><!-- [formGroupName]="idx" --></lib-gung-text-input
        >
      </ng-container>
    </div>
  </div>
  <div class="row mb-3">
    <div class="col-lg-6 col-md-12">
      <div class="mb-3">
        <h4 translate>DIGITAL_ASSETS_IMAGES</h4>
        <div
          class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
          style="
            background: #ffffff;
            border: 5px dashed #ddd;
            text-align: center;
            padding: 50px 10px;
            margin-left: 10px;
          "
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event, 'images')"
          (click)="fileInput.click()"
        >
          <!-- <span data-translate="ADD_FILE" class="ng-scope">Add file(s) by clicking on or dragging to this area</span> -->
          <!-- for="file" --><label
            ><strong>Add file(s) by clicking on</strong
            ><span class="box__dragndrop"> or dragging to this area</span>.</label
          >
          <br />
          <span
            ><small class="" *ngFor="let file of fileInput.files || fileInput.files; let last = last"
              >{{ file?.name }}{{ !last ? ', ' : '' }}</small
            ></span
          >
          <input
            hidden
            #fileInput
            type="file"
            name="files[]"
            id="file"
            (change)="onDrop($event, 'images')"
            accept="image/*,application/pdf"
            multiple
          />
        </div>
      </div>
      <!-- Images -->
      <div
        *ngFor="let image of digitalAsset?.images; let first = first; let last = last; let index = index"
        class="border-bottom"
      >
        <div class="row my-2">
          <div class="col-6">
            <span *ngIf="first" class="d-block" translate>DIGITAL_ASSETS_PRIMARY_IMAGE</span>
            <img
              *ngIf="image.s3ETag; else imageTemp"
              class="img-fluid d-block"
              [src]="image.s3Uri | gungImageUrl
                :'type:list'
                :'etag:'+image.s3ETag
              "
              alt="{{ image.description }}"
            />
            <ng-template #imageTemp>
              <i *ngIf="!image.s3Uri" class="fa fa-spinner fa-spin fa-7x"></i>
              <img *ngIf="image.s3Uri" class="img-fluid d-block" [src]="image.s3Uri" alt="{{ image.name }}" />
            </ng-template>
          </div>
          <div class="col-6 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                *ngIf="!first"
                type="button"
                class="btn btn-secondary"
                (click)="moveElement(digitalAsset.images, index, index - 1)"
              >
                <i class="fas fa-arrow-up"></i>
              </button>
              <button
                *ngIf="!last"
                type="button"
                class="btn btn-secondary"
                (click)="moveElement(digitalAsset.images, index, index + 1)"
              >
                <i class="fas fa-arrow-down"></i>
              </button>
            </div>
            <button type="button" class="btn btn-danger" (click)="removeElement(digitalAsset.images, index)" translate>
              DELETE
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-12">
      <div class="mb-3">
        <h4 translate>DIGITAL_ASSETS_DOCUMENTS</h4>
        <div
          class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
          style="
            background: #ffffff;
            border: 5px dashed #ddd;
            text-align: center;
            padding: 50px 10px;
            margin-left: 10px;
          "
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event, 'documents')"
          (click)="fileInput2.click()"
        >
          <!-- <span data-translate="ADD_FILE" class="ng-scope">Add file(s) by clicking on or dragging to this area</span> -->
          <!-- for="file" --><label
            ><strong>Add file(s) by clicking on</strong
            ><span class="box__dragndrop"> or dragging to this area</span>.</label
          >
          <br />
          <span
            ><small class="" *ngFor="let file of fileInput2.files || fileInput2.files; let last = last"
              >{{ file?.name }}{{ !last ? ', ' : '' }}</small
            ></span
          >
          <input
            hidden
            #fileInput2
            type="file"
            name="files[]"
            id="file"
            (change)="onDrop($event, 'documents')"
            multiple
          />
        </div>
      </div>
      <!-- Documents -->
      <div
        *ngFor="let document of digitalAsset?.documents; let first = first; let last = last; let index = index"
        class="border-bottom"
      >
        <div class="row my-2">
          <div class="col-6">
            <span *ngIf="first" class="d-block" translate>DIGITAL_ASSETS_PRIMARY_DOCUMENT</span>
            <a
              *ngIf="document.s3ETag; else documentTemp"
              [href]="document | gungDownloadUrl"
              target="_blank"
              >{{ document.name }}</a
            >
            <ng-template #documentTemp>
              <a [href]="" target="_blank">{{ document.name }}</a>
            </ng-template>
          </div>
          <div class="col-6 text-right">
            <div class="btn-group" role="group" aria-label="Basic example">
              <button
                *ngIf="!first"
                type="button"
                class="btn btn-secondary"
                (click)="moveElement(digitalAsset.documents, index, index - 1)"
              >
                <i class="fas fa-arrow-up"></i>
              </button>
              <button
                *ngIf="!last"
                type="button"
                class="btn btn-secondary"
                (click)="moveElement(digitalAsset.documents, index, index + 1)"
              >
                <i class="fas fa-arrow-down"></i>
              </button>
            </div>
            <button
              type="button"
              class="btn btn-danger"
              (click)="removeElement(digitalAsset.documents, index)"
              translate
            >
              DELETE
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
