<div class="ql-editor ck-content mw-100" [innerHTML]="(news.i18n?.[currentLang]?.html || news?.html) | safeHtml"></div>

<div *ngIf="productListIds?.length > 0" class="row mt-4">
  <div class="products col-12">
      <lib-product-list-by-ids [productIds]="productListIds"></lib-product-list-by-ids>
  </div>  
</div>

<!-- <div *ngIf="loading" class="d-flex flex-row justify-content-center align-items-center h-100">
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</div> -->
