import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

export interface ActuatorEnvironment {
  activeProfiles: string[];
  propertySources: ActuatorEnvironmentPropertySources[]
}

export interface ActuatorEnvironmentPropertySources {
  name: string;
  properties: ActuatorEnvironmentProperty
}

export interface ActuatorEnvironmentProperty {
  [key: string]: {
    value: string;
    origin: string;
    propertySources?: string;
  }
}

@Injectable({
  providedIn: 'root'
})
export class ActuatorEnvironmentService {

  baseUrl: string = 'actuator/env';

  constructor(private http: HttpClient) {}

  getAll(): Observable<ActuatorEnvironment> {
    const headers = new HttpHeaders();
    const url = `${this.baseUrl}`;
    return this.http.get<any>(url,{headers:headers});
  }

  getAllPropertySources(): Observable<ActuatorEnvironmentPropertySources[]> {
    return this.getAll().pipe(
      map(data => {
        return data.propertySources;
      })
    )
  }

  getAllDistinctProperties(): Observable<ActuatorEnvironmentProperty[]> {
    return this.getAllPropertySources().pipe(
      map(data => {
        const properties: ActuatorEnvironmentProperty = {};
        for (const envProp of data) {
          for (const prop in envProp.properties) {
            if (!properties[prop]) {
              properties[prop] = {
                ...envProp.properties[prop],
                propertySources: envProp.name
              }
            }
          }
        }
        const res: ActuatorEnvironmentProperty[] = Object.keys(properties).reduce((prev, curr) => [...prev, {[curr]: properties[curr]}], []);
        return res;
      })
    )
  }
}
