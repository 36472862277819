import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Invoice } from '../../models/invoice';
import { switchMap } from 'rxjs';
import { InvoiceRow } from '../../models/invoiceRow';



@Injectable({
  providedIn: 'root'
})
export class InvoiceService {
  constructor(protected http: HttpClient) {}

  getInvoices(customerId: string): Observable<Invoice[]> {
    const url = `json/invoices/list/${customerId}`;
    return this.http.get<Invoice[]>(url);
  }

  getInvoice(invoiceId: string): Observable<Invoice> {
    const url = `json/invoices/${invoiceId}`;
    return this.http.get<Invoice>(url);
  }

  getOpenInvoices(): Observable<Invoice[]> {
    const url = `json/open-invoices`;
    return this.http.get<Invoice[]>(url).pipe(switchMap(response => of(response || [])));
  }

  getInvoiceRows(invoiceId: string): Observable<InvoiceRow[]> {
    const url = `json/invoice-rows/${invoiceId}`;
    return this.http.get<InvoiceRow[]>(url);
  }
}
