<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" translate>ID</th>
        <th scope="col" translate>TYPE</th>
        <th scope="col" translate>NAME</th>
        <th scope="col" *ngFor="let lang of showLanguages">
          {{ lang | uppercase }}
        </th>
        <th scope="col" translate>EDIT</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let dimension of data">
        <td>{{ dimension.dimensionId }}</td>
        <td>{{ dimension.type }}</td>
        <td>{{ dimension.name }}</td>
        <td *ngFor="let lang of showLanguages">
          {{ dimension?.i18n[lang]?.description }}
        </td>
        <td>
          <button type="button" class="btn btn-secondary" (click)="onEdit(dimension)" translate>EDIT</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
