import {
  Component,
  OnInit,
  SimpleChanges,
  OnChanges,
  Type,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  inject
} from '@angular/core';
import { CheckoutStepComponent } from '../steps/base/checkout-step.component';
import { takeUntil, first, tap, Observable } from 'rxjs';
import { Subject } from 'rxjs';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { SaveCartModalConfigService } from '../../../services/save-cart-modal-config/save-cart-modal-config.service';
import { CartService } from '../../../services/cart/cart.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../services/auth/auth.service';
import { DiscountCodeV2 } from '../../../services/discount-codes-v2/discount-codes-v2.service';
import { CheckoutObject } from '../../../models/checkout';
import { FeatureActivatedGuardWrapper } from '../../../guards/feature-activated/feature-activated.guard';
import { CheckoutConfigService } from '../../../services/checkout-config/checkout-config.service';

@Component({
  selector: 'lib-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.css']
})
export class ButtonsComponent implements OnChanges, OnInit, OnDestroy {
  @Input()
  currentStep: Type<CheckoutStepComponent>;

  @Input()
  allSteps: Type<CheckoutStepComponent>[];

  @Output()
  nextClicked = new EventEmitter<void | any>();

  @Output()
  prevClicked = new EventEmitter<void>();

  @Output()
  applyDiscountCode = new EventEmitter<DiscountCodeV2>();

  @Output()
  createOffer = new EventEmitter<void | any>();

  @Input()
  createOffers = false;

  @Input()
  disableNextButtonObservable: Observable<boolean>;

  public prevBtnText: string;
  public nextBtnText: string;
  public isFirstStep = true;
  public currentStepIndex = 0;
  public isLastStep = false;
  public isExternalPaymentStep = false;
  public isUser = false;
  public isSales = false;
  public isAdmin = false;

  public cartContents: NavbarCartModel;
  public cartTotalValue = 0;
  public cartCurrency = '';
  public cartTotalItems = 0;
  public cartTotalRows = 0;

  public unsubscribe: Subject<void> = new Subject();

  public enableDiscount = false;
  public discountDescription: string;
  campaignCode: string;
  _discountCode: DiscountCodeV2;
  @Input()
  set discountCode(value) {
    if (value) {
      this._discountCode = value;
      this.campaignCode = value.code;
    } else {
      this._discountCode = undefined;
      this.campaignCode = undefined;
    }
    if (!!this.cartContents) {
      this.setTotals(this.cartContents);
    }
  }
  get discountCode() {
    return this._discountCode;
  }

  isCreateOfferStep: boolean = false;

  public disableNextButton: boolean = false;

  constructor(
    protected navbarCartService: NavbarCartService,
    protected gungModalService: GungModalService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected cartService: CartService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected checkoutConfig: CheckoutConfigService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.updateData();
  }

  ngOnInit() {
    this.updateData();

    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSales = roles.indexOf('SALES') > -1;
        this.isAdmin = roles.indexOf('ADMIN') > -1;
        this.isUser = roles.indexOf('USER') > -1 && !this.isSales && !this.isAdmin;
      });

    this.navbarCartService
      .getContents()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contents => {
        this.cartContents = contents;
        this.cartTotalValue = contents.totalValue;
        this.applyDiscountToTotalValue(null, this._discountCode);
        this.cartCurrency = contents.currency;
        this.cartTotalRows = contents.rows.length;
        this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
      });

    if (!!this.disableNextButtonObservable) {
      this.disableNextButtonObservable.pipe(
        takeUntil(this.unsubscribe)
      ).subscribe(disable => this.disableNextButton = disable);
    }
  }

  setTotals(contents: NavbarCartModel): void { }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  updateData() {
    if (!!this.currentStep?.prototype?.isCreateOfferStep()) {
      this.isCreateOfferStep = true;
    } else {
      this.isCreateOfferStep = false;
    }
    if (!!this.currentStep?.prototype?.isExternalPaymentStep()) {
      this.isExternalPaymentStep = true;
    } else {
      this.isExternalPaymentStep = false;
    }

    this.isFirstStep = this.allSteps.indexOf(this.currentStep) === 0;
    this.currentStepIndex = this.allSteps.indexOf(this.currentStep);
    this.isLastStep = this.allSteps.indexOf(this.currentStep) === this.allSteps.length - 1;

    /*     const btnTexts = this.currentStep.prototype.getBtnTexts();
    this.nextBtnText = 'NEXT';
    this.prevBtnText = 'PREV';
    if (!btnTexts) {
      return;
    }

    this.nextBtnText = btnTexts.next || this.nextBtnText;
    this.prevBtnText = btnTexts.prev || this.prevBtnText; */
  }

  updateBtnTexts(currentCheckout: CheckoutObject) {
    const btnTexts = this.currentStep.prototype.getBtnTexts(currentCheckout);
    this.nextBtnText = 'NEXT';
    this.prevBtnText = 'PREV';
    if (!btnTexts) {
      return;
    }

    this.nextBtnText = btnTexts.next || this.nextBtnText;
    this.prevBtnText = btnTexts.prev || this.prevBtnText;
  }

  saveCart(): void {
    this.saveCartModalConfigService.openSaveCartModal();
  }

  createQuotation(): void {
    this.saveCartModalConfigService.openCreateQuotationModal();
  }

  emptyCart(): void {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CLEAR_CART_CONFIRM'), { size: 'sm' })
      .then(
        result => {
          if (result) {
            this.cartService.clearCart();
          }
        },
        reason => { }
      );
  }

  setDiscountCode(discountCode) {
    const oldDiscount = this.discountCode;
    this.discountCode = discountCode;
    this.applyDiscountCode.emit(discountCode);
    this.applyDiscountToTotalValue(oldDiscount, discountCode);
  }

  applyDiscountToTotalValue(oldDiscount?: DiscountCodeV2, newDiscount?: DiscountCodeV2) {
    // Remove old discount
    if (oldDiscount?.addsNewRow) {
      this.cartTotalValue += oldDiscount.discountAmount;
    }

    // Add new discount
    if (newDiscount?.addsNewRow) {
      this.cartTotalValue -= newDiscount.discountAmount;
    }
  }
}
