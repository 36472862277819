<div class="row">
  <button
    *ngIf="edit"
    class="btn btn-primary position-absolute cursor-pointer"
    style="z-index: 50;"
    (click)="$event.preventDefault(); $event.stopPropagation(); edit ? openModal('sliderTop', true) : ''"
    translate
  >
    EDIT_SLIDER
  </button>
  <div
    class="col-12 my-2 flow-slider"
    *ngIf="sliderContent"
    style="max-height: 400px"
  >
    <lib-slider [sliderContent]="sliderContent"></lib-slider>
  </div>
</div>

<div class="dynamic-productlist row">
  <div class="dynamic-productlist-header col-6 p-0">
    <div class="title text-primary" [ngClass]="{'cursor-pointer': edit}" (click)="$event.preventDefault(); $event.stopPropagation(); edit ? openModal('productList1', false, true) : ''" translate> {{ getText(data.elements?.productList1?.text) }}</div>
  </div>
  <div class="dynamic-productlist-controls col-6 d-flex justify-content-end align-items-center">
    <button class="btn action-button text-primary" (click)="previousCard(dynamicProductList1Ref)">
      <i class="fa-solid fa-circle-arrow-left"></i>
    </button>
    <button class="btn action-button text-primary" (click)="nextCard(dynamicProductList1Ref)">
      <i class="fa-solid fa-circle-arrow-right"></i>
    </button>
  </div>
  <div class="dynamic-productlist-body col-12 mt-2" #dynamicProductList1>
    <div class="card product-card" *ngFor="let product of firstList" #{{product.id}}>
      <div class="card-body">
        <div class="image-wrapper cursor-pointer" (click)="goToDetail(product)">
          <img
            class="img-fluid"
            *ngIf="product.extra && product.extra.images && product.extra.images[0]"
            [src]="product.extra.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+product.extra.images[0].s3ETag
            "
            alt=""
          />
        </div>
        <div class="product-text pb-1">
          <div>{{ product.id }}</div>
          <div class="text-truncate" translate>{{ product.name }}</div>
          <div class="product-card-button">
            <container-element [ngSwitch]="product?.productType">
              <button *ngSwitchCase="'concept'" class="btn btn-sm btn-primary" (click)="goToDetail(product)" translate>
                SHOW_PRODUCTS
              </button>
              <lib-buy-button-wrapper *ngSwitchDefault [product]="product"></lib-buy-button-wrapper>
            </container-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row mt-4 mb-4">
  <div
    class="col-xs-12 col-sm-4 p-1 min-height-250"
    (click)="edit ? openModal('middleOne') : navigateTo(data.elements?.middleOne.link)"
  >
    <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.middleOne?.image) + ')'">
      <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate>
        {{ getText(data.elements?.middleOne?.text) }}
      </div>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-4 p-1 min-height-250"
    (click)="edit ? openModal('middleTwo') : navigateTo(data.elements?.middleTwo.link)"
  >
    <div class="w-100 h-100 bgimg" [style.backgroundImage]="'url(' + getImage(data.elements?.middleTwo?.image) + ')'">
      <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate>
        {{ getText(data.elements?.middleTwo?.text) }}
      </div>
    </div>
  </div>
  <div
    class="col-xs-12 col-sm-4 p-1 min-height-250"
    (click)="edit ? openModal('middleThree') : navigateTo(data.elements?.middleThree.link)"
  >
    <div
      class="w-100 h-100 bgimg"
      [style.backgroundImage]="'url(' + getImage(data.elements?.middleThree?.image) + ')'"
    >
      <div class="banner-spaced-text text-center h-100 d-flex justify-content-center align-items-center" translate>
        {{ getText(data.elements?.middleThree?.text) }}
      </div>
    </div>
  </div>
</div>

<div class="dynamic-productlist row">
  <div class="dynamic-productlist-header col-6 p-0">
    <div class="title text-primary" [ngClass]="{'cursor-pointer': edit}" (click)="$event.preventDefault(); $event.stopPropagation(); edit ? openModal('productList2', false, true) : ''" translate> {{ getText(data.elements?.productList2?.text) }}</div>
  </div>
  <div class="dynamic-productlist-controls col-6 d-flex justify-content-end align-items-center">
    <button class="btn action-button text-primary" (click)="previousCard(dynamicProductList2Ref)">
      <i class="fa-solid fa-circle-arrow-left"></i>
    </button>
    <button class="btn action-button text-primary" (click)="nextCard(dynamicProductList2Ref)">
      <i class="fa-solid fa-circle-arrow-right"></i>
    </button>
  </div>
  <div class="dynamic-productlist-body col-12 mt-2" #dynamicProductList2>
    <div class="card product-card" *ngFor="let product of secondList" #{{product.id}}>
      <div class="card-body">
        <div class="image-wrapper cursor-pointer" (click)="goToDetail(product)">
          <img
            class="img-fluid"
            *ngIf="product.extra && product.extra.images && product.extra.images[0]"
            [src]="product.extra.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+product.extra.images[0].s3ETag
            "
            alt=""
          />
        </div>
        <div class="product-text pb-1">
          <div>{{ product.id }}</div>
          <div class="text-truncate" translate>{{ product.name }}</div>
          <div class="product-card-button">
            <container-element [ngSwitch]="product?.productType">
              <button *ngSwitchCase="'concept'" class="btn btn-sm btn-primary" (click)="goToDetail(product)" translate>
                SHOW_PRODUCTS
              </button>
              <lib-buy-button-wrapper *ngSwitchDefault [product]="product"></lib-buy-button-wrapper>
            </container-element>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
