<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ customerContact ? 'EDIT_CONTACT' : 'ADD_CONTACT' }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body" *ngIf="customerContactForm">
  <div class="form" [formGroup]="customerContactForm">
    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="name" class="control-label" translate>NAME</label>
          <input class="form-control" type="text" name="name" id="name" [formControlName]="'name'" ngbAutofocus />
        </div>
      </div>

      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="email" class="control-label" translate>EMAIL</label>
          <input class="form-control" type="text" name="email" id="emailId" formControlName="email" />
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-12 col-md-6">
        <div class="form-group">
          <label for="phone" class="control-label" translate>PHONE</label>
          <input class="form-control" type="text" name="phone" id="phoneId" formControlName="phone" />
        </div>
      </div>

      <!-- <div class="col-12 col-md-6">
          <div class="form-group">
            <label for="mobile" class="control-label" translate>MOBILE_PHONE</label>
            <input class="form-control" type="text" name="mobile" id="mobileId" formControlName="mobile">
          </div>
        </div> -->
    </div>
    <div class="form-row">
      <div class="col-12">
        <div class="form-group">
          <lib-gung-text-area-input gungTranslate="NOTE" formControlName="notes"></lib-gung-text-area-input>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button
    type="button"
    class="btn btn-primary ml-auto"
    (click)="submitForm()"
    [disabled]="isAddingContact || undefined"
    translate
  >
    {{ customerContact ? 'EDIT_CONTACT' : 'ADD_CONTACT' }}
  </button>
  <button
    type="button"
    class="btn btn-secondary"
    [disabled]="isAddingContact || undefined"
    (click)="activeModal.close('Closed')"
    translate
  >
    CLOSE
  </button>
</div>
