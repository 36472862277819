import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption, SimpleConfigBaseFilter } from 'gung-list';
import { Observable } from 'rxjs';
import { AccountRequestsCardListComponent } from '../../components/accoun-request/account-requests-card-list/account-requests-card-list.component';
import { AccountRequest, AccountRequestService } from '../account-request/account-request.service';

export class AccountRequestStatusFilter extends SimpleConfigBaseFilter<AccountRequest> {
  constructor() {
    super();
  }

  getName(): string {
    return 'STATUS';
  }

  getOptionIds(account: AccountRequest): string[] {
    if (!account.processed && !account.rejected) {
      return ['PENDING'];
    } else if(account.processed && account.rejected) {
      return ['REJECTED'];
    } else if (account.processed && !account.rejected) {
      return ['ACCEPTED'];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class AccountRequestListConfigService implements ConfigService<AccountRequest> {
  constructor(protected accountRequestService: AccountRequestService) {}

  getFilters(): ConfigBaseFilter<AccountRequest>[] {
    return [new AccountRequestStatusFilter()];
  }

  getSortOptions(): ListSortOption<AccountRequest>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: AccountRequest) {
    return item.id;
  }

  getSearchTerms(item: AccountRequest) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return 'd-none';
  }

  getLayouts(): ListLayout<AccountRequest>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => AccountRequestsCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<AccountRequest[]> {
    return this.accountRequestService.getAccountRequestInternal();
  }
}
