import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { map, tap } from 'rxjs';
import { AssortmentTreeListViewComponent } from '../../components/assortment-tree-list-view/assortment-tree-list-view.component';
import { AssortmentRecursive, AssortmentService } from '../assortment.service';

@Injectable({
  providedIn: 'root'
})
export class AssortmentTreeListConfigService implements ConfigService<AssortmentRecursive> {
  assortmentObjectsSubject: Subject<AssortmentRecursive[]> = new BehaviorSubject(null);

  constructor(protected assortmentService: AssortmentService) {}

  getFilters(): ConfigBaseFilter<AssortmentRecursive>[] {
    return [];
  }

  getSortOptions(): ListSortOption<AssortmentRecursive>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: AssortmentRecursive) {
    return item.id;
  }

  getSearchTerms(item: AssortmentRecursive) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return 'd-none';
  }

  getLayouts(): ListLayout<AssortmentRecursive>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => AssortmentTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<AssortmentRecursive[]> {
    // const noImage = {
    //   s3ETag: '7aac1c15970c4e8e94d6a2a51685933b',
    //   s3Uri: 'images/no-image.jpg'
    // };
    return this.assortmentObjectsSubject;
  }
}
