import { QuantityPayload } from '../actions';
import { getRowFromRows, filterOutRow } from './common';
import { CartRow } from '../types';
import { mergeDeep } from '../../../utils/merge-utils';

const createRow = (
  productId: string,
  date: Date,
  targetStockId?: string,
  productPartialId?: string,
  rownr?: number
): CartRow => ({
  productId,
  productPartialId,
  targetStockId,
  extra: {},
  qty: 0,
  timeAdded: date,
  timeUpdated: date,
  rownr
});

export const addReducer = (current: CartRow[], payload: QuantityPayload, currentTime?: number): CartRow[] => {
  const currentDate = currentTime ? new Date(currentTime) : new Date();
  const { productId, productPartialId, targetStockId, qty, position, rownr } = payload;
  const oldRow =
    getRowFromRows(current, productId, targetStockId, productPartialId) ||
    createRow(productId, currentDate, targetStockId, productPartialId, rownr);

  const newRow = {
    ...oldRow,
    // HAndle rounding of decimal qty
    qty: Math.round((oldRow.qty + qty + Number.EPSILON) * 1000) / 1000,
    timeUpdated: currentDate
  };
  if (!!payload.extra) {
    const oldExtra = JSON.parse(JSON.stringify(oldRow.extra || {}));
    let newExtra = payload.extra;
    if (!payload.replace) {
      newExtra = mergeDeep(oldExtra, payload.extra);
    }
    newRow.extra = newExtra;
  }

  if (!isNaN(position)) {
    let cartFiltered = current.filter(r => filterOutRow(r, productId, targetStockId, productPartialId));
    cartFiltered.splice(position, 0, newRow);
    cartFiltered = updatePositionOfRows(cartFiltered);
    return cartFiltered;
  }
  return [...current.filter(r => filterOutRow(r, productId, targetStockId, productPartialId)), newRow];
};

export const updatePositionOfRows = (rows: CartRow[]): CartRow[] => {
  return rows.map((row, index, array) => {
    return {
      ...row,
      extra: {
        ...row.extra,
        _position: index
      }
    };
  });
};

export const bulkAddReducer = (current: CartRow[], payload: QuantityPayload[]): CartRow[] => {
  let currentTime = new Date().getTime();

  for (const item of payload) {
    current = addReducer(current, item, currentTime);
    currentTime++;
  }

  return current;
};
