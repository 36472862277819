import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-actuator-wrapper',
  templateUrl: './actuator-wrapper.component.html',
  styleUrls: ['./actuator-wrapper.component.css']
})
export class ActuatorWrapperComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
