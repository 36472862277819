<div class="digital-assets-wrapper">
  <!-- isManagement: {{isManagement}}
  isCreateNew: {{isCreateNew}}
  isFolder: {{isFolder}}
  isEdit: {{isEdit}}
  parentId: {{parentId}} -->
  <div class="row">
    <div class="col text-center">
      <!-- BREADCRUMB -->
      <div class="row row-breadcrumb" *ngIf="breadcrumbs">
        <div class="col-12">
          <!-- <lib-breadcrumb></lib-breadcrumb> <hr/> -->
          <nav aria-label="breadcrumb">
            <ol class="breadcrumb px-0 align-items-cente justify-content-center bg-transparent">
              <li
                *ngFor="let breadcrumb of breadcrumbs; let last = last"
                class="breadcrumb-item p-0 pl-2"
                [attr.aria-current]="last ? 'page' : undefined"
              >
                <a [ngClass]="{ 'active-assortment': last }" [routerLink]="breadcrumb.urlParts" translate>{{
                  breadcrumb.label
                }}</a>
              </li>
            </ol>
          </nav>
        </div>
      </div>
      <div *ngIf="!productList && !isEdit && !isCreateNew">
        <input
          class="search-digital"
          type="text"
          placeholder="{{ 'SEARCH' | translate }}"
          [(ngModel)]="digitalAssetsSearch"
          (ngModelChange)="searchChange($event)"
        />
      </div>
    </div>
  </div>
  <div class="row" *ngIf="isCreateNew && !isSearching">
    <div class="col-12">
      <lib-digital-assets-management-item></lib-digital-assets-management-item>
    </div>
  </div>
  <div class="row" *ngIf="isEdit && !isSearching">
    <div class="col-12">
      <lib-digital-assets-management-item [digitalAsset]="selectedDigitalAsset"></lib-digital-assets-management-item>
    </div>
  </div>

  <div class="row" *ngIf="!isCreateNew && !isFolder && !isEdit && !isSearching && !productList">
    <div class="col-12">
      <lib-filter-list [configService]="digitalAssetsListConfigService"></lib-filter-list>
    </div>
  </div>
  <div class="row" *ngIf="isFolder && !isSearching && !productList">
    <div class="col-12">
      <lib-filter-list [configService]="digitalAssetsFolderListConfigService"></lib-filter-list>
    </div>
  </div>
  <div class="row" *ngIf="productList">
    <div class="col-12 product-export-assets">
      <lib-product-export-list [digitalAssets]="true"></lib-product-export-list>
    </div>
  </div>

  <div class="row" *ngIf="isSearching && !productList">
    <div class="col-12">
      <h2>RESULTS</h2>
      <lib-filter-list [configService]="digitalAssetsSearchListConfigService"></lib-filter-list>
    </div>
  </div>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
