import { Component, OnInit, Input, OnChanges, OnDestroy, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { first, takeUntil } from 'rxjs';
import { Subject } from 'rxjs';
import { CartService } from '../../services/cart/cart.service';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { ProductInputQuantityConfigService } from '../../services/product-input-quantity-config/product-input-quantity-config.service';
import { Product } from '../../models';
import { Availability, SimplifiedAvailability } from '../../models/availability';
import { th } from 'date-fns/locale';
import { CustomerProductPrice } from '../../models/price';
import { AuthService } from '../../services/auth/auth.service';

interface QuantityUpdatedEvent {
  id: string;
  qty: number;
}

@Component({
  selector: 'lib-buy-btn',
  templateUrl: './buy-btn.component.html',
  styleUrls: ['./buy-btn.component.scss']
})
export class BuyBtnComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  id: string;

  @Input()
  disabled: boolean;

  @Input()
  disabledAdd: boolean;

  @Input()
  disabledSub: boolean;

  @Input()
  productPartialId?: string;

  @Input()
  product?: Product;

  @Input()
  price?: CustomerProductPrice;

  @Input()
  minimumOrderQuantity: number;

  @Input()
  stepAmount: number;

  @Input()
  targetStockId?: string;

  @Output()
  quantityUpdatedBuyBtn? = new EventEmitter<QuantityUpdatedEvent>();

  currentQty: number;
  previousQty: number;

  allowManualQtyInputOutsideStepSizes = false;

  isOnlyDisplayFlow = false;
  unsubscribe: Subject<void> = new Subject();

  @Input()
  checkoutCart: boolean;

  @Input()
  recomendedInitOrderQuantity?: number;

  @Input()
  availability?: Availability | SimplifiedAvailability;

  isInit = true;

  roles: string[];
  isAnonymous: boolean = false;
  isUser: boolean;
  isSales: boolean;
  isAdmin: boolean;
  isActuator: boolean

  decimals: number;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected authService: AuthService
  ) {}

  ngOnInit() {
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.roles = roles;
      this.isAnonymous = roles.indexOf('ANONYMOUS') > -1;
      this.isUser = roles.indexOf('USER') > -1 && roles.indexOf('SALES') === -1 && roles.indexOf('ADMIN') === -1 && roles.indexOf('ACTUATOR') === -1;
      this.isSales = roles.indexOf('SALES') > -1;
      this.isAdmin = roles.indexOf('ADMIN') > -1;
      this.isActuator = roles.indexOf('ACTUATOR') > -1;
      // if flow is only display then do not show the buy-btn, instead the Show product detail
      this.gungFlowService
        .getSelectedFlow()
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(flow => {
          this.isOnlyDisplayFlow = !!flow && !!flow.displayOnly;

          if (
            !!this.availability &&
            this.availability.maxFutureStock === 0 &&
            !this.disabled &&
            flow.disableNonAvailable
          ) {
            this.disabled = true;
          }

          this.setValues();
        });
      })

    this.disabled = this.disabled || this.productInputQuantityConfigService.disableBuyBtn(this.product, this.availability);

    this.initQtySubscription();
  }

  initQtySubscription(): void {
    this.cartService
      .getProductQty(this.id, this.productPartialId)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(qty => {
        setTimeout(() => {
          this.currentQty = qty;
          this.previousQty = qty;
          this.quantityUpdatedBuyBtn.emit({id: this.id, qty: this.currentQty});
        }, 0);
      });
  }

  ngOnChanges() {
    this.setValues();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  add() {
    if (!this.currentQty) {
      const toAdd = Math.max(this.minimumOrderQuantity, this.stepAmount, this.recomendedInitOrderQuantity);
      if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(toAdd, this.availability, this.product, this.roles)) {
        return;
      }
      this.cartService.addToCart(this.id, toAdd, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product?.name);
      return;
    }

    const differenceToStep = this.stepAmount - Number(Number((this.currentQty % this.stepAmount)).toFixed(this.decimals));
    if (differenceToStep && differenceToStep !== this.stepAmount) {
      if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(this.currentQty + differenceToStep, this.availability, this.product, this.roles)) {
        return;
      }
      this.cartService.addToCart(this.id, differenceToStep, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product?.name);
      return;
    }

    if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(this.currentQty + this.stepAmount, this.availability, this.product, this.roles)) {
      return;
    }
    if(this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product) && this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product) < this.currentQty + this.stepAmount){
      return;
    }
    this.cartService.addToCart(this.id, this.stepAmount, this.targetStockId, this.productPartialId, undefined, undefined, undefined, this.product?.name);
  }

  sub() {
    if (this.currentQty - this.stepAmount >= this.minimumOrderQuantity) {
      const differenceToStep = this.stepAmount - Number(Number((this.currentQty % this.stepAmount)).toFixed(this.decimals));
      if (differenceToStep && differenceToStep !== this.stepAmount) {
        const quantityToSubtract = this.stepAmount - differenceToStep;
        this.cartService.subtractFromCart(this.id, quantityToSubtract, this.targetStockId, this.productPartialId);
        return;
      }

      this.cartService.subtractFromCart(this.id, this.stepAmount, this.targetStockId, this.productPartialId);
    } else {
      if (this.checkoutCart) {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE_PRODUCT') + '?', { size: 'sm' })
          .then(result => {
            if (result) {
              this.cartService.subtractFromCart(this.id, this.currentQty, this.targetStockId, this.productPartialId);
            } else {
              this.cartService.setProductQuantity(this.id, this.previousQty, this.targetStockId, this.productPartialId);
            }
          });
      } else {
        const differenceToStep = this.stepAmount - Number(Number((this.currentQty % this.stepAmount)).toFixed(this.decimals));
        if (differenceToStep && differenceToStep !== this.stepAmount) {
          const quantityToSubtract = this.stepAmount - differenceToStep;
          this.cartService.subtractFromCart(this.id, quantityToSubtract, this.targetStockId, this.productPartialId);
          return;
        }

        this.cartService.subtractFromCart(this.id, this.currentQty, this.targetStockId, this.productPartialId);
      }
    }
  }

  set(value: number, ele?: HTMLInputElement) {

    if(this.decimals === 0) {
      value = Math.floor(value);
    } else {
      value = Number(Number(value).toFixed(this.decimals));
    }

    if (!this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(value, this.availability, this.product, this.roles)) {
      value = this.previousQty;
    } else if (this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product) && value > this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product)) {
      this.cartService.setProductQuantity(this.id, this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product), this.targetStockId, this.productPartialId);
      value = this.productInputQuantityConfigService.getMaxOrderQuantity(this.id, this.product);
    }else if (this.allowManualQtyInputOutsideStepSizes) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else if (value >= this.minimumOrderQuantity && (this.stepAmount === 1 || value % this.stepAmount <= Number.EPSILON)) {
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else if (value >= 0 && value % this.stepAmount >= Number.EPSILON) {
      value += this.stepAmount - (value % this.stepAmount);
      this.cartService.setProductQuantity(this.id, value, this.targetStockId, this.productPartialId);
    } else {
      if (this.checkoutCart) {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE_PRODUCT') + '?', { size: 'sm' })
          .then(result => {
            if (result) {
              this.cartService.setProductQuantity(this.id, 0, this.targetStockId, this.productPartialId);
            } else {
              this.cartService.setProductQuantity(this.id, this.previousQty, this.targetStockId, this.productPartialId);
            }
          });
      } else {
        this.cartService.setProductQuantity(this.id, 0, this.targetStockId, this.productPartialId);
      }
    }

    if(ele) {
      ele.value = isNaN(value) ? '' : value.toString();
    }
  }

  formatValue(value: number): string {
    if (value == null) {
      return '';
    }
    return value.toFixed(this.decimals);
  }

  goToDetailUrl(): string {
    return `/product/${this.id}`;
  }

  setValues(): void {
    if (!this.minimumOrderQuantity) {
      this.minimumOrderQuantity = this.productInputQuantityConfigService.getMinimumOrderQuantity(
        this.id,
        this.product,
        this.price
      );
    }

    this.allowManualQtyInputOutsideStepSizes =
      this.productInputQuantityConfigService.getAllowManualQtyInputOutsideStepSizes(this.id, this.product, this.price, this.isAnonymous, this.isUser, this.isSales, this.isAdmin, this.isActuator);

    if (!this.stepAmount) {
      this.stepAmount = this.productInputQuantityConfigService.getStepAmount(this.id, this.product, this.price);
    }

    // this.stepAmount = Math.trunc(this.stepAmount); // REVIEW THIS, THE STEP CAN BE FLOAT !!!
    if (!this.recomendedInitOrderQuantity) {
      this.recomendedInitOrderQuantity = this.productInputQuantityConfigService.getRecomendedInitOrderQuantity(
        this.id,
        this.product,
        this.price
      );
    }

    this.decimals = this.productInputQuantityConfigService.showDecimals(this.id, this.product);
  }
}
