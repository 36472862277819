import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import { DiscountCodesV2Service, DiscountCodeV2 } from '../../../services/discount-codes-v2/discount-codes-v2.service';
import { DiscountCode } from '../../../services/discount-codes.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-discount-codes-table',
  templateUrl: './discount-codes-table.component.html',
  styleUrls: ['./discount-codes-table.component.css']
})
export class DiscountCodesTableComponent extends ListItemRendererComponent<DiscountCodeV2[]> implements OnInit, AfterViewInit {
  constructor(
    public dateUtilService: DateUtilService,
    protected discountCodesV2Service: DiscountCodesV2Service,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  deleteDiscountCode(code: string) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ' ' + code + '?', { size: 'sm' })
      .then(result => {
        if (result) {
          this.discountCodesV2Service.deleteDiscountCodes(code).subscribe();
        }
      });
  }
}
