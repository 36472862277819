import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { CustomerContact } from '../../models/customerContact';
import { CustomerContactsGungListConfigService } from '../../services/customer-contacts-gung-list-config/customer-contacts-gung-list-config.service';

@Component({
  selector: 'lib-customer-contacts-gung-list',
  template: `<lib-filter-list [configService]="customerContactsGungListConfigService"></lib-filter-list>`,
  styles: []
})
export class CustomerContactsGungListComponent {
  constructor(
    public customerContactsGungListConfigService: CustomerContactsGungListConfigService,
    route: ActivatedRoute
  ) {
    customerContactsGungListConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
