import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-pim-template-item-editor-modal',
  templateUrl: './pim-template-item-editor-modal.component.html',
  styleUrls: ['./pim-template-item-editor-modal.component.css']
})
export class PimTemplateItemEditorModalComponent  {
  @Input('item')
  set item(value) {
    const item = JSON.parse(value);
    this.submitForm.patchValue(item);
  }

  submitForm: FormGroup;

  constructor(protected formBuilder: FormBuilder, public ngbActiveModal: NgbActiveModal) {
    this.setForm();
  }

  setForm() {
    this.submitForm = this.formBuilder.group({
      required: this.formBuilder.control(false),
      columnName: this.formBuilder.control(''),
      path: this.formBuilder.control('')
    });
  }

  onSubmit() {
    if (!this.submitForm.valid) {
      this.submitForm.markAllAsTouched();
      return;
    }
    const item = this.submitForm.getRawValue();
    this.submitForm.reset();
    this.ngbActiveModal.close(JSON.stringify(item));
  }
}
