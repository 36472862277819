<div class="pb-2">
  <h3 class="item-info" translate>CART_SUBTOTAL</h3>

  <div>
    <div class="pt-2 item-info">
      {{ 'ITEMS' | translate }}
      <div class="items-pieces-totals">
        {{ 'CART_ARTICLE_SKU_TOTAL' | translate: { article: cartTotalRows, sku: cartTotalItems } }}
      </div>
    </div>

    <h3 class="pt-2 item-title">{{ cartTotalValue | gungCurrency: cartCurrency:'code' }}</h3>
  </div>

  <div class="" *ngIf="enableDiscount && isLastStep">
    <lib-discount-input
      class="d-block"
      [(ngModel)]="campaignCode"
      (discountDescription)="discountDescription = $event"
      (discountCode)="setDiscountCode($event)"
    ></lib-discount-input>
    <div class="alert alert-success" *ngIf="discountDescription && discountDescription.length > 0" style="z-index: 1">
      {{ discountDescription | translate }}
    </div>
  </div>

  <!-- <div class="" *ngIf="enableDiscount && !isFirstStep && discountCode">
    <span class="font-weight-bold" translate>CAMPAIGN_CODE</span>: {{ discountCode?.code }}
  </div> -->

  <div>
    <button type="button" class="btn btn-primary w-100 cart-btn" (click)="saveCart()" *ngIf="isFirstStep">
      {{ 'SAVE_CART' | translate }}
    </button>

    <button type="button" class="btn btn-primary w-100 cart-btn" (click)="createQuotation()" *ngIf="isFirstStep && !checkoutConfig.hideCreateQuotation">
      {{ 'CREATE_QUOTATION' | translate }}
    </button>

    <button type="button" class="btn btn-secondary w-100 cart-btn" (click)="emptyCart()" *ngIf="isFirstStep">
      {{ 'EMPTY_CART' | translate }}
    </button>

    <button class="btn btn-secondary w-100 cart-btn" type="button" (click)="prevClicked.emit()" *ngIf="!isFirstStep">
      <span translate>{{ prevBtnText }}</span>
    </button>

    <button class="btn btn-primary w-100 cart-checkout-btn" type="button" (click)="nextClicked.emit()" [disabled]="disableNextButton">
      <span translate>{{ nextBtnText }}</span>
    </button>
  </div>
</div>
