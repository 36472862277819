import { Component, ViewChild } from '@angular/core';
import { ActuatorEnvironmentListConfigService } from '../../../services/actuator-environment-list-config/actuator-environment-list-config.service';
import { FilterListComponent } from 'gung-list';
import { ActuatorEnvironment } from '../../../services/actuator-environment/actuator-environment.service';

@Component({
  selector: 'lib-actuator-environment',
  template: `<lib-filter-list #list [configService]="configService"></lib-filter-list>`,
  styles: []
})
export class ActuatorEnvironmentComponent {
  @ViewChild('list') list: FilterListComponent<ActuatorEnvironment>;

  constructor(
    public configService: ActuatorEnvironmentListConfigService
  ) {}
}
