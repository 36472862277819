import { Component, OnInit } from '@angular/core';
import { ExportDragConfigService } from '../../services/export-drag-config.service';

@Component({
  selector: 'lib-product-export-drag-in',
  templateUrl: './product-export-drag-in.component.html',
  styleUrls: ['./product-export-drag-in.component.css']
})
export class ProductExportDragInComponent implements OnInit {
  constructor(public productExportListConfigService: ExportDragConfigService) {}

  ngOnInit(): void {}
}
