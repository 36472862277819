import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../services/auth/auth.service';
import { forkJoin, of } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { Whiteboard } from '../../types';
import { WhiteboardGridConfigService } from '../../services/whiteboard-grid-config.service';
import { WhiteboardService } from '../../services/whiteboard.service';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-whiteboard-create-modal',
  templateUrl: './whiteboard-create-modal.component.html',
  styleUrls: ['./whiteboard-create-modal.component.scss']
})
export class WhiteboardCreateModalComponent implements OnInit {
  alertMessage: Alert;
  submited = false;
  isUpdate = false;

  whiteboardForm: FormGroup;
  whiteboard: Whiteboard = {} as Whiteboard;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected authService: AuthService,
    protected whiteboardGridConfigService: WhiteboardGridConfigService,
    protected whiteboardService: WhiteboardService
  ) {}

  ngOnInit(): void {
    this.whiteboard.users = this.whiteboard.users || [];
    this.initForm();
  }

  initForm() {
    this.whiteboardForm = this.formBuilder.group({
      name: this.formBuilder.control(this.whiteboard.name, []),
      users: this.formBuilder.control(this.whiteboard.users.join(','), [])
    });
  }

  onSave() {
    if (!this.whiteboardForm.valid) {
      this.whiteboardForm.markAllAsTouched();
      return;
    }

    const formRaw = this.whiteboardForm.getRawValue();
    this.whiteboardForm.disable();

    this.whiteboard = {
      ...this.whiteboard,
      name: formRaw.name,
      users: formRaw.users.split(',')
    } as Whiteboard;

    forkJoin([this.authService.getCurrentUser().pipe(first()), of(this.whiteboardGridConfigService.sheets)])
      .pipe(
        first(),
        tap(([user, whiteboardSheets]) => {
          this.whiteboard.users = [...this.whiteboard.users, user.username];
          if (!this.isUpdate) {
            this.whiteboard.sheets = whiteboardSheets;
          }
        }),
        switchMap(u => forkJoin([this.whiteboardService.create(this.whiteboard)]))
      )
      .subscribe(
        ([whiteboard]) => {
          this.alertMessage = { type: 'success', message: this.isUpdate ? 'WHITEBOARD_SAVED' : 'WHITEBOARD_CREATED' };
          this.submited = true;
        },
        () => {
          this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
          this.submited = true;
        }
      );
  }
}
