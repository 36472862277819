<div class="modal-header px-5 border-0 justify-content-center pb-0">
    <h4 class="modal-title mt-0 text-primary" id="modal-basic-title"><span translate>DELETE</span>&nbsp;</h4>
  </div>
  
  <div class="modal-body px-5 border-0 text-center">
    <div class="row mb-2">
      <div class="col-12 font-weight-bold">
        <p *ngIf="delete.extra.tag === 'QUOTE'; else isNormalCart" ><span>{{deleteString}}</span></p>
        <ng-template #isNormalCart>
          <p><span translate>CONFIRM_DELETE_SHOPPING_CART</span>?</p>
        </ng-template>
        <p>{{delete.name}} - {{dateUtilService.parseDate(delete.creationDate) | date: dateUtilService.dateFormat}}</p>
      </div>
    </div>
  </div>
  
  <div class="modal-footer d-block px-5 border-0">
    <div class="row">
      <div class="col-12 text-center">
        <button class="btn btn-sm btn-primary btn-default px-4 mr-2" (click)="activeModal.close('true')" translate>
          YES
        </button>
        <button class="btn btn-sm btn-primary btn-default px-4 ml-2" (click)="activeModal.close(false)" translate>
          NO
        </button>
      </div>
    </div>
  </div>
  