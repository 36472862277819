<div class="table-responsive">
  <table class="table table-borderless table-striped">
    <thead>
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th translate>DATE</th>
        <th translate></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let news of this.data">
        <td>
          {{ news.id }}
        </td>
        <td>
          {{ news.name }}
        </td>
        <td>
          <span *ngIf="news.extra.timestamp">
            {{ news.extra.timestamp | date: dateUtilService.dateFormat }}
          </span>
        </td>
        <td>
          <button class="btn btn-sm btn-outline-primary m-1 action-btn" (click)="openEditNewsModal(news)" translate>
            EDIT
          </button>

          <button class="btn btn-sm btn-outline-secondary m-1 action-btn" (click)="removeNews(news)" translate>
            REMOVE
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
