import { Component, Input, OnChanges } from '@angular/core';
import { MultiOrderImportResult } from '../../../services/order-import.service';
import { Order } from '../../../models/order';
import { OrderSubmitService } from '../../../services/order-submit.service';
import { first } from 'rxjs';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';

@Component({
  selector: 'lib-multi-order-excel-import-result',
  templateUrl: './multi-order-excel-import-result.component.html',
  styleUrls: ['./multi-order-excel-import-result.component.scss']
})
export class MultiOrderExcelImportResultComponent implements OnChanges {
  creatingOrders: { [index: number]: boolean } = {};
  createdOrders: { [index: number]: string } = {};
  errorsInCreation: { [index: number]: string } = {};

  @Input()
  result: MultiOrderImportResult;

  constructor(protected orderSubmitService: OrderSubmitService, protected gungFlowService: GungFlowService) {}

  ngOnChanges(): void {
    if (!this.result) {
      return;
    }

    this.gungFlowService
      .getSelectedFlow()
      .pipe(first())
      .subscribe(flow => {
        this.result.orders = this.result.orders.map(o => {
          o.extra._flow = flow;
          return o;
        });
      });
  }

  createOrder(order: Order, index: number) {
    this.creatingOrders[index] = true;

    this.orderSubmitService
      .postOrder('json/order/create/GUNG_WEB', order)
      .pipe(first())
      .subscribe({
        next: res => {
          this.createdOrders[index] = res.erpOrderIds.join(', ');
          this.creatingOrders[index] = false;
        },
        error: err => {
          this.errorsInCreation[index] = err.message;
          this.creatingOrders[index] = false;
        }
      });
  }
}
