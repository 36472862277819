import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { first, tap } from 'rxjs';
import { GungBase } from '../../models';

export interface AccountRequest extends GungBase {
  companyAddress: string;
  companyContactEmail: string;
  companyContactName: string;
  companyContactPhone: string;
  companyCountry: string;
  companyId: string;
  companyName: string;
  companyOrganisationId: string;
  creationDate: Date;
  processed: boolean;
  processedByUserId: string;
  rejected: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AccountRequestService {
  protected baseUrl = 'json/account-requests';

  private accountRequestSubject: BehaviorSubject<AccountRequest[]>;

  constructor(protected http: HttpClient) {}

  public getAccountRequest(nocache?: boolean): Observable<AccountRequest[]> {
    const url = this.baseUrl;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<AccountRequest[]>(url, { headers });
  }

  public markAsProcessed(accountId): Observable<AccountRequest[]> {
    const url = `${this.baseUrl}/${accountId}/mark-as-processed`;
    return this.http.get<AccountRequest[]>(url).pipe(tap(_ => this.updateAccountRequestSubject(true)));
  }

  public markAsRejected(accountId): Observable<void> {
    const url = `${this.baseUrl}/${accountId}/mark-as-rejected`;
    return this.http.get<void>(url).pipe(tap(_ => this.updateAccountRequestSubject(true)));
  }

  // List in internal Subject

  public getAccountRequestInternal(nocache?: boolean): Observable<AccountRequest[]> {
    if (!this.accountRequestSubject) {
      this.accountRequestSubject = new BehaviorSubject<AccountRequest[]>([]);
      this.updateAccountRequestSubject(nocache);
    }
    return this.accountRequestSubject.asObservable();
  }

  updateAccountRequestSubject(nocache?: boolean): void {
    if (!this.accountRequestSubject) {
      return;
    }
    this.getAccountRequest(nocache)
      .pipe(first())
      .subscribe((data: AccountRequest[]) => this.accountRequestSubject.next(data));
  }
}
