import { Component, OnInit, ChangeDetectorRef, Type, ViewChild } from '@angular/core';
import { CartService } from '../../services/cart/cart.service';
import { CartRow } from '../../state/cart/types';
import { debounce } from 'rxjs';
import { timer, Observable } from 'rxjs';
import { CartConfigService } from '../../services/cart-config/cart-config.service';
import { ListItemRendererComponent, FilterListComponent } from 'gung-list';

@Component({
  selector: 'lib-cart',
  template: '<lib-filter-list #cartList [configService]="cartConfigService"></lib-filter-list>',
  styleUrls: ['./cart.component.css']
})
export class CartComponent {
  constructor(public cartConfigService: CartConfigService) {}
  @ViewChild('productList') productList: FilterListComponent<CartRow>;
}
