import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-pim-import-excel-modal',
  templateUrl: './pim-import-excel-modal.component.html',
  styleUrls: ['./pim-import-excel-modal.component.css']
})
export class PimImportExcelModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
