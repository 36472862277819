import { Component } from '@angular/core';
import { GlobalSearchResult } from '../../services/global-search-config.service';
import { FastsearchItemRendererComponent } from 'gung-list';

@Component({
  selector: 'lib-global-search-product-detail',
  templateUrl: './global-search-product-detail.component.html',
  styleUrls: ['./global-search-product-detail.component.css']
})
export class GlobalSearchProductDetailComponent extends FastsearchItemRendererComponent<GlobalSearchResult> { }
