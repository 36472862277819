<div class="change-log-level">
  <div class="row justify-content-center">
    <div class="col-md-4">
      <!-- <select
        name="changeLogLevel"
        class="form-control"
        [(ngModel)]="changeService"
        (ngModelChange)="searchService($event)"
      >
        <option
          [value]="option"
          *ngFor="let option of logServices; let i = index"
        >
          {{ option}}
        </option>
      </select> -->
      <lib-gung-text-input-typeahead
        [(ngModel)]="changeService"
        (ngModelChange)="searchService($event)"
        [suppressLabel]="true"
        [renderStatic]="false"
        [list]="logServices"
        (selectItem)="searchService($event)"
        [search]="searchTypeahead"
        [resultFormatter]="resultFormatter"
        [resultTemplate]="rt"
      >
      </lib-gung-text-input-typeahead>
      <ng-template #rt let-changeService="result" let-t="term">
        <span class="font-weight-bold">{{changeService}}</span>
        <span *ngIf="changeService && loggers?.[changeService]?.configuredLevel as configuredLevel">
          : <span class="badge badge-{{configuredLevel}}">{{configuredLevel}}</span>
        </span>
      </ng-template>
      <select
        name="changeLogLevel"
        class="form-control mt-3"
        [(ngModel)]="changeLogLevel"
      >
        <option
          [value]="option"
          *ngFor="let option of logLevels; let i = index"
        >
          {{ option}}
        </option>
      </select>
      <button type="button" class="btn btn-block btn-secondary mt-3" (click)="updateLogLevel()" translate>CHANGE</button>
    </div>
    <div class="col-md-3">
        <ng-container *ngFor="let log of loggers | keyvalue">
          <span *ngIf="log.value.configuredLevel" class="d-block">
            <ng-container *ngTemplateOutlet="rt; context:{ $implicit: undefined, result: log.key }"></ng-container>
          </span>
        </ng-container>
    </div>
  </div>
</div>
