<!-- <div class="row mb-3">
    <div class="col-12">
      <h4 translate>DIGITAL_ASSETS_DOCUMENTS</h4>
      <div class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
        style="background: #FFFFFF; border: 5px dashed #DDD; text-align: center; padding: 50px 10px; margin-left: 10px;"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event, 'documents')"
        (click)="fileInput2.click();">
        <!-- <span data-translate="ADD_FILE" class="ng-scope">Add file(s) by clicking on or dragging to this area</span> - ->
        <!-- for="file" - -><label><strong>Add file(s) by clicking on</strong><span class="box__dragndrop"> or dragging to this area</span>.</label>
        <br>
        <span><small class="" *ngFor="let file of (fileInput2.files || fileInput2.files); let last = last">{{file?.name}}{{!last ? ', ' : ''}}</small></span>
        <input hidden #fileInput2 type="file" name="files[]" id="file" (change)="onDrop($event, 'documents')" accept="image/*,application/pdf" multiple />
      </div>
    </div>
</div> -->
<div class="row">
  <div class="col-12 col-md-1 text-md-right">
    <label for="file-upload" translate>SELECT_FILES</label>
  </div>
  <div class="col">
    <input
      #inputFile
      type="file"
      class="_custom-file-input"
      id="file-upload"
      aria-describedby="file-upload"
      [multiple]="false"
      [disabled]="loading ? true : null"
    />
    <!-- (change)="onFileSelect($event)" -->
  </div>
</div>
<div class="row mt-2">
  <div class="offset-md-1 col">
    <button
      type="button"
      class="btn btn.sm btn-primary px-5"
      (click)="uploadFile(inputFile)"
      [disabled]="loading ? true : null"
      translate
    >
      UPLOAD <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
    </button>
  </div>
</div>
