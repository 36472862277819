<lib-gung-text-input
  *ngIf="!horizontal"
  [(ngModel)]="dateString"
  [renderStatic]="true"
  [gungTranslate]="gungTranslate"
  [labelBold]="labelBold"
></lib-gung-text-input>
<lib-gung-text-input-horizontal
  *ngIf="horizontal"
  [labelClass]="labelClass"
  [(ngModel)]="dateString"
  [renderStatic]="true"
  [gungTranslate]="gungTranslate"
  [labelBold]="labelBold"
></lib-gung-text-input-horizontal>
