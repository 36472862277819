<div class="modal-header" *ngIf="!!availability">
  <h4 class="modal-title" id="modal-basic-title">
    <span translate>FUTURE</span>&nbsp;
    <small>
      <span translate>AVAILABILITY</span>
      <span> {{ availability.currentAvailability || 0 | number }} <span translate>PCS</span></span> /
      <span translate>MAX_FUTURE</span>&nbsp;<span
        *ngIf="availability?.maxFutureStock === 100000; else showMaxFutureStock"
        translate
        >STOCK_REPLENISHMENT</span
      >
      /
      <ng-template #showMaxFutureStock
        >{{ availability.maxFutureStock || 0 | number }}<span translate>PCS</span></ng-template
      >
      <span translate>STOCK</span>
      <span> {{ availability.currentStock || 0 | number }} <span translate>PCS</span></span>
    </small>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <lib-gung-tabset [destroyOnHide]="false">
    <lib-gung-tab title="{{ 'BREAKDOWN' | translate }}">
      <ng-template libGungTabContent>
        <table class="table table-striped">
          <thead class="table-dark">
            <tr>
              <th scope="col" translate>DATE</th>
              <th scope="col" translate>ITEMS_AVAILABLE</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let availabilitie of availability.extra.availabilities | keyvalue">
              <td>{{ parseDate(availabilitie.key) | date: 'shortDate' }}</td>
              <td>
                {{
                  availabilitie.value > 0
                    ? availabilitie.value === 100000
                      ? ('STOCK_REPLENISHMENT' | translate)
                      : (availabilitie.value | number)
                    : ('NO_AVAILABILITY' | translate)
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </lib-gung-tab>
    <lib-gung-tab *ngIf="purchaseOrders && purchaseOrders.length > 0">
      <ng-template libGungTabTitle>{{ 'PURCHASE_ORDER_DETAILS' | translate }}</ng-template>
      <ng-template libGungTabContent>
        <table class="table table-striped">
          <thead class="table-dark">
            <tr>
              <th scope="col" translate>ORDER</th>
              <th scope="col" translate>QUANTITY</th>
              <th scope="col" translate>DELIVERY_DATE</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of purchaseOrders">
              <td>{{ row.orderId }}</td>
              <td>{{ parseFloat(row.quantity) | number }} {{ 'PCS' | translate }}</td>
              <td>{{ row.transactionDate | date: dateUtilService.dateFormat }}</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </lib-gung-tab>
    <lib-gung-tab title="{{ 'CUSTOMER_ORDER_DETAILS' | translate }}" *ngIf="customerOrders.length > 0">
      <ng-template libGungTabContent>
        <table class="table table-striped">
          <thead class="table-dark">
            <tr>
              <th scope="col" translate>CUSTOMER_ID</th>
              <th scope="col" translate>CUSTOMER_NAME</th>
              <th scope="col" translate>ORDER</th>
              <th scope="col" translate>QUANTITY_REMAINING</th>
              <th scope="col" translate>DELIVERY_DATE</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of customerOrders">
              <td>{{ row.customerId }}</td>
              <td>{{ row.customerName }}</td>
              <td>{{ row.orderId }}</td>
              <td>{{ parseFloat(row.quantity) - parseFloat(row.quantityDelivered || 0) | number }} {{ 'PCS' | translate }}</td>
              <td>{{ row.transactionDate | date: dateUtilService.dateFormat }}</td>
            </tr>
          </tbody>
        </table>
      </ng-template>
    </lib-gung-tab>
  </lib-gung-tabset>
</div>

<div class="modal-footer"></div>
