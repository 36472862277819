import { Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { Customer } from '../models';
import { Observable, filter, first, forkJoin, of, switchMap } from 'rxjs';
import { GungModalService } from './gung-modal/gung-modal.service';
import { AuthService } from './auth/auth.service';
import { Router } from '@angular/router';
import { SelectedCustomerService } from './selected-customer/selected-customer.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarConfigService } from './navbar-config/navbar-config.service';
import { CreateUserModalComponent } from '../components/users/create-user-modal/create-user-modal.component';
import { ReportCentralReportService } from '../modules/report-central/services/report-central-report.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerActionConfigService implements ActionButtonConfigService<Customer> {
  enableContacts = this.navbarConfigService.enableContacts;
  enableCustomerActivities = this.navbarConfigService.enableCustomerActivities;
  enableStatisticsExcelExport = this.navbarConfigService.enableStatisticsExcelExport;

  base = [
    {
      title: 'ADD_CONTACT',
      action: (item: Customer) => {
        this.gungModalService.openAddContactModal(item);
      }
    },
    {
      title: 'PLACE_ORDER',
      action: (item: Customer) => {
        this.selectedCustomerService.selectCustomer(item.id);
        this.authService
          .getCurrentUser()
          .pipe(first())
          .subscribe(user => {
            this.router.navigate(['products']);
          });
      }
    },
    {
      title: 'CREATE_USER',
      action: (item: Customer) => {
        this.createUser(item);
      }
    },
  ];


  statisticsExcelExport = [
    {
      title: "EXPORT_ORDER_STATISTICS",
      action: (item) => {
        this.reportCentralReportService.exportOrderStatistics(item.id).pipe(first()).subscribe(_ => {
          this.gungModalService.openConfirmYesNoModal('EXPORT_ORDER_STATISTICS', 'EXPORT_ORDER_STATISTICS_MODAL', {}, 'GO_TO_REPORT_CENTRAL', 'CLOSE').then(
            result => {
              if (result) {
                this.router.navigate(['/report-central']);
              }
            },
            reject => { }
          );
        });
      }
    },
    {
      title: "EXPORT_INVOICE_STATISITCS",
      action: (item) => {
        this.reportCentralReportService.exportInvoiceStatistics(item.id).pipe(first()).subscribe(_ => {
          this.gungModalService.openConfirmYesNoModal('EXPORT_INVOICE_STATISITCS', 'EXPORT_INVOICE_STATISITCS_MODAL', {}, 'GO_TO_REPORT_CENTRAL', 'CLOSE').then(
            result => {
              if (result) {
                this.router.navigate(['/report-central']);
              }
            },
            reject => { }
          );
        });
      }
    }
  ]


  extra = [
    {
      title: 'SELECT_CUSTOMER',
      action: (item: Customer) => {
        this.router.navigate(['customers', item.id]);
        this.selectedCustomerService.selectCustomer(item.id);
      }
    }
  ];

  activitiesActions = [
    {
      title: 'ADD_ACTIVITY',
      action: (item: Customer) => {
        this.createActivity(item);
      }
    }
  ];

  contactsActions = [
    {
      title: 'ADD_CONTACT',
      action: (item: Customer) => {
        this.gungModalService.openAddContactModal(item);
      }
    }
  ];

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected ngbModal: NgbModal,
    protected gungModalService: GungModalService,
    protected navbarConfigService: NavbarConfigService,
    protected reportCentralReportService: ReportCentralReportService
  ) { }

  getButtons(item: Customer): Observable<ActionButton<Customer>[]> {
    return this.authService.getRoles().pipe(
      filter(roles => !!roles),
      first(),
      switchMap(roles => forkJoin([of(roles), of(this.router.url)])),
      switchMap(([roles, url]) => {
        if (roles.indexOf('SALES') === -1) {
          return of([]);
        }

        if (this.router.url === '/customers') {
          return new Observable<ActionButton<Customer>[]>(subscriber => {
            subscriber.next([
              ...this.extra,
              ...this.base,
              ...(this.enableContacts ? this.contactsActions : []),
              ...(this.enableCustomerActivities ? this.activitiesActions : [])

            ]);
            subscriber.complete();
          });
        } else {
          return new Observable<ActionButton<Customer>[]>(subscriber => {
            subscriber.next([
              ...this.base,
              ...(this.enableContacts ? this.contactsActions : []),
              ...(this.enableCustomerActivities ? this.activitiesActions : []),
              ...(this.enableStatisticsExcelExport ? this.statisticsExcelExport : [])
            ]);
            subscriber.complete();
          });
        }
      })
    );
  }

  createUser(customer: Customer): void {
    const userToCreateFrom = {
      username: '',
      name: customer.name,
      email: '',
      password: '',
      managedCompanyIds: customer.id,
      roles: ['USER'],
      managedMultistockIds: [],
      managedAssortmentIds: [],
      assortment: '',
      activeGroups: {},
      phone: ''
    };

    const modalRef = this.ngbModal.open(CreateUserModalComponent, { size: 'lg' });
    modalRef.componentInstance.createUserFrom = userToCreateFrom;
    modalRef.result.then(
      (u: any) => {
        if (!!u) {
          this.router.navigate(['/admin/users-details/' + u.username]);
        }
      },
      reason => { }
    );
  }

  createActivity(customer: Customer): void {
    this.gungModalService.openCreateActivityModal().then(
      result => { },
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }
}
