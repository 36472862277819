<div class="card product-card">
  <div class="card-body">
    <span class="favourite-icon-wrapper">
      <lib-favourite-product-icon [id]="data.product.id"></lib-favourite-product-icon>
    </span>
    <div class="image-wrapper cursor-pointer" (click)="goToDetail(data.product)">
      <img
        class="img-fluid"
        *ngIf="data.product.extra && data.product.extra.images && data.product.extra.images[0]"
        [src]="data.product.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.product.extra.images[0].s3ETag
        "
        alt=""
      />
    </div>
    <div class="product-text">
      <div>{{ data.product.id }}</div>
      <div class="text-truncate" translate>{{ data.product.name }}</div>
      <!--<div class="d-block" *ngFor="let value of data.product.dynamicCollumnsValues">{{value}}</div>-->
      <div translate *ngIf="data.price && !isAnonymous" class="font-weight-bold">
        <lib-price
          [price]="data.price"
          type="NET"
          [currency]="data.price.customerNetPrice.currencyCode"
          [priceLevelsDisplayType]="'COMPACT_GRID'"></lib-price>
      </div>
      <lib-availability [availability]="data.availability" [isSales]="isSales" *ngIf="includeAvailability && !isAnonymous" [product]="data.product">
      </lib-availability>
      <div class="product-card-button text-center">
        <container-element [ngSwitch]="data.product?.productType" *ngIf="!isAnonymous;else details">
          <button *ngSwitchCase="'concept'" class="btn btn-sm btn-primary" (click)="goToDetail(data.product)" translate>
            SHOW_PRODUCTS
          </button>
          <lib-buy-button-wrapper *ngSwitchDefault [product]="data.product" [availability]="data.availability"></lib-buy-button-wrapper>
        </container-element>
        <ng-template #details>
          <button class="btn btn-sm btn-primary" (click)="goToDetail(data.product)" translate>
            DETAILS
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
