<div class="form-group {{extraHostClass}}">
  <label [for]="inputId" *ngIf="!suppressLabel" [ngClass]="labelClass">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <select
    #selectOption
    *ngIf="!renderStatic"
    [class]="extraInputClass"
    [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control form-select'"
    [id]="inputId"
    [name]="inputId"
    [required]="required"
    [disabled]="disabled"
    [(ngModel)]="value"
  >
    <option *ngFor="let option of optionsList" [ngValue]="option.id">
      {{ showId ? option.id + ' - ' : '' }}{{ option.name }}
    </option>
  </select>
  <input
    *ngIf="renderStatic"
    type="text"
    readonly
    class="form-control-plaintext"
    [id]="inputId"
    [value]="selectedValueName | translate"
  />
</div>
