<div class="modal-header">
  <h3 translate>DEFAULT_PRODUCTS_TEMPLATE</h3>
</div>
<div class="modal-body">
  <div class="templates">
    <select class="form-control" [(ngModel)]="selectedTemplateId" (ngModelChange)="changeTemplate()">
      <option [value]="template.id" *ngFor="let template of templates">
        {{ template.id }}
      </option>
    </select>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" aria-label="Close" (click)="cancel()" translate>CANCEL</button>
  <button type="button" class="btn btn-light" (click)="setDefaultTemplate()" translate>SAVE</button>
</div>

