import { Injectable } from '@angular/core';
import { Order } from '../models/order';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class OrderImportService {
  constructor(protected http: HttpClient) {}

  public parseMultiOrderColumnExcel(formData: FormData): Observable<MultiOrderImportResult> {
    const url = `json/order-xlsx-import/multi-order`;
    return this.http.post<MultiOrderImportResult>(url, formData);
  }
}

export interface MultiOrderImportResult {
  orders: Order[];
  errors: OrderImportError[];
}

export interface OrderImportError {
  sheet: string;
  rowNumber: number;
  productId: string;
  error: string;
}
