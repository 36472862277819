import { Observable } from 'rxjs';
import { ActivityInternalComment } from '../services/activity-internal/activity-internal.service';
import { GungBase } from './base';
import { S3Item } from './s3';

export interface Supplier extends GungBase {}

export interface SupplierPurchaseOrder extends GungBase {
  quantity: number;
  orderId: string;
  productId: string;
  supplierId: string;
  rowNumber: number;
  status: number;
  etd: any;
  eta: any;
  comments: SupplierPurchaseComment[];
  text1: string;
  text2: string;
  text3: string;
  text4: string;
  text5: string;
  checkbox1: boolean;
  checkbox2: boolean;
  checkbox3: boolean;
  checkbox4: boolean;
  checkbox5: boolean;
}

export interface SupplierPurchaseOrderHistory {
  changes: string[];
  id: string;
  orderLineId: string;
  timestamp: Date;
  username: string;
}

export interface SupplierPurchaseComment {
  documents: S3Item[];
  comment: string;
  date: Date | string;
  userId: string;
  read?: boolean;
}

export enum ColumnRowTableType {
  TEXT,
  NUMBER,
  DATE,
  HTML,
  IMAGE,
  ACTION,
  TABLE,
  CHECKBOX,
  PRICE,
  INPUTNUMBER,
  INPUTDATE,
  INPUTCHECKBOX,
  INPUTTEXT,
  INPUTTEXTAREA,
  HIDDEN,
  CUSTOM
}

export interface SupplierTableColumns {
  name: string;
  path: string | string[] | (string | string[])[];
  type: ColumnRowTableType;
  sorted?: boolean;
  sort?: string; 
  extra?: {
    globalActions?: string[];
    conditions?: {
      comparator: '===' | '>' | '>==' | '<' | '<==' | '!==';
      path1: string;
      path2: string;
      value2?: string | number; // Replace comparator path2 (need to set path2 = null)
      result: (item: SupplierPurchaseOrder) => Observable<SupplierPurchaseOrder>;
    }[];
    // value?: string; // TODO: Use to force some value if don't want to use the path
    thClass?: string;
    tdClass?: string;
    required?: boolean; // Used to check if the field is filled
    copyTo?: string | string[]; // Used to copy from one field to another
    delete?: string | string[]; // Used to delete field to not send to backend
    patchValue?: 'replace'; // Used to patch in the request endpoint
    nextStatus?: string; // Used to set the next status id
    rejectStatus?: string; // Used to set the reject status id
    maxValue?: (item: SupplierPurchaseOrder) => number;
    minValue?: (item: SupplierPurchaseOrder) => number;
    
    [s: string]: any;
  } | null;
}
