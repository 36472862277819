<div class="pt-3">
  <div class="row">
    <div class="col-12 col-md-3">
      <div
        class="form-check pt-1 d-flex align-items-center justify-content-between"
        *ngFor="let multiStocksId of multiStocksIds; let i = index; trackBy: trackByFn"
      >
        <label class="form-check-label">
          {{ multiStocksId }}
        </label>
        <button type="button" class="btn btn-link p-1" (click)="remove(i)"><i class="fas fa-times"></i></button>
      </div>
    </div>
  </div>

  <div class="row pb-4">
    <div class="col-6 col-md-1">
      <input type="text" class="form-control form-control-sm" placeholder="0" #value />
    </div>
    <div class="col-6 col-md-2">
      <button type="button" class="btn btn-primary btn-block btn-sm" (click)="add(value)">
        {{ 'ADD' | translate }}
      </button>
    </div>
  </div>
</div>
