<div class="container">
  <div class="row mb-2">
    <div class="col">
      <input
        class="form-control"
        type="text"
        [attr.placeholder]="'SEARCH' | translate"
        [formControl]="searchTermForm"
      />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <ng-template libCustomHost></ng-template>
    </div>
  </div>
</div>
