import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, of } from 'rxjs';
import { switchMap } from 'rxjs';
import { EdiRespondeOrder, GungEdimessageType } from '../../models/edi';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';
import { AuthService } from '../auth/auth.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { EdiMessageBaseActionConfigService } from '../edi-message-base-action-config/edi-message-base-action-config.service';
import { EdiService } from '../edi/edi.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class EdiRespondeOrderResponseActionConfigService
  extends EdiMessageBaseActionConfigService
  implements ActionButtonConfigService<EdiRespondeOrder>
{
  protected getMessageType(): GungEdimessageType {
    return GungEdimessageType.ORDER_RESPONSE;
  }
}
