import { AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewEncapsulation } from '@angular/core';
import L, { MapOptions, Map, Marker, FeatureGroup, tileLayer, latLng, marker, MarkerClusterGroupOptions, MarkerCluster } from 'leaflet';

export interface GungMap {
  id?: string,
  name?: string,
  extra?: { [s: string]: any }
  bindPopup: string, //Accepts html content.
  coordenates: number[]
}

@Component({
  selector: 'lib-gung-map',
  templateUrl: './gung-map.component.html',
  styleUrls: ['./gung-map.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class GungMapComponent implements OnInit, OnChanges, AfterViewInit {
  //map layers: https://leaflet-extras.github.io/leaflet-providers/preview/

  private customIcon = L.icon({
    iconUrl: './assets/pin-icon.svg',
    iconSize: [32, 32],
    iconAnchor: [16, 32],
    popupAnchor: [0, -32],
  });

  @Input()
  options: MapOptions;

  @Input()
  data: GungMap[];

  @Input()
  invalidateSize: number;

  mapOptions: MapOptions;
  map: Map;
  markers: Marker[] = [];
  markerGroup: FeatureGroup = new FeatureGroup();
  markerClusterGroup: L.MarkerClusterGroup;

  mapInitialized: boolean = false;

  ngOnInit(): void {
    this.initializeMap();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.mapInitialized && !!changes.data && !changes.data.firstChange && changes.data.previousValue.length !== changes.data.currentValue.length) {
      this.refreshMap();
    }
  }

  ngAfterViewInit(): void {
    if (this.invalidateSize) {
      setTimeout(() => {
        this.map.invalidateSize();
      }, this.invalidateSize);
    }
  }

  private initializeMap(): void {
    this.mapOptions = {
      layers: [
        tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: 'Gung'
        })
      ],
      zoom: 2,
      center: latLng(0, 0),
      ...this.options
    };
  }

  protected refreshMap(): void {
    if (this.mapInitialized) {
      this.map.removeLayer(this.markerClusterGroup);
      this.markerGroup.clearLayers();
      this.markers = [];
      this.addMarkersToMap();
    }
  }

  onMapReady(map: Map): void {
    this.map = map;
    this.addMarkersToMap();
    this.mapInitialized = true;
  }

  private addMarkersToMap(): void {
    for (const data of this.data) {
      const location = data.coordenates;

      if (location && location.length === 2) {
        const coords = latLng(location[0], location[1]);
        const mapMarker = marker(coords, { icon: this.customIcon }).bindPopup(data.bindPopup);
        this.markers.push(mapMarker);
      }
    }

    const markerClusterOptions: MarkerClusterGroupOptions = {
      chunkedLoading: true,
      showCoverageOnHover: false,
      spiderfyOnMaxZoom: true,
      iconCreateFunction: this.createClusterIcon
    };

    this.markerClusterGroup = L.markerClusterGroup(markerClusterOptions);

    this.markers.forEach((marker) => {
      this.markerClusterGroup.addLayer(marker);
    });

    this.map.addLayer(this.markerClusterGroup);

    const validMarkers = this.markers.filter(marker => marker.getLatLng().lat !== 0 && marker.getLatLng().lng !== 0);
    if (validMarkers.length > 0) {
      if (validMarkers.length === 1) {
        this.map.setView(validMarkers[0].getLatLng(), 15);
      } else {
        try {
          this.map.fitBounds(this.markerClusterGroup.getBounds());
        } catch (error) {
          console.log(error);
        }
      }
    }
  }

  private createClusterIcon(cluster: L.MarkerCluster): L.DivIcon {
    const childCount = cluster.getChildCount();
    let c = ' marker-cluster-';

    if (childCount < 10) {
      c += 'small';
    } else if (childCount < 100) {
      c += 'medium';
    } else {
      c += 'large';
    }

    return new L.DivIcon({
      html: '<div><span>' + childCount + '</span></div>',
      className: 'marker-cluster' + c,
      iconSize: new L.Point(40, 40)
    });
  }
}
