import { Injectable } from '@angular/core';
import { map, Observable, of, switchMap } from 'rxjs';
import { Customer } from '../../models';
import { AppState } from '../../state/state.module';
import { Store } from '@ngrx/store';
import { SelectCustomer } from '../../state/customer/actions';
import { Supplier } from '../../models/supplier';
import { SelectSupplier } from '../../state/supplier/actions';
import { SupplierService } from '../suppliers/supplier.service';

@Injectable({
  providedIn: 'root'
})
export class SelectedSupplierService {
  constructor(
    protected store: Store<AppState>,
    protected supplierService: SupplierService
  ) {}

  getSelectedSupplier(): Observable<Supplier | null> {
    return this.store.select(state => state.supplier.selectedSupplier).pipe(
      switchMap(supplier => {
        if (!supplier) {
          return this.supplierService.getDefaultSupplier();
        }
        return of(supplier);
      })
    );
  }

  selectSupplier(supplierId: string): void {
    this.store.dispatch(
      new SelectSupplier({
        supplierId
      })
    );
  }
}
