import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { first, forkJoin, mergeMap } from 'rxjs';
import { SelectedCustomerService } from '../../../../services/selected-customer/selected-customer.service';
import { AuthService } from '../../../../services/auth/auth.service';

@Component({
  selector: 'lib-assign-defaults',
  templateUrl: './assign-defaults.component.html',
  styleUrl: './assign-defaults.component.scss'
})
export class AssignDefaultsComponent extends CheckoutStepComponent implements OnInit{

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected authService: AuthService
  ){
    super()
  }

  ngOnInit(): void {
    this.onNextBtnClicked.subscribe(() => {
      forkJoin({
        customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
        user: this.authService.getCurrentUser().pipe(first())
      }).subscribe(({customer, user}) => {
          this.setValues(customer, user);
          this.stepDone.emit(this.checkout);
        });
    });
  }
  
  setValues(customer, user){
    if (customer == null) {
      this.stepError.emit({
        message: 'Could not find a selected customer',
        step: 'AssignDefaultsComponent',
        error: new Error()
      });
      return;
    }
    this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];
    this.checkout.extra.gme = {}
    this.checkout.extra.gme.billingAddress = customer.extra.gme.billingAddress;
    this.checkout.extra.gme.deliveryAddress = {}

    //todo
    this.checkout.extra.gme.termsOfPayment = customer.extra.gme?.termsOfPayment;
    this.checkout.extra.gme.termsOfDelivery = customer.extra.gme?.termsOfDelivery;
    this.checkout.extra.gme.deliveryMethod = customer.extra.gme?.deliveryMethod;
  }

  public isVisible() {
    return false;
  }
}
