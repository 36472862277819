<div class="form-group {{extraFormClass}}">
  <label [for]="inputId" *ngIf="!suppressLabel" [ngClass]="{ 'font-weight-bold': labelBold }">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
    <span *ngIf="footnote" class="ml-1">*</span>
  </label>
  <input
    type="number"
    #inputNumber
    [class]="extraInputClass"
    [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
    [readonly]="renderStatic"
    [id]="inputId"
    [name]="inputId"
    [required]="required"
    [disabled]="disabled"
    [max]="maxValue"
    [min]="minValue"
    [step]="stepAmount"
    [placeholder]="placeholder | translate"
    [(ngModel)]="value"
  />
</div>
