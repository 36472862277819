import { ExportSelection } from 'gung-list';
import { Injectable } from '@angular/core';
import { AbstractSelectionService, SelectionAction } from 'gung-list';
import { Product } from '../../models';
import { Observable, of } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ProductSelectionService extends AbstractSelectionService<Product> {
  constructor() {
    super();
  }

  getItemId(item: Product): string {
    return item.id;
  }
}
