<div class="modal-header">
  <h4 class="modal-title" id="modal-title" translate>CREATE_NEW_GUNG_FLOW</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-12">
    <div class="form-group">
      <label for="flowid" class="control-label" translate>NEWFLOW_ID</label>
      <input class="form-control" type="text" name="flowid" id="flowid" [(ngModel)]="flowId" ngbAutofocus />
    </div>
  </div>

  <div class="col-12 pt-2 alert alert-danger mt-1" role="alert" *ngIf="error">
    <h5>{{ 'ERROR' | translate }}</h5>
    <h6>{{ error.message }}</h6>
  </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col d-flex justify-content-end">
      <button type="button" class="btn btn-light btn-sm" (click)="activeModal.close(false)" translate>CANCEL</button>
      <button type="submit" class="btn btn-primary btn-sm ml-2" (click)="create()" translate>CREATE</button>
    </div>
  </div>
</div>
