import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterListComponent } from 'gung-list';
import { Product } from '../../../models';
import { WhiteboardOrderProductListConfigService } from '../../services/whiteboard-order-product-list-config.service';

@Component({
  selector: 'lib-whiteboard-order-product-list',
  template: '<lib-filter-list #productList [configService]="listConfig"></lib-filter-list>',
  styleUrls: ['./whiteboard-order-product-list.component.css']
})
export class WhiteboardOrderProductListComponent implements OnInit {
  constructor(public listConfig: WhiteboardOrderProductListConfigService, public route: ActivatedRoute) {}
  @ViewChild('productList') productList: FilterListComponent<Product>;

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const whiteboardId = params.get('whiteboardId');

      if (whiteboardId != null) {
        this.listConfig.whiteboardSubject.next(whiteboardId);
      } else {
        this.listConfig.whiteboardSubject.next(null);
      }
    });
  }
}
