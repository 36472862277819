import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutSingleComponent } from 'gung-list';
import { Observable, of } from 'rxjs';
import { CustomerContactsGungCardListComponent } from '../../components/customer-contacts-gung-card-list/customer-contacts-gung-card-list.component';
import { CustomerContact } from '../../models/customerContact';
import { CustomerContactsGungService } from '../customer-contacts-gung/customer-contacts-gung.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactsGungListConfigService implements ConfigService<CustomerContact> {
  /*
   This variable and setter is called in the customer-contact-list-component to set
   the customerId to the id in the route params. It did not work to get the routeparams
   from this service.
   */
  customerId: string;
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(
    protected customerContactsGungService: CustomerContactsGungService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  getItems(): Observable<CustomerContact[]> {
    if (this.customerId == null) {
      return of([]);
    }

    return this.customerContactsGungService.getCustomerContacts(this.customerId);
  }

  getFilters(): ConfigBaseFilter<CustomerContact>[] {
    return [];
  }

  getSortOptions(): ListSortOption<CustomerContact>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<CustomerContact>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => CustomerContactsGungCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'CustomerContacts grid'
      }
    ];
  }

  getSearchTerms(item: any): string[] {
    return [item.id];
  }

  getItemId(item: any): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
