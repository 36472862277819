import { Component, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { AssortmentRecursive } from '../../services/assortment.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-assortment-tree-list-view',
  templateUrl: './assortment-tree-list-view.component.html',
  styleUrls: ['./assortment-tree-list-view.component.css']
})
export class AssortmentTreeListViewComponent extends ListItemRendererComponent<AssortmentRecursive[]> implements OnInit, AfterViewInit {
  currentLang: string;

  constructor(protected translateService: TranslateService) {
    super();
  }

  ngOnInit(): void {
    this.currentLang = this.translateService.currentLang;
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
