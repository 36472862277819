<button fastsearchSelect type="button" class="btn btn-light btn-block btn-sm text-left" (click)="goToDetail(data.id)">
  <div class="row">
    <div class="col-3 p-0">
      <img
        alt="{{ data.name }}"
        class="img-fluid"
        *ngIf="data.extra.images && data.extra.images[0]; else noimage"
        [src]="data.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.extra.images[0].s3ETag
        "
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
    </div>
    <div class="col-9 text-truncate">
      <span class="d-block">
        <small
          ><b>{{ data.id }}</b></small
        >
        <lib-price
          class="float-right"
          *ngIf="!!data.extra.price"
          [price]="data.extra.price"
          [product]="data"
          type="NET"
          [currency]="data.extra.price.customerNetPrice.currencyCode"
        ></lib-price>
      </span>
      <br />
      <span>{{ data.name }}</span>
    </div>
  </div>
</button>
