import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { getDocument, GlobalWorkerOptions, PDFPageProxy, RenderTask, version } from 'pdfjs-dist/legacy/build/pdf';
import { RenderParameters } from 'pdfjs-dist/types/src/display/api';
import { PDFDocumentProxy } from 'pdfjs-dist/types/web/pdf_find_controller';
import { LineMatching, PdfMatchingResponse, PDFTableArea } from '../../models/pdf-to-cart';
import { PdfReaderService } from '../../services/pdf-reader/pdf-reader.service';
import { AuthService } from './../../services/auth/auth.service';
import { CartService } from './../../services/cart/cart.service';
import { ProductService } from './../../services/products/product.service';
import { Product } from '../../models';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-pdf-to-cart',
  templateUrl: './pdf-to-cart.component.html',
  styleUrls: ['./pdf-to-cart.component.scss']
})
export class PdfToCartComponent implements OnInit {
  @ViewChild('pdfCanvas')
  pdfCanvas: ElementRef;
  pdfPage: PDFPageProxy;
  pdf: PDFDocumentProxy;
  renderContext: RenderParameters;
  renderingInProgress: boolean;
  renderTask: RenderTask;

  fileToSubmit: File;
  s3Uri: string;
  canvasMouseClicked: { x: number; y: number } | undefined = undefined;
  drawnRectangle: PDFTableArea | undefined = undefined;

  pdfMatchingResponse: PdfMatchingResponse;
  selectedQuantitiesMap: {
    [productId: string]: string;
  };

  importing = false;
  manuallySetColumns = false;
  runningManualMatching = false;
  currentCustomerId: string;

  // PDF viewer settings
  scale = 1.5;
  pageNumber = 1;
  pageCount = undefined;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected domSanitizer: DomSanitizer,
    protected cartService: CartService,
    protected pdfReaderService: PdfReaderService,
    protected productService: ProductService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  ngOnInit() {
    GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${version}/pdf.worker.min.js`;
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        this.currentCustomerId = customer.id;
      });
  }

  mouseDown(event: PointerEvent) {
    this.canvasMouseClicked = { x: event.offsetX, y: event.offsetY };
  }

  mouseUp(event: PointerEvent) {
    this.drawnRectangle = {
      left: this.canvasMouseClicked.x / this.scale,
      top: this.canvasMouseClicked.y / this.scale,
      right: event.offsetX / this.scale,
      bottom: event.offsetY / this.scale
    };
    this.canvasMouseClicked = undefined;
  }

  mouseMove(event: PointerEvent) {
    if (!this.canvasMouseClicked) {
      return;
    }
    if (this.renderingInProgress) {
      return;
    }

    const x = this.canvasMouseClicked.x;
    const y = this.canvasMouseClicked.y;
    const w = event.offsetX - this.canvasMouseClicked.x;
    const h = event.offsetY - this.canvasMouseClicked.y;
    this.drawRectangle(x, y, w, h);
  }

  drawRectangle(x: number, y: number, w: number, h: number) {
    this.renderingInProgress = true;
    this.renderTask = this.pdfPage.render(this.renderContext);
    this.renderTask.promise
      .then(() => {
        const canvasContext = this.renderContext.canvasContext as CanvasRenderingContext2D;
        canvasContext.strokeStyle = '#0000FF';
        canvasContext.strokeRect(x, y, w, h);
      })
      .finally(() => {
        this.renderingInProgress = false;
      });
  }

  mouseOut() {
    this.canvasMouseClicked = undefined;
  }

  public selectFile(files: FileList) {
    const file = files[0];

    this.fileToSubmit = file;

    const fileReader = new FileReader();
    fileReader.onload = e => {
      this.readPDFFile(new Uint8Array(e.target.result as ArrayBuffer));
    };
    fileReader.readAsArrayBuffer(file);
  }

  submitFile() {
    this.importing = true;
    this.pdfReaderService
      .parsePdfTable(this.s3Uri, this.pageNumber - 1, this.drawnRectangle, this.currentCustomerId)
      .subscribe({
        next: res => {
          this.importing = false;
          this.pdfMatchingResponse = res;
          this.updateProductNames();
        },
        error: err => {
          console.error('Parse error', err);
          this.importing = false;
        }
      });
  }

  updateProductNames() {
    const productLines = this.pdfMatchingResponse.lineMatchings.filter(line => line.matchFound);
    this.productService.getProductsByIdsUnfiltered(productLines.map(line => line.productId)).subscribe(products => {
      products.forEach((product, index) => {
        productLines[index].productName = product.name;
      });
    });
  }

  async readPDFFile(pdfData) {
    this.pdf = await getDocument({ data: pdfData }).promise;
    // eslint-disable-next-line no-underscore-dangle
    this.pageCount = this.pdf._pdfInfo.numPages || undefined;
    this.pageNumber = 1;
    await this.setRenderPage();
    await this.renderPdf();
    this.submitPDF();
  }

  private submitPDF() {
    this.pdfReaderService.submitOrderPdf(this.fileToSubmit).subscribe({
      next: res => {
        this.s3Uri = res;
      },
      error: err => {
        console.error(err);
        alert('File could not be uploaded - try again');
      }
    });
  }

  private async renderPdf() {
    const viewport = this.pdfPage.getViewport({ scale: this.scale });

    // Prepare canvas using PDF page dimensions
    const canvas = this.pdfCanvas.nativeElement;
    const context = canvas.getContext('2d');
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    // Render PDF page into canvas context
    this.renderContext = {
      canvasContext: context,
      viewport
    };
    this.renderTask = this.pdfPage.render(this.renderContext);
  }

  private async setRenderPage() {
    this.pdfPage = await this.pdf.getPage(this.pageNumber);
  }

  async onPrevPage() {
    if (this.pageNumber <= 1) {
      return;
    }
    this.pageNumber--;
    await this.setRenderPage();
    await this.renderPdf();
  }

  async onNextPage() {
    if (this.pageNumber >= this.pageCount) {
      return;
    }
    this.pageNumber++;
    await this.setRenderPage();
    await this.renderPdf();
  }

  addToCart() {
    const rows = this.pdfMatchingResponse.lineMatchings.filter(line => line.matchFound && line.qty > 0);
    this.cartService.bulkAddToCart(rows);
  }

  setAsProductIdColumn(index: number) {
    this.pdfMatchingResponse.indices.idIndex = index;
    this.updateProductMatchingsManually();
    this.checkIfNewMatchesFound();
  }

  setAsQuantityColumn(index: number) {
    this.pdfMatchingResponse.indices.qtyIndex = index;
    this.updateProductMatchingsManually();
  }

  updateProductMatchingsManually() {
    this.manuallySetColumns = true;
    this.pdfMatchingResponse.lineMatchings.forEach((lineMatching, index) => {
      lineMatching.qty = parseInt(
        this.pdfMatchingResponse.tableData[index][this.pdfMatchingResponse.indices.qtyIndex],
        10
      );
      lineMatching.productId = this.pdfMatchingResponse.tableData[index][this.pdfMatchingResponse.indices.idIndex];
      lineMatching.pdfId = this.pdfMatchingResponse.tableData[index][this.pdfMatchingResponse.indices.idIndex];
      lineMatching.matcherUsed = 'MANUALLY_SET_COLUMNS';
    });
  }

  checkIfNewMatchesFound() {
    this.pdfMatchingResponse.lineMatchings.forEach(lineMatching => {
      const id = lineMatching.productId;
      this.productService.getProduct(id).subscribe({
        next: product => {
          lineMatching.matchFound = true;
          lineMatching.productName = product.name;
        },
        error: () => {
          lineMatching.matchFound = false;
          lineMatching.productName = '';
        }
      });
    });
  }

  selectProduct(product: Product, line: LineMatching) {
    line.productId = product.id;
    line.productName = product.name;
    line.matchFound = true;
    line.matcherUsed = 'MANUALLY_SET_ID';
  }

  runMatchersWithManuallySetColumns() {
    this.runningManualMatching = true;
    this.pdfReaderService.runMatchersWithManualIndices(this.pdfMatchingResponse).subscribe({
      next: res => {
        this.pdfMatchingResponse = res;
        this.runningManualMatching = false;
        alert('Parsing done, check other tab for results');
      },
      error: err => {
        this.runningManualMatching = false;
        console.error('Parse error', err);
        alert('Problem parsing pdf, try again');
      }
    });
  }

  productSearchFormatter(result: Product) {
    return result.id + ' ' + result.name;
  }
}
