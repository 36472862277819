<div class="modal-header">
  <h4 class="modal-title" translate>{{ data.gln }} - {{ data.b24.name }} - {{ data.companyId }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem>
      <a ngbNavLink translate>ADDRESS</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-12">* <span translate>GLN_WITHOUT_INVOICE_CUSTOMER</span></div>
          <div class="col-6">
            <lib-gung-text-input
              [(ngModel)]="data.gln"
              [gungTranslate]="'CUSTOMER_GLN'"
              [placeholder]="'CUSTOMER_GLN' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.glnBuyer"
              [gungTranslate]="'BUYER_GLN'"
              [placeholder]="'CUSTOMER_GLN' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.glnRecipient"
              [gungTranslate]="'RECIPIENT_GLN'"
              [placeholder]="'CUSTOMER_GLN' | translate"
            ></lib-gung-text-input>
            <div class="w-100">
              <label for="internal" class="mr-1" translate>PRIMARY</label>
              <input type="checkbox" id="internal" name="internal" [(ngModel)]="data.primary" />
            </div>
            <lib-gung-text-input
              [(ngModel)]="data.b24.address"
              [gungTranslate]="'ADDRESS'"
              [placeholder]="'ADDRESS' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.zipCode"
              [gungTranslate]="'ZIP_CODE'"
              [placeholder]="'ZIP_CODE' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.city"
              [gungTranslate]="'CITY'"
              [placeholder]="'CITY' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.countryCode"
              [gungTranslate]="'COUNTRY_CODE'"
              [placeholder]="'COUNTRY_CODE' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.vatNumber"
              [gungTranslate]="'VAT_NUMBER'"
              [placeholder]="'VAT_NUMBER' | translate"
            ></lib-gung-text-input>
          </div>
          <div class="col-6">
            <lib-gung-text-input
              [(ngModel)]="data.b24.name"
              [gungTranslate]="'COMPANY_NAME'"
              [placeholder]="'COMPANY_NAME' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.companyId"
              [gungTranslate]="'CUSTOMER_ID'"
              [placeholder]="'CUSTOMER_ID' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.emailAddress"
              [gungTranslate]="'EMAIL_ADDRESS'"
              [placeholder]="'EMAIL_ADDRESS' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.telephoneNumber"
              [gungTranslate]="'PHONE_NUMBER'"
              [placeholder]="'PHONE_NUMBER' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [(ngModel)]="data.b24.orgNo"
              [gungTranslate]="'ORGANISATION_NUMBER'"
              [placeholder]="'ORGANISATION_NUMBER' | translate"
            ></lib-gung-text-input>
            <lib-gung-text-input
            [(ngModel)]="data.b24.bankName"
            [gungTranslate]="'BANK_NAME'"
            [placeholder]="'BANK_NAME' | translate"
          ></lib-gung-text-input>
          <lib-gung-text-input
            [(ngModel)]="data.b24.bankAccountNumber"
            [gungTranslate]="'BANK_ACCOUNT_NUMBER'"
            [placeholder]="'BANK_ACCOUNT_NUMBER' | translate"
          ></lib-gung-text-input>
        </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-container ngbNavItem>
      <a ngbNavLink translate>B24_INTEGRATIONS</a>
      <ng-template ngbNavContent>
        <div class="row">
          <div class="col-12">
            <span translate>SELECT_INTEGRATIONS</span>
          </div>
          <div class="col-4">
            <div class="w-100">
              <label for="internal" class="mr-1" translate>INVOICES</label>
              <input type="checkbox" id="internal" name="internal" [(ngModel)]="data.b24.invoices" />
            </div>
          </div>
          <div class="col-4">
            <div class="w-100">
              <label for="internal" class="mr-1" translate>ORDER_RESPONSES</label>
              <input type="checkbox" id="internal" name="internal" [(ngModel)]="data.b24.orderResponses" />
            </div>
          </div>
          <div class="col-4">
            <div class="w-100">
              <label for="internal" class="mr-1" translate>PRICATS</label>
              <input type="checkbox" id="internal" name="internal" [(ngModel)]="data.b24.pricats" />
            </div>
          </div>
          <div class="col-4">
            <div class="w-100">
              <label for="internal" class="mr-1" translate>DESPATCH_ADVISES</label>
              <input type="checkbox" id="internal" name="internal" [(ngModel)]="data.b24.despatchAdvices" />
            </div>
          </div>
          <div class="col-4">
            <div class="w-100">
              <label for="internal" class="mr-1" translate>SKIP_MISSING_LINES</label>
              <input
                type="checkbox"
                id="internal"
                name="internal"
                [(ngModel)]="data.b24.orderResponsesSkipEmptyLines"
              />
            </div>
          </div>
        </div>
      </ng-template>
    </ng-container>
  </nav>

  <div [ngbNavOutlet]="nav" class="mt-2"></div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSave()" translate>SAVE</button>
</div>
