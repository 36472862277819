<ng-template #htmlContent>
  <img *ngIf="selectedImageUrl" [src]="selectedImageUrl" class="tooltip-img" />
</ng-template>
<div class="table-responsive-sm gung-responsive" *ngIf="mappedData; else loading">
  <table class="table table-striped">
    <thead class="sticky-table-header">
      <tr>
        <th translate>PRODUCT</th>
        <th *ngFor="let headerKey of dynamicColumns" class="fit-column">{{ headerKey.translationKey | translate }}</th>
        <th translate>AVAILABILITY</th>
        <th class="buy-btn-width-150" translate>PRICE</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData; index as i">
        <td [attr.data-label]="'PRODUCT' | translate">
          <a [routerLink]="['./', item.product?.id]" class="product-link">
            <span class="d-block font-weight-bold text-nowrap">{{
              item.product?.extra.ar?.artnr || item.product?.id
            }}</span>
            {{ item.product?.extra.ar?.artbeskr || item.product?.name }}
          </a>
        </td>
        <td
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          *ngFor="let value of item.product.dynamicCollumnsValues; let i = index"
          class="text-right text-nowrap"
        >
          <span *ngIf="tooltipEnabled" placement="right" [ngbTooltip]="htmlContent" tooltipClass="tooltip-container">{{
            value
          }}</span>
          <span *ngIf="!tooltipEnabled">{{ value }}</span>
        </td>
        <td>
          <lib-availability [availability]="item.availability" [isSales]="isSales"></lib-availability>
        </td>
        <td [attr.data-label]="'PRICE' | translate">
          <span *ngIf="item.price" class="d-block text-center">
            <lib-price [price]="item.price" type="GROSS"></lib-price>
            <span *ngIf="item.price.customerDiscountPercent !== 0">(-{{ item.price.customerDiscountPercent }}%)</span>
          </span>
          <container-element [ngSwitch]="item.product?.productType">
            <button
              *ngSwitchCase="'concept'"
              class="btn btn-sm btn-primary"
              (click)="goToDetail(item.product)"
              translate
            >
              SHOW_PRODUCTS
            </button>
            <lib-buy-button-wrapper
              *ngSwitchDefault
              class="height-30"
              [id]="item.product?.id"
              [product]="item.product"
              [minimumOrderQuantity]="item.product?.packageSize"
              [stepAmount]="item.product?.packageSize"
            ></lib-buy-button-wrapper>
          </container-element>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i *ngIf="!findError" class="fa fa-spinner fa-spin fa-7x"></i>
  <div *ngIf="findError" class="mb-2 text-center">
    {{ errorMessage }}
  </div>
</ng-template>
