import { Component, AfterViewInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ListItemRendererComponent } from 'gung-list';
import { EdiGlnNumbers } from '../../../models/edi';
import { EdiService } from '../../../services/edi/edi.service';
import { EdiGlnNumbersEditModalComponent } from '../edi-gln-numbers-edit-modal/edi-gln-numbers-edit-modal.component';

@Component({
  selector: 'lib-edi-gln-numbers-table-view',
  templateUrl: './edi-gln-numbers-table-view.component.html',
  styleUrls: ['./edi-gln-numbers-table-view.component.css']
})
export class EdiGlnNumbersTableViewComponent extends ListItemRendererComponent<EdiGlnNumbers[]> implements AfterViewInit {
  constructor(protected modalService: NgbModal, protected ediService: EdiService) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onEdit(index) {
    const element = this.data[index];
    const modalRef = this.modalService.open(EdiGlnNumbersEditModalComponent, {
      backdrop: 'static',
      keyboard: true,
      size: 'lg'
    });
    modalRef.componentInstance.data = JSON.parse(JSON.stringify(element));
    modalRef.result.then(
      result => {
        const newElement = JSON.parse(result);
        console.log(newElement);
        this.ediService.postGlnNumbers(element.id, newElement).subscribe(
          resp => {
            if (resp && resp.id) {
              this.data[index] = newElement;
            }
          },
          error => {}
        );
      },
      reason => {}
    );
  }
}
