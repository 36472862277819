import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../../models/pim';

@Component({
  selector: 'lib-pim-product-shopify-attributes',
  templateUrl: './pim-product-shopify-attributes.component.html',
  styleUrls: ['./pim-product-shopify-attributes.component.css']
})
export class PimProductShopifyAttributesComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  @Input()
  language: string;

  constructor() {}

  ngOnInit() {
    if (!this.pimProduct.i18n[this.language]?.shopify) {
      this.pimProduct.i18n = {
        ...(this.pimProduct.i18n || {}),
        [this.language]: {
          ...(this.pimProduct.i18n[this.language] || {}),
          shopify: {
            ...(this.pimProduct.i18n[this.language]?.shopify || {})
          }
        }
      };
    }
  }
}
