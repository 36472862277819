<div
  [ngClass]="{ 'btn-group': !blockBtns, 'block-btns': blockBtns }"
  *ngIf="(buttons | async)?.length > 0 || (individualButtons | async)?.length > 0"
>
  <button
    class="btn btn-outline-primary"
    *ngFor="let button of individualButtons | async"
    (click)="$event.preventDefault(); $event.stopPropagation(); button.action(item, $event)"
    [disabled]="button.disable"
  >
    <i *ngIf="button.icon" class="{{ button.icon }}"></i><span translate>{{ button.title }}</span>
  </button>
  <div
    ngbDropdown
    placement="{{placement}}"
    class="d-inline-block mr-2"
    [class.w-100]="blockBtns"
    *ngIf="(buttons | async)?.length > 0"
  >
    <button class="btn btn-outline-primary {{btnClass}}" id="dropdownBasic1" ngbDropdownToggle translate>{{title}}</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
      <button
        ngbDropdownItem
        *ngFor="let button of buttons | async"
        (click)="$event.preventDefault(); $event.stopPropagation(); button.action(item, $event)"
        [disabled]="button.disable"
      >
        <i *ngIf="button.icon" class="{{ button.icon }}"></i><span translate>{{ button.title }}</span>
      </button>
    </div>
  </div>
</div>
