import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EdiOrderMessageService {
  constructor(
    protected http: HttpClient
  ) {}

  updateEanToNewEan(idList:string[], eanList: {}): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('json/edi/v2/batch/messages/items/replace', {messageIds:idList, eanReplaceList:eanList}).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  updateItemsChanges(idList:string[], itemList: {}): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('json/edi/v2/batch/messages/items/changes/update', {messageIds:idList, itemList:itemList}).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }
}
