import { Component, Input, OnInit } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';
import { SupplierService } from './../../../../services/suppliers/supplier.service';
import { Supplier } from './../../../../models/supplier';
import { Observable, OperatorFunction } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs';

@Component({
  selector: 'lib-user-details-managed-supplier',
  templateUrl: './user-details-managed-supplier.component.html',
  styleUrls: ['./user-details-managed-supplier.component.css']
})
export class UserDetailsManagedSupplierComponent implements OnInit {
  loaded: boolean;

  @Input()
  currentUser: User;

  managedSupplierIds: Supplier[];
  managedSupplierQuery: any;
  managedSupplierIdModel: string;

  parseError = {
    hasError: false,
    errorText: ''
  };

  protected suppliers: Supplier[];
  public modelSearch: any;
  getField = (contact: Supplier) => contact?.name || (contact as any);
  formatter = (item: Supplier) => this.getField(item);
  resultFormatter = (item: Supplier) => item.name + ' - ' + this.getField(item);

  search: OperatorFunction<string, readonly Supplier[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter((term: string) => term.length >= 2),
      map(term => this.suppliers.filter(contact => new RegExp(term, 'mi').test(this.getField(contact))).slice(0, 10))
    );

  constructor(protected supplierService: SupplierService) {}

  ngOnInit() {
    if (!!this.currentUser.managedSupplierQuery) {
      // this.managedSupplierQuery = JSON.stringify(this.currentUser.managedSupplierQuery, undefined, 4);
      this.managedSupplierQuery = JSON.parse(this.currentUser.managedSupplierQuery);
    }

    this.supplierService.getSuppliers().subscribe(suppliers => {
      this.suppliers = suppliers;
      if (suppliers?.length > 0) {
        this.managedSupplierIds = this.currentUser.managedSupplierIds.map(id => {
          return suppliers.find(s => s.id === id) || { name: '', id, extra: undefined };
        });
      } else {
        this.managedSupplierIds = this.currentUser.managedSupplierIds.map(id => {
          return { name: '', id, extra: undefined };
        });
      }

      this.loaded = true;
    });
  }

  validate(): void {
    try {
      if (!this.currentUser.managedSupplierQuery) {
        this.currentUser.managedSupplierQuery = null;
      }

      this.parseError.hasError = false;
      this.managedSupplierQuery = JSON.parse(this.currentUser.managedSupplierQuery);
    } catch (error) {
      this.parseError.hasError = true;
      this.parseError.errorText = error.message;
    }
  }

  removeSelected(supplierId: string): void {
    const supplierIndexToRemove = this.managedSupplierIds.findIndex(supplier => supplier.id === supplierId);
    if (supplierIndexToRemove > -1) {
      this.managedSupplierIds.splice(supplierIndexToRemove, 1);
      const supplierIndexToRemove2 = this.currentUser.managedSupplierIds.findIndex(e => e === supplierId);
      if (supplierIndexToRemove2 > -1) {
        this.currentUser.managedSupplierIds.splice(supplierIndexToRemove2, 1);
      }
    }
  }

  addSupplierId(supplierId: string) {
    if (!supplierId) {
      return;
    }
    if (!this.currentUser.managedSupplierIds) {
      this.currentUser.managedSupplierIds = [];
    }
    this.currentUser.managedSupplierIds.push(supplierId);
    if (!this.managedSupplierIds) {
      this.managedSupplierIds = [];
    }
    this.managedSupplierIds.push({ name: '', id: supplierId, extra: undefined });
    this.managedSupplierIdModel = '';
  }

  selectItem({ item: itemObject, preventDefault }, target) {
    const item: Supplier = itemObject;
    if (this.currentUser.managedSupplierIds.indexOf(item.id) > -1) {
      preventDefault();
      this.modelSearch = '';
      target.value = '';
      return;
    }
    if (!this.currentUser.managedSupplierIds) {
      this.currentUser.managedSupplierIds = [];
    }
    this.currentUser.managedSupplierIds.push(item.id);
    if (!this.managedSupplierIds) {
      this.managedSupplierIds = [];
    }
    this.managedSupplierIds.push(item);
    preventDefault();
    this.modelSearch = '';
    target.value = '';
  }

  ngModelChange(itemObject, target) {
    console.log('ngModelChange itemObject', itemObject);
    // const item: CustomerGungContact = itemObject;
    // const inputItem = this.form.get('contacts');
    // if (!item) {
    //   inputItem.patchValue(this.formBuilder.array([]));
    // }
  }

  focusout({ target }, modelSearch: Supplier) {
    console.log('focusout modelSearch', modelSearch);
    // if (!modelSearch) {
    //   target.value = '';
    // }
  }
}
