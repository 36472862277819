import { Action } from '@ngrx/store';
import { Metadata } from './types';

export enum ActionTypes {
  GetMetadata = '[Metadata] Get',
  MetadataReceived = '[Metadata] Received'
}

export class GetMetadata implements Action {
  type: string = ActionTypes.GetMetadata;
}

export class MetadataReceived implements Action {
  type: string = ActionTypes.MetadataReceived;
  constructor(public payload: Metadata) {}
}
