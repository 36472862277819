import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PriceService } from './../../../../../../services/price/price.service';
import { ProductService } from './../../../../../../services/products/product.service';
import { CartService } from './../../../../../../services/cart/cart.service';
import { AuthService } from './../../../../../../services/auth/auth.service';
import { Product } from './../../../../../../models/product';
import { first, pipe } from 'rxjs';
import { CartListCheckoutConfigService } from './../../../../../../services/cart-list-checkout-config/cart-list-checkout-config.service';
import { ProductInputQuantityConfigService } from './../../../../../../services/product-input-quantity-config/product-input-quantity-config.service';
import { CustomerProductPrice } from './../../../../../../models/price';

@Component({
  selector: 'lib-checkout-add-row-modal',
  templateUrl: './checkout-add-row-modal.component.html',
  styleUrls: ['./checkout-add-row-modal.component.css']
})
export class CheckoutAddRowModalComponent implements OnInit {

  private orderLineItemProductId = '';
  isSalesOrAdmin: boolean = false;
  isUser: boolean = false;
  isSales: boolean = false;
  isAdmin: boolean = false;
  isAnonymous: boolean = false;

  modalRows: {
    productId: string;
    qty: number;
    price: number;
    discount: number;
    productObject?: Product;
    priceObject?: CustomerProductPrice;
  }[] = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected cartService: CartService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected authService: AuthService,
    public cartListCheckoutConfig: CartListCheckoutConfigService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService
  ) {}

  ngOnInit(): void {
    this.authService.getRoles().pipe(first()).subscribe(roles => {
      this.isSalesOrAdmin = roles.indexOf('ADMIN') > -1 || roles.indexOf('SALES') > -1;
      this.isSales = roles.indexOf('SALES') > -1;
      this.isAdmin = roles.indexOf('ADMIN') > -1;
      this.isUser = roles.indexOf('USER') > -1 && !this.isAdmin && !this.isUser;
      this.isAnonymous = roles.indexOf('ANONYMOUS') > -1;
    });
    this.addNewRow();
  }

  addNewRow() {
    this.modalRows.push({
      productId: this.orderLineItemProductId,
      qty: 1,
      price: undefined,
      discount: undefined
    });
  }

  removeRow(index) {
    this.modalRows.splice(index, 1);
  }

  selectProduct(product: Product, rowIndex: number) {
    this.modalRows[rowIndex].productId = product.id;
    this.modalRows[rowIndex].productObject = product;
    this.priceService
      .getCurrentCustomerPrice(product.id)
      .pipe(first())
      .subscribe(price => {
        this.modalRows[rowIndex].price = price.customerNetPrice.value;
        this.modalRows[rowIndex].priceObject = price;
        this.validateQuantity(1, rowIndex, undefined)
      }
    );
  }

  validateQuantity(value: number, rowIndex: number, target?): void {
    const {productId, productObject: product, priceObject: price} = this.modalRows[rowIndex];
    const allowManualQtyInputOutsideStepSizes = this.productInputQuantityConfigService.getAllowManualQtyInputOutsideStepSizes(productId, product, price, this.isAnonymous, this.isUser, this.isSales, this.isAdmin);
    const minimumOrderQuantity = this.productInputQuantityConfigService.getMinimumOrderQuantity(productId, product, price);
    const stepAmount = this.productInputQuantityConfigService.getStepAmount(productId, product, price);
    const recomendedInitOrderQuantity = this.productInputQuantityConfigService.getRecomendedInitOrderQuantity(productId, product, price);
    const showDecimals = this.productInputQuantityConfigService.showDecimals(productId, product);
    // const allowPurchaseOverAvailability = this.productInputQuantityConfigService.getAllowPurchaseOverAvailability(value, availability, product, roles);

    if(showDecimals === 0) {
      value = Math.floor(value);
    } else {
      value = Number(Number(value).toFixed(showDecimals));
    }

    /* if (!allowManualQtyInputOutsideStepSizes) {
      value = 0;
    } else if (allowManualQtyInputOutsideStepSizes) {
      //
    } */ 
    if (value < recomendedInitOrderQuantity) {
      value = recomendedInitOrderQuantity;
    } else if (value >= minimumOrderQuantity && (stepAmount === 1 || value % stepAmount === 0)) {
      // 
    } else if (!allowManualQtyInputOutsideStepSizes && value >= 0 && value % stepAmount !== 0) {
      value += stepAmount - (value % stepAmount);
    }

    if (value <= 0) {
      this.modalRows[rowIndex].qty = recomendedInitOrderQuantity;
    }
    if (target) {
      target.value = value;
    } else {
      this.modalRows[rowIndex].qty = value;
    }
  }

  submitRows() {
    if (this.modalRows.length === 0) {
      this.activeModal.close();
      return;
    }
    const ids = [];
    for (const modalRow of this.modalRows) {
      if (modalRow.productId) {
        
        let extra = {}
        const price = this.cartListCheckoutConfig.changePriceField;
        const discount = this.cartListCheckoutConfig.changeDiscountField
        if(this.cartListCheckoutConfig.enableSalesEditPrice){
          this.setObjectPropertyValue(extra, price, modalRow.price);
        }        
        if(this.cartListCheckoutConfig.enableSalesEditDiscount){
          this.setObjectPropertyValue(extra, discount, modalRow.discount);
        }
        this.cartService.addToCart(modalRow.productId, modalRow.qty, undefined, undefined, undefined, extra);
        ids.push(modalRow.productId);
      }
    }

    this.activeModal.close({ ids });
  }

  productSearchFormatter(result: Product) {
    return result.id + ' - ' + result.name;
  }

  productSearchInputFormatter(result: Product) {
    return result.id;
  }

  setObjectPropertyValue(obj, propertyString, value) {
    const properties = propertyString.split('.');
    const lastPropertyIndex = properties.length - 1;
    properties.reduce((result, property, index) => {
      if (index === lastPropertyIndex) {
        result[property] = value; // Set the value of the final property
      } else if (property in result) {
        return result[property]; // Access the property
      } else {
        result[property] = {}; // Create nested objects if the property doesn't exist
        return result[property];
      }
    }, obj);
  }
}
