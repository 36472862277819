<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CHANGE_ITEM_ID</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <span class="d-block"><strong translate>ID</strong>: {{ ediOrderId }}</span>
  <span class="d-block"><strong translate>LINE</strong>: {{ index }}</span>
  <lib-gung-text-input-horizontal
    [(ngModel)]="ediOrderItem.ids.gungId"
    [gungTranslate]="'GUNGID'"
    [placeholder]="'GUNGID' | translate"
  ></lib-gung-text-input-horizontal>
  <lib-gung-text-input-horizontal
    [(ngModel)]="ediOrderItem.ids.ean13"
    [gungTranslate]="'EAN13'"
    [placeholder]="'EAN13' | translate"
  ></lib-gung-text-input-horizontal>
  <lib-gung-text-input-horizontal
    [(ngModel)]="ediOrderItem.ids.upca"
    [gungTranslate]="'UPCA'"
    [placeholder]="'UPCA' | translate"
  ></lib-gung-text-input-horizontal>
  <lib-gung-checkbox-input-horizontal
    gungTranslate="Validate Again"
    [(ngModel)]="validate"
  ></lib-gung-checkbox-input-horizontal>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="save($event)" translate>SAVE</button>
  <button class="btn btn-sm btn-outline-primary px-4" (click)="activeModal.close(false)" translate>CANCEL</button>
</div>
