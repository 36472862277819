<div *ngIf="data">
  <div class="row border-bottom mb-2 card-body" *ngFor="let ediDispatchAdvice of data; index as i; trackBy: trackByFn">
    <div class="col-12">
      <h5 class="d-inline cursor-pointer">
        <a [routerLink]="ediDispatchAdvice.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          {{ ediDispatchAdvice.id }} - <lib-edi-send-status [message]="ediDispatchAdvice"></lib-edi-send-status>
        </a>
      </h5>
      <div class="float-right">
        <lib-action-btn [item]="ediDispatchAdvice" [config]="actionConfigService"></lib-action-btn>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="ORDER_ID"
        [(ngModel)]="ediDispatchAdvice.documentReferences.buyerOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="SUPPLIER_ORDER_NUMBER"
        [(ngModel)]="ediDispatchAdvice.documentReferences.supplierOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4"></div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="DISPATCH_ADVICE_DATE"
        [(ngModel)]="ediDispatchAdvice.deliveryDate"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="DELIVERY_DATE"
        [(ngModel)]="ediDispatchAdvice.deliveryDate"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="VALIDATION"
        [(ngModel)]="ediDispatchAdvice.validationState"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="MESSAGE_DATE"
        [(ngModel)]="ediDispatchAdvice.documentReferences.despatchAdvice.date"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="CONFIG_ID"
        [(ngModel)]="ediDispatchAdvice.ediConfigId"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4" *ngIf="!!ediDispatchAdvice.timestamp">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="TIMESTAMP"
        [(ngModel)]="ediDispatchAdvice.timestamp"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
  </div>
</div>
