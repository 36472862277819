import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { DynamicPdfService } from '../../services/dynamic-pdf.service';

@Component({
  selector: 'lib-pdf-export-default-template-modal',
  templateUrl: './pdf-export-default-template-modal.component.html',
  styleUrls: ['./pdf-export-default-template-modal.component.css']
})
export class PdfExportDefaultTemplateModalComponent implements OnInit {
  templates;
  selectedTemplateId: string;
  selectedTemplate;

  constructor(
    public activeModal: NgbActiveModal,
    protected dynamicPdfService: DynamicPdfService,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.dynamicPdfService.getProductTemplates().subscribe(templates => {
      this.templates = templates;
      this.selectedTemplate = this.templates[0];
      this.selectedTemplateId = this.templates[0].id;
    });
  }

  changeTemplate() {
    const template = this.templates.find(t => t.id === this.selectedTemplateId);
    this.selectedTemplate = template;
  }

  setDefaultTemplate() {
    this.activeModal.close(this.selectedTemplate);
  }

  cancel() {
    this.activeModal.dismiss();
    this.router.navigate(['products-export']);
  }
}
