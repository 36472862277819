import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../models/pim';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { PimService } from './../../../../services/pim/pim.service';
import { GungModalService } from './../../../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-pim-product-shopify',
  templateUrl: './pim-product-shopify.component.html',
  styleUrls: ['./pim-product-shopify.component.css']
})
export class PimProductShopifyComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  @Input()
  languages: string[];

  useShopifyProductTemplate: boolean;
  syncInProgress: boolean;

  constructor(
    protected metadataService: MetadataService,
    protected pimService: PimService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {}

  ngOnInit() {
    this.useShopifyProductTemplate = this.pimService.enableShopifyProductTemplates;
    this.ensureShopifyPropertiesInitialized();
  }

  protected ensureShopifyPropertiesInitialized() {
    this.pimProduct.extra = this.pimProduct.extra || {};
    this.pimProduct.extra.pim = this.pimProduct.extra.pim || {};
    this.pimProduct.extra.pim.buyerGroups = this.pimProduct.extra.pim.buyerGroups || {};
    if (!('SHOPIFY' in this.pimProduct.extra.pim.buyerGroups)) {
      this.pimProduct.extra.pim.buyerGroups.SHOPIFY = false;
    }

    this.pimProduct.extra.pim.colors = this.pimProduct.extra.pim.colors || {};
    const colortags = this.languages.reduce((acc, curr) => {
      acc[curr] = {
        colortags: {}
      };
      return acc;
    }, {});

    this.pimProduct.dimensions?.colors?.forEach(color => {
      this.pimProduct.extra.pim.colors[color.dimensionId] = this.pimProduct.extra.pim.colors[color.dimensionId] || {
        shopifySync: false,
        isNew: false,
        isOutlet: false,
        i18n: {
          ...JSON.parse(JSON.stringify(colortags)) // clone colortags so that they are not synced for all colors
        }
      };
    });
  }

  toggleShopifyActive() {
    this.pimProduct.extra.pim.buyerGroups.SHOPIFY = !this.pimProduct.extra.pim.buyerGroups.SHOPIFY;
    if (this.pimProduct.extra.pim.buyerGroups.SHOPIFY) {
      Object.keys(this.pimProduct.extra.pim.colors).forEach(color => {
        this.pimProduct.extra.pim.colors[color].shopifySync = true;
      });
    } else {
      Object.keys(this.pimProduct.extra.pim.colors).forEach(color => {
        this.pimProduct.extra.pim.colors[color].shopifySync = false;
      });
    }
  }

  syncToShopify(id: string) {
    this.syncInProgress = true;
    this.pimService.syncToShopify(id).subscribe(
      result => {
        this.gungModalService.openConfirmYesNoModal(
          undefined,
          this.translationService.instant('SHOPIFY_PRODUCT_SYNC_SUCCESS'),
          { size: 'sm' },
          'OK',
          null
        );
        this.syncInProgress = false;
      },
      err => {
        this.gungModalService.openConfirmYesNoModal(
          undefined,
          this.translationService.instant('SHOPIFY_PRODUCT_SYNC_FAIL'),
          { size: 'sm' },
          'OK',
          null
        );
        this.syncInProgress = false;
      }
    );
  }
}
