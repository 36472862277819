import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-assign-flow-setting',
  templateUrl: './assign-flow-setting.component.html',
  styleUrls: ['./assign-flow-setting.component.css']
})
export class AssignFlowSettingComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected gungFlowService: GungFlowService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.gungFlowService
        .getSelectedFlow()
        .pipe(first())
        .subscribe(flow => {
          if (flow.orderParams) {
            const merged = this.mergeDeep(this.checkout.extra, flow.orderParams);
            this.stepDone.emit({
              ...this.checkout,
              extra: {
                ...merged
              }
            });
          } else {
            this.stepDone.emit(this.checkout);
          }
        });
    });
  }

  public isVisible() {
    return false;
  }

  mergeDeep(target, ...sources) {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  isObject(item) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }
}
