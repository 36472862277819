<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">
      <span translate>3D_MODEL_VIEWER</span>
    </h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body p-0 text-center">
    <lib-gung-viewer3d-wrapper
      #gungViewer3d
      [modelFile]="models"
      width="100%"
      height="80vh"
      [initLoad]="true"
      [isExpanded]="true"
      [showMoreOptions]="true"
    ></lib-gung-viewer3d-wrapper>
  </div>
  
  <div class="modal-footer"></div>
  