import { Component, OnChanges, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { Subject, Subscription } from 'rxjs';
import { ExportDragProductService } from '../../services/export-drag-product.service';

@Component({
  selector: 'lib-product-drag-grid',
  templateUrl: './product-drag-grid.component.html',
  styleUrls: ['./product-drag-grid.component.scss']
})
export class ProductDragGridComponent extends ListItemRendererComponent<any[]> implements OnInit, OnDestroy, OnChanges {
  protected keyedMapData = {};
  public mappedData = [];
  public itemsPerRow = 3;

  protected mappedDataChanged: Subject<void> = new Subject<void>();
  protected unsubscribe: Subject<void> = new Subject();

  errorMessage = 'ERROR: ';
  findError = false;
  constructor(
    protected router: Router,
    protected exportDragProductService: ExportDragProductService,
    private renderer: Renderer2
  ) {
    super();
  }

  productDragExport = [];

  public ngOnInit() {
    this.subscribeToProducts();
  }

  public ngOnChanges() {
    this.subscribeToProducts();
  }

  public ngOnDestroy() {
    this.mappedDataChanged.complete();

    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected subscribeToProducts() {
    const newIds = this.data.map(p => p.id).filter(id => !Object.keys(this.keyedMapData).includes(id));
    if (newIds.length === 0) {
      this.readMappedDataFromCache();
      return;
    }

    const productData = this.data;

    this.keyedMapData = {
      ...this.keyedMapData,
      ...newIds.reduce((acc, curr) => {
        const product = productData.filter(p => p && p.id === curr)[0];

        if (!product) {
          this.findError = true;
          this.errorMessage += 'No product found. ';
          throw new Error('No product found');
        }

        const item = this.mapItem(curr, product);
        return {
          ...acc,
          [curr]: item
        };
      }, {})
    };
    this.readMappedDataFromCache();
  }

  protected readMappedDataFromCache() {
    const ids = this.data.map(d => d.id);
    this.mappedData = ids.map(id => this.keyedMapData[id]);

    this.mappedDataChanged.next();
  }

  protected mapItem(id: string, product) {
    return {
      product
    };
  }

  addToSheet(product) {
    this.exportDragProductService.setOneProduct(product);
  }

  stopDragProduct(event, id) {
    console.log('stop Drag');
  }

  onDragStart(event, product) {
    this.exportDragProductService.draggedProduct = product;
  }
}
