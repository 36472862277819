import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { Product } from '../../models';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';
import { ProductGridViewComponent } from '../../components/product-grid-view/product-grid-view.component';
import { ProductLogoApplicatorGridViewComponent } from '../../components/product-logo-applicator-grid-view/product-logo-applicator-grid-view.component';
import { AuthService } from '../auth/auth.service';
import { ProductService } from '../products/product.service';
import { ProductSortService } from '../product-sort/product-sort.service';

@Injectable({
  providedIn: 'root'
})
export class ProductLogoApplicatorListConfigService implements ConfigService<Product> {
  constructor(
    protected productListConfigService: ProductListConfigService,
    protected authService: AuthService,
    protected productService: ProductService,
    protected productSortService: ProductSortService
  ) {}

  getBatchSizes(): number[] | undefined {
    return this.productListConfigService.getBatchSizes();
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.productListConfigService.getFilters();
  }

  getItemId(item: Product): string {
    return this.productListConfigService.getItemId(item);
  }

  getItems(): Observable<Product[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        return this.productService.getProductsByAssortment(user.assortment).pipe(
          first(),
          map(products => {
            return products.filter(p => !!p.extra.pim && !!p.extra.pim.customizable);
          }),
          map(products => this.productSortService.sortProducts(products))
        );
      })
    );
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductLogoApplicatorGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getSearchTerms(item: Product): string[] {
    return this.productListConfigService.getSearchTerms(item);
  }

  getSortOptions(): ListSortOption<Product>[] {
    return this.productListConfigService.getSortOptions();
  }
}
