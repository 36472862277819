<div class="row" *ngIf="allowCreateBookmark">
  <div class="col text-right">
    <button class="btn btn-primary" (click)="openCreateModal()" translate>CREATE_NEW</button>
  </div>
</div>

<div class="row" *ngIf="bookmarks.length == 0">
  <div class="col">
    <span class="no-bookmarks-found" translate>NO_BOOKMARKS_FOUND</span>
  </div>
</div>

<div class="row">
  <div class="col">
    <div class="d-flex flex-row justify-content-between mb-3 bookmark-entry" *ngFor="let bookmark of bookmarks">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-column">
          <span class="id">{{ bookmark.id }}</span>
          <span class="name">{{ bookmark.name }}</span>
        </div>
      </div>
      <div class="d-flex flex-row align-items-center">
        <lib-action-btn [item]="bookmark" [config]="actionButtonConfig"></lib-action-btn>
      </div>
    </div>
  </div>
</div>
