import { Injectable, Input } from '@angular/core';
import { ConfigBaseFilter, IdBasedSubscriptionConfigService } from 'gung-list';
import { BehaviorSubject, mergeMap } from 'rxjs';
import { Product } from '../../models';
import { ProductListV2ConfigService } from '../product-list-v2-config/product-list-v2-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductListByIdsConfigService
  extends ProductListV2ConfigService
  implements IdBasedSubscriptionConfigService<Product>
{
  productIdsSubject = new BehaviorSubject<string[]>([]);
  showHeaderBanner = false;

  @Input()
  parentClass: string = 'col-12'; // Use to calculate number item per row

  setSubscriptionIds(ids: string[]) {
    this.productIdsSubject.next(ids);
  }

  getItems() {
    return this.productIdsSubject
      .asObservable()
      .pipe(mergeMap(productIds => this.productService.getProductsByIds(productIds)));
  }

  public getSearchGroupCss() {
    return '';
  }

  public getParentClass(): string {
    return this.parentClass;
  }

  public getChildColClassCss(parentClass?: string): {[colClassCss: string]: boolean} {
    parentClass = parentClass || this.parentClass;
    let childColClassCss: {[classCss: string]: boolean} = {
      'col-': false,
      'col-sm-': false,	
      'col-md-': false,	
      'col-lg-': false,	
      'col-xl-': false,
    };
    switch (parentClass) {
      case 'col-12':
        childColClassCss = {
          'col-6': true,
          'col-sm-4': false,	
          'col-md-4': true,	
          'col-lg-3': true,	
          'col-xl-3': false,
        };
        break;
      case 'col-6':
        childColClassCss = {
          'col-12': true,
          'col-sm-6': true,	
          'col-md-6': true,	
          'col-lg-4': true,	
          'col-xl-4': true,
        };
        break;
      default:
        break;
    }

    return childColClassCss;
  }

  getFilters(): ConfigBaseFilter<Product>[] {
    return [];
    // If you want to show the filter from the product list remove the previoud line
    return this.cloudPimFiltersService.getFilters(this.currentAssortmentService.currentAssortment);
  }
}
