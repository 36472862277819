<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>{{ isCreate ? 'ADD_ACTIVITY' : 'EDIT_ACTIVITY' }}</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row" *ngIf="activity">
    <div class="col-12">
      <div class="form-group">
        <label for="contactPerson" class="control-label" translate>GUNGDATA_KONTAKTPERSON</label>
        <select
          name="contactPerson"
          id="contactPerson"
          class="form-control"
          [(ngModel)]="activity.extra.gungData.Kontaktperson"
        >
          <option [value]="customerContact.name" *ngFor="let customerContact of customerContacts">
            {{ customerContact.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label class="control-label" for="ansvarig" translate>RESPONSIBLE</label>
        <lib-meta-select-input
          [suppressLabel]="true"
          [(ngModel)]="activity.extra.gungData.Ansvarig"
          (ngModelChange)="onChangeInput('Ansvarig')"
          table="TA04"
          metaId="KEY"
          metaDisplay="TX1"
        >
        </lib-meta-select-input>
        <div *ngIf="formErrors['Ansvarig']" class="text-danger font-weight-bolder input-error" translate>REQUIRED</div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label class="control-label" for="aktivitet" translate>ACTIVITY</label>
        <lib-meta-select-input
          [suppressLabel]="true"
          [(ngModel)]="activity.extra.gungData.Aktivitet"
          (ngModelChange)="onChangeInput('Aktivitet')"
          table="TA51"
          metaId="KEY"
          metaDisplay="TX1"
        >
        </lib-meta-select-input>
        <div *ngIf="formErrors['Aktivitet']" class="text-danger font-weight-bolder input-error" translate>REQUIRED</div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label class="control-label" for="prioritet" translate>PRIORITY</label>
        <lib-meta-select-input
          [suppressLabel]="true"
          [(ngModel)]="activity.extra.gungData.Prioritet"
          table="TA53"
          metaId="KEY"
          metaDisplay="TX1"
        >
        </lib-meta-select-input>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label class="control-label" for="status" translate>STATUS</label>
        <lib-meta-select-input
          [suppressLabel]="true"
          [(ngModel)]="activity.extra.gungData.Status"
          table="TA54"
          metaId="KEY"
          metaDisplay="TX1"
        >
        </lib-meta-select-input>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="date" class="control-label" translate>DATE</label>
        <div class="input-group">
          <input
            class="form-control input-date"
            placeholder="yyyy-MM-dd"
            name="dp"
            readonly
            ngbDatepicker
            #d="ngbDatepicker"
            container="body"
            [value]="getDisplayStartDate()"
            (dateSelect)="dateSelect($event)"
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
              <i class="fal fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="comment" lass="control-label" translate>COMMENT</label>
        <textarea
          class="form-control"
          type="text"
          name="comment"
          id="comment"
          rows="4"
          [(ngModel)]="activity.extra.gungData._text"
        ></textarea>
      </div>
    </div>
  </div>

  <div class="row" *ngIf="saved">
    <div class="col-12">
      <div class="alert alert-success mt-2 text-center" role="alert" translate>SAVED</div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-sm btn-default btn-outline-secondary text-uppercase" (click)="close()" translate>
    CLOSE
  </button>
  <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="save()" translate>
    SAVE
  </button>
</div>
