import { Component, OnInit, Optional } from '@angular/core';
import { LoginComponent } from '../login.component';
import { AuthService } from '../../../services/auth/auth.service';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { ScannedActionsSubject } from '@ngrx/store';
import { Location } from '@angular/common';
import { first } from 'rxjs';
import { LoginCheckService } from '../../../services/login-check.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthBackendService } from '../../../services/auth-backend/auth-backend.service';

@Component({
  selector: 'lib-navbar-login',
  templateUrl: './navbar-login.component.html',
  styleUrls: ['./navbar-login.component.css']
})
export class NavbarLoginComponent extends LoginComponent implements OnInit {
  forgotPassword: boolean;

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected actions$: ScannedActionsSubject,
    protected location: Location,
    protected loginCheckService: LoginCheckService,
    @Optional()
    protected oauth2Service: OAuthService
  ) {
    super(authService, formBuilder, route, router, actions$, loginCheckService, oauth2Service);

    const checkUrl = (url: string): boolean => {
      if (url && url === '/forgot-password') {
        return true;
      }
      return false;
    };

    this.forgotPassword = checkUrl(location.path());
    router.events.pipe(first()).subscribe((val: NavigationStart) => {
      this.forgotPassword = checkUrl(val.url);
    });
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
