import { Injectable } from '@angular/core';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class BackendRerouteService {
  constructor(protected backendInterceptor: BackendInterceptor) {}

  reroute(path: string, newTab?: boolean) {
    const url = this.backendInterceptor.getBaseUrl() + path;
    if (newTab) {
      window.open(url, '_blank').focus();
      return;
    }
    window.location.href = url;
  }
}
