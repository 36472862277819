import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'lib-gung-datepicker-modal',
  templateUrl: './gung-datepicker-modal.component.html',
  styleUrls: ['./gung-datepicker-modal.component.css']
})
export class GungDatepickerModalComponent implements OnInit {
  @Input() label: string;
  @Input() title: string;
  @Input() selectedDate: Date = new Date();

  delegate;
  componentId: string;

  constructor() {}

  ngOnInit() {
    this.componentId = GungDatepickerModalComponent.name;
  }

  close() {
    this.delegate.modalRef.get(this.componentId).dismiss();
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close({ selectedDate: this.selectedDate });
  }

  setDate(date: Date) {
    this.selectedDate = date;
  }
}
