import { Injectable } from '@angular/core';
import { I18n } from '../../models/i18n';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GungNotificationService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class TranslationManagementService {

  constructor(protected http: HttpClient, protected translationService: TranslateService,  protected gungNotificationService: GungNotificationService) {}

  loadTranslation(language: string): Observable<I18n> {
    const url = `public/json/i18n/customer/${language}`;
    return this.http.get<I18n>(url);
  }

  loadTranslations(): Observable<I18n[]> {
    const url = `json/i18n/customer`;
    return this.http.get<I18n[]>(url);
  }

  saveTranslations(translations: I18n[]): void{
    const url = 'json/i18n/customer';
    for (const translation of translations) {
      this.http.post(url, translation, {observe: 'response'} ).subscribe( data => {
        if(data.status == 200){
          this.gungNotificationService.notify(translation.id.toUpperCase() + ' ' + this.translationService.instant('UPDATE'), this.translationService.instant('SUCCESSFULLY_UPDATED_TRANSLATION'), 'success');
        }else{
          this.gungNotificationService.notify(translation.id.toUpperCase() + ' ' + this.translationService.instant('UPDATE_FAILED'), this.translationService.instant('ERROR_UPDATED_TRANSLATION'), 'error');
        }
      });
    }
    
  }
}
