import { Inject, Injectable, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject, of } from 'rxjs';
import { User } from './../../state/auth/types';
import { Customer } from '../../models/customer';
import { map, tap } from 'rxjs';
import { PasswordRequirement } from 'gung-common';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { NavigationConfig } from '../../models/navigation-config';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private userSelectedCustomerIdsSubject = new BehaviorSubject<Customer[]>([]);
  private userUnselectedCustomerIdSubject = new BehaviorSubject<string>('');

  private usersList: BehaviorSubject<User[]>;

  loginLowerCaseSensitive = false;
  allowSendEmailToUser = true;

  constructor(
    protected http: HttpClient,
    protected backendInterceptor: BackendInterceptor,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) {
    this.loginLowerCaseSensitive = this.backendInterceptor.getEnvironmentProperty('loginLowerCaseSensitive') || false;
  }

  getDefaultUserRoles():Observable<any> {
    return this.getUserRolesFromSystem().pipe(map(systemRoles =>{
      let baseRoles = [];
      if(systemRoles.length > 0){
        for (const role of systemRoles) {
          baseRoles.push({ selected: false, id: role })
        }
      }else{
        baseRoles = [
          { selected: false, id: 'ACTUATOR' },
          { selected: false, id: 'ADMIN' },
          { selected: false, id: 'SALES' },
          { selected: false, id: 'USER' }
        ];
      }
      if (this.environment.enableWarrantyClaims) {
        baseRoles.push(
          { selected: false, id: 'ACTIVE_FOR_CLAIMS_WARRANTY' }
        )
      }
      if (this.environment.enableSupplierPortal) {
        baseRoles.push({ selected: false, id: 'SUPPLIER' });
        baseRoles.push({ selected: false, id: 'BUYER' });
      }
      return baseRoles;
    }));

  }

  getUserRolesFromSystem(): Observable<any> {
    return this.http.get<any>('json/system/GUNG').pipe(map(system =>{
      return system.extra.userRoles || [];
    }));
  }

  getPasswordRequirement(): PasswordRequirement {
    const passwordRequirement: PasswordRequirement = {
      minCharacters: 0,
      maxCharacters: 0,
      especialCharacters: 0,
      lowercaseCharacters: 0,
      uppercaseCharacters: 0,
      numberCharacters: 0
    };
    return passwordRequirement;
  }

  getAllUsers(): Observable<User[]> {
    const url = `json/users`;
    const headers = { maxAge: '-1' };
    return this.http.get<User[]>(url, { headers }).pipe(
      map(users => {
        return users.map(user => {
          return { ...user, password: '' };
        });
      })
    );
  }

  getUsersIdByRoles(role: string): Observable<string[]> {
    const url = `json/users/with-role/${role}`;
    const headers = { maxAge: '-1' };
    return this.http.get<string[]>(url, { headers });
  }

  updateUsersListSubject(): void {
    this.getAllUsers().subscribe(users => this.usersList?.next(users));
  }

  getAllUsersSubject(): Observable<User[]> {
    if (!this.usersList) {
      this.usersList = new BehaviorSubject<User[]>(null);
      this.updateUsersListSubject();
    }
    return this.usersList.asObservable();
  }

  createUser(user: User): Observable<User> {
    if (!user.password) {
      user.password = '';
    }
    if (this.loginLowerCaseSensitive) {
      user.username = user.username.toLowerCase();
    }
    return this.http.post<User>('json/users', user).pipe(tap(() => this.updateUsersListSubject()));
  }

  updateUser(user: User): Observable<User> {
    if (!user.password) {
      user.password = '';
    }
    if (this.loginLowerCaseSensitive) {
      user.username = user.username.toLowerCase();
    }
    return this.http.put<User>('json/users/' + user.username, user).pipe(tap(() => this.updateUsersListSubject()));
  }

  deleteUser(username: string): Observable<boolean> {
    return this.http.delete<boolean>('json/users/' + username).pipe(tap(() => this.updateUsersListSubject()));
  }

  importFile(formData): Observable<any> {
    return this.http.post<any>('import/users', formData);
  }

  setUserSelectedCustomerIdsSubject(customers: Customer[]) {
    this.userSelectedCustomerIdsSubject.next(customers);
  }

  getUserSelectedCustomerIdsFromSubject(): Observable<Customer[]> {
    return this.userSelectedCustomerIdsSubject.asObservable();
  }

  sendUnselectedCustomerIdSubject(customerId: string) {
    this.userUnselectedCustomerIdSubject.next(customerId);
  }

  getUserUnselectedCustomerIdFromSubject(): Observable<string> {
    return this.userUnselectedCustomerIdSubject.asObservable();
  }

  sendWelcomeUserMessage(username: string, gungDomain: string, template?: string, resetPassword = this.environment.includePasswordResetTokenDefault) {
    const url = `json/users/welcome-message/${username}`;
    const data = {
      username,
      gungDomain,
      template,
      resetPassword
    }
    return this.http.post<User[]>(url, data).pipe(
      tap(res => console.log('res', res))
    );
  }
}
