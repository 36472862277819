<div class="table-responsive-sm gung-responsive" *ngIf="data; else loading">
  <table class="table table-striped">
    <thead class="sticky-table-header">
      <tr>
        <th translate>PRODUCT_ID</th>
        <th translate>NAME</th>
        <th *ngFor="let headerKey of dynamicColumns" class="fit-column">{{ headerKey.translationKey | translate }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data; index as i; trackBy: trackByFn">
        <td [attr.data-label]="'PRODUCT_ID' | translate">
          <a [routerLink]="['./', item.id]" class="product-link">
            {{ item.id }}
          </a>
        </td>
        <td [attr.data-label]="'NAME' | translate">
          {{ item.name }}
        </td>
        <td
          [attr.data-label]="dynamicColumns[i].translationKey | translate"
          *ngFor="let value of item.dynamicCollumnsValues; let i = index"
          class="text-right text-nowrap"
        >
          <span class="pre-line">{{ value }}</span>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
