import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionsListMulti } from 'gung-common';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';

@Component({
  selector: 'lib-documents-edit-modal',
  templateUrl: './documents-edit-modal.component.html',
  styleUrls: ['./documents-edit-modal.component.css']
})
export class DocumentsEditModalComponent implements OnInit {
  @Input()
  editMethod: (documentId: string, fileDescription: string, inMenu: boolean, userGroups: any) => Promise<boolean>;

  @Input()
  multiple: boolean;

  @Input()
  document: any;
  fileDescription = '';
  selectedGroups: any = {};
  inMenu = false;

  spinner = false;
  enableDocumentUserGroups = false;
  settingsGroups;

  constructor(
    private http: HttpClient,
    public activeModal: NgbActiveModal,
    protected navbarConfigService: NavbarConfigService,
    protected gungFlowService: GungFlowService
  ) {}

  ngOnInit() {
    this.initDocumentUserGroups();
    this.fileDescription = this.document.metaData.description;
    this.inMenu = this.document.metaData.inMenu === true || this.document.metaData.inMenu === 'true';
    this.selectedGroups = this.document.metaData.userGroups;
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files.length === 1 ? event.target.files[0].name : event.target.files.length + '';
    }
  }

  onEdit() {
    this.spinner = true;
    this.editMethod(this.document.id, this.fileDescription, this.inMenu, this.selectedGroups).then(
      () => {
        this.activeModal.close(true);
        this.spinner = false;
      },
      error => {
        console.log('error', error);
        this.spinner = false;
      }
    );
  }

  setSelectedValues(event) {
    let userGroupsSaveFormat;

    (this.settingsGroups as string[]).forEach(settingsGroup => {
      const userGroup = event.find(group => group === settingsGroup);
      if (userGroup) {
        userGroupsSaveFormat = {
          ...userGroupsSaveFormat,
          [settingsGroup]: true
        };
      } else {
        userGroupsSaveFormat = {
          ...userGroupsSaveFormat,
          [settingsGroup]: false
        };
      }
    });
    this.selectedGroups = userGroupsSaveFormat;
  }

  getUserGroups(): OptionsListMulti[] {
    const tmp: OptionsListMulti[] = [];
    let docExtraGroups = {};
    if (!this.selectedGroups) {
      this.selectedGroups = {};
    }

    docExtraGroups = this.selectedGroups;

    for (const key of Object.keys(docExtraGroups)) {
      if (docExtraGroups[key]) {
        tmp.push({
          id: key,
          name: '',
          selected: docExtraGroups[key]
        });
      }
    }

    (this.settingsGroups as string[]).forEach(settingsGroup => {
      const userGroup = tmp.find(group => group.id === settingsGroup);
      if (!userGroup) {
        tmp.push({
          id: settingsGroup,
          name: '',
          selected: false
        });
      }
    });
    return tmp;
  }

  initDocumentUserGroups() {
    this.enableDocumentUserGroups = this.navbarConfigService.getEnableDocumentUserGroups();
    if (this.enableDocumentUserGroups) {
      this.http.get<any>('json/system/GUNG').subscribe(systemSettings => {
        this.settingsGroups = systemSettings.extra.userGroups;
      });
    }
  }
}
