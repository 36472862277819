<div *ngIf="isLoaded; else loading">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>CREATE_PURCHASE_ORDER</h4>
    <span class="align-self-end mb-1 ml-2" *ngIf="product">
      {{ product.name }} {{ product.primaryDimension[0].name }}
    </span>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body" [formGroup]="form">
    <lib-meta-select-input
      table="LA"
      metaId="LNR"
      metaDisplay="NAM"
      formControlName="LNR"
      hideEmptyOption="true"
      showId="true"
    ></lib-meta-select-input>
    <div class="row">
      <div class="col-6">
        <lib-gung-text-input gungTranslate="NAME" formControlName="name"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="POST_ADDRESS" formControlName="AD1"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="ADDRESS_INFO" formControlName="AD2"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="BESOKSADRESS" formControlName="name"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="ZIP_CITY" formControlName="ftgpostnr"></lib-gung-text-input>
        <lib-meta-select-input formControlName="LND" table="TA11" metaId="KEY" metaDisplay="TX1">
        </lib-meta-select-input>
      </div>
      <div class="col-6">
        <lib-gung-text-input gungTranslate="VALFRI_INFO" formControlName="VALFRI"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="GARP_OGF_TX1" formControlName="TX1"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="GARP_OGF_TX2" formControlName="TX2"></lib-gung-text-input>
        <lib-gung-text-input gungTranslate="YOUR_REFERENCE" formControlName="REF"></lib-gung-text-input>
        <lib-gung-text-area-input
          gungTranslate="INTERNAL_TEXT"
          rowsNumber="3"
          formControlName="internalText"
        ></lib-gung-text-area-input>
        <lib-delivery-date-picker-beta (selectedDate)="selectedDate($event)"></lib-delivery-date-picker-beta>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="table-responsive gung-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th translate>PRODUCT_ID</th>
                <th translate>NAME</th>
                <th translate>STOCK</th>
                <th translate>AVAILABLE_NOW</th>
                <th translate>QUANTITY</th>
                <th translate>PRICE</th>
                <th translate>INCLUDE</th>
              </tr>
            </thead>
            <tbody formArrayName="rows">
              <tr *ngFor="let row of rows.controls; let i = index" [formGroupName]="i">
                <td [attr.data-label]="'PRODUCT_ID' | translate">
                  {{ row.id }}
                  <img
                    *ngIf="
                      productsMapped[groupItems[i].productId]?.extra.images &&
                        productsMapped[groupItems[i].productId].extra.images[0] as image;
                      else noimage
                    "
                    class="card-img-top img-fluid d-block w-auto"
                    [src]="image.s3Uri | gungImageUrl
                      :'type:thumbnail'
                      :'etag:'+image.s3ETag
                    "
                    alt="{{ image.description }}"
                  />
                  <ng-template #noimage>
                    <img
                      class="card-img-top img-fluid w-100"
                      [src]="'images/no-image.jpg' | gungImageUrl"
                      alt="NO IMAGE"
                      class="img-fluid d-block w-auto"
                    />
                  </ng-template>
                </td>
                <td [attr.data-label]="'NAME' | translate">
                  <div class="row">
                    <div class="col-auto">
                      <lib-gung-text-input suppressLabel="true" renderStatic="true" formControlName="productName">
                      </lib-gung-text-input>
                    </div>
                    <div class="col-3">
                      <lib-gung-text-input
                        suppressLabel="true"
                        renderStatic="true"
                        formControlName="productPrimaryDimensionName"
                      >
                      </lib-gung-text-input>
                    </div>
                    <div class="col-2">
                      <lib-gung-text-input
                        suppressLabel="true"
                        renderStatic="true"
                        formControlName="productSecondaryDimensionName"
                      >
                      </lib-gung-text-input>
                    </div>
                  </div>
                </td>
                <td>
                  {{ groupItemsAvailabilites && groupItemsAvailabilites[groupItems[i].productId]?.currentStock }}
                </td>
                <td>
                  {{ groupItemsAvailabilites && groupItemsAvailabilites[groupItems[i].productId]?.currentAvailability }}
                </td>
                <td [attr.data-label]="'QUANTITY' | translate">
                  <lib-gung-text-input suppressLabel="true" renderStatic="" formControlName="quantity">
                  </lib-gung-text-input>
                </td>
                <td [attr.data-label]="'PRICE' | translate">
                  <lib-gung-text-input suppressLabel="true" renderStatic="" formControlName="purchasePrice">
                  </lib-gung-text-input>
                </td>
                <td [attr.data-label]="'INCLUDE' | translate">
                  <lib-gung-checkbox-input-horizontal
                    suppressLabel="true"
                    formControlName="include"
                  ></lib-gung-checkbox-input-horizontal>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-primary col" (click)="activeModal.close()" translate>
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-sm btn-default btn-primary col"
      (click)="createPurchaseOrder($event)"
      translate
    >
      CREATE
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
