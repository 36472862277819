<div class="form-group row" [ngClass]="divClass">
  <label [for]="inputId" *ngIf="!suppressLabel" class="col-form-label" [ngClass]="labelClass || 'col-12 col-sm-4'">
    {{ translateTag | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col d-flex align-items-center justify-content-center">
    <input
      *ngIf="!useYesNo"
      type="checkbox"
      class="form-check-input quadratic-checkbox"
      [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
      [readonly]="renderStatic"
      [id]="inputId"
      [name]="inputId"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      [(ngModel)]="value"
    />
    <input
      *ngIf="useYesNo"
      type="checkbox"
      class="form-check-input quadratic-checkbox"
      [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
      [readonly]="renderStatic"
      [id]="inputId"
      [name]="inputId"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      [checked]="value === 'YES'"
      (change)="value = $event.target.checked ? 'YES' : 'NO'"
    />
  </div>
</div>
