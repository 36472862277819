<div class="col">
  <div class="row pt-2 pb-3">
    <span class="bold font-weight-bold pr-1" translate>IMPORTANT</span> <i class="fas fa-exclamation"></i> <br />
    <span translate>PIM_PROXY_ID_EXPLANATION</span>
  </div>
  <div class="row">
    <table class="table table-condensed" *ngIf="pimProducts">
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th translate>PROXY_ID</th>
      </tr>
      <tr *ngFor="let variant of pimProducts">
        <td>{{ variant.id }}</td>
        <td>{{ variant.name }}</td>
        <td>
          <lib-gung-text-input-horizontal
            [disabled]="!isActuator"
            [suppressLabel]="true"
            [(ngModel)]="variant.extra.proxyId"
            (change)="onProxyIdChanged(variant.id)"
          ></lib-gung-text-input-horizontal>
        </td>
      </tr>
    </table>
  </div>
</div>
