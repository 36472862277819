import { Component, OnDestroy, OnInit } from '@angular/core';
import { GungFlow } from '../../state/flow/types';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';

@Component({
  selector: 'lib-flow-select',
  templateUrl: './flow-select.component.html',
  styleUrls: ['./flow-select.component.css']
})
export class FlowSelectComponent implements OnInit,OnDestroy {
  private flowChangeForbiddenURLs = ['/checkout']

  selectedFlow: GungFlow;
  availableFlows: GungFlow[];

  disabled = false;

  unsubscribe: Subject<void> = new Subject();

  constructor(protected gungFlowService: GungFlowService, protected router: Router) {}

  ngOnInit() {
    this.gungFlowService.getGungFlows().subscribe(flows => (this.availableFlows = flows));
    this.gungFlowService.getSelectedFlow().subscribe(flow => (this.selectedFlow = flow));
    if (this.flowChangeForbiddenURLs.includes(this.router.url)) {
      this.disabled = true;
    }
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.flowChangeForbiddenURLs.includes(event.url)) {
          this.disabled = true;
        } else {
          this.disabled = false;
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


  selectFlow(flowId: string): void {
    this.gungFlowService.selectFlow(flowId);
  }
}
