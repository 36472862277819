<div *ngIf="data">
  <div class="row border-bottom mb-2 card-body" *ngFor="let eanNumber of data">
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="EAN"
        [(ngModel)]="eanNumber.ean"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="ARTICLE_NUMBER"
        [(ngModel)]="eanNumber.sku"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4" *ngIf="eanNumber.duplicateEANs">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="DUPLICATE_EAN_NUMBERS"
        class="label-bold"
      ></lib-gung-text-input>
      <span *ngFor="let duplicate of eanNumber.duplicateEANs">{{ duplicate }}<br /></span>
    </div>
  </div>
</div>
