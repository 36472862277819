import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ColumnRowTableType, SupplierPurchaseOrder, SupplierTableColumns } from '../../../models';
import { getValuesDynamic, setValueDynamic } from '../../../services/supplier-dynamic-columns/supplier-dynamic-columns.service';

@Component({
  selector: 'lib-supplier-po-details-modal',
  templateUrl: './supplier-po-details-modal.component.html',
  styleUrls: ['./supplier-po-details-modal.component.css']
})
export class SupplierPoDetailsModalComponent implements OnInit {

  isLoading = false;
  public ColumnRowTableType = ColumnRowTableType;

  @Input()
  data: SupplierTableColumns[];

  @Input()
  item: SupplierPurchaseOrder;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  getValues(row: SupplierPurchaseOrder, path: string | string[]) {
    return getValuesDynamic(row, path);
    // if (Array.isArray(path)) {
    //   return path.map(p => gungGetPropertyOfObject(p, row)).join(', ');
    // }
    // return gungGetPropertyOfObject(path, row);
  }

  setValue(row: SupplierPurchaseOrder, path: string, value: any) {
    return setValueDynamic(row, path, value);
    // const result = gungSetPropertyOfObject(row, path, value);
    // if (!result) {
    //   console.log('NOT SAVED - result', result);
    // }
  }
}
