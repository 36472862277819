import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { Product } from '../../../models';
import { Availability } from '../../../models/availability';
import { CartRowPrice } from '../../../models/price';
import { CartService } from '../../../services/cart/cart.service';
import { NavbarCartModel, NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { ProductService } from '../../../services/products/product.service';
import { CartRow } from '../../../state/cart/types';

@Component({
  selector: 'lib-quickorder-summarize',
  templateUrl: './quickorder-summarize.component.html',
  styleUrls: ['./quickorder-summarize.component.css']
})
export class QuickorderSummarizeComponent implements OnInit, OnDestroy {
  protected unsubscribeRouter: Subject<void> = new Subject<void>();

  @Output()
  selectProduct = new EventEmitter<string>();

  @Input()
  cart: CartRow[] = [];

  @Input() cachedData: { [id: string]: { product: Product; price: CartRowPrice; availability: Availability } } = {};

  cartContents: NavbarCartModel;
  public cartTotalItems = 0;
  public cartTotalRows = 0;

  constructor(
    protected cartService: CartService,
    protected productService: ProductService,
    protected navbarCartService: NavbarCartService
  ) {}

  ngOnInit(): void {
    this.navbarCartService
      .getContents()
      .pipe(takeUntil(this.unsubscribeRouter))
      .subscribe(contents => {
        this.cartContents = contents;
        this.cartTotalRows = contents.rows.length;
        this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
      });
  }

  ngOnDestroy() {
    this.unsubscribeRouter.next();
    this.unsubscribeRouter.complete();
  }

  public removeRow(row: CartRow) {
    this.cartService.removeRow(row);
  }

  clickProduct(productId: string) {
    this.selectProduct.next(productId);
  }
}
