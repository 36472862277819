import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first, switchMap } from 'rxjs';
import { CustomerContactService } from '../../../services/customer-contacts/customer-contact.service';
import { OpenPurchaseOrdersService } from '../../../services/open-purchase-orders/open-purchase-orders.service';
import { OrderService } from '../../../services/orders/order.service';
import { SupplierService } from '../../../services/suppliers/supplier.service';

@Component({
  selector: 'lib-order-confirmation-contacts-modal',
  templateUrl: './order-confirmation-contacts-modal.component.html',
  styleUrls: ['./order-confirmation-contacts-modal.component.css']
})
export class OrderConfirmationContactsModalComponent implements OnInit {
  @Input()
  orderId: string;

  @Input()
  contactType: 'customer' | 'supplier';

  contacts: { selected: boolean; value: string }[] = [];
  loading = true;
  manualEmailAddress: string;
  constructor(
    public activeModal: NgbActiveModal,
    protected customerContactService: CustomerContactService,
    protected orderService: OrderService,
    protected ppenPurchaseOrdersService: OpenPurchaseOrdersService,
    protected supplierService: SupplierService
  ) {}

  ngOnInit(): void {
    if (this.contactType === 'customer') {
      this.orderService.getOrder(this.orderId).subscribe(order => {
        this.getCustomerContacts(order.billingCustomerId || order.deliveryCustomerId);
      });
    } else if (this.contactType === 'supplier') {
      this.getSupplierContacts();
    }
  }

  getCustomerContacts(customerId) {
    this.customerContactService
      .getCustomerContacts(customerId)
      .pipe(first())
      .subscribe(contacts => {
        this.contacts = contacts
          .map(c => ({ selected: false, value: c.extra.contactData.EMAIL.trim() }))
          .filter(c => !!c.value);
        this.loading = false;
      });
  }

  getSupplierContacts() {
    this.supplierService
      .getSupplierOrders(this.orderId)
      .pipe(
        first(),
        switchMap(order => {
          return this.supplierService.getSupplierContacts(order.id); // CHANGE THIS TO SUPPLIER ID
        })
      )
      .subscribe(contacts => {
        this.contacts = contacts
          .map(c => ({ selected: false, value: c.extra.contactData.EMAIL.trim() }))
          .filter(c => !!c.value);
        this.loading = false;
      });
  }

  sendOrderConfirmation() {
    if (this.contactType === 'customer') {
      this.orderService
        .sendConfrimation(
          this.orderId,
          this.contacts.filter(c => c.selected).map(c => c.value)
        )
        .subscribe(_ => this.activeModal.close('Save click'));
    } else if (this.contactType === 'supplier') {
      this.supplierService
        .sendConfrimation(
          this.orderId,
          this.contacts.filter(c => c.selected).map(c => c.value)
        )
        .subscribe(_ => this.activeModal.close('Save click'));
    }
  }

  sendEmail() {
    const addresses = [];
    addresses.push(this.manualEmailAddress);
    if (this.contactType === 'customer') {
      this.orderService.sendConfrimation(this.orderId, addresses).subscribe(_ => this.activeModal.close('Save click'));
    } else if (this.contactType === 'supplier') {
      this.supplierService
        .sendConfrimation(this.orderId, addresses)
        .subscribe(_ => this.activeModal.close('Save click'));
    }
  }
}
