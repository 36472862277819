import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EdiService } from '../../../services/edi/edi.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-edi-import-xml',
  templateUrl: './edi-import-xml.component.html',
  styleUrls: ['./edi-import-xml.component.css']
})
export class EdiImportXmlComponent {
  loading = false;

  constructor(
    protected ediService: EdiService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService
  ) {}

  uploadFile({ files }) {
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }

    this.ediService.importEdiOrderXml(formData).subscribe(
      data => {
        const msg = 'Order sucessfully imported';
        this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
        this.loading = false;
      },
      error => {
        const msg = this.translateService.instant('ERROR');
        this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
        this.loading = false;
      }
    );
  }
}
