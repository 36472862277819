import { Injectable } from '@angular/core';
import { Observable, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssortmentTreeImageSelectionService {
  public tooltipEnabled = false;
  private selectedIamge: ReplaySubject<string> = new ReplaySubject<string>();

  constructor() {}

  setSelectedImageUrl(url: string) {
    this.selectedIamge.next(url);
  }

  getSelectedImageUrl(): Observable<string> {
    return this.selectedIamge.asObservable();
  }
}
