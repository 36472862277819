import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, Observable } from 'rxjs';
import { SystemSettings } from '../models/system-setting';

@Injectable({
  providedIn: 'root'
})
export class SystemSettingsService {
  private url = `json/system`;

  constructor(protected http: HttpClient) {

  }

  public createSystemSettings(systemSettings: SystemSettings): Observable<SystemSettings> {
    return this.http.post<SystemSettings>(this.url, systemSettings).pipe(first());
  }

  public getSystemSettings(): Observable<SystemSettings> {
    return this.http.get<SystemSettings>(this.url).pipe(first());
  }

  public getSystemSettingsById(id: string) {
    const url = this.url + `/${id}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<SystemSettings>(url, {headers}).pipe(first());
  }

  public updateSystemSettings(id: string, systemSettings: SystemSettings): Observable<SystemSettings> {
    const url = this.url + `/${id}`;
    return this.http.put<SystemSettings>(url, systemSettings).pipe(first());
  }

  public deleteSystemSettings(): Observable<SystemSettings> {
    return this.http.delete<SystemSettings>(this.url).pipe(first());
  }
}
