import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { CustomerContact } from '../../models/customerContact';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-customer-contact-card-list',
  templateUrl: './customer-contact-card-list.component.html',
  styleUrls: ['./customer-contact-card-list.component.css']
})
export class CustomerContactCardListComponent extends ListItemRendererComponent<CustomerContact> implements AfterViewInit {
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
