import { Component, OnInit, Output, EventEmitter, HostListener, Directive } from '@angular/core';
import { Product } from '../../models';
import { Subject, Observable } from 'rxjs';
import { FastsearchItemRendererComponent } from 'gung-list';

@Component({
  selector: 'lib-product-fastsearch-card',
  templateUrl: './product-fastsearch-card.component.html',
  styleUrls: ['./product-fastsearch-card.component.css']
})
export class ProductFastsearchCardComponent extends FastsearchItemRendererComponent<Product> implements OnInit{

  
  constructor() {
    super();
  }

  ngOnInit(): void {
    
  }
}
