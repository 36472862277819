import { Action } from '@ngrx/store';
import { Customer } from '../../models';

export enum CustomerActionTypes {
  SelectCustomer = '[Customer] Select customer',
  CustomerSelected = '[Customer] Customer selected',
  GetDefaultCustomer = '[Customer] Get default customer',
  ClearSelectedCustomer = '[Customer] Clear selected customer'
}

export interface SelectCustomerPayload {
  customerId: string;
}

export class SelectCustomer implements Action {
  type: string = CustomerActionTypes.SelectCustomer;
  constructor(public payload: SelectCustomerPayload) {}
}

export class CustomerSelected implements Action {
  type: string = CustomerActionTypes.CustomerSelected;
  constructor(public payload: Customer) {}
}

export class GetDefaultCustomer implements Action {
  type: string = CustomerActionTypes.GetDefaultCustomer;
}

export class ClearSelectedCustomer implements Action {
  type: string = CustomerActionTypes.ClearSelectedCustomer;
}
