import { Component, OnDestroy, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { StatisticsListItem } from '../../models/statistics';
import { DateUtilService } from 'gung-common';
import { CustomerService } from '../../services/customers/customer.service';
import { first, Subject, takeUntil } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { ColumnSortSelectionService } from '../../services/column-sort-selection/column-sort-selection.service';

@Component({
  selector: 'lib-order-finder-table-list',
  templateUrl: './order-finder-table-list.component.html',
  styleUrls: ['./order-finder-table-list.component.css']
})
export class OrderFinderTableListComponent extends ListItemRendererComponent<StatisticsListItem[]> implements OnDestroy, AfterViewInit {
  currentSortPath: string;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(public dateUtilService: DateUtilService, protected customerService: CustomerService, protected route: ActivatedRoute, protected columnSortSelectionService: ColumnSortSelectionService) {
    super();

    this.columnSortSelectionService.getSelectedPathSort().pipe(takeUntil(this.unsubscribe)).subscribe(({path, sort}) => {
      if (this.currentSortPath === path) {
        this.currentSortPath = '-' + path;
      } else {
        this.currentSortPath = path;
      }
    })
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
  
  sortByPath(path: string) {
    this.columnSortSelectionService.setSelectedPathSort({path, sort: this.currentSortPath !== path ? 1 : -1});
  }
}
