<div *ngIf="data; else loading" class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>NAME</th>
        <th translate>DATE_CREATED</th>
        <th translate>CREATED_BY</th>
        <th translate>DATE_UPDATED</th>
        <th translate>UPDATED_BY</th>
        <th style="min-width: 50px" translate>ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      <ng-container *ngFor="let template of data">
        <tr>
          <td [attr.data-label]="'NAME' | translate">
            {{ template.name }}
          </td>
          <td [attr.data-label]="'DATE_CREATED' | translate">
            {{ template.createdAt | date: dateUtilService.dateFormat }}
          </td>
          <td [attr.data-label]="'CREATED_BY' | translate">
            {{ template.createdBy }}
          </td>
          <td [attr.data-label]="'DATE_UPDATED' | translate">
            {{ template.updatedAt | date: dateUtilService.dateFormat }}
          </td>
          <td [attr.data-label]="'CREATED_BY' | translate">
            {{ template.updatedBy }}
          </td>
          <td [attr.data-label]="'ACTIONS' | translate" class="text-nowrap">
            <button type="button" class="btn btn-sm btn-outline-primary mr-2" (click)="editCompanyTemplate(template)">
              <i class="fas fa-edit"></i>
            </button>
            <button type="button" class="btn btn-sm btn-danger mr-2" (click)="deleteCompanyTemplate(template)">
              <i class="fas fa-trash"></i>
            </button>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </table>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
