<div *ngIf="label; else noLabel">
  <div class="form-group row">
    <label class="col-form-label col-12 col-sm-4 font-weight-bold">
      {{ label | translate }}
      <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
    </label>
    <div class="col col-sm-8">
      <ng-container *ngTemplateOutlet="noLabel"></ng-container>
    </div>
  </div>
</div>
<ng-template #noLabel>
  <div ngbDropdown [autoClose]="'outside'" (openChange)="dropdownIsOpen = $event" class="border">
    <button class="btn btn-block text-left input-multi-select" ngbDropdownToggle>
      <div class="d-flex align-items-center">
        <span class="flex-grow-1 text-truncate">
          {{ previewList && previewList.length > 0 ? previewList.join(',') : (previewEmpty | translate) }}
        </span>
        <i
          class="fa float-right ml-2"
          [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
        ></i>
      </div>
    </button>
    <ul class="input-multi-select-list" ngbDropdownMenu>
      <li *ngIf="optionsFilterList.length == 0">
        <span translate>NO_RESULTS_FOUND</span>
      </li>
      <li *ngIf="optionsList.length > 0">
        <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
      </li>
      <li *ngFor="let option of optionsFilterList" [ngClass]="{ 'd-none': !option.id || option.hide }">
        <button
          class="btn top-filter-item w-100 text-left"
          (click)="option.selected = !option.selected; setSelectValue(option.id, option.selected)"
          translate
        >
          <i class="far" [ngClass]="{ 'fa-square': !option.selected, 'fa-check-square': option.selected }"></i>
          {{ option.id }} {{ option.name ? '- ' + option.name : '' }}
        </button>
      </li>
    </ul>
  </div>
</ng-template>
