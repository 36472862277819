import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { Subject, first, takeUntil, of, delay } from 'rxjs';
import { Customer } from '../../../models';
import { Activity } from '../../../models/activity';
import { CustomerContact } from '../../../models/customerContact';
import { ActivityService } from '../../../services/activitys/activity.service';
import { AuthService } from '../../../services/auth/auth.service';
import { CustomerContactService } from '../../../services/customer-contacts/customer-contact.service';

@Component({
  selector: 'lib-activities-editor-modal',
  templateUrl: './activities-editor-modal.component.html',
  styleUrls: ['./activities-editor-modal.component.css']
})
export class ActivitiesEditorModalComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  customer: Customer;
  activity: Activity;

  isCreate = false;
  customerContacts: CustomerContact[] = [];
  formErrors: { [fieldName: string]: boolean } = {};
  saved = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected activityService: ActivityService,
    protected customerContactService: CustomerContactService,
    protected authService: AuthService,
    public activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (!this.activity) {
      this.isCreate = true;

      this.activity = {
        ...this.activity,
        customerId: this.customer.id,
        extra: {
          gungData: {
            start: this.formatDate(new Date()),
            end: this.formatDate(new Date())
          }
        }
      };

      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe((currentUser: any) => {
          if (
            currentUser.extra.orderParams &&
            currentUser.extra.orderParams.OGA &&
            currentUser.extra.orderParams.OGA.SLJ
          ) {
            this.activity.extra.gungData.Ansvarig = currentUser.extra.orderParams.OGA.SLJ;
          }
        });
    }

    this.loadCustomerContacts();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  dateSelect(date: NgbDate): void {
    const formatedDate = date ? this.formatDate(new Date(date.year, date.month - 1, date.day)) : '';
    this.activity.extra.gungData.start = formatedDate;
  }

  close() {
    this.activeModal.close();
  }

  onChangeInput(fieldName: string): void {
    if (fieldName === 'Ansvarig') {
      this.formErrors.Ansvarig = !this.activity.extra.gungData.Ansvarig;
    } else if (fieldName === 'Aktivitet') {
      this.formErrors.Aktivitet = !this.activity.extra.gungData.Aktivitet;
    }
  }

  save() {
    if (!this.activity.extra.gungData.Ansvarig) {
      this.formErrors.Ansvarig = true;
    }

    if (!this.activity.extra.gungData.Aktivitet) {
      this.formErrors.Aktivitet = true;
    }

    if (Object.values(this.formErrors).filter(x => x).length > 0) {
      return;
    }

    if (this.isCreate) {
      this.activityService
        .createActivity(this.customer.id, this.activity)
        .pipe(first())
        .subscribe(() => {
          this.isCreate = false;
          // close the modal beacuse we do not know the id of the created activity
          this.close();
        });
    } else {
      this.activityService
        .updateActivity(this.customer.id, this.activity)
        .pipe(first())
        .subscribe(() => {
          this.afterSave();
        });
    }
  }

  getDisplayStartDate(): string {
    if (this.activity.extra.gungData.start) {
      return format(parse(this.activity.extra.gungData.start.split(' ')[0], 'yyyyMMdd', new Date()), 'yyyy-MM-dd');
    }

    return '';
  }

  private loadCustomerContacts() {
    this.customerContactService
      .getCustomerContacts(this.customer.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contacts => (this.customerContacts = contacts));
  }

  protected formatDate(date: Date): string {
    return format(date, 'yyyyMMdd HH:mm:ss');
  }

  protected afterSave(): void {
    this.saved = true;
    of(true)
      .pipe(delay(2000), first())
      .subscribe(() => (this.saved = false));
  }
}
