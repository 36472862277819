<!-- <div *ngIf="availability && displayAvailability" class="row text-truncate"
  [ngClass]="productId && (isSales || !isSales && modalRestrictedViewAllowed) ? 'open-availability' : ''"
  (click)="!productId || (!isSales && !modalRestrictedViewAllowed) ? $event.preventDefault() : openModalAvailabilty()">
  <! -- Current Stock for Users -- >
  <div class="col-12 badge badge-green" *ngIf="displayAvailability && displayAvailability.hasCurrenStock">
    {{ displayAvailability.currentAvailability }}
  </div>
  <! -- No Current Stock and Future Stock for Users -- >
  <div class="col-12 badge badge-yellow"
    *ngIf="displayAvailability && displayAvailability.hasFutureStock && !displayAvailability.hasCurrenStock">
    <span *ngIf="isSales">
      {{ displayAvailability.futureStock }}
      <span *ngIf="displayAvailability.futureStockDate">/</span>
    </span>
    <span *ngIf="displayAvailability.futureStockDate">
      {{ displayAvailability.futureStockDate | date: dateUtilService.dateFormat }}
    </span>
    <span *ngIf="!isSales && !displayAvailability.futureStockDate"> - </span>
  </div>
  <! -- No Current Stock for Users and No  Future Stock for Users  -- >
  <div class="col-12 badge badge-red"
    *ngIf="!displayAvailability.hasCurrenStock && !displayAvailability.hasFutureStock">
    <span *ngIf="isSales">{{ displayAvailability.futureStock }} </span>
    <span *ngIf="displayAvailability.futureStockDate">
      / {{ displayAvailability.futureStockDate | date: dateUtilService.dateFormat }}
    </span>
    <span *ngIf="!isSales && !displayAvailability.futureStockDate"> - </span>
  </div>
</div> -->

<div
  *ngIf="availability && status && class"
  class="row text-truncate"
  [ngClass]="enableOpenModalAvailabilty ? 'open-availability' : ''"
  (click)="!enableOpenModalAvailabilty ? $event.preventDefault() : openModalAvailabilty()"
>
  <span
    class="col-12 border-bottom-0 badge availability-status"
    [ngClass]="class"
    [innerHTML]="status | safeHtml"
  ></span>
</div>
