import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungInputMultiSelectComponent } from '../gung-input-multi-select/gung-input-multi-select.component';

@Component({
  selector: 'lib-gung-input-multi-select-horizontal',
  templateUrl: './gung-input-multi-select-horizontal.component.html',
  styleUrls: ['./gung-input-multi-select-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungInputMultiSelectComponent),
      multi: true
    }
  ]
})
export class GungInputMultiSelectHorizontalComponent extends GungInputMultiSelectComponent {
  constructor() {
    super();
  }
}
