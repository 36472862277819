<div class="d-flex justify-content-between">
  <label translate>FROM</label>
  <a *ngIf="showTodayDate" (click)="selectTodayMinDate()" translate>SELECT_TODAY</a>
</div>
<div class="input-group mb-2">
  <input
    class="form-control"
    placeholder="yyyy-mm-dd"
    name="dp"
    [(ngModel)]="minDate"
    ngbDatepicker
    #d="ngbDatepicker"
    readonly
    [minDate]="minPossibleDate"
    [maxDate]="maxDate"
    (dateSelect)="minDateSelect($event)"
    [firstDayOfWeek]="firstDayOfWeek"
  />
  <div class="input-group-append">
    <button class="btn calendar" (click)="d.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
  </div>
</div>
<div class="d-flex justify-content-between">
  <label translate>TO</label>
  <a *ngIf="showTodayDate" (click)="selectTodayMaxDate()" translate>SELECT_TODAY</a>
</div>
<div class="input-group mb-2">
  <input
    class="form-control"
    placeholder="yyyy-mm-dd"
    name="dp"
    [(ngModel)]="maxDate"
    ngbDatepicker
    #m="ngbDatepicker"
    readonly
    [minDate]="minDate"
    [maxDate]="maxPossibleDate"
    (dateSelect)="maxDateSelect($event)"
    [firstDayOfWeek]="firstDayOfWeek"
  />
  <div class="input-group-append">
    <button class="btn calendar" (click)="m.toggle()" type="button"><i class="fal fa-calendar-alt"></i></button>
  </div>
</div>
