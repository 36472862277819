import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { FreightCalculationRequest, FreightTerm } from '../../models/freight-term';
import { OrderRow } from '../../models/orderRow';
import { CheckoutRow } from '../../models/checkout';

@Injectable({
  providedIn: 'root'
})
export class FreightTermService {
  private baseURL = 'json/freight-terms';

  constructor(protected http: HttpClient) {}

  public calculateFreight(
    totalAmount: number,
    freightTerm: string,
    orderRows?: CheckoutRow[] | OrderRow[],
    extra?: { [s: string]: any }
  ): Observable<number> {
    const url = `${this.baseURL}/calculate`;
    const body = {
      totalAmount,
      freightTerm,
      orderRows,
      extra
    } as FreightCalculationRequest;

    return this.http.post<number>(url, body);
  }

  public getValidShippingCharges(orderRows: CheckoutRow[] | OrderRow[]): Observable<FreightTerm[]> {
    const url = `${this.baseURL}/valid`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');

    return this.http.post<FreightTerm[]>(url, orderRows, { headers });
  }

  public getShippingCharges(): Observable<FreightTerm[]> {
    const url = this.baseURL;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');

    return this.http.get<FreightTerm[]>(url, { headers });
  }

  public getShippingChargeById(id: string): Observable<FreightTerm> {
    const url = `${this.baseURL}/${id}`;
    const headers = new HttpHeaders();
    /*  headers = headers.set('maxAge', '-1'); */
    return this.http.get<FreightTerm>(url, { headers });
  }

  public addShippingCharge(freightTerm: FreightTerm): Observable<FreightTerm> {
    const url = this.baseURL;
    return this.http.post<FreightTerm>(url, freightTerm);
  }

  public updateShippingCharge(freightTerm: FreightTerm): Observable<FreightTerm> {
    const url = this.baseURL;
    return this.http.put<FreightTerm>(url, freightTerm);
  }

  public deleteShippingCharge(id: string): Observable<FreightTerm> {
    const url = this.baseURL + `/${id}`;
    return this.http.delete<FreightTerm>(url);
  }
}
