<div class="fluid-container">
  <!-- HEADER -->
  <div class="current-assortment" *ngIf="currentAssortment && type && type !== 'PRODUCT'">
    <!-- TITLE ONLY -->
    <div class="row" *ngIf="type !== 'PRODUCTS' && type !== 'CONCEPT'">
      <div class="col-12 current-assortment-name">
        <h2>{{ currentAssortment.extra?.i18n && currentAssortment.extra.i18n[currentLang] && currentAssortment.extra?.i18n[currentLang]?.assortmentName ? currentAssortment.extra.i18n[currentLang].assortmentName : currentAssortment.name }}</h2>
      </div>
    </div>
    <!-- TITLE and DETAILS -->
    <div class="row pt-2 pb-4" *ngIf="type === 'PRODUCTS' || type === 'CONCEPT'">
      <div class="col-xs-12 col-sm-6">
        <!--  && (currentAssortment.extra.images && currentAssortment.extra.images.length && currentAssortment.extra.images[0].s3Uri !== 'images/no-image.jpg') -->
        <lib-image-carousel
          *ngIf="currentAssortment"
          [openExternal]="true"
          [zoom]="false"
          [images]="currentAssortment.extra.images"
          (selectedImage)="changeSelectedImage($event)"
        ></lib-image-carousel>
      </div>
      <!-- Product data -->
      <div class="col-xs-12 col-sm-6">
        <div class="row">
          <div class="col-12">
            <h1>{{ currentAssortment.extra?.i18n && currentAssortment.extra.i18n[currentLang] && currentAssortment.extra?.i18n[currentLang]?.assortmentName ? currentAssortment.extra.i18n[currentLang].assortmentName : currentAssortment.name }}</h1>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <p>{{ currentAssortment.description }}</p>
          </div>
        </div>
        <div class="row" *ngIf="currentAssortment?.documents && currentAssortment?.documents.length > 0">
          <div class="col-12 mt-2">
            <lib-gung-accordion>
              <lib-gung-accordion-item>
                <ng-template libGungAccordionHeader>{{ 'DOCUMENTS' | translate }}</ng-template>
                <ng-template libGungAccordionBody>
                  <div class="table-responsive">
                    <table class="table table-borderless table-striped table-sm">
                      <thead>
                        <tr>
                          <th scope="col" translate>DOCUMENT</th>
                          <th scope="col" translate>DOWNLOAD_DOCUMENT</th>
                        </tr>
                      </thead>

                      <tbody>
                        <tr *ngFor="let doc of currentAssortment?.documents">
                          <td translate>
                            {{ doc.name || 'TECHINCAL_CHARACTERISTICS_CATALOG' }}
                          </td>
                          <td>
                            <a href="{{ doc.url }}" target="_blank" translate>DOWNLOAD</a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </lib-gung-accordion-item>        
            </lib-gung-accordion>                        
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- BREADCRUMB -->
  <div class="row row-breadcrumb" *ngIf="breadcrumbMenu">
    <div class="col-12">
      <!-- <lib-breadcrumb></lib-breadcrumb> <hr/> -->
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb px-0">
          <li
            *ngFor="let breadcrumb of breadcrumbMenu; let last = last"
            class="breadcrumb-item"
            [attr.aria-current]="last ? 'page' : undefined"
          >
            <a [ngClass]="{ 'active-assortment': last }" [routerLink]="breadcrumb.urlParts" translate>{{
              breadcrumb.label
            }}</a>
          </li>
        </ol>
      </nav>
    </div>
  </div>
  <!-- LIST -->
  <ng-template libCustomHost></ng-template>
</div>

<div *ngIf="!currentAssortment || !breadcrumbMenu || !type" class="px-5">LOADING...</div>
