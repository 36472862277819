import { Action } from '@ngrx/store';
import { Supplier } from '../../models/supplier';

export enum SupplierActionTypes {
  SelectSupplier = '[Supplier] Select supplier',
  SupplierSelected = '[Supplier] Supplier selected',
  GetDefaultSupplier = '[Supplier] Get default supplier',
  ClearSelectedSupplier = '[Supplier] Clear selected supplier'
}

export interface SelectSupplierPayload {
  supplierId: string;
}

export class SelectSupplier implements Action {
  type: string = SupplierActionTypes.SelectSupplier;
  constructor(public payload: SelectSupplierPayload) {}
}

export class SupplierSelected implements Action {
  type: string = SupplierActionTypes.SupplierSelected;
  constructor(public payload: Supplier) {}
}

export class GetDefaultSupplier implements Action {
  type: string = SupplierActionTypes.GetDefaultSupplier;
}

export class ClearSelectedSupplier implements Action {
  type: string = SupplierActionTypes.ClearSelectedSupplier;
}
