<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <th scope="col" translate>ID</th>
        <th scope="col" translate>SEGMENT</th>
        <th scope="col" translate>DESCRIPTION</th>
        <th scope="col" *ngFor="let lang of showLanguages">
          {{ this.showField.toUpperCase() + '_' | translate:{lang: lang} | uppercase }}
        </th>
        <th scope="col">
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-primary" (click)="onCreate()" translate>CREATE_METADATA</button>
          </div>
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let metadata of data">
        <th scope="row">{{ metadata.id }}</th>
        <td>{{ metadata.segment }}</td>
        <td>{{ metadata.name }}</td>
        <td *ngFor="let lang of showLanguages">
          {{ getTranslatedName(metadata, lang) }}
        </td>
        <td>
          <div class="d-flex justify-content-end">
            <button type="button" class="btn btn-secondary" (click)="onEdit(metadata)" translate>EDIT</button>
            <button type="button" class="btn btn-danger" (click)="onDelete(metadata)" translate>DELETE</button>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>
