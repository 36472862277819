import { Component, OnInit, OnDestroy } from '@angular/core';
import { SchedulersService } from '../../services/schedulers/schedulers.service';
import { Scheduler, JobKey } from './../../models/scheduler/scheduler';
import { interval, Subscription } from 'rxjs';
import { first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from '../../services/date-util/date-util.service';

@Component({
  selector: 'lib-schedulers-monitor',
  templateUrl: './schedulers-monitor.component.html',
  styleUrls: ['./schedulers-monitor.component.css']
})
export class SchedulersMonitorComponent implements OnInit, OnDestroy {
  subscription: Subscription;

  intervaleSeconds = this.schedulersService.intervaleSeconds;
  lastUpdate: Date;

  schedulersList: Scheduler[];

  panelOpenStates: string[] = [];

  lastException: { jobName: string; lastExceptionDate: Date; lastExceptionTrace: string };

  constructor(
    protected schedulersService: SchedulersService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit() {
    this.getSchedulers();
    const source = interval(this.intervaleSeconds * 1000);
    this.subscription = source.subscribe(val => {
      this.getSchedulers();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getSchedulers() {
    this.schedulersService
      .getSchedulers(true)
      .pipe(first())
      .subscribe((schedulers: Scheduler[]) => {
        this.lastUpdate = new Date();
        this.schedulersList = schedulers.sort((a, b) => (b.jobName > a.jobName ? -1 : 1));
      });
  }

  clearAllError() {
    this.schedulersService
      .clearAllErrors()
      .pipe(first())
      .subscribe(({ status }) => {});
  }

  triggerJob(jobKey: JobKey) {
    const scheduler = this.schedulersList.find(s => s.jobKey === jobKey);
    if (scheduler?.jobdatamap) {
      scheduler.jobdatamap.running = true;
    }
    this.schedulersService
      .triggerJob(jobKey)
      .pipe(first())
      .subscribe((jobkey: { jobKey: JobKey }) => {});
  }

  panelOpenState(jobName: string, add?: boolean) {
    if (add === undefined) {
      return this.panelOpenStates.includes(jobName);
    }
    if (add) {
      this.panelOpenStates.push(jobName);
    } else {
      this.panelOpenStates = this.panelOpenStates.filter(p => p !== jobName);
    }
  }

  showLastExceptionTrace(scheduler: Scheduler, content) {
    if (!scheduler?.jobdatamap?.lastExceptionTrace) {
      return;
    }
    this.lastException = {
      jobName: scheduler.jobName,
      lastExceptionDate: scheduler.jobdatamap.lastExceptionDate,
      lastExceptionTrace: scheduler.jobdatamap.lastExceptionTrace
    };
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.finally(() => {
      this.lastException = undefined;
    });
  }
}
