import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdiDispatchAdviceDetailConfigService {
  disable = {
    dispatchAdviceNumber: true,
    shipper: true,
    dispatchAdviceDate: true,
    shipmentNumber: true,
    deliveryDate: true,
    shippingDate: true,
    buyerOrdeNumber: true,
    notes: true
  };

  constructor() {}
}
