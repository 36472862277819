<div class="container">
  <h5 class="text-primary">
    <strong translate>RESET_PASSWORD</strong>
  </h5>
  <form [formGroup]="resetPasswordForm" (ngSubmit)="onSubmit()">
    <!-- <div class="form-group">
        <label for="password" translate>RESET_NEW_PASSWORD</label>
        <input type="password" formControlName="password" class="form-control" [ngClass]="{'is-invalid': resetPasswordForm.controls.password.invalid || !!resetPasswordForm.errors}">
      </div> -->
    <lib-gung-password-input
      gungTranslate="PASSWORD"
      [placeholder]="'PASSWORD' | translate"
      formControlName="password"
      (validPassword)="isValid($event)"
      extraInputClass="{{
        resetPasswordForm.controls.password.invalid || !!resetPasswordForm.errors ? ', is-invalid' : ''
      }}"
      [passwordRequirement]="passwordRequirements"
    ></lib-gung-password-input>
    <div class="form-group">
      <label for="passwordConfirm" translate>PASSWORD_CONFIRM</label>
      <input
        type="password"
        formControlName="passwordConfirm"
        class="form-control"
        [ngClass]="{ 'is-invalid': resetPasswordForm.controls.passwordConfirm.invalid || !!resetPasswordForm.errors }"
      />
      <div class="invalid-feedback" *ngIf="!!resetPasswordForm.errors">
        <span *ngIf="resetPasswordForm.errors && resetPasswordForm.errors.noMatch" translate
          >PASSWORDS_DO_NOT_MATCH</span
        >
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <button type="submit" class="btn btn-primary" [disabled]="resetPasswordForm.disabled" translate>
          SET_PASSWORD
        </button>
      </div>
      <div class="col-6 d-flex justify-content-end align-items-center">
        <!-- /login -->
        <a [routerLink]="['login']" translate>
          <button type="submit" class="btn btn-primary"  translate>
            BACK_TO_LOGIN
          </button>
          </a>
      </div>
    </div>
    <div class="alert alert-success" *ngIf="passwordSet" translate>PASSWORD_HAS_BEEN_UPDATED</div>
    <div
      class="alert alert-danger"
      *ngIf="resetPasswordForm.errors && resetPasswordForm.errors.errorSettingPw"
      translate
    >
      ERROR_SET_PASSWORD
    </div>
  </form>
</div>
