<div class="row">
  <div class="col text-right">
    <button class="btn btn-sm btn-primary mr-1" translate (click)="config.addTranslationKey()">ADD_TRANSLATION</button>
    <button class="btn btn-sm btn-primary mr-1" translate (click)="config.addLanguage()">ADD_LANGUAGE</button>
    <button class="btn btn-sm btn-primary" translate (click)="config.save()">SAVE</button>
  </div>
</div>
<div class="row">
  <div class="col" style="overflow-x: scroll">
    <table class="table table-borderless table-sm">
      <thead>
        <tr>
          <th></th>
          <th *ngFor="let language of config.languages">
            {{ language }}
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let translation of data">
          <tr *ngIf="!translation.key.includes('$')">
            <th>
              <lib-gung-text-input-horizontal [suppressLabel]="true" [disabled]="true" [(ngModel)]="translation.key">
              </lib-gung-text-input-horizontal>
            </th>
            <td *ngFor="let language of config.languages">
              <div class="d-flex align-items-center">
                <lib-gung-text-input-horizontal
                  [suppressLabel]="true"
                  [(ngModel)]="translation[language]"
                  (change)="config.addTranslation(translation.key, translation[language], language)"
                  class="w-100"
                  style="display: inline-block"
                >
                </lib-gung-text-input-horizontal>
                <i
                  *ngIf="
                    config.customerOwnedTranslations[translation.key] &&
                    config.customerOwnedTranslations[translation.key][language]
                  "
                  class="fa-solid fa-user-check mr-1 ml-1"
                  style="color: darkgreen"
                  placement="top"
                  ngbTooltip="Customer owned translation"
                ></i>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
</div>
