import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { CdkStepper } from '@angular/cdk/stepper';

/**
 *  Documentation for the stepper can be found on this link
 *  https://material.angular.io/cdk/stepper/api
 */
@Component({
  selector: 'lib-custom-stepper',
  templateUrl: './custom-stepper.component.html',
  styleUrls: ['./custom-stepper.component.css'],
  providers: [{ provide: CdkStepper, useExisting: CustomStepperComponent }]
})
export class CustomStepperComponent extends CdkStepper {
  @ViewChild('stepperTop', { static: true })
  el: ElementRef;

  /**
   * Shows a paging indicator on the bottom of the stepper as well as the default top one.
   */
  @Input()
  bottomIndicator?: boolean;

  onClick(index: number): void {
    this.selectedIndex = index;
  }

  next(): void {
    super.next();
    this.scrollToTop(this.el.nativeElement as HTMLElement);
  }

  previous(): void {
    super.previous();
    this.scrollToTop(this.el.nativeElement as HTMLElement);
  }

  private scrollToTop(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'end' });
  }
}
