<div class="card">
  <div class="card-body">
    <div class="card-title">
      <h5>
        {{ data.name }} <small>{{ data.customerId }}</small>
      </h5>
    </div>
    <div class="card-text"></div>
  </div>
</div>
