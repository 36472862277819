import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

export interface GungSlider {
  id: number;
  // name: string;
  // extra: {[s: string]: any} | null;
  title?: string;
  description?: string;
  innerHTML?: string;
  button: string;
  link: string;
  background: string;
  customClass?: string;
}

@Component({
  selector: 'lib-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.css']
})
export class SliderComponent implements OnInit {
  @Input()
  public sliderContent: GungSlider[];

  constructor(private router: Router) {}

  ngOnInit(): void {}

  navigateTo(slide: GungSlider) {
    if (slide.customClass?.includes('gung-image-only') || (slide.customClass?.includes('gung-image-and-caption') && slide.customClass?.includes('external-link'))) {
      if (slide.link?.includes('http://') || slide.link?.includes('https://')) {
        window.open(slide.link, '_blank').focus();
      } else {
        // this.router.navigateByUrl('/categories/' + assortment);
        this.router.navigateByUrl(slide.link);
      }
    }
  }
}
