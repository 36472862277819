import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungTextInputComponent } from '../gung-text-input/gung-text-input.component';

@Component({
  selector: 'lib-gung-text-area-input',
  templateUrl: './gung-text-area-input.component.html',
  styleUrls: ['./gung-text-area-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungTextAreaInputComponent),
      multi: true
    }
  ]
})
export class GungTextAreaInputComponent extends GungTextInputComponent {
  @Input()
  public labelClass: string | string[];

  @Input()
  public rowsNumber: number;

  @Input()
  public blockResize = false;

  @Input()
  public labelBold = false;

  @Input()
  public maxlength?: string;

  constructor() {
    super();
  }
}
