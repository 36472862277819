import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  GridLayoutComponent,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { first, map, switchMap } from 'rxjs';
import { TopProductGridComponent } from '../../components/top-product-grid/top-product-grid.component';
import { TopProductsSummaryComponent } from '../../components/top-products/top-products-summary/top-products-summary.component';
import { OrderStatistic, TopProductsStatistics } from '../../models/order-statistics';
import { OrderStatisticsService } from '../order-statistics/order-statistics.service';

export class ProductPrimaryDimensionNameFilter extends SimpleConfigBaseFilter<OrderStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'Product Color';
  }

  getOptionIds(item: OrderStatistic): string[] {
    return [item.productPrimaryDimensionName];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class ProductCategoryDescriptionFilter extends SimpleConfigBaseFilter<OrderStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'Product Category';
  }

  getOptionIds(item: OrderStatistic): string[] {
    return [item.productCategoryDescription];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class OrderSeasonCodeFilter extends SimpleConfigBaseFilter<OrderStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'SEASON';
  }

  getOptionIds(item: OrderStatistic): string[] {
    return [item.orderSeasonCode];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class CustomerNameFilter extends SimpleConfigBaseFilter<OrderStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'Customer';
  }

  getOptionIds(item: OrderStatistic): string[] {
    return [item.customerId + ' - ' + item.customerName];
  }

  getOptionName(key: string): string {
    return key;
  }
}
export class OrderTypeDescriptionFilter extends SimpleConfigBaseFilter<OrderStatistic> {
  constructor() {
    super();
  }

  getName(): string {
    return 'ORDER_TYPE';
  }

  getOptionIds(item: OrderStatistic): string[] {
    return [item.orderTypeDescription];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class TopProductsListConfigService implements ConfigService<OrderStatistic> {
  topFilter = true;

  seasons = {};
  seasonToShow = {};
  public seasonList = [];
  orderStatisticsCache: OrderStatistic[];
  seasonsAllowed = [];

  constructor(protected orderStatisticsService: OrderStatisticsService, protected http: HttpClient) {}

  getFilters(): ConfigBaseFilter<OrderStatistic>[] {
    return [
      new OrderTypeDescriptionFilter(),
      new OrderSeasonCodeFilter(),
      new CustomerNameFilter(),
      new ProductPrimaryDimensionNameFilter(),
      new ProductCategoryDescriptionFilter()
    ];
  }

  getSortOptions(): ListSortOption<OrderStatistic>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [-1];
  }

  getLayouts(): ListLayout<OrderStatistic>[] {
    return [
      {
        getIconClass: () => 'fas fa-th',
        getListItemComponent: () => TopProductsSummaryComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  formatDate(date) {
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();
    const year = d.getFullYear();
    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }
    return [year, month, day].join('-');
  }

  getItems(): Observable<OrderStatistic[]> {
    const date = new Date();
    date.setFullYear(date.getFullYear() - 1);
    const dateService = this.formatDate(date);
    return this.orderStatisticsService.getOrderStatistics(dateService).pipe(first());
  }

  getItemId(item: OrderStatistic) {
    return item.id;
  }

  getSearchTerms(item: OrderStatistic) {
    return [item.productId, item.productName];
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND_CONTACT_GUNG';
  }
}
