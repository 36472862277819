<div class="modal-header">
  <h3 translate>PRODUCT_TEMPLATE</h3>
</div>
<div class="modal-body">
  <div class="templates">
    <select class="form-control" [(ngModel)]="selectedTemplateId" (ngModelChange)="changeTemplate()">
      <option [value]="template.id" *ngFor="let template of templates">
        {{ template.id }}
      </option>
    </select>
  </div>
  <div class="add-products mt-3" *ngIf="selectedTemplate && selectedTemplate.template?.isMultidimensional">
    <div *ngIf="productsVariants" class="d-flex align-items-center">
      <div class="mr-1">
        <div class="custom-control custom-checkbox d-flex align-items-center" *ngFor="let variant of productsVariants">
          <input
            type="checkbox"
            [(ngModel)]="variant.selected"
            class="custom-control-input"
            id="variant-{{ variant.id }}"
            name="variant-{{ variant.id }}"
          />
          <label class="custom-control-label" for="variant-{{ variant.id }}" translate>{{ variant.name }}</label>
        </div>
      </div>
      <div>
        <div *ngFor="let variant of productsVariants" class="d-flex align-items-center" style="min-height: 1.35rem">
          <input
            type="radio"
            [(ngModel)]="primaryVariantId"
            [disabled]="!variant.selected"
            [value]="variant.id"
            id="variant-radio-{{ variant.id }}"
            name="variant-radio-{{ variant.id }}"
            style="margin-right: 3px"
          />
          <label
            for="variant-radio-{{ variant.id }}"
            [ngStyle]="{ color: !variant.selected ? '#7d7d7d' : 'inherit' }"
            translate
            >PRIMARY</label
          >
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary" aria-label="Close" (click)="activeModal.dismiss()" translate>
    CANCEL
  </button>
  <button type="button" class="btn btn-light" (click)="applyToProduct()" translate>SAVE</button>
</div>
