<div class="form-group row">
  <label *ngIf="!suppressLabel" [for]="id" class="col-form-label" [ngClass]="labelClass || 'col-12 col-sm-4'">
    {{ label | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col">
    <select
      *ngIf="!renderStatic"
      [disabled]="disabled"
      [name]="id"
      [id]="id"
      class="form-control form-select {{extraSelectClass}}"
      [(ngModel)]="selected"
    >
      <option *ngIf="!hideEmptyOption" value="">{{ emptyOptionLabel | translate }}</option>
      <option [value]="nameAsValue ? option.value : option.key" *ngFor="let option of metaOptions$ | async">
        {{ showId ? option.key + ' - ' : '' }}  {{ needTraslate ? (option.value | translate) : option.value }} {{ showIdEnd ? ' - ' + option.key : '' }}
      </option>
    </select>
    <input
      *ngIf="renderStatic"
      type="text"
      readonly
      class="form-control-plaintext"
      [id]="id"
      [value]="getSelectedValue(selected) | async | translate"
    />
  </div>
</div>
