import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../../models/pim';
import { MetadataService } from '../../../../../services/metadata/metadata.service';

@Component({
  selector: 'lib-pim-product-shopify-variants',
  templateUrl: './pim-product-shopify-variants.component.html',
  styleUrls: ['./pim-product-shopify-variants.component.css']
})
export class PimProductShopifyVariantsComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  @Input()
  languages: string[];

  constructor(protected metadataService: MetadataService) {}

  ngOnInit() {
    // .i18n[lang].colortags[color.name]

    for (const variant of this.pimProduct.dimensions?.colors) {
      const colors = this.pimProduct.extra.pim.colors[variant.dimensionId];
      for (const language of this.languages) {
        if (!colors.i18n?.[language]?.colortags) {
          colors.i18n = {
            ...(colors.i18n || {}),
            [language]: {
              ...(colors.i18n?.[language] || {}),
              colortags: {
                ...(colors.i18n?.[language]?.colortags || {})
              }
            }
          };
        }
      }
    }
  }

  getMetadataList(table: string) {
    return this.metadataService.getMetadataTableList(table);
  }
}
