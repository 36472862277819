import { Component, OnInit, Input } from '@angular/core';
import { GungFlow } from './../../../../state/flow/types';
import { NgbCalendar, NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { parse, format, isDate, parseISO, isValid } from 'date-fns';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { DigitalAssetsService } from './../../../../services/digital-assets/digital-assets.service';

interface UserGroup {
  key: string;
  selected: boolean;
}

@Component({
  selector: 'lib-gung-flow-details-basic-settings',
  templateUrl: './gung-flow-details-basic-settings.component.html',
  styleUrls: ['./gung-flow-details-basic-settings.component.css']
})
export class GungFlowDetailsBasicSettingsComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;

  userGroups: UserGroup[] = [];
  public isMultidropEnabled = false;

  deliveryStartDate: NgbDate | null;
  deliveryEndDate: NgbDate | null;
  orderStartDate: NgbDate | null;
  orderEndDate: NgbDate | null;
  priceDate: NgbDate | null;
  multiDropDate1: NgbDate | null;
  multiDropDate2: NgbDate | null;

  showFlowsAsDigitalAssets = false;

  constructor(
    protected calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    protected gungFlowService: GungFlowService,
    protected digitalAssetsService: DigitalAssetsService
  ) {}

  ngOnInit() {
    this.showFlowsAsDigitalAssets = this.digitalAssetsService?.showFlowsAsDigitalAssets;
    this.setUserGroupsCanSeeFlow();
    this.isMultidropEnabled = this.gungFlowService.isMultiDropFlowsEnabled;
    if (this.isMultidropEnabled) {
      this.initMutliDrops();
    }
  }

  toogleGroup(key: string, selected: boolean): void {
    this.userGroups.find(group => group.key === key).selected = !selected;
    this.curentGungFlow.isActiveGroup[key] = !selected;
  }

  parseDate(value: any): Date {
    if (!value) {
      return null;
    }

    if (isDate(value)) {
      return value;
    }

    const date = parseISO(value);
    if (isValid(date)) {
      return date;
    }
    return new Date(value);
  }

  validateInput(currentValue: NgbDate | null, input: string, id: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    const resultParsed = parsed && this.calendar.isValid(NgbDate.from(parsed)) ? NgbDate.from(parsed) : currentValue;
    if (this.calendar.isValid(NgbDate.from(resultParsed))) {
      this.updateDate(resultParsed, id);
    }
    return resultParsed;
  }

  updateDate(date: NgbDate, id: string): void {
    const formatedDate = format(new Date(date.year, date.month - 1, date.day), 'yyyy-MM-dd');

    if (id === 'deliveryStartDate') {
      this.curentGungFlow.deliveryStartDate = formatedDate;
    } else if (id === 'deliveryEndDate') {
      this.curentGungFlow.deliveryEndDate = formatedDate;
    } else if (id === 'orderStartDate') {
      this.curentGungFlow.orderStartDate = formatedDate;
    } else if (id === 'orderEndDate') {
      this.curentGungFlow.orderEndDate = formatedDate;
    } else if (id === 'priceDate') {
      this.curentGungFlow.priceDate = formatedDate;
    } else if (id === 'multiDropDate1') {
      this.curentGungFlow.drops[0].firstDeliveryDate = formatedDate;
    } else if (id === 'multiDropDate2') {
      this.curentGungFlow.drops[1].firstDeliveryDate = formatedDate;
    }
  }

  updateDataValue(date, id: string) {
    const formatedDate = date.target.value;
    if (id === 'deliveryStartDate') {
      this.curentGungFlow.deliveryStartDate = formatedDate;
    } else if (id === 'deliveryEndDate') {
      this.curentGungFlow.deliveryEndDate = formatedDate;
    } else if (id === 'orderStartDate') {
      this.curentGungFlow.orderStartDate = formatedDate;
    } else if (id === 'orderEndDate') {
      this.curentGungFlow.orderEndDate = formatedDate;
    } else if (id === 'priceDate') {
      this.curentGungFlow.priceDate = formatedDate;
    } else if (id === 'rrpDate') {
      this.curentGungFlow.rrpDate = formatedDate;
    }
  }

  clearDateIfEmpty(event: any, id: string) {
    if (!event.target.value) {
      if (id === 'priceDate') {
        this.curentGungFlow.priceDate = null;
        this.priceDate = null;
        console.log('priceDate cleared');
      }
      // Should be added to the other date-inputs as well but will se so this doesn't break anything first. 2021-12-01
    }
  }

  private setUserGroupsCanSeeFlow(): void {
    const allKey = 'ALL';
    this.userGroups.push({
      key: allKey,
      selected: !!this.curentGungFlow.isActiveGroup[allKey]
    });
    this.gungFlowService.getSystemGung().subscribe(systemSettings => {
      const settingsGroups = systemSettings.extra.userGroups || [];
      for (const key of settingsGroups) {
        this.userGroups.push({
          key,
          selected: !!this.curentGungFlow.isActiveGroup[key]
        });
      }
    });
  }
  initMutliDrops() {
    if (!this.curentGungFlow.drops) {
      this.curentGungFlow.drops = [];
    }
    if (!this.curentGungFlow.drops[0]) {
      this.curentGungFlow.drops[0] = {
        id: 'D1',
        name: 'Drop 1',
        firstDeliveryDate: undefined,
        productConditions: null
      };
    }
    if (!this.curentGungFlow.drops[1]) {
      this.curentGungFlow.drops[1] = {
        id: 'D2',
        name: 'Drop 2',
        firstDeliveryDate: undefined,
        productConditions: null
      };
    }
  }
}
