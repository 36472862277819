import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { Product } from '../../models/product';
import { Observable, forkJoin, of, from } from 'rxjs';
import { switchMap, first, map } from 'rxjs';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { DownloadFile, DownloadFileService } from '../file-downloader/download-file.service';

export enum ExportProductSelectionDataType {
  Xlsx,
  Csv,
  Img,
  Linesheet,
  Pricat,
  ProductInfo,
  ProductStock,
  ProductInformationCategory,
  ProductInfoLight,
  ProductInfoV3,
  ProductDocuments
}

@Injectable({
  providedIn: 'root'
})
export class ProductExportService {
  currentLang = this.translateService.currentLang || 'se';

  constructor(
    protected http: HttpClient,
    protected productService: ProductService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected downloadFileService: DownloadFileService
  ) {}

  exportExcel(
    itemList: Product[],
    template?: string,
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-excel-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Xlsx,
      template,
      additionalVariables
    );
  }

  exportCsv(
    itemList: Product[],
    template?: string,
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-csv-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Csv,
      template,
      additionalVariables
    );
  }

  exportLinesheet(itemList: Product[], additionalVariables?: {}): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-linesheet-excel-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Linesheet,
      undefined,
      additionalVariables
    );
  }

  exportPricat(itemList: Product[], additionalVariables?: {}): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-pricat-excel-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Pricat,
      undefined,
      additionalVariables
    );
  }

  exportImg(itemList: Product[], additionalVariables?: {}): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-image-zip-from-products-ids',
      itemList,
      ExportProductSelectionDataType.Img,
      undefined,
      additionalVariables
    );
  }

  exportProductInfo(
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    useV2?: boolean
  ): Observable<{ status: boolean; message: string }> {
    const url = useV2 ? 'v2/generate-product-pdf-from-products-ids' : 'generate-product-pdf-from-products-ids';
    return this.downloadFile(
      url,
      itemList,
      ExportProductSelectionDataType.ProductInfo,
      template,
      additionalVariables,
      additionalParams
    );
  }

  exportProductInfoV3(
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    sendToMail?: boolean
  ): Observable<any> {
    const url = 'v3/generate-product-pdf-from-products-ids';

    return this.getExportRequestBodyByType(
      ExportProductSelectionDataType.ProductInfoV3,
      itemList,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      map(data => {
        return { isValid: true, body: data };
      }),
      switchMap(data => {
        if (sendToMail) {
          return this.http.post('download/' + url + '?sendToMail=true', data.body);  
        } else {
          return this.http.post('download/' + url, data.body, { responseType: 'blob', observe: 'response' });
        }
      })
    );
  }

  exportProductInfoBytes(
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    fileName?: string
  ): Observable<DownloadFile> {
    const url = 'download/generate-product-pdf-from-products-ids/bytes';
    if (!!!fileName) {
      fileName = 'product-data.pdf';
    }
    return this.getExportRequestBodyByType(
      ExportProductSelectionDataType.ProductInfo,
      itemList,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      switchMap(body => {
        return this.http.post(url, body, { responseType: 'arraybuffer', observe: 'response' }).pipe(
          map((response: HttpResponse<ArrayBuffer>) => {
            return this.downloadFileService.create(response);
          })
        );
      })
    );

    /*   const body = itemList.map(i => i.id);
      return this.http.post(url, body, { responseType: 'arraybuffer', observe: 'response' })
        .pipe(
          map((response: HttpResponse<ArrayBuffer>) => {
            return this.downloadFileService.create(response);
          })
        ); */
  }

  exportProductStock(
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-pdf-from-products-ids',
      itemList,
      ExportProductSelectionDataType.ProductStock,
      template,
      additionalVariables,
      additionalParams
    );
  }

  exportProductInformationCategory(
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'v2/generate-product-pdf-from-products-ids',
      itemList,
      ExportProductSelectionDataType.ProductInformationCategory,
      template,
      additionalVariables,
      additionalParams
    );
  }

  buildExportRequestFromModal(
    exportSelectionDataType: ExportProductSelectionDataType,
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ isValid: boolean; body: any }> {
    // build request body
    return this.getExportRequestBodyByType(
      exportSelectionDataType,
      itemList,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      switchMap((data: any) => {
        return forkJoin({
          data: of(data),
          modalOutput: from(this.gungModalService.openConfirmExportModal(data.recipient)).pipe(first())
        });
      }),
      switchMap(({ data, modalOutput }) => {
        let isValid = false;

        if (modalOutput && modalOutput.email) {
          isValid = true;
          data.recipient = modalOutput.email;
        }

        return of({ isValid, body: data });
      })
    );
  }

  buildExportPricatRequestFromModal(
    exportSelectionDataType: ExportProductSelectionDataType,
    itemList: Product[],
    additionalVariables?: {}
  ): Observable<{ isValid: boolean; body: any }> {
    // build request body
    return this.getExportRequestBodyByType(exportSelectionDataType, itemList, undefined, additionalVariables).pipe(
      first(),
      switchMap((data: any) => {
        return forkJoin({
          data: of(data),
          modalOutput: from(this.gungModalService.openConfirmExportPricatModal(data.recipient)).pipe(first()),
          currentUser: this.authService.getCurrentUser().pipe(first())
        });
      }),
      switchMap(({ data, modalOutput, currentUser }) => {
        let isValid = false;

        if (modalOutput && modalOutput.email) {
          isValid = true;
          data.recipient = modalOutput.email;
          data.definitionId = modalOutput.definitionId;
          data.params = {
            ...modalOutput.params,
            user: currentUser
          };
        }

        return of({ isValid, body: data });
      })
    );
  }

  getExportRequestBodyByType(
    exportSelectionDataType: ExportProductSelectionDataType,
    itemList: Product[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<any> {
    return forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      map(([user, customer, flow]) => {
        // this parameters are shared along all the requests
        let requestBody: any = {
          ids: itemList.map(x => x.id),
          recipient: user.email,
          customerId: customer.id,
          flowId: flow.id,
          ...additionalVariables
        };

        // Linesheet exports contains stockId
        if (exportSelectionDataType === ExportProductSelectionDataType.Linesheet) {
          requestBody = {
            ...requestBody,
            stockId: user.managedMultistockIds[0]
          };
        }

        // Product Info and Stock has two additional parameters
        // template and params
        if (
          [
            ExportProductSelectionDataType.ProductInfo,
            ExportProductSelectionDataType.ProductInfoV3,
            ExportProductSelectionDataType.ProductStock,
            ExportProductSelectionDataType.ProductInformationCategory
          ].includes(exportSelectionDataType)
        ) {
          const paramsValue: any = {
            stockId: user.managedMultistockIds[0],
            customerId: customer.id,
            flowId: flow.id,
            ...additionalParams
          };

          // const templateValue = exportSelectionDataType === ExportProductSelectionDataType.ProductInfo ? 'PRODUCT_INFORMATION_EXPORT' : 'PRODUCT_STOCK_EXPORT';
          let templateValue;
          switch (exportSelectionDataType) {
            case ExportProductSelectionDataType.ProductInfo:
              paramsValue.language = this.currentLang;
              paramsValue.dateCreated = new Date().toDateString();
              templateValue = template || 'PRODUCT_INFORMATION_EXPORT';
              break;
            case ExportProductSelectionDataType.ProductInfoV3:
              paramsValue.language = this.currentLang;
              paramsValue.dateCreated = new Date().toDateString();
              templateValue = template || 'PRODUCT_INFORMATION_EXPORT';
              break;
            case ExportProductSelectionDataType.ProductInformationCategory:
              paramsValue.language = this.currentLang;
              paramsValue.dateCreated = new Date().toDateString();
              templateValue = template || 'PRODUCT_INFORMATION_EXPORT_CATEGORY';
              break;
            default:
              paramsValue.language = this.currentLang;
              paramsValue.dateCreated = new Date().toDateString();
              templateValue = template || 'PRODUCT_STOCK_EXPORT';
          }

          requestBody = {
            ...requestBody,
            template: templateValue,
            params: paramsValue
          };
        }

        if (
          !!template &&
          [ExportProductSelectionDataType.Xlsx, ExportProductSelectionDataType.Csv].includes(exportSelectionDataType)
        ) {
          requestBody.definitionId = template;
        }

        return requestBody;
      })
    );
  }

  exportProductsServiceCall(serviceUrl: string, body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/' + serviceUrl, body).pipe(
      first(),
      map(resp => {
        return {
          status: !resp || resp.status === 'OK',
          message: resp && resp.status
        };
      })
    );
  }

  downloadFile(
    serviceUrl: string,
    itemList: Product[],
    type: ExportProductSelectionDataType,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ status: boolean; message: string }> {
    let requestToSendObs: Observable<{ isValid: boolean; body: any }> = null;
    if (type === ExportProductSelectionDataType.Pricat) {
      requestToSendObs = this.buildExportPricatRequestFromModal(type, itemList, additionalVariables);
    } else {
      requestToSendObs = this.buildExportRequestFromModal(
        type,
        itemList,
        template,
        additionalVariables,
        additionalParams
      );
    }

    return requestToSendObs.pipe(
      first(),
      switchMap((request: { isValid: boolean; body: any }) => {
        if (request.isValid) {
          return this.exportProductsServiceCall(serviceUrl, request.body);
        } else {
          return of({
            status: false,
            message: 'An error has occurred'
          });
        }
      })
    );
  }

  exportDoc(
    itemList: Product[],
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-documents-zip-from-products-ids',
      itemList,
      ExportProductSelectionDataType.ProductDocuments,
      undefined,
      additionalVariables
    );
  }

  exportImgByUris(
    uris: string[],
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadZip(
      'generate-image-zip-from-url-list',
      uris,
      'images',
      additionalVariables
    );
  }

  exportDocByUris(
    uris: string[],
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadZip(
      'generate-document-zip-from-url-list',
      uris,
      'documents',
      additionalVariables
    );
  }

  downloadZip(
    serviceUrl: string,
    uriList: string[],
    fileType: string,
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    let requestToSendObs: Observable<{ isValid: boolean; body: any }> = forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      map(([user, customer, flow]) => {
        let requestBody: any = {
          recipient: user.email,
          customerId: customer.id,
          flowId: flow.id,
          ...additionalVariables
        };
        requestBody[fileType] = uriList;

        return requestBody;
      }),
      switchMap(data => {
        return forkJoin([
          of(data),
          from(this.gungModalService.openConfirmExportModal(data.recipient)).pipe(first())
        ]).pipe(
        map(([data, modalOutput]) => {
          let isValid = false;

          if (modalOutput && modalOutput.email) {
            isValid = true;
            data.recipient = modalOutput.email;
          }
  
          return { isValid, body: data };
        }))
      })
    );
    
    return requestToSendObs.pipe(
      first(),
      switchMap((request: { isValid: boolean; body: any }) => {
        if (request.isValid) {
          return this.exportProductsServiceCall(serviceUrl, request.body);
        } else {
          return of({
            status: false,
            message: 'An error has occurred'
          });
        }
      })
    );
  }
}
