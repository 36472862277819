import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable, map, of } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { OrderAnalyticsListComponent } from '../../components/order-analytics/order-analytics-list/order-analytics-list.component';
import { StandardStatisticsData } from '../../models/statistics';
import { CustomerStatisticsService } from '../customer-statistics/customer-statistics.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { format } from 'date-fns';
import { OrderAnalyticsGraphList } from '../../components/order-analytics/order-analytics-standalone/order-analytics-standalone.component';

@Injectable({
  providedIn: 'root'
})
export class OrderAnalyticsConfigService implements ConfigService<StandardStatisticsData> {
  topFilter = false;
  searchDisabled = true;
  useCategoryDescription = false;
  useDefaultGraphList = true;

  defaultGraphList: OrderAnalyticsGraphList[] = [
    {
      id: '1',
      translationKey: 'YEAR',
      groupBy: 'orderCreationDateYear',
      heading: 'YEAR',
      headerRows: ['YEAR', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '2',
      translationKey: 'QUARTER',
      groupBy: 'orderCreationDateYearQuarter',
      heading: 'QUARTER',
      headerRows: ['QUARTER', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '3',
      translationKey: 'MONTH',
      groupBy: 'orderCreationDateYearMonth',
      heading: 'MONTH',
      headerRows: ['MONTH', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '4',
      translationKey: 'ORDER_SEASONS',
      groupBy: 'orderSeasonCode',
      heading: 'ORDER_SEASONS',
      headerRows: ['SEASON_NAME', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true,
    },
    {
      id: '5',
      translationKey: 'ORDER_TYPES',
      groupBy: 'orderTypeDescription',
      heading: 'ORDER_TYPES',
      headerRows: ['ORDER_TYPE', 'SALES_AMOUNT', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true,
    },
    {
      id: '6',
      translationKey: 'PRODUCT_CATEGORIES_LAST_12_MONTHS',
      groupBy: this.useCategoryDescription
        ? 'productCategoryDescription'
        : 'productCategoryCode',
      heading: 'PRODUCT_CATEGORY',
      headerRows: ['PRODUCT_CATEGORY', 'SALES_AMOUNT', 'QTY'],
    },
    {
      id: '7',
      translationKey: 'PRODUCTS',
      groupBy: 'productId',
      heading: 'PRODUCTS',
      headerRows: [],
      showProductAnalyticsList: true,
    }
  ];

  getDefaultGraphList(): OrderAnalyticsGraphList[] {
    return this.defaultGraphList;
  }

  /*
 This variable and setter is called in the invoice-list-component to set
 the customerId to the id in the route params. It did not work to get the routeparams
 from this service.
 */
  customerId: string;
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(
    protected customerStatisticsService: CustomerStatisticsService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService
  ) { }

  getItems(route?: ActivatedRouteSnapshot): Observable<StandardStatisticsData[]> {
    if (!this.customerId) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        first(),
        switchMap(cust => this.customerStatisticsService.getStatisticsByCustomer(cust?.id || '')),
        map(statistics => {
          const result: StandardStatisticsData[] = [];
          for (const [index, statistic] of statistics.entries()) {
            if (!Object.values(statistic).every(value => !value || (Array.isArray(value) && value.length === 0))) {
              result.push(statistic);
            }
          }
          return result;
        }),
        switchMap(statistics => {
          if (!!statistics) {
            return of(statistics);
          } else {
            return of([]);
          }
        })
      );
    }
    return this.customerStatisticsService.getStatisticsByCustomer(this.customerId).pipe(
      map(statistics => {
        const result: StandardStatisticsData[] = [];
        for (const [index, statistic] of statistics.entries()) {
          if (!Object.values(statistic).every(value => !value || (Array.isArray(value) && value.length === 0))) {
            result.push(statistic);
          }
        }
        return result;
      }),
      switchMap(statistics => {
        if (!!statistics) {
          return of(statistics);
        } else {
          return of([]);
        }
      })
    );
  }

  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<StandardStatisticsData>[] {
    return [new OrderAnalyticsDateFilter(this.translateService)];
  }

  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<StandardStatisticsData>[] {
    return [];
  }

  getBatchSizes(route?: ActivatedRouteSnapshot): number[] | undefined {
    return [Number.MAX_SAFE_INTEGER];
  }

  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderAnalyticsListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order analytics charts'
      }
    ];
  }

  getSearchTerms(item: StandardStatisticsData): string[] {
    return [item.orderId, item.productId, item.productName];
  }

  getItemId(item: StandardStatisticsData): string {
    return item.id;
  }
}

export class OrderAnalyticsDateFilter extends SimpleConfigBaseFilter<StandardStatisticsData> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: StandardStatisticsData): string[] {
    return [format(new Date(item.orderCreationDate), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}
