export function isObject(item) {
  return item && typeof item === 'object' && !Array.isArray(item);
}

/*
 * Found on https://stackoverflow.com/questions/27936772/how-to-deep-merge-instead-of-shallow-merge.
 * Lodash would be prefered.
 */
export function mergeDeep(target, ...sources) {
  if (!sources.length) {
    return target;
  }
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) {
          Object.assign(target, { [key]: {} });
        }
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}
