import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ListLayout, ListLayoutSingleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { tap } from 'rxjs';
import { DeliveryLocationListCardComponent } from '../../components/delivery-location-list-card/delivery-location-list-card.component';
import { Customer } from '../../models';
import { AuthService } from '../auth/auth.service';
import { CustomerListConfigService } from '../customer-list-config/customer-list-config.service';
import { CustomerService } from '../customers/customer.service';
import { MetadataService } from '../metadata/metadata.service';
import { UsersService } from '../users/users.service';
import { CustomerSelectionActionConfigService } from '../customer-selection-action-config/customer-selection-action-config.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class DeliveryLocationListConfigService extends CustomerListConfigService {
  constructor(
    public customerService: CustomerService,
    protected usersService: UsersService,
    protected authService: AuthService,
    protected customerSelectionActionConfigService: CustomerSelectionActionConfigService,
    protected translateService: TranslateService,
    public metadataService: MetadataService,
    public router: Router
  ) {
    super(customerService, usersService, authService, customerSelectionActionConfigService, translateService);
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => DeliveryLocationListCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Customer list'
      }
    ];
  }

  getItems(): Observable<Customer[]> {
    return this.customerService.getCustomers().pipe(
      tap(customers => {
        if (!customers || customers.length < 2) {
          this.router.navigate(['/categories']);
        }
      })
    );
  }
}
