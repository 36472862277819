import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerUserListConfigService } from '../../services/customer-user-list-config/customer-user-list-config.service';

@Component({
  selector: 'lib-customer-user-list',
  template: `<lib-filter-list [configService]="customerUserListConfigService"></lib-filter-list>`,
  styleUrls: ['./customer-user-list.component.css']
})
export class CustomerUserListComponent {
  constructor(public customerUserListConfigService: CustomerUserListConfigService, route: ActivatedRoute) {
    customerUserListConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
