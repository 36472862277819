import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of, map } from 'rxjs';
import { CustomPageConfigService } from './custom-page-config.service';
import { HomeDefaultTemplateComponent } from './../../components/custom-page/default-template/home-default-template/home-default-template.component';
import { HomeFastSearchTemplateComponent } from '../../components/custom-page/default-template/home-fast-search-template/home-fast-search-template.component';
import { HomeComponent } from '../../components/home/home.component';
import { HomeAssortmentNewTemplateComponent } from '../../components/custom-page/default-template/home-assortment-new-template/home-assortment-new-template.component';
import { HomeFashionTemplateComponent } from './../../components/custom-page/default-template/home-fashion-template/home-fashion-template.component';
import { HomeSlideProductListTemplateComponent } from '../../components/custom-page/default-template/home-slide-product-list-template/home-slide-product-list-template.component';
import { HtmlTemplateComponent } from '../../components/custom-page/default-template/html-template/html-template.component';
import { CustomPageMissingComponent } from '../../components/custom-page-missing/custom-page-missing.component';
import { HomeImagesAndProductsTemplateComponent } from '../../components/custom-page/default-template/home-images-and-products-template/home-images-and-products-template.component';

@Injectable({
  providedIn: 'root'
})
export class CustomPageResolverService  {
  constructor(protected customPageConfigService: CustomPageConfigService, protected router: Router) {}

  public getLayouts(): { name; componentDisplay }[] {
    const layouts = [
      {
        name: 'homedefault',
        componentDisplay: HomeDefaultTemplateComponent
      },
      {
        name: 'homeassortment',
        componentDisplay: HomeAssortmentNewTemplateComponent
      },
      {
        name: 'homefastsearch',
        componentDisplay: HomeFastSearchTemplateComponent
      },
      {
        name: 'homefashion',
        componentDisplay: HomeFashionTemplateComponent
      },
      {
        name: 'homeslideproductlist',
        componentDisplay: HomeSlideProductListTemplateComponent
      },
      {
        name: 'homeimagesandproducts',
        componentDisplay: HomeImagesAndProductsTemplateComponent
      },
      {
        name: 'htmleditor',
        componentDisplay: HtmlTemplateComponent
      },
    ];
    return layouts;
  }

  getDefaultLayoutComponent(templateName) {
    let componentDisplay = this.router.url === '/admin/custom-page-editor' ? CustomPageMissingComponent : HomeComponent;
    const index = this.getLayouts().findIndex(l => l.name === templateName);
    if (index !== -1) {
      componentDisplay = this.getLayouts()[index].componentDisplay;
    }
    return componentDisplay;
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
    templateName?: string
  ): Observable<any> | Observable<never> {
    // GET LAYOUT TO DISPLAY BY PAGENAME SET IN ROUTE
    if (templateName && !route?.params?.id) {
      return of(this.getDefaultLayoutComponent(templateName));
    }
    return this.customPageConfigService.getTemplateDataByUserGroups(route.data.pageName || route?.params?.id).pipe(
      map(templateData => {
        const name = templateData ? templateData.templateName : undefined;
        return this.getDefaultLayoutComponent(name);
      })
    );
  }
}
