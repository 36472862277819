import { Component, OnInit } from '@angular/core';
import { NewsPageListConfigService } from '../../../services/news/news-page-list-config/news-page-list-config.service';

@Component({
  selector: 'lib-news-page-list',
  template:
    '<h3 translate class="page-title text-center">NEWS</h3><lib-filter-list [configService]="newsPageListConfigService"></lib-filter-list>',
  styleUrls: ['./news-page-list.component.css']
})
export class NewsPageListComponent implements OnInit {
  constructor(public newsPageListConfigService: NewsPageListConfigService) {}

  ngOnInit(): void {}
}
