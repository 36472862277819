import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { CurrencyPipe } from '@angular/common';
import { PipeService } from '../services/pipe/pipe.service';

@Pipe({
  name: 'gungCurrency'
})
export class GungCurrencyPipe extends CurrencyPipe implements PipeTransform {
  constructor(@Inject(LOCALE_ID) private locale: string, protected pipeService: PipeService) {
    super(locale);
  }

  transform(
    value: any,
    currencyCode?: string,
    display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean,
    digitsInfo?: string,
    locale?: string
  ): any {
    if (!currencyCode) {
      display = '';
    }
    // In some cases we want to override the display and locale in general for a specific
    // currency. One such case is $. These below allows us to always override on an application level
    // (without having to change in every component of the application).
    display = this.pipeService.overrideDisplay(currencyCode, display);
    locale = this.pipeService.overrideLocale(currencyCode, locale);

    const processed = this.pipeService.preprocessCurrency(value);
    const x = super.transform(processed, currencyCode, display, digitsInfo, locale);
    return this.pipeService.transformCurrency(x, value, currencyCode, display, digitsInfo, locale);
  }
}
