import { DigitalAssetsService } from './../digital-assets/digital-assets.service';
import { Injectable, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { DigitalAssetsFolderGridViewComponent } from '../../components/digital-assets/digital-assets-folder-grid-view/digital-assets-folder-grid-view.component';
import { AuthService } from '../auth/auth.service';
import { DigitalAsset } from '../digital-assets/digital-assets.service';
import { DownloadService } from '../download/download.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { MetadataService } from '../metadata/metadata.service';
import { filter, first, map, mergeMap, switchMap } from 'rxjs';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class DigitalAssetsFolderListConfigService implements ConfigService<DigitalAsset> {
  topFilter = true;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);
  firstLoad = true;

  constructor(
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected metadataService: MetadataService,
    protected authService: AuthService,
    protected downloadService: DownloadService,
    protected digitalAssetsService: DigitalAssetsService,
    protected gungFlowServive: GungFlowService,
    protected productService: ProductService
  ) {}

  getFilters(): ConfigBaseFilter<DigitalAsset>[] {
    return [];
  }

  getSortOptions(): ListSortOption<DigitalAsset>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: DigitalAsset) {
    return item.id;
  }

  getSearchTerms(item: DigitalAsset) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<DigitalAsset>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => DigitalAssetsFolderGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'PimConceptGrid'
      }
    ];
  }

  getItems(): Observable<DigitalAsset[]> {
    return this.digitalAssetsObjectsSubject.pipe(
      // filter(ids => !!ids),
      switchMap(ids => 
        forkJoin([
          of(ids),
          this.digitalAssetsService.getDigitalAssets(true).pipe(first()),
          this.gungFlowServive.getGungFlows().pipe(first()),
          this.authService.getCurrentUser().pipe(first())
        ])
      ),
      switchMap(([filterIds, digitalAssetsOriginal, flows, user]) => {
        const digitalAssets = digitalAssetsOriginal
          ? JSON.parse(JSON.stringify(digitalAssetsOriginal))
          : digitalAssetsOriginal;
        if (
          this.firstLoad &&
          this.digitalAssetsService.showFlowsAsDigitalAssets &&
          flows.findIndex(f => filterIds.includes(f.id)) > -1
        ) {
          const filteredDigitalAssets = flows
            .filter(f => f.extra.digitalAssets)
            .map(f => ({
              dateLastUpdate: null,
              i18n: {},
              images: [],
              documents: [],
              id: f.id,
              name: f.name,
              extra: { flow: true }
            }));
          // return of(filteredDigitalAssets);
          digitalAssets.unshift(...filteredDigitalAssets);
        }
        if (
          this.firstLoad &&
          digitalAssets.findIndex(x => x.id === 'product-images') < 0 &&
          !this.digitalAssetsService.showFlowsAsDigitalAssets
        ) {
          const productfolder: DigitalAsset = {
            id: 'product-images',
            dateLastUpdate: 1,
            documents: [],
            extra: {
              type: 'folder',
              content: 'digitalAsset',
              sequence: 99999
            },
            name: 'Product Images',
            i18n: {
              se: {},
              en: {}
            },
            images: []
          };
          const found = flows.find(flow => flow.extra.digitalAssets === true);
          if (found) {
            return this.productService.getProductsForDigitalAssets(user.assortment).pipe(
              first(),
              mergeMap(products => {
                const index = products.findIndex(p => p.extra.images[0].s3Uri !== 'images/no-image.jpg');
                if (index >= 0) {
                  productfolder.images.push(products[index].extra.images[0]);
                }
                digitalAssets.unshift(productfolder);
                if (filterIds) {
                  return of(digitalAssets.filter(d => filterIds.includes(d.id) || d.id === 'product-images'));
                }
              })
            );
          }
          if (filterIds) {
            return of(digitalAssets.filter(d => filterIds.includes(d.id) || d.id === 'product-images'));
          }
        }
        if (filterIds) {
          return of(digitalAssets.filter(d => filterIds.includes(d.id)));
        }
        return of(digitalAssets);
      }),
      map(digitalAssets => {
        return digitalAssets.sort((a, b) => {
          if (!a.extra?.sequence && !b.extra?.sequence) {
            return 0;
          }
          if (a.extra?.sequence && !b.extra?.sequence) {
            return -1;
          }
          if (b.extra?.sequence && !a.extra?.sequence) {
            return 1;
          }
          if (Number(a.extra.sequence) > Number(b.extra.sequence)) {
            return -1;
          }
          if (Number(a.extra.sequence) < Number(b.extra.sequence)) {
            return 1;
          }
          return 0;
        });
      })
    );
  }

  getNothingFoundTranslateTag() {
    return null;
  }

  getSearchGroupCss() {
    return 'hide-search';
  }
}
