import { Component, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-pim-template-create-modal',
  templateUrl: './pim-template-create-modal.component.html',
  styleUrls: ['./pim-template-create-modal.component.css']
})
export class PimTemplateCreateModalComponent {
  submitForm: FormGroup;
  @Input('template')
  set template(value) {
    if (value) {
      const template = JSON.parse(value);
      this.submitForm.patchValue(template);
      this.submitForm.get('id').disable();
      this.submitForm.get('id').updateValueAndValidity();
    }
  }

  constructor(protected formBuilder: FormBuilder, public ngbActiveModal: NgbActiveModal) {
    this.setForm();
  }

  setForm() {
    this.submitForm = this.formBuilder.group({
      active: this.formBuilder.control(true),
      id: this.formBuilder.control(''),
      name: this.formBuilder.control(''),
      order: this.formBuilder.control(null)
    });
  }

  onSubmit() {
    if (!this.submitForm.valid) {
      this.submitForm.markAllAsTouched();
      return;
    }
    const item = this.submitForm.getRawValue();
    this.submitForm.reset();
    this.ngbActiveModal.close(JSON.stringify(item));
  }
}
