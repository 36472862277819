import { Injectable } from '@angular/core';
import { OrderListConfigService } from '../order-list-config/order-list-config.service';
import { TranslateService } from '@ngx-translate/core';
import { OrderService } from '../orders/order.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { MetadataService } from '../metadata/metadata.service';
import { ListLayout } from 'gung-list';
import { Order } from '../../models/order';
import { ConfigBaseFilter } from 'gung-list';
import { SimpleConfigBaseFilter } from 'gung-list';
import { differenceInDays } from 'date-fns';
import { ListLayoutSingleComponent, ListLayoutMultipleComponent } from 'gung-list';
import { OpenOrderCardListComponent } from '../../components/open-order-card-list/open-order-card-list.component';
import { mergeMap, first, map } from 'rxjs';
import { forkJoin, of } from 'rxjs';
import { CustomerService } from '../customers/customer.service';
import { OpenOrderTableComponent } from '../../components/open-order-table/open-order-table.component';
import { DateUtilService } from 'gung-common';


@Injectable({
  providedIn: 'root'
})
export class OpenOrdersConfigService extends OrderListConfigService {
  constructor(
    protected translationService: TranslateService,
    protected orderService: OrderService,
    protected selectedCustomerService: SelectedCustomerService,
    protected metadataService: MetadataService,
    protected customerService: CustomerService,
    protected dateUtilService: DateUtilService
  ) {
    super(orderService, selectedCustomerService, dateUtilService);
  }

  getLayouts(): ListLayout<Order>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OpenOrderTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getFilters(): ConfigBaseFilter<Order>[] {
    return [];
  }

  getSearchTerms(item: Order): string[] {
    const result = super.getSearchTerms(item);

    if (item.extra?.customerName) {
      result.push(item.extra.customerName);
    }

    return result;
  }

  getItems() {
    return this.orderService.getOpenOrders().pipe(
      mergeMap(items => forkJoin([of(items), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) => {
        const result = orders.map(order => {
          return {
            ...order,
            extra: {
              ...order.extra
            }
          };
        });

        result.map(resOrder => {
          const cust = customers.find(customer => customer.id === resOrder.deliveryCustomerId);
          resOrder.extra.customerName = cust ? cust.name : '';
          return resOrder;
        });



        return result;
      })
    );
  }

  getAddQueryParameterLimitToUrl(): boolean {
    return true;
  }
}
