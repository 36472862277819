import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-pim-create-model-modal',
  templateUrl: './pim-create-model-modal.component.html',
  styleUrls: ['./pim-create-model-modal.component.css']
})
export class PimCreateModelModalComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
