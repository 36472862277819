import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

export interface GungConfiguration {
  '@class': string,
  id: string,
  [s: string]: any
}

@Injectable({
  providedIn: 'root'
})
export class ConfigurationsService {
  constructor(protected http: HttpClient) { }

  public getConfigurations(): Observable<any[]> {
    const url = 'json/admin/configurations';
    return this.http.get<any[]>(url);
  }

  public getConfigurationsByIds(ids: string[]): Observable<any[]> {
    const url = 'json/admin/configurations/by-ids?ids=' + ids.join(',');
    return this.http.get<any[]>(url);
  }

  public updateConfiguration(config: any): Observable<any> {
    const url = 'json/admin/configurations';
    return this.http.put<any>(url, config);
  }
}
