<lib-gung-html-editor
  *ngIf="curentGungFlow"
  suppressLabel="true"
  placeholder="Type the content here!"
  [(ngModel)]="curentGungFlow.extra.html"
></lib-gung-html-editor>
<div class="mt-3">
  <div class="form-group">
    <label class="w-100" for="file-upload" translate>BACKGROUND_IMAGE</label>
    <div class="input-group">
      <div class="flex-fill">
        <input [(ngModel)]="curentGungFlow.extra.backgroundImage" class="form-control bg-transparent" type="text" />
        <input
          #inputFile
          type="file"
          class="_custom-file-input form-control d-none"
          placeholder="File Upload"
          id="file-upload"
          aria-label="File Upload"
          aria-describedby="file-upload"
          accept=".jpg, .jpeg, .png"
          [multiple]="false"
          (change)="onFileChange($event)"
        />
      </div>
      <div class="input-group-append" (click)="inputFile.click()">
        <span class="input-group-text" id="file-upload"><i class="fas fa-folder"></i></span>
      </div>
    </div>
  </div>
</div>
