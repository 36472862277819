<div class="inline-search-container" [ngClass]="{ 'inline-search-container-mobile': isSmallScreen && resultsShow }">
  <div class="row inline-input-search-container">
    <div class="col">
      <div class="input-group inline-search d-none d-md-flex" [ngClass]="{ 'd-flex': resultsShow }">
        <span class="silva-nav-search">
          <i class="fas fa-search"></i>
        </span>
        <input
          #searchInput
          type="text"
          class="form-control pl-4"
          [attr.placeholder]="'NAVBAR_SEARCH_PLACEHOLDER' | translate"
          [formControl]="searchTermForm"
        />
      </div>
      <div class="inline-search-mobile d-md-none" [class.d-none]="isSmallScreen && resultsShow">
        <div class="input-group-append">
          <span class="input-group-text" (click)="resultsShow = true">
            <i class="fas fa-search"></i>
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="inline-search-products" [class.d-none]="!resultsShow" data-inline-search-products="1">
    <ng-template libCustomHost></ng-template>
    <div class="d-block show-more" *ngIf="totalCount > resultsLimit">
      <button type="button" class="btn btn-light btn-block btn-sm" (click)="showMoreProducts(searchInput)">
        <small>{{ 'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: resultsLimit, total: totalCount } }}</small>
      </button>
    </div>
  </div>
</div>
