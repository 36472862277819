import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutSingleComponent, ListSortOption } from 'gung-list';
import { filter, map, mergeMap, Observable, of } from 'rxjs';
import { OfferCardListComponent } from '../../components/offer-card-list/offer-card-list.component';
import { Offer } from '../../models/offer';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { OfferService } from './offer.service';

@Injectable({
  providedIn: 'root'
})
export class OfferListConfigService  implements ConfigService<Offer> {
  customerId: string;
  /*
  This variable and setter is called in the invoice-list-component to set
  the customerId to the id in the route params. It did not work to get the routeparams
  from this service.
  */
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(protected offerService: OfferService, protected selectedCustomerService: SelectedCustomerService) {}

  getItems(): Observable<Offer[]> {
    if (this.customerId !== null) {
      return this.getOffers(this.customerId);
    }
    return this.offerService.getOpenOffers();
  }

  getOffers(customerId): Observable<Offer[]>{
    return this.offerService.getOffers(customerId, true);

  }

  getFilters(): ConfigBaseFilter<Offer>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Offer>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Offer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OfferCardListComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Order list'
      }
    ];
  }

  getSearchTerms(item: Offer): string[] {
    return [item.id];
  }

  getItemId(item: Offer): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
