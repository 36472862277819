import { Component, Input, OnInit } from '@angular/core';
import { EdiMessageBase } from '../../../models/edi';

@Component({
  selector: 'lib-edi-detail-error-display',
  templateUrl: './edi-detail-error-display.component.html',
  styleUrls: ['./edi-detail-error-display.component.css']
})
export class EdiDetailErrorDisplayComponent implements OnInit {
  @Input()
  public ediMessage: EdiMessageBase;

  constructor() {}

  ngOnInit(): void {}
}
