import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { PdfDocument } from '../../models/pdf-document';
import { PdfManagementActionButtonConfigService } from '../../services/pdf-management-action-button-config.service';

@Component({
  selector: 'lib-pdf-export-grid-view',
  templateUrl: './pdf-export-grid-view.component.html',
  styleUrls: ['./pdf-export-grid-view.component.scss']
})
export class PdfExportGridViewComponent extends ListItemRendererComponent<PdfDocument[]> implements OnInit {
  blockBtns = true;

  constructor(public actionButtonConfig: PdfManagementActionButtonConfigService, protected router: Router) {
    super();
  }

  ngOnInit(): void {}

  createNewPdf() {
    this.router.navigate(['/export-pdf/new']);
  }
}
