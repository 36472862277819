import { Injectable, Input, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties
} from 'gung-list';
import { BehaviorSubject, first, forkJoin, Observable, of, Subject, switchMap } from 'rxjs';
import { map, tap } from 'rxjs';
import { Product, ProductExtended } from '../../models';
import { AuthService } from '../auth/auth.service';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { ColumnSortSelectionService } from '../column-sort-selection/column-sort-selection.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductService } from '../products/product.service';
import { ConceptDetailProductListViewComponent } from './../../components/concept-detail-product-list-view/concept-detail-product-list-view.component';
import { DynamicColumnsService } from '../dynamic-columns/dynamic-columns.service';

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ConceptDetailProductListConfigService implements ConfigService<Product> {
  topFilter = true;

  conceptSubProductsObjectsSubject: Subject<Product[]> = new BehaviorSubject(null);

  @Input()
  filters: ConfigBaseFilter<Product>[] = [];

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected baseViewConfigService: BaseViewConfigService,
    protected columnSortSelectionService: ColumnSortSelectionService,
    protected dynamicColumnsService: DynamicColumnsService
  ) {}

  getFilters(): ConfigBaseFilter<Product>[] {
    return this.filters;
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getConceptDetailV2ProductListViewComponent(), //ConceptDetailProductListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return this.columnSortSelectionService.getSelectedColumnSort().pipe(
      switchMap(selectedColumnSort => {
        return forkJoin([
          of(selectedColumnSort),
          this.conceptSubProductsObjectsSubject.pipe(first())
        ]);
      }),
      map(([selectedColumnSort, products]) => {
        // Sort rows
        if (selectedColumnSort) {
          return products = this.columnSortSelectionService.sortItemsByColumn(products, selectedColumnSort);
        } else {
          return products = products.sort((a, b) => {
            // SORT by pim sequence high
            const aSort = a.extra.pim && a.extra.pim.sequence;
            const bSort = b.extra.pim && b.extra.pim.sequence;
            if ((!bSort && aSort) || (aSort && bSort && Number(aSort) > Number(bSort))) {
              return -1;
            } else if ((!aSort && bSort) || (aSort && bSort && Number(aSort) < Number(bSort))) {
              return 1;
            } else {
              return 0;
            }
          })
        }
      }),
      map(products => {
        // Dyncamic column values
        return this.dynamicColumnsService.getDynamicColumnsValuesConcept(this.dynamicColumns, products);
      })
    );
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getSortOptions(): ListSortOption<Product>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: Product) {
    return item.id;
  }

  getDynamicColumns(): PimTemplateProperties[] {
    return this.dynamicColumns;
  }

  getPimOptions(): { [s: string]: any } {
    return this.pimOptions;
  }
}
