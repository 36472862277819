import { Component, OnInit } from '@angular/core';
import { NgbDate, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import {
  ActivityInternal,
  ActivityInternalService
} from '../../../services/activity-internal/activity-internal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { DateUtilService } from 'gung-common';
import { ActivityInternalTableComponent } from '../../activity-internal/activity-internal-table/activity-internal-table.component';
import { ActivityInternalCustomerResponsibleService } from '../../../services/activity-internal-customer-responsible.service';

@Component({
  selector: 'lib-activity-internal-todo-table',
  templateUrl: './activity-internal-todo-table.component.html',
  styleUrls: ['./activity-internal-todo-table.component.css']
})
export class ActivityInternalTodoTableComponent extends ActivityInternalTableComponent {
  constructor(
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected activityInternalService: ActivityInternalService,
    protected authService: AuthService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService,
    public metadataService: MetadataService,
    public activityInternalCustomerResponsibleService: ActivityInternalCustomerResponsibleService
  ) {
    super(
      modalService,
      gungModalService,
      activityInternalService,
      authService,
      translationService,
      dateUtilService,
      metadataService,
      activityInternalCustomerResponsibleService
    );
  }
  updateDate(date: NgbDate, activity: ActivityInternal): void {
    activity.activityDate = this.dateUtilService.createDateFromNgbDate(date).toISOString();
    this.activityInternalService.postActivityInternal(activity).subscribe(() => {});
  }
}
