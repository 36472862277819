import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoverImage, ExportCoverSheet } from '../../../models/export-cover-sheets';
import { ExportCoverSheetsService } from '../../../services/export-cover-sheets/export-cover-sheets.service';

@Component({
  selector: 'lib-update-cover-sheet-images-modal',
  templateUrl: './update-cover-sheet-images-modal.component.html',
  styleUrls: ['./update-cover-sheet-images-modal.component.css']
})
export class UpdateCoverSheetImagesModalComponent implements OnInit {
  data: ExportCoverSheet;
  updatedCoverSheet: ExportCoverSheet;
  images: CoverImage[] = [];
  loader: boolean = true;
  
  constructor(public activeModal: NgbActiveModal, public exportCoverSheetService: ExportCoverSheetsService) { }

  ngOnInit(): void {
    this.loadImages();
    this.loader = false;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.images, event.previousIndex, event.currentIndex);
  }
  
  delete(index: number) {
    if (index > -1) { 
      this.images.splice(index, 1);
      if (this.images.length === 0) {
        this.exportCoverSheetService.deleteExportCoverSheet(this.data.id).subscribe((data) => {
          this.activeModal.close(data);
        })
      }
    }
  }

  save() {
    this.updatedCoverSheet = {
      id: this.data.category + '_' + this.data.season,
      category: this.data.category,
      extra: this.data.extra,
      image: this.images.shift(),
      additionalImages: this.images.length > 0 ? Array.from(this.images) : [],
      index: null,
      name: null,
      season: this.data.season
    };
    this.exportCoverSheetService.putExportCoverSheet(this.updatedCoverSheet).subscribe(data => {
      this.activeModal.close(data);
    })
  }

  loadImages() {
    this.images.push(this.data.image);
    if (this.data?.additionalImages.length > 1) {
      this.images.push(...this.data.additionalImages);
    }
  }
}
