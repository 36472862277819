import { Injectable } from '@angular/core';
import { CompanyTemplatesService } from './company-templates.service';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { Observable, of } from 'rxjs';
import { CompanyTemplatesListViewComponent } from '../components/company-templates-list-view/company-templates-list-view.component';
import { CompanyTemplate } from '../models/company-template';

@Injectable({
  providedIn: 'root'
})
export class CompanyTemplatesListConfigService implements ConfigService<CompanyTemplate> {

  constructor(
    protected companyTemplatesService: CompanyTemplatesService
  ) { }

  getLayouts(): ListLayout<CompanyTemplate>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => CompanyTemplatesListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'CompanyTemplatesTable'
      }
    ];
  }

  getItems(): Observable<CompanyTemplate[]> {
    return this.companyTemplatesService.getAllCompanyTemplates();
  }

  getFilters(): ConfigBaseFilter<CompanyTemplate>[] {
    return [];
  }

  getSortOptions(): ListSortOption<CompanyTemplate>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }
  
  getSearchTerms(item: CompanyTemplate): string[] {
    return [item.id];
  }

  getItemId(item: CompanyTemplate): string {
    return item.id;
  }
}
