import { Component, Input, OnInit } from '@angular/core';
import { CompanyTemplate, CompanyTemplateErpFields } from '../../models/company-template';
import { CompanyTemplatesService } from '../../services/company-templates.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { GungNotificationService, OptionsListMulti, clearEmpties, gungGetPropertyOfObject } from 'gung-common';
import { Observable, first, forkJoin } from 'rxjs';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { gungComparatorHelper } from '../../utils/gung-utils';
import { CompanyTemplatesConfigService } from '../../services/company-templates-config.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-company-templates-add-edit-modal',
  templateUrl: './company-templates-add-edit-modal.component.html',
  styleUrls: ['./company-templates-add-edit-modal.component.css']
})
export class CompanyTemplatesAddEditModalComponent implements OnInit {
  @Input() template: CompanyTemplate;

  public isCreate: boolean = false;
  public isEdit: boolean = false;
  public userGroups: OptionsListMulti[] = [];

  public form: FormGroup;
  protected mappedExtraFormGroup: FormGroup;

  public companyTemplateErpFields: CompanyTemplateErpFields[] = [];

  public erpFieldsAuxData: { [s: string]: any } = {};

  constructor(
    protected activeModal: NgbActiveModal,
    protected companyTemplatesService: CompanyTemplatesService,
    protected gungFlowService: GungFlowService,
    protected companyTemplatesConfigService: CompanyTemplatesConfigService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    if (!!this.template) {
      this.isEdit = true;
    } else {
      this.isCreate = true;
    }

    forkJoin({
      systemSettings: this.gungFlowService.getSystemGung().pipe(first()),
      erpFields: this.companyTemplatesConfigService.getCompanyTemplateErpFields().pipe(first())
    }).subscribe(({ systemSettings, erpFields }) => {
      this.mapErpFieldsToAngularForms(erpFields);
      this.initForm();
      this.setupUserGroups(systemSettings?.extra?.userGroups || []);
      this.getErpFieldsAuxData(erpFields);
    });
  }

  protected mapErpFieldsToAngularForms(erpFields: CompanyTemplateErpFields[]) {
    this.mappedExtraFormGroup = new FormGroup({});

    for (const field of erpFields) {
      const fieldPath: string[] = field.path.split('.');
      let currentPath: string = '';
      let lastPath: string = '';

      for (const [index, path] of fieldPath.entries()) {
        currentPath += `${path}`;
        
        if (fieldPath.length === 1) {
          this.mappedExtraFormGroup.addControl(path, new FormControl(gungGetPropertyOfObject(field.path, this.template?.extra) || ''));
          break;
        }
        if (index === (fieldPath.length - 1)) {
          (this.mappedExtraFormGroup.get(lastPath) as FormGroup).addControl(path, new FormControl(gungGetPropertyOfObject(field.path, this.template?.extra) || ''));
          break;
        }

        if (!this.mappedExtraFormGroup.get(currentPath)) {
          if (index === 0) {
            this.mappedExtraFormGroup.addControl(path, new FormGroup({}));
          } else {
            (this.mappedExtraFormGroup.get(lastPath) as FormGroup).addControl(path, new FormGroup({}));
          }
        }

        lastPath = currentPath;
        currentPath += `.`;
      }
    }
  }

  protected initForm(): void {
    this.form = new FormGroup({
      name: new FormControl(this.template?.name || ''),
      extra: this.mappedExtraFormGroup,
      allowedUserGroups: new FormArray([])
    });

    // Populate allowedUserGroups form array //
    this.setFormUserGroups(this.template?.allowedUserGroups || []);
  }

  protected setupUserGroups(userGroups: string[]) {
    this.userGroups.push({
      id: '*',
      name: 'ALL',
      selected: false
    });

    for (const group of userGroups) {
      this.userGroups.push({
        id: group,
        name: group,
        selected: false
      });
    }

    const formUserGroups: string[] = this.form.get('allowedUserGroups').getRawValue();
    for (const group of this.userGroups) {
      if (formUserGroups.includes('*')) {
        group.selected = true;
        continue;
      }

      if (formUserGroups.includes(group.id)) {
        group.selected = true;
      }
    }
  }

  protected getErpFieldsAuxData(erpFields: CompanyTemplateErpFields[]): void {
    let dataObservables = undefined;

    for (const field of erpFields) {
      if (field.auxDataName && field.data) {
        if (!dataObservables) {
          dataObservables = {};
        }
        dataObservables[field.auxDataName] = field.data().pipe(first())
      }
    }

    if (dataObservables) {
      forkJoin(dataObservables).subscribe(data => {
        this.erpFieldsAuxData = data;
        this.companyTemplateErpFields = erpFields;
      })
    } else {
      this.companyTemplateErpFields = erpFields;
    } 
  }

  public save(): void {
    let resultTemplate: CompanyTemplate;

    if (this.isEdit) {
      resultTemplate = this.template;
    }

    resultTemplate = {
      ...resultTemplate,
      ...this.form.getRawValue()
    };

    // Clear empty fields from template //
    resultTemplate.extra = clearEmpties(resultTemplate.extra);

    this.companyTemplatesService.saveCompanyTemplate(resultTemplate).pipe(first()).subscribe(result => {
      this.gungNotificationService.notify(
        this.translateService.instant('SUCCESS'),
        this.translateService.instant('SAVED_COMPANY_TEMPLATE_SUCCESS'),
        'success'
      );
      this.activeModal.close();
    })
  }

  public close(): void {
    this.activeModal.dismiss();
  }

  protected setFormUserGroups(selectedGroups: string[]): void {
    const formUserGroups: string[] = this.form.get('allowedUserGroups').getRawValue();
    const missingFromForm: string[] = [];
    const removeFromFormIndex: number[] = [];

    for (const selected of selectedGroups) {
      if (!formUserGroups.includes(selected)) {
        missingFromForm.push(selected);
      }
    }

    for (const [i, value] of formUserGroups.entries()) {
      if (!selectedGroups.includes(value)) {
        removeFromFormIndex.push(i);
      }
    }

    for (const index of removeFromFormIndex.sort((a, b) => gungComparatorHelper(a, b, -1))) {
      (this.form.get('allowedUserGroups') as FormArray).removeAt(index);
    }

    for (const group of missingFromForm) {
      (this.form.get('allowedUserGroups') as FormArray).push(new FormControl(group));
    }
  }

  protected onChangeUserGroupCheckbox(id: string, checked: boolean) : void {
    if (id === '*') {
      for (const group of this.userGroups) {
        group.selected = checked;
      }
    } else {
      for (const group of this.userGroups) {
        if (group.id === id) {
          group.selected = checked;
        }

        if (group.id === '*') {
          group.selected = false;
        }
      }
    }

    let selectedUserGroups: string[] = this.userGroups.filter(g => !!g.selected).map(g => g.id);

    if (id !== '*' && selectedUserGroups.length === this.userGroups.length - 1) {
      for (const group of this.userGroups) {
        if (group.id === '*') {
          group.selected = true;
        }
      }

      selectedUserGroups = this.userGroups.filter(g => !!g.selected).map(g => g.id);
    }

    if (selectedUserGroups.includes('*')) {
      this.setFormUserGroups(['*']);
    } else {
      this.setFormUserGroups(selectedUserGroups);
    }
  }
}
