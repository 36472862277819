import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungSlider } from 'gung-list';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { takeUntil, first } from 'rxjs';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { Product } from '../../../../models/product';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { AssortmentService } from '../../../../services/assortment.service';
import { ProductService } from '../../../../services/products/product.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungNoImageUrl } from 'gung-common';

@Component({
  selector: 'lib-home-slide-product-list-template',
  templateUrl: './home-slide-product-list-template.component.html',
  styleUrls: ['./home-slide-product-list-template.component.css']
})
export class HomeSlideProductListTemplateComponent extends CustomPageTemplateComponent implements OnInit {
  sliderContent: GungSlider[];
  
  firstList: Product[] = [];
  secondList: Product[] = [];
  @ViewChild('dynamicProductList1', { read: ElementRef }) public dynamicProductList1Ref: ElementRef<any>;
  @ViewChild('dynamicProductList2', { read: ElementRef }) public dynamicProductList2Ref: ElementRef<any>;

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected translateService: TranslateService,
    protected productService: ProductService,
    protected customPageConfigService: CustomPageConfigService
  ) {
    super(router, modalService, gungFlowService, assortmentService,translateService, customPageConfigService);
    this.assortmentService
      .getUserAssortment()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(userAssortment => {
        this.userAssortment = userAssortment;
      });
    this.gungFlowService
      .getSelectedFlow()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(currentFlow => {
        this.currentFlowId = currentFlow;
      });
  }

  ngOnInit(): void {
    this.refreshData();
  }

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  refreshData(): void {
    this.sliderContent = this.getSliderDataFromTemplate(this.data, 'sliderTop');
    if (this.data?.elements?.productList1?.text?.text) {
      this.productService.getProductsByAssortment(this.data?.elements?.productList1?.text?.text).pipe(first()).subscribe(products => {
        this.firstList = products;
      });
    }
    
    if (this.data?.elements?.productList2?.text?.text) {
      this.productService.getProductsByAssortment(this.data?.elements?.productList2?.text?.text).pipe(first()).subscribe(products => {
        this.secondList = products;
      });
    }
  }

  goToDetail(product: Product): void {
    if (product?.productType === 'concept') {
      this.router.navigate(['/concept/', product?.id]);
    } else {
      this.router.navigate(['/product/', product?.id]);
    }
  }
}

const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    sliderTop_0: {
      link: {
        enabled: true,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    productList1: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    middleOne: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    middleTwo: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    middleThree: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    productList2: {
      link: {
        enabled: false,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    }
  }
};