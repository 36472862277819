<div>
  <div class="modal-header">
    <div>
      <h4 class="modal-title mt-0" id="modal-basic-title" translate>{{ data?.title }}</h4>
      <span translate>{{ data?.subTitle }}</span>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="delegate.modalRef.get(this.componentId).close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <pre>{{ data?.typeString ? data?.json : (data?.json | json) }}</pre>
  </div>
  <div class="modal-footer">
    <!-- <button type="button" class="btn btn-outline-dark" (click)="delegate.modalRef.get(this.componentId).close('Save click')">Save</button> -->
  </div>
</div>
