<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>ATTRIBUTES_EN</h4>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.EN.WASHINGADVICE"
      [(ngModel)]="pimProduct.i18n.en.washingAdvice"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.EN.MATERIAL"
      [(ngModel)]="pimProduct.i18n.en.material"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.EN.GENDER"
      [(ngModel)]="pimProduct.i18n.en.gender"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.EN.PRODUCTTYPE"
      [(ngModel)]="pimProduct.i18n.en.productType"
    ></lib-gung-text-input-horizontal>
  </div>
  <div class="col-12 col-md-6">
    <h4 translate>ATTRIBUTES_SE</h4>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.SE.WASHINGADVICE"
      [(ngModel)]="pimProduct.i18n.se.washingAdvice"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.SE.MATERIAL"
      [(ngModel)]="pimProduct.i18n.se.material"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.SE.GENDER"
      [(ngModel)]="pimProduct.i18n.se.gender"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="PIM_I18N.SE.PRODUCTTYPE"
      [(ngModel)]="pimProduct.i18n.se.productType"
    ></lib-gung-text-input-horizontal>
  </div>
</div>
