import { Injectable } from '@angular/core';
import { Observable, filter, first } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { DocumentTemplatesService as CommonDocumentTemplatesService } from 'gung-common';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplatesService extends CommonDocumentTemplatesService {
  enableCreateTemplate = true;

  constructor(protected http: HttpClient, protected authService: AuthService) {
    super(http);
  }

  checkIfIsActuator(): Observable<boolean> {
    return this.authService.getRoles().pipe(first(), filter(roles => !!roles), map(roles => roles.includes('ACTUATOR') || roles.includes('ADMIN')));
  }
}
