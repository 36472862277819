<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>SAVE_CART</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group">
      <label for="name" class="control-label" translate>NAME</label>
      <input class="form-control" type="text" name="name" id="name" [(ngModel)]="cartTitle" ngbAutofocus />
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-auto" (click)="saveCart()" translate>SAVE_CART</button>
</div>
