<div class="modal-header">
  <h5 class="modal-title" id="modal-basic-title" translate>IMPORT_EXCEL</h5>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="alert alert-info" role="alert">
    <p translate>PIM_IMPORT_EXCEL_INFO</p>
  </div>

  <div class="form-group">
    <form [formGroup]="uploadForm">
      <div>
        <input
          type="file"
          accept=".xls, .xlsx"
          name="importExcel"
          [placeholder]="'SELECT' | translate"
          (change)="onFileSelect($event)"
        />
      </div>
    </form>
  </div>

  <div class="alert alert-success mt-1" role="alert" *ngIf="success">
    <h5 translate>IMPORT_SUCCESSFUL</h5>
  </div>

  <div class="alert alert-danger mt-1" role="alert" *ngIf="error">
    <h5 translate>IMPORT_FAILED</h5>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-secondary" [disabled]="uploading ? true : null" (click)="close()" translate>CANCEL</button>
  <button class="btn btn-primary" [disabled]="uploading ? true : null" (click)="onSubmit()" translate>
    IMPORT <i *ngIf="uploading" class="fas fa-spinner fa-spin"></i>
  </button>
</div>
