import { Component, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutSingleComponent } from 'gung-list';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'lib-cart-list-layout',
  templateUrl: './cart-list-layout.component.html',
  styleUrls: ['./cart-list-layout.component.css']
})
export class CartListLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  constructor(
    componentFactoryResolver: ComponentFactoryResolver,
    changeDetectorRef: ChangeDetectorRef,
    private cartService: CartService,
    private modalService: NgbModal
  ) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  public removeAll(content): void {
    this.modalService.open(content).result.then(
      closeResult => {
        if (closeResult === 'CLEAR_CART') {
          this.cartService.clearCart();
        }
      },
      dissmissReason => {
        console.log(dissmissReason);
      }
    );
  }
}
