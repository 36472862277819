<div class="container">
  <div class="pagination-list-header">
    <div class="row" [ngClass]="getSearchGroupCss()">
      <div class="col-md col-12">
        <div *ngIf="loadSearch">
          <lib-filter-list-search
            [initSearchTerm]="terms"
            (searchUpdated)="searchButtonEnabled ? (searchString = $event) : setSearch($event)"
            (searchEnter)="searchButtonEnabled ? setSearch(searchString) : undefined"
          ></lib-filter-list-search>
        </div>
      </div>
      <div class="col col-md-3 col-lg-2" *ngIf="batchSizes && batchSizes.length > 1 && loadBatch">
        <lib-filter-list-batch
          [inicializedbatchSize]="limit"
          (batchSizeUpdated)="setLimit($event)"
          [batchSizes]="batchSizes"
        ></lib-filter-list-batch>
      </div>
      <div class="col-auto" *ngIf="searchButtonEnabled">
        <button type="button" class="btn btn-primary" (click)="setSearch(searchString)" translate>
          {{ searchButtonText }}
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div *ngIf="searching" class="progress mt-10">
          <div
            class="progress-bar progress-bar-striped progress-bar-animated bg-primary"
            role="progressbar"
            aria-valuenow="75"
            aria-valuemin="0"
            aria-valuemax="100"
            style="width: 75%"
          ></div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!searching">
      <div class="col d-flex flex-row justify-content-between">
        <button type="button" class="btn btn-primary" (click)="setSkip(-limit)" [disabled]="skip === 0">
          <i class="fas fa-angle-left"></i>
        </button>
        <small> Visar produkter {{ itemsStep }} - {{ itemsStep + limit }} av {{ itemsCount }} </small>
        <button
          type="button"
          class="btn btn-primary"
          (click)="setSkip(limit)"
          [disabled]="skip >= this.itemsCount - this.limit"
        >
          <i class="fas fa-angle-right"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row" [class.d-none]="searching">
    <div class="col mt-4 mb-4">
      <ng-template libCustomHost></ng-template>
      <p *ngIf="!searching && items.length === 0" class="nothing-found">
        <span translate>{{ getNothingFoundTranslateTag() }}</span>
      </p>
    </div>
  </div>
</div>
