import { Component, OnInit, Input } from '@angular/core';
import { first, tap } from 'rxjs';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { Assortment, AssortmentService } from '../../../../services/assortment.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungNoImageUrl } from 'gung-common';

// DEFAULT DATA EXAMPLE USED WHEN NO DATA
const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    topImage: {
      link: {
        enabled: false,
        internal: true,
        url: '/products-search'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: 'PRODUCT_FAST_SEARCH'
      }
    }
  }
};

@Component({
  selector: 'lib-home-fast-search-template',
  templateUrl: './home-fast-search-template.component.html',
  styleUrls: ['./home-fast-search-template.component.css']
})
export class HomeFastSearchTemplateComponent extends CustomPageTemplateComponent implements OnInit {
  assortmentsList: Assortment[];

  constructor(
    protected router: Router,
    protected modalService: NgbModal,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    private authService: AuthService,
    protected translateService: TranslateService,
    protected customPageConfigService: CustomPageConfigService
  ) {
    super(router, modalService, gungFlowService, assortmentService, translateService, customPageConfigService);
  }

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user =>
        this.assortmentService
          .getAssortment(user.assortment)
          .pipe(first())
          .subscribe(assortment => (this.assortmentsList = assortment.children))
      );
  }

  navigateTo(link: { enabled: boolean; internal: boolean; url: string }) {
    if (link && !link.url) {
      return;
    }
    if (link && link.internal) {
      this.router.navigate([link.url]);
      return;
    }
    if (link && !link.internal) {
      window.location.href = link.url;
    }
  }

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }
}
