import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-edi-modal-change-delivery-date',
  templateUrl: './edi-modal-change-delivery-date.component.html',
  styleUrls: ['./edi-modal-change-delivery-date.component.css']
})
export class EdiModalChangeDeliveryDateComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
