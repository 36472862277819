import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssortmentManagementService {
  constructor(private http: HttpClient) {}

  public exportAssortmentExcel(body: FormData): Observable<any> {
    const url = `export/assortment/excel`;
    const httpOptions = {
      headers: new HttpHeaders({
        Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      responseType: 'blob' as 'json'
    };

    return this.http.post<any>(url, body, httpOptions);
  }

  public importAssortmentExcel(body: FormData): Observable<any> {
    const url = `import/assortment/excel`;

    return this.http.post<any>(url, body);
  }
}
