import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationStart, Router, RouterStateSnapshot } from '@angular/router';
import { first, map, switchMap, takeUntil } from 'rxjs';
import { forkJoin, of, Subject } from 'rxjs';
import { Pim, PimConcept, PimProductDimensions } from '../../../models/pim';
import { TranslateService } from '@ngx-translate/core';
import { GungModalService } from './../../../services/gung-modal/gung-modal.service';
import { PimService } from './../../../services/pim/pim.service';
import { Location } from '@angular/common';
import { RoutingUtilService } from '../../../services/utils/routing-util.service';
import { checkObjectEquals } from 'gung-common';

@Component({
  selector: 'lib-pim-product',
  templateUrl: './pim-product.component.html',
  styleUrls: ['./pim-product.component.css']
})
export class PimProductComponent implements OnInit {
  pimProduct: Pim;
  pimProducts: Pim[];
  pimProductVariants: Pim[];
  pimDimensions: PimProductDimensions[];
  pimConcepts: PimConcept[];
  isLoading: boolean;

  inicialProduct: Pim;
  savedUrl: string;
  forceExit = false;

  productSavedSubject: Subject<void> = new Subject<void>();
  public enableShopify = this.pimService.enableShopify;
  public shopifyLanguages = this.pimService.shopifyLanguages;

  unsubscribe: Subject<void> = new Subject();

  constructor(
    protected route: ActivatedRoute,
    protected pimService: PimService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected router: Router,
    protected location: Location,
    protected routingUtilService: RoutingUtilService
  ) {}

  ngOnInit() {
    window.addEventListener('beforeunload', e => {
      if (!this.forceExit && !checkObjectEquals(this.inicialProduct, this.pimProduct, true)) {
        const confirmationMessage = 'o/';
        e.returnValue = confirmationMessage; // Gecko, Trident, Chrome 34+
        return confirmationMessage; // Gecko, WebKit, Chrome <34
      }
    });
    this.route.params
      .pipe(
        map(params => {
          return params.productId as string;
        }),
        switchMap(productId =>
          forkJoin([
            this.pimService.getPimProduct(productId, true),
            this.pimService.getPimProductVariantsByMasterId(productId),
            this.pimService.getPimProducts().pipe(first()),
            this.pimService.getPimProductDimensions(),
            this.pimService.getPimConcepts()
          ])
        )
      )
      .subscribe(([product, variants, products, dimensions, concepts]) => {
        this.pimProduct = product;
        this.pimProductVariants = variants;
        this.pimProducts = products;
        this.pimDimensions = dimensions;
        this.pimConcepts = concepts;
        this.inicialProduct = JSON.parse(JSON.stringify(this.pimProduct));
        this.initPimProduct();
      });

    // this.router.events.pipe(takeUntil(this.unsubscribe)).subscribe(event => {
    //   if (!this.pimProduct || !this.pimProduct.id) {
    //     return;
    //   }
    //   const url = '/pim/' + this.pimProduct.id;
    //   if (event instanceof NavigationStart && event.url !== url) {
    //     this.savedUrl = event.url;
    //     if (!this.forceExit && !checkObjectEquals(this.inicialProduct, this.pimProduct, true)) {
    //       this.router.navigate([url]);
    //       this.exit();
    //       return;
    //     }
    //     window.onpopstate = () => {};
    //   }
    // });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    window.removeEventListener('beforeunload',  e => {});
  }

  onSave() {
    this.isLoading = true;
    this.pimService.postPimProduct(this.pimProduct).subscribe(next => {
      this.productSavedSubject.next();
      this.inicialProduct = JSON.parse(JSON.stringify(this.pimProduct));
      this.isLoading = false;
      this.gungModalService.openConfirmYesNoModal(
        undefined,
        this.translationService.instant('PRODUCT_SAVED'),
        { size: 'sm' },
        'OK',
        null
      );
    });
  }

  onDelete() {
    this.gungModalService
      .openConfirmYesNoModal(
        undefined,
        this.translationService.instant('CONFIRM_DELETION', { value: 'Pim Product ' + this.pimProduct.id }) + '?',
        { size: 'sm' }
      )
      .then(result => {
        if (!result) {
          return;
        }
        this.isLoading = true;
        this.pimService.deletePimProduct(this.pimProduct.id).subscribe(_ => {
          this.isLoading = false;
          this.router.navigate(['/pim']);
        });
      });
  }

  protected initPimProduct() {
    this.pimProduct = {
      ...this.pimProduct,
      extra: {
        ...this.returnObject(this.pimProduct, 'extra'),
        pim: {
          ...this.returnObject(this.pimProduct, 'extra.pim')
        }
      },
      i18n: {
        ...this.returnObject(this.pimProduct, 'i18n'),
        se: {
          ...this.returnObject(this.pimProduct, 'i18n.se')
        },
        en: {
          ...this.returnObject(this.pimProduct, 'i18n.en')
        }
      }
    };
  }

  protected returnObject(obj: any, prop: string) {
    if (obj && obj.hasOwnProperty(prop.split('.')[0])) {
      if (prop.includes('.')) {
        return this.returnObject(obj[prop.split('.')[0]], prop.substring(prop.indexOf('.') + 1));
      }
      return obj[prop];
    }
    return {};
  }

  

  exit(): void {
    this.gungModalService
      .openConfirmYesNoModal(
        undefined,
        this.translationService.instant('You have data to save. Are you sure you want to leave', { value: '' }) + '?',
        { size: 'sm' }
      )
      .then(
        result => {
          this.forceExit = result;
          if (result) {
            window.onpopstate = () => {};
            const pathWithParams = this.routingUtilService.parsePathWithQueryParams(this.savedUrl);
            this.router.navigate([pathWithParams.path], { queryParams: pathWithParams.params });
          }
        },
        reason => {}
      );
  }

  hasUnsavedChanges(currentRoute: ActivatedRouteSnapshot, currentState: RouterStateSnapshot, nextState: RouterStateSnapshot): boolean {
    if (this.forceExit) {
      return false;
    }
    if (!checkObjectEquals(this.inicialProduct, this.pimProduct, true)) {
      this.savedUrl = nextState.url;
      this.exit();
      return true;
    }
    return !checkObjectEquals(this.inicialProduct, this.pimProduct, true);
  }
}
