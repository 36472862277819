import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ListLayoutMultipleComponent, ListLayout, ListSortOption, ConfigBaseFilter, ConfigService } from 'gung-list';
import { Cart } from '../../models/cart';
import { CartsService } from '../carts/carts.service';
import { CreationDateFilterService } from '../../filters/creation-date-filter/creation-date-filter.service';
import { SavedQuoteTableComponent } from '../../components/saved-quote-table/saved-quote-table.component';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SavedQuoteConfigService implements ConfigService<Cart> {
  constructor(protected cartsService: CartsService, protected creationDateFilterService: CreationDateFilterService) {}

  getItems(): Observable<Cart[]> {
    return this.cartsService.getSavedQuotesFromSubject().pipe(
      tap(results => {
        results.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
      })
    );
  }

  getFilters(): ConfigBaseFilter<Cart>[] {
    return [this.creationDateFilterService];
  }

  getSortOptions(): ListSortOption<Cart>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Cart>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SavedQuoteTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'SavedQuoteGrid'
      }
    ];
  }

  getSearchTerms(item: Cart): string[] {
    const searchTerms = [];

    if (item.name) {
      searchTerms.push(item.name);
    }

    return searchTerms;
  }

  getItemId(item: Cart): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
