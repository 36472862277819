import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  Type,
  ViewChildren,
  Optional,
  EventEmitter
} from '@angular/core';
import { ActivatedRoute, ParamMap, Router, UrlSerializer } from '@angular/router';
import { BehaviorSubject, forkJoin, Observable, of, ReplaySubject, Subject, interval } from 'rxjs';
import { first, mergeMap, switchMap, debounce, filter, takeUntil } from 'rxjs';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import {
  BackendFilterIndex,
  BackendFilterListConfigService,
  BackendFilterRequest,
  BackendFilterResponse,
  ListItemRendererComponent,
  ListLayout,
  ListLayoutComponent,
  RenderFilter,
  SelectionAction
} from './../types';
import { AbstractSelectionService } from '../services/selections/abstract-selection-service';

@Component({
  selector: 'lib-backend-filter-list',
  templateUrl: './backend-filter-list.component.html',
  styleUrls: ['./backend-filter-list.component.css']
})
export class BackendFilterListComponent<T> implements OnInit, OnChanges, OnDestroy, AfterViewInit {
  @Input()
  public configService: BackendFilterListConfigService<T>;

  @ViewChildren(CustomHostDirective)
  private viewChild: QueryList<CustomHostDirective>;

  protected currentParamMap: ParamMap;

  public finishedLoading = false;
  protected unsubscribe: Subject<void> = new Subject<void>();

  public backendFilterIndex: BackendFilterIndex;
  public renderFilters: RenderFilter[];

  protected backendFilterRequestSubject: BehaviorSubject<BackendFilterRequest>;
  protected backendFilterRequest: Observable<BackendFilterRequest>;

  protected backendFilterResponseSubject: BehaviorSubject<BackendFilterResponse<T>>;
  protected backendFilterResponse: Observable<BackendFilterResponse<T>>;

  protected viewInitializedSubject: ReplaySubject<void> = new ReplaySubject<void>();

  public containerStyles: { [styleName: string]: any } = {};
  public filterWidth: number;
  public searchFilterWidth: number;

  public filterChange = new EventEmitter<{ optionId: string; filterName: string; selected: boolean }>();

  public limit = 50;
  public searchString: string;
  public allIds: string[];
  public filteredIds: string[];
  public selectionActions: SelectionAction<string>[];

  public currentSkip = 1;
  public currentLimit = this.limit;

  public initialFreeTextTerms: {
    [key: string]: string;
  } = {};

  public selectedFilterOptions: {
    filterName: string;
    optionId: string;
  }[] = [];

  protected excludedBySearch: string[] = [];

  // Layouts
  public layouts: ListLayout<T>[];

  public renderItems: T[];

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected changeDetectorRef: ChangeDetectorRef,
    private location: Location,
    private serializer: UrlSerializer,
    @Optional() protected selectionService?: AbstractSelectionService<T>
  ) {}

  ngOnInit() {
    forkJoin([
      this.configService.getBackendFilterIndex(this.route.snapshot).pipe(first()),
      this.configService.getBackendFilters(this.route.snapshot).pipe(first()),
      this.configService.getSelectionActions
        ? this.configService.getSelectionActions(this.route.snapshot).pipe(first())
        : of([]),
      this.route.queryParamMap.pipe(first())
    ]).subscribe(([filterIndex, backendFilters, selectionActions, queryParamMap]) => {
      this.backendFilterIndex = filterIndex;

      this.allIds = this.backendFilterIndex.includedIds;
      this.filteredIds = this.backendFilterIndex.includedIds;

      if (selectionActions && selectionActions.length > 0 && this.selectionService) {
        this.selectionActions = selectionActions;
        this.selectionService.setSelectionsEnabled(true);
      }

      this.limit = this.configService.getLimit() || this.limit;

      this.renderFilters = backendFilters.map(backendFilter => {
        const renderFilter: RenderFilter = {
          type: 'SimpleConfigBaseFilter',
          name: backendFilter.filterName,
          active: true,
          excludedIds: [],
          isPostFilter: true,
          valueList: backendFilter.options?.map(option => ({
            hide: option.includedIds.length === 0,
            itemCountAfterFilter: option.includedIds.length,
            itemCountTotal: option.includedIds.length,
            itemsIncluded: option.includedIds,
            selected: false,
            valueId: option.optionName,
            valueName: option.optionName
          }))
        };

        return renderFilter;
      });

      this.setInitialFilters(queryParamMap);
      const freeTextSearchTerms = this.getInitialSearchTerms(queryParamMap, this.backendFilterIndex);

      const backendFilterRequest: BackendFilterRequest = {
        backendFilterIndexId: filterIndex.backendFilterIndexId,
        filteredIds: filterIndex.includedIds,
        freeTextSearchTerms,
        limit: this.configService.getLimit(),
        skip: 0,
        sort: ' '
      };

      const filterWidth = 100 / (this.renderFilters.length || 1);
      if (filterWidth > 50) {
        this.filterWidth = 50;
      } else if (filterWidth < 25) {
        this.filterWidth = 25;
      } else {
        this.filterWidth = filterWidth;
      }

      const searchFilterWidth = 100 / (this.backendFilterIndex.freeTextFilters.length || 1);
      if (searchFilterWidth < 25) {
        this.searchFilterWidth = 25;
      } else {
        this.searchFilterWidth = searchFilterWidth;
      }

      this.finishedLoading = true;
      this.backendFilterRequestSubject = new BehaviorSubject<BackendFilterRequest>(backendFilterRequest);
      this.backendFilterRequest = this.backendFilterRequestSubject.asObservable();

      this.backendFilterResponse = this.backendFilterRequest.pipe(
        takeUntil(this.unsubscribe),
        switchMap(request => this.configService.getItems(request, this.route.snapshot))
      );

      this.backendFilterResponse
        .pipe(
          takeUntil(this.unsubscribe),
          mergeMap(response =>
            forkJoin([
              of(response).pipe(takeUntil(this.unsubscribe)),
              this.configService.getLayouts(this.route.snapshot).pipe(first()),
              this.configService.getSelectedLayout ? this.configService.getSelectedLayout().pipe(first()) : of(0)
            ])
          )
        )
        .subscribe(([response, layouts, selectedLayout]) => {
          this.updateQueryParams(response);

          this.renderItems = response.items;
          this.layouts = layouts;
          const layout = layouts[selectedLayout];
          this.filteredIds = response.filteredIds;
          this.excludedBySearch = response.excludedIdsBySearch;
          this.updatePostFilter();

          this.currentLimit = Math.min(response.skip + response.limit, response.filteredItemCount);
          this.currentSkip = response.skip + 1;

          this.renderLayout(layout.getListLayoutComponent(), layout.getListItemComponent(), response.items);
        });
      const initialFilteredItems = this.filter(null, null, null, true);
      this.makeBackendFilterRequest(
        filterIndex.backendFilterIndexId,
        initialFilteredItems,
        backendFilterRequest.freeTextSearchTerms,
        0,
        this.configService.getLimit(),
        ' '
      );
    });
  }

  async updateQueryParams(backendFilterResponse: BackendFilterResponse<T>) {
    const res = {};
    Object.keys(backendFilterResponse.freeTextSearchTerms).forEach(key => {
      const terms = backendFilterResponse.freeTextSearchTerms[key];
      if (terms.length > 0) {
        if (terms.length === 1 && terms[0] === '') {
          res['s_' + key] = null;
        } else {
          res['s_' + key] = terms.join(' ');
        }
      }
    });

    this.renderFilters.forEach(renderFilter => {
      const filterValues = renderFilter.valueList.filter(value => value.selected).map(value => value.valueId);
      if (filterValues.length > 0) {
        res['f_' + renderFilter.name] = filterValues;
      }
    });

    const oldQueryParams = await this.route.queryParamMap.pipe(first()).toPromise();
    const oldParamNulls = {};
    oldQueryParams.keys.forEach(key => (oldParamNulls[key] = null));

    const urlString = this.route.snapshot.url.join('/');
    const tree = this.router.createUrlTree([], {
      relativeTo: this.route,
      queryParams: {
        ...oldParamNulls,
        ...res
      },
      queryParamsHandling: 'merge'
    });

    const serialized = this.serializer.serialize(tree);
    this.location.replaceState(serialized);
  }

  ngAfterViewInit(): void {
    this.viewInitializedSubject.next();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // throw new Error("Method not implemented.");
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }

  renderLayout(
    layoutComponent: Type<ListLayoutComponent<T>>,
    itemComponent: Type<ListItemRendererComponent<T | T[]>>,
    items: T[]
  ): void {
    this.viewInitializedSubject
      .asObservable()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(_ => {
        if (!this.viewChild || this.viewChild.length === 0) {
          return;
        }
        this.changeDetectorRef.detectChanges();
        const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
        const containerRef = this.viewChild.last.viewContainerRef;
        containerRef.clear();
        const componentRef = containerRef.createComponent(factory);
        const typedComponent = componentRef.instance as ListLayoutComponent<T>;
        typedComponent.listItemRenderer = itemComponent;
        typedComponent.renderItems = of(items);
        this.changeDetectorRef.detectChanges();
      });
  }

  async makeBackendFilterRequest(
    backendFilterIndexId?: string,
    filteredIds?: string[],
    freeTextSearchTerms?: { [filterName: string]: string[] },
    skip?: number,
    limit?: number,
    sort?: string
  ) {
    let oldRequest: BackendFilterRequest;
    if (
      !backendFilterIndexId ||
      !filteredIds ||
      !freeTextSearchTerms ||
      skip === undefined ||
      limit === undefined ||
      sort === undefined
    ) {
      oldRequest = await this.backendFilterRequest.pipe(first()).toPromise();
    }

    const prevSearch = oldRequest ? oldRequest.freeTextSearchTerms : {};
    const updatedSearchTerm = freeTextSearchTerms ? freeTextSearchTerms : {};
    const mergedFreeTextSearchTerms = {
      ...prevSearch,
      ...updatedSearchTerm
    };

    const request: BackendFilterRequest = {
      backendFilterIndexId: backendFilterIndexId || oldRequest.backendFilterIndexId,
      filteredIds: filteredIds || oldRequest.filteredIds,
      freeTextSearchTerms: mergedFreeTextSearchTerms,
      limit: limit || oldRequest.limit,
      skip: skip !== undefined ? skip : oldRequest.skip,
      sort: sort || oldRequest.sort
    };

    if ((filteredIds !== undefined || freeTextSearchTerms !== undefined) && skip === undefined) {
      request.skip = 0;
    }

    this.backendFilterRequestSubject.next(request);
  }

  toggleChip(filterName: string, optionId: string, selected: boolean) {
    this.filterChange.emit({
      filterName,
      optionId,
      selected
    });

    this.filter(filterName, optionId, selected);
  }

  toggleAllChips() {
    this.renderFilters.forEach(renderFilter => {
      renderFilter.valueList.forEach(value => {
        value.selected = false;
      });
    });

    this.selectedFilterOptions.forEach(option => {
      this.filterChange.emit({
        filterName: option.filterName,
        optionId: option.optionId,
        selected: false
      });
    });

    this.selectedFilterOptions = [];

    this.filter(null, null, null);
  }

  filter(filterName: string, optionId: string, selected: boolean, preventRequest?: boolean): string[] {
    if (selected) {
      this.selectedFilterOptions.push({
        filterName,
        optionId
      });
    } else {
      const selectedFilterIndex = this.selectedFilterOptions.findIndex(
        val => val.filterName === filterName && val.optionId === optionId
      );
      if (selectedFilterIndex > -1) {
        this.selectedFilterOptions.splice(selectedFilterIndex, 1);
      }
    }
    this.renderFilters
      // all filters that exists
      .filter(renderFilter => renderFilter.name === filterName)
      .forEach(renderFilter =>
        renderFilter.valueList
          // all values that are selected
          .filter(value => value.valueId === optionId)
          .forEach(v => (v.selected = selected))
      );

    const filtersWithSelection = this.renderFilters.filter(renderFilter => {
      const optionWithSelection = renderFilter.valueList.find(value => value.selected);
      return !!optionWithSelection;
    });

    let filteredItems = this.backendFilterIndex.includedIds;

    filtersWithSelection.forEach(renderFilter => {
      const includedIds = renderFilter.valueList
        .filter(value => value.selected)
        .map(value => value.itemsIncluded)
        .reduce((accumulatedIds, currentValueIds) => accumulatedIds.concat(currentValueIds), [])
        .reduce((accumulatedSet, currentIds) => accumulatedSet.add(currentIds), new Set());
      // items are filtered iteratively
      filteredItems = filteredItems.filter(item => {
        const itemId = item;
        if (!itemId) {
          throw new Error('Item ID could not be determined, please check the config service. ');
        }
        return includedIds.has(itemId);
      });

      renderFilter.excludedIds = this.backendFilterIndex.includedIds.filter(id => !includedIds.has(id));
    });

    if (!preventRequest) {
      this.makeBackendFilterRequest(null, filteredItems);
    }
    return filteredItems;
  }

  protected updatePostFilter() {
    this.renderFilters.forEach(renderFilter => {
      if (!renderFilter.isPostFilter) {
        return;
      }

      let excludedByOthers = this.excludedBySearch;

      const filtersWithSelection = this.renderFilters.filter(rFilter => {
        const optionWithSelection = rFilter.valueList.find(value => value.selected);
        return !!optionWithSelection;
      });

      filtersWithSelection.forEach(internalFilter => {
        if (internalFilter === renderFilter) {
          return;
        }
        excludedByOthers = excludedByOthers.concat(internalFilter.excludedIds);
      });

      const excludedByOthersSet = new Set(excludedByOthers);
      renderFilter.valueList.forEach(value => {
        const tmp = value.itemsIncluded.filter(includedItem => !excludedByOthersSet.has(includedItem));
        value.itemCountAfterFilter = tmp.length;
        value.hide = !value.selected && tmp.length === 0;
      });
    });
  }

  next() {
    this.backendFilterResponse.pipe(first()).subscribe(response => {
      const maxSkip = response.filteredIds.length - response.limit;
      const desiredSkip = response.skip + response.limit;

      const skip = Math.min(desiredSkip, maxSkip);

      this.makeBackendFilterRequest(
        response.backendFilterIndexId,
        response.filteredIds,
        response.freeTextSearchTerms,
        skip,
        response.limit,
        response.sort
      );
    });
  }

  previous() {
    this.backendFilterResponse.pipe(first()).subscribe(response => {
      const desiredSkip = response.skip - response.limit;
      const skip = Math.max(desiredSkip, 0);

      this.makeBackendFilterRequest(
        response.backendFilterIndexId,
        response.filteredIds,
        response.freeTextSearchTerms,
        skip,
        response.limit,
        response.sort
      );
    });
  }

  search(searchValue: string, filterName: string) {
    const search = {};
    search[filterName] = searchValue.split(' ');
    this.makeBackendFilterRequest(null, null, search);
  }

  adjustBottomMargin(margin: number) {
    this.containerStyles['margin-bottom'] = margin + 10 + 'px';
  }

  getInitialSearchTerms(queryParamMap: ParamMap, backendFilterIndex: BackendFilterIndex): { [key: string]: string[] } {
    const freeTextFilterKeys = queryParamMap.keys.filter(key => key.startsWith('s_'));
    const res = {};

    freeTextFilterKeys.forEach(key => {
      const filterName = key.substring(2);
      if (backendFilterIndex.freeTextFilters.indexOf(filterName) >= 0) {
        res[filterName] = queryParamMap.get(key).split(' ');
        this.initialFreeTextTerms[filterName] = queryParamMap.get(key);
      }
    });

    return res;
  }

  setInitialFilters(queryParamMap: ParamMap) {
    const filterKeys = queryParamMap.keys.filter(key => key.startsWith('f_'));

    filterKeys.forEach(key => {
      const filterName = key.substring(2);
      const renderFilter = this.renderFilters.find(renderF => renderF.name === filterName);
      if (!renderFilter) {
        return;
      }
      const values = queryParamMap.getAll(key);
      values.forEach(value => {
        const option = renderFilter.valueList.find(val => val.valueId === value);

        if (option) {
          option.selected = true;
          this.filter(filterName, option.valueId, true, true);
        }
      });
    });
  }

  changeLayout(index: number) {
    if (this.configService.setSelectedLayout) {
      this.configService.setSelectedLayout(index);
    }
    
    this.renderLayout(this.layouts[index].getListLayoutComponent(), this.layouts[index].getListItemComponent(), this.renderItems);
  }
}
