import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  Input,
  OnInit,
  QueryList,
  Type,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';

@Component({
  selector: 'lib-product-detail-wrapper',
  templateUrl: './product-detail-wrapper.component.html',
  styleUrls: ['./product-detail-wrapper.component.css']
})
export class ProductDetailWrapperComponent implements OnInit, AfterViewInit {
  @ViewChild(CustomHostDirective)
  viewChild: CustomHostDirective;

  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver
  ) {}

  ngOnInit(): void {}

  renderLayout(layoutComponent: Type<any>, data: any = {}): void {
    if (!this.viewChild) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as any;
    typedComponent.id = '';
    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.viewChild) {
      const containerRef = this.viewChild.viewContainerRef;
      containerRef.clear();
    }
    this.renderLayout(this.baseViewConfigService.getProductDetailComponent());
  }
}
