<div class="card product-card">
  <div class="card-body">
    <div class="image-wrapper" [routerLink]="['/product/', data.id]">
      <img
        *ngIf="data.extra.images && data.extra.images[0] as image; else noimage"
        class="img-fluid"
        [src]="data.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.extra.images[0].s3ETag
        "
        alt=""
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
    </div>
    <div class="product-text pb-1">
      <div>{{ data.id }}</div>
      <div class="text-truncate" translate>{{ data.name }}</div>
      <div class="product-card-button">
        <lib-item-selection-button [item]="data"></lib-item-selection-button>
      </div>
    </div>
  </div>
</div>
