import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungSelectOptionInputComponent } from '../gung-select-option-input/gung-select-option-input.component';

@Component({
  selector: 'lib-gung-select-option-input-horizontal',
  templateUrl: './gung-select-option-input-horizontal.component.html',
  styleUrls: ['./gung-select-option-input-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungSelectOptionInputHorizontalComponent),
      multi: true
    }
  ]
})
export class GungSelectOptionInputHorizontalComponent extends GungSelectOptionInputComponent {
  @Input()
  public labelClass: string | string[] = 'col-12 col-sm-4';

  constructor() {
    super();
  }
}
