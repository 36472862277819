import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';
import { LocationConfigService } from '../../services/location-config/location-config.service';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-open-order-table',
  templateUrl: './open-order-table.component.html',
  styleUrls: ['./open-order-table.component.css']
})
export class OpenOrderTableComponent extends ListItemRendererComponent<Order[]> implements OnInit, AfterViewInit {
  adjustedScroll = false;
  protected unsubscribe: Subject<void> = new Subject();

  constructor(
    protected locationConfigService: LocationConfigService,
    protected router: Router,
    public dateUtilService: DateUtilService
  ) {
    super();
  }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  goToDetail(order: Order): void {
    const url = `/orders/${order.id}`;
    this.router.navigate([url]);
  }

  
}
