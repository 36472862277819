import { Component, EventEmitter, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FilterListSideFilterComponent } from '../filter-list-side-filter/filter-list-side-filter.component';

@Component({
  selector: 'lib-filter-list-top-filter',
  templateUrl: './filter-list-top-filter.component.html',
  styleUrls: ['./filter-list-top-filter.component.css']
})
export class FilterListTopFilterComponent extends FilterListSideFilterComponent implements OnInit, OnChanges {
  dropdownIsOpen = false;

  constructor() {
    super();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
  
  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);
  }

  handleDropdownOpen(isOpen: boolean) {
    this.dropdownIsOpen = isOpen;
  }
}
