<div class="form-group">
  <div class="d-flex justify-content-between align-items-center">
    <label
      [for]="inputId"
      *ngIf="!suppressLabel"
      class="col-form-label"
    >
      {{ translateTag | translate: translateParams }}
      <i *ngIf="!!tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
    </label>


    <div >
      <button
        class="btn btn-default"
        style="font-size: 20px;"
        [disabled]="error"
        (click)="format()"
      >
        <i ngbTooltip="{{'REFORMAT_JSON' | translate}}" class="fas fa-indent"></i>
      </button>


      <button
        class="btn btn-default"
        style="font-size: 20px;"
        [disabled]="error"
        (click)="save()"
        >
        <i ngbTooltip="{{'SAVE' | translate}}" class="fas fa-save"></i>
      </button>
    </div>
  </div>
  <div>
    <textarea
      class="form-control"
      type="text"
      rows="20"
      (ngModelChange)="getValidatedJSON($event)"
      [(ngModel)]="value"
    ></textarea>
    <div *ngIf="error">
      <p translate>{{ statusMessage }}</p>
    </div>
    <br />

  </div>
</div>
