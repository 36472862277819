import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { Availability, AvailabilityTransactions } from '../../models/availability';
import { GungFlow } from '../../state/flow/types';
import { AuthService } from '../auth/auth.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungStringConverterService } from '../gung-string-converter.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {
  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService
  ) {}

  protected toGung = id => GungStringConverterService.toGungString(id);

  protected fromGung = id => GungStringConverterService.fromGungString(id);

  public getAvailability(
    productId: string,
    stockIdInput?: string,
    nocache?: boolean,
    targetMaxAge?: number
  ): Observable<Availability> {
    return forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      first(),
      switchMap(([customer, flow]) => {
        const mappedId = this.toGung(productId);
        let urlBase = '';
        const headers: { [s: string]: string | string[] } = {};
        if (nocache) {
          urlBase = `json/availability-nocache/${mappedId}`;
          headers.maxAge = '-1';
        } else {
          urlBase = `json/availability/${mappedId}`;
          headers.maxAge = '300';
        }
        if (targetMaxAge) {
          headers.maxAge = targetMaxAge.toString();
        }


        const stockObs = this.getStockObservable(stockIdInput, flow);
        return stockObs.pipe(
          switchMap(stock =>
            this.http.get<Availability>(this.withStockId(urlBase, stock) + '&customerId=' + customer.id, { headers })
          ),
          map(av => ({ ...av, productId: this.fromGung(av.productId) }))
        );
      })
    );
  }

  protected getStockObservable(stockIdInput: string | undefined, flow: GungFlow):  Observable<string> {
    return stockIdInput ? of(stockIdInput) : this.getUserStockId();
  }

  protected withStockId(url: string, stockId: string): string {
    return url + `?stock=${stockId}`;
  }

  protected getUserStockId(): Observable<string> {
    return this.authService.getCurrentUser().pipe(
      first(),
      map(user => user.managedMultistockIds[0])
    );
  }
  public getAvailabilities(
    productIds: string[],
    stockIdInput?: string,
    nocache?: boolean,
    targetMaxAge?: number
  ): Observable<Availability[]> {
    return forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      first(),
      switchMap(([customer, flow]) => {
        const url = `json/availability-list${nocache ? '-nocache' : ''}`;
        const headers = {
          maxAge: nocache ? '-1' : targetMaxAge ? targetMaxAge.toString() : '300'
        };
        const stockObs = this.getStockObservable(stockIdInput, flow);

        const mappedIds = productIds.map(this.toGung);
        return stockObs.pipe(
          map(stock => this.withStockId(url, stock)),
          switchMap(uri => {
            // Customer id is used in some cases to modify the return availabities
            const uri2 = uri + '&customerId=' + customer.id;
            return this.http.post<Availability[]>(uri2, productIds, { headers });
          }),
          map(avs => avs.map(av => ({ ...av, productId: this.fromGung(av.productId) })))
        );
      })
    );
  }

  getAvailabilityTransactions(productId: string, stockId: string): Observable<AvailabilityTransactions[]> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(customer => {
        const url = `json/availability-transactions/by-product-id-and-stock-id?productId=${productId}&stockId=${stockId}&customerId=${customer.id}`;
        return this.http.get<AvailabilityTransactions[]>(url);
      })
    );
  }
}
