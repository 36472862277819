import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../models/pim';

@Component({
  selector: 'lib-pim-product-attributes',
  templateUrl: './pim-product-attributes.component.html',
  styleUrls: ['./pim-product-attributes.component.css']
})
export class PimProductAttributesComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  constructor() {}

  ngOnInit() {}
}
