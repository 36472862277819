<div class="modal-header">
  <h4 class="modal-title" translate>UPLOAD</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    [disabled]="spinner ? true : null"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <div class="input-group mb-3">
        <div class="custom-file">
          <input
            #inputFile
            type="file"
            class="_custom-file-input"
            id="inputGroupFile01"
            aria-describedby="inputGroupFileAddon01"
            (change)="onFileSelect($event)"
            [disabled]="spinner ? true : null"
            [multiple]="multiple ? true : false"
            [accept]="accept ? accept : null"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row" [class.d-none]="hideDescription">
    <div class="col">
      <lib-gung-text-input
        #inputDescription
        gungTranslate="{{ 'DESCRIPTION' }}"
        placeholder="{{ 'DESCRIPTION' | translate }}"
      ></lib-gung-text-input>
    </div>
  </div>
  <div class="row pt-3" [class.d-none]="hideInMenu">
    <label for="inMenu" class="col-form-label col-12 col-sm-4 font-weight-bold" translate>VISIBLE_IN_MENU</label>
    <div class="col d-flex align-items-center">
      <input type="checkbox" #inMenu id="inMenu" name="inMenu" [disabled]="spinner ? true : null" />
    </div>
  </div>

  <lib-gung-input-multi-select
    label="GROUPS"
    *ngIf="enableDocumentUserGroups && settingsGroups && settingsGroups.length >= 0"
    [optionsList]="getUserGroups()"
    (selectedValues)="setSelectedValues($event)"
  >
  </lib-gung-input-multi-select>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
    [disabled]="spinner ? true : null"
    translate
  >
    CLOSE
  </button>
  <button
    type="button"
    class="btn btn-primary"
    (click)="onUpload(inputFile, inputDescription, inMenu)"
    [disabled]="spinner ? true : null"
    translate
  >
    UPLOAD <i *ngIf="spinner" class="fa fa-spinner fa-spin fa-lg"></i>
  </button>
</div>
