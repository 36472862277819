import { Component } from '@angular/core';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { GungNoImageUrl } from 'gung-common';

@Component({
  selector: 'lib-home-images-and-products-template',
  templateUrl: './home-images-and-products-template.component.html',
  styleUrls: ['./home-images-and-products-template.component.css']
})
export class HomeImagesAndProductsTemplateComponent extends CustomPageTemplateComponent {
  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  public getTextNoTranslate(text: { enabled: boolean; text: string; i18n?: {} }, textField = 'title') {
    if (text && !text.enabled) {
      return null;
    }
    if (text && text.text) {
      return text.text;
    }
    if (this.edit) {
      return 'INSERT_TEXT_HERE';
    }
    return '';
  }

  public isTextFilled(text: { enabled: boolean; text: string; i18n?: {} }, textField = 'title') {
    if (text && !text.enabled) {
      return false;
    }
    if (text && (text.text || text?.i18n?.[this.currentLang]?.[textField])) {
      return true;
    }
    return false;
  }
}

const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    section1Image: {
      link: {
        enabled: false,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 400x400
      },
      text: {
        enabled: false,
        text: ''
      }
    },
    section1ImageText1: {
      link: {
        enabled: false,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section1ImageText2: {
      link: {
        enabled: false,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section1ImageText3: {
      link: {
        enabled: false,
        internal: undefined,
        url: ''
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section1Title: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section1Products: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'product ids'
      }
    },
    section1ProductsSize: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'size'
      }
    },
    section2Title: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section2Products: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'product ids'
      }
    },
    section2ProductsSize: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'size'
      }
    },
    section3Title: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section3Products: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'product ids'
      }
    },
    section3ProductsSize: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: 'size'
      }
    },
    section4Title: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile1: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 400x400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile1Text: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile2: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 400x400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile2Text: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile3: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 400x400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile3Text: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile4: {
      link: {
        enabled: true,
        internal: true,
        url: ''
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 400x400
      },
      text: {
        enabled: true,
        text: ''
      }
    },
    section4Tile4Text: {
      link: {
        enabled: false,
        internal: undefined,
        url: undefined
      },
      image: {
        enabled: false,
        sourceUrl: undefined
      },
      text: {
        enabled: true,
        text: ''
      }
    }
  }
};
