<div class="table-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>ID</th>
        <th translate>NAME</th>
        <th style="min-width: 50px"></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of mappedData">
        <td *ngIf="item.replacementId == null">
          <a [routerLink]="['/product/', item.id]" class="text-dark">
            {{ item.id }}
          </a>
        </td>
        <td *ngIf="item.replacementId != null">
          <span [ngbPopover]="popContent2" triggers="mouseenter" [autoClose]="'outside'">
            <a [routerLink]="['/product/', item.id]" class="text-red">
              {{ item.id }} <i class="fas fa-exclamation-triangle"></i>
            </a>
          </span>
          <ng-template #popContent2>
            <div class="row">
              <div class="col text-center">
                <span translate>PRODUCT_REPLACED_BY</span> <br />
                <a [routerLink]="['/product/', item.id]" class="text-dark">
                  {{ item.replacementId }}
                </a>
              </div>
            </div>
          </ng-template>
        </td>
        <td>
          <a [routerLink]="['/product/', item.id]" class="text-dark">
            {{ item.name }}
          </a>
        </td>
        <td style="width: 50px">
          <lib-item-selection-button [item]="item"></lib-item-selection-button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
