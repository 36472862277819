import { Injectable } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { Observable, ReplaySubject, Subject, filter, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GungListRouterEventsService {
  private router: Router;
  private routerEventsNavigationStart: Subject<NavigationStart>;

  constructor() {}

  initRouterEvents() {
    this.routerEventsNavigationStart = new ReplaySubject<NavigationStart>(1); // cached 1 value
    this.router.events.pipe(
      filter((event) => {
        return event instanceof NavigationStart;
      })
    ).subscribe((event: NavigationStart) => {
      this.routerEventsNavigationStart.next(event);
    });
  }

  setRouter(router: Router) {
    if (router) {
      this.router = router;
      this.initRouterEvents();
    }
  }

  getRouterNavigationStart(): Observable<NavigationStart> {
    if (this.routerEventsNavigationStart) {
      return this.routerEventsNavigationStart.asObservable();
    }
    return of(undefined);
  }
}
