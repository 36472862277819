<form [formGroup]="form" (submit)="onCreate(id.value, inputFile)">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>MAP_NEW_COVER_SHEET</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12 col-md-5">
        <div class="form-group">
          <label class="" for="CATEGORY_VALUE" translate>CATEGORY_VALUE</label>
          <input
            formControlName="category"
            #id
            class="form-control"
            type="text"
            id="CATEGORY_VALUE"
            name="CATEGORY_VALUE"
          />
        </div>
        <div class="form-group">
          <label class="" for="SEASON" translate>SEASON</label>

          <div class="radio" *ngFor="let season of seasonList">
            <label>
              <input class="mr-1" type="radio" [value]="season" formControlName="season" name="season" />
              <span translate>{{ season | uppercase }}</span>
            </label>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-7">
        <div class="form-group">
          <label class="w-100" for="file-upload" translate>CATEGORY_IMAGE_UPLOAD</label>
          <input
            #inputFile
            type="file"
            class="_custom-file-input"
            id="file-upload"
            aria-describedby="file-upload"
            [multiple]=true
            [disabled]="!form.valid || isSubmitted ? true : null"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-light mr-auto"
      aria-label="Close"
      (click)="activeModal.close()"
      [disabled]="isSubmitted ? true : null"
      translate
    >
      CANCEL
    </button>
    <button type="submit" class="btn btn-primary ml-auto" [disabled]="isSubmitted" translate>CREATE</button>
  </div>
</form>
