import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first, forkJoin, from, map, Observable, of, switchMap } from 'rxjs';
import { ActivityInternal } from '../activity-internal/activity-internal.service';
import { AuthService } from '../auth/auth.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

export enum ExportActivityInternalSelectionDataType {
  Xlsx
}

@Injectable({
  providedIn: 'root'
})
export class ActivityInternalExportService {
  currentLang = this.translateService.currentLang || 'se';

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService
  ) {}

  exportExcel(
    itemList: ActivityInternal[],
    template?: string,
    additionalVariables?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.downloadFile(
      'generate-product-excel-from-products-ids',
      itemList,
      ExportActivityInternalSelectionDataType.Xlsx,
      template,
      additionalVariables
    );
  }

  downloadFile(
    serviceUrl: string,
    itemList: ActivityInternal[],
    type: ExportActivityInternalSelectionDataType,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ status: boolean; message: string }> {
    return this.buildExportRequestFromModal(type, itemList, template, additionalVariables, additionalParams).pipe(
      first(),
      switchMap((request: { isValid: boolean; body: any }) => {
        if (request.isValid) {
          return this.exportProductsServiceCall(serviceUrl, request.body);
        } else {
          return of({
            status: false,
            message: 'An error has occurred'
          });
        }
      })
    );
  }

  buildExportRequestFromModal(
    exportSelectionDataType: ExportActivityInternalSelectionDataType,
    itemList: ActivityInternal[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ isValid: boolean; body: any }> {
    // build request body
    return this.getExportRequestBodyByType(
      exportSelectionDataType,
      itemList,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      switchMap((data: any) => {
        return forkJoin({
          data: of(data),
          modalOutput: from(this.gungModalService.openConfirmExportModal(data.recipient)).pipe(first())
        });
      }),
      switchMap(({ data, modalOutput }) => {
        let isValid = false;

        if (modalOutput && modalOutput.email) {
          isValid = true;
          data.recipient = modalOutput.email;
        }

        return of({ isValid, body: data });
      })
    );
  }

  exportProductsServiceCall(serviceUrl: string, body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/' + serviceUrl, body).pipe(
      first(),
      map(resp => {
        return {
          status: !resp || resp.status === 'OK',
          message: resp && resp.status
        };
      })
    );
  }

  getExportRequestBodyByType(
    exportSelectionDataType: ExportActivityInternalSelectionDataType,
    itemList: ActivityInternal[],
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<any> {
    return forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      map(([user, customer, flow]) => {
        // this parameters are shared along all the requests
        let requestBody: any = {
          ids: itemList.map(x => x.id),
          recipient: user.email,
          customerId: customer.id,
          flowId: flow.id,
          ...additionalVariables
        };

        if (!!template && [ExportActivityInternalSelectionDataType.Xlsx].includes(exportSelectionDataType)) {
          requestBody.definitionId = template;
        }

        return requestBody;
      })
    );
  }
}
