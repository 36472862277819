import { Observable, of } from 'rxjs';

export abstract class PdfProductExportService {
  abstract exportExcel(itemList): Observable<any>;

  abstract exportImg(itemList): Observable<any>;

  abstract exportCsv(itemList): Observable<any>;

  abstract exportLinesheet(itemList): Observable<any>;

  abstract exportPricat(itemList): Observable<any>;

  abstract exportProductInfoV3(itemList, string): Observable<any>;

  abstract exportProductStock(itemList): Observable<any>;
}
