import { Injectable, Input, Optional } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  ConfigService,
  ConfigBaseFilter,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties
} from 'gung-list';
import { first, forkJoin, Observable, of, switchMap } from 'rxjs';
import { map, tap } from 'rxjs';
import { Product, ProductExtended } from '../../models';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { CloudPimFiltersService } from '../cloud-pim-filters/cloud-pim-filters.service';
import { ColumnSortSelectionService } from '../column-sort-selection/column-sort-selection.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class ConceptDetailProductListV2ConfigService implements ConfigService<Product> {
  topFilter = true;

  @Input()
  conceptProduct: Product;

  constructor(
    protected productService: ProductService,
    protected baseViewConfigService: BaseViewConfigService,
    protected metadataService: MetadataService,
    protected cloudPimFiltersService: CloudPimFiltersService,
    protected columnSortSelectionService: ColumnSortSelectionService
  ) {}

  getFilters(): ConfigBaseFilter<Product>[] {
    if (this.conceptProduct) {
      return this.cloudPimFiltersService.getFilters(this.conceptProduct as any);
    }
    return [];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getConceptDetailV2ProductListViewComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<Product[]> {
    return this.columnSortSelectionService.getSelectedColumnSort().pipe(
      switchMap(selectedColumnSort => {
        return forkJoin([
          of(selectedColumnSort),
          this.productService.getProductsByIds((this.conceptProduct as any).subProductIds || []).pipe(first())
        ]);
      }),
      map(([selectedColumnSort, products]) => {
        // Sort rows
        if (selectedColumnSort) {
          products = this.columnSortSelectionService.sortItemsByColumn(products, selectedColumnSort);
        }
        // Dyncamic column values
        const dynamicColumns = this.getDynamicColumns();
        if (dynamicColumns && dynamicColumns.length > 0) {
          products.map((product: ProductExtended) => {
            const dynamicCollumnsValues: string[] = [];
            for (const column of dynamicColumns) {
              if (column.isDisplay) {
                const paths: string[] = column.path.split('.');
                const value = (product.extra[paths[0]] && product.extra[paths[0]][paths[1]]) || '';
                if (value && value.description) {
                  dynamicCollumnsValues.push(value.description);
                } else if (Array.isArray(value)) {
                  const valueArray = value.map(v => v.description).join(', ');
                  dynamicCollumnsValues.push(valueArray);
                } else if (column.type === 'metadata') {
                  if (column.metaReference) {
                    dynamicCollumnsValues.push(
                      this.metadataService.getMetadataValue(
                        column.metaReference.table,
                        column.metaReference.field,
                        value
                      )
                    );
                  } else if (column.metadata && column.metadata.split('.').length === 2) {
                    const splitmeta = column.metadata.split('.');
                    const metaTable = splitmeta[0];
                    const metaField = splitmeta[1];
                    dynamicCollumnsValues.push(this.metadataService.getMetadataValue(metaTable, metaField, value));
                  } else {
                    dynamicCollumnsValues.push(
                      this.metadataService.getMetadataValue(column.metadata, 'description', value)
                    );
                  }
                } else {
                  dynamicCollumnsValues.push(value || '');
                }
              } else {
                dynamicCollumnsValues.push('');
              }
            }
            product.dynamicCollumnsValues = dynamicCollumnsValues;
          });
        }
        return products;
      })
    );
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getSortOptions(): ListSortOption<Product>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: Product) {
    return item.id;
  }

  getDynamicColumns?(route?: ActivatedRouteSnapshot): PimTemplateProperties[] {
    const skuProperties = this.conceptProduct.extra?.skuProperties || [];

    const filteredProperties = skuProperties ? skuProperties.filter(p => p.isDisplayList) : null;
    return filteredProperties;
  }
}
