
<div class="row"  *ngIf="!glnRecord">
  <div class="col-12">

    <div class="alert alert-danger"  translate>THE_GLN_NUMBER_COULD_NOT_BE_FOUND</div>
  </div>
  <div class="col-12">

    <table class="table table-striped" *ngIf="addressFromMessage">
       <thead>
        <tr>
          <th class="col-3">Address Info From Message</th>
          <th></th>
        </tr>
      </thead>
      <tbody>

        <tr  *ngIf="addressFromMessage.name">
          <td translate>CUSTOMER_NAME</td>
          <td>
            {{ addressFromMessage.name }}
          </td>
        </tr>
        <tr  *ngIf="addressFromMessage.address1">
          <td translate>ADDRESS1</td>
          <td>
            {{ addressFromMessage.address1 }}
          </td>
        </tr>
        <tr  *ngIf="addressFromMessage.address2">
          <td translate>ADDRESS2</td>
          <td>
            {{ addressFromMessage.address2 }}
          </td>
        </tr>
        <tr  *ngIf="addressFromMessage.zipCode">
          <td translate>CITY</td>
          <td>
            {{ addressFromMessage.zipCode }}
          </td>
        </tr>
        <tr  *ngIf="addressFromMessage.city">
          <td translate>ZIP</td>
          <td>
            {{ addressFromMessage.city }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<table class="table table-striped" *ngIf="glnRecord">
  <thead>
    <tr>
      <th class="col-3">Property</th>
      <th>Value</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td translate>CUSTOMER_ID</td>
      <td>
        <a [routerLink]="'/customers/' + glnRecord.companyId">{{ glnRecord.companyId }}</a>
      </td>
    </tr>
    <tr>
      <td translate>CUSTOMER_NAME</td>
      <td>
        {{ glnRecord.b24?.name }}
      </td>
    </tr>
    <tr>
      <td translate>GLN</td>
      <td>
        {{ glnNumber }}
      </td>
    </tr>
    <tr>
      <td translate>NAME</td>
      <td>
        {{ glnRecord.name }}
      </td>
    </tr>
    <tr>
      <td translate>CITY</td>
      <td>
        {{ glnRecord.b24.zipCode }}
      </td>
    </tr>
    <tr>
      <td translate>ZIP</td>
      <td>
        {{ glnRecord.b24.city }}
      </td>
    </tr>
  </tbody>
</table>
