import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgModule,
  OnInit,
  Output,
  Pipe,
  PipeTransform,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { CoverImage } from '../../models/export-cover-sheets';
import { S3Image } from '../../models/s3';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { GungViewer3dComponent } from '../gung-viewer3d/gung-viewer3d.component';
import { mediaEmbed } from '../../utils/gung-utils';
import { GungImageOption, GungImageType, GungImageUrlService } from 'gung-common';

export interface GungVideo {
  url: string;
  provider: string;
  code: string;
  embed: any;
}

@Component({
  selector: 'lib-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnInit, AfterViewInit {
  @Input()
  minimal: boolean;

  @Input()
  showNavigationArrows: boolean;

  @Input()
  images: CoverImage[];

  @Input()
  zoom: boolean; // Mouse hover zoom effect

  @Input()
  imageSize;
  @Input()
  imageSizeZoom;
  @Input()
  imageSizeThumbnail;

  @Input()
  imageFill = true;

  @Input()
  imageColorFill;

  @Input()
  imageFormat;

  @Input()
  enableFullImage: boolean; //Image will have the original size

  @Input()
  openExternal: boolean; // Icon open image new tab

  @Input()
  openInternal: boolean; // Icon open image modal

  @Input()
  vertical?: boolean; // Show carousel vertically to the left of the main image

  @Input()
  highRes?: boolean; // Double the pixles in URL but keep size on page

  @Input()
  videoUrl: string;

  @Input()
  multipleVideoUrlSeparator: string; // Separator for videoUrl string, so that we can show multiple videos

  @Input()
  model3dFile?: string[];

  @Input()
  maxHeightModalImg?: string;

  @ViewChild('gungViewer3dComponent')
  gungViewer3d: GungViewer3dComponent;

  view3DModel: boolean = false;

  videos: GungVideo[] = [];

  @Output() selectedImage = new EventEmitter<string>(); // Current display image

  public imageIdString = 'productImage';
  @ViewChild(NgbCarousel)
  public myCarousel: NgbCarousel;
  @ViewChild('scrollContainer')
  scrollingImages: ElementRef;

  verticalMaxHeight: number;

  imageMaxWidth: number;
  imageMaxHeight: number;
  thumbnailMaxWidth: number;
  thumbnailMaxHeight: number;

  lastCarouselSelectedIndex: number;

  orientation?: 'portrait' | 'landscape';

  constructor(
    protected gungModalService: GungModalService,
    protected domSanitizer: DomSanitizer,
    protected gungImageUrlService: GungImageUrlService
  ) { }

  ngOnInit() {
    if (!!this.highRes) {
      if (!!this.imageSize) {
        this.imageMaxWidth = Number(this.imageSize.split('x')[0]);
        this.imageMaxHeight = Number(this.imageSize.split('x')[1]);
        this.imageSize = this.imageMaxWidth * 2 + 'x' + this.imageMaxHeight * 2;
      }
      if (!!this.imageSizeThumbnail) {
        this.thumbnailMaxWidth = Number(this.imageSizeThumbnail.split('x')[0]);
        this.thumbnailMaxHeight = Number(this.imageSizeThumbnail.split('x')[1]);
        this.imageSizeThumbnail = this.thumbnailMaxWidth * 2 + 'x' + this.thumbnailMaxHeight * 2;
      }
    }
    if (!this.vertical) {
      this.orientation = undefined;
      this.imageSizeZoom = this.imageSizeZoom || this.gungImageUrlService.defaultHighDefinitionOptions.size;
      this.imageSize = this.imageSize || this.gungImageUrlService.defaultDetailsOptions.size;
      this.imageSizeThumbnail = this.imageSizeThumbnail || this.gungImageUrlService.defaultListThumbnailOptions.size;
    } else {
      this.orientation = 'portrait';
      this.imageSizeZoom = this.imageSizeZoom || this.gungImageUrlService.defaultHighDefinitionOptions.sizePortrait;
      this.imageSize = this.imageSize || this.gungImageUrlService.defaultDetailsOptions.sizePortrait;
      this.imageSizeThumbnail = this.imageSizeThumbnail || this.gungImageUrlService.defaultListThumbnailOptions.sizePortrait;
    }
    this.imageColorFill = this.imageColorFill ?? this.gungImageUrlService.defaultDetailsOptions.fill;
    this.imageFormat = this.gungImageUrlService.defaultDetailsOptions.format;

    if (this.images && this.images.length > 0) {
      for (const image of this.images) {
        image.extra = {
          ...(image.extra || {}),
          s3UriEncoded: this.encodeUriComponentCustom(image.s3Uri)
        };
      }
      const image = this.images[0];
      const imgUrl = this.gungImageUrlService.getUrl(
        (image.extra.s3UriEncoded || image.s3Uri),
        {
          type: GungImageType.detail,
          format: this.imageFormat,
          size: this.imageSize,
          etag: image.s3ETag,
          orientation: this.orientation
        }
      );
      this.selectedImage.emit(imgUrl);
    }
    if (this.videoUrl) {
      this.loadVideoUrl();
    }
  }

  ngAfterViewInit(): void {
    const zoomImage = document.getElementsByClassName('ngxImageZoomThumbnail')?.[0];
    if (zoomImage) {
      (zoomImage as HTMLElement).style.maxWidth = this.imageMaxWidth + 'px';
      (zoomImage as HTMLElement).style.maxHeight = this.imageMaxHeight + 'px';
    }
  }

  select(index: number): void {
    this.myCarousel.select(this.imageIdString + index);
    const activeId = this.myCarousel.activeId;

    // Image
    if (index < this.images.length) {
      const image = this.images[index];
      const imgUrl = this.gungImageUrlService.getUrl(
        this.encodeUriComponentCustom(image.s3Uri),
        {
          type: GungImageType.detail,
          format: this.imageFormat,
          size: this.imageSize,
          etag: image.s3ETag,
          orientation: this.orientation
        }
      );
      this.selectedImage.emit(imgUrl);
    }

    // If video then autoplay
    const videoIndex: number = index - this.images.length;
    const videoObject: GungVideo = this.videos?.[videoIndex];
    let hostEle: HTMLIFrameElement = undefined;
    if (!!videoObject) {
      hostEle = document.getElementById(this.imageIdString + '' + index) as HTMLIFrameElement;
    }
    if (videoObject && hostEle && videoObject?.provider === 'youtube') {
      // Autoplay youtube video when scroll in
      hostEle.contentWindow.postMessage('{"event":"command","func":"' + 'playVideo' + '","args":""}', '*');
    }

    // Check if last carousel index was a video, if it was, pause video
    const lastVideoIndex: number = this.lastCarouselSelectedIndex - this.images.length;
    const lastVideo: GungVideo = this.videos?.[lastVideoIndex];
    if (!!lastVideo) {
      hostEle = document.getElementById(this.imageIdString + '' + this.lastCarouselSelectedIndex) as HTMLIFrameElement;
      if (index !== this.lastCarouselSelectedIndex && hostEle && lastVideo?.provider === 'youtube') {
        // Pause playing video when scrool out youtube
        hostEle.contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
      } else if (index !== this.lastCarouselSelectedIndex && hostEle && lastVideo?.provider === 'vimeo') {
        // Pause playing video when scrool out vimeo
        const saveSrc = hostEle.src;
        hostEle.src = undefined;
        hostEle.src = saveSrc;
      }
    }

    this.lastCarouselSelectedIndex = index;
  }

  scrollImagesRight(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.min(
      scroller.scrollLeft + scroller.clientWidth,
      scroller.scrollWidth - scroller.clientWidth
    );
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  scrollImagesLeft(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollWidth = Math.max(scroller.scrollLeft - scroller.clientWidth, 0);
    scroller.scroll({ left: scrollWidth, top: 0, behavior: 'smooth' });
  }

  scrollImagesDown(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollHeight = Math.min(
      scroller.scrollTop + scroller.clientHeight,
      scroller.scrollHeight - scroller.clientHeight
    );
    scroller.scroll({ left: 0, top: scrollHeight, behavior: 'smooth' });
  }

  scrollImagesUp(): void {
    const scroller = this.scrollingImages.nativeElement as HTMLElement;
    const scrollHeight = Math.max(scroller.scrollTop - scroller.clientHeight, 0);
    scroller.scroll({ left: 0, top: scrollHeight, behavior: 'smooth' });
  }

  getVerticalMaxHeight() {
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    if (!this.imageSize) {
      return '' + (Number(this.gungImageUrlService.defaultDetailsOptions['size' + (this.orientation ? capitalizeFirstLetter(this.orientation) : '')].split('x')[1]) - 32);
    }
    // Max height of image scroll as image height - arrows height
    return '' + (Number(this.imageSize.split('x')[1]) - 32);
  }

  openImageModal(image: S3Image): void {
    const imageName = image.s3Uri.substring(image.s3Uri.lastIndexOf('/') + 1);
    const content = `
    <img class="img-fluid mx-auto d-block"
        ${this.maxHeightModalImg ? `style="max-height: ${this.maxHeightModalImg};"` : ''}
        src="${this.gungImageUrlService.getUrl(
      this.encodeUriComponentCustom(image.s3Uri),
      {
        type: GungImageType.high,
        format: this.imageFormat,
        size: this.imageSizeZoom,
        etag: image.s3ETag,
        orientation: this.orientation
      }
    )}"
        alt="{{image.description}}">`;
    this.gungModalService.openBasicModal(imageName, content, { size: 'xl' }, true);
  }

  loadVideoUrl() {
    let videoUrls: string[] = [];

    if (this.multipleVideoUrlSeparator) {
      videoUrls = this.videoUrl.split(this.multipleVideoUrlSeparator);
    } else {
      videoUrls = [this.videoUrl];
    }

    for (const url of videoUrls) {
      this.videos.push(this.convertVideoUrlToGungVideo(url));
    }
  }

  convertVideoUrlToGungVideo(url: string): GungVideo {
    let result: GungVideo;

    if (url.includes('youtube.com')) {
      const spliter = 'youtube.com/watch?v=';
      const code = url.substring(url.indexOf(spliter) + spliter.length);
      if (!code) {
        return;
      }
      result = {
        url: url,
        provider: 'youtube',
        code,
        embed: this.domSanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube-nocookie.com/embed/${code}?controls=1&enablejsapi=1`
        )
      };
    } else if (url.includes('youtu.be')) {
      const spliter = 'youtu.be/';
      const code = url.substring(url.indexOf(spliter) + spliter.length);
      if (!code) {
        return;
      }
      result = {
        url: url,
        provider: 'youtube',
        code,
        embed: this.domSanitizer.bypassSecurityTrustResourceUrl(
          `https://www.youtube-nocookie.com/embed/${code}?controls=1&enablejsapi=1`
        )
      };
    } else if (url.includes('vimeo.com')) {
      const spliter = 'vimeo.com/';
      const code = url.substring(url.indexOf(spliter) + spliter.length);
      if (!code) {
        return;
      }
      result = {
        url: url,
        provider: 'vimeo',
        code,
        embed: this.domSanitizer.bypassSecurityTrustResourceUrl(
          `https://player.vimeo.com/video/${code}?api=1&title=0&byline=0&portrait=0`
        )
      };
    } else {
      result = {
        url: url,
        provider: 'direct',
        code: '',
        embed: mediaEmbed(url, '', '100%', this.imageSize.split('x')[1]).embedHtml
      };
    }

    return result;
  }

  encodeUriComponentCustom(str: string): string {
    return str.split('/').map((part, index) => {
      return index !== str.split('/').length - 1 ? encodeURIComponent(part) : part;
    }).join('/');
  }
}
