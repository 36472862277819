import { Component, OnInit } from '@angular/core';
import { EdiDispatchAdvicesListConfigService } from '../../../services/edi-dispatch-advices-list-config/edi-dispatch-advices-list-config.service';

@Component({
  selector: 'lib-edi-dispatch-advices',
  template: `<h3 class="d-block page-title text-center" translate>EDI - Despatch advice</h3>
    <lib-filter-list [configService]="ediDispatchAdvicesListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-dispatch-advices.component.css']
})
export class EdiDispatchAdvicesComponent implements OnInit {
  constructor(public ediDispatchAdvicesListConfigService: EdiDispatchAdvicesListConfigService) {}

  ngOnInit(): void {}
}
