<div class="row">
  <div class="col-12 col-md-6">
    <label for="typeahead-prevent-manual-entry" translate>ORDER_ID</label>
    <input
      id="typeahead-prevent-manual-entry"
      type="text"
      class="form-control"
      [(ngModel)]="selectedOrder"
      [ngbTypeahead]="search"
      [inputFormatter]="formatter"
      [resultFormatter]="formatter"
      (selectItem)="addOrder($event)"
      [editable]="false"
      [disabled]="loading"
    />
    <!-- (focus)="clearFields()" -->
    <!-- (blur)="addOrderId($event.target.value)" -->
  </div>
  <div class="col-12 col-md-6">
    <p><span translate>ORDERS</span>: {{ selectedOrdersId.join(', ') }}</p>
    <p><span translate>CUSTOMERS</span>: {{ selectedOrdersCustomer.join(', ') }}</p>
  </div>
</div>
<div class="row order-itens">
  <div class="col-12 table-responsive gung-responsive">
    selectedOrdersRows {{ selectedOrdersRows.length }}
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>PRODUCT_ID</th>
          <th translate>NAME</th>
          <th translate>QUANTITY</th>
          <th translate>ORDER_ID</th>
          <th><i class="fas fa-tools"></i></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of selectedOrdersRows">
          <td [attr.data-label]="'PRODUCT_ID' | translate">
            <a [routerLink]="['/product/', item.productId]">{{ item.productId }}</a>
          </td>
          <td [attr.data-label]="'NAME' | translate">
            {{ item.extra.product?.name }}
          </td>
          <td [attr.data-label]="'QUANTITY' | translate">
            {{ item.quantity }}
          </td>
          <td [attr.data-label]="'ORDER_ID' | translate">
            <a [routerLink]="['/orders/', item.productId]">{{ item.orderId }}</a>
          </td>
          <td>
            <button type="button" class="btn btn-secondary d-block" (click)="releaseOrders(item)">
              RELEASE_ORDERS
            </button>
            <button type="button" class="btn btn-secondary d-block" (click)="changeDeliveryDate(item)">
              CHANGE_DELIVERY_DATE
            </button>
            <button type="button" class="btn btn-secondary d-block" (click)="addCampaignProducts(item)">
              ADD_CAMPAIGN_PRODCUTS
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
