import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { switchMap, filter } from 'rxjs';
import { ProductListConfigService } from '../product-list-config/product-list-config.service';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { Product } from '../../models';
import { FavouriteProductsService } from '../favourite-products/favourite-products.service';
import { ConfigBaseFilter, ConfigService, ListLayout, ListSortOption } from 'gung-list';
import { ActivatedRouteSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FavouriteProductListConfigService implements ConfigService<Product> {
  constructor(
    protected productService: ProductService,
    protected productListConfigService: ProductListConfigService,
    protected authService: AuthService,
    protected favouritesService: FavouriteProductsService
  ) {}
  topFilter?: boolean;
  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<Product>[] {
    return this.productListConfigService.getFilters();
  }
  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<Product>[] {
    return this.productListConfigService.getSortOptions();
  }
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] {
    return this.productListConfigService.getBatchSizes();
  }
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<Product>[] {
    return this.productListConfigService.getLayouts();
  }
  getSearchTerms(item: Product): string[] {
    return this.productListConfigService.getSearchTerms(item);
  }
  getItemId(item: Product): string {
    return this.productListConfigService.getItemId(item);
  }

  getItems(): Observable<Product[]> {
    return this.favouritesService.getFavourites().pipe(
      switchMap(favourites => {
        const ids: Array<string> = Object.keys(favourites);
        const filteredIds = ids.filter(id => {
          return favourites[id];
        });

        return this.productService.getProductsByIds(filteredIds);
      })
    );
  }
}
