import { Injectable } from '@angular/core';
import { UsersService } from './../users/users.service';
import { User } from './../../state/auth/types';
import {
  ConfigService,
  ListSortOption,
  ListLayout,
  ConfigBaseFilter,
  ListLayoutMultipleComponent,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable, filter, first, forkJoin, map, mergeMap, of, switchMap } from 'rxjs';
import { UserTableComponent } from '../../components/users/user-table/user-table.component';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../customers/customer.service';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { gungComparatorHelper } from '../../utils/gung-utils';
import { LoggedInUsersService } from '../logged-in-users.service';

export class UserTimeSinceSuccessfulLoginFilter extends SimpleConfigBaseFilter<User> {
  type = 'RangeConfigBaseFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'TIME_SINCE_SUCESSFUL_LOGIN_MONTHS';
  }

  getOptionIds(item: User): string[] {
    if (item?.extra?.loginData?.latestSuccessfulLogin) {
      const monthsAgo = differenceInMonths(new Date(), new Date(item.extra.loginData.latestSuccessfulLogin));

      if (!isNaN(monthsAgo)) {
        return [monthsAgo.toString()];
      } else {
        return [];
      }
    } else {
      return [];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserLoginStatusFilter extends SimpleConfigBaseFilter<User> {
  getName(): string {
    return 'LOGIN_ATTEMPTS';
  }

  getOptionIds(item: User): string[] {
    if (item.extra?.loginData?.loginFailAttempts > 0 && item.extra?.loginData?.loginSucessAttempts === 0) {
      return ['HIDE_USERS_WITH_SUCCESS_ATEMPTS'];
    } else if (item.extra?.loginData?.loginFailAttempts === 0 && item.extra?.loginData?.loginSucessAttempts > 0) {
      return ['HIDE_USERS_WITH_FAIL_ATEMPTS'];
    } else {
      return [];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserCurrentlyLoggedInFilter extends SimpleConfigBaseFilter<User> {
  getName(): string {
    return 'CURRENTLY_LOGGED_IN';
  }

  getOptionIds(item: User): string[] {
    if (item.extra._isCurrentlyLoggedIn === undefined) {
      return [];
    }

    if (item.extra._isCurrentlyLoggedIn) {
      return ['TRUE'];
    }
    return ['FALSE'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserRolesFilter extends SimpleConfigBaseFilter<User> {
  constructor(private translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ROLES';
  }

  getOptionIds(item: User): string[] {
    return item.roles || [];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class UserActiveGroupsFilter extends SimpleConfigBaseFilter<User> {
  constructor(private translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'GROUPS';
  }

  getOptionIds(item: any): string[] {
    return Object.keys(item.activeGroups).filter(key => item.activeGroups[key]) || [];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class UserAssortmentFilter extends SimpleConfigBaseFilter<User> {
  constructor() {
    super();
  }

  getName(): string {
    return 'ASSORTMENT';
  }

  getOptionIds(item: User): string[] {
    return [item.assortment];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserStockFilter extends SimpleConfigBaseFilter<User> {
  constructor() {
    super();
  }

  getName(): string {
    return 'STOCK_ID';
  }

  getOptionIds(item: User): string[] {
    return item.managedMultistockIds;
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class UserGungAdminFilter extends SimpleConfigBaseFilter<User> {
  getName(): string {
    return 'GUNG_ADMIN';
  }

  getOptionIds(item: User): string[] {
    if (item.authenticationMethod === 'COGNITO_USERNAME_PASSWORD') {
      return ['YES'];
    } else {
      return ['NO'];
    }
  }

  getOptionName(key: string): string {
    return key;
  }
}
@Injectable({
  providedIn: 'root'
})
export class UsersListConfigService implements ConfigService<User> {
  constructor(
    protected usersService: UsersService,
    protected translateService: TranslateService,
    protected customerService: CustomerService,
    protected loggedInUsersService: LoggedInUsersService
  ) {}

  getItems(): Observable<User[]> {
    return this.usersService.getAllUsersSubject().pipe(
      filter(users => !!users),
      switchMap(users => {
        return forkJoin({
          users: of(users),
          customers: this.customerService.getCustomers().pipe(first()),
          loggedInUsers: this.loggedInUsersService.getLoggedInUsers().pipe(first())
        });
      }),
      mergeMap(({ users, customers, loggedInUsers }) => {
        const loggedInUsernames = new Set<string>(loggedInUsers || []);

        users = users.map(user => {
          if (user.extra?.loginData?.latestSuccessfulLogin) {
            const successDaysAgo = differenceInDays(new Date(), new Date(user.extra.loginData.latestSuccessfulLogin));
            if (!isNaN(successDaysAgo)) {
              user.extra._successLatestLoginDays =
                successDaysAgo > 0
                  ? `${successDaysAgo} ` + this.translateService.instant('DAYS_AGO')
                  : this.translateService.instant('TODAY');
            }
          }
          if (user.extra?.loginData?.latestFailedLogin) {
            const failedDaysAgo = differenceInDays(new Date(), new Date(user.extra.loginData.latestFailedLogin));
            if (!isNaN(failedDaysAgo)) {
              user.extra._failedLatestLoginDays =
                failedDaysAgo > 0
                  ? `${failedDaysAgo} ` + this.translateService.instant('DAYS_AGO')
                  : this.translateService.instant('TODAY');
            }
          }
          if (user.managedCompanyIds?.length > 0) {
            user.extra._managedCompanies = customers
              .filter(c => user.managedCompanyIds.includes(c.id))
              .map(customer => ({ id: customer.id, name: customer.name }));
          }

          // We only want to run this if we got an actual result from the backend. There exists a case where the frontend
          // has updated but not the backend. In that case, a regular ADMIN will not be allowed to access the old endpoint.
          // Instead of showing that all users are not logged in and confusing the customers, we instead want to skip
          // the filter all together. Checking it in this way will solve that problem.
          if (!!loggedInUsers) {
            if (loggedInUsernames.has(user.username)) {
              user.extra._isCurrentlyLoggedIn = true;
            } else {
              user.extra._isCurrentlyLoggedIn = false;
            }
          }

          return user;
        });
        return of(users);
      }),
      map(users =>
        users.sort((a, b) =>
          gungComparatorHelper(
            new Date(a?.extra?.createdAt).getTime() || 0,
            new Date(b?.extra?.createdAt).getTime() || 0,
            -1
          )
        )
      )
    );
  }

  getFilters(): ConfigBaseFilter<User>[] {
    return [
      new UserRolesFilter(this.translateService),
      new UserActiveGroupsFilter(this.translateService),
      new UserAssortmentFilter(),
      new UserStockFilter(),
      new UserLoginStatusFilter(),
      new UserTimeSinceSuccessfulLoginFilter(this.translateService),
      new UserCurrentlyLoggedInFilter(),
      new UserGungAdminFilter()
    ];
  }

  getSortOptions(): ListSortOption<User>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<User>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => UserTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'UserTable'
      }
    ];
  }

  getSearchTerms(item: User): string[] {
    return [item.username, item.name, item.email, ...(item.managedCompanyIds || [])];
  }

  getItemId(item: User): string {
    return item.username;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
