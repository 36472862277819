import { Component, OnInit, ViewChild } from '@angular/core';
import { Product } from '../../models';
import { ProductLogoApplicatorListConfigService } from '../../services/product-logo-applicator-list-config/product-logo-applicator-list-config.service';
import { FilterListComponent } from 'gung-list';

@Component({
  selector: 'lib-product-logo-applicator-list',
  template:
    '<lib-filter-list #productLogoApplicatorList [configService]="productLogoApplicatorListConfigService"></lib-filter-list>',
  styleUrls: ['./product-logo-applicator-list.component.css']
})
export class ProductLogoApplicatorListComponent implements OnInit {
  @ViewChild('productExportList') productLogoApplicatorList: FilterListComponent<Product>;

  constructor(public productLogoApplicatorListConfigService: ProductLogoApplicatorListConfigService) {}

  ngOnInit() {}
}
