<div *ngIf="result">

  <div *ngIf="result.errors && result.errors.length > 0" class="row mt-4">
    <div class="col-9">
      <table class="table">
        <thead>
        <tr>
          <th scope="col" translate>ERRORS</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let error of result.errors">
          <td>{{error.sheet}} - {{error.rowNumber}}: {{error.error}} (<span translate>PRODUCT_ID</span> {{error.productId}})</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>


  <div class="row" *ngFor="let order of result.orders; let index = index;">
    <div class="col-9">
      <table class="table table-striped">
        <thead class="table-dark">
        <tr>
          <th scope="col">
            <span translate>ORDER</span>: {{ index + 1 }}.  <span translate>CUSTOMER</span> {{order.deliveryCustomerId}}
          </th>
          <th class="text-right">
            <span *ngIf="createdOrders[index]" class="success-text">
              <span class="fa fa-check mr-1"></span>
              <span translate>CREATED_ORDER</span>: {{createdOrders[index]}}
            </span>
            <span *ngIf="errorsInCreation[index]"
                  [ngbTooltip]="errorsInCreation[index]"
                  class="error-text"
            >
              <i class="fa-regular fa-circle-exclamation mr-1"></i>
              <span translate>ERROR_WHILE_CREATING_ORDER</span>
            </span>
            <button
              *ngIf="!createdOrders[index] && !errorsInCreation[index]"
              (click)="createOrder(order, index)"
              [disabled]="creatingOrders[index]"
              class="btn btn-primary">
              <span *ngIf="!creatingOrders[index]" translate>CREATE_ORDER</span>
              <span *ngIf="creatingOrders[index]">
                <i class="fa fa-spinner fa-spin mr-1"></i>
                <span translate>CREATING_ORDER</span>
              </span>
            </button>
          </th>
        </tr>
        <tr>
          <th translate>PRODUCT_ID</th>
          <th class="text-right" translate>QUANTITY</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let row of order.rows">
          <td>
            <a [routerLink]="['/product/', row.productId]" target="_blank">{{
                row.productId
              }}</a>
          </td>
          <td class="text-right">{{ row.quantity }}</td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="col-3">
      <div *ngIf="index == 0" class="alert alert-warning text-left" role="alert">
        <span translate>ORDER_ID_WILL_CHANGE_ORDER_IMPORT_HELP_TEXT</span>
      </div>

    </div>
  </div>

</div>
