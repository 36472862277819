import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs';
import { PdfAuthService } from './pdf-auth.service';

interface GungBase {
  id: string;
  name: string;
  extra: { [s: string]: any } | null;
}

export interface DigitalAsset extends GungBase {
  dateLastUpdate: number;
  i18n: { [s: string]: any };
  images: DigitalAssetFile[];
  documents: DigitalAssetFile[];
}

export interface DigitalAssetFile extends GungBase {
  category: string;
  description: any;
  i18n: { [s: string]: any };
  index: any;
  pimDimensionIds: any[];
  s3ETag: string;
  s3Uri: string;
  s3lastModifiedDate: any;
}

@Injectable({
  providedIn: 'root'
})
export class PdfDigitalAssetsService {
  public showFlowsAsDigitalAssets = false;

  protected baseUrl = 'json/pim/digitalassets';
  protected baseUrlImages = 'json/pim/digitalasset-images';
  protected baseUrlDocuments = 'json/pim/digitalasset-documents';

  private digitalAssetSubject: BehaviorSubject<DigitalAsset[]>;

  constructor(protected http: HttpClient, protected authService: PdfAuthService) {}

  public getDigitalAssets(nocache?: boolean): Observable<DigitalAsset[]> {
    if (!this.digitalAssetSubject) {
      this.digitalAssetSubject = new BehaviorSubject<DigitalAsset[]>([]);
      // Added return to fix pipe first() call
      return this.updateDigitalAssetSubject(nocache);
    }
    return this.digitalAssetSubject.asObservable();
  }

  protected internalGetDigitalAssets(nocache?: boolean): Observable<DigitalAsset[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<DigitalAsset[]>(url, { headers });
  }

  updateDigitalAssetSubject(nocache?: boolean): Observable<DigitalAsset[]> {
    return this.internalGetDigitalAssets(nocache).pipe(
      switchMap((data: DigitalAsset[]) => {
        if (this.digitalAssetSubject) {
          this.digitalAssetSubject.next(data);
        }
        return this.digitalAssetSubject || of(data);
      })
    );
  }
}
