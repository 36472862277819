<div class="form-group">
    <label  [for]="inputId" *ngIf="!suppressLabel" [ngClass]="{ 'font-weight-bold': labelBold }">
        {{ translateTag | translate: translateParams }}
        <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
        <span *ngIf="footnote" class="ml-1">*</span>
    </label>
    <div class="input-group mb-2 mr-sm-2">
        <div class="input-group-prepend" *ngIf="showCountryCode">
            <div class="input-group-text form-control" ngbDropdown class="d-inline-block" placement="bottom-start" (openChange)="dropdownToggle($event)">
                <button type="button" class="btn btn-outline-primary d-flex justify-content-center align-items-center" id="dropdownBasic1" ngbDropdownToggle [disabled]="disabled">
                    <img *ngIf="showFlags" src="https://flagcdn.com/{{'20x15' || flagSize}}/{{countryCodes[selectedCountryCode].code | lowercase}}.png" alt="{{countryCodes[selectedCountryCode].code}}" class="mr-1">
                    <span *ngIf="!showFlags">({{ countryCodes[selectedCountryCode].code }})</span>
                    {{ countryCodes[selectedCountryCode].dial_code }}
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" [style.max-height]="maxHeight" style="overflow-y: auto; width: 433px;">
                    <input [autofocus] ngbAutofocus #searchInput autocomplete="off" id="typeahead-basic" type="text" class="form-control form-control-sm typeahead" [(ngModel)]="searchModel" (ngModelChange)="setFilter($event)"/>
                    <button ngbDropdownItem *ngFor="let country of (filteredCountryCodesArray || countryCodesArray)" (click)="selectCountry(country)" [class.active]="country.dial_code === selectedCountryCode">
                        <img *ngIf="showFlags" src="https://flagcdn.com/{{flagSize}}/{{country.code | lowercase}}.png" alt="{{country.code}}" class="mr-1">
                        <span *ngIf="!showFlags">({{ country.code }})</span>
                        ({{ country.dial_code }}) {{ country.name }}
                    </button>
                </div>
            </div>
        </div>
        <input 
            type="tel"
            [class]="extraInputClass"
            [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
            [readonly]="renderStatic"
            [id]="inputId"
            [name]="inputId"
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder | translate"
            [(ngModel)]="valueNumber"
            pattern="[0-9\s]*"
            inputmode="numeric"
            (ngModelChange)="createNumberPhone()" [ngModelOptions]="{ updateOn: 'blur' }"
        />

        <input 
            class="d-none"
            type="text"
            [class]="extraInputClass"
            [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
            [readonly]="renderStatic"
            [id]="inputId"
            [name]="inputId"
            [required]="required"
            [disabled]="disabled"
            [placeholder]="placeholder | translate"
            [(ngModel)]="value"
            pattern="[0-9\s]*"
            inputmode="numeric"
        />
    </div>
</div>
  