import { Component, Input, OnInit, OnDestroy, Optional } from '@angular/core';
import { Subscription } from 'rxjs';
import { BackendPriceLevel, CustomerProductPrice } from '../../models/price';
import { CartService } from '../../services/cart/cart.service';
import { PriceConfigService } from '../../services/price-config/price-config.service';
import { CartRow } from '../../state/cart/types';

@Component({
  selector: 'lib-price-levels',
  templateUrl: './price-levels.component.html',
  styleUrls: ['./price-levels.component.css']
})
export class PriceLevelsComponent implements OnInit, OnDestroy {
  priceLevels: BackendPriceLevel[] = [];
  dataCart: CartRow;
  currentPrice = 0;
  cartSubscription: Subscription;

  @Input() useStaffedPrice;
  @Input() price;
  @Input() priceLevelsDisplayType: string; // Controls the type of layout of the price levels
  // To display the current price level it will use this quantity instead of the current quantity in cart
  @Input()
  priceLevelsCurrentQty: number;

  constructor(protected priceConfigService: PriceConfigService, protected cartService: CartService) {
  }

  ngOnInit(): void {
    if(this.useStaffedPrice) {
      this.initPriceLevels(this.price, this.priceLevelsCurrentQty);
    }
  }

  initPriceLevels(price: CustomerProductPrice, priceLevelsCurrentQty: number = undefined) {
    if (price.backendPrice?.levels) {
      this.priceLevels = price.backendPrice.levels;

      if (price.backendPrice?.levels.length > 1) {
        if (priceLevelsCurrentQty === undefined) {
          this.cartSubscription = this.cartService.getCurrentCart().subscribe(cart => {
            this.dataCart = cart.find(c => c.productId === price.productId);
            if (!this.dataCart) {
              this.currentPrice = 0;
            } else {
              if (
                this.priceLevels[this.currentPrice + 1] &&
                this.dataCart?.qty >= this.priceLevels[this.currentPrice + 1].quantity
              ) {
                this.currentPrice++;
              }

              if (
                this.priceLevels[this.currentPrice] &&
                this.dataCart?.qty < this.priceLevels[this.currentPrice].quantity &&
                this.currentPrice > 0
              ) {
                this.currentPrice--;
              }
            }
          });
        } else {
          if (
            this.priceLevels[this.currentPrice + 1] &&
            priceLevelsCurrentQty >= this.priceLevels[this.currentPrice + 1].quantity
          ) {
            this.currentPrice++;
          }

          if (
            this.priceLevels[this.currentPrice] &&
            priceLevelsCurrentQty < this.priceLevels[this.currentPrice].quantity &&
            this.currentPrice > 0
          ) {
            this.currentPrice--;
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.cartSubscription?.unsubscribe();
  }
}
