import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../models/pim';
import { KeyValue } from '@angular/common';
import { PriceService } from '../../../../services/price/price.service';
import { first } from 'rxjs';
import { Pricelist } from '../../../../models/price';

@Component({
  selector: 'lib-pim-product-prices',
  templateUrl: './pim-product-prices.component.html',
  styleUrls: ['./pim-product-prices.component.css']
})
export class PimProductPricesComponent implements OnInit {
  constructor(protected priceService: PriceService) {}

  pricelists: Map<string, Pricelist> = new Map<string, Pricelist>();

  @Input()
  pimProduct: Pim;

  public keyAsNumberAsc(a: KeyValue<string, any>, b: KeyValue<string, any>): number {
    return 0;
  }

  ngOnInit(): void {
    this.priceService
      .getPriceListOptions()
      .pipe(first())
      .subscribe(pricelists => {
        pricelists.forEach(p => {
          this.pricelists.set(p.id, p);
        });
      });
  }
}
