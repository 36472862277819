import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'encodeUri'
})
export class EncodeUriPipe implements PipeTransform {

  transform(value: string, type: 'encodeURI' | 'encodeURIComponent' = 'encodeURI', ...args: string[]): string {
    switch (type) {
      case 'encodeURIComponent':
        return encodeURIComponent(value);
      case 'encodeURI':
      default:
        return encodeURI(value);
    }    
  }

}
