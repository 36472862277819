import { Component, OnInit, Pipe, PipeTransform } from '@angular/core';
import { ActuatorService } from '../../../services/actuator/actuator.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';

@Component({
  selector: 'lib-actuator-info',
  templateUrl: './actuator-info.component.html',
  styleUrls: ['./actuator-info.component.css']
})
export class ActuatorInfoComponent implements OnInit {
  infoLinks = [];
  constructor(protected actuatorService: ActuatorService, protected backendInterceptor: BackendInterceptor) {}

  ngOnInit(): void {
    this.actuatorService.actuatorLinks().subscribe(links => {
      if (links._links) {
        // eslint-disable-next-line guard-for-in
        for (const key in links._links) {
          if (Object.prototype.hasOwnProperty.call(links._links, key)) {
            const element = links._links[key];
            const urlPath = 22; // cut out the localhost env link - e.g. http://127.0.0.1:2001/actuator"
            const elementHref = this.backendInterceptor.getBaseUrl().concat(element.href.substring(urlPath));
            this.infoLinks.push({ href: elementHref, name: key });
          }
        }
      }
    });
  }
}
