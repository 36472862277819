import { AfterViewInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { StandardStatisticsData } from '../../../models/statistics';
import { ChartDataset, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { differenceInMonths, parseISO } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-order-analytics-chart-table-horizontal',
  templateUrl: './order-analytics-chart-table-horizontal.component.html',
  styleUrls: ['./order-analytics-chart-table-horizontal.component.css']
})
export class OrderAnalyticsChartTableHorizontalComponent implements OnInit, OnChanges {
  @Input()
  data: StandardStatisticsData[];

  @Input()
  groupBy: string;

  @Input()
  tableOnly: boolean;

  @Input()
  heading: string;

  @Input()
  headerRows: string[];

  @Input()
  hideVerticalGridLines?: boolean = false;

  @Input()
  newTableLayout?: boolean = false;

  barChartData: ChartDataset[];
  barChartLabels: BaseChartDirective['labels'] = [];
  barChartOptions: any = {}; // todo
  barChartLegend = false;
  barChartType: ChartType = 'bar';

  loading: boolean = true;

  tableHeaderRowData: string[] = [];
  tableRowData: string[][] = [];

  constructor(protected translateService: TranslateService) { }

  ngOnInit() {
    this.barChartOptions = {
      backgroundColor: 'rgba(206,212,218, 0.6)',
      hoverBackgroundColor: 'rgba(206,212,218, 0.8)'
    };

    if (this.hideVerticalGridLines) {
      this.barChartOptions = {
        ...this.barChartOptions,
        scales: {
          ...this.barChartOptions?.scales,
          x: {
            ...this.barChartOptions?.scales?.x,
            grid: {
              ...this.barChartOptions?.scales?.x?.grid,
              display: false
            }
          }
        }
      };
    }

    const groupedData = this.groupData();
    this.buildGraphData(groupedData);
    this.buildTableData(groupedData);
    this.loading = false;
  }

  ngOnChanges() {
    const groupedData = this.groupData();
    this.buildGraphData(groupedData);
    this.buildTableData(groupedData);
    this.loading = false;
  }

  buildGraphData(groupedData: { [key: string]: StandardStatisticsData[] | any }[]) {
    const groupedDataValue = groupedData.map(element => {
      element.value = Math.round(
        element.elements.reduce((acc: number, curr: StandardStatisticsData) => {
          return acc + curr.orderRowTotalValueCurrency;
        }, 0)
      );
      element.count = Math.round(
        element.elements.reduce((acc: number, curr: StandardStatisticsData) => {
          return acc + curr.orderRowTotalQuantity;
        }, 0)
      );
      return element;
    });
    const chartData: number[] = [];
    const chartLabels: string[] = [];
    groupedDataValue.forEach(element => {
      chartData.push(element.value);
      if (this.heading === 'PRODUCT_CATEGORY' && element.key.toString() === 'null') {
        chartLabels.push(this.translateService.instant('ALL_CATEGORIES'));
      } else {
        chartLabels.push(element.key);
      }
    });
    this.barChartData = [
      {
        data: chartData
      }
    ];
    this.barChartLabels = chartLabels;
  }

  buildTableData(groupedData: { [key: string]: StandardStatisticsData[] | any }[]) {
    this.tableHeaderRowData = [];
    this.tableRowData = [];

    for (const [index, row] of this.headerRows.entries()) {
      if (index === 0) {
        this.tableHeaderRowData.push(row);
        for (let data of groupedData) {
          if (this.heading === 'PRODUCT_CATEGORY' && data.key.toString() === 'null') {
            this.tableHeaderRowData.push(this.translateService.instant('ALL_CATEGORIES'));
          } else {
            this.tableHeaderRowData.push(data.key.toString());
          }
        }
        continue;
      }

      const newDataRow: string[] = [];
      newDataRow.push(row);
      for (let data of groupedData) {
        if (row === 'SALES_AMOUNT' || row === 'SALES') {
          newDataRow.push(data?.value || '0');
        } else if (row === 'QTY') {
          newDataRow.push(data?.count || '0');
        } else if (row === 'NUMBER_OF_ORDERS') {
          newDataRow.push(this.getNumberOfOrders(data));
        }
      }

      this.tableRowData.push(newDataRow);
    }
  }

  getNumberOfOrders(data: { [key: string]: StandardStatisticsData[] | any }): string {
    const idArray: string[] = data?.elements?.map(e => e?.orderId);
    const uniqueIdsSet: Set<string> = new Set(idArray);
    return uniqueIdsSet.size.toString();
  }

  protected groupData(): { [key: string]: StandardStatisticsData[] | any }[] {
    let filteredData = this.data;

    if (this.groupBy === 'productCategoryCode') {
      const currentDate = new Date();
      filteredData = this.data.filter(item => {
        const orderCreationDate = parseISO(item.orderCreationDate);

        return differenceInMonths(currentDate, orderCreationDate) < 12;
      });
    }

    const result = [];
    const groupedData = filteredData.reduce((acc, curr) => {
      if (this.groupBy === 'orderCreationDateYear') {
        const quarterKey = 'orderCreationDateYearQuarter';
        const year = curr[quarterKey].split('-')[0];
        (acc[year] = acc[year] || []).push(curr);
      } else {
        (acc[curr[this.groupBy]] = acc[curr[this.groupBy]] || []).push(curr);
      }
      return acc;
    }, {});
    for (const key of Object.keys(groupedData)) {
      result.push({
        key,
        elements: groupedData[key]
      });
    }

    result.sort((p1, p2) => {
      const key1: string = p1.key;
      const key2: string = p2.key;
      return key1.localeCompare(key2);
    });

    if (this.groupBy === 'orderCreationDateYearMonth') {
      const result12Months = [];
      for (const month of this.getLast12Months()) {
        result12Months.push({
          key: month,
          elements: []
        });
      }

      for (const res of result) {
        const index = result12Months.findIndex(r => r?.key === res?.key);
        if (index > -1) {
          result12Months.splice(index, 1, res);
        }
      }

      return result12Months;
    }
    return result;
  }

  getLast12Months() {
    const currentDate = new Date();
    const months = [];

    for (let i = 11; i >= 0; i--) {
      let month = currentDate.getMonth() - i;
      let year = currentDate.getFullYear();

      if (month < 0) {
        // Adjust for months in the previous year
        month += 12;
        year -= 1;
      }

      const monthString = String(month + 1).padStart(2, '0'); // Add leading zero if needed
      const yearString = String(year);

      const formattedDate = `${yearString}-${monthString}`;
      months.push(formattedDate);
    }

    return months;
  }

  isNotNumber(value: any): boolean {
    return isNaN(value);
  }
}
