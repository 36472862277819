import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../../models/customer';

@Component({
  selector: 'lib-order-change-customer-confirmation-modal',
  templateUrl: './order-change-customer-confirmation-modal.component.html',
  styleUrls: ['./order-change-customer-confirmation-modal.component.css']
})
export class OrderChangeCustomerConfirmationModalComponent implements OnInit {
  currentCustomer: Customer;
  nextCustomer: Customer;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {}
}
