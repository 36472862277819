import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { CustomerService } from '../../services/customers/customer.service';
import { Customer } from '../../models';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, tap, first, mergeMap, switchMap, filter } from 'rxjs';
import { NgbTypeaheadSelectItemEvent, NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';

@Component({
  selector: 'lib-customer-select-dropdown',
  templateUrl: './customer-select-dropdown.component.html',
  styleUrls: ['./customer-select-dropdown.component.css']
})
export class CustomerSelectDropdownComponent implements OnInit {
  @Input()
  private maxCustomersInList = 20;
  @Output()
  protected customerSelected = new EventEmitter<Customer>();

  @ViewChild('instance') instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  public customers?: Customer[];
  public model: any;

  public selectedCustomer: Observable<Customer>;

  constructor(protected customerService: CustomerService, protected selectedCustomerService: SelectedCustomerService) {}

  ngOnInit() {
    this.selectedCustomer = this.selectedCustomerService.getSelectedCustomer();
    this.selectedCustomer.subscribe(customer => {
      this.model = this.formatter(customer);
    });
    this.customerService
      .getCustomers()
      .pipe(first())
      .subscribe(customers => (this.customers = customers));
  }

  search = (searchTerm$: Observable<string>) => {
    const debouncedText$ = searchTerm$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => {
        return term === '' || !term
          ? this.customers
          : this.customers.filter(customer => this.filterCustomer(customer, term));
      }),
      map(customerList => customerList.slice(0, this.maxCustomersInList))
    );
  };

  formatter = (result: Customer) => result.id + ' ' + result.name;

  onItemSelect(event: NgbTypeaheadSelectItemEvent) {
    const cust = event.item as Customer;
    this.model = this.formatter(cust);
    this.selectedCustomerService.selectCustomer(cust.id);
    event.preventDefault();
    this.customerSelected.emit(cust);
  }

  filterCustomer(customer: Customer, searchTerm: string): boolean {
    const splitString = searchTerm
      .toLowerCase()
      .split(' ')
      .filter(searchString => searchString.length > 0);
    let matchAll = true;
    splitString.forEach(search => {
      let matchFound = false;
      matchFound = matchFound || customer.id.toLowerCase().indexOf(search) >= 0;
      matchFound = matchFound || customer.name.toLowerCase().indexOf(search) >= 0;
      matchAll = matchAll && matchFound;
    });
    return matchAll;
  }

  selectCurrentSelectedCustomer() {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(first())
      .subscribe(customer => {
        if (customer) {
          // this.selectedCustomer = customer;
          this.model = this.formatter(customer);
        }
      });
  }
}
