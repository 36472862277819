import { CustomerState } from './types';
import { Action } from '@ngrx/store';
import { CustomerActionTypes, CustomerSelected } from './actions';

const initialState: CustomerState = {
  selectedCustomer: undefined,
  doneLoading: false
};

export function reducers(state: CustomerState = initialState, action: Action): CustomerState {
  switch (action.type) {
    case CustomerActionTypes.CustomerSelected: {
      const { payload } = action as CustomerSelected;
      return {
        ...state,
        selectedCustomer: payload,
        doneLoading: true
      };
    }
    case CustomerActionTypes.ClearSelectedCustomer: {
      return {
        ...state,
        selectedCustomer: null,
        doneLoading: true
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
