import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, DoCheck } from '@angular/core';
import { RenderFilter, RenderFilterSubValueList, SubRenderFilter } from '../types';
import { filter } from 'rxjs';
import { parse } from 'date-fns';
import { ActivatedRoute, Params, Router } from '@angular/router';

const FILTER_PARAM_SPLIT_STRING1 = '__|__'; // BETWEEN FILTERS
const FILTER_PARAM_SPLIT_STRING2 = '__:__'; // BETWEEN FILTER NAME AND SELECTIONS
const FILTER_PARAM_SPLIT_STRING3 = '_____'; // BETWEEN FILTER SELECTIONS
@Component({
  selector: 'lib-selected-filter',
  templateUrl: './selected-filter.component.html',
  styleUrls: ['./selected-filter.component.css']
})
export class SelectedFilterComponent implements OnChanges {
  @Input()
  public renderFilters: RenderFilter[];

  @Input()
  public topFilter = false;

  public selectedOptions: { filterName: string; valueId: string; optionName: string; valueIdRange: string[] }[];
  public selectedOptionsGroupByFilter: { [filterName: string]: { filterName: string; valueId: string; optionName: string; valueIdRange: string[] }[] };

  @Output()
  deselectAll = new EventEmitter<void>();

  @Output()
  deselectValue = new EventEmitter<{ filterName: string; valueId: string }>();

  @Output()
  deselectValueRange = new EventEmitter<{ filterName: string; valueIdRange: string[] }>();

  showRangeSelectedFilter = [];

  @Input()
  public showFilterTitle: 'minimal' | 'group';

  constructor(private activatedRoute: ActivatedRoute, private router: Router) { }

  clickRemoveFilter(option: { filterName: string; valueId: string; optionName: string; valueIdRange: string[] }) {
    if (option.valueIdRange && option.valueIdRange.length > 0) {
      this.deselectValueRange.emit({ filterName: option.filterName, valueIdRange: option.valueIdRange });
    } else {
      this.deselectValue.emit({ filterName: option.filterName, valueId: option.valueId });
    }
  }

  clickRemoveDateFilter(filter) {
    const paramsFilter = this.activatedRoute.snapshot.queryParamMap.get('filters');
    if (paramsFilter) {
      const filters = paramsFilter as string;
      const index = filters.indexOf(filters);
      const indexValues = index + filter.name.length;
      let values = filters.substring(indexValues, filters.length);
      if (values.indexOf('__:__') > -1) {
        const removedfilters = filters.replace(values, "");
        this.router.navigate(
          [],
          {
            relativeTo: this.activatedRoute,
            queryParams: { filters: removedfilters },
            queryParamsHandling: 'merge',
          });
      }
    }
    this.deselectValueRange.emit({ filterName: filter.name, valueIdRange: null });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!this.renderFilters) {
      return;
    }
    this.showRangeSelectedFilter = [];
    const filterOptions = this.renderFilters
      .map(renderFilter => {
        const valueList = renderFilter.valueList;
        if (renderFilter.type === 'RangeConfigBaseFilter' || renderFilter.type === 'dateRangeFilter') {
          const filteredValueListRange = valueList
            .filter(value => !!value.selected)
            .map(value => ({ ...value, filterName: renderFilter.name }));
          if (filteredValueListRange.length > 0) {
            this.showRangeSelectedFilter.push({ name: renderFilter.name, show: false });
            const max = filteredValueListRange.reduce((prev, current) =>
              parseFloat(prev.valueId) > parseFloat(current.valueId) ? prev : current
            );
            const min = filteredValueListRange.reduce((prev, current) =>
              parseFloat(prev.valueId) < parseFloat(current.valueId) ? prev : current
            );
            const rangeValue = {
              ...max,
              valueName: min.valueName + '-' + max.valueName,
              valueId: '',
              valueIdRange: filteredValueListRange.map(v => v.valueId)
            };
            if (renderFilter.type === 'dateRangeFilter') {

              rangeValue.valueName = this.formartDateToButton(renderFilter);
            }
            return [rangeValue];
          } else if (renderFilter.minValue && renderFilter.maxValue) {
            this.showRangeSelectedFilter.push({ name: renderFilter.name, show: true, text: this.formartDateToButton(renderFilter) });

          }
          return [];
        }
        const filteredValueList = valueList
          .filter(value => !!value.selected)
          .map(value => ({ ...value, filterName: renderFilter.name, valueIdRange: null }));
        let subFilteredValueList = [];
        if (renderFilter.type === 'SubSimpleConfigBaseFilter') {
          // Show selected sub values
          subFilteredValueList = valueList
            .filter(value => !!value.selected)
            .map(value => (value as RenderFilterSubValueList).subValueList)
            .flat()
            .filter(value => !!value.selected)
            .map(value => ({ ...value, filterName: (renderFilter as SubRenderFilter).subName, valueIdRange: null }));
        }
        return [
          ...filteredValueList,
          ...subFilteredValueList
        ];
      })
      .filter(renderFilter => renderFilter.length > 0)
      .reduce((prev, curr) => prev.concat(curr), []);
    this.selectedOptions = filterOptions
      .map(filterOption => ({
        filterName: filterOption.filterName,
        valueId: filterOption.valueId,
        valueIdRange: filterOption.valueIdRange,
        optionName: filterOption.valueName
      }))
      .sort((a, b) => a.optionName.localeCompare(b.optionName));
    if (this.showFilterTitle === 'group') {
      this.selectedOptionsGroupByFilter = this.selectedOptions.reduce((prev, curr) => {
        if (!prev[curr.filterName]) {
          prev[curr.filterName] = [curr];
        } else {
          prev[curr.filterName].push(curr);
        }
        return {
          ...prev
        }
      }, {});
    }
  }

  formartDateToButton(renderFilter) {
    const minDate = parse(renderFilter.minValue.toString(), 'yyMMdd', new Date());
    const minDay = minDate.getDate().toString().padStart(2, '0');
    const minMonth = (minDate.getMonth() + 1).toString().padStart(2, '0');
    const minYear = minDate.getFullYear();
    const maxDate = parse(renderFilter.maxValue.toString(), 'yyMMdd', new Date());
    const maxDay = maxDate.getDate().toString().padStart(2, '0');
    const maxMonth = (maxDate.getMonth() + 1).toString().padStart(2, '0');
    const maxYear = maxDate.getFullYear();
    return `${minDay}/${minMonth}/${minYear} - ${maxDay}/${maxMonth}/${maxYear} `;
  }
}
