<table class="table table-borderless table-striped table-hover">
  <thead>
    <tr>
      <th scope="col" translate>Id</th>
      <th scope="col" translate>ORDER_DATE</th>
      <th scope="col" translate>TOTAL</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of data" [routerLink]="'/orders/' + order.id">
      <td>{{ order.id }}</td>
      <td>{{ order.extra.oh.orddatum | date: dateUtilService.dateFormat }}</td>
      <td>{{ order.extra.oh.ohvbordsum | gungCurrency: order.extra.oh.valkod:'symbol-narrow':'1.2-2' }}</td>
    </tr>
  </tbody>
</table>
