import { Injectable } from '@angular/core';
import { Whiteboard } from '../types';
import { Observable, of } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { first, map } from 'rxjs/operators';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { WhiteboardService } from './whiteboard.service';
import { WhiteboardCreateModalComponent } from '../components/whiteboard-create-modal/whiteboard-create-modal.component';
import { Router } from '@angular/router';
import { Product } from '../../models';
import { ProductExportService } from '../../services/product-export/product-export.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardManagementActionButtonConfigService implements ActionButtonConfigService<Whiteboard> {
  constructor(
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected whiteboardService: WhiteboardService,
    protected router: Router,
    protected productExportService: ProductExportService
  ) {}

  getButtons(item: Whiteboard): Observable<ActionButton<Whiteboard>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        const actions = [];

        actions.push({
          title: 'LOAD_WHITEBOARD',
          displayAsButton: true,
          action: (whiteboard: Whiteboard) => {
            this.router.navigate(['whiteboard']).then(w => {
              // This was a temporary workaround to get the navigation to work when already on the url. Otherwise data would
              // not be loaded. This fixed that temporary. Previously was a window reload but this worked more fluently.
              this.router.navigate(['whiteboard', whiteboard.id]).then(w2 => {
                this.modalService.dismissAll();
              });
            });
          }
        });

        actions.push({
          title: 'CREATE_ORDER',
          displayAsButton: true,
          action: (whiteboard: Whiteboard) => {
            this.router.navigate(['products-whiteboard', whiteboard.id]).then(w => {
              this.modalService.dismissAll();
            });
          }
        });

        if (roles.includes('ADMIN')) {
          actions.push(
            {
              title: 'EDIT',
              displayAsButton: false,
              action: (whiteboard: Whiteboard) => {
                const modal = this.modalService.open(WhiteboardCreateModalComponent);
                modal.componentInstance.whiteboard = whiteboard;
                modal.componentInstance.isUpdate = true;
              }
            },
            {
              title: 'DELETE',
              displayAsButton: false,
              action: (whiteboard: Whiteboard) => {
                this.gungModalService
                  .openConfirmYesNoModal(undefined, this.translateService.instant('DELETE_WHITEBOARD_CONFIRM'), {
                    size: 'sm'
                  })
                  .then(
                    result => {
                      if (result) {
                        this.whiteboardService
                          .delete(whiteboard.id)
                          .pipe(first())
                          .subscribe(_ => {});
                      }
                    },
                    reason => {}
                  );
              }
            }
          );
        }

        actions.push({
          title: 'PRODUCT_EXPORT_XLSX',
          action: (whiteboard: Whiteboard) => {
            const itemList = this.convertWhiteboardToProductList(whiteboard);
            this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
            return of();
          }
        });
        actions.push({
          title: 'PRODUCT_EXPORT_IMG',
          action: (whiteboard: Whiteboard) => {
            const itemList = this.convertWhiteboardToProductList(whiteboard);
            this.productExportService.exportImg(itemList).pipe(first()).subscribe();
            return of();
          }
        });

        if (roles.includes('SALES')) {
          actions.push({
            title: 'PRODUCT_EXPORT_CSV',
            action: (whiteboard: Whiteboard) => {
              const itemList = this.convertWhiteboardToProductList(whiteboard);
              this.productExportService.exportCsv(itemList).pipe(first()).subscribe();
              return of();
            }
          });

          actions.push({
            title: 'PRODUCT_EXPORT_LINESHEET',
            action: (whiteboard: Whiteboard) => {
              const itemList = this.convertWhiteboardToProductList(whiteboard);
              this.productExportService.exportLinesheet(itemList).pipe(first()).subscribe();
              return of();
            }
          });
          actions.push({
            title: 'PRODUCT_EXPORT_PRICAT',
            action: (whiteboard: Whiteboard) => {
              const itemList = this.convertWhiteboardToProductList(whiteboard);
              this.productExportService.exportPricat(itemList).pipe(first()).subscribe();
              return of();
            }
          });
          actions.push({
            title: 'PRODUCT_EXPORT_PRODUCT_INFO',
            action: (whiteboard: Whiteboard) => {
              const itemList = this.convertWhiteboardToProductList(whiteboard);
              this.productExportService.exportProductInfo(itemList).pipe(first()).subscribe();
              return of();
            }
          });
          actions.push({
            title: 'PRODUCT_EXPORT_PRODUCT_STOCK',
            action: (whiteboard: Whiteboard) => {
              const itemList = this.convertWhiteboardToProductList(whiteboard);
              this.productExportService.exportProductStock(itemList).pipe(first()).subscribe();
              return of();
            }
          });
        }

        return actions;
      })
    );
  }

  private convertWhiteboardToProductList(whiteboard: Whiteboard): Product[] {
    const items: Product[] = [];

    whiteboard.sheets.forEach(sheet => {
      const converted = sheet.items.map(i => {
        return {
          id: i.productId
        } as Product;
      });

      items.push(...converted);
    });

    return items;
  }
}
