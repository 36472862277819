import { Injectable } from '@angular/core';
import { WhiteboardOption } from '../types';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardOptionsService {
  options: WhiteboardOption[];

  constructor() {
    // IMPORTANT: Currently the path is misleading as it doesnt allow nested fields.
    // Should be fixed if it becomes a problem. Did not have time to prioritize at the time of writing.
    this.options = [
      {
        id: 'showIds',
        value: false,
        label: 'SHOW_IDS',
        path: 'id',
        type: 'checkbox'
      },
      {
        id: 'showNames',
        value: false,
        label: 'SHOW_NAMES',
        path: 'name',
        type: 'checkbox'
      },
      {
        id: 'showRrp',
        value: false,
        label: 'SHOW_RRP',
        path: 'rrp',
        type: 'checkbox'
      }
    ];
  }
}
