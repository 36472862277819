<div>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row ids-copy-area">
      <div class="col-12">
        <textarea class="w-100" rows="5" [(ngModel)]="productIds"></textarea>
      </div>
      <div class="col-12 d-flex flex-row justify-content-between mt-2">
        <button type="button" class="btn btn-primary ean-replace-row-add" translate (click)="copyToList()">
          COPY_TO_LIST
        </button>
        <button type="button" class="btn btn-primary ean-replace-row-add" translate (click)="addId()">
          ADD_TO_LIST
        </button>
      </div>
    </div>
    <form [formGroup]="itemListForm" (ngSubmit)="submitData()">
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th translate>
                ID
              </th>
              <th translate><label class="switch">
                  <input id="selectAllSkip" type="checkbox" (change)="selectAllSkipItem($event)" />
                  <span class="slider round bg-primary"></span>
                </label>
                SKIP_VALIDATION
              </th>
              <th translate>
                <input id="selectAllCancelled" type="checkbox" (change)="selectAllCancelledItem($event)" />
                CANCELLED
              </th>
              <th translate>

              </th>
            </tr>
          </thead>
          <tbody formArrayName="itemList">
            <tr *ngFor="let idList of changeItemList.controls; let i=index">
              <div class="ean-table-row" [formGroupName]="i">
                <td><input class="form-control" type="text" formControlName="id" /></td>
                <td>
                  <input id="skipValidation" formControlName="skipValidation" type="checkbox" />
                </td>
                <td>
                  <input id="cancelled" formControlName="cancelled" type="checkbox" />
                </td>
                <td>
                  <button type="button" class="btn btn-warning pull-right" translate (click)="remove(i)">
                    REMOVE_FIELD
                  </button>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <button [disabled]="columnCount ==0" type="submit" class="btn btn-secondary" translate>
        SUBMIT
      </button>
    </form>
  </div>
</div>