<div class="row" *ngFor="let item of data; index as i; trackBy: trackByFn">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <b>
          <span translate>EDI_ITEM_{{ item.messageType }}</span> -
        </b>
        <span class="d-inline"
          >{{ item.id }} - {{ item.status }}
          <span (click)="viewError(item)"><i class="fas fa-question-circle"></i></span
        ></span>
        <lib-action-btn class="float-right" [item]="item" [config]="actionConfig"></lib-action-btn>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="FILE_NAME"
          [(ngModel)]="item.shortFileName"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="B24_ORDER_ID"
          [(ngModel)]="item.b24OrderId"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="BUYER_ORDER_ID"
          [(ngModel)]="item.buyerOrderid"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <!-- Order / Order Response -->
      <div class="col-12 col-md-4" *ngIf="item.isOrder || item.isOrderResponse">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ORDERS_IDS"
          [(ngModel)]="item.orderIds"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4" *ngIf="item.isOrder || item.isOrderResponse">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ORDER_CONTEXT_ID"
          [(ngModel)]="item.orderContextId"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <!-- Invoice -->
      <div class="col-12 col-md-4" *ngIf="item.isInvoice">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ITEM_DESPATCHADVICENUMBER"
          [(ngModel)]="item.despatchAdviceNumber"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4" *ngIf="item.isInvoice">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ITEM_SUPPLIERORDERNUMBER"
          [(ngModel)]="item.supplierOrderNumber"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <!-- DespatchAdvice -->
      <div data-ng-if="item.messageType=='DESADV'" class="col-xs-6 col-md-4 gung-gln-list-attribute-wrapper">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ORDER_ID"
          [(ngModel)]="item.orderId"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
