import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GungProductCrossReference } from '../models/gung-product-cross-reference';

@Injectable({
  providedIn: 'root'
})
export class PdfToCartSavedMappingsService {
  referenceType = 'PDF_IMPORT_SAVED_IDS';
  baseUrl = 'json/pdf-parser/cross-reference';
  constructor(protected http: HttpClient) {}

  getMappingsForCustomer(customerId: string): Observable<GungProductCrossReference[]> {
    const url = `${this.baseUrl}/customer/${this.referenceType}/${customerId}`;
    return this.http.get<GungProductCrossReference[]>(url);
  }

  saveCrossReference(crossReference: GungProductCrossReference): Observable<GungProductCrossReference> {
    const url = `${this.baseUrl}/save`;
    return this.http.put<GungProductCrossReference>(url, crossReference);
  }

  getAllMappings(): Observable<GungProductCrossReference[]> {
    const url = `${this.baseUrl}/${this.referenceType}`;
    return this.http.get<GungProductCrossReference[]>(url);
  }

  getReferenceType(): string {
    return this.referenceType;
  }

  deleteMapping(id: string): Observable<GungProductCrossReference> {
    const url = `${this.baseUrl}/${id}`;
    return this.http.delete<GungProductCrossReference>(url);
  }
}
