import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Notification, NotificationLink, NotificationType } from '../../models/gung-notification/gung-notification';

@Injectable({
  providedIn: 'root'
})
export class GungNotificationService {
  private notificationSubject: BehaviorSubject<Notification> = new BehaviorSubject<Notification>(null);

  public notify(title: string, message: string, type: NotificationType, link?: NotificationLink): void {
    this.notificationSubject.next({ title, message, type, link });
  }

  public getNotificationSubject(): Observable<Notification> {
    return this.notificationSubject;
  }
}
