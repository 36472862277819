import { Component, Input, OnInit } from '@angular/core';
import { Product, ProductMultiDimension } from '../../models/product';
import { ProductMatrixModalService } from '../../services/product-matrix-modal/product-matrix-modal.service';
import { ProductService } from '../../services/products/product.service';

@Component({
  selector: 'lib-buy-btn-matrix',
  templateUrl: './buy-btn-matrix.component.html',
  styleUrls: ['./buy-btn-matrix.component.css']
})
export class BuyBtnMatrixComponent implements OnInit {
  @Input()
  id: string;
  @Input()
  product?: Product;
  constructor(
    protected productMatrixModalService: ProductMatrixModalService,
    protected productService: ProductService
  ) {}

  ngOnInit(): void {
    if (!this.product) {
      this.productService.getProduct(this.id).subscribe(product => {
        this.product = product;
      });
    }
  }

  openMatrixModal(product: Product) {
    this.productMatrixModalService.openProductMatrixModal(
      this.getMultiDimensionModelIdOrDefaultId(product),
      { image: product.extra.images[0], id: product.id },
      product.id
    );
  }

  private getMultiDimensionModelIdOrDefaultId(product: Product): string {
    if (product.productType === 'multiDimension') {
      return (product as ProductMultiDimension).modelId;
    }
    return product.id;
  }
}
