import { Component, OnInit } from '@angular/core';
import { ExportDragProductService } from '../../services/export-drag-product.service';
import { DynamicPdfService } from '../../services/dynamic-pdf.service';

@Component({
  selector: 'lib-add-standalone-note',
  templateUrl: './add-standalone-note.component.html',
  styleUrls: ['./add-standalone-note.component.scss']
})
export class AddStandaloneNoteComponent implements OnInit {
  note: string;
  heading: string;
  selectedFont: string;

  fonts: { id: string; name: string }[] = [];

  constructor(
    protected exportDragProductService: ExportDragProductService,
    protected dynamicPdfService: DynamicPdfService
  ) {}

  ngOnInit(): void {
    this.dynamicPdfService.getFonts().subscribe(fonts => {
      this.fonts = fonts;
      if (fonts.length > 0) {
        this.selectedFont = fonts[0].id;
      }
    });
  }

  createNote(): void {
    this.exportDragProductService.setOneNote({ heading: this.heading, note: this.note, font: this.selectedFont });
    this.note = '';
  }
}
