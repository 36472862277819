import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-gung-viewer3d-modal',
  templateUrl: './gung-viewer3d-modal.component.html',
  styleUrls: ['./gung-viewer3d-modal.component.css']
})
export class GungViewer3dModalComponent implements OnInit {

  @Input() models: string[];

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
