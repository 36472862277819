<div class="row contrast-container product-detail" *ngIf="concept != null; else loading">
  <div class="col-xs-12 col-sm-6">
    <lib-image-carousel *ngIf="concept" [openExternal]="false" [zoom]="false" [images]="concept.extra.images" imageSize="800x800">
    </lib-image-carousel>
  </div>
  <div class="col-xs-12 col-sm-6">
    <h1>{{ concept.name }} - {{ concept.id }}</h1>
    <p *ngIf="concept.extra && concept.extra.i18n && concept.extra.i18n[currentLang] && concept.extra.i18n[currentLang].description">
      {{concept.extra.i18n[currentLang].description}}
    </p>
    <!--TODO add dynamic properties for the concept here-->
  </div>
</div>

<ng-template #cloudPimFields>
  <lib-cloud-pim-product-fields [product]="concept"></lib-cloud-pim-product-fields>
</ng-template>
<ng-template #nan></ng-template>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
