import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { CartRow } from '../../../../state/cart/types';
import { first } from 'rxjs';
import { CartService } from '../../../../services/cart/cart.service';

@Component({
  selector: 'lib-assign-order-rows',
  templateUrl: './assign-order-rows.component.html',
  styleUrls: ['./assign-order-rows.component.css']
})
export class AssignOrderRowsComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected cartService: CartService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.cartService
        .getCurrentCart()
        .pipe(first())
        .subscribe(c => this.handleCart(c));
    });
  }

  protected handleCart(rows: CartRow[]) {
    this.stepDone.emit({
      ...this.checkout,
      rows: rows.map(r => ({
        id: r.productId,
        partialId: r.productPartialId,
        targetStockId: r.targetStockId,
        quantity: r.qty,
        extra: r.extra || {},
        rownr: r.rownr
      }))
    });
  }

  public isVisible() {
    return false;
  }
}
