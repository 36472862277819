import { Component, OnInit } from '@angular/core';
import { UsersListConfigService } from '../../../services/users-list-config/users-list-config.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { User } from '../../../state/auth/types';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-manage-users-wrapper',
  templateUrl: './manage-users-wrapper.component.html',
  styleUrls: ['./manage-users-wrapper.component.css']
})
export class ManageUsersWrapperComponent implements OnInit {
  public listUser = true;

  backendBaseUrl = this.backendInterceptor.getBaseUrl();

  constructor(
    public usersListConfigService: UsersListConfigService,
    protected modalService: NgbModal,
    protected router: Router,
    protected backendInterceptor: BackendInterceptor,
    protected gungModalService: GungModalService
  ) {}

  ngOnInit() {}

  openCreateUser(): void {
    this.gungModalService.createUser({}, false).then(
      (user: User) => {
        if (!!user) {
          this.router.navigate(['/admin/users-details/' + user.username]);
        }
      },
      reason => {}
    );
  }
}
