<div class="form-group row {{extraFormClass}}">
  <!-- [ngClass]="{labelClass: labelClass, 'col-12 col-sm-4' : !labelClass'font-weight-bold': labelBold}" -->
  <label [for]="inputId" *ngIf="!suppressLabel" class="col-form-label" [ngClass]="labelClass || 'col-12 col-sm-4'">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
    <span *ngIf="footnote" class="ml-1">*</span>
  </label>
  <div class="col">
    <input
      type="number"
      #inputNumber
      [class]="extraInputClass"
      [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
      [readonly]="renderStatic"
      [id]="inputId"
      [name]="inputId"
      [required]="required"
      [disabled]="disabled"
      [ngModelOptions]="{ updateOn: 'blur' }"
      [max]="maxValue"
      [min]="minValue"
      [step]="stepAmount"
      [placeholder]="placeholder | translate"
      [(ngModel)]="value"
    />
  </div>
</div>
