import { Component, EventEmitter, Inject, Input, OnChanges, OnInit, Optional, Output, QueryList, SimpleChanges, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { isValid, setYear, setMonth, getDaysInMonth, getDay, isSameDay, min, max, addYears, differenceInYears, isSameMonth, isSameYear, getISOWeek, subYears } from 'date-fns';
import { DateUtilService } from '../../services/date-util/date-util.service';
import { CdkDragDrop, CdkDropListGroup, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { CommonModalService } from '../../services/common-modal/common-modal.service';
import { CommonNavigationConfig } from '../../models/common-navigation-config';

export interface GungCalendarItem {
  id: string,
  date: Date,
  heading: string,
  action?(): void,
  link?: string
}

export interface GungCalendarDay {
  day: number,
  showMore?: boolean,
  items?: GungCalendarItem[]
}

export interface GungCalendarWeek {
  week: number,
  items?: GungCalendarDay[]
}

export interface OptionsListCalendar {
  id: number;
  name: string;
  numberOfItems?: number;
}

export interface ChangeDateEvent {
  id: string;
  date: Date
}

enum GungCalendarMonths {
  January = 0,
  February = 1,
  March = 2,
  April = 3,
  May = 4,
  June = 5,
  July = 6,
  August = 7,
  September = 8,
  October = 9,
  November = 10,
  December = 11,
}

@Component({
  selector: 'lib-gung-calendar-view',
  templateUrl: './gung-calendar-view.component.html',
  styleUrls: ['./gung-calendar-view.component.scss']
})
export class GungCalendarViewComponent implements OnInit, OnChanges {
  @Input() calendarItems: GungCalendarItem[];
  @Input() selectedYear?: number;
  @Input() selectedMonth?: GungCalendarMonths;
  @Input() showNumberItemsMonthYear?: boolean = true;
  @Input() showWeekNumbers?: boolean = true;

  // If this field is enabled, it's respective output must be used to perform the action
  @Input() enableCreateItem?: boolean = false;
  @Output() createItemEvent = new EventEmitter<Date>();
  
  // If this field is enabled, it's respective output must be used to perform the action
  @Input() enableChangeDate?: boolean = false;
  @Output() changeDateEvent = new EventEmitter<ChangeDateEvent>();

  // If this field is enabled, it's respective output must be used to perform the action
  @Input() enableDragDropChangeDate?: boolean = false;
  @Output() dragDropEvent = new EventEmitter<ChangeDateEvent>();

  
  gungCalendarFirstWeekday: string = this.environment.gungCalendarFirstWeekday || 'Monday';

  isLoading: boolean = true;
  public optionsListYears: OptionsListCalendar[] = [];
  public optionsListMonths: OptionsListCalendar[] = [
    {id: 0, name: 'JANUARY', numberOfItems: 0},
    {id: 1, name: 'FEBRUARY', numberOfItems: 0},
    {id: 2, name: 'MARCH', numberOfItems: 0},
    {id: 3, name: 'APRIL', numberOfItems: 0},
    {id: 4, name: 'MAY', numberOfItems: 0},
    {id: 5, name: 'JUNE', numberOfItems: 0},
    {id: 6, name: 'JULY', numberOfItems: 0},
    {id: 7, name: 'AUGUST', numberOfItems: 0},
    {id: 8, name: 'SEPTEMBER', numberOfItems: 0},
    {id: 9, name: 'OCTOBER', numberOfItems: 0},
    {id: 10, name: 'NOVEMBER', numberOfItems: 0},
    {id: 11, name: 'DECEMBER', numberOfItems: 0},
  ];
  mappedCalendarMonth: GungCalendarWeek[] = [];
  selectedDay: { day: number, date: string, items: GungCalendarItem[] } = undefined;
  todayYear: number;
  todayMonth: number;
  todayDay: number;

  @ViewChildren(CdkDropListGroup) dropListGroups: QueryList<CdkDropListGroup<any>>;

  numberSubYears: number = 0;
  numberAddYears: number = 10;

  public gungCalendarWeekdayOrder: number[] = this.getOrderOfWeekdaysAsNumbers(this.gungCalendarFirstWeekday)
  public weekdayTranslationMap: {[s:string]: {full: string, abr: string}} = {
    0: { full: 'SUNDAY', abr: 'SUNDAY_ABREVIATION' },
    1: { full: 'MONDAY', abr: 'MONDAY_ABREVIATION' },
    2: { full: 'TUESDAY', abr: 'TUESDAY_ABREVIATION' },
    3: { full: 'WEDNESDAY', abr: 'WEDNESDAY_ABREVIATION' },
    4: { full: 'THURSDAY', abr: 'THURSDAY_ABREVIATION' },
    5: { full: 'FRIDAY', abr: 'FRIDAY_ABREVIATION' },
    6: { full: 'SATURDAY', abr: 'SATURDAY_ABREVIATION' }
  }
  
  constructor(
    protected router: Router,
    protected dateUtilService: DateUtilService,
    protected commonModalService: CommonModalService,
    @Optional()
    @Inject('environment')
    protected environment: CommonNavigationConfig
  ) { }

  ngOnInit(): void {
    this.buildSelectOptions();
    this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.calendarItems) {
      this.isLoading = true;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    }
  }

  buildSelectOptions() {
    const itemDateList = this.calendarItems?.map(i => i?.date) || [];
    const minDate: Date = subYears(isValid(min(itemDateList)) ? min(itemDateList) : new Date(), this.numberSubYears);
    const maxDate: Date = addYears(isValid(max(itemDateList)) ? max(itemDateList) : new Date(), this.numberAddYears);
    const diferenceYears: number = differenceInYears(maxDate, minDate);
    const allYears = Array.from({ length: diferenceYears + 1 }, (_, index) =>
      minDate.getFullYear() + index
    );

    this.optionsListYears = [];
    for (const year of allYears) {
      this.optionsListYears.push({
        id: year,
        name: year.toString()
      });
    }
  }

  populateNumberOfItems() {
    if (!this.showNumberItemsMonthYear) {
      return;
    }
    const itemDateList = this.calendarItems?.map(i => i?.date);

    for (const month of this.optionsListMonths) {
      const testDate = new Date(this.selectedYear, month.id);
      const count = itemDateList?.reduce((acc, date) => {
        if(isSameMonth(date, testDate)) {
          acc++;
        }
        return acc;
      }, 0);
      month.numberOfItems = count;
    }

    for (const year of this.optionsListYears) {
      const testDate = new Date(year.id, 0);
      const count = itemDateList?.reduce((acc, date) => {
        if(isSameYear(date, testDate)) {
          acc++;
        }
        return acc;
      }, 0);
      year.numberOfItems = count;
    }
  }

  goToMonth(value: GungCalendarMonths) {
    this.selectedMonth = value;
    this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
  }

  goToNextMonth() {
    const nextMonthValue = this.selectedMonth + 1;
    if (nextMonthValue > 11) {
      this.selectedMonth = GungCalendarMonths.January;
      this.goToNextYear();
    } else {
      this.selectedMonth = nextMonthValue;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    }
  }

  goToPreviousMonth() {
    const previousMonthValue = this.selectedMonth - 1;
    if (previousMonthValue < 0) {
      this.selectedMonth = GungCalendarMonths.December;
      this.goToPreviousYear();
    } else {
      this.selectedMonth = previousMonthValue;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    }
  }

  goToYear(value: number) {
    this.selectedYear = value;
    this.selectedMonth = 0;
    this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
  }

  goToNextYear() {
    if (this.optionsListYears.find(o => o.id === this.selectedYear + 1)) {
      this.selectedYear = this.selectedYear + 1;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    } else {
      this.numberAddYears = this.numberAddYears + 10;
      this.buildSelectOptions();
      this.selectedYear = this.selectedYear + 1;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    }
  }

  goToPreviousYear() {
    if (this.optionsListYears.find(o => o.id === this.selectedYear - 1)) {
      this.selectedYear = this.selectedYear - 1;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    } else {
      this.numberSubYears = this.numberSubYears + 10;
      this.buildSelectOptions();
      this.selectedYear = this.selectedYear - 1;
      this.mapMonthCalendarItems(this.calendarItems, this.selectedMonth, this.selectedYear);
    }
  }
  
  mapMonthCalendarItems(calendarItems: GungCalendarItem[], month: number, year: number) {
    this.selectedDay = undefined;
    this.mappedCalendarMonth = [];

    const today = new Date();
    this.todayYear = today.getFullYear();
    this.todayMonth = today.getMonth();
    this.todayDay = today.getDate();

    // Check if provided date is valid, if not then set todays month and year
    if (!this.isValidDate(month, year)) {
      this.selectedMonth = today.getMonth();
      this.selectedYear = today.getFullYear();
      month = this.selectedMonth;
      year = this.selectedYear;
    }

    // Populate select dropdowns with number of items if enabled
    this.populateNumberOfItems();

    // Get weekday offset
    let currentArray: GungCalendarDay[] = [];
    const startWeekDay = Number(this.dateUtilService.getGungWeekDay(new Date(year, month, 1), this.gungCalendarFirstWeekday));
    for (let i = 0; i < startWeekDay; i++) {
      currentArray.push({ day: 0 });
    }

    // Get number of days in current month to ensure it fills whole table row
    let currentMonthDays: number = getDaysInMonth(new Date(year, month));

    for (let i = 1; i <= currentMonthDays; i++) {
      const testDate = new Date(year, month, i);

      if (i > 0 && i <= currentMonthDays) {
        currentArray.push({
          day: i,
          showMore: false,
          items: calendarItems?.filter(item => isSameDay(item?.date, testDate)) || []
        });
      } else {
        currentArray.push({ day: 0 });
      }

      if (currentArray.length === 7) {
        this.mappedCalendarMonth.push({
          week: getISOWeek(testDate),
          items: currentArray
        });
        currentArray = [];
      } else if (i === currentMonthDays) {
        if (currentArray.length === 7) {
          this.mappedCalendarMonth.push({
            week: getISOWeek(testDate),
            items: currentArray
          });
          currentArray = [];
        } else {
          while (currentArray.length < 7) {
            currentArray.push({ day: 0 });
          }
          this.mappedCalendarMonth.push({
            week: getISOWeek(testDate),
            items: currentArray
          });
          currentArray = [];
        }
      }
    }

    this.isLoading = false;
  }

  isValidDate(month: number, year: number) {
    let date = setYear(new Date(year, 0), year);
    date = setMonth(date, month);

    return isValid(date);
  }

  selectDay(day: GungCalendarDay) {
    if (day.day === 0) {
      this.selectedDay = undefined;
      return;
    }

    this.selectedDay = {
      day: day.day,
      date: this.dateUtilService.getFormattedDateString(new Date(this.selectedYear, this.selectedMonth, day.day)),
      items: day.items
    };
  }

  onDrop(event: CdkDragDrop<any[]>, day: number) {
    if (day === 0) {
      return;
    }

    if (event.previousContainer === event.container) {
      moveItemInArray(
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex
      );

      const selectedDate: Date = new Date(this.selectedYear, this.selectedMonth, day);
      this.dragDropEvent.emit({date: selectedDate, id: event?.container?.data?.[event?.currentIndex].id});
    }
    this.dropListGroups.forEach((dropListGroup) => dropListGroup.disabled = false);
  }

  triggerEditEvent(day: number, id: string) {
    const selectedDate: Date = new Date(this.selectedYear, this.selectedMonth, day);
    this.commonModalService.openDatepickerModal('SELECT_DESIRED_DATE', 'EDIT_DATE', selectedDate).then(
      result => {
        if (result.selectedDate) {
          this.changeDateEvent.emit({date: result.selectedDate, id: id});
        }
      },
      reject => {}
    );
  }

  triggerCreateEvent(day: number) {
    const selectedDate: Date = new Date(this.selectedYear, this.selectedMonth, day);
    this.createItemEvent.emit(selectedDate);
  }

  public getOrderOfWeekdaysAsNumbers(firstWeekDay: string = 'Monday'): number[] {
    const weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const firstWeekDayIndex = weekdays.indexOf(firstWeekDay);
    let weekdayNumbers: number[] = Array(7).fill(0).map((_, index) => (index));
    const splitPart = weekdayNumbers.slice(0, firstWeekDayIndex);
    const restPart = weekdayNumbers.slice(firstWeekDayIndex);
    return [...restPart, ...splitPart];
  }
}
