<ul
  ngbNav
  #nav="ngbNav"
  [class]="'nav-tabs ' + justifyClass"
  [(activeId)]="activeId"
  [destroyOnHide]="destroyOnHide"
  [orientation]="orientation"
  (navChange)="select($event.nextId)"
  [animation]="false"
>
  <li *ngFor="let tab of tabs" [ngbNavItem]="tab.id" [disabled]="tab.disabled">
    <!-- 
    <a ngbNavLink>
      <b>{{ tab.title }}</b>
      <ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef || null"></ng-template>
    </a>
    -->
    <button ngbNavLink type="button" class="btn btn-link w-100">
      <b>{{ tab.title }}</b>
      <ng-template [ngTemplateOutlet]="tab.titleTpl?.templateRef || null"></ng-template>
    </button>
    <ng-template ngbNavContent>
      <ng-template [ngTemplateOutlet]="tab.contentTpl?.templateRef || null"></ng-template>
    </ng-template>
  </li>
</ul>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
