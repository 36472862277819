<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_QUOTATION</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="name" class="control-label" translate>NAME</label>
        <input class="form-control" type="text" name="name" id="name" [(ngModel)]="name" ngbAutofocus />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="description" lass="control-label" translate>DESCRIPTION</label>
        <textarea
          class="form-control"
          type="text"
          name="description"
          id="description"
          [(ngModel)]="description"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-auto" (click)="saveCart()" translate>SAVE_QUOTATION</button>
</div>
