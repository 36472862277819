import { Component, OnDestroy, OnInit } from '@angular/core';
import { debounceTime, distinctUntilChanged, Subject } from 'rxjs';
import { SearchComponent } from '../types';
@Component({
  selector: 'lib-filter-list-search',
  templateUrl: './filter-list-search.component.html',
  styleUrls: ['./filter-list-search.component.css'],
  host: {
    '[attr.custom-id]': 'true'
  }
})
export class FilterListSearchComponent extends SearchComponent implements OnInit, OnDestroy {
  public searchTerm: string;
  public searchTermUpdate: Subject<string> = new Subject<string>();;

  ngOnInit() {
    this.reset();
    this.searchTermUpdate.pipe(
      debounceTime(500),
      distinctUntilChanged())
      .subscribe(value => {
        this.searchUpdated.emit(value)
      });

    if (this.initSearchTerm) {
      this.searchTerm = this.initSearchTerm;
      this.searchTermUpdate.next(this.initSearchTerm);
    }
  }

  ngOnDestroy(): void {
    // this.searchTermUpdate.next(''); // Should we clear the search term?
    this.searchTermUpdate.unsubscribe();
  }

  reset(): void {
    this.searchTerm = '';
  }
}
