export interface GungFlow {
  id: string;
  name: string;

  isActiveRole: { [key: string]: boolean };
  isActiveGroup: { [key: string]: boolean };

  isDefault: boolean;
  isReturnOrder: boolean;
  isPreorder: boolean;
  inventory: boolean;
  displayOnly: boolean;
  useAvailabilities: boolean;
  requireAvailability: boolean;
  disableNonAvailable: boolean;

  productConditions: { [key: string]: any }[];

  deliveryStartDate: Date | string;
  deliveryEndDate: Date | string;

  orderStartDate: Date | string;
  orderEndDate: Date | string;
  priceDate?: Date | string;
  rrpDate?: Date | string;

  orderParams: { [key: string]: any };
  multidrop?: boolean;
  drops?: Drops[];
  extra?: { [s: string]: any };
  useCustomerIdForAvailabilities?: boolean;
  requireCurrentAvailability?: boolean;
}

export interface GungFlowState {
  selectedFlow?: GungFlow;
  doneLoading: boolean;
}

export interface Drops {
  firstDeliveryDate: Date | string;
  id: string;
  name: string;
  productConditions: { [key: string]: any }[];
}

export const flowFeatureName = 'gungFlow';
