import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-activity-internal-wrapper',
  templateUrl: './activity-internal-wrapper.component.html',
  styleUrls: ['./activity-internal-wrapper.component.css']
})
export class ActivityInternalWrapperComponent implements OnInit {
  showList: boolean = true;

  constructor(protected modalService: NgbModal, protected gungModalService: GungModalService) {}

  ngOnInit() {}

  openCreateModal() {
    this.gungModalService.openCreateActivityModal().then(
      result => {},
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }
}
