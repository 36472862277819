import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Customer } from '../../models';
import { ListItemRendererComponent } from 'gung-list';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { Router } from '@angular/router';
import { CustomerActionConfigService } from '../../services/customer-action-config.service';

@Component({
  selector: 'lib-customer-list-table',
  templateUrl: './customer-list-table.component.html',
  styleUrl: './customer-list-table.component.scss'
})
export class CustomerListTableComponent extends ListItemRendererComponent<Customer> implements OnInit, AfterViewInit {
  constructor(
    public selectCustomerService: SelectedCustomerService,
    protected router: Router,
    public actionButtonsConfig?: CustomerActionConfigService
  ) {
    super();
  }

 

  sort(){
    return (a, b) => a.customerId?.localeCompare(b.customerId);
  }

  sortName(){
    return (a, b) => a.name?.localeCompare(b.name);
  }

  ngOnInit() { }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  selectCustomer(id) {
    this.router.navigate(['customers', id]);
    this.selectCustomerService.selectCustomer(id);
  }

  selectCustomerWithoutNavigate(id) {
    this.selectCustomerService.selectCustomer(id);
  }
}