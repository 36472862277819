import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
/* import { ChangeContext, Options, PointerType } from 'ngx-slider-v2'; */
import { debounceTime } from 'rxjs';
import { Subject } from 'rxjs';


@Component({
  selector: 'lib-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RangeSliderComponent implements OnInit, AfterViewInit, OnDestroy {
  public thumbsize = 14;
  @Input()
  public step = 1;

  @Input()
  maxRange: { max: number; min: number } = { max: 100, min: 0 };

  @Input()
  selectedRange: { max: number; min: number };

  @Input()
  unit?: string;

  @Output()
  selectedRangeOutput: EventEmitter<{ max: number; min: number }> = new EventEmitter<{ max: number; min: number }>();

  private debouncer: Subject<{ max: number; min: number }> = new Subject<{ max: number; min: number }>(); // avoid many outputs

  options/* : Options */;

  sliderValue;

  constructor() {
    this.debouncer.pipe(debounceTime(500)).subscribe(value => {
      this.selectedRangeOutput.emit(value);
    });
  }

  ngOnInit() {
    this.initSlider();
    this.selectedRange = this.selectedRange || JSON.parse(JSON.stringify(this.maxRange));
    if (typeof this.selectedRange.max === 'string') {
      this.selectedRange.max = Number(this.selectedRange.max);
    }
    if (typeof this.selectedRange.min === 'string') {
      this.selectedRange.min = Number(this.selectedRange.min);
    }
    this.sliderValue = [this.selectedRange.min, this.selectedRange.max];
  }

  ngAfterViewInit() {
    this.ngOnInit();
  }

  ngOnDestroy(): void {
    this.debouncer.complete();
    this.debouncer.unsubscribe();
  }

  /**
   * Slider options
   */
  initSlider() {
    this.options = {
      floor: this.maxRange.min,
      ceil: this.maxRange.max,
      // step: this.step, // broke with the new angular/slider version
      step: this.calcStepInterval(),
      translate: !this.unit ? undefined : (value: number): string => this.unit + value
      // combineLabels: (minValue: string, maxValue: string): string => {
      //   return 'from ' + minValue + ' up to ' + maxValue;
      // },
      // ticksArray: [0, 10, 25, 50, 100]
    };
  }

  //large ranges degrades performance and can make the page crash if to small steps is used
  calcStepInterval(): number {
    var interval = 1;
    const range = this.maxRange.max - this.maxRange.min;
    if(range > 500000) {
      interval = 500;
    }else if(range > 10000) {
      interval = 100;
    }else if(range > 1000) {
      interval = 10;
    }else if(range > 100) {
      interval = 5;
    }
    return interval;
  }

  /**
   * Value updated from input
   */
  updateSelectedRange(value, type: 'min' | 'max') {
    value = (value.target && (value.target as HTMLInputElement).value) || value;
    if (!value || isNaN(value as any)) {
      // Invalid value
      if (type === 'min') {
        value = value = this.maxRange.min + '';
      } else {
        value = value = this.maxRange.max + '';
      }
    }
    if (Number(value) > this.maxRange.max) {
      value = value = this.maxRange.max + '';
    }
    if (Number(value) < this.maxRange.min) {
      value = value = this.maxRange.min + '';
    }

    if (typeof this.selectedRange.max === 'string') {
      this.selectedRange.max = Number(this.selectedRange.max);
    }
    if (typeof this.selectedRange.min === 'string') {
      this.selectedRange.min = Number(this.selectedRange.min);
    }
    this.sliderValue = [this.selectedRange.min, this.selectedRange.max];
    this.debouncer.next(this.selectedRange);
    
  }

  update(changeContext/* : ChangeContext */) {
    /* const type = changeContext.pointerType === PointerType.Min ? 'min' : 'max'; */
    const min = changeContext.value;
    const max = changeContext.highValue;

    this.selectedRange = { max, min };
    this.selectedRangeOutput.emit(this.selectedRange);
    this.debouncer.next(this.selectedRange);
  }

  changeNzSlider(event){
    this.selectedRange.max = event[1];
    this.selectedRange.min = event[0];
    this.selectedRangeOutput.emit(this.selectedRange);
    this.debouncer.next(this.selectedRange);
  }
}
