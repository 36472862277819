import { Injectable } from '@angular/core';
import { Observable, Subject, catchError, filter, first, forkJoin, map, of, switchMap } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { ProductService } from './products/product.service';
import { PriceService } from './price/price.service';
import { FastSearchLayout, InlineSearchLayoutComponent, SearchRequest } from 'gung-list';
import { CustomerProductPrice } from '../models/price';
import { Product } from '../models';
import { BaseViewConfigService, ProductViewType } from './base-view-config/base-view-config.service';
import { GlobalSearchProductDetailComponent } from '../components/global-search-product-detail/global-search-product-detail.component';
import { ProductAssortmentParentService, SearchAssortments } from './product-assortment-parent/product-assortment-parent.service';
import { Assortment } from './assortment.service';
import { ProductConcept } from '../components/concept-detail-v2/concept-detail-v2.component';
import { GungStringConverterService } from './gung-string-converter.service';

export interface GlobalSearchResult {
  id: string,
  product: Product | ProductConcept | Assortment,
  price: CustomerProductPrice,
  path: string,
  odd?: boolean
}

export interface GlobalSearchResults {
  results: GlobalSearchResult[],
  count: number
}

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchConfigService {
  // Translations //
  public searchInputPlaceholder: string = 'SEARCH_PRODUCTS';
  public productsColumnTitle: string = 'PRODUCTS';
  public categoriesColumnTitle: string = 'CATEGORIES';

  // Behaviour //
  public searchCategories: boolean = false;
  public searchCategoriesAssortment: boolean = false;
  public maxResultShow: number = 5;
  public viewMoreLink: string = '/view-more';
  public skuLevel: boolean = false;
  public requireAvailability: boolean = false;
  public conceptDetailRoute: string = '/concept';
  public productDetailRoute: string = '/product';
  public assortmentTreeBaseLink: string = '/articles';
  public assortmentCategoriesRoute: string = '/articles';
  public closeSubject: Subject<void> = new Subject<void>();

  // Icons //
  public inputSearchIcon: string = 'fa-light fa-magnifying-glass';
  public inputClearIcon: string = 'fa-light fa-broom-wide';
  public inputLoadingIcon: string = 'fa-regular fa-spinner fa-spin';

  // Style //
  public globalInputExtraClasses: string = '';

  // Temp //
  protected userAssortment: string = '';

  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    protected authService: AuthService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) { }

  public getViewMoreQueryParams(value: string): { [s: string]: any } {
    return { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: this.searchCategories ? true : this.skuLevel };
  }

  public getViewMoreCategoriesQueryParams(value: string): { [s: string]: any } {
    return { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: false };
  }

  public searchCategoriesAssortments(searchTerms: string, searchAssortments: SearchAssortments | undefined): Observable<GlobalSearchResults> {
    if (!searchTerms || !searchAssortments) {
      return of({
        results: [],
        count: 0
      });
    } else {
      const assortments = searchAssortments.assortments.map(a => {
        const _path = a.path.map(p => p.id);
        _path.splice(0, 1, this.assortmentCategoriesRoute);
        return { ...a.assortment, _path: _path.join('') };
      });

      const concepts = searchAssortments.concepts.map(c => {
        const _path = c.path.map(p => p.id);
        _path.splice(0, 1, this.conceptDetailRoute);
        return { ...c.concept, _path: _path.join('') };
      });

      return of({
        results: [...assortments, ...concepts].map((item, index) => {
          return {
            id: item.id,
            product: item,
            price: undefined,
            path: item._path,
            odd: ((index + 1) % 2 === 1)
          }
        }),
        count: [...assortments, ...concepts].length
      });
    }
  }

  public searchProducts(searchTerms: string, searchCategories: boolean = false): Observable<GlobalSearchResults> {
    if (!searchTerms) {
      return of({
        results: [],
        count: 0
      });
    } else {
      return this.authService.getCurrentUser().pipe(
        first(),
        switchMap(user => {
          this.userAssortment = user.assortment;
          const searchRequest: SearchRequest = {
            terms: searchTerms.split(' '),
            skip: 0,
            limit: this.maxResultShow,
            assortment: user.assortment,
            skuLevel: this.searchCategories ? (searchCategories ? false : true) : this.skuLevel,
            requireAvailability: this.requireAvailability
          };
          return this.productService.getPagedProducts(searchRequest).pipe(
            first(),
            catchError(_ => of(undefined))
          );
        }),
        switchMap(productResults => {
          const ids: string[] = productResults?.items?.map(i => i.id) || [];
          return forkJoin({
            productResults: of(productResults),
            prices: this.priceService.getCurrentCustomerPrices(ids).pipe(first(), catchError(_ => of([]))),
            productPaths: this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView ? this.getPathInfo(ids, searchCategories) : of([])
          });
        }),
        switchMap(({ productResults, prices, productPaths }) => {
          if (!productResults) {
            return of({
              results: [],
              count: 0
            });
          }

          const result: GlobalSearchResults = {
            results: productResults.items.map((product, index) => {
              const price: CustomerProductPrice | undefined = prices?.find(p => p.productId === product.id);
              let path: string = '';

              if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
                path = productPaths?.find(pp => pp.id === product.id)?.path || `${product.productType === 'concept' ? this.conceptDetailRoute : this.productDetailRoute}/` + GungStringConverterService.toGungString(product.id);
              } else {
                path = `${product.productType === 'concept' ? this.conceptDetailRoute : this.productDetailRoute}/` + GungStringConverterService.toGungString(product.id);
              }

              return {
                id: product.id,
                product,
                price,
                path,
                odd: ((index + 1) % 2 === 1)
              };
            }),
            count: productResults.totalItems
          }

          return of(result);
        })
      );
    }
  }

  protected getPathInfo(ids: string[], searchCategories: boolean): Observable<{ id: string, path: string }[]> {
    if (ids.length === 0) {
      return of([]);
    }

    if (searchCategories) {
      return forkJoin(
        ids.map(id => this.productAssortmentParentService.getSearchAssortments(id).pipe(first(), catchError(() => of({ assortments: [], concepts: [] }))))
      ).pipe(
        map(results => {
          return results.map(res => {
            return res.concepts.map(a => {
              const _path = a.path.map(p => p.id);
              _path.splice(0, 1, this.assortmentTreeBaseLink);
              return { id: a.concept.id, path: _path.join('/') };
            });
          }).flat();
        })
      );
    } else {
      return this.productAssortmentParentService.getProductAssortmentParentByProductIds(ids, this.userAssortment).pipe(
        first(),
        catchError(() => of([])),
        map(results => {
          const result: { id: string, path: string }[] = [];

          for (const key in results) {
            if (results.hasOwnProperty(key)) {
              const element: { id: string }[] = results[key];
              element.shift();
              result.push({
                id: key,
                path: [
                  this.assortmentTreeBaseLink,
                  ...element.map(e => e.id),
                  key
                ].join('/')
              });
            }
          }

          return result;
        })
      );
    }
  }

  public getLayout(): FastSearchLayout<GlobalSearchResult> {
    return {
      getLayout: () => InlineSearchLayoutComponent,
      getListItemComponent: () => this.baseViewConfigService.getGlobalSearchCardComponent(),
      getDetailItemComponent: () => GlobalSearchProductDetailComponent
    };
  }
}
