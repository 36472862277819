import { Injectable } from '@angular/core';
import { FastSearchLayout, InlineSearchLayoutComponent, SearchRequest, SearchResult } from 'gung-list';
import { forkJoin, Observable, of } from 'rxjs';
import { first, mergeMap, switchMap } from 'rxjs';
import { ProductFastsearchDetailComponent } from '../../components/product-fastsearch-detail/product-fastsearch-detail.component';
import { Product } from '../../models';
import { AuthService } from '../auth/auth.service';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { PriceService } from '../price/price.service';
import { ProductFastSearchListConfigService } from '../product-fast-search-list-config/product-fast-search-list-config.service';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductInlineSearchListConfigService extends ProductFastSearchListConfigService {
  skuLevel = false;
  requireAvailability: boolean;

  // Is put as /product on purpose in order to not break legacy implementations
  public conceptDetailRoute: string = '/product/';
  public productDetailRoute: string = '/product/';

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected baseViewConfigService: BaseViewConfigService
  ) {
    super(productService, authService);
  }

  getLayout(): FastSearchLayout<Product> {
    return {
      getLayout: () => InlineSearchLayoutComponent,
      getListItemComponent: () => this.baseViewConfigService.getProductInlineSearchCardComponent(),
      getDetailItemComponent: () => ProductFastsearchDetailComponent
    };
  }

  getItems(terms: string[], skip: number, limit: number): Observable<SearchResult<Product>> {
    return super.getItems(terms, skip, limit, this.skuLevel, this.requireAvailability).pipe(
      mergeMap(products => {
        return forkJoin([
          of(products),
          this.priceService
            .getCurrentCustomerPrices(products.items.filter(p => p.productType !== 'concept').map(p => p.id))
            .pipe(first())
        ]);
      }),
      switchMap(([products, prices]) => {
        products.items.map(p => (p.extra.price = prices.find(pr => pr.productId === p.id)));
        return of(products);
      })
    );
  }
}
