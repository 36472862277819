import { Component, OnInit, Input } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';

@Component({
  selector: 'lib-user-details-multistocks',
  templateUrl: './user-details-multistocks.component.html',
  styleUrls: ['./user-details-multistocks.component.css']
})
export class UserDetailsMultistocksComponent implements OnInit {
  @Input()
  currentUser: User;

  multiStocksIds: string[] = [];

  constructor() {}

  ngOnInit() {
    this.multiStocksIds = this.currentUser.managedMultistockIds;
  }

  add(ele: HTMLInputElement): void {
    if (!ele.value) {
      return;
    }
    this.multiStocksIds.push(ele.value);
    ele.value = null;
    this.update();
  }

  update(): void {
    this.currentUser.managedMultistockIds = this.multiStocksIds.filter(Boolean);
  }

  remove(index: number): void {
    this.multiStocksIds.splice(index, 1);
    this.update();
  }

  trackByFn(index: number, item: any): any {
    return index;
  }
}
