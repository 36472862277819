<h3 translate class="page-title text-center">SHIPPING_CHARGES_TITLE</h3>
<div class="row">
  <div class="col text-right mb-2">
    <button
      (click)="openAddNewFreightForm()"
      *ngIf="!showAddNewFreightTerm"
      type="button"
      class="btn btn-primary btn-new-freight-term"
      translate
    >
      NEW_FREIGHT_TERM
    </button>
  </div>
</div>
<div *ngIf="showAddNewFreightTerm" class="new-freight-term-content">
  <h4 translate class="page-title">ADD_NEW_FREIGHT_TERM</h4>
  <div class="new-freight-term-form">
    <lib-gung-text-input-horizontal gungTranslate="FREIGHT_TERM_ID" [(ngModel)]="newFreightTerm.freightTermId">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal gungTranslate="FREIGHT_PRODUCT_ID" [(ngModel)]="newFreightTerm.freightProductId">
    </lib-gung-text-input-horizontal>
    <lib-gung-number-input-horizontal
      gungTranslate="MINIMUM_FREIGHT_SUM"
      [(ngModel)]="newFreightTerm.minimumFreightSum"
      placeholder="0"
      [outputAsString]="false"
    >
    </lib-gung-number-input-horizontal>
    <lib-gung-number-input-horizontal
      gungTranslate="FREIGHT_ROOF"
      [(ngModel)]="newFreightTerm.freightRoof"
      placeholder="0"
      [outputAsString]="false"
    >
    </lib-gung-number-input-horizontal>
    <lib-gung-number-input-horizontal
      gungTranslate="FREIGHT_PERCENT"
      [(ngModel)]="newFreightTerm.freightPercent"
      placeholder="0"
      [outputAsString]="false"
    >
    </lib-gung-number-input-horizontal>
  </div>
  <div class="text-right">
    <button
      type="button"
      class="btn btn-secondary btn-new-freight-term"
      (click)="showAddNewFreightTerm = false; cleanMessageToUser()"
      [disabled]="creatingNewFreightTerm"
      translate
    >
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-primary btn-new-freight-term"
      [disabled]="creatingNewFreightTerm"
      (click)="saveNewShippingCharge(newFreightTerm)"
      translate
    >
      SAVE
      <i *ngIf="creatingNewFreightTerm" class="fa fa-spinner fa-spin fa-lg"></i>
    </button>
  </div>
</div>
<div
  class="alert alert-danger"
  *ngIf="displayMessageToUser && displayMessageToUser.length > 0"
  (click)="displayMessageToUser = ''"
>
  <span (click)="displayMessageToUser = ''" translate> {{ displayMessageToUser }} </span>
</div>
<div class="table-responsive gung-responsive">
  <!-- table-responsive -->
  <table class="table table-striped table-hover">
    <thead class="table-dark">
      <tr>
        <th scope="col" id="idFreightId" translate>FREIGHT_TERM_ID</th>
        <th scope="col" id="idFreightProductId" translate>FREIGHT_PRODUCT_ID</th>
        <th scope="col" id="idMinimumFreightSum" translate>MINIMUM_FREIGHT_SUM</th>
        <th scope="col" id="idFreightRoof" translate>FREIGHT_ROOF</th>
        <th scope="col" id="idFreightPercent" translate>FREIGHT_PERCENT</th>
        <th scope="col" id="idFreightCurrency" translate>CURRENCY</th>
        <th scope="col" id="idActions" translate>SHIPPING_CHARGES_TABLE_ACTIONS</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let shippingCharge of shippingCharges; index as i">
        <td headers="idFreightId">
          {{ shippingCharge.freightTermId }}
          <small class="d-block text-muted small">({{ shippingCharge.id }})</small>
        </td>
        <td headers="idFreightProductId">
          <div *ngIf="enableEdit && editingIndex === i; else displayFreightProductId">
            <input type="text" [(ngModel)]="shippingCharge.freightProductId" />
          </div>
          <ng-template #displayFreightProductId>
            <div class="minimum-freight-sum">
              {{ shippingCharge.freightProductId }}
            </div>
          </ng-template>
        </td>
        <td headers="idMinimumFreightSum">
          <div *ngIf="enableEdit && editingIndex === i; else displayMinimumFreightSum">
            <input type="number" [(ngModel)]="shippingCharge.minimumFreightSum" />
          </div>
          <ng-template #displayMinimumFreightSum>
            <div class="minimum-freight-sum">
              {{ shippingCharge.minimumFreightSum }}
            </div>
          </ng-template>
        </td>
        <td headers="idFreightRoof">
          <div *ngIf="enableEdit && editingIndex === i; else displayFreightRoof">
            <input type="number" [(ngModel)]="shippingCharge.freightRoof" />
          </div>
          <ng-template #displayFreightRoof>
            <div class="minimum-freight-sum">
              {{ shippingCharge.freightRoof }}
            </div>
          </ng-template>
        </td>
        <td headers="idFreightPercent">
          <div *ngIf="enableEdit && editingIndex === i; else displayFreightPercentf">
            <input type="number" [(ngModel)]="shippingCharge.freightPercent" />
          </div>
          <ng-template #displayFreightPercentf>
            <div class="minimum-freight-sum">
              {{ shippingCharge.freightPercent }}
            </div>
          </ng-template>
        </td>
        <td headers="idFreightCurrency">
          <div *ngIf="enableEdit && editingIndex === i; else displayFreightCurrency">
            <input type="text" [(ngModel)]="shippingCharge.currency" />
          </div>
          <ng-template #displayFreightCurrency>
            <div class="minimum-freight-sum">
              {{ shippingCharge.currency }}
            </div>
          </ng-template>
        </td>

        <td headers="idActions">
          <button
            type="button"
            class="btn btn-primary table-btn"
            translate
            [disabled]="disableActionsIndex === i"
            (click)="onClickEditShippingCharge(shippingCharge, i)"
            ngbTooltip="{{ editingIndex === i ? 'SAVE' : ('EDIT' | translate) }}"
          >
            <i *ngIf="editingIndex !== i" class="far fa-edit"></i
            ><!-- ngbTooltip="{{'EDIT' | translate}}" -->
            <i *ngIf="editingIndex === i" class="fas fa-save"></i
            ><!-- ngbTooltip="{{'SAVE' | translate}}" -->
          </button>
          <button
            type="button"
            class="btn btn-secondary table-btn"
            translate
            [disabled]="disableActionsIndex === i"
            (click)="openDeleteConfirmationModal(shippingCharge, i)"
            ngbTooltip="{{ 'DELETE' | translate }}"
          >
            <i class="fas fa-times"></i>
          </button>
          <i *ngIf="disableActionsIndex === i" class="fa fa-spinner fa-spin fa-lg"></i>
        </td>
      </tr>
      <tr *ngIf="shippingCharges && shippingCharges.length === 0"></tr>
    </tbody>
  </table>
  <div class="alert alert-info text-center" *ngIf="shippingCharges && shippingCharges.length === 0">
    <span translate> NO_SHIPPING_CHARGERS </span>
  </div>
</div>
<div class="text-center" *ngIf="!shippingCharges">
  <i class="fa fa-spinner fa-spin fa-5x"></i>
</div>
