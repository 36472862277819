import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ListLayout, ListLayoutMultipleComponent, ListSortOption, ConfigService } from 'gung-list';
import { Observable } from 'rxjs';
import { DiscountCodesTableComponent } from '../../components/discount-codes/discount-codes-table/discount-codes-table.component';
import { DiscountCodesV2Service, DiscountCodeV2 } from '../discount-codes-v2/discount-codes-v2.service';
import { DiscountCode, DiscountCodesService } from '../discount-codes.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodesListConfigService implements ConfigService<DiscountCodeV2> {
  constructor(protected discountCodesV2Service: DiscountCodesV2Service) {}

  getFilters(): ConfigBaseFilter<DiscountCodeV2>[] {
    return [];
  }

  getSortOptions(): ListSortOption<DiscountCodeV2>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<DiscountCodeV2>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => DiscountCodesTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'DiscountCodeGrid'
      }
    ];
  }

  getItems(): Observable<DiscountCodeV2[]> {
    return this.discountCodesV2Service.getAllDiscounCode();
  }

  getItemId(item: DiscountCodeV2) {
    return item.code;
  }

  getSearchTerms(item: DiscountCodeV2) {
    return [item.code, item.description];
  }
}
