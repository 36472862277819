import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs';
import { forkJoin } from 'rxjs';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AbstractSelectionService } from 'gung-list';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';
import { PimUploadExcelModalComponent } from '../pim-upload-excel-modal/pim-upload-excel-modal.component';
import { PimService } from '../../../services/pim/pim.service';
import { Pim } from '../../../models/pim';
import { PimUploadModalComponent } from './../pim-upload-modal/pim-upload-modal.component';
import { PimListConfigService } from './../../../services/pim-list-config/pim-list-config.service';

@Component({
  selector: 'lib-pim-wrapper',
  templateUrl: './pim-wrapper.component.html',
  styleUrls: ['./pim-wrapper.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class PimWrapperComponent implements OnInit {
  public modalRef: NgbModalRef;

  modalOptions: NgbModalOptions = {
    backdrop: true || 'static',
    size: 'lg',
    keyboard: true
  };

  constructor(
    public pimListConfigService: PimListConfigService,
    protected modalService: NgbModal,
    protected pimService: PimService
  ) {}

  ngOnInit() {}

  openModalUploadImages() {
    this.modalRef = this.modalService.open(PimUploadModalComponent, this.modalOptions);
    this.modalRef.componentInstance.uploadType = 'image';
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  openModalUploadDocuments() {
    this.modalRef = this.modalService.open(PimUploadModalComponent, this.modalOptions);
    this.modalRef.componentInstance.uploadType = 'document';
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  openModalUploadExcel() {
    this.modalRef = this.modalService.open(PimUploadExcelModalComponent, this.modalOptions);
    this.modalRef.result.then(
      result => {},
      reason => {}
    );
  }

  async fixPimProducts() {
    // fetch all pim products and updates them
    this.pimService
      .getPimProducts()
      .pipe(first())
      .subscribe((pimProducts: Pim[]) => {
        for (const pimProduct of pimProducts) {
          this.mergePimProduct(pimProduct);
        }
        this.updatePimProducts(pimProducts).then(() => console.log('ALL PIM PRODUCTS UPDATED'));
      });
  }

  private async updatePimProducts(pimProducts: Pim[]): Promise<void> {
    // send 10 requests each time
    for (let index = 0; index < pimProducts.length; index += 10) {
      const pimProductsSet = pimProducts.slice(index, index + 10);
      const pimUpdated = await forkJoin(
        pimProductsSet.map(pimProduct => this.pimService.postPimProduct(pimProduct).pipe(first()))
      ).toPromise();
      console.log(console.log('pimUpdated - ' + pimUpdated.map(p => p.id)));
    }
  }

  private mergePimProduct(pimProduct: Pim): Pim {
    // copy PIM into pim (but the i18n)
    if (pimProduct.extra && pimProduct.extra.PIM) {
      pimProduct.extra.pim = {
        ...pimProduct.extra.pim,
        ...pimProduct.extra.PIM
      };

      if (pimProduct.extra.pim.i18n) {
        delete pimProduct.extra.pim.i18n;
      }
    }

    // copy PIM i18n into i18n
    if (pimProduct.extra && pimProduct.extra.PIM && pimProduct.extra.PIM.i18n) {
      pimProduct.i18n = {
        ...pimProduct.i18n,
        ...pimProduct.extra.PIM.i18n
      };
    }

    return pimProduct;
  }
}
