import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { NewsPageTableComponent } from '../../../components/news/news-page-table/news-page-table.component';
import { News } from '../../../models/news';
import { NewsService } from '../news.service';
import { NewsCategoryFilter, NewsDateFilter } from '../news-editor-config-list/news-editor-config-list.service';
import { NewsPageGridViewComponent } from '../../../components/news/news-page-grid-view/news-page-grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class NewsPageListConfigService implements ConfigService<News> {
  constructor(protected newsService: NewsService, protected translateService: TranslateService) {}

  getItems(): Observable<News[]> {
    return this.newsService.getShowInPageUserNews();
  }

  getFilters(): ConfigBaseFilter<News>[] {
    return [new NewsDateFilter(this.translateService), new NewsCategoryFilter()];
  }

  getSortOptions(): ListSortOption<News>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<News>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => NewsPageGridViewComponent, // NewsPageTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'NewsPageTable'
      }
    ];
  }

  getSearchTerms(item: News): string[] {
    return [item.id, item.name];
  }

  getItemId(item: News): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
