<div *ngIf="data">
  <div class="row border-bottom mb-2 card-body" *ngFor="let ediOrderResponse of data; index as i; trackBy: trackByFn">
    <div class="col-12">
      <h5 class="d-inline cursor-pointer">
        <a [routerLink]="ediOrderResponse.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          {{ ediOrderResponse.id }} - <lib-edi-send-status [message]="ediOrderResponse"></lib-edi-send-status>
        </a>
      </h5>
      <div class="float-right">
        <lib-action-btn [(item)]="data[i]" [config]="actionConfigService"></lib-action-btn>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="ORDER_ID"
        [(ngModel)]="ediOrderResponse.documentReferences.supplierOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="RECEIVED_DATE"
        [(ngModel)]="ediOrderResponse.documentReferences.buyerOrder.date"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="ORDER_TYPE"
        [(ngModel)]="ediOrderResponse.orderType"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="BUYER_ID"
        [(ngModel)]="ediOrderResponse.documentReferences.buyerOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="VALIDATION"
        [(ngModel)]="ediOrderResponse.validationState"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="CONFIG"
        [(ngModel)]="ediOrderResponse.ediConfigId"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4" *ngIf="!!ediOrderResponse.timestamp">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="TIMESTAMP"
        [(ngModel)]="ediOrderResponse.timestamp"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
  </div>
</div>
