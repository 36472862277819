import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OptionsList } from 'gung-common';
import { first } from 'rxjs';
import { OrderStatistic } from '../../../models/order-statistics';
import { Product, ProductMultiDimension } from '../../../models/product';
import { AvailabilityService } from '../../../services/availability/availability.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { OrderSubmitService } from '../../../services/order-submit.service';
import { ProductService } from '../../../services/products/product.service';
import { DateUtilService } from 'gung-common';
import { gungAddRemoveSpinner, gungComparatorHelper } from '../../../utils/gung-utils';

export interface TopProductOrderStatistic {
  qty: number;
  orderCount: number;
  productId: string;
  productName: string;
  subItems: OrderStatistic[];
}

export interface GroupedItems {
  productId: string;
  productName: string;
  quantity: number;
  productModelId: string;
  productPrimaryDimensionId: string;
  productPrimaryDimensionName: string;
  productSecondaryDimensionId: string;
  productSecondaryDimensionName: string;
  purchasePrice: number;
}
@Component({
  selector: 'lib-top-products-create-purchase-order-modal',
  templateUrl: './top-products-create-purchase-order-modal.component.html',
  styleUrls: ['./top-products-create-purchase-order-modal.component.css']
})
export class TopProductsCreatePurchaseOrderModalComponent implements OnInit {
  protected channel = 'GARP_PURCHASE_ORDER';

  @Input() item: TopProductOrderStatistic;

  public form: FormGroup;

  isLoaded: boolean;
  groupItems: GroupedItems[];
  product: ProductMultiDimension;
  products: ProductMultiDimension[];
  deliveryOptions: OptionsList[];
  tableLA: any[];
  groupItemsAvailabilites: any = {};
  productsMapped: any = {};

  isSales: boolean;

  get rows() {
    return this.form.get('rows') as FormArray;
  }

  subAvailabilities;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected productService: ProductService,
    protected metadataService: MetadataService,
    protected dateUtilService: DateUtilService,
    protected orderSubmitService: OrderSubmitService,
    protected availabilityService: AvailabilityService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {}
  // "SIP": "      16.40",
  ngOnInit(): void {
    this.productService
      .getFullProductsByIds(this.item.subItems.map(subItem => subItem.productId))
      .pipe(first())
      .subscribe(products => {
        products.forEach(p => {
          this.productsMapped[p.id] = p;
        });

        this.productService.getProduct(this.item.productId).subscribe(product => {
          this.product = product as any;
          this.tableLA = this.metadataService.getMetadataTableList('LA');
          this.calculateItems(this.item);
          this.initForm();
          this.deliveryOptions = [
            {
              id: 'assa',
              name: 'asdasd'
            }
          ];
          this.isLoaded = true;
        });
      });
  }

  initForm() {
    this.form = this.formBuilder.group({
      LNR: this.formBuilder.control('', [Validators.required]),
      name: this.formBuilder.control('', {
        validators: [Validators.required]
      }),
      AD1: this.formBuilder.control(''),
      AD2: this.formBuilder.control(''),
      ftgpostnr: this.formBuilder.control(''),
      LND: this.formBuilder.control(''),
      VALFRI: this.formBuilder.control(''),
      TX1: this.formBuilder.control(''),
      TX2: this.formBuilder.control(''),
      REF: this.formBuilder.control(''),
      internalText: this.formBuilder.control(''),
      externalText: this.formBuilder.control(''),
      deliveryDate: this.formBuilder.control(new Date()),
      rows: this.formBuilder.array([])
    });

    if (this.groupItems?.length > 0) {
      this.groupItems
        .sort((a, b) => {
          return a.productId.localeCompare(b.productId);
          /*
          if (
            sortSizes.includes(
              a?.productSecondaryDimensionName ||
                b?.productSecondaryDimensionName
            )
          ) {
            return gungComparatorHelper(
              a?.productSecondaryDimensionName,
              b?.productSecondaryDimensionName,
              1,
              sortSizes
            );
          }
          return gungComparatorHelper(
            a?.productSecondaryDimensionName,
            b?.productSecondaryDimensionName,
            1
          );*/
        })
        .map(row => this.addRow(row));
    }

    this.form.get('LNR').valueChanges.subscribe(value => {
      this.supplierSelected(value);
    });

    if (this.tableLA.length > 0) {
      let id = this.tableLA[0].LNR;
      if (this.product) {
        const productSuppiler = this.tableLA.find(s => s.LNR === this.product.extra.AGA.LNR);
        id = productSuppiler?.LNR || id;
      }
      // this.supplierSelected(id);
      this.form.get('LNR').setValue(id);
    }
  }

  calculateItems(data: TopProductOrderStatistic) {
    const groupItems: { [s: string]: GroupedItems } = {};
    for (const item of data.subItems) {
      const av =
        this.product?.extra?.availability?.extra?.subAvailabilities &&
        this.product.extra.availability.extra.subAvailabilities[item.productId];
      if (groupItems[item.productId]) {
        groupItems[item.productId].quantity = groupItems[item.productId].quantity + item.orderRowTotalQuantity;
      } else {
        groupItems[item.productId] = {
          productName: item.productName,
          quantity: item.orderRowTotalQuantity,
          productId: item.productId,
          productModelId: item.productModelId,
          productPrimaryDimensionId: item.productPrimaryDimensionId,
          productPrimaryDimensionName: item.productPrimaryDimensionName,
          productSecondaryDimensionId: item.productSecondaryDimensionId,
          productSecondaryDimensionName: item.productSecondaryDimensionName,
          purchasePrice: parseFloat(this.productsMapped[item.productId].extra.AGA.SIP)
        };
      }
    }
    this.groupItems = Object.keys(groupItems).map(key => groupItems[key]);
    console.log('this.getting avs for ', Object.keys(groupItems));

    this.availabilityService
      .getAvailabilities(Object.keys(groupItems))
      .pipe(first())
      .subscribe(availabilities => {
        availabilities.forEach(av => {
          this.groupItemsAvailabilites[av.productId] = av;
        });
      });

    this.groupItems = this.groupItems.sort((a, b) => {
      const aSort = a?.productSecondaryDimensionId;
      const bSort = b?.productSecondaryDimensionId;
      if ((!bSort && aSort) || (aSort && bSort && aSort > bSort)) {
        return -1;
      } else if ((!aSort && bSort) || (aSort && bSort && aSort < bSort)) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  deleteRow(i) {
    this.rows.removeAt(i);
  }

  addRow(row?: GroupedItems) {
    this.rows.push(
      this.formBuilder.group({
        id: this.formBuilder.control(row.productId || ''),
        productName: this.formBuilder.control(row.productName || ''),
        quantity: this.formBuilder.control(row.quantity || 0),
        productId: this.formBuilder.control(row.productId || ''),
        productModelId: this.formBuilder.control(row.productModelId || ''),
        productPrimaryDimensionId: this.formBuilder.control(row.productPrimaryDimensionId || ''),
        productPrimaryDimensionName: this.formBuilder.control(row.productPrimaryDimensionName || ''),
        productSecondaryDimensionId: this.formBuilder.control(row.productSecondaryDimensionId || ''),
        productSecondaryDimensionName: this.formBuilder.control(row.productSecondaryDimensionName || ''),
        purchasePrice: this.formBuilder.control(row.purchasePrice || 0),
        include: this.formBuilder.control(true)
      })
    );
  }

  supplierSelected(suppilerId: string) {
    const suppiler = this.metadataService.getMetadataPost('LA', suppilerId);
    if (suppiler) {
      this.form.patchValue({
        name: suppiler.NAM || '',
        AD1: suppiler.AD1 || '',
        AD2: suppiler.AD2 || '',
        ftgpostnr: suppiler.AD1 || '',
        LND: suppiler.LND || '', // ORT
        TX1: suppiler.TX1 || '',
        TX2: suppiler.TX2 || '',
        REF: suppiler.REF || ''
      });
    }
  }

  selectedDate(deliveryDateIn) {
    const deliveryDate = this.dateUtilService.parseDate(deliveryDateIn);
    this.form.get('deliveryDate').setValue(deliveryDate);
  }

  createPurchaseOrder({ target }) {
    gungAddRemoveSpinner(target);
    const form = this.form.getRawValue();
    const checkout = this.buildCheckoutObject(form);

    console.log('checkout', checkout);

    this.orderSubmitService.postOrder('json/order/create/' + this.channel, checkout).subscribe(
      result => {
        if (result.erpOrderIds) {
          const orderId = result.erpOrderIds[0];
          this.router.navigate(['/open-purchase-orders', orderId]);
        }
        this.activeModal.close(result);
      },
      error => {
        gungAddRemoveSpinner(target);
      },
      () => {
        gungAddRemoveSpinner(target);
      }
    );
  }

  protected buildCheckoutObject(form: any) {
    return {
      deliveryCustomerId: form.LNR,
      deliveryCustomer: {
        id: form.LNR,
        name: form.name,
        ftgpostnr: form.ftgpostnr
      },
      extra: {
        internalText: form.internalText,
        externalText: form.externalText,
        IGA: {
          BLT: this.dateUtilService.getFormattedDateString(form.deliveryDate, 'yyMMdd'),
          //       AD1: form.AD1,
          //       AD2: form.AD2,
          //       VALFRI: form.VALFRI,
          //    TX1: form.TX1,
          //   TX2: form.TX2,
          ERF: form.REF
        },
        IGL: {
          NAM: form.name,
          AD1: form.AD1,
          AD2: form.AD2,
          ORT: form.ftgpostnr,
          LND: form.LND
        },
        IGF: {
          TX1: form.TX1,
          TX2: form.TX2,
          TX3: form.VALFRI
        }
      },
      rows: form.rows
        .filter(row => row.include)
        .map(row => ({
          id: row.productModelId + row.productPrimaryDimensionId + row.productSecondaryDimensionId,
          quantity: row.quantity,
          extra: {
            IGR: {
              PRI: row.purchasePrice + ''
            }
          }
        }))
    };
  }
}
