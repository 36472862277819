<button type="button" class="btn btn-light btn-block btn-sm text-left global-search-item" [ngClass]="{'stripped': data.odd}" [routerLink]="data.path" (click)="sendCloseSignal()">
  <div class="row m-0">
    <div class="col-3 p-0 d-flex flex-column justify-content-center">
      <img
        alt="{{ data.product.name }}"
        class="img-fluid mx-auto d-block"
        *ngIf="data.product.extra.images && data.product.extra.images[0]; else noimage"
        [src]="data.product.extra.images[0].s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+data.product.extra.images[0].s3ETag
        "
      />
      <ng-template #noimage>
        <img
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
    </div>
    <div class="col-9 p-0 pl-2 text-truncate">
      <span class="d-block">
        <small
          ><b>{{ data.product.id }}</b></small
        >
        <lib-price
          class="float-right"
          *ngIf="!!data.price"
          [price]="data.price"
          type="NET"
          [currency]="data.price.customerNetPrice.currencyCode"
        ></lib-price>
      </span>
      <br />
      <span>{{ data.product.name }}</span>
    </div>
  </div>
</button>
