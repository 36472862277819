import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { first } from 'rxjs';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';

@Component({
  selector: 'lib-assign-system-setting',
  templateUrl: './assign-system-setting.component.html',
  styleUrls: ['./assign-system-setting.component.css']
})
export class AssignSystemSettingComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected gungFlowService: GungFlowService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.gungFlowService
        .getSystemGung()
        .pipe(first())
        .subscribe(systemSettings => {
          if (!!systemSettings && !!systemSettings.orderParams) {
            this.stepDone.emit({
              ...this.checkout,
              extra: {
                ...this.checkout.extra,
                ...systemSettings.orderParams
              }
            });
          } else {
            this.stepDone.emit(this.checkout);
          }
        });
    });
  }

  public isVisible() {
    return false;
  }
}
