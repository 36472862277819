<div class="card" *ngFor="let user of data">
  <div class="card-body">
    <div class="card-title" *ngIf="user.name">
      <h5>{{ user.name }}</h5>
      <lib-action-btn [item]="user" [config]="actionConfig"></lib-action-btn>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <div class="row pt-1">
            <div class="col-4">
              {{ 'USERNAME' | translate }}
            </div>
            <div class="col-8">
              {{ user.username }}
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-4">
              {{ 'EMAIL' | translate }}
            </div>
            <div class="col-8">
              {{ user.email }}
            </div>
          </div>
        </div>

        <div class="col-md-6 col-xs-12">
          <div class="row pt-1">
            <div class="col-4">
              {{ 'NAME' | translate }}
            </div>
            <div class="col-8">
              {{ user.name }}
            </div>
          </div>
          <div class="row pt-1">
            <div class="col-4">
              {{ 'PHONE' | translate }}
            </div>
            <div class="col-8">
              {{ user.phone }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
