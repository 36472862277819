import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, forkJoin, map, Observable, of, switchMap } from 'rxjs';
import { Customer } from '../../models';
import { Offer } from '../../models/offer';
import { CustomerService } from '../customers/customer.service';

@Injectable({
  providedIn: 'root'
})
export class OfferService {
  constructor(protected http: HttpClient, protected customerService: CustomerService) {}

  getOffers(customerId: string, nocache?: boolean, maxSize?: number): Observable<Offer[]> {
    let url = `json/offers/list/${customerId}`;
    if (!!maxSize) {
      url = `${url}?maxSize=${maxSize}`;
    }
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<Offer[]>(url, { headers }).pipe(
      switchMap(offers => forkJoin([of(offers), this.customerService.getCustomer(customerId).pipe(first())])),
      map(([offers, customer]) => offers.map(offer => this.offerMapper(offer, customer)))
    );
  }

  getOffer(offerId: string, nocache?: boolean): Observable<Offer> {
    const url = `json/offers/${offerId}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<Offer>(url, { headers }).pipe(
      switchMap(offer =>
        forkJoin([of(offer), this.customerService.getCustomer(offer.deliveryCustomerId).pipe(first())])
      ),
      map(([offer, customer]) => this.offerMapper(offer, customer))
    );
  }

  protected offerMapper(offer: Offer, customer: Customer): Offer {
    return {
      ...offer,
      extra: {
        ...offer.extra,
        deliveryCustomer: customer
      }
    };
  }

  updateOffer(offer: Offer) {
    const url = 'json/offer/update/' + offer.id;
    return this.http.put<any>(url, offer);
  }

  convertOfferToOrder(offerId){
    const url = `json/offers/${offerId}/convert-to-order`;
    return this.http.get(url);
  }

  getOpenOffers(): Observable<Offer[]>{
    const url = `json/open-offers`;
    return this.http.get<Offer[]>(url);
  }
}