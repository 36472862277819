import { MetadataService } from '../../services/metadata/metadata.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Component, OnInit, Input, forwardRef } from '@angular/core';
import { TableRecord } from '../../state/metadata/types';
import { map, first } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-meta-data-select-input',
  templateUrl: './meta-data-select-input.component.html',
  styleUrls: ['./meta-data-select-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MetaDataSelectInputComponent),
      multi: true
    }
  ]
})
export class MetaDataSelectInputComponent implements OnInit, ControlValueAccessor {
  @Input()
  public labelClass: string | string[];

  @Input()
  private table: string;

  @Input()
  private metaId: string;

  @Input()
  private metaDisplay: string;

  @Input()
  public label: string;

  @Input()
  private optionsFilterFunction?: (option: { key: string; value: string | TableRecord }) => boolean;

  @Input()
  private optionsFilterFunctionTableRecord?: (option: { key: string; value: TableRecord }) => boolean;

  @Input()
  public suppressLabel?: boolean;

  @Input()
  public renderStatic?: boolean;

  @Input()
  public showId?: boolean;

  @Input()
  public showIdEnd?: boolean;

  @Input()
  public hideEmptyOption?: boolean;

  @Input()
  public emptyOptionLabel = '';

  @Input()
  public tooltip?: string;

  @Input()
  public needTraslate?: boolean;

  @Input()
  public prefix?: string;

  @Input()
  public nameAsValue?: boolean;

  @Input()
  public extraSelectClass?: string;

  selectedValue: string;

  id: string;

  disabled = false;

  metaOptions$: Observable<{ key: string; value: string }[]>;

  onChange = (_: any) => {};

  get selected() {
    return this.selectedValue;
  }

  set selected(val: string) {
    this.selectedValue = val;
    this.onChange(this.selectedValue);
  }

  writeValue(val: any): void {
    if (val !== undefined) {
      this.selected = val;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {}
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  constructor(private metadataService: MetadataService) {}

  ngOnInit() {
    this.id = `uuid$${uuidv4()}`;
    this.label = this.label || `${this.table}_${this.metaDisplay}`.toUpperCase();
    if (this.optionsFilterFunctionTableRecord) {
      this.metaOptions$ = this.metadataService
        .getMetadataValuesAsArray$(this.table, this.metaId, undefined)
        .pipe(
          map(array => {
            if (this.prefix) {
              return array
                .map(element => {
                  element.key = element.key.replace(this.prefix, '');
                  return element;
                })
                .filter(this.optionsFilterFunctionTableRecord).map(({key, value}) => ({key, value: this.resolve(this.metaDisplay, value)}))
                .sort((a, b) => a.key.localeCompare(b.key, 'en', { numeric: true }));
            } else {
              return array.filter(this.optionsFilterFunctionTableRecord).map(({key, value}) => ({key, value: this.resolve(this.metaDisplay, value)}));
            }
          })
        ) as Observable<{ key: string; value: string }[]>;
    } else if (this.optionsFilterFunction) {
      this.metaOptions$ = this.metadataService
        .getMetadataValuesAsArray$(this.table, this.metaId, this.metaDisplay)
        .pipe(
          map(array => {
            if (this.prefix) {
              return array
                .map(element => {
                  element.key = element.key.replace(this.prefix, '');
                  return element;
                })
                .filter(this.optionsFilterFunction)
                .sort((a, b) => a.key.localeCompare(b.key, 'en', { numeric: true }));
            } else {
              return array.filter(this.optionsFilterFunction);
            }
          })
        ) as Observable<{ key: string; value: string }[]>;
    } else if (this.prefix) {
      this.metaOptions$ = this.metadataService
        .getMetadataValuesAsArray$(this.table, this.metaId, this.metaDisplay)
        .pipe(
          map(array =>
            array
              .map(element => {
                element.key = element.key.replace(this.prefix, '');
                return element;
              })
              .sort((a, b) => a.key.localeCompare(b.key, 'en', { numeric: true }))
          )
        ) as Observable<{ key: string; value: string }[]>;
    } else {
      this.metaOptions$ = this.metadataService
        .getMetadataValuesAsArray$(this.table, this.metaId, this.metaDisplay)
        .pipe(map(array => array.sort((a, b) => a.key.localeCompare(b.key, 'en', { numeric: true })))) as Observable<
        { key: string; value: string }[]
      >;
    }
  }

  getSelectedValue(key: string): Observable<string> {
    return this.metaOptions$.pipe(
      first(),
      map(options => {
        const value = options.find(metaObject => metaObject.key === '' + key);
        return value ? value.value : '';
      })
    );
  }

  private resolve(path, obj, separator = '.') {
    const properties = Array.isArray(path) ? path : path.split(separator);
    const res = properties.reduce((prev, curr) => prev && prev[curr], obj);
    return res;
  }
}
