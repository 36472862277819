import { Component, OnInit } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { GungNotificationService } from '../../services/notification/gung-notification.service';
import { Notification, NotificationLink, NotificationType } from '../../models/gung-notification/gung-notification';

@Component({
  selector: 'lib-gung-notification',
  templateUrl: './gung-notification.component.html',
  styleUrls: ['./gung-notification.component.css']
})
export class GungNotificationComponent implements OnInit {
  public unsubscribe: Subject<void> = new Subject();
  notifications: Map<number, Notification> = new Map();
  timers: Map<number, ReturnType<typeof setTimeout>> = new Map();
  currentId: number = 0;
  notificationTime: number = 6000;

  constructor(private gungNotification: GungNotificationService) {}

  ngOnInit(): void {
    this.gungNotification
      .getNotificationSubject()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(notification => {
        if (notification) {
          this.notify(notification.title, notification.message, notification.type, notification.link);
        }
      });
  }

  public notify(title: string, message: string, type: NotificationType, link?: NotificationLink): void {
    const id = this.currentId++;
    const notification: Notification = { title, message, type, link };
    this.notifications.set(id, notification);

    const timer = setTimeout(() => {
      this.notifications.delete(id);
      this.timers.delete(id);
    }, this.notificationTime);

    this.timers.set(id, timer);
  }

  private close(id: number): void {
    this.notifications.delete(id);
  }

  private onMouseEnter(id: number): void {
    clearTimeout(this.timers.get(id));
    this.removeProgressBar(id);
  }

  private removeProgressBar(notificationId: number): void {
    const element = document.querySelector(`.gungNotification[data-id="${notificationId}"]`);
    if (element) {
      element.classList.add('hideProgressBar');
    }
  }

  private onMouseLeave(id: number): void {
    this.restartTimer(id);
  }

  private restartTimer(id: number): void {
    clearTimeout(this.timers.get(id));
    const timer = setTimeout(() => {
      this.notifications.delete(id);
      this.timers.delete(id);
    }, this.notificationTime);
    this.timers.set(id, timer);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
