import { Component, OnInit } from '@angular/core';
import { BrandsListConfigService } from '../../../services/brands/brands-list-config.service';
@Component({
  selector: 'lib-brands-manager',
  templateUrl: './brands-manager.component.html',
  styleUrls: ['./brands-manager.component.css']
})
export class BrandsManagerComponent implements OnInit {
  constructor(public brandsListConfigService: BrandsListConfigService) {}

  ngOnInit(): void {}
}
