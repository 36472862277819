<div class="row">
  <div class="col-12 text-center"><h3 translate>QUICKORDER</h3></div>
</div>
<div class="row">
  <div class="col-9 col-md-6">
    <label for="typeahead-prevent-manual-entry" translate>SEARCH</label>
    <input
      #productElement
      id="typeahead-prevent-manual-entry"
      type="text"
      class="form-control"
      [(ngModel)]="product"
      [ngbTypeahead]="search"
      [inputFormatter]="formatter"
      [resultFormatter]="formatter"
      (selectItem)="selectProduct($event)"
      (focus)="clearFields()"
      (ngModelChange)="$event ? focusElement(quantityElement, true) : undefined"
      [editable]="false"
    />
    <lib-quickorder-listing-product
      [product]="product"
      [assortment]="assortment"
      (valueChanged)="addProductToCart($event)"
    ></lib-quickorder-listing-product>
  </div>
  <div class="col-3 col-md-2">
    <label for="quantityElement" translate>QUANTITY</label>
    <input
      #quantityElement
      id="quantityElement"
      type="number"
      class="form-control"
      [(ngModel)]="quantity"
      placeholder="0"
      (keydown.enter)="addProductToCart($event.target.value); productElement.focus()"
    />
  </div>
  <div class="col-12 col-md-4" tabindex="0" #quickorderSummarize>
    <lib-quickorder-summarize
      [cart]="cart"
      [cachedData]="cachedData"
      (selectProduct)="selectProductById($event); quantityElement.focus()"
    ></lib-quickorder-summarize>
  </div>
  <div class="col-12 text-right fixed-bottom pr-5" [class.d-none]="isQuickorderSummarizeScrolledIntoView">
    <button type="button" class="btn btn-secondary" (click)="quickorderSummarize.focus()">
      <i class="fas fa-shopping-basket"></i>
    </button>
  </div>
</div>
