<div *ngIf="data">
  <div class="row border-bottom mb-2 cart-body" *ngFor="let ediInvoice of data; index as i; trackBy: trackByFn">
    <div class="col-12">
      <h5 class="d-inline cursor-pointer">
        <a [routerLink]="ediInvoice.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          {{ ediInvoice.id }} - <lib-edi-send-status [message]="ediInvoice"></lib-edi-send-status>
        </a>
      </h5>
      <div class="float-right">
        <lib-action-btn [(item)]="data[i]" [config]="actionConfigService"></lib-action-btn>
      </div>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="ORDER_ID"
        [(ngModel)]="ediInvoice.documentReferences.supplierOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="INVOICE_ID"
        [(ngModel)]="ediInvoice.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="BUYER"
        [(ngModel)]="ediInvoice.documentReferences.buyerOrder.id"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="VALIDATION"
        [(ngModel)]="ediInvoice.validationState"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="MESSAGE_DATE"
        [(ngModel)]="ediInvoice.documentReferences.despatchAdvice.date"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="CONFIG_ID"
        [(ngModel)]="ediInvoice.ediConfigId"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
    <div class="col-12 col-md-4" *ngIf="!!ediInvoice.timestamp">
      <lib-gung-text-input
        renderStatic="true"
        gungTranslate="TIMESTAMP"
        [(ngModel)]="ediInvoice.timestamp"
        class="label-bold"
      ></lib-gung-text-input>
    </div>
  </div>
</div>
