import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PasswordResetService {
  constructor(protected http: HttpClient) {}

  sendPasswordResetToken(email: string, domain: string): Observable<boolean | any> {
    return this.http
      .post<{
        status: string;
      }>('json/reset-password/send-token-email', {
        email,
        gungDomain: domain,
        newTemplate: true
      })
      .pipe(
        catchError(err => {
          return of({ status: 'ERROR' });
        }),
        map(res => {
          if (!res) {
            return false;
          }

          if (res.status === 'ERROR') {
            if ((res as any).users === 'MULTIPLE') {
              return {
                ...res,
                error: true
              };
            }
            return false;
          }
          return !!res;
        })
      );
  }
  updatePassword(token: string, newPassword: string): Observable<boolean> {
    return this.http
      .post<{
        status: string;
      }>('json/reset-password/set-password', {
        password: newPassword,
        token
      })
      .pipe(
        catchError(err => {
          return of({ status: 'ERROR' });
        }),
        map(res => {
          if (!res) {
            return false;
          }

          if (res.status === 'ERROR') {
            return false;
          }
          return !!res;
        })
      );
  }
}
