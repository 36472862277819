import {
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  ElementRef,
  HostListener,
  OnInit,
  OnDestroy
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, NavigationStart, ParamMap, Router } from '@angular/router';
import { interval, Subject } from 'rxjs';
import { debounce, filter, first, takeUntil } from 'rxjs';
import { FastsearchComponent } from '../fastsearch/fastsearch.component';

@Component({
  selector: 'lib-inline-search[configService]',
  templateUrl: './inline-search.component.html',
  styleUrls: ['./inline-search.component.css']
})
export class InlineSearchComponent<T> extends FastsearchComponent<T> implements OnInit, OnDestroy {
  public resultsLimit = 5;
  public resultsShow = false; // show results list
  public isSmallScreen = true; // is mobile
  protected unsubscribeRouter: Subject<void> = new Subject<void>();
  public totalCount = 0;
  public navigate: boolean = false; // when click inside but navigate (selected a product)

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (event.path && event.path.findIndex(t => t.dataset && !!t.dataset.inlineSearchProducts) > -1) {
      // Click on a product, close results
      this.resultsShow = false;
      return;
    }
    const insideClick = this.eRef.nativeElement.contains(event.target);
    if (!this.resultsShow && insideClick && !this.isSmallScreen) {
      this.resultsShow = true;
      return;
    } else if (this.resultsShow && !insideClick) {
      this.resultsShow = false;
    }
    this.navigate = false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.isSmallScreen = window.innerWidth <= 767;
  }

  public searchPlaceholderTranslate: string = 'SEARCH';

  constructor(
    protected resolver: ComponentFactoryResolver,
    protected detector: ChangeDetectorRef,
    protected eRef: ElementRef,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(resolver, detector, router, route);
    router.events
      .pipe(
        takeUntil(this.unsubscribeRouter),
        filter(event => event instanceof NavigationStart)
      )
      .subscribe(event => {
        this.resultsShow = false;
        this.navigate = true;
      });
  }

  ngOnInit() {
    // this.search('');

    this.searchTermForm = new FormControl(this.searchTerm);
    this.searchTermForm.valueChanges
      .pipe(
        takeUntil(this.unsubscribeRouter),
        debounce(() => interval(250))
      )
      .subscribe(valueChanged => {
        this.search(valueChanged);
      });

    const mediaQuery = matchMedia('screen and (max-width: 767px)');
    this.isSmallScreen = mediaQuery.matches;

    this.searchPlaceholderTranslate = this.configService.getSearchPlaceholderTranslate ? this.configService.getSearchPlaceholderTranslate() : this.searchPlaceholderTranslate;

    this.totalItems.pipe(takeUntil(this.unsubscribeRouter)).subscribe(total => (this.totalCount = total));
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
    this.unsubscribeRouter.next();
    this.unsubscribeRouter.complete();
  }

  public showMoreProducts({ value }) {
    // localStorage.setItem('searchTerm', value);
    this.resultsShow = false;
    //this.searchTermForm.patchValue('');
    const skuLevel = this.configService.hasOwnProperty('skuLevel') ? this.configService['skuLevel'] : true;
    this.router.navigate(['/no-operation'], { skipLocationChange: true }).then(() => {
      this.router.navigate(['/view-more'], { queryParams: { search: 'DEFAULT__:__' + value, limit: 24, skuLevel: skuLevel } });
    });
  }

  readQueryParams(queryParams: ParamMap) {
    return;
  }
  public keyPressedHandler(event, searchInput) {
    if (event.keyCode === 13) {
      //ENTER
      this.showMoreProducts(searchInput);
    }
  }
}
