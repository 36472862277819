import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs';
import { GungBase } from '../../models';
import { OrderRow } from '../../models/orderRow';

export interface OpenPurchaseOrders extends GungBase {
  deliveryCustomerId: string;
  rows: OrderRow[];
}

@Injectable({
  providedIn: 'root'
})
export class OpenPurchaseOrdersService {
  private baseUrl = 'json/purchase-orders';
  private openPurchaseOrdersSubjects: Subject<OpenPurchaseOrders[]>;

  constructor(protected http: HttpClient) {}

  addOpenPurchaseOrders(openPurchaseOrder: OpenPurchaseOrders): Observable<OpenPurchaseOrders[]> {
    const url = `${this.baseUrl}`;
    return this.http.post<OpenPurchaseOrders[]>(url, openPurchaseOrder).pipe(
      tap(
        // Log the result or error
        data => this.updateOpenPurchaseOrdersSubject(),
        error => console.log('ERROR', error)
      )
    );
  }

  updateOpenPurchaseOrdersSubject(): void {
    if (!this.openPurchaseOrdersSubjects) {
      this.openPurchaseOrdersSubjects = new ReplaySubject<OpenPurchaseOrders[]>();
    }
    this.internalGetOpenPurchaseOrdersFromUrl().subscribe((cc: OpenPurchaseOrders[]) =>
      this.openPurchaseOrdersSubjects.next(cc)
    );
  }

  protected internalGetOpenPurchaseOrdersFromUrl(): Observable<OpenPurchaseOrders[]> {
    const url = `${this.baseUrl}/list-open-purchase-orders`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<OpenPurchaseOrders[]>(url, { headers });
  }

  getOpenPurchaseOrders(): Observable<OpenPurchaseOrders[]> {
    if (!this.openPurchaseOrdersSubjects) {
      this.openPurchaseOrdersSubjects = new ReplaySubject<OpenPurchaseOrders[]>();
      return this.internalGetOpenPurchaseOrdersFromUrl().pipe(
        tap((cc: OpenPurchaseOrders[]) => this.openPurchaseOrdersSubjects.next(cc)),
        switchMap(_ => this.openPurchaseOrdersSubjects)
      );
    }
    return this.openPurchaseOrdersSubjects.asObservable();
  }
}
