import { Input, OnChanges, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { ConfigBaseFilter, FilterListComponent, PimTemplateProperties } from 'gung-list';
import { Product } from '../../models';
import { ProductTreeListConfigService } from '../../services/product-tree-list-config/product-tree-list-config.service';

@Component({
  selector: 'lib-product-tree-list',
  template: '<lib-filter-list #productList [configService]="configService"></lib-filter-list>',
  styles: []
})
export class ProductTreeListComponent implements OnInit, OnChanges {
  @Input()
  products: Product[];

  @Input()
  filters: ConfigBaseFilter<Product>[] = [];

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  @ViewChild('productList') conceptList: FilterListComponent<Product>;

  constructor(public configService: ProductTreeListConfigService) {}

  ngOnInit() {
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
    this.configService.productsObjectsSubject.next(this.products);
  }

  ngOnChanges() {
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
    this.configService.productsObjectsSubject.next(this.products);
  }
}
