<table class="table table-striped">
  <thead>
    <tr>
      <th>
        <strong translate>ORDER</strong>
      </th>
      <th>
        <strong translate>ROW</strong>
      </th>
      <th>
        <strong translate>QUANTITY</strong>
      </th>
      <th>
        <strong translate>DELIVERED</strong>
      </th>
      <th>
        <strong>Delta</strong>
      </th>
      <th>
        <strong translate>DELIVERY_DATE</strong>
      </th>
      <th>
        <strong translate>TYPE</strong>
      </th>
      <th>
        <strong translate>IGNORED</strong>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let transaction of transactionsData" [class.green]="transaction.transactionType === 'PO'">
      <td>
        {{ transaction.orderId }}
      </td>
      <td>
        {{ transaction.lineNumber }}
      </td>
      <td>
        {{ transaction.quantity }} <span *ngIf="transaction.quantity || transaction.quantity == 0" translate>pcs</span>
      </td>
      <td>
        {{ transaction.quantityDelivered }}
        <span *ngIf="transaction.quantityDelivered || transaction.quantityDelivered == 0" translate>pcs</span>
      </td>
      <td>
        {{ transaction.delta }}
      </td>
      <td>
        {{ transaction.transactionDate | date: 'yyyy-MM-dd' }}
      </td>
      <td>
        <span translate>{{ transaction.orderTypeCode }}</span>
      </td>
      <td>
        <span translate>{{ transaction.ignored ? 'YES' : 'NO' }}</span>
      </td>
    </tr>
  </tbody>
  <tfoot></tfoot>
</table>
