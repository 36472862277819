import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EdiInvoicesActionConfigService } from '../../../services/edi-invoices-action-config/edi-invoices-action-config.service';

@Component({
  selector: 'lib-edi-invoice-card-list',
  templateUrl: './edi-invoice-card-list.component.html',
  styleUrls: ['./edi-invoice-card-list.component.css']
})
export class EdiInvoiceCardListComponent extends ListItemRendererComponent<any[]> implements OnInit, AfterViewInit {
  constructor(public actionConfigService: EdiInvoicesActionConfigService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  trackByFn(index: any, item: any) {
    return item.id;
  }
}
