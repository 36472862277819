import { Injectable } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { Customer } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class CustomerSelectionService extends AbstractSelectionService<Customer> {
  constructor() {
    super();
  }

  getItemId(item: Customer): string {
    return item.id;
  }
}
