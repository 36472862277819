import { Component, Input, OnInit } from '@angular/core';
import { NgbTypeaheadSelectItemEvent } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs';
import { Pim, PimConcept } from '../../../../models/pim';
@Component({
  selector: 'lib-pim-product-related-products',
  templateUrl: './pim-product-related-products.component.html',
  styleUrls: ['./pim-product-related-products.component.css']
})
export class PimProductRelatedProductsComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  @Input()
  pimProducts: Pim[];

  @Input()
  pimConcepts: PimConcept[];

  @Input()
  variantLevel?: boolean;

  modelRelatedProduct = null;
  modelIncludedBrand = null;
  searchProducts = [];

  constructor() {}

  ngOnInit() {
    // FIX when some bug save array in string - sportmarket
    if (
      typeof this.pimProduct.extra.pim.concepts === 'string' &&
      this.pimProduct.extra.pim.concepts.startsWith('[') &&
      this.pimProduct.extra.pim.concepts.endsWith(']')
    ) {
      const concepts: string = this.pimProduct.extra.pim.concepts;
      this.pimProduct.extra.pim.concepts = concepts.substring(1, concepts.length - 1);
      this.pimProduct.extra.pim.concepts = this.pimProduct.extra.pim.concepts.split(', ');
    }

    if (this.variantLevel) {
      this.searchProducts = this.pimProducts.reduce((acc, product) => {
        if (!!product?.dimensions?.colors) {
          product.dimensions.colors.forEach(color => {
            acc.push({
              ...product,
              id: product.id + color.dimensionId,
              name: product.name + ' - ' + color.name
            });
          });
        } else {
          acc.push(product);
        }
        return acc;
      },[]);
    } else {
      this.searchProducts = this.pimProducts;
    }
  }

  searchRelatedProducts = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        !this.searchProducts
          ? []
          : (!term
              ? this.searchProducts
              : this.searchProducts.filter(
                  item =>
                    item.id.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    (!!item.name && item.name.toLowerCase().indexOf(term.toLowerCase()) > -1)
                )
            ).slice(0, 10)
      )
    );

  formatterRelatedProducts = (result: Pim): string => result.id + ' - ' + result.name;

  onRelatedProductSelect(event: NgbTypeaheadSelectItemEvent) {
    const pim = event.item as Pim;

    if (!this.pimProduct.extra.pim.relatedProducts) {
      this.pimProduct.extra.pim.relatedProducts = pim.id;
    } else {
      if (this.pimProduct.extra.pim.relatedProducts.indexOf(pim.id) < 0) {
        this.pimProduct.extra.pim.relatedProducts += ',' + pim.id;
      }
    }

    event.preventDefault();
    this.modelRelatedProduct = null;
  }

  removeRelatedProduct(id: string) {
    const allRelatedProducts = this.pimProduct.extra.pim.relatedProducts.split(',');

    const indexToRemove = allRelatedProducts.findIndex(itemId => itemId === id);
    if (indexToRemove > -1) {
      allRelatedProducts.splice(indexToRemove, 1);
    }

    this.pimProduct.extra.pim.relatedProducts = allRelatedProducts.join(',');
  }

  searchIncludedBrands = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        !this.pimConcepts
          ? []
          : (!term
              ? this.pimConcepts
              : this.pimConcepts.filter(item => {
                  return (
                    item.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
                    item.id.toLowerCase().indexOf(term.toLowerCase()) > -1
                  );
                })
            ).slice(0, 10)
      )
    );

  formatterIncludedBrands = (result: PimConcept): string => result.id + ' - ' + result.name;

  onIncludedBrandsSelect(event: NgbTypeaheadSelectItemEvent) {
    const pimConcept = event.item as PimConcept;

    this.pimProduct.extra.pim.concepts = this.pimProduct.extra.pim.concepts || [];

    if (!this.pimProduct.extra.pim.concepts.includes(pimConcept.id)) {
      this.pimProduct.extra.pim.concepts.push(pimConcept.id);
    }

    event.preventDefault();
    this.modelIncludedBrand = null;
  }

  removeIncludedBrands(conceptId: string) {
    const indexToRemove = this.pimProduct.extra.pim.concepts.findIndex(item => item === conceptId);
    if (indexToRemove > -1) {
      this.pimProduct.extra.pim.concepts.splice(indexToRemove, 1);
    }
  }
}
