import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { forkJoin, Observable, of } from 'rxjs';
import { LogoApplication, LogoApplicationSave } from '../../models/logo-application';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { first, map, mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LogoApplicatorService {
  constructor(protected http: HttpClient, protected selectedCustomerService: SelectedCustomerService) {}

  public getLogoApplications(customerId: string): Observable<LogoApplication[]> {
    const url = 'json/logo-applicator/get-applications';
    return this.http.post<LogoApplication[]>(url, customerId);
  }

  public getLogoApplication(id: string): Observable<LogoApplication> {
    const url = 'json/logo-applicator/' + id;
    return this.http.get<LogoApplication>(url);
  }

  public saveApplication(logoApp: LogoApplicationSave): Observable<any> {
    return this.selectedCustomerService.getSelectedCustomer().pipe(
      first(),
      switchMap(selectedCustomer => {
        logoApp.customerId = selectedCustomer.id;

        return forkJoin([
          of(logoApp),
          this.uploadCanvas(logoApp.canvasImages, logoApp.customerId),
          this.uploadImages(logoApp.imagesToUpload)
        ]);
      }),
      switchMap(([la, canvasResponses, imagesResponses]) => {
        const uploadedImagesMap = {};
        imagesResponses.forEach(img => {
          uploadedImagesMap[img.id] = img.uri;
        });

        la.profiles.forEach(profile => {
          profile.images.forEach(img => {
            if (!img.text && !!img.s3Uri) {
              uploadedImagesMap[img.id] = img.s3Uri;
            }
          });
        });

        const canvasS3Uris = canvasResponses.map(o => o.s3Uri);
        const body = {
          name: la.name,
          canvasS3Uris,
          appliedImageUris: uploadedImagesMap,
          customerId: la.customerId,
          productId: la.productId,
          state: {
            profiles: la.profiles,
            colors: la.profiles,
            selectedColor: la.selectedColor
          },
          id: la.applicationId
        };

        if (!la.isEdit) {
          delete body.id;
        }

        return this.http.post('json/logo-applicator/store-application', body);
      })
    );
  }

  private uploadCanvas(canvasImages, customerId): Observable<any> {
    const timeStamp = new Date();
    const promises = [];

    canvasImages.forEach(img => {
      promises.push(
        this.http.post('json/logo-applicator/canvas-image', {
          file: img,
          timeStamp,
          customerId
        })
      );
    });

    return forkJoin(promises);
  }

  private uploadImages(images) {
    const timeStamp = new Date();
    if (!images || !images.length) {
      return of([]);
    }

    const promises = [];
    images.forEach(img => {
      if (!!img.file.$error) {
        return;
      }

      const formData = new FormData();
      formData.append('file', img.file);
      promises.push(this.http.post('json/logo-applicator/image', formData));
    });

    return forkJoin(promises).pipe(
      map(responseImages => {
        return responseImages.map(({ id, s3Uri }) => {
          return {
            id,
            uri: s3Uri
          };
        });
      })
    );
  }
}
