import { Component, OnInit } from '@angular/core';
import { ChangeDateEvent, GungCalendarItem } from 'gung-common';
import { first, map } from 'rxjs';
import { ActivityInternal, ActivityInternalService } from '../../../services/activity-internal/activity-internal.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-activity-internal-gung-calendar',
  templateUrl: './activity-internal-gung-calendar.component.html',
  styleUrls: ['./activity-internal-gung-calendar.component.css']
})
export class ActivityInternalGungCalendarComponent implements OnInit {
  calendarItemsList: GungCalendarItem[] = [];

  constructor(protected activityInternalService: ActivityInternalService, protected gungModalService: GungModalService) { }

  ngOnInit(): void {
    this.activityInternalService.getAll().pipe(
      map(result =>
        result.sort((a, b) => {
          if (new Date(a.activityDate) > new Date(b.activityDate)) {
            return 1;
          }
          if (new Date(a.activityDate) < new Date(b.activityDate)) {
            return -1;
          }
          return 0;
        })
      )
    ).subscribe(res => {
      this.calendarItemsList = [];
      for (const activity of res) {
        this.calendarItemsList.push({
          id: activity.id,
          date: new Date(activity.activityDate),
          heading: activity.customerName,
          action: () => this.editActivity(activity)
        });
      }
    });
  }

  createActivity(selectedDate: Date) {
    this.gungModalService.openCreateActivityModal(undefined, undefined, undefined, undefined, selectedDate).then(
      result => {},
      reject => {}
    );
  }

  editActivity(activity: ActivityInternal) {
    this.gungModalService.openViewActivityModal(activity).then(
      result => {},
      reject => {}
    );
  }

  changeActivityDate(event: ChangeDateEvent) {
    this.activityInternalService.getById(event.id).pipe(first()).subscribe(activity => {
      activity.activityDate = event.date.toISOString();
      this.activityInternalService.postActivityInternal(activity).pipe(first()).subscribe(_ => {});
    });
  }
}