import { Component, Input, OnInit } from '@angular/core';
import { CommonModalService } from '../../../services/common-modal/common-modal.service';

export interface JsonViewModal {
  title: string;
  subTitle?: string;
  json: any;
  typeString?: boolean;
}

@Component({
  selector: 'lib-json-view-modal',
  templateUrl: './json-view-modal.component.html',
  styleUrls: ['./json-view-modal.component.css']
})
export class JsonViewModalComponent implements OnInit {
  delegate: CommonModalService;
  componentId: string;

  @Input()
  data: JsonViewModal;

  constructor() {}

  ngOnInit() {
    this.componentId = JsonViewModalComponent.name;
  }
}
