<div class="p-2 bg-light">
  <div class="row">
    <div class="col-12">
      <div class="text-center">
        <button type="button" class="btn btn-primary w-100" routerLink="/checkout" translate>GO_TO_CHECKOUT</button>
      </div>
    </div>
  </div>
  <div class="row my-3">
    <div class="col-12 col-md-6">
      <span
        ><b translate>TOTAL</b>:
        <lib-price *ngIf="cartContents" [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price
      ></span>
    </div>
    <div class="col-12 col-md-6">
      <span class="d-block"><b translate>ITEMS</b>: {{ cartTotalRows }}</span>
      <!-- <span class="d-block"><b translate>QUANTITY</b>: {{cartTotalItems}}</span> -->
    </div>
  </div>
  <div class="mt-2" *ngFor="let row of cart">
    <div class="row" *ngIf="cachedData[row.productId]">
      <div class="col-12 col-sm-8 col-md-12 col-xl-7">
        <span class="cursor-pointer" (click)="clickProduct(row.productId)">
          {{ cachedData[row.productId].product.name }}<br />
          <small class="text-secondary">{{ row.productId }}</small>
        </span>
      </div>
      <div class="col-12 col-sm-4 col-md-12 col-xl-5 d-flex flex-wrap">
        <lib-buy-btn [id]="row.productId" [product]="cachedData[row.productId].product" class="mr-1"></lib-buy-btn>
        <button style="height: 29px" type="button" class="btn btn-sm btn-danger" (click)="removeRow(row)">
          <i class="fas fa-times-circle"></i>
        </button>
      </div>
    </div>
  </div>
</div>
