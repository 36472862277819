import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { FreightTerm } from '../../models/freight-term';
import { FreightTermService } from '../../services/freight-term/freight-term.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-shipping-charges-manager',
  templateUrl: './shipping-charges-manager.component.html',
  styleUrls: ['./shipping-charges-manager.component.css']
})
export class ShippingChargesManagerComponent implements OnInit {
  /* newFreightForm: FormGroup; */

  showAddNewFreightTerm = false;
  creatingNewFreightTerm = false;
  disableActionsIndex: number;
  loadingAllShippingCharges = false;

  displayMessageToUser = '';

  shippingCharges: FreightTerm[];
  enableEdit = false;
  editingIndex: number;

  newFreightTerm: FreightTerm = {
    id: '',
    freightProductId: '',
    freightTermId: '',
    freightRoof: 0,
    minimumFreightSum: 0,
    freightPercent: 0,
    extra: {}
  };

  constructor(
    /* protected formBuilder: FormBuilder, */
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected freightTermService: FreightTermService
  ) {}

  ngOnInit(): void {
    this.getAllShippingCharges();
  }

  onClickEditShippingCharge(shippingCharge: FreightTerm, index: number) {
    if (this.editingIndex === index) {
      this.enableEdit = false;
      this.editingIndex = undefined;
      this.updateShippingCharge(shippingCharge, index);
    } else {
      this.enableEdit = true;
      this.editingIndex = index;
    }
  }

  updateShippingCharge(shippingCharge: FreightTerm, index: number) {
    this.disableActionsIndex = index;
    this.freightTermService.updateShippingCharge(shippingCharge).subscribe(shippingChargeUpdated => {
      this.disableActionsIndex = undefined;
    });
  }

  saveNewShippingCharge(shippingCharge: FreightTerm) {
    if (
      shippingCharge &&
      (shippingCharge.id || shippingCharge.freightTermId) &&
      (shippingCharge.id.length > 0 || shippingCharge.freightTermId.length > 0)
    ) {
      this.creatingNewFreightTerm = true;
      if (shippingCharge.id === '') {
        delete shippingCharge.id;
      }
      if (shippingCharge.freightTermId === '') {
        shippingCharge.freightTermId = shippingCharge.id;
      }

      if (!shippingCharge.id) {
        shippingCharge.id = `${shippingCharge.freightTermId}_${(new Date()).getTime()}`
      }
      this.freightTermService.addShippingCharge(shippingCharge).subscribe(shippingChargeCreated => {
        this.creatingNewFreightTerm = false;
        this.showAddNewFreightTerm = false;
        this.getAllShippingCharges();
      });
    } else {
      this.displayMessageToUser = 'SHIPPING_CHARGES_FIELD_ID_IS_REQUIRED';
    }
  }

  openDeleteConfirmationModal(shippingCharge: FreightTerm, index: number) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CONFIRM_DELETION', { value: '' }) + '?', {
        size: 'sm'
      })
      .then(
        result => {
          if (result) {
            this.onClickDeleteShippingCharge(shippingCharge, index);
          }
        },
        reason => {}
      );
  }

  onClickDeleteShippingCharge(shippingCharge: FreightTerm, index: number) {
    if (shippingCharge && shippingCharge.id) {
      this.disableActionsIndex = index;
      this.freightTermService.deleteShippingCharge(shippingCharge.id).subscribe(freightTerm => {
        this.disableActionsIndex = undefined;
        this.getAllShippingCharges();
      });
    }
  }

  getAllShippingCharges() {
    this.loadingAllShippingCharges = true;
    this.freightTermService.getShippingCharges().subscribe(shippingCharges => {
      this.shippingCharges = shippingCharges.sort((a, b) => {
        const comp = (a.freightTermId || a.id).localeCompare(b.freightTermId || b.id);
        if (comp !== 0) {
          return comp;
        }
        return a.minimumFreightSum - b.minimumFreightSum;
      });
      this.loadingAllShippingCharges = false;
      this.cleanMessageToUser();
    });
  }

  /* protected initForm() {
    this.newFreightForm = this.formBuilder.group({
      paymentMethodId: this.formBuilder.control({ value: this.newFreightTerm || '', disabled: true }),
      paymentTermsId: this.formBuilder.control({ value: this.checkout.extra.salesOrders.paymentTermsId || '', disabled: false }),
      shipmentMethodId: this.formBuilder.control({ value: this.checkout.extra.salesOrders.shipmentMethodId || '', disabled: false }),
      orderType: this.formBuilder.control({ value: this.checkout.extra.salesOrders.orderType || '', disabled: true }),
      salesperson: this.formBuilder.control({ value: this.checkout.extra.salesOrders.salesperson || '', disabled: true }),
    });
  } */

  openAddNewFreightForm() {
    this.showAddNewFreightTerm = true;
    this.setNewFreightTerm();
  }

  setNewFreightTerm() {
    this.newFreightTerm = {
      id: '',
      freightProductId: '',
      freightTermId: '',
      freightRoof: 0,
      minimumFreightSum: 0,
      freightPercent: 0,
      extra: {}
    };
  }

  cleanMessageToUser() {
    this.displayMessageToUser = '';
  }
}
