import { Component, OnInit } from '@angular/core';
import { SystemSettings } from '../../../models/system-setting';
import { SystemSettingsService } from '../../../services/system-settings.service';

@Component({
  selector: 'lib-system-settings',
  templateUrl: './system-settings.component.html',
  styleUrls: ['./system-settings.component.css']
})
export class SystemSettingsComponent implements OnInit {
  systemSettingsString;
  statusMessage: string;
  error = false;

  constructor(protected systemSettingsService: SystemSettingsService) { 

  }

  ngOnInit() {
    this.systemSettingsService.getSystemSettingsById('GUNG').subscribe(settings => {
      this.systemSettingsString = this.parseSystemSettingsAsJSON(settings);
    });
  }

  getValidatedJSON(value: string) {
    try {
      const json = JSON.parse(value);
      this.error = false;
      return json;
    } catch (err) {
      this.statusMessage = err;
      this.error = true;
      throw err;
    }
  }

  getSystemSettings(id: string) {
    this.systemSettingsService.getSystemSettingsById(id).subscribe(settings => {
      this.systemSettingsString = this.parseSystemSettingsAsJSON(settings);
    })
  }

  private parseSystemSettingsAsJSON(settings: SystemSettings): string {
    if (settings == null) {
      return;
    }
    this.systemSettingsString = JSON.stringify(settings, undefined, 4);
    return this.systemSettingsString;
  }

  save() {
    try {
      const validatedJson = this.getValidatedJSON(this.systemSettingsString);
      this.systemSettingsService.updateSystemSettings('GUNG', validatedJson).subscribe(settings => {
        if (!!settings) {
          this.statusMessage = 'SAVED';
          alert(this.statusMessage);
          return;
        }
      })
    } catch (err) {
      this.statusMessage = err;
      alert(err);
      this.error = true;
      throw err;
    }
  }
}
