import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DynamicPdfService } from './dynamic-pdf.service';
import { switchMap } from 'rxjs';
import { PdfDocument } from '../models/pdf-document';
import { PdfProductService } from './pdf-product.service';
import { PdfProductGridViewComponent } from '../components/pdf-product-grid-view/pdf-product-grid-view.component';

@Injectable({
  providedIn: 'root'
})
export class ExportPdfOrderProductListConfigService implements ConfigService<any> {
  exportPdfSubject: Subject<string> = new BehaviorSubject(null);
  topFilter = false;

  constructor(protected productService: PdfProductService, protected dynamicPdfService: DynamicPdfService) {
    this.topFilter = true;
  }

  getItems(): Observable<any[]> {
    return this.exportPdfSubject.pipe(
      switchMap((pdfDocument: string) => {
        if (!pdfDocument) {
          return;
        }

        return this.dynamicPdfService.getPdf(pdfDocument);
      }),
      switchMap((pdfDocument: PdfDocument) => {
        if (!pdfDocument) {
          return;
        }

        const itemIds: string[] = [];
        pdfDocument.pages.forEach(page => {
          page.items.forEach(i => {
            if (i.data.variantIds) {
              for (const v of i.data.variantIds) {
                itemIds.push(v);
              }
            }else if (i.data.productId) {
              itemIds.push(i.data.productId);
            }
          });
        });
        return this.productService.getProductsByIds(itemIds);
      })
    );
  }

  getBatchSizes(): number[] | undefined {
    return [24];
  }

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getItemId(item: any): string {
    return item.id;
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PdfProductGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getSearchTerms(item: any): string[] {
    return [item.id];
  }

  getSortOptions(): ListSortOption<any>[] {
    return [];
  }
}
