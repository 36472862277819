<h2 class="text-center" translate>MANAGE_ASSORTMENT</h2>

<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink translate>IMPORT</a>
    <ng-template ngbNavContent>
      <lib-assortment-management-import></lib-assortment-management-import>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>EXPORT</a>
    <ng-template ngbNavContent>
      <lib-assortment-management-export></lib-assortment-management-export>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>EXPORT_TEMPLATES</a>
    <ng-template ngbNavContent>
      <p><a href="{{ backendBaseUrl }}/templates/excel/assortment-template.xlsx">Download Template</a></p>
    </ng-template>
  </ng-container>
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
