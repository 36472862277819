import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { EdiOrder, EdiMessageBase } from '../../../models/edi';
import { EdiOrderActionConfigService } from '../../../services/edi-order-action-config/edi-order-action-config.service';

@Component({
  selector: 'lib-edi-order-card-list',
  templateUrl: './edi-order-card-list.component.html',
  styleUrls: ['./edi-order-card-list.component.css']
})
export class EdiOrderCardListComponent extends ListItemRendererComponent<EdiOrder[]> implements OnInit, AfterViewInit {
  selectedEdiOrders: EdiMessageBase[] = [];

  constructor(public actionConfigService: EdiOrderActionConfigService) {
    super();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  selectEdiOrder(order: EdiMessageBase, select: boolean) {
    const idx = this.selectedEdiOrders.findIndex(o => o.id === order.id);
    if (select && idx === -1) {
      this.selectedEdiOrders.push(order);
    } else if (!select && idx > -1) {
      this.selectedEdiOrders.splice(idx, 1);
    }
  }

  trackByFn(index: any, item: any) {
    return item.documentReferences.buyerOrder.id + '' + item.erpIds;
  }
}
