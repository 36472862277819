<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>SELECT_CUSTOMER_MODAL_TITLE</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <span translate>SELECTED_CUSTOMER_OPTION</span>
  <span style="font-weight: bold"> {{ currentCustomer.name }} </span>
  <span translate>SELECTED_CUSTOMER_CONTINUE</span>
  <span style="font-weight: bold"> {{ nextCustomer.name }}</span> ?
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="activeModal.close('Keep')" translate>
    CUSTOMER_CONTINUE
  </button>
  <button type="button" class="btn btn-primary" (click)="activeModal.close('Change')" translate>CUSTOMER_CHANGE</button>
</div>
