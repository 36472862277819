import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductConcept } from '../concept-detail-v2/concept-detail-v2.component';

@Component({
  selector: 'lib-concept-detail-v2-top-details',
  templateUrl: './concept-detail-v2-top-details.component.html',
  styleUrls: ['./concept-detail-v2-top-details.component.css']
})
export class ConceptDetailV2TopDetailsComponent implements OnInit {
  @Input()
  concept: ProductConcept;
  currentLang = this.translateService.currentLang;

  constructor( protected translateService: TranslateService) {}

  ngOnInit(): void {}
}
