import { Component, OnInit } from '@angular/core';
import { EdiInvoicesListConfigService } from '../../../services/edi-invoices-list-config/edi-invoices-list-config.service';

@Component({
  selector: 'lib-edi-invoices',
  template: `<h3 class="d-block page-title text-center" translate>EDI - Invoices</h3>
    <lib-filter-list [configService]="ediInvoicesListConfigService"></lib-filter-list>`,
  styleUrls: ['./edi-invoices.component.css']
})
export class EdiInvoicesComponent implements OnInit {
  constructor(public ediInvoicesListConfigService: EdiInvoicesListConfigService) {}

  ngOnInit(): void {}
}
