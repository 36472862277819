<div *ngIf="priceLevels.length > 1 && priceLevelsDisplayType !== 'FULL'" [ngClass]="priceLevelsDisplayType === 'COMPACT_LIST' ? 'd-inline' : ''">
  <span *ngIf="priceLevels[currentPrice + 1]" [ngClass]="priceLevelsDisplayType === 'COMPACT_LIST' ? 'ml-2 remaining-text' : 'remaining-text'">{{ priceLevels[currentPrice + 1].quantity - dataCart?.qty }} <span translate>LEFT_FOR</span> {{ priceLevels[currentPrice + 1].price | gungCurrency: price.customerNetPrice.currencyCode }}</span>
  <span ngbDropdown class="ml-2" container="body">
    <i ngbDropdownToggle class="fas fa-info-circle"></i>
    
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
      <table class="table">
        <tr>
          <th translate>QTY</th>
          <th translate>PRICE</th>
        </tr>
        <tr *ngFor="let level of priceLevels">
          <td>{{ level.quantity }}</td>
          <td>{{ level.price | gungCurrency: price.customerNetPrice.currencyCode }}</td>
        </tr>
      </table>
    </div>
  </span>
</div>

<div *ngIf="priceLevels.length > 1 && priceLevelsDisplayType === 'FULL'">
  <span *ngIf="priceLevels[currentPrice + 1]" class="remaining-text-big primary">{{ priceLevels[currentPrice + 1].quantity - dataCart?.qty }} <span translate>LEFT_FOR</span> {{ priceLevels[currentPrice + 1].price | gungCurrency: price.customerNetPrice.currencyCode }}</span>
  <div *ngIf="priceLevels.length > 1" class="d-flex flex-column">
    <div *ngFor="let lvl of priceLevels; let i = index" class="remaining-text primary">
      <span *ngIf="i === 0 || dataCart?.qty < lvl.quantity; else after">
        <span *ngIf="i === 0">{{ lvl.quantity }} </span>
        <span *ngIf="i > 0">{{ lvl.quantity - dataCart?.qty }} </span>
        <span translate *ngIf="i > 0">LEFT_FOR</span>
        <span translate *ngIf="i === 0">FOR</span>
        {{ lvl.price | gungCurrency: price.customerNetPrice.currencyCode }}
      </span>
      <ng-template #after>
        <span *ngIf="lvl">
          {{ lvl.quantity }} 
          <span translate>FOR</span> 
          {{ lvl.price | gungCurrency: price.customerNetPrice.currencyCode }}
        </span>
      </ng-template>
    </div>
  </div>
</div>
