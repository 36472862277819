import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { PimConcept } from '../../../models/pim';
import { PimService } from '../../../services/pim/pim.service';
import { DocumentsUploadModalComponent } from '../../documents-upload/documents-upload-modal/documents-upload-modal.component';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-brand-edit',
  templateUrl: './brand-edit.component.html',
  styleUrls: ['./brand-edit.component.css']
})
export class BrandEditComponent implements OnInit {
  alertMessage: Alert;
  loadingsubmitConcept = false;
  loadingDeleteConcept = false;
  uploadingConceptFiles = false;
  conceptBrand: PimConcept;
  edit = false;

  public brandForm: FormGroup = null;

  constructor(
    protected formBuilder: FormBuilder,
    protected modalService: NgbModal,
    protected router: Router,
    protected pimService: PimService,
    protected translationService: TranslateService,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      const brandId = params.get('brandId');
      if (!brandId || brandId === 'new-brand') {
        this.initForm();
      } else {
        this.getConceptById(brandId);
      }
    });
  }

  getConceptById(id) {
    this.pimService
      .getPimConcept(id, true)
      .pipe(first())
      .subscribe(concept => {
        this.conceptBrand = concept;
        this.edit = true;
        this.initForm();
      });
  }

  initForm() {
    this.brandForm = this.formBuilder.group({
      id: this.formBuilder.control((this.conceptBrand && this.conceptBrand.id) || '', [Validators.required]),
      name: this.formBuilder.control((this.conceptBrand && this.conceptBrand.name) || '', [Validators.required])
    });
  }

  onSubmit() {
    if (this.brandForm.invalid) {
      this.brandForm.markAllAsTouched();
      return;
    }

    this.loadingsubmitConcept = true;
    const formRaw = this.brandForm.getRawValue();

    if (this.edit) {
      const conceptBrandObject: PimConcept = {
        ...this.conceptBrand,
        name: formRaw.name,
        id: formRaw.id,
        dateLastUpdate: new Date().getTime(),
        images: this.conceptBrand.images ? this.conceptBrand.images : []
      };

      this.pimService.editConcept(conceptBrandObject.id, conceptBrandObject).subscribe(result => {
        this.conceptBrand = result;
        this.loadingsubmitConcept = false;
      });
    } else {
      // CREATE
      const conceptBrandObject: PimConcept = {
        name: formRaw.name,
        id: formRaw.id,
        dateLastUpdate: new Date().getTime(),
        images: [],
        i18n: {},
        extra: {}
      };

      this.pimService.newConcept(conceptBrandObject).subscribe(result => {
        this.loadingsubmitConcept = false;
        this.conceptBrand = result;
        this.edit = true;
        if (this.conceptBrand && this.conceptBrand.id) {
          this.router.navigate(['/admin/related-brands/' + this.conceptBrand.id]);
        }
      });
    }
  }

  deleteConceptBrand() {
    if (this.conceptBrand && this.conceptBrand.id) {
      this.loadingDeleteConcept = true;
      this.pimService.deleteConcept(this.conceptBrand.id).subscribe(result => {
        this.loadingDeleteConcept = false;
        this.router.navigate(['../'], { relativeTo: this.route });
      });
    }
  }

  uploadDroppedFile(files: File[], id) {
    const formData = new FormData();
    const aFiles = Array.of(...files);
    formData.append('file', aFiles.shift());

    this.uploadingConceptFiles = true;
    this.pimService
      .postConceptImages(id, formData)
      .pipe(first())
      .subscribe((event: PimConcept) => {
        if (aFiles.length > 0) {
          this.conceptBrand = event;
          this.uploadDroppedFile(aFiles, id);
        } else {
          if (typeof event === 'object') {
            if (event.images) {
              this.conceptBrand = event;
              this.uploadingConceptFiles = false;
            }
          }
        }
      });
  }

  goToConceptBrandsList() {
    this.router.navigate(['/admin/related-brands/']);
  }

  /* DRAG AND DROP METHODS */

  onDragOver(event /* : DragEvent */) {
    // console.log(`Drag Over`, event);
    event.target.style.opacity = 0.5;
    event.preventDefault();
    event.stopPropagation();
  }

  onDragLeave(event /* : DragEvent */) {
    // console.log('Drag Leave', event);
    event.target.style.opacity = 1;
    event.preventDefault();
    event.stopPropagation();
  }

  onDrop(event, type: string) {
    event.preventDefault();
    event.stopPropagation();
    event.target.style.opacity = 1;
    const files = event.target.files || event.dataTransfer.files;
    const brand = this.brandForm.getRawValue() as PimConcept;
    if (files.length > 0 && brand && brand.id) {
      this.uploadDroppedFile(files, brand.id);
      event.target.value = '';
    }
  }

  removeElement(image: any, index) {
    this.conceptBrand.images.splice(index, 1);
    const formData = new FormData();
    formData.append('file', image);
    formData.append('language', this.translationService.currentLang);

    this.pimService
      .deleteConceptImages(this.conceptBrand.id, image)
      .pipe(first())
      .subscribe(
        (conceptBrandResult: PimConcept) => {
          if (typeof conceptBrandResult === 'object') {
            if (conceptBrandResult.images) {
              this.conceptBrand.images = conceptBrandResult.images;
            }
          }
        },
        error => {}
      );
  }

  /* openUploadImagesModal() {
    const brand = this.brandForm.getRawValue();
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false
    };

    const modalRef = this.modalService.open(DocumentsUploadModalComponent, config);
    modalRef.componentInstance.multiple = true;
    modalRef.componentInstance.uploadMethod = (file, fileDescription, inMenu) => this.uploadDroppedFile(file, brand.id);
    modalRef.result.then((result) => {
      if (result === true) {
        //this.getAllDocuments(true);
      }
    }, (reason) => {
      console.log(`Dismissed ${reason}`);
    });
  } */

  /* uploadFile(files: File[], fileDescription: string, inMenu: boolean = false, id: string): Promise<boolean> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    formData.append('inMenu', inMenu.toString());
    formData.append('description', fileDescription);
    formData.append('language', this.translationService.currentLang);

    return new Promise((resolve, reject) => {
      this.pimService.postConceptImages(id, formData).pipe(first())
        .subscribe(
          (event: PimConcept) => {
            if (typeof (event) === 'object') {
              if (event.images) {
                console.log("postConceptImages", event.images);
                resolve(true);
              }
            }
          },
          (error) => { reject(error); }
        );
    });
  } */
}
