<!-- Old Implementation <div class="row">
  <div class="col-1 d-flex align-items-center justify-content-center">
  </div>
  <div class="col-1">
  </div>
  <div class="col d-flex align-items-center">
    <div translate>
      NAME
    </div>
  </div>
  <div class="col-2 d-flex align-items-center justify-content-center">
    <div class="text-center" translate>
      REQUESTED_QUANTITY
    </div>
  </div>
  <div class="col-2 d-flex align-items-center justify-content-center">
    <div class="text-center" translate>
      UNIT_PRICE
    </div>
  </div>
  <div class="vhr col-2 d-flex align-items-center justify-content-center flex-column">
    <div translate>
      TOTAL_PRICE
    </div>
  </div>
</div>
<div class="row hr mt-2"></div>
<div class="row">
  <div class="col-12" *ngFor="let item of items">
    <ng-template libCustomHost></ng-template>
  </div>
</div>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>CLEAR_CART</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('CANCEL')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p translate>CLEAR_CART_CONFIRM</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn secondary" (click)="modal.dismiss('CANCEL')" translate>CANCEL</button>
    <button type="button" class="btn btn-primary" (click)="modal.close('CLEAR_CART')" translate>OK</button>
  </div>
</ng-template> -->

<div class="row pb-2 d-none d-md-flex">
  <div class="col-4 col-lg-1"></div>
  <div class="d-none d-lg-block col-12 col-md-6 col-lg-3"></div>
  <div class="d-none d-lg-block col-4 col-lg-3">
    <b translate>REQUESTED_QUANTITY</b>
  </div>
  <div class="d-none d-lg-block col-4 col-lg-2">
    <b translate>UNIT_PRICE</b>
  </div>
  <div class="vhr d-none d-lg-block col-4 col-lg-2">
    <b translate>TOTAL_PRICE</b>
  </div>
  <div class="col-1"></div>
</div>

<div class="">
  <div *ngFor="let item of items" class="col-12">
    <ng-template libCustomHost></ng-template>
  </div>
</div>
