<div class="alert alert-danger" role="alert">
  <h4 translate>ORDER_SUBMIT_ERROR</h4>
  <div *ngIf="error.exceptionClass === 'io.gung.api.exceptions.GungPreventDoubleSubmitException'; else otherError">
    <h1>The order has already been submitted</h1>

    <p>
      The contents of your order has recently been sent to the server and a order is being created. Because of this
      attempt to create an order has been prevented.
    </p>

    <p>
      If you wanted to create another order just like the one you recently posted, then you should wait at least 30
      seconds before trying again.
    </p>

    <p>Otherwise, click here to clear the contents of your cart</p>

    <button type="button" class="btn btn-sm btn-outline-secondary w-100 pl-0" (click)="clearCart()" translate>
      CLEAR_CART
    </button>
  </div>
  <ng-template #otherError>
    <div *ngIf="errorDetails; else errorDefault">
      <span translate>{{ errorDetails }}</span>
    </div>
    <ng-template #errorDefault>
      <span translate>{{ error.gungMessage || error.message }}</span>
    </ng-template>
  </ng-template>
</div>
