<div class="modal-header">
  <h5 class="modal-title" translate>UPLOAD - {{ uploadType | uppercase }}</h5>
  <button
    type="button"
    class="close"
    data-dismiss="modal"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12" *ngIf="valueProgressbar === 0">
      <div
        class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
        style="background: #ffffff; border: 5px dashed #ddd; text-align: center; padding: 50px 10px; margin-left: 10px"
        (dragover)="onDragOver($event)"
        (dragleave)="onDragLeave($event)"
        (drop)="onDrop($event)"
        (click)="fileInput.click()"
      >
        <!-- <span data-translate="ADD_FILE" class="ng-scope">Add file(s) by clicking on or dragging to this area</span> -->
        <!-- for="file" --><label
          ><strong>Add file(s) by clicking on</strong
          ><span class="box__dragndrop"> or dragging to this area</span>.</label
        >
        <br />
        <span
          ><small class="" *ngFor="let file of files; let last = last"
            >{{ file?.name }}{{ !last ? ', ' : '' }}</small
          ></span
        >
        <input
          hidden
          #fileInput
          type="file"
          name="files[]"
          id="file"
          (change)="onDrop($event)"
          [accept]="uploadType === 'image' ? 'image/*' : 'application/pdf'"
          multiple
          [disabled]="uploading ? true : null"
        />
      </div>
    </div>
    <div class="col-12" *ngIf="valueProgressbar !== 0">
      <ngb-progressbar [value]="valueProgressbar"></ngb-progressbar>
    </div>
  </div>
  <div class="row" *ngIf="uploadedFiles.total !== 0">
    <div class="col-12 col-md-4">
      <b translate>TOTAL</b>:
      <span>{{ uploadedFiles.total }}</span>
    </div>
    <div class="col-12 col-md-4">
      <b translate>COMPLETED</b>:
      <span>{{ uploadedFiles.completed }}</span>
    </div>
    <div class="col-12 col-md-4">
      <b translate>FAILED</b>:
      <span>{{ uploadedFiles.failed }}</span>
    </div>
  </div>
  <div class="row" *ngIf="uploadedFiles.failed !== 0">
    <div class="col-12">
      <span translate>FILE_NOT_UPLOADED</span>
      <span class="d-block" *ngFor="let errorFile of uploadedFiles.error">
        {{ errorFile }}
      </span>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-secondary"
    data-dismiss="modal"
    [disabled]="uploading ? true : null"
    (click)="activeModal.close(false)"
    translate
  >
    CLOSE
  </button>
  <button type="button" class="btn btn-primary" (click)="onUpload()" [disabled]="uploading ? true : null" translate>
    UPLOAD <i *ngIf="uploading" class="fas fa-spinner fa-spin"></i>
  </button>
</div>
