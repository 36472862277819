<div *ngIf="availability != null; else loading">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'FUTURE' | translate }}
      <small class="">
        <span
          >{{ 'AVAILABILITY' | translate }}:&nbsp;{{ availability.currentAvailability }}&nbsp;{{
            'PCS' | translate
          }}&nbsp;/&nbsp;</span
        >
        <span
          >{{ 'MAX_FUTURE' | translate }}:&nbsp;{{ availability.maxFutureStock }}&nbsp;{{
            'PCS' | translate
          }}&nbsp;/&nbsp;</span
        >
        <span>{{ 'STOCK' | translate }}:&nbsp;{{ availability.currentStock }}&nbsp;{{ 'PCS' | translate }}</span>
      </small>
    </h3>

    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col">
        <lib-gung-tabset [destroyOnHide]="false">
          <lib-gung-tab [title]="'BREAKDOWN' | translate">
            <ng-template libGungTabContent>
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>
                      <strong translate>DATE</strong>
                    </th>
                    <th>
                      <strong translate>ITEMS_AVAILABLE</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let breakDown of breakDowns">
                    <td>
                      {{ breakDown.date | date: dateUtilService.dateFormat }}
                    </td>
                    <td>
                      {{ breakDown.value }}
                    </td>
                  </tr>
                </tbody>
                <tfoot></tfoot>
              </table>
            </ng-template>
          </lib-gung-tab>
          <lib-gung-tab title="{{ 'TRANSACTION_DETAILS' | translate }}" *ngIf="showAvTransactionsInModal">
            <ng-template libGungTabContent>
              <lib-availability-transactions [availability]="availability"></lib-availability-transactions>
            </ng-template>
          </lib-gung-tab>
        </lib-gung-tabset>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-warning btn-default btn-block" (click)="close()">
      {{ 'CANCEL' | translate }}
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
