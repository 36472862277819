import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { CompanyTemplate } from '../../models/company-template';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { CompanyTemplatesService } from '../../services/company-templates.service';
import { DateUtilService } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

@Component({
  selector: 'lib-company-templates-list-view',
  templateUrl: './company-templates-list-view.component.html',
  styleUrls: ['./company-templates-list-view.component.css']
})
export class CompanyTemplatesListViewComponent extends ListItemRendererComponent<CompanyTemplate[]> implements AfterViewInit {
  constructor(
    protected gungModalService: GungModalService,
    protected companyTemplatesService: CompanyTemplatesService,
    protected translateService: TranslateService,
    public dateUtilService: DateUtilService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  public editCompanyTemplate(template: CompanyTemplate): void {
    this.gungModalService.openAddEditCompanyTemplate(template).then(
      result => {},
      reject => {}
    );
  }

  public deleteCompanyTemplate(template: CompanyTemplate): void {
    const html: string = `<span>${this.translateService.instant('REMOVE_TEMPLATE')} <b>${template.name}</b>?</span>`;
    this.gungModalService
      .openConfirmYesNoModal(undefined, html, { size: 'sm' }, undefined, undefined, true)
      .then(result => {
        if (result) {
          this.companyTemplatesService.deleteCompanyTemplate(template.id).pipe(first()).subscribe(_ => {});
        }
      });
  }
}
