import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';
import { UsersDetailsCustomersListConfigService } from './../../../../services/users-list-config/users-details-customers-list-config.service';
import { UsersService } from './../../../../services/users/users.service';
import { takeUntil, first, map } from 'rxjs';
import { Subject, Observable } from 'rxjs';
import { Customer } from './../../../../models/customer';

@Component({
  selector: 'lib-user-details-customers',
  templateUrl: './user-details-customers.component.html',
  styleUrls: ['./user-details-customers.component.css']
})
export class UserDetailsCustomersComponent implements OnInit, OnDestroy {
  @Input()
  currentUser: User;

  selectedCustomers: Customer[] = [];
  unsubscribe: Subject<void> = new Subject();

  private isSelectedKey = 'isSelected';

  constructor(
    public usersDetailsCustomersListConfigService: UsersDetailsCustomersListConfigService,
    protected usersService: UsersService
  ) {}

  ngOnInit() {
    this.usersDetailsCustomersListConfigService.setSelectedCustomerIds(this.currentUser.managedCompanyIds);

    this.usersService
      .getUserSelectedCustomerIdsFromSubject()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(selectedCustomers => {
        this.selectedCustomers = selectedCustomers;
        this.setUserCustomers();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  removeSelectedCustomer(customerId): void {
    const customerIndexToRemove = this.selectedCustomers.findIndex(customer => customer.id === customerId);
    if (customerIndexToRemove > -1) {
      this.selectedCustomers.splice(customerIndexToRemove, 1);
      this.usersService.sendUnselectedCustomerIdSubject(customerId);
    }
  }

  private setUserCustomers(): void {
    this.currentUser.managedCompanyIds = this.selectedCustomers.map(c => c.id);
  }
}
