import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, mergeMap, Observable, Subject } from 'rxjs';
import { switchMap, tap } from 'rxjs';
import {
  Pim,
  PimConcept,
  PimDataTemplate,
  PimProductDimensions,
  PimProductStateLog,
  PimUpload
} from '../../models/pim';

@Injectable({
  providedIn: 'root'
})
export class PimService {
  protected baseUrl = 'json/pim';

  protected pimsSubject: Subject<Pim[]>;

  public enableShopify = false;
  public shopifyLanguages: string[] = ['en'];
  public enableShopifyProductTemplates = false;

  constructor(protected http: HttpClient) {}

  public getPimDataTemplate(nocache?: boolean): Observable<PimDataTemplate[]> {
    const url = `${this.baseUrl}-data-templates`;
    const headers: { [s: string]: string | string[] } = {};
    headers.maxAge = nocache ? '-1' : '300';

    return this.http.get<any[]>(url, { headers });
  }

  public getPimProducts(nocache?: boolean): Observable<Pim[]> {
    if (!this.pimsSubject) {
      this.pimsSubject = new BehaviorSubject<Pim[]>([]);
      nocache = true;
    }
    if (nocache) {
      return this.getPimProductsInternalAsObservable(nocache).pipe(
        switchMap(data => {
          this.pimsSubject?.next(data);
          return this.pimsSubject.asObservable();
        }));
    }
    return this.pimsSubject.asObservable();
  }

  public getPimProductsInternalAsObservable(nocache?: boolean): Observable<Pim[]> {
    const url = `${this.baseUrl}-products`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<Pim[]>(url, { headers });
  }

  public getPimConcepts(nocache?: boolean): Observable<PimConcept[]> {
    const url = `${this.baseUrl}/concepts`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');
    return this.http.get<PimConcept[]>(url, { headers });
  }

  public postPimSyncToErp(ids: string[], nocache?: boolean): Observable<null> {
    const url = `${this.baseUrl}/sync-to-erp`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.post<null>(url, ids);
  }

  public getPimErpProductStateLog(id: string, nocache?: boolean): Observable<any[]> {
    const url = `${this.baseUrl}-erp-product-state-log/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<any[]>(url, { headers });
  }

  public getPimProductStateLog(id: string, nocache?: boolean): Observable<PimProductStateLog[]> {
    const url = `${this.baseUrl}-product-state-log/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<PimProductStateLog[]>(url, { headers });
  }

  public getPimProductDimensions(nocache?: boolean): Observable<PimProductDimensions[]> {
    const url = `${this.baseUrl}-product-dimensions`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<PimProductDimensions[]>(url, { headers });
  }

  public getPimProduct(id: string, nocache?: boolean): Observable<Pim> {
    const url = `${this.baseUrl}-products/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<Pim>(url, { headers });
  }

  public getPimProductVariantsByMasterId(id: string, nocache?: boolean): Observable<Pim[]> {
    const url = `${this.baseUrl}-products-color-variants-by-master-id/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<Pim[]>(url, { headers });
  }

  public getPimProductByMasterId(id: string, nocache?: boolean): Observable<Pim[]> {
    const url = `${this.baseUrl}-products-by-master-id/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');

    return this.http.get<Pim[]>(url, { headers });
  }

  public getPimConcept(id: string, nocache?: boolean): Observable<PimConcept> {
    const url = `${this.baseUrl}/concepts/${id}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', nocache ? '-1' : '300');
    return this.http.get<PimConcept>(url, { headers });
  }

  public newConcept(concept: PimConcept): Observable<PimConcept> {
    const url = `${this.baseUrl}/concepts`;
    return this.http.post<PimConcept>(url, concept);
  }

  public editConcept(id: string, concept: PimConcept): Observable<PimConcept> {
    const url = `${this.baseUrl}/concepts/${id}`;
    return this.http.put<PimConcept>(url, concept);
  }

  public deleteConcept(id: string): Observable<PimConcept> {
    const url = `${this.baseUrl}/concepts/${id}`;
    return this.http.delete<PimConcept>(url);
  }

  public postConceptImages(id: string, dataImg: FormData): Observable<PimConcept> {
    const url = `${this.baseUrl}/concept-images/upload/${id}`;
    return this.http.post<PimConcept>(url, dataImg);
  }

  public deleteConceptImages(id: string, dataImg: FormData): Observable<PimConcept> {
    const url = `${this.baseUrl}/concept-images/delete/${id}`;
    return this.http.post<PimConcept>(url, dataImg);
  }

  public postPimProduct(pim: Pim): Observable<Pim> {
    const url = `${this.baseUrl}-products/${pim.id}`;
    return this.http.post<Pim>(url, pim).pipe(tap(_ => this.getPimProducts(true).subscribe()));
  }

  public postPimProductsBatch(items: Pim[]): Observable<Pim[]> {
    const url = `json/save-pim-products-batch`;
    return this.http.post<Pim[]>(url, items);
  }

  public postPimImageBatch(dataImg: FormData): Observable<PimUpload> {
    const url = `import/pim-image-batch-v2/`;
    return this.http.post<PimUpload>(url, dataImg);
  }

  public postPimDocumentBatch(dataImg: FormData): Observable<PimUpload> {
    const url = `import/pim-document-batch-v2/`;
    return this.http.post<PimUpload>(url, dataImg);
  }

  public postPimImage(id: string, imgNumber: number, dataImg: FormData): Observable<Pim> {
    const url = `import/pim-image/${id}/${imgNumber}`;
    return this.http.post<Pim>(url, dataImg);
  }

  public postPimDocument(id: string, imgNumber: number, dataImg: FormData): Observable<Pim> {
    const url = `import/pim-document/${id}/${imgNumber}`;
    return this.http.post<Pim>(url, dataImg);
  }

  public postPimExcelDocument(file: FormData): Observable<Pim> {
    const url = `import/pim-data-template`;
    return this.http.post<Pim>(url, file);
  }

  public generatePimDataTemplateWithProductsIds(
    ids: string[],
    recipent: string,
    template: 'EXPORT' | 'SORTING' | string
  ) {
    const url = `download/generate-pim-data-template-with-products-ids`;
    return this.http.post<Pim>(url, { ids, recipent, template });
  }

  public syncToErp(ids: string[]) {
    const url = `pim/sync-to-erp`;
    return this.http.post<Pim>(url, ids);
  }

  public syncToShopify(id: string): Observable<Pim> {
    const url = `json/shopify/sync-product/${id}`;
    return this.http.get<Pim>(url);
  }

  public getPimProductsFromMasterId(masterId: string): Observable<Pim[]> {
    const url = `json/pim-products-by-master-id/` + masterId;
    return this.http.get<Pim[]>(url);
  }

  public createPimFortnoxArticlesExcelDocument(file: FormData): Observable<Pim> {
    const url = `json/pim/products/import`;
    return this.http.post<Pim>(url, file);
  }

  public deletePimProduct(productId: string): Observable<string> {
    const url = this.baseUrl + `-products/${productId}`;
    return this.http.delete<string>(url);
  }
  
  public getPimProductsVariants(): Observable<Pim[]> {
    const url = `json/pim-products-variants`;
    return this.http.get<Pim[]>(url);
  }
}
