import { Component, OnInit } from '@angular/core';
import { AccountRequestListConfigService } from '../../../services/account-request-list-config/account-request-list-config.service';

@Component({
  selector: 'lib-account-requests-manage',
  templateUrl: './account-requests-manage.component.html',
  styleUrls: ['./account-requests-manage.component.css']
})
export class AccountRequestsManageComponent implements OnInit {
  constructor(public accountRequestListConfigService: AccountRequestListConfigService) {}

  ngOnInit(): void {}
}
