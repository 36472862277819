<div class="row">
  <div class="col-4 offset-4">
    <lib-gung-checkbox-input-horizontal
      *ngIf="isAdmin"
      gungTranslate="SHOW_MAPPINGS_FOR_ALL_CUSTOMERS"
      [(ngModel)]="showAllCustomersMappings"
      [divClass]="['d-flex justify-content-center']"
    >
    </lib-gung-checkbox-input-horizontal>
  </div>
</div>
<div class="table-responsive">
  <table class="table table-striped">
    <thead>
      <tr>
        <td translate>MAPPED_ID</td>
        <td translate>ERP_ID</td>
        <td translate>SELECT_NEW_PRODUCT</td>
        <td *ngIf="isAdmin" translate>CUSTOMER_ID</td>
        <td></td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let mapping of showAllCustomersMappings ? allMappings : selectedCustomerMappings; index as i">
        <td>
          <lib-gung-text-input [suppressLabel]="true" [(ngModel)]="mapping.referenceId"></lib-gung-text-input>
        </td>
        <td>
          <span>{{ mapping.productId }}</span>
        </td>
        <td>
          <lib-product-select-dropdown (productSelected)="selectProduct($event, mapping)">
          </lib-product-select-dropdown>
        </td>
        <td *ngIf="isAdmin">
          <lib-gung-text-input [suppressLabel]="true" [(ngModel)]="mapping.referenceTypeId"></lib-gung-text-input>
        </td>
        <td>
          <button type="button" class="btn btn-primary" (click)="saveMappingChanges(mapping)" translate>SAVE</button>
          <button type="button" class="btn btn-danger" (click)="deleteMapping(mapping)" translate>DELETE</button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
<button type="button" class="btn btn-primary" (click)="addNewMapping()" translate>ADD_NEW_MAPPING</button>
