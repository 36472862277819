<!-- <lib-gung-text-input
  gungTranslate="CAMPAIGN_CODE"
  (change)="validateInput($event.target.value)"
  [(ngModel)]="value"
  [extraInputClass]="extraClasses"
  [renderStatic]="disabled"
></lib-gung-text-input> -->
<div class="form-group mb-0">
  <label translate>
    CAMPAIGN_CODE
  </label>
  <input
    type="text"
    (change)="validateInput($event.target.value)"
    [(ngModel)]="value"
    [class]="extraClasses"
    [ngClass]="disabled ? 'form-control-plaintext' : 'form-control'"
    [readonly]="disabled"
  />
</div>
