import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'lib-filter-list-batch',
  templateUrl: './filter-list-batch.component.html',
  styleUrls: ['./filter-list-batch.component.css']
})
export class FilterListBatchComponent implements OnInit {
  constructor() {}

  @Input()
  batchSizes: number[];

  @Input()
  inicializedbatchSize: number;

  currentBatchSize: number;

  @Output()
  batchSizeUpdated = new EventEmitter<number>();

  ngOnInit() {
    if (this.inicializedbatchSize && this.batchSizes.includes(this.inicializedbatchSize)) {
      this.currentBatchSize = this.inicializedbatchSize;
    } else {
      this.currentBatchSize = this.batchSizes[0];
    }
  }

  updateBatchSize(value: string): void {
    this.batchSizeUpdated.emit(parseInt(value, 10));
  }
}
