<div class="modal-header">
    <h4 translate>DOWNLOAD_IMAGES</h4>
  </div>
  <div class="modal-body">
    <div>
      <select class="form-control form-select" [(ngModel)]="selectedOption" (ngModelChange)="selectedOptionChanged()">
        <option *ngFor="let option of sizeOptions" [value]="option?.id"><span translate>{{ option?.name }}</span></option>
      </select>
    </div>
    <div class="pt-2" *ngIf="showEnableBackground">
      <input id="changeBackgroundStatus" type="checkbox" [(ngModel)]="enableBackground" (change)="changeBackgroundStatus($event.target.checked)" [disabled]="sizeOptions?.[selectedOption]?.forceBackgroundColor"/>
      <label for="changeBackgroundStatus" class="ml-2" translate>ENABLE_BACKGROUND</label>
    </div>

    <div *ngIf="enableBackground" class="form-group pt-3 ml-2">
      <div></div>
      <label class="control-label" for="address" translate>IMAGE_BACKGROUND_COLOR</label>
      <div class="row">
        <div class="col-12 d-flex align-items-center">
          <input
            class="form-control reduce-input-width"
            name="address"
            type="text"
            [(ngModel)]="selectedColor"
            (keypress)="filterForHexColors($event)"
            maxlength="8"
          />
          <!-- <div class="ml-2 square" [ngStyle]="{ 'background-color': '#'+selectedColor }">
          </div> -->
          <i
            class="fas fa-square fa-3x ml-2 border"
            (click)="colorPicker1.click()"
            [ngStyle]="{ color: '#' + selectedColor }"
          ></i>
          <input #colorPicker1 type="color" [(ngModel)]="selectedColor" (ngModelChange)="changeSelectedColor()" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
        </div>
    </div>

  </div>

  <div class="modal-footer">
    <button class="btn btn-sm btn-primary btn-default px-4 mr-auto" (click)="close()" translate>GO_BACK</button>
    <button class="btn btn-sm btn-primary btn-default px-4" (click)="save()" [disabled]="selectedColor.length===0" translate>SELECT_SIZE</button>
  </div>
