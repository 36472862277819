import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties,
  SelectionAction,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { Product } from '../../models/product';
import { AuthService } from '../../services/auth/auth.service';
import { ProductExportService } from '../../services/product-export/product-export.service';
import { ProductListConfigService } from '../../services/product-list-config/product-list-config.service';
import { ProductSortService } from '../../services/product-sort/product-sort.service';
import { ProductService } from '../../services/products/product.service';
import { WhiteboardProductListViewComponent } from '../components/whiteboard-product-list/whiteboard-product-list-view/whiteboard-product-list-view.component';
import { WhiteboardProductActionConfigService } from './whiteboard-product-action-config.service';

class DidriksonsCategoryFilter extends SimpleConfigBaseFilter<Product> {
  constructor() {
    super();
  }

  getName(): string {
    return 'CATEGORY';
  }

  getOptionIds(item: Product): string[] {
    return [(item.extra.litium && item.extra.litium.categoryItem) || ''];
  }

  getOptionName(key: string): string {
    return key;
  }
}
@Injectable({
  providedIn: 'root'
})
export class WhiteboardProductListConfigService implements ConfigService<Product> {
  topFilter = true;

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productExportService: ProductExportService,
    protected productSortService: ProductSortService,
    protected productListConfigService: ProductListConfigService,
    protected whiteboardProductActionConfigService: WhiteboardProductActionConfigService
  ) {}

  getItems(): Observable<Product[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        this.productListConfigService.assortmentSubject.next(user.assortment);
        return this.productListConfigService.getItems();
      })
    );
  }

  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<Product>[] {
    return [...this.productListConfigService.getFilters(), new DidriksonsCategoryFilter()];
  }
  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<Product>[] {
    return [];
  }
  getBatchSizes(route?: ActivatedRouteSnapshot): number[] {
    return [12];
  }
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => 'fas fa-list',
        getListItemComponent: () => WhiteboardProductListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductList'
      }
    ];
  }
  getSearchTerms(item: Product): string[] {
    return [item.id, item.name];
  }
  getItemId(item: Product): string {
    return item.id;
  }
  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return this.whiteboardProductActionConfigService.getSelectionActions();
  }
  getSelectionActionsButtonTitle(): string {
    return 'SEND_TO_WHITEBOARD';
  }
}
