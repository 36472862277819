<div class="modal-body">
  <span translate>LOAD_SAVED_CART</span>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close('add')" translate>
    ADD_TO_EXISTING_CART
  </button>
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close('replace')" translate>
    REPLACE_EXISTING
  </button>
  <button class="btn btn-sm btn-outline-primary px-4" (click)="activeModal.close(false)" translate>GO_BACK</button>
</div>
