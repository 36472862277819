import { CartRow } from '../types';
import { getIndexOfRow, getRowFromRows } from './common';
import { ExtraPayload } from '../actions';
import { mergeDeep } from '../../../utils/merge-utils';

const isObject = item => {
  return item && typeof item === 'object' && !Array.isArray(item);
};

export const setExtraReducer = (cartRows: CartRow[], payload: ExtraPayload): CartRow[] => {
  // MR 2022-05-11 - Not very inefficient
  // console.time("setExtraReducer "+payload.productId);
  const currentDate = new Date();
  const oldRow = getRowFromRows(cartRows, payload.productId, payload.targetStockId, payload.productPartialId);
  if(!oldRow){
    return [...cartRows];
  }
  const oldExtra = JSON.parse(JSON.stringify(oldRow?.extra || {}));
  let newExtra = payload.extra;
  if (!payload.replace) {
    newExtra = mergeDeep(oldExtra, payload.extra);
  }
  const newRow = {
    ...oldRow,
    extra: newExtra,
    timeUpdated: currentDate
  };

  // Preserve position of row we want to replace extra on
  const index = getIndexOfRow(cartRows, payload.productId, payload.targetStockId, payload.productPartialId);
  cartRows[index] = newRow;

  return [...cartRows];
};

export const bulkSetExtraReducer = (rows: CartRow[], payload: ExtraPayload[]): CartRow[] => {
  for (const item of payload) {
    rows = setExtraReducer(rows, item);
  }

  return rows;
};
