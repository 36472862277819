import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { Observable, of } from 'rxjs';
import { CustomerUserCardListComponent } from '../../components/customer-user-card-list/customer-user-card-list.component';
import { User } from '../../state/auth/types';
import { CustomerUserService } from '../customer-users/customer-user.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerUserListConfigService implements ConfigService<User> {
  /*
   This variable and setter is called in the customer-user-list-component to set
   the customerId to the id in the route params. It did not work to get the routeparams
   from this service.
   */
  customerId: string;
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(
    protected customerUserService: CustomerUserService,
    protected selectedCustomerService: SelectedCustomerService
  ) {}

  getItems(): Observable<User[]> {
    if (this.customerId == null) {
      return of([]);
    }
    return this.customerUserService.getCustomerUsers(this.customerId);
  }

  getFilters(): ConfigBaseFilter<User>[] {
    return [];
  }

  getSortOptions(): ListSortOption<User>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<User>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => CustomerUserCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'CustomerUsers grid'
      }
    ];
  }

  getSearchTerms(item: User): string[] {
    return [item.username, item.name, item.email];
  }

  getItemId(item: User): string {
    return item.username;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
