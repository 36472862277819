<div>
  <div class="modal-header">
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <button type="button" class="btn btn-primary pull-right ean-replace-row-add" translate (click)="addEan()">
      ADD_TO_LIST
    </button>

    <form [formGroup]="eanForm" (ngSubmit)="submitData()">
      <div class="table-responsive">
        <table class="table table-borderless">
          <thead>
            <tr>
              <th translate>
                OLD_EAN
              </th>
              <th translate>
                NEW_EAN
              </th>
            </tr>
          </thead>
          <tbody formArrayName="eanList">
            <tr *ngFor="let ean of eanList.controls; let i=index">
              <div class="ean-table-row" [formGroupName]="i">
                <td>
                  <input class="form-control" type="text" formControlName="oldEan" />
                </td>
                <td><input class="form-control" type="text" formControlName="newEan" /></td>
                <td>
                  <button type="button" class="btn btn-warning pull-right" translate (click)="remove(i)">
                    REMOVE_FIELD
                  </button>
                </td>
              </div>
            </tr>
          </tbody>
        </table>
      </div>
      <button [disabled]="columnCount ==0" type="submit" class="btn btn-secondary" translate>
        SUBMIT
      </button>
    </form>
  </div>
</div>