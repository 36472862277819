<div class="row justify-content-center">
  <div class="col-3" *ngFor="let lang of languages">
    <img
      src="./assets/{{ lang.image }}"
      class="w-100 h-auto p-2 button"
      alt="{{ lang.language }}"
      [class.opacity-05]="lang.short !== languageConfigService.getDefaultLanguage()"
      (click)="changeLanguage(lang.short)"
    />
  </div>
</div>
