import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { InputSaveModalComponent } from '../input-save-modal/input-save-modal.component';

@Component({
  selector: 'lib-input-modal',
  templateUrl: './input-modal.component.html',
  styleUrls: ['./input-modal.component.css']
})
export class InputModalComponent extends InputSaveModalComponent {
  @Input()
  public btnOk = 'OK';

  @Input()
  public btnCancel = 'CANCEL';

  @Input()
  public type: 'text' | 'textarea' = 'text'

  @Input()
  public labelType: 'text' | 'html' = 'text';

  @Input()
  public labelClass?: string;

  constructor(public activeModal: NgbActiveModal) {
    super();
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close(this.value);
  }
}
