import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ExportCoverSheet, CoverImage } from '../../models/export-cover-sheets';

@Injectable({
  providedIn: 'root'
})
export class ExportCoverSheetsService {
  protected baseUrl = `json/export-cover-sheets`;

  constructor(protected http: HttpClient) {}

  public getExportCoverSheets(nocache?: boolean): Observable<ExportCoverSheet[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };

    return this.http.get<ExportCoverSheet[]>(url, { headers });
  }

  public putExportCoverSheet(sheet: ExportCoverSheet): Observable<ExportCoverSheet> {
    const url = `${this.baseUrl}`;
    return this.http.put<ExportCoverSheet>(url, sheet);
  }

  public deleteExportCoverSheet(sheetId: string): Observable<null> {
    const url = `${this.baseUrl}/${sheetId}`;
    return this.http.delete<null>(url);
  }

  public postCoverImageUpload(id: string, dataImg: FormData): Observable<CoverImage> {
    const url = `${this.baseUrl}/cover-image-upload/${id}`;
    return this.http.post<CoverImage>(url, dataImg);
  }
}
