<div class="container">
  <div class="row pb-4">
    <div class="col-12 d-flex justify-content-center">
      <h3 class="mt-0 ml-auto page-title" translate>NEWS</h3>
      <div class="btn-group ml-auto">
        <div ngbDropdown placement="bottom-right" class="d-inline-block mr-2">
          <button class="btn btn-outline-primary" id="dropdownBasic1" ngbDropdownToggle translate>ACTIONS</button>
          <div ngbDropdownMenu aria-labelledby="dropdownBasic1" class="dropdown-container">
            <button ngbDropdownItem (click)="openAddNewsModal()" translate>ADD</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-filter-list [configService]="newsEditorListConfigService"></lib-filter-list>
    </div>
  </div>
</div>
