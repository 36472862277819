import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GungFlowsListConfigService } from '../../../services/gung-flows-list-config/gung-flows-list-config.service';
import { CreateGungFlowModalComponent } from '../create-gung-flow-modal/create-gung-flow-modal.component';

@Component({
  selector: 'lib-gung-flows-wrapper',
  templateUrl: './gung-flows-wrapper.component.html',
  styleUrls: ['./gung-flows-wrapper.component.css']
})
export class GungFlowsWrapperComponent implements OnInit {
  constructor(public gungFlowsListConfigService: GungFlowsListConfigService, protected modalService: NgbModal) {}

  ngOnInit() {}

  openCreateFlow(): void {
    const modalRef = this.modalService.open(CreateGungFlowModalComponent, { size: 'lg' });
    modalRef.result.then(
      () => {},
      reason => {}
    );
  }
}
