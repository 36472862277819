import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductSelectionService } from '../../services/product-selection-service/product-selection.service';
import { AuthService } from '../../services/auth/auth.service';
import { forkJoin } from 'rxjs';
import { first, switchMap, tap } from 'rxjs/operators';
import { MongoAssortment } from '../../models/mongo-assortment';
import { MongoAssortmentService } from '../../services/mongo-assortment/mongo-assortment.service';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-mongo-assortment-create-modal',
  templateUrl: './mongo-assortment-create-modal.component.html',
  styleUrls: ['./mongo-assortment-create-modal.component.css']
})
export class MongoAssortmentCreateModalComponent implements OnInit {
  alertMessage: Alert;
  submited = false;
  isUpdate = false;

  assortmentForm: FormGroup;
  mongoAssortment: MongoAssortment = {} as MongoAssortment;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected mongoAssortmentService: MongoAssortmentService,
    protected productSelectionService: ProductSelectionService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.initForm();
  }

  initForm() {
    this.assortmentForm = this.formBuilder.group({
      id: this.formBuilder.control(this.mongoAssortment.id, [Validators.required]),
      name: this.formBuilder.control(this.mongoAssortment.name, [])
    });
  }

  onSave() {
    if (!this.assortmentForm.valid) {
      this.assortmentForm.markAllAsTouched();
      return;
    }

    const formRaw = this.assortmentForm.getRawValue();
    this.assortmentForm.disable();

    this.mongoAssortment = {
      ...this.mongoAssortment,
      id: formRaw.id,
      name: formRaw.name
    } as MongoAssortment;

    this.productSelectionService
      .getSelection()
      .pipe(
        first(),
        tap(selection => {
          if (!this.isUpdate) {
            this.mongoAssortment.children = Object.values(selection.selectedItems).map(p => p.id);
          }
        }),
        switchMap(u => forkJoin([this.mongoAssortmentService.create(this.mongoAssortment)]))
      )
      .subscribe(
        ([assortment]) => {
          this.alertMessage = { type: 'success', message: this.isUpdate ? 'ASSORTMENT_SAVED' : 'ASSORTMENT_CREATED' };
          this.submited = true;
        },
        () => {
          this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
          this.submited = true;
        }
      );
  }
}
