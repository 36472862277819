import { Component, OnInit, Input } from '@angular/core';
import { DateUtilService } from 'gung-common';
import { parse } from 'date-fns';

@Component({
  selector: 'lib-gung-date-output',
  templateUrl: './gung-date-output.component.html',
  styleUrls: ['./gung-date-output.component.css']
})
export class GungDateOutputComponent implements OnInit {
  @Input()
  public value: string | number | Date;

  @Input()
  public gungTranslate: string;

  @Input()
  public dateFormat?: string;

  @Input()
  public horizontal?: boolean;

  @Input()
  public inputFormat?: string;

  public dateString: string;

  @Input()
  public labelClass: string | string[];

  @Input()
  public labelBold = false;

  constructor(protected dateUtilService: DateUtilService) {}

  ngOnInit() {
    let date = new Date(this.value);
    if (this.inputFormat) {
      date = parse((this.value as string).split(' ')[0], this.inputFormat, new Date());
    }
    this.dateString = this.dateUtilService.getFormattedDateString(date, this.dateFormat);
  }
}
