import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-forms-message-error',
  templateUrl: './forms-message-error.component.html',
  styleUrls: ['./forms-message-error.component.css']
})
export class FormsMessageErrorComponent implements OnInit {
  @Input()
  controller: FormControl;

  // private _controller: FormControl;
  // @Input() set controller(controller: FormControl) {
  //   console.log('controller', controller);
  //   if (controller === undefined || controller === null || this._controller === controller) {
  //     return;
  //   }
  //   this._controller = controller;
  // }
  // get controller(): FormControl { return this._controller; }

  errorMessages: { [s: string]: string } = {
    'FORM_ERROR_REQUIRED': 'FORM_ERROR_REQUIRED',
    'FORM_ERROR_EMAIL': 'NOT_VALID_EMAIL',
    'FORM_ERROR_MIN_LENGTH': 'FORM_ERROR_MIN_LENGTH',
    'FORM_ERROR_MIN': 'FORM_ERROR_MIN',
    'FORM_ERROR_MAX_LENGTH': 'FORM_ERROR_MAX_LENGTH',
    'FORM_ERROR_MAX': 'FORM_ERROR_MAX',
    'FORM_ERROR_PATTERN': 'FORM_ERROR_PATTERN',
    'FORM_ERROR_MAX_VALUE': 'FORM_ERROR_MAX_VALUE'
  };

  @Input('errorMessages')
  set inputErrorMessages(data: { [s: string]: string }) {
    this.errorMessages = {
      ...this.errorMessages,
      ...data
    }
  }

  @Input()
  public class: string;

  @Input()
  set classAlert(className: string) {
    this.class = className;
  }

  constructor(protected translateService: TranslateService) { }

  ngOnInit(): void { }

  checkController(controller: FormControl): string {
    // if (controller instanceof FormGroup) {
    //   let errors = '';
    //   Object.keys(controller.controls).forEach(key => {
    //     const controlErrors: ValidationErrors = controller.get(key).errors;
    //     if (controlErrors != null) {
    //       errors += this.checkController(controller.get(key) as FormControl);
    //     }
    //   });
    //   console.log('errors', errors);
    //   return;
    // }
    if (!controller || !controller.invalid || !controller.errors) {
      return;
    }
    const errors = Object.keys(controller.errors);
    if (controller.errors.required) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_REQUIRED']);
    }
    if (controller.errors.email) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_EMAIL']);
    }
    if (controller.errors.minlength) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_MIN_LENGTH'], {
        actualLength: controller.errors.minlength.actualLength,
        requiredLength: controller.errors.minlength.requiredLength
      });
    }
    if (controller.errors.min) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_MIN'], {
        // actual: controller.errors.min.actual,
        // min: controller.errors.min.min
        length: controller.errors.min.min
      });
    }
    if (controller.errors.maxlength) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_MAX_LENGTH'], {
        actualLength: controller.errors.maxlength.actualLength,
        requiredLength: controller.errors.maxlength.requiredLength
      });
    }
    if (controller.errors.pattern) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_PATTERN']);
    }
    if (controller.errors.max) {
      return this.translateService.instant(this.errorMessages['FORM_ERROR_MAX_VALUE'], {
        actualValue: controller.errors.max.actual,
        maxValue: controller.errors.max.max
      });
    }
    for (const error of errors) {
      const errorMessage = this.errorMessages[error.toUpperCase()];
      if (errorMessage) {
        return this.translateService.instant(errorMessage);
      }
    }
    console.log('errors', errors);
    return;
  }
}
