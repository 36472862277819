<div *ngIf="!isLoading; else loading">
    <div class="modal-header">
      <div>
        <h3 translate>
          HISTORY
        </h3>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>

    <div class="modal-body">
        <div class="table-responsive">
          <div *ngIf="isMap">
            <div *ngFor="let line of data">
            <table class="table table-striped">
              <thead style="font-weight: bold;background-color: #98999b;">
              <tr>
                <th scope="col" translate>LINE ID</th>
                <th scope="col" translate>DATE</th>
                <th scope="col" translate>USER</th>
                <th scope="col" translate>CHANGES</th>
                <th scope="col" translate>STATE</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of line">
                <td>{{row.orderLineId}}</td>
                <td scope="row">
                  <span class="text-nowrap">{{row.timestamp | date: 'short'}}</span>
                </td>
                <td>{{row.username}}</td>
                <td>
                      <pre>
                        <span *ngFor="let change of row.changes" class="d-block">{{change}}</span>
                      </pre>
                </td>
                <td>
                  <button type="button" class="btn btn-secondary btn-sm" (click)="showJSON(row)"><i class="fa-solid fa-file-code"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
            </div>
          </div>
          <div *ngIf="!isMap">
            <table class="table table-striped">
              <thead>
              <tr>
                <th scope="col" translate>USER</th>
                <th scope="col" translate>CHANGES</th>
                <th scope="col" translate>STATE</th>
              </tr>
              </thead>
              <tbody>
              <tr *ngFor="let row of data">
                <td>{{row.username}}</td>
                <td>
                  <span class="text-nowrap font-weight-bold">{{row.timestamp | date: dateUtilService.dateFormat + ", HH:mm"}}</span> <br/>
                  <pre>
                  <span *ngFor="let change of row.changes" class="d-block">"{{change.substring(0, change.indexOf(' ')) | translate }}" {{change.substring(change.indexOf(' ') + 1)}}</span></pre>
                </td>
                <td>
                  <button type="button" class="btn btn-secondary btn-sm" (click)="showJSON(row)"><i class="fa-solid fa-file-code"></i></button>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-sm btn btn-outline-primary text-uppercase" (click)="activeModal.dismiss('Cancel click')" translate>
            CLOSE
        </button>
    </div>
</div>

<ng-template #loading> LOADING... </ng-template>
