<div class="modal-header">
  <h4 class="modal-title"><span translate>EDIT</span> {{ document.filename }}</h4>
  <button
    type="button"
    class="close"
    aria-label="Close"
    [disabled]="spinner ? true : null"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col">
      <lib-gung-text-input
        #inputDescription
        gungTranslate="{{ 'DESCRIPTION' }}"
        placeholder="{{ 'DESCRIPTION' | translate }}"
        [(ngModel)]="fileDescription"
      ></lib-gung-text-input>
    </div>
  </div>
  <div class="row pt-3">
    <div class="col-12">
      <lib-gung-checkbox-input-horizontal
        gungTranslate="VISIBLE_IN_MENU"
        [(ngModel)]="inMenu"
      ></lib-gung-checkbox-input-horizontal>
    </div>
  </div>

  <lib-gung-input-multi-select
    *ngIf="enableDocumentUserGroups && settingsGroups && settingsGroups.length >= 0"
    label="VISIBLE_FOR_ROLES"
    [optionsList]="getUserGroups()"
    (selectedValues)="setSelectedValues($event)"
  >
  </lib-gung-input-multi-select>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-dark"
    (click)="activeModal.close('Close click')"
    [disabled]="spinner ? true : null"
    translate
  >
    CLOSE
  </button>
  <button type="button" class="btn btn-primary" (click)="onEdit()" [disabled]="spinner ? true : null" translate>
    EDIT <i *ngIf="spinner" class="fa fa-spinner fa-spin fa-lg"></i>
  </button>
</div>
