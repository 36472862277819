<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>ADD_ORDER_ROW</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="table-responsive">
        <table class="table table-striped">
          <thead>
            <tr>
              <th translate>ITEM_NO</th>
              <th translate>QUANTITY</th>
              <th translate *ngIf="cartListCheckoutConfig.enableSalesEditPrice && isSalesOrAdmin">PRICE</th>
              <th translate *ngIf="cartListCheckoutConfig.enableSalesEditDiscount && isSalesOrAdmin">DISCOUNT</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let row of modalRows; let rowIndex = index">
              <td class="select-product">
                <lib-product-select-dropdown
                  (productSelected)="selectProduct($event, rowIndex)"
                  [formatterFunction]="productSearchFormatter"
                  [formatterInputFunction]="productSearchInputFormatter"
                ></lib-product-select-dropdown>
              </td>
              <td>
                <input
                  #inputQty
                  class="form-control"
                  type="number"
                  [(ngModel)]="row.qty"
                  [ngModelOptions]="{ updateOn: 'blur', standalone: true }"
                  (ngModelChange)="validateQuantity(row.qty, rowIndex, inputQty)"
                />
              </td>
              <td *ngIf="cartListCheckoutConfig.enableSalesEditPrice && isSalesOrAdmin">
                <input class="form-control" type="number" [(ngModel)]="row.price" />
              </td>
              <td *ngIf="cartListCheckoutConfig.enableSalesEditDiscount && isSalesOrAdmin">
                <input class="form-control" type="number" [(ngModel)]="row.discount" />
              </td>
              <td width="5%">
                <i class="fa fa-trash-alt clickable" (click)="removeRow(rowIndex)"></i>
              </td>
            </tr>
            <tr>
              <td colspan="5">
                <button class="btn btn-primary btn-block" (click)="addNewRow()" translate>ADD_ORDER_ROW</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button *ngIf="true" type="button" class="btn btn-primary" (click)="submitRows()" translate>ADD_ORDER_ROW</button>
</div>
