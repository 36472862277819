import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { debounce, first, interval } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import {
  DiscountCodeV2,
  DiscountCodeWriteExpressionTemplate,
  DiscountCodesV2Service,
  LogicalConditions
} from '../../../services/discount-codes-v2/discount-codes-v2.service';
import { format } from 'date-fns';

@Component({
  selector: 'lib-discount-codes-modal',
  templateUrl: './discount-codes-modal.component.html',
  styleUrls: ['./discount-codes-modal.component.css']
})
export class DiscountCodesModalComponent implements OnInit {
  @Input()
  discountCode: DiscountCodeV2;
  discountCodeMongo: DiscountCodeV2;

  submitForm: FormGroup;
  disabled: boolean;

  minDate = new Date();

  writeExpressions: DiscountCodeWriteExpressionTemplate[];
  conditionExpressions = [];
  isActuator = false;
  disableDiscountConditions = true;

  get logicalConditions() {
    return this.submitForm.get('discountCodeConditions').get('logicalConditions') as FormArray;
  }

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected discountCodesV2Service: DiscountCodesV2Service,
    protected authService: AuthService,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {
    this.authService
      .getHasRoles('ACTUATOR')
      .pipe(first())
      .subscribe(isActuator => (this.isActuator = isActuator));
    this.writeExpressions = this.discountCodesV2Service.writeExpressions;
    // if (this.discountCode) {
    //   this.discountCodeMongo = JSON.parse(JSON.stringify(this.discountCode));
    // }
    this.initForm();
    this.generateMongoObject();
    this.submitForm.valueChanges.pipe(debounce(() => interval(250))).subscribe(x => {
      this.generateMongoObject();
    });
  }

  initForm() {
    this.submitForm = this.formBuilder.group({
      code: this.formBuilder.control(
        { value: this.discountCode?.code || '', disabled: this.discountCode ? true : false },
        [Validators.required]
      ),
      description: this.formBuilder.control(this.discountCode?.description || ''),
      // usedByCompanyForOrderMap: this.formBuilder.control(this.discountCode?.usedByCompanyForOrderMap || this.discountCodeMongo?.usedByCompanyForOrderMap || {}),
      createdDate: this.formBuilder.control(this.discountCode?.createdDate || new Date()),
      validFrom: this.formBuilder.control(
        format(this.discountCode?.validFrom ? new Date(this.discountCode.validFrom) : new Date(), 'yyyy-MM-dd\'T\'HH:mm')
      ),
      validUntil: this.formBuilder.control(
        format(this.discountCode?.validUntil ? new Date(this.discountCode.validUntil) : new Date(), 'yyyy-MM-dd\'T\'HH:mm')
      ),
      maxMultipleUses: this.formBuilder.control(this.discountCode?.maxMultipleUses || 0, [
        Validators.required,
        Validators.min(0)
      ]),
      // extra: this.formBuilder.group({
      //   oh: this.formBuilder.group({
      //     ordrabatt: this.formBuilder.control(this.discountCode?.extra?.oh?.ordrabatt || this.discountCodeMongo?.extra?.oh?.ordrabatt || 0, [Validators.required, Validators.min(0), Validators.max(100)]),
      //   })
      // }),
      writeExpression: this.formBuilder.control(this.discountCode?.writeExpression || ''),
      discountAmount: this.formBuilder.control(this.discountCode?.discountAmount || ''),
      orderRowDiscount: this.formBuilder.control(this.discountCode?.orderRowDiscount || false),
      addsNewRow: this.formBuilder.control({ value: this.discountCode?.addsNewRow || false, disabled: true })
      // discountCodeConditions: this.formBuilder.group({
      //   logicalConditions: this.formBuilder.array(this.discountCode?.discountCodeConditions?.logicalConditions || [
      //     this.formBuilder.group({
      //       conditionName: this.formBuilder.control(''),
      //       expressionString: this.formBuilder.control(''),
      //       expressionValues: this.formBuilder.array(['']),
      //       expressionValuesAllowed: this.formBuilder.control(true),
      //       type: this.formBuilder.control('')
      //     })
      //   ]),
      //   logicalOperator: this.formBuilder.control(this.discountCode?.discountCodeConditions?.logicalOperator || ''),
      //   type: this.formBuilder.control(this.discountCode?.discountCodeConditions?.type || '')
      // })
    });
    this.enableDiscountCodeConditions(this.submitForm.get('orderRowDiscount').value, this.discountCode);
    this.enableProductId(this.submitForm.get('addsNewRow').value);
  }

  generateMongoObject() {
    const today = format(new Date(), 'yyyy-MM-dd\'T\'00:00');
    const formRaw = this.submitForm.getRawValue();
    this.discountCodeMongo = {
      code: formRaw.code || '',
      description: formRaw.description || '',
      // usedByCompanyForOrderMap: this.discountCode?.usedByCompanyForOrderMap || formRaw.usedByCompanyForOrderMap || {},
      createdDate: formRaw.createdDate || new Date(),
      validFrom: formRaw.validFrom || today,
      validUntil: formRaw.validUntil || today,
      maxMultipleUses: formRaw.maxMultipleUses || 0,
      // extra: this.discountCode?.extra || formRaw.extra || {oh: {ordrabatt: 0}},
      writeExpression: formRaw.writeExpression || '',
      discountAmount: formRaw.discountAmount || 0,
      orderRowDiscount: formRaw.orderRowDiscount || false,
      discountCodeConditions: formRaw.discountCodeConditions || undefined
    };
  }

  saveMongoObject(event) {
    this.discountCodeMongo = JSON.parse(event);
    this.submitForm = this.formBuilder.group({
      code: this.formBuilder.control(this.discountCodeMongo?.code || '', [Validators.required]),
      description: this.formBuilder.control({
        value: this.discountCodeMongo?.description || '',
        disabled: this.discountCode ? true : false
      }),
      // usedByCompanyForOrderMap: this.formBuilder.control(this.discountCode?.usedByCompanyForOrderMap || this.discountCodeMongo?.usedByCompanyForOrderMap || {}),
      createdDate: this.formBuilder.control(this.discountCodeMongo?.createdDate || new Date()),
      validFrom: this.formBuilder.control(
        format(this.discountCodeMongo?.validFrom ? new Date(this.discountCodeMongo.validFrom) : new Date(), 'yyyy-MM-dd\'T\'HH:mm')
      ),
      validUntil: this.formBuilder.control(
        format(this.discountCodeMongo?.validUntil ? new Date(this.discountCodeMongo.validUntil) : new Date(), 'yyyy-MM-dd\'T\'HH:mm')
      ),
      maxMultipleUses: this.formBuilder.control(this.discountCodeMongo?.maxMultipleUses || 0, [
        Validators.required,
        Validators.min(0)
      ]),
      // extra: this.formBuilder.group({
      //   oh: this.formBuilder.group({
      //     ordrabatt: this.formBuilder.control(this.discountCode?.extra?.oh?.ordrabatt || this.discountCodeMongo?.extra?.oh?.ordrabatt || 0, [Validators.required, Validators.min(0), Validators.max(100)]),
      //   })
      // }),
      writeExpression: this.formBuilder.control(this.discountCodeMongo?.writeExpression || ''),
      discountAmount: this.formBuilder.control(this.discountCodeMongo?.discountAmount || ''),
      orderRowDiscount: this.formBuilder.control(this.discountCodeMongo?.orderRowDiscount || false),
      addsNewRow: this.formBuilder.control(this.discountCode?.addsNewRow || false)
      // discountCodeConditions: this.formBuilder.group({
      //   logicalConditions: this.formBuilder.array(this.discountCodeMongo?.discountCodeConditions?.logicalConditions || [
      //     this.formBuilder.group({
      //       conditionName: this.formBuilder.control(''),
      //       expressionString: this.formBuilder.control(''),
      //       expressionValues: this.formBuilder.array(['']),
      //       expressionValuesAllowed: this.formBuilder.control(true),
      //       type: this.formBuilder.control('')
      //     })
      //   ]),
      //   logicalOperator: this.formBuilder.control(this.discountCodeMongo?.discountCodeConditions?.logicalOperator || ''),
      //   type: this.formBuilder.control(this.discountCodeMongo?.discountCodeConditions?.type || '')
      // })
    });
    this.enableDiscountCodeConditions(this.submitForm.get('orderRowDiscount').value, this.discountCodeMongo);
    this.enableProductId(this.submitForm.get('addsNewRow').value);
  }

  enableDiscountCodeConditions(enable: boolean, origin: DiscountCodeV2) {
    if (enable) {
      this.submitForm.addControl(
        'discountCodeConditions',
        this.formBuilder.group({
          logicalConditions: this.formBuilder.array<LogicalConditions>(
            (origin?.discountCodeConditions?.logicalConditions || [
              {
                conditionName: '',
                expressionString: '',
                expressionValues: [''],
                expressionValuesAllowed: true,
                type: ''
              }
            ]) as LogicalConditions[]
          ),
          logicalOperator: this.formBuilder.control(origin?.discountCodeConditions?.logicalOperator || ''),
          type: this.formBuilder.control(origin?.discountCodeConditions?.type || '')
        })
      );
    } else {
      this.submitForm.removeControl('discountCodeConditions');
    }
  }

  enableProductId(enable) {
    if (enable) {
      this.submitForm.addControl(
        'productId',
        this.formBuilder.control(this.discountCode?.productId || '', [Validators.required])
      );
      this.submitForm.get('discountAmount').addValidators([Validators.required]);
    } else {
      this.submitForm.removeControl('discountCodeConditions');
      this.submitForm.get('discountAmount').removeValidators([Validators.required]);
    }
  }

  addLogicalConditions() {
    this.logicalConditions.push(
      this.formBuilder.group({
        conditionName: this.formBuilder.control(''),
        expressionString: this.formBuilder.control(''),
        expressionValues: this.formBuilder.array(['']),
        expressionValuesAllowed: this.formBuilder.control(true),
        type: this.formBuilder.control('')
      })
    );
  }

  deleteLogicalConditions(i) {
    this.logicalConditions.removeAt(i);
  }

  onSave() {
    this.submitForm.markAllAsTouched();
    if (this.submitForm.invalid) {
      // FILL_ALL_REQUIRED_FIELDS
      return;
    }
    this.disabled = true;
    const formRaw = this.submitForm.getRawValue();

    let discountCode: DiscountCodeV2 = {
      ...formRaw
    };
    discountCode = {
      ...discountCode,
      validFrom: new Date(discountCode.validFrom),
      validUntil: new Date(discountCode.validUntil)
    };
    // Update
    if (this.discountCode?.code) {
      // discountCode.code = this.discountCode.code;
      this.discountCodesV2Service.putDiscountCodes(discountCode).subscribe(ticket => {
        this.disabled = false;
        this.activeModal.close(JSON.stringify(ticket));
      });
    } else {
      this.discountCodesV2Service.postDiscountCodes(discountCode).subscribe(ticket => {
        this.disabled = false;
        this.activeModal.close(JSON.stringify(ticket));
      });
    }
  }

  writeExpressionChange(writeExpression) {
    const foundWriteExpression = this.writeExpressions.find(w => w.expression === writeExpression);
    this.submitForm.get('orderRowDiscount').setValue(!!foundWriteExpression?.orderRowDiscount);
    this.submitForm.get('addsNewRow').setValue(!!foundWriteExpression?.addsNewRow);
    this.enableProductId(foundWriteExpression?.addsNewRow);
  }
}
