import { Injectable } from '@angular/core';
import { parse } from 'date-fns';
import { Availability, SimplifiedAvailability } from '../../models/availability';
import { Product } from '../../models/product';
import { DisplayAvailability } from '../../models/product-matrix';
import { User } from '../../state/auth/types';
import { GungFlow } from '../../state/flow/types';

@Injectable({
  providedIn: 'root'
})
export class ProductMatrixConfigService {
  constructor() {}

  getDisplayAvailability(
    availability: Availability | SimplifiedAvailability,
    user: User,
    flow?: GungFlow,
    product?: Product
  ): DisplayAvailability {
    const nextFutureStockQty = this.getFutureStockQty(availability);
    if (user.roles.includes('USER') && user.roles.length === 1) {
      return {
        hasCurrenStock: availability.currentAvailability > 0,
        currentAvailability:
          availability.currentAvailability > this.getAvailabilityDisplayLimit()
            ? this.getAvailabilityDisplayLimit().toString() + '+'
            : availability.currentAvailability.toString(),
        futureStock:
          nextFutureStockQty > this.getAvailabilityDisplayLimit()
            ? this.getAvailabilityDisplayLimit().toString() + '+'
            : nextFutureStockQty.toString(),
        hasFutureStock: availability.maxFutureStock > 0,
        futureStockDate: availability.maxFutureStock > 0 ? this.getFutureStockDate(availability) : null
      };
    } else {
      return {
        hasCurrenStock: availability.currentAvailability > 0,
        currentAvailability: availability.currentAvailability.toString(),
        futureStock: nextFutureStockQty.toString(),
        hasFutureStock: availability.maxFutureStock > 0,
        futureStockDate: availability.maxFutureStock > 0 ? this.getFutureStockDate(availability) : null
      };
    }
  }

  protected getAvailabilityDisplayLimit() {
    return 10;
  }

  protected getFutureStockQty(availability: Availability | SimplifiedAvailability): number {
    // if (!this.showAvailability) {
    //   return null;
    // }

    if (Object.keys(availability).includes('extra')) {
      const extraKey = 'extra';
      const availabilitiesKey = 'availabilities';

      const keys = availability[extraKey][availabilitiesKey] && Object.keys(availability[extraKey][availabilitiesKey]).filter(
        entry => availability[extraKey][availabilitiesKey][entry] > 0
      );
      if (keys?.length > 0) {
        return availability[extraKey][availabilitiesKey][keys[0]];
      }
    }

    return availability.maxFutureStock;
  }

  protected getFutureStockDate(availability: Availability | SimplifiedAvailability): Date {
    // if (!this.showAvailability) {
    //   return null;
    // }

    if (Object.keys(availability).includes('extra')) {
      const extraKey = 'extra';
      const availabilitiesKey = 'availabilities';

      const keys = Object.keys(availability[extraKey][availabilitiesKey]).filter(
        entry => availability[extraKey][availabilitiesKey][entry] > 0
      );
      if (keys.length > 0) {
        return parse(keys[0], 'yyMMdd', new Date());
      }
    }

    return null;
  }
}
