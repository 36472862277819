import { Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-json-editor',
  templateUrl: './gung-json-editor.component.html',
  styleUrls: ['./gung-json-editor.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungJsonEditorComponent),
      multi: true
    }
  ]
})
export class GungJsonEditorComponent extends BaseInputDirective<string> {
  @Output()
  onSave = new EventEmitter();

  statusMessage: string;
  error = false;

  getValidatedJSON(value: string) {
    try {
      const json = JSON.parse(value);
      this.error = false;
      return json;
    } catch (err) {
      this.statusMessage = err;
      this.error = true;
    }
  }

  save() {
    const validatedJson = this.getValidatedJSON(this.value);
    try {
      this.onSave.emit(validatedJson);
    } catch (e) {
      this.error = true;
      this.statusMessage = e;
    }
  }

  format(): void {
    const validated = this.getValidatedJSON(this.value);
    this.value = JSON.stringify(validated, undefined, 4);
  }
}
