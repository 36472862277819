import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, forkJoin, of } from 'rxjs';
import { first, filter, switchMap } from 'rxjs';
import { Customer } from '../../models';
import { AuthService } from '../auth/auth.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { HomeNavigationConfigService } from '../home-navigation-config/home-navigation-config.service';

@Injectable({
  providedIn: 'root'
})
export class DeliveryLocationActionConfigService implements ActionButtonConfigService<Customer> {
  public singleCustomerUserLink = this.homeNavigationConfigService.singleCustomerUserLink;
  base = [
    {
      title: 'SELECT',
      action: (item: Customer) => {
        this.selectedCustomerService.selectCustomer(item.id);
        this.router.navigate(this.singleCustomerUserLink);
      }
    }
  ];

  constructor(
    protected authService: AuthService,
    protected router: Router,
    protected selectedCustomerService: SelectedCustomerService,
    protected homeNavigationConfigService: HomeNavigationConfigService
  ) {}

  getButtons(item: Customer): Observable<ActionButton<Customer>[]> {
    return this.authService.getRoles().pipe(
      filter(roles => !!roles),
      switchMap(roles =>
        forkJoin([of(roles), of(this.router.url), this.selectedCustomerService.getSelectedCustomer().pipe(first())])
      ),
      switchMap(([roles, url, customer]) => {
        // if (customer.id === item.id) { return of([]); } // Selected customer
        return new Observable<ActionButton<Customer>[]>(subscriber => {
          subscriber.next(this.base);
          subscriber.complete();
        });
      })
    );
  }
}
