import { Injectable, Input } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListSortOption } from 'gung-list';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfDigitalAssetsSearchListConfigService implements ConfigService<any> {
  topFilter = true;
  searchDigitalAssetsIds = [];

  @Input()
  isManagement: boolean;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);
  searchDigitalAssetsObjectsSubject: Subject<any> = new BehaviorSubject(null);
  searchDigital: string;

  constructor() {}

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getSortOptions(): ListSortOption<any>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: any) {
    return item.id;
  }

  getSearchTerms(item: any) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<any>[] {
    return [];
  }

  getItems(search): Observable<any[]> {
    return of([]);
  }

  getNothingFoundTranslateTag() {
    if (this.isManagement) {
      return null;
    }
    return 'NOTHING_FOUND_CONTACT_GUNG';
  }

  getSearchGroupCss() {
    return 'd-none';
  }
}
