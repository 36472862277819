<div *ngIf="data; else loading" [class.border]="edit">
    <div class="row">
    </div>
    <div class="row d-flex justify-content-center align-items-center">
      <div
        class="col-md-12"
        (click)="edit ? openHtmlModal('html') : null"
      >
        <div class="custom-pages-html">
          <div [innerHTML]="getHtmlText(data.elements?.html?.text) | safeHtml"></div>
        </div>
      </div>
    </div>
  
  </div>
  
  <ng-template #loading translate> LOADING </ng-template>
  
