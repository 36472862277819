<div class="side-filter-panel-container" [ngClass]="{ 'hide-scrollbar': hideScroll }">
  <div class="row no-gutters">
    <div class="col position-relative form-group">
      <input
        [attr.placeholder]="'SEARCH' | translate"
        class="form-control"
        type="text"
        [(ngModel)]="searchTerm"
        (ngModelChange)="searchEvent.emit(searchTerm)"
      />
      <button
        type="button"
        *ngIf="searchTerm"
        class="btn border-left-0 clear-button"
        aria-label="Clear"
        (click)="searchTerm = ''; searchEvent.emit(searchTerm)"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h5 class="d-flex align-items-end justify-content-between border-bottom">
        {{ 'SELECTED_FILTERS' | translate }}
        <small
          *ngIf="selectedFilters.length > 0"
          role="button"
          class="float-right clear-text"
          (click)="selectedFilters = []; clearEvent.emit()"
        >
          {{ 'CLEAR' | translate }} <i class="fa fa-times-circle"></i>
        </small>
      </h5>
      <ul class="list-unstyled">
        <li
          role="button"
          *ngFor="let option of selectedFilters"
          (click)="filterSelection(option.filterName, option.optionId, false, option.optionName, option.subFilterName)"
        >
          {{ option.optionName }}
          <span class="float-right"><i class="fa fa-times"></i></span>
        </li>
      </ul>
    </div>
    <div class="col-12" *ngFor="let rfg of renderFilterGroups">
      <h5 class="border-bottom">{{ rfg.mainFilter.name | translate }}</h5>
      <ul class="list-unstyled">
        <li
          role="button"
          *ngFor="let option of rfg.mainFilter.valueList"
          (click)="
            filterSelection(rfg.mainFilter.name, option.valueId, !option.selected, option.valueName, rfg.subFilter.name)
          "
          [ngClass]="{ 'selected-side-panel-filter': option.selected }"
        >
          <i class="fa" [ngClass]="{ 'fa-caret-right': !option.selected, 'fa-caret-down': option.selected }"></i>
          <span class="ml-1">{{ option.valueName }}</span>
          <span class="float-right">({{ option.itemCountAfterFilter }})</span>
          <ul class="list-unstyled" *ngIf="option.selected" style="font-weight: normal; color: black">
            <li
              role="button"
              *ngFor="let subOption of getValuesFiltered(rfg.subFilter.valueList)"
              (click)="
                $event.stopPropagation();
                filterSelection(rfg.subFilter.name, subOption.valueId, !subOption.selected, subOption.valueName)
              "
              [ngClass]="{ 'selected-side-panel-filter': subOption.selected }"
            >
              <span>{{ '- ' + subOption.valueName }}</span>
              <span class="float-right">({{ subOption.itemCountAfterFilter }})</span>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </div>
</div>
