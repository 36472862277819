<div class="row" style="padding-bottom: 2px">
  <div class="input-group">
    <input
      placeholder="0"
      [disabled]="true"
      class="form-control form-control-sm text-center input-total"
      [value]="value"
    />
  </div>
</div>

<lib-product-matrix-element-simplified-availability [availability]="simplifiedAvailability" [product]="product">
</lib-product-matrix-element-simplified-availability>
