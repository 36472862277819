import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';
import { NavbarCartService, NavbarCartModel } from '../../services/navbar-cart/navbar-cart.service';
import { SaveCartModalConfigService } from '../../services/save-cart-modal-config/save-cart-modal-config.service';

@Component({
  selector: 'lib-navbarcart',
  templateUrl: './navbarcart.component.html',
  styleUrls: ['./navbarcart.component.scss']
})
export class NavbarcartComponent implements OnInit, OnDestroy {
  cartContents: NavbarCartModel;
  public cartTotalItems = 0;
  public cartTotalRows = 0;
  protected subs: Subscription[] = [];

  showType = this.baseViewConfigService?.navbarcartStyle || 'CartShopping';

  @Input() enableNavbarGlobalSearch: boolean = false;

  @Input() badgeColorClass = 'badge-primary';

  constructor(
    protected navbarCartService: NavbarCartService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected gungModalService: GungModalService,
    protected baseViewConfigService: BaseViewConfigService
  ) { }

  ngOnInit() {
    const sub = this.navbarCartService.getContents().subscribe(contents => {
      this.cartContents = contents;
      this.cartTotalRows = contents.rows.length;
      this.cartTotalItems = contents.rows.map(row => row.cartRow.qty).reduce((acc, curr) => acc + curr, 0);
    });

    this.subs.push(sub);
  }

  ngOnDestroy() {
    this.subs.forEach(s => s.unsubscribe());
    this.subs = [];
  }

  clearCart() {
    this.gungModalService.openConfirmYesNoModal(undefined, 'CLEAR_CART_CONFIRM', { size: 'sm' }).then(
      result => {
        if (result) {
          this.navbarCartService.clearCart();
        }
      },
      reason => { }
    );
  }

  saveCart(): void {
    this.saveCartModalConfigService.openSaveCartModal();
  }
}
