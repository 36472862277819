import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChildren,
  ViewContainerRef
} from '@angular/core';
import { CustomHostDirective, ListItemRendererComponent, ListLayoutSingleComponent } from 'gung-list';
import { Observable, Subscription } from 'rxjs';
import { StandardStatisticsData, StatisticsListItem } from '../../models/statistics';

@Component({
  selector: 'lib-order-finder-list-layout',
  templateUrl: './order-finder-list-layout.component.html',
  styleUrls: ['./order-finder-list-layout.component.css']
})
export class OrderFinderListLayoutComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input()
  public renderItems: Observable<StandardStatisticsData[]>;

  @Input()
  public listItemRenderer: Type<ListItemRendererComponent<StatisticsListItem | StatisticsListItem[]>>;

  @ViewChildren(CustomHostDirective)
  private viewChildren: QueryList<CustomHostDirective>;

  private componentFactory: ComponentFactory<ListItemRendererComponent<StatisticsListItem>>;

  private viewInitiated = false;

  public items: StatisticsListItem[];
  private subscription: Subscription;

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.subscription = this.renderItems.subscribe(ps => {
      this.items = this.groupByOrderId(ps);
      this.items = this.sortByCreationDate(this.items);
      if (!!this.viewInitiated) {
        this.renderItemComponents();
      }
    });
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.listItemRenderer as Type<ListItemRendererComponent<StatisticsListItem>>
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    const children = this.viewChildren.toArray();
    if (!this.items || this.items.length === 0) {
      return;
    }
    for (let i = 0; i < this.items.length; i++) {
      this.renderItemComponent(this.items[i], children[i].viewContainerRef);
    }
    this.changeDetectorRef.detectChanges();
  }

  renderItemComponent(item: StatisticsListItem, containerRef: ViewContainerRef) {
    containerRef.clear();
    const componentRef = containerRef.createComponent(this.componentFactory);
    const typed = componentRef.instance as ListItemRendererComponent<StatisticsListItem>;
    typed.data = item;
  }

  ngAfterViewInit(): void {
    this.viewInitiated = true;
    this.renderItemComponents();
  }

  groupByOrderId(ps: StandardStatisticsData[]): StatisticsListItem[] {
    const result: StatisticsListItem[] = [];
    const groupedStatistics = ps.reduce((acc, x) => {
      (acc[x.orderId] = acc[x.orderId] || []).push(x);
      return acc;
    }, {});
    for (const key of Object.keys(groupedStatistics)) {
      result.push({
        key,
        elements: groupedStatistics[key],
        count: groupedStatistics[key].length
      });
    }
    return result;
  }

  sortByCreationDate(items: StatisticsListItem[]): StatisticsListItem[] {
    return items.sort((a, b) => {
      const aOrderDateTime = a.elements[0].orderCreationDate;
      const bOrderDateTime = b.elements[0].orderCreationDate;

      if (aOrderDateTime > bOrderDateTime) {
        return -1;
      } else if (aOrderDateTime < bOrderDateTime) {
        return 1;
      } else {
        return 0;
      }
    });
  }
}
