import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GungMap } from '../gung-map/gung-map.component';
import { MapOptions } from 'leaflet';


@Component({
  selector: 'lib-map-modal',
  templateUrl: './map-modal.component.html',
  styleUrls: ['./map-modal.component.css']
})
export class MapModalComponent {
  
  modalTitle: string;
  data: GungMap[];
  mapOptions: MapOptions;

  constructor(protected activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.close('ok');
  }
}
