import { Component, OnInit } from '@angular/core';
import { SavedCartConfigService } from '../../services/saved-cart-config/saved-cart-config.service';

@Component({
  selector: 'lib-saved-cart-list',
  templateUrl: './saved-cart-list.component.html',
  styleUrls: ['./saved-cart-list.component.css']
})
export class SavedCartListComponent implements OnInit {
  constructor(public savedCartConfigService: SavedCartConfigService) {}

  ngOnInit() {}
}
