import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { News } from '../../../models/news';
import { NewsService } from '../../../services/news/news.service';
import { Router } from '@angular/router';
import { Product } from '../../../models';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-news-modal',
  templateUrl: './news-modal.component.html',
  styleUrls: ['./news-modal.component.css']
})
export class NewsModalComponent implements OnInit, AfterViewInit {
  @Input()
  news: News[];
  loading: boolean;
  productListIds: string[];
  public currentLang = this.translateService.currentLang;

  @ViewChild('newcontent', { static: false }) newContent: ElementRef;

  constructor(
    protected activeModal: NgbActiveModal,
    protected newsService: NewsService,
    protected router: Router,
    protected elementRef: ElementRef,
    protected translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    if (!!this.news[0].extra?.productList) {
      this.initSlideshow();
      this.loading = false;
    } else {
      this.loading = false;
    }
  }

  ngAfterViewInit(): void {
    // close modal if link open in same page
    if (this.newContent) {
      const links = this.newContent.nativeElement.querySelectorAll('a');
      links.forEach(link => {
        link.addEventListener('click', (event) => {
          // Prevent the default behavior
          if (link.getAttribute('target') !== '_blank') {
            event.preventDefault();
            this.close();
            window.location.href = link.getAttribute('href');
          }
          // Navigate to the specified route
          /*  window.location.href = link.getAttribute('href'); */
        });
      });
    }

  }

  public trackByFn(index: number, item: News) {
    return index;
  }

  close() {
    this.activeModal.close();
  }

  initSlideshow(): void {
    const productList: Product[] = this.news[0].extra?.productList;
    this.productListIds = productList.map(p => p.id);
  }

  goToDetail(product: Product): void {
    if (product?.productType === 'concept') {
      this.router.navigate(['/concept/', product.id]);
    } else {
      if (product?.extra?.conceptId) {
        this.router.navigate(['/concept/', product.extra.conceptId], {
          queryParams: {
            productid: product.id
          }
        });
      } else {
        this.router.navigate(['/product/', product.id]);
      }
    }
  }
}
