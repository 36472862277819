import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ProductService } from '../../services/products/product.service';
import { FilterListComponent, IdBasedSubscriptionConfigService } from 'gung-list';
import { Product } from '../../models';
import { ProductListByIdsConfigService } from '../../services/product-list-by-ids-config/product-list-by-ids-config.service';

@Component({
  selector: 'lib-product-list-by-ids',
  template: '<lib-filter-list #productList [configService]="configService"></lib-filter-list>',
  styleUrls: ['./product-list-by-ids.component.css']
})
export class ProductListByIdsComponent implements OnInit {
  @ViewChild('productList') productList: FilterListComponent<Product>;

  @Input()
  private _productIds: string[];

  @Input() set productIds(value: string[] | string) {
    if (value && !Array.isArray(value)) {
      value = value.split(',');
    }
    if (this.isArrayIdenticalToProductIds(value)) {
      return;
    }
    this._productIds = value as string[];
    this._productIds = this._productIds.map(id => id.trim());
    this.configService.setSubscriptionIds(this._productIds);
  }

  @Input() set productIdsCSS(values: string) {
    let idsArray = [];
    if (values) {
      idsArray = values.split(',');
    }
    if (this.isArrayIdenticalToProductIds(idsArray)) {
      return;
    }
    this._productIds = idsArray;
    this.configService.setSubscriptionIds(this._productIds);
  }

  isArrayIdenticalToProductIds(idsArray): boolean {
    if (
      this._productIds &&
      idsArray &&
      this._productIds.length === idsArray.length &&
      this._productIds.every((ele, index) => ele === idsArray[index])
    ) {
      return true;
    }
    return false;
  }

  @Input()
  parentClass: string = 'col-12'; // Use to calculate number item per row

  constructor(public configService: ProductListByIdsConfigService, protected productService: ProductService) {}

  ngOnInit() {
    this.configService.parentClass = this.parentClass;
  }
}
