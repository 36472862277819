import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of, from } from 'rxjs';
import { switchMap, first, map } from 'rxjs';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { Cart, CartExport } from '../../models/cart';
import { format } from 'date-fns';
import { TranslateService } from '@ngx-translate/core';
import { Customer } from '../../models';

export enum ExportCartSelectionDataType {
  SavedQuote
}

@Injectable({
  providedIn: 'root'
})
export class CartExportService {
  currentLang = this.translateService.currentLang || 'se';

  constructor(
    protected http: HttpClient,
    protected productService: ProductService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService
  ) {}

  exportSavedQuote(
    cart: Cart,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    useV2?: boolean
  ): Observable<{ status: boolean; message: string }> {
    const url = useV2 ? 'v2/generate-product-pdf-from-products-ids' : 'generate-product-pdf-from-products-ids';
    return this.downloadFile(
      url,
      cart,
      ExportCartSelectionDataType.SavedQuote,
      template,
      additionalVariables,
      additionalParams
    );
  }

  exportSavedQuoteBytes(
    cart: Cart,
    name?: string,
    description?: string,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
    usePuppeteerEngine?: boolean
  ): Observable<any> {
    let url = '';
    if (!!usePuppeteerEngine) {
      // New engine
      url = '/download/v3/generate-product-pdf-from-products-ids';
    } else {
      // Old engine
      url = 'download/generate-product-pdf-from-products-ids/bytes';
    }
    return this.getExportRequestBodyByType(
      ExportCartSelectionDataType.SavedQuote,
      cart,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      map(data => {
        return { isValid: true, body: data };
      }),
      switchMap((request: { isValid: boolean; body: any }) => {
        return this.http.post(url, request.body, { responseType: 'blob', observe: 'response' });
      }),
      switchMap((x: any) => {
        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([x.body], { type: 'application/pdf' });

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        // @ts-ignore
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          if (!!name) {
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(newBlob, name);
            return of();
          } else {
            // @ts-ignore
            window.navigator.msSaveOrOpenBlob(newBlob, name);
            return of();
          }
        }

        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob);

        const link = document.createElement('a');
        link.href = data;
        if (!!name) {
          if (!name.endsWith('.pdf')) {
            name = name + '.pdf';
          }
          link.download = name;
        } else {
          link.download = 'quotation.pdf';
        }

        // this is necessary as link.click() does not work on the latest firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

        setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 100);

        return of(null);
      })
    );
  }
  exportCartExcel(
    cart: Cart,
    definitionId: string,
    additionalParams?: { [s: string]: any },
    mailTitle?: string,
    filename?: string
  ): void {
    const url = 'download/cart/excel';

    forkJoin({
      user: this.authService.getCurrentUser().pipe(first()),
      customer: this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      flow: this.gungFlowService.getSelectedFlow().pipe(first())
    })
      .pipe(
        switchMap(data => {
          return forkJoin({
            data: of(data),
            modalOutput: from(this.gungModalService.openConfirmExportModal(data.user.email)).pipe(first())
          });
        })
      )
      .subscribe(({ data, modalOutput }) => {
        if (modalOutput && modalOutput.email) {
          const extra = additionalParams || {};
          extra.customerId = data.customer.id;
          extra.flowId = data.flow.id;

          const cartExport: CartExport = {
            id: '',
            name: '',
            definitionId,
            rows: cart.data.rows,
            recipient: modalOutput.email,
            mailTitle,
            filename,
            extra
          };

          this.http
            .post<any>(url, cartExport)
            .pipe(first())
            .subscribe(_ => {});
        }
      });
  }

  protected buildExportRequestFromModal(
    exportSelectionDataType: ExportCartSelectionDataType,
    cart: Cart,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<{ isValid: boolean; body: any }> {
    // build request body
    return this.getExportRequestBodyByType(
      exportSelectionDataType,
      cart,
      template,
      additionalVariables,
      additionalParams
    ).pipe(
      first(),
      switchMap((data: any) => {
        return forkJoin({
          data: of(data),
          modalOutput: from(this.gungModalService.openConfirmExportModal(data.recipient)).pipe(first())
        });
      }),
      switchMap(({ data, modalOutput }) => {
        let isValid = false;

        if (modalOutput && modalOutput.email) {
          isValid = true;
          data.recipient = modalOutput.email;
        }

        return of({ isValid, body: data });
      })
    );
  }

  getExportRequestBodyByType(
    exportSelectionDataType: ExportCartSelectionDataType,
    cart: Cart,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {}
  ): Observable<any> {
    return forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      map(([user, customer, flow]) => {
        // this parameters are shared along all the requests
        let requestBody: any = {
          ids: cart.data.rows.map(row => row.productId || row.id),
          recipient: user.email,
          customerId: customer.id,
          flowId: flow.id
        };

        // Saved Quote
        if (exportSelectionDataType === ExportCartSelectionDataType.SavedQuote) {
          const paramsValue = {
            language: this.currentLang,
            dateCreated: format(new Date(), 'MMMM dd, yyyy'),
            cartItems: cart.data.rows.map(row => {
              return {
                ...row,
                id: row.productId || row.id,
                quantity: row.qty || row.quantity
              };
            }),
            quoteDescription: cart.extra.description,
            stockId: user.managedMultistockIds[0],
            customerId: customer.id,
            cartName: cart.name,
            flowId: flow.id,
            customerCurrency: this.getCustomerCurrency(customer),
            user: {
              ...user,
              details: user
            },
            extra: cart.extra,
            ...additionalParams
          };

          requestBody = {
            ...requestBody,
            template: template || 'QUOTE_PDF_EXPORT',
            params: paramsValue,
            ...additionalVariables
          };
        }

        return requestBody;
      })
    );
  }

  getCustomerCurrency(customer: Customer): string {
    return '';
  }

  protected exportProductsServiceCall(serviceUrl: string, body: any): Observable<{ status: boolean; message: string }> {
    return this.http.post<{ status: string }>('download/' + serviceUrl, body).pipe(
      first(),
      map(resp => {
        return {
          status: resp.status === 'OK',
          message: resp.status
        };
      })
    );
  }

  protected downloadFile(
    serviceUrl: string,
    cart: Cart,
    type: ExportCartSelectionDataType,
    template?: string,
    additionalVariables?: {},
    additionalParams?: {},
  ): Observable<{ status: boolean; message: string }> {
    return this.buildExportRequestFromModal(type, cart, template, additionalVariables, additionalParams).pipe(
      first(),
      switchMap((request: { isValid: boolean; body: any }) => {
        if (request.isValid) {
          return this.exportProductsServiceCall(serviceUrl, request.body);
        } else {
          return of({
            status: false,
            message: 'An error has occurred'
          });
        }
      })
    );
  }
}
