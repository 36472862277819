<div *ngIf="!!loaded && submitForm; else loading">
  <div class="modal-header">
    <h4 class="modal-title" translate>{{ activity?.id ? 'ACTIVITY_EDIT' : 'ACTIVITY_CREATE' }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="submitForm">
      <div class="row" *ngIf="!activity?.id">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="searchElementCustomer"></ng-container>
          <!-- <lib-gung-text-input [renderStatic]="true" [gungTranslate]="'CUSTOMER'" formControlName="customer"></lib-gung-text-input> -->
          <lib-forms-message-error [controller]="submitForm.get('customer')"></lib-forms-message-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-select-option-input
            *ngIf="!!customersResponsible && customersResponsible.length > 0; else customerResponsibleTextInput"
            gungTranslate="CUSTOMER_RESPONSIBLE"
            [optionsList]="customersResponsible"
            formControlName="customerResponsible"
          ></lib-gung-select-option-input>
          <ng-template #customerResponsibleTextInput>
            <lib-gung-text-input
              [gungTranslate]="'CUSTOMER_RESPONSIBLE'"
              formControlName="customerResponsible"
            ></lib-gung-text-input>
          </ng-template>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-meta-data-select-input
            formControlName="status"
            table="ACTIVITYINTERNALSTATUS"
            metaId="id"
            metaDisplay="name"
            prefix="@ACTIVITYINTERNALSTATUS"
            hideEmptyOption="true"
            label="STATUS"
            labelClass="col-12 font-weight-normal"
          ></lib-meta-data-select-input>
          <lib-forms-message-error [controller]="submitForm.get('status')"></lib-forms-message-error>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <lib-gung-date-picker
            label="ACTIVITY_DATE"
            [currentselectedDate]="submitForm.get('activityDate').value"
            [minInitialDate]="minDatePickerDate"
            (selectedDate)="updateDate($event)"
          ></lib-gung-date-picker>
          <lib-forms-message-error [controller]="submitForm.get('activityDate')"></lib-forms-message-error>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ng-container *ngTemplateOutlet="searchElement"></ng-container>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <lib-meta-data-select-input
            formControlName="type"
            table="ACTIVITYINTERNALTYPE"
            metaId="id"
            metaDisplay="name"
            prefix="@ACTIVITYINTERNALTYPE"
            label="ACTIVITY_TYPE"
            hideEmptyOption="true"
            labelClass="col-12 font-weight-normal"
          ></lib-meta-data-select-input>
          <lib-forms-message-error [controller]="submitForm.get('type')"></lib-forms-message-error>
        </div>
      </div>
      <div class="row" *ngIf="!activity?.id">
        <div class="col-12">
          <div class="form-group">
            <label for="comment" class="control-label" translate>COMMENT</label>
            <textarea
              class="form-control"
              type="text"
              name="comment"
              id="comment"
              formControlName="comment"
              rows="5"
            ></textarea>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="!activity?.id">
        <div class="col-12">
          <div class="form-group">
            <label class="w-100" for="file-upload" translate>ATTACH_FILES</label>
            <input
              #inputFile
              type="file"
              class=""
              id="file-upload"
              aria-describedby="file-upload"
              [multiple]="multipleFiles"
              (change)="onFileSelect($event.target.files)"
              [disabled]="disabled ? true : null"
            />
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <span *ngIf="!!selectedCustomers && selectedCustomers.length > 0">Save {{selectedCustomers.length}} activities</span>
    <button type="button" class="btn btn-secondary" (click)="activeModal.close('Cance click')" translate>CANCEL</button>
    <button type="button" class="btn btn-primary" [disabled]="disabled ? true : null" (click)="!!selectedCustomers  && selectedCustomers.length > 0 ? onSaveMultiple() : onSave()" translate>
      SAVE
    </button>
    <div class="alert alert-danger w-100" *ngIf="errorEnableMessage">
      This feature is not available, please contact support to enable it. 
    </div>
    <div class="alert alert-danger w-100" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </div>
</div>

<ng-template #searchElement>
  <div>
    <label for="typeahead-prevent-manual-entry" translate>CONTACT</label>
    <input
      #searchInput
      id="typeahead-prevent-manual-entry"
      type="text"
      class="form-control"
      [(ngModel)]="contactSearch"
      [disabled]="customerContacts.length <= 0"
      [ngbTypeahead]="search"
      [inputFormatter]="resultFormatter"
      [resultFormatter]="resultFormatter"
      (selectItem)="selectItem($event, searchInput)"
      placeholder="{{ (customerContacts.length > 0 ? 'CONTACT' : 'NO_AVAILABLE_CONTACTS') | translate }}"
      (focusout)="focusout($event, contactSearch)"
      (focus)="openContacts($event)"
      [showHint]="false"
      [editable]="false"
    />
  </div>
</ng-template>

<ng-template #searchElementCustomer>
  <div *ngIf="!!selectedCustomers && selectedCustomers.length > 0; else singleCustomer">
    <label translate>CUSTOMERS</label>
    <div class="cards">
      <div *ngFor="let selectedCustomer of selectedCustomers" class="card">
        <span>{{ selectedCustomer.id }} - {{ selectedCustomer.name}}</span>
        <button class="delete-button" (click)="removeCustomer(selectedCustomer.id)"><i class="fas fa-times"></i></button>
      </div>
    </div>
  </div>
  <ng-template #singleCustomer>
    <div>
      <label for="typeahead-prevent-manual-entry" translate>CUSTOMER</label>
      <input
        id="typeahead-prevent-manual-entry"
        type="text"
        class="form-control"
        #searchInputCustomer
        [(ngModel)]="modelSearchCustomer"
        [ngbTypeahead]="searchCustomer"
        [inputFormatter]="formatterCustomer"
        [resultFormatter]="formatterCustomer"
        (selectItem)="selectItemCustomer($event, searchInputCustomer)"
        (blur)="changeCustomer($event.target.value)"
        (focusout)="focusoutCustomer($event, modelSearchCustomer)"
        [showHint]="false"
        [editable]="false"
      />
    </div>
  </ng-template>
</ng-template>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
