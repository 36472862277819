import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnInit,
  Type,
  ViewChild
} from '@angular/core';
import { CustomHostDirective } from 'gung-list';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';
import { BarcodeScannerComponent } from './barcode-scanner.component';

@Component({
  selector: 'lib-barcode-scanner-wrapper',
  template: `<ng-template libCustomHost></ng-template>`,
  styles: []
})
export class BarcodeScannerWrapperComponent implements AfterViewInit {
  @ViewChild(CustomHostDirective)
  viewChild: CustomHostDirective;

  constructor(
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected baseViewConfigService: BaseViewConfigService
  ) {}

  ngAfterViewInit(): void {
    this.renderLayout(this.baseViewConfigService.getBarcodeScannerComponent());
  }

  renderLayout(layoutComponent: Type<BarcodeScannerComponent>, data: any = {}): void {
    if (!this.viewChild) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(factory);
    const typedComponent = componentRef.instance as any;
    this.changeDetectorRef.detectChanges();
  }
}
