<div class="row" *ngIf="finishedLoading && !!filteredIds">
  <input type="text" name="" id="" [(ngModel)]="searchString" (change)="search(searchString, 'default')" />
  <lib-filter-list-side-filter
    *ngFor="let renderFilter of renderFilters"
    [renderFilter]="renderFilter"
    (selectValue)="filter(renderFilter.name, $event, true)"
    (deselectValue)="filter(renderFilter.name, $event, false)"
  ></lib-filter-list-side-filter>
  <div class="col-12">
    <ng-template libCustomHost></ng-template>
  </div>
  <div class="col-12">
    <button class="btn btn-primary" (click)="previous()">PREVIOUS</button>
    <button class="btn btn-primary" (click)="next()">NEXT</button>
  </div>
</div>
