import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../../../services/auth/auth.service';
import { first } from 'rxjs';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { AssortmentService } from '../../../../services/assortment.service';
import { TranslateService } from '@ngx-translate/core';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { GungNoImageUrl } from 'gung-common';

// DEFAULT DATA EXAMPLE USED WHEN NO DATA
const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    top: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 1400x1400
      },
      text: {
        enabled: true,
        text: 'See our news!'
      }
    },
    middleOne: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Shoes'
      }
    },
    middleTwo: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Clothes'
      }
    },
    middleThree: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Accessories'
      }
    },
    bottomOne: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'See our sale!'
      }
    },
    bottomTwo: {
      link: {
        enabled: true,
        internal: true,
        url: '/products'
      },
      image: {
        enabled: true,
        sourceUrl: GungNoImageUrl // 300x300
      },
      text: {
        enabled: true,
        text: 'Monitor products'
      }
    }
  }
};

@Component({
  selector: 'lib-home-fashion-template',
  templateUrl: './home-fashion-template.component.html',
  styleUrls: ['./home-fashion-template.component.css']
})
export class HomeFashionTemplateComponent extends CustomPageTemplateComponent implements OnInit {

  ngOnInit() {}

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

  getImage(image) {
    const url = encodeURI(super.getImage(image));
    return `'${url}'`;
  }
}
