import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { first } from 'rxjs';
import { AuthService } from './../../../../services/auth/auth.service';

@Component({
  selector: 'lib-email-recipient',
  templateUrl: './email-recipient.component.html',
  styleUrls: ['./email-recipient.component.css']
})
export class EmailRecipientComponent extends CheckoutStepComponent implements OnInit {
  constructor(protected authService: AuthService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();

    this.onNextBtnClicked.pipe(first()).subscribe(() => {
      this.authService
        .getCurrentUser()
        .pipe(first())
        .subscribe(user => {
          this.checkout.extra.confirmationEmails = this.checkout.extra.confirmationEmails || [];
          this.checkout.extra.confirmationEmails.push(user.email);

          this.stepDone.emit(this.checkout);
        });
    });
  }

  isVisible() {
    return false;
  }
}
