<div class="row">
  <div *ngFor="let pim of data" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
    <div
      class="card border h-100"
      (click)="selectButton.toggleSelection($event)"
      [class.border-selected]="selectButton.isSelected"
    >
      <img
        *ngIf="pim.images && pim.images[0] as image; else noimage"
        class="card-img-top img-fluid mx-auto d-block"
        [src]="image.s3Uri| gungImageUrl
          :'type:list'
          :'etag:'+image.s3ETag
        "
        alt="{{ image.description }}"
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
      <div class="card-body">
        <h5 style="height: 3em; text-overflow: ellipsis" class="card-title">{{ pim.name }}</h5>
        <p class="card-text">{{ pim.masterId ? pim.masterId : pim.id }}</p>
        <button type="button" class="btn btn-primary w-100" [routerLink]="['./', pim.id]" translate>
          MANAGE_PRODUCT
        </button>
        <lib-item-selection-button [item]="pim" class="d-none" #selectButton [id]="pim.id"></lib-item-selection-button>
      </div>
    </div>
  </div>
</div>
