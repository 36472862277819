<input
  #instance="ngbTypeahead"
  *ngIf="customers && customers.length > 1; else maxOneCustomer"
  id="typeahead-basic"
  type="text"
  (focus)="focus$.next($event.target.value)"
  (click)="click$.next($event.target.value)"
  class="form-control"
  [resultFormatter]="formatter"
  [(ngModel)]="model"
  [ngbTypeahead]="search"
  (blur)="selectCurrentSelectedCustomer()"
  (selectItem)="onItemSelect($event)"
/>

<ng-template #maxOneCustomer>
  <a *ngIf="customers; else loading" class="nav-link" routerLink="/customers/{{ (selectedCustomer | async).id }}">{{
    (selectedCustomer | async).name
  }}</a>
</ng-template>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-lg"></i>
</ng-template>
