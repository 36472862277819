<div
  class="inline-search-container"
  [ngClass]="{ 'inline-search-container-mobile': isSmallScreen && resultsShow }"
  (keydown.enter)="showMoreProducts(searchInput)"
>
  <div class="row">
    <div class="col">
      <div class="input-group inline-search d-none d-md-flex" [ngClass]="{ 'd-flex': resultsShow }">
        <input
          #searchInput
          type="text"
          class="form-control"
          [attr.placeholder]="searchPlaceholderTranslate | translate"
          [formControl]="searchTermForm"
          (keypress)="keyPressedHandler($event, searchInput)"
        />
        <div class="input-group-append">
          <span class="input-group-text d-none d-md-flex" (click)="search(searchInput.value)"
            ><i class="fas fa-search"></i
          ></span>
          <span
            class="input-group-text d-md-none"
            (click)="$event.stopPropagation(); $event.preventDefault(); resultsShow = false"
            ><i class="fas fa-times"></i
          ></span>
        </div>
      </div>
      <div class="inline-search-mobile d-md-none" [class.d-none]="isSmallScreen && resultsShow">
        <div class="input-group-append">
          <span class="input-group-text" (click)="resultsShow = true"><i class="fas fa-search"></i></span>
        </div>
      </div>
    </div>
  </div>
  <div class="row inline-search-products" [class.d-none]="!resultsShow" data-inline-search-products="1">
    <div class="col-12">
      <ng-template libCustomHost></ng-template>
    </div>
    <div class="col-12" *ngIf="totalCount > resultsLimit">
      <button type="button" class="btn btn-light btn-block btn-sm" (click)="showMoreProducts(searchInput)">
        <small>{{ 'GUNG_PRODUCT_LIST_LOAD_MORE' | translate: { limit: resultsLimit, total: totalCount } }}</small>
      </button>
    </div>
  </div>
</div>
