import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdiInvoiceDetailConfigService {
  disable = {
    invoiceNumber: true,
    currency: true,
    buyerOrderNumber: true,
    valueDate: true,
    supplierOrderNumber: true,
    despatchAdviceNumber: true,
    invoiceDate: true,
    interestOnOverduePayment: true,
    buyerOrderDate: true,
    deliveryDate: true,
    netAmout: true,
    taxableAmount: true,
    totalTaxAmount: true,
    totalAmount: true,
    rounding: true,
    totalAmountInclRounding: true
  };

  constructor() {}
}
