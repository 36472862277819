import { Injectable } from '@angular/core';
import { ProductsBookmark } from '../../models/products-bookmark';
import { forkJoin, Observable, of } from 'rxjs';
import { Product } from '../../models';
import { ProductSelectionService } from '../product-selection-service/product-selection.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsBookmarkService } from '../products-bookmark/products-bookmark.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { first, map, switchMap } from 'rxjs/operators';
import { ProductsBookmarkCreateModalComponent } from '../../components/products-bookmark/products-bookmark-create-modal/products-bookmark-create-modal.component';
import { AuthService } from '../auth/auth.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { ProductService } from '../products/product.service';

@Injectable({
  providedIn: 'root'
})
export class ProductsBookmarkActionButtonConfigService implements ActionButtonConfigService<ProductsBookmark> {
  constructor(
    protected selectionService: ProductSelectionService,
    protected modalService: NgbModal,
    protected productsBookmarkService: ProductsBookmarkService,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected authService: AuthService,
    protected productService: ProductService
  ) {}

  getButtons(item: ProductsBookmark): Observable<ActionButton<ProductsBookmark>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        const actions = [];
        actions.push({
          title: 'SELECT_BOOKMARK',
          displayAsButton: true,
          action: (pb: ProductsBookmark) => {
            this.selectionService.clearSelection();
            this.selectionService.select(pb.products.map(id => ({ id } as Product)));
            this.productService.getProductsByIdsUnfiltered(pb.products).pipe(first()).subscribe(products => {
              // If get the list clear again and set full product list
              this.selectionService.clearSelection();
              this.selectionService.select(products);
            })
            this.modalService.dismissAll();
          }
        });

        if (roles.includes('ADMIN')) {
          actions.push(
            {
              title: 'UPDATE_WITH_CURRENT_PRODUCT_SELECTION',
              displayAsButton: false,
              action: (pb: ProductsBookmark) => {
                this.selectionService
                  .getSelection()
                  .pipe(
                    first(),
                    map(selection => {
                      pb.products = Object.values(selection.selectedItems).map(p => p.id);
                      return pb;
                    }),
                    switchMap(bookmark => forkJoin([this.productsBookmarkService.create(bookmark).pipe(first())]))
                  )
                  .subscribe(([]) => {});
              }
            },
            {
              title: 'EDIT',
              displayAsButton: false,
              action: (pb: ProductsBookmark) => {
                const modal = this.modalService.open(ProductsBookmarkCreateModalComponent);
                modal.componentInstance.productsBookmark = pb;
                modal.componentInstance.isUpdate = true;
              }
            },
            {
              title: 'DELETE',
              displayAsButton: false,
              action: (pb: ProductsBookmark) => {
                this.gungModalService
                  .openConfirmYesNoModal(undefined, this.translateService.instant('DELETE_BOOKMARK_CONFIRM'), {
                    size: 'sm'
                  })
                  .then(
                    result => {
                      if (result) {
                        this.productsBookmarkService
                          .deleteById(pb.id)
                          .pipe(first())
                          .subscribe(_ => {});
                      }
                    },
                    reason => {}
                  );
              }
            }
          );
        }
        return actions;
      })
    );
  }
}
