<div class="row">
  <div class="col-12 pb-3 border-bottom">
    <div class="row">
      <div class="col-12 mt-4 col-md">
        <button class="btn btn-primary" (click)="toggleShopifyActive()">
          <i
            class="fa mr-2"
            [class.fa-check-square]="pimProduct.extra.pim.buyerGroups.SHOPIFY"
            [class.fa-square]="!pimProduct.extra.pim.buyerGroups.SHOPIFY"
          ></i>
          <span translate>ACTIVE_IN_SHOPIFY</span>
        </button>
        <button type="button" class="btn btn-link" (click)="syncToShopify(pimProduct.id)" translate>
          SYNC_TO_SHOPIFY
          <i *ngIf="syncInProgress" class="fa fa-spinner fa-spin fa-lg ml-1"></i>
        </button>
      </div>
      <div class="col-12 col-md-4" *ngIf="useShopifyProductTemplate">
        <lib-meta-select-input
          [(ngModel)]="pimProduct.extra.pim.shopifyProductTemplate"
          table="SHOPIFY_PRODUCT_TEMPLATE"
          metaId="id"
          gung-translate="PRODUCT_TEMPLATE"
          metaDisplay="name"
          suffix="@SHOPIFY_PRODUCT_TEMPLATE"
          [nameAsValue]="true"
        >
        </lib-meta-select-input>
      </div>
    </div>
  </div>
  <div class="col-12 mt-4">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem>
        <a ngbNavLink translate>VARIANTS</a>
        <ng-template ngbNavContent>
          <lib-pim-product-shopify-variants
            [pimProduct]="pimProduct"
            [languages]="languages"
          ></lib-pim-product-shopify-variants>
        </ng-template>
      </ng-container>
      <ng-container ngbNavItem *ngFor="let lang of languages">
        <a ngbNavLink> {{ 'SHOPIFY_ATTRIBUTES' | translate }} - {{ lang.toUpperCase() }}</a>
        <ng-template ngbNavContent>
          <lib-pim-product-shopify-attributes
            [pimProduct]="pimProduct"
            [language]="lang"
          ></lib-pim-product-shopify-attributes>
        </ng-template>
      </ng-container>
    </nav>

    <div *ngIf="pimProduct; else loading" [ngbNavOutlet]="nav" class="mt-2"></div>
    <ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
  </div>
</div>
