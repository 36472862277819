<div class="lang-selection" ngbDropdown *ngIf="languages && (languages.length > 1 || forceShowLanguage)">
  <button class="btn w-100" ngbDropdownToggle>
    <span>
      <img src="./assets/{{ currentLang.image }}" class="button" alt="{{ currentLang.language }}" />
      <span *ngIf="!showOnlyFlags" class="btn-text ml-2" translate>CHANGE_LANGUAGE</span>
    </span>
    <i class="icon fas fa-angle-down "></i>
  </button>
  <div class="w-100" ngbDropdownMenu [class.width-auto]="showOnlyFlags">
    <div *ngFor="let lang of languages">
      <span class="d-flex ml-2 btn-pointer" (click)="changeLanguage(lang.short)">
        <img src="./assets/{{ lang.image }}" class="button" alt="{{ lang.language }}" />
        <p *ngIf="!showOnlyFlags" class="text-capitalize ml-2">{{ lang.language }}</p>
      </span>
    </div>
  </div>
</div>