<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="col-12">
    <div class="form-group">
      <label for="value" class="control-label" translate>{{ label }}</label>

      <input
        *ngIf="!isTextArea"
        class="form-control"
        type="text"
        name="value"
        id="value"
        [(ngModel)]="value"
        ngbAutofocus
      />
      <textarea
        *ngIf="isTextArea"
        class="form-control"
        type="text"
        name="value"
        id="value"
        [(ngModel)]="value"
        ngbAutofocus
      ></textarea>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="close()" translate>CANCEL</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="save()" translate>SAVE</button>
      </div>
    </div>
  </div>
</div>
