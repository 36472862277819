import {
  Component,
  OnInit,
  Input,
  Type,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewChild,
  AfterViewInit,
  ComponentFactory,
  ComponentRef,
  OnChanges,
  SimpleChange,
  OnDestroy,
  EventEmitter
} from '@angular/core';
import { Observable, Subject, forkJoin, Subscription, takeUntil } from 'rxjs';
import { ListItemRendererComponent, ListLayoutComponent } from '../types';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import { first } from 'rxjs';

@Component({
  selector: 'lib-list-layout-multiple',
  templateUrl: './list-layout-multiple.component.html',
  styleUrls: ['./list-layout-multiple.component.css']
})
export class ListLayoutMultipleComponent<T> extends ListLayoutComponent<T> implements OnInit, AfterViewInit, OnDestroy {
  @Input()
  public renderItems: Observable<T[]>;

  @Input()
  public listItemRenderer: Type<ListItemRendererComponent<T | T[]>>;

  @ViewChild(CustomHostDirective, { static: true })
  private viewChild: CustomHostDirective;

  public items: T[];

  private viewInitiated = new Subject<void>();
  private viewInitiated$ = this.viewInitiated.asObservable();

  private renderedComponent?: ListItemRendererComponent<T[]>;

  private componentFactory: ComponentFactory<ListItemRendererComponent<T[]>>;

  private subscription: Subscription;

  private unsubscribe: Subject<void> = new Subject<void>();

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.listItemRenderer as Type<ListItemRendererComponent<T[]>>
    );
    this.subscription = this.viewInitiated$.pipe(first()).subscribe(() =>
      this.renderItems.subscribe(items => {
        this.items = items;
        this.renderItemComponent();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngAfterViewInit(): void {
    this.viewInitiated.next();
  }

  renderItemComponent() {
    if (this.renderedComponent) {
      const changes = { data: new SimpleChange(this.renderedComponent.data, this.items, false) };
      this.renderedComponent.data = this.items;
      const typed = this.renderedComponent as unknown as OnChanges;
      if (typed.ngOnChanges) {
        typed.ngOnChanges(changes);
      }
      return;
    }

    this.viewChild.viewContainerRef.clear();
    const componentRef = this.viewChild.viewContainerRef.createComponent(this.componentFactory);
    const typedComponent = componentRef.instance as ListItemRendererComponent<T[]>;
    this.renderedComponent = typedComponent;
    typedComponent.data = this.items;
    typedComponent.dynamicColumns = this.dynamicColumns;
    typedComponent.itemsPerRow = this.itemsPerRow;
    typedComponent.pimOptions = this.pimOptions;
    typedComponent.parentClassCss = this.parentClassCss;
    typedComponent.childColClassCss = this.childColClassCss;
    typedComponent.renderFinished = new EventEmitter<void>();
    typedComponent.renderFinished.pipe(takeUntil(this.unsubscribe)).subscribe(() => this.renderFinished?.emit());
    componentRef.changeDetectorRef.detectChanges();
  }
}
