import { ChangeDetectorRef, Component, ComponentFactoryResolver } from '@angular/core';
import { takeUntil } from 'rxjs';
import { InlineSearchLayoutComponent } from '../inline-search-layout/inline-search-layout.component';

@Component({
  selector: 'lib-inline-search-layout-multiple',
  templateUrl: './inline-search-layout-multiple.component.html',
  styleUrls: ['./inline-search-layout-multiple.component.css']
})
export class InlineSearchLayoutMultipleComponent<T> extends InlineSearchLayoutComponent<T> {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super(componentFactoryResolver, changeDetectorRef);
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    const children = this.viewChildren.toArray();
    if (!this.items || this.items.length === 0) {
      return;
    }
    this.renderListComponent(this.items as any, children[0].viewContainerRef);
    this.changeDetectorRef.detectChanges();
  }
}
