import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-frontend-settings',
  templateUrl: './frontend-settings.component.html',
  styleUrls: ['./frontend-settings.component.css']
})
export class FrontendSettingsComponent implements OnInit {
  doneLoading = false;

  constructor() {}

  ngOnInit(): void {}
}
