import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CoverImage, ExportCoverSheet } from '../../../models/export-cover-sheets';
import { ExportCoverSheetsService } from '../../../services/export-cover-sheets/export-cover-sheets.service';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { forkJoin, Observable } from 'rxjs';

@Component({
  selector: 'lib-new-cover-sheet-modal',
  templateUrl: './new-cover-sheet-modal.component.html',
  styleUrls: ['./new-cover-sheet-modal.component.css']
})
export class NewCoverSheetModalComponent implements OnInit {
  isSubmitted: boolean;
  form: FormGroup;
  coverSheet: ExportCoverSheet;

  seasonList = ['winter', 'summer', 'default'];

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected exportCoverSheetsService: ExportCoverSheetsService
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.form = this.formBuilder.group({
      category: this.formBuilder.control('', [Validators.required]),
      season: this.formBuilder.control('', [Validators.required])
    });
  }

  onCreate(id: string, inputFile) {
    const form = this.form.getRawValue();
    const files: File[] = inputFile.files;
    
    const coverImageUploadObservables: Observable<CoverImage>[] = [];
    for (const file of files) {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('timeStamp', new Date().toISOString());
      coverImageUploadObservables.push(this.exportCoverSheetsService.postCoverImageUpload(id, formData));
    }

    this.isSubmitted = true;

    forkJoin(coverImageUploadObservables).subscribe((images: CoverImage[]) => {
      this.coverSheet = {
        id: form.category + '_' + form.season,
        category: form.category,
        extra: null,
        image: images.shift(),
        additionalImages: images.length > 0 ? Array.from(images) : [],
        index: null,
        name: null,
        season: form.season
      };

      this.exportCoverSheetsService.putExportCoverSheet(this.coverSheet).subscribe(exportCoverSheet => {
        this.isSubmitted = false;
        this.activeModal.close(exportCoverSheet);
      });
    })
  }
}
