import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BeepService {
  constructor() { }

  beep(type: 'success' | 'error' = 'success') {
    const audio = new Audio();
    if (type === 'success') {
      audio.src = './assets/beep.mp3';
    } else {
      audio.src = './assets/beep-error.mp3';
    }
    audio.load();
    audio.play();
  }
}
