import { EventEmitter, Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService } from 'gung-common';
import { Invoice } from '../../models/invoice';
import { Observable, of } from 'rxjs';
import { BackendRerouteService } from '../utils/backend-reroute.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceActionConfigService implements ActionButtonConfigService<Invoice>{

  constructor(
    protected backendRerouteService: BackendRerouteService
  ) { }

  actions = {
    title: 'PDF',
    action: (item: Invoice) => this.backendRerouteService.reroute('download/invoice-pdf/' + item.id + '.pdf')
  };

  getButtons(item: Invoice, eventEmitter?: EventEmitter<Invoice>): Observable<ActionButton<Invoice>[]> {
    return of([this.actions]);
  }

  
}
