import { Injectable } from '@angular/core';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, of } from 'rxjs';
import { OrderConfirmationContactsModalComponent } from '../../components/open-purchase-orders/order-confirmation-contacts-modal/order-confirmation-contacts-modal.component';
import { OpenPurchaseOrders } from '../open-purchase-orders/open-purchase-orders.service';
import { BackendRerouteService } from '../utils/backend-reroute.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class OpenPurchaseOrdersActionConfigService implements ActionButtonConfigService<OpenPurchaseOrders> {
  base = [
    {
      title: 'DOWNLOAD',
      action: (item: OpenPurchaseOrders) =>
        this.backendRerouteService.reroute('download/supplier-order/' + item.id, true)
    },
    {
      title: 'EMAIL_CONFIRMATION',
      action: (item: OpenPurchaseOrders) => {
        const modalRef: NgbModalRef = this.modalService.open(OrderConfirmationContactsModalComponent);
        modalRef.componentInstance.orderId = item.id;
        modalRef.componentInstance.contactType = 'supplier';
        modalRef.result.then(_ => {});
      }
    }
  ];

  constructor(protected backendRerouteService: BackendRerouteService, protected modalService: NgbModal) {}

  getButtons(item: OpenPurchaseOrders): Observable<ActionButton<OpenPurchaseOrders>[]> {
    return of(this.base);
  }
}
