import { Component, OnChanges, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ListItemRendererComponent } from 'gung-list';
import { Subject, Subscription } from 'rxjs';
import { PimConcept } from '../../../services/pim-concept-list-config/pim-concept-list-config.service';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';

@Component({
  selector: 'lib-pim-concept-grid-view',
  templateUrl: './pim-concept-grid-view.component.html',
  styleUrls: ['./pim-concept-grid-view.component.css']
})
export class PimConceptGridViewComponent extends ListItemRendererComponent<PimConcept[]> implements OnInit, AfterViewInit {
  public selectIds: string[];

  constructor(protected route: ActivatedRoute) // protected productSelectionService: ProductSelectionService
  {
    super();
  }

  ngOnInit() {
    const selectIds = this.route.snapshot.queryParamMap.get('select');
    if (selectIds && selectIds.trim() !== '') {
      this.selectIds = selectIds.split(',');
      // this.productSelectionService.select(product);
    }
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  /**
   * Used to select the init selection
   *  - Better solution is use productSelectionService.select()
   */
  checkSelected(pim: PimConcept) {
    const selected = this.selectIds.findIndex(id => id === pim.id);
    if (selected > -1) {
      this.selectIds.splice(selected, 1);
    }
    return selected > -1;
  }
}
