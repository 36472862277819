import { Location } from '@angular/common';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PasswordResetService } from '../../services/password-reset.service';

@Component({
  selector: 'lib-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  @Output()
  returnToLogin = new EventEmitter<void>();

  routeToLogin = false;

  forgotPasswordForm: FormGroup = this.formBuilder.group({
    email: ['', Validators.required]
  });

  sentRequest = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected passwordResetService: PasswordResetService,
    protected location: Location
  ) {}

  ngOnInit() {
    const url = this.location.path();
    if (url && url === '/forgot-password') {
      this.routeToLogin = true;
    }
  }

  onSubmit() {
    this.forgotPasswordForm.controls.email.setErrors(null);
    this.forgotPasswordForm.disable();
    const { value } = this.forgotPasswordForm.controls.email;
    const domain = location.origin;
    this.passwordResetService.sendPasswordResetToken(value, domain).subscribe(res => {
      if (!res) {
        // an error occured
        this.forgotPasswordForm.enable();
        this.forgotPasswordForm.controls.email.setErrors({
          badEmail: true
        });
        return;
      }
      if (res && res.error && res.users === 'MULTIPLE') {
        // an error occured
        this.forgotPasswordForm.enable();
        this.forgotPasswordForm.controls.email.setErrors({
          multipleEmails: true
        });
        return;
      }
      this.sentRequest = true;
    });
  }
}
