import { HttpClient, HttpResponse } from '@angular/common/http';
import { PimTemplateProperties } from 'gung-list';
import { debounceTime, distinctUntilChanged, filter, Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs';
import { Product } from '../models';
import { MetadataService } from '../services/metadata/metadata.service';
import trim from 'lodash/trim';
import {
  gungAddRemoveSpinner as gungAddRemoveSpinnerCommon,
  gungCheckAndDownloadFile as gungCheckAndDownloadFileCommon,
  gungGetPropertyOfObject as gungGetPropertyOfObjectCommon,
  gungSetPropertyOfObject as gungSetPropertyOfObjectCommon,
  gungGetUrlFormated as gungGetUrlFormatedCommon,
  gungComparatorHelper as gungComparatorHelperCommon,
  gungFilterParamStrToObj as gungFilterParamStrToObjCommon,
  gungCheckFileExistsLocally as gungCheckFileExistsLocallyCommon,
  gungColorToHsl as gungColorToHslCommon,
  gungTypeaheadSearchStreamFn as gungTypeaheadSearchStreamFnCommon,
  mediaEmbed as mediaEmbedCommon,
 } from 'gung-common';

/**
 * Gung function to add/remove spinner to HTML element (eg btn)
 * input the $event in the HTML and pass element.target in the TS
 * @param htmlElement html element where the spinner will appear
 * @returns true if spinner added; false if spinner removed
 */
export function gungAddRemoveSpinner(htmlElement: HTMLElement, showConsoleLogs?: boolean): boolean {
  return gungAddRemoveSpinnerCommon(htmlElement, showConsoleLogs);
}

/**
 * Gung function to check if file exist before download in one request
 * @param http HttpClient inicialized in the constructor
 * @param url request endpoint
 * @param filename used if the endpoint don't return the filename
 * @param showConsoleLogs enable console logs
 * @returns true if file exists and downloaded: false if don't exists or error
 */
export function gungCheckAndDownloadFile(
  http: HttpClient,
  url: string,
  filename: string = 'export',
  showConsoleLogs?: boolean
): Promise<boolean> {
  return gungCheckAndDownloadFileCommon(http, url, filename, showConsoleLogs);
}
/**
 * Returns the value for a property of a product
 * @param templateProp Pim template propertie
 * @param product Product to get value
 * @returns value
 */
export function gungGetPropertyOfProduct(
  templateProp: PimTemplateProperties,
  product: Product,
  currentLang?: string,
  metadataService?: MetadataService,
  fullProduct?: boolean
): string {
  const fields = templateProp.path.split('.');
  if (!product || !fields || fields.length === 0) {
    return;
  }

  if (fields[0] === 'i18n' && !['en', 'se', 'dk', 'de'].includes(fields[1])) {
    fields.splice(1, 0, currentLang || 'en');
  }

  // Get field form object if exist
  let obj = gungGetPropertyOfObject(fields.join('.'),  Object.assign({}, fullProduct ? product : product.extra));
  /*
  const getField = (obje: object, field: string) => {
    if (field.includes('[') && field.includes(']')) {
      // Used when propr have brakets, e.g. table[field]
      const idx = field.slice(field.indexOf('[') + 1, field.indexOf(']'));
      field = field.slice(0, field.indexOf('['));
      if (!obje.hasOwnProperty(field)) {
        return null;
      }
      return obje[field][Number(idx)] || obje[field][idx];
    }
    if (!obje.hasOwnProperty(field)) {
      return null;
    }
    return obje[field];
  };

  // Get property from object
  let obj: any = Object.assign({}, product.extra);
  for (const field of fields) {
    if (!obj) {
      break;
    }
    obj = getField(obj, field);
  }
  */
 if (obj !== undefined && obj !== null) {
   if (Array.isArray(obj) && obj.length > 0) {
     obj = obj[0];
    }

    if (templateProp.type === 'text') {
      return obj.description || obj;
    }
    if (templateProp.type === 'metadata') {
      if (obj.hasOwnProperty('description')) {
        // PIM metadata
        return obj.description;
      }
      if (templateProp.metaReference) {
        return (
          metadataService.getMetadataValue(templateProp.metaReference.table, templateProp.metaReference.field, obj) ||
          obj
        );
      }
      if (templateProp.metadata) {
        if (templateProp.metadata.split('.').length === 2) {
          const splitmeta = templateProp.metadata.split('.');
          const metaTable = splitmeta[0];
          const metaField = splitmeta[1];
          return metadataService.getMetadataValue(metaTable, metaField, obj) || obj;
        }
        return metadataService.getMetadataValue(templateProp.metadata, 'description', obj) || obj;
      }
    }
    return obj;
  }

  return null;
}

/**
 * Return the property from the path of the object.
 * Similar to GungExtraUtils.getDotSeparatedMapValue() in backend.
 * @param path
 * @param object
 * @returns
 */
export function gungGetPropertyOfObject(
  path: string,
  object: any
): any {
  return gungGetPropertyOfObjectCommon(path, object)
}

/**
 * Set the property from the path of the object.
 * Similar to GungExtraUtils.setDotSeparatedMapValue() in backend.
 * @param object
 * @param path
 * @param value
 * @returns
 */
 export function gungSetPropertyOfObject(
  object: any,
  path: string,
  value: any,
  forceDelete?: boolean
): boolean {
  return gungSetPropertyOfObjectCommon(object, path, value, forceDelete);
}

/**
 * Returns url and queryParams to use in HTML [routerLink]="link" [queryParams]="queryParams"
 * @param link object {url: string, queryParams?: {[s: string]: any}}
 * @param showConsoleLogs enable console logs
 * @returns object {url: string, queryParams?: {[s: string]: any}}
 */
export function gungGetUrlFormated(
  link: { url: string; queryParams?: { [s: string]: any } },
  showConsoleLogs?: boolean
): { url: string; queryParams?: { [s: string]: any } } {
  return gungGetUrlFormatedCommon(link, showConsoleLogs);
}

/**
 * Compare two value in ASC or DESC
 * @param aValue Value or Array of values
 * @param bValue Value to compare
 * @param ascSort ascending (1) or descending (-1)
 * @returns number (0 equal, 1, -1)
 */
export function gungComparatorHelper(aValue: any, bValue: any, ascSort: -1 | 1 | (1 | -1)[], sortList?: any[], orderStringByNumber?: boolean): -1 | 0 | 1 | number {
  return gungComparatorHelperCommon(aValue, bValue, ascSort, sortList, orderStringByNumber);
}

const FILTER_PARAM_SPLIT_STRING1 = '__|__'; // BETWEEN FILTERS
const FILTER_PARAM_SPLIT_STRING2 = '__:__'; // BETWEEN FILTER NAME AND SELECTIONS
const FILTER_PARAM_SPLIT_STRING3 = '_____'; // BETWEEN FILTER SELECTIONS

/**
 * Extract the special encoding from the string
 * @param paramMapStr this.route.snapshot.queryParams
 * @returns object with the filters
 */
export function gungFilterParamStrToObj(paramMapStr: string | null): { [filterName: string]: string[] } {
  return gungFilterParamStrToObjCommon(paramMapStr);
}

/**
 * gungCheckFileExistsLocally - check if the file exist on the project
 * @param filePath string of the file path
 * @returns true if existe false if not
 */
export function gungCheckFileExistsLocally(
  filePath: string,
  httpClient: HttpClient,
  showConsoleLogs?: boolean
): Observable<boolean> {
  return gungCheckFileExistsLocallyCommon(filePath, httpClient, showConsoleLogs);
}

/**
 * Calculate HSL from color (used in theming)
 * @param color
 * @returns
 */
export function gungColorToHsl(color: string): any[] {
  return gungColorToHslCommon(color);
}

/**
 * Use this to call the autocomplete
 * @param text$
 * @param inputList
 * @param getField
 * @param minLength
 * @param maxOutput
 * @param debounceTimeValue
 * @returns
 */
export function gungTypeaheadSearchStreamFn(
    text$: Observable<string>,
    inputList: any[],
    getField: ((a: any) => string) | ((a: any) => string)[] = (s: any) => { return s; },
    minLength = 2,
    maxOutput = 10,
    debounceTimeValue = 200
): Observable<any[]> {
  return gungTypeaheadSearchStreamFnCommon(text$, inputList, getField, minLength, maxOutput, debounceTimeValue);
}


/* compare two objects */
export function object_equals(obj1, obj2) {
  const x = clearEmpties(JSON.parse(JSON.stringify(obj1)));
  const y = clearEmpties(JSON.parse(JSON.stringify(obj2)));
  if (x === y) {
    return true;
  }
  if (!(x instanceof Object) || !(y instanceof Object)) {
    return false;
  }
  if (x.constructor !== y.constructor) {
    return false;
  }
  for (const p in x) {
    if (!x.hasOwnProperty(p)) {
      continue;
    }
    if (!y.hasOwnProperty(p)) {
      return false;
    }
    if (x[p] === y[p]) {
      continue;
    }
    if (typeof x[p] !== 'object') {
      return false;
    }
    if (!object_equals(x[p], y[p])) {
      return false;
    }
  }
  for (const p in y) {
    if (y.hasOwnProperty(p) && !x.hasOwnProperty(p)) {
      return false;
    }
  }
  return true;
}

/**
 *
 * @param o object
 * @returns  object without empties values
 */

export function clearEmpties(o) {
  for (const k in o) {
    if (!o[k] || typeof o[k] !== 'object') {
      continue; // If null or not an object, skip to the next iteration
    }
    if (o[k] === null || o[k] === '') {
      delete o[k];
    }

    // The property is an object
    clearEmpties(o[k]); // <-- Make a recursive call on the nested object
    if (Object.keys(o[k]).length === 0) {
      delete o[k]; // The object had no properties, so delete that property
    }
  }
  return o;
}

/**
 * Use this function to validate emails when not using Angular Forms. This validation complies with the RFC 5322 Format and should validate most emails.
 * @param email string value
 * @returns true if provided email is valid, else returns false
 */
export function gungValidateEmail(email: string): boolean {
  let regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email);
}

/**
 * Use to get the embed html/url from a video url
 * @param url
 * @returns
 */
export function mediaEmbed(url: string, extraClass?: string, width?: string, height?: string): {url: string; embedUrl: string; embedHtml: string; } {
  return mediaEmbedCommon(url, extraClass, width, height);
}
