import { Component, OnDestroy, OnInit } from '@angular/core';
import { CachesService } from '../../../services/caches/caches.service';
import { Cache } from '../../../models/cache';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'lib-manage-caches',
  templateUrl: './manage-caches.component.html',
  styleUrls: ['./manage-caches.component.css']
})
export class ManageCachesComponent implements OnInit, OnDestroy {
  cachesList: Cache[] = [];

  keysShownMap: { [cacheName: string]: boolean } = {}; // Track keys visibility

  unsubscribe: Subject<boolean> = new Subject<boolean>();

  constructor(protected cachesService: CachesService) {}

  ngOnInit() {
    this.loadCache();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  loadCache() {
    this.cachesService
      .getCaches()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(caches => {
        this.cachesList = caches;
        this.cachesList.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  clearCache(name: string) {
    this.cachesService
      .getClearCache(name)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(caches => {
        this.cachesList = caches.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  clearAllCache() {
    this.cachesService
      .getClearAllCache()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(caches => {
        this.cachesList = caches.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  deleteCacheKey(cacheName: string, key) {
    this.cachesService
      .getClearCacheKey(cacheName, key)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(caches => {
        this.cachesList = caches.sort((a, b) => a.name.localeCompare(b.name));
      });
  }

  // Function to toggle keys visibility
  toggleKeysVisibility(cacheName: string) {
    this.keysShownMap[cacheName] = !this.keysShownMap[cacheName];
  }
}
