import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CartRow } from '../../state/cart/types';
import { SavedCart } from '../../services/saved-cart-config/saved-cart-config.service';
import { SaveCartModalConfigService } from '../../services/save-cart-modal-config/save-cart-modal-config.service';
import { Product } from '../../models';
import { PriceService } from '../../services/price/price.service';
import { CartRowPrice } from '../../models/price';
import { ProductInputQuantityConfigService } from '../../services/product-input-quantity-config/product-input-quantity-config.service';
@Component({
  selector: 'lib-saved-cart-product-modal',
  templateUrl: './saved-cart-product-modal.component.html',
  styleUrls: ['./saved-cart-product-modal.component.css']
})
export class SavedCartProductModalComponent implements OnInit {
  delegate: SaveCartModalConfigService;
  editRows: CartRow[] = [];
  prices: CartRowPrice[] = [];
  product: Product;
  productSelected = false;
  minQty = 1;
  step = 1;
  allowManualQtyInputOutsideStepSizes = false;
  public quantity: number;

  @Input()
  savedCart: SavedCart;

  constructor(
    public activeModal: NgbActiveModal,
    protected priceService: PriceService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService
  ) {}

  ngOnInit(): void {
  }


  selectProduct(product: Product, row: CartRow) {
    this.minQty = this.productInputQuantityConfigService.getMinimumOrderQuantity(product.id, product);
    this.step = this.productInputQuantityConfigService.getStepAmount(product.id, product);
    this.allowManualQtyInputOutsideStepSizes =
      this.productInputQuantityConfigService.getAllowManualQtyInputOutsideStepSizes(product.id, product);
    if (row) {
      this.quantity = row.qty;
    } else {
      this.quantity = Math.max(this.minQty, this.step);
    }
    
    row.productId = product.id;
    row.extra = product.extra;
    row.extra.cartOrder = this.savedCart.data.rows.length;
    row.qty = this.quantity;
    this.productSelected = true;
  }

  addNewRow() {
    this.editRows.push({
      extra: {},
      productId: '',
      qty: 1,
      timeAdded: undefined,
      timeUpdated: undefined
    });
  }

  removeRow(index: number) {
    this.editRows.splice(index, 1);
  }

  setQuantity(value: number, row: CartRow): void {
    const rowIndex = this.editRows.findIndex(editRow => editRow.productId === row.productId);
    this.editRows[rowIndex].qty = value;
  }
  submitRows() {
    const validRows = this.editRows.filter(row => row.productId);
    this.savedCart.data.rows.push(...validRows);
    this.activeModal.close({changed: true});
  }

  productSearchFormatter(result: Product) {
    return result.id + ' - ' + result.name;
  }

}
