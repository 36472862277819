import { Component, OnInit } from '@angular/core';
import { ResetPasswordComponent } from '../reset-password.component';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { PasswordResetService } from '../../../services/password-reset.service';
import { BackendFeatureService } from '../../../services/backend-feature.service';
import { PasswordPolicyService } from '../../../services/password-policy.service';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';
import { first, forkJoin, mergeMap, of } from 'rxjs';
import { LoginConfigService } from '../../../services/login-config/login-config.service';

@Component({
  selector: 'lib-fashion-reset-password',
  templateUrl: './fashion-reset-password.component.html',
  styleUrls: ['./fashion-reset-password.component.css']
})
export class FashionResetPasswordComponent extends ResetPasswordComponent implements OnInit {
  public backgroundImage: string = this.navbarConfigService.getBackgroundLoginUrl();
  public logoUrl: string = this.navbarConfigService.getLogoLoginUrl();
  public videoBackground: string;

  constructor(
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected passwordResetService: PasswordResetService,
    protected backendFeatureService: BackendFeatureService,
    protected passwordPolicyService: PasswordPolicyService,
    protected loginConfigService: LoginConfigService,
    protected navbarConfigService: NavbarConfigService
  ) {
    super(
      route,
      formBuilder,
      passwordResetService,
      backendFeatureService,
      passwordPolicyService
    );
  }

  ngOnInit() {
    super.ngOnInit();

    this.videoBackground = this.loginConfigService.getVideoBackground();

    this.navbarConfigService
      .getBackgroundLoginUrlObservable()
      .pipe(
        first(),
        mergeMap(backgroundImage =>
          forkJoin({
            backgroundImage: of(backgroundImage),
            logoLoginUrl: this.navbarConfigService.getLogoLoginUrlObservable().pipe(first())
          })
        )
      )
      .subscribe(({ backgroundImage, logoLoginUrl }) => {
        if (backgroundImage) {
          this.backgroundImage = backgroundImage;
        }
        if (logoLoginUrl) {
          this.logoUrl = logoLoginUrl;
        }
      });
  }
}
