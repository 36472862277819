import { AfterViewInit, Component, Input, OnChanges, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StandardStatisticsData } from '../../../models/statistics';

export interface SummaryData {
  key: string;
  elements: StandardStatisticsData[];
  orderCount: number;
  value: number;
}

@Component({
  selector: 'lib-order-analytics-chart-table',
  templateUrl: './order-analytics-chart-table.component.html',
  styleUrls: ['./order-analytics-chart-table.component.css']
})
export class OrderAnalyticsChartTableComponent implements OnInit, OnChanges, AfterViewInit {
  @Input()
  data: StandardStatisticsData[];

  @Input()
  groupBy: string;

  @Input()
  tableOnly: boolean;

  @Input()
  heading: string;

  barChartData: ChartDataset[];
  barChartLabels: BaseChartDirective['labels'] = [];
  barChartOptions: any = {}; // todo
  barChartLegend = false;
  barChartType: ChartType = 'bar';

  summaryData: SummaryData[];
  summaryTotals;

  @ViewChild(MatSort) sort: MatSort;
  displayedColumns = [];

  dataSource = new MatTableDataSource();
  searchData: Subject<Event> = new Subject();

  loading = true;

  constructor(protected changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    this.barChartOptions = {
      backgroundColor: 'rgba(206,212,218, 0.6)',
      hoverBackgroundColor: 'rgba(206,212,218, 0.8)'
    };
    this.searchData.pipe(debounceTime(100)).subscribe(search => {
      // Need to cast it
      const element = search.target as HTMLInputElement;
      const searchTerm = element.value;
      this.dataSource.data = this.summaryData.filter(item => {
        // If MultiDimension
        if (!!item.elements[0].productModelId) {
          return (
            item.key.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.elements[0].productModelId.includes(searchTerm)
          );
        } else {
          // If not MultiDimension
          return (
            item.key.toLowerCase().includes(searchTerm.toLowerCase()) || item.elements[0].productId.includes(searchTerm)
          );
        }
      });
    });
  }

  ngOnChanges() {
    const groupedData = this.groupData();
    const groupedDataValue = groupedData.map(element => {
      element.value = Math.round(
        element.elements.reduce((acc: number, curr: StandardStatisticsData) => {
          return acc + curr.orderRowTotalValueCurrency;
        }, 0)
      );
      element.count = Math.round(
        element.elements.reduce((acc: number, curr: StandardStatisticsData) => {
          return acc + curr.orderRowTotalQuantity;
        }, 0)
      );
      return element;
    });

    const chartData = [];
    const chartLabels = [];
    groupedDataValue.forEach(element => {
      chartData.push(element.value);
      chartLabels.push(element.key);
    });
    this.barChartData = [
      {
        data: chartData
      }
    ];
    this.barChartLabels = chartLabels;

    if (this.heading === 'ORDERS') {
      this.displayedColumns = ['key', 'value', 'count'];
    } else if (this.heading === 'PRODUCTS') {
      this.displayedColumns = ['productId', 'key', 'value', 'count', 'orderCount'];
    } else {
      this.displayedColumns = ['key', 'value', 'count', 'orderCount'];
    }

    this.buildSummaryData(this.groupBy === 'productName' ? groupedDataValue : groupedDataValue.reverse());

    this.loading = false;
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.sort.sort({ id: 'value', start: 'desc', disableClear: true });
    this.changeDetectorRef.detectChanges();
  }

  protected groupData(): { [key: string]: StandardStatisticsData[] | any }[] {
    const result = [];
    const groupedData = this.data.reduce((acc, curr) => {
      if (this.groupBy === 'orderCreationDateYear') {
        const quarterKey = 'orderCreationDateYearQuarter';
        const year = curr[quarterKey].split('-')[0];
        (acc[year] = acc[year] || []).push(curr);
      } else {
        (acc[curr[this.groupBy]] = acc[curr[this.groupBy]] || []).push(curr);
      }
      return acc;
    }, {});
    for (const key of Object.keys(groupedData)) {
      result.push({
        key,
        elements: groupedData[key]
      });
    }

    result.sort((p1, p2) => {
      const key1: string = p1.key;
      const key2: string = p2.key;
      return key1.localeCompare(key2);
    });

    return result;
  }

  protected buildSummaryData(grouped: { [key: string]: any }) {
    const summaryTotals = {
      value: 0,
      count: 0,
      orderCount: 0
    };

    this.summaryData = grouped.map(element => {
      const orderCount = new Set();
      element.elements.forEach((e: StandardStatisticsData) => {
        orderCount.add(e.orderId);
      });
      element.orderCount = orderCount.size;

      summaryTotals.value += element.value;
      summaryTotals.count += element.count;
      summaryTotals.orderCount += element.orderCount;

      return element;
    });

    this.summaryTotals = summaryTotals;
    this.dataSource.data = this.summaryData;
  }
}
