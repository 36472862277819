import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PriceConfigService } from '../../../../services/price-config/price-config.service';
import { NavbarCartService } from '../../../../services/navbar-cart/navbar-cart.service';
import { CartRow } from '../../../../state/cart/types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'lib-context-total-price',
  templateUrl: './context-total-price.component.html',
  styleUrls: ['./context-total-price.component.css']
})
export class ContextTotalPriceComponent implements OnInit, OnDestroy {
  @Input()
  cartRows: CartRow[];

  priceHTML: string;

  protected unsubscribe: Subject<void> = new Subject();

  constructor(protected navbarCartService: NavbarCartService, protected priceConfigService: PriceConfigService) {}

  ngOnInit() {
    this.navbarCartService
      .getContents()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contents => {
        let price = 0;
        let priceAfterDiscount = 0;
        let currencyCode;
        this.cartRows.forEach(cartRow => {
          let row = contents.rowsMapped[cartRow.productId + (cartRow.productPartialId ? '_' + cartRow.productPartialId : '')] || contents.rowsMapped[cartRow.productId + '_' + cartRow.productId +'_0'] || contents.rowsMapped[cartRow.productId + '_0'];
          if (!row) {
            return;
          }
          price += row.price.cartRowTotalPrice.value;
          priceAfterDiscount += row.price.cartRowTotalPriceInclRowDiscount.value;
          currencyCode = row.price.cartRowTotalPrice.currencyCode;
        });
        this.priceHTML = this.priceConfigService.getCartPriceTotalHTML(price, priceAfterDiscount, currencyCode);
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
