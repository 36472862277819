import { Injectable } from '@angular/core';
import { GungImageBaseUrl } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class SelectProductImageSizeExportConfigService {
  public sizeOptions: {
    id: number;
    name: string;
    startUrl: string;
    imageOptions: string;
    transformations: string;
    format: string;
    forceBackgroundColor?: boolean;
  }[] = [
      {
        id: 0,
        name: 'ORIGINAL',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: '',
        transformations: '',
        format: ''
      },
      {
        id: 1,
        name: 'PNG_800x800',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/800x800/',
        transformations: 'filters:format(png)',
        format: 'png'
      },
      {
        id: 2,
        name: 'PNG_1600x1600',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/1600x1600/',
        transformations: 'filters:format(png)',
        format: 'png'
      },
      {
        id: 3,
        name: 'PNG_4000x4000',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/4000x4000/',
        transformations: 'filters:format(png)',
        format: 'png'
      },
      {
        id: 4,
        name: 'JPG_800x800',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/800x800/',
        transformations: 'filters:format(jpg)',
        format: 'jpg'
      },
      {
        id: 5,
        name: 'JPG_1600x1600',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/1600x1600/',
        transformations: 'filters:format(jpg)',
        format: 'jpg'
      },
      {
        id: 6,
        name: 'JPG_4000x4000',
        startUrl: GungImageBaseUrl+'/',
        imageOptions: 'fit-in/4000x4000/',
        transformations: 'filters:format(jpg)',
        format: 'jpg'
      }
    ];
}
