import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Customer } from '../../models';
import { AppState } from '../../state/state.module';
import { Store } from '@ngrx/store';
import { SelectCustomer } from '../../state/customer/actions';
import { skipWhile } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SelectedCustomerService {
  constructor(protected store: Store<AppState>) {}

  getSelectedCustomer(): Observable<Customer | null> {
    return this.store.select(state => state.customer.selectedCustomer);
  }

  selectCustomer(customerId: string): void {
    this.store.dispatch(
      new SelectCustomer({
        customerId
      })
    );
  }
}
