import { Component, Input, OnInit } from '@angular/core';
import { WhiteboardActionsConfigService } from '../../services/whiteboard-actions-config.service';
import { WhiteboardAction } from '../../types';
import { first } from 'rxjs/operators';
import { WhiteboardGridConfigService } from '../../services/whiteboard-grid-config.service';

@Component({
  selector: 'lib-whiteboard-actions',
  templateUrl: './whiteboard-actions.component.html',
  styleUrls: ['./whiteboard-actions.component.css']
})
export class WhiteboardActionsComponent implements OnInit {
  actions: WhiteboardAction[] = [];

  constructor(
    public configService: WhiteboardGridConfigService,
    protected whiteboardActionService: WhiteboardActionsConfigService
  ) {}

  ngOnInit(): void {
    this.whiteboardActionService
      .getActions()
      .pipe(first())
      .subscribe(actions => {
        this.actions = actions;
      });
  }
}
