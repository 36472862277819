import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval, Subscription } from 'rxjs';
import { first, startWith, switchMap } from 'rxjs';
import { SimplifiedOrderContext } from '../../models/simplifiedOrderContext';
import { OrderContextService } from '../../services/order-context.service';

@Component({
  selector: 'lib-send-counting-transaction',
  templateUrl: './send-counting-transaction.component.html',
  styleUrls: ['./send-counting-transaction.component.css']
})
export class SendCountingTransactionComponent implements OnInit {
  public context?: SimplifiedOrderContext;

  timeInterval: Subscription;
  public error?: any;

  constructor(protected orderContextService: OrderContextService, protected router: ActivatedRoute) {}

  ngOnInit() {
    const contextId = this.router.snapshot.params.contextId;

    this.timeInterval = interval(1000)
      .pipe(
        startWith(0),
        switchMap(() => this.orderContextService.getSimplifiedOrderContext(contextId)),
        first(e => e.finalized)
      )
      .subscribe(
        context => {
          this.context = context;
          console.log('context', context);
        },
        err => {
          console.log('err', err);
        }
      );
  }
}
