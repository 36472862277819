import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  QueryList,
  ViewChildren,
  OnChanges
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Observable, Subject, Subscription, first, forkJoin, of, switchMap, takeUntil } from 'rxjs';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import {
  ConfigService,
  ListItemRendererComponent,
  ListLayout,
  ListLayoutComponent,
  SearchField
} from '../types';
import { FilterListSearchComponent } from '../filter-list-search/filter-list-search.component';
import { GungListRouterEventsService } from '../services/gung-list-router-events.service';
import { FilterListLocationConfigService } from '../services/filter-list-location-config.service';

@Component({
  selector: 'lib-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.css']
})
export class SimpleListComponent<T> implements AfterViewInit, OnInit, OnChanges, OnDestroy {
  @Input()
  protected configService: ConfigService<T>;

  @ViewChildren(CustomHostDirective)
  private viewChild: QueryList<CustomHostDirective>;

  @Output()
  loaded = new EventEmitter<boolean>();

  itemsPerRow: number;
  navbarHeight: number;
  // stores the layout rendered
  private itemRendererComponent: Type<ListItemRendererComponent<T | T[]>>;
  public layouts: ListLayout<T>[];
  public defaultLayout: number;
  public currentLayoutIdx: number;

  public searches: Record<string, string> = {};

  public batchSizes: number[];
  public batchSize: number;
  public limit: number;

  public items: T[];
  public filteredItems: T[];
  public searchDisabled: boolean;
  public layoutDisabled: boolean;

  private getItemsSubscription: Subscription;

  public renderItemsSubject = new BehaviorSubject<T[]>([]);
  public renderItems: Observable<T[]> = this.renderItemsSubject.asObservable();

  private loadingVar = true;

  containerStyles: { [styleName: string]: any } = {};

  set loading(value: boolean) {
    this.loadingVar = value;
    this.loaded.emit(!value);
  }

  get loading() {
    return this.loadingVar;
  }

  defaultSortIdx: T[];

  protected unsubscribeRouterEvents = new Subject<void>();
  protected scrollToPosition: boolean = true;

  constructor(
    public route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    protected router: Router,
    protected gungListRouterEventsService: GungListRouterEventsService,
    protected filterListLocationConfigService: FilterListLocationConfigService
  ) {
    this.navbarHeight =
      document.getElementsByClassName('navbar').length > 0 &&
      (document.getElementsByClassName('navbar')[0] as any).offsetHeight;
  }

  adjustBottomMargin(margin: number) {
    this.containerStyles['margin-bottom'] = margin + 10 + 'px';
  }

  ngOnInit(): void {
    const routeSnapshot = this.route.snapshot;
    this.getItemsSubscription = this.configService.getItems(routeSnapshot).subscribe(ps => {
      this.items = ps;
      // This only needs to be a shallow copy.
      // Doing it the other way will copy all data to a string and back
      // The product list breaks totally at 5000 k products
      this.defaultSortIdx = ps.slice(); // JSON.parse(JSON.stringify(ps));
      this.loading = false;
      this.searchDisabled = !!this.configService.searchDisabled;
      this.layoutDisabled = !!this.configService.layoutDisabled;
      this.batchSizes = this.configService.getBatchSizes() ? this.configService.getBatchSizes(routeSnapshot) : [12];
      this.layouts = this.configService.getLayouts(routeSnapshot);
      this.defaultLayout =
        typeof this.configService.setPreviousLayout === 'function' ? this.configService.getPreviousLayout() : 0;

      this.limit = this.limit || (!!this.configService.getLimit ? this.configService.getLimit() : this.batchSizes[0]);
      this.batchSize = this.batchSize || this.batchSizes[0];

      // in case the item component layout rendered is of a type which does not exists in new layouts (in case in the subcription the layouts are updated)
      // then render new layout
      // this situation can happen for example when the getLayouts dependes on the assortment (some assortment products showAsList, others not)
      const layoutListItemComponents = this.layouts.map(layout => layout.getListItemComponent());
      if (
        (!!this.itemRendererComponent && !layoutListItemComponents.includes(this.itemRendererComponent)) ||
        this.defaultLayout
      ) {
        this.renderNewLayout();
      }

      this.filter();
    });
  }

  ngOnChanges(): void {
    this.inicializeItemsPerRow();
  }

  inicializeItemsPerRow(): void {
    if (this.configService.itemsPerRow) {
      this.itemsPerRow = this.configService.itemsPerRow;
    }
  }

  ngAfterViewInit(): void {
    const layouts = this.configService.getLayouts();
    this.currentLayoutIdx = this.defaultLayout || 0;
    const toRender = layouts[this.currentLayoutIdx];
    this.inicializeItemsPerRow();

    setTimeout(() => {
      this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
    }, 0);
  }

  ngOnDestroy(): void {
    if (!!this.getItemsSubscription) {
      this.getItemsSubscription.unsubscribe();
    }

    this.unsubscribeRouterEvents.next();
    this.unsubscribeRouterEvents.complete();
  }

  renderNewLayout() {
    const layouts = this.configService.getLayouts();
    this.currentLayoutIdx = this.defaultLayout || 0;
    const toRender = layouts[this.currentLayoutIdx];
    this.renderLayout(toRender.getListLayoutComponent(), toRender.getListItemComponent());
  }

  changeLayout(index: number) {
    if (typeof this.configService.setPreviousLayout === 'function') {
      this.configService.setPreviousLayout(index);
      this.defaultLayout = index;
    }
    this.currentLayoutIdx = index;
    this.renderLayout(this.layouts[index].getListLayoutComponent(), this.layouts[index].getListItemComponent());
  }

  renderLayout(
    layoutComponent: Type<ListLayoutComponent<T>>,
    itemComponent: Type<ListItemRendererComponent<T | T[]>>
  ): void {
    if (!this.viewChild || this.viewChild.length === 0) {
      return;
    }

    this.itemRendererComponent = itemComponent;
    this.changeDetectorRef.detectChanges();
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    const componentRef = containerRef.createComponent(layoutComponent);
    const typedComponent = componentRef.instance as ListLayoutComponent<T>;

    typedComponent.listItemRenderer = itemComponent;
    typedComponent.renderItems = this.renderItems;
    typedComponent.itemsPerRow = this.itemsPerRow ? 12 / this.itemsPerRow : 3;
    typedComponent.renderFinished = new EventEmitter<void>();
    typedComponent.renderFinished.pipe(takeUntil(this.unsubscribeRouterEvents)).subscribe(() => this.scrollFilterList());

    // draw search boxess
    const searchFieldContainerRef = this.viewChild.find(vc => vc.name === 'searchFields')?.viewContainerRef;
    if (searchFieldContainerRef) {
      searchFieldContainerRef.clear();
      const searchField: SearchField<T> = this.getDefaultSearchField(this.configService);
      const searchCompRef = searchFieldContainerRef.createComponent(searchField.getComponent());
      searchCompRef.instance.searchUpdated.subscribe(evt => this.setSearch(evt, searchField.getKey()));
      searchCompRef.instance.placeholder = searchField.getPlaceHolder();
      searchCompRef.instance.initSearchTerm = this.searches[searchField.getKey()];
      searchCompRef.instance.classes = searchField.componentClass?.();
    }

    this.changeDetectorRef.detectChanges();
    if (this.items) {
      this.updateRenderItems();
    }
  }

  filter(): void {
    this.filteredItems = this.getFilteredList(
      this.items,
      this.searches,
      this.configService
    );
    this.setFilteredItems(this.filteredItems);
    this.updateRenderItems();
  }

  updateRenderItems(): void {
    this.renderItemsSubject.next(this.getSlicedItems());
  }

  private getSlicedItems(): T[] {
    if (!this.filteredItems) {
      return [];
    }

    if (this.limit < 0) {
      return this.filteredItems;
    }
    const sliceSize = Math.min(this.limit, this.filteredItems.length);

    // use slice here to make a shallow copy of the elements required
    const slicedItems = this.filteredItems.slice(0, sliceSize);
    // create a deep copy of the slice
    return JSON.parse(JSON.stringify(slicedItems));
  }

  setFilteredItems(filteredItems: T[]): void {
    this.filteredItems = filteredItems;
  }

  setBatchSize(desiredSize: number) {
    this.limit = desiredSize;
    this.batchSize = desiredSize;
    this.updateRenderItems();
  }

  setSearch(searchString: string, searchKey?: string) {
    const parsedSearchKey = searchKey || this.getDefaultSearchField(this.configService).getKey();
    if (!searchString) {
      delete this.searches[parsedSearchKey];
    } else {
      this.searches[parsedSearchKey] = searchString;
    }

    this.filter();
  }

  loadMore(): void {
    this.limit = parseInt(this.limit as any, 10) + parseInt(this.batchSize as any, 10);
    this.updateRenderItems();
  }

  getNothingFoundTranslateTag(): string {
    if (!!this.configService.getNothingFoundTranslateTag) {
      return this.configService.getNothingFoundTranslateTag();
    } else {
      return 'NOTHING_FOUND_CONTACT_GUNG';
    }
  }

  getSearchGroupCss(): string {
    if (!!this.configService.getSearchGroupCss) {
      return this.configService.getSearchGroupCss();
    } else {
      return '';
    }
  }

  getLoadMoreCss(): string {
    if (!!this.configService.getLoadMoreCss) {
      return this.configService.getLoadMoreCss();
    } else {
      return 'btn-primary btn-block';
    }
  }

  getDefaultSearchField(configService: ConfigService<T>): SearchField<T> {
    return {
      getComponent: () => {
        return FilterListSearchComponent;
      },
      getKey: () => {
        return 'DEFAULT';
      },
      getSearchTerms: (item: T) => configService.getSearchTerms(item),
      getPlaceHolder: () => configService.getSearchPlaceholderTranslate?.() || 'SEARCH',
      componentClass: () => 'col-12'
    };
  }

  getFilteredList(
    items: T[],
    searchTerms: Record<string, string>,
    configService: ConfigService<T>
  ): T[] {
    let filteredItems = items;
    const searchFields = configService.getSearchFields?.() || [this.getDefaultSearchField(configService)];
    for (const key in searchTerms) {
      if (typeof key !== 'string') {
        continue;
      }
      const field = searchFields.find(sf => sf.getKey() === key);
      if (!field) {
        continue;
      }
      filteredItems = this.filterBySearchTerm(searchTerms[key], filteredItems, field);
    }
    return filteredItems;
  }

  filterBySearchTerm(searchTerm: string, items: T[], searchField: SearchField<T>): T[] {
    const queryTerms = searchField.mapSearchTerm?.(searchTerm) || this.defaultMapSearchTerm(searchTerm);
    return items.filter(item => {
      const itemTerms = searchField.getSearchTerms(item);
      if (searchField.filterBySearchTerms) {
        return searchField.filterBySearchTerms?.(queryTerms, itemTerms);
      }
      return this.defaultFilterByQueryTerms(queryTerms, itemTerms);
    });
  }

  defaultFilterByQueryTerms(queryTerms: string[], itemTerms: string[]): boolean {
    let hasHitAllTerms = true;
    queryTerms.forEach(queryTerm => {
      const locatedTerm = itemTerms.find(term => {
        if (term === null || term === undefined) {
          return false;
        }
        return term.toUpperCase().indexOf(queryTerm) >= 0;
      });

      hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
    });

    return hasHitAllTerms;
  }

  defaultMapSearchTerm(query: string): string[] {
    return query
      .split(' ')
      .map(e => e.trim().toUpperCase())
      .filter(e => e.length > 0);
  }

  protected scrollFilterList(): void {
    if (this.filteredItems?.length > 0 && this.scrollToPosition) {
      this.gungListRouterEventsService.getRouterNavigationStart().pipe(
        first(),
        switchMap(nav => forkJoin({
          nav: of(nav),
          savedLocation: this.filterListLocationConfigService.getSavedLocation(nav?.url?.split('?')?.[0] || '').pipe(first())
        }))
      ).subscribe(({ nav, savedLocation }) => {
        if (nav?.navigationTrigger === 'popstate' && savedLocation) {
          if (this.layouts[savedLocation.layout] && this.currentLayoutIdx !== savedLocation.layout) {
            this.changeLayout(savedLocation.layout);
          }

          window.scrollTo({ top: savedLocation.scrollY, behavior: 'smooth' });
          this.scrollToPosition = false;
        }
      });
    }
  }
}
