<lib-navbar
  *ngIf="showNavbar"
  class="navbar-login-component"
  (navbarHeight)="setContainerMarginTop($event)"
></lib-navbar>
<div class="login-gung-theme" [ngStyle]="containerStyles">
  <ng-template libCustomHost></ng-template>
</div>
<footer class="d-flex justify-content-center align-items-center">
  <a href="https://www.gung.se" class="gung-se-link">www.gung.se</a>
</footer>
