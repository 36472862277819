import { Injectable } from '@angular/core';
import { HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';

export interface DownloadFile {
  blob: Blob;
  fileName: string;
}

@Injectable({
  providedIn: 'root'
})
export class DownloadFileService {
  constructor() {}

  create(response: HttpResponse<ArrayBuffer>): DownloadFile {
    const headers = response.headers;

    let fileName;
    try {
      fileName = this.getFilenameFromContentDisposition(headers);
    } catch (error) {
      console.error(error);
      fileName = 'export';
    }

    const contentType = headers.get('Content-Type');
    const blob = new Blob([response.body], { type: contentType });
    return {
      blob,
      fileName
    };
  }

  private getFilenameFromContentDisposition(headers: HttpHeaders) {
    const contentDisposition = headers.get('Content-Disposition');

    if (contentDisposition == null) {
      throw new Error(`Server Error: Cannot retrieve file name.
      Either Content-Dispostion header is not available or it is not exposed.`);
    }

    const fileNameItem = contentDisposition
      .split(';')
      .map(item => item.trim())
      .find(item => item.toLowerCase().startsWith('filename='));
    if (fileNameItem == null) {
      throw new Error(`Server didn't send a filename`);
    }

    let fileName = fileNameItem.split('=')[1];
    if (fileName.startsWith('"')) {
      fileName = fileName.substring(1, fileName.length - 1);
    }
    return fileName;
  }

  download(file: DownloadFile): Observable<any> {
    // IE doesn't allow using a blob object directly as link href
    // instead it is necessary to use msSaveOrOpenBlob
    //@ts-ignore
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      //@ts-ignore
      window.navigator.msSaveOrOpenBlob(file.blob, file.fileName);
      return;
    }

    // Create a link pointing to the ObjectURL containing the blob.
    const data = window.URL.createObjectURL(file.blob);

    const link = document.createElement('a');
    link.href = data;
    link.download = file.fileName;
    // this is necessary as link.click() does not work on the latest firefox
    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 100);

    return of(null);
  }
}
