<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_AVAILABILITY_CONDITION</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div class="form-group">
        <label for="name" class="control-label" translate>CREATE_AVAILABILITY_CONDITION_NAME</label>
        <input class="form-control" type="text" name="name" id="name" [(ngModel)]="name" ngbAutofocus />
      </div>
    </div>

    <div class="col-12">
      <div class="form-group">
        <label for="expression" class="control-label" translate>CREATE_AVAILABILITY_CONDITION_EXPRESSION</label>
        <textarea
          class="form-control"
          type="text"
          name="expression"
          id="expression"
          [(ngModel)]="expressionString"
          rows="5"
        ></textarea>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-primary ml-auto" (click)="saveCondition()" translate>
    SAVE_AVAILABILITY_CONDITION
  </button>
</div>
