import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ListSortOption,
  SearchRequest,
  SearchResult,
  FastSearchLayout,
  FastsearchLayoutComponent
} from 'gung-list';
import { Product } from '../../models';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { Observable, of } from 'rxjs';
import { ProductFastsearchCardComponent } from '../../components/product-fastsearch-card/product-fastsearch-card.component';
import { ProductFastsearchDetailComponent } from '../../components/product-fastsearch-detail/product-fastsearch-detail.component';
import { FastSearchConfigService } from 'gung-list';
import { first, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductFastSearchListConfigService implements FastSearchConfigService<Product> {
  constructor(protected productService: ProductService, protected authService: AuthService) {}

  getLayout(): FastSearchLayout<Product> {
    return {
      getLayout: () => FastsearchLayoutComponent,
      getListItemComponent: () => ProductFastsearchCardComponent,
      getDetailItemComponent: () => ProductFastsearchDetailComponent
    };
  }

  getItems(
    terms: string[],
    skip: number,
    limit: number,
    skuLevel?: boolean,
    requireAvailability?: boolean
  ): Observable<SearchResult<Product>> {
    return this.authService.getCurrentUser().pipe(
      first(),
      mergeMap(user => {
        const searchRequest: SearchRequest = {
          terms,
          skip,
          limit,
          assortment: user.assortment,
          skuLevel,
          requireAvailability
        };
        return this.productService.getPagedProducts(searchRequest);
      })
    );
  }

  getShowMoreProductsRoute(): string {
    return '/products';
  }
}
