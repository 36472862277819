import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SupplierPurchaseOrder } from '../../../models';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-supplier-po-split-modal',
  templateUrl: './supplier-po-split-modal.component.html',
  styleUrls: ['./supplier-po-split-modal.component.css']
})
export class SupplierPoSplitModalComponent implements OnInit {

  isLoading: false;

  @Input()
  delegate: GungModalService;

  @Input()
  supplierPurchaseOrder: SupplierPurchaseOrder;
  originalSupplierPurchaseOrder: SupplierPurchaseOrder;
  newSupplierPurchaseOrder: SupplierPurchaseOrder;

  @ViewChild('eleInputQty') eleInputQty: HTMLInputElement;
  newInputQty: number;
  originalQty: number;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
    this.originalSupplierPurchaseOrder = JSON.parse(JSON.stringify(this.supplierPurchaseOrder));
    this.newSupplierPurchaseOrder = JSON.parse(JSON.stringify(this.supplierPurchaseOrder));

    this.originalQty = Number(this.supplierPurchaseOrder.extra.gungPurchaseLine.quantity);
    this.originalSupplierPurchaseOrder.extra.gungPurchaseLine.quantity = (this.originalQty - 1);
    this.newSupplierPurchaseOrder.extra.gungPurchaseLine.quantity = 1;
    this.newInputQty = 1;
  }

  inputChanged(qty: number) {
    if (qty >= this.originalQty) {
      this.newInputQty = qty = (this.originalQty - 1);
    }
    this.originalSupplierPurchaseOrder.extra.gungPurchaseLine.quantity = (this.originalQty - qty);
    this.newSupplierPurchaseOrder.extra.gungPurchaseLine.quantity = (qty);
  }

  split() {
    if (this.newInputQty && this.newInputQty > 0 && this.newInputQty < this.originalQty) {
      this.activeModal.close([
        this.originalSupplierPurchaseOrder,
        this.newSupplierPurchaseOrder
      ])
    }
    this.activeModal.dismiss('No quantity to split');
  }
}
