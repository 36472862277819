import { Component, OnInit, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { ListItemRendererComponent } from 'gung-list';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { PimMetadata, PimMetadataService } from '../../../services/pim-metadata/pim-metadata.service';
import { PimMetadataModalComponent } from '../pim-metadata-modal/pim-metadata-modal.component';

@Component({
  selector: 'lib-pim-metadata-card-list',
  templateUrl: './pim-metadata-card-list.component.html',
  styleUrls: ['./pim-metadata-card-list.component.css']
})
export class PimMetadataCardListComponent extends ListItemRendererComponent<PimMetadata[]> implements OnInit, AfterViewInit {
  showLanguages: string[];
  showField: string;

  constructor(
    protected ngbModal: NgbModal,
    protected pimMetadataService: PimMetadataService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {
    super();
  }

  ngOnInit(): void {
    this.showLanguages = this.pimMetadataService.translateLanguages;
    this.showField = this.pimMetadataService.translateField;
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  onCreate() {
    this.gungModalService.openPimMetadataModalComponent(false).then(
      result => {},
      reason => {}
    );
  }

  onEdit(metadata: PimMetadata) {
    this.gungModalService.openPimMetadataModalComponent(true, metadata).then(
      result => {},
      reason => {}
    );
  }

  onDelete(metadata: PimMetadata) {
    this.gungModalService
      .openConfirmYesNoModal(
        this.translationService.instant('DELETE'),
        this.translationService.instant('DELETE') + ' ' + metadata.name + '?',
        { size: 'sm' }
      )
      .then(result => {
        if (result) {
          this.pimMetadataService.deletePimMetadata(metadata.metadataId).subscribe(_ => {});
        }
      });
  }

  getTranslatedName(metadata: any, lang: string) {
    return metadata?.i18n?.[lang]?.[this.showField];
  }
}
