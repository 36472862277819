import { Component, OnInit } from '@angular/core';
import { ProductFastSearchListConfigService } from '../../services/product-fast-search-list-config/product-fast-search-list-config.service';

@Component({
  selector: 'lib-product-fast-search',
  template: ' <lib-fastsearch #productList [configService]="configService"></lib-fastsearch>',
  styleUrls: ['./product-fast-search.component.css']
})
export class ProductFastSearchComponent implements OnInit {
  constructor(public configService: ProductFastSearchListConfigService) {}

  ngOnInit() {}
}
