import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderFinderConfigService } from '../../services/order-finder-config/order-finder-config.service';

@Component({
  selector: 'lib-customer-order-finder',
  template: '<lib-filter-list [configService]="orderFinderConfigService"></lib-filter-list>',
  styleUrls: ['./customer-order-finder.component.css']
})
export class CustomerOrderFinderComponent {
  constructor(public orderFinderConfigService: OrderFinderConfigService, route: ActivatedRoute) {
    orderFinderConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
