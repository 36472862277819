import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { CustomerUserActionConfigService } from '../../services/customer-user-action-config/customer-user-action-config.service';
import { User } from '../../state/auth/types';

@Component({
  selector: 'lib-customer-user-card-list',
  templateUrl: './customer-user-card-list.component.html',
  styleUrls: ['./customer-user-card-list.component.css']
})
export class CustomerUserCardListComponent extends ListItemRendererComponent<User[]> implements OnInit, AfterViewInit {
  constructor(public actionConfig: CustomerUserActionConfigService) {
    super();
  }

  ngOnInit() {
    this.actionConfig.deleteEvent.subscribe(username => {
      this.data.splice(
        this.data.findIndex(user => user.username === username),
        1
      );
    });

    this.actionConfig.updateEvent.subscribe(u => {
      this.data.splice(
        this.data.findIndex(user => user.username === u.username),
        1,
        u
      );
    });
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
