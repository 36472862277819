import { Component, OnInit } from '@angular/core';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { NewsEditorConfigListService } from '../../../services/news/news-editor-config-list/news-editor-config-list.service';

@Component({
  selector: 'lib-news-editor-list',
  templateUrl: './news-editor-list.component.html',
  styleUrls: ['./news-editor-list.component.css']
})
export class NewsEditorListComponent implements OnInit {
  constructor(
    public newsEditorListConfigService: NewsEditorConfigListService,
    protected gungModalService: GungModalService
  ) {}

  ngOnInit() {}

  openAddNewsModal(): void {
    this.gungModalService.openEditorNewsModal(null);
  }
}
