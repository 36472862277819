import { Injectable } from '@angular/core';
import { ListLayoutMultipleComponent, ListLayout, ListSortOption, ConfigService, ConfigBaseFilter } from 'gung-list';
import { Observable } from 'rxjs';
import { CreationDateFilterService } from '../../filters/creation-date-filter/creation-date-filter.service';
import { Cart } from '../../models/cart';
import { SavedCartTableComponent } from '../../components/saved-cart-table/saved-cart-table.component';
import { CartsService } from '../carts/carts.service';
import { map } from 'rxjs';

export interface SavedCart extends Cart {
  customerId?: string;
}

@Injectable({
  providedIn: 'root'
})
export class SavedCartConfigService implements ConfigService<SavedCart> {
  constructor(protected cartsService: CartsService, protected creationDateFilterService: CreationDateFilterService) {}

  getItems(): Observable<SavedCart[]> {
    return this.cartsService.getSavedCartsFromSubject().pipe(
      map(carts =>
        carts.sort((a, b) => {
          if (new Date(a.creationDate) < new Date(b.creationDate)) {
            return 1;
          }
          if (new Date(a.creationDate) > new Date(b.creationDate)) {
            return -1;
          }
          return 0;
        })
      )
    );
  }

  getFilters(): ConfigBaseFilter<SavedCart>[] {
    return [this.creationDateFilterService];
  }

  getSortOptions(): ListSortOption<SavedCart>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<SavedCart>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => SavedCartTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getSearchTerms(item: SavedCart): string[] {
    const searchTerms = [];
    const savedCart = item as SavedCart;

    if (item.name) {
      searchTerms.push(item.name);
    }

    if (savedCart.customerId) {
      searchTerms.push(savedCart.customerId);
    }

    if (savedCart.extra?.customer?.name) {
      searchTerms.push(savedCart.extra.customer.name);
    }

    return searchTerms;
  }

  getItemId(item: SavedCart): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
