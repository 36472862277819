import { Injectable } from '@angular/core';
import { HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class RequestCacheService {
  cache = new Map();

  get(req: HttpRequest<any>): HttpResponse<any> | undefined {
    const url = req.urlWithParams;
    const cached = this.cache.get(url);

    if (!cached) {
      return undefined;
    }

    const headers = req.headers;
    const isExpired = this.getIsExpired(headers, cached.lastRead);
    if (isExpired) {
      return undefined;
    }

    return cached.response;
  }

  put(req: HttpRequest<any>, response: HttpResponse<any>): void {
    const url = req.urlWithParams;
    const headers = req.headers;
    const entry = { url, headers, response, lastRead: Date.now() };
    this.cache.set(url, entry);

    this.cache.forEach(cachedEntry => {
      const isExpired = this.getIsExpired(cachedEntry.headers, cachedEntry.lastRead);
      if (isExpired) {
        this.cache.delete(cachedEntry.url);
      }
    });
  }

  private getIsExpired(headers: HttpHeaders, lastRead: number): boolean {
    const headerMaxAge = headers.get('maxAge');
    if (!headerMaxAge) {
      return false;
    }
    // Max-age defined as seconds, time is milliseconds
    const maxAge = parseInt(headerMaxAge, 10) * 1000;

    const isExpired = lastRead < Date.now() - maxAge;

    return isExpired;
  }
}
