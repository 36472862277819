import { Component, OnDestroy, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { CartService } from '../../../../services/cart/cart.service';
import { Subject, debounceTime, first, forkJoin, map, of, pipe, switchMap, takeUntil } from 'rxjs';
import { CartListRow } from '../../../cart-list/cart-list.component';
import { GungFlow } from '../../../../state/flow/types';
import { GungFlowService } from '../../../../services/gung-flow/gung-flow.service';
import { ProductService } from '../../../../services/products/product.service';
import { CartRow } from '../../../../state/cart/types';
import { PriceService } from '../../../../services/price/price.service';
import { AvailabilityService } from '../../../../services/availability/availability.service';
import { Product, ProductMultiDimension } from '../../../../models/product';
import { CartRowPrice, Price } from '../../../../models/price';
import { Availability } from '../../../../models/availability';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { format, parse } from 'date-fns';
import { DateUtilService, gungSetPropertyOfObject, gungGetPropertyOfObject } from 'gung-common';
import {
  CartListCheckoutConfigService,
  CartListColumns
} from '../../../../services/cart-list-checkout-config/cart-list-checkout-config.service';
import { AuthService } from '../../../../services/auth/auth.service';
import { GungModalService } from '../../../../services/gung-modal/gung-modal.service';
import { isMultiDimensionProduct } from '../../../../utils/product-utils';
import { MetadataService } from '../../../../services/metadata/metadata.service';

export interface CartDataContext {
  isMultiDimension: boolean;
  image; // if multiDimension { image of primaryDimension } else { image of produt }
  id: string; // if multiDimension { model + primaryDimension } else { product.id }
  totals: {
    // if mulitDimension { totals for primary dimension } else { totals for product }
    qty: number;
    price: Price;
    discount: number;
  };
  related: RelatedInfo[];
}

// if multiDimension { contains all secondaryDimension product and related items } else { contains one element which is singleDimension product and related items }
export interface RelatedInfo {
  product: Product;
  cartRow: CartRow;
  availability: Availability;
  price: Price;
  cartRowPrice: CartRowPrice;
  deliveryDate;
  minDate;
}

@Component({
  selector: 'lib-checkout-cart-list',
  templateUrl: './checkout-cart-list.component.html',
  styleUrls: ['./checkout-cart-list.component.scss']
})
export class CheckoutCartListComponent extends CheckoutStepComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();
  loading = true;
  cartRows: CartRow[];
  public mappedData: CartListRow[];
  cartDataContext: CartDataContext[] = [];
  currentFlow: GungFlow;
  deliveryDate = new Date();
  isSales = false;
  products: Product[];
  availabilities: Availability[];
  prices: CartRowPrice[];
  columns: CartListColumns;
  isMultiDimension = false;
  hasMultidimentoProducts = false;
  public sizesVisible: any = {};
  cachedData: string[];
  public sortByOptions = this.cartListCheckoutConfig.sortOptions; // Used when sort is enabled in config
  public sortByFn: any = this.sortByOptions?.length > 0 && this.sortByOptions[0].value;

  constructor(
    protected cartService: CartService,
    protected gungFlowService: GungFlowService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService,
    public dateUtilService: DateUtilService,
    protected authService: AuthService,
    public cartListCheckoutConfig: CartListCheckoutConfigService,
    protected gungModalService: GungModalService,
    protected metadataService: MetadataService
  ) {
    super();
  }

  ngOnInit() {
    this.onNextBtnClicked.subscribe(_ => this.nextButtonClick());
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => (this.isSales = roles.filter(role => role.toUpperCase() === 'SALES').length > 0));
    this.getCurrentCartInfo();
    this.columns = this.cartListCheckoutConfig.cartListColumns;
  }

  getLabel() {
    return 'CART';
  }

  nextButtonClick() {
    this.stepDone.emit(this.checkout);
  }

  getCurrentCartInfo() {
    this.cartService
      .getCurrentCart()
      .pipe(debounceTime(500), takeUntil(this.unsubscribe))
      .subscribe(cart => {
        this.loading = true;
        this.checkout = {
          ...this.checkout,
          rows: cart.map(r => ({
            id: r.productId,
            partialId: r.productPartialId,
            targetStockId: r.targetStockId,
            quantity: r.qty,
            extra: r.extra || {},
            rownr: r.rownr
          }))
        };
        this.cartRows = cart;
        this.getProductsInfo();
      });
  }

  getProductsInfo() {
    const toGetIds = this.checkout.rows.map(c => c.id);
    let useCachedData = false;
    if (this.arrayEquals(toGetIds, this.cachedData)) {
      useCachedData = true;
    }
    this.gungFlowService
      .getSelectedFlow()
      .pipe(
        first(),
        takeUntil(this.unsubscribe),
        switchMap(flow =>
          forkJoin({
            products: useCachedData ? of(this.products) : this.productService.getProductsByIds(toGetIds).pipe(first()),
            flow: of(flow),
            prices: this.priceService.getCartRowPricesObservable(this.cartRows).pipe(first()),
            availabilities:
              flow.requireAvailability || flow.useAvailabilities
                ? useCachedData
                  ? of(this.availabilities)
                  : this.availabilityService.getAvailabilities(toGetIds).pipe(
                    first(),
                    map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))
                  )
                : of([])
          })
        )
      )
      .subscribe(({ products, flow, prices, availabilities }) => {
        this.cachedData = toGetIds;
        this.currentFlow = flow;
        this.products = products;
        this.prices = prices;
        this.availabilities = availabilities;
        this.updateCart();
      });
  }

  getMinDate(av: Availability): Date {
    if (!av) {
      return new Date();
    }
    if (av.currentAvailability > 0) {
      return new Date();
    }
    if (av.maxFutureStock === 0) {
      return new Date();
    }
    const valids = Object.keys(av.extra.availabilities).filter(entry => av.extra.availabilities[entry] > 0);
    const dateFormat = this.cartListCheckoutConfig.saveDateFormat;
    return valids.length > 0 ? this.dateUtilService.parseDate(valids[0], dateFormat) : new Date();
  }

  updateDeliveryDate(
    row: CartListRow,
    selectedDeliveryDate,
    ngbFormat = false,
    shouldSave = true
  ): { [s: string]: any } {
    if (ngbFormat) {
      selectedDeliveryDate.date = new Date(
        selectedDeliveryDate.year,
        selectedDeliveryDate.month - 1,
        selectedDeliveryDate.day
      );
    }
    const oldVal = row.deliveryDate || row.minDate;

    const deliveryDate = format(selectedDeliveryDate.date, this.cartListCheckoutConfig.saveDateFormat);

    if (oldVal === deliveryDate) {
      return;
    }

    let newExtra: { [s: string]: any };
    const deliveryDateField = this.cartListCheckoutConfig.deliveryDateField;
    if (!deliveryDate) {
      newExtra = row.cartRow.extra;
      gungSetPropertyOfObject(newExtra, deliveryDateField, undefined, true);
      return newExtra;
    }
    newExtra = {};
    gungSetPropertyOfObject(newExtra, deliveryDateField, deliveryDate);
    if (shouldSave) {
      this.cartService.setExtra(
        row.cartRow.productId,
        newExtra,
        row.cartRow.targetStockId,
        row.cartRow.productPartialId
      );
    }
    return newExtra;
  }

  formatNgbDate(date: NgbDate) {
    const dObj = this.dateUtilService.createDateFromNgbDate(date);
    return format(dObj, 'yyyy-MM-dd');
  }

  dateSelected(event) {
    const date = event.date;
    const bulkExtraOps = [];

    if (this.mappedData.length > 0) {
      for (const row of this.mappedData) {
        const dateField = this.cartListCheckoutConfig.deliveryDateField;

        let rowDate = gungGetPropertyOfObject(dateField, row.cartRow.extra) || row.deliveryDate || row.minDate;
        if (typeof rowDate === 'string') {
          rowDate = parse(rowDate, 'yyyy-MM-dd', new Date());
        } else if (rowDate instanceof NgbDate) {
          rowDate = new Date(rowDate.year, rowDate.month - 1, rowDate.day);
        }
        if (rowDate < date) {
          const dateFortmated = format(date, 'yyyy-MM-dd');
          row.deliveryDate = dateFortmated;
          let newExtra = {};
          gungSetPropertyOfObject(newExtra, dateField, dateFortmated);
          bulkExtraOps.push({
            productId: row.cartRow.productId,
            extra: newExtra,
            targetStockId: row.cartRow.targetStockId,
            productPartialId: row.cartRow.productPartialId
          });
        }
      }
      this.cartService.bulkSetExtra(bulkExtraOps);
    }
  }

  dateSelectedMultidimension(event) {
    const date = event.date;
    const bulkExtraOps = [];

    if (this.cartDataContext.length > 0) {
      const dateField = this.cartListCheckoutConfig.deliveryDateField;
      for (const context of this.cartDataContext) {
        for (const related of context.related) {
          let rowDate = gungGetPropertyOfObject(dateField, related.cartRow.extra) || related.deliveryDate || related.minDate;
          if (typeof rowDate === 'string') {
            rowDate = parse(rowDate, this.cartListCheckoutConfig.saveDateFormat, new Date());
          } else if (rowDate instanceof NgbDate) {
            rowDate = new Date(rowDate.year, rowDate.month - 1, rowDate.day);
          }
          if (rowDate < date) {
            const dateFortmated = format(date, this.cartListCheckoutConfig.saveDateFormat);
            related.deliveryDate = dateFortmated;
            let newExtra = {};
            gungSetPropertyOfObject(newExtra, dateField, dateFortmated);
            bulkExtraOps.push({
              productId: related.cartRow.productId,
              extra: newExtra,
              targetStockId: related.cartRow.targetStockId,
              productPartialId: related.cartRow.productPartialId
            });
          }
        }
      }
      this.cartService.bulkSetExtra(bulkExtraOps);
    }
  }

  restoreMinDate() {
    const bulkExtraOps = [];
    for (const row of this.mappedData) {
      const rowDate = new Date(row.minDate.year, row.minDate.month - 1, row.minDate.day);

      // --------- FC: Removed code to remove extra "deliveryDateField" (if you will allways set the extra next lines ?)
      const dateFortmated = format(rowDate, 'yyyy-MM-dd');

      row.deliveryDate = dateFortmated;
      let newExtra = {};
      gungSetPropertyOfObject(newExtra, this.cartListCheckoutConfig.deliveryDateField, dateFortmated);
      bulkExtraOps.push({
        productId: row.cartRow.productId,
        extra: newExtra,
        targetStockId: row.cartRow.targetStockId,
        productPartialId: row.cartRow.productPartialId
      });
    }
    if (bulkExtraOps.length > 0) {
      this.cartService.bulkSetExtra(bulkExtraOps);
    }
    this.deliveryDate = new Date();
  }

  restoreMinDateMultidimension() {
    const bulkExtraOps = [];
    for (const context of this.cartDataContext) {
      for (const related of context.related) {
        const rowDate = new Date(related.minDate.year, related.minDate.month - 1, related.minDate.day);
        const dateFortmated = format(rowDate, this.cartListCheckoutConfig.saveDateFormat);
        related.deliveryDate = dateFortmated;
        let newExtra = {};
        gungSetPropertyOfObject(newExtra, this.cartListCheckoutConfig.deliveryDateField, dateFortmated);
        bulkExtraOps.push({
          productId: related.cartRow.productId,
          extra: newExtra,
          targetStockId: related.cartRow.targetStockId,
          productPartialId: related.cartRow.productPartialId
        });
      }
    }
    if (bulkExtraOps.length > 0) {
      this.cartService.bulkSetExtra(bulkExtraOps);
    }
    this.deliveryDate = new Date();
  }

  setPrice(event: any, row: CartRow) {
    const targetPrice = this.cartListCheckoutConfig.transformPriceToERP(event.target.value);
    const fields = this.cartListCheckoutConfig.changePriceField.split('.');
    this.setExtraOnFields(row, fields, targetPrice);
    this.updatePrices();
  }

  updatePrices() {
    this.priceService
      .getCartRowPricesObservable(this.cartRows)
      .pipe(first())
      .subscribe(prices => {
        this.prices = prices;
        this.updateCart();
      });
  }

  setDiscount(event: any, row: CartRow) {
    const targetDiscount = this.cartListCheckoutConfig.transformDiscountToERP(event.target.value);
    const fields = this.cartListCheckoutConfig.changeDiscountField.split('.');
    this.setExtraOnFields(row, fields, targetDiscount);
    this.updatePrices();
  }

  setExtraOnFields(row: CartRow, fields: string[], value) {
    const newRow = JSON.parse(JSON.stringify(row));
    gungSetPropertyOfObject(newRow.extra, fields.join('.'), (value || value === 0) ? value : null, true);

    this.cartService.setExtra(row.productId, newRow.extra, row.targetStockId, row.productPartialId, true);
    this.updateCartRow(newRow);
  }

  updateCartRow(row: CartRow) {
    const indexUpdate = this.cartRows.findIndex(
      x => x.productId === row.productId && x.productPartialId === row.productPartialId
    );
    this.cartRows[indexUpdate] = row;
  }

  updateCart() {
    const multiDimension = this.products?.findIndex(p => p.productType === 'multiDimension');
    this.hasMultidimentoProducts = multiDimension > -1;
    this.cartDataContext = [];
    if (this.hasMultidimentoProducts && !this.cartListCheckoutConfig.forceSingleDimentionCart) {
      this.isMultiDimension = true;
      this.createMultidimentionCart();
    } else {
      this.createSingleDimentionCart();
    }
    this.loading = false;
  }

  createSingleDimentionCart(): void {
    this.mappedData = this.cartRows.map(cr => {
      const product = this.products.find(p => p.id === cr.productId);
      const price = this.prices.find(p => p.productId === cr.productId && p.productPartialId === cr.productPartialId);
      const av = this.availabilities.find(a => a.productId === cr.productId);
      const minDateJs: Date = this.getMinDate(av);
      let deliveryDate;
      const dateField = this.cartListCheckoutConfig.deliveryDateField;
      if (gungGetPropertyOfObject(dateField, cr.extra)) {
        const setDate = this.dateUtilService.parseDate(gungGetPropertyOfObject(dateField, cr.extra));
        deliveryDate = format(setDate, 'yyyy-MM-dd');
      }
      this.setFieldTodisplay(product);
      return {
        cartRow: cr,
        product,
        price,
        availability: av,
        minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate()),
        deliveryDate
      };
    });
  }

  createMultidimentionCart(): void {
    const bulkExtraOps = [];
    for (const cartRow of this.cartRows) {
      const product = this.products.find(p => p.id === cartRow.productId) as ProductMultiDimension;
      const isMultiDimension = isMultiDimensionProduct(product);
      const availability = this.currentFlow.useAvailabilities
        ? this.availabilities.find(a => a.productId === cartRow.productId)
        : null;
      const minDateJs: Date = this.getMinDate(availability) || new Date();
      let deliveryDate = format(minDateJs, 'yyyy-MM-dd');
      const price = this.prices.find(p => p.productId === cartRow.productId);
      const dateField = this.cartListCheckoutConfig.deliveryDateField;
      if (gungGetPropertyOfObject(dateField, cartRow.extra)) {
        const setDate = parse(gungGetPropertyOfObject(dateField, cartRow.extra), this.cartListCheckoutConfig.saveDateFormat, new Date());
        if (minDateJs && setDate > minDateJs) {
          deliveryDate = format(setDate, 'yyyy-MM-dd');
        }
      } else {
        // Set delivery date to cart rows
        const newExtra = this.updateDeliveryDate({ cartRow, price, product }, { date: minDateJs }, false, false);
        if (newExtra) {
          bulkExtraOps.push({
            productId: cartRow.productId,
            extra: newExtra,
            targetStockId: cartRow.targetStockId,
            productPartialId: cartRow.productPartialId
          });
        }
      }
      let added = false;
      if (isMultiDimension) {
        const element = this.cartDataContext.find(d => d.id === product.modelId + product.primaryDimension[0].id);
        if (element) {
          element.related.push({
            product,
            cartRow,
            availability: availability,
            price: price.cartRowUnitPrice,
            cartRowPrice: price,
            deliveryDate,
            minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate())
          });
          added = true;
        }
      }
      if (!added) {
        const id = isMultiDimension ? product.modelId + product.primaryDimension[0].id : product.id;
        this.cartDataContext.push({
          isMultiDimension,
          image: product.extra.images[0],
          id,
          totals: this.getCartProductTotals(id),
          related: [
            {
              product,
              cartRow,
              availability,
              price: price.cartRowUnitPrice, // single dimension id or secondary dimension di
              cartRowPrice: price,
              deliveryDate,
              minDate: new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate())
            }
          ]
        });
      }
    }
    if (bulkExtraOps.length > 0) {
      this.cartService.bulkSetExtra(bulkExtraOps);
    }
  }

  protected getCartProductTotals(
    id: string,
    skipId?: string,
    skipProductPartialId?: string
  ): { qty: number; price: Price; discount: number } {
    const result = {
      qty: 0,
      price: { value: 0, currencyCode: '' },
      priceAfterDiscount: { value: 0, currencyCode: '' },
      discount: 0
    };

    if (this.cartRows) {
      const items = this.cartRows.filter(cartItem => cartItem.productId.startsWith(id));
      for (const item of items) {
        const price = this.prices.find(p => p.productId === item.productId);
        if (this.priceService.isStaffedPrice(price)) {
          const singleProductPriceLevel = this.priceService.getPriceLevel(price.backendPrice, 1);
          result.price.value += singleProductPriceLevel.price * item.qty;
          result.discount += price.cartRowUnitPrice.value * item.qty;
        } else {
          result.price.value += price.cartRowUnitPrice.value * item.qty;
          result.discount += price.cartRowUnitPriceInclRowDiscount.value * item.qty;
        }

        result.qty += item.qty;
        result.priceAfterDiscount.value += price.cartRowUnitPriceInclRowDiscount.value * item.qty;
        result.price.currencyCode = price.cartRowTotalPrice.currencyCode;
        result.priceAfterDiscount.currencyCode = price.cartRowTotalPrice.currencyCode;
      }
    }
    return result;
  }

  toogleSizes(elementId: string, eventName: string): void {
    this.sizesVisible[elementId] = eventName === 'show';
  }

  setFieldTodisplay(product) {
    if (this.columns.unit.show) {
      const unitField = this.cartListCheckoutConfig.unitField;
      product.extra._unit = gungGetPropertyOfObject(unitField, product.extra);
      if (product.extra?._unit?.trim()) {
        product.extra._unit = this.metadataService.getMetadataValue('unitsOfMeasure', 'name', product.extra._unit) || product.extra._unit;
      }
    }
  }

  trackByFn(index: number, item: any): any {
    return item?.product?.id;
  }

  trackByCartDataContext(index: number, item: CartDataContext) {
    return item?.id;
  }

  trackByRelated(index: number, item: RelatedInfo) {
    return item?.product?.id;
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  removeRow(cartRow: CartRow): void {
    // remove from cart
    const indexToRemove = this.cartRows.findIndex(
      x => x.productId === cartRow.productId && x.productPartialId === cartRow.productPartialId
    );
    if (indexToRemove > -1) {
      this.cartRows.splice(indexToRemove, 1);
      this.cartService.removeRow(cartRow);
    }

    this.updateCart();
  }

  addDescription(cartRow: CartRow) {
    const descriptionField = this.cartListCheckoutConfig.rowDescriptionField;
    const currentDescription = gungGetPropertyOfObject(descriptionField, cartRow.extra);
    this.gungModalService.openEditNoteModal(currentDescription).then(
      (result: any) => {
        if (!!result && result.hasOwnProperty('note')) {
          this.setExtraOnFields(cartRow, [descriptionField], result.note);
        }
      },
      reason => { }
    );
  }

  copyRow(row: CartRow, index: number) {
    const productPartialId = row.productId + '_' + new Date().getTime();
    this.cartService.addToCart(row.productId, row.qty || 1, row.targetStockId, productPartialId, index + 1);
  }

  openCartDiscountModal(data: CartDataContext) {
    const productMultiDimension = data.related[0].product as ProductMultiDimension;
    this.gungModalService
      .openCartCheckoutDiscountModal(productMultiDimension.modelId)
      .then(() => { })
      .catch(error => {
        console.log('error, nothing change');
      });
  }

  addNewOrderLine(): void {
    this.gungModalService.addRowCheckoutModal().then(
      result => {
        if (result) {
        }
      },
      reason => { }
    );
  }

  arrayEquals(a, b) {
    return Array.isArray(a) && Array.isArray(b) && a.length === b.length && a.every((val, index) => val === b[index]);
  }

  getFormattedPrice(price: number): string {
    return price.toFixed(2);
  }
}
