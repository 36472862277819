import { QuantityPayload } from '../actions';
import { CartRow, CartRowIdentification } from '../types';
import { filterOutRow, getRowFromRows, getIndexOfRow } from './common';
import { updatePositionOfRows } from './add';

const createRow = (productId: string, date: Date, targetStockId?: string, productPartialId?: string): CartRow => ({
  productId,
  productPartialId,
  targetStockId,
  extra: {},
  qty: 0,
  timeAdded: date,
  timeUpdated: date
});

export const setQtyReducer = (rows: CartRow[], identification: CartRowIdentification, qty: number): CartRow[] => {
  const currentDate = new Date();
  const { productId, productPartialId, targetStockId } = identification;
  if (qty <= 0) {
    // remove row
    let toReturn = rows.filter(r => filterOutRow(r, productId, targetStockId, productPartialId));
    return updatePositionOfRows(toReturn);
  }
  const oldRow =
    getRowFromRows(rows, productId, targetStockId, productPartialId) ||
    createRow(productId, currentDate, targetStockId, productPartialId);
  const newRow = {
    ...oldRow,
    qty,
    timeUpdated: currentDate
  };

  // Preserve position on the row we want to set quantity on
  const existingIndex = getIndexOfRow(rows, productId, targetStockId, productPartialId);
  if (existingIndex >= 0) {
    rows[existingIndex] = newRow;
    return [...rows];
  } else {
    return [...rows, newRow];
  }
};

export const bulkSetQtyReducer = (rows: CartRow[], payload: QuantityPayload[]): CartRow[] => {
  for (const item of payload) {
    rows = setQtyReducer(
      rows,
      { productId: item.productId, productPartialId: item.productPartialId, targetStockId: item.targetStockId },
      item.qty
    );
  }

  return rows;
};
