import { Component } from '@angular/core';
import { CompanyTemplatesListConfigService } from '../../services/company-templates-list-config.service';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-company-templates-list',
  templateUrl: './company-templates-list.component.html',
  styleUrls: ['./company-templates-list.component.css']
})
export class CompanyTemplatesListComponent {
  constructor(
    public companyTemplatesListConfig: CompanyTemplatesListConfigService,
    protected gungModalService: GungModalService
  ) {}

  addCompanyTemplate(): void {
    this.gungModalService.openAddEditCompanyTemplate().then(
      result => {},
      reject => {}
    );
  }
}
