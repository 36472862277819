import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { Observable } from 'rxjs';
import { BrandsListComponent } from '../../components/brands/brands-list/brands-list.component';
import { PimConcept } from '../../models/pim';
import { PimService } from '../pim/pim.service';

@Injectable({
  providedIn: 'root'
})
export class BrandsListConfigService implements ConfigService<PimConcept> {
  constructor(protected pimService: PimService) {}
  topFilter = false;

  getFilters(): ConfigBaseFilter<PimConcept>[] {
    return [];
  }

  getSortOptions(): ListSortOption<PimConcept>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: PimConcept) {
    return item.id;
  }

  getSearchTerms(item: PimConcept) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getLayouts(): ListLayout<PimConcept>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => BrandsListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'AssortmentGrid'
      }
    ];
  }

  getItems(): Observable<PimConcept[]> {
    return this.pimService.getPimConcepts(true);
  }
}
