import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { News } from '../../../models/news';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-news-page-grid-view',
  templateUrl: './news-page-grid-view.component.html',
  styleUrls: ['./news-page-grid-view.component.css']
})
export class NewsPageGridViewComponent extends ListItemRendererComponent<News[]> implements AfterViewInit {
  public currentLang = this.translateService.currentLang;
  constructor(
    protected translateService: TranslateService
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
