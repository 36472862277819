import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../../services/users/users.service';
import { first } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';

@Component({
  selector: 'lib-import-users',
  templateUrl: './import-users.component.html',
  styleUrls: ['./import-users.component.css']
})
export class ImportUsersComponent implements OnInit {
  error: any;
  success: any;
  uploadForm: FormGroup;
  loading = false;

  backendBaseUrl = this.backendInterceptor.getBaseUrl();

  constructor(
    private formBuilder: FormBuilder,
    private usersService: UsersService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  ngOnInit() {
    this.uploadForm = this.formBuilder.group({ importUsersDocument: [''] });
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.uploadForm.get('importUsersDocument').setValue(file);
    }
  }

  onSubmit() {
    this.loading = true;
    const formData = new FormData();
    formData.append('document', this.uploadForm.get('importUsersDocument').value);

    this.usersService
      .importFile(formData)
      .pipe(first())
      .subscribe(
        httpSuccessResponse => {
          this.loading = false;
          this.success = httpSuccessResponse;
        },
        httpErrorResponse => {
          this.loading = false;
          this.error = httpErrorResponse.error;
        }
      );
  }
}
