import { Component, OnInit, AfterViewInit } from '@angular/core';
import { OpenOrdersConfigService } from '../../services/open-orders-config/open-orders-config.service';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';

@Component({
  selector: 'lib-open-orders',
  template:
    '<h3 translate class="page-title text-center">OPEN_ORDERS</h3><lib-filter-list [configService]="config"></lib-filter-list>',
  styleUrls: ['./open-orders.component.css']
})
export class OpenOrdersComponent extends ListItemRendererComponent<Order[]> implements OnInit, AfterViewInit {
  constructor(public config: OpenOrdersConfigService) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
