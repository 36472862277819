import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { first, forkJoin } from 'rxjs';
import { Product } from '../../models';
import { Availability } from '../../models/availability';
import { AuthService } from '../../services/auth/auth.service';
import { AvailabilityService } from '../../services/availability/availability.service';
import { PriceService } from '../../services/price/price.service';
import { ProductService } from '../../services/products/product.service';

interface ConceptProduct extends Product {
  subProductIds?: string[];
}
@Component({
  selector: 'lib-concept-products-modal',
  templateUrl: './concept-products-modal.component.html',
  styleUrls: ['./concept-products-modal.component.css']
})
export class ConceptProductsModalComponent implements OnInit {
  @Input()
  conceptId: string;

  roles: string[];
  isSales = false;

  concept: ConceptProduct;
  mappedData = [];

  constructor(
    public activeModal: NgbActiveModal,
    protected authService: AuthService,
    protected productService: ProductService,
    protected priceService: PriceService,
    protected availabilityService: AvailabilityService
  ) {}

  ngOnInit(): void {
    this.authService
    .getRoles()
    .pipe(first())
    .subscribe(roles => {
      this.roles = roles;
      this.isSales = roles.indexOf('SALES') > -1 || roles.indexOf('ADMIN') > -1;
    });
    
    this.productService
      .getProduct(this.conceptId)
      .pipe(first())
      .subscribe(concept => {
        this.concept = concept;
        const productIds = this.concept?.subProductIds || [];
        forkJoin([
          this.productService.getProductsByIds(productIds).pipe(first()),
          this.priceService.getCurrentCustomerPrices(productIds).pipe(first())
        ]).subscribe(([products, prices]) => {
          for (const product of products) {
            this.mappedData.push({
              product,
              price: prices.find(p => p.productId === product.id),
              availability: this.getProductAvailability(product)
            });
          }
        });
      });
  }

  protected getProductAvailability(product: Product): Availability {
    if (product.extra.availabilities) {
      const availabilities = Object.keys(product.extra.availabilities).map(key => product.extra.availabilities[key]);
      if (availabilities.length > 0) {
        return availabilities[0];
      }
    }

    return null;
  }
}
