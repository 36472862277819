import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { first, map, mergeMap, switchMap, tap } from 'rxjs';
import { GungBase } from '../../models';
import { AuthService } from '../auth/auth.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { DownloadService } from '../download/download.service';

export interface DigitalAsset extends GungBase {
  dateLastUpdate: number;
  i18n: { [s: string]: any };
  images: DigitalAssetFile[];
  documents: DigitalAssetFile[];
  /*
  extra: {
    brand: string;
    season: string;
    roles: string;
    tags: string;
    parentId: string;
    type: string; ('folder')
    content: string; ('folder')
  }
  */
}

export interface DigitalAssetFile extends GungBase {
  category: string;
  description: any;
  i18n: { [s: string]: any };
  index: any;
  pimDimensionIds: any[];
  s3ETag: string;
  s3Uri: string;
  s3lastModifiedDate: any;
}

@Injectable({
  providedIn: 'root'
})
export class DigitalAssetsService {
  public showFlowsAsDigitalAssets = false;

  protected baseUrl = 'json/pim/digitalassets';
  protected baseUrlImages = 'json/pim/digitalasset-images';
  protected baseUrlDocuments = 'json/pim/digitalasset-documents';

  private digitalAssetSubject: BehaviorSubject<DigitalAsset[]>;

  public useLinks = false;

  constructor(
    protected http: HttpClient,
    protected authService: AuthService,
    protected downloadService: DownloadService
  ) {}

  public getDigitalAssets(nocache?: boolean): Observable<DigitalAsset[]> {
    if (!this.digitalAssetSubject) {
      this.digitalAssetSubject = new BehaviorSubject<DigitalAsset[]>([]);
      // Added return to fix pipe first() call
      return this.updateDigitalAssetSubject(nocache);
    }
    if(nocache) {
      return this.updateDigitalAssetSubject(nocache);
    }
    return this.digitalAssetSubject.asObservable();
  }

  public getDigitalAsset(id: string, nocache?: boolean): Observable<DigitalAsset> {
    const url = `${this.baseUrl}/${id}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<DigitalAsset>(url, { headers });
  }

  protected internalGetDigitalAssets(nocache?: boolean): Observable<DigitalAsset[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<DigitalAsset[]>(url, { headers });
  }

  updateDigitalAssetSubject(nocache?: boolean): Observable<DigitalAsset[]> {
    return this.internalGetDigitalAssets(nocache).pipe(
      mergeMap(digitalAssets =>
        forkJoin([
          of(digitalAssets),
          this.authService.getRoles().pipe(first()),
          this.authService.getCurrentUser().pipe(first())
        ])
      ),
      map(([digitalAssets, roles, user]) => {
        if (roles.indexOf('ADMIN') === -1) {
          // Filter by roles
          // digitalAssets = digitalAssets.filter(d => {
          //   if (d.extra.roles && d.extra.roles.split(',').length > 0) {
          //     const digitalRoles: string[] = d.extra.roles.split(',').map(r => r.trim());
          //     return digitalRoles.some(r => roles.includes(r.toLocaleUpperCase()));
          //   }
          //   return true;
          // });
          // Filter by groups
          const groupsObject = (user as any).activeGroups;
          const groups = Object.keys(groupsObject).filter(g => groupsObject[g]);
          digitalAssets = digitalAssets.filter(d => {
            if (d.extra.groups && d.extra.groups.split(',').length > 0) {
              const digitalGroups: string[] = d.extra.groups.split(',').map(r => r.trim());
              return digitalGroups.some(r => groups.includes(r.toLocaleUpperCase()));
            }
            return true;
          });
        }
        return digitalAssets;
      }),
      switchMap((data: DigitalAsset[]) => {
        if (this.digitalAssetSubject) {
          this.digitalAssetSubject.next(data);
        }
        return this.digitalAssetSubject || of(data);
      })
      // ).subscribe((data: DigitalAsset[]) => { if (this.digitalAssetSubject) { this.digitalAssetSubject.next(data); } });
    );
  }

  public sendDigitalAssetsFiles(digitalAssets: DigitalAsset[], gungModalService: GungModalService, userEmail: string) {
    if (digitalAssets.length === 0) {
      return;
    }
    const imageS3Uris: string[] = [];
    const documentsToSend: DigitalAssetFile[] = [];
    for (const item of digitalAssets) {
      if (item.images && item.images.length > 0) {
        //      imageS3Uris.push(...item.images.map(image => 'fit-in/5000x5000/filters:quality(100):background_color(white)/' + image.s3Uri)); // Don't remove the fit-in, prevents image size cap from being reached
        imageS3Uris.push(...item.images.map(image => image.s3Uri)); // for urls without fit-in we will use cdn2.gung.io that does not use scaling function, hence no image size cap.
      }
      if (item.documents && item.documents.length > 0) {
        documentsToSend.push(...item.documents);
      }
    }
    gungModalService.openConfirmExportModal(userEmail || '').then(({ email }) => {
      if (email && (email as string).trim().length > 0) {
        this.downloadService.postGenerateAssetsZipFromUrlList(email, imageS3Uris, documentsToSend).subscribe();
      }
    });
  }

  public sendDigitalAssetsFilesUri(
    imageS3Uris: string[],
    documentsToSend: DigitalAssetFile[],
    gungModalService: GungModalService,
    userEmail: string
  ) {
    if (imageS3Uris?.length === 0 && documentsToSend?.length === 0) {
      return;
    }

    gungModalService.openConfirmExportModal(userEmail || '').then(({ email }) => {
      if (email && (email as string).trim().length > 0) {
        this.downloadService
          .postGenerateAssetsZipFromUrlList(email, imageS3Uris || [], documentsToSend || [])
          .subscribe();
      }
    });
  }

  public postDigitalAsset(digitalAsset: DigitalAsset): Observable<DigitalAsset> {
    const url = `${this.baseUrl}`;

    return this.http
      .post<DigitalAsset>(url, digitalAsset)
      .pipe(tap(_ => this.updateDigitalAssetSubject(true).subscribe(result => result)));
  }

  public putDigitalAsset(digitalAsset: DigitalAsset): Observable<DigitalAsset> {
    const url = `${this.baseUrl}/${digitalAsset.id}`;
    return this.http
      .put<DigitalAsset>(url, digitalAsset)
      .pipe(tap(_ => this.updateDigitalAssetSubject(true).subscribe(result => result)));
  }

  public deleteDigitalAsset(digitalAssetId: string): Observable<DigitalAsset> {
    const url = `${this.baseUrl}/${digitalAssetId}`;
    return this.http
      .delete<DigitalAsset>(url)
      .pipe(tap(_ => this.updateDigitalAssetSubject(true).subscribe(result => result)));
  }

  public postDigitalAssetDocuments(digitalAssetId: string, dataImg: FormData): Observable<DigitalAsset> {
    const url = `${this.baseUrlDocuments}/upload/${digitalAssetId}`;
    return this.http.post<DigitalAsset>(url, dataImg);
  }

  public postDigitalAssetImages(digitalAssetId: string, dataImg: FormData): Observable<DigitalAsset> {
    const url = `${this.baseUrlImages}/upload/${digitalAssetId}`;
    return this.http.post<DigitalAsset>(url, dataImg);
  }

  public deleteDigitalAssetImages(digitalAssetId: string, img: DigitalAssetFile): Observable<DigitalAsset> {
    const url = `${this.baseUrlImages}/delete/${digitalAssetId}`;
    return this.http.post<DigitalAsset>(url, img);
  }
}
