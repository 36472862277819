<button
  type="button"
  class="gung-btn btn text-center {{ classes }}"
  [class.btn-with-icon]="icon"
  (click)="handleClick()"
  [disabled]="loading || disable"
>
  @if (icon) {
    <span class="btn-icon"><i class="{{ icon }}"></i></span>
  }
  @if(!loading){
    <span>{{ label | translate }}</span>
  }@else{
    <i class="fa fa-spinner fa-spin"></i>
  }
  
  
</button>
