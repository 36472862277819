<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>RELEASE_ORDERS</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <span translate>CONFIRM</span>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close(true)" translate>YES</button>
  <button class="btn btn-sm btn-outline-primary px-4" (click)="activeModal.close(false)" translate>NO</button>
</div>
