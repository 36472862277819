import { Injectable, Input, Directive } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  PimTemplateProperties
} from 'gung-list';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs';
import { ConceptTreeListViewComponent } from '../../components/concept-tree-list-view/concept-tree-list-view.component';
import { ConceptTreeTableViewComponent } from '../../components/concept-tree-table-view/concept-tree-table-view.component';
import { AssortmentRecursive } from '../assortment.service';
import { AuthService } from '../auth/auth.service';
import { MetadataService } from '../metadata/metadata.service';
import { ProductSortService } from '../product-sort/product-sort.service';
import { ProductService } from '../products/product.service';
import { DynamicColumnsService } from '../dynamic-columns/dynamic-columns.service';

interface AssortmentRecursiveExtended extends AssortmentRecursive {
  dynamicCollumnsValues?: string[];
}

@Directive()
@Injectable({
  providedIn: 'root'
})
export class ConceptTreeListConfigService implements ConfigService<AssortmentRecursive> {
  conceptObjectsSubject: Subject<AssortmentRecursive[]> = new BehaviorSubject(null);

  @Input()
  filters: ConfigBaseFilter<AssortmentRecursive>[] = [];

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  constructor(
    protected productService: ProductService,
    protected authService: AuthService,
    protected productSortService: ProductSortService,
    protected translationService: TranslateService,
    protected metadataService: MetadataService,
    protected dynamicColumnsService: DynamicColumnsService
  ) {}

  getSortOptions(): ListSortOption<AssortmentRecursive>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [99];
  }

  getItemId(item: AssortmentRecursive) {
    return item.id;
  }

  getSearchTerms(item: AssortmentRecursive) {
    return [item.id, item.name];
  }

  getSearchGroupCss() {
    return '';
  }

  getFilters(): ConfigBaseFilter<AssortmentRecursive>[] {
    return this.filters;
  }

  getLayouts(): ListLayout<AssortmentRecursive>[] {
    if (this.pimOptions && this.pimOptions.showAsList) {
      return [
        {
          getIconClass: () => '',
          getListItemComponent: () => ConceptTreeTableViewComponent,
          getListLayoutComponent: () => ListLayoutMultipleComponent,
          getName: () => 'ProductGrid'
        }
      ];
    }
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ConceptTreeListViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<AssortmentRecursive[]> {
    return this.conceptObjectsSubject.pipe(
      map(m =>
        m.sort((a, b) => {
          // SORT by pim sequence high
          const aSort = a.extra.pim && a.extra.pim.sequence;
          const bSort = b.extra.pim && b.extra.pim.sequence;
          if ((!bSort && aSort) || (aSort && bSort && Number(aSort) > Number(bSort))) {
            return -1;
          } else if ((!aSort && bSort) || (aSort && bSort && Number(aSort) < Number(bSort))) {
            return 1;
          } else {
            return 0;
          }
        })
      ),
      map(products => {
        // Dyncamic column values
        return this.dynamicColumnsService.getDynamicColumnsValuesAssortment(this.dynamicColumns, products);
      })
    );
  }

  getDynamicColumns(): PimTemplateProperties[] {
    return this.dynamicColumns;
  }

  getPimOptions(): { [s: string]: any } {
    return this.pimOptions;
  }
}
