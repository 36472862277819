<div class="row">
  <div class="col-sm-12 col-md-6 d-flex flex-row flex-wrap">
    <button
      *ngFor="let graph of graphList"
      class="btn btn-primary rounded-pill select-graph-button"
      [ngClass]="{'active': selectedGraph?.id === graph.id}"
      (click)="changeGraph(graph.id)"
      translate
    >{{ graph.translationKey }}</button>
  </div>
</div>

<div class="row">
  <div class="col-12" *ngIf="!!selectedGraph; else isLoading">
    <lib-order-analytics-chart-table-horizontal
      [data]="data"
      [groupBy]="selectedGraph.groupBy"
      [heading]="selectedGraph.heading"
      [headerRows]="selectedGraph.headerRows"
      [hideVerticalGridLines]="selectedGraph.hideVerticalGridLines"
    />
  </div>
  <ng-template #isLoading>
    <div class="col-12 d-flex flex-row justify-content-center p-4">
      <i class="fa fa-spinner fa-spin fa-5x"></i>
    </div>
  </ng-template>
</div>
