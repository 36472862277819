<div class="form-group">
    <label [for]="inputId" *ngIf="!suppressLabel" [ngClass]="{ 'font-weight-bold': labelBold }">
      {{ translateTag | translate: translateParams }}
      <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
      <span *ngIf="footnote" class="ml-1">*</span>
    </label>
    <input
        #instance="ngbTypeahead"
        #searchInput
        id="typeahead-prevent-manual-entry"
        type="text"
        [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
        [(ngModel)]="value"
        [ngbTypeahead]="search"
        [inputFormatter]="inputFormatter"
        [resultFormatter]="resultFormatter"
        [resultTemplate]="resultTemplate"
        (selectItem)="selectItemFn($event, searchInput)"
        placeholder="{{ hidePlaceholder ? '' : (translateTag | translate) }}"
        (focusout)="focusout($event, value)"
        (ngModelChange)="ngModelChange($event, searchInput)"
        [showHint]="showHint"
        [editable]="editable"
        [readonly]="renderStatic"
        [disabled]="disabled"
        (focus)="focus$.next($any($event).target.value)"
	      (click)="click$.next($any($event).target.value)"
    />
</div>
