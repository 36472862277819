<div class="table-responsive gung-responsive">
  <table class="table">
    <thead>
      <tr>
        <th translate>CODE</th>
        <th translate>DESCRIPTION</th>
        <th translate>VALID_FROM</th>
        <th translate>VALID_UNTIL</th>
        <th></th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let item of data">
        <td [attr.data-label]="'CODE' | translate" class="text-dark">
          {{ item.code }}
        </td>
        <td [attr.data-label]="'DESCRIPTION' | translate">
          {{ item.description }}
        </td>
        <td [attr.data-label]="'VALID_FROM' | translate">
          {{ item.validFrom | date: dateUtilService.dateFormat }}
        </td>
        <td [attr.data-label]="'VALID_UNTIL' | translate">
          {{ item.validUntil | date: dateUtilService.dateFormat }}
        </td>
        <td>
          <button type="button" class="btn btn-secondary" [routerLink]="['.', item.code]">
            <i class="fas fa-edit"></i>
          </button>
          <button type="button" class="btn btn-danger" (click)="deleteDiscountCode(item.code)">
            <i class="fas fa-trash-alt"></i>
          </button>
        </td>
      </tr>
    </tbody>
  </table>
</div>
