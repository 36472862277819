import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs';
import 'url-search-params-polyfill';
import { User } from '../../models';

interface LoginResponse {
  error: string;
  status: number;
}

@Injectable({
  providedIn: 'root'
})
export class AuthBackendService {
  constructor(protected http: HttpClient) {}

  logout(): Observable<any> {
    return this.http.post('logout', {});
  }

  getUser(): Observable<User | null> {
    const url = 'user';
    return this.http.get<User | null>(url, {
      headers: {
        maxAge: '-1'
      }
    });
  }

  loginUser(userName: string, password: string): Observable<LoginStatus> {
    const url = 'login';
    const body = new URLSearchParams();
    body.set('username', userName);
    body.set('password', password);
    const options = {
      headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    };
    return this.http.post<LoginResponse>(url, body.toString(), options).pipe(
      map(resp => ({ valid: resp.error === 'OK' })),
      catchError(data =>
        of({
          valid: false,
          status: data.status,
          message: data.message,
          error: typeof data.error === 'string' ? data.error.replace(/\r\n/g, '') : data.error
        })
      )
    );
  }
}

export interface LoginStatus {
  valid: boolean;
  status?: number;
  message?: string;
  error?: string;
}
