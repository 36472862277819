<div class="card">
  <div class="card-body">
    <div class="card-title">
      <div class="row">
        <div class="col">
          <h5 class="customer-contact-name">
            {{ data.name }} <small>{{ data.customerId }}</small>
          </h5>
        </div>
        <div class="col-auto">
          <button class="btn btn-primary" type="button" (click)="editContact(data)" translate>EDIT_CONTACT</button>
        </div>
      </div>
    </div>
    <div class="card-text">
      <div class="row">
        <div class="col-12 col-sm-4 mb-2 mb-xs-0" *ngIf="data.phone">
          <lib-gung-text-input [gungTranslate]="'CS1_COMNR'" [renderStatic]="true" [(ngModel)]="data.phone">
          </lib-gung-text-input>
        </div>
        <div class="col-12 col-sm-4 mb-2 mb-xs-0" *ngIf="data.extra?.gungContact?.mobile">
          <lib-gung-text-input
            [gungTranslate]="'CS4_COMNR'"
            [renderStatic]="true"
            [(ngModel)]="data.extra.gungContact.mobile"
          >
          </lib-gung-text-input>
        </div>
        <div class="col-12 col-sm-4 mb-2 mb-xs-0" *ngIf="data.email">
          <lib-gung-text-input [gungTranslate]="'CS8_COMNR'" [renderStatic]="true" [(ngModel)]="data.email">
          </lib-gung-text-input>
        </div>
      </div>
    </div>
  </div>
</div>
