<div class="row mt-4">
  <div class="col-12">
    <div class="image-container" [style.backgroundImage]="'url(' + getImage(data.elements?.topImage?.image) + ')'">
      <div class="image-text text-primary w-100 text-uppercase" translate>
        <button
          type="button"
          class="btn btn-primary w-100"
          (click)="edit ? openModal('topImage') : navigateTo(data.elements?.topImage?.link)"
          [style.color]="'' + getTextColor(data.elements?.topImage?.text) + ' !important'"
          translate
        >
          {{ getText(data.elements?.topImage?.text) }}
        </button>
      </div>
    </div>
  </div>
</div>
<div class="row mt-4">
  <div
    class="col-2 mb-2 assortment pointer"
    *ngFor="let assortment of assortmentsList; let i = index"
    [routerLink]="!edit ? '/categories/' + assortment.id : undefined"
  >
    <img [src]="getImage(null)" alt="" class="w-100" />
    <span class="d-block text-center">{{ assortment.name }}</span>
  </div>
</div>
