import { Injectable } from '@angular/core';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { CartConfigService } from '../cart-config/cart-config.service';
import { CartService } from '../cart/cart.service';
import { ProductAssortmentParentService } from '../product-assortment-parent/product-assortment-parent.service';

@Injectable({
  providedIn: 'root'
})
export class SalesCartConfigService extends CartConfigService {
  constructor(
    protected cartService: CartService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) {
    super(cartService, baseViewConfigService, productAssortmentParentService);
  }

  getSearchGroupCss() {
    return 'd-none';
  }
}
