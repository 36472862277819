import { Component, Input, OnInit, ViewChild, OnChanges } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { AssortmentTreeListConfigService } from '../../services/assortment-tree-list-config/assortment-tree-list-config.service';
import { AssortmentRecursive } from './../../services/assortment.service';

@Component({
  selector: 'lib-assortment-tree-list',
  template: '<lib-filter-list #assortmentList [configService]="configService"></lib-filter-list>',
  styleUrls: ['./assortment-tree-list.component.css']
})
export class AssortmentTreeListComponent implements OnInit, OnChanges {
  @Input()
  private assortmentChildren: AssortmentRecursive[];

  @ViewChild('assortmentList') assortmentList: FilterListComponent<AssortmentRecursive>;

  constructor(public configService: AssortmentTreeListConfigService) {}

  ngOnInit() {
    this.configService.assortmentObjectsSubject.next(this.assortmentChildren);
  }

  ngOnChanges() {
    this.configService.assortmentObjectsSubject.next(this.assortmentChildren);
  }
}
