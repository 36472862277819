import { Injectable } from '@angular/core';
import { CustomerService } from '../customers/customer.service';
import { ListLayout, ConfigBaseFilter, ListSortOption, ListLayoutMultipleComponent, ConfigService } from 'gung-list';
import { Customer } from './../../models/customer';
import { UserDetailsCustomersTableComponent } from '../../components/users/user-details/user-details-customers/user-details-customers-table/user-details-customers-table.component';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersDetailsCustomersListConfigService implements ConfigService<Customer> {
  private isSelectedKey = 'isSelected';

  selectedCustomerIds: string[];
  setSelectedCustomerIds(selectedCustomerIds: string[]) {
    this.selectedCustomerIds = selectedCustomerIds;
  }

  constructor(public customerService: CustomerService) {}

  getItems(): Observable<Customer[]> {
    return this.customerService.getCustomers().pipe(
      first(),
      map(customers => {
        for (const customer of customers) {
          customer[this.isSelectedKey] = this.selectedCustomerIds.includes(customer.id);
        }

        // first selected then unselected
        // for each group order by customer name
        const selectedCustomers = customers.filter(c => c[this.isSelectedKey]);
        selectedCustomers.sort((a, b) => {
          const customerNameA = a.name.toUpperCase();
          const customerNameB = b.name.toUpperCase();
          return customerNameA < customerNameB ? -1 : customerNameA > customerNameB ? 1 : 0;
        });

        const unselectedCustomers = customers.filter(c => !c[this.isSelectedKey]);
        unselectedCustomers.sort((a, b) => {
          const customerNameA = a.name.toUpperCase();
          const customerNameB = b.name.toUpperCase();
          return customerNameA < customerNameB ? -1 : customerNameA > customerNameB ? 1 : 0;
        });

        return selectedCustomers.concat(unselectedCustomers);
      })
    );
  }

  getFilters(): ConfigBaseFilter<Customer>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Customer>[] {
    return [];
  }

  getBatchSizes(): number[] {
    const nemberCustomers = this.selectedCustomerIds.length;
    const batch = 5 + nemberCustomers;
    return [batch];
  }

  getSearchTerms(item: Customer): string[] {
    return [item.id, item.name];
  }

  getItemId(item: Customer): string {
    return item.id;
  }

  getLayouts(): ListLayout<Customer>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => UserDetailsCustomersTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'UserDetailsCustomerTable'
      }
    ];
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
