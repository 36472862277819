import { Component, OnInit } from '@angular/core';
import { SavedQuoteConfigService } from '../../services/saved-quote-config/saved-quote-config.service';

@Component({
  selector: 'lib-saved-quote-list',
  templateUrl: './saved-quote-list.component.html',
  styleUrls: ['./saved-quote-list.component.css']
})
export class SavedQuoteListComponent implements OnInit {
  constructor(public savedQuoteConfigService: SavedQuoteConfigService) {}

  ngOnInit() {}
}
