<div class="container" *ngIf="curentGungFlow; else loading">
  <div class="row">
    <div class="col-6">
      <h5>{{ curentGungFlow.name }}: {{ curentGungFlow.id }}</h5>
    </div>
    <div class="col-3 offset-3">
      <div class="btn-group btn-group-justified w-100">
        <button type="button" class="btn btn-sm btn-danger" (click)="deleteFlow()">{{ 'DELETE' | translate }}</button>
      </div>
    </div>

    <div class="col-12">
      <lib-gung-tabset [destroyOnHide]="false" class="gung-flow-details-tabs" *ngIf="curentGungFlow">
        <lib-gung-tab [title]="'BASIC_SETTINGS' | translate">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-basic-settings [curentGungFlow]="curentGungFlow">
            </lib-gung-flow-details-basic-settings>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'PRODUCT_SETTINGS' | translate">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-product-settings [curentGungFlow]="curentGungFlow">
            </lib-gung-flow-details-product-settings>
          </ng-template>
        </lib-gung-tab>

        <lib-gung-tab [title]="'ORDER_SETTINGS' | translate">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-order-settings [curentGungFlow]="curentGungFlow">
            </lib-gung-flow-details-order-settings>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'HTML'">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-html [curentGungFlow]="curentGungFlow"> </lib-gung-flow-details-html>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'SLIDER'">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-slider [curentGungFlow]="curentGungFlow"></lib-gung-flow-details-slider>
          </ng-template>
        </lib-gung-tab>
        <lib-gung-tab [title]="'DROP'" *ngIf="curentGungFlow.multidrop">
          <ng-template libGungTabContent>
            <lib-gung-flow-details-drops [curentGungFlow]="curentGungFlow"></lib-gung-flow-details-drops>
          </ng-template>
        </lib-gung-tab>
      </lib-gung-tabset>
    </div>
  </div>

  <div class="row justify-content-end pt-4">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button type="button" class="btn btn-sm btn-warning" (click)="cancelChanges()">
          {{ 'CANCEL' | translate }}
        </button>
        <button type="button" class="btn btn-sm btn-primary" (click)="saveCurrentUser()">
          {{ 'SAVE_CHANGES' | translate }}
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
</ng-template>
