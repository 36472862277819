import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CheckoutObject } from '../models/checkout';
import { SimplifiedOrderContext } from '../models/simplifiedOrderContext';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { GungGoogleTagManagerService } from './google-tag-manager/gung-google-tag-manager.service';

@Injectable({
  providedIn: 'root'
})
export class OrderSubmitService {
  constructor(protected http: HttpClient, protected gungGoogleTagManagerService: GungGoogleTagManagerService) {}

  postOrder(url: string, checkout: CheckoutObject): Observable<SimplifiedOrderContext> {
    /*
      Make sure we never submit the bloated html connected to some flows
    */
    if (checkout.extra._flow) {
      const flowInitial = checkout.extra._flow;
      if (flowInitial.extra && flowInitial.extra.html) {
        const flowWithoutHtml = JSON.parse(JSON.stringify(flowInitial));
        delete flowWithoutHtml.extra.html;
        if (flowWithoutHtml.extra.i18n) {
          flowWithoutHtml.extra.i18n.forEach(element => {
            delete element.html;
          });
        }
        checkout.extra._flow = flowWithoutHtml;
      }
    }

    return this.http.post<any>(url, checkout).pipe(
      map(resp => ({
        contextId: resp.id,
        erpOrderIds: resp.erpOrderIds,
        finalized: !!resp.finalized
      })),
      tap(resp => {
        this.gungGoogleTagManagerService.orderSubmitted(checkout, resp.contextId || null);
      })
    );
  }
}
