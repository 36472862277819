import { Injectable } from '@angular/core';
import { Product } from '../../models';
import { GungGridItem, WhiteboardSheet } from '../types';
import { ProductService } from '../../services/products/product.service';
import { WhiteboardService } from './whiteboard.service';
import {
  CompactType,
  DisplayGrid,
  GridsterConfig,
  GridsterItem,
  GridsterItemComponentInterface,
  GridType
} from 'angular-gridster2';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardGridConfigService {
  private productsAddedSubject: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  productsAdded = this.productsAddedSubject.asObservable();

  public sheets: WhiteboardSheet[] = [{ items: [], name: 'Sheet 1' }];
  public currentSheetIndex = 0;

  private resizeCallbackSubject: Subject<any> = new ReplaySubject<any>();

  constructor(protected productService: ProductService, protected whiteboardService: WhiteboardService) {}

  addProductToGrid(productIds: string[]): void {
    productIds = productIds.filter(id => {
      return !this.sheets[this.currentSheetIndex].items.find(item => item.productId === id);
    });

    const items = productIds.map(id => {
      return {
        productId: id,
        rows: 4,
        cols: 3
      } as GungGridItem;
    });
    if (items.length > 0) {
      this.sheets[this.currentSheetIndex].items.push(...items);
      this.productsAddedSubject.next(productIds);
    }
  }

  createNewSheet(): void {
    this.sheets.push({
      name: 'Sheet ' + (this.sheets.length + 1),
      items: []
    });
  }

  removeSheet(index: number): void {
    this.sheets.splice(index, 1);
    if (this.currentSheetIndex === this.sheets.length) {
      this.currentSheetIndex--;
    }
  }

  removeItem(item): void {
    this.sheets[this.currentSheetIndex].items.splice(this.sheets[this.currentSheetIndex].items.indexOf(item), 1);
  }

  getGridOptions(): GridsterConfig {
    return {
      gridType: GridType.ScrollVertical,
      compactType: CompactType.None,
      displayGrid: DisplayGrid.None,
      enableEmptyCellDrag: false,
      pushResizeItems: false,
      pushItems: true,
      draggable: {
        enabled: true
      },
      resizable: {
        enabled: true
      },
      minCols: 30,
      maxCols: 30,
      minRows: 40,
      maxRows: 5000,
      itemResizeCallback: this.itemResize.bind(this),
      itemChangeCallback: this.itemResize.bind(this)
    };
  }

  public itemResize(item: GridsterItem, itemComponent: GridsterItemComponentInterface): void {
    this.resizeCallbackSubject.next(itemComponent);
    // console.log(itemComponent); // take a closer look at the gridster property;
  }

  public getResizeCallbackSubject() {
    return this.resizeCallbackSubject;
  }

  getSidenavOptions(): { bottom: number; fixed: boolean; top: number; open: boolean } {
    return {
      bottom: 0,
      fixed: true,
      top: 120,
      open: false
    };
  }
}
