import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  AfterViewInit,
  ElementRef,
  ViewChild
} from '@angular/core';
import { SelectionAction, ExportSelection } from '../types';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { AbstractSelectionService } from '../services/selections/abstract-selection-service';

@Component({
  selector: 'lib-selection-bar',
  templateUrl: './selection-bar.component.html',
  styleUrls: ['./selection-bar.component.scss']
})
export class SelectionBarComponent<T> implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('selectionBar')
  selectionBar: ElementRef;

  @Output()
  barHeight = new EventEmitter<number>();

  @Input()
  filteredItems: T[];

  @Input()
  allItems: T[];

  @Input()
  selectionActions: SelectionAction<T>[];

  @Input()
  selectionMarkingActions: SelectionAction<T>[];

  @Input()
  actionButtonTitle: string;

  @Output()
  selectedItemsToggle = new EventEmitter<string[]>();

  selectedToggle = false;

  unsubscribe = new Subject<void>();

  selection: ExportSelection<T> = {
    selectedItems: {},
    selectedItemCount: 0
  };

  constructor(protected selectionService: AbstractSelectionService<T>) {}

  ngOnInit() {
    this.selectionService
      .getSelection()
      .pipe(takeUntil(this.unsubscribe.asObservable()))
      .subscribe(selection => {
        this.selection = selection;
      });
  }

  ngAfterViewInit() {
    this.barHeight.emit((this.selectionBar.nativeElement as HTMLElement).offsetHeight);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getActions(): SelectionAction<T>[] {
    return this.selectionActions;
  }

  getSelectionMarkingActions(): SelectionAction<T>[] {
    return this.selectionMarkingActions;
  }

  selectAllFilteredItems() {
    this.selectionService.select(this.filteredItems);
  }

  selectAllItems() {
    this.selectionService.select(this.allItems);
  }

  toggleSelectedItems(forceState?: boolean) {
    if (this.selection.selectedItemCount !== 0) {
      if (!this.selectedToggle || forceState) {
        this.selectedItemsToggle.emit(Object.keys(this.selection.selectedItems));
        if (!forceState || !this.selectedToggle) {
          this.selectedToggle = !this.selectedToggle;
        }
      } else {
        this.selectedItemsToggle.emit([]);
        this.selectedToggle = !this.selectedToggle;
      }
    }
  }

  clearSelection() {
    this.selectionService.clearSelection();
  }

  deselectAllFilteredItems() {
    this.selectionService.deselect(this.filteredItems);
  }
}
