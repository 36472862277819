<div class="modal-header">
  <h4 class="modal-title" id="modal-title" translate>CREATE_NEW_XLSX_TEMPLATE</h4>
  <button type="button" class="close" aria-describedby="modal-title" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body row">
  <div class="col-12">
    <div class="form-group">
      <label for="templateId" class="control-label" translate>ID</label>
      <input class="form-control" type="text" name="templateId" id="templateId" [(ngModel)]="templateId" ngbAutofocus />
    </div>
    <div class="form-group">
      <label for="templateName" class="control-label" translate>NAME</label>
      <input
        class="form-control"
        type="text"
        name="templateName"
        id="templateName"
        [(ngModel)]="templateName"
        ngbAutofocus
      />
    </div>

    <div class="form-group">
      <label for="templatelist" translate>COPY_EXISTING_TEMPLATE</label>
      <select id="templatelist" class="custom-select" [(ngModel)]="selectedTemplate">
        <option *ngFor="let template of templatesList" [ngValue]="template">{{ template.name }}</option>
      </select>
    </div>
    <lib-gung-input-multi-select
      *ngIf="!!rolesList"
      class="multi-select-user-groups"
      [optionsList]="rolesList"
      (selectedValues)="setSelectedValues($event)"
      label="AVAILABLE_TO_ROLES"
    ></lib-gung-input-multi-select>

    <div class="form-group">
      <lib-gung-checkbox-input-horizontal
        tooltip="IS_DYNAMIC_DOCUMENT_TEMPLATE_TOOLTIP"
        gungTranslate="IS_DYNAMIC_DOCUMENT_TEMPLATE"
        [(ngModel)]="showXlsxAutomatically"
      >
      </lib-gung-checkbox-input-horizontal>
    </div>
  </div>

  <div class="col-12 pt-2 alert alert-danger mt-1" role="alert" *ngIf="error">
    <h5>{{ 'ERROR' | translate }}</h5>
    <h6>{{ error.message }}</h6>
  </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col d-flex justify-content-end">
      <button type="button" class="btn btn-light btn-sm" (click)="activeModal.close(false)" translate>CANCEL</button>
      <button type="submit" class="btn btn-primary btn-sm ml-2" (click)="create()" translate>CREATE</button>
    </div>
  </div>
</div>
