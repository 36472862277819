import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Cache } from '../../models/cache';

@Injectable({
  providedIn: 'root'
})
export class CachesService {
  constructor(private http: HttpClient) {}

  public getCaches(): Observable<Cache[]> {
    const url = `admin/json/caches/list`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '-1');

    return this.http.get<Cache[]>(url, { headers });
  }

  public getClearCache(cacheName: string): Observable<Cache[]> {
    const url = `admin/json/cache/${cacheName}/clear`;
    const headers = new HttpHeaders();
    // headers = headers.set('maxAge', '-1');

    return this.http.get<Cache[]>(url, { headers });
  }

  public getClearCacheKey(cacheName: string, key: string): Observable<Cache[]> {
    const url = `admin/json/cache/${cacheName}/clearkey?cacheKey=${encodeURIComponent(key)}`;
    const headers = new HttpHeaders();
    // headers = headers.set('maxAge', '-1');

    return this.http.get<Cache[]>(url, { headers });
  }

  public getClearAllCache(): Observable<Cache[]> {
    const url = `admin/json/caches/clear`;
    const headers = new HttpHeaders();
    // headers = headers.set('maxAge', '-1');

    return this.http.get<Cache[]>(url, { headers });
  }
}
