import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays, parseISO } from 'date-fns';
import { ConfigBaseFilter, ConfigService, ListLayout, ListSortOption, SimpleConfigBaseFilter, ListLayoutMultipleComponent } from 'gung-list';
import { forkJoin, map, mergeMap, Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { OrderFinderCardListComponent } from '../../components/order-finder-card-list/order-finder-card-list.component';
import { OrderFinderListLayoutComponent } from '../../components/order-finder-list-layout/order-finder-list-layout.component';
import { OrderFinderTableListComponent } from '../../components/order-finder-table-list/order-finder-table-list.component';
import { StandardStatisticsData, StatisticsListItem } from '../../models/statistics';
import { CustomerStatisticsService } from '../customer-statistics/customer-statistics.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { format } from 'date-fns';
import { gungComparatorHelper, gungGetPropertyOfObject } from 'gung-common';
import { ColumnSortSelectionService } from '../column-sort-selection/column-sort-selection.service';

@Injectable({
  providedIn: 'root'
})
export class OrderFinderConfigService implements ConfigService<StandardStatisticsData> {
  topFilter = false;

  /*
   This variable and setter is called in the invoice-list-component to set
   the customerId to the id in the route params. It did not work to get the routeparams
   from this service.
   */
  customerId: string;
  data: {[s: string]: StandardStatisticsData[]} = {};
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(
    protected customerStatisticsService: CustomerStatisticsService,
    protected selectedCustomerService: SelectedCustomerService,
    protected translateService: TranslateService,
    protected columnSortSelectionService: ColumnSortSelectionService
  ) {}

  getItems(route?: ActivatedRouteSnapshot): Observable<StandardStatisticsData[]> {
    return this.columnSortSelectionService.getSelectedPathSort().pipe(
      mergeMap(path => {
        return forkJoin([
          of(path),
          ((this.customerId && of(this.customerId)) || this.selectedCustomerService.getSelectedCustomer().pipe(first(), map(c => {this.customerId = c.id; return this.customerId; })))
        ])
      }),
      switchMap(([path, customerId]) => 
        forkJoin([
          of(path),
          (this.data[this.customerId] && of(this.data[this.customerId])) || this.customerStatisticsService.getStatisticsByCustomer(customerId)
        ])
      ),
      switchMap(([{path, sort}, list]) => {
        this.data[this.customerId] = list;
        if (!path) {
          return of(list);
        }
        if (path === 'orderRowTotalValueCurrency/orderRowTotalQuantity') {
          return of(list.sort((a, b) => (
            gungComparatorHelper(
              (gungGetPropertyOfObject('orderRowTotalValueCurrency', a) || 1) / (gungGetPropertyOfObject('orderRowTotalQuantity', a) || 1), 
              (gungGetPropertyOfObject('orderRowTotalValueCurrency', b) || 1) / (gungGetPropertyOfObject('orderRowTotalQuantity', b) || 1), 
            sort)))
          );
        }
        return of(list.sort((a, b) => gungComparatorHelper(gungGetPropertyOfObject(path, a), gungGetPropertyOfObject(path, b), sort)));
      })
    );
  }

  getFilters(route?: ActivatedRouteSnapshot): ConfigBaseFilter<StandardStatisticsData>[] {
    return [new OrderStatisticsDateFilter(this.translateService), new OrderStatisticsExpectedDeliveryFilter(this.translateService), new OrderStatisticsProductCategoryFilter()];
  }

  getSortOptions(route?: ActivatedRouteSnapshot): ListSortOption<StandardStatisticsData>[] {
    return [];
  }

  getBatchSizes(route?: ActivatedRouteSnapshot): number[] | undefined {
    return [100];
  }

  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderFinderTableListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order finder list'
      }
    ];
  }

  getSearchTerms(item: StandardStatisticsData): string[] {
    return [item.orderId, item.productId, item.productName];
  }

  getItemId(item: StandardStatisticsData): string {
    return item.id;
  }

  getNothingFoundTranslateTag(): string {
    return 'NOTHING_FOUND_ORDERS_CONTACT_GUNG'
  }
}

// export class OrderTypeFilter extends SimpleConfigBaseFilter<StandardStatisticsData> {
//   constructor(protected translateService: TranslateService) {
//     super();
//   }

//   getName(): string {
//     return 'ORDER_TYPE';
//   }
//   getOptionIds(item: StandardStatisticsData): string[] {
//     return item.
//   }
//   getOptionName(key: string): string {
//     throw new Error('Method not implemented.');
//   }
// }

export class OrderStatisticsDateFilter extends SimpleConfigBaseFilter<StandardStatisticsData> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'ORDER_DATE';
  }

  getOptionIds(item: StandardStatisticsData): string[] {
    return [format(new Date(item.orderCreationDate), 'yyMMdd')];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class OrderStatisticsExpectedDeliveryFilter extends SimpleConfigBaseFilter<StandardStatisticsData> {
  type = 'dateRangeFilter';

  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'EXPECTED_DELIVERY_DATE';
  }

  getOptionIds(item: StandardStatisticsData): string[] {
    if (item.orderDeliveryDate) {
      return [format(new Date(item.orderDeliveryDate || Date()), 'yyMMdd')];
    }
    return [];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class OrderStatisticsProductCategoryFilter extends SimpleConfigBaseFilter<StandardStatisticsData> {
  getName(): string {
    return 'PRODUCT_CATEGORY';
  }

  getOptionIds(item: StandardStatisticsData): string[] {
    return [item.productCategoryCode];
  }

  getOptionName(key: string): string {
    return key;
  }
}