import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivationEnd, Router } from '@angular/router';
import { filter, map, distinctUntilChanged, tap } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import { BackendFeatureService } from '../../../services/backend-feature.service';

@Component({
  selector: 'lib-edi-wrapper',
  templateUrl: './edi-wrapper.component.html',
  styleUrls: ['./edi-wrapper.component.css']
})
export class EdiWrapperComponent implements OnInit {
  minimize = false;
  isActuator = false;
  incomingOrderResponsesEnabled = false;

  constructor(
    private router: Router,
    protected authService: AuthService,
    protected backendFeatureService: BackendFeatureService
  ) {
    this.router.events
      .pipe(
        filter(event => event instanceof ActivationEnd),
        filter(event => (event as ActivationEnd).snapshot.component === EdiWrapperComponent),
        map(event => (event as ActivationEnd).snapshot.children.length),
        distinctUntilChanged()
      )
      .subscribe(children => (this.minimize = children !== 0));
    this.minimize = !this.router.url.endsWith('/edi');

    this.backendFeatureService.isActivated('gung-feature-edi.incoming-order-responses').subscribe(isActivated => {
      if (isActivated) {
        this.incomingOrderResponsesEnabled = true;
      }
    });
  }

  ngOnInit() {
    this.authService.getHasRoles('ACTUATOR').subscribe(isActuator => (this.isActuator = isActuator));
  }
}
