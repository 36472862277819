import { Injectable } from '@angular/core';
import { Product } from '../../models';
import { Assortment } from '../../services/assortment.service';
import { TranslateService } from '@ngx-translate/core';
import { PimTemplateProperties } from 'gung-list';
import { MetadataService } from '../../services/metadata/metadata.service';

@Injectable({
  providedIn: 'root'
})
export class CloudPimPropertiesHelperService {
  constructor(protected translateService: TranslateService, protected metadataService: MetadataService) { }

  public hasAnyPropertiesToShow(product: Product): boolean {
    const productDetailsTable = this.getPropertiesTable(product);
    for (const productDetail of productDetailsTable) {
      if (!!productDetail && !!productDetail.value && productDetail.value !== '') {
        return true;
      }
    }
    return false;
  }
  public getPropertiesTable(
    product: Product,
    assortment?: Assortment,
    propertiesToShow?: ('productTemplateProperties' | 'itemProperties' | 'skuProperties')[],
    showEmpty: boolean = false,
    translateBooleans: boolean = false
  ): { title: string; value: string }[] {
    const productDetailsTable = [];
    if (product?.extra?.itemProperties || product?.extra?.productTemplateProperties) {
      let itemProperties = [];
      if (propertiesToShow) {
        if (propertiesToShow.includes('itemProperties')) {
          itemProperties.push(...(product.extra.itemProperties || []));
        }
        if (propertiesToShow.includes('productTemplateProperties')) {
          itemProperties.push(...(product.extra.productTemplateProperties || []));
        }
        if (propertiesToShow.includes('skuProperties')) {
          itemProperties.push(...(product.extra.skuProperties || []));
        }
      } else {
        itemProperties = [
          ...(product.extra.itemProperties || []),
          ...(product.extra.productTemplateProperties || [])
        ];
      }
      for (const templateProp of itemProperties) {
        if (
          templateProp.isDisplayDetails &&
          productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
        ) {
          const value = this.getProductDetailTable(templateProp, product);
          if (Array.isArray(value)) {
            const valueArray = value.map(v => v.description).join(', ');
            productDetailsTable.push({ title: templateProp.translationKey, value: valueArray });
          } else if (templateProp.type === 'number' && !isNaN(value as any)) {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (typeof value === 'string' && value.trim().length !== 0) {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (typeof value === 'boolean') {
            if (translateBooleans) {
              productDetailsTable.push({ title: templateProp.translationKey, value: this.translateService.instant('BOOLEAN_' + (!!value)) });
            } else {
              productDetailsTable.push({ title: templateProp.translationKey, value });
            }
          } else if (value !== undefined && value !== null && typeof value !== 'object') {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (showEmpty) {
            productDetailsTable.push({ title: templateProp.translationKey, value: '' });
          }
        }
      }
    }
    if (assortment && (assortment.extra.itemProperties || assortment.extra.productTemplateProperties)) {
      const itemProperties = [
        ...(assortment.extra.itemProperties || []),
        ...(assortment.extra.productTemplateProperties || [])
      ];
      for (const templateProp of itemProperties) {
        if (
          templateProp.isDisplayDetails &&
          productDetailsTable.findIndex(prop => prop.title === templateProp.translationKey) === -1
        ) {
          const value = this.getProductDetailTable(templateProp, product);
          if (Array.isArray(value)) {
            const valueArray = value.map(v => v.description).join(', ');
            productDetailsTable.push({ title: templateProp.translationKey, value: valueArray });
          } else if (typeof value === 'string' && value.trim().length !== 0) {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (templateProp.type === 'number' && !isNaN(value as any)) {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (value !== undefined && value !== null) {
            productDetailsTable.push({ title: templateProp.translationKey, value });
          } else if (showEmpty) {
            productDetailsTable.push({ title: templateProp.translationKey, value: '' });
          }
        }
      }
    }
    return productDetailsTable;
  }

  getProductDetailTable(templateProp: PimTemplateProperties, product: Product): string {
    const fields = templateProp.path.split('.');

    if (!product || !fields || fields.length === 0) {
      return;
    }

    if (fields[0] === 'i18n') {
      fields.splice(1, 0, this.translateService.currentLang);
    }

    return this.getFieldOrPropertyFromProduct(templateProp, product, fields);
  }

  getFieldOrPropertyFromProduct(templateProp: PimTemplateProperties, product: Product, fields: string[]): string {
    // Get field form object if exist
    const getField = (obje: object, field: string) => {
      if (!obje.hasOwnProperty(field)) {
        return null;
      }
      return obje[field];
    };

    // Get property from object
    let obj: any = Object.assign({}, product.extra);
    for (const field of fields) {
      if (!obj) {
        break;
      }
      obj = getField(obj, field);
    }

    if (obj !== undefined && obj !== null) {
      if (templateProp.type === 'text') {
        return obj.description || obj;
      }
      if (templateProp.type === 'metadata') {
        if (obj.hasOwnProperty('description')) {
          // PIM metadata
          return obj.description;
        }
        if (templateProp.metaReference) {
          return this.metadataService.getMetadataValue(
            templateProp.metaReference.table,
            templateProp.metaReference.field,
            obj
          );
        }
        if (templateProp.metadata) {
          if (templateProp.metadata.split('.').length === 2) {
            const splitmeta = templateProp.metadata.split('.');
            const metaTable = splitmeta[0];
            const metaField = splitmeta[1];
            return this.metadataService.getMetadataValue(metaTable, metaField, obj);
          }
          return this.metadataService.getMetadataValue(templateProp.metadata, 'description', obj);
        }
      }
      return obj;
    }

    return null;
  }
}
