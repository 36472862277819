<div class="pt-1" *ngFor="let customer of data" [class.d-none]="isCustomerSelected(customer.id)">
  <div class="form-check d-flex align-items-center justify-content-between">
    <label class="form-check-label" [for]="customer.id">
      {{ customer.name + ' - (' + customer.id + ')' }}
    </label>
    <input
      type="checkbox"
      class="scale18"
      (change)="toggleSelection(customer.id)"
      [id]="customer.id"
      [checked]="isCustomerSelected(customer.id)"
    />
  </div>
  <hr />
</div>
