import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService, GungDownloadUrl } from 'gung-common';
import { catchError, filter, first, forkJoin, Observable, of } from 'rxjs';
import { SupplierPurchaseComment } from '../../../models';
import { AuthService } from '../../../services/auth/auth.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { SupplierPortalDocument, SupplierService } from '../../../services/suppliers/supplier.service';
import { User } from '../../../state/auth/types';

@Component({
  selector: 'lib-supplier-comments-modal',
  templateUrl: './supplier-comments-modal.component.html',
  styleUrls: ['./supplier-comments-modal.component.css']
})
export class SupplierCommentsModalComponent implements OnInit {

  isLoading = false;
  currentUser: User;

  @Input()
  comments: SupplierPurchaseComment[];

  @Input()
  purchaseOrderIds: string[];

  @Input()
  postComment: (comment: SupplierPurchaseComment) => Observable<any>;

  newComment: SupplierPurchaseComment = {
    documents: [],
    comment: undefined,
    date: new Date(),
    userId: undefined,
    read: false,
  };

  documents: SupplierPortalDocument[];

  constructor(
    public activeModal: NgbActiveModal,
    protected backendInterceptor: BackendInterceptor,
    protected authService: AuthService,
    public dateUtilService: DateUtilService,
    protected supplierService: SupplierService
  ) {
    authService.getCurrentUser().pipe(filter(user => !!user), first()).subscribe(user => this.currentUser = user);
  }

  ngOnInit(): void {
    forkJoin(
      this.purchaseOrderIds.map(id => this.supplierService.getDocumentByRowId(id).pipe(first(), catchError(err => of(null))))
    ).subscribe(docs => {
      this.documents = docs.flat().filter(doc => !!doc).reduce(function (acc: SupplierPortalDocument[], curr: SupplierPortalDocument) {
        if (acc.findIndex(a => a.id === curr.id) === -1) {
          acc.push(curr);
        }
        return acc;
      }, []);
      if(this.documents){
        this.updateDocuments(this.purchaseOrderIds);
        //this.updateDocuments(this.documents?.map(doc=>doc.id));
      }
    })
      this.updateComments(this.purchaseOrderIds);
  }

  save() {
    if (this.newComment.comment) {
      this.newComment.userId = this.currentUser.username;
      this.postComment(this.newComment).subscribe(result => {
        if (result) {
          this.activeModal.close(result);
        }
      });
    }
  }

  downloadFile(attachment) {
    let url: string;
    if (attachment.includes('/')) {
      url = GungDownloadUrl(attachment);
    }/*  else {
      url = this.backendInterceptor.getBaseUrl() + this.activityInternalService.getDocumentDownloadUrl(attachment);
    } */
    const win = window.open(url, '_blank');
    win.focus();
  }

  isImage({s3Uri}) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(s3Uri);
  }

  updateComments(ids: string[]) {
    this.newComment.read = true;
      this.supplierService.readComments(ids).subscribe(result => {
      });

  }

  updateDocuments(ids: string[]) {
      this.supplierService.readDocuments(ids).subscribe(result => {
      });

  }

}
