import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Offer } from '../../models/offer';

@Component({
  selector: 'lib-offer-card-list',
  templateUrl: './offer-card-list.component.html',
  styleUrls: ['./offer-card-list.component.css']
})
export class OfferCardListComponent extends ListItemRendererComponent<Offer> implements AfterViewInit {
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
