import { Injectable } from '@angular/core';
import { PdfDocument } from '../models/pdf-document';
import { first } from 'rxjs';
import { DynamicPdfService } from './dynamic-pdf.service';
import { CommonModalService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class PdfGeneratorService {
  constructor(protected dynamicPdfService: DynamicPdfService, protected gungModalService: CommonModalService) {}

  generatePdf(id: string) {
    this.dynamicPdfService
      .getPdf(id)
      .pipe(first())
      .subscribe(pdf => {
        const payload = {
          pages: pdf.pages,
          isHorizontal: pdf.isHorizontal,
          recipient: ''
        } as PdfDocument;
        this.gungModalService.openEmailConfirmExportModal('').then(res => {
          payload.recipient = res.email;
          this.dynamicPdfService.exportPdf(payload).subscribe();
        });
      });
  }
}
