<h3 class="d-block page-title text-center" translate>EDI - GLN numbers</h3>
<div class="row">
  <div class="col-2">
    <button type="button" class="btn btn-primary" (click)="downloadEanXlsx()" translate>DOWNLOAD_EAN_XLSX</button>
  </div>
  <div class="col-2">
    <button type="button" class="btn btn-secondary" (click)="sendSingleInvoice()" translate>
      SEND_SINGLE_EDI_INVOICE
    </button>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <lib-filter-list #list [configService]="configService"></lib-filter-list>
  </div>
</div>
