import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface FooterText {
  text: string;
}

export interface FooterImage {
  imageUrl: string;
}

export interface FooterLink {
  label: string;
  linkUrl: string;
}

@Injectable({
  providedIn: 'root'
})
export class FooterService {
  constructor() {}

  getLeftColumnContent(): (FooterText | FooterImage | FooterLink)[] {
    const base: (FooterText | FooterImage | FooterLink)[] = [
      {
        text: 'Gung default footer'
      },
      {
        text: 'You need to create a service that extends footer-service to customize the content'
      }
    ];
    return base;
  }
  getCenterColumnContent(): (FooterText | FooterImage | FooterLink)[] {
    const base: (FooterText | FooterImage | FooterLink)[] = [];
    // Insert images like this
    /*  {
        imageUrl: './../../../assets/logo-footer.png'
      }
    */
    return base;
  }
  getRightColumnContent(): (FooterText | FooterImage | FooterLink)[] {
    const base: (FooterText | FooterImage | FooterLink)[] = [
      {
        text: 'Extend your ERP',
        linkUrl: '#/products'
      }
    ];
    return base;
  }
}
