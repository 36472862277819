import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DeliveryLocationActionConfigService } from '../../services/delivery-location-action-config/delivery-location-action-config.service';
import { MetadataService } from '../../services/metadata/metadata.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';
import { CustomerListCardComponent } from '../customer-list-card/customer-list-card.component';

@Component({
  selector: 'lib-delivery-location-list-card',
  templateUrl: './delivery-location-list-card.component.html',
  styleUrls: ['./delivery-location-list-card.component.css']
})
export class DeliveryLocationListCardComponent extends CustomerListCardComponent {
  constructor(
    public selectCustomerService: SelectedCustomerService,
    public metadataService: MetadataService,
    public actionConfig: DeliveryLocationActionConfigService,
    public router: Router
  ) {
    super(selectCustomerService, router);
  }
}
