import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ConfigBaseFilter, FilterListComponent, PimTemplateProperties } from 'gung-list';
import { AssortmentRecursive } from '../../services/assortment.service';
import { ConceptTreeListConfigService } from './../../services/concept-tree-list-config/concept-tree-list-config.service';

@Component({
  selector: 'lib-concept-tree-list',
  template: '<lib-filter-list #conceptList [configService]="configService"></lib-filter-list>',
  styleUrls: ['./concept-tree-list.component.css']
})
export class ConceptTreeListComponent implements OnInit, OnChanges {
  @Input()
  private conceptChildren: AssortmentRecursive[];

  @Input()
  filters: ConfigBaseFilter<AssortmentRecursive>[] = [];

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  @ViewChild('conceptList') conceptList: FilterListComponent<AssortmentRecursive>;

  constructor(public configService: ConceptTreeListConfigService) {}

  ngOnInit() {
    this.configService.conceptObjectsSubject.next(this.conceptChildren);
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
  }

  ngOnChanges() {
    this.configService.conceptObjectsSubject.next(this.conceptChildren);
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
  }
}
