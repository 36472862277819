<div class="modal-header" *ngIf="!!title">
  <h4 class="modal-title" id="modal-basic-title" translate>{{ title }}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <span *ngIf="!htmlDescription; else htmlObject" class="text-break" translate>{{ description }}</span>
  <ng-template #htmlObject>
    <div [innerHtml]="description | safeHtml"></div>
  </ng-template>
</div>

<div class="modal-footer" [ngClass]="{'reverse-button-order': reverseButtonOrder}">
  <button *ngIf="!!btnTrue" class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close(true)" translate>
    {{ btnTrue }}
  </button>
  <button *ngIf="!!btnFalse" class="btn btn-sm btn-outline-primary px-4" (click)="activeModal.close(false)" translate>
    {{ btnFalse }}
  </button>
</div>
