import { Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { filter, first, map, mergeMap } from 'rxjs';
import { SelectionAction, ExportSelection } from 'gung-list';
import { Product } from '../../models/product';
import { ProductExportService } from '../product-export/product-export.service';
import { AuthService } from '../auth/auth.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ProductsBookmarkManagementModalComponent } from '../../components/products-bookmark/products-bookmark-management-modal/products-bookmark-management-modal.component';
import { MongoAssortmentManagementModalComponent } from '../../components/mongo-assortment-management-modal/mongo-assortment-management-modal.component';
import { Router } from '@angular/router';
import { DocumentTemplatesService } from '../document-templates/document-templates.service';
import { Template } from 'gung-common';
import { SelectProductImageExportSizeModalComponent } from '../../components/select-product-image-export-size-modal/select-product-image-export-size-modal.component';
import { ExportDragProductService } from 'gung-dynamic-pdf';
import { NavbarConfigService } from '../navbar-config/navbar-config.service';

@Injectable({
  providedIn: 'root'
})
export class ProductExportActionConfigService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();
  private enableExportPdf = this.navbarConfigService.enableExportPdf;

  constructor(
    protected productExportService: ProductExportService,
    protected authService: AuthService,
    protected modalService: NgbModal,
    protected router: Router,
    protected documentTemplatesService: DocumentTemplatesService,
    protected navbarConfigService: NavbarConfigService,
    protected exportDragProductService: ExportDragProductService
  ) {}

  userRoles(): Observable<string[]> {
    return this.authService.getRoles().pipe(filter(roles => !!roles));
  }
  getDynamicSelectionActions(): Observable<SelectionAction<Product>[]> {
    return forkJoin([
      this.authService.getRoles().pipe(first()),
      this.documentTemplatesService.getTemplates().pipe(first())
    ]).pipe(
      first(),
      mergeMap(([roles, templates]) => {
        const dynamicActions = [];
        templates.forEach(template => {
          if (template.showXlsxAutomatically && this.matchesAnyUserRole(template, roles)) {
            dynamicActions.push(this.getExportActionFromTemplate(template));
          }
        });
        return of(dynamicActions);
      })
    );
  }

  getSelectionActions(): Observable<SelectionAction<Product>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        let userActions: SelectionAction<Product>[] = [
          {
            label: 'PRODUCT_EXPORT_XLSX',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.values(selection.selectedItems);
              this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
              return of();
            }
          },
          {
            label: 'PRODUCT_EXPORT_IMG',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.values(selection.selectedItems);
              this.productExportService.exportImg(itemList).pipe(first()).subscribe();
              return of();
            }
          }
        ];
        if (roles.includes('SALES') || roles.includes('ADMIN')) {
          userActions = [
            {
              label: 'PRODUCT_EXPORT_XLSX',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportExcel(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_CSV',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportCsv(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_LINESHEET',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportLinesheet(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRICAT',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportPricat(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_IMG',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                const id = SelectProductImageExportSizeModalComponent.name;
                const ref = this.modalService.open(SelectProductImageExportSizeModalComponent, {
                  size: 'md',
                  windowClass: 'modal-md-custom'
                });
                ref.componentInstance.items = itemList;
                ref.result.then(
                  result => {
                    if (result.success) {
                      this.productExportService
                        .exportImg(itemList, {
                          imageTransformations: result.imageTransformations,
                          format: result.format
                        })
                        .pipe(first())
                        .subscribe();
                    }
                  },
                  reason => {}
                );
                this.modalRef.set(id, ref);
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRODUCT_INFO',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportProductInfo(itemList).pipe(first()).subscribe();
                return of();
              }
            },
            {
              label: 'PRODUCT_EXPORT_PRODUCT_STOCK',
              performAction: (selection: ExportSelection<Product>) => {
                const itemList = Object.values(selection.selectedItems);
                this.productExportService.exportProductStock(itemList).pipe(first()).subscribe();
                return of();
              }
            }
          ];
        }
        return userActions;
      })
    );
  }

  matchesAnyUserRole(template: Template, roles: string[]): boolean {
    if (!roles || !template.availableToRoles) {
      return false;
    }
    let matchesRole = false;
    roles.forEach(role => {
      if (template.availableToRoles.includes(role)) {
        matchesRole = true;
      }
    });
    return matchesRole;
  }
  getExportActionFromTemplate(template: Template): SelectionAction<Product> {
    return {
      label: template.name,
      performAction: (selection: ExportSelection<Product>) => {
        const itemList = Object.values(selection.selectedItems);
        if (template.type === 'PRODUCT') {
          this.productExportService.exportExcel(itemList, template.id).pipe(first()).subscribe();
        } else if (template.type === 'B24_PRICAT') {
          this.productExportService.exportPricat(itemList).pipe(first()).subscribe();
        } else {
          console.log('NO MATCHING export found. Please check you configuration');
        }

        return of();
      }
    };
  }
  getSelectionMarkingActions(): Observable<SelectionAction<Product>[]> {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        const actions: SelectionAction<Product>[] = [
          {
            label: 'BOOKMARKS',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.values(selection.selectedItems);
              const modal = this.modalService.open(ProductsBookmarkManagementModalComponent, { size: 'xl' });
              modal.componentInstance.products = itemList;
              return of();
            }
          }
        ];
        if (roles.includes('ADMIN')) {
          actions.push({
            label: 'ASSORTMENTS',
            performAction: (selection: ExportSelection<Product>) => {
              const modal = this.modalService.open(MongoAssortmentManagementModalComponent, { size: 'xl' });
              return of();
            }
          });
        }
        if (this.enableExportPdf) {
          actions.push({
            label: 'SEND_TO_PDF_EXPORT',
            performAction: (selection: ExportSelection<Product>) => {
              const itemList = Object.keys(selection.selectedItems);
              this.exportDragProductService.addListOfProducts(itemList);
              this.router.navigate(['export-pdf/new']);
              return of();
            }
          });
        }
        return actions;
      })
    );
  }
}
