import { Component, OnInit } from '@angular/core';
import { CommonModalService } from '../../../services/common-modal/common-modal.service';

@Component({
  selector: 'lib-email-export-confirm-modal',
  templateUrl: './email-export-confirm-modal.component.html',
  styleUrls: ['./email-export-confirm-modal.component.css']
})
export class EmailExportConfirmModalComponent implements OnInit {
  email = '';
  delegate: CommonModalService;
  componentId: string;

  ngOnInit() {
    this.componentId = EmailExportConfirmModalComponent.name;
  }

  confirm() {
    this.delegate.modalRef.get(this.componentId).close({ email: this.email });
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).dismiss();
  }
}
