<p>customer-list-table works!</p>

<gung-table 
  [data]="data"
  >
  <thead>
    <tr>
      <th [gungColumnSort]="sort()" gungColumnId="id">id</th>
      <th [gungColumnSort]="sortName()" gungColumnId="name">name</th>
      <th>etes</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let row of data">
      <td>{{row.id}}</td>
      <td>{{row.name}}</td>
      <td>
        <lib-gung-text-input
          [gungTranslate]="'ORDER_STATUS'"
          [renderStatic]="false"
          [ngModel]="row.name"
        ></lib-gung-text-input>
      </td>
    </tr>
  </tbody>

</gung-table>