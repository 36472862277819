import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-quotes-table',
  templateUrl: './quotes-table.component.html',
  styleUrls: ['./quotes-table.component.css']
})
export class QuotesTableComponent extends ListItemRendererComponent<Order[]> implements OnInit, AfterViewInit {
  constructor(public dateUtilService: DateUtilService) {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
