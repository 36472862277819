<div *ngIf="!isLoading">
  <div class="modal-header">
    <h3>
      <span translate>CUSTOMIZE</span> <span class="small ml-1">{{ cartRow.productId }}</span>
    </h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <div
        class="col-sm-12 col-md-4 p-1"
        *ngFor="
          let application of matchingLogoApplications | slice: (page - 1) * pageSize:(page - 1) * pageSize + pageSize
        "
      >
        <ngb-carousel
          [showNavigationIndicators]="false"
          [showNavigationArrows]="application.canvasS3Uris.length > 1"
          [interval]="0"
        >
          <ng-template ngbSlide *ngFor="let img of application.canvasS3Uris">
            <img class="img-fluid" [src]="img | gungImageUrl
              :'type:thumbnail'
            " alt="" />
          </ng-template>
        </ngb-carousel>

        <div *ngIf="!(cartRow.extra.appliedLogos[application.id] > 0)" class="mt-1">
          <button
            class="btn btn-sm btn-primary btn-block mb-0"
            (click)="add(application, 1)"
            [disabled]="getRemainingRowQuantity() <= 0"
            translate
          >
            ADD
          </button>
        </div>
        <div *ngIf="cartRow.extra.appliedLogos[application.id] > 0" class="mt-1">
          <div class="input-group">
            <div class="input-group-prepend">
              <button
                class="btn btn-primary btn-sm"
                (click)="sub(application, 1)"
                tabindex="-1"
                [disabled]="cartRow.extra.appliedLogos[application.id] <= 0"
              >
                <i class="fas fa-minus"></i>
              </button>
            </div>
            <input
              type="number"
              placeholder="0"
              class="form-control form-control-sm text-center"
              [(ngModel)]="cartRow.extra.appliedLogos[application.id]"
              (ngModelChange)="inputChanged(application)"
              (click)="$event.preventDefault(); $event.stopPropagation()"
              [ngModelOptions]="{ updateOn: 'blur' }"
            />
            <div class="input-group-append">
              <button class="btn btn-sm btn-primary" (click)="add(application, 1)" tabindex="-1">
                <i class="fas fa-plus"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="text-center">
          <h5 class="mt-2">{{ application.name }}</h5>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center mt-3 remaining-text">
      <span>{{ getRemainingRowQuantity() }}</span> <span class="ml-1" translate>REMAINING</span>
    </div>

    <div class="d-flex justify-content-center mt-1">
      <ngb-pagination
        [collectionSize]="matchingLogoApplications.length"
        [(page)]="page"
        [pageSize]="pageSize"
        [maxSize]="5"
        [rotate]="true"
        [ellipses]="false"
        [boundaryLinks]="true"
      ></ngb-pagination>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="close()" translate>
        CLOSE
      </button>
    </div>
  </div>
</div>

<ng-template *ngIf="isLoading" #loading> LOADING... </ng-template>
