import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from '../../../models';
import { Assortment } from '../../../services/assortment.service';
import { AuthService } from '../../../services/auth/auth.service';
import { AvailabilityService } from '../../../services/availability/availability.service';
import { PriceService } from '../../../services/price/price.service';
import { ProductService } from '../../../services/products/product.service';
import { QuickorderProduct } from '../../../services/quickorder/quickorder.service';
import { ProductDetailComponent } from '../../product-detail/product-detail.component';
import { TranslateService } from '@ngx-translate/core';
import { AssortmentService } from '../../../services/assortment.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';

@Component({
  selector: 'lib-quickorder-product-details',
  templateUrl: './quickorder-product-details.component.html',
  styleUrls: ['./quickorder-product-details.component.css']
})
export class QuickorderProductDetailsComponent extends ProductDetailComponent implements OnInit, QuickorderProduct {
  @Input()
  product: Product;

  @Output()
  updateQuantity = new EventEmitter<number>();

  @Input()
  assortment: Assortment;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService
  ) {
    super(
      route,
      productService,
      availabilityService,
      authService,
      priceService,
      metadataService,
      translateService,
      assortmentService,
      gungFlowService
    );
  }

  ngOnInit(): void {
    this.productId = this.product.id;
    super.ngOnInit();
  }

  addQuantity(qty) {
    this.updateQuantity.next(qty);
  }
}
