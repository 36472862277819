export interface CartRowIdentification {
  productId: string;
  targetStockId?: string;
  productPartialId?: string;
}

export interface CartRow extends CartRowIdentification {
  qty: number;
  timeAdded: Date;
  timeUpdated: Date;
  extra?: { [s: string]: any };
  rownr?: number;
}

export interface CartState {
  rows: CartRow[];
}

export const featureName = 'cart';
