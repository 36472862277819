import { Component, OnInit } from '@angular/core';
import { ProductInlineSearchListConfigService } from '../../services/product-inline-search-list-config/product-inline-search-list-config.service';

@Component({
  selector: 'lib-product-inline-search',
  template: ' <lib-inline-search #productList [configService]="configService"></lib-inline-search>',
  styleUrls: ['./product-inline-search.component.css']
})
export class ProductInlineSearchComponent {
  constructor(public configService: ProductInlineSearchListConfigService) {}
}
