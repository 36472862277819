<div class="form-group">
    <label [for]="inputId" *ngIf="!suppressLabel" [ngClass]="{ 'font-weight-bold': labelBold }">
        {{ translateTag | translate: translateParams }}
        <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
      </label>
    <div
      ngbDropdown
      #myDrop="ngbDropdown"
      [autoClose]="'outside'"
      placement="bottom-left"
      (openChange)="dropdownIsOpen = $event"
      [id]="inputId"
      class="p-0"
      [class.form-control]="!renderStatic"
    >
      <button
        [disabled]="renderStatic"
        class="w-100 border-0 text-left dropdown p-0"
        [ngClass]="{'btn btn-block': !renderStatic}"
        ngbDropdownToggle
      >
        <div class="d-flex align-items-center">
            <!-- <ng-container *ngIf="!renderStatic; else view">
                <lib-gung-text-input
                    suppressLabel="true"
                    [renderStatic]="true"
                    [ngModel]="viewValue"
                    class="w-100"
                ></lib-gung-text-input>
                <i
                    class="fa float-right ml-auto"
                    [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
                ></i>
            </ng-container>
            <ng-template #view>
                {{viewValue}}
                form-control-plaintext
            </ng-template> -->
          <lib-gung-text-input
            suppressLabel="true"
            [renderStatic]="true"
            [ngModel]="viewValue"
            class="w-100 form-control-plaintext p-0"
          ></lib-gung-text-input>
          <i
            *ngIf="!renderStatic"
            class="fa float-right ml-auto"
            [ngClass]="{ 'fa-chevron-down': !dropdownIsOpen, 'fa-chevron-up': dropdownIsOpen }"
          ></i>
        </div>
      </button>
      <ul class="dropdown-list" ngbDropdownMenu>
        <li *ngIf="list?.length > 0">
          <lib-filter-list-search (searchUpdated)="setSearch($event)"></lib-filter-list-search>
        </li>
        <li *ngIf="filteredList?.length === 0">
          <span translate>NO_RESULTS_FOUND</span>
        </li>
        <li
          *ngFor="let item of filteredList"
          class="item"
        >
          <button
            class="btn w-100 text-left"
            (click)="selected(item); $event.stopPropagation(); myDrop.close()"
            translate
          >
            {{ formatterView(item) }}
          </button>
        </li>
      </ul>
    </div>
</div>