<div *ngIf="!isLoading; else loading">
  <div class="modal-header">
    <h3>
      {{ product.name }} <span class="small">{{ product.id }}</span>
    </h3>
  </div>

  <div class="modal-body">
    <div class="row mb-3">
      <!-- Product img -->
      <div class="col-xs-12 col-md-3 col-lg-2">
        <img
          [src]="productImage.image.s3Uri
            | gungImageUrl
              :'type:thumbnail'
              :'etag:'+productImage.image.s3ETag
          "
          alt="{{ productImage.image.description }}"
          class="img-fluid mx-auto d-block"
        />
      </div>

      <!-- Product data -->
      <div class="col-xs-12 col-sm-auto">
        <div class="row">
          <div class="col-12">
            <h3>{{ product.name }}</h3>
          </div>
        </div>

        <div class="row" *ngIf="!!selectedProduct">
          <div class="col">
            <span translate>PRODUCT_ID</span>:
            <span> {{ selectedProduct.id }}</span>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <span>
              <span>{{ 'NET_PRICE' | translate }}</span
              >:
              <strong>
                <lib-price [price]="price" type="NET" [currency]="price?.customerNetPrice.currencyCode"></lib-price>
              </strong>
            </span>
          </div>
        </div>

        <div class="row" *ngIf="price?.recommendedRetailPrice">
          <div class="col">
            <span>
              <span>{{ 'RRP' | translate }}</span
              >:
              <strong>
                <lib-price
                  [price]="price"
                  type="RRP"
                  [currency]="price?.recommendedRetailPrice.currencyCode"
                ></lib-price>
              </strong>
            </span>
          </div>
        </div>

        <!-- Table properties for each erp here (necessary to extend this is standard-garp; standard-jeeves; ...) -->
      </div>
    </div>

    <div class="row">
      <div class="product-data-tabs">
        <lib-product-matrix [baseDetailData]="baseMultiDimensionData" (selectedId)="handleProductSelect($event)">
        </lib-product-matrix>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="closeMatrix()">
      {{ 'OK' | translate | uppercase }}
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
