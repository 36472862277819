<div class="row">
  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 pdf-entry new-pdf-entry" (click)="createNewPdf()">
    <i class="fas fa-plus"></i>
    <div class="new-pdf-text" translate>NEW</div>
  </div>
  <div class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3 pdf-entry" *ngFor="let pdf of data">
    <div class="card pdf-card">
      <div class="d-flex flex-row align-items-center">
        <div class="d-flex flex-column mb-3">
          <span class="name">{{ pdf.name }}</span>
        </div>
      </div>
      <div class="align-items-center">
        <lib-action-btn [item]="pdf" [config]="actionButtonConfig" [blockBtns]="blockBtns"></lib-action-btn>
      </div>
    </div>
  </div>
</div>
