<div class="my-company-wrapper">
  <div class="row">
    <div class="col-12">
      <h3 translate>THE_COMPANY</h3>
    </div>
  </div>
  <div class="row" *ngIf="form; else loading" [formGroup]="form">
    <div class="col-12">
      <nav ngbNav #nav="ngbNav" class="nav-tabs">
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'information'" ngbNavLink translate>INFORMATION</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6">
                <lib-gung-text-input gungTranslate="COMPANY_NAME" formControlName="name"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS1" formControlName="address1"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ADDRESS2" formControlName="address2"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="ZIP" formControlName="zipCode"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="CITY" formControlName="city"></lib-gung-text-input>
              </div>
              <div class="col-12 col-md-6">
                <lib-gung-text-input
                  gungTranslate="ORG_NUMBER"
                  formControlName="organizationNumber"
                ></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="EMAIL_ADDRESS" formControlName="email"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="TELEPHONE" formControlName="telephone"></lib-gung-text-input>
                <lib-gung-text-input gungTranslate="WEBADDRESS" formControlName="webAddress"></lib-gung-text-input>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'logo'" ngbNavLink translate>LOGO</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6">
                <h5 class="text-center">
                  <span translate>FAVICON</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['extra.favicon'],
                      imgTag: 'extra.favicon',
                      inputAccept: '.ico'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12 col-md-6">
                <h5 class="text-center">
                  <span translate>CUSTOMIZE_NAVBAR_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logo'],
                      imgTag: 'logo'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12 col-md-6">
                <h5 class="text-center">
                  <span translate>CUSTOMIZE_LOGIN_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logoLogin'],
                      imgTag: 'logoLogin'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12 col-md-6">
                <h5 class="text-center">
                  <span translate>CUSTOMIZE_ORDER_CONFIRM_LOGO</span>
                </h5>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['logoOrderConfirm'],
                      imgTag: 'logoOrderConfirm'
                    }
                  "
                ></ng-template>
              </div>
            </div>
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'footer'" ngbNavLink translate>FOOTER</a>
          <ng-template ngbNavContent>
            <lib-my-company-footer [company]="myCompany"></lib-my-company-footer>            
          </ng-template>
        </ng-container>
        <ng-container ngbNavItem>
          <a (click)="selectedNavItem = 'theme'" ngbNavLink translate>THEME</a>
          <ng-template ngbNavContent>
            <div class="row">
              <div class="col-12 col-md-6" formGroupName="colors">
                <h3 translate>COLOR</h3>

                <div class="d-flex align-items-end">
                  <lib-gung-text-input gungTranslate="PRIMARY_COLOR" formControlName="primary"></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker1.click()"
                    [ngStyle]="{ color: form.get('colors').get('primary').value }"
                  ></i>
                  <input #colorPicker1 type="color" formControlName="primary" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="SECONDARY_COLOR"
                    formControlName="secondary"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker2.click()"
                    [ngStyle]="{ color: form.get('colors').get('secondary').value }"
                  ></i>
                  <input #colorPicker2 type="color" formControlName="secondary" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="HOVER_COLOR"
                    formControlName="hover"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker3.click()"
                    [ngStyle]="{ color: form.get('colors').get('hover').value }"
                  ></i>
                  <input #colorPicker3 type="color" formControlName="hover" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <div class="d-flex align-items-end">
                  <lib-gung-text-input
                    gungTranslate="CONTRASTING_COLOR"
                    formControlName="contrasting"
                  ></lib-gung-text-input>
                  <i
                    class="fas fa-square fa-3x ml-2"
                    (click)="colorPicker4.click()"
                    [ngStyle]="{ color: form.get('colors').get('contrasting').value }"
                  ></i>
                  <input #colorPicker4 type="color" formControlName="contrasting" style="height: 0px; width: 0px; visibility: hidden;" class="mt-5">
                </div>
                <button type="button" (click)="setupCustomization()" class="btn btn-primary" translate>TEST</button>
                <hr />
                <div class="d-flex">
                  <h3 translate class="m-0">LOGIN_BACKGROUND</h3>
                </div>
                <ng-template
                  *ngTemplateOutlet="
                    uploadImage;
                    context: {
                      $implicit: objectFileImagePreview['loginBackgroundImage'],
                      imgTag: 'loginBackgroundImage'
                    }
                  "
                ></ng-template>
              </div>
              <div class="col-12 col-md-6" formGroupName="extra">
                <h3 translate>SCRIPT</h3>
                <div formGroupName="customization">
                  <lib-gung-text-area-input
                    suppressLabel="true"
                    gungTranslate="INDEX_HEADER"
                    formControlName="indexHead"
                    rowsNumber="10"
                  >
                  </lib-gung-text-area-input>
                </div>
              </div>
            </div>
          </ng-template>


          <ng-container ngbNavItem>
            <a (click)="selectedNavItem = 'adminConfigurationWrapper'" ngbNavLink translate>CONFIGURATION</a>
            <ng-template ngbNavContent>
              <lib-admin-configuration-wrapper></lib-admin-configuration-wrapper>
            </ng-template>
          </ng-container>

          <ng-container *ngIf="featureActivatedGuardWrapper.isActivated('gung-company-templates', '1.0.0', true) | async" ngbNavItem>
            <a (click)="selectedNavItem = 'adminCompanyTemplates'" ngbNavLink translate>COMPANY_TEMPLATES</a>
            <ng-template ngbNavContent>
              <lib-company-templates-list></lib-company-templates-list>
            </ng-template>
          </ng-container>
        </ng-container>
      </nav>

      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-right" *ngIf="shouldDisplayButtons()">
      <button type="button" class="btn btn-secondary" (click)="initForm()" translate>RESET</button>
      <button type="button" class="btn btn-primary" (click)="save()" translate>SAVE</button>
    </div>
  </div>
</div>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>

<ng-template #uploadImage let-fileImagePrev let-imgTag="imgTag" let-inputAccept="inputAccept">
  <lib-gung-input-file
    [accept]="inputAccept || 'image/*,application/pdf'"
    multiple="true"
    enableDragDrop="true"
    (onFileSelect)="onDrop($event, imgTag)" />
  <div *ngIf="fileImagePrev" class="text-center">
    <span class="d-block" translate>PREVIEW</span>
    @if(fileImagePrev.s3ETag && fileImagePrev.s3Uri.endsWith('.ico')) {
      <img class="img-fluid"
        [src]="'https://cdn2.gung.io/'+ fileImagePrev.s3Uri + '?etag='+fileImagePrev.s3ETag"
        alt="{{ fileImagePrev.description }}"
      />
    } @else if(fileImagePrev.s3ETag) {
      <img class="img-fluid"
        [src]="fileImagePrev.s3Uri | gungImageUrl
          :'etag:'+fileImagePrev.s3ETag
        "
        alt="{{ fileImagePrev.description }}"
      />
    } @else if(fileImagePrev.s3Uri) {
      <img class="img-fluid" [src]="fileImagePrev.s3Uri" alt="{{ fileImagePrev.name }}" />
    } @else {
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    }
    
  </div>
</ng-template>
