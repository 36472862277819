import { Injectable } from '@angular/core';
import { createEffect, Actions } from '@ngrx/effects';
import { filter, map, tap, mergeMap, first, debounceTime } from 'rxjs';
import { ActionTypes, LoadCart, ClearZeroQuantities, CartLoaded } from './actions';
import { Store } from '@ngrx/store';
import { AppState } from '../state.module';
import { CartKeyService } from '../../services/cart-key/cart-key.service';
import { CustomerActionTypes } from '../customer/actions';
import { CustomerSelected } from '../customer/actions';
import { CartRow } from './types';
import { GungFlowActionTypes } from '../flow/actions';

@Injectable()
export class CartEffects {
  constructor(private actions$: Actions, private store: Store<AppState>, private cartKeyService: CartKeyService) {}

  getCustomerOrFlowSelected$ = createEffect(() =>
    this.actions$.pipe(
      filter(
        action =>
          action.type === CustomerActionTypes.CustomerSelected || action.type === GungFlowActionTypes.FlowSelected
      ),
      mergeMap(() => this.cartKeyService.getCurrentCartKey()),
      map(cartKey => new LoadCart({ cartKey }))
    )
  );

  loadCart$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.LoadCart),
      map(action => action as LoadCart),
      map(action => action.payload.cartKey),
      map(cartKey => {
        const localStorageCartRows = localStorage.getItem(cartKey);
        if (localStorageCartRows !== null) {
          return this.mapCartFromLocalStorage(localStorageCartRows);
        } else {
          const oldCartKey = cartKey.substring(0, cartKey.lastIndexOf('_'));
          const oldCart = localStorage.getItem(oldCartKey);
          if (oldCart !== null && oldCart !== undefined) {
            return this.mapCartFromLocalStorage(oldCart);
          }
          return [];
        }
      }),
      map(cartRows => new CartLoaded(cartRows))
    )
  );

  clearZeroQuantities$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => {
        return (
          Object.keys(ActionTypes)
            .map(e => {
              return ActionTypes[e];
            })
            .indexOf(action.type) > -1
        );
      }),
      filter(action => action.type !== ActionTypes.LoadCart && action.type !== ActionTypes.ClearZeroQuantities),
      map(() => new ClearZeroQuantities())
    )
  );

  saveCart$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => {
          return (
            Object.keys(ActionTypes)
              .map(e => {
                return ActionTypes[e];
              })
              .indexOf(action.type) > -1
          );
        }),
        filter(action => action.type !== ActionTypes.LoadCart && action.type !== ActionTypes.ClearZeroQuantities),
        mergeMap(() => this.store.select(state => state.cart.rows)),
        debounceTime(500),
        tap(mappedStore => {
          this.cartKeyService
            .getCurrentCartKey()
            .pipe(first())
            .subscribe(cartKey => {
              localStorage.setItem(cartKey, JSON.stringify(mappedStore));
            });
        })
      ),
    { dispatch: false }
  );

  private mapCartFromLocalStorage = (storedString: string): CartRow[] => {
    const localStorageObject = JSON.parse(storedString) as CartRow[];
    return localStorageObject.map(row => {
      return {
        productId: row.productId,
        productPartialId: row.productPartialId,
        qty: row.qty,
        targetStockId: row.targetStockId,
        timeAdded: new Date(row.timeAdded),
        timeUpdated: new Date(row.timeUpdated),
        extra: row.extra
      };
    });
  };
}
