import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Customer } from '../../models';
import { CustomerContact } from '../../models/customerContact';
import { CustomerContactsGungService } from '../../services/customer-contacts-gung/customer-contacts-gung.service';
import { CustomerContactService } from '../../services/customer-contacts/customer-contact.service';
import { SelectedCustomerService } from '../../services/selected-customer/selected-customer.service';

@Component({
  selector: 'lib-customer-contact-add-edit-modal',
  templateUrl: './customer-contact-add-edit-modal.component.html',
  styleUrls: ['./customer-contact-add-edit-modal.component.css']
})
export class CustomerContactAddEditModalComponent implements OnInit {
  @Input()
  public customerContact: CustomerContact;
  @Input()
  public customer: Customer;
  customerContactForm: FormGroup;
  isAddingContact = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected customerContactsGungService: CustomerContactsGungService,
    protected selectedCustomerService: SelectedCustomerService,
    public activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.customerContactForm = this.formBuilder.group({
      phone: this.formBuilder.control(this.customerContact?.phone || ''),
      // mobile: this.formBuilder.control(this.customerContact?.extra?.gungContact?.mobile || ''),
      email: this.formBuilder.control(this.customerContact?.email || ''),
      name: this.formBuilder.control(this.customerContact?.name || ''),
      notes: this.formBuilder.control(this.customerContact?.notes || '')
    });
  }

  submitForm() {
    const customerId = this.customer?.id || this.customerContact?.customerId;
    const formRaw = this.customerContactForm.getRawValue();
    this.isAddingContact = true;
    const newCustomerContact: CustomerContact = {
      customerId,
      ...(this.customerContact || {}),
      ...formRaw
    };

    this.customerContactsGungService.updateCustomerContact(customerId, newCustomerContact).subscribe(() => {
      this.isAddingContact = false;
      this.customerContactsGungService.updateCustomerContactsSubject(customerId);
      this.activeModal.close('Closed');
    });
  }
}
