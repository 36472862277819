import { Injectable } from '@angular/core';

import { HttpClient, HttpParams } from '@angular/common/http';
import { Order } from '../../models/order';
import { Observable, of, forkJoin } from 'rxjs';
import { OrderRow } from '../../models/orderRow';
import { map, switchMap, first } from 'rxjs';
import { CustomerService } from '../customers/customer.service';
import { Customer } from '../../models';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { format } from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class OrderService {
  constructor(protected http: HttpClient, protected customerService: CustomerService) {}

  getOrders(customerId: string, nocache?: boolean, maxSize?: number, includeRows?: boolean): Observable<Order[]> {
    let url = `json/orders/list/${customerId}`;
    if (!!maxSize && !!includeRows) {
      url = `${url}?maxSize=${maxSize}&includeRows=1`;
    } else if (!!maxSize) {
      url = `${url}?maxSize=${maxSize}`;
    } else if (!!includeRows) {
      url = `${url}?includeRows=1`;
    }

    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<Order[]>(url, { headers }).pipe(
      switchMap(orders => forkJoin([of(orders), this.customerService.getCustomer(customerId).pipe(first())])),
      map(([orders, customer]) => orders.map(order => this.orderMapper(order, customer)))
    );
  }

  getOrder(orderId: string, nocache?: boolean): Observable<Order> {
    const url = `json/orders/${orderId}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<Order>(url, { headers }).pipe(
      switchMap(order =>
        forkJoin([of(order), this.customerService.getCustomer(order.deliveryCustomerId).pipe(first())])
      ),
      map(([order, customer]) => this.orderMapper(order, customer))
    );
  }

  getOpenOrders(nocache?: boolean, maxSize?: number, fromDate?: Date, toDate?: Date): Observable<Order[]> {
    let url = `json/open-orders`;
    let params: HttpParams;
    if (!!maxSize) {
      url += `?maxSize=${maxSize}`;
    }
    const headers = {
      maxAge: nocache ? '-1' : '60'
    };
    if(fromDate && toDate) {
      params = new HttpParams()
      .set('fromDate', fromDate.toString())
      .set('toDate', toDate.toString());
    }
    return this.http.get<Order[]>(url, { headers, params }).pipe(
      switchMap(orders => forkJoin([of(orders), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) =>
        orders.map(order => {
          const customer = customers.filter(_customer => _customer.id === order.deliveryCustomerId)[0];
          return this.orderMapper(order, customer);
        })
      )
    );
  }

  getQuotes(nocache?: boolean): Observable<Order[]> {
    const url = `json/quotes`;
    const headers = {
      maxAge: nocache ? '-1' : '60'
    };
    return this.http.get<Order[]>(url, { headers }).pipe(
      switchMap(orders => forkJoin([of(orders), this.customerService.getCustomers().pipe(first())])),
      map(([orders, customers]) =>
        orders.map(order => {
          const customer = customers.filter(_customer => _customer.id === order.deliveryCustomerId)[0];
          return this.orderMapper(order, customer);
        })
      )
    );
  }

  updateOrderRow(orderRow: OrderRow) {
    const url = 'json/order-rows';
    return this.http.put<OrderRow>(url, orderRow);
  }

  protected orderMapper(order: Order, customer: Customer): Order {
    return {
      ...order,
      extra: {
        ...order.extra,
        deliveryCustomer: customer
      }
    };
  }

  sendConfrimation(orderId: string, emailRecipients: string[]) {
    return this.http.post('json/order/send-confirmation', { emailRecipients, orderId });
  }
}
