import { Component, OnInit, Input, ViewChild, ComponentFactoryResolver, Type, OnDestroy } from '@angular/core';
import { CustomPageConfigService } from '../../../services/custom-page/custom-page-config.service';
import { CustomPageDirectiveDirective } from '../custom-page-directive/custom-page-directive.directive';
import { TemplateComponent, BackendTemplate } from '../custom-page-interface/template-component';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs';
import { HomeComponent } from '../../home/home.component';
import { CustomPageTemplateComponent } from '../custom-page-class/custom-page-template-component';

@Component({
  selector: 'lib-custom-page-display',
  templateUrl: './custom-page-display.component.html',
  styleUrls: ['./custom-page-display.component.css']
})
export class CustomPageDisplayComponent implements OnInit, OnDestroy {
  @ViewChild(CustomPageDirectiveDirective, { static: true })
  protected viewChild: CustomPageDirectiveDirective;

  protected layout: any; // CustomPageTemplateComponent;
  protected pageName: string;

  constructor(
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected customPageConfigService: CustomPageConfigService,
    protected route: ActivatedRoute,
    protected router: Router
  ) {}

  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.layout = this.route.snapshot.data.pageComponent;
    this.pageName = this.route.snapshot.data.pageName || this.route.snapshot.params?.id;
    if (this.layout === HomeComponent) {
      this.redirectNoMatchingCustomPage();
    } else if (this.layout && this.pageName) {
      this.customPageConfigService
        .getTemplateDataByUserGroups(this.pageName)
        .subscribe((templateData: BackendTemplate) => {
          this.loadComponent(this.layout, templateData);
        });
    }
  }

  ngOnDestroy(): void {
    this.router.onSameUrlNavigation = 'ignore';
  }

  redirectNoMatchingCustomPage() {
    this.router.navigate([this.customPageConfigService.getFallbackLink()]);
  }

  loadComponent(layoutComponent: Type<CustomPageTemplateComponent>, itemComponent: TemplateComponent) {
    const resolver = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.viewContainerRef;
    containerRef.clear();
    const componentFactory = containerRef.createComponent(resolver);
    const typedComponent = componentFactory.instance as CustomPageTemplateComponent;
    typedComponent.edit = false;
    typedComponent.data = itemComponent;
  }
}
