import { CartRow } from '../types';
import { updatePositionOfRows } from './add';
import { getIndexOfRow } from './common';

export const cartReorderReducer = (
  current: CartRow[],
  payload: { productId: string; targetStockId?: string; productPartialId?: string }[]
): CartRow[] => {
  let result = [];
  for (const row of payload) {
    let index = getIndexOfRow(current, row.productId, row.targetStockId, row.productPartialId);
    result.push(current[index]);
    current.splice(index, 1);
  }

  result = [...result, ...current];
  return updatePositionOfRows(result);
};
