<div *ngIf="topFilter; else sideFilter">
  <div *ngIf="selectedOptions && selectedOptions.length > 0 ">
    <span class="" translate>SELECTED_FILTERS</span>
    <button type="button" class="btn btn-sm btn-outline-danger ml-2 mb-1" (click)="deselectAll.emit()">
      <span class="mr-1"><i class="fa fa-times-circle"></i></span><span translate>CLEAR_ALL</span>
    </button>
    <button
      type="button"
      class="btn btn-sm btn-outline-dark ml-1 mb-1"
      *ngFor="let option of selectedOptions"
      (click)="clickRemoveFilter(option)"
    >
      <span class="mr-1"><i class="fa fa-times-circle"></i></span>{{ option.optionName | translate }}
    </button>
  </div>
</div>
<ng-template #sideFilter>
  <div class="filter-list-item" *ngIf="selectedOptions && selectedOptions.length > 0">
    <div class="filter-header px-4 py-3">
      <span translate>SELECTED_FILTERS</span>
      <span class="float-right hover-pointer" (click)="deselectAll.emit()">
        <i class="fas fa-times"></i>
      </span>
    </div>
    <div class="filter-body">
      <div *ngIf="showFilterTitle !== 'group'" class="px-3 py-2 d-flex flex-wrap">
        <button class="pill-button" *ngFor="let option of selectedOptions" (click)="clickRemoveFilter(option)">
          <span class="pr-1" translate>
            {{ option.optionName }} 
            <span *ngIf="showFilterTitle === 'minimal'">({{option.filterName | translate}})</span>
          </span>
          <i class="fas fa-times"></i>
        </button>
      </div>
      <div *ngIf="showFilterTitle === 'group'" class="filter-title">
        <div class="row" *ngFor="let optionGroup of (selectedOptionsGroupByFilter | keyvalue)">
          <div class="col-12">
            <span class="badge badge-light p-3 w-100" translate>{{optionGroup.key}}</span>
            <div class="px-3 py-2 d-flex flex-wrap">
              <button class="pill-button" *ngFor="let option of optionGroup.value" (click)="clickRemoveFilter(option)">
                <span class="pr-1" translate>
                  {{ option.optionName }}
                </span>
                <i class="fas fa-times"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div *ngFor="let showFilterRange of showRangeSelectedFilter">
      <div *ngIf="showFilterRange.show">
        <button class="pill-button" (click)="clickRemoveDateFilter(showFilterRange)">
          <span class="pr-1" translate>
            {{showFilterRange.text}}
          </span>
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
</ng-template>

