import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-modal-confirm-yes-no',
  templateUrl: './modal-confirm-yes-no.component.html',
  styleUrls: ['./modal-confirm-yes-no.component.css']
})
export class ModalConfirmYesNoComponent {
  title: string;
  description: string;
  btnTrue: string;
  btnFalse: string;
  htmlDescription: boolean;
  reverseButtonOrder: boolean;

  constructor(public activeModal: NgbActiveModal) { }
}
