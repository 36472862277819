import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  OnChanges,
  SimpleChanges
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';
import { forkJoin } from 'rxjs';
import { CartRow } from './../../../../state/cart/types';
import { Availability, SimplifiedAvailability } from './../../../../models/availability';
import { ProductDimension, ProductMultiDimension } from './../../../../models';
import { MatrixInputElement, MatrixSummaryElement } from './../../../../models/product-matrix';
import { CustomerProductPrice } from './../../../../models/price';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { CartService } from './../../../../services/cart/cart.service';
import { ProductMatrixWrapperInputElementComponent } from '../product-matrix-wrapper-input-element/product-matrix-wrapper-input-element.component';
import { ProductService } from './../../../../services/products/product.service';
import { BaseMultiDimensionData } from '../product-detail-matrix-modal/product-detail-matrix-modal.component';

export enum IdsToMapType {
  MultiDimension = 0,
  PrimaryDimension = 1,
  SecondaryDimension = 2
}

@Component({
  selector: 'lib-product-matrix',
  templateUrl: './product-matrix.component.html',
  styleUrls: ['./product-matrix.component.css']
})
export class ProductMatrixComponent implements OnInit, OnChanges {
  isloading = true;

  @Input()
  set baseDetailData(data: BaseMultiDimensionData) {
    this.product = data.product;
    this.price = data.price;
    this.availability = data.availability;
  }

  @Input()
  productPartialId?: string;

  @Output()
  selectedId = new EventEmitter<string>();

  product: ProductMultiDimension;
  price: CustomerProductPrice;
  availability: Availability;

  matrixInputMap: Map<string, MatrixInputElement> = new Map<string, MatrixInputElement>();
  matrixSummaryPrimaryDimensionMap: Map<string, MatrixSummaryElement> = new Map<string, MatrixSummaryElement>();
  matrixSummarySecondaryDimensionMap: Map<string, MatrixSummaryElement> = new Map<string, MatrixSummaryElement>();
  matrixSummaryAllMap: Map<string, MatrixSummaryElement> = new Map<string, MatrixSummaryElement>();
  primaryDimensionFilteredByFlowRequiredAvailability: ProductDimension[] = [];

  @ViewChildren(ProductMatrixWrapperInputElementComponent)
  children: QueryList<ProductMatrixWrapperInputElementComponent>;

  mapIds: Map<string, { modelId: string; primaryDimensionId: string; secondaryDimensionId: string }> = new Map<
    string,
    { modelId: string; primaryDimensionId: string; secondaryDimensionId: string }
  >();

  constructor(
    protected translateService: TranslateService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected productService: ProductService
  ) { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes.baseDetailData) {
      if (
        !!changes.baseDetailData.previousValue &&
        changes.baseDetailData.currentValue.product.id === changes.baseDetailData.previousValue.product.id &&
        changes.baseDetailData.currentValue.product.primaryDimension[0].id ===
        changes.baseDetailData.previousValue.product.primaryDimension[0].id
      ) {
        return;
      }

      this.isloading = true;

      this.removePrimaryDimensionIfBlocked();
      // remove secondary dimension blocked, not salable in any color
      this.removeSecondaryDimensionIfBlocked();

      const multiDimensionProduct: ProductMultiDimension = JSON.parse(JSON.stringify(this.product));
      const secondaryDimensionIds: string[] = [];
      multiDimensionProduct.primaryDimension.forEach(primaryDimension => {
        multiDimensionProduct.secondaryDimension.forEach(secondaryDimension => {
          secondaryDimensionIds.push(multiDimensionProduct.modelId + primaryDimension.id + secondaryDimension.id);
        });
      });

      forkJoin({
        flow: this.gungFlowService.getSelectedFlow().pipe(first()),
        blockedIds: this.productService.getBlockedProducts(secondaryDimensionIds).pipe(first())
      }).subscribe(({ flow, blockedIds }) => {
        const allSetOfIdsToBuilddMaps = this.getAllIdsRequiredToBuildMatrixMap(multiDimensionProduct);

        this.buildMatrixMaps(
          blockedIds,
          allSetOfIdsToBuilddMaps,
          flow.useAvailabilities,
          flow.requireAvailability,
          flow.requireCurrentAvailability,
          flow.disableNonAvailable
        );

        secondaryDimensionIds.forEach(sku => {
          this.setTotalQuantity(sku);
        });

        this.filterPrimaryDimensionByFlowRequiredAvailability(flow.requireAvailability);

        this.isloading = false;
      });
    }
  }

  removePrimaryDimensionIfBlocked(): void {
    for (const primaryDimensionId of this.product.primaryDimension.map(primaryDimension => primaryDimension.id)) {
      if (this.isPrimaryDimensionBlocked(primaryDimensionId)) {
        const index = this.product.primaryDimension.findIndex(
          primaryDimension => primaryDimension?.id === primaryDimensionId
        );
        if (index > -1) {
          this.product.primaryDimension.splice(index, 1);
        }
      }
    }
  }

  removeSecondaryDimensionIfBlocked(): void {
    for (const secondaryDimensionId of this.product.secondaryDimension.map(
      secondaryDimension => secondaryDimension.id
    )) {
      if (this.isSecondaryDimensionBlocked(secondaryDimensionId)) {
        const index = this.product.secondaryDimension.findIndex(
          secondaryDimension => secondaryDimension?.id === secondaryDimensionId
        );
        if (index > -1) {
          this.product.secondaryDimension.splice(index, 1);
        }
      }
    }
  }

  setTotalQuantity(productMultiDimensionId: string): void {
    this.cartService
      .getCurrentCart()
      .pipe(first())
      .subscribe((cartRows: CartRow[]) => {
        // update cell all
        const allCartRowsToSum = cartRows.filter(row => this.matrixSummaryAllMap.get('').ids.includes(row.productId));
        this.matrixSummaryAllMap.get('').value = this.sumArray(allCartRowsToSum.map(row => row.qty));

        // update primary dimension cell
        for (const entry of Array.from(this.matrixSummaryPrimaryDimensionMap)) {
          if (entry[1].ids.includes(productMultiDimensionId)) {
            const cartRowsToSum = cartRows.filter(row => entry[1].ids.includes(row.productId));
            this.matrixSummaryPrimaryDimensionMap.get(entry[0]).value = this.sumArray(
              cartRowsToSum.map(row => row.qty)
            );
            break;
          }
        }

        // update secondary dimension cell
        for (const entry of Array.from(this.matrixSummarySecondaryDimensionMap)) {
          if (entry[1].ids.includes(productMultiDimensionId)) {
            const cartRowsToSum = cartRows.filter(row => entry[1].ids.includes(row.productId));
            this.matrixSummarySecondaryDimensionMap.get(entry[0]).value = this.sumArray(
              cartRowsToSum.map(row => row.qty)
            );
            break;
          }
        }
      });
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key);
  }

  copyDown(operation: string, currentRowOrder: number): void {
    this.copyRowsDown(operation, currentRowOrder, this.children);
  }

  filterPrimaryDimensionByFlowRequiredAvailability(requireAvailability: boolean): void {
    // IN CASE required availability
    // THEN check the maxFutureStock for each secondary Dimension
    //    IF any secondary dimension has some future stock
    //        THEN this primary dimension is displayed
    //        OTHERWISE it is not

    if (requireAvailability) {
      this.primaryDimensionFilteredByFlowRequiredAvailability = this.product.primaryDimension.filter(primaryDimension =>
        this.anyMaxFutureStock(primaryDimension.id)
      );
    } else {
      this.primaryDimensionFilteredByFlowRequiredAvailability = this.product.primaryDimension;
    }
  }

  public trackByFn(index: number, item: ProductDimension): any {
    return index;
  }

  protected isBlocked(allBlockedIds: { [productId: string]: boolean }, productMultiDimensionId: string): boolean {
    // check if product is blocked (data from service getBlockedProducts call)
    // if not, check also the blocked array of the multidimension product
    if (allBlockedIds[productMultiDimensionId]) {
      return true;
    }

    const key = 'blocked';
    if (this.product[key]) {
      const blockedIds = (this.product[key] as string[]) || [];
      return blockedIds.includes(productMultiDimensionId);
    }

    return false;
  }

  private isPrimaryDimensionBlocked(primaryDimensionId: string): boolean {
    const keyBlocked = 'blocked';
    if (this.product[keyBlocked]) {
      const blockedIds = (this.product[keyBlocked] as string[]) || [];
      return blockedIds.includes(this.product.modelId + primaryDimensionId);
    }

    return false;
  }

  private isSecondaryDimensionBlocked(secondaryDimensionId: string): boolean {
    // IF secondaryDimension with id aaa, is not available in the matrix due to SIZEaaa being blocked, this is a general rule for all ERP
    // THEN remove the secondary dimensions that are blocked
    const keyBlocked = 'blocked';
    if (this.product[keyBlocked]) {
      const blockedIds = (this.product[keyBlocked] as string[]) || [];
      return blockedIds.includes('SIZE' + secondaryDimensionId);
    }

    return false;
  }

  protected anyMaxFutureStock(primaryDimensionId: string): boolean {
    let allMaxFutureStocks = 0;

    const subAvailabilitiesKey = 'subAvailabilities';
    const subAvailabilitiesByPrimaryDimension = Object.values(this.availability.extra[subAvailabilitiesKey])
      .map(x => x as Availability)
      .filter(av => av.id.startsWith(this.product.modelId + primaryDimensionId));

    for (const subAvailability of subAvailabilitiesByPrimaryDimension) {
      allMaxFutureStocks += subAvailability.maxFutureStock;
    }

    return allMaxFutureStocks !== 0;
  }

  protected getSimplifiedAvailability(productMultiDimensionIds: string[]): SimplifiedAvailability {
    const simplifiedAvailability = {
      currentStock: 0,
      currentAvailability: 0,
      maxFutureStock: 0
    };

    const subAvailabilitiesKey = 'subAvailabilities';
    const subAvailabilitiesByProductMultiDimensionIds = Object.entries(this.availability.extra[subAvailabilitiesKey])
      .filter(([key, _]) => productMultiDimensionIds.includes(key))
      .map(([_, value]) => value as Availability);

    for (const availability of subAvailabilitiesByProductMultiDimensionIds) {
      simplifiedAvailability.currentStock += availability.currentStock;
      simplifiedAvailability.currentAvailability += availability.currentAvailability;
      simplifiedAvailability.maxFutureStock += availability.maxFutureStock;
    }

    return simplifiedAvailability;
  }

  getAllIdsRequiredToBuildMatrixMap(
    product: ProductMultiDimension
  ): { idsToMapType: IdsToMapType; idsMultiDimensionToMap: string[]; idsToMap: Map<string, string[]> }[] {
    // Lets considering the next matrix
    // 91461-B-S    91461-B-M   91461-B-L   91461-B-XL
    // 91461-C-S    91461-C-M   91461-C-L   91461-C-XL
    // 91461-D-S    91461-D-M   91461-D-L   91461-D-XL

    // This method as ouput will produce the next elements
    // multiDimensionIds --> arrays which contains all the ids in the matrix
    // primaryDimensionIds --> only ids by primaryDimenison (row) --> it produces the next map
    //      key: -B-; values: 91461-B-S    91461-B-M   91461-B-L   91461-B-XL
    //      key: -C-; values: 91461-C-S    91461-C-M   91461-C-L   91461-C-XL
    //      key: -S-; values: 91461-D-S    91461-D-M   91461-D-L   91461-D-XL
    // secondaryDimensionIds --> only ids by secondaryDimenison (collumn) --> it produces the next map
    //      key: S; values: 91461-B-S    91461-C-S   91461-D-S
    //      key: M; values: 91461-B-M    91461-C-M   91461-D-M
    //      key: L; values: 91461-B-L    91461-C-L   91461-D-L
    //      key: XL;values: 91461-B-XL   91461-C-XL  91461-D-XL

    // load multiDimensionProducts
    const multiDimensionIds: string[] = [];
    const primaryDimensionIds: Map<string, string[]> = new Map<string, string[]>();
    const secondaryDimensionIds: Map<string, string[]> = new Map<string, string[]>();

    // go through the product primary dimension
    for (const primaryDimensionId of product.primaryDimension.map(x => x.id)) {
      const idsToPrimaryDimension: string[] = [];
      primaryDimensionIds.set(primaryDimensionId, idsToPrimaryDimension);

      // go through the product secondary dimension
      for (const secondaryDimensionId of product.secondaryDimension.map(x => x.id)) {
        // multidimension
        multiDimensionIds.push(product.modelId + primaryDimensionId + secondaryDimensionId);

        // by primary dimension
        idsToPrimaryDimension.push(product.modelId + primaryDimensionId + secondaryDimensionId);

        // by secondary dimension
        const idsToSecondaryDimension: string[] = product.primaryDimension
          .map(x => x.id)
          .map(primDimId => product.modelId + primDimId + secondaryDimensionId);

        secondaryDimensionIds.set(secondaryDimensionId, idsToSecondaryDimension);

        this.mapIds.set(product.modelId + primaryDimensionId + secondaryDimensionId, {
          modelId: product.modelId,
          primaryDimensionId,
          secondaryDimensionId
        });
        this.mapIds.set(product.modelId + primaryDimensionId, {
          modelId: product.modelId,
          primaryDimensionId,
          secondaryDimensionId: null
        });
        this.mapIds.set(product.modelId, {
          modelId: product.modelId,
          primaryDimensionId: null,
          secondaryDimensionId: null
        });
      }
    }

    return [
      {
        idsMultiDimensionToMap: multiDimensionIds,
        idsToMap: null,
        idsToMapType: IdsToMapType.MultiDimension
      },
      {
        idsMultiDimensionToMap: null,
        idsToMap: primaryDimensionIds,
        idsToMapType: IdsToMapType.PrimaryDimension
      },
      {
        idsMultiDimensionToMap: null,
        idsToMap: secondaryDimensionIds,
        idsToMapType: IdsToMapType.SecondaryDimension
      }
    ];
  }

  protected buildMatrixMaps(
    allBlockedMultiDimensionIds: { [productId: string]: boolean },
    idsToMap: { idsToMapType: IdsToMapType; idsMultiDimensionToMap: string[]; idsToMap: Map<string, string[]> }[],
    showAvailability: boolean,
    requireAvailability: boolean,
    requireCurrentAvailability: boolean,
    disableNonAvailable: boolean
  ): void {
    const subAvailabilitiesKey = 'subAvailabilities';

    // MULTI_DIMENSION --> input elements inherit from buy-btn
    idsToMap
      .find(ids => ids.idsToMapType === IdsToMapType.MultiDimension)
      .idsMultiDimensionToMap.forEach(productMultiDimensionId => {
        const matrixIds = this.mapIds.get(productMultiDimensionId);

        let readonly = this.isBlocked(allBlockedMultiDimensionIds, productMultiDimensionId);
        if (requireAvailability || disableNonAvailable) {
          readonly =
            readonly ||
            (this.availability.extra[subAvailabilitiesKey][productMultiDimensionId] as Availability)?.maxFutureStock <=
            0;
        } else if (requireCurrentAvailability) {
          readonly =
            readonly ||
            (this.availability.extra[subAvailabilitiesKey][productMultiDimensionId] as Availability)
              ?.currentAvailability <= 0;
        }

        this.matrixInputMap.set(productMultiDimensionId, {
          readOnly: readonly,
          id: productMultiDimensionId,
          availability: showAvailability
            ? this.availability.extra[subAvailabilitiesKey][productMultiDimensionId]
            : null,
          product: this.product.extra._secondaryDimension?.find(s => s?.id === matrixIds.modelId + matrixIds.primaryDimensionId + matrixIds.secondaryDimensionId)
            || this.product.extra._secondaryDimension?.find(s => s?.id === matrixIds.modelId + matrixIds.primaryDimensionId)
        });
      });

    // set map entries for totals of primary dimension
    idsToMap
      .find(ids => ids.idsToMapType === IdsToMapType.PrimaryDimension)
      .idsToMap.forEach((value, key) => {
        this.matrixSummaryPrimaryDimensionMap.set(key, {
          value: 0,
          availability: showAvailability ? this.getSimplifiedAvailability(value) : null,
          ids: value
        });
      });

    // set map entries for totals of secondary dimension
    idsToMap
      .find(ids => ids.idsToMapType === IdsToMapType.SecondaryDimension)
      .idsToMap.forEach((value, key) => {
        const matrixIds = this.mapIds.get(value[0]);
        this.matrixSummarySecondaryDimensionMap.set(key, {
          value: 0,
          availability: showAvailability ? this.getSimplifiedAvailability(value) : null,
          ids: value,
          product:
            value.length > 0 &&
            this.product.extra._secondaryDimension?.find(s => s?.id === matrixIds.modelId + matrixIds.primaryDimensionId)
        });
      });

    // set map entries for to display total of all
    this.matrixSummaryAllMap.set('', {
      value: 0,
      availability: showAvailability
        ? {
          currentAvailability: this.availability.currentAvailability,
          currentStock: this.availability.currentStock,
          maxFutureStock: this.availability.maxFutureStock
        }
        : null,
      ids: idsToMap.find(ids => ids.idsToMapType === IdsToMapType.MultiDimension).idsMultiDimensionToMap // all multidimension product ids
    });
  }

  protected sumArray(values: number[]): number {
    return values.reduce((a, b) => a + b, 0);
  }

  private copyRowsDown(operation: string, currentRowOrder: number, children: any): void {
    // Get all matrix children elements
    const allChildrenElements: ProductMatrixWrapperInputElementComponent[] = children.toArray();

    // Get childrens for the current row index
    const selectedRowCollumnChildrens: ProductMatrixWrapperInputElementComponent[] = allChildrenElements.filter(
      x => x.rowOrder === currentRowOrder
    );

    // Get all childrens to set values
    let allChildrenToSet: ProductMatrixWrapperInputElementComponent[];
    if (operation === 'all') {
      allChildrenToSet = allChildrenElements.filter(x => x.rowOrder > currentRowOrder);
    } else if (operation === 'once') {
      allChildrenToSet = allChildrenElements.filter(x => x.rowOrder === currentRowOrder + 1);
    }

    // collections which will contains all the matrix inputs to update
    const matrixInputsToUpdate: {
      productId: string;
      qty: number;
      targetStockId?: string;
      productPartialId?: string;
    }[] = [];

    // Set values for children elements
    if (allChildrenToSet && allChildrenToSet.length > 0) {
      let indexCollumn = 0;
      for (const selectedRowCollumnChildren of selectedRowCollumnChildrens) {
        let indexRow = indexCollumn;

        while (indexRow < allChildrenToSet.length) {
          if (!selectedRowCollumnChildren.readOnly) {
            if (!allChildrenToSet[indexRow].readOnly) {
              const valueToCopy = selectedRowCollumnChildren.productMatrixInputElement.currentQty;
              const valueToReplace = allChildrenToSet[indexRow].productMatrixInputElement.currentQty;

              if (valueToCopy !== valueToReplace) {
                matrixInputsToUpdate.push({
                  productId: allChildrenToSet[indexRow].productMatrixInputElement.id,
                  qty:
                    valueToCopy >= allChildrenToSet[indexRow].productMatrixInputElement.minimumOrderQuantity
                      ? valueToCopy
                      : 0,
                  targetStockId: allChildrenToSet[indexRow].productMatrixInputElement.targetStockId,
                  productPartialId: this.productPartialId
                });
              }
            }
          }

          indexRow += selectedRowCollumnChildrens.length;
        }
        indexCollumn++;
      }
    }

    if (matrixInputsToUpdate.length > 0) {
      this.cartService.bulkSetProductQuantity(matrixInputsToUpdate);
    }
  }

  focusNext(id: string, productModelId: string, primaryDimensionId: string, secondaryDimensionId: string) {
    const idxSecondary = this.product.secondaryDimension.findIndex(s => s.id === secondaryDimensionId);
    let idNext;
    if (idxSecondary < this.product.secondaryDimension.length - 1) {
      idNext = productModelId + primaryDimensionId + this.product.secondaryDimension[idxSecondary + 1].id;
    } else {
      const idxPrimary = this.primaryDimensionFilteredByFlowRequiredAvailability.findIndex(s => s.id === primaryDimensionId);
      if (idxPrimary < this.primaryDimensionFilteredByFlowRequiredAvailability.length - 1) {
        idNext = productModelId + this.primaryDimensionFilteredByFlowRequiredAvailability[idxPrimary + 1].id + this.product.secondaryDimension[0].id;
      }
    }
    if (idNext) {
      document.getElementById(idNext)?.focus();
    }
  }
}
