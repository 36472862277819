import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AssortmentManagementService } from '../../../services/assortment-management/assortment-management.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-assortment-management-import',
  templateUrl: './assortment-management-import.component.html',
  styleUrls: ['./assortment-management-import.component.css']
})
export class AssortmentManagementImportComponent {
  public files: File[] = [];
  loading = false;

  constructor(
    protected assortmentManagementService: AssortmentManagementService,
    protected translateService: TranslateService,
    protected gungModalService: GungModalService
  ) {}

  uploadFile({ files }) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('document', file);
    }

    this.assortmentManagementService.importAssortmentExcel(formData).subscribe(
      data => {
        const msg = 'Sucessfully imported';
        this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
        this.loading = false;
      },
      error => {
        const msg = this.translateService.instant('ERROR');
        this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
        this.loading = false;
      }
    );
  }
}
