<div class="row">
  <!-- <div class="col-12 col-md-8">
        <h3>{{product.name}}</h3>
        <small>{{product.id}}</small>
    </div> -->
  <!-- <div class="col-12 col-md-4">
        <div class="row">
            <div class="col-6">
                <div class="btn-group w-100 mb-1" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="addQuantity(-1)">-1</button>
                    <button type="button" class="btn btn-success" (click)="addQuantity(+1)">+1</button>
                </div>
            </div>
            <div class="col-6">
                <div class="btn-group w-100 mb-1" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="addQuantity(-5)">-5</button>
                    <button type="button" class="btn btn-success" (click)="addQuantity(+5)">+5</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="btn-group w-100 mb-1" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="addQuantity(-10)">-10</button>
                    <button type="button" class="btn btn-success" (click)="addQuantity(+10)">+10</button>
                </div>
            </div>
            <div class="col-6">
                <div class="btn-group w-100 mb-1" role="group" aria-label="Basic example">
                    <button type="button" class="btn btn-secondary" (click)="addQuantity(-25)">-25</button>
                    <button type="button" class="btn btn-success" (click)="addQuantity(+25)">+25</button>
                </div>
            </div>
        </div>
    </div> -->
  <div class="col-xs-12 col-sm-6">
    <img
      class="img-fluid"
      *ngIf="product?.extra?.images?.length > 0"
      [src]="product.extra.images[0].s3Uri
        | gungImageUrl
          :'type:detail'
          :'orientation:portrait'
          :'etag:'+product.extra.images[0].s3ETag
      "
      alt="{{ product.extra.images[0].description }}"
    />
  </div>
  <div class="col-xs-12 col-sm-6">
    <h1>{{ product.name }} - {{ product.id }}</h1>
    <p>{{ product.description }}</p>

    <div class="price-wrapper row text-left mb-1" *ngIf="product.productType !== 'concept'">
      <div class="col-auto mr-auto">
        <span *ngIf="price">
          <lib-price
            [price]="price"
            type="GROSS"
            [currency]="price.customerNetPrice.currencyCode"
            [product]="product"
          ></lib-price>

          <span *ngIf="price.customerDiscountAmount.value > 0">
            (-{{ price.customerDiscountPercent | number: '1.2-2' }}%)
          </span>
        </span>
      </div>
      <div class="col-auto text-muted" *ngIf="product.productType !== 'concept'">
        <span *ngIf="price && price.recommendedRetailPrice">
          {{ 'RRP' | translate }}
          <lib-price
            [price]="price"
            type="RRP"
            [currency]="price.customerNetPrice.currencyCode"
            [product]="product"
          ></lib-price>
        </span>
      </div>
    </div>
  </div>
</div>
