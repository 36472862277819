<div class="card mb-2">
  <div class="card border h-100" (click)="selectButton.toggleSelection($event)"
    [class.border-selected]="selectButton.isSelected">
    <div class="card-body">
      <div class="card-title row">
        <div class="col">
          <a [routerLink]="data.id" class="border-0" style="color: unset;">
            <h5 class="customer-name" (click)="selectCustomerWithoutNavigate(data.id)">
              <i class="fas fa-eye pr-1"></i> {{ data.name }} - <small>{{ data.id }}</small> &nbsp;
            </h5>
          </a>
        </div>
        <div class="col-auto">
          <lib-action-btn [item]="data" [config]="actionButtonsConfig"></lib-action-btn>
        </div>
      </div>
      <div class="card-text">
        <div class="row">
          <div class="col col-sm-6 col-md-4">
            <lib-gung-text-input
              [gungTranslate]="'ADDRESS'"
              [renderStatic]="true"
              [ngModel]="data.extra?.gme?.address?.address1 || ' '"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [gungTranslate]="'ZIP'"
              [renderStatic]="true"
              [ngModel]="data.extra?.gme?.address?.zipCode || ''"
            ></lib-gung-text-input>
          </div>
          <div class="col col-sm-6 col-md-4">
            <lib-gung-text-input
              [gungTranslate]="'CITY'"
              [renderStatic]="true"
              [ngModel]="data.extra?.gme?.address?.city || ''"
            ></lib-gung-text-input>
            <lib-gung-text-input
              [gungTranslate]="'COUNTRY'"
              [renderStatic]="true"
              [ngModel]="data.extra?.gme?.address?.countryCode || ''"
            ></lib-gung-text-input>
          </div>
          <div class="col col-sm-6 col-md-4">
            <lib-gung-text-input
              [gungTranslate]="'ORG_NR'"
              [renderStatic]="true"
              [ngModel]="data.extra?.gme?.organizationNumber || ''"
            ></lib-gung-text-input>
          </div>
        </div>
      </div>
    </div>
  </div>
  <lib-item-selection-button [item]="data" class="d-none" #selectButton [id]="data.id"></lib-item-selection-button>
</div>
