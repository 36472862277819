import { Component, OnInit } from '@angular/core';
import { CustomPageTemplateComponent } from '../../custom-page-class/custom-page-template-component';
import { TemplateComponent } from '../../custom-page-interface/template-component';
import { CustomPageHtmlEditorComponent } from '../../custom-page-html-editor/custom-page-html-editor.component';


const dataDefault: TemplateComponent = {
  templateName: null,
  elements: {
    html: {
      link: {
        enabled: false,
        internal: true,
        url: ''
      },
      image: {
        enabled: false,
        sourceUrl: ''
      },
      text: {
        enabled: true,
        text: '',
      }
    },
  }
};

@Component({
  selector: 'lib-html-template',
  templateUrl: './html-template.component.html',
  styleUrls: ['./html-template.component.css']
})
export class HtmlTemplateComponent extends CustomPageTemplateComponent {

  getDefaultData(): TemplateComponent {
    return dataDefault;
  }

}
