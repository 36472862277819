import { Injectable } from '@angular/core';
import {
  PaginationConfigService,
  ConfigBaseFilter,
  ListSortOption,
  ListLayoutSingleComponent,
  SearchRequest,
  SearchResult
} from 'gung-list';
import { Product } from '../../models';
import { ProductService } from '../products/product.service';
import { AuthService } from '../auth/auth.service';
import { ListLayout } from 'gung-list';
import { ProductCardComponent } from '../../components/product-card/product-card.component';
import { Observable, of } from 'rxjs';
import { first, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProductPaginationListConfigService implements PaginationConfigService<Product> {
  constructor(protected productService: ProductService, protected authService: AuthService) {}

  getFilters(): ConfigBaseFilter<Product>[] {
    return [];
  }

  getSortOptions(): ListSortOption<Product>[] {
    return [];
  }
  getBatchSizes(): number[] {
    return [24, 48];
  }

  getLayouts(): ListLayout<Product>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ProductCardComponent,
        getListLayoutComponent: () => ListLayoutSingleComponent,
        getName: () => 'Product grid'
      }
    ];
  }

  getPagedItems(request: SearchRequest): Observable<SearchResult<Product>> {
    return this.authService.getCurrentUser().pipe(
      first(),
      mergeMap(user => {
        request.assortment = user.assortment;
        return this.productService.getPagedProducts(request);
      })
    );
  }

  getItems(): Observable<Product[]> {
    return of([]);
  }

  getItemId(item: Product) {
    return item.id;
  }

  getSearchTerms(item: Product) {
    return [item.id, item.name];
  }
}
