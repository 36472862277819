import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'lib-input-save-modal',
  templateUrl: './input-save-modal.component.html',
  styleUrls: ['./input-save-modal.component.css']
})
export class InputSaveModalComponent implements OnInit {
  @Input() label;

  @Input() title;

  @Input() isTextArea = false;

  value: string;
  delegate;
  componentId: string;

  constructor() {}

  ngOnInit() {
    this.componentId = InputSaveModalComponent.name;
  }

  close() {
    this.delegate.modalRef.get(this.componentId).close();
  }

  save() {
    this.delegate.modalRef.get(this.componentId).close({ value: this.value });
  }
}
