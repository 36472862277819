<div class="row" [ngClass]="{ 'mt-4 mb-4': data && data.length > 0 }">
  <div *ngFor="let brand of data" class="col-6 col-md-4 col-lg-3 mb-2">
    <div class="card concept-card">
      <div class="card-body">
        <div class="image-wrapper" [routerLink]="['/admin/related-brands/', brand.id]">
          <img
            class="card-img-top img-fluid mx-auto d-block"
            *ngIf="brand.images[0] && brand.images[0].s3ETag && brand.images[0].s3Uri; else noimage"
            [src]="brand.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'etag:'+brand.images[0].s3ETag
            "
            alt=""
          />
          <ng-template #noimage>
            <img
              class="card-img-top img-fluid mx-auto d-block"
              [src]="'images/no-image.jpg' | gungImageUrl"
              alt="NO IMAGE"
            />
          </ng-template>
        </div>
        <div class="brand-content">
          <div class="text-truncate brand-name" translate>{{ brand.name }}</div>
          <div>{{ brand.id }}</div>
          <button
            type="button"
            [id]="brand.id"
            class="btn btn-primary w-100 mt-2 mb-4 cart-btn text-uppercase"
            [routerLink]="['/admin/related-brands/', brand.id]"
          >
            {{ 'MANAGE_BRAND' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
