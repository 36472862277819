<div *ngIf="!isLoading; else loading">
  <div class="modal-header">
    <h4 class="modal-title mt-0" id="modal-basic-title"><span translate>EDIT</span>&nbsp;</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col-12" [formGroup]="form">
        <lib-gung-text-input-horizontal
          gungTranslate="ID"
          formControlName="id"
          placeholder="NOT_ASSIGNED"
          [renderStatic]="true"
        ></lib-gung-text-input-horizontal>
        <lib-gung-text-input-horizontal
          gungTranslate="TYPE"
          formControlName="type"
          placeholder="NOT_ASSIGNED"
          [renderStatic]="true"
        ></lib-gung-text-input-horizontal>
        <lib-gung-text-input-horizontal
          gungTranslate="NAME"
          formControlName="name"
          placeholder="NOT_ASSIGNED"
        ></lib-gung-text-input-horizontal>
        <div formGroupName="i18n">
          <div *ngFor="let lang of translateLangs" [formGroupName]="lang">
            <lib-gung-text-input-horizontal
              gungTranslate="DESCRIPTION_"
              [translateParams]="{ lang: lang }"
              formControlName="description"
              placeholder="NOT_ASSIGNED"
            ></lib-gung-text-input-horizontal>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-secondary col" (click)="activeModal.dismiss('cancel click')" translate>
      CANCEL
    </button>
    <button type="button" class="btn btn-sm btn-default btn-primary col" (click)="onSave()" translate>SAVE</button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
