import { EventEmitter, Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService, GungNotificationService } from 'gung-common';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';
import { EdiIncomingDespatchAdvice, EdiMessageBase, GungEdimessageType } from '../../models/edi';
import { EdiMessageBaseActionConfigService } from '../edi-message-base-action-config/edi-message-base-action-config.service';
import { Observable, switchMap, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { EdiService } from '../edi/edi.service';
import { GungModalService } from '../gung-modal/gung-modal.service';

@Injectable({
  providedIn: 'root'
})
export class EdiIncomingDespatchAdvicesActionConfigService
  extends EdiMessageBaseActionConfigService
  implements ActionButtonConfigService<EdiIncomingDespatchAdvice>
{
  constructor(
    protected ediService: EdiService,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected backend: BackendInterceptor,
    protected router: Router,
    protected http: HttpClient,
    protected gungNotificationService: GungNotificationService
  ) {
    super(ediService, gungModalService, authService, backend, router, http, gungNotificationService);
  }

  protected getMessageType(): GungEdimessageType {
    return GungEdimessageType.INCOMING_DESPATCH_ADVICE;
  }

  getButtons(
    item: EdiMessageBase,
    eventEmitter: EventEmitter<EdiMessageBase>
  ): Observable<ActionButton<EdiMessageBase>[]> {
    return this.authService.getHasRoles('ACTUATOR').pipe(
      switchMap(isActuator => {
        const baseActions: ActionButton<EdiMessageBase>[] = [];
        if (item.gungEdiMessageSendStatus !== 'Sent') {
          baseActions.push({
            title: 'VALIDATE',
            action: (ediMessage: EdiMessageBase) => this.validateMessage(ediMessage, event, eventEmitter)
          });
        }
        if (['Sent', 'ReadyToSend', 'ErrorWhileSending'].includes(item.gungEdiMessageSendStatus)) {
          const readyToSend = item.gungEdiMessageSendStatus === 'ReadyToSend';
          // baseActions.push({
          //   title: readyToSend ? 'SEND_MESSAGE' : 'SEND_MESSAGE_AGAIN',
          //   action: (ediMessage: EdiMessageBase) =>
          //     this.messageTransformAndSend(ediMessage, readyToSend, event, eventEmitter)
          // });
          baseActions.push({
            title: readyToSend ? 'POST_MESSAGE' : 'POST_MESSAGE_AGAIN',
            action: (ediMessage: EdiMessageBase) => this.messagePostToERP(ediMessage, readyToSend, event, eventEmitter)
          });
        }
        // baseActions.push({
        //   title: 'DESPATCH_ADVICE_DETAILS',
        //   action: (ediMessage: EdiMessageBase) =>
        //     this.redirectToDespatchAdviceDetails(ediMessage.documentReferences.supplierOrder.id, event)
        // });

        if (isActuator) {
          baseActions.push({
            title: 'DOWNLOAD_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.downloadMessage(ediMessage, event)
          });
          baseActions.push({
            title: 'ARCHIVE_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.archiveMessage(ediMessage, event, eventEmitter)
          });
        }

        return of(baseActions);
      })
    );
  }

  messagePostToERP(message: EdiMessageBase, readyToSend: boolean, element, eventEmitter: EventEmitter<EdiMessageBase>) {
    gungAddRemoveSpinner(element.target);
    this.gungModalService
      .openConfirmYesNoModal(
        readyToSend ? 'SEND_MESSAGE' : 'SEND_MESSAGE_AGAIN',
        readyToSend ? 'WANT_TO_SEND_MESSAGE' : 'WANT_TO_SEND_MESSAGE_AGAIN',
        {},
        'YES',
        'NO'
      )
      .then(answer => {
        if (answer) {
          this.gungNotificationService.notify('INFO', 'MESSAGE_SENDING', 'success');

          this.ediService
            .getMessagePostToERP(message.id, this.getMessageType())
            .subscribe(result => {})
            .add(() => {
              this.ediService.getMessageById(message.id, this.getMessageType()).subscribe(updatedMessage => {
                if (updatedMessage.errors.length > 0) {
                  this.gungNotificationService.notify('ERROR', 'ERROR_WHILE_SENDING_MESSAGE', 'error');
                } else {
                  this.gungNotificationService.notify('SUCCESS', 'MESSAGE_SENT_SUCCESSFULLY', 'success');
                }
                gungAddRemoveSpinner(element.target);
                eventEmitter.emit(updatedMessage);
              });
            });
        } else {
          gungAddRemoveSpinner(element.target);
        }
      })
      .catch(res => {
        this.gungNotificationService.notify('ERROR', 'ERROR_WHILE_SENDING_MESSAGE', 'error');
        gungAddRemoveSpinner(element.target);
      });
  }
}
