<div>
  <div class="row">
    <div class="col">
      <button
        [class.button-active]="showProductList"
        type="button"
        mat-button
        class="btn btn-sm btn-outline-primary px-4"
        (click)="openOptionsProductList()"
      >
        <span *ngIf="showProductList">Hide Products</span>
        <span *ngIf="!showProductList">Show Products</span>
      </button>
      <button
        [class.button-active]="showDigitalAssets"
        type="button"
        mat-button
        *ngIf="dynamicPdfConfigService.includesDigitalAssets"
        class="btn btn-sm btn-outline-primary px-4"
        (click)="openOptionsDigitalAssets()"
      >
        <span *ngIf="showDigitalAssets">Hide Digital Assets</span>
        <span *ngIf="!showDigitalAssets">Show Digital Assets</span>
      </button>
      <lib-add-standalone-note></lib-add-standalone-note>
    </div>
    <div class="col-auto"></div>
  </div>

  <div class="row">
    <div class="products-list border-right" [ngClass]="{ 'col-4': showProductList, 'd-none': !showProductList }">
      <lib-product-export-drag-in></lib-product-export-drag-in>
    </div>
    <div
      class="products-list border-right"
      [ngClass]="{ 'col-4': showDigitalAssets, 'd-none': !showDigitalAssets }"
      *ngIf="dynamicPdfConfigService.includesDigitalAssets"
    >
      <lib-export-digital-assets-drag-in></lib-export-digital-assets-drag-in>
    </div>
    <div
      [ngClass]="{ 'col-8': showProductList || showDigitalAssets, 'col-12': !showProductList && !showDigitalAssets }"
    >
      <lib-product-export-drag-drop
        *ngIf="renderPage"
        [pdfDocument]="pdfDocument"
        [refresh]="showProductList || showDigitalAssets"
      >
      </lib-product-export-drag-drop>
    </div>
  </div>
</div>
