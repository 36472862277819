<table class="table table-borderless table-striped table-hover">
  <thead>
    <tr>
      <th scope="col" translate class="cursor-pointer" class="cursor-pointer" (click)="sortByPath('id')">ORDER_ROW
        <i
            *ngIf="currentSortPath === 'id' || currentSortPath === '-id'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-id',
                'fa-sort-amount-up': currentSortPath === 'id'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('productId')">PRODUCT
        <i
            *ngIf="currentSortPath === 'productId' || currentSortPath === '-productId'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-productId',
                'fa-sort-amount-up': currentSortPath === 'productId'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderRowTotalQuantity')">QUANTITY
        <i
            *ngIf="currentSortPath === 'orderRowTotalQuantity' || currentSortPath === '-orderRowTotalQuantity'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderRowTotalQuantity',
                'fa-sort-amount-up': currentSortPath === 'orderRowTotalQuantity'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderRowTotalValueCurrency/orderRowTotalQuantity')">UNIT_PRICE
        <i
            *ngIf="currentSortPath === 'orderRowTotalValueCurrency/orderRowTotalQuantity' || currentSortPath === '-orderRowTotalValueCurrency/orderRowTotalQuantity'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderRowTotalValueCurrency/orderRowTotalQuantity',
                'fa-sort-amount-up': currentSortPath === 'orderRowTotalValueCurrency/orderRowTotalQuantity'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderRowDiscountPercent')">DISCOUNT
        <i
            *ngIf="currentSortPath === 'orderRowDiscountPercent' || currentSortPath === '-orderRowDiscountPercent'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderRowDiscountPercent',
                'fa-sort-amount-up': currentSortPath === 'orderRowDiscountPercent'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderRowTotalValueCurrency')">TOTAL
        <i
            *ngIf="currentSortPath === 'orderRowTotalValueCurrency' || currentSortPath === '-orderRowTotalValueCurrency'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderRowTotalValueCurrency',
                'fa-sort-amount-up': currentSortPath === 'orderRowTotalValueCurrency'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderCreationDate')">ORDER_DATE
        <i
            *ngIf="currentSortPath === 'orderCreationDate' || currentSortPath === '-orderCreationDate'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderCreationDate',
                'fa-sort-amount-up': currentSortPath === 'orderCreationDate'
            }"
        ></i>
      </th>
      <th scope="col" translate class="cursor-pointer" (click)="sortByPath('orderDeliveryDate')">DELIVERY_DATE
        <i
            *ngIf="currentSortPath === 'orderDeliveryDate' || currentSortPath === '-orderDeliveryDate'"
            class="fas"
            [ngClass]="{
                'fa-sort-amount-down': currentSortPath === '-orderDeliveryDate',
                'fa-sort-amount-up': currentSortPath === 'orderDeliveryDate'
            }"
        ></i>
      </th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let element of data">
      <td>
        <a [href]="'orders/' + element.orderId"> {{ element.id.replace('_', ' - ') }} </a>
      </td>
      <td>{{ element.productId }} - {{ element.productName }}</td>
      <td>
        {{ element.orderRowTotalQuantity }}
      </td>
      <td *ngIf="element.currency; else unitPriceNoCurrency">
        <lib-price
          *ngIf="element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity"
          [price]="element.orderRowTotalGrossValueCurrency / element.orderRowTotalQuantity"
          [currency]="element.currency"
        ></lib-price>

        <lib-price
          *ngIf="!element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity && element.orderRowTotalValueCurrency"
          [price]="element.orderRowTotalValueCurrency / element.orderRowTotalQuantity"
          [currency]="element.currency"
        ></lib-price>

        <lib-price
          *ngIf="(!element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity && !element.orderRowTotalValueCurrency) || !element.orderRowTotalQuantity"
          [price]="0"
          [currency]="element.currency"
        ></lib-price>
      </td>
      <ng-template #unitPriceNoCurrency>
        <td>
          <div *ngIf="element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity">
            {{
              (element.orderRowTotalGrossValueCurrency / element.orderRowTotalQuantity | number:'1.2-2')
            }}
          </div>
          <div *ngIf="!element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity && element.orderRowTotalValueCurrency">
            {{
              (element.orderRowTotalValueCurrency / element.orderRowTotalQuantity | number:'1.2-2')
            }}
          </div>
          <div *ngIf="(!element.orderRowTotalGrossValueCurrency && element.orderRowTotalQuantity && !element.orderRowTotalValueCurrency) || !element.orderRowTotalQuantity">
            {{
              (0 | number:'1.2-2')
            }}
          </div>
        </td>
      </ng-template>
      <td>
        {{ !!element.orderRowDiscountPercent ? element.orderRowDiscountPercent : 0 }}%
      </td>
      <td *ngIf="element.currency; else totalNoCurrency">
        <lib-price
          [price]="element.orderRowTotalValueCurrency"
          [currency]="element.currency"
        ></lib-price>
      </td>
      <ng-template #totalNoCurrency>
        <td>
          {{
            (element.orderRowTotalValueCurrency | number:'1.2-2')
          }}
        </td>
      </ng-template>
      <td>
        {{ (element.orderCreationDate | date: dateUtilService.dateFormat) || '-' }}
      </td>
      <td>
        {{ (element.orderDeliveryDate | date: dateUtilService.dateFormat) || '-' }}
      </td>
    </tr>
  </tbody>
</table>
