import { Component, OnInit } from '@angular/core';
import { SaveCartModalConfigService } from '../../services/save-cart-modal-config/save-cart-modal-config.service';

@Component({
  selector: 'lib-save-cart-modal',
  templateUrl: './save-cart-modal.component.html',
  styleUrls: ['./save-cart-modal.component.css']
})
export class SaveCartModalComponent implements OnInit {
  cartTitle = '';
  delegate: SaveCartModalConfigService;

  constructor() {}

  ngOnInit() {}

  close() {
    this.delegate.modalRef.get(SaveCartModalComponent.name).close('Closed');
  }

  saveCart() {
    this.delegate.modalRef.get(SaveCartModalComponent.name).close({ cartTitle: this.cartTitle });
  }
}
