import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { OrderStatistic } from '../../models/order-statistics';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class TopProductsActionButtonConfigService implements ActionButtonConfigService<OrderStatistic> {
  base = [
    {
      title: 'CREATE_PO',
      action: (item: OrderStatistic) => {
        this.gungModalService.openPurchaseOrder(item);
      }
    }
  ];

  constructor(protected gungModalService: GungModalService) {}

  getButtons(item: OrderStatistic): Observable<ActionButton<OrderStatistic>[]> {
    return of([]);
    return of(this.base); // to work remove the upper line
  }
}
