import { Pipe, PipeTransform } from "@angular/core";
import { gungComparatorHelper, gungGetPropertyOfObject } from "../utils/gung-utils";

@Pipe({
  name: 'orderBy'
})
export class OrderByPipe implements PipeTransform {
  constructor() { }
  /**
   * 
   * @param value original array list
   * @param type ascending or descending
   * @param path object path
   * @param sortIndex array to use in sort by index
   * @returns 
   */
  transform(value: any[], type: 'asc' | 'desc' = 'asc', path?: string | any /* FUNCTION */, sortIndex?: any[]): any[] {
    if (!value) {
      return value;
    }
    const ascSort = type === 'asc' ? 1 : -1;
    return value.sort((a, b) => {
      let valueA = a;
      let valueB = b;
      if (typeof path === 'function') {
        return path(valueA, valueB) * ascSort;
      }
      if (path) {
        valueA = gungGetPropertyOfObject(path, a);
        valueB = gungGetPropertyOfObject(path, b);
      }
      return gungComparatorHelper(valueA, valueB, ascSort, sortIndex);
    })
    
  }
}