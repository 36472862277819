import { Injectable } from '@angular/core';
import { first } from 'rxjs';
import { SupplierPurchaseOrder, SupplierTableColumns, ColumnRowTableType } from '../../models';
import { gungGetPropertyOfObject, gungSetPropertyOfObject } from '../../utils/gung-utils';
import { SupplierService } from '../suppliers/supplier.service';
import { TableRecord } from '../../state/metadata/types';

export const getValuesDynamic = (
  row: SupplierPurchaseOrder,
  path: string | string[] | (string | string[])[],
  iteration: number = 0
) => {
  if (Array.isArray(path)) {
    if (iteration === 0) {
      return path.map(p => getValuesDynamic(row, p, iteration + 1)).join(', ');
    }
    return path.map(p => getValuesDynamic(row, p, iteration + 1)).join('');
  }
  return gungGetPropertyOfObject(path, row);
};

export const setValueDynamic = (row: SupplierPurchaseOrder, path: string, value: any, forceDelete?: boolean) => {
  const result = gungSetPropertyOfObject(row, path, value, forceDelete);
  if (!result) {
    console.log('NOT SAVED - result', result);
  }
};

@Injectable({
  providedIn: 'root'
})
export class SupplierDynamicColumnsService {
  protected poStatusColumnTable: { [s: string]: SupplierTableColumns[] } = {
    // NEW
    10: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo', 'extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT,
        sorted: true
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.gungPurchaseLine.requestedReceiptDate',
        type: ColumnRowTableType.DATE,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.gungPurchaseLine.no', 'extra.gungPurchaseLine.description'],
        type: ColumnRowTableType.TEXT,
        sorted: true,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: 'QUANTITY_ORDERED',
        path: 'extra.gungPurchaseLine.quantity',
        type: ColumnRowTableType.NUMBER,
        sorted: true
      },
      {
        name: 'PRICE',
        path: ['extra.gungPurchaseLine.unitCost', 'extra.gungPurchaseLine.lineDiscount'],
        type: ColumnRowTableType.PRICE,
        sorted: true
      },
      {
        name: 'TOTAL_PRICE',
        path: 'extra.gungPurchaseLine.lineAmount',
        type: ColumnRowTableType.PRICE,
        sorted: true
      },
      {
        name: 'QUANTITY_CONFIRM',
        path: 'extra.gungPurchaseLine_qtyToReceive',
        type: ColumnRowTableType.INPUTNUMBER,
        extra: {
          thClass: 'col-1',
          required: true,
          copyTo: 'extra.gungPurchaseLine.qtyToReceive',
          delete: 'extra.gungPurchaseLine_qtyToReceive',
          maxValue: item => {
            return item.extra.gungPurchaseLine.qtyToReceive;
          },
          minValue: item => 0,
          conditions: [
            {
              comparator: '<',
              path1: 'extra.gungPurchaseLine_qtyToReceive',
              path2: 'extra.gungPurchaseLine.qtyToReceive',
              result: item => {
                // If they don't confirm all quantities requested, need to split the row first
                return this.supplierService
                  .postPurchaseOrderSplit(item.id, item.extra.gungPurchaseLine_qtyToReceive)
                  .pipe(first());
              }
            }
          ]
        }
      },
      {
        name: 'CONFIRM_READY_DATE',
        path: 'extra.gungPurchaseLine_promisedReceiptDate',
        type: ColumnRowTableType.INPUTDATE,
        extra: {
          thClass: 'col-1',
          required: true,
          copyTo: ['extra.gungPurchaseLine.promisedReceiptDate', 'extra.gungPurchaseLine.plannedReceiptDate'],
          delete: 'extra.gungPurchaseLine_promisedReceiptDate',
          conditions: [
            {
              comparator: '===',
              path1: 'extra.gungPurchaseLine_promisedReceiptDate', // 'extra.gungPurchaseLine.promisedReceiptDate',
              path2: null,
              value2: '0001-01-01',
              result: item => {
                item.extra.errors = ['extra.gungPurchaseLine_promisedReceiptDate'];
                return null;
              }
            }
          ]
        }
      },
      {
        name: null,
        path: [/* 'btnDownload',  */ 'btnMoveNextStatus', 'btnSplit', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {}
      }
    ],
    // CONFIRMED
    20: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo', 'extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT,
        sorted: true
      },
      {
        name: 'PLANNED_DATE',
        path: 'extra.gungPurchaseLine.plannedReceiptDate',
        type: ColumnRowTableType.DATE,
        sorted: true,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.gungPurchaseLine.no', 'extra.gungPurchaseLine.description'],
        type: ColumnRowTableType.TEXT,
        sorted: true,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: 'QUANTITY_ORDERED',
        path: 'extra.gungPurchaseLine.quantity',
        type: ColumnRowTableType.NUMBER,
        sorted: true
      },
      {
        name: 'QUANTITY_TO_RECEIVE',
        path: 'extra.gungPurchaseLine.qtyToReceive',
        type: ColumnRowTableType.NUMBER,
        sorted: true
      },
      {
        name: 'PRICE',
        path: ['extra.gungPurchaseLine.unitCost', 'extra.gungPurchaseLine.lineDiscount'],
        type: ColumnRowTableType.PRICE,
        sorted: true
      },
      {
        name: 'TOTAL_PRICE',
        path: 'extra.gungPurchaseLine.lineAmount',
        type: ColumnRowTableType.PRICE,
        sorted: true
      },
      {
        name: null,
        path: [/* 'btnDownload',  */ 'btnMoveNextStatus', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      }
    ],
    // STARTED
    30: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo', 'extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT,
        sorted: true
      },
      {
        name: 'PLANNED_DATE',
        path: 'extra.gungPurchaseLine.plannedReceiptDate',
        type: ColumnRowTableType.DATE,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.gungPurchaseLine.no', 'extra.gungPurchaseLine.description'],
        type: ColumnRowTableType.TEXT,
        sorted: true,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: null,
        path: ['btnMoveNextStatus', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      }
    ],
    // READY TO SHIP
    40: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo', 'extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT,
        sorted: true
      },
      {
        name: 'PLANNED_DATE',
        path: 'extra.gungPurchaseLine.plannedReceiptDate',
        type: ColumnRowTableType.DATE,
        sorted: true,
        extra: {
          thClass: 'col-1'
        }
      },
      {
        name: 'ITEM',
        path: ['extra.gungPurchaseLine.no', 'extra.gungPurchaseLine.description'],
        type: ColumnRowTableType.TEXT,
        sorted: true,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: null,
        path: ['btnMoveNextStatus', 'btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      }
    ],
    // SHIPPED
    50: [
      {
        name: null,
        path: null,
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'PO_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo', 'extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT,
        sorted: true
      },
      {
        name: 'ITEM',
        path: ['extra.gungPurchaseLine.no', 'extra.gungPurchaseLine.description'],
        type: ColumnRowTableType.TEXT,
        sorted: true,
        extra: {
          thClass: 'col-2'
        }
      },
      {
        name: null,
        path: ['btnHistory'],
        type: ColumnRowTableType.ACTION,
        extra: {
          thClass: 'col-2'
        }
      }
    ]
  };

  protected poSupplierTableColumnsDetails: { [s: string]: SupplierTableColumns[] } = {
    10: [
      {
        name: 'DOCUMENT_NUMBER',
        path: ['extra.gungPurchaseLine.documentNo'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'LINE_NUMBER',
        path: ['extra.gungPurchaseLine.lineNo'],
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'REQUEST_DATE',
        path: 'extra.gungPurchaseLine.requestedReceiptDate',
        type: ColumnRowTableType.TEXT
      },
      {
        name: 'TEXT1',
        path: 'text1',
        type: ColumnRowTableType.INPUTTEXT
      },
      {
        name: 'TEXT2',
        path: 'text2',
        type: ColumnRowTableType.INPUTTEXT
      },
      {
        name: 'TEXT3',
        path: 'text3',
        type: ColumnRowTableType.INPUTTEXT
      },
      {
        name: 'TEXT4',
        path: 'text4',
        type: ColumnRowTableType.INPUTTEXT
      },
      {
        name: 'TEXT5',
        path: 'text5',
        type: ColumnRowTableType.INPUTTEXT
      },
      {
        name: 'CHECKBOX1',
        path: 'checkbox1',
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'CHECKBOX2',
        path: 'checkbox2',
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'CHECKBOX3',
        path: 'checkbox3',
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'CHECKBOX4',
        path: 'checkbox4',
        type: ColumnRowTableType.INPUTCHECKBOX
      },
      {
        name: 'CHECKBOX5',
        path: 'checkbox5',
        type: ColumnRowTableType.INPUTCHECKBOX
      }
    ],
    20: [],
    30: [],
    40: [],
    50: []
  };

  constructor(public supplierService: SupplierService) {}

  public getPoStatusColumnTable(status: number | string) {
    return this.poStatusColumnTable[status];
  }

  public getPoSupplierTableColumnsDetails(status: number | string) {
    return this.poSupplierTableColumnsDetails[status];
  }

  public shouldDisplayConfirmCheckedButton(): boolean {
    return true;
  }

  public getInitData(currentStatus: string, postatusTable: [TableRecord]) {
    
    const tableColumn = this.getPoStatusColumnTable(currentStatus);
    if (!tableColumn) {
      console.error('Supplier portal - missing dynamic table info');
    }
    
    // Reject status
    let rejectStatusValue, rejectStatusName;
    const rejectStatus = tableColumn?.find(c => c.path?.indexOf('btnMoveRejectStatus') > -1)?.extra.rejectStatus;
    if (rejectStatus) {
      rejectStatusValue = Number(rejectStatus);
      const postatus = postatusTable?.find(s => s.id === rejectStatus);
      if (postatus) {
        rejectStatusName = postatus.name;
      }
    }

    // Next status
    let nextStatusValue, nextStatusName;
    const nextStatus = tableColumn?.find(c => c.path?.indexOf('btnMoveNextStatus') > -1)?.extra.nextStatus;
    if (nextStatus) {
      nextStatusValue = Number(nextStatus);
      const postatus = postatusTable?.find(s => s.id === nextStatus);
      if (postatus) {
        nextStatusName = postatus.name;
      }
    } else {
      nextStatusValue = currentStatus !== undefined && currentStatus !== null 
        && Number(this.getNextStatus(currentStatus.toString(), rejectStatusValue, postatusTable));
      nextStatusName = postatusTable?.find(p => p.id === nextStatusValue.toString())?.name;
    }

    const detailsColumn = this.getPoSupplierTableColumnsDetails(currentStatus);
    const idxAction = tableColumn.findIndex(c => c.type === ColumnRowTableType.ACTION);
    if (idxAction > -1) {
      const actions = tableColumn[idxAction].extra?.globalActions ? tableColumn[idxAction].extra?.globalActions : tableColumn[idxAction].path;
      // this.supplierPoActionConfigService.setActions(actions);
      return {
        currentStatus: currentStatus,
        nextStatus: nextStatusValue,
        nextStatusName: nextStatusName,
        rejectStatus: rejectStatusValue,
        rejectStatusName: rejectStatusName,
        tableColumn: tableColumn,
        detailsColumn: detailsColumn,
        actions
      }
      
    }
    
  }

  getNextStatus(statusId: string, rejectStatus: number, postatus, previous?: boolean): string {
    const idx = postatus?.findIndex(s => s.id === statusId);
    if (!previous && idx > -1 && idx < postatus?.length - 1) {
      if (rejectStatus && rejectStatus?.toString() === postatus[idx + 1]?.id) {
        return this.getNextStatus(postatus[idx + 1]?.id, rejectStatus, postatus); // Next is rejected get next
      }
      return postatus[idx + 1]?.id.toString();
    }
    // Get status before rejected
    if (previous && idx > 0 && idx < postatus.length - 1) {
      return postatus[idx - 1]?.id.toString();
    }
    return null;
  }
}
