<p>create-customer-modal works!</p>
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>CREATE_CUSTOMER</h4>
  <button type="button" class="close" aria-label="Close" (click)="close()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div class="row" *ngIf="hasTemplates">
    <!--  && !hasTemplateSelection -->
    <div class="col-xs-12" *ngFor="let template of companyTemplates">
      <button
        type="button"
        class="btn btn-sm btn-primary m-1"
        [attr.disabled]="currentTemplate === template.name ? true : undefined"
        (click)="applyTemplate(template)"
      >
        {{ template.name }}
      </button>
    </div>
  </div>

  <div *ngIf="customerForm">
    <lib-gung-tabset #ngbTab="gungTabset" [(activeId)]="activeId" (tabChange)="tabChanged($event)">
      <lib-gung-tab [title]="'INVOICE_ADDRESS' | translate">
        <ng-template libGungTabContent>
          <form [formGroup]="customerForm">
            <div class="row">
              <div class="col-12">
                <lib-gung-text-input
                  gungTranslate="NAME"
                  formControlName="displayName"
                  placeholder="{{ 'ENTER_NAME' | translate }}"
                  ngbAutofocus
                ></lib-gung-text-input>
                <div
                  *ngIf="
                    getFormControl('displayName').invalid &&
                    (getFormControl('displayName').dirty || getFormControl('displayName').touched) &&
                    getFormControl('displayName').errors.required
                  "
                  class="alert alert-danger"
                >
                  <span translate>REQUIRED</span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <lib-gung-text-input
                  gungTranslate="EMAIL"
                  formControlName="email"
                  placeholder="{{ 'ENTER_EMAIL' | translate }}"
                  ngbAutofocus
                ></lib-gung-text-input>
                <div
                  *ngIf="
                    getFormControl('email').invalid &&
                    (getFormControl('email').dirty || getFormControl('email').touched) &&
                    getFormControl('email').errors.pattern
                  "
                  class="alert alert-danger"
                >
                  <span translate>NOT_VALID_EMAIL</span>
                </div>
              </div>
            </div>
          </form>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'DELIVERY_ADDRESS' | translate">
        <ng-template libGungTabContent>
          <div *ngIf="!hasTemplates || (!customerCreated && hasTemplateSelection)" class="form-horizontal">
            <button
              class="btn btn-sm btn-primary btn-block"
              (click)="copyInvoiceAddressIntoDeliveryAddress()"
              translate
            >
              COPY_DELIVERY_ADDRESS_FROM_INVOICE_ADDRESS
            </button>
          </div>

          <div class="row">
            <div class="col-12">
              <form [formGroup]="customerForm">

              </form>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>-->
      <lib-gung-tab [title]="'CONDITIONS' | translate">
        <ng-template libGungTabContent>
          <div class="row">
            <div class="col-12">
              <form [formGroup]="customerForm">

              </form>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
      <lib-gung-tab [title]="'USER' | translate">
        <ng-template libGungTabContent>
          <div class="alert alert-info text-center" role="alert">
            <span translate>REQUEST_ALL_FIELDS_TO_CREATE_USER</span>
          </div>
          <form [formGroup]="userForm">
            <div class="row">
              <div class="col-12">
                <lib-gung-text-input
                  gungTranslate="NAME"
                  formControlName="name"
                  [placeholder]="'NAME' | translate"
                ></lib-gung-text-input>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <lib-gung-text-input
                  gungTranslate="EMAIL"
                  formControlName="email"
                  [placeholder]="'EMAIL' | translate"
                ></lib-gung-text-input>
                <lib-forms-message-error [controller]="userForm.get('email')"></lib-forms-message-error>
              </div>
            </div>
          </form>
          <div class="row">
            <div class="col-12">
              <lib-gung-password-input
                gungTranslate="PASSWORD"
                [placeholder]="'PASSWORD' | translate"
                [(ngModel)]="password"
                (validPassword)="setPassword($event)"
                autocomplete="new-password"
                [passwordRequirement]="passwordRequirements"
              >
              </lib-gung-password-input>
            </div>
          </div>
          <div class="row justify-content-end">
            <div class="col-auto">
              <lib-gung-checkbox-input-horizontal
                gungTranslate="SEND_EMAIL_TO_USER"
                [(ngModel)]="sendEmail"
                labelClass="col-auto"
              >
              </lib-gung-checkbox-input-horizontal>
            </div>
          </div>
        </ng-template>
      </lib-gung-tab>
    </lib-gung-tabset>
  </div>

  <div *ngIf="customerCreated">
    <div class="alert alert-success mt-2 text-center" role="alert">
      <span class="d-block">{{ 'CUSTOMER_ID' | translate }}: {{ savedCustomer.id }}</span>
      <span class="d-block">{{ 'CUSTOMER_NAME' | translate }}: {{ savedCustomer.name }}</span>
      <span class="d-block" *ngIf="savedUser">{{ 'USERNAME' | translate }}: {{ savedUser.username }}</span>
      <a (click)="selectCustomer(savedCustomer)" translate>SELECT_CUSTOMER_GO_TO_DETAILS</a>
    </div>
  </div>
</div>

<div class="modal-footer">
  <div class="row w-100">
    <div class="col-12">
      <div class="btn-group btn-group-justified w-100">
        <button class="btn btn-sm btn-outline-secondary btn-default" (click)="close()" translate>CANCEL</button>
        <button class="btn btn-sm btn-primary btn-default" (click)="onNextClick($event)">{{ nextBtn | translate }}</button>
      </div>
    </div>
  </div>
</div>
