import { Action, ActionReducerMap, MetaReducer } from '@ngrx/store';
import {
  ActionTypes,
  Add,
  Subtract,
  Remove,
  SetQuantity,
  LoadCart,
  CartLoaded,
  SetExtra,
  RemoveExtra,
  BulkSetQuantity,
  BulkSetExtra,
  BulkRemove,
  SetProductPartialId,
  Replace,
  CartReorder
} from '../actions';
import { CartRow, CartState } from '../types';
import { bulkSetQtyReducer, setQtyReducer } from './setquantity';
import { addReducer, bulkAddReducer } from './add';
import { subtractReducer } from './subtract';
import { cartLoadedReducer } from './cartLoaded';
import { bulkSetExtraReducer, setExtraReducer } from './setextra';
import { removeExtraReducer } from './removeextra';
import { BulkAdd } from './../actions';
import { setProductPartialIdReducer } from './setproductpartialid';
import { replaceReducer } from './replace';
import { cartReorderReducer } from './cartReorder';

export function reducer(current: CartRow[] = [], action: Action): CartRow[] {
  switch (action.type) {
    case ActionTypes.Add: {
      const { payload } = action as Add;
      return addReducer(current, payload);
    }
    case ActionTypes.BulkAdd: {
      const { payload } = action as BulkAdd;
      return bulkAddReducer(current, payload);
    }
    case ActionTypes.Replace: {
      const { payload } = action as Replace;
      return replaceReducer(current, payload);
    }
    case ActionTypes.Subtract: {
      const { payload } = action as Subtract;
      return subtractReducer(current, payload);
    }
    case ActionTypes.Remove: {
      const { payload } = action as Remove;
      return setQtyReducer(current, payload, 0);
    }
    case ActionTypes.BulkRemove: {
      const { payload } = action as BulkRemove;
      return bulkSetQtyReducer(current, payload);
    }
    case ActionTypes.SetQuantity: {
      const { payload } = action as SetQuantity;
      return setQtyReducer(
        current,
        {
          productId: payload.productId,
          productPartialId: payload.productPartialId,
          targetStockId: payload.targetStockId
        },
        payload.qty
      );
    }
    case ActionTypes.BulkSetQuantity: {
      const { payload } = action as BulkSetQuantity;
      return bulkSetQtyReducer(current, payload);
    }
    case ActionTypes.Clear: {
      current = [];
      return [];
    }
    case ActionTypes.CartLoaded: {
      const { payload } = action as CartLoaded;
      return cartLoadedReducer(null, payload);
    }
    case ActionTypes.ClearZeroQuantities: {
      return current.filter(row => row.qty > 0);
    }
    case ActionTypes.SetExtra: {
      const { payload } = action as SetExtra;
      return setExtraReducer(current, payload);
    }
    case ActionTypes.BulkSetExtra: {
      const { payload } = action as BulkSetExtra;
      return bulkSetExtraReducer(current, payload);
    }
    case ActionTypes.RemoveExtra: {
      const { payload } = action as RemoveExtra;
      return removeExtraReducer(current, payload);
    }
    case ActionTypes.SetProductPartialId: {
      const { payload } = action as SetProductPartialId;
      return setProductPartialIdReducer(current, payload, payload.productPartialId);
    }
    case ActionTypes.CartReorder: {
      const { payload } = action as CartReorder;
      return cartReorderReducer(current, payload);
    }
  }
  return current;
}
export const reducers: ActionReducerMap<CartState> = {
  rows: reducer
};
