<div class="row mt-4 mb-4">
  <div *ngFor="let data of mappedData; last as isLast" class="col-6 col-md-4 col-lg-3 mb-3">
    <div class="card product-card product-card-container">
      <div class="card-body">
        <div>
          <span>
            <!--TODO Add color wheel here -->
          </span>
        </div>
        <div class="image-wrapper" [routerLink]="['/customize-product/', data.product.id]">
          <img
            class="img-fluid"
            [src]="data.product.extra.images[0].s3Uri | gungImageUrl
              :'type:list'
              :'orientation:portrait'
              :'etag:'+data.product.extra.images[0].s3ETag
            "
            alt="{{ data.product.extra.images[0].description }}"
          />
        </div>

        <div class="product-text">
          <div class="row text-muted small text-left">
            <div class="col-auto mr-auto">
              <span>{{ data.product.id }}</span>
            </div>
            <div class="col-auto"></div>
          </div>

          <div class="row">
            <div class="col text-left text-truncate">
              <span translate>{{ data.product.name }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="findError" class="col-12 mb-2 text-center">
    {{ errorMessage }}
  </div>
</div>
