import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CsvUtilsService {

  constructor(protected http: HttpClient) {}

  parseHeader(file: File, delimiter: string = ';'): Observable<string[]> {
    const url = `json/csv-utils/parse-header`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('delimiter', delimiter);
    return this.http.post<string[]>(url, formData).pipe(
      catchError(error => { // REMOVE THIS WHEN ENDPOINT CREATED
        console.error('parseHeader() - error', error);
        return of([]);
      })
    );
  }

  parse(file: File, delimiter: string = ';', skipHeader: boolean = false, maxRows: number = 1): Observable<string[]> {
    const url = `json/csv-utils/parse`;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('delimiter', delimiter);
    formData.append('skipHeader', skipHeader.toString());
    formData.append('maxRows', maxRows.toString());
    return this.http.post<string[]>(url, formData).pipe(
      catchError(error => { // REMOVE THIS WHEN ENDPOINT CREATED
        console.error('parse() - error', error);
        return of([]);
      })
    );
  }
}
