<ng-template #content let-modal [ngIf]="content">
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title" translate>{{!cloneItem ? 'EDIT' : 'COPY'}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="ngbActiveModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <form [formGroup]="submitForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="row">
        <div class="col-12 mt-2">
          <lib-gung-text-input [gungTranslate]="'TITLE'" formControlName="title"></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <lib-gung-text-input
            [gungTranslate]="'CONDITION'"
            formControlName="targetPath"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-2">
          <lib-gung-text-input
            [gungTranslate]="'EXPRESSION'"
            formControlName="expression"
            [renderStatic]="!isActuator ? true : null"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-6">
          <div class="form-group">
            <label for="name" translate>INCLUDE</label>
            <input type="checkbox" formControlName="active" />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <label for="name" translate>IGNORE_ERRORS</label>
            <input type="checkbox" formControlName="ignoreErrors" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-secondary" (click)="ngbActiveModal.close('Cance click')" translate>
        CANCEL
      </button>
      <button type="submit" class="btn btn-outline-dark" translate>SAVE</button>
    </div>
  </form>
</ng-template>
