import { Component } from '@angular/core';
import { AssortmentManagementService } from '../../../services/assortment-management/assortment-management.service';

@Component({
  selector: 'lib-assortment-management-export',
  templateUrl: './assortment-management-export.component.html',
  styleUrls: ['./assortment-management-export.component.css']
})
export class AssortmentManagementExportComponent {
  fileName = '';
  assortment = '';

  constructor(protected assortmentManagementService: AssortmentManagementService) {}

  exportFile() {

    const formData = new FormData();
    this.checkFileEnding(this.fileName);
    formData.append('outputFileName', this.fileName);
    formData.append('root', this.assortment);

    this.assortmentManagementService
      .exportAssortmentExcel(formData)
      .subscribe(data => this.downLoadFile(data, 'application/ms-excel'));
  }

  downLoadFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a') as any;
    a.style = 'display: none';
    a.href = url;
    a.download = this.fileName || 'ASSORTMENT-' + new Date().getTime() + '.xlsx';
    a.click();
    window.URL.revokeObjectURL(url);
  }

  checkFileEnding(fileName: string) {
    this.fileName = fileName.trim();
    if (!fileName.endsWith('.xlsx')) {
      this.fileName = this.fileName + '.xlsx';
    }
  }
}
