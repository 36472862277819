<div class="container">
  <div class="login-container">
    <h5 class="text-primary"><strong translate>LOGIN</strong></h5>
    <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label for="username" translate>USERNAME</label>
        <input
          type="text"
          formControlName="username"
          class="form-control"
          [ngClass]="{ 'is-invalid': submitted && f.username.errors }"
          [placeholder]="'USERNAME' | translate"
        />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required" translate>USERNAME_REQUIRED</div>
        </div>
      </div>
      <div class="form-group">
        <!-- <label for="password" translate>PASSWORD</label>
            <input type="password" formControlName="password" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" /> -->
        <lib-gung-password-input
          [enablePasswordRequirement]="false"
          [suppressLabel]="true"
          gungTranslate="PASSWORD"
          [placeholder]="'PASSWORD' | translate"
          formControlName="password"
        ></lib-gung-password-input>
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required" translate>PASSWORD_REQUIRED</div>
        </div>
      </div>
      <div
        *ngIf="error && (error.type === ActionTypes.BadLogin || error.type === ActionTypes.BackendDown)"
        class="alert alert-danger"
      >
        <span *ngIf="error.type === ActionTypes.BadLogin" translate>LOGIN_FAILED</span>
        <span *ngIf="error.type === ActionTypes.BackendDown" translate>BACKEND_DOWN</span>
      </div>

      <div class="form-group d-flex justify-content-between align-items-center">
        <a routerLink="/forgot-password" class="forgot-password" translate>FORGOT_PASSWORD</a>
        <button *ngIf="!loading" class="btn btn-primary" translate>LOGIN</button>
        <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
        <!-- <img *ngIf="loading" class="pl-3" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" /> -->
      </div>
    </form>
  </div>
</div>
