<div class="row" *ngIf="product != null; else loading">
  <div class="col-xs-12 col-sm-6">
    <img [src]="product.imageUrlSmall" alt="Product image" class="img-fluid" />
  </div>
  <div class="col-xs-12 col-sm-6">
    <h1>{{ product.name }} - {{ product.id }}</h1>
    <p>{{ product.description }}</p>
  </div>
</div>
<ng-template #loading> LOADING... </ng-template>
