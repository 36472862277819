import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs';

export interface OrderServicesTicket {
  comments: OrderServicesTicketComment[];
  customerId: string;
  customerName: string;
  customerResponsible: string;
  date: string;
  id: string;
  orderId: string;
  status: string;
  rows: OrderServicesRow[];
  approvedBy: string;
  assignTo: string;
  extra: { [s:string]: any }
}

export interface OrderServicesTicketComment {
  attachments: any[];
  comment: string;
  date: string;
  user: string;
}

export interface OrderServicesRow {
  attachments: string[];
  quantity: number;
  reasonCode: string[];
  id: string;
  note: string;
  date: Date;
  extra: { [s:string]: any };
}

@Injectable({
  providedIn: 'root'
})
export class OrderServicesService {
  private orderServicesSubjects: BehaviorSubject<OrderServicesTicket[]>;

  constructor(protected http: HttpClient) {}

  getAll(nocache?: boolean): Observable<OrderServicesTicket[]> {
    if (!this.orderServicesSubjects) {
      this.orderServicesSubjects = new BehaviorSubject<OrderServicesTicket[]>([]);
      this.updateOrderServicesSubjectsSubject(nocache);
    }
    return this.orderServicesSubjects.asObservable();
  }

  updateOrderServicesSubjectsSubject(nocache?: boolean): void {
    this.internalGetAll(nocache).subscribe((data: OrderServicesTicket[]) => this.orderServicesSubjects.next(data));
  }

  protected internalGetAll(nocache?: boolean): Observable<OrderServicesTicket[]> {
    const url = `json/order-services/list/all`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<OrderServicesTicket[]>(url, { headers });
  }

  postOrderServices(orderServicesTicket: any): Observable<OrderServicesTicket> {
    const url = `json/order-services/update`;
    return this.http
      .post<OrderServicesTicket>(url, orderServicesTicket)
      .pipe(tap(_ => this.updateOrderServicesSubjectsSubject(true)));
  }

  uploadDocuments(fd: FormData, timestamp): Observable<any[]> {
    return this.http.post<any[]>(`/import/documents/commentAttachment/${timestamp}`, fd);
  }

  deleteOrderServices(id: string) {
    const url = `json/order-services/delete/${id}`;
    return this.http.delete<OrderServicesTicket>(url).pipe(tap(_ => this.updateOrderServicesSubjectsSubject(true)));
  }

  deleteDocuments(id: string): Observable<any[]> {
    return this.http.delete<any[]>(`json/documents/${id}`);
  }

  getDocumentDownloadUrl(id: string) {
    return `gung-document-archive/document/${id}`;
  }
}
