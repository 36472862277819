<div *ngIf="!showV2" class="row {{ customClass }}">
  <div class="col">
    <div class="table-responsive gung-responsive">
      <table class="table table-striped table-hover">
        <thead class="">
          <tr>
            <!-- <th scope="col" translate>#</th> -->
            <th
              *ngFor="let column of columns"
              scope="col"
              [ngClass]="{ 'w-1perc': column.type === EdiRowTableType.IMAGE }"
              translate
            >
              {{ column.header }}
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let row of table; index as idx">
            <tr>
              <!-- <th scope="row" [attr.data-label]="'#' | translate">{{idx}}</th> -->
              <td *ngFor="let column of row" [attr.data-label]="column.header | translate" [class]="column.customClass">
                <ng-container [ngSwitch]="column.type">
                  <ng-template [ngSwitchCase]="EdiRowTableType.TEXT">{{ column.value }}</ng-template>
                  <ng-template [ngSwitchCase]="EdiRowTableType.NUMBER">{{ column.value | number }}</ng-template>
                  <ng-template [ngSwitchCase]="EdiRowTableType.NUMBER">{{
                    column.value | date: dateUtilService.dateFormat
                  }}</ng-template>
                  <ng-template [ngSwitchCase]="EdiRowTableType.HTML"
                    ><div [innerHTML]="column.value | safeHtml"></div
                  ></ng-template>
                  <ng-template [ngSwitchCase]="EdiRowTableType.IMAGE">
                    <img
                      style="width: 120px; height: 120px;"
                      *ngIf="column.value?.length > 0 && column.value[0] as image; else noimage"
                      [src]="image.s3Uri | gungImageUrl
                        :'type:list'
                        :'etag:'+image.s3ETag
                      "
                      alt="{{ image.description }}"
                      class="img-fluid mw-unset"
                    />
                    <ng-template #noimage>
                      <img
                        style="width: 120px; height: 120px;"
                        [src]="'images/no-image.jpg' | gungImageUrl"
                        alt="NO IMAGE"
                        class="img-fluid mw-unset"
                      />
                    </ng-template>
                  </ng-template>
                  <ng-template [ngSwitchCase]="EdiRowTableType.ACTION">
                    <button
                      *ngFor="let action of column.value"
                      type="button"
                      (click)="action.getValue(column.data)"
                      [class]="action.customClass || 'btn btn-info btn-sm'"
                      translate
                    >
                      <i class="fas fa-trash-alt"></i>{{ action.header }}
                    </button>
                  </ng-template>
                  <ng-template ngSwitchDefault>{{ column.value }}</ng-template>
                </ng-container>
              </td>
            </tr>
            <!-- SECONDARY TABLE -->
            <ng-container *ngFor="let secondary of secondaryTable">
              <tr *ngIf="secondary.data?.length > 0">
                <td [attr.colspan]="columns.length">
                  <lib-edi-row-table [data]="secondary.data" [columns]="secondary.columns"></lib-edi-row-table>
                </td>
              </tr>
            </ng-container>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>

<!-- V2 Angular Material Table -->
<table
  *ngIf="showV2 && displayedColumns && dataSource"
  mat-table
  matSort
  [dataSource]="dataSource"
  class="mat-elevation-z8 w-100"
>
  <!-- (matSortChange)="announceSortChange($event)" -->

  <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

  <!-- Column -->
  <!-- <ng-container *ngFor="let column of columns" [matColumnDef]="column.header">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>{{column.header | translate}}</th>
        <td mat-cell *matCellDef="let element"> {{element[column.header]}} </td>
    </ng-container> -->

  <ng-container *ngFor="let column of columns">
    <ng-container [ngSwitch]="column.type">
      <!-- TEXT -->
      <ng-template [ngSwitchCase]="EdiRowTableType.TEXT">
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column.header] }}</td>
        </ng-container>
      </ng-template>
      <!-- NUMBER -->
      <ng-template [ngSwitchCase]="EdiRowTableType.NUMBER">
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column.header] | number }}</td>
        </ng-container>
      </ng-template>
      <!-- DATE -->
      <ng-template [ngSwitchCase]="EdiRowTableType.DATE">
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column.header] | date: dateUtilService.dateFormat }}</td>
        </ng-container>
      </ng-template>
      <!-- HTML -->
      <ng-template [ngSwitchCase]="EdiRowTableType.HTML">
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <div [innerHTML]="element[column.header] | safeHtml"></div>
          </td>
        </ng-container>
      </ng-template>
      <!-- IMAGE -->
      <ng-template [ngSwitchCase]="EdiRowTableType.IMAGE">
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">
            <img
              *ngIf="element[column.header]?.length > 0 && element[column.header][0] as image; else noimage"
              style="width: 120px; height: 120px;"
              [src]="image.s3Uri | gungImageUrl
                :'type:list'
                :'etag:'+image.s3ETag
              "
              alt="{{ image.description }}"
              class="img-fluid mw-unset"
            />
            <ng-template #noimage>
              <img
                style="width: 120px; height: 120px;"
                [src]="'images/no-image.jpg' | gungImageUrl"
                alt="NO IMAGE"
                class="img-fluid mw-unset"
              />
            </ng-template>
          </td>
        </ng-container>
      </ng-template>
      <!-- DEFAULT -->
      <ng-template [ngSwitchDefault]>
        <ng-container [matColumnDef]="column.header">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ column.header | translate }}</th>
          <td mat-cell *matCellDef="let element">{{ element[column.header] }}</td>
        </ng-container>
      </ng-template>
    </ng-container>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
