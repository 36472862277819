import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Availability } from './../../../../models/availability';
import { ProductMatrixInputElementComponent } from '../product-matrix-input-element/product-matrix-input-element.component';
import { Product } from './../../../../models';

@Component({
  selector: 'lib-product-matrix-wrapper-input-element',
  templateUrl: './product-matrix-wrapper-input-element.component.html',
  styleUrls: ['./product-matrix-wrapper-input-element.component.css']
})
export class ProductMatrixWrapperInputElementComponent implements OnInit {
  isAvailability: boolean;
  @Input()
  id: string;

  @Input()
  productPartialId?: string;

  @Input()
  readOnly: boolean;

  @Input()
  rowOrder: number;

  @Input()
  product?: Product;

  @Input()
  availability: Availability;

  @Input()
  showButtons?: boolean;

  @Output()
  quantityUpdated = new EventEmitter<string>();

  @Output()
  selectedId = new EventEmitter<string>();

  @ViewChild(ProductMatrixInputElementComponent) productMatrixInputElement: ProductMatrixInputElementComponent;
  
  @Output()
  focusNextOutput = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {
    if (this.availability) {
      this.isAvailability = true
    }
  }
  
  focusNext(id) {
    this.focusNextOutput.emit(id);
  }
}
