import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProductGridViewComponent } from '../product-grid-view/product-grid-view.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { GungFlowService } from '../../services/gung-flow/gung-flow.service';
import { PriceService } from '../../services/price/price.service';
import { AuthService } from '../../services/auth/auth.service';
import { GungAnonymousConfigService } from '../../services/gung-anonymous-config/gung-anonymous-config.service';

@Component({
  selector: 'lib-product-logo-applicator-grid-view',
  templateUrl: './product-logo-applicator-grid-view.component.html',
  styleUrls: ['./product-logo-applicator-grid-view.component.scss']
})
export class ProductLogoApplicatorGridViewComponent extends ProductGridViewComponent {

}
