import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';

export interface OptionsList {
  id: string;
  name: string;
}

@Component({
  selector: 'lib-gung-select-option-input',
  templateUrl: './gung-select-option-input.component.html',
  styleUrls: ['./gung-select-option-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungSelectOptionInputComponent),
      multi: true
    }
  ]
})
export class GungSelectOptionInputComponent extends BaseInputDirective<string> {
  selectedValueName: string;

  @Input()
  public labelClass: string | string[];

  @Input()
  public extraInputClass = '';

  @Input()
  public extraHostClass = '';

  @Input()
  public optionsList: OptionsList[];

  @Input()
  public showId = false;

  @ViewChild('selectOption', { static: false }) selectOptionElement: ElementRef;

  constructor() {
    super();
  }

  set value(value: string) {
    // eslint-disable-next-line @typescript-eslint/naming-convention, no-underscore-dangle, id-blacklist, id-match
    this._value = value;
    this.onChange(value);
    this.selectedValueName = this.optionsList.find(o => o.id === value)?.name || value;
  }

  get value() {
    return this._value;
  }

  writeValue(obj: string): void {
    if (obj !== undefined) {
      this.value = obj;
    }
  }

  // get selected() {
  //   return this.selectedValue;
  // }

  // set selected(val: string) {
  //   this.selectedValue = val;
  //   this.onChange(this.selectedValue);
  // }

  // writeValue(val: any): void {
  //   if (val !== undefined) {
  //     this.selected = val;
  //   }
  // }
  // registerOnChange(fn: any): void {
  //   this.onChange = fn;
  // }
  // registerOnTouched(fn: any): void {
  // }
}
