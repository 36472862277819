<div class="row">
  <div class="col-12 col-md-6">
    <h4 translate>ATTRIBUTES</h4>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_TITLE"
      [(ngModel)]="pimProduct.i18n[language].shopify.title"
    ></lib-gung-text-input-horizontal>
    <lib-meta-data-select-input
      [(ngModel)]="pimProduct.i18n[language].shopify.productType"
      table="PRODUCTTYPE"
      metaId="name"
      label="MAIN_PRODUCTTYPE"
      metaDisplay="name"
      class="gung-select-input-sm"
    >
    </lib-meta-data-select-input>
    <lib-meta-data-checkbox-input
      [(selectedOptions)]="pimProduct.i18n[language].shopify.type"
      outputType="string"
      metaId="name"
      table="PRODUCTTYPE"
      label="PRODUCTTYPE"
      metaDisplay="name"
      previewField="name"
      class="gung-select-input-sm"
    >
    </lib-meta-data-checkbox-input>
    <lib-meta-data-checkbox-input
      [(selectedOptions)]="pimProduct.i18n[language].shopify.segment"
      outputType="string"
      metaId="name"
      table="SEGMENT"
      label="SEGMENT"
      metaDisplay="name"
      previewField="name"
      class="gung-select-input-sm"
    >
    </lib-meta-data-checkbox-input>
    <lib-meta-data-checkbox-input
      [(selectedOptions)]="pimProduct.i18n[language].shopify.group"
      outputType="string"
      metaId="name"
      table="GROUP"
      label="GROUP"
      metaDisplay="name"
      previewField="name"
      class="gung-select-input-sm"
    >
    </lib-meta-data-checkbox-input>
    <lib-gung-text-area-input-horizontal
      gungTranslate="OTHER_TAGS"
      rowsNumber="3"
      [(ngModel)]="pimProduct.i18n[language].shopify.otherTags"
    ></lib-gung-text-area-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SEO_TITLE"
      [(ngModel)]="pimProduct.i18n[language].shopify.seoTitle"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-area-input-horizontal
      gungTranslate="SEO_DESCRIPTION"
      rowsNumber="3"
      [(ngModel)]="pimProduct.i18n[language].shopify.seoDescription"
    ></lib-gung-text-area-input-horizontal>
  </div>
  <div class="col-12 col-md-6">
    <h4 translate>HTML</h4>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_SUBTITLE"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlSubTitle"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_ATTRIBUTE1"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlAttribute1"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_ATTRIBUTE2"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlAttribute2"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_ATTRIBUTE3"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlAttribute3"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_ATTRIBUTE4"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlAttribute4"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_ATTRIBUTE5"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlAttribute5"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_COMPOSITION"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlComposition"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-area-input-horizontal
      gungTranslate="SHOPIFY_DESCRIPTION"
      rowsNumber="3"
      [(ngModel)]="pimProduct.i18n[language].shopify.htmlDescription"
    ></lib-gung-text-area-input-horizontal>
  </div>
</div>
<div class="row">
  <div class="col-12 col-md-2"><h4 translate>USP</h4></div>
  <div class="col-12 col-md-8">Do not leave any field empty between two with text</div>
  <div class="col-12 col-md-6">
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP1"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp1"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP2"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp2"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP3"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp3"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP4"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp4"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP5"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp5"
    ></lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal
      gungTranslate="SHOPIFY_USP6"
      [(ngModel)]="pimProduct.i18n[language].shopify.usp6"
    ></lib-gung-text-input-horizontal>
  </div>
</div>
