import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ExportDigitalAssetsRouterService {
  currentDigitalAsset: Subject<string> = new ReplaySubject<string>();

  constructor() {}

  getCurrentDigitalAsset() {
    return this.currentDigitalAsset.asObservable();
  }
}
