<div class="modal-header">
    <h4 class="modal-title" translate>HTML_EDITOR</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-12">
        
        <!-- HTML EDITOR -->
      <div *ngFor="let lang of translateLangs">
        <lib-gung-html-editor
          [(ngModel)]="data[0].text.i18n[lang].text"
          [gungTranslate]="lang.toUpperCase()"
          placeholder="Type the content here!"
        ></lib-gung-html-editor>
      </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="onSave()" [disabled]="isLoading ? true : null" translate>
      SAVE<i *ngIf="isLoading" class="fas fa-spinner fa-spin ml-2"></i>
    </button>
  </div>
  