import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin } from 'rxjs';
import { first, switchMap, tap } from 'rxjs';
import { PdfDocument } from '../../models/pdf-document';
import { PdfAuthService } from '../../services/pdf-auth.service';
import { DynamicPdfService } from '../../services/dynamic-pdf.service';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-pdf-export-edit-modal',
  templateUrl: './pdf-export-edit-modal.component.html',
  styleUrls: ['./pdf-export-edit-modal.component.scss']
})
export class PdfExportEditModalComponent implements OnInit {
  alertMessage: Alert;
  submited = false;
  isCreate = false;

  pdfDocument: PdfDocument;
  pdfDocumentForm: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected authService: PdfAuthService,
    protected dynamicPdfService: DynamicPdfService
  ) {}

  ngOnInit(): void {
    this.pdfDocument.users = this.pdfDocument.users || [];
    this.initForm();
  }

  initForm() {
    this.pdfDocumentForm = this.formBuilder.group({
      name: this.formBuilder.control(this.pdfDocument.name, []),
      users: this.formBuilder.control(this.pdfDocument.users.join(','), []),
      readOnly: this.formBuilder.control(!!this.pdfDocument.readOnly, []),
      archived: this.formBuilder.control(!!this.pdfDocument.archived, [])
    });
  }

  onSave() {
    if (!this.pdfDocumentForm.valid) {
      this.pdfDocumentForm.markAllAsTouched();
      return;
    }

    const formRaw = this.pdfDocumentForm.getRawValue();
    this.pdfDocumentForm.disable();

    this.pdfDocument = {
      ...this.pdfDocument,
      name: formRaw.name,
      users: formRaw.users.split(','),
      readOnly: formRaw.readOnly,
      archived: formRaw.archived
    } as PdfDocument;

    if (this.isCreate) {
      delete this.pdfDocument.id;
    }

    this.authService
      .getCurrentUser()
      .pipe(
        first(),
        switchMap(user => {
          if (user) {
            this.pdfDocument.users = [...this.pdfDocument.users, user.username];
          }
          return this.dynamicPdfService.savePdf(this.pdfDocument);
        })
      )
      .subscribe({
        next: v => {
          this.alertMessage = { type: 'success', message: 'PDFEXPORT_SAVED' };
          this.submited = true;
        },
        error: e => {
          this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
          this.submited = true;
        }
      });
  }
}
