import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DigitalAssetFile } from '../digital-assets/digital-assets.service';

@Injectable({
  providedIn: 'root'
})
export class DownloadService {
  protected baseUrl = `download`;

  constructor(protected http: HttpClient) {}

  postGenerateImageZipFromUrlList(email: string, imageUrls: string[]) {
    const url = `${this.baseUrl}/generate-image-zip-from-url-list`;
    const formData = { recipientEmail: email, images: imageUrls };
    return this.http.post<any>(url, formData);
  }

  postGenerateAssetsZipFromUrlList(email: string, imageUrls: string[], documentsToSend: DigitalAssetFile[]) {
    const url = `${this.baseUrl}/generate-email-from-documents`;
    const formData = { recipientEmail: email, images: imageUrls, documents: documentsToSend };
    return this.http.post<any>(url, formData);
  }
}
