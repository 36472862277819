import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models/product';
import { CartService } from '../../services/cart/cart.service';

@Component({
  selector: 'lib-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent extends ListItemRendererComponent<Product> implements OnInit, AfterViewInit {
  currentQty: number;

  constructor() {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
