import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { Product } from '../../models';
import { ProductSelectionService } from '../../services/product-selection-service/product-selection.service';
import { ProductExportListConfigService } from '../../services/product-export-list-config/product-export-list-config.service';
import { CurrentAssortmentService } from '../../services/base-view-config/base-view-config.service';
import { filter } from 'rxjs';
import { AssortmentService } from '../../services/assortment.service';

@Component({
  selector: 'lib-product-export-list',
  template:
    '<h3 translate class="page-title text-center">PRODUCT_EXPORT_NAV_BAR</h3><lib-filter-list #productExportList [configService]="productExportListConfigService"></lib-filter-list>',
  styleUrls: ['./product-export-list.component.scss'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => ProductSelectionService)
    }
  ]
})
export class ProductExportListComponent implements OnInit {
  @Input()
  digitalAssets = false;

  constructor(
    public productExportListConfigService: ProductExportListConfigService, 
    public route: ActivatedRoute,
    protected currentAssortmentService: CurrentAssortmentService,
    protected assortmentService: AssortmentService

  ) {
    this.assortmentService.getUserAssortment().pipe(
      filter(assortment => !!assortment),
    ).subscribe(assortment => {
      this.currentAssortmentService.currentAssortment = assortment
    })
  }
  @ViewChild('productExportList') productExportList: FilterListComponent<Product>;

  ngOnInit() {
    this.productExportListConfigService.digitalAssets = this.digitalAssets;
  }
}
