import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { EdiGungMessages } from '../../../models/edi';

@Component({
  selector: 'lib-edi-invoice-modal',
  templateUrl: './edi-invoice-modal.component.html',
  styleUrls: ['./edi-invoice-modal.component.css']
})
export class EdiInvoiceModalComponent implements OnInit {
  sendSingleInvoice: boolean;

  @Input() data: EdiGungMessages;

  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit() {
    this.sendSingleInvoice = !this.data || !this.data.id;
  }

  onSend() {
    this.activeModal.close(this.data);
  }
}
