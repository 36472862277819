import { NgbActiveModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnChanges, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import {
  DigitalAsset,
  DigitalAssetFile,
  DigitalAssetsService
} from '../../../services/digital-assets/digital-assets.service';
import { DownloadService } from '../../../services/download/download.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { DateUtilService, GungDownloadUrl } from 'gung-common';
import { User } from '../../../state/auth/types';
import { HttpClient } from '@angular/common/http';
import { gungCheckAndDownloadFile } from '../../../utils/gung-utils';

@Component({
  selector: 'lib-digital-assets-modal',
  templateUrl: './digital-assets-modal.component.html',
  styleUrls: ['./digital-assets-modal.component.css']
})
export class DigitalAssetsModalComponent implements OnInit, OnChanges {
  currentUser: User;
  isManagement = false;
  parentId: string;
  @Input() selectedDigitalAsset: DigitalAsset;
  downloadingInProgress = false;
  selectedTotal = 0;
  modalRef: NgbModalRef;
  lastUpdateDate: Date;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService,
    protected digitalAssetsService: DigitalAssetsService,
    protected activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.parentId = undefined;
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.currentUser = user));
    if (this.route.snapshot.url.length > 1) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 2].path;
    }
    this.isManagement = this.router.url.startsWith('/digital-assets/management');
    this.lastUpdateDate = this.dateUtilService.parseDate(this.selectedDigitalAsset.dateLastUpdate);
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  downloadAllImages(digitalAsset: DigitalAsset) {
    const srcImages = digitalAsset.images || [];
    const imageS3Uris: string[] = srcImages.map(image => image.s3Uri);
    if (imageS3Uris.length > 0) {
      this.digitalAssetsService.sendDigitalAssetsFilesUri(
        imageS3Uris,
        [],
        this.gungModalService,
        this.currentUser.email
      );
    }
  }

  downloadAllSelected(digitalAsset: DigitalAsset) {
    const srcImages = digitalAsset.images || [];
    const srcDocuments = digitalAsset.documents || [];
    const imageS3Uris: string[] = srcImages
      .filter(i => i.extra._selected)
      //   .map(i => 'fit-in/5000x5000/filters:quality(100):background_color(white)/' + i.s3Uri);
      .map(image => image.s3Uri); // for urls without fit-in we will use cdn2.gung.io that does not use scaling function, hence no image size cap.

    const documentsToSend: DigitalAssetFile[] = srcDocuments.filter(i => i.extra._selected);
    if (imageS3Uris.length > 0 || documentsToSend.length > 0) {
      this.digitalAssetsService.sendDigitalAssetsFilesUri(
        imageS3Uris,
        documentsToSend,
        this.gungModalService,
        this.currentUser.email
      );
    }
  }

  fetchImage(image: DigitalAssetFile) {
    this.downloadingInProgress = true;
    const src = GungDownloadUrl(image);
    const anchor = document.createElement('a');
    anchor.style.display = 'none';
    anchor.href = src;
    anchor.download = image.name;
    document.body.appendChild(anchor);
    anchor.click();
    window.URL.revokeObjectURL(src);
    this.downloadingInProgress = false;
  }

  selectImage(image: DigitalAssetFile) {
    image.extra._selected = !image.extra._selected;

    if (image.extra._selected) {
      this.selectedTotal++;
    } else {
      this.selectedTotal--;
    }
  }

  clearAllSelection() {
    for (const img of this.selectedDigitalAsset.images) {
      img.extra._selected = false;
    }

    for (const document of this.selectedDigitalAsset.documents) {
      document.extra._selected = false;
    }

    this.selectedTotal = 0;
  }

  selectAll() {
    for (const img of this.selectedDigitalAsset.images) {
      if (!img.extra._selected) {
        img.extra._selected = true;
        this.selectedTotal++;
      }
    }

    for (const document of this.selectedDigitalAsset.documents) {
      if (!document.extra._selected) {
        document.extra._selected = true;
        this.selectedTotal++;
      }
    }
  }

  selectAllImages() {
    for (const img of this.selectedDigitalAsset.images) {
      if (!img.extra._selected) {
        img.extra._selected = true;
        this.selectedTotal++;
      }
    }
  }

  selectAllDocuments() {
    for (const document of this.selectedDigitalAsset.documents) {
      if (!document.extra._selected) {
        document.extra._selected = true;
        this.selectedTotal++;
      }
    }
  }

  crossClick() {
    this.activeModal.dismiss('Cross click');
  }

  saveClick() {
    this.activeModal.close('Save click');
  }

  encodeURIComponent(component: string | number | boolean) {
    return encodeURIComponent(component);
  }
}
