<!--<div class="row">
  <div class="col-12">
    <div class="form-check">
      <input
        class="form-check-input"
        type="checkbox"
        value=""
        id="flexCheckDefault"
        [(ngModel)]="showProcessed"
        [ngModelOptions]="{ standalone: true }"
      />
      <label class="form-check-label" for="flexCheckDefault" tranlsated>SHOW_PROCESSED</label>
    </div>
  </div>
</div>-->
<div *ngFor="let account of data">
  <div class="row border-top mt-2">
    <div class="col-md-5">INFO</div>
    <div class="col-md-5">CONTACT</div>
  </div>
  <div class="row">
    <div class="col-md-5">
      <lib-gung-text-input-horizontal
        gungTranslate="DATE"
        [renderStatic]="true"
        [(ngModel)]="account.creationDate"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="NAME"
        [renderStatic]="true"
        [(ngModel)]="account.companyName"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="CUSTOMER_ID"
        [renderStatic]="true"
        [(ngModel)]="account.companyId"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="ORGANISATION_ID"
        [renderStatic]="true"
        [(ngModel)]="account.companyOrganisationId"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="ADDRESS"
        [renderStatic]="true"
        [(ngModel)]="account.companyAddress"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="COUNTRY"
        [renderStatic]="true"
        [(ngModel)]="account.companyCountry"
      ></lib-gung-text-input-horizontal>
    </div>
    <div class="col-md-5">
      <lib-gung-text-input-horizontal
        gungTranslate="NAME"
        [renderStatic]="true"
        [(ngModel)]="account.companyContactName"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="EMAIL"
        [renderStatic]="true"
        [(ngModel)]="account.companyContactEmail"
      ></lib-gung-text-input-horizontal>
      <lib-gung-text-input-horizontal
        gungTranslate="PHONE"
        [renderStatic]="true"
        [(ngModel)]="account.companyContactPhone"
      ></lib-gung-text-input-horizontal>
      <div class="form-group row">
        <label class="col-form-label col-12 col-sm-4 font-weight-bold">EXTRA_DATA</label>
        <div class="col">
          <pre>{{ account.extra | json }}</pre>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <button
        *ngIf="!account.processed || account.rejected"
        type="button"
        class="btn btn-primary m-1 w-100"
        (click)="createUser(account)"
        translate
      >
        CREATE_USER
      </button>
      <button
        *ngIf="!account.processed || account.rejected"
        type="button"
        class="btn btn-danger m-1 w-100"
        (click)="processed(account)"
        translate
      >
        PROCESSED
      </button>
      <button
        *ngIf="!account.processed"
        type="button"
        class="btn btn-primary m-1 w-100"
        (click)="rejectUser(account)"
        translate
      >
        REJECT_USER
      </button>

      <button
        *ngIf="account.processed && !account.rejected"
        class="btn btn-primary m-1 w-100"
        disabled="disabled"
        translate
      >
        USER_EXISTS
      </button>
      <button
        *ngIf="account.processed && account.rejected"
        class="btn btn-danger m-1 w-100"
        disabled="disabled"
        translate
      >
        USER_REJECTED
      </button>
    </div>
  </div>
</div>
