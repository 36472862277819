import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterListComponent } from 'gung-list';
import { ActivityInternalTodoListConfigService } from '../../services/activity-internal-todo-list-config/activity-internal-todo-list-config.service';
import { ActivityInternal } from '../../services/activity-internal/activity-internal.service';
import { ActivityInternalCreateModalComponent } from '../activity-internal/activity-internal-create-modal/activity-internal-create-modal.component';

@Component({
  selector: 'lib-activity-internal-todo',
  templateUrl: './activity-internal-todo.component.html',
  styles: []
})
export class ActivityInternalTodoComponent implements OnInit {
  showList: boolean = true;
  
  @Input()
  customerId?: string;

  @ViewChild('list', { static: false }) list: FilterListComponent<ActivityInternal>;

  constructor(public configService: ActivityInternalTodoListConfigService, protected modalService: NgbModal) {}

  ngOnInit(): void {}

  openCreateModal() {
    const modalRef = this.modalService.open(ActivityInternalCreateModalComponent, {
      ariaLabelledBy: 'modal-basic-title',
      size: 'lg',
      backdrop: 'static'
    });
    modalRef.result.then(
      result => {},
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }
}
