import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';
import { OptionsListMulti } from 'gung-common';

@Component({
  selector: 'lib-documents-upload-modal',
  templateUrl: './documents-upload-modal.component.html',
  styleUrls: ['./documents-upload-modal.component.css']
})
export class DocumentsUploadModalComponent implements OnInit {
  @Input()
  uploadMethod: (file: File[], fileDescription: string, inMenu: boolean, userGroups: any) => Promise<boolean>;

  @Input()
  multiple: boolean;

  @Input()
  hideDescription: boolean;

  @Input()
  hideInMenu: boolean;

  @Input()
  accept: string;

  fileDescription: string;

  spinner = false;

  enableDocumentUserGroups = false;
  settingsGroups;
  selectedGroups: any = {};

  constructor(
    protected http: HttpClient,
    public activeModal: NgbActiveModal,
    protected navbarConfigService: NavbarConfigService,
    protected gungFlowService: GungFlowService
  ) {}

  ngOnInit() {
    this.initDocumentUserGroups();
  }

  onFileSelect(event) {
    if (event.target.files.length > 0) {
      const fileName = event.target.files.length === 1 ? event.target.files[0].name : event.target.files.length + '';
    }
  }

  onUpload(inputFile, inputDescription, inputInMenu) {
    if (!inputFile.files || inputFile.files.length === 0) {
      return;
    }
    this.spinner = true;
    const files: File[] = inputFile.files;
    const fileDescription: string = inputDescription.value;
    const inMenu: boolean = inputInMenu.checked;
    this.uploadMethod(files, fileDescription, inMenu, this.selectedGroups).then(
      (data) => {
        this.activeModal.close(data);
        this.spinner = false;
      },
      error => {
        console.log('error', error);
        this.spinner = false;
      }
    );
  }

  setSelectedValues(event) {
    if(!this.settingsGroups) {
      return;
    }
    let userGroupsSaveFormat;

    (this.settingsGroups as string[]).forEach(settingsGroup => {
      const userGroup = event.find(group => group === settingsGroup);
      if (userGroup) {
        userGroupsSaveFormat = {
          ...userGroupsSaveFormat,
          [settingsGroup]: true
        };
      } else {
        userGroupsSaveFormat = {
          ...userGroupsSaveFormat,
          [settingsGroup]: false
        };
      }
    });
    this.selectedGroups = userGroupsSaveFormat;
  }

  getUserGroups(): OptionsListMulti[] {
    const tmp: OptionsListMulti[] = [];
    // let docExtraGroups = { 'ADMIN': true, 'SALES': false };
    let docExtraGroups = {};
    if (!this.selectedGroups) {
      this.selectedGroups = {};
    }
    /* if (!this.selectedGroups.extra) { this.selectedGroups.extra = {}; }
    if (!this.selectedGroups.extra.userGroups) { this.selectedGroups.extra.userGroups = {}; } */

    docExtraGroups = this.selectedGroups;

    for (const key of Object.keys(docExtraGroups)) {
      if (docExtraGroups[key]) {
        tmp.push({
          id: key,
          name: '',
          selected: docExtraGroups[key]
        });
      }
    }

    (this.settingsGroups as string[]).forEach(settingsGroup => {
      const userGroup = tmp.find(group => group.id === settingsGroup);
      if (!userGroup) {
        tmp.push({
          id: settingsGroup,
          name: '',
          selected: false
        });
      }
    });
    return tmp;
  }

  initDocumentUserGroups() {
    this.enableDocumentUserGroups = this.navbarConfigService.getEnableDocumentUserGroups();
    if (this.enableDocumentUserGroups) {
      this.http.get<any>('json/system/GUNG').subscribe(systemSettings => {
        this.settingsGroups = systemSettings.extra.userGroups;
      });
    }
  }
}
