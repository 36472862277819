import { Component, OnInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { GungLanguageConfigService, Language } from '../../services/gung-language-config/gung-language-config.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-language-selection',
  templateUrl: './language-selection.component.html',
  styleUrls: ['./language-selection.component.css']
})
export class LanguageSelectionComponent {
  languages: Language[] = [];
  public forceShowLanguage = this.languageConfigService.getForceShowLanguages();
  constructor(protected translateService: TranslateService, public languageConfigService: GungLanguageConfigService) {
    this.initLanguages();
    const currentLang = translateService.currentLang;
    if (currentLang) {
      languageConfigService.setDefaultLanguage(currentLang);
    } else {
      const lang = localStorage.getItem('selectedLanguage');
      if (lang) {
        languageConfigService.setDefaultLanguage(lang);
      }
    }
  }

  protected initLanguages() {
    const langs = this.languageConfigService.getLanguages().filter(l => l.enabled);
    if (langs.length >= 2 || this.forceShowLanguage) {
      this.languages = langs;
    }
  }

  // deprecated
  getLanguages() {
    const langs = this.languageConfigService.getLanguages().filter(l => l.enabled);
    if (langs.length >= 2) {
      return langs;
    }
    return [];
  }

  changeLanguage(lang) {
    this.languageConfigService.setDefaultLanguage(lang);
    if (!!this.languageConfigService.reloadOnLangChange) {
      this.translateService.use(lang).pipe(first()).subscribe(_ => {
        location.reload();
      });
    } else {
      this.translateService.use(lang);
    }
  }
}
