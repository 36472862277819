import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PdfAuthService {
  constructor() {}

  getCurrentUser(): Observable<any> {
    return of(null);
  }

  getRoles(): Observable<string[]> {
    return of(['ADMIN', 'SALES', 'USER']);
  }
}
