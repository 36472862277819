<div class="row">
  <div class="col-sm-12 col-md-6">
    <div class="input-group mb-3 d-flex flex-row justify-content-center justify-content-md-start">
      <button class="btn btn-outline-secondary gung-calendar-select-button" (click)="goToPreviousYear()" type="button"><i class="fa-light fa-chevron-left"></i></button>
      <select
        class="form-select gung-calendar-select"
        [(ngModel)]="selectedYear"
        (ngModelChange)="goToYear($event)"
      >
        <option
          *ngFor="let year of optionsListYears"
          [ngValue]="year.id"
        >
          {{ year.name }}<span *ngIf="year.numberOfItems">&nbsp; ({{ year.numberOfItems }})</span>
        </option>
      </select>
      <button class="btn btn-outline-secondary gung-calendar-select-button" (click)="goToNextYear()" type="button"><i class="fa-light fa-chevron-right"></i></button>
    </div>
  </div>

  <div class="col-sm-12 col-md-6">
    <div class="input-group mb-3 d-flex flex-row justify-content-center justify-content-md-end">
      <button class="btn btn-outline-secondary gung-calendar-select-button" (click)="goToPreviousMonth()" type="button"><i class="fa-light fa-chevron-left"></i></button>
      <select
        class="form-select gung-calendar-select"
        [(ngModel)]="selectedMonth"
        (ngModelChange)="goToMonth($event)"
      >
        <option
          *ngFor="let month of optionsListMonths"
          [ngValue]="month.id"
        >
          {{ month.name | translate }}<span *ngIf="month.numberOfItems">&nbsp; ({{ month.numberOfItems }})</span>
        </option>
      </select>
      <button class="btn btn-outline-secondary gung-calendar-select-button" (click)="goToNextMonth()" type="button"><i class="fa-light fa-chevron-right"></i></button>
    </div>
  </div>
</div>

<table class="gung-calendar">
  <tr class="gung-calendar-header-row">
    <th class="week-row-header">W</th>
    <th *ngFor="let weekday of gungCalendarWeekdayOrder">
      <span class="mobile-weekday text-capitalize" translate>{{ weekdayTranslationMap?.[weekday]?.abr | translate }}</span>
      <span class="desktop-weekday text-capitalize" translate>{{ weekdayTranslationMap?.[weekday]?.full | translate }}</span>
    </th>
  </tr>

  <tr *ngIf="isLoading; else calendarRowsTemplate">
    <td class="text-center p-5 w-100" colspan="8">
      <i class="fa fa-spinner fa-spin fa-7x"></i>
    </td>
  </tr>
</table>

<div *ngIf="!!selectedDay?.day" class="mobile-item-view">
  <div class="gung-calendar-item-header mb-2">
    <h4 class="mt-0">{{ selectedDay.date }}</h4>

    <div *ngIf="enableCreateItem" class="add-button" (click)="triggerCreateEvent(selectedDay.day)">
      <i class="fa-regular fa-plus"></i>
    </div>
  </div>
  <span *ngFor="let item of selectedDay.items; let index = index">
    <span *ngIf="index <= 4 || selectedDay.showMore" class="gung-calendar-item-link">
      <a [routerLink]="item.link ? [item.link] : undefined" (click)="item.action ? item.action() : undefined">{{ item.heading }}</a>
      <button *ngIf="enableChangeDate" class="btn btn-primary" (click)="triggerEditEvent(selectedDay.day, item.id)"><i class="fa-regular fa-pen-to-square"></i></button>
    </span>
  </span>
  <div *ngIf="selectedDay?.items?.length > 5" class="mt-2 text-center show-more-button">
    <a class="btn btn-primary" (click)="selectedDay.showMore = !selectedDay.showMore;">{{ selectedDay.showMore ? ('SHOW_LESS' | translate) : ('SHOW_MORE' | translate)}}</a>
  </div>
</div>

<ng-template #calendarRowsTemplate cdkDropListGroup>
  <tr *ngFor="let calendarRow of mappedCalendarMonth">
    <td class="week-row">
      {{ calendarRow.week }}
    </td>
    <td *ngFor="let calendarItem of calendarRow.items" class="gung-calendar-item" [ngClass]="{'non-selectable-day': !calendarItem.day}" cdkDropList (cdkDropListDropped)="onDrop($event, calendarItem.day)" [cdkDropListData]="calendarItem.items">
      <div class="item-content" [ngClass]="{'non-selectable-day': !calendarItem.day}">
        <div *ngIf="calendarItem.day" class="gung-calendar-item-header mb-2">
          <div
            class="day"
            [ngClass]="{
              'day-has-items bg-primary text-white': calendarItem?.items?.length > 0,
              'today-day': calendarItem?.items?.length === 0 && (selectedYear === todayYear && selectedMonth === todayMonth && calendarItem.day === todayDay)
            }"
          >
            {{ calendarItem.day }}
          </div>

          <div *ngIf="enableCreateItem" class="add-button" (click)="triggerCreateEvent(calendarItem.day)">
            <i class="fa-regular fa-plus"></i>
          </div>
        </div>

        <div *ngFor="let item of calendarItem.items; let index = index" [cdkDragDisabled]="!enableDragDropChangeDate" cdkDrag>
          <div *ngIf="index <= 4 || calendarItem.showMore" class="gung-calendar-item-link">
            <span *ngIf="enableDragDropChangeDate" class="gung-calendar-item-link-draghandle bg-primary" cdkDragHandle><i class="fa-solid fa-grip-dots-vertical"></i></span>
            <a [routerLink]="item.link ? [item.link] : undefined" (click)="item.action ? item.action() : undefined">{{ item.heading }}</a>
            <button *ngIf="enableChangeDate" class="btn btn-primary" (click)="triggerEditEvent(calendarItem.day, item.id)"><i class="fa-regular fa-pen-to-square"></i></button>
          </div>
          <div class="custom-drag-placeholder" *cdkDragPlaceholder></div>
        </div>
        <div *ngIf="calendarItem?.items?.length > 5" class="mt-2 text-center show-more-button">
          <a class="btn btn-primary" (click)="calendarItem.showMore = !calendarItem.showMore;">{{ calendarItem.showMore ? 'SHOW_LESS' : 'SHOW_MORE'}}</a>
        </div>
      </div>

      <div class="item-content-mobile" (click)="selectDay(calendarItem)" [ngClass]="{'selected-mobile text-primary bg-secondary': calendarItem.day === selectedDay?.day, 'selectable-day': !!calendarItem.day, 'non-selectable-day': !calendarItem.day}">
        <span
          *ngIf="calendarItem.day"
          class="day"
          [ngClass]="{
            'day-has-items bg-primary text-white': calendarItem?.items?.length > 0,
            'today-day': calendarItem?.items?.length === 0 && (selectedYear === todayYear && selectedMonth === todayMonth && calendarItem.day === todayDay)
          }"
        >
          {{ calendarItem.day }}
        </span>
      </div>
    </td>
  </tr>
</ng-template>