import { Component, OnInit } from '@angular/core';
import { CartTotalStepComponent } from '../cart-total-step/cart-total-step.component';
import { SalesCartConfigService } from '../../../../services/sales-cart-config/sales-cart-config.service';

@Component({
  selector: 'lib-cart-sales-step',
  template: '<lib-filter-list #cartList [configService]="salesCartConfigService"></lib-filter-list>'
})
export class CartSalesStepComponent extends CartTotalStepComponent {
  constructor(public salesCartConfigService: SalesCartConfigService) {
    super();
  }
}
