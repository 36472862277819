import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';
import * as CountryCodes from './CountryCodes.json';
import { gungComparatorHelper } from '../../utils/gung-utils';
import { GungLanguageConfigService } from '../../services/gung-language-config/gung-language-config.service';

export interface CountryCode {
  name: string;
  dial_code: string;
  code: string;
}

@Component({
  selector: 'lib-gung-phone-number-input',
  templateUrl: './gung-phone-number-input.component.html',
  styleUrls: ['./gung-phone-number-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungPhoneNumberInputComponent),
      multi: true
    }
  ]
})
export class GungPhoneNumberInputComponent extends BaseInputDirective<string> implements OnInit {
  @Input()
  public extraInputClass = '';

  @Input()
  public labelBold = false;

  @Input()
  public footnote = false;
  
  @Input()
  showCountryCode: boolean = true;

  @Input()
  showFlags: boolean = true;

  @Input()
  filterCountryByCode: string[];

  @Input()
  maxHeight = '200px';

  searchModel: string;

  countryCodes: {[dial_code: string]: CountryCode} = {};
  countryCodesArray: CountryCode[];
  filteredCountryCodesArray: CountryCode[];

  @Input()
  selectedCountryCode: string;

  public flagSize: '16x12' | 
  '20x15' | 
  '24x18' | 
  '28x21' | 
  '32x24' | 
  '36x27' | 
  '40x30' | 
  '48x36' | 
  '56x42' | 
  '60x45' | 
  '64x48' | 
  '72x54' | 
  '80x60' | 
  '84x63' | 
  '96x72' | 
  '108x81' | 
  '112x84' | 
  '120x90' | 
  '128x96' | 
  '144x108' | 
  '160x120' | 
  '192x144' | 
  '224x168' | 
  '256x192' = '24x18';

  set value(value: string) {
    if (value && super.value !== value) {
      super.value = value;
      this.splittPhoneNumber(value);
    } else {
      this.selectedCountryCode = this.selectedCountryCode || Object.values(this.countryCodes)?.[0]?.dial_code;
    }
  }

  valueNumber: string;

  @ViewChild("searchInput") public searchInput: ElementRef;

  constructor(
    private _cdRef: ChangeDetectorRef,
    protected languageConfigService: GungLanguageConfigService
  ) {
    super();
    if (this.showCountryCode) {
      this.countryCodesArray = (Array(...(CountryCodes as any).default))
        .filter((country: CountryCode) => !this.filterCountryByCode || this.filterCountryByCode.includes(country.code))
        .sort((a,b) => gungComparatorHelper(a.name, b.name, 1)
      );
      this.countryCodes = this.countryCodesArray.reduce((prev, curr) => ({...prev, [curr.dial_code]: curr}), {});      
    }
    const country = this.languageConfigService.getDefaultCountryPhone() && 
      this.countryCodesArray.findIndex(country => country.dial_code === this.languageConfigService.getDefaultCountryPhone());
    if (country) {
      this.selectCountry(this.countryCodesArray[country]);
    }
  }
  
  ngOnInit(): void {
    super.ngOnInit();
    // if (this.value && !this.selectedCountryCode) {
    //   this.splittPhoneNumber(this.value);
    // } else {
    //   this.selectedCountryCode = this.selectedCountryCode || Object.values(this.countryCodes)?.[0]?.dial_code;
    // }
  }

  dropdownToggle(dropDownOpen: boolean) {
    if (dropDownOpen) {
      setTimeout(() => { // need to wait for the visual changes to focus 
        this._cdRef.detectChanges();
        this.searchInput.nativeElement.focus();
      }, 100);
    }
  }

  selectCountry(country: CountryCode) {
    this.selectedCountryCode = country.dial_code;
    this.value = this.selectedCountryCode + (this.valueNumber || '');
    this.searchModel = undefined;
    this.filteredCountryCodesArray = undefined;
  }

  splittPhoneNumber(value: string) {
    if (this.showCountryCode) {
      if (value.startsWith('00')) {
        value = value.replace('00', '+');
      }
      if (value.includes('+')) {
        this.selectedCountryCode = Object.keys(this.countryCodes).find(countryCode => value.startsWith(countryCode));
        if (this.selectedCountryCode) {
          this.valueNumber = value.slice(this.selectedCountryCode.length);
        }
      }

      // If component loaded and value added after
      if (!this.selectedCountryCode) {
        this.selectedCountryCode = Object.values(this.countryCodes)?.[0]?.dial_code;
        this.valueNumber = value;
      }

      this.createNumberPhone();
    }
  }

  createNumberPhone(){
    this.value = (this.selectedCountryCode || '') + (this.valueNumber || '');
  }

  setFilter(term: string) {
    this.filteredCountryCodesArray = this.countryCodesArray.filter((countryCode) => (
      countryCode.name.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
      countryCode.code.toLowerCase().indexOf(term.toLowerCase()) > -1 ||
      countryCode.dial_code.toLowerCase().indexOf(term.toLowerCase()) > -1
    ));
  }
}
