import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ComponentFactoryResolver,
  OnDestroy,
  OnInit,
  QueryList,
  Type,
  ViewChildren
} from '@angular/core';
import { ActivatedRoute, UrlSegment } from '@angular/router';
import { CustomHostDirective } from 'gung-list';
import { Subject, takeUntil } from 'rxjs';
import { BaseViewConfigService } from '../../services/base-view-config/base-view-config.service';

@Component({
  selector: 'lib-product-list-wrapper',
  templateUrl: './product-list-wrapper.component.html',
  styleUrls: ['./product-list-wrapper.component.css']
})
export class ProductListWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(CustomHostDirective)
  viewChild: QueryList<CustomHostDirective>;
  unsubscribe: Subject<void> = new Subject();
  assortment;

  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    protected changeDetectorRef: ChangeDetectorRef,
    protected componentFactoryResolver: ComponentFactoryResolver,
    protected route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.url.pipe(takeUntil(this.unsubscribe)).subscribe((segments: UrlSegment[]) => {
      if (this.viewChild && this.viewChild.length !== 0) {
        // Clear views already rendered from previous navigation
        const containerRef = this.viewChild.last.viewContainerRef;
        containerRef.clear();
      }
      this.renderLayout(this.baseViewConfigService.getProductListComponent());
    });
  }

  renderLayout(layoutComponent: Type<any>, data: any = {}): void {
    if (!this.viewChild || this.viewChild.length === 0) {
      return;
    }
    this.changeDetectorRef.detectChanges();
    const factory = this.componentFactoryResolver.resolveComponentFactory(layoutComponent);
    const containerRef = this.viewChild.last.viewContainerRef;
    containerRef.clear();
    containerRef.createComponent(factory);

    this.changeDetectorRef.detectChanges();
  }

  ngAfterViewInit() {
    if (this.viewChild && this.viewChild.length !== 0) {
      // Clear views already rendered from previous navigation
      const containerRef = this.viewChild.last.viewContainerRef;
      containerRef.clear();
    }

    this.renderLayout(this.baseViewConfigService.getProductListComponent());
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
