import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FilterListComponent } from 'gung-list';
import { Offer } from '../../models/offer';
import { OfferListConfigService } from '../../services/offers/offer-list-config.service';

@Component({
  selector: 'lib-offer-list',
  template: `<h3 translate class="page-title text-center">OFFERS</h3>
    <lib-filter-list #offerList [configService]="offerListConfigService"></lib-filter-list>`,
  styleUrls: ['./offer-list.component.css']
})
export class OfferListComponent{

  constructor(public offerListConfigService: OfferListConfigService, route: ActivatedRoute) {
    const customerId = route.snapshot.params.customerId;

    if (customerId != null) {
      offerListConfigService.setCustomerId(customerId);
    } else {
      offerListConfigService.setCustomerId(null);
    }
  }
  @ViewChild('offerList') orderList: FilterListComponent<Offer>;
}
