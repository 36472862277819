<div ngbDropdown class="d-inline-block">
  <button type="button" class="btn btn-sm btn-outline-primary px-4" id="dropdownForm1" ngbDropdownToggle translate>
    ADD_NOTE
  </button>
  <div ngbDropdownMenu class="dropdown-body p-3">
    <lib-gung-text-area-input gungTranslate="HEADING" [(ngModel)]="heading" rowsNumber="2"></lib-gung-text-area-input>
    <lib-gung-text-area-input gungTranslate="NOTE" [(ngModel)]="note" rowsNumber="6"></lib-gung-text-area-input>
    <lib-gung-select-option-input
      [optionsList]="fonts"
      [(ngModel)]="selectedFont"
      gungTranslate="FONTS"
    ></lib-gung-select-option-input>
    <div class="dropdown-divider"></div>
    <div class="d-flex justify-content-end">
      <button ngbDropdownItem type="button" class="btn btn-primary submitbutton" (click)="createNote()">ADD</button>
    </div>
  </div>
</div>
