import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Document } from '../../models/document';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {
  protected baseUrl = `json/documents`;
  protected uploadUrl = `v2/import/documents`;
  protected editUrl = `import/documents`;

  constructor(protected http: HttpClient, protected backendInterceptor: BackendInterceptor) {}

  public getDocuments(nocache?: boolean): Observable<Document[]> {
    const url = `${this.baseUrl}/list`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<Document[]>(url, { headers });
  }

  public deleteDocument(documentId: string): Observable<Document[]> {
    const url = `${this.baseUrl}/${documentId}`;
    return this.http.delete<Document[]>(url);
  }

  public uploadDocument(formData: FormData): Observable<any> {
    const url = this.uploadUrl;
    return this.http.post<any>(url, formData);
  }

  public getDocumentUrl(docId) {
    return this.backendInterceptor.getBaseUrl() + 'gung-document-archive/document/' + docId;
  }

  public editDocument(documentId, metadata): Observable<any> {
    const url = `${this.editUrl}/${documentId}`;
    return this.http.put<any>(url, metadata);
  }
}
