<div class="row">
  <div *ngFor="let digitalAsset of data" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2">
    <div class="card border h-100" (click)="!isManagement ? openModal(digitalAsset) : undefined"
      [class.border-selected]="selectButton.isSelected" [class.cursor-pointer]="!isManagement">
      <div class="box-img position-relative">
        <div class="show-details">+ <p translate>SHOW_DETAILS</p>
        </div>
        <img *ngIf="digitalAsset.images && digitalAsset.images[0] as image; else noimage"
          class="card-img-top img-fluid mx-auto d-block" 
          [src]="image.s3Uri | gungImageUrl
            :'type:list'
            :'etag:'+image.s3ETag
          " alt="{{ image.description }}" />
        <ng-template #noimage>
          <img class="card-img-top img-fluid w-100"
            [src]="'images/no-image.jpg' | gungImageUrl" alt="NO IMAGE"
            class="img-fluid mx-auto d-block" />
        </ng-template>
      </div>
      <button translate class="btn btn-primary btn-sm w-100">SHOW_DETAILS</button>

      <div class="card-body">
        <h5 class="card-title">{{ digitalAsset.name }}</h5>
        <!-- <p class="card-text">{{digitalAsset.id}}</p> -->
        <!-- <button type="button" class="btn btn-primary w-100" [routerLink]="['./', pim.id]" translate>MANAGE_PRODUCT</button> -->
        <div class="row">
          <div class="col-12 col-md-6">
            <button *ngIf="!isManagement" type="button" class="btn btn-primary btn-sm w-100" translate
              (click)="$event.preventDefault(); $event.stopPropagation(); download(digitalAsset)">
              DOWNLOAD_ALL
            </button>
            <button *ngIf="isManagement" type="button" class="btn btn-primary w-100" [routerLink]="[digitalAsset.id]"
              translate>
              DIGITAL_ASSETS_MANAGE
            </button>
          </div>
          <div class="col-12 col-md-6">
            <lib-item-selection-button [item]="digitalAsset" class="" #selectButton [id]="digitalAsset.id">
            </lib-item-selection-button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isManagement" class="col-12 col-sm-6 col-md-4 col-lg-3 mb-2 add-new-folder">
    <div class="card border h-100 middle inside-center pointer bg-light" [routerLink]="['new']">
      <div class="card-body inside-center-text image-text w-100 text-uppercase">
        <h5 class="card-title text-center">
          <button type="button" class="btn btn-link"><i class="fas fa-plus-circle fa-2x"></i></button>
        </h5>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-modal>
  <div class="digital-assets-modal">
    <div class="modal-header">
      <h4 class="modal-title m-0" id="modal-basic-title">{{ selectedDigitalAsset.name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="row">
            <div class="col-12">
              <div class="assets-thumbs mx-auto d-block"
                *ngIf="selectedDigitalAsset.images && selectedDigitalAsset.images[0] as image; else noimage">
                <img class="img-fluid d-block" *ngFor="let image of selectedDigitalAsset.images"
                  [ngClass]="{ border: image.extra._selected }" (click)="image.extra._selected = !image.extra._selected"
                  [src]="image.s3Uri | gungImageUrl
                    :'type:list'
                    :'etag:'+image.s3ETag
                  " 
                  alt="{{ image.description }}" />
              </div>

              <ng-template #noimage>
                <img class="img-fluid w-100"
                  [src]="'images/no-image.jpg' | gungImageUrl"
                  alt="NO IMAGE"
                  class="img-fluid mx-auto d-block" />
              </ng-template>
            </div>
          </div>

          <div class="row" *ngIf="!!selectedDigitalAsset.extra.links && selectedDigitalAsset.extra.links.length > 0">
            <div class="col-12">
              <h5>
                <span translate>LINKS</span>:
              </h5>
              <ul style="list-style-type: disc;">
                <li *ngFor="let link of selectedDigitalAsset.extra.links">
                  <a href="{{ link }}" target="_blank">{{ link }}</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <!-- <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="fileType" class="control-label" translate>FILE_TYPE</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="fileType" id="fileType" [value]="selectedDigitalAsset.extra.fileType || 'zip'" readonly>
              </div>
            </div>
          </div> -->
          <!-- <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="size" class="control-label" translate>SIZE</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="size" id="size" [value]="selectedDigitalAsset.extra.size || '-'" readonly>
              </div>
            </div>
          </div> -->
          <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="images" class="control-label" translate>NUMBER_OF_FILES</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="images" id="images"
                  [value]="selectedDigitalAsset.images.length" readonly />
              </div>
            </div>
          </div>
          <!-- <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="dimensions" class="control-label" translate>DIMENSIONS</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="dimensions" id="dimensions" [value]="selectedDigitalAsset.extra.dimensions || '-'" readonly>
              </div>
            </div>
          </div> -->
          <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="brand" class="control-label" translate>BRAND</label>
              </div>
              <div class="col-12 col-md-6">
                <!-- <input class="form-control" type="text" name="brand" id="brand" [value]="selectedDigitalAsset.extra.brand" readonly> -->
                <lib-gung-meta-output [horizontal]="true" [gungTranslate]="''"
                  [value]="selectedDigitalAsset.extra.brand" metadataTable="TA40" metaOptionName="TX1">
                </lib-gung-meta-output>
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="tags" class="control-label" translate>TAGS</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="tags" id="tags"
                  [value]="selectedDigitalAsset.extra.tags || '-'" readonly />
              </div>
            </div>
          </div>
          <div class="form-group">
            <div class="row flex-fill">
              <div class="col-12 col-md-6 d-flex align-items-center">
                <label for="date" class="control-label" translate>DATE</label>
              </div>
              <div class="col-12 col-md-6">
                <input class="form-control" type="text" name="date" id="date"
                  [value]="selectedDigitalAsset.dateLastUpdate | date: dateUtilService.dateFormat" readonly />
              </div>
            </div>
          </div>
          <button type="button" class="btn btn-primary btn-sm" trnaslate
            (click)="download(selectedDigitalAsset); modal.close('Save click')">
            DOWNLOAD
          </button>
          <hr />
          <h5><span translate>DOCUMENTS</span>:</h5>
          <ul class="list-unstyled">
            <li *ngFor="let document of selectedDigitalAsset?.documents">
              <input type="checkbox" aria-label="Checkbox for selection" class="mr-1" style="transform: scale(1.2)"
                [(ngModel)]="document.extra._selected" />
              <a [href]="document | gungDownloadUrl" target="_blank">{{ document.name }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
    </div> -->
  </div>
</ng-template>
