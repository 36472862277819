import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ConfigFilterGroup, ConfigService, FilterSelectionAction, RenderFilter } from '../types';

interface SelectedFilter {
  filterName: string;
  optionId: string;
  optionName: string;
  subFilterName?: string;
}

@Component({
  selector: 'lib-filter-list-side-filter-panel',
  templateUrl: './filter-list-side-filter-panel.component.html',
  styleUrls: ['./filter-list-side-filter-panel.component.scss']
})
export class FilterListSideFilterPanelComponent implements OnInit, OnChanges {
  @Input()
  protected configService: ConfigService<any>;

  @Input()
  protected renderFilters: RenderFilter[];

  @Output()
  filterEvent = new EventEmitter<FilterSelectionAction[]>();

  @Output()
  clearEvent = new EventEmitter<void>();

  @Output()
  searchEvent = new EventEmitter<string>();

  @Output()
  batchEvent = new EventEmitter<number>();

  searchTerm: string;
  renderFilterGroups: ConfigFilterGroup[] = [];
  selectedFilters: SelectedFilter[] = [];

  hideScroll = true;

  constructor() {}

  ngOnInit(): void {
    /* this.hideScroll = this.configService. SOME METHOD TO HIDE SCROLL */
  }

  ngOnChanges(): void {
    this.renderFilterGroups = [];
    this.selectedFilters = [];
    this.buildFilterGroups();
  }

  buildFilterGroups() {
    if (!!this.renderFilters) {
      this.configService.getFilterGroups().forEach(filterGroup => {
        const mainRenderFilter = this.renderFilters.find(rf => rf.name === filterGroup.mainFilter);
        const subRenderFilter = this.renderFilters.find(rf => rf.name === filterGroup.subFilter);
        this.renderFilterGroups.push({
          mainFilter: mainRenderFilter,
          subFilter: subRenderFilter
        });
      });
      this.initSelectedFilters();
      this.sortFilterValues();
    }
  }

  getValuesFiltered(valueList: any[]) {
    return valueList.filter(
      option =>
        option.selected || (option.itemCountAfterFilter > 0 && option.valueId !== 'OTHER' && option.valueId !== '-')
    );
  }

  initSelectedFilters() {
    const filterGroups = this.configService.getFilterGroups();
    this.renderFilters.forEach(renderFilter => {
      const subFilterName = filterGroups.find(fg => fg.mainFilter === renderFilter.name)?.subFilter as string;
      renderFilter.valueList.forEach(option => {
        if (option.selected) {
          this.selectedFilters.push({
            filterName: renderFilter.name,
            optionId: option.valueId,
            optionName: option.valueName,
            subFilterName
          });
        }
      });
    });
  }

  sortFilterValues() {
    return;
  }

  filterSelection(filterName: string, optionId: string, selected: boolean, optionName: string, subFilterName?: string) {
    const actionList: FilterSelectionAction[] = [];

    this.updateSelectedFilters(filterName, optionId, selected, optionName, subFilterName);

    // If the filter is a main filter: deselect all values from main filter and sub filter
    if (!!subFilterName) {
      actionList.push({
        filterName,
        optionId: 'CLEAR_FILTER',
        selected: false
      });
      actionList.push({
        filterName: subFilterName,
        optionId: 'CLEAR_FILTER',
        selected: false
      });
    }

    actionList.push({
      filterName,
      optionId,
      selected
    });

    this.filterEvent.emit(actionList);
  }

  updateSelectedFilters(
    filterName: string,
    optionId: string,
    selected: boolean,
    optionName: string,
    subFilterName?: string
  ) {
    if (!!subFilterName) {
      this.selectedFilters = this.selectedFilters.filter(
        selectedFilter => selectedFilter.filterName !== filterName && selectedFilter.filterName !== subFilterName
      );
    }

    if (selected) {
      this.selectedFilters.push({
        filterName,
        optionId,
        optionName,
        subFilterName
      });
    } else {
      this.selectedFilters = this.selectedFilters.filter(
        selectedFilter => !(selectedFilter.filterName === filterName && selectedFilter.optionId === optionId)
      );
    }
  }
}
