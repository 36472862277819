import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Availability } from '../../models/availability';
import { ProductDetailMatrixModalComponent } from '../../components/matrix/product/product-detail-matrix-modal/product-detail-matrix-modal.component';
import { ProductMatrixElementAvailabilityModalComponent } from '../../components/matrix/product/product-matrix-element-availability-modal/product-matrix-element-availability-modal.component';

@Injectable({
  providedIn: 'root'
})
export class ProductMatrixModalService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();
  public allowRestrictedAvailabilityView = false;

  constructor(protected ngbModal: NgbModal) {}

  openProductMatrixModal(modelId: string, initialImage: { image: any; id: string }, productId?: string) {
    const id = ProductDetailMatrixModalComponent.name;

    const ref = this.ngbModal.open(ProductDetailMatrixModalComponent, {
      size: 'lg',
      windowClass: 'modal-lg-custom'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.modelId = modelId;
    ref.componentInstance.initialImage = initialImage;
    ref.componentInstance.productId = productId;
    ref.result.then(
      result => {},
      reason => {}
    );

    this.modalRef.set(id, ref);
  }

  openAvailabilityModal(availability: Availability, productId: string, restrictedView?: boolean) {
    const id = ProductMatrixElementAvailabilityModalComponent.name;

    const ref = this.ngbModal.open(ProductMatrixElementAvailabilityModalComponent, {
      size: 'lg'
    });
    ref.componentInstance.delegate = this;
    ref.componentInstance.availability = availability;
    ref.componentInstance.productId = productId;
    ref.componentInstance.restrictedView = !!restrictedView && this.allowRestrictedAvailabilityView;
    ref.result.then(
      result => {},
      reason => {}
    );

    this.modalRef.set(id, ref);
  }
}
