import { Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungNumberInputHorizontalComponent } from '../gung-number-input-horizontal/gung-number-input-horizontal.component';

@Component({
  selector: 'lib-gung-number-input',
  templateUrl: './gung-number-input.component.html',
  styleUrls: ['./gung-number-input.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungNumberInputComponent),
      multi: true
    }
  ]
})
export class GungNumberInputComponent extends GungNumberInputHorizontalComponent {}
