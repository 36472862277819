import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class EdiOrderResponseDetailConfigService {
  disable = {
    customerNumber: true,
    orderDate: true,
    currency: true,
    supplierOrderNumber: true,
    deliveryDate: true,
    orderStatus: true,
    b24OrderNumber: true,
    earliestDelivery: true,
    orderType: true,
    contactOrderNo: true,
    latestDeliveryDate: true,
    discount: true,
    orderChangeNumber: true,
    customerReference: true,
    customerOrderNotes: true
  };

  constructor() {}
}
