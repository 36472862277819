import { Component, ElementRef, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-number-input-horizontal',
  templateUrl: './gung-number-input-horizontal.component.html',
  styleUrls: ['./gung-number-input-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungNumberInputHorizontalComponent),
      multi: true
    }
  ]
})
export class GungNumberInputHorizontalComponent extends BaseInputDirective<number | string> implements OnInit {
  @Input()
  public labelClass: string | string[];

  @Input()
  public extraInputClass = '';

  @Input()
  public extraFormClass = '';

  @Input()
  protected outputAsString?: boolean;

  @Input()
  public labelBold = false;

  @Input()
  decimals?: number;

  @Input()
  stepAmount: number;

  @Input()
  minValue: number;

  @Input()
  maxValue: number;

  @Input()
  public footnote = false;

  @ViewChild('inputNumber', { static: true }) inputNumberElement: ElementRef;

  constructor(inputNumberElement: ElementRef) {
    super();
    this.inputNumberElement = inputNumberElement;
  }

  set value(value: number) {
    if (this.maxValue && value > this.maxValue) {
      value = this.maxValue;
      this.inputNumberElement.nativeElement.value = value;
    }
    if (this.minValue && value < this.minValue) {
      value = this.minValue;
      this.inputNumberElement.nativeElement.value = value;
    }
    if (this.outputAsString) {
      super.value = '' + this.fixDecimals(Number(value));
    } else {
      super.value = this.fixDecimals(value);
    }
    if (super.value !== value) {
      // Update INPUT value
      this.inputNumberElement.nativeElement.value = super.value;
    }
  }

  get value() {
    if (super.value === null || super.value === undefined || super.value === '') {
      return;
    }
    if (this.outputAsString) {
      return this.fixDecimals(Number(super.value));
    }
    return Number(this.fixDecimals(super.value));
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.setStepAmount();
  }

  fixDecimals(value): number {
    if (value && (this.decimals !== null && this.decimals !== undefined)) {
      value = +value.toFixed(this.decimals);
    }
    return value;
  }

  setStepAmount() {
    if ((this.decimals !== null && this.decimals !== undefined) && !this.stepAmount) {
      let stepAmount = '0.0000000001';
      stepAmount = stepAmount.substring(0, 2) + stepAmount.substring(stepAmount.length - this.decimals);
      this.stepAmount = +parseFloat(stepAmount);
    }
  }
}
