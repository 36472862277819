import { EventEmitter, Injectable } from '@angular/core';
import { ActionButton, ActionButtonConfigService, DownloadMultipleFilesComponent } from 'gung-common';
import { Order } from '../../models/order';
import { first, forkJoin, Observable, of, switchMap } from 'rxjs';
import { BackendRerouteService } from '../utils/backend-reroute.service';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { CustomerService } from '../customers/customer.service';
import { Customer } from '../../models/customer';
import { OrderChangeCustomerConfirmationModalComponent } from '../../components/order-change-customer-confirmation-modal/order-change-customer-confirmation-modal.component';
import { OrderToBasketService } from '../services/order-to-basket.service';

@Injectable({
  providedIn: 'root'
})
export class OrderActionConfigService implements ActionButtonConfigService<Order> {
  modalRef: NgbModalRef;
  constructor(
    protected backendRerouteService: BackendRerouteService,
    protected modalService: NgbModal,
    protected selectedCustomerService: SelectedCustomerService,
    protected customerService: CustomerService,
    protected orderToBasketService: OrderToBasketService
  ) {}

  standard = [
    { title: 'XLSX', action: (item: Order) => this.backendRerouteService.reroute('download/order-excel/' + item.id) },
    { title: 'PDF', action: (item: Order) => this.backendRerouteService.reroute('download/order-pdf/' + item.id) },
    { title: 'IMAGES', action: (item: Order) => this.openImageZipDownloadModal(item) },
    { title: 'COPY_TO_CART', action: (item: Order) => this.addOrderToCart(item) }
  ];

  getButtons(item: Order, eventEmitter?: EventEmitter<Order>): Observable<ActionButton<Order>[]> {
    return of(this.standard);
  }

  openImageZipDownloadModal(item: Order) {
    const option: NgbModalOptions = {
      keyboard: true,
      centered: true
    };

    const ref = this.modalService.open(DownloadMultipleFilesComponent, option);
    const componentInstance = ref.componentInstance as DownloadMultipleFilesComponent;
    componentInstance.orderId = item.id;
  }

  addOrderToCart(item: Order) {
    this.selectedCustomerService
      .getSelectedCustomer()
      .pipe(
        first(),
        switchMap(selectedCustomer => {
          return forkJoin({
            selectedCustomer: of(selectedCustomer),
            orderCustomer: this.customerService.getCustomer(item.deliveryCustomerId).pipe(first())
          });
        })
      )
      .subscribe(({ selectedCustomer, orderCustomer }) => {
        if (selectedCustomer.id === orderCustomer.id) {
          this.copyToCart(item);
        } else {
          this.changeCustomerDialog(selectedCustomer, orderCustomer, item);
        }
      });
  }

  changeCustomerDialog(currentCustomer: Customer, orderCustomer: Customer, item: Order) {
    this.modalRef = this.modalService.open(OrderChangeCustomerConfirmationModalComponent);
    this.modalRef.componentInstance.currentCustomer = currentCustomer;
    this.modalRef.componentInstance.nextCustomer = orderCustomer;

    this.modalRef.result
      .then(
        resolve => {
          if (resolve === 'Change') {
            this.selectedCustomerService.selectCustomer(orderCustomer.id);
          }
        },
        () => {
          throw new Error('Rejected');
        }
      )
      .then(() => this.copyToCart(item))
      .catch(error => console.log(error));
  }

  copyToCart(item: Order) {
    this.orderToBasketService.addOrderToBasket(item.id).then(
      result => {},
      reason => {}
    );
  }
}
