<div>
  <lib-gung-accordion>
    <lib-gung-accordion-item [collapsed]="false">
      <ng-template libGungAccordionHeader>{{ 'WITHOUT_SPECIFIC_STEP' | translate }}</ng-template>
      <ng-template libGungAccordionBody>
        <div class="mb-1">
          <input type="text" class="form-control" [(ngModel)]="context" />
        </div>
        <div>
          <button type="button" class="btn btn-primary" translate (click)="sendOrderContext()">SEND</button>
          <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
        </div>
      </ng-template>
    </lib-gung-accordion-item>
    
    <lib-gung-accordion-item>
      <ng-template libGungAccordionHeader>{{ 'WITH_SPECIFIC_STEP' | translate }}</ng-template>
      <ng-template libGungAccordionBody>
        <div class="row">
          <div class="col-6">
            <div class="mb-1">
            <input type="text" class="form-control" [(ngModel)]="contextId" />
          </div>
          </div>
          <div class="col-6">
            <div class="mb-1">
              <button type="button" class="btn btn-primary" translate (click)="getSteps()">Get Steps</button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <select id="steps" class="form-control form-select" [(ngModel)]="selectedStep">
              <option [value]="option.name" *ngFor="let option of steps;">
                <span [ngClass]="option.errorStep?'issued_step':''">{{ option.name}} - <span *ngIf="option.errorStep" style="color: red">Issue step </span></span>
              </option>
            </select>
          </div>
          <div>
            <button type="button" class="btn btn-primary" translate (click)="sendOrderContextWithStep()">SEND</button>
            <i *ngIf="loading" class="fa fa-spinner fa-spin fa-lg"></i>
          </div>
        </div>
      </ng-template>
    </lib-gung-accordion-item>  
  </lib-gung-accordion>

  <pre *ngIf="this.error">
        {{ error | json }}
    </pre
  >
  <pre *ngIf="data">
        {{ data | json }}
    </pre
  >
</div>
