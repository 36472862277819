import { Injectable } from '@angular/core';
import { FashionLoginComponent } from '../../components/login/fashion-login/fashion-login.component';
import { LoginComponent } from '../../components/login/login.component';
import { NavbarLoginComponent } from '../../components/login/navbar-login/navbar-login.component';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { GungLanguageConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class LoginConfigService {
  constructor(
    protected baseViewConfigService: BaseViewConfigService,
    protected languageConfigService: GungLanguageConfigService
  ) { }

  showRequestAccount() {
    return false;
  }

  getLoginType(): LoginType {
    return undefined;
  }

  getLoginComponent(loginType?) {
    switch (this.getLoginType()) {
      case LoginType.FashionLogin:
        return FashionLoginComponent;
      case LoginType.NavbarLogin:
        return NavbarLoginComponent;
      default:
        return this.baseViewConfigService.getLoginComponent(loginType);
    }
  }

  getVideoBackground(): string {
    return undefined;
  }

  getShowLanguageDropDown(): boolean {
    return this.languageConfigService.showLanguageDropDownLogin;
  }

  getShowInTabsEnabled(): boolean {
    return false;
  }
}

export enum LoginType {
  FashionLogin,
  NavbarLogin,
  default
}
