@if(form){
  <div class="row" [formGroup]="form">
    <div class="col-12 col-md-6" formGroupName="deliveryAddress">
      <div class="form-group">
        <label for="name" class="control-label" translate>NAME</label>
        <input class="form-control" type="text" name="name" id="name" [formControlName]="'name'" />
      </div>
      <div class="form-group">
        <label for="address1" class="control-label" translate>ADDRESS</label>
        <input class="form-control" type="text" name="address1" id="address1" [formControlName]="'address1'" />
      </div>
      <div class="form-group">
        <label for="address2" class="control-label" translate>ADDRESS_2</label>
        <input class="form-control" type="text" name="address2" id="address2"
          [formControlName]="'address2'" />
      </div>
      <div class="form-group">
        <label for="zipCode" class="control-label" translate>ZIP</label>
        <input class="form-control" type="text" name="zipCode" id="zipCode" [formControlName]="'zipCode'" />
      </div>
      <div class="form-group">
        <label for="city" class="control-label" translate>CITY</label>
        <input class="form-control" type="text" name="city" id="city" [formControlName]="'city'" />
      </div>
  
      <lib-meta-select-input [formControlName]="'countryCode'" table="countries" metaId="alpha-2"
        metaDisplay="name" gungTranslate="COUNTRY">
      </lib-meta-select-input>
    </div>
    <div class="col-12 col-md-6">
      <div class="form-group">
        <label for="message" class="control-label" translate>MESSAGE</label>
        <textarea class="form-control" rows="5" name="message" id="message" [formControlName]="'message'"></textarea>
      </div>
      <div class="form-group">
        <label for="referenceNo" class="control-label" translate>REFERENCE_NO</label>
        <input class="form-control" type="text" name="referenceNo" id="referenceNo" [formControlName]="'referenceNo'" />
      </div>
      <lib-confirmation-emails [emails]="checkout.extra.confirmationEmails"
        (emailsUpdated)="checkout.extra.confirmationEmails = $event"></lib-confirmation-emails>
    </div>
    <div class="row">
      <div class="col-12">
        <ngb-alert class="mt-2" *ngIf="alert" [type]="alert.type" (close)="alert = null">
          {{ alert.message | translate }}
        </ngb-alert>
      </div>
    </div>
  </div>
}
