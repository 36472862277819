import { Component, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { ConfigBaseFilter, FilterListComponent, PimTemplateProperties } from 'gung-list';
import { Product } from '../../models';
import { ConceptDetailProductListConfigService } from '../../services/concept-detail-product-list-config/concept-detail-product-list-config.service';

@Component({
  selector: 'lib-concept-detail',
  template: '<lib-filter-list #conceptList [configService]="configService"></lib-filter-list>',
  styleUrls: ['./concept-detail.component.css']
})
export class ConceptDetailComponent implements OnInit, OnChanges {
  @Input()
  private subProducts: Product[];

  @Input()
  filters: ConfigBaseFilter<Product>[] = [];

  @ViewChild('conceptList') conceptList: FilterListComponent<Product>;

  @Input()
  public dynamicColumns?: PimTemplateProperties[];

  @Input()
  public pimOptions?: { [s: string]: any };

  constructor(public configService: ConceptDetailProductListConfigService) {}

  ngOnInit() {
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
    this.configService.conceptSubProductsObjectsSubject.next(this.subProducts);
  }

  ngOnChanges() {
    this.configService.filters = this.filters;
    this.configService.dynamicColumns = this.dynamicColumns;
    this.configService.pimOptions = this.pimOptions;
    this.configService.conceptSubProductsObjectsSubject.next(this.subProducts);
  }
}
