export interface TableRecord {
  [field: string]: string;
}

export interface Metadata {
  [tableName: string]: {
    _description: string;
    list: [TableRecord];
    map: {
      [key: string]: TableRecord;
    };
  };
}

export interface MetadataState {
  metadata: Metadata;
}

export const metadataFeatureName = 'metadata';
