import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-edi-modal-add-campaign-products',
  templateUrl: './edi-modal-add-campaign-products.component.html',
  styleUrls: ['./edi-modal-add-campaign-products.component.css']
})
export class EdiModalAddCampaignProductsComponent implements OnInit {
  constructor(public activeModal: NgbActiveModal) {}

  ngOnInit(): void {}
}
