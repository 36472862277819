import { Component } from '@angular/core';
import { DeliveryLocationListConfigService } from '../../services/delivery-location-list-config/delivery-location-list-config.service';

@Component({
  selector: 'lib-delivery-location',
  template:
    '<h3 translate class="page-title text-center">DELIVERY_LOCATION</h3><lib-filter-list [configService]="deliveryLocationListConfigService"></lib-filter-list>',
  styles: ['h1.page-title { font-size:20px;text-transform:none;margin-bottom: 15px;text-align: center; }']
})
export class DeliveryLocationComponent {
  constructor(public deliveryLocationListConfigService: DeliveryLocationListConfigService) {}
}
