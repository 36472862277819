<div *ngIf="data; else loading" [class.border]="edit">
  <!--Section 1-->
  <div class="row">
    <div class="col-12 col-lg-6 image-container" [ngClass]="{'cursor-pointer': edit}" [style.backgroundImage]="'url(' + getImage(data.elements?.section1Image?.image) + ')'" (click)="edit ? openModal('section1Image') : navigateTo(data.elements?.section1Image.link)">
      <div class="d-flex flex-row justify-content-between align-items-end w-100 text-white">
        <h1 class="mb-4" style="max-width: 150px;" [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section1ImageText1') : navigateTo(data.elements?.section1ImageText1.link); $event.stopPropagation();">{{ getText(data.elements?.section1ImageText1?.text) }}</h1>
        <div class="mb-4 mr-4 text-center">
          <h5 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section1ImageText2') : navigateTo(data.elements?.section1ImageText2.link); $event.stopPropagation();">{{ getText(data.elements?.section1ImageText2?.text) }}</h5>
          <h5 class="mt-0" [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section1ImageText3') : navigateTo(data.elements?.section1ImageText3.link); $event.stopPropagation();">{{ getText(data.elements?.section1ImageText3?.text) }}</h5>
        </div>
      </div>
    </div>

    <div class="col-12 col-lg-5 offset-lg-1 mt-5 mt-lg-0">
      <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section1Title') : navigateTo(data.elements?.section1Title.link)">{{ getText(data.elements?.section1Title?.text) }}</h3>
      <button *ngIf="edit" (click)="openModalTextInput('section1Products')" class="image-change-button btn btn-primary mr-2" translate>CHANGE_PRODUCTS</button>
      <button *ngIf="edit" (click)="openModalTextInput('section1ProductsSize')" class="image-change-button btn btn-primary" translate>CHANGE_PRODUCTS_PER_ROW</button>
      <lib-product-ids-card-show [productIds]="getTextNoTranslate(data.elements?.section1Products?.text)" [itemsPerRow]="getTextNoTranslate(data.elements?.section1ProductsSize?.text)"></lib-product-ids-card-show>
    </div>
  </div>

  <!--Section 2-->
  <div class="row mt-5">
    <div class="col-12">
      <h3 class="text-center" [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section2Title') : navigateTo(data.elements?.section2Title.link)">{{ getText(data.elements?.section2Title?.text) }}</h3>
      <button *ngIf="edit" (click)="openModalTextInput('section2Products')" class="image-change-button btn btn-primary mr-2" translate>CHANGE_PRODUCTS</button>
      <button *ngIf="edit" (click)="openModalTextInput('section2ProductsSize')" class="image-change-button btn btn-primary" translate>CHANGE_PRODUCTS_PER_ROW</button>
      <lib-product-ids-card-show [productIds]="getTextNoTranslate(data.elements?.section2Products?.text)" [itemsPerRow]="getTextNoTranslate(data.elements?.section2ProductsSize?.text)"></lib-product-ids-card-show>
    </div>
  </div>

  <!--Section 3-->
  <div class="row mt-5">
    <div class="col-12">
      <h3 class="text-center" [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section3Title') : navigateTo(data.elements?.section3Title.link)">{{ getText(data.elements?.section3Title?.text) }}</h3>
      <button *ngIf="edit" (click)="openModalTextInput('section3Products')" class="image-change-button btn btn-primary mr-2" translate>CHANGE_PRODUCTS</button>
      <button *ngIf="edit" (click)="openModalTextInput('section3ProductsSize')" class="image-change-button btn btn-primary" translate>CHANGE_PRODUCTS_PER_ROW</button>
      <lib-product-ids-card-show [productIds]="getTextNoTranslate(data.elements?.section3Products?.text)" [itemsPerRow]="getTextNoTranslate(data.elements?.section3ProductsSize?.text)"></lib-product-ids-card-show>
    </div>
  </div>

  <!--Section 4 Tiles-->
  <div class="row mt-5">
    <div class="col-12">
      <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section4Title') : navigateTo(data.elements?.section4Title.link)">{{ getText(data.elements?.section4Title?.text) }}</h3>

      <div class="row mt-4">
        <div class="col-12 col-lg-6 p-2">
          <div class="image-container tile" [ngClass]="{'cursor-pointer': edit, 'tile-with-text': isTextFilled(data.elements?.section4Tile1Text?.text), 'tile': !isTextFilled(data.elements?.section4Tile1Text?.text)}" [style.backgroundImage]="'url(' + getImage(data.elements?.section4Tile1?.image) + ')'" (click)="edit ? openModal('section4Tile1') : undefined">
            <div class="text-white tile-contents">
              <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section4Tile1Text') : navigateTo(data.elements?.section4Tile1Text.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile1Text?.text) }}</h3>
              <button class="btn btn-primary tile-button" (click)="edit ? openModal('section4Tile1') : navigateTo(data.elements?.section4Tile1.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile1?.text) }}</button>
            </div>
          </div>
        </div>


        <div class="col-12 col-lg-6 p-2">
          <div class="image-container tile" [ngClass]="{'cursor-pointer': edit, 'tile-with-text': isTextFilled(data.elements?.section4Tile2Text?.text), 'tile': !isTextFilled(data.elements?.section4Tile2Text?.text)}" [style.backgroundImage]="'url(' + getImage(data.elements?.section4Tile2?.image) + ')'" (click)="edit ? openModal('section4Tile2') : undefined">
            <div class="text-white tile-contents">
              <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section4Tile2Text') : navigateTo(data.elements?.section4Tile2Text.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile2Text?.text) }}</h3>
              <button class="btn btn-primary tile-button" (click)="edit ? openModal('section4Tile2') : navigateTo(data.elements?.section4Tile2.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile2?.text) }}</button>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 p-2">
          <div class="image-container tile" [ngClass]="{'cursor-pointer': edit, 'tile-with-text': isTextFilled(data.elements?.section4Tile3Text?.text), 'tile': !isTextFilled(data.elements?.section4Tile3Text?.text)}" [style.backgroundImage]="'url(' + getImage(data.elements?.section4Tile3?.image) + ')'" (click)="edit ? openModal('section4Tile3') : undefined">
            <div class="text-white tile-contents">
              <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section4Tile3Text') : navigateTo(data.elements?.section4Tile3Text.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile3Text?.text) }}</h3>
              <button class="btn btn-primary tile-button" (click)="edit ? openModal('section4Tile3') : navigateTo(data.elements?.section4Tile3.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile3?.text) }}</button>
            </div>
          </div>
        </div>

        <div class="col-12 col-lg-6 p-2">
          <div class="image-container tile" [ngClass]="{'cursor-pointer': edit, 'tile-with-text': isTextFilled(data.elements?.section4Tile4Text?.text), 'tile': !isTextFilled(data.elements?.section4Tile4Text?.text)}" [style.backgroundImage]="'url(' + getImage(data.elements?.section4Tile4?.image) + ')'" (click)="edit ? openModal('section4Tile4') : undefined">
            <div class="text-white tile-contents">
              <h3 [ngClass]="{'cursor-pointer': edit}" (click)="edit ? openModal('section4Tile4Text') : navigateTo(data.elements?.section4Tile4Text.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile4Text?.text) }}</h3>
              <button class="btn btn-primary tile-button" (click)="edit ? openModal('section4Tile4') : navigateTo(data.elements?.section4Tile4.link); $event.stopPropagation();">{{ getText(data.elements?.section4Tile4?.text) }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #loading translate> LOADING </ng-template>

