import { Component, OnDestroy, OnInit } from '@angular/core';
import { WhiteboardGridConfigService } from '../../services/whiteboard-grid-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductService } from '../../../services/products/product.service';
import { WhiteboardService } from '../../services/whiteboard.service';
import { first, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { forkJoin, of, Subject } from 'rxjs';
import { WhiteboardOptionsService } from '../../services/whiteboard-options.service';
import { keyBy } from 'lodash-es';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';
import { PriceService } from '../../../services/price/price.service';

@Component({
  selector: 'lib-whiteboard-grid',
  templateUrl: './whiteboard-grid.component.html',
  styleUrls: ['./whiteboard-grid.component.scss']
})
export class WhiteboardGridComponent implements OnInit, OnDestroy {
  protected unsubscribe: Subject<void> = new Subject();

  sidenavOptions: { bottom: number; fixed: boolean; top: number; open: boolean };
  products = {};
  whiteboardId: string;

  public scrollHeight: number;
  public scrollWidth: number;

  constructor(
    public configService: WhiteboardGridConfigService,
    protected router: Router,
    protected productService: ProductService,
    protected route: ActivatedRoute,
    protected whiteboardService: WhiteboardService,
    public whiteboardOptionsService: WhiteboardOptionsService,
    public navbarConfigService: NavbarConfigService,
    protected priceService: PriceService
  ) {
    this.sidenavOptions = configService.getSidenavOptions();
  }

  ngOnInit(): void {
    this.configService.getResizeCallbackSubject().subscribe(data => {
      this.calculateDivSize();
    });
    this.route.paramMap
      .pipe(
        first(),
        switchMap(params => {
          this.whiteboardId = params.get('id');
          if (this.whiteboardId != null) {
            return this.whiteboardService.getOne(this.whiteboardId).pipe(
              first(),
              switchMap(whiteboard => {
                console.log(whiteboard);
                this.configService.sheets = whiteboard.sheets || [{ items: [], name: 'Sheet 1' }];

                const items = [];
                this.configService.sheets.forEach(sheet => items.push(...sheet.items));
                return of(items);
              })
            );
          } else {
            const items = [];
            this.configService.sheets.forEach(sheet => items.push(...sheet.items));
            return of(items);
          }
        }),
        mergeMap(items =>
          forkJoin([
            of(items),
            this.productService.getProductsByIds(items.map(i => i.productId)).pipe(first()),
            this.priceService.getCurrentCustomerPrices(items.map(i => i.productId)).pipe(first()),
            this.whiteboardService.initializeSession().pipe(first())
          ])
        )
      )
      .subscribe(([items, products, prices, _]) => {
        this.products = keyBy(products, 'id') || {};
        prices.forEach(p => {
          this.products[p.productId].rrp = p.recommendedRetailPrice.value + ' ' + p.recommendedRetailPrice.currencyCode;
        });
      });

    this.configService.productsAdded
      .pipe(
        takeUntil(this.unsubscribe),
        mergeMap(ids =>
          forkJoin([
            this.productService.getProductsByIds(ids).pipe(first()),
            this.priceService.getCurrentCustomerPrices(ids).pipe(first())
          ])
        )
      )
      .subscribe(([products, prices]) => {
        products.forEach(p => {
          this.products[p.id] = p;
        });
        prices.forEach(p => {
          this.products[p.productId].rrp = p.recommendedRetailPrice.value + ' ' + p.recommendedRetailPrice.currencyCode;
        });
      });
  }

  removeItem(item): void {
    this.configService.removeItem(item);
  }

  public ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  printWhiteboard(elementId = 'gridster-div') {
    this.calculateDivSize();
    window.print();
    return false; // Prevents the default action of following the link
  }

  calculateDivSize() {
    const gridster = document.getElementById('gridster-div').getElementsByTagName('gridster')[0];
    this.scrollHeight = gridster.scrollHeight;
    this.scrollWidth = gridster.scrollWidth;
    console.log(this.scrollHeight, this.scrollWidth);
  }

  goToPrintWhiteboard() {
    this.router.navigateByUrl('/whiteboard/' + this.whiteboardId + '/print');
  }
}
