import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-gung-ribbon-banner',
  templateUrl: './gung-ribbon-banner.component.html',
  styleUrl: './gung-ribbon-banner.component.scss'
})
export class GungRibbonBannerComponent {
  @Input()
  public label: string;

  @Input()
  public backgroundColor: string = '#000';

  @Input()
  public textColor: string = '#fff';

  @Input()
  public position: 'right' | 'left' = 'right';

  @Input()
  public ribbonClass: string;

  @Input()
  public styleTop: string;

  @Input()
  public styleLeft: string;

  @Input()
  public styleRight: string;

  @Input()
  public styleHeight: string;

  @Input()
  public styleWidth: string;

  @Input()
  public fontSize: string;
}
