import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NavbarCartService } from '../../../services/navbar-cart/navbar-cart.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { SaveCartModalConfigService } from '../../../services/save-cart-modal-config/save-cart-modal-config.service';
import { CartService } from '../../../services/cart/cart.service';
import { ButtonsComponent } from '../buttons/buttons.component';
import { AuthService } from '../../../services/auth/auth.service';
import { CheckoutConfigService } from '../../../services/checkout-config/checkout-config.service';

@Component({
  selector: 'lib-side-buttons',
  templateUrl: './side-buttons.component.html',
  styleUrls: ['./side-buttons.component.css']
})
export class SideButtonsComponent extends ButtonsComponent implements OnInit {
  constructor(
    protected navbarCartService: NavbarCartService,
    protected gungModalService: GungModalService,
    protected saveCartModalConfigService: SaveCartModalConfigService,
    protected cartService: CartService,
    protected translationService: TranslateService,
    protected authService: AuthService,
    protected checkoutConfig: CheckoutConfigService
  ) {
    super(
      navbarCartService,
      gungModalService,
      saveCartModalConfigService,
      cartService,
      translationService,
      authService,
      checkoutConfig
    );
  }

  ngOnInit() {
    super.ngOnInit();
  }
}
