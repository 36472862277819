import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { first, map, Observable, switchMap } from 'rxjs';
import { PasswordRequirement } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class PasswordPolicyService {
  constructor(protected http: HttpClient) {}

  public getPasswordRequirements(): Observable<PasswordRequirement> {
    // Validate with empty string to fail all tests. This gives us the requirements.
    return this.validatePasswordInternal('').pipe(
      first(),
      map(result => {
        return {
          minCharacters: this.getMinCharacters(result),
          maxCharacters: this.getMaxCharacters(result),
          especialCharacters: this.getSpecialCharacters(result),
          lowercaseCharacters: this.getLowercaseCharacters(result),
          uppercaseCharacters: this.getUppercaseCharacters(result),
          numberCharacters: this.getNumberCharacters(result)
        };
      })
    );
  }

  public validatePassword(password: string): Observable<boolean> {
    return this.validatePasswordInternal(password).pipe(map(result => result.valid));
  }

  private validatePasswordInternal(password: string): Observable<PasswordCheckResult> {
    const url = 'public/password-check';
    return this.http.post<PasswordCheckResult>(url, { password });
  }

  private getMinCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'TOO_SHORT') {
        return error.parameters.minimumLength;
      }
    }
    return 0;
  }

  private getMaxCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'TOO_SHORT') {
        return error.parameters.maximumLength;
      }
    }
    return 0;
  }

  private getSpecialCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'INSUFFICIENT_SPECIAL') {
        return error.parameters.minimumRequired;
      }
    }
    return 0;
  }

  private getLowercaseCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'INSUFFICIENT_LOWERCASE') {
        return error.parameters.minimumRequired;
      }
    }
    return 0;
  }

  private getUppercaseCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'INSUFFICIENT_UPPERCASE') {
        return error.parameters.minimumRequired;
      }
    }
    return 0;
  }

  private getNumberCharacters(result: PasswordCheckResult): number {
    for (const error of result.errorDetails) {
      if (error.errorCode === 'INSUFFICIENT_DIGIT') {
        return error.parameters.minimumRequired;
      }
    }
    return 0;
  }
}

interface PasswordCheckResult {
  valid: boolean;
  errorDetails: ErrorDetails[];
}

interface ErrorDetails {
  errorCode: string;
  parameters: Parameters;
}

interface Parameters {
  minimumLength?: number;
  maximumLength?: number;
  minimumRequired?: number;
}
