<div class="row" *ngFor="let item of data; index as i; trackBy: trackByFn">
  <div class="col-12">
    <div class="row">
      <div class="col-12">
        <h4 class="d-inline">{{ item.gln }} - {{ item.b24.name }} - {{ item.companyId }}</h4>
        <button type="button" class="btn btn-primary float-right" translate (click)="onEdit(i)">EDIT</button>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ADDRESS"
          [(ngModel)]="item.b24.address"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ZIP"
          [(ngModel)]="item.b24.zipCode"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="CITY"
          [(ngModel)]="item.b24.city"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="COUNTRY_CODE"
          [(ngModel)]="item.b24.countryCode"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="EMAIL"
          [(ngModel)]="item.b24.emailAddress"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="PHONE"
          [(ngModel)]="item.b24.telephoneNumber"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="VAT"
          [(ngModel)]="item.b24.vatNumber"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
      <div class="col-12 col-md-4">
        <lib-gung-text-input
          renderStatic="true"
          gungTranslate="ORG_NR"
          [(ngModel)]="item.b24.orgNo"
          class="label-bold"
        ></lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
