<div *ngIf="horizontal" class="form-group row">
  <label *ngIf="!suppressLabel" [for]="id" class="col-form-label" [ngClass]="labelClass || 'col-12 col-sm-4'">
    {{ label | translate }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col-12 col-sm-8">
    <lib-gung-input-multi-select-horizontal
      [optionsList]="metadataOptions"
      (selectedValues)="setSelectedValues($event)"
      [previewField]="previewField"
    >
    </lib-gung-input-multi-select-horizontal>
  </div>
</div>
<div *ngIf="!horizontal">
  <lib-gung-input-multi-select
  label="{{ label | translate }}"
  [optionsList]="metadataOptions"
  (selectedValues)="setSelectedValues($event)"
  [showId]="showId"
  [previewField]="previewField"
></lib-gung-input-multi-select>

</div>