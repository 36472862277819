import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first } from 'rxjs';
import { GungModalService } from '../../gung-modal/gung-modal.service';
import { NewsService } from '../news.service';

@Injectable({
  providedIn: 'root'
})
export class NewsActionConfigService {
  constructor(protected gungModalService: GungModalService, protected newsService: NewsService) {}

  getIsEnabledShowUserNewsInModal(): Observable<boolean> {
    return of(true);
  }

  getIsEnabledShowUserNewsInPage(): Observable<boolean> {
    return of(true);
  }

  openUserNewsInModal(): void {
    this.getIsEnabledShowUserNewsInModal()
      .pipe(first())
      .subscribe(isEnabled => {
        if (isEnabled) {
          this.newsService
            .getShowInModalUserNews()
            .pipe(first())
            .subscribe(news => {
              // only open the news modal
              // in case there is any news to show
              if (news && news.length > 0 && !this.hasModalOpened()) {
                this.gungModalService.openShowNewsModal(news);
                sessionStorage.setItem('isNewsModalOpened', 'y');
              }
            });
        }
      });
  }

  hasModalOpened(): boolean {
    const sessionItem = sessionStorage.getItem('isNewsModalOpened');
    return !!sessionItem && sessionItem === 'y';
  }
}
