import { Injectable } from '@angular/core';
import { OrderAnalyticsConfigService } from './order-analytics-config/order-analytics-config.service';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { OrderAnalyticsProductTableComponent } from '../components/order-analytics/order-analytics-product-table/order-analytics-product-table.component';
import { Observable } from 'rxjs';
import { StandardStatisticsData } from '../models/statistics';

@Injectable({
  providedIn: 'root'
})
export class OrderAnalyticsProductListConfigService extends OrderAnalyticsConfigService {
  getLayouts(route?: ActivatedRouteSnapshot): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OrderAnalyticsProductTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Order analytics charts'
      }
    ];
  }

  getItems(route?: ActivatedRouteSnapshot): Observable<StandardStatisticsData[]> {
    return super.getItems(route);
  }
}
