import { AuthService } from './../auth/auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchRequest, SearchResult } from 'gung-list/lib/types';
import { Observable, of, pipe, forkJoin } from 'rxjs';
import { map, switchMap, first, mergeMap, tap, filter } from 'rxjs';
import { Product, ProductMultiDimension } from '../../models/product';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { GungStringConverterService } from '../gung-string-converter.service';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  public cachedProductsList: { [productId: string]: Product } = {};
  public cachedProductsFlowFilterList: { [productId: string]: Product } = {};

  constructor(
    protected http: HttpClient,
    protected gungFlowService: GungFlowService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService
  ) {
    gungFlowService.getSelectedFlow().subscribe(() => (this.cachedProductsFlowFilterList = {}));
  }

  public getProductsByAssortment(assortmentId: string): Observable<Product[]> {
    return this.gungFlowService.getSelectedFlow().pipe(
      filter(selectedFlow => !!selectedFlow),
      switchMap(selectedFlow => {
        let url = `json/products-list-assortment-with-flow-filter/${assortmentId}?flow=${selectedFlow.id}`;
        let headers = new HttpHeaders();
        headers = headers.set('maxAge', '1800');
        if (selectedFlow.useCustomerIdForAvailabilities) {
          return forkJoin([of(selectedFlow), this.selectedCustomerService.getSelectedCustomer().pipe(first())]).pipe(
            first(),
            switchMap(([flow, selectedCustomer]) => {
              url = `${url}&customerId=${selectedCustomer.id}`;
              return this.http
                .get<Product[]>(url, { headers })
                .pipe(map(products => products.map(product => this.productMap(product))));
            })
          );
        }
        return this.http
          .get<Product[]>(url, { headers })
          .pipe(map(products => products.map(product => this.productMap(product))));
      })
    );
  }

  public getProductsByAssortmentAndFlow(assortmentId: string, flow: string): Observable<Product[]> {
    const url = `json/products-list-assortment-with-flow-filter/${assortmentId}?flow=${flow}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '1800');
    return this.http
      .get<Product[]>(url, { headers })
      .pipe(map(products => products.map(product => this.productMap(product))));
  }

  public getProductsByAssortmentExpanded(assortmentId: string): Observable<Product[]> {
    return this.gungFlowService.getSelectedFlow().pipe(
      filter(flow => !!flow),
      switchMap(selectedFlow => {
        let headers = new HttpHeaders();
        headers = headers.set('maxAge', '1800');
        if (selectedFlow.useCustomerIdForAvailabilities) {
          return forkJoin([of(selectedFlow), this.selectedCustomerService.getSelectedCustomer().pipe(first())]).pipe(
            first(),
            switchMap(([flow, selectedCustomer]) => {
              const url = `json/products-list-assortment-expanded-with-flow-filter/${assortmentId}?flow=${flow.id}&customerId=${selectedCustomer.id}`;
              return this.http
                .get<Product[]>(url, { headers })
                .pipe(map(products => products.map(product => this.productMap(product))));
            }),
            map(products => {
              return products;
            })
          );
        } else {
          const url = `json/products-list-assortment-expanded-with-flow-filter/${assortmentId}?flow=${selectedFlow.id}`;

          return this.http
            .get<Product[]>(url, { headers })
            .pipe(map(products => products.map(product => this.productMap(product))));
        }
      })
    );
  }

  public getProductsByAssortmentExpandedAndFlow(assortmentId: string, flowId: string): Observable<Product[]> {
    const url = `json/products-list-assortment-expanded-with-flow-filter/${assortmentId}?flow=${flowId}`;
    let headers = new HttpHeaders();
    headers = headers.set('maxAge', '1800');
    return this.http
      .get<Product[]>(url, { headers })
      .pipe(map(products => products.map(product => this.productMap(product))));
  }

  public getProductsByAssortmentUnfiltered(assortmentId: string): Observable<Product[]> {
    const url = `json/products-list-assortment/${assortmentId}`;
    const headers = new HttpHeaders();
    // headers = headers.set('maxAge', '-1');

    return this.http
      .get<Product[]>(url, { headers })
      .pipe(map(products => products.map(product => this.productMap(product))));
  }

  protected productMap(product: Product): Product {
    if (product.productType && product.productType === 'multiDimension') {
      (product as ProductMultiDimension).primaryDimension = (product as ProductMultiDimension).primaryDimension.map(
        pd => {
          return {
            ...pd,
            id: GungStringConverterService.fromGungString(pd.id)
          };
        }
      );
      (product as ProductMultiDimension).secondaryDimension = (product as ProductMultiDimension).secondaryDimension.map(
        sd => {
          return {
            ...sd,
            id: GungStringConverterService.fromGungString(sd.id)
          };
        }
      );
    }
    return {
      ...product,
      id: GungStringConverterService.fromGungString(product.id)
    };
  }

  public getProduct(productId: string, noCache?: boolean): Observable<Product> {
    const formattedString = GungStringConverterService.toGungString(productId);
    let url = `json/products/${formattedString}`;
    let headers = new HttpHeaders();
    if (noCache) {
      url = `json/products-nocache/${formattedString}`;
      headers = headers.set('maxAge', '-1');
    }

    return this.http.get<Product>(url, { headers }).pipe(
      tap(p => (p ? (p.id = productId) : p)),
      map(product => (product ? this.productMap(product) : product))
    );
  }

  public getProductsByIds(productIds: string[]): Observable<Product[]> {
    return this.gungFlowService.getSelectedFlow().pipe(
      filter(flow => !!flow),
      first(),
      switchMap(selectedFlow => {
        if (!productIds || productIds.length === 0) {
          return of([]);
        }

        const newListProductsIds = productIds.filter(productId => !this.cachedProductsFlowFilterList[productId]);
        const convertedIds = newListProductsIds.map(id => GungStringConverterService.toGungString(id));
        const flowId = selectedFlow.id;
        if (selectedFlow.useCustomerIdForAvailabilities) {
          return forkJoin([of(selectedFlow), this.selectedCustomerService.getSelectedCustomer().pipe(filter(customer => !!customer), first())]).pipe(
            first(),
            switchMap(([flow, selectedCustomer]) => {
              const url = `json/products-list-with-flow-filter?flow=${flow.id}&customerId=${selectedCustomer.id}`;
              return this.postRequestProductList(url, productIds, convertedIds);
            }),
            map(products => {
              return products;
            })
          );
        } else {
          const url = `json/products-list-with-flow-filter?flow=${flowId}`;
          return this.postRequestProductList(url, productIds, convertedIds);
        }
      })
    );
  }

  public getProductsByIdsUnfiltered(productIds: string[], excludeBlockedProducts: boolean = true): Observable<Product[]> {
    const convertedIds = productIds.map(id => GungStringConverterService.toGungString(id));
    if (convertedIds.length <= 0) {
      return of([]);
    }

    const url = `json/products-list?excludeBlockedProducts=${excludeBlockedProducts}`;
    return this.http.post<Product[]>(url, convertedIds);
  }

  postRequestProductList(url, productIds, convertedIds): Observable<Product[]> {
    return (convertedIds.length > 0 ? this.http.post<Product[]>(url, convertedIds) : of([])).pipe(
      map((newProducts: Product[]) => {
        // add to cache the requested products
        if (newProducts.length > 0) {
          newProducts.forEach(newProduct => {
            const product = this.productMap(newProduct);
            this.cachedProductsFlowFilterList[product.id] = product;
          });
        }

        const toReturn: Product[] = [];
        for (const productId of productIds) {
          // this verification is necessary because some products
          // may not exist in the cache as they may not belong to the flow (this service filters them by flow)
          // note: every time the flow is changed this cache is cleaned (it only contains the products for the current flow)
          if (!!this.cachedProductsFlowFilterList[productId]) {
            toReturn.push(this.cachedProductsFlowFilterList[productId]);
          }
        }

        return toReturn;
      })
    );
  }

  public getFullProductsByIds(productIds: string[], excludeBlockedProducts: boolean = true): Observable<Product[]> {
    if (productIds.length === 0) {
      return of([]);
    }

    const newListProductsIds = productIds.filter(productId => !this.cachedProductsList[productId]);
    const convertedIds = newListProductsIds.map(id => GungStringConverterService.toGungString(id));
    const url = `json/products-list?excludeBlockedProducts=${excludeBlockedProducts}`;

    return (convertedIds.length > 0 ? this.http.post<Product[]>(url, convertedIds) : of([])).pipe(
      map((newProducts: Product[]) => {
        if (newProducts.length > 0) {
          newProducts.forEach(newProduct => {
            const product = this.productMap(newProduct);
            this.cachedProductsList[product.id] = product;
          });
        }

        const toReturn: Product[] = [];
        for (const productId of productIds) {
          if (!!this.cachedProductsList[productId]) {
            toReturn.push(this.cachedProductsList[productId]);
          }
        }

        return toReturn.map(product => this.productMap(product));
      })
    );
  }

  public getProductsByPriceList(priceList: string): Observable<Product[]> {
    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow => {
        const flowId = selectedFlow.id;
        const url = `json/products-list-assortment-with-flow-filter/${priceList}?flow=${flowId}`;
        const headers = new HttpHeaders();
        // headers = headers.set('maxAge', '-1');
        return this.http
          .get<Product[]>(url, { headers })
          .pipe(map(products => products.map(product => this.productMap(product))));
      })
    );
  }

  public getPagedProducts(searchRequest: SearchRequest): Observable<SearchResult<Product>> {
    return forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first())
    ]).pipe(
      switchMap(([currentUser, currentFlow, currentCustomer]) => {
        const url = `json/products-search-global-with-flow-filter`;
        searchRequest.multiStocks = currentUser.managedMultistockIds;
        searchRequest.flowId = currentFlow?.id || '';
        searchRequest.customerId = currentCustomer?.id || '';
        if (searchRequest.requireAvailability === undefined) {
          searchRequest.requireAvailability = currentFlow.requireAvailability;
        }
        return this.http.post<SearchResult<Product>>(url, searchRequest).pipe(
          map(res => {
            return {
              ...res,
              items: res.items.map(product => this.productMap(product))
            };
          })
        );
      })
    );
  }

  public getBlockedProducts(productIds: string[]): Observable<{ [productId: string]: boolean }> {
    return this.gungFlowService.getSelectedFlow().pipe(
      switchMap(selectedFlow => {
        const url = `json/products-blocked-flow-multi?flowId=${selectedFlow.id}`;
        const convertedIds = productIds.map(id => GungStringConverterService.toGungString(id));
        return this.http.post<{ [productId: string]: boolean }>(url, convertedIds);
      }),
      map(blockedProducts => {
        const convertedBlockedProducts: { [productId: string]: boolean } = {};
        Object.keys(blockedProducts).forEach(key => {
          convertedBlockedProducts[GungStringConverterService.fromGungString(key)] = blockedProducts[key];
        });
        return convertedBlockedProducts;
      })
    );
  }

  public getBlockedProduct(productId: string, flowId?: string): Observable<{ [productId: string]: boolean }> {
    const convertedId = GungStringConverterService.toGungString(productId);

    let url = '';
    if (!!flowId) {
      url = `json/products-blocked-flow-single?flowId=${flowId}&productId=${convertedId}`;
    } else {
      url = `json/products-blocked-single?productId=${convertedId}`;
    }

    return this.http.get<{ [productId: string]: boolean }>(url);
  }

  getProductsForDigitalAssets(assortment, expanded = false) {
    return forkJoin([
      this.gungFlowService.getGungFlows().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first())
    ]).pipe(
      switchMap(([flows, selectedFlow]) => {
        let flowId = selectedFlow.id;
        if (!selectedFlow.extra.digitalAssets) {
          const found = flows.find(flow => flow.extra.digitalAssets === true);
          if (found) {
            this.gungFlowService.selectFlow(found.id);
            flowId = found.id;
          } else {
            return of([]);
          }
        }
        if (expanded) {
          return this.getProductsByAssortmentExpandedAndFlow(assortment, flowId);
        } else {
          return this.getProductsByAssortmentAndFlow(assortment, flowId);
        }
      })
    );
  }

  getListProducts(): Observable<Product[]> {
    return this.authService.getCurrentUser().pipe(
      first(),
      switchMap(user => {
        return this.getProductsByAssortmentExpanded(user.assortment);
      })
    );
  }

  getModelId(productId: string): Observable<string> {
    return this.getProduct(productId).pipe(
      map(product => {
        if (product.productType === 'multiDimension') {
          return (product as ProductMultiDimension).modelId;
        }
        return productId;
      })
    );
  }

  /**
   * @returns variantId as long as the productId is not a model
   */
  getVariantProductId(productId: string): Observable<string> {
    return this.getProduct(productId).pipe(
      map(product => {
        if (product.productType === 'multiDimension') {
          const variantId =
            (product as ProductMultiDimension).modelId + (product as ProductMultiDimension).primaryDimension[0].id;
          return variantId.length >= productId.length ? variantId : productId;
        }
        return productId;
      })
    );
  }
}
