import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DynamicPdfConfigService {
  includesDigitalAssets = true;

  constructor() {}
}
