import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from './../../../state/auth/types';
import { UsersService } from './../../../services/users/users.service';
import { first } from 'rxjs';

@Component({
  selector: 'lib-user-details',
  templateUrl: './user-details.component.html',
  styleUrls: ['./user-details.component.css']
})
export class UserDetailsComponent implements OnInit {
  currentUser: User;
  showError = false;
  errorMessage = '';
  validPasswordFromChild: boolean = true;
  constructor(protected route: ActivatedRoute, protected router: Router, protected usersService: UsersService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.usersService
        .getAllUsers()
        .pipe(first())
        .subscribe(users => {
          this.currentUser = users.find(x => x.username === params.get('username'));
        });
    });
  }

  cancelChanges(): void {
    this.router.navigate(['../../manage-users'], { queryParamsHandling: 'preserve', relativeTo: this.route });
  }

  onValidPasswordChanged(valid: boolean) {
    this.validPasswordFromChild = valid;
  }

  saveCurrentUser(): void {
    if(!this.validPasswordFromChild) {
      return;
    }
    this.usersService
      .updateUser(this.currentUser)
      .pipe(first())
      .subscribe(
        user => {
          this.router.navigate(['../../manage-users'], { queryParamsHandling: 'preserve', relativeTo: this.route });
        },
        error => {
          this.showError = true;
          this.errorMessage = error.error.message;
        }
      );
  }
}
