<ngb-carousel *ngIf="sliderContent?.length > 0" class="h-100">
  <ng-template ngbSlide *ngFor="let slide of sliderContent">
    <div class="slide-wrapper" [class]="slide.customClass" (click)="navigateTo(slide)">
      <div *ngIf="slide.background" class="img-wrapper">
        <img [src]="slide.background" class="absolute" />
      </div>
      <div class="carousel-caption text-left">
        <h3 *ngIf="slide.title" class="header-text" translate>{{ slide.title }}</h3>
        <p *ngIf="slide.description" class="sub-headline-text mt-4" translate>{{ slide.description }}</p>
        <div *ngIf="slide.innerHTML" class="mt-4" [innerHTML]="slide.innerHTML | translate | safeHtml"></div>
        <ng-container *ngIf="!slide.customClass?.includes('external-link'); else external">
          <a *ngIf="slide.link" [routerLink]="slide.link">
            <button type="button" class="btn btn-outline-light mt-5 p-3 px-4" translate>{{ slide.button }}</button>
          </a>
        </ng-container>
        <ng-template #external>
          <a *ngIf="slide.link" (click)="navigateTo(slide)">
            <button type="button" class="btn btn-outline-light mt-5 p-3 px-4" translate>{{ slide.button }}</button>
          </a>
        </ng-template>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
