import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Template } from '../../../models/document-template/document-template';
import { first } from 'rxjs';
import { DocumentTemplatesService } from '../../../services/document-templates/document-templates.service';
import { OptionsListMulti } from '../../gung-input-multi-select/gung-input-multi-select.component';

@Component({
  selector: 'lib-document-template-create-modal',
  templateUrl: './document-template-create-modal.component.html',
  styleUrls: ['./document-template-create-modal.component.css']
})
export class DocumentTemplateCreateModalComponent implements OnInit {
  templateId: string;
  templateName: string;
  error: any;
  @Input()
  templatesList: Template[];
  rolesList: OptionsListMulti[];
  availableToRoles: string[] = [];
  selectedTemplate: Template;
  showXlsxAutomatically: boolean;

  constructor(
    protected translateService: TranslateService,
    protected documentTemplatesService: DocumentTemplatesService,
    public activeModal: NgbActiveModal,
    protected router: Router
  ) {}

  ngOnInit(): void {
    this.rolesList = [];
    this.rolesList.push({ id: 'USER', name: 'USER', selected: false });
    this.rolesList.push({ id: 'SALES', name: 'SALES', selected: false });
    this.rolesList.push({ id: 'ADMIN', name: 'ADMIN', selected: false });
  }

  public setSelectedValues(roles): void {
    this.availableToRoles = roles;
  }

  create(): void {
    this.error = null;

    if (!this.templateId) {
      this.error = { message: this.translateService.instant('TEMPLATE_ID_UNDEFINED') };
      return;
    }
    if (!this.templateName) {
      this.error = { message: this.translateService.instant('TEMPLATE_NAME_UNDEFINED') };
      return;
    }
    if (!this.selectedTemplate) {
      this.error = { message: this.translateService.instant('COPY_TEMPLATE_REQUIRED') };
      return;
    }
    this.templateId = this.templateId.toUpperCase();
    this.templateId = this.templateId.replace(' ', '_'); // ID is used for translation tag.
    const documentTemplate: Template = {
      ...this.selectedTemplate,
      id: this.templateId,
      name: this.templateName,
      showXlsxAutomatically: this.showXlsxAutomatically,
      availableToRoles: this.availableToRoles
    };
    this.documentTemplatesService
      .createNewTemplate(documentTemplate)
      .pipe(first())
      .subscribe(
        newTemplate => {
          this.activeModal.close();
          window.location.reload();
        },
        httpErrorResponse => {
          this.error = httpErrorResponse.error;
        }
      );
  }
}
