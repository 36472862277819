<div class="mount-document">
  <div class="document-options ml-auto">
    <div class="zoom-btns mr-2">
      <button
        [disabled]="zoom < 25"
        type="button"
        mat-button
        class="btn btn-sm btn-outline-primary px-4"
        (click)="zoomOut()"
        translate
      >
        -
      </button>
      {{ zoom }} %
      <button
        [disabled]="zoom > 190"
        type="button"
        mat-button
        class="btn btn-sm btn-outline-primary px-4"
        (click)="zoomIn()"
        translate
      >
        +
      </button>
    </div>
    <div class="btn-group mr-2">
      <button
        type="button"
        mat-button
        class="btn btn-sm btn-outline-primary px-4"
        [ngClass]="{ 'button-active': pageOrientation === 'PORTRAIT' }"
        (click)="changeOrientation('PORTRAIT')"
        translate
      >
        PORTRAIT
      </button>
      <button
        type="button"
        mat-button
        class="btn btn-sm btn-outline-primary px-4"
        [ngClass]="{ 'button-active': pageOrientation === 'LANDSCAPE' }"
        (click)="changeOrientation('LANDSCAPE')"
        translate
      >
        LANDSCAPE
      </button>
    </div>
    <div>
      <div ngbDropdown placement="top-right" class="d-inline-block dropup">
        <div>
          <label for="templates"><small translate>TEMPLATE</small></label>
          <button
            class="btn ml-2 mr-3 btn-sm btn-outline-primary export-area-buttons"
            id="templates"
            ngbDropdownToggle
          >
            {{ exportDragProductService?.selectedTemplate?.id }}
          </button>
        </div>
        
        <div ngbDropdownMenu aria-labelledby="templates" class="dropdown-container">
          <button
            class="export-area-buttons"
            ngbDropdownItem
            *ngFor="let template of templates"
            (click)="setDefaultTemplate(template)"
            translate
          >
            {{ template.id }}
          </button>
        </div>
      </div>
      <button type="button" mat-button class="btn btn-sm btn-outline-primary px-4 mr-2" (click)="save()" translate>
        SAVE
      </button>
      <button type="button" mat-button class="btn btn-sm btn-outline-primary px-4" (click)="export()">Export</button>
    </div>
  </div>
  <div class="document-pages d-flex">
    <div class="loading" *ngIf="loadGrid">
      <div class="container">
        <div class="box1"></div>
        <div class="box2"></div>
        <div class="box3"></div>
      </div>
    </div>
    <div class="page-work-space" (scroll)="onScroll($event.target)">
      <h2 class="text-center mt-2" *ngIf="pdfDocument">
        {{ name }} <span class="cursor-pointer" (click)="editName()"><i class="fas fa-pencil"></i></span>
      </h2>
      <div>
        <div class="edit-pdf mx-4 mb-5">
          <div class="row">
            <div class="col"></div>
            <div class="col-auto"></div>
          </div>
          <div class="pages" *ngIf="!loadGrid" [ngStyle]="{ transform: 'scale(' + zoom / 100 + ')' }">
            <div
              class="page"
              id="page-{{ i }}"
              *ngFor="let page of productDragExport.pages; let i = index"
              [ngStyle]="{ 'width.cm': this.pageOrientation === 'PORTRAIT' ? 21 : 29.7 }"
            >
              <div class="page-head" [ngStyle]="{ 'width.cm': this.pageOrientation === 'PORTRAIT' ? 21 : 29.7 }">
                <div class="page-number">
                  {{ 'Page' | translate }} {{ i + 1 }} / {{ productDragExport.pages.length }}
                </div>
                <div class="page-menu">
                  <a class="mr-2" (click)="changePagePosition(i, i + 1)" *ngIf="i + 1 < productDragExport.pages.length"
                    ><i class="fas fa-chevron-down"></i
                  ></a>
                  <a class="mr-2" (click)="changePagePosition(i, i - 1)" *ngIf="i > 0"
                    ><i class="fas fa-chevron-up"></i
                  ></a>
                  <a class="mr-2" (click)="addNewPage(i + 1)"><i class="fas fa-plus"></i></a>
                  <a class="mr-2" (click)="duplicatePage(i)"><i class="fas fa-copy"></i></a>
                  <a class="mr-2" (click)="removePage(i)" *ngIf="productDragExport.pages.length > 1"
                    ><i class="fas fa-trash"></i
                  ></a>
                </div>
              </div>
              <div
                class="page-body"
                [ngStyle]="{
                  'width.cm': this.pageOrientation === 'PORTRAIT' ? 21 : 29.7,
                  'height.cm': this.pageOrientation === 'PORTRAIT' ? 29.7 : 21
                }"
              >
                <gridster
                  [options]="options[i]"
                  #gridster
                  class="gridster w-100 h-100"
                  (mouseup)="getGridsterPos($event)"
                  [class.active-page]="exportDragProductService.selectedPage === i"
                >
                  <gridster-item [item]="item" *ngFor="let item of page.items">
                    <div class="options-buttons">
                      <i
                        class="fas fa-video mr-1"
                        *ngIf="
                          !item.data?.url &&
                          productList &&
                          productList[item.data?.productId] &&
                          productList[item.data?.productId].extra.pim &&
                          productList[item.data?.productId].extra.pim['video-url']
                        "
                        (mousedown)="$event.preventDefault(); $event.stopPropagation()"
                        (click)="openVideoModal(item, productList[item.data?.productId].extra.pim['video-url'])"
                      ></i>
                      <i
                        class="fas fa-edit mr-1"
                        *ngIf="!item.data?.url"
                        (mousedown)="$event.preventDefault(); $event.stopPropagation()"
                        (click)="editItem(item, i)"
                      ></i>
                      <i
                        class="fas fa-trash"
                        (mousedown)="$event.preventDefault(); $event.stopPropagation()"
                        (click)="removeItem(item, i)"
                      ></i>
                    </div>

                    <!--  TODO
                    Instead of having one of each here with a div we should start to use structured data for each
                    different type where we have a service that instantiates them correctly
                    -->
                    <div class="h-100" *ngIf="item.data?.modelId + item.data?.productId as id">
                      <div
                        class="h-100"
                        *ngIf="
                          !item.data?.url &&
                          item.fragment !== 'text-display' &&
                          exportDragProductService.renderedItem &&
                          exportDragProductService.renderedItem[id]
                        "
                        [innerHTML]="exportDragProductService.renderedItem[id]"
                      ></div>
                    </div>
                    <div
                      class="h-100"
                      *ngIf="
                        item.data?.url &&
                        exportDragProductService.renderedItem &&
                        exportDragProductService.renderedItem[item.data?.url]
                      "
                      [innerHTML]="exportDragProductService.renderedItem[item.data?.url]"
                    ></div>
                    <div
                      class="h-100"
                      *ngIf="
                        item.fragment === 'text-display' &&
                        exportDragProductService.renderedItem &&
                        exportDragProductService.renderedItem[item.data?.id]
                      "
                      [innerHTML]="exportDragProductService.renderedItem[item.data?.id]"
                    ></div>
                  </gridster-item>
                </gridster>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="right-options">
      <div (click)="showPagesLeft()" class="menu-r-export">
        <div class="open-icons">
          <span *ngIf="showPages"><i class="fas fa-chevron-right"></i></span>
          <span *ngIf="!showPages"><i class="fas fa-chevron-left"></i></span>
        </div>
        <div class="menu-icon"><i class="far fa-book-open"></i></div>
        <div translate>PAGES</div>
      </div>
      <div class="hidden-pages-menu" *ngIf="showPages">
        <div
          class="small-page"
          [class.portrait]="pageOrientation === 'PORTRAIT'"
          [class.active-page]="exportDragProductService.selectedPage === i"
          (click)="goToPage(i)"
          *ngFor="let page of productDragExport.pages; let i = index"
        >
          <div>{{ i + 1 }}</div>
          <div class="page-w-content" *ngIf="page.items.length > 0">
            <i class="far fa-game-board-alt"></i>
          </div>
        </div>
        <button type="button" (click)="addNewPage()" mat-button class="btn btn-sm btn-outline-primary px-4" translate>
          ADD
        </button>
      </div>
    </div>
  </div>
</div>
