import { Component, OnInit } from '@angular/core';
import { SaveCartModalConfigService } from '../../services/save-cart-modal-config/save-cart-modal-config.service';

@Component({
  selector: 'lib-create-quotation-modal',
  templateUrl: './create-quotation-modal.component.html',
  styleUrls: ['./create-quotation-modal.component.css']
})
export class CreateQuotationModalComponent implements OnInit {
  name = '';
  description = '';
  delegate: SaveCartModalConfigService;

  constructor() {}

  ngOnInit() {}

  close() {
    this.delegate.modalRef.get(CreateQuotationModalComponent.name).close('Closed');
  }

  saveCart() {
    this.delegate.modalRef
      .get(CreateQuotationModalComponent.name)
      .close({ name: this.name, description: this.description });
  }
}
