<div class="row pt-2 form-group">
  <div class="col-12">
    <lib-gung-text-input
      gungTranslate="GUNGFLOW_NAME"
      [(ngModel)]="curentGungFlow.name"
      [placeholder]="'GUNGFLOW_NAME' | translate"
    >
    </lib-gung-text-input>
  </div>
</div>

<div class="row form-group">
  <div class="col-md-6">
    <label for="deliveryStartDate" class="control-label" translate>GUNGFLOW_DELIVERYSTARTDATE</label>
    <div class="input-group">
      <input
        class="form-control input-date"
        placeholder="yyyy-mm-dd"
        name="deliveryStartDate"
        id="deliveryStartDate"
        ngbDatepicker
        #dpDeliveryStartDate
        #deliveryStartDatePicker="ngbDatepicker"
        container="body"
        [value]="parseDate(curentGungFlow.deliveryStartDate) | date: 'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'deliveryStartDate')"
        (change)="updateDataValue($event, 'deliveryStartDate')"
        (input)="deliveryStartDate = validateInput(deliveryStartDate, dpDeliveryStartDate.value, 'deliveryStartDate')"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="deliveryStartDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <label for="deliveryEndDate" class="control-label" translate>GUNGFLOW_DELIVERYENDDATE</label>
    <div class="input-group">
      <input
        class="form-control input-date"
        placeholder="yyyy-mm-dd"
        name="deliveryEndDate"
        id="deliveryEndDate"
        ngbDatepicker
        #deliveryEndDatePicker="ngbDatepicker"
        container="body"
        #dpDeliveryEndDate
        [value]="parseDate(curentGungFlow.deliveryEndDate) | date: 'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'deliveryEndDate')"
        (change)="updateDataValue($event, 'deliveryEndDate')"
        (input)="deliveryEndDate = validateInput(deliveryEndDate, dpDeliveryEndDate.value, 'deliveryEndDate')"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="deliveryEndDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="row form-group">
  <div class="col-md-6">
    <label for="orderStartDate input-date" class="control-label" translate>GUNGFLOW_ORDERSTARTDATE</label>
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="orderStartDate"
        id="orderStartDate"
        ngbDatepicker
        #orderStartDatePicker="ngbDatepicker"
        container="body"
        #dpOrderStartDate
        [value]="parseDate(curentGungFlow.orderStartDate) | date: 'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'orderStartDate')"
        (change)="updateDataValue($event, 'orderStartDate')"
        (input)="orderStartDate = validateInput(orderStartDate, dpOrderStartDate.value, 'orderStartDate')"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="orderStartDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <label for="orderEndDate input-date" class="control-label" translate>GUNGFLOW_ORDERENDDATE</label>
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="orderEndDate"
        id="orderEndDate"
        ngbDatepicker
        #orderEndDatePicker="ngbDatepicker"
        container="body"
        #dpOrderEndDate
        [value]="parseDate(curentGungFlow.orderEndDate) | date: 'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'orderEndDate')"
        (change)="updateDataValue($event, 'orderEndDate')"
        (input)="orderEndDate = validateInput(orderEndDate, dpOrderEndDate.value, 'orderEndDate')"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="orderEndDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>

  <div class="col-md-6">
    <label for="priceDate input-date" class="control-label" translate>GUNGFLOW_PRICEDATE</label>
    <div class="input-group">
      <input
        class="form-control"
        placeholder="yyyy-mm-dd"
        name="priceDate"
        id="priceDate"
        ngbDatepicker
        #priceDatePicker="ngbDatepicker"
        container="body"
        #dpPriceDate
        [value]="parseDate(curentGungFlow.priceDate) | date: 'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'priceDate')"
        (blur)="clearDateIfEmpty($event, 'priceDate')"
        (change)="updateDataValue($event, 'priceDate')"
        (input)="priceDate = validateInput(priceDate, dpPriceDate.value, 'priceDate')"
      />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="priceDatePicker.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <lib-gung-text-input
      gungTranslate="SORTING_PRIORITY"
      [(ngModel)]="curentGungFlow.extra.sortingPriority"
      [placeholder]="'SORTING_PRIORITY' | translate"
    >
    </lib-gung-text-input>
  </div>

  <div class="col-md-12">
    <!--break line-->
  </div>

  <!--Drop
  <div class="col-md-6"
    *ngIf="isMultidropEnabled && curentGungFlow.multidrop && !!curentGungFlow.drops && !!curentGungFlow.drops[0]">
    <lib-gung-text-input gungTranslate="DROP_NAME_1" [(ngModel)]="curentGungFlow.drops[0].name"
      [placeholder]="'DROP_NAME_1' | translate">
    </lib-gung-text-input>
  </div>
  <div class="col-md-6"
    *ngIf="isMultidropEnabled && curentGungFlow.multidrop && !!curentGungFlow.drops && !!curentGungFlow.drops[0]">
    <label for="multiDropDate1 input-date" class="control-label" translate>DROP_DATE_1</label>
    <div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd" name="multiDropDate1" id="multiDropDate1" ngbDatepicker
        #multiDropDatePicker1="ngbDatepicker" container="body" #dpMultiDrop1
        [value]="parseDate(curentGungFlow.drops[0].firstDeliveryDate) | date:'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'multiDropDate1')" (blur)="clearDateIfEmpty($event, 'multiDropDate1')"
        (input)="multiDropDate1 = validateInput(multiDropDate1, dpMultiDrop1.value, 'multiDropDate1')" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="multiDropDatePicker1.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>
  </div>


  <div class="col-md-6"
    *ngIf="isMultidropEnabled && curentGungFlow.multidrop && !!curentGungFlow.drops && !!curentGungFlow.drops[1]">
    <lib-gung-text-input gungTranslate="DROP_NAME_2" [(ngModel)]="curentGungFlow.drops[1].name"
      [placeholder]="'DROP_NAME_2' | translate">
    </lib-gung-text-input>
  </div>
  <div class="col-md-6"
    *ngIf="isMultidropEnabled && curentGungFlow.multidrop && !!curentGungFlow.drops && !!curentGungFlow.drops[1]">
    <label for="multiDropDate2 input-date" class="control-label" translate>DROP_DATE_2</label>
    <div class="input-group">
      <input class="form-control" placeholder="yyyy-mm-dd" name="multiDropDate2" id="multiDropDate2" ngbDatepicker
        #multiDropDatePicker2="ngbDatepicker" container="body" #dpMutliDrop2
        [value]="parseDate(curentGungFlow.drops[1].firstDeliveryDate) | date:'yyyy-MM-dd'"
        (dateSelect)="updateDate($event, 'multiDropDate2')" (blur)="clearDateIfEmpty($event, 'multiDropDate2')"
        (input)="multiDropDate2 = validateInput(multiDropDate2, dpMutliDrop2.value, 'multiDropDate2')" />
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="multiDropDatePicker2.toggle()" type="button">
          <i class="fal fa-calendar-alt"></i>
        </button>
      </div>
    </div>

  </div>
  -->
</div>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="useAvailabilities"
      name="useAvailabilities"
      [(ngModel)]="curentGungFlow.useAvailabilities"
    />
    <label class="form-check-label" for="useAvailabilities" translate>GUNGFLOW_USE_AVAILABILITIES</label>
  </div>
</div>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireAvailability"
      name="requireAvailability"
      [(ngModel)]="curentGungFlow.requireAvailability"
    />
    <label class="form-check-label" for="requireAvailability" translate>GUNGFLOW_REQUIRE_AVAILABILITY</label>
  </div>
</div>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="requireCurrentAvailability"
      name="requireCurrentAvailability"
      [(ngModel)]="curentGungFlow.requireCurrentAvailability"
    />
    <label class="form-check-label" for="requireCurrentAvailability" translate
      >GUNGFLOW_REQUIRE_CURRENT_AVAILABILITY</label
    >
  </div>
</div>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="isPreorder"
      name="isPreorder"
      [(ngModel)]="curentGungFlow.isPreorder"
    />
    <label class="form-check-label" for="isPreorder" translate>GUNGFLOW_IS_PREORDER</label>
  </div>
</div>

<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="disableNonAvailable"
      name="disableNonAvailable"
      [(ngModel)]="curentGungFlow.disableNonAvailable"
    />
    <label class="form-check-label" for="disableNonAvailable" translate>GUNGFLOW_DISABLE_NONAVAILABLE</label>
  </div>
</div>

<!-- <div class="form-group">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="displayOnly" name="displayOnly"
      [(ngModel)]="curentGungFlow.displayOnly">
    <label class="form-check-label" for="displayOnly" translate>GUNGFLOW_DISPLAY_ONLY</label>
  </div>
</div>

<div class="form-group">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="inventory" name="inventory"
      [(ngModel)]="curentGungFlow.inventory">
    <label class="form-check-label" for="inventory" translate>GUNGFLOW_INVENTORY</label>
  </div>
</div> -->
<div class="form-group" *ngIf="showFlowsAsDigitalAssets">
  <div class="form-check">
    <input class="form-check-input" type="checkbox" id="digitalAssets" name="digitalAssets"
      [(ngModel)]="curentGungFlow.extra.digitalAssets">
    <label class="form-check-label" for="digitalAssets" translate>DIGITAL_ASSETS</label>
  </div>
</div>
<div class="form-group">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="useCustomerIdForAvailabilities"
      name="useCustomerIdForAvailabilities"
      [(ngModel)]="curentGungFlow.useCustomerIdForAvailabilities"
    />
    <label class="form-check-label" for="useCustomerIdForAvailabilities" translate
      >USE_CUSTOMER_FOR_AVAILABILITIES</label
    >
  </div>
</div>
<div class="form-group" *ngIf="isMultidropEnabled">
  <div class="form-check">
    <input
      class="form-check-input"
      type="checkbox"
      id="multidrop"
      name="multidrop"
      [(ngModel)]="curentGungFlow.multidrop"
    />
    <label class="form-check-label" for="multidrop" translate>MULTI_DROP</label>
  </div>
</div>

<div class="form-group">
  <h6>{{ 'GUNGFLOW_ISACTIVE_GROUPS' | translate }}</h6>
  <div class="form-check pt-1" *ngFor="let group of userGroups">
    <label class="form-check-label" [for]="group.key">
      <input
        type="checkbox"
        class="form-check-input"
        (change)="toogleGroup(group.key, group.selected)"
        [id]="group.key"
        [checked]="group.selected"
      />{{ group.key | translate | titlecase }}
    </label>
  </div>
</div>
