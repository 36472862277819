import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of, pipe } from 'rxjs';
import { finalize, first, switchMap, tap } from 'rxjs';
import { EdiInvoice, EdiMessageBase, GungEdiMessageSendStatus, GungEdimessageType } from '../../models/edi';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';
import { AuthService } from '../auth/auth.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { EdiService } from '../edi/edi.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService, GungNotificationService } from 'gung-common';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export abstract class EdiMessageBaseActionConfigService implements ActionButtonConfigService<EdiMessageBase> {
  constructor(
    protected ediService: EdiService,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected backend: BackendInterceptor,
    protected router: Router,
    protected http: HttpClient,
    protected gungNotificationService: GungNotificationService
  ) {}
  getButtons(
    item: EdiMessageBase,
    eventEmitter: EventEmitter<EdiMessageBase>
  ): Observable<ActionButton<EdiMessageBase>[]> {
    return this.authService.getHasRoles('ACTUATOR').pipe(
      switchMap(isActuator => {
        const baseActions: ActionButton<EdiMessageBase>[] = [];
        if (item.gungEdiMessageSendStatus !== 'Sent') {
          baseActions.push({
            title: 'VALIDATE',
            action: (ediMessage: EdiMessageBase) => this.validateMessage(ediMessage, event, eventEmitter)
          });
        }
        if (['Sent', 'ReadyToSend', 'ErrorWhileSending'].includes(item.gungEdiMessageSendStatus)) {
          const readyToSend = item.gungEdiMessageSendStatus === 'ReadyToSend';
          baseActions.push({
            title: readyToSend ? 'SEND_MESSAGE' : 'SEND_MESSAGE_AGAIN',
            action: (ediMessage: EdiMessageBase) =>
              this.messageTransformAndSend(ediMessage, readyToSend, event, eventEmitter)
          });
        }
        baseActions.push({
          title: 'ORDER_DETAILS',
          action: (ediMessage: EdiMessageBase) =>
            this.redirectToOrderDetails(ediMessage.documentReferences.supplierOrder.id, event)
        });

        if (isActuator) {
          baseActions.push({
            title: 'DOWNLOAD_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.downloadMessage(ediMessage, event)
          });
          baseActions.push({
            title: 'ARCHIVE_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.archiveMessage(ediMessage, event, eventEmitter)
          });
          baseActions.push({
            title: 'RE_GENERATE_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.messageGenerateByGungId(ediMessage, event, eventEmitter, false)
          });
          baseActions.push({
            title: 'RE_GENERATE_MESSAGE_WITH_DECORATORS',
            action: (ediMessage: EdiMessageBase) => this.messageGenerateByGungId(ediMessage, event, eventEmitter, true)
          });
        }

        return of(baseActions);
      })
    );
  }

  downloadMessage(ediMessage: EdiMessageBase, element) {
    gungAddRemoveSpinner(element.target);
    const messageType = this.getMessageType();
    const url = `${this.backend.getBaseUrl()}json/edi/v2/actuator/download-message?id=${
      ediMessage.id
    }&messageType=${messageType}`;
    this.ediService.downloadMessage(ediMessage.id, messageType).subscribe(
      data => {
        window.open(url, '_blank');
      },
      error => {
        if (error.status === 200) {
          window.open(url, '_blank');
        } else {
          const msg = 'Sorry, this file is not available';
          this.gungModalService.openConfirmYesNoModal(undefined, msg, { size: 'sm' }, 'OK', null).then(_ => {});
        }
        gungAddRemoveSpinner(element.target);
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }

  validateMessage(message: EdiMessageBase, element, eventEmitter: EventEmitter<EdiMessageBase>) {
    var titleKey = 'RE_VALIDATE_MESSAGE';
    var messageKey = 'WANT_TO_REVALIDATE_MESSAGE';
    if (message.gungEdiMessageSendStatus === 'Sent') {
      titleKey = 'RE_VALIDATE_SENT_MESSAGE';
      messageKey = 'WANT_TO_REVALIDATE_SENT_MESSAGE';
    }
    this.gungModalService
      .openConfirmYesNoModal(titleKey, messageKey, {}, 'YES', 'NO')

      .then(answer => {
        if (answer) {
          gungAddRemoveSpinner(element.target);
          this.gungNotificationService.notify('INFO', 'VALIDATION_STARTED', 'success');

          this.ediService
            .getMessageValidate(message.id, this.getMessageType())
            .subscribe(result => {})
            .add(() => {
              this.ediService.getMessageById(message.id, this.getMessageType()).subscribe(updatedMessage => {
                if (updatedMessage.validationState === 'VALIDATED_WITH_ERRORS') {
                  this.gungNotificationService.notify('ERROR', 'ERROR_WHILE_VALIDATING', 'error');
                } else {
                  this.gungNotificationService.notify('SUCCESS', 'VALIDATION_FINISHED', 'success');
                }
                gungAddRemoveSpinner(element.target);
                eventEmitter.emit(updatedMessage);
              });
            });
        } else {
          //
        }
      });
  }

  messageTransformAndSend(
    message: EdiMessageBase,
    readyToSend: boolean,
    element,
    eventEmitter: EventEmitter<EdiMessageBase>
  ) {
    gungAddRemoveSpinner(element.target);
    this.gungModalService
      .openConfirmYesNoModal(
        readyToSend ? 'SEND_MESSAGE' : 'SEND_MESSAGE_AGAIN',
        readyToSend ? 'WANT_TO_SEND_MESSAGE' : 'WANT_TO_SEND_MESSAGE_AGAIN',
        {},
        'YES',
        'NO'
      )
      .then(answer => {
        if (answer) {
          this.ediService
            .getMessageTransformAndSend(message.id, this.getMessageType())
            .subscribe(result => {})
            .add(() => {
              this.ediService.getMessageById(message.id, this.getMessageType()).subscribe(updatedMessage => {
                gungAddRemoveSpinner(element.target);
                eventEmitter.emit(updatedMessage);
              });
            });
        } else {
          gungAddRemoveSpinner(element.target);
        }
      })
      .catch(res => gungAddRemoveSpinner(element.target));
  }

  messageGenerateByGungId(
    ediMessage: EdiMessageBase,
    element,
    eventEmitter: EventEmitter<EdiMessageBase>,
    sampleDecorators: boolean
  ) {
    gungAddRemoveSpinner(element.target);
    this.gungModalService
      .openConfirmYesNoModal('RE_GENERATE_MESSAGE', 'WANT_TO_SEND_MESSAGE_AGAIN_WILL_OVERWRITE', {}, 'YES', 'NO')
      .then(answer => {
        if (answer) {
          this.ediService
            .postGenerateEdiMessageByGungId(ediMessage.id, ediMessage, this.getMessageType(), sampleDecorators)
            .subscribe(result => {})
            .add(() => {
              this.ediService.getMessageById(ediMessage.id, this.getMessageType()).subscribe(updatedMessage => {
                gungAddRemoveSpinner(element.target);
                eventEmitter.emit(updatedMessage);
              });
            });
        } else {
          gungAddRemoveSpinner(element.target);
        }
      })
      .catch(res => gungAddRemoveSpinner(element.target));
  }

  archiveMessage(ediMessage: EdiMessageBase, element, eventEmitter: EventEmitter<EdiMessageBase>) {
    gungAddRemoveSpinner(element.target);
    this.gungModalService
      .openConfirmYesNoModal('ARCHIVE_MESSAGE', 'ARCHIVE_MESSAGE_CONFIRMATION_INFO', {}, 'YES', 'NO')
      .then(answer => {
        if (answer) {
          this.ediService
            .setMessageStatus(ediMessage.id, this.getMessageType(), GungEdiMessageSendStatus.Archived)
            .pipe(
              finalize(() => {
                this.ediService
                  .getMessageById(ediMessage.id, this.getMessageType())
                  .pipe(first())
                  .subscribe(updatedMessage => {
                    gungAddRemoveSpinner(element.target);
                    eventEmitter.emit(updatedMessage);
                  });
              })
            )
            .subscribe();
        } else {
          gungAddRemoveSpinner(element.target);
        }
      })
      .catch(res => gungAddRemoveSpinner(element.target));
  }

  redirectToOrderDetails(id: string, element) {
    gungAddRemoveSpinner(element.target);
    this.ediService.getMessageByErpOrderId(id).subscribe(
      ids => {
        if (ids?.length > 0) {
          const orderId = ids[0].id;
          this.router.navigate(['/admin/edi/orders/', orderId]);
        }
      },
      error => {
        gungAddRemoveSpinner(element.target);
      },
      () => {
        gungAddRemoveSpinner(element.target);
      }
    );
  }
  protected abstract getMessageType(): GungEdimessageType;
}
