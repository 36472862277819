import { GungBase } from './base';

export interface EdiGlnNumbers extends GungBase {
  version: any;
  id: string;
  gln: number;
  companyId: string;
  primary: boolean;
  glnBuyer: any;
  glnRecipient: any;
  b24: B24;
}

export interface EdiGungMessages extends GungBase {
  fileSizeInBytes: number;
  sentToFtp: boolean;
  allowDoubleSubmit: boolean;
  localFileName: string;
  messageType: string;
  direction: string;
  buyerOrderid: any;
  b24OrderId: any;
  status: string;
  errorStackTrace: string;
  orderIds: string[];
  orderContextId: string;
  despatchAdviceNumber: string;
  invoiceNumber: string;
  supplierOrderNumber: string;
  orderId: string;

  shortFileName: string; // Name without the path
  orderNumberModel: string;
  isOrder: boolean;
  isInvoice: boolean;
  isOrderResponse: boolean;
  isDespatchAdvice: boolean;
  deliveredOrderIds: string[];
  notDeliveredOrderIds: string[];
  selectedOrderIdsForDespatchAdvice: string[];
}

export interface B24 {
  invoices: boolean;
  inventoryReports: boolean;
  orders: boolean;
  orderResponses: boolean;
  orderResponsesSkipEmptyLines: boolean;
  pricats: boolean;
  despatchAdvices: boolean;
  address: string;
  zipCode: string;
  city: string;
  countryCode: string;
  emailAddress: string;
  telephoneNumber: string;
  vatNumber: string;
  orgNo: any;
  name: string;
  bankName: string;
  bankAccountNumber: string;
}

export interface EdiOrderItem {
  changes: {
    cancelled: boolean;
    skipValidation: boolean;
  };
  ids: {
    gungId: string;
    ean13: string;
    upca: string;
    buyerId: string;
  };
  quantities: {
    expectedQuantity: number;
  };
}

export interface EdiMessageBase extends GungBase {
  ediChannel: string;
  ediConfigId: string;
  buyerGln: number;
  buyerAddress: GungEdiAddressInfo;
  invoiceeGln: number;
  invoiceeAddress: GungEdiAddressInfo;
  consigneeGln: number;
  consigneeAddress: GungEdiAddressInfo;
  deliveryGln: number;
  deliveryAddress: GungEdiAddressInfo;
  recipientGln: number;
  recipientAddress: GungEdiAddressInfo;
  supplierGln: number;
  supplierAddress: GungEdiAddressInfo;
  senderGln: number;
  senderAddress: GungEdiAddressInfo;

  documentReferences: GungEdiDocumentReferences;
  items: GungEdiMessageItem[];
  freightItems: GungEdiMessageItem[];
  chargeItems: GungEdiMessageItem[];

  orderType: string;
  deliveryDate: Date;
  fileNameInclPath: string;
  erpIds: string[];
  deliveryTermId: string;
  paymentTermText: string;

  // Price related
  currency: string;
  headerDiscountPercentage: number;
  errors: GungEdiError[];
  validationState: string;
  gungEdiMessageSendStatus: GungEdiMessageSendStatus;
  stackTraceMap: string;
  processedValidators: string[];
  processedDecorators: { [s: string]: any };

  [s: string]: any;
}
export interface EdiOrder extends EdiMessageBase {}
export interface GungEdiMessageItem {
  changes: GungEdiItemChanges;
  dimensions: GungEdiDimensions;
  documentReferences: GungEdiDocumentReferences;
  ids: GungEdiItemIds;
  prices: GungEdiPrices;
  quantities: GungEdiItemQtys;
  name: string;
  description: string;
  lineText: string;
  deliveryDate: Date;
  unitOfMeasure: string;
  isFreightArticle: boolean;
  extra: { [s: string]: any };
  errors: GungEdiError[];
}

export interface GungEdiError {
  error: string;
  validator: string;
  type: string; // Enum
  propertyCausingError: string;
}

export interface GungEdiParcel {
  quantity: number;
  id: string;
  ginIdentifier: string;
}
export interface GungEdiItemQtys {
  expectedQuantity: number;
  actualQuantity: number;
  actualQuantityDelivered: number;
}
export interface GungEdiPrices {
  expectedUnitGrossPrice: GungEdiPrice;
  expectedUnitNetPrice: GungEdiPrice;
  expectedUnitDiscountAmount: GungEdiPrice;
  expectedTotalGrossPrice: GungEdiPrice;
  expectedTotalNetPrice: GungEdiPrice;
  expectedTotalDiscountAmount: GungEdiPrice;
  expectedDiscountPercent: number; // BD
  actualUnitGrossPrice: GungEdiPrice;
  actualUnitNetPrice: GungEdiPrice;
  actualUnitDiscountAmount: GungEdiPrice;
  actualTotalGrossPrice: GungEdiPrice;
  actualTotalNetPrice: GungEdiPrice;
  actualTotalDiscountAmoun: GungEdiPrice;
  actualDiscountPercent: number; // BD
  vatIncluded: boolean; // BD
  vatBaseAmount: GungEdiPrice;
  vatPercent: number; // BD
  vatAmount: GungEdiPrice;
  totalRoundingAmount: GungEdiPrice;
}
export interface GungEdiPrice {
  value: number; // BD
  currency: string;
}
export interface GungEdiDimensions {
  color: string;
  size: string;
}
export interface GungEdiItemChanges {
  skipValidation: boolean;
  cancelled: boolean;
  cancellationReason: string;
  ids: GungEdiItemIds;
  quantity: number;
}
export interface GungEdiItemIds {
  gungId: string;
  ean13: string;
  upca: string;
  buyerId: string;
}
export interface GungEdiDocumentReferences {
  supplierOrder: GungEdiOrderDocumentReference;
  buyerOrder: GungEdiOrderDocumentReference;
  invoice: GungEdiOrderDocumentReference;
  invoiceOcr: GungEdiOrderDocumentReference;
  despatchAdvice: GungEdiOrderDocumentReference;
  parcel: GungEdiOrderDocumentReference;
}
export interface GungEdiOrderDocumentReference {
  id: string;
  lineId: string;
  date: Date;
}
export interface GungEdiAddressInfo {
  name: string;
  address1: string;
  address2: string;
  zipCode: string;
  city: string;
  countryCode: string;
  contact: string;
  phone: string;
  email: string;
  vatNumber: string;
  orgNumber: string;
  bankName: string;
  bankAccountNumber: string;
}

export interface EdiOrderMessageRelations {
  sourceMessageId: string;
  erpInvoiceIds: string[];
  erpDesadvIds: string[];
  erpOrderIds: string[];
  erpOrdrspIds: string[];
}

export interface EdiRespondeOrder extends EdiMessageBase {
  orderMessageId: string;
  summary: any;
}

export interface EdiInvoice extends EdiMessageBase {
  orderMessageId: string;
  invoiceDueDate: Date;
  supplierVatRegNumber: string;
  supplierCompanyRegNumber: string;
  creditInvoice: boolean;
  summary: GungEdiInvoiceSummary;
}
export interface GungEdiInvoiceSummary {
  totalPayableAmount: GungEdiPrice;
  totalPayableAmountBookkeepingCurrency: GungEdiPrice;
  totalVATBaseAmount: GungEdiPrice;
  totalVATBaseAmountBookkeepingCurrency: GungEdiPrice;
  totalVATPercent: number;
  totalVATAmount: GungEdiPrice;
  totalVATAmountBookkeepingCurrency: GungEdiPrice;
  totalFreightAmount: GungEdiPrice;
  totalFreightAmountBookkeepingCurrency: GungEdiPrice;
  totalAdminFeeAmount: GungEdiPrice;
  totalAdminFeeBookkeepingCurrency: GungEdiPrice;
  totalRoundingAmount: GungEdiPrice;
  totalRoundingAmountBookkeepingCurrency: GungEdiPrice;
  lineCount: number;
  lineItemsCount: number;
  lineItemsGrossAmount: GungEdiPrice;
  lineItemsGrossTaxAmount: GungEdiPrice;
  lineItemsNetAmount: GungEdiPrice;
  lineItemsNetTaxAmount: GungEdiPrice;
  vatLevels: GungEdiVatObject[]; // the sum of all items corresponding to a vat percent
}

export interface GungEdiVatObject {
  vatAmount: GungEdiPrice;
  vatBaseAmount: GungEdiPrice;
  vatPercent: number;
}
export interface EdiDespatchAdvice extends EdiMessageBase {}

export interface EdiIncomingDespatchAdvice extends EdiDespatchAdvice {}

export enum GungEdimessageType {
  ORDER = 'ORDER',
  ORDER_RESPONSE = 'ORDER_RESPONSE',
  DESPATCH_ADVICE = 'DESPATCH_ADVICE',
  INCOMING_DESPATCH_ADVICE = 'INCOMING_DESPATCH_ADVICE',
  INVOICE = 'INVOICE',
  PURCHASE_ORDER = 'PURCHASE_ORDER'
}

export enum GungEdiMessageSendStatus {
  Sent = 'Sent',
  NotSent = 'NotSent',
  ReadyToSend = 'ReadyToSend',
  Sending = 'Sending',
  ErrorWhileSending = 'ErrorWhileSending',
  ErrorWhileGenerating = 'ErrorWhileGenerating',
  ErrorWhileValidating = 'ErrorWhileValidating',
  OnHold = 'OnHold',
  ShouldNotBeSent = 'ShouldNotBeSent',
  InitialMessage = 'InitialMessage',
  Archived = 'Archived',
  ErrorReported = 'ErrorReported'
}

export interface GLNRecord extends GungBase {
  primary: boolean;
  version: number;
  glnRecipient: any;
  glnInvoice: any;
  glnBuyer: string;
  gln: string;
  ediInstanceId: any;
  companyId: string;
  b24: { [s: string]: any } | null;
}

export interface EdiPurchaseOrder extends EdiMessageBase {}
