import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractSelectionService, FilterListComponent } from 'gung-list';
import { ActivityInternalListConfigService } from '../../../services/activity-internal-list-config/activity-internal-list-config.service';
import { ActivityInternal } from '../../../services/activity-internal/activity-internal.service';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';

@Component({
  selector: 'lib-activity-internal-list',
  template: '<lib-filter-list #list [configService]="configService"></lib-filter-list>',
  styleUrls: ['./activity-internal-list.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => ProductSelectionService)
    }
  ]
})
export class ActivityInternalListComponent implements OnInit {
  @Input()
  customerId?: string;

  @ViewChild('list', { static: false }) list: FilterListComponent<ActivityInternal>;

  constructor(public configService: ActivityInternalListConfigService) {}

  ngOnInit() {
    this.configService.setCustomerId(this.customerId);
  }
}
