import { Injectable } from '@angular/core';
import { ListLayout, ListLayoutMultipleComponent, ConfigService, ConfigBaseFilter, ListSortOption } from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { ExportDigitalAssetsFolderGridViewComponent } from '../components/export-digital-assets-folder-grid-view/export-digital-assets-folder-grid-view.component';
import { filter, first, mergeMap, switchMap } from 'rxjs';
import { DigitalAsset, PdfDigitalAssetsService } from './pdf-digital-assets.service';

@Injectable({
  providedIn: 'root'
})
export class ExportDigitalAssetsFolderListConfigService implements ConfigService<DigitalAsset> {
  topFilter = true;

  digitalAssetsObjectsSubject: Subject<string[]> = new BehaviorSubject(null);
  firstLoad = true;

  constructor(protected digitalAssetsService: PdfDigitalAssetsService) {}

  getFilters(): ConfigBaseFilter<DigitalAsset>[] {
    return [];
  }

  getSortOptions(): ListSortOption<DigitalAsset>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: DigitalAsset) {
    return item.id;
  }

  getSearchTerms(item: DigitalAsset) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<DigitalAsset>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => ExportDigitalAssetsFolderGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'folder view'
      }
    ];
  }

  getItems(): Observable<DigitalAsset[]> {
    return this.digitalAssetsObjectsSubject.pipe(
      filter(ids => !!ids),
      mergeMap(ids => forkJoin([of(ids), this.digitalAssetsService.getDigitalAssets(true).pipe(first())])),
      switchMap(([filterIds, digitalAssetsOriginal]) => {
        const digitalAssets = digitalAssetsOriginal
          ? JSON.parse(JSON.stringify(digitalAssetsOriginal))
          : digitalAssetsOriginal;
        if (
          this.firstLoad &&
          digitalAssets.findIndex(x => x.id === 'product-images') < 0 &&
          !this.digitalAssetsService.showFlowsAsDigitalAssets
        ) {
          const productfolder: DigitalAsset = {
            id: 'product-images',
            dateLastUpdate: 1,
            documents: [],
            extra: {
              type: 'folder',
              content: 'digitalAsset'
            },
            name: 'Product Images',
            i18n: {
              se: {},
              en: {}
            },
            images: []
          };
          if (filterIds) {
            return of(digitalAssets.filter(d => filterIds.includes(d.id) || d.id === 'product-images'));
          }
        }
        console.log('digitalAssets', digitalAssets);
        if (filterIds) {
          console.log(
            'digitalAssets filter',
            digitalAssets.filter(d => filterIds.includes(d.id))
          );
          return of(digitalAssets.filter(d => filterIds.includes(d.id)));
        }
        return of(digitalAssets);
      })
    );
  }

  getNothingFoundTranslateTag() {
    return null;
  }

  getSearchGroupCss() {
    return 'hide-search';
  }
}
