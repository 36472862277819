<div class="modal-header">
  <h4 class="modal-title" translate>ELEMENT_EDITOR</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <div>
        <lib-gung-text-input
          [gungTranslate]="'TEXT'"
          placeholder="{{ 'FILL_OPTION_TEXT' | translate }}"
          [(ngModel)]="data[0].text.text"
        >
        </lib-gung-text-input>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" (click)="onSave()" [disabled]="isLoading ? true : null" translate>
    SAVE<i *ngIf="isLoading" class="fas fa-spinner fa-spin ml-2"></i>
  </button>
</div>
