import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { StandardStatisticsData } from '../../../models/statistics';
import { OrderAnalyticsGraphList } from '../order-analytics-standalone/order-analytics-standalone.component';

@Component({
  selector: 'lib-order-analytics-list',
  templateUrl: './order-analytics-list.component.html',
  styleUrls: ['./order-analytics-list.component.css']
})
export class OrderAnalyticsListComponent extends ListItemRendererComponent<StandardStatisticsData> implements OnInit, AfterViewInit {
  selectedGraph: OrderAnalyticsGraphList;
  graphList: OrderAnalyticsGraphList[] = [
    {
      id: '1',
      translationKey: 'YEAR',
      groupBy: 'orderCreationDateYear',
      heading: 'YEAR',
      headerRows: ['YEAR', 'SALES', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '2',
      translationKey: 'MONTH',
      groupBy: 'orderCreationDateYearMonth',
      heading: 'MONTH',
      headerRows: ['MONTH', 'SALES', 'QTY', 'NUMBER_OF_ORDERS'],
      hideVerticalGridLines: true
    },
    {
      id: '3',
      translationKey: 'PRODUCT_CATEGORIES_LAST_12_MONTHS',
      groupBy: 'productCategoryCode',
      heading: 'PRODUCT_CATEGORY',
      headerRows: ['PRODUCT_CATEGORY', 'SALES', 'QTY'],
      hideVerticalGridLines: true
    }
  ];

  ngOnInit(): void {
    if (this.graphList && this.graphList.length > 0) {
      this.changeGraph(this.graphList[0].id);
    }
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  changeGraph(id: string) {
    const foundGraph = this.graphList.find(g => g.id === id);
    if (!!foundGraph) {
      this.selectedGraph = foundGraph;
    }
  }
}
