import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'lib-list-input',
  templateUrl: './list-input.component.html',
  styleUrls: ['./list-input.component.css']
})
export class ListInputComponent implements OnInit {
  @Input()
  input: string[];

  @Input()
  gungTranslate: string;

  @Input()
  placeholder: string;

  @Input()
  disabled = false;

  @Output()
  inputUpdated = new EventEmitter<string[]>();

  ngOnInit(): void {
    if (!this.input) {
      this.input = [];
    }
  }

  emit() {
    this.inputUpdated.emit(this.input);
  }

  add() {
    this.input.push('');
    this.emit();
  }

  remove(index: number) {
    this.input.splice(index, 1);
    this.emit();
  }

  trackByFn(index: any, item: any) {
    return index;
  }
}
