import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { tap, first } from 'rxjs';
import { GungBase } from '../../models';
import { PimDimension } from '../../models/pim';

@Injectable({
  providedIn: 'root'
})
export class PimDimensionsService {
  protected baseUrl = 'json/pim-product-dimensions';
  private pimDimensionSubject: Subject<PimDimension[]>;

  constructor(protected http: HttpClient) {}

  public getPimDimensions(nocache?: boolean): Observable<PimDimension[]> {
    if (!this.pimDimensionSubject) {
      this.pimDimensionSubject = new BehaviorSubject<PimDimension[]>([]);
      this.updatePimDimensionsSubject(nocache);
    }
    return this.pimDimensionSubject.asObservable();
  }

  public postPimDimensions(pimDimensions: PimDimension): Observable<PimDimension> {
    const url = `${this.baseUrl}`;

    return this.http.post<PimDimension>(url, pimDimensions).pipe(tap(_ => this.updatePimDimensionsSubject(true)));
  }

  updatePimDimensionsSubject(nocache?: boolean): void {
    if (!this.pimDimensionSubject) {
      return;
    }
    this.getPimDimensionsInternal(nocache)
      .pipe(first())
      .subscribe((data: PimDimension[]) => this.pimDimensionSubject.next(data));
  }

  private getPimDimensionsInternal(nocache?: boolean): Observable<PimDimension[]> {
    const url = `${this.baseUrl}`;
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<PimDimension[]>(url, { headers });
  }
}
