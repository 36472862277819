import { Component, Input, OnInit } from '@angular/core';
import { Pim } from '../../../../models/pim';

@Component({
  selector: 'lib-pim-product-variants',
  templateUrl: './pim-product-variants.component.html',
  styleUrls: ['./pim-product-variants.component.css']
})
export class PimProductVariantsComponent implements OnInit {
  @Input()
  pimProductVariants: Pim[];

  constructor() {}

  ngOnInit() {}
}
