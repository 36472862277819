import { Injectable } from '@angular/core';
import {
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListLayoutMultipleComponent,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { Observable, ReplaySubject, Subject } from 'rxjs';
import { PdfExportGridViewComponent } from '../components/pdf-export-grid-view/pdf-export-grid-view.component';
import { PdfDocument } from '../models/pdf-document';
import { DynamicPdfService } from './dynamic-pdf.service';
import { format, parse } from 'date-fns';

class ReadOnlyPdfFilter extends SimpleConfigBaseFilter<PdfDocument> {
  constructor() {
    super();
  }

  getName(): string {
    return 'STANDARD_TEMPLATE';
  }

  getOptionIds(item: PdfDocument): string[] {
    if (item.readOnly) {
      return ['YES'];
    }
    return ['NO'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class ArchivedFilter extends SimpleConfigBaseFilter<PdfDocument> {
  constructor() {
    super();
  }

  getName(): string {
    return 'ARCHIVED';
  }

  getOptionIds(item: PdfDocument): string[] {
    if (item.archived) {
      return ['YES'];
    }
    return ['NO'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class CreatedByFilter extends SimpleConfigBaseFilter<PdfDocument> {
  constructor() {
    super();
  }

  getName(): string {
    return 'CREATED_BY';
  }

  getOptionIds(item: PdfDocument): string[] {
    if (!item.createdBy) {
      return ['UNKNOWN'];
    }
    return [item.createdBy];
  }

  getOptionName(key: string): string {
    return key;
  }
}

class CreationDateFilter extends SimpleConfigBaseFilter<PdfDocument> {
  type = 'dateRangeFilter';

  constructor() {
    super();
  }

  getName(): string {
    return 'CREATED_DATE';
  }

  getOptionIds(item: PdfDocument): string[] {
    if (item.createdAt) {
      return [format(new Date(item.createdAt), 'yyMMdd')];
    }
    return [];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class PdfExportListConfigService implements ConfigService<PdfDocument> {
  public updateList: Subject<any> = new ReplaySubject<any>();

  constructor(protected dynamicPdfService: DynamicPdfService) {}

  getFilters(): ConfigBaseFilter<PdfDocument>[] {
    return [new CreatedByFilter(), new CreationDateFilter(), new ReadOnlyPdfFilter(), new ArchivedFilter()];
  }

  getSortOptions(): ListSortOption<PdfDocument>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getItemId(item: PdfDocument) {
    return item.id;
  }

  getSearchTerms(item: PdfDocument) {
    return [item.id, item.name];
  }

  getLayouts(): ListLayout<PdfDocument>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => PdfExportGridViewComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'exportpdf'
      }
    ];
  }

  getItems(): Observable<PdfDocument[]> {
    return this.dynamicPdfService.getAllPdfs();
  }

  getNothingFoundTranslateTag() {
    return null;
  }

  getSearchGroupCss() {
    return 'hide-search';
  }

  getUdatedList(): Observable<any> {
    return this.updateList.asObservable();
  }
}
