import { Component, Inject, Input, OnDestroy, OnInit, Optional } from '@angular/core';
import { Product } from '../../../models';
import { ProductsBookmarkService } from '../../../services/products-bookmark/products-bookmark.service';
import { first, takeUntil, tap } from 'rxjs/operators';
import { ProductsBookmark } from '../../../models/products-bookmark';
import { ProductsBookmarkActionButtonConfigService } from '../../../services/products-bookmark-action-button-config/products-bookmark-action-button-config.service';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductsBookmarkCreateModalComponent } from '../products-bookmark-create-modal/products-bookmark-create-modal.component';
import { AuthService } from '../../../services/auth/auth.service';
import { NavigationConfig } from '../../../models/navigation-config';

@Component({
  selector: 'lib-products-bookmark-management',
  templateUrl: './products-bookmark-management.component.html',
  styleUrls: ['./products-bookmark-management.component.scss']
})
export class ProductsBookmarkManagementComponent implements OnInit, OnDestroy {
  unsubscribe: Subject<void> = new Subject();

  bookmarks: ProductsBookmark[] = [];
  isAdmin = false;
  allowedCreateBookmarkRoles: string[] = ['ADMIN'];
  allowCreateBookmark: boolean = false;

  constructor(
    protected productsBookmarkService: ProductsBookmarkService,
    public actionButtonConfig: ProductsBookmarkActionButtonConfigService,
    protected modalService: NgbModal,
    protected authService: AuthService,
    @Optional()
    @Inject('environment')
    protected environment: NavigationConfig
  ) { }

  ngOnInit(): void {
    if (this.environment.productExportBookmarkRolesAllowedCreation) {
      this.allowedCreateBookmarkRoles = [...this.allowedCreateBookmarkRoles, ...this.environment.productExportBookmarkRolesAllowedCreation];
    }

    this.authService.getRoles().pipe(
      first(),
      tap(roles => (this.isAdmin = roles.includes('ADMIN')))
    ).subscribe(roles => {
      if (this.allowedCreateBookmarkRoles.some(role => roles.includes(role))) {
        this.allowCreateBookmark = true;
      }
    });

    this.productsBookmarkService
      .getAll()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(bookmarks => (this.bookmarks = bookmarks));
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  openCreateModal(update?: boolean): void {
    const modal = this.modalService.open(ProductsBookmarkCreateModalComponent);
    modal.componentInstance.isUpdate = update;
  }
}
