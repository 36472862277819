import { Injectable } from '@angular/core';
import { parse } from 'date-fns';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { Observable } from 'rxjs';
import { map } from 'rxjs';
import { OpenPurchaseOrdersCardListComponent } from '../../components/open-purchase-orders/open-purchase-orders-card-list/open-purchase-orders-card-list.component';
import { MetadataService } from '../metadata/metadata.service';
import { OpenPurchaseOrders, OpenPurchaseOrdersService } from '../open-purchase-orders/open-purchase-orders.service';

@Injectable({
  providedIn: 'root'
})
export class OpenPurchaseOrdersListConfigService implements ConfigService<OpenPurchaseOrders> {
  constructor(
    protected openPurchaseOrdersService: OpenPurchaseOrdersService,
    protected metaDataService: MetadataService
  ) {}

  getFilters(): ConfigBaseFilter<OpenPurchaseOrders>[] {
    return [];
  }

  getSortOptions(): ListSortOption<OpenPurchaseOrders>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<OpenPurchaseOrders>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => OpenPurchaseOrdersCardListComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'ProductGrid'
      }
    ];
  }

  getItems(): Observable<OpenPurchaseOrders[]> {
    return this.openPurchaseOrdersService.getOpenPurchaseOrders().pipe(
      map(data => {
        //  var result = data.filter(d => d.extra.IGA.LEF === '0').sort((a, b) => this.getDate(a.extra.IGA.ODT) < this.getDate(b.extra.IGA.ODT) ? 1 : -1);

        const result = data.map(element => {
          const name = this.metaDataService.getMetadataValue('LA', 'NAM', element.deliveryCustomerId);
          element.extra._gemoSupplierName = name;
          return element;
        });
        return result;
      })
    );
  }

  getItemId(item: OpenPurchaseOrders) {
    return item.id;
  }

  getSearchTerms(item: OpenPurchaseOrders) {
    return [item.id, item.name];
  }

  protected getDate(value: any): Date {
    if (!!value) {
      return parse(value, 'yyMMdd', new Date());
    }
    return null;
  }
}
