import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';
import { PriceService } from '../../../services/price/price.service';
import { ProductService } from '../../../services/products/product.service';
import { WhiteboardGridConfigService } from '../../services/whiteboard-grid-config.service';
import { WhiteboardOptionsService } from '../../services/whiteboard-options.service';
import { WhiteboardService } from '../../services/whiteboard.service';
import { WhiteboardGridComponent } from '../whiteboard-grid/whiteboard-grid.component';

@Component({
  selector: 'lib-whiteboard-print',
  templateUrl: './whiteboard-print.component.html',
  styleUrls: ['./whiteboard-print.component.scss']
})
export class WhiteboardPrintComponent extends WhiteboardGridComponent implements OnInit {
  constructor(
    public configService: WhiteboardGridConfigService,
    protected router: Router,
    protected productService: ProductService,
    protected route: ActivatedRoute,
    protected whiteboardService: WhiteboardService,
    public whiteboardOptionsService: WhiteboardOptionsService,
    public navbarConfigService: NavbarConfigService,
    protected priceService: PriceService
  ) {
    super(
      configService,
      router,
      productService,
      route,
      whiteboardService,
      whiteboardOptionsService,
      navbarConfigService,
      priceService
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
  }
}
