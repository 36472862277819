import { EventEmitter, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { EdiMessageBase, EdiOrder, GungEdiMessageSendStatus, GungEdimessageType } from '../../models/edi';
import { gungAddRemoveSpinner } from '../../utils/gung-utils';
import { AuthService } from '../auth/auth.service';
import { BackendInterceptor } from '../backend-interceptor/backend-interceptor.service';
import { EdiMessageBaseActionConfigService } from '../edi-message-base-action-config/edi-message-base-action-config.service';
import { EdiService } from '../edi/edi.service';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { ActionButton, ActionButtonConfigService } from 'gung-common';

@Injectable({
  providedIn: 'root'
})
export class EdiOrderActionConfigService
  extends EdiMessageBaseActionConfigService
  implements ActionButtonConfigService<EdiOrder>
{
  protected getMessageType(): GungEdimessageType {
    return GungEdimessageType.ORDER;
  }

  getButtons(item: EdiOrder, eventEmitter: EventEmitter<EdiOrder>): Observable<ActionButton<EdiOrder>[]> {
    return this.authService.getRoles().pipe(
      first(),
      switchMap(roles => {
        const isActuator = roles.indexOf("ACTUATOR")!==-1;
        const isAdmin = roles.indexOf("ADMIN")!==-1;

        const baseActions: ActionButton<EdiOrder>[] = [];
        if (item.gungEdiMessageSendStatus !== 'Sent' || (isActuator || isAdmin)) {
          baseActions.push({
            title: 'REVALIDATE',
            action: (ediOrder: EdiOrder) => this.validateMessage(ediOrder, event, eventEmitter)
          });
        }
        if (isActuator) {
          baseActions.push({
            title: 'DOWNLOAD_MESSAGE',
            action: (ediMessage: EdiOrder) => this.downloadMessage(ediMessage, event)
          });
          if (item.gungEdiMessageSendStatus === 'ReadyToSend') {
            baseActions.push({
              title: 'SEND_TO_ERP',
              action: (ediOrder: EdiOrder) => this.postOrderMessageToErp(ediOrder, event, eventEmitter)
            });
          }
        }
        if (isActuator || isAdmin) {
          baseActions.push({
            title: 'ARCHIVE_MESSAGE',
            action: (ediMessage: EdiMessageBase) => this.archiveMessage(ediMessage, event, eventEmitter)
          });
        }
        if (item.gungEdiMessageSendStatus !== 'Sent' && item.gungEdiMessageSendStatus !== 'OnHold') {
          baseActions.push({
            title: 'SET_STATUS_ON_HOLD',
            action: (ediOrder: EdiOrder) =>
              this.ediService
                .setMessageStatus(item.id, GungEdimessageType.ORDER, GungEdiMessageSendStatus.OnHold)
                .subscribe(res => {
                  this.ediService.getMessageById(ediOrder.id, this.getMessageType()).subscribe(updatedMessage => {
                    eventEmitter.emit(updatedMessage);
                  });
                })
          });
        }
        if (item.gungEdiMessageSendStatus !== 'Sent' && item.gungEdiMessageSendStatus !== 'ReadyToSend') {
          baseActions.push({
            title: 'SET_STATUS_READY_TO_SEND',
            action: (ediOrder: EdiOrder) =>
              this.ediService
                .setMessageStatus(item.id, GungEdimessageType.ORDER, GungEdiMessageSendStatus.ReadyToSend)
                .subscribe(res => {
                  this.ediService.getMessageById(ediOrder.id, this.getMessageType()).subscribe(updatedMessage => {
                    eventEmitter.emit(updatedMessage);
                  });
                })
          });
        }

        if (item.ediChannel === 'EDI_EMAIL_SCANNER') {
          baseActions.push({
            title: 'DOWNLOAD_ORIGINAL_PDF',
            action: (ediOrder: EdiOrder) => this.downloadEmailScannerInput(ediOrder, event)
          });
        }

        return of(baseActions);
      })
    );
  }

  downloadEmailScannerInput(ediOrder: EdiOrder, element): void {
    gungAddRemoveSpinner(element.target);

    // Necessary, because when using in list not all data is contained in the oobject
    const fullEdiOrderUrl = '/json/gung/edi/orders/'+ediOrder.id;
    this.http.get<EdiOrder>(fullEdiOrderUrl).pipe(first()).subscribe(ediOrderFull => {
      const filePath = ediOrderFull.data.extra._originalEmail.path;
      if (!filePath) {
        gungAddRemoveSpinner(element.target);
        return;
      }
      const url = `${this.backend.getBaseUrl()}json/edi/email-scanner/documents?filePath=${filePath}`;
      window.open(url, '_blank');
      gungAddRemoveSpinner(element.target);
    })

  }
  postOrderMessageToErp(ediOrder: EdiOrder, element, eventEmitter: EventEmitter<EdiOrder>) {
    gungAddRemoveSpinner(element.target);
    this.ediService
      .postOrderMessageToErp(ediOrder.id, ediOrder)
      .subscribe(result => {})
      .add(() => {
        this.ediService.getMessageById(ediOrder.id, this.getMessageType()).subscribe(updatedMessage => {
          eventEmitter.emit(updatedMessage);
          gungAddRemoveSpinner(element.target);
        });
      });
  }

  addRemoveSpinner(htmlElement: HTMLElement) {
    if (!htmlElement) {
      return;
    }
    const spinnerHtml = '<i class="fa fa-spinner fa-spin ml-1"></i>';
    const oemHtml: string = htmlElement.innerHTML;
    if (oemHtml.includes(spinnerHtml)) {
      // remove spinner
      htmlElement.innerHTML = oemHtml.slice(0, -spinnerHtml.length);
      if (htmlElement instanceof HTMLInputElement) {
        (htmlElement as HTMLInputElement).disabled = false;
      }
    } else {
      // add spinner
      htmlElement.innerHTML += spinnerHtml;
      if (htmlElement instanceof HTMLInputElement) {
        (htmlElement as HTMLInputElement).disabled = true;
      }
    }
  }
}
