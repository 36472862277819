import {
  Component,
  OnInit,
  AfterViewInit,
  Input,
  Type,
  ViewChildren,
  QueryList,
  ComponentFactory,
  ComponentFactoryResolver,
  ChangeDetectorRef,
  ViewContainerRef,
  OnDestroy
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { FastSearchListLayoutComponent, FastsearchItemRendererComponent } from '../types';
import { CustomHostDirective } from '../custom-host/custom-host.directive';
import { takeUntil, switchMap } from 'rxjs';

@Component({
  selector: 'lib-fastsearch-layout',
  templateUrl: './fastsearch-layout.component.html',
  styleUrls: ['./fastsearch-layout.component.css']
})
export class FastsearchLayoutComponent<T>
  extends FastSearchListLayoutComponent<T>
  implements AfterViewInit, OnInit, OnDestroy
{
  @Input()
  public renderItems: Observable<T[]>;

  @Input()
  public itemListRenderer: Type<FastsearchItemRendererComponent<T | T[]>>;

  @Input()
  public itemDetailRenderer: Type<FastsearchItemRendererComponent<T>>;

  @ViewChildren(CustomHostDirective)
  protected viewChildren: QueryList<CustomHostDirective>;

  private listComponentFactory: ComponentFactory<FastsearchItemRendererComponent<T>>;
  private detailComponentFactory: ComponentFactory<FastsearchItemRendererComponent<T>>;

  public items: T[];
  private unsubscribe: Subject<boolean> = new Subject();
  private viewInitiated: Subject<void> = new Subject();
  private viewInitiated$: Observable<void> = this.viewInitiated.asObservable();

  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  ngOnInit() {
    this.viewInitiated$
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap(() => this.renderItems)
      )
      .subscribe(items => {
        this.items = items;
        this.renderItemComponents();
      });

    this.listComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.itemListRenderer as Type<FastsearchItemRendererComponent<T>>
    );
    this.detailComponentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.itemDetailRenderer as Type<FastsearchItemRendererComponent<T>>
    );
  }

  ngAfterViewInit(): void {
    this.viewInitiated.next();
    this.viewInitiated.complete();
  }

  ngOnDestroy() {
    this.unsubscribe.next(true);
    this.unsubscribe.complete();
  }

  renderDetailComponent(item: T, containerRef: ViewContainerRef) {
    containerRef.clear();
    const componentRef = containerRef.createComponent(this.detailComponentFactory);
    const typed = componentRef.instance as FastsearchItemRendererComponent<T>;
    typed.data = item;
  }

  renderListComponent(item: T, containerRef: ViewContainerRef) {
    containerRef.clear();
    const componentRef = containerRef.createComponent(this.listComponentFactory);
    const typed = componentRef.instance as FastsearchItemRendererComponent<T>;
    typed.dataSelection$.pipe(takeUntil(this.unsubscribe)).subscribe(selection => {
      this.items = [selection];
      this.renderItemComponents();
    });
    typed.data = item;
  }

  renderItemComponents(): void {
    this.changeDetectorRef.detectChanges();
    const children = this.viewChildren.toArray();
    if (!this.items || this.items.length === 0) {
      return;
    }
    if (this.items.length === 1) {
      this.renderDetailComponent(this.items[0], children[0].viewContainerRef);
    } else {
      for (let i = 0; i < this.items.length; i++) {
        this.renderListComponent(this.items[i], children[i].viewContainerRef);
      }
    }
    this.changeDetectorRef.detectChanges();
  }
}
