import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProductsBookmark } from '../../../models/products-bookmark';
import { ProductsBookmarkService } from '../../../services/products-bookmark/products-bookmark.service';
import { AuthService } from '../../../services/auth/auth.service';
import { first, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';

interface Alert {
  type: string;
  message: string;
}

@Component({
  selector: 'lib-products-bookmark-create-modal',
  templateUrl: './products-bookmark-create-modal.component.html',
  styleUrls: ['./products-bookmark-create-modal.component.scss']
})
export class ProductsBookmarkCreateModalComponent implements OnInit {
  alertMessage: Alert;
  submited = false;
  isUpdate = false;

  bookmarkForm: FormGroup;
  productsBookmark: ProductsBookmark = {} as ProductsBookmark;

  constructor(
    public activeModal: NgbActiveModal,
    protected formBuilder: FormBuilder,
    protected productsBookmarkService: ProductsBookmarkService,
    protected productSelectionService: ProductSelectionService,
    protected authService: AuthService
  ) {}

  ngOnInit(): void {
    this.productsBookmark.users = this.productsBookmark.users || [];
    this.initForm();
  }

  initForm() {
    this.bookmarkForm = this.formBuilder.group({
      id: this.formBuilder.control(this.productsBookmark.id, [Validators.required]),
      name: this.formBuilder.control(this.productsBookmark.name, []),
      users: this.formBuilder.control(this.productsBookmark.users.join(','), [])
    });
  }

  onSave() {
    if (!this.bookmarkForm.valid) {
      this.bookmarkForm.markAllAsTouched();
      return;
    }

    const formRaw = this.bookmarkForm.getRawValue();
    this.bookmarkForm.disable();

    this.productsBookmark = {
      ...this.productsBookmark,
      id: formRaw.id,
      name: formRaw.name,
      users: formRaw.users.split(',')
    } as ProductsBookmark;

    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.productSelectionService.getSelection().pipe(first())
    ])
      .pipe(
        first(),
        tap(([user, selection]) => {
          this.productsBookmark.users = [...this.productsBookmark.users, user.username];
          if (!this.isUpdate) {
            this.productsBookmark.products = Object.values(selection.selectedItems).map(p => p.id);
          }
        }),
        switchMap(u => forkJoin([this.productsBookmarkService.create(this.productsBookmark)]))
      )
      .subscribe(
        ([bookmark]) => {
          this.alertMessage = { type: 'success', message: this.isUpdate ? 'BOOKMARK_SAVED' : 'BOOKMARK_CREATED' };
          this.submited = true;
        },
        () => {
          this.alertMessage = { type: 'danger', message: 'AN_ERROR_HAS_OCCURED' };
          this.submited = true;
        }
      );
  }
}
