<div class="row">
  <!-- *ngIf="itemProperties; else loading" -->
  <div *ngFor="let concept of data" class="col-12 col-sm-6 col-md-3 col-lg-2 pt-2">
    <div class="h-100 current-assortment-children">
      <a
        [routerLinkActive]="'active-assortment'"
        [routerLink]="['./', concept.id]"
        queryParamsHandling="merge"
        class="d-block"
      >
        <img
          *ngIf="concept.extra.images && concept.extra.images[0] as image; else noimage"
          class="card-img-top img-fluid mx-auto d-block"
          [src]="image.s3Uri | gungImageUrl
            :'type:list'
            :'etag:'+image.s3ETag
          "
          alt="{{ image.description }}"
        />
        <ng-template #noimage>
          <img
            class="card-img-top img-fluid w-100"
            [src]="'images/no-image.jpg' | gungImageUrl"
            alt="NO IMAGE"
            class="img-fluid mx-auto d-block"
          />
        </ng-template>
      </a>
      <span class="d-block current-assortment-children-name align-self-center pt-2 pl-2">
        <div class="d-flex flex-column justify-content-center align-items-center">
          <div class="">
            <a
              [routerLinkActive]="'active-assortment'"
              [routerLink]="['./', concept.id]"
              queryParamsHandling="merge"
              class="d-block"
            >
              <h6>
                {{ concept.name }}
              </h6>
            </a>
          </div>
          <div class="description">
            <!-- {{ getExtraPimDescription(concept, currentLang) }} -->
          </div>
        </div>
      </span>
    </div>
  </div>
</div>
<ng-template #loading> LOADING... </ng-template>
