<div *ngIf="!isLoading; else loading">
  <div class="modal-header">
    <h3>
      {{ product.name }} <span class="small">{{ product.id }}</span>
    </h3>
  </div>

  <div class="modal-body">
    <div class="row">
      <div class="col">
        <div class="table-responsive">
          <table class="table">
            <thead>
              <tr>
                <th class="select-product" translate>PRODUCT</th>
                <th></th>
                <th translate *ngIf="cartListCheckoutConfig.enableSalesEditPrice">PRICE</th>
                <th translate *ngIf="cartListCheckoutConfig.enableSalesEditDiscount">DISCOUNT</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let row of cartRows; index as rowIndex">
                <td class="d-flex justify-content-start align-items-center">
                  <img
                    [src]="row.product.extra.images[0].s3Uri
                      | gungImageUrl
                        :'type:thumbnail'
                        :'etag:'+row.product.extra.images[0].s3ETag
                    "
                    style="width: 75px; height: 75px;"
                    alt="{{ row.product.extra.images[0].description }}"
                    class="img-fluid"
                  />
                  <div>
                    <span class="product-id">
                      {{ row.cartRow.productId }}
                      <span *ngIf="row.cartRow.productPartialId">
                        {{ ' - ' + row.cartRow.productPartialId }}
                      </span>
                    </span>
                    <br />
                    <span class="multidimensions" *ngIf="!!row.isMultiDimensional">
                      <span
                        >{{ row.product.primaryDimension[0].name }} - {{ row.product.secondaryDimension[0].name }}</span
                      >
                    </span>
                  </div>
                </td>
                <td>
                  <div *ngIf="rowIndex !== cartRows.length - 1">
                    <span class="pr-1" *ngIf="rowIndex !== cartRows.length - 2">
                      <button
                        type="button"
                        class="fa fa-angle-double-down"
                        tabindex="-1"
                        [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ALL')"
                        (click)="copyDown('all', rowIndex)"
                      ></button>
                    </span>
                    <button
                      type="button"
                      class="fa fa-angle-down"
                      tabindex="-1"
                      [title]="getTranslation('MATRIX_COPY_ROW_DOWN_ONCE')"
                      (click)="copyDown('once', rowIndex)"
                    ></button>
                  </div>
                </td>
                <td *ngIf="cartListCheckoutConfig.enableSalesEditPrice">
                  <lib-gung-number-input [suppressLabel]="true" [(ngModel)]="row.editExtra._setPrice"></lib-gung-number-input>
                </td>
                <td *ngIf="cartListCheckoutConfig.enableSalesEditDiscount">
                  <lib-gung-number-input [suppressLabel]="true" [(ngModel)]="row.editExtra._setDiscount"></lib-gung-number-input>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-primary text-uppercase" (click)="closeMatrix()" translate>
      CANCEL
    </button>
    <button
      type="button"
      class="btn btn-sm btn-default btn-primary text-uppercase"
      (click)="applyAndCloseMatrix()"
      translate
    >
      OK
    </button>
  </div>
</div>

<ng-template #loading> LOADING... </ng-template>
