<div class="row">
  <div class="col-12">
    <div class="row">
      <ng-template name="searchFields" libCustomHost></ng-template>
    </div>
  </div>
  <div class="col-12">
    <div class="top-filter-wrapper">
      <lib-filter-list-top-filter
        *ngFor="let renderFilter of renderFilters"
        [renderFilter]="renderFilter"
        (selectValue)="setSelection(renderFilter.name, $event, true)"
        (deselectValue)="setSelection(renderFilter.name, $event, false)"
      ></lib-filter-list-top-filter>
    </div>
  </div>
  <div class="col-12">
    <ng-template libCustomHost></ng-template>
    <button
      *ngIf="!!filteredItems && filteredItems.length > limit"
      class="btn btn-primary btn-block"
      (click)="loadMore()"
    >
      Load more (showing {{ limit }} of {{ filteredItems.length }})
    </button>
  </div>
</div>
