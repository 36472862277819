import { Component, OnInit } from '@angular/core';
import { ActuatorService } from '../../../services/actuator/actuator.service';

@Component({
  selector: 'lib-order-context',
  templateUrl: './order-context.component.html',
  styleUrls: ['./order-context.component.css']
})
export class OrderContextComponent implements OnInit {
  error;
  context = 'test';
  contextId: string;
  data;
  loading = false;
  steps: any
  selectedStep: string;
  constructor(protected actuatorService: ActuatorService) {}

  ngOnInit(): void {}

  sendOrderContext() {
    this.error = null;
    this.loading = true;
    this.actuatorService.sendOrderContext(this.context).subscribe(
      data => {
        this.loading = false;
        this.data = data;
      },
      error => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  sendOrderContextWithStep() {
    this.error = null;
    this.loading = true;
    this.actuatorService.sendOrderContextWithStep(this.contextId,this.selectedStep).subscribe(
      data => {
        this.loading = false;
        this.data = data;
      },
      error => {
        this.error = error;
        this.loading = false;
      }
    );
  }

  getSteps(){
    this.actuatorService.getSteps(this.contextId).subscribe(
      data => {
        this.loading = false;
        this.steps = data;
      },
      error => {
        this.error = error;
        this.loading = false;
      }
    );
  }
}
