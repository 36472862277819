<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">
    <span translate *ngIf="isUpdate">UPDATE_WHITEBOARD</span>
    <span translate *ngIf="!isUpdate">CREATE_WHITEBOARD</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>

<div class="modal-body">
  <div *ngIf="whiteboardForm" [formGroup]="whiteboardForm">
    <lib-gung-text-input-horizontal gungTranslate="NAME" formControlName="name" placeholder="NAME">
    </lib-gung-text-input-horizontal>
    <lib-gung-text-input-horizontal gungTranslate="USERS" formControlName="users" placeholder="USERS">
    </lib-gung-text-input-horizontal>
    <span class="field-description" translate>USERS_COMMA_SEPARATED</span>
  </div>

  <div class="row">
    <div class="col-12">
      <div
        *ngIf="alertMessage"
        class="alert mt-2 text-center"
        role="alert"
        [ngClass]="{ 'alert-success': alertMessage.type === 'success', 'alert-danger': alertMessage.type === 'danger' }"
        (click)="alertMessage = null"
      >
        <span>{{ alertMessage.message | translate }}</span>
      </div>
    </div>
  </div>
</div>

<div class="modal-footer">
  <button class="btn btn-sm btn-primary btn-default px-4" (click)="activeModal.close(false)" translate>CANCEL</button>
  <button class="btn btn-sm btn-outline-primary px-4" [disabled]="submited" (click)="onSave()" translate>SAVE</button>
</div>
