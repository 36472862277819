import { Component, OnInit } from '@angular/core';
import { DocumentsService } from '../../../services/documents/documents.service';
import { Document } from '../../../models/document';
import { NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { DocumentsUploadModalComponent } from '../documents-upload-modal/documents-upload-modal.component';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { first, map } from 'rxjs';
import { DateUtilService } from 'gung-common';
import { Observable } from 'rxjs';
import { DocumentsEditModalComponent } from '../documents-edit-modal/documents-edit-modal.component';

@Component({
  selector: 'lib-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})
export class DocumentsComponent implements OnInit {
  documentsList: Document[];
  duplicate = {};

  constructor(
    protected documentsService: DocumentsService,
    protected modalService: NgbModal,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService
  ) {}

  ngOnInit() {
    this.initDocuments();
  }

  initDocuments(nocache?: boolean) {
    this.getAllDocuments(nocache).subscribe(documents => {
      this.documentsList = documents;
      this.documentsList.map(doc => {
        if (doc.metaData && doc.metaData.userGroups && typeof doc.metaData.userGroups === 'string') {
          doc.metaData.userGroups = JSON.parse(doc.metaData.userGroups);
        }
        const results = this.documentsList.filter(d => d.filename === doc.filename);
        if (results.length > 1) {
          this.duplicate[doc.id] = true;
        }
      });
    });
  }

  protected getAllDocuments(nocache?: boolean): Observable<Document[]> {
    return this.documentsService.getDocuments(nocache).pipe(
      first(),
      map(documents =>
        documents.sort((a, b) =>
          this.dateUtilService.parseDate(a.uploadDate) < this.dateUtilService.parseDate(b.uploadDate) ? 1 : -1
        )
      )
    );
  }

  removeDocument(document) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('CONFIRM_DELETION', { value: document.filename }) + '?', {
        size: 'sm'
      })
      .then(
        result => {
          if (result) {
            this.documentsService
              .deleteDocument(document.id)
              .pipe(first())
              .subscribe(documents => this.initDocuments(true));
          }
        },
        reason => {}
      );
  }

  openUploadModal() {
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true
    };

    const modalRef = this.modalService.open(DocumentsUploadModalComponent, config);
    modalRef.componentInstance.multiple = true;
    modalRef.componentInstance.uploadMethod = (file, fileDescription, inMenu, userGroups) =>
      this.uploadFile(file, fileDescription, inMenu, userGroups);
    modalRef.result.then(
      result => {
        if (result === true) {
          this.initDocuments(true);
        }
      },
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }

  uploadFile(files: File[], fileDescription: string, inMenu: boolean = false, userGroups: any): Promise<boolean> {
    const formData = new FormData();
    for (const file of files) {
      formData.append('documents', file);
    }
    formData.append('inMenu', inMenu.toString());
    formData.append('description', fileDescription);
    formData.append('language', this.translationService.currentLang);
    formData.append('userGroups', JSON.stringify(userGroups));

    return new Promise((resolve, reject) => {
      this.documentsService
        .uploadDocument(formData)
        .pipe(first())
        .subscribe(
          (event: any) => {
            if (typeof event === 'object') {
              if (event.filenames) {
                resolve(true);
              }
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) {
      return '0 Bytes';
    }

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  getDocumentUrl(id): string {
    return this.documentsService.getDocumentUrl(id);
  }

  editFile(
    documentId,
    fileDescription: string,
    inMenu: boolean = false,
    userGroups: any,
    includedAssortments: string,
    includedCustomers: string,
    excludedCustomers: string
  ): Promise<boolean> {
    const metadata = {
      inMenu: inMenu.toString(),
      description: fileDescription,
      language: this.translationService.currentLang,
      userGroups: JSON.stringify(userGroups)
    };

    return new Promise((resolve, reject) => {
      this.documentsService
        .editDocument(documentId, metadata)
        .pipe(first())
        .subscribe(
          (event: any) => {
            if (typeof event === 'object') {
              if (event.description) {
                resolve(true);
              }
            }
          },
          error => {
            reject(error);
          }
        );
    });
  }

  editDocument(document) {
    const config: NgbModalOptions = {
      backdrop: 'static',
      keyboard: true
    };
    const modalRef = this.modalService.open(DocumentsEditModalComponent, config);
    modalRef.componentInstance.document = document;
    modalRef.componentInstance.multiple = true;
    modalRef.componentInstance.editMethod = (
      documentId,
      fileDescription,
      inMenu,
      userGroups,
      includedAssortments,
      includedCustomers,
      excludedCustomers
    ) =>
      this.editFile(
        documentId,
        fileDescription,
        inMenu,
        userGroups,
        includedAssortments,
        includedCustomers,
        excludedCustomers
      );
    modalRef.result.then(
      result => {
        if (result === true) {
          this.initDocuments(true);
        }
      },
      reason => {
        console.log(`Dismissed ${reason}`);
      }
    );
  }
}
