import { Injectable } from '@angular/core';
import { AbstractSelectionService } from './abstract-selection-service';

@Injectable({
  providedIn: 'root'
})
export class StringSelectionService extends AbstractSelectionService<string> {
  constructor() {
    super();
  }

  getItemId(item: string): string {
    return item;
  }
}
