import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { ActionTypes, GetMetadata, MetadataReceived } from './actions';
import { ActionTypes as AuthActionTypes } from '../auth/actions';
import { map, mergeMap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Metadata } from './types';
import { Observable } from 'rxjs';

interface MetadataResponse {
  extra: Metadata;
}

@Injectable()
export class MetadataEffects {
  userReceived$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthActionTypes.UserReceived),
      map(_ => new GetMetadata())
    )
  );

  getMetadata$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ActionTypes.GetMetadata),
      mergeMap(_ => this.getMetadata()),
      map(metadata => new MetadataReceived(metadata))
    )
  );

  private getMetadata(): Observable<Metadata> {
    const url = 'json/meta-data';
    return this.http.get<MetadataResponse>(url).pipe(map(resp => resp.extra));
  }
  constructor(private actions$: Actions, private http: HttpClient) {}
}
