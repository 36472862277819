import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Observable, forkJoin, of, Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { first, map, switchMap, takeUntil, tap } from 'rxjs';
import { Product, ProductMultiDimension } from './../../../../models';
import { Availability } from './../../../../models/availability';
import { AuthService } from './../../../../services/auth/auth.service';
import { PriceService } from './../../../../services/price/price.service';
import { ProductService } from './../../../../services/products/product.service';
import { AvailabilityService } from './../../../../services/availability/availability.service';
import { ProductMatrixModalService } from './../../../../services/product-matrix-modal/product-matrix-modal.service';
import { TranslateService } from '@ngx-translate/core';
import { AssortmentService } from '../../../../services/assortment.service';
import { MetadataService } from '../../../../services/metadata/metadata.service';
import { CustomerProductPrice } from './../../../../models/price';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { User } from '../../../../state/auth/types';
import { GungFlow } from '../../../../state/flow/types';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-product-detail-matrix-modal',
  templateUrl: './product-detail-matrix-modal.component.html',
  styleUrls: ['./product-detail-matrix-modal.component.css']
})
export class ProductDetailMatrixModalComponent implements OnInit, OnDestroy {
  @Input()
  modelId: string; // TODO CHANGE THIS TO productId

  @Input()
  initialImage: { image: any; id: string };

  @Input()
  productId?: string;

  productImage: { image: any; id: string };
  selectedProduct: ProductMultiDimension;
  delegate: ProductMatrixModalService;
  pimBullets: string[] = [];
  baseMultiDimensionData: BaseMultiDimensionData;
  containsPacks = false;
  packsProducts: Product[];
  packsAvailabilties: Availability[];
  isLoading = true;
  secondaryDimension: Product[];
  product: Product;
  availability: Availability;
  price: CustomerProductPrice;
  prices: CustomerProductPrice[];
  noCache = true;
  isSales: boolean;

  unsubscribe: Subject<void> = new Subject();

  public currentFlow: GungFlow;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected availabilityService: AvailabilityService,
    protected authService: AuthService,
    protected priceService: PriceService,
    protected metadataService: MetadataService,
    protected translateService: TranslateService,
    protected assortmentService: AssortmentService,
    protected gungFlowService: GungFlowService,
    protected activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.productImage = this.initialImage;
    const baseObservable = this.authService.getCurrentUser().pipe(
      first(),
      map(user => user.managedMultistockIds[0]),
      switchMap(multistock =>
        forkJoin({
          product: this.productService.getProduct(this.modelId) as Observable<ProductMultiDimension>,
          availability: this.availabilityService.getAvailability(this.modelId, multistock, this.noCache),
          price: this.priceService.getCurrentCustomerPrice(this.modelId).pipe(first()),
          user: this.authService.getCurrentUser().pipe(first()),
          flow: this.gungFlowService.getSelectedFlow().pipe(first())
        })
      )
    );
    this.handleObservable(baseObservable);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  protected handleObservable(observable: Observable<BaseMultiDimensionData>): void {
    // In the observable, the product data is obtained calling the service /json/products/10209 (eg. 10209 is a modelId)
    // This returns a product which contains primaryDimensions which are not filtered by flow
    // HOWEVER is pretended that the packs and colors (primary Dimensions) are filtered by flow

    // THEN here is filtered out the primary dimensions (this will filter the colors in matrix and also the packs (packs are fetch by colors))
    // Calling the getProductsByIds (this filters by flowId), passing all the primaryDimensions and only returns the primaryDimensions allowed for the flow

    // THEN the primaryDimensions in the product returned in the obervable (the product fetched by modedlId)
    // are set to the primary Dimensions returned by the getProductsByIds

    // ALSO is there is any color (primaryDimension) which is blocked then it is removed from the data.product which will be shown in the matrix
    observable
      .pipe(
        takeUntil(this.unsubscribe),
        switchMap((data: BaseMultiDimensionData) => {
          return forkJoin({
            baseMultiDimensionData: of(data),
            filteredPrimaryDimensionIdsFlowIds: this.productService
              .getProductsByIds(
                data.product.primaryDimension.map(primaryDimension =>
                  data.product.id === data.product.modelId ? data.product.id + primaryDimension.id : data.product.id
                )
              )
              .pipe(
                first(),
                tap(filteredProducts => (this.secondaryDimension = filteredProducts)),
                map(filteredProducts => filteredProducts.filter(product => !!product).map(product => product.id))
              ),
            blockedPrimaryDimensions: this.productService
              .getBlockedProducts(
                data.product.primaryDimension.map(primaryDimension => data.product.id + primaryDimension.id)
              )
              .pipe(first()),
            productPrices: this.priceService
              .getCurrentCustomerPrices(
                data.product.primaryDimension.map(primaryDimension => data.product.id + primaryDimension.id)
              )
              .pipe(first())
          });
        })
      )
      .subscribe(
        ({ baseMultiDimensionData, filteredPrimaryDimensionIdsFlowIds, blockedPrimaryDimensions, productPrices }) => {
          this.currentFlow = baseMultiDimensionData.flow;
          // this deep copy is necessary due to that some primaryDimensions may be removed from the model product
          // the the baseMultiDimensionData.product is a reference for the product stored in cache
          const data: BaseMultiDimensionData = JSON.parse(JSON.stringify(baseMultiDimensionData));
          this.prices = productPrices;
          // Filter primaryDimension products in baseData product, by products allowed by flow
          for (const dataModelPrimaryDimensionId of data.product.primaryDimension.map(x => x.id)) {
            // In case filtered primaryDimension products does not contains the primaryDimensionId
            // Then remove it from the primaryDimension array
            if (!filteredPrimaryDimensionIdsFlowIds.includes(data.product.id + dataModelPrimaryDimensionId)) {
              const index = data.product.primaryDimension.findIndex(item => item.id === dataModelPrimaryDimensionId);
              if (index >= 0) {
                data.product.primaryDimension.splice(index, 1);
              }
            }

            // IF primaryDimension is blocked THEN remove it from product primaryDimension
            if (blockedPrimaryDimensions[data.product.id + dataModelPrimaryDimensionId]) {
              const index = data.product.primaryDimension.findIndex(item => item.id === dataModelPrimaryDimensionId);
              if (index >= 0) {
                data.product.primaryDimension.splice(index, 1);
              }
            }
          }

          data.product.extra._secondaryDimension = this.secondaryDimension;
          this.baseMultiDimensionData = data;
          this.product = data.product;
          this.availability = data.availability;
          if (this.productId) {
            this.handleProductSelect(this.productId);
          } else {
            this.price = data.price;
          }
          this.isSales =
            data.user.roles.filter(role => role.toUpperCase() === 'ADMIN' || role.toUpperCase() === 'SALES').length > 0;

          this.isLoading = false;
        }
      );
  }

  closeMatrix() {
    // OLD METHOD
    // this.delegate.modalRef.get(ProductDetailMatrixModalComponent.name).close('ok');

    this.activeModal.close('ok');
  }

  handleProductSelect(productId: string): void {
    if (!this.selectedProduct || this.selectedProduct.id !== productId) {
      this.productService
        .getProduct(productId)
        .pipe(first())
        .subscribe(product => {
          this.productImage = { image: product.extra.images[0], id: product.id };
          this.selectedProduct = product as ProductMultiDimension;
          this.price =
            this.prices.find(
              p => p.productId === this.selectedProduct.modelId + this.selectedProduct.primaryDimension[0].id
            ) || this.prices.find(p => p.productId === this.selectedProduct.modelId);
        });
    }
  }
}

export interface BaseMultiDimensionData {
  product: ProductMultiDimension;
  price: CustomerProductPrice;
  availability: Availability;
  user: User;
  flow: GungFlow;
}
