import { Action } from '@ngrx/store';
import { GungFlow } from './types';

export enum GungFlowActionTypes {
  SelectFlow = '[GungFlow] Select flow',
  FlowSelected = '[GungFlow] Flow selected',
  GetDefaultFlow = '[GungFlow] Get default flow',
  ClearSelectedFlow = '[GungFlow] Clear selected flow'
}

export interface SelectFlowPayload {
  flowId: string;
}

export class SelectFlow implements Action {
  type: string = GungFlowActionTypes.SelectFlow;
  constructor(public payload: SelectFlowPayload) {}
}

export class FlowSelected implements Action {
  type: string = GungFlowActionTypes.FlowSelected;
  constructor(public payload: GungFlow) {}
}

export class GetDefaultFlow implements Action {
  type: string = GungFlowActionTypes.GetDefaultFlow;
}

export class ClearSelectedFlow implements Action {
  type: string = GungFlowActionTypes.ClearSelectedFlow;
}
