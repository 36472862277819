import { Component, OnInit } from '@angular/core';
import { PimMetadataListConfigService } from '../../../services/pim-metadata-list-config/pim-metadata-list-config.service';

@Component({
  selector: 'lib-pim-metadata-wrapper',
  templateUrl: './pim-metadata-wrapper.component.html',
  styleUrls: ['./pim-metadata-wrapper.component.css']
})
export class PimMetadataWrapperComponent implements OnInit {
  constructor(public pimMetadataListConfigService: PimMetadataListConfigService) {}

  ngOnInit(): void {}
}
