<div class="digital-assets-wrapper">
  <div class="row">
    <div class="col text-center">
      <!-- BREADCRUMB -->
      <!-- <div class="row row-breadcrumb" *ngIf="breadcrumbs">
          <div class="col-12">
            <lib-breadcrumb></lib-breadcrumb> <hr/>
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb px-0 align-items-cente justify-content-center bg-transparent">
                <li *ngFor="let breadcrumb of breadcrumbs; let last = last" class="breadcrumb-item p-0 pl-2"
                  [attr.aria-current]="last ? 'page' : undefined">
                  <a [ngClass]="{'active-assortment': last}" [routerLink]="breadcrumb.urlParts"
                    translate>{{breadcrumb.label}}</a>
                </li>
              </ol>
            </nav>
          </div>
        </div> -->
      <h3 class="mt-3"><a translate (click)="homeDigitalAsset()">DIGITAL_ASSETS</a></h3>
      <div>
        <!--    <input class="search-digital" type="text" placeholder="{{'SEARCH' | translate}}"
            [(ngModel)]="digitalAssetsSearch" (ngModelChange)="searchChange($event)"> -->
      </div>
    </div>
  </div>

  <div class="row" *ngIf="!isFolder && !digitalAssetDetails">
    <div class="col-12">
      <lib-filter-list [configService]="digitalAssetsListConfigService"></lib-filter-list>
    </div>
  </div>
  <div class="row" *ngIf="isFolder && !digitalAssetDetails">
    <div class="col-12">
      <lib-filter-list [configService]="digitalAssetsFolderListConfigService"></lib-filter-list>
    </div>
  </div>
  <div class="row" *ngIf="digitalAssetDetails && !isFolder">
    <div class="col-12">
      <div class="row mt-4 mb-4">
        <div
          *ngFor="let image of selectedDigitalAsset.images; last as isLast"
          class="col-12 col-sm-6 col-md-6 col-lg-4 col-xl-4 mb-3"
        >
          <div
            class="card product-card product-card-container"
            id="p-id-{{ image.s3ETag }}"
            draggable="true"
            (dragstart)="onDragStart($event, image)"
          >
            <div class="card-body">
              <div class="image-wrapper text-center">
                <img
                  class="img-fluid"
                  draggable="false"
                  [src]="image.s3Uri | gungImageUrl
                    :'type:thumbnail'
                    :'etag:'+image.s3ETag
                    :'orientation:portrait'
                  "
                />
              </div>
              <div class="product-text">
                <div class="name">{{ image.name }}</div>
                <button
                  class="btn btn-sm btn-default btn-block btn-primary ng-star-inserted"
                  (click)="addToSheet(image)"
                >
                  Add to Page
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="row" *ngIf="isSearching && !productList">
        <div class="col-12">
          <h2>RESULTS</h2>
          <lib-filter-list [configService]="digitalAssetsSearchListConfigService"></lib-filter-list>
        </div>
      </div> -->
</div>
<ng-template #loading>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
