import { GungImageBaseUrl, GungNoImageUrl } from 'gung-common';
import { GungBase, Product, ProductMultiDimension } from '../models';

export function isMultiDimensionProduct(product: Product): boolean {
  const key = 'productType';
  return product?.[key] && product[key] === 'multiDimension';
}

export function getMultiDimensionModelIdOrDefaultId(product: Product): string {
  if (isMultiDimensionProduct(product)) {
    return (product as ProductMultiDimension).modelId;
  }

  return product.id;
}

export function isStructuredProduct(product: Product): boolean {
  const key = 'productType';
  return product?.[key] && product[key] === 'structured';
}

export function getExtraPimDescription(gungObject: GungBase, currentLang: string): string {
  let description = '';
  if (gungObject.extra.i18n && gungObject.extra.i18n[currentLang]) {
    description = gungObject.extra.i18n[currentLang].description || '';
  }
  if (!description && gungObject.extra.pim) {
    description = gungObject.extra.pim.description || '';
  }
  return description;
}

export function getExtraImage(gungObject: GungBase, size?: string, noFill?: boolean) {
  const fitInSize = size || '500x500';
  const fill: string = noFill ? '' : 'fill(white):background_color(white):';
  if (gungObject.extra.images && gungObject.extra.images.length > 0) {
    const image = gungObject.extra.images[0];
    return `${GungImageBaseUrl}/fit-in/${fitInSize}/filters:${fill}etag(${image.s3ETag}):format(jpeg)/${image.s3Uri}`;
  }
  if ((gungObject as any).s3Uri) {
    const image = gungObject as any;
    return `${GungImageBaseUrl}/fit-in/${fitInSize}/filters:${fill}etag(${image.s3ETag}):format(jpeg)/${image.s3Uri}`;
  }
  return GungNoImageUrl;
}
