import { Component, forwardRef, OnInit } from '@angular/core';
import { AbstractSelectionService } from 'gung-list';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';
import { WhiteboardProductListConfigService } from '../../services/whiteboard-product-list-config.service';
import { WhiteboardSelectionService } from '../../services/whiteboard-selection.service';

@Component({
  selector: 'lib-whiteboard-product-list',
  // templateUrl: './whiteboard-product-list.component.html',
  template:
    '<lib-filter-list class="whiteboard-product-list" [configService]="whiteboardProductListConfigService"></lib-filter-list>',
  styleUrls: ['./whiteboard-product-list.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useExisting: forwardRef(() => WhiteboardSelectionService)
    }
  ]
})
export class WhiteboardProductListComponent implements OnInit {
  constructor(public whiteboardProductListConfigService: WhiteboardProductListConfigService) {}

  ngOnInit(): void {}
}
