import { Directive, Input, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[libCustomHost]'
})
export class CustomHostDirective {
  @Input() name = '';

  constructor(public viewContainerRef: ViewContainerRef) {}
}
