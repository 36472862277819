import { Component, ComponentFactoryResolver, ChangeDetectorRef } from '@angular/core';
import { ListLayoutSingleComponent } from '../list-layout-single/list-layout-single.component';

@Component({
  selector: 'lib-grid-layout',
  templateUrl: './grid-layout.component.html',
  styleUrls: ['./grid-layout.component.css']
})
export class GridLayoutComponent<T> extends ListLayoutSingleComponent<T> {
  constructor(public componentFactoryResolver: ComponentFactoryResolver, public changeDetectorRef: ChangeDetectorRef) {
    super(componentFactoryResolver, changeDetectorRef);
  }
}
