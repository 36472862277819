import { first, forkJoin, mergeMap, of } from 'rxjs';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router, NavigationStart } from '@angular/router';
import { ScannedActionsSubject } from '@ngrx/store';
import { LoginComponent } from '../login.component';
import { AuthService } from '../../../services/auth/auth.service';
import { Location } from '@angular/common';
import { LoginCheckService } from '../../../services/login-check.service';
import { LoginConfigService } from '../../../services/login-config/login-config.service';
import { NavbarConfigService } from '../../../services/navbar-config/navbar-config.service';
import { BaseViewConfigService } from '../../../services/base-view-config/base-view-config.service';
import { OAuthService } from 'angular-oauth2-oidc';
import { AuthBackendService } from '../../../services/auth-backend/auth-backend.service';

@Component({
  selector: 'lib-fashion-login',
  templateUrl: './fashion-login.component.html',
  styleUrls: ['./fashion-login.component.css']
})
export class FashionLoginComponent extends LoginComponent implements OnInit {
  forgotPassword: boolean;
  requestAccountEnabled: boolean;
  view: string;
  public backgroundImage: string = this.navbarConfigService.getBackgroundLoginUrl();
  // public logoUrl: string = this.navbarConfigService.getLogoUrl();
  public logoUrl: string = this.navbarConfigService.getLogoLoginUrl();

  public videoBackground: string;
  public showLanguageDropDown: boolean;
  public showInTabsEnabled: boolean;

  requestAccountComponent = this.baseViewConfigService.getRequestAccountComponent();

  constructor(
    protected authService: AuthService,
    protected formBuilder: FormBuilder,
    protected route: ActivatedRoute,
    protected router: Router,
    protected actions$: ScannedActionsSubject,
    protected location: Location,
    protected loginCheckService: LoginCheckService,
    protected loginConfigService: LoginConfigService,
    protected navbarConfigService: NavbarConfigService,
    protected baseViewConfigService: BaseViewConfigService,
    @Optional()
    protected oauth2Service: OAuthService,
    @Optional()
    @Inject('environment')
    protected environment: { [s: string]: any }
  ) {
    super(authService, formBuilder, route, router, actions$, loginCheckService, oauth2Service);
  }

  ngOnInit() {
    super.ngOnInit();

    this.forgotPassword = this.checkUrl(this.location.path());
    this.router.events.pipe(first()).subscribe((val: NavigationStart) => {
      this.forgotPassword = this.checkUrl(val.url || (val as any).routerEvent?.url);
    });

    this.requestAccountEnabled = this.loginConfigService.showRequestAccount();
    this.view = this.forgotPassword ? 'forgot-password' : 'login';
    this.videoBackground = this.loginConfigService.getVideoBackground();
    this.showLanguageDropDown = this.loginConfigService.getShowLanguageDropDown();
    this.showInTabsEnabled = this.loginConfigService.getShowInTabsEnabled();

    this.navbarConfigService
      .getBackgroundLoginUrlObservable()
      .pipe(
        first(),
        mergeMap(backgroundImage =>
          forkJoin({
            backgroundImage: of(backgroundImage),
            logoLoginUrl: this.navbarConfigService.getLogoLoginUrlObservable().pipe(first())
          })
        )
      )
      .subscribe(({ backgroundImage, logoLoginUrl }) => {
        if (backgroundImage) {
          this.backgroundImage = backgroundImage;
        }
        if (logoLoginUrl) {
          this.logoUrl = logoLoginUrl;
        }
      });
  }

  protected checkUrl = (url: string): boolean => {
    if (url && url === '/forgot-password') {
      return true;
    }
    return false;
  };

  public returnToLoginFn() {
    this.view = 'login';
  }

  tabChanged(event) {
    this.view = event.nextId;
  }
}
