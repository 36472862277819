import { Component, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs';
import { Pim } from '../../../../models/pim';
import { PimUploadModalComponent } from '../../pim-upload-modal/pim-upload-modal.component';
import { PimService } from '../../../../services/pim/pim.service';

@Component({
  selector: 'lib-pim-product-documents',
  templateUrl: './pim-product-documents.component.html',
  styleUrls: ['./pim-product-documents.component.css']
})
export class PimProductDocumentsComponent implements OnInit {
  @Input()
  pimProduct: Pim;

  protected modalOptions: NgbModalOptions = {
    backdrop: true || 'static',
    size: 'lg',
    keyboard: true
  };

  protected modalRef: NgbModalRef;

  constructor(protected modalService: NgbModal, protected pimService: PimService) {}

  ngOnInit() {}

  moveElement(idx, arr: any[], pos: string) {
    const ele = arr.splice(idx, 1)[0];
    switch (pos) {
      case 'up':
        arr.splice(idx - 1, 0, ele);
        break;
      case 'down':
        arr.splice(idx + 1, 0, ele);
        break;
      case 'primary':
        arr.splice(0, 0, ele);
        break;
    }
  }

  toggleArray(ele: any, arr: any[]) {
    const idx = arr.findIndex(a => a === ele);
    if (idx !== -1) {
      arr.splice(idx, 1);
    } else {
      arr.push(ele);
    }
  }

  openModalUploadDocuments() {
    this.modalRef = this.modalService.open(PimUploadModalComponent, this.modalOptions);
    this.modalRef.componentInstance.uploadType = 'document';
    this.modalRef.componentInstance.fnUpload = (files: File[]) => this.uploadDocument(files);
    this.modalRef.result.then(_ => {});
  }

  protected uploadDocument(uploadFiles): Observable<boolean> {
    const docNumber = !!this.pimProduct.documents ? this.pimProduct.documents.length + 1 : 0;

    const productDocument = {
      index: 0,
      aclPublic: false,
      description: 'File ' + docNumber,
      category: 'Product File',
      s3Uri: '.....'
    };
    const files: File[] = uploadFiles;
    const listHttpPost = [];
    let docCount = this.pimProduct.documents.length;

    for (const file of files) {
      const formData = new FormData();
      formData.append('productDocument', JSON.stringify(productDocument));
      formData.append('file', file);
      listHttpPost.push(
        this.pimService
          .postPimDocument(this.pimProduct.id, ++docCount, formData)
          .pipe(catchError(error => of({ isError: true, error })))
      );
    }

    return forkJoin(listHttpPost).pipe(
      switchMap(data => {
        data.forEach((ele: any, idx) => {
          if (!ele.isError) {
            this.pimProduct.documents.push(ele);
          }
        });
        return of(true);
      })
    );
  }
}
