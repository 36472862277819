import { Injectable } from '@angular/core';

export interface PathWithParams {
  path: string;
  params: {};
}

@Injectable({
  providedIn: 'root'
})
export class RoutingUtilService {
  constructor() {}

  parsePathWithQueryParams(value: string): PathWithParams {
    const splittedPath = value.split('?');
    const path = splittedPath[0];
    const params = {};

    if (splittedPath.length > 1) {
      const searchParams = new URLSearchParams(splittedPath[1]);
      searchParams.forEach((v, k) => {
        params[k] = v;
      });
    }
    return {
      path,
      params
    };
  }
}