<div class="container">
  <div class="mb-4">
    <h2 class="text-center">
      <span translate>THANK_YOU_HEADER</span>
    </h2>
    <p class="text-center" translate>THANK_YOU_EMAIL_CONFIRMATION</p>
  </div>

  <div class="mb-4">
    <span translate>THANK_YOU_ORDER_SUMMARY</span>
  </div>
  <!-- <div class="mb-4">
    <span translate>PRODUCT_ID</span> - <span translate>NAME</span> x <span translate>QUANTITY</span>
  </div> -->
  <div class="row mb-2" *ngFor="let row of rows">
    <div class="col">
      <p class="text-left">{{ row.id }} - {{ row.name }}</p>
    </div>
    <div class="col">
      <p class="text-right">
        {{ row.quantity }}
      </p>
    </div>
    <div class="col-xl-1 col-sm-2">
      <p class="text-right">
        {{ row.price * row.quantity | gungCurrency: row.currency:'symbol-narrow':'1.2-2' }}
      </p>
    </div>
  </div>
  <div class="row mb-4" style="border-bottom: none">
    <div class="col">
      <p class="text-left" translate>TOTAL_PRICE</p>
    </div>
    <div class="col">
      <p class="text-right">
        {{ grandTotal | gungCurrency: currencyCode:'symbol-narrow':'1.2-2' }}
      </p>
    </div>
  </div>

  <div class="mb-4">
    <p class="text-center small" translate>
      THANK_YOU_QUESTIONS<a href="mailto:mousquetaires.info@hl-display.com"> mousquetaires.info&#64;hl-display.com</a>
    </p>
  </div>
</div>
