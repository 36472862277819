import { Component, OnInit, Input } from '@angular/core';
import { User } from './../../../../../lib/state/auth/types';
import { UsersService } from './../../../../../lib/services/users/users.service';
interface UserRole {
  id: string;
  selected: boolean;
}

@Component({
  selector: 'lib-user-details-roles',
  templateUrl: './user-details-roles.component.html',
  styleUrls: ['./user-details-roles.component.css']
})
export class UserDetailsRolesComponent implements OnInit {
  @Input()
  currentUser: User;

  userRoles: any[];

  constructor(protected usersService: UsersService) {}

  ngOnInit() {
    this.usersService.getDefaultUserRoles().subscribe(roles => {
      this.userRoles = roles;
      for (const role of this.currentUser.roles) {
        const roleDefinedFromService = this.userRoles.find(x => x.id === role);
        if (roleDefinedFromService !== undefined) {
          roleDefinedFromService.selected = true;
        } else {
          // User has a role on it which does not exist in usersService, add it here to allow removal of the role
          this.userRoles.push({ selected: true, id: role });
        }
      }
    });
  }

  toogleRole(id: string, selected: boolean): void {
    this.userRoles.find(role => role.id === id).selected = !selected;
    // if was selected then remove it roles collection
    if (selected) {
      const indexOf = this.currentUser.roles.indexOf(id);
      this.currentUser.roles.splice(indexOf, 1);
    } else {
      this.currentUser.roles.push(id);
    }
  }
}
