import { AfterViewInit, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseInputDirective } from '../base-input/base-input.directive';

@Component({
  selector: 'lib-gung-input-dropdown-filter',
  templateUrl: './gung-input-dropdown-filter.component.html',
  styleUrls: ['./gung-input-dropdown-filter.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungInputDropdownFilterComponent),
      multi: true
    }
  ]
})
export class GungInputDropdownFilterComponent extends BaseInputDirective<string> implements OnInit, AfterViewInit {

  @Input()
  list: any[];

  @Output()
  onSelected: EventEmitter<File[]> = new EventEmitter<File[]>();

  @Input()
  formatterView = (item) => { return item };

  @Input()
  formatter = (item) => { return item };

  @Input()
  filterOption = (searchTerm, item) => {
    let hasHitAllTerms = true;
    const queryTerms = searchTerm.split(' ');
    const terms = [`${this.formatter(item)}]`];
    queryTerms.forEach(queryTerm => {
      const locatedTerm = terms.find(term => {
        if (term === null || term === undefined) {
          return false;
        }
        return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
      });

      hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
    });

    return hasHitAllTerms;
  };

  public searchString: string = '';
  public filteredList: any[];
  public dropdownIsOpen: boolean = false;
  public selectedItem: any;
  public viewValue: string;

  set value(value: string) {
    super.value = value;
    this.viewValue = this.value;
    if (this.value && this.list) {
      this.selectedItem = this.list.find(l => this.value === this.formatter(l));
      if (this.selectedItem) {
        this.viewValue = this.formatterView(this.selectedItem)
      }
    }
  }

  get value() {
    return super.value;
  }

  constructor() {
    super();
  }
  ngAfterViewInit(): void {
    
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.filteredList = this.list;
  }
  

  setSearch(search: string) {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.filteredList = this.list;
      return;
    }

    this.filteredList = this.filterBySearchTerm(search, this.list);
  }

  filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => this.filterOption(searchTerm, item));
  }

  selected(item) {
    this.value = this.formatter(item);
    this.viewValue = this.formatterView(item);
    this.onSelected.emit(item);
    // (click)="submitForm.get('customerId').setValue(customer.id);submitForm.get('customerName').setValue(customer.name); submitForm.get('customer').setValue(customer.name); $event.stopPropagation(); myDrop.close()"
  }
}
