<div class="container">
  <h3 translate class="page-title text-center">GUNG_FLOWS</h3>
  <div class="row pb-4">
    <div class="col-12">
      <button type="button" class="btn btn-primary btn-block" (click)="openCreateFlow()">
        {{ 'CREATE_NEW_GUNG_FLOW' | translate }}
      </button>
    </div>
  </div>

  <div class="row">
    <div class="col-12">
      <lib-filter-list [configService]="gungFlowsListConfigService"></lib-filter-list>
    </div>
  </div>
</div>
