import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { OrderAnalyticsConfigService } from '../../services/order-analytics-config/order-analytics-config.service';
import { OrderFinderConfigService } from '../../services/order-finder-config/order-finder-config.service';

@Component({
  selector: 'lib-customer-order-analytics',
  templateUrl: './customer-order-analytics.component.html',
  styleUrls: ['./customer-order-analytics.component.css']
})
export class CustomerOrderAnalyticsComponent {
  constructor(public orderAnalyticsConfigService: OrderAnalyticsConfigService, public orderFinderConfigService: OrderFinderConfigService, route: ActivatedRoute) {
    orderAnalyticsConfigService.setCustomerId(route.snapshot.params.customerId);
    orderFinderConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
