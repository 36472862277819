import { QuantityPayload } from '../actions';
import { getRowFromRows, filterOutRow } from './common';
import { CartRow } from '../types';

export const subtractReducer = (current: CartRow[], payload: QuantityPayload): CartRow[] => {
  const currentDate = new Date();
  const { productId, targetStockId, qty, productPartialId } = payload;
  const oldRow = getRowFromRows(current, productId, targetStockId, productPartialId);
  if (!oldRow) {
    return current;
  }
  // filter out the row to remove quantities for
  const currentFiltered = current.filter(r => filterOutRow(r, productId, targetStockId, productPartialId));
  const newRow = {
    ...oldRow,
    qty: Math.round((oldRow.qty - qty + Number.EPSILON) * 1000) / 1000,
    timeUpdated: currentDate
  };

  return newRow.qty > 0 ? [...currentFiltered, newRow] : currentFiltered;
};
