/* eslint-disable no-underscore-dangle */
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CartState, featureName, CartRow } from './types';

interface PartialAppState {
  [featureName]: CartState;
}

export const selectCart = createFeatureSelector<CartState>(featureName);
const cartRowComparator = (c1: CartRow, c2: CartRow): number => c1.timeAdded.getTime() - c2.timeAdded.getTime();

export const selectAllCartContents = createSelector(selectCart, (state: CartState) =>
  [...state.rows].sort(cartRowComparator)
);

export const selectAllCartContentsWithoutSort = createSelector(selectCart, (state: CartState) =>
  state.rows
);

export interface ByTargetStockProps {
  targetStockId: string;
}

const byTargetStockProjector = (props: ByTargetStockProps) => (state: CartState) =>
  [...state.rows.filter(r => r.targetStockId === props.targetStockId)].sort(cartRowComparator);

export const selectCartContentsByTargetStock = (props: ByTargetStockProps) =>
  createSelector(selectCart, byTargetStockProjector(props));

interface ByProductIdProps {
  productId: string;
}

const byProductIdSingleProjector = (props: ByProductIdProps) => (state: CartState) =>
  state.rows.find(r => r.productId === props.productId && !r.productPartialId);

export const selectCartRowByProductId = (props: ByProductIdProps) =>
  createSelector(selectCart, byProductIdSingleProjector(props));

interface ByProductIdProductPartialIdProps {
  productId: string;
  productPartialId: string;
}

const byCartRowProductIdProductPartialIdProjector = (props: ByProductIdProductPartialIdProps) => (state: CartState) =>
  state.rows.find(r => r.productId === props.productId && r.productPartialId === props.productPartialId);

export const selectCartRowByProductIdProductPartialId = (props: ByProductIdProductPartialIdProps) =>
  createSelector(selectCart, byCartRowProductIdProductPartialIdProjector(props));

export const selectCartRowsByProductId = (props: ByProductIdProps) =>
  createSelector(selectCart, (state: CartState) => state.rows.filter(r => r.productId === props.productId));

export const selectCartRowByExtraPosition = createSelector(selectCart, (state: CartState) =>
  [...state.rows].sort((c1: CartRow, c2: CartRow): number => {
    if (!isNaN(c1.extra._position) && !isNaN(c2.extra._position)) {
      return c1.extra._position - c2.extra._position;
    }
    if (!isNaN(c1.extra._position)) {
      return 1;
    }
    if (!isNaN(c2.extra._position)) {
      return -1;
    }
    return 0;
  })
);
