<nav ngbNav #nav="ngbNav" class="nav-tabs">
  <ng-container ngbNavItem>
    <a ngbNavLink translate>INFO</a>
    <ng-template ngbNavContent>
      <lib-actuator-info></lib-actuator-info>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>ENVIRONMENT</a>
    <ng-template ngbNavContent>
      <lib-actuator-environment></lib-actuator-environment>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>CONFIG_RESTART</a>
    <ng-template ngbNavContent>
      <lib-config-restart></lib-config-restart>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>ORDER_CONTEXTS</a>
    <ng-template ngbNavContent>
      <lib-order-context></lib-order-context>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>SYSTEM_SETTINGS</a>
    <ng-template ngbNavContent>
      <lib-system-settings></lib-system-settings>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>CONFIG</a>
    <ng-template ngbNavContent>
      <lib-configurations-editor></lib-configurations-editor>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>CHANGE_LOG_LEVEL</a>
    <ng-template ngbNavContent>
      <lib-change-log-level></lib-change-log-level>
    </ng-template>
  </ng-container>
  <ng-container ngbNavItem>
    <a ngbNavLink translate>SHOPIFY</a>
    <ng-template ngbNavContent>
      <lib-actuator-shopify></lib-actuator-shopify>
    </ng-template>
  </ng-container>
</nav>
<div [ngbNavOutlet]="nav" class="mt-2"></div>
