import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map, first, switchMap } from 'rxjs';
import { CalendarDate } from '../../models/calender-date';
import { CartRow } from '../../state/cart/types';
import { compareAsc } from 'date-fns';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { CheckoutObject } from '../../models/checkout';

@Injectable({
  providedIn: 'root'
})
export class DeliveryDateService {
  showWeekNumbers = false;

  constructor(protected http: HttpClient, protected gungFlowService: GungFlowService) {}

  public getDeliveryDatesForCheckout(checkoutObject: CheckoutObject): Observable<CalendarDate[]> {
    const calenderDates = this.generateDatesFrom(new Date(), 365 * 2);
    return this.filterDeliveryDatesBySelectedFlow(calenderDates);
  }

  public getDeliveryDates(deliveryMethod: string, rows?: CartRow[]): Observable<CalendarDate[]> {
    const calenderDates = this.generateDatesFrom(new Date(), 365 * 2);
    return this.filterDeliveryDatesBySelectedFlow(calenderDates);
  }

  protected generateDatesFrom(date: Date, days: number) {
    const dates: CalendarDate[] = [];
    for (let i = 0; i < days; i++) {
      const day = new Date(date.getTime());
      day.setDate(date.getDate() + i);
      if (day.getDay() === 0 || day.getDay() === 6) {
        dates.push({ valid: false, invalidReason: null, date: day });
      } else {
        dates.push({ valid: true, invalidReason: null, date: day });
      }
    }
    return dates;
  }

  findFirstAvailableDate(calendarDates: CalendarDate[], fromDate?: Date): CalendarDate {
    return calendarDates.find(calendarDate => {
      if (!calendarDate.valid) {
        return false;
      }

      if (fromDate) {
        return calendarDate.date >= fromDate;
      }

      return true;
    });
  }

  protected filterDeliveryDatesBySelectedFlow(calendarDates: CalendarDate[]): Observable<CalendarDate[]> {
    // GET deliveryStartDate and deliveryEndDate from selected flow

    // IN CASE deliveryStartDate is defined THEN the lower date will be the deliveryStartDate
    // IN CASE deliveryEndDate is defined THEN the upper date will be the deliveryEndDate
    return this.gungFlowService.getSelectedFlow().pipe(
      first(),
      switchMap(selectedFlow => this.gungFlowService.getGungFlow(selectedFlow.id)),
      map(flow => {
        if (!!flow.deliveryStartDate && !!flow.deliveryEndDate) {
          const deliveryStartDateValue = new Date(flow.deliveryStartDate);
          const deliveryEndDateValue = new Date(flow.deliveryEndDate);

          return calendarDates.filter(
            calendarDate =>
              compareAsc(calendarDate.date, deliveryStartDateValue) >= 0 &&
              compareAsc(calendarDate.date, deliveryEndDateValue) <= 0
          );
        }

        if (!!flow.deliveryStartDate) {
          const deliveryStartDateValue = new Date(flow.deliveryStartDate);
          return calendarDates.filter(calendarDate => compareAsc(calendarDate.date, deliveryStartDateValue) >= 0);
        }

        if (!!flow.deliveryEndDate) {
          const deliveryEndDateValue = new Date(flow.deliveryEndDate);
          return calendarDates.filter(calendarDate => compareAsc(calendarDate.date, deliveryEndDateValue) <= 0);
        }

        return calendarDates;
      })
    );
  }

  public checkIfDateInDeliveryDates(checkDate: Date, deliveryMethod?: string): Observable<Date | boolean> {
    return this.getDeliveryDates(deliveryMethod).pipe(
      map(calenderDates => {
        const validDate = calenderDates.filter(d => d.valid);
        const findDate = validDate.map(d => d.date.getTime()).find(t => t === checkDate.getTime());
        // console.log('findDate', findDate);
        return new Date(findDate) || false;
      })
    );
  }
}
