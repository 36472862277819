import { Component, OnInit } from '@angular/core';
import { ConfigurationsService } from '../../services/configurations.service';
import { catchError, first, of } from 'rxjs';
import { GungNotificationService } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-order-confirm-configuration',
  templateUrl: './order-confirm-configuration.component.html',
  styleUrls: ['./order-confirm-configuration.component.css']
})
export class OrderConfirmConfigurationComponent implements OnInit {
  private readonly configurationId = 'OrderConfirmDbConfig';

  orderConfirmConfiguration: OrderConfirmConfiguration;

  constructor(
    protected configurationService: ConfigurationsService,
    protected gungNotificationService: GungNotificationService,
    protected translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.configurationService
      .getConfigurationsByIds([this.configurationId])
      .pipe(first())
      .subscribe(configuration => {
        if (!configuration) {
          return;
        }

        this.orderConfirmConfiguration = configuration[0];
      });
  }

  save(): void {
    this.configurationService
      .updateConfiguration(this.orderConfirmConfiguration)
      .pipe(
        first(),
        catchError((err, caught) => {
          this.gungNotificationService.notify(
            this.translateService.instant('ERROR_SAVING_CONFIGURATION'),
            this.translateService.instant('ERROR_SAVING_ORDER_CONFIRM_CONFIGURATION_MESSAGE'),
            'error'
          );
          return of(null);
        })
      )
      .subscribe(res => {
        if (!res) {
          return;
        }
        this.gungNotificationService.notify(
          this.translateService.instant('CONFIGURATION_SAVED'),
          this.translateService.instant('ORDER_CONFIRM_CONFIGURATION_SAVED_MESSAGE'),
          'success'
        );
      });
  }
}

interface OrderConfirmConfiguration {
  orderConfirmBCCList: string[];
}
