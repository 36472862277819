import { Component, OnInit } from '@angular/core';
import { FormArray } from '@angular/forms';
import { SearchRequest } from 'gung-list';
import { debounceTime, distinctUntilChanged, first, forkJoin, map, Observable, of, OperatorFunction, switchMap } from 'rxjs';
import { Product } from '../../../models';
import { ActuatorService } from '../../../services/actuator/actuator.service';

@Component({
  selector: 'lib-change-log-level',
  templateUrl: './change-log-level.component.html',
  styleUrls: ['./change-log-level.component.css']
})
export class ChangeLogLevelComponent implements OnInit {
  changeLogLevel: string = '';
  changeService: string = '';
  logLevels = []
  logServices:string[];
  loggers: {[s: string]: {configuredLevel: string; effectiveLevel: string}};

  resultFormatter = (item: any) => item;
  searchTypeahead: OperatorFunction<string, readonly string[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term =>
        this.logServices
          .filter(s => new RegExp(term, 'mi').test(s))
          .slice(0, 10)
      )
    );

  constructor(protected actuatorService: ActuatorService) {}
  ngOnInit(): void {
    this.loadLevel();
  }

  updateLogLevel(){
    this.actuatorService.changeLogLevel(this.changeService,this.changeLogLevel).subscribe(res=>{
      this.loggers[this.changeService].configuredLevel = this.changeLogLevel;
      console.log(res)
    });
  }

  loadLevel(){
    this.actuatorService.getLogLevel().subscribe(res=>{
      this.loggers = JSON.parse(JSON.stringify(res.loggers));
      // for (const log of Object.keys(this.loggers)) {
      //   if (!this.loggers[log].configuredLevel) {
      //     delete this.loggers[log];
      //   }
      // }

      this.logServices = Object.keys(res.loggers)
      this.logLevels = res.levels
    });
  }

  searchService(event:any){
  }

  public selectItem({ item, preventDefault, target }, rowIdx: number, reasonIdx?: number) {
    // if (reasonIdx > -1) {
    //   // reason code selecte
    //   const reasonCode = this.rows.at(rowIdx).get('reasonCode') as FormArray;
    //   reasonCode.at(reasonIdx).setValue(item?.description || null);
    // } else {
    //   // product id selected
    //   this.rows
    //     .at(rowIdx)
    //     .get('id')
    //     .setValue(item?.id || null);
    // }
  }
}
