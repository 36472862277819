<div class="row">
  <div class="col-12 col-md-6">
    <lib-gung-text-input-horizontal
      gungTranslate="CATERGORY_CODES"
      tooltip="JETSHOP_CATEGORY_TOOLTIP"
      [(ngModel)]="master.extra.pim.jetshop.categories"
    ></lib-gung-text-input-horizontal>
  </div>
</div>

<div class="row mt-2">
  <div class="col">
    <nav ngbNav #nav="ngbNav" class="nav-tabs">
      <ng-container ngbNavItem>
        <a ngbNavLink translate>VARIANTS</a>
        <ng-template ngbNavContent>
          <table class="table table-hover">
            <thead>
              <tr>
                <th>
                  <span translate>ACTIVE</span>
                </th>
                <th>
                  <span translate>HIDE</span>
                </th>
                <th>
                  <span translate>COLOR_ID</span>
                </th>
                <th>
                  <span translate>COLOR_NAME</span>
                </th>
              </tr>
            </thead>
            <tbody *ngFor="let variant of variants">
              <tr>
                <td>
                  <input type="checkbox" [(ngModel)]="variant.extra.pim.buyerGroups.JETSHOP" />
                </td>
                <td>
                  <input type="checkbox" [(ngModel)]="variant.extra.pim.jetshop.hidden" />
                </td>
                <td>
                  {{ variant.id }}
                </td>
                <td>
                  <span>{{ colorCodes[variant.variantDefinition['colors']].name }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </ng-container>

      <ng-container ngbNavItem *ngFor="let lang of languages">
        <a ngbNavLink><span translate>DETAILS</span> {{ lang | uppercase }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-6">
              <h4 translate>BASIC</h4>

              <lib-gung-text-input-horizontal
                gungTranslate="NAME"
                tooltip="JETSHOP_NAME_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.name"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="DIMENSION_NAME"
                tooltip="JETSHOP_DIMENSION_NAME_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.primaryDimensionProperty"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-area-input-horizontal
                gungTranslate="DESCRIPTION"
                tooltip="JETSHOP_DESCRIPTION_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.description"
              ></lib-gung-text-area-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="SHORT_DESCRIPTION"
                tooltip="JETSHOP_SHORT_DESCRIPTION_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.shortDescription"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="META_KEYWORDS"
                tooltip="JETSHOP_META_KEYWORDS_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.metaKeywords"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="META_DESCRIPTION"
                tooltip="JETSHOP_META_DESCRIPTION_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.metaDescription"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="PAGE_TITLE"
                tooltip="JETSHOP_PAGE_TITLE_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.pageTitle"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="MAIN_HEADER"
                tooltip="JETSHOP_MAIN_HEADER_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.mainHeader"
              ></lib-gung-text-input-horizontal>

              <lib-gung-text-input-horizontal
                gungTranslate="MOBILE_DESCRIPTION"
                tooltip="JETSHOP_MOBILE_DESCRIPTION_TOOLTIP"
                [(ngModel)]="master.i18n[lang].jetshop.mobileDescription"
              ></lib-gung-text-input-horizontal>
            </div>
            <div class="col-6">
              <h4 translate>TEMPLATE</h4>
            </div>
          </div>
        </ng-template>
      </ng-container>
    </nav>

    <div [ngbNavOutlet]="nav" class="mt-2"></div>
  </div>
</div>
