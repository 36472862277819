import { Component, OnInit } from '@angular/core';
import { GungModalService } from '../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-gung-data-export-confirm-modal',
  templateUrl: './gung-data-export-confirm-modal.component.html',
  styleUrls: ['./gung-data-export-confirm-modal.component.css']
})
export class GungDataExportConfirmModalComponent implements OnInit {
  email = '';
  delegate: GungModalService;
  componentId: string;

  constructor() {}

  ngOnInit() {
    this.componentId = GungDataExportConfirmModalComponent.name;
  }

  confirm() {
    this.delegate.modalRef.get(this.componentId).close({ email: this.email });
  }

  cancel() {
    this.delegate.modalRef.get(this.componentId).close({ email: '' });
  }
}
