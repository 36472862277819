import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { GungTextInputComponent } from '../gung-text-input/gung-text-input.component';

@Component({
  selector: 'lib-gung-checkbox-input-horizontal',
  templateUrl: './gung-checkbox-input-horizontal.component.html',
  styleUrls: ['./gung-checkbox-input-horizontal.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungCheckboxInputHorizontalComponent),
      multi: true
    }
  ]
})
export class GungCheckboxInputHorizontalComponent extends GungTextInputComponent {
  @Input()
  public labelClass: string | string[];

  @Input()
  public divClass: string | string[];

  @Input()
  public useYesNo = false;
}
