import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';

@Component({
  selector: 'lib-delete-saved-card',
  templateUrl: './delete-saved-card.component.html',
  styleUrls: ['./delete-saved-card.component.css']
})
export class DeleteSavedCartModalComponent {
  
  @Input() delete;
  deleteString: string;

  constructor(
    public activeModal: NgbActiveModal,
    public dateUtilService: DateUtilService,
    public translationService: TranslateService
  ) {
    this.deleteString = this.translationService.instant('CONFIRM_DELETION', { value: '' }) + '?'
  }

}
