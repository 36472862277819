<div class="row">
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12" *ngFor="let drop of curentGungFlow.drops">
    <div class="row">
      <div class="col-12">
        <h2>{{ drop.id }}: {{ drop.name }}</h2>
      </div>
      <div class="col-12">
        <lib-gung-text-input gungTranslate="DROP_NAME" [(ngModel)]="drop.name" [placeholder]="'DROP_NAME' | translate">
        </lib-gung-text-input>
      </div>
      <div class="col-12">
        <lib-delivery-date-picker
          id="flow-drop-date"
          [label]="'FIRST_DELIVERY_DATE'"
          [initialDate]="drop.firstDeliveryDate"
          (selectedDate)="dateSelected($event, drop)"
        >
        </lib-delivery-date-picker>
      </div>
    </div>
  </div>
  <div class="col-xl-3 col-lg-4 col-md-6 col-sm-12 d-flex justify-content-center align-items-center">
    <i class="fal fa-plus fa-7x add-button" (click)="addDrop()"></i>
  </div>
</div>
