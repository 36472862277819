import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../../services/auth/auth.service';
import { first, map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {
  constructor(protected authService: AuthService, private router: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.authService.getRoles().pipe(
      first(),
      map(roles => {
        if (roles.includes('ADMIN')) {
          return true;
        }

        // redirect to home
        this.router.navigate(['']);
        return false;
      })
    );
  }
}
