<div class="pt-3 row">
  <div class="col-12 col-md-2">
    <div
      class="form-check pt-1 d-flex align-items-center justify-content-between"
      *ngFor="let role of currentUser.roles"
    >
      <label class="form-check-label" [for]="'remove_' + role">
        {{ role | translate | titlecase }}
      </label>
      <button [id]="'remove_' + role" type="button" class="btn btn-link p-1" (click)="toogleRole(role, true)">
        <i class="fas fa-times"></i>
      </button>
    </div>
    <hr *ngIf="currentUser.roles.length > 0" />
    <div
      class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
      *ngFor="let role of userRoles"
      [class.d-none]="role.selected"
    >
      <label class="form-check-label" [for]="role.id">
        {{ role.id | translate | titlecase }}
      </label>
      <input
        type="checkbox"
        class="scale18"
        (change)="toogleRole(role.id, role.selected)"
        [id]="role.id"
        [checked]="role.selected"
      />
    </div>
  </div>
</div>
