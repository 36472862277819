<div *ngIf="brandForm; else loadingForm">
  <form class="form" [formGroup]="brandForm" (ngSubmit)="onSubmit()">
    <div class="modal-body">
      <div class="row">
        <div class="col-6">
          <lib-gung-text-input
            gungTranslate="ID"
            [formControl]="brandForm.controls['id']"
            [placeholder]="'ID' | translate"
            [renderStatic]="edit"
          >
          </lib-gung-text-input>
        </div>
        <div class="col-6">
          <lib-gung-text-input
            gungTranslate="NAME"
            [formControl]="brandForm.controls['name']"
            [placeholder]="'NAME' | translate"
          >
          </lib-gung-text-input>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <ngb-alert class="mt-2" *ngIf="alertMessage" [type]="alertMessage.type" (close)="alertMessage = null">
            {{ alertMessage.message | translate }}
          </ngb-alert>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="row w-100">
        <div class="col d-flex pl-0">
          <button
            type="button"
            [disabled]="loadingDeleteConcept"
            class="btn btn-primary btn-sm text-uppercase"
            (click)="goToConceptBrandsList()"
            translate
          >
            RELATED_BRANDS
          </button>
        </div>
        <div class="col d-flex justify-content-end pr-0">
          <button
            type="button"
            *ngIf="edit"
            [disabled]="loadingDeleteConcept"
            class="btn btn-primary btn-sm ml-2 text-uppercase"
            (click)="deleteConceptBrand()"
            translate
          >
            DELETE
            <i *ngIf="loadingDeleteConcept" class="fa fa-spinner fa-spin fa-lg"></i>
          </button>
          <button
            type="submit"
            [disabled]="loadingsubmitConcept || !brandForm.valid"
            class="btn btn-primary btn-sm ml-2 text-uppercase"
            translate
          >
            {{ edit ? 'SAVE' : 'CREATE' }}
            <i *ngIf="loadingsubmitConcept" class="fa fa-spinner fa-spin fa-lg"></i>
          </button>
          <!-- <button type="button" [disabled]="!brandForm.valid || !edit"
                        class="btn btn-primary btn-sm ml-2 text-uppercase" (click)="openUploadImagesModal()" translate>
                        UPLOAD IMAGES
                    </button> -->
        </div>
      </div>
    </div>
  </form>

  <div *ngIf="brandForm.valid && conceptBrand && conceptBrand.images">
    <div class="row mb-3">
      <div class="col-12">
        <h4 translate>DIGITAL_ASSETS_IMAGES</h4>
        <div
          class="panel drop-box ng-pristine ng-untouched ng-valid ng-empty dragover"
          style="
            background: #ffffff;
            border: 5px dashed #ddd;
            text-align: center;
            padding: 50px 10px;
            margin-left: 10px;
          "
          (dragover)="onDragOver($event)"
          (dragleave)="onDragLeave($event)"
          (drop)="onDrop($event, 'images')"
          (click)="fileInput.click()"
        >
          <label>
            <strong>Add file(s) by clicking on</strong>
            <span class="box__dragndrop"> or dragging to this area</span>.
          </label>
          <br />
          <span>
            <small class="" *ngFor="let file of fileInput.files || fileInput.files; let last = last">
              {{ file?.name }}{{ !last ? ', ' : '' }}
            </small>
          </span>
          <input
            hidden
            #fileInput
            type="file"
            name="files[]"
            id="file"
            (change)="onDrop($event, 'images')"
            accept="image/*,application/pdf"
            multiple
          />
        </div>
      </div>
    </div>
  </div>

  <div
    class="row"
    *ngIf="conceptBrand && conceptBrand.images"
    [ngClass]="{ 'mt-4 mb-4': conceptBrand.images.length > 0 }"
  >
    <div
      *ngFor="let image of conceptBrand.images; let first = first; let last = last; let index = index"
      class="col-6 col-md-4 col-lg-3 mb-2 mt-2"
    >
      <div class="card concept-card">
        <div class="card-body">
          <div class="image-wrapper">
            <img
              class="card-img-top img-fluid mx-auto d-block"
              *ngIf="image.s3ETag && image.s3Uri; else noimage"
              [src]="image.s3Uri
                | gungImageUrl
                  :'type:list'
                  :'etag:'+image.s3ETag
              "
              alt=""
            />
            <ng-template #noimage>
              <img
                class="card-img-top img-fluid mx-auto d-block"
                [src]="'images/no-image.jpg' | gungImageUrl"
                alt="NO IMAGE"
              />
            </ng-template>
          </div>
          <div class="brand-content">
            <div class="text-truncate" translate>{{ image.name }}</div>
            <div>{{ image.id }}</div>
          </div>
          <div class="brand-content-buttons">
            <button type="button" class="btn btn-danger" (click)="removeElement(image, index)" translate>DELETE</button>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="uploadingConceptFiles" class="col-6 col-md-4 col-lg-3 mb-2 mt-2">
      <div class="card concept-card">
        <div class="card-body">UPLOADING...</div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingForm>
  <i class="fa fa-spinner fa-spin fa-7x"></i>
  LOADING...
</ng-template>
