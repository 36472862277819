import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyTemplate } from '../models/company-template';
import { BehaviorSubject, Observable, switchMap, tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyTemplatesService {
  protected baseUrl: string = 'json/feature/company-templates';
  protected companyTemplatesSubject: BehaviorSubject<CompanyTemplate[]>;

  constructor(protected http: HttpClient) {}

  getAllCompanyTemplates(nocache?: boolean): Observable<CompanyTemplate[]> {
    if (!this.companyTemplatesSubject) {
      return this.updateCompanyTemplatesSubject(nocache).pipe(
        switchMap(() => this.companyTemplatesSubject.asObservable())
      );
    }
    return this.companyTemplatesSubject.asObservable();
  }

  getInternalAllCompanyTemplates(nocache?: boolean): Observable<CompanyTemplate[]> {
    const headers = {
      maxAge: nocache ? '-1' : '300'
    };
    return this.http.get<CompanyTemplate[]>(`${this.baseUrl}`, { headers });
  }

  updateCompanyTemplatesSubject(nocache?: boolean): Observable<CompanyTemplate[]> {
    return this.getInternalAllCompanyTemplates(nocache).pipe(
      tap(templates => {
        if (!this.companyTemplatesSubject) {
          this.companyTemplatesSubject = new BehaviorSubject<CompanyTemplate[]>(templates);
        } else {
          this.companyTemplatesSubject.next(templates);
        }
      })
    );
  }

  getCompanyTemplate(id: string): Observable<CompanyTemplate> {
    return this.http.get<CompanyTemplate>(`${this.baseUrl}/${id}`);
  }

  saveCompanyTemplate(template: CompanyTemplate): any {
    return this.http
      .post<CompanyTemplate>(`${this.baseUrl}`, template)
      .pipe(tap(_ => this.updateCompanyTemplatesSubject(true).subscribe(_ => {})));
  }

  deleteCompanyTemplate(id: string): any {
    return this.http
      .delete<CompanyTemplate>(`${this.baseUrl}/${id}`)
      .pipe(tap(_ => this.updateCompanyTemplatesSubject(true).subscribe(_ => {})));
  }
}
