import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { filter, Observable } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { map, mergeMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AssortmentService {
  constructor(protected http: HttpClient, protected authService: AuthService) {}

  getAssortment(assortmentId: string): Observable<Assortment> {
    return this.http
      .get<Assortment>('json/assortment/' + assortmentId)
      .pipe(map(assortment => this.mapAssortment(assortment)));
  }

  getUserAssortment(): Observable<Assortment> {
    return this.authService.getCurrentUser().pipe(
      filter(user => !!user),
      mergeMap(user => {
        return this.getAssortment(user.assortment);
      }),
      map(assortment => this.mapAssortment(assortment))
    );
  }

  getUserAssortmentFlattened(): Observable<Assortment> {
    return this.authService.getCurrentUser().pipe(
      mergeMap(user => {
        return this.getAssortmentFlattened(user.assortment);
      }),
      map(assortment => this.mapAssortment(assortment))
    );
  }

  getAssortmentFlattened(assortmentId: string): Observable<Assortment> {
    return this.http
      .get<Assortment>('json/assortment-flattened/' + assortmentId)
      .pipe(map(assortment => this.mapAssortment(assortment)));
  }

  getAssortmentRecursive(assortmentId: string): Observable<Assortment> {
    return this.http
      .get<Assortment>('json/assortment-recursive/' + assortmentId)
      .pipe(map(assortment => this.mapAssortment(assortment)));
  }

  getAssortmentNoFilter(assortmentId: string): Observable<Assortment> {
    return this.http
      .get<Assortment>('json/assortment-nofilter/' + assortmentId)
      .pipe(map(assortment => this.mapAssortment(assortment)));
  }

  getPimAssortmentData(assortmentId: string): Observable<Assortment> {
    return this.http
      .get<Assortment>('json/assortment-nochildren/' + assortmentId)
      .pipe(map(assortment => this.mapAssortment(assortment)));
  }

  protected mapAssortment(assortment: Assortment): Assortment {
    return assortment;
  }

  isGroupedAssortment(assortment: AssortmentRecursiveExtended) {
    const itemPropertiesPimCollection = assortment.extra.itemPropertiesPimCollection || '';
    const childPimCollection = assortment.extra.pim ? assortment.extra.pim.childPimCollection : '';
    const res = itemPropertiesPimCollection === 'pim-item' && childPimCollection === 'pim-item-concept';
    return res;
  }
}

export interface Assortment {
  id: string;
  name: string;
  assortment?: boolean;
  extra?: any;
  children: Assortment[];
}

export interface AssortmentRecursive extends Assortment {
  children: (Assortment | AssortmentRecursive)[];
  parent?: string[];
  extra?: { [s: string]: any } | null;
}

export interface AssortmentRecursiveExtended extends AssortmentRecursive {
  dynamicCollumnsValues?: string[];
}
