<div class="modal-container">
  <h2 class="title text-center" translate>{{modalTitle}}</h2>

  <lib-gung-map 
  [data]="data"
  [options]="mapOptions"
  ></lib-gung-map>

  <div class="modal-footer">
    <button type="button" class="btn btn-sm btn-default btn-block btn-primary" (click)="closeModal()">
        {{ 'CLOSE' | translate | uppercase }}
    </button>
  </div>
</div>

