import { Component, Input, OnInit } from '@angular/core';
import { GungSlidesService } from '../../../../services/gung-slides/gung-slides.service';
import { CustomPageConfigService } from '../../../../services/custom-page/custom-page-config.service';
import { BackendInterceptor } from '../../../../services/backend-interceptor/backend-interceptor.service';
import { GungFlow } from '../../../../state/flow/types';
import { GungSlider } from 'gung-list';
import { gungAddRemoveSpinner } from '../../../../utils/gung-utils';

@Component({
  selector: 'lib-gung-flow-details-slider',
  templateUrl: './gung-flow-details-slider.component.html',
  styleUrls: ['./gung-flow-details-slider.component.css']
})
export class GungFlowDetailsSliderComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;

  public baseUrl = this.backendInterceptor.getBaseUrl();
  uploading = false;

  constructor(
    protected customPagesService: CustomPageConfigService,
    protected gungSlidesService: GungSlidesService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  ngOnInit(): void {
    if (!this.curentGungFlow.extra.slider) {
      this.curentGungFlow.extra.slider = [];
    }
    this.changeSlider();
    // this.gungSlidesService.getGungSlides().subscribe(slides => this.curentGungFlow.extra.slider = slides);
  }

  changeSlider() {
    this.curentGungFlow.extra.slider = this.curentGungFlow.extra.slider.map(s => {
      const slide: GungSlider = {
        id: s.id,
        title: undefined,
        description: undefined,
        innerHTML: undefined,
        button: undefined,
        link: s.link,
        background: s.background,
        customClass: 'gung-image-only'
      };
      return slide;
    });
  }

  onFileChange(event, row: GungSlider, element) {
    if (this.uploading) {
      return;
    }
    gungAddRemoveSpinner(element);
    this.uploading = true;
    this.uploadSlidesImage(event, row, element);
  }

  uploadSlidesImage(event, row: GungSlider, element) {
    const filesToSubmit = event.target.files;
    const fd = new FormData();
    for (const file of filesToSubmit) {
      fd.append('documents', file);
    }
    const timestamp = new Date();
    this.gungSlidesService.uploadImage(fd, timestamp).subscribe(doc => {
      row.background = this.baseUrl + '/gung-document-archive/document/' + doc[0].document;
      row.id = doc[0].document;
      gungAddRemoveSpinner(element);
      this.uploading = false;
    });
  }

  addRow() {
    const slide: GungSlider = {
      id: undefined,
      title: undefined,
      description: undefined,
      innerHTML: undefined,
      button: undefined,
      link: undefined,
      background: undefined,
      customClass: 'gung-image-only'
    };
    this.curentGungFlow.extra.slider.push(slide);
  }

  moveElement(arr: any[], oldIndex: number, newIndex: number) {
    arr.splice(newIndex, 0, ...arr.splice(oldIndex, 1));
  }

  removeElement(arr: any[], index) {
    arr.splice(index, 1);
  }
}
