import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { switchMap } from 'rxjs';
import { GungBase } from '../../models/base';

export interface GungSlide extends GungBase {
  imgUrl: string;
  link: string;
}

@Injectable({
  providedIn: 'root'
})
export class GungSlidesService {
  // private slides: Subject<GungSlide[]>;
  private slides: BehaviorSubject<GungSlide[]>;

  constructor(protected http: HttpClient) {}

  private internalGetGungSlides(): Observable<GungSlide[]> {
    const url = 'json/gung-slides';
    const headers = { maxAge: '-1' };
    return this.http.get<GungSlide[]>(url, { headers });
  }

  getGungSlides(noCache?: boolean): Observable<GungSlide[]> {
    if (!this.slides) {
      // this.slides = new ReplaySubject<GungSlide[]>();
      // noCache = true;
      return this.internalGetGungSlides().pipe(
        switchMap(slides => {
          this.slides = new BehaviorSubject<GungSlide[]>(slides);
          return this.slides;
        })
      );
    }
    if (noCache) {
      this.internalGetGungSlides().subscribe(slides => this.slides.next(slides));
    }
    return this.slides.asObservable();
  }

  uploadImage(fd: FormData, timestamp) {
    const url = 'import/documents/SLIDES/0'; // ${timestamp}
    return this.http.post<any[]>(url, fd);
  }
}
