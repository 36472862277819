import { Inject, Injectable, Optional } from '@angular/core';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { parseISO, isDate, parse, format, differenceInDays, differenceInHours, differenceInMinutes, differenceInMonths, differenceInSeconds, differenceInWeeks, differenceInYears } from 'date-fns';
import sv from 'date-fns/locale/sv';
import { GungLanguageConfigService } from '../gung-language-config/gung-language-config.service';
import { CommonNavigationConfig } from '../../models/common-navigation-config';

@Injectable({
  providedIn: 'root'
})
export class DateUtilService {
  protected currentLang = this.translateService.currentLang;
  public dateFormat: string = this.languageConfigService.getLanguages().find(lang => lang.short === this.currentLang)?.dateFormat || 'yyyy-MM-dd'; 
  public inputFormat = 'yyMMdd';
  public allowedDateFormats = [this.inputFormat, this.dateFormat, 'yyyy-MM-dd', 'yy-MM-dd', 'dd-MM-yyyy', 'yyy-MM-dd', 'y-MM-dd'];
  constructor(
    protected translateService: TranslateService,
    protected languageConfigService: GungLanguageConfigService,
    @Optional()
    @Inject('environment')
    protected environment: CommonNavigationConfig
  ) { }

  public getFormattedDateString(date: Date, dateFormat?: string): string {
    if (typeof date === 'string') {
      date = this.parseDate(date);
    }
    dateFormat = dateFormat || this.dateFormat;

    let res = format(date, dateFormat);

    if (res === '1999-12-31') {
      res = '2099-12-31';
    }

    return res;
    let dateString = '';
    dateString = dateString + date.getFullYear();

    const month = '' + (date.getMonth() + 1);
    if (month.length === 1) {
      dateString = dateString + '-' + '0' + month;
    } else {
      dateString = dateString + '-' + month;
    }

    const day = '' + date.getDate();
    if (day.length === 1) {
      dateString = dateString + '-' + '0' + day;
    } else {
      dateString = dateString + '-' + day;
    }

    return dateString;
  }

  /**
   * A funciton that compares dates on year / month / day values
   * @param date1 Date object 1
   * @param date2 Date object 2
   */
  public compareDates(date1: Date, date2: Date): number {
    const date1String = this.getFormattedDateString(date1);
    const date2String = this.getFormattedDateString(date2);

    return date1String.localeCompare(date2String);
  }

  public getDifference(a: Date, b: Date, unitOfTime: 'years' | 'months' | 'weeks' | 'days' | 'hours' | 'minutes' | 'seconds') {
    switch (unitOfTime) {
      case 'years':
          return differenceInYears(a, b);
      case 'months':
          return differenceInMonths(a, b);
      case 'weeks':
          return differenceInWeeks(a, b);
      case 'days':
          return differenceInDays(a, b);
      case 'hours':
          return differenceInHours(a, b);
      case 'minutes':
          return differenceInMinutes(a, b);
      case 'seconds':
          return differenceInSeconds(a, b);
      default:
          throw new Error('Invalid unit of time');
  }
  }

  public createDateFromNgbDate(ngbDate: NgbDate | NgbDateStruct): Date {
    const year = '' + ngbDate.year;
    let month = '' + ngbDate.month;
    let day = '' + ngbDate.day;

    if (month.length === 1) {
      month = '0' + month;
    }

    if (day.length === 1) {
      day = '0' + day;
    }

    const dateString = `${year}-${month}-${day}`;
    let dateFromNgbDate: Date = parseISO(dateString);
    if (isDate(dateFromNgbDate) && !isNaN(dateFromNgbDate as any)){
      return dateFromNgbDate;
    }

    for (const format of this.allowedDateFormats) {
      dateFromNgbDate = parse(dateString, format, new Date());
      if (isDate(dateFromNgbDate) && !isNaN(dateFromNgbDate.getTime())) {
        return dateFromNgbDate;
      }
    }

    if (isDate(dateFromNgbDate) && !isNaN(dateFromNgbDate as any)){
      return dateFromNgbDate;
    }
    return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
  }

  public createNgbDateFromDate(date: Date): NgbDate {
    if (date.getFullYear() === 1999) {
      return new NgbDate(2099, date.getMonth() + 1, date.getDate());
    }
    return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate()); // FIX Month need +1 form JS date
  }

  public parseDate(value: any, inputFormat = this.inputFormat): Date {
    if (!value) {
      return null;
    }

    if (isDate(value)) {
      return value;
    }

    if (isDate(value?.date)) {
      return value.date;
    }

    if (value.year && value.month && value.day) {
      // NgbDate
      return new Date(value.year, value.month - 1, value.day);
    }

    let valueDate: Date;

    let options = {};
    if (inputFormat.includes('ww')) {
      options = { locale: sv, useAdditionalWeekYearTokens: true };
    }
    valueDate = parse(value, inputFormat, new Date(), options);
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return valueDate;
    }

    for (const format of this.allowedDateFormats) {
      valueDate = parse(value, format, new Date());
      if (isDate(valueDate) && !isNaN(valueDate.getTime())) {
        return valueDate;
      }
    }
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return valueDate;
    }

    valueDate = new Date(value);
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return valueDate;
    }

    valueDate = parseISO(value + '');
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return valueDate;
    }

    return new Date();
  }

  public changeDateFormat(value: any, inputFormat = this.inputFormat, outputFormat = this.dateFormat): string {
    if (!value) {
      return null;
    }

    if (isDate(value)) {
      return this.getFormattedDateString(value, outputFormat);
    }

    let valueDate: Date;

    let options = {};
    if (inputFormat.includes('ww')) {
      options = { locale: sv, useAdditionalWeekYearTokens: true };
    }
    valueDate = parse(value, inputFormat, new Date(), options);
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return this.getFormattedDateString(valueDate, outputFormat);
    }

    valueDate = new Date(value);
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return this.getFormattedDateString(valueDate, outputFormat);
    }

    valueDate = parseISO(value + '');
    if (isDate(valueDate) && !isNaN(valueDate as any)) {
      return this.getFormattedDateString(valueDate, outputFormat);
    }

    return 'N/A';
  }

  addWorkDays(startDate, daysToAdd: number) {
    if (isNaN(daysToAdd)) {
      console.log('Value provided for "days" was not a number');
      return;
    }
    if (!(startDate instanceof Date)) {
      console.log('Value provided for "startDate" was not a Date object');
      return;
    }
    // Get the day of the week as a number (0 = Sunday, 6 = Saturday)
    const dow = startDate.getDay();
    // If the start date plus the additional days falls on or after the closest Saturday calculate weekends
    if (dow + daysToAdd >= 6) {
      // Subtract days in current working week from work days
      const remainingWorkDays = daysToAdd - (5 - dow);
      // Add current working week's weekend
      daysToAdd += 2;
      if (remainingWorkDays > 5) {
        // Add two days for each working week by calculating how many weeks are included
        daysToAdd += 2 * Math.floor(remainingWorkDays / 5);
        // Exclude final weekend if remainingWorkDays resolves to an exact number of weeks
        if (remainingWorkDays % 5 === 0) {
          daysToAdd -= 2;
        }
      }
    }
    startDate.setDate(startDate.getDate() + daysToAdd);
    return startDate;
  }

  /**
 * Returns day of week, depending on passed parameters on which day the week starts
 * @param date variable of type Date which will be tests for the week day
 * @param firstWeekDay variable of type String which decides which day will be the first day of the week
 * @returns One of these numbers: 0 | 1 | 2 | 3 | 4 | 5 | 6
 */
  public getGungWeekDay(date: Date, firstWeekDay: string): number {
    const weekday = date.getDay();
    let result: number;
  
    switch (firstWeekDay) {
      case 'Monday':
        if (weekday === 0) {
          result = 6;
        } else {
          result = weekday - 1;
        }
        break;
  
      case 'Tuesday':
        if (weekday === 0) {
          result = 5;
        } else if (weekday === 1) {
          result = 6;
        } else {
          result = weekday - 2;
        }
        break;
  
      case 'Wednesday':
        if (weekday <= 1) {
          result = weekday + 4;
        } else {
          result = weekday - 3;
        }
        break;
  
      case 'Thursday':
        if (weekday <= 2) {
          result = weekday + 3;
        } else {
          result = weekday - 4;
        }
        break;
  
      case 'Friday':
        if (weekday <= 3) {
          result = weekday + 2;
        } else {
          result = weekday - 5;
        }
        break;
  
      case 'Saturday':
        if (weekday <= 4) {
          result = weekday + 1;
        } else {
          result = weekday - 6;
        }
        break;
  
      case 'Sunday':
        result = weekday;
        break;
  
      default:
        result = this.getGungWeekDay(date, 'Monday');
        break;
    }
  
    return result;
  }

  /**
 * Returns first day of week number, depending on environment variable "gungCalendarFirstWeekday"
 * @param useISO8601 variable of type Boolean which decides which type of return the function will give back
 * @returns One of these numbers: Default: [0 | 1 | 2 | 3 | 4 | 5 | 6] || ISO8601: [1 | 2 | 3 | 4 | 5 | 6 | 7]
 */
  public getFirstDayOfWeekRealNumber(useISO8601: boolean = false): number {
    const weekdays = useISO8601 ? ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'] : ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const firstWeekDayIndex = weekdays.indexOf(this.environment.gungCalendarFirstWeekday || 'Monday');
    return useISO8601 ? firstWeekDayIndex + 1 : firstWeekDayIndex;
  }
}
