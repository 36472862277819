import { Component, OnInit } from '@angular/core';
import { LinesheetService } from '../../../services/linesheet/linesheet.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { CartService } from '../../../services/cart/cart.service';
import { GungFlow } from '../../../state/flow/types';
import { AssortmentService, Assortment } from '../../../services/assortment.service';
import { ImportLinesheet, ExcelToJson, MatchProducts } from '../../../models/linesheet';
import { User } from '../../../state/auth/types';
import { AuthService } from '../../../services/auth/auth.service';
import { first } from 'rxjs';
import { LinesheetImportSupportService } from '../../../services/linesheet/linesheet-import-support.service';
import { Product } from '../../../models';
import { ProductService } from '../../../services/products/product.service';
import { ProductInputQuantityConfigService } from '../../../services/product-input-quantity-config/product-input-quantity-config.service';
import { OptionsList } from 'gung-common';
import { TranslateService } from '@ngx-translate/core';
import { FeatureActivatedGuardWrapper } from '../../../guards/feature-activated/feature-activated.guard';
import { MultiOrderImportResult, OrderImportService } from '../../../services/order-import.service';

@Component({
  selector: 'lib-import-linesheet',
  templateUrl: './import-linesheet.component.html',
  styleUrls: ['./import-linesheet.component.scss']
})
export class ImportLinesheetComponent implements OnInit {
  MATRIX_FORMAT: string = 'MATRIX_FORMAT';
  COLUMN_FORMAT: string = 'COLUMN_FORMAT';
  MULTI_ORDER_COLUMN_FORMAT: string = 'MULTI_ORDER_COLUMN_FORMAT';

  replaceModeOptions: OptionsList[] = [
    {
      id: 'REPLACE_CURRENT_ORDER',
      name: this.translateService.instant('REPLACE_CURRENT_ORDER')
    },
    {
      id: 'ADD_TO_CURRENT_ORDER',
      name: this.translateService.instant('ADD_TO_CURRENT_ORDER')
    }
  ];

  loading: boolean;
  uploadFormat: string;
  replaceMode = 'REPLACE_CURRENT_ORDER';
  selectedFlow: GungFlow;
  selectedAssortment: Assortment;
  currentUser: User;

  result: ImportLinesheet;
  multiOrderResult: MultiOrderImportResult;

  quantityChanged = false;
  isSalesOrAdmin: boolean;

  constructor(
    protected linesheetService: LinesheetService,
    protected gungFlowService: GungFlowService,
    protected assortmentService: AssortmentService,
    protected cartService: CartService,
    protected authService: AuthService,
    protected linesheetImportSupportService: LinesheetImportSupportService,
    protected productService: ProductService,
    protected productInputQuantityConfigService: ProductInputQuantityConfigService,
    protected translateService: TranslateService,
    protected featureActivatedGuardWrapper: FeatureActivatedGuardWrapper,
    protected orderImportService: OrderImportService
  ) {
  }

  ngOnInit() {
    this.gungFlowService
      .getSelectedFlow()
      .pipe(first())
      .subscribe(flow => (this.selectedFlow = flow));
    this.assortmentService
      .getUserAssortment()
      .pipe(first())
      .subscribe(assortment => (this.selectedAssortment = assortment));
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.currentUser = user));
    this.authService
      .getRoles()
      .pipe(first())
      .subscribe(roles => {
        this.isSalesOrAdmin = roles.indexOf('ADMIN') !== -1 || roles.indexOf('SALES') !== -1;
      });
  }

  selectFormat(format: string) {
    this.uploadFormat = format;
  }

  uploadFile({ files }) {
    if (!this.uploadFormat || files.length === 0) {
      // Show message/alert
      return;
    }
    if (this.uploadFormat === this.MATRIX_FORMAT) {
      this.uploadFileMatrix(files);
    }
    if (this.uploadFormat === this.COLUMN_FORMAT) {
      this.uploadFileColumn(files);
    }
    if (this.uploadFormat === this.MULTI_ORDER_COLUMN_FORMAT) {
      this.uploadFileMultiOrderColumn(files);
    }
  }

  uploadFileMatrix(files: File[]) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('documents', file);
    }
    formData.append('combinationMode', 'DISTINCT_FILES');
    this.linesheetService.linesheetsExcelToJson(formData).subscribe(
      (event: ExcelToJson) => {
        if (typeof event === 'object') {
          if (event) {
            this.result = {
              errors: null,
              rows: event.rows.map(r => ({
                rownr: null,
                productId: r.id,
                quantity: r.quantity,
                eanCode: null,
                product: r as any
              }))
            };

            if (!this.isSalesOrAdmin) {
              this.checkQuantity();
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      },
      error => {
        // console.log('error', error);
        this.loading = false;
      }
    );
  }

  uploadFileColumn(files: File[]) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }
    this.linesheetService.cartsMatchProducts(this.selectedAssortment.id, this.selectedFlow.id, formData).subscribe(this.getImportResultHandler());
  }

  getImportResultHandler() {
    return {
      next: (event: MatchProducts) => {
        if (typeof event === "object") {
          // Cannot require mapped because we also want to show results if there is only errors
          if (event) {
            this.result = {
              errors: event.errors,
              rows: event.mapped.map(r => ({
                rownr: r.rowCount,
                productId: r.productId,
                quantity: r.amount,
                eanCode: r.eanCode,
                product: r.product,
                discountPercent: r.discountPercent,
                externalPrice: r.externalPrice
              }))
            };

            if (!this.isSalesOrAdmin) {
              this.checkQuantity();
            } else {
              this.loading = false;
            }
          } else {
            this.loading = false;
          }
        } else {
          this.loading = false;
        }
      },
      err: error => {
        // console.log('error', error);
        this.loading = false;
      }
    };
  }

  uploadFileMultiOrderColumn(files: File[]) {
    this.loading = true;
    const formData = new FormData();
    for (const file of files) {
      formData.append('file', file);
    }

    this.orderImportService
      .parseMultiOrderColumnExcel(formData)
      .pipe(first())
      .subscribe(res => {
        this.multiOrderResult = res;
        this.loading = false;
      });
  }

  loadProductsToCart() {
    console.log('ImportLinesheetComponent.loadProductsToCart');
    this.linesheetImportSupportService.loadProductsToCart(
      this.uploadFormat,
      this.currentUser.managedMultistockIds,
      this.result,
      this.replaceMode
    );

    this.result = undefined;
    this.uploadFormat = undefined;
  }

  replaceGungDot(productId: string): string {
    if (productId.includes('_GUNGDOT_')) {
      return productId.replace('_GUNGDOT_', '.');
    }
  }

  checkQuantity() {
    if (this.result && this.result.rows.length > 0) {
      const ids: string[] = this.result.rows.map(r => r.productId);
      this.productService.getProductsByIds(ids).subscribe((products: Product[]) => {
        for (const row of this.result.rows) {
          const pIndex = products.findIndex(p => p.id === row.productId);
          if (pIndex !== -1 && !this.productInputQuantityConfigService.getAllowManualQtyInputOutsideStepSizes(products?.[pIndex]?.id, products?.[pIndex])) {
            const stepAmount = this.productInputQuantityConfigService.getStepAmount(
              products[pIndex].id,
              products[pIndex]
            );
            if (row.quantity % stepAmount !== 0) {
              row.quantity = stepAmount * Math.ceil(row.quantity / stepAmount);
              this.quantityChanged = true;
            }
          }
        }
        this.loading = false;
      });
    }
  }
}
