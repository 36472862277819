import { Component, Input, OnInit } from '@angular/core';
import { EdiMessageBase, GungEdiMessageSendStatus } from '../../../models/edi';

@Component({
  selector: 'lib-edi-send-status',
  templateUrl: './edi-send-status.component.html',
  styleUrls: ['./edi-send-status.component.scss']
})
export class EdiSendStatusComponent implements OnInit {
  @Input()
  message: any;

  okStatus = false;
  attentionStatus = false;
  errorStatus = false;
  okStatuses = [GungEdiMessageSendStatus.Sent, GungEdiMessageSendStatus.Archived];
  attentionStatuses = [
    GungEdiMessageSendStatus.NotSent,
    GungEdiMessageSendStatus.OnHold,
    GungEdiMessageSendStatus.ReadyToSend,
    GungEdiMessageSendStatus.ReadyToSend,
    GungEdiMessageSendStatus.Sending,
    GungEdiMessageSendStatus.ShouldNotBeSent
  ];
  errorStatuses = [
    GungEdiMessageSendStatus.ErrorReported,
    GungEdiMessageSendStatus.ErrorWhileGenerating,
    GungEdiMessageSendStatus.ErrorWhileSending,
    GungEdiMessageSendStatus.ErrorWhileValidating
  ];

  constructor() {}

  ngOnInit(): void {
    if (this.okStatuses.includes(this.message.gungEdiMessageSendStatus)) {
      this.okStatus = true;
    } else if (this.attentionStatuses.includes(this.message.gungEdiMessageSendStatus)) {
      this.attentionStatus = true;
    } else if (this.errorStatuses.includes(this.message.gungEdiMessageSendStatus)) {
      this.errorStatus = true;
    }
  }
}
