import { Component, OnInit } from '@angular/core';
import { forkJoin, Observable, of, throwError } from 'rxjs';
import { first, map, mergeMap, switchMap } from 'rxjs';
import { Customer } from '../../../models';
import { Availability } from '../../../models/availability';
import { CustomerContact } from '../../../models/customerContact';
import { AuthService } from '../../../services/auth/auth.service';
import { AvailabilityService } from '../../../services/availability/availability.service';
import { CartService } from '../../../services/cart/cart.service';
import { CustomerContactService } from '../../../services/customer-contacts/customer-contact.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { User } from '../../../state/auth/types';
import { CartRow } from '../../../state/cart/types';
import { GungFlow } from '../../../state/flow/types';
import { merge } from 'lodash-es';
import { format, isDate, isValid, parse, parseISO } from 'date-fns';
import { DateUtilService } from 'gung-common';
import { NgbActiveModal, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from '../../../services/customers/customer.service';
import { CheckoutConfigService } from '../../../services/checkout-config/checkout-config.service';
import { OrderSubmitService } from '../../../services/order-submit.service';
import { DatePipe } from '@angular/common';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { mergeDeep } from '../../../utils/merge-utils';
import { TranslateService } from '@ngx-translate/core';
import { ProductService } from '../../../services/products/product.service';
import { SystemSettings } from '../../../models/system-setting';

interface BlockedProducts {
  [productId: string]: boolean;
}

@Component({
  selector: 'lib-barcode-quick-checkout',
  templateUrl: './barcode-quick-checkout.component.html',
  styleUrls: ['./barcode-quick-checkout.component.css']
})
export class BarcodeQuickCheckoutComponent implements OnInit {
  checkout;
  extraOperations: any[];
  sendingOrder = false;
  loading = true;
  today = new Date();
  deliveryDate = this.today;

  getBillingCustomerId = (customer: Customer) => customer.id; // TODO: Get Billing Customer ERP

  constructor(
    protected selectedCustomerService: SelectedCustomerService,
    protected gungFlowService: GungFlowService,
    protected cartService: CartService,
    protected authService: AuthService,
    protected availabilityService: AvailabilityService,
    protected customerContactService: CustomerContactService,
    protected dateUtilService: DateUtilService,
    protected customerService: CustomerService,
    protected checkoutConfig: CheckoutConfigService,
    protected orderSubmitService: OrderSubmitService,
    public activeModal: NgbActiveModal,
    protected datePipe: DatePipe,
    protected gungModalService: GungModalService,
    protected translateService: TranslateService,
    protected productService: ProductService
  ) {}

  ngOnInit(): void {
    forkJoin([
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      this.gungFlowService.getSelectedFlow().pipe(first()),
      this.authService.getCurrentUser().pipe(first()),
      this.cartService.getCurrentCart().pipe(first()),
      this.gungFlowService.getSystemGung().pipe(first())
    ])
      .pipe(
        mergeMap(([customer, flow, user, cartRows, systemSettings]) => {
          const productIds = cartRows.map(row => row.productId);
          return forkJoin({
            customer: of(customer),
            flow: of(flow),
            user: of(user),
            cartRows: of(cartRows),
            systemSettings: of(systemSettings),
            avs: this.availabilityService
              .getAvailabilities(productIds, undefined, true)
              .pipe(map(avs => avs.map(av => ({ ...av, availabilities: av.extra.availabilities })))),
            blockedProducts: this.productService.getBlockedProducts(productIds).pipe(first())
          });
        }),
        mergeMap(({ customer, flow, user, cartRows, systemSettings, avs, blockedProducts }) => {
          const billingCustomerId = this.getBillingCustomerId(customer);
          if (billingCustomerId) {
            return forkJoin({
              customer: of(customer),
              flow: of(flow),
              user: of(user),
              cartRows: of(cartRows),
              systemSettings: of(systemSettings),
              avs: of(avs),
              blockedProducts: of(blockedProducts),
              customerContacts: this.customerContactService.getCustomerContacts(customer.id).pipe(first()),
              billingCustomer: this.customerService.getCustomer(billingCustomerId).pipe(first())
            });
          }
          return forkJoin({
            customer: of(customer),
            flow: of(flow),
            user: of(user),
            cartRows: of(cartRows),
            systemSettings: of(systemSettings),
            avs: of(avs),
            blockedProducts: of(blockedProducts),
            customerContacts: this.customerContactService.getCustomerContacts(customer.id).pipe(first()),
            billingCustomer: of(undefined)
          });
        })
      )
      .subscribe(
        ({
          customer,
          flow,
          user,
          cartRows,
          systemSettings,
          avs,
          blockedProducts,
          customerContacts,
          billingCustomer
        }) => {
          this.setValues(
            customer,
            flow,
            user,
            customerContacts,
            billingCustomer,
            cartRows,
            avs,
            systemSettings,
            blockedProducts
          );
        }
      );
  }

  protected setValues(
    customer: Customer,
    flow: GungFlow,
    user: User,
    customerContacts: CustomerContact[],
    billingCustomer: Customer,
    cartRows: CartRow[],
    availabilities: Availability[],
    systemSettings: SystemSettings,
    blockedProducts: { [productId: string]: boolean }
  ) {
    this.initCheckout(customer);
    this.assignSystemSetting(systemSettings);
    this.assignFlowSetting(flow);
    this.removeBlockedProductsStepComponent(cartRows, blockedProducts);
    this.assignOrderRowsComponent(cartRows);
    this.loading = false;
    // TODO: set ERP values
  }

  // InitCheckoutObjectComponent
  initCheckout(customer: Customer) {
    if (customer == null) {
      throwError('Could not find a selected customer');
      return;
    }
    this.checkout = {
      deliveryCustomerId: customer.id,
      extra: {},
      rows: []
    };
  }

  // AssignSystemSettingComponent
  assignSystemSetting(systemSettings: SystemSettings) {
    if (!!systemSettings && !!systemSettings.orderParams) {
      this.checkout = {
        ...this.checkout,
        extra: {
          ...this.checkout.extra,
          ...systemSettings.orderParams
        }
      };
    }
  }

  // AssignFlowSettingComponent
  assignFlowSetting(flow: GungFlow) {
    if (flow.orderParams) {
      const merged = mergeDeep(this.checkout.extra, flow.orderParams);
      this.checkout = {
        ...this.checkout,
        extra: {
          ...merged
        }
      };
    }
  }

  // RemoveBlockedProductsStepComponent
  removeBlockedProductsStepComponent(cartRows: CartRow[], blockedProducts: { [productId: string]: boolean }) {
    const blockedProductsIds: string[] = [];
    const rowsToRemove: any[] = [];
    for (const productId of Object.keys(blockedProducts)) {
      // in case product is blocked then remove it from the cart
      if (blockedProducts[productId]) {
        const cartRow = cartRows.find(cr => cr.productId === productId);
        if (!!cartRow) {
          rowsToRemove.push(cartRow);
          blockedProductsIds.push(productId);
        }
      }
    }
    this.cartService.bulkRemoveRows(rowsToRemove);

    if (blockedProductsIds.length > 0) {
      this.gungModalService
        .openConfirmYesNoModal(
          this.translateService.instant('BLOCKED_PRODUCTS'),
          this.translateService.instant('BLOCKED_PRODUCTS_MESSAGE', { products: blockedProductsIds.join(', ') }),
          null,
          'OK',
          null
        )
        .then(_ => {});
    }
  }

  // AssignOrderRowsComponent
  assignOrderRowsComponent(rows: CartRow[]) {
    this.checkout = {
      ...this.checkout,
      rows: rows.map(r => ({
        id: r.productId,
        partialId: r.productPartialId,
        targetStockId: r.targetStockId,
        quantity: r.qty,
        extra: r.extra || {},
        rownr: r.rownr
      }))
    };
  }

  updateDate(date: NgbDate): void {
    const inputDate = new Date(date.year, date.month - 1, date.day);
    const formattedDate = format(inputDate, 'yyMMdd');

    this.deliveryDate = inputDate;
    for (const row of this.checkout.rows) {
      row.extra.OGR.LDT = format(row.extra.minDate, 'yyMMdd');
    }
  }

  parseDate(value: any): Date {
    if (!value) {
      return null;
    }

    if (isDate(value)) {
      return value;
    }

    const date = parseISO(value);
    if (isValid(date)) {
      return date;
    }
    return new Date(value);
  }

  protected setDeliveryDates(flow: GungFlow, cartRows: CartRow[], availabilities: Availability[]) {
    let firstAvailableDate;
    let flowFirstDeliveryDate: Date;
    if (!!flow.deliveryStartDate) {
      flowFirstDeliveryDate = parseISO(flow.deliveryStartDate.toString());
      if (!isValid(flowFirstDeliveryDate)) {
        flowFirstDeliveryDate = new Date(flow.deliveryStartDate);
      }
      if (flowFirstDeliveryDate < new Date()) {
        flowFirstDeliveryDate = new Date();
      }
    }
    this.extraOperations = [];
    
    cartRows = structuredClone(cartRows);
    for (const cartRow of cartRows) {
      const availability = flow.useAvailabilities ? availabilities.find(x => x.productId === cartRow.productId) : null;
      const minDateJs: Date = this.getMinDate(availability) || new Date();

      const deliveryDate = format(minDateJs, 'yyyy-MM-dd');
      /* Use delivery date from flow if there is one */

      cartRow.extra.minDate = minDateJs;

      if (flowFirstDeliveryDate) {
        /* Check for firstAvailableDate first */
        if (minDateJs > flowFirstDeliveryDate) {
          this.updateDeliveryDate(
            { cartRow },
            new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate())
          );
        } else {
          this.updateDeliveryDate(
            { cartRow },
            new NgbDate(
              flowFirstDeliveryDate.getFullYear(),
              flowFirstDeliveryDate.getMonth() + 1,
              flowFirstDeliveryDate.getDate()
            )
          );
        }
      } else {
        // TODO: Check if cartRow have delivery date already set else get minDateJs...
        // Set delivery date to cart rows
        this.updateDeliveryDate(
          { cartRow },
          new NgbDate(minDateJs.getFullYear(), minDateJs.getMonth() + 1, minDateJs.getDate())
        );

        // Set checkout first available date
        if (
          !firstAvailableDate ||
          parse(deliveryDate, 'yyyy-MM-dd', new Date()) < parse(firstAvailableDate, 'yyMMdd', new Date())
        ) {
          firstAvailableDate = format(minDateJs, 'yyMMdd');
          // TODO: SAVE firstAvailableDate on this.checkout
        }
      }
    }
    this.cartService.bulkSetExtra(this.extraOperations);
  }

  public updateDeliveryDate(row, deliveryDate: NgbDate) {
    const oldVal = row.deliveryDate || row.minDate;

    if (oldVal === deliveryDate) {
      return;
    }

    if (!deliveryDate) {
      // TODO: this.cartService.removeExtraField(row.cartRow.productId, ERP_ROW_FIELD, row.targetStockId);
      return;
    }

    // first delivery date
    const dObj = this.formatNgbDate(deliveryDate);
    if (this.checkout.extra.OGA && this.checkout.extra.OGA.BLT) {
      let firstAvailableDate = this.checkout.extra.OGA.BLT;
      if (!firstAvailableDate || parse(dObj, 'yyMMdd', new Date()) < parse(firstAvailableDate, 'yyMMdd', new Date())) {
        firstAvailableDate = dObj;
        this.checkout.extra.OGA.BLT = dObj;
      }
    } else {
      this.checkout.extra = {
        ...this.checkout.extra,
        OGA: {
          ...this.checkout.extra.OGA,
          BLT: dObj
        }
      };
    }

    if (!this.extraOperations) {
      this.extraOperations = [];
    }

    if (!row.cartRow.extra) {
      row.extra = {};
    }

    const dateDelivery = format(new Date(deliveryDate.year, deliveryDate.month - 1, deliveryDate.day), 'yyMMdd');
    // TODO: Set dateDelivery to row
  }

  public formatNgbDate(date: NgbDate, formatString = 'yyMMdd') {
    const dObj = this.dateUtilService.createDateFromNgbDate(date);
    return format(dObj, formatString);
  }

  protected getMinDate(av: Availability): Date {
    if (!av) {
      return new Date();
    }
    if (av.currentAvailability > 0) {
      return new Date();
    }
    if (av.maxFutureStock === 0) {
      return new Date();
    }
    if (!av.availabilities && !av.extra.availabilities) {
      return new Date();
    }
    const valids = Object.keys(av.availabilities || av.extra.availabilities).filter(
      entry => (av.availabilities || av.extra.availabilities)[entry] > 0
    );
    const dateFormat = 'yyMMdd';
    return valids.length > 0 ? parse(valids[0], dateFormat, new Date()) : new Date();
  }

  getDeliveryDateMin(): NgbDate {
    return new NgbDate(this.today.getFullYear(), this.today.getMonth() + 1, this.today.getDate());
  }

  sendCheckout() {
    let showDeliveryWarring = false;
    const productIdsWithoutstock = [];

    for (const row of this.checkout.rows) {
      // TODO: Check if row dont have stock
      if (false) {
        showDeliveryWarring = true;
        productIdsWithoutstock.push({ id: row.id, name: row.extra.product.name, stock: row.extra.minDate });
      }
    }
    if (showDeliveryWarring) {
      let description = 'Some products are not available for this delivery date: <br>';
      for (const pWithoutStock of productIdsWithoutstock) {
        description += "<div class='mt-2'><span>" + pWithoutStock.id + ' | ' + pWithoutStock.name + '</span><br>';
        description += "<span style='color:orange'>" + format(pWithoutStock.stock, 'yyyy-MM-dd') + '</span></div>';
      }
      description += 'Do you want to continue ?';
      this.gungModalService.openConfirmYesNoModal(undefined, description, { size: 'sm' }, 'Yes', 'No', true).then(
        result => {
          if (result) {
            this.sendCheckoutToServer();
          } else {
            this.sendingOrder = false;
          }
        },
        reason => {}
      );
    } else {
      this.sendCheckoutToServer();
    }
  }

  sendCheckoutToServer() {
    this.sendingOrder = true;
    this.checkoutConfig
      .getSubmitUrl(this.checkout)
      .pipe(switchMap(url => this.orderSubmitService.postOrder(url, this.checkout)))
      .subscribe(
        response => {
          this.cartService.clearCart();
          this.activeModal.close();
          this.checkoutConfig.onOrderSubmitted(response);
          this.sendingOrder = false;
        },
        err => {
          console.error('ERROR POSTING ORDER', err.error);
        }
      );
  }
}
