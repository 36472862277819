import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs';
import { User } from '../../state/auth/types';

@Injectable({
  providedIn: 'root'
})
export class CustomerUserService {
  private usersSubjects: { [customerId: string]: BehaviorSubject<User[]> } = {};

  constructor(protected http: HttpClient) {}

  /**
   * Gets all users for a specific customer.
   * @param customerId - The customer which users we want to get.
   */
  getCustomerUsers(customerId: string): Observable<User[]> {
    if (!this.usersSubjects[customerId]) {
      this.usersSubjects[customerId] = new BehaviorSubject<User[]>([]);
      this.updateCustomerUsersSubject(customerId);
    }
    return this.usersSubjects[customerId].asObservable();
  }

  /**
   *  Gets all users for a specific customer.
   *   @param customerId - The customer which users we want to get.
   */
  protected internalGetCustomerUsersFromUrl(customerId: string): Observable<User[]> {
    const url = `json/users/by-managed-company-id?companyId=${customerId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<User[]>(url, { headers });
  }

  updateCustomerUsersSubject(customerId: string): void {
    this.internalGetCustomerUsersFromUrl(customerId).subscribe((cc: User[]) => this.usersSubjects[customerId].next(cc));
  }
}
