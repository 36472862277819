<div class="row">
  <div class="col-12">
    <div class="table-responsive-sm gung-responsive">
      <table class="table table-sm">
        <thead>
          <tr>
            <th class="col-1" translate></th>
            <th class="col-1" translate>PRODUCT</th>
            <th translate></th>
            <th translate>QUANTITY</th>
            <th translate class="text-right">ORDER_COUNT</th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngFor="let item of _productsSorted; let i = index">
          <tr>
            <td [attr.data-label]="'NUMBER' | translate">
              <span
                class="fa fa-plus"
                *ngIf="item.number !== expandedNumber"
                (click)="expand(item.number)"
                aria-hidden="true"
              ></span>
              <span
                class="fa fa-minus"
                *ngIf="item.number === expandedNumber"
                (click)="expand(-1)"
                aria-hidden="true"
              ></span>
              &nbsp;
              <span class="top-number">{{ item.number }}</span>
            </td>
            <td>
              <div class="image-wrapper">
                <img
                  class="img-fluid"
                  *ngIf="
                    productsInfo[item.productId] &&
                    productsInfo[item.productId].extra &&
                    productsInfo[item.productId].extra.images &&
                    productsInfo[item.productId].extra.images[0]
                  "
                  [src]="productsInfo[item.productId].extra.images[0].s3Uri | gungImageUrl
                    :'type:list'
                    :'etag:'+productsInfo[item.productId].extra.images[0].s3ETag
                  "
                  alt=""
                />
              </div>
            </td>
            <td [attr.data-label]="'PRODUCT' | translate">{{ item.productId }} - {{ item.productName }}</td>
            <td [attr.data-label]="'QTY' | translate">
              {{ item.qty }}
            </td>
            <td class="text-right" [attr.data-label]="'ORDER_COUNT' | translate">
              {{ item.orderCount }}
            </td>
            <td>
              <lib-action-btn [item]="item" [config]="buttonConfigService"></lib-action-btn>
            </td>
          </tr>
          <tr *ngIf="item.number == expandedNumber">
            <td colspan="6">
              <table class="table table-sm table-striped">
                <thead>
                  <tr>
                    <th translate>Customer</th>
                    <th translate>Item Id</th>
                    <th translate>Size</th>
                    <th translate>Order Id</th>
                    <th translate>Line Id</th>
                    <th translate>Quantity</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let subItem of item.subItems; let i = index">
                    <td>{{ subItem.customerId }} {{ subItem.customerName }}</td>
                    <td>
                      {{ subItem.productId }}
                    </td>
                    <td>
                      {{ subItem.productSecondaryDimensionName }}
                    </td>
                    <td>
                      {{ subItem.orderId }}
                    </td>
                    <td>
                      {{ subItem.orderRowNumber }}
                    </td>
                    <td>
                      {{ subItem.orderRowTotalQuantity }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
