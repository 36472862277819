import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter, map, merge, Observable, OperatorFunction, Subject } from 'rxjs';
import { BaseInputDirective } from '../base-input/base-input.directive';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-gung-text-input-typeahead',
  templateUrl: './gung-text-input-typeahead.component.html',
  styleUrls: ['./gung-text-input-typeahead.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => GungTextInputTypeaheadComponent),
      multi: true
    }
  ]
})
export class GungTextInputTypeaheadComponent extends BaseInputDirective<string> implements OnInit, OnDestroy {

  @Input()
  showHint = false;
  @Input()
  editable = false;
  @Input()
  hidePlaceholder = false;

  @Input()
  public footnote = false;

  @Input()
  list: any[] = [];

  @Input()
  inputFormatter = (item: any) => item?.id || item?.code || item;

  @Input()
  resultFormatter = (item: any) => (item?.name || item?.description || item);

  @Input()
  resultTemplate = undefined;

  @Input()
  search: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      filter(term => term.length >= 2),
      map(term =>
        this.list
          ?.filter(item => new RegExp(term, 'mi').test(this.inputFormatter(item)))
          .slice(0, 10)
      )
    );

  @Output()
  @ViewChild('instance', { static: true }) 
  public instance: NgbTypeahead;
  @Output()
  public focus$ = new Subject<string>();
  @Output()
	public click$ = new Subject<string>();

  // Example
  searchOnFocus: OperatorFunction<string, readonly any[]> = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map((term) =>{
        if (term === '') {
          return this.list.slice(0, 10);
        }
        return this.list
          ?.filter(item => new RegExp(term, 'mi').test(this.inputFormatter(item)))
          .slice(0, 10);
      }),
    );
  }

  @Output() selectItem: EventEmitter<{item: any, preventDefault: any, target: any}> = new EventEmitter<{item: any, preventDefault: any, target: any}>();

  constructor() {
    super();
  }
  ngOnDestroy(): void {
    this.focus$.complete();
    this.click$.complete();
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

  selectItemFn({ item, preventDefault }, target) {
    // const item: CustomerGungContact = itemObject;
    // const inputItem: FormArray = this.form.get('contacts') as FormArray;
    // if (this.ediTextContacts.indexOf(this.contactEmailField(item)) !== -1) {
    //   preventDefault();
    //   this.modelSearch = '';
    //   target.value = '';
    //   return;
    // }
    // this.addContact(this.contactEmailField(item));
    // preventDefault();
    // this.modelSearch = '';
    // target.value = '';

    this.value = this.inputFormatter(item);
    preventDefault();
    this.selectItem.emit({item, preventDefault, target});
  }

  ngModelChange(itemObject, target) {
    // const item: CustomerGungContact = itemObject;
    // const inputItem = this.form.get('contacts');
    // if (!item) {
    //   inputItem.patchValue(this.formBuilder.array([]));
    // }
  }

  focusout({ target, preventDefault }, modelSearch: any) {
    if (!this.editable && !modelSearch) {
      target.value = '';
      this.value = undefined;
      this.selectItem.emit({item: null, preventDefault, target});
    }
  }
}
