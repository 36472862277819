import { Component, OnChanges, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ListItemRendererComponent } from 'gung-list';
import { first, Observable, Observer } from 'rxjs';
import { AuthService } from '../../../services/auth/auth.service';
import {
  DigitalAsset,
  DigitalAssetFile,
  DigitalAssetsService
} from '../../../services/digital-assets/digital-assets.service';
import { DownloadService } from '../../../services/download/download.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { DateUtilService } from 'gung-common';
import { User } from '../../../state/auth/types';
import { HttpClient } from '@angular/common/http';
import { DigitalAssetsModalComponent } from '../digital-assets-modal/digital-assets-modal.component';

@Component({
  selector: 'lib-digital-assets-grid-view',
  templateUrl: './digital-assets-grid-view.component.html',
  styleUrls: ['./digital-assets-grid-view.component.css']
})
export class DigitalAssetsGridViewComponent
  extends ListItemRendererComponent<DigitalAsset[]>
  implements OnInit, OnChanges, AfterViewInit {
  currentUser: User;
  isManagement = false;
  parentId: string;
  selectedDigitalAsset: DigitalAsset;
  downloadingInProgress = false;
  selectedTotal = 0;
  public modalRef: NgbModalRef;

  constructor(
    protected router: Router,
    protected route: ActivatedRoute,
    protected gungModalService: GungModalService,
    protected authService: AuthService,
    protected modalService: NgbModal,
    public dateUtilService: DateUtilService,
    protected digitalAssetsService: DigitalAssetsService,
  ) {
    super();
  }

  ngOnInit() {
    this.parentId = undefined;
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => (this.currentUser = user));
    if (this.route.snapshot.url.length > 1) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 2].path;
    }
    this.isManagement = this.router.url.startsWith('/digital-assets/management');
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  openModal(digitalAsset: DigitalAsset) {
    this.gungModalService.openDigitalAssetsModal(digitalAsset, { size: 'xl', scrollable: true }).then(
      result => { },
      reason => { }
    );
  }

  download(digitalAsset: DigitalAsset) {
    const srcImages = digitalAsset.images || [];
    const srcDocuments = digitalAsset.documents || [];
    const imageS3Uris: string[] = srcImages
      .filter(i => i.extra._selected)
      //   .map(i => 'fit-in/5000x5000/filters:quality(100):background_color(white)/' + i.s3Uri);
      .map(image => image.s3Uri); // for urls without fit-in we will use cdn2.gung.io that does not use scaling function, hence no image size cap.

    const documentsToSend: DigitalAssetFile[] = srcDocuments.filter(i => i.extra._selected);
    if (imageS3Uris.length > 0 || documentsToSend.length > 0) {
      this.digitalAssetsService.sendDigitalAssetsFilesUri(
        imageS3Uris,
        documentsToSend,
        this.gungModalService,
        this.currentUser.email
      );
    } else {
      this.digitalAssetsService.sendDigitalAssetsFiles([digitalAsset], this.gungModalService, this.currentUser.email);
    }
    this.selectedDigitalAsset = undefined;
  }
}
