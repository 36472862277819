import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthBackendService } from '../../services/auth-backend/auth-backend.service';

@Component({
  selector: 'lib-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.css']
})
export class LoginModalComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  badLogin = false;

  constructor(
    protected formBuilder: FormBuilder,
    protected authService: AuthBackendService,
    protected activeModal: NgbActiveModal
  ) {}

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  loginDone() {
    this.loading = false;
  }

  get f() {
    return this.loginForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    const { username, password } = this.loginForm.controls;
    this.loading = true;
    this.authService.loginUser(username.value, password.value).subscribe(valid => {
      this.loginDone();
      if (!valid) {
        this.badLogin = true;
        return;
      }
      this.activeModal.close();
    });
  }
}
