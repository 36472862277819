import { Component, OnInit, Input } from '@angular/core';
import { parse } from 'date-fns';
import { first } from 'rxjs';
import { Availability, SimplifiedAvailability } from './../../../../models/availability';
import { ProductMatrixModalService } from './../../../../services/product-matrix-modal/product-matrix-modal.service';
import { AuthService } from './../../../../services/auth/auth.service';
import { ProductMatrixConfigService } from './../../../../services/product-matrix-config/product-matrix-config.service';
import { DisplayAvailability } from './../../../../models/product-matrix';
import { DateUtilService } from 'gung-common';
import { Product } from './../../../../models/product';
import { ProductService } from './../../../../services/products/product.service';
import { forkJoin } from 'rxjs';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { AvailabilityHelperService } from './../../../../services/availability-helper.service';

@Component({
  selector: 'lib-product-matrix-element-simplified-availability',
  templateUrl: './product-matrix-element-simplified-availability.component.html',
  styleUrls: ['./product-matrix-element-simplified-availability.component.css']
})
export class ProductMatrixElementSimplifiedAvailabilityComponent implements OnInit {
  @Input()
  productId: string;

  @Input()
  availability: Availability | SimplifiedAvailability;

  @Input()
  product: Product;

  displayAvailability: DisplayAvailability;
  showAvailability = false;
  isSales = false;
  modalRestrictedViewAllowed = false;

  status: string;
  class: { [className: string]: boolean };
  enableOpenModalAvailabilty = false;

  constructor(
    protected matrixModalService: ProductMatrixModalService,
    protected authService: AuthService,
    protected productMatrixConfigService: ProductMatrixConfigService,
    public dateUtilService: DateUtilService,
    protected productService: ProductService,
    protected gungFlowService: GungFlowService,
    protected availabilityHelper: AvailabilityHelperService
  ) {}

  ngOnInit() {
    if (!!this.availability) {
      forkJoin([
        this.authService.getCurrentUser().pipe(first()),
        this.gungFlowService.getSelectedFlow().pipe(first())
      ]).subscribe(([user, currentFlow]) => {
        this.isSales = user.roles.indexOf('SALES') > -1;
        this.displayAvailability = this.productMatrixConfigService.getDisplayAvailability(
          this.availability,
          user,
          currentFlow,
          this.product
        );
        this.modalRestrictedViewAllowed = this.matrixModalService.allowRestrictedAvailabilityView;
      });
      // this.authService.getHasRoles('SALES').pipe(first()).subscribe(isSales => {
      //   this.isSales = isSales;
      //   this.modalRestrictedViewAllowed = this.matrixModalService.allowRestrictedAvailabilityView ;
      // });
      this.getAvailability();
    }
  }

  getAvailability() {
    this.enableOpenModalAvailabilty = this.availabilityHelper.getMatrixEnableOpenModalAvailabilty(
      this.productId,
      this.isSales,
      this.matrixModalService.allowRestrictedAvailabilityView
    );
    this.availabilityHelper
      .getMatrixAvailabilityDisplay(this.availability, this.productId, this.product)
      .pipe(first())
      .subscribe(d => {
        this.status = d.value;
        this.class = d.class;
      });
  }

  openModalAvailabilty(): void {
    if (this.modalRestrictedViewAllowed && !this.isSales) {
      this.matrixModalService.openAvailabilityModal(null /* force reload */, this.productId, true);
    } else {
      this.matrixModalService.openAvailabilityModal(null /* force reload */, this.productId);
    }
  }
}
