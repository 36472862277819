import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { switchMap, tap, first, takeUntil } from 'rxjs';
import { FavouriteProductsService } from '../../../services/favourite-products/favourite-products.service';

@Component({
  selector: 'lib-favourite-product-icon',
  templateUrl: './favourite-product-icon.component.html',
  styleUrls: ['./favourite-product-icon.component.css'],
  host: {
    "(click)": "toggle()"
 }
})
export class FavouriteProductIconComponent implements OnInit, OnDestroy {
  @Input()
  id: string;

  // @Input()
  // customClass: string;

  public isFavourite: boolean;
  favourite: Subject<string> = new Subject();
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(protected favouritesService: FavouriteProductsService) {}

  ngOnInit() {
    this.favouritesService
      .isFavourite(this.id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(isFavourite => {
        this.isFavourite = isFavourite;
      });
  }
  public toggle() {
    this.favouritesService.toggleFavourite(this.id);
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
