import { Component, Input, OnInit } from '@angular/core';
import { Availability, AvailabilityTransactions } from '../../../models/availability';
import { AvailabilityService } from '../../../services/availability/availability.service';

@Component({
  selector: 'lib-availability-transactions',
  templateUrl: './availability-transactions.component.html',
  styleUrls: ['./availability-transactions.component.css']
})
export class AvailabilityTransactionsComponent implements OnInit {
  @Input() availability: Availability;

  transactionsData: AvailabilityTransactions[] = [];
  constructor(protected availabilityService: AvailabilityService) {}

  ngOnInit(): void {
    const getStockId = this.availability.id.match(/\[(.*?)\]/);
    if (getStockId) {
      const stockId = getStockId[1];
      this.availabilityService
        .getAvailabilityTransactions(this.availability.productId, stockId)
        .subscribe(transactions => {
          this.transactionsData = transactions;
        });
    }
  }
}
