<div *ngIf="isDownloading" class="pdf-spinner">
  <!--
    The reason we have the tooltip in separate div where the position is relative is that otherwise it will bug since the
    parent div is fixed. Without this "hack" if we would scroll the tooltip would not follow the scroll.
    -->
  <div
    class="d-flex justify-content-center"
    style="position: relative"
    ngbTooltip="{{ 'PDF_IS_GENERATING' | translate }}"
    placement="top"
  >
    <div class="spinner-border spinner-border-sm" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</div>
