import { Injectable } from '@angular/core';
import { NgbModal, NgbModalOptions, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { SaveCartModalComponent } from '../../components/save-cart-modal/save-cart-modal.component';
import { CartsService } from '../carts/carts.service';
import { CreateQuotationModalComponent } from '../../components/create-quotation-modal/create-quotation-modal.component';
import { GungModalService } from '../gung-modal/gung-modal.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SaveCartModalConfigService {
  public modalRef: Map<string, NgbModalRef> = new Map<string, NgbModalRef>();

  modalOptions: NgbModalOptions = {
    backdrop: 'static',
    size: 'lg',
    keyboard: true
  };

  constructor(
    protected ngbModal: NgbModal,
    protected cartsService: CartsService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService
  ) {}

  openSaveNewCartModal() {
    const id = SaveCartModalComponent.name;

    const ref = this.ngbModal.open(SaveCartModalComponent, this.modalOptions);
    ref.componentInstance.delegate = this;
    ref.result.then(
      result => {
        const title = result.cartTitle;
        if (title) {
          this.cartsService.saveCart(title).subscribe(() => {
            this.cartsService.updateSavedCartsSubject();
          });
        }
      },
      reason => {}
    );

    this.modalRef.set(id, ref);
  }

  openSaveCartModal() {
    const loadedCard = this.cartsService.getLoadedCardId();
    if (loadedCard) {
      const cartId = loadedCard.split('#')[0];
      const cartName = loadedCard.split('#')[1];
      const ref = this.gungModalService
        .openConfirmYesNoModal(
          undefined,
          this.translationService.instant('CARTS_REPLACE_EXISTING_OR_CREATE_NEW', { cartName }),
          { size: 'sm' },
          this.translationService.instant('REPLACE'),
          this.translationService.instant('CREATE_NEW')
        )
        .then(
          result => {
            if (result) {
              this.cartsService.deleteCart(cartId).subscribe(() => {
                this.cartsService.saveCart(cartName).subscribe(() => {
                  this.cartsService.updateSavedCartsSubject();
                });
              });
            } else {
              this.openSaveNewCartModal();
            }
          },
          reason => {}
        );
      // this.modalRef.set(id, ref);
    } else {
      this.openSaveNewCartModal();
    }
  }

  openCreateQuotationModal(): void {
    const id = CreateQuotationModalComponent.name;

    const ref = this.ngbModal.open(CreateQuotationModalComponent, {
      size: 'lg',
      backdrop: 'static',
      keyboard: true
    });
    ref.componentInstance.delegate = this;
    ref.result.then(
      result => {
        const name = result.name;
        if (name) {
          this.cartsService.saveQuotation(name, result.description).subscribe(() => {
            this.cartsService.updateSavedQuotesSubject();
          });
        }
      },
      reason => {}
    );

    this.modalRef.set(id, ref);
  }
}
