<div ngbDropdown>
  <button class="btn" ngbDropdownToggle>
    <i class="flag-icon flag-icon-{{ currentLangClass }} flag-icon-squared mr-2 "></i>
    <span translate>CHANGE_LANGUAGE</span>
  </button>
  <div ngbDropdownMenu>
    <button class="btn" ngbDropdownItem (click)="changeLanguage('en')">
      <i class="flag-icon flag-icon-gb flag-icon-squared mr-2"></i>
      <span>English</span>
    </button>
    <button class="btn" ngbDropdownItem (click)="changeLanguage('fr')">
      <i class="flag-icon flag-icon-fr flag-icon-squared mr-2"></i>
      <span>Français</span>
    </button>
    <button class="btn" ngbDropdownItem (click)="changeLanguage('se')">
      <i class="flag-icon flag-icon-se flag-icon-squared mr-2"></i>
      <span>Svenska</span>
    </button>
  </div>
</div>
