import { Component, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { AssortmentRecursive, AssortmentRecursiveExtended } from '../../services/assortment.service';

@Component({
  selector: 'lib-concept-tree-list-view',
  templateUrl: './concept-tree-list-view.component.html',
  styleUrls: ['./concept-tree-list-view.component.css']
})
export class ConceptTreeListViewComponent extends ListItemRendererComponent<AssortmentRecursiveExtended[]> implements AfterViewInit {
  constructor() {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
