<div class="pt-3" *ngIf="loaded; else loading">
  <!-- <div class="row">
        <div class="col">
            <ng-container *ngTemplateOutlet="searchElement; context:{ $implicit: modelSearch, index: 0 }"></ng-container>
        </div>
    </div> -->
  <div class="row">
    <div class="col-3">
      <lib-gung-text-input gungTranslate="ORIGIN_SUPPLIERID" [(ngModel)]="managedSupplierIdModel"></lib-gung-text-input>
    </div>
    <div class="col-1 d-flex align-items-end">
      <button type="button" class="btn btn-primary" translate (click)="addSupplierId(managedSupplierIdModel)">
        ADD
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-12 col-md-5">
      <div
        class="form-check pt-1 d-flex align-items-center justify-content-between"
        *ngFor="let managedSupplierId of managedSupplierIds"
      >
        <label class="form-check-label">
          {{ managedSupplierId.id + ' ' + managedSupplierId.name }}
        </label>
        <button type="button" class="btn btn-secondary" (click)="removeSelected(managedSupplierId.id)">
          <i class="fas fa-times"></i>
        </button>
      </div>
    </div>
  </div>
  <div class="row mt-1">
    <div class="col">
      <hr />
      <h5 class="pb-1">{{ 'ENTER_CONDITION_TO_MATCH' | translate }}</h5>
    </div>
  </div>
  <div class="row">
    <div class="col-md-6">
      <textarea
        (keyup)="validate()"
        class="form-control input-sm"
        rows="10"
        cols="40"
        [(ngModel)]="currentUser.managedSupplierQuery"
      ></textarea>
    </div>
    <div class="col-md-6">
      <pre
        *ngIf="currentUser.managedSupplierQuery"
        class="jsonviewer"
      ><code>{{ managedSupplierQuery | json }}</code></pre>
      <div class="alert alert-warning" *ngIf="parseError.hasError">{{ parseError.errorText }}</div>
    </div>
  </div>
  <div class="row">
    <div class="col-10 col-md-5">
      <lib-gung-checkbox-input-horizontal
        gungTranslate="Enable supplier portal notification."
        [(ngModel)]="currentUser.extra.shouldReceiveSupplierPortalNotifications"
      >
      </lib-gung-checkbox-input-horizontal>
    </div>
  </div>
</div>

<ng-template #searchElement let-searchmodel let-index="index">
  <input
    #searchInput
    id="typeahead-prevent-manual-entry"
    type="text"
    class="form-control"
    [(ngModel)]="modelSearch"
    [ngbTypeahead]="search"
    [inputFormatter]="formatter"
    [resultFormatter]="resultFormatter"
    (selectItem)="selectItem($event, searchInput)"
    placeholder="{{ 'CONTACT' | translate }}"
    (focusout)="focusout($event, modelSearch)"
    (ngModelChange)="ngModelChange($event, modelSearch)"
    [ngModelOptions]="{ standalone: true }"
    [showHint]="false"
    [editable]="true"
  />
</ng-template>

<ng-template #loading> LOADING <i class="fas fa-spinner fa-spin ml-2"></i> </ng-template>
