<div class="form-group row">
  <label [for]="inputId" *ngIf="!suppressLabel" class="col-form-label" [ngClass]="labelClass || 'col-12 col-sm-4'">
    {{ translateTag | translate: translateParams }}
    <i *ngIf="tooltip" class="fal fa-question-circle" ngbTooltip="{{ tooltip | translate }}"></i>
  </label>
  <div class="col">
    <textarea
      type="text"
      [ngClass]="renderStatic ? 'form-control-plaintext' : 'form-control'"
      [readonly]="renderStatic"
      [id]="inputId"
      [name]="inputId"
      [rows]="rowsNumber"
      [required]="required"
      [disabled]="disabled"
      [placeholder]="placeholder | translate"
      [(ngModel)]="value"
    >
    </textarea>
  </div>
</div>
