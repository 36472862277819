import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';
import { PriceConfigService } from '../../services/price-config/price-config.service';
import { OrderActionConfigService } from '../../services/order-action-config/order-action-config.service';


@Component({
  selector: 'lib-order-card-list',
  templateUrl: './order-card-list.component.html',
  styleUrls: ['./order-card-list.component.scss']
})
export class OrderCardListComponent extends ListItemRendererComponent<Order> implements AfterViewInit {

  constructor(
    public priceConfigService: PriceConfigService,
    public configService: OrderActionConfigService,
  ) {
    super();
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
