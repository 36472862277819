import { Injectable } from '@angular/core';
import { ConfigService, ConfigBaseFilter, ListSortOption, ListLayout, ListLayoutMultipleComponent } from 'gung-list';
import { GungFlow } from './../../state/flow/types';
import { GungFlowService } from '../gung-flow/gung-flow.service';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { GungFlowsTableComponent } from '../../components/gung-flows/gung-flows-table/gung-flows-table.component';

@Injectable({
  providedIn: 'root'
})
export class GungFlowsListConfigService implements ConfigService<GungFlow> {
  constructor(protected gungFlowService: GungFlowService, protected translateService: TranslateService) {}

  getItems(): Observable<GungFlow[]> {
    return this.gungFlowService.getGungAdminFlows();
  }

  getFilters(): ConfigBaseFilter<GungFlow>[] {
    return [];
  }

  getSortOptions(): ListSortOption<GungFlow>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<GungFlow>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => GungFlowsTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'GungFlowsTable'
      }
    ];
  }

  getSearchTerms(item: GungFlow): string[] {
    return [item.id, item.name];
  }

  getItemId(item: GungFlow): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
