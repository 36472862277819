import { Component, OnInit } from '@angular/core';
import { EdiOrderResponseListConfigService } from '../../../services/edi-order-response-list-config/edi-order-response-list-config.service';

@Component({
  selector: 'lib-edi-order-response',
  template: `<h3 class="d-block page-title text-center" translate>Manage order responses</h3>
    <lib-filter-list [configService]="ediOrderResponseListConfigService"></lib-filter-list>`,
  styles: []
})
export class EdiOrderResponseComponent implements OnInit {
  constructor(public ediOrderResponseListConfigService: EdiOrderResponseListConfigService) {}

  ngOnInit(): void {}
}
