import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../../services/products/product.service';
import { WhiteboardGridConfigService } from '../../services/whiteboard-grid-config.service';
import { WhiteboardOptionsService } from '../../services/whiteboard-options.service';
import { GungGridItem, WhiteboardOption } from '../../types';
@Component({
  selector: 'lib-whiteboard-options',
  templateUrl: './whiteboard-options.component.html',
  styleUrls: ['./whiteboard-options.component.css']
})
export class WhiteboardOptionsComponent implements OnInit {
  options: WhiteboardOption[] = [];

  constructor(
    protected whiteboardOptionsService: WhiteboardOptionsService,
    public configService: WhiteboardGridConfigService,
    protected productService: ProductService
  ) {}

  ngOnInit(): void {
    this.options = this.whiteboardOptionsService.options;
  }
}
