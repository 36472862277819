import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface EanNumbers {
  id: string;
  ean: string;
  sku: string;
  upc: string;
  type: string;
  duplicateEANs: string[];
}

@Injectable({
  providedIn: 'root'
})
export class EanNumbersService {
  constructor(protected http: HttpClient) {}

  getEanNumbers(): Observable<EanNumbers[]> {
    const url = `json/ean-numbers`;
    const headers = new HttpHeaders();
    return this.http.get<EanNumbers[]>(url, { headers });
  }

  getEanNumber(eanNumber): Observable<EanNumbers[]> {
    const url = `json/ean-numbers?eanNumber=${eanNumber}`;
    const headers = new HttpHeaders();
    return this.http.get<EanNumbers[]>(url, { headers });
  }
}
