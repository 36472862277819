<div class="pt-3">
  <div class="row">
    <div class="col-12">
      <div class="row">
        <div class="col-12 col-md-5">
          <div
            class="form-check pt-1 d-flex align-items-center justify-content-between"
            *ngFor="let selectedCustomer of selectedCustomers"
          >
            <label class="form-check-label">
              {{ selectedCustomer.name + ' - (' + selectedCustomer.id + ')' }}
            </label>
            <button type="button" class="btn btn-link p-1" (click)="removeSelectedCustomer(selectedCustomer.id)">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="pt-4 row">
        <div class="col-12 col-md-5">
          <lib-filter-list [configService]="usersDetailsCustomersListConfigService"></lib-filter-list>
        </div>
      </div>

      <div class="pt-2">
        <h5 class="pb-1">{{ 'ENTER_CONDITION_TO_MATCH' | translate }}</h5>
        <div>
          <textarea
            rows="5"
            class="form-control form-control-sm"
            [(ngModel)]="currentUser['managedCompanyQuery']"
          ></textarea>
        </div>
      </div>
    </div>
  </div>
</div>
