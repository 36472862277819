import { Component, Input, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { Pim } from '../../../models/pim';
import { Observable } from 'rxjs';
import { Subscription } from 'rxjs';
import { PimService } from '../../../services/pim/pim.service';

@Component({
  selector: 'lib-jetshop-management-pim',
  templateUrl: './jetshop-management-pim.component.html',
  styleUrls: ['./jetshop-management-pim.component.scss']
})
export class JetshopManagementPimComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  master: Pim;

  @Input()
  variants: Pim[];

  @Input()
  languages: string[];

  @Input()
  saveEvent: Observable<void>;

  private saveEventSubscription: Subscription;

  colorCodes: { [key: string]: any } = {};

  constructor(protected pimService: PimService) {}

  ngOnInit(): void {
    this.saveEventSubscription = this.saveEvent.subscribe(() =>
      this.pimService.postPimProductsBatch(this.variants).subscribe()
    );
  }

  ngOnChanges(): void {
    if (!this.master || !this.variants) {
      return;
    }

    this.master.dimensions.colors.forEach(color => {
      this.colorCodes[color.id] = color;
    });

    this.master.extra.pim = this.master.extra.pim || {};
    this.master.extra.pim.jetshop = this.master.extra.pim.jetshop || {};

    this.languages.forEach(lang => {
      this.master.i18n[lang] = this.master.i18n[lang] || {};
      this.master.i18n[lang].jetshop = this.master.i18n[lang].jetshop || {};
      this.master.i18n[lang].jetshop.template = this.master.i18n[lang].jetshop.template || {};
    });

    this.variants.forEach(variant => {
      variant.extra.pim = variant.extra.pim || {};
      variant.extra.pim.buyerGroups = variant.extra.pim.buyerGroups || {};
      variant.extra.pim.buyerGroups.JETSHOP = variant.extra.pim.buyerGroups.JETSHOP || false;
      variant.extra.pim.jetshop = variant.extra.pim.jetshop || {};

      this.languages.forEach(lang => {
        variant.i18n[lang].jetshop = variant.i18n[lang].jetshop || {};
      });
    });
  }

  ngOnDestroy(): void {
    this.saveEventSubscription.unsubscribe();
  }
}
