import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GungStringConverterService {
  constructor() {}

  public static toGungString(str: string): string {
    return str.split('/').join('_GUNGSLASH_').split('#').join('_GUNGHASH_').split('.').join('_GUNGDOT_');
  }

  public static fromGungString(str: string): string {
    return str.split('_GUNGSLASH_').join('/').split('_GUNGHASH_').join('#').split('_GUNGDOT_').join('.');
  }
}
