import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  LOCALE_ID,
  OnChanges,
  SimpleChanges,
  Optional,
  OnDestroy
} from '@angular/core';
import { DeliveryDateService } from '../../services/delivery-date/delivery-date.service';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService } from 'gung-common';
import { CalendarDate } from '../../models/calender-date';
import { isSameDay, isDate, compareAsc } from 'date-fns';
import { GungError } from './../../models/gung-error';
import { Subject } from 'rxjs';
import { GungModalService } from './../../services/gung-modal/gung-modal.service';
import { takeUntil } from 'rxjs';
import { CartRow } from '../../state/cart/types';

@Component({
  selector: 'lib-delivery-date-picker',
  templateUrl: './delivery-date-picker.component.html',
  styleUrls: ['./delivery-date-picker.component.css']
})
export class DeliveryDatePickerComponent implements OnInit, OnChanges, OnDestroy {
  @Input()
  public label = 'DELIVERY_DATE';

  @Input()
  public suppressLabel = false;

  @Input()
  protected deliveryMethod: string;

  @Input()
  protected rows?: CartRow[];

  @Input()
  protected initialDate?: Date | string;

  @Input('currentselectedDate')
  set _currentselectedDate(value: Date | string) {
    if (!value) {
      return;
    }
    this.currentselectedDate = value;
    if (this.currentselectedDate && !isDate(this.currentselectedDate)) {
      this.currentselectedDate = new Date(this.currentselectedDate);
    }
    const valueDate = this.currentselectedDate as Date;
    this.ngModel = new NgbDate(valueDate.getFullYear(), valueDate.getMonth() + 1, valueDate.getDate());
  }
  protected currentselectedDate?: Date | string;

  @Input()
  emptyStartDate?: boolean;

  @Input()
  protected minInitialDate?: Date | string;

  @Input()
  protected maxDate?: Date | string;

  @Input()
  tooltip?: string;

  @Output()
  protected selectedDate = new EventEmitter<CalendarDate>();

  @Input()
  disabled?: boolean;

  @Input()
  readonly?: boolean;

  @Input()
  public labelBold = false;

  public loaded = false;
  public anyError = false;
  private gungError: GungError;
  public firstDateAvailable: CalendarDate;
  public firstDatePickerDate: NgbDate;
  public maxDatePickerDate: NgbDate;
  public showWeekNumbers = false;

  public ngModel?: NgbDateStruct;

  protected dates: CalendarDate[];

  protected unsubscribe: Subject<void> = new Subject();

  @Input()
  markDisabled: (date: NgbDate, _current: { year: number; month: number }) => boolean;

  public firstDayOfWeek: number = this.dateUtilService.getFirstDayOfWeekRealNumber(true);

  constructor(
    protected deliveryDateService: DeliveryDateService,
    public dateUtilService: DateUtilService,
    protected gungModalService: GungModalService
  ) {
    this.markDisabled = this.markDisabled || this._markDisabled.bind(this);
    this.showWeekNumbers = deliveryDateService.showWeekNumbers;
  }

  ngOnInit() {
    if (this.initialDate && !isDate(this.initialDate)) {
      this.initialDate = new Date(this.initialDate);
    }
    if (this.minInitialDate && !isDate(this.minInitialDate)) {
      this.minInitialDate = this.dateUtilService.parseDate(this.minInitialDate);
    }
    this.updateDeliveryDates();
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      (changes.deliveryMethod && !changes.deliveryMethod.firstChange) ||
      (changes.minInitialDate && !changes.minInitialDate.firstChange)
    ) {
      if (!!changes.minInitialDate && !isDate(this.minInitialDate)) {
        this.minInitialDate = this.dateUtilService.parseDate(this.minInitialDate);
      }
      this.updateDeliveryDates();
    }
  }

  protected updateDeliveryDates() {
    this.deliveryDateService
      .getDeliveryDates(this.deliveryMethod, this.rows)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(dates => {
        this.dates = dates;
        this.firstDateAvailable = this.dates.find(date => {
          return date.valid;
        });
        if (this.firstDateAvailable) {
          this.firstDatePickerDate = new NgbDate(
            this.firstDateAvailable.date.getFullYear(),
            this.firstDateAvailable.date.getMonth() + 1,
            this.firstDateAvailable.date.getDate()
          );
          if (this.emptyStartDate) {
            let dateModel: NgbDate;
            this.ngModel = null;
            if (this.minInitialDate && compareAsc(this.minInitialDate as Date, this.firstDateAvailable.date) >= 0) {
              const minInitialDate = this.minInitialDate as Date;
              dateModel = new NgbDate(
                minInitialDate.getFullYear(),
                minInitialDate.getMonth() + 1,
                minInitialDate.getDate()
              );
              this.firstDatePickerDate = dateModel;
            }
          } else if (!this.ngModel) {
            let dateModel: NgbDate;
            if (this.minInitialDate && compareAsc(this.minInitialDate as Date, this.firstDateAvailable.date) >= 0) {
              const minInitialDate = this.minInitialDate as Date;
              dateModel = new NgbDate(
                minInitialDate.getFullYear(),
                minInitialDate.getMonth() + 1,
                minInitialDate.getDate()
              );
              this.firstDatePickerDate = dateModel;
            } else if (this.initialDate && compareAsc(this.initialDate as Date, this.firstDateAvailable.date) >= 0) {
              const initialDate = this.initialDate as Date;
              const findFirstAvDate = this.dates.find(date => {
                return date.valid && compareAsc(date.date, initialDate as Date) >= 0;
              });
              if (findFirstAvDate) {
                dateModel = new NgbDate(
                  findFirstAvDate.date.getFullYear(),
                  findFirstAvDate.date.getMonth() + 1,
                  findFirstAvDate.date.getDate()
                );
              }
            } else {
              dateModel = this.firstDatePickerDate;
            }
            this.ngModel = dateModel;
            this.dateSelected(dateModel);
          } else {
            const model = NgbDate.from(this.ngModel);
            if (model.before(this.firstDatePickerDate)) {
              this.ngModel = this.firstDatePickerDate;
              this.dateSelected(this.firstDatePickerDate);
            }
            if (this.minInitialDate && compareAsc(this.minInitialDate as Date, this.firstDateAvailable.date) >= 0) {
              const minInitialDate = this.minInitialDate as Date;
              const firstDateAvailable =
                this.dates.find(d => {
                  return d.valid && d.date > minInitialDate;
                })?.date || minInitialDate;
              this.firstDatePickerDate = new NgbDate(
                firstDateAvailable.getFullYear(),
                firstDateAvailable.getMonth() + 1,
                firstDateAvailable.getDate()
              );
              if (
                !this.currentselectedDate &&
                (this.ngModel.day !== this.firstDatePickerDate.day ||
                  this.ngModel.month !== this.firstDatePickerDate.month ||
                  this.ngModel.year !== this.firstDatePickerDate.year)
              ) {
                this.ngModel = this.firstDatePickerDate;
                this.dateSelected(this.firstDatePickerDate);
              }
            }
          }
          this.loaded = true;
        } else {
          this.setError('ERROR_DELIVERY_DATE_UNDEFINED');
        }

        if (this.maxDate) {
          const maxDate = this.maxDate as Date;
          const dateModel = new NgbDate(maxDate.getFullYear(), maxDate.getMonth() + 1, maxDate.getDate());
          this.maxDatePickerDate = dateModel;
        }
      });
  }

  // true if the date should be marked as disabled
  _markDisabled(date: NgbDate, _current: { year: number; month: number }) {
    const foundDate = this.dates
      // first filter out all the invalid dates
      .filter(d => d.valid)
      .map(d => d.date)
      // find a matching date with the one to check
      .filter(
        d =>
          d.getFullYear() === date.year &&
          // js date object is zero indexed
          d.getMonth() + 1 === date.month &&
          d.getDate() === date.day
      );

    // return true if the date wasn't found, false if it was.
    return foundDate.length === 0;
  }

  dateSelected(event: NgbDate) {
    const date = this.dateUtilService.createDateFromNgbDate(event);
    const calendarDate = this.dates.find(
      dateElement =>
        isSameDay(dateElement.date, date) /*  this.dateUtilService.compareDates(dateElement.date, date) === 0 */
    );
    this.selectedDate.emit(calendarDate);
  }

  setError(i18nKeyMessage: string): void {
    this.loaded = false;
    this.anyError = true;
    this.gungError = {
      i18nKeyMessage,
      name: '',
      message: ''
    };

    this.openErrorModal();
  }

  openErrorModal(): void {
    this.gungModalService.openGungErrorHandler(this.gungError);
  }
}
