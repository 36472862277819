import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { SelectionAction } from 'gung-list';
import { Observable, of, forkJoin } from 'rxjs';
import { first, switchMap } from 'rxjs';
import { Customer } from '../../../models';
import {
  ActivityInternal,
  ActivityInternalService
} from '../../../services/activity-internal/activity-internal.service';
import { AuthService } from '../../../services/auth/auth.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { DateUtilService, GungDownloadUrl, S3Item, S3UploadService } from 'gung-common';
import { User } from '../../../state/auth/types';
import { CustomerContactService } from '../../../services/customer-contacts/customer-contact.service';
import { CustomerContact } from '../../../models/customerContact';
import { gungAddRemoveSpinner } from '../../../utils/gung-utils';
import { ActivityInternalCustomerResponsibleService } from '../../../services/activity-internal-customer-responsible.service';

@Component({
  selector: 'lib-activity-internal-view-modal',
  templateUrl: './activity-internal-view-modal.component.html',
  styleUrls: ['./activity-internal-view-modal.component.css']
})
export class ActivityInternalViewModalComponent implements OnInit {
  loaded = false;

  @Input() data: ActivityInternal;

  actions;
  public multipleFiles = true;
  public disabled = false;

  filesToSubmit: File[] = [];
  currentUser: User;
  contact: CustomerContact;

  comment = '';
  isAdmin = false;
  isAdminOrSales = false;

  commentEditMap: boolean[] = [];
  commentOld: string = '';

  constructor(
    public activeModal: NgbActiveModal,
    protected activityInternalService: ActivityInternalService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected backendInterceptor: BackendInterceptor,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    public dateUtilService: DateUtilService,
    public metadataService: MetadataService,
    public customerContactService: CustomerContactService,
    protected s3UploadService: S3UploadService,
    public activityInternalCustomerResponsibleService: ActivityInternalCustomerResponsibleService
  ) {}

  ngOnInit() {
    this.authService
      .getCurrentUser()
      .pipe(first())
      .subscribe(user => {
        this.currentUser = user;
        this.isAdmin = user.roles.indexOf('ADMIN') > -1;
        this.isAdminOrSales = user.roles.indexOf('ADMIN') > -1 || user.roles.indexOf('SALES') > -1;

        this.customerContactService
          .getCustomerContact(this.data.customerId, this.data.contacts)
          .pipe(first())
          .subscribe(contact => {
            this.contact = contact;
            this.loaded = true;
          });
      });
    this.actions = this.getSelectionActions();
  }

  getSelectionActions(): Observable<SelectionAction<string>[]> {
    const actions: SelectionAction<string>[] = this.metadataService
      .getMetadataTableList('ACTIVITYINTERNALSTATUS')
      .map(item => ({
        label: 'MARK_AS_' + item.name.toLocaleUpperCase(),
        performAction: (selection: any) => {
          this.data.status = item.id;
          this.activityInternalService.postActivityInternal(this.data).subscribe();
          return of();
        }
      }));
    // Add delete option
    actions.push({
      label: 'ACTIVITY_DELETE',
      performAction: (selection: any) => {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ` ${this.data.id}?`, {
            size: 'sm'
          })
          .then(result => {
            if (result) {
              this.activityInternalService.deleteActivityInternal(this.data.id).subscribe();
              this.activeModal.close();
            }
          });
        return of();
      }
    });
    return of(actions);
  }

  public onFileSelect(files: FileList) {
    this.filesToSubmit = [];
    for (let file = 0; file < files.length; file++) {
      this.filesToSubmit.push(files.item(file));
    }
  }

  onSave({target}) {
    gungAddRemoveSpinner(target);
    const timestamp = new Date();
    const newComment = {
      comment: this.comment,
      date: timestamp,
      attachments: [],
      user: this.currentUser.name
    };

    let observables: Observable<S3Item>[] = [];

    if (this.filesToSubmit.length > 0) {
      this.filesToSubmit.forEach(doc => {
        observables.push(this.s3UploadService.postDocument(doc));
      });
    } else {
      observables.push(of({} as S3Item));
    }

    forkJoin(observables)
      .pipe(
        first(),
        switchMap(documents => {
          for (const element of documents) {
            newComment.attachments.push(element.s3Uri);
          }
          const savedTicket = Object.assign({}, this.data);
          delete savedTicket.customerName;
          savedTicket.comments.push(newComment as any);
          return this.activityInternalService.postActivityInternal(this.data);
        })
      )
      .subscribe({
        next: ticket => {
          this.comment = '';
          this.filesToSubmit = [];
          this.disabled = false;
          gungAddRemoveSpinner(target);
        },
        error: (error: any) => {
          this.disabled = false;
          gungAddRemoveSpinner(target);
        }
      });
  }

  downloadFile(attachment) {
    let url: string;
    if (attachment.includes('/')) {
      url = GungDownloadUrl(attachment);
    } else {
      url = this.backendInterceptor.getBaseUrl() + this.activityInternalService.getDocumentDownloadUrl(attachment);
    }
    const win = window.open(url, '_blank');
    win.focus();
  }

  editActivityInternal(item: ActivityInternal) {
    this.gungModalService
      .openCreateActivityModal(item, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static' })
      .then(
        result => {
          const activity: ActivityInternal = JSON.parse(result);
          this.data = activity;
        },
        reason => {
          console.log(`Dismissed ${reason}`);
        }
      );
  }

  toggleEdit(index: number) {
    this.commentEditMap[index] = !this.commentEditMap[index];
    
    if (this.commentEditMap[index]) {
      this.commentOld = this.data.comments[index].comment;
    } else {
      this.data.comments[index].comment = this.commentOld;
      this.commentOld = ''; 
    }
  }

  saveExisting() {
    this.activityInternalService.postActivityInternal(this.data).pipe(first()).subscribe({
      next: ticket => {
        this.comment = '';
        this.filesToSubmit = [];
        this.disabled = false;
      },
      error: (error: any) => {
        this.disabled = false;
      }
    });
  }

  removeComment(index: number) {
    this.gungModalService.openConfirmYesNoModal('DELETE_COMMENT', 'DELETE_COMMENT_CONFIRMATION_TEXT').then(
      result => {
        if (result === true) {
          this.data.comments.splice(index, 1);
          this.saveExisting();
        }
      },
      reject => {}
    )
  }

  isImage(url) {
    return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
  }
}
