import { Component, OnInit, OnChanges, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OptionsListMulti } from 'gung-common';
import { ListItemRendererComponent } from 'gung-list';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs';
import { GungBase } from '../../../models';
import { DigitalAssetsFolderListConfigService } from '../../../services/digital-assets-folder-list-config/digital-assets-folder-list-config.service';
import { DigitalAssetsSearchListConfigService } from '../../../services/digital-assets-search/digital-assets-search-list-config.service';
import { DigitalAsset, DigitalAssetsService } from '../../../services/digital-assets/digital-assets.service';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import { gungAddRemoveSpinner } from '../../../utils/gung-utils';

@Component({
  selector: 'lib-digital-assets-folder-grid-view',
  templateUrl: './digital-assets-folder-grid-view.component.html',
  styleUrls: ['./digital-assets-folder-grid-view.component.css']
})
export class DigitalAssetsFolderGridViewComponent
  extends ListItemRendererComponent<DigitalAsset[]>
  implements OnInit, OnChanges, AfterViewInit
{
  public isManagement = false;

  form: FormGroup;
  isSubmitted: boolean;
  parentId: string;

  digitalAsset: DigitalAsset;
  public userGroups: OptionsListMulti[];
  systemSettings: GungBase;

  constructor(
    protected gungModalService: GungModalService,
    protected router: Router,
    protected route: ActivatedRoute,
    protected formBuilder: FormBuilder,
    protected translationService: TranslateService,
    protected digitalAssetsService: DigitalAssetsService,
    protected modalService: NgbModal,
    protected gungFlowService: GungFlowService,
  ) {
    super();
  }

  ngOnInit() {
    this.parentId = undefined;
    this.isManagement = this.router.url.startsWith('/digital-assets/management');
    if (this.route.snapshot.url.length > 0) {
      this.parentId = this.route.snapshot.url[this.route.snapshot.url.length - 1].path;
    }
    this.gungFlowService
      .getSystemGung()
      .pipe(first())
      .subscribe(systemSettings => (this.systemSettings = systemSettings));
  }

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  initForm() {
    this.form = this.formBuilder.group({
      name: this.formBuilder.control((this.digitalAsset && this.digitalAsset.name) || '', [Validators.required]),
      image: this.formBuilder.control('', []),
      content: this.formBuilder.control(
        { value: (this.digitalAsset && this.digitalAsset.extra.content) || '', disabled: !!this.digitalAsset },
        [Validators.required]
      ),
      roles: this.formBuilder.control((this.digitalAsset && this.digitalAsset.extra.roles) || '', []),
      groups: this.formBuilder.control((this.digitalAsset && this.digitalAsset.extra.groups) || '', []),
      sequence: this.formBuilder.control(this.digitalAsset?.extra?.sequence || '', [])
    });
  }

  open(content, digitalAsset?: DigitalAsset) {
    this.digitalAsset = undefined;
    if (digitalAsset) {
      this.digitalAsset = digitalAsset;
    }
    this.initForm();
    const settingsGroups = this.systemSettings.extra?.userGroups || [];
    this.userGroups = settingsGroups.map(g => ({ id: g, name: undefined, selected: false }));
    if (this.digitalAsset && this.digitalAsset.extra?.groups) {
      const groups = this.digitalAsset.extra.groups.split(',');
      for (const group of groups) {
        this.userGroups.find(g => g.id === group).selected = true;
      }
    }
    this.modalService
      .open(content, { ariaLabelledBy: 'modal-basic-title', size: 'lg', backdrop: 'static', keyboard: true })
      .result.then(
        result => {},
        reason => {}
      );
  }

  onSubmit(inputFile, activeModal, event?) {
    const { target } = event || {};
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }
    const formRaw = this.form.getRawValue();
    this.form.disable();
    this.isSubmitted = true;
    gungAddRemoveSpinner(target);

    if (!this.digitalAsset) {
      const newDigitalAsset: DigitalAsset = {
        name: formRaw.name,
        extra: {
          type: 'folder', // If current path is folder you will only be able to create a folder with contente type folders or digitial assets
          content: formRaw.content,
          roles: formRaw.roles,
          groups: formRaw.groups,
          sequence: formRaw.sequence
        }
      } as any;

      if (this.parentId) {
        newDigitalAsset.extra.parentId = this.parentId;
      }

      this.digitalAssetsService.postDigitalAsset(newDigitalAsset).subscribe(
        digitalAsset => {
          const files: File[] = inputFile.files;
          if (files.length > 0) {
            const formData = new FormData();
            formData.append('file', files[0]);
            this.digitalAssetsService.postDigitalAssetImages(digitalAsset.id, formData).subscribe(
              uploaded => {
                digitalAsset.images = uploaded.images;
                this.digitalAssetsService.putDigitalAsset(digitalAsset).subscribe(
                  {
                    next: (digitalAssetWithCover) => {
                      this.isSubmitted = false;
                      gungAddRemoveSpinner(target);
                      this.form.enable();
                      this.router.navigate([digitalAssetWithCover.id], { relativeTo: this.route, queryParams: { new: 'true'} });
                      activeModal.close(digitalAssetWithCover);
                    },
                    error: (error) => {
                      this.isSubmitted = false;
                      gungAddRemoveSpinner(target);
                      this.form.enable();
                    }
                  }
                );
              },
              error => {
                this.isSubmitted = false;
                gungAddRemoveSpinner(target);
                this.form.enable();
              }
            );
          } else {
            this.isSubmitted = false;
            gungAddRemoveSpinner(target);
            this.form.enable();
            this.router.navigate([digitalAsset.id], { relativeTo: this.route, queryParams: { new: 'true'} });
            activeModal.close(digitalAsset);
          }
        },
        error => {
          this.isSubmitted = false;
          gungAddRemoveSpinner(target);
          this.form.enable();
        }
      );
    } else {
      const updateDigitalAsset: DigitalAsset = {
        ...this.digitalAsset,
        name: formRaw.name,
        extra: {
          ...this.digitalAsset.extra,
          roles: formRaw.roles,
          groups: formRaw.groups,
          sequence: formRaw.sequence
        }
      } as any;
      const files: File[] = inputFile.files;
      if (files.length > 0) {
        if (updateDigitalAsset.images.length > 0) {
          // remove old Images
          forkJoin(
            updateDigitalAsset.images.map(i =>
              this.digitalAssetsService.deleteDigitalAssetImages(updateDigitalAsset.id, i).pipe(first())
            )
          ).subscribe(_ => {});
        }
        // upload new image
        const formData = new FormData();
        formData.append('file', files[0]);
        this.digitalAssetsService.postDigitalAssetImages(updateDigitalAsset.id, formData).subscribe(
          uploaded => {
            if (uploaded.images.length > 0) {
              updateDigitalAsset.images = [uploaded.images[uploaded.images.length - 1]];
            }
            this.digitalAssetsService.putDigitalAsset(updateDigitalAsset).subscribe(
              digitalAssetWithCover => {
                this.isSubmitted = false;
                gungAddRemoveSpinner(target);
                this.form.enable();
                activeModal.close(digitalAssetWithCover);
              },
              error => {
                this.isSubmitted = false;
                gungAddRemoveSpinner(target);
                this.form.enable();
              }
            );
          },
          error => {
            this.isSubmitted = false;
            gungAddRemoveSpinner(target);
            this.form.enable();
          }
        );
      } else {
        this.digitalAssetsService.putDigitalAsset(updateDigitalAsset).subscribe(
          digitalAsset => {
            this.isSubmitted = false;
            gungAddRemoveSpinner(target);
            this.form.enable();
            activeModal.close(digitalAsset);
          },
          error => {
            this.isSubmitted = false;
            gungAddRemoveSpinner(target);
            this.form.enable();
          }
        );
      }
    }
  }

  onDelete(digitalAsset: DigitalAsset) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + '?', { size: 'sm' })
      .then(result => {
        if (result) {
          this.digitalAssetsService.deleteDigitalAsset(digitalAsset.id).subscribe(_ => {
            this.data = this.data.filter(dA => dA.id !== digitalAsset.id);
          });
        }
      });
  }

  setSelectedValues(event: string[]): void {
    this.form.get('groups').setValue(event.join(','));
  }
}
