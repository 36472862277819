import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DateUtilService, JsonViewModal } from 'gung-common';
import { SupplierPurchaseOrderHistory } from '../../../models';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-supplier-po-history-modal',
  templateUrl: './supplier-po-history-modal.component.html',
  styleUrls: ['./supplier-po-history-modal.component.css']
})
export class SupplierPoHistoryModalComponent implements OnInit {
  isLoading = false;

  @Input()
  data: SupplierPurchaseOrderHistory[];
  isMap: boolean;

  constructor(
    public activeModal: NgbActiveModal,
    protected modalService: GungModalService,
    protected dateUtilService: DateUtilService
  ) {}

  ngOnInit(): void {}

  showJSON(row) {
    const jsonViewModal: JsonViewModal = {
      json: row,
      title: undefined
    };
    this.modalService.openJSONModal(jsonViewModal);
  }
}
