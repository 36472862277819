import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Router } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { GungListModule } from 'gung-list';
import {
  GungStandardModule,
  CheckoutConfigService,
  OrderListConfigService,
  InvoiceListConfigService,
  CustomerContactListConfigService,
  CustomerListConfigService,
  SalesCartConfigService,
  AuthGuard,
  CartTotalsService,
  PriceService,
  ProductListConfigService,
  OpenOrdersConfigService,
  OrderService,
  DeliveryDateService,
  CheckoutQuantityValidationService,
  DeliveryLocationListConfigService,
  BaseViewConfigService,
  BarcodeScannerConfigService,
  SupplierDynamicColumnsService,
  SupplierPoActionConfigService,
  SupplierPoListConfigService,
  GungModalService,
  CartListCheckoutConfigService,
  ActivityInternalCustomerResponsibleService,
  ProductDetailWrapperComponent
} from 'gung-standard';
import { AssignJeevesDefaultsComponent } from './components/checkout/steps/assign-jeeves-defaults/assign-jeeves-defaults.component';
import { JeevesCheckoutConfigService } from './services/jeeves-checkout-config/jeeves-checkout-config.service';
import { DeliveryLocationsJeevesComponent } from './components/checkout/steps/delivery-locations-jeeves/delivery-locations-jeeves.component';
import { JeevesOrderCardListComponent } from './components/jeeves-order-card-list/jeeves-order-card-list.component';
import { JeevesOrderListConfigService } from './services/jeeves-order-list-config/jeeves-order-list-config.service';
import { JeevesInvoiceCardListComponent } from './components/jeeves-invoice-card-list/jeeves-invoice-card-list.component';
import { OrderTermsJeevesComponent } from './components/checkout/steps/order-terms-jeeves/order-terms-jeeves.component';
import { JeevesCustomerContactListConfigService } from './services/jeeves-customer-contact-list-config/jeeves-customer-contact-list-config.service';
import { JeevesCustomerContactCardListComponent } from './components/jeeves-customer-contact-card-list/jeeves-customer-contact-card-list.component';
import { SummarizeOrderJeevesComponent } from './components/checkout/steps/summarize-order-jeeves/summarize-order-jeeves.component';
import { JeevesCustomerListCardComponent } from './components/jeeves-customer-list-card/jeeves-customer-list-card.component';
import { JeevesCustomerListConfigService } from './services/jeeves-customer-list-config/jeeves-customer-list-config.service';
import { JeevesCustomerDetailsComponent } from './components/jeeves-customer-details/jeeves-customer-details.component';

import { JeevesSalesCartListComponent } from './components/jeeves-sales-cart-list/jeeves-sales-cart-list.component';

import { JeevesSalesCartConfigService } from './services/jeeves-sales-cart-config/jeeves-sales-cart-config.service';
import { JeevesOpenOrdersComponent } from './components/jeeves-open-orders/jeeves-open-orders.component';
import { JeevesOpenOrderListComponent } from './components/jeeves-open-order-list/jeeves-open-order-list.component';
import { JeevesQuotesListComponent } from './components/jeeves-quotes-list/jeeves-quotes-list.component';
import { JeevesOpenOrderTableComponent } from './components/jeeves-open-order-table/jeeves-open-order-table.component';
import { JeevesEditOrderModalComponent } from './components/jeeves-edit-order-modal/jeeves-edit-order-modal.component';
import { JeevesEditOrderrowModalComponent } from './components/jeeves-edit-orderrow-modal/jeeves-edit-orderrow-modal.component';
import { JeevesCartTotalsService } from './services/jeeves-cart-totals.service';
import { JeevesOrderDetailsComponent } from './components/jeeves-order-details/jeeves-order-details.component';
import { JeevesProductDetailsComponent } from './components/jeeves-product-details/jeeves-product-details.component';
import { JeevesPriceService } from './services/jeeves-price-service/jeeves-price.service';
import { JeevesProductGridComponent } from './components/jeeves-product-grid/jeeves-product-grid.component';
import { JeevesProductCardComponent } from './components/jeeves-product-card/jeeves-product-card.component';
import { JeevesProductListConfigService } from './services/jeeves-product-list-config/jeeves-product-list-config.service';
import { JeevesProductTableComponent } from './components/jeeves-product-table/jeeves-product-table.component';
import { CommonModule, DatePipe } from '@angular/common';
import { JeevesAddCustomerModalComponent } from './components/jeeves-add-customer-modal/jeeves-add-customer-modal.component';
import { JeevesInvoiceTableComponent } from './components/jeeves-invoice-table/jeeves-invoice-table.component';
import { JeevesInvoiceListComponent } from './components/jeeves-invoice-list/jeeves-invoice-list.component';
import { JeevesInvoiceListTableConfigService } from './services/jeeves-invoice-list-table-config/jeeves-invoice-list-table-config.service';
import { JeevesEditContactModalComponent } from './components/jeeves-edit-contact-modal/jeeves-edit-contact-modal.component';
import { JeevesCustomerDetailsWrapperComponent } from './components/jeeves-customer-details-wrapper/jeeves-customer-details-wrapper.component';
import { JeevesOpenOrdersListTableConfigService } from './services/jeeves-open-orders-list-table-config/jeeves-open-orders-list-table-config.service';
import { JeevesAddContactModalComponent } from './components/jeeves-add-contact-modal/jeeves-add-contact-modal.component';
import { JeevesOrderService } from './services/jeeves-order.service';
import { AssignOrderRowsJeevesComponent } from './components/checkout/steps/assign-order-rows-jeeves/assign-order-rows-jeeves.component';
import { JeevesAddOrderRowModalComponent } from './components/jeeves-add-order-row-modal/jeeves-add-order-row-modal.component';
import { JeevesDeliveryDateService } from './services/jeeves-delivery-date-service/jeeves-delivery-date.service';
import { GungDashboardComponent, GungDashboardModule, ImportBudgetComponent } from 'gung-dashboard';
import { JeevesProductGridViewComponent } from './components/jeeves-product-grid-view/jeeves-product-grid-view.component';
import { JeevesCheckoutQuantityValidationService } from './services/jeeves-quantity-validation/jeeves-checkout-quantity-validation.service';
import { JeevesDeliveryAddressStepComponent } from './components/jeeves-delivery-address-step/jeeves-delivery-address-step.component';
import { JeevesDeliveryLocationListCardComponent } from './components/jeeves-delivery-location-list-card/jeeves-delivery-location-list-card.component';
import { JeevesDeliveryLocationListConfigService } from './services/jeeves-delivery-location-list-config/jeeves-delivery-location-list-config.service';
import { JeevesCustomerDeliveryLocationCartListComponent } from './components/jeeves-customer-delivery-location-cart-list/jeeves-customer-delivery-location-cart-list.component';
import { JeevesCustomerDeliveryLocationModalComponent } from './components/jeeves-customer-delivery-location-modal/jeeves-customer-delivery-location-modal.component';
import { JeevesUpdateKuarModalComponent } from './components/jeeves-update-kuar-modal/jeeves-update-kuar-modal.component';
import { OrderTermsWithCartListJeevesComponent } from './components/checkout/steps/order-terms-with-cart-list-jeeves/order-terms-with-cart-list-jeeves.component';
import { JeevesBaseViewConfigService } from './services/jeeves-base-view-config/jeeves-base-view-config.service';
import { JeevesBarcodeQuickCheckoutComponent } from './components/jeeves-barcode-scanner/jeeves-barcode-quick-checkout/jeeves-barcode-quick-checkout.component';
import { JeevesBarcodeScannerConfigService } from './services/jeeves-barcode-scanner-config/jeeves-barcode-scanner-config.service';
import { CommonModalService, GungCommonModule } from 'gung-common';
import { JeevesCreditBlockedCheckerComponent } from './components/checkout/steps/jeeves-credit-blocked-checker/jeeves-credit-blocked-checker.component';
import { CartDiscountMatrixComponent } from './components/cart-discount-matrix/cart-discount-matrix.component';
import { JeevesSupplierDynamicColumnsService } from './services/jeeves-supplier-dynamic-columns/jeeves-supplier-dynamic-columns.service';
import { JeevesCheckoutAddRowModalComponent } from './components/jeeves-checkout-add-row-modal/jeeves-checkout-add-row-modal.component';
import { JeevesProductSelectDropdownComponent } from './components/jeeves-product-select-dropdown/jeeves-product-select-dropdown.component';
import { JeevesClaimListComponent } from './components/jeeves-claim-list/jeeves-claim-list.component';
import { JeevesClaimTableViewComponent } from './components/jeeves-claim-table-view/jeeves-claim-table-view.component';
import { JeevesCreateClaimModalComponent } from './components/jeeves-create-claim-modal/jeeves-create-claim-modal.component';
import { JeevesSupplierPoActionConfigService } from './services/jeeves-supplier-po-action-config/jeeves-supplier-po-action-config.service';
import { JeevesUserClaimListComponent } from './components/jeeves-user-claim-list/jeeves-user-claim-list.component';
import { JeevesSupplierPoListConfigService } from './services/jeeves-supplier-po-list-config/jeeves-supplier-po-list-config.service';
import { JeevesSupplierPoSplitModalComponent } from './components/supplier/jeeves-supplier-po-split-modal/jeeves-supplier-po-split-modal.component';
import { JeevesModalService } from './services/jeeves-modal-service/jeeves-modal.service';
import { JeevesGungModalService } from './services/jeeves-gung-modal/jeeves-gung-modal.service';
import { JeevesSupplierPoListTableViewComponent } from './components/supplier/jeeves-supplier-po-list-table-view/jeeves-supplier-po-list-table-view.component';
import { JeevesCartListCheckoutConfigService } from './services/jeeves-cart-list-checkout-config/jeeves-cart-list-checkout-config.service';
import { JeevesActivityInternalCustomerResponsibleService } from './services/jeeves-activity-internal-customer-responsible.service';
import { JeevesOpenOrderTableListComponent } from './components/jeeves-open-order-table-list/jeeves-open-order-table-list.component';
import { JeevesAvailabilityModalComponent } from './components/jeeves-availability-modal/jeeves-availability-modal.component';

@NgModule({
  declarations: [
    AssignJeevesDefaultsComponent,
    DeliveryLocationsJeevesComponent,
    JeevesOrderCardListComponent,
    JeevesInvoiceCardListComponent,
    OrderTermsJeevesComponent,
    JeevesCustomerContactCardListComponent,
    SummarizeOrderJeevesComponent,
    JeevesCustomerListCardComponent,
    JeevesCustomerDetailsComponent,
    JeevesSalesCartListComponent,
    JeevesOpenOrdersComponent,
    JeevesOpenOrderListComponent,
    JeevesQuotesListComponent,
    JeevesOpenOrderTableComponent,
    JeevesEditOrderModalComponent,
    JeevesEditOrderrowModalComponent,
    JeevesAddCustomerModalComponent,
    JeevesOrderDetailsComponent,
    JeevesProductDetailsComponent,
    JeevesProductGridComponent,
    JeevesProductCardComponent,
    JeevesProductTableComponent,
    JeevesInvoiceTableComponent,
    JeevesInvoiceListComponent,
    JeevesEditContactModalComponent,
    JeevesCustomerDetailsWrapperComponent,
    JeevesAddContactModalComponent,
    AssignOrderRowsJeevesComponent,
    JeevesAddOrderRowModalComponent,
    JeevesProductGridViewComponent,
    JeevesDeliveryAddressStepComponent,
    JeevesDeliveryLocationListCardComponent,
    JeevesCustomerDeliveryLocationCartListComponent,
    JeevesCustomerDeliveryLocationModalComponent,
    JeevesUpdateKuarModalComponent,
    OrderTermsWithCartListJeevesComponent,
    JeevesBarcodeQuickCheckoutComponent,
    JeevesCreditBlockedCheckerComponent,
    CartDiscountMatrixComponent,
    JeevesCheckoutAddRowModalComponent,
    JeevesProductSelectDropdownComponent,
    JeevesClaimListComponent,
    JeevesClaimTableViewComponent,
    JeevesCreateClaimModalComponent,
    JeevesUserClaimListComponent,
    JeevesSupplierPoSplitModalComponent,
    JeevesSupplierPoListTableViewComponent,
    JeevesOpenOrderTableListComponent,
    JeevesAvailabilityModalComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    NgbModule,
    GungCommonModule,
    GungListModule,
    GungStandardModule,
    GungDashboardModule,
    TranslateModule
  ],
  providers: [
    { provide: CheckoutConfigService, useClass: JeevesCheckoutConfigService },
    {
      provide: OrderListConfigService,
      useClass: JeevesOrderListConfigService
    },
    {
      provide: InvoiceListConfigService,
      useClass: JeevesInvoiceListTableConfigService
    },
    {
      provide: OpenOrdersConfigService,
      useClass: JeevesOpenOrdersListTableConfigService
    },
    {
      provide: CustomerContactListConfigService,
      useClass: JeevesCustomerContactListConfigService
    },
    {
      provide: CustomerListConfigService,
      useClass: JeevesCustomerListConfigService
    },
    {
      provide: SalesCartConfigService,
      useClass: JeevesSalesCartConfigService
    },

    {
      provide: PriceService,
      useClass: JeevesPriceService
    },
    {
      provide: ProductListConfigService,
      useClass: JeevesProductListConfigService
    },
    {
      provide: OrderService,
      useClass: JeevesOrderService
    },
    {
      provide: DeliveryDateService,
      useClass: JeevesDeliveryDateService
    },
    {
      provide: CheckoutQuantityValidationService,
      useClass: JeevesCheckoutQuantityValidationService
    },
    {
      provide: DeliveryLocationListConfigService,
      useClass: JeevesDeliveryLocationListConfigService
    },
    {
      provide: BaseViewConfigService,
      useClass: JeevesBaseViewConfigService
    },
    {
      provide: BarcodeScannerConfigService,
      useClass: JeevesBarcodeScannerConfigService
    },
    {
      provide: SupplierDynamicColumnsService,
      useClass: JeevesSupplierDynamicColumnsService
    },
    {
      provide: SupplierPoActionConfigService,
      useClass: JeevesSupplierPoActionConfigService
    },
    {
      provide: SupplierPoListConfigService,
      useClass: JeevesSupplierPoListConfigService
    },
    {
      provide: CommonModalService,
      useClass: JeevesGungModalService
    },
    {
      provide: GungModalService,
      useClass: JeevesGungModalService
    },
    {
      provide: CartListCheckoutConfigService,
      useClass: JeevesCartListCheckoutConfigService
    },
    {
      provide: ActivityInternalCustomerResponsibleService,
      useClass: JeevesActivityInternalCustomerResponsibleService
    },
    DatePipe
  ],
  exports: [
    AssignJeevesDefaultsComponent,
    DeliveryLocationsJeevesComponent,
    OrderTermsJeevesComponent,
    SummarizeOrderJeevesComponent,
    JeevesCustomerDetailsComponent,
    JeevesOpenOrdersComponent,
    JeevesInvoiceCardListComponent,
    OrderTermsJeevesComponent,
    JeevesOpenOrdersComponent,
    JeevesEditOrderModalComponent,
    JeevesInvoiceListComponent,
    JeevesEditOrderrowModalComponent,
    JeevesAddCustomerModalComponent,
    JeevesProductDetailsComponent,
    JeevesProductGridComponent,
    JeevesProductCardComponent,
    JeevesProductTableComponent,
    JeevesCustomerDetailsWrapperComponent,
    JeevesCustomerListCardComponent,
    JeevesAddContactModalComponent,
    AssignOrderRowsJeevesComponent,
    JeevesProductGridViewComponent,
    JeevesDeliveryAddressStepComponent,
    OrderTermsWithCartListJeevesComponent,
    CartDiscountMatrixComponent,
    JeevesCheckoutAddRowModalComponent,
    JeevesProductSelectDropdownComponent,
    JeevesClaimListComponent
  ]
})
export class GungStandardJeevesModule {
  constructor(router: Router) {
    const config = router.config;

    router.resetConfig([
      {
        path: 'product/:productId',
        canActivate: [AuthGuard],
        component: ProductDetailWrapperComponent
      },
      {
        path: 'customers/:customerId',
        component: JeevesCustomerDetailsComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-dashboard',
        component: GungDashboardComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'sales-dashboard/import/budget',
        canActivate: [AuthGuard],
        component: ImportBudgetComponent
      },
      {
        path: 'orders/:orderId',
        canActivate: [AuthGuard],
        component: JeevesOrderDetailsComponent
      },
      {
        path: 'claims',
        canActivate: [AuthGuard],
        component: JeevesClaimListComponent
      },
      {
        path: 'customer-claims/:customerId',
        canActivate: [AuthGuard],
        component: JeevesUserClaimListComponent
      },
      ...config
    ]);
  }
}
