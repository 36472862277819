import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoggedInUsersService {
  protected baseUrl: string = 'json/admin/active-users';
  protected legacyBaseUrl: string = 'json/actuator/active-users';

  constructor(protected http: HttpClient) {}

  getLoggedInUsers(): Observable<string[]> {
    return this.http.get<string[]>(this.baseUrl).pipe(
      // Fallback to the legacy url if we get an error on the primary one. This can happen for all backends that have
      // not been updated to a version newer than today (2023-11-06).
      catchError(() => this.http.get<string[]>(this.legacyBaseUrl)),
      // If even the fallback request fails, return an empty array. This can happen when we have backends that have
      // not been updated for a long time. We want to ensure that we get a result in order not to break consumer lists.
      catchError(() => of(undefined))
    );
  }
}
