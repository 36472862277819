<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title" translate>EMAIL_CONFIRMATION</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div *ngIf="!loading; else loader">
    <span class="d-block" *ngFor="let contact of contacts">
      <lib-gung-checkbox-input-horizontal
        [gungTranslate]="contact.value || ''"
        [(ngModel)]="contact.selected"
      ></lib-gung-checkbox-input-horizontal>
    </span>
    <div>
      <div class="row some-padding">
        <lib-gung-text-input-horizontal gungTranslate="INSERT_EMAIL" [(ngModel)]="manualEmailAddress">
        </lib-gung-text-input-horizontal>
        <button class="btn btn-primary btn-sm btn-adjustments" (click)="sendEmail()" translate>SEND</button>
      </div>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light" aria-label="Close" (click)="activeModal.close()" translate>CANCEL</button>
  <button type="button" class="btn btn-outline-dark" (click)="sendOrderConfirmation()" translate>SEND</button>
</div>

<ng-template #loader translate> LOADING <i class="fa fa-spinner fa-spin fa-1x"></i> </ng-template>
