import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'lib-lang-select',
  templateUrl: './lang-select.component.html',
  styleUrls: ['./lang-select.component.css']
})
export class LangSelectComponent implements OnInit {
  currentLang: string;
  currentLangClass: string;

  constructor(protected translateService: TranslateService) {}

  ngOnInit() {
    this.updateData();
    this.translateService.onLangChange.subscribe(_ => this.updateData.bind(this)());
  }

  updateData() {
    this.currentLang = this.translateService.currentLang;
    if (this.currentLang === 'en') {
      this.currentLangClass = 'gb';
    } else {
      this.currentLangClass = this.currentLang;
    }
  }
  changeLanguage(langCode: string): void {
    this.translateService.use(langCode);
  }
}
