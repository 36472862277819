<nav aria-label="breadcrumb lib-breadcrumb">
  <ol class="breadcrumb">
    <!-- <li class="breadcrumb-item" [attr.aria-current]="breadcrumbLinks.length === 0 ? 'page' : undefined">
      <a [ngClass]="{'active-breadcrumb': breadcrumbLinks.length === 0}" [routerLink]="'/categories'" translate>PRODUCTS</a>
    </li> -->
    <li
      *ngFor="let breadcrumb of breadcrumbLinks; let last = last"
      class="breadcrumb-item"
      [attr.aria-current]="last ? 'page' : undefined"
    >
      <a [ngClass]="{ 'active-breadcrumb': last }" [routerLink]="recursiveUrl ? breadcrumb.recursiveUrl : ('/'+parentUrl+'/' + breadcrumb.url)" translate>{{
        breadcrumb.label
      }}</a>
    </li>
  </ol>
</nav>
