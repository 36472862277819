import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ListItemRendererComponent } from 'gung-list';
import { Order } from '../../models/order';

@Component({
  selector: 'lib-quotes-card-list',
  templateUrl: './quotes-card-list.component.html',
  styleUrls: ['./quotes-card-list.component.css']
})
export class QuotesCardListComponent extends ListItemRendererComponent<Order> implements OnInit, AfterViewInit {
  constructor() {
    super();
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    this.renderFinished?.emit();
  }
}
