import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectProductImageSizeExportConfigService } from '../../services/select-product-image-size-export-config.service';

@Component({
  selector: 'lib-select-product-image-export-size-modal',
  templateUrl: './select-product-image-export-size-modal.component.html',
  styleUrls: ['./select-product-image-export-size-modal.component.css']
})
export class SelectProductImageExportSizeModalComponent {
  constructor(
    protected activeModal: NgbActiveModal,
    protected selectProductImageSizeExportConfigService: SelectProductImageSizeExportConfigService
  ) { }

  @Input()
  showEnableBackground = true;
  enableBackground = false;
  selectedOption = 0;
  selectedColor = 'ffffff';
  @Input()
  sizeOptions: {
    id: number;
    name: string;
    startUrl: string;
    imageOptions: string;
    transformations: string;
    format: string;
    forceBackgroundColor?: boolean;
  }[] = this.selectProductImageSizeExportConfigService.sizeOptions;

  changeSelectedColor() {
    this.selectedColor = this.selectedColor.replace('#', '');
  }

  public selectedOptionChanged(): void {
    if (!!this.sizeOptions[this.selectedOption].forceBackgroundColor) {
      this.enableBackground = true;
    } else {
      this.enableBackground;
    }
  }

  save() {
    const sizeOption = JSON.parse(JSON.stringify(this.sizeOptions[this.selectedOption]));
    if (this.enableBackground) {
      // Made changes in BE 230417, if there is a problem with this you likely just need to update
      const background_color = ':fill(' + this.selectedColor + '):background_color(' + this.selectedColor + ')';
      if (this.sizeOptions[this.selectedOption].transformations.indexOf('filters:') < 0) {
        this.sizeOptions[this.selectedOption].transformations += 'filters';
      }
      this.sizeOptions[this.selectedOption].transformations =
        this.sizeOptions[this.selectedOption].transformations + background_color;
    }
    const result = {
      success: true,
      imageTransformations:
        this.sizeOptions[this.selectedOption].imageOptions + this.sizeOptions[this.selectedOption].transformations,
      sizeOption,
      selectedColor: this.enableBackground ? this.selectedColor : undefined,
      format: this.sizeOptions[this.selectedOption].format
    };

    this.activeModal.close(result);
  }

  filterForHexColors(filterKey) {
    return (
      (filterKey.charCode >= 48 && filterKey.charCode <= 57) ||
      (filterKey.charCode >= 65 && filterKey.charCode <= 70) ||
      (filterKey.charCode >= 97 && filterKey.charCode <= 102)
    );
  }

  changeBackgroundStatus(event: boolean) {
    this.enableBackground = event;
  }

  close() {
    const result = {
      success: false,
      data: ''
    };

    this.activeModal.close(result);
  }
}
