import { Injectable } from '@angular/core';
import { OnInitEffects, createEffect, ofType, Actions } from '@ngrx/effects';
import {
  CustomerActionTypes,
  SelectCustomer,
  CustomerSelected,
  GetDefaultCustomer,
  ClearSelectedCustomer
} from './actions';
import { map, filter, mergeMap, tap, first, switchMap, catchError } from 'rxjs';
import { CustomerService } from '../../services/customers/customer.service';
import { Action } from '@ngrx/store';
import { ActionTypes, UserReceived } from '../auth/actions';
import { of } from 'rxjs';
import { Customer } from '../../models/customer';

@Injectable()
export class CustomerEffects {
  constructor(private actions$: Actions, private customerService: CustomerService) {}

  selectCustomer$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === CustomerActionTypes.SelectCustomer),
      map(action => action as SelectCustomer),
      map(action => action.payload.customerId),
      // switchMap(id => this.customerService.getCustomer(id, false)), // FC: fix when no customer present
      switchMap(id =>
        this.customerService
          .getCustomer(id, false)
          .pipe(catchError(err => of({ id: undefined, name: undefined, extra: undefined } as Customer)))
      ),
      map(customer => new CustomerSelected(customer))
    )
  );

  getDefaultCustomer$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CustomerActionTypes.GetDefaultCustomer),
      switchMap(_ => {
        // return this.customerService.getDefaultCustomer(true); // FC: fix when no customer present
        return this.customerService
          .getDefaultCustomer(true)
          .pipe(catchError(err => of({ id: undefined, name: undefined, extra: undefined } as Customer)));
      }),
      map(cust => (cust !== null ? new CustomerSelected(cust) : new ClearSelectedCustomer()))
    )
  );

  getCustomerSelected$ = createEffect(
    () =>
      this.actions$.pipe(
        filter(action => action.type === CustomerActionTypes.CustomerSelected),
        map(action => action as CustomerSelected),
        map(action => action.payload),
        tap(customer => localStorage.setItem('selectedCustomerId', customer.id))
      ),
    { dispatch: false }
  );

  userReceived$ = createEffect(() =>
    this.actions$.pipe(
      filter(action => action.type === ActionTypes.UserReceived),
      map(action => action as UserReceived),
      map(() => {
        let customerId: string | null = null;

        const customerIdFromLocalstorage = localStorage.getItem('selectedCustomerId');
        if (customerIdFromLocalstorage != null) {
          customerId = customerIdFromLocalstorage;
        }
        return customerId;
      }),
      switchMap(customerId => {
        if (!customerId) {
          return of(new GetDefaultCustomer());
        }
        return this.customerService
          .getCustomerAllowed(customerId)
          .pipe(map(allowed => (allowed ? new SelectCustomer({ customerId }) : new GetDefaultCustomer())));
      })
    )
  );
}
