import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AbstractSelectionService } from 'gung-list';
import { AssortmentService } from '../../../services/assortment.service';
import { PimConceptListConfigService } from '../../../services/pim-concept-list-config/pim-concept-list-config.service';
import { ProductSelectionService } from '../../../services/product-selection-service/product-selection.service';

@Component({
  selector: 'lib-pim-concept-wrapper',
  templateUrl: './pim-concept-wrapper.component.html',
  styleUrls: ['./pim-concept-wrapper.component.css'],
  providers: [
    {
      provide: AbstractSelectionService,
      useClass: ProductSelectionService
    }
  ]
})
export class PimConceptWrapperComponent implements OnInit {
  assortmentId: string;

  constructor(
    protected route: ActivatedRoute,
    protected assortmentService: AssortmentService,
    public pimConceptListConfigService: PimConceptListConfigService
  ) {}

  ngOnInit() {
    const assortmentId = this.route.snapshot.paramMap.get('assortmentId');
    this.assortmentService.getAssortment(assortmentId).subscribe(assortment => {
      const itemProperties = assortment.extra.itemProperties;
      this.assortmentId = assortment.id;
      const selectIds = this.route.snapshot.queryParamMap.get('select');
      // if (selectIds && selectIds.trim() !== '') {
      //   this.pimConceptListConfigService.selectedIds = selectIds.split(',');
      // }
      this.pimConceptListConfigService.itemProperties = itemProperties;
      this.pimConceptListConfigService.assortmentId = assortment.id;
    });
  }
}
