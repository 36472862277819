import { Component, OnInit, ViewChild } from '@angular/core';
import { FilterListComponent } from 'gung-list';
import { OpenPurchaseOrdersListConfigService } from '../../services/open-purchase-orders-list-config/open-purchase-orders-list-config.service';
import { OpenPurchaseOrders } from '../../services/open-purchase-orders/open-purchase-orders.service';

@Component({
  selector: 'lib-open-purchase-orders',
  template:
    '<h3 class="text-center" translate>OPEN_PURCHASE_ORDER</h3><lib-filter-list #productList [configService]="listConfigService"></lib-filter-list>',
  styleUrls: ['./open-purchase-orders.component.css']
})
export class OpenPurchaseOrdersComponent implements OnInit {
  @ViewChild('productList') productList: FilterListComponent<OpenPurchaseOrders>;

  constructor(public listConfigService: OpenPurchaseOrdersListConfigService) {}

  ngOnInit(): void {}
}
