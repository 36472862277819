import { Component, OnInit } from '@angular/core';
import { InvoiceListConfigService } from '../../services/invoice-list-config/invoice-list-config.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'lib-invoice-list',
  template: `<lib-filter-list [configService]="invoiceListConfigService"></lib-filter-list>`,
  styleUrls: ['./invoice-list.component.css']
})
export class InvoiceListComponent {
  constructor(public invoiceListConfigService: InvoiceListConfigService, route: ActivatedRoute) {
    invoiceListConfigService.setCustomerId(route.snapshot.params.customerId);
  }
}
