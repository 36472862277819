import { Component, OnInit } from '@angular/core';
import { CheckoutStepComponent } from '../base/checkout-step.component';
import { Product, ProductMultiDimension } from '../../../../models';
import { CartRowPrice, Price } from '../../../../models/price';
import { NavbarCartModel, NavbarCartService } from '../../../../services/navbar-cart/navbar-cart.service';
import { CheckoutRow } from '../../../../models/checkout';
import { first, Subject, takeUntil } from 'rxjs';
import { CartRow } from '../../../../state/cart/types';
import { PriceService } from '../../../../services/price/price.service';
import { ProductService } from '../../../../services/products/product.service';

export interface CartSummaryTableDataEntry {
  checkoutRow: CheckoutRow;
  isMultiDimension: boolean;
  productId?: string;
  totals?: {
    qty: number;
    price: Price;
  };
  primaryDimensionName?: string;
  secondaryDimension?: { size: string; qty: number }[];
}

export interface CartSummaryTableData {
  totals: {
    qty: number;
    price: Price;
  };
  rows: CartSummaryTableDataEntry[];
}

@Component({
  selector: 'lib-summarize-order',
  templateUrl: './summarize-order.component.html',
  styleUrl: './summarize-order.component.scss'
})
export class SummarizeOrderComponent extends CheckoutStepComponent implements OnInit {
  isSales: boolean;
  productsMap?: { [productId: string]: Product };
  pricesMap?: { [productId: string]: CartRowPrice };

  isloading = true;
  cartContents: NavbarCartModel;
  cartSummaryTableData: CartSummaryTableData = {
    totals: { qty: 0, price: { value: 0, currencyCode: '' } },
    rows: []
  };

  unsubscribe: Subject<void> = new Subject();

  constructor(
    protected priceService: PriceService,
    protected productService: ProductService,
    protected navbarCartService: NavbarCartService
  ){
    super()
  }

  ngOnInit(): void {
    const ids = this.checkout.rows.map(row => row.id);
    const cartRows: CartRow[] = this.checkout.rows.map(checkoutRow => {
      const rowMap: CartRow = {
        extra: {
          ...checkoutRow.extra
        },
        qty: checkoutRow.quantity,
        productId: checkoutRow.id,
        timeUpdated: new Date(),
        timeAdded: new Date()
      };
      return rowMap;
    });

    this.priceService
      .getCartRowPricesObservable(cartRows)
      .pipe(first())
      .subscribe(priceElements => {
        this.pricesMap = {};
        priceElements.forEach(priceElement => (this.pricesMap[priceElement.productId] = priceElement));
      });
    this.productService.getFullProductsByIds(ids).subscribe(products => {
      this.productsMap = {};
      products.forEach(product => (this.productsMap[product.id] = product));
    });
    this.onNextBtnClicked.subscribe(() => this.stepDone.emit(this.checkout));

    this.subscribeToCartContents();
  }

  subscribeToCartContents(){
    this.navbarCartService
      .getContents()
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(contents => {
        this.cartContents = contents;

        this.cartSummaryTableData = {
          totals: { qty: 0, price: { value: 0, currencyCode: '' } },
          rows: []
        };
        
        for (const checkoutRow of this.checkoutRows()) {
          let cartSummaryTableDataEntry: CartSummaryTableDataEntry = {
            checkoutRow: checkoutRow.row,
            isMultiDimension: checkoutRow.isMultiDimension
          };

          if (checkoutRow.isMultiDimension) {
            const productMultiDimension = this.productsMap[checkoutRow.row.id] as ProductMultiDimension;
            const modelId = productMultiDimension.modelId;
            const primaryDimensionId = productMultiDimension.primaryDimension[0].id;

            cartSummaryTableDataEntry = {
              ...cartSummaryTableDataEntry,
              productId: modelId + primaryDimensionId,
              totals: this.getCartTotalsByEachRow(modelId + primaryDimensionId, checkoutRow.row.partialId),
              primaryDimensionName: productMultiDimension.primaryDimension[0].name,
              secondaryDimension: this.getCartSizeQtyPairsByPrimaryDimension(modelId, primaryDimensionId)
            };
          } else {
            cartSummaryTableDataEntry = {
              ...cartSummaryTableDataEntry,
              productId: this.productsMap[checkoutRow.row.id].id,
              totals: this.getCartTotalsByEachRow(this.productsMap[checkoutRow.row.id].id, checkoutRow.row.partialId)
            };
          }

          this.cartSummaryTableData.rows.push(cartSummaryTableDataEntry);
        }

        // add data about totals
        this.cartSummaryTableData.totals = this.getCartTotals();

        // show singleDimension entries first
        this.cartSummaryTableData.rows.sort((x, y) => (x === y ? 0 : x.isMultiDimension ? 1 : -1));

        this.isloading = false;
      });
  }

  protected getCartTotalsByEachRow(productId: string, partialId: string): { qty: number; price: Price } {
    const result = { qty: 0, price: { value: 0, currencyCode: '' } };

    if (this.cartContents && this.cartContents.rows) {
      for (const item of this.cartContents.rows.filter(row => row.product.id.startsWith(productId) && partialId === row.cartRow.productPartialId)) {
        result.qty += item.cartRow.qty;
        result.price.value += item.price.cartRowTotalPriceInclRowDiscount.value;
        result.price.currencyCode = item.price.cartRowTotalPriceInclRowDiscount.currencyCode;
      }
    }

    return result;
  }

  protected getCartSizeQtyPairsByPrimaryDimension(
    modelId: string,
    primaryDimensionId: string
  ): { size: string; qty: number }[] {
    const result: { size: string; qty: number }[] = [];

    if (this.cartContents && this.cartContents.rows) {
      for (const item of this.cartContents.rows.filter(row =>
        row.product.id.startsWith(modelId + primaryDimensionId)
      )) {
        result.push({
          size: (item.product as ProductMultiDimension).secondaryDimension[0].name,
          qty: item.cartRow.qty
        });
      }
    }

    return result;
  }

  protected getCartTotals(): { qty: number; price: Price } {
    const result = { qty: 0, price: { value: 0, currencyCode: '' } };

    if (this.cartContents && this.cartContents.rows) {
      for (const item of this.cartContents.rows) {
        result.qty += item.cartRow.qty;
        result.price.value += item.price.cartRowTotalPriceInclRowDiscount.value;
        result.price.currencyCode = item.price.cartRowTotalPriceInclRowDiscount.currencyCode;
      }
    }

    return result;
  }


  protected checkoutRows(): { isMultiDimension: boolean; row: CheckoutRow }[] {
    const filteredRows: { isMultiDimension: boolean; row: CheckoutRow }[] = [];

    for (const row of this.checkout.rows) {
      const product = this.productsMap[row.id];
      if (product.productType === 'multiDimension') {
        const modelId = (this.productsMap[row.id] as ProductMultiDimension).modelId;
        const primaryDimension = (this.productsMap[row.id] as ProductMultiDimension).primaryDimension[0].id;

        if (filteredRows.findIndex(x => x.row.id.startsWith(modelId + primaryDimension)) < 0) {
          filteredRows.push({ isMultiDimension: true, row });
        }
      } else {
        filteredRows.push({ isMultiDimension: false, row });
      }
    }

    return filteredRows;
  }

  getLabel() {
    return 'CONFIRM';
  }

  public getBtnTexts(checkout): { next: string | void; prev: string | void } | void {
    return {
      next: 'SUBMIT_ORDER',
      prev: null
    };
  }
}
