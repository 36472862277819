import { Component, OnInit, Input } from '@angular/core';
import { Product } from './../../../../models';
import { SimplifiedAvailability } from './../../../../models/availability';

@Component({
  selector: 'lib-product-matrix-summary-element',
  templateUrl: './product-matrix-summary-element.component.html',
  styleUrls: ['./product-matrix-summary-element.component.css']
})
export class ProductMatrixSummaryElementComponent implements OnInit {
  @Input()
  value: number;

  @Input()
  simplifiedAvailability: SimplifiedAvailability;

  @Input()
  product?: Product;

  constructor() {}

  ngOnInit() {}
}
