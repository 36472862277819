import { Injectable } from '@angular/core';
import { ConfigBaseFilter, ConfigService, ListLayout, ListLayoutMultipleComponent, ListSortOption } from 'gung-list';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { TranslationManagementComponent } from '../../components/translation-management/translation-management.component';
import { TranslationLocalService } from '../translation-local/translation-local.service';
import { first, map } from 'rxjs/operators';
import { I18n } from '../../models/i18n';
import { TranslationManagementService } from '../translation-management/translation-management.service';
import { BaseViewConfigService } from '../base-view-config/base-view-config.service';
import { GungModalService } from '../gung-modal/gung-modal.service';




@Injectable({
  providedIn: 'root'
})
export class TranslationManagementListConfigService implements ConfigService<any> {
  private subject: BehaviorSubject<any[]>;

  public customerOwnedTranslations: {} = {};
  public languages: string[] = [];

  constructor(
    protected translationLocaleService: TranslationLocalService,
    protected translationManagementService: TranslationManagementService,
    protected baseViewConfigService: BaseViewConfigService,
    protected gungModalService: GungModalService
  ) {}

  public addTranslationKey() {
    const translationKey = prompt('Enter the translation key.');
    if (!translationKey) {
      return;
    }
    this.customerOwnedTranslations[translationKey] = this.customerOwnedTranslations[translationKey] || {};
    this.internalGetItems();
  }

  public addTranslation(translationKey: string, translationValue: string, language: string) {
    this.customerOwnedTranslations[translationKey] = this.customerOwnedTranslations[translationKey] || {};
    this.customerOwnedTranslations[translationKey][language] = translationValue;
  }

  public addLanguage() {
    this.gungModalService.openInputModalComponent(
      'LANGUAGE',
      'New languages can be added but you need to talk to your Gung consultant before adding new languages. New languanges should have 2-sign lower case characters, en, se, fi, dk etc. Incorrect entries will potentially cause problem in page views.'
    ).then(language => {
      if (!language) {
        return;
      }
  
      if(language.includes('$')) {
        alert("Language can't contain $ sign")
        return;
      }
  
      this.languages.push(language.toLowerCase());
      this.internalGetItems();
    }).catch( _ => {});
    
  }

  public save() {
    const result: I18n[] = [];

    for (const language of this.languages) {
      const langTranslations = {} as I18n;
      langTranslations.id = language;
      langTranslations.translations = {};

      Object.keys(this.customerOwnedTranslations).forEach(key => {
        if (this.customerOwnedTranslations[key][language]) {
          langTranslations.translations[key] = this.customerOwnedTranslations[key][language];
        }
      });
      result.push(langTranslations);
    }

    this.translationManagementService.saveTranslations(result);
  
  }

  private internalGetItems(): void {
    this.translationManagementService.loadTranslations().subscribe(custTranslations => {
      this.languages.push('en');
      this.languages.push('se');
      if (custTranslations.length > 0) {
        for (const translationLanguage of custTranslations) {
          this.languages.push(translationLanguage.id);

          Object.keys(translationLanguage.translations).forEach(key => {
            const keyTranslations = this.customerOwnedTranslations[key] || {};
            keyTranslations[translationLanguage.id] =
              keyTranslations[translationLanguage.id] || translationLanguage.translations[key];
            this.customerOwnedTranslations[key] = keyTranslations;
          });
        }
      }

      this.languages = [...new Set(this.languages)];

      forkJoin(
        this.languages.map(lang => {
          return this.translationLocaleService.getTranslations(lang).pipe(
            first(),
            map(translations => {
              return {
                lang,
                translations
              };
            })
          );
        })
      ).subscribe(languageTranslation => {
        const mappedTranslations = {};

        for (const translations of languageTranslation) {
          Object.keys(translations.translations).forEach(key => {
            if (typeof translations.translations[key] === 'string') {
              const keyTranslations = mappedTranslations[key] || {};
              keyTranslations[translations.lang] = translations.translations[key];
              mappedTranslations[key] = keyTranslations;
            }
          });
        }

        Object.keys(this.customerOwnedTranslations).forEach(key => {
          const keyTranslations = mappedTranslations[key] || {};
          Object.keys(this.customerOwnedTranslations[key]).forEach(langKey => {
            keyTranslations[langKey] = this.customerOwnedTranslations[key][langKey];
          });
          mappedTranslations[key] = keyTranslations;
        });

        const mappedResult = Object.keys(mappedTranslations).map(key => {
          return {
            ...mappedTranslations[key],
            key
          };
        });
        this.subject.next(mappedResult);
      });
    });
  }

  getItems(): Observable<any[]> {
    if (!this.subject) {
      this.subject = new BehaviorSubject<any[]>([]);
      this.internalGetItems();
    }
    return this.subject.asObservable();
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getFilters(): ConfigBaseFilter<any>[] {
    return [];
  }

  getItemId(item: any): string {
    return item.key;
  }

  getLayouts(): ListLayout<any>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => this.baseViewConfigService.getTranslationManagementComponent(),
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Translations'
      }
    ];
  }

  getSearchTerms(item: any): string[] {
    return Object.values(item);
  }

  getSortOptions(): ListSortOption<any>[] {
    return [];
  }
}
