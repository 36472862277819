import { Component, EventEmitter, Input, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs';
import { AbstractSelectionService } from '../services/selections/abstract-selection-service';

@Component({
  selector: 'lib-item-selection-button',
  templateUrl: './item-selection-button.component.html',
  styleUrls: ['./item-selection-button.component.css']
})
export class ItemSelectionButtonComponent<T> implements OnInit, OnDestroy {
  @Input()
  item: T;

  @Input()
  selectionText = 'SELECT';

  @Input()
  showBorder = true;

  @Output()
  itemIsSelected = new EventEmitter<boolean>();

  selectionsEnabled = false;

  isSelected = false;
  unsubscribe: Subject<void> = new Subject<void>();

  constructor(@Optional() protected selectionService: AbstractSelectionService<T>) {
    if (this.selectionService && this.selectionService.getSelectionsEnabled()) {
      this.selectionsEnabled = true;
    }
  }

  ngOnInit() {
    if (this.selectionsEnabled) {
      this.selectionService
        .isItemSelected(this.item)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(isSelected => {
          this.isSelected = isSelected;
          this.itemIsSelected.emit(this.isSelected);
        });
    }
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  select() {
    this.selectionService.select(this.item);
  }

  deselect() {
    this.selectionService.deselect(this.item);
  }

  toggleSelection(event: any) {
    if (!this.selectionsEnabled) {
      return;
    }
    event.stopPropagation();
    event.preventDefault();
    const itemsSelected = document.querySelector('#items-selected');
    if (!!itemsSelected) {
      itemsSelected.classList.add('action', 'bg-primary');
      setTimeout(function () {
        itemsSelected.classList.remove("action", 'bg-primary');
      }, 1000);
    }
    if (this.isSelected) {
      this.deselect();
    } else {
      this.select();
    }
  }
}
