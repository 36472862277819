import { AfterContentInit, Component, computed, ContentChildren, Directive, effect, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, QueryList, Renderer2, Signal, ViewEncapsulation } from '@angular/core';
import { GungTableService } from '../../services/gung-table/gung-table.service';

interface GungTableColumn {
  key: string;
  label: string;
  sortable?: boolean;
  type?: 'html'| 'component' | null
}

@Directive({
  selector: 'th[gungColumnSort]'
})
export class GungColumnSortDirective {
  @Input() gungColumnSort!: string;  // chave da coluna
  @Input() gungColumnId!: string | null;  // chave da coluna atualmente sendo ordenada
  @Input() sortDirection: 'asc' | 'desc' | null = null;  // direção da ordenação

  @Output() columnClick = new EventEmitter<string>();

  constructor(
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  ngOnInit() {
    this.updateSortIcon();
  }

  @HostListener('click')
  handleClick() {
    console.log('click')
    this.columnClick.emit(this.gungColumnSort);
    if(!this.sortDirection || this.sortDirection === 'desc'){
      this.sortDirection = 'asc';
    }else{
      this.sortDirection = 'desc';
    }
    this.updateSortIcon();
  }

  private updateSortIcon() {
    const nativeElement = this.el.nativeElement;

    // Remover qualquer ícone de ordenação anterior
    const icons = nativeElement.querySelectorAll('.sort-icon');
    icons.forEach((icon: any) => this.renderer.removeChild(nativeElement, icon));

    // Criar o ícone apropriado com base na direção da ordenação
    if (this.gungColumnId) {
      const icon = this.renderer.createElement('span');
      this.renderer.addClass(icon, 'sort-icon');

      if (this.sortDirection === 'asc') {
        this.renderer.setProperty(icon, 'innerHTML', '▲'); // Seta para cima
      } else if (this.sortDirection === 'desc') {
        this.renderer.setProperty(icon, 'innerHTML', '▼'); // Seta para baixo
      }

      // Adicionar o ícone ao cabeçalho
      this.renderer.appendChild(nativeElement, icon);
    }
  }

  
}

@Component({
  selector: 'gung-table',
  templateUrl: './gung-table.component.html',
  styleUrl: './gung-table.component.scss',
  encapsulation: ViewEncapsulation.None

})
export class GungTableComponent implements OnInit{

  @Input() data: any[] = [];
  @Input() sortKey: string | null = null;
  @Input() sortDirection: 'asc' | 'desc' | null = null;

  constructor(private gungTable: GungTableService){
    
  }

  ngOnInit() {
    
   
  }


}

