<div class="">
  <div
    class="header-wrapper image-container d-flex flex-column justify-content-center"
    [style.backgroundImage]="'url(' + getImage(data.elements?.topImage?.image) + ')'"
    (click)="edit ? openModal('topImage') : navigateTo(data.elements?.topImage.link)"
  >
    <div class="header-text" translate>
      {{ getText(data.elements?.topImage?.text) }}
    </div>
    <div>
      <button
        class="btn header-button"
        (click)="
          $event.preventDefault();
          $event.stopPropagation();
          edit ? openModal('topButton') : navigateTo(data.elements?.topButton.link)
        "
        translate
      >
        {{ getText(data.elements?.topButton?.text) }}
      </button>
    </div>
  </div>
</div>

<div class="container-fluid">
  <h1 class="mt-5 mb-4" translate>SELECT_CATEGORY</h1>
  <div class="row">
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageOne?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageOne') : navigateTo(data.elements?.assortmentImageOne.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageOne?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageOne?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageTwo?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageTwo') : navigateTo(data.elements?.assortmentImageTwo.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageTwo?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageTwo?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageThree?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageThree') : navigateTo(data.elements?.assortmentImageThree.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageThree?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageThree?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageFour?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageFour') : navigateTo(data.elements?.assortmentImageFour.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageFour?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageFour?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageFive?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageFive') : navigateTo(data.elements?.assortmentImageFive.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageFive?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageFive?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageSix?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageSix') : navigateTo(data.elements?.assortmentImageSix.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageSix?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageSix?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageSeven?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageSeven') : navigateTo(data.elements?.assortmentImageSeven.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageSeven?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageSeven?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageEight?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageEight') : navigateTo(data.elements?.assortmentImageEight.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageEight?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageEight?.text) }}
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6 col-xl-4 mb-4" *ngIf="!!data.elements?.assortmentImageNine?.image.sourceUrl || edit">
      <div
        class="category-image"
        (click)="edit ? openModal('assortmentImageNine') : navigateTo(data.elements?.assortmentImageNine.link)"
      >
        <img class="img-fluid" [src]="getImage(data.elements?.assortmentImageNine?.image)" alt="" />
        <div class="text-banner" translate>
          {{ getText(data.elements?.assortmentImageNine?.text) }}
        </div>
      </div>
    </div>
  </div>
</div>
