import { Component, OnInit, Input } from '@angular/core';
import { GungFlow } from './../../../../state/flow/types';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';

interface Setting {
  key: string;
  innerKey: string;
  label: string;
  value: string;
}

@Component({
  selector: 'lib-gung-flow-details-order-settings',
  templateUrl: './gung-flow-details-order-settings.component.html',
  styleUrls: ['./gung-flow-details-order-settings.component.css']
})
export class GungFlowDetailsOrderSettingsComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;

  settings: Setting[] = [];

  constructor(protected gungFlowService: GungFlowService) {}

  ngOnInit() {
    this.settings = [];

    this.gungFlowService.getSystemGung().subscribe(systemGung => {
      if (!!this.curentGungFlow.orderParams) {
        for (const key of Object.keys(this.curentGungFlow.orderParams)) {
          for (const innerKey of Object.keys(this.curentGungFlow.orderParams[key])) {
            this.settings.push({
              key,
              innerKey,
              label: (key + '_' + innerKey).toUpperCase(),
              value: this.curentGungFlow.orderParams[key][innerKey]
            });
          }
        }
      }

      if (systemGung.extra.flowOrderConditions) {
        for (const key of Object.keys(systemGung.extra.flowOrderConditions)) {
          for (const innerKey of Object.keys(systemGung.extra.flowOrderConditions[key])) {
            const finIndex = this.settings.findIndex(s => s.innerKey.localeCompare(innerKey) === 0);
            if (finIndex === -1) {
              this.settings.push({
                key,
                innerKey,
                label: (key + '_' + innerKey).toUpperCase(),
                value: ''
              });
            }
          }
        }
      }
    });
  }

  public trackByFn(index: number, item: any) {
    return index;
  }

  setValue(value: any, setting: Setting): void {
    setting.value = value;
    if (!this.curentGungFlow.orderParams) {
      this.curentGungFlow.orderParams = {};
    }
    if (!this.curentGungFlow.orderParams[setting.key]) {
      this.curentGungFlow.orderParams[setting.key] = {};
    }
    if (!this.curentGungFlow.orderParams[setting.key][setting.innerKey]) {
      this.curentGungFlow.orderParams[setting.key][setting.innerKey] = {};
    }
    if (setting.value && setting.value.trim() !== '') {
      this.curentGungFlow.orderParams[setting.key][setting.innerKey] = setting.value;
    } else {
      this.clearObject(setting);
    }
  }

  clearObject(setting: Setting) {
    if (this.curentGungFlow.orderParams[setting.key][setting.innerKey]) {
      delete this.curentGungFlow.orderParams[setting.key][setting.innerKey];
    }
    if (Object.keys(this.curentGungFlow.orderParams[setting.key]).length === 0) {
      delete this.curentGungFlow.orderParams[setting.key];
    }
    if (Object.keys(this.curentGungFlow.orderParams).length === 0) {
      delete this.curentGungFlow.orderParams;
    }
  }
}
