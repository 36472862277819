@if(!!cartContents){
    <div class="cart-popup">
        <div class="cart-content" >
          <div class="row">
            <div class="col-12">
              <h6 class="font-weight-bold pt-0 mt-0" translate>CART_SUBTOTAL</h6>
              {{ 'CART_ARTICLE_SKU_TOTAL' | translate: { article: cartTotalRows, sku: cartTotalItems } }}
            </div>
          </div>
      
          <hr style="width: 96%; margin-left: 2%" />
      
          <h5 class="text-right w-100">
            {{ 'TOTAL' | translate }}
            <lib-price [price]="cartContents.totalValue" [currency]="cartContents.currency"></lib-price>
          </h5>
      
          <div class="row pt-2">
            <div class="col-12 pb-1">
              <button
                type="button"
                class="btn btn-sm btn-primary w-100 navbarcart-btn-checkout"
                routerLink="/checkout"
                translate
              >
                CHECKOUT
              </button>
            </div>
      
            <div class="col-12 pb-1">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary w-100"
                (click)="clearCart()"
                translate
              >
                CLEAR_CART
              </button>
            </div>
      
            <div class="col-12 pb-1">
              <button
                type="button"
                class="btn btn-sm btn-outline-secondary w-100 pl-0"
                (click)="saveCart()"
                translate
              >
                SAVE_CART
              </button>
            </div>
          </div>
        </div>
      </div>
}

