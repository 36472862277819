import { Component, OnInit } from '@angular/core';
import { CustomPagesService, CustomPage } from '../../services/custom-pages.service';
import { SafeHtmlPipe } from '../../pipes/safe-html.pipe';

@Component({
  selector: 'lib-my-custom-pages',
  templateUrl: './my-custom-pages.component.html',
  styleUrls: ['./my-custom-pages.component.css']
})
export class MyCustomPagesComponent implements OnInit {
  customPages: CustomPage[] = [];

  constructor(protected custompagesService: CustomPagesService) {}

  ngOnInit() {
    this.custompagesService.getMyCustomPages().subscribe(pages => (this.customPages = pages));
  }
}
