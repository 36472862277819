import { Component, OnInit } from '@angular/core';
import { ConceptTreeListViewComponent } from '../concept-tree-list-view/concept-tree-list-view.component';

@Component({
  selector: 'lib-concept-tree-table-view',
  templateUrl: './concept-tree-table-view.component.html',
  styleUrls: ['./concept-tree-table-view.component.css']
})
export class ConceptTreeTableViewComponent extends ConceptTreeListViewComponent {
  constructor() {
    super();
  }

  public trackByFn(index: number) {
    return index;
  }
}
