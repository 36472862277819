import { Component, OnInit, Input } from '@angular/core';
import { GungFlow } from './../../../../state/flow/types';
import { GungFlowService } from './../../../../services/gung-flow/gung-flow.service';
import { gungComparatorHelper } from '../../../../utils/gung-utils';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs';

interface Entry {
  key: '_$in' | '_$nin';
  value: any;
}

export interface Setting {
  index: number;
  key: string[];
  label: string;
  entry: Entry;
}

@Component({
  selector: 'lib-gung-flow-details-product-settings',
  templateUrl: './gung-flow-details-product-settings.component.html',
  styleUrls: ['./gung-flow-details-product-settings.component.css']
})
export class GungFlowDetailsProductSettingsComponent implements OnInit {
  @Input()
  curentGungFlow: GungFlow;
  settings: Setting[] = [];
  private readonly INCLUDE_KEY = '_$in';
  private readonly EXCLUDE_KEY = '_$nin';

  constructor(
    protected gungFlowService: GungFlowService,
    protected translateService: TranslateService
  ) {}

  ngOnInit() {
    this.settings = [];

    this.gungFlowService.getSystemGung().pipe(first()).subscribe(systemGung => {
      // product conditions can be an array
      // go through all its indexs and get all the object properties
      if (!!this.curentGungFlow.productConditions && Array.isArray(this.curentGungFlow.productConditions)) {
        for (const [index, entry] of this.curentGungFlow.productConditions.entries()) {
          for (const key of Object.keys(this.curentGungFlow.productConditions[index])) {
            this.processNestedObjectFromFlow(this.curentGungFlow.productConditions[index][key], index, [key]);
          }
        }
      }

      if (systemGung.extra.flowProductConditions) {
        for (const key of Object.keys(systemGung.extra.flowProductConditions)) {
          this.processNestedObjectFromSystemSettings(systemGung.extra.flowProductConditions[key], [key]);
        }
      }

      this.settings = this.settings.sort((a, b) => gungComparatorHelper(this.translateService.instant(a.label), this.translateService.instant(b.label), 1));
      
      this.doAfterInit();
    });
  }

  doAfterInit() {
    // override this method for custom logic
  }

  private processNestedObjectFromSystemSettings(obj, key = []) {
    for (const nextMapKey of Object.keys(obj)) {
      const nextMap = obj[nextMapKey];
      const setting: Setting = {
        index: 0,
        key: [...key, nextMapKey],
        label: ([...key, nextMapKey].join('_')).toUpperCase(),
        entry: { key: '_$in', value: null }
      };
      const pathNotAddedFromFlow = this.settings.findIndex(s => s.label === setting.label) === -1;
      if (pathNotAddedFromFlow && typeof nextMap === 'string') {
        this.settings.push(setting);
      } else if (typeof nextMap === 'object') {
        this.processNestedObjectFromSystemSettings(nextMap, setting.key);
      }
    }
  }

  processNestedObjectFromFlow(obj, index, key = []) {
    for (const nextMapKey of Object.keys(obj)) {
      const nextMap = obj[nextMapKey];
      const setting: Setting = {
        index,
        key: [...key, nextMapKey],
        label: ([...key, nextMapKey].join('_')).toUpperCase(),
        entry: this.getValue(nextMap)
      };
      if (nextMap.hasOwnProperty(this.INCLUDE_KEY) || nextMap.hasOwnProperty(this.EXCLUDE_KEY)) {
        this.settings.push(setting);
      } else if (typeof nextMap === 'object') {
        this.processNestedObjectFromFlow(nextMap, index, setting.key);
      } else {
        console.error('This should not happen');
      }
    }
  }

  public trackByFn(index: number, item: any) {
    return index;
  }

  setValue(value: any, setting: Setting): void {
    setting.entry.value = value;
    if (!this.curentGungFlow.productConditions) {
      this.curentGungFlow.productConditions = [];
    }
    if (!this.curentGungFlow.productConditions[setting.index]) {
      this.curentGungFlow.productConditions.push({});
    }

    let currentMap = this.curentGungFlow.productConditions[setting.index];
    // Create missing maps along the path
    for (let i = 0; i < setting.key.length - 1; i++) {
      if (!currentMap[setting.key[i]]) {
        currentMap[setting.key[i]] = {};
      }
      currentMap = currentMap[setting.key[i]];
    }

    if (!currentMap[setting.key[setting.key.length - 1]]) {
      currentMap[setting.key[setting.key.length - 1]] = {};
    }
    currentMap = currentMap[setting.key[setting.key.length - 1]];

    // Make final entry a list of values and assign it
    if (!currentMap[setting.entry.key]) {
      currentMap[setting.entry.key] = [];
    }
    if (setting.entry.value && setting.entry.value.trim() !== '') {
      currentMap[setting.entry.key] = setting.entry.value.split(',');
    } else {
      // do not send input which are set to empty
      this.clearObject(setting);
    }
  }

  keyChange(key: any, setting: Setting) {
    const oldVal = setting.entry.value;
    this.clearObject(setting);
    setting.entry.key = key;
    this.setValue(oldVal, setting);
  }

  clearObject(setting: Setting) {
    let currentMap = this.curentGungFlow.productConditions[setting.index];
    for (let i = 0; i < setting.key.length - 1; i++) {
      if (!currentMap[setting.key[i]]) {
        return;
      }
      currentMap = currentMap[setting.key[i]];
    }

    if (currentMap[setting.key[setting.key.length - 1]]) {
      delete currentMap[setting.key[setting.key.length - 1]];
    }
    if (Object.keys(currentMap).length === 0 && setting.key.length > 1) {
      currentMap = this.curentGungFlow.productConditions[setting.index];
      for (let i = 0; i < setting.key.length - 2; i++) {
        currentMap = currentMap[setting.key[i]];
      }
      delete currentMap[setting.key[setting.key.length - 2]];
    }
    if (Object.keys(this.curentGungFlow.productConditions[setting.index]).length === 0) {
      this.curentGungFlow.productConditions.splice(setting.index, 1);
    }
    if (this.curentGungFlow.productConditions.length === 0) {
      delete this.curentGungFlow.productConditions;
    }
  }


  protected getValue(value: any): Entry {
    return {
      key: Object.keys(value)[0] == this.EXCLUDE_KEY ? this.EXCLUDE_KEY : this.INCLUDE_KEY,
      value: Array.from(Object.values(value)[0] as any).join(',')
    };
  }
}
