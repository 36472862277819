<div class="modal-header">
  <h3 translate>PRODUCT_TEMPLATE</h3>
</div>
<div class="modal-body">
  <lib-gung-text-area-input gungTranslate="HEADING" [(ngModel)]="heading" rowsNumber="2"></lib-gung-text-area-input>
  <lib-gung-text-area-input gungTranslate="NOTE" [(ngModel)]="noteText" rowsNumber="6"></lib-gung-text-area-input>
  <lib-gung-select-option-input
    [optionsList]="fonts"
    [(ngModel)]="selectedFont"
    gungTranslate="FONTS"
  ></lib-gung-select-option-input>
  <div class="dropdown-divider"></div>
</div>
<div class="modal-footer">
  <div class="d-flex justify-content-end">
    <button ngbDropdownItem type="button" class="btn btn-border-primary submitbutton" (click)="editNote()">Edit</button>
  </div>
</div>
