<div ngbAccordion [closeOthers]="closeOthers" #accordion="ngbAccordion">
  @for (item of accordionItems; track item) {
    <div ngbAccordionItem="{{ item.id }}" [collapsed]="item.collapsed">
      <div ngbAccordionHeader>
        @if (!item.customHeader) {
            <button ngbAccordionButton>
            <ng-template [ngTemplateOutlet]="item.headerTpl?.templateRef"></ng-template>
            </button>
        } @else {
            <ng-template [ngTemplateOutlet]="item.headerTpl?.templateRef"></ng-template>
        }
      </div>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <ng-template [ngTemplateOutlet]="item.bodyTpl?.templateRef"></ng-template>
        </div>
      </div>
    </div>
  }
</div>
