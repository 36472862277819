import { AfterViewInit, Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { trigger } from '@angular/animations';
import { ActivatedRoute } from '@angular/router';
import { first, map, switchMap } from 'rxjs/operators';
import { forkJoin, Observable, of } from 'rxjs';
import { LogoApplicatorService } from '../../services/logo-applicator/logo-applicator.service';
import { Product } from '../../models';
import { LogoApplication, LogoApplicationSave } from '../../models/logo-application';
import { ProductService } from '../../services/products/product.service';
import { DomSanitizer } from '@angular/platform-browser';
import { GungImageUrlService } from 'gung-common';

/**
 * This is a port of a component made in the legacy angularjs projects. Due to time constraints, a lot of corners had
 * to be cut to make it in time. Because of this, there exists a lot of improvements that can be done in the code,
 * especially to make it more "Angular2".
 */
@Component({
  selector: 'lib-product-detail-logo-applicator',
  templateUrl: './product-detail-logo-applicator.component.html',
  styleUrls: ['./product-detail-logo-applicator.component.css']
})
export class ProductDetailLogoApplicatorComponent implements OnInit, AfterViewInit {
  @ViewChild('logoApplicator')
  canvas: ElementRef<HTMLCanvasElement>;

  @ViewChild('objectPreviewCanvas')
  previewCanvas: ElementRef<HTMLCanvasElement>;

  ctx: CanvasRenderingContext2D;
  previewCtx: CanvasRenderingContext2D;

  productId: string;
  applicationId: string;
  isEdit: boolean;

  textInput = '';
  textColor = '#000000';
  selectedFont = 'Roboto';
  nameInput = '';
  imageId = 0;

  profiles = [];
  selectedProfile;

  colors = {};
  selectedColor;

  previewWidth = 200;
  previewHeight = 120;

  width = 800;
  height = 800;
  uploadedImages = {};
  backgroundImages = [];

  gungResponseTranslate = 'APPLICATION_SAVED';

  BB;
  offsetX;
  offsetY;

  dragOk = false;
  startX;
  startY;
  saving = false;
  isSaved = false;

  constructor(
    protected route: ActivatedRoute,
    protected productService: ProductService,
    protected logoApplicatorService: LogoApplicatorService,
    protected sanitizer: DomSanitizer,
    protected gungImageUrlService: GungImageUrlService
  ) {}

  @HostListener('document:scroll', ['$event'])
  onScroll(ev: Event) {
    this.setOffsets();
  }

  @HostListener('document:resize', ['$event'])
  onResize(ev: Event) {
    this.setOffsets();
  }

  private setOffsets() {
    this.BB = this.canvas.nativeElement.getBoundingClientRect();
    this.offsetX = this.BB.left;
    this.offsetY = this.BB.top;
  }

  ngOnInit() {
    this.route.params
      .pipe(
        first(),
        switchMap(params => {
          const forks: Observable<any>[] = [];

          if (!!params.applicationId) {
            forks.push(this.logoApplicatorService.getLogoApplication(params.applicationId).pipe(first()));
            forks.push(of(null));
          } else {
            forks.push(of(null));
            if (!!params.productId) {
              forks.push(this.productService.getProduct(params.productId).pipe(first()));
            } else {
              forks.push(of(null));
            }
          }

          forks.push(of(params.edit === 'Edit=True'));

          return forkJoin(forks);
        })
      )
      .subscribe(([logoApplication, product, isEdit]) => {
        if (!!logoApplication) {
          const la = logoApplication as LogoApplication;
          this.nameInput = la.name;
          if (la.state) {
            this.setState(la.state, la.appliedImageUris);
            this.draw();
          }
          this.applicationId = la.id;
        }

        if (!!product) {
          const p = product as Product;
          this.productId = p.id;
          p.extra.images.forEach(img => {
            img.pimDimensionIds.forEach(pimDimensionId => {
              if (!!!this.colors[pimDimensionId]) {
                this.colors[pimDimensionId] = { name: pimDimensionId, profiles: [] };
              }
              const profile = { background: this.getImage(img.s3Uri), images: [], s3Uri: img.s3Uri };
              this.colors[pimDimensionId].profiles.push(profile);
            });
          });

          if (Object.keys(this.colors).length > 0) {
            this.profiles = this.colors[Object.keys(this.colors)[0]].profiles;
          } else {
            this.profiles = [
              { background: this.getImage(p.extra.images[0].s3Uri), images: [], s3Uri: p.extra.images[0].s3Uri }
            ];
          }

          this.selectedProfile = this.profiles[0];
          this.selectedColor = Object.keys(this.colors)[0];
        }

        this.isEdit = isEdit as boolean;
      });
  }

  logoInputChange(logoInput: any) {
    if (logoInput.target.files && logoInput.target.files[0]) {
      const img = new Image();
      img.src = window.URL.createObjectURL(logoInput.target.files[0]);

      // Temporal for it to work in time of deadline.
      // @ts-ignore
      img.file = logoInput.target.files[0];
      this.addImageScale(img, 300, 300);
    }
  }

  ngAfterViewInit(): void {
    this.canvas.nativeElement.width = this.width;
    this.canvas.nativeElement.height = this.height;

    this.ctx = this.canvas.nativeElement.getContext('2d');
    this.ctx.lineWidth = 1;

    this.setOffsets();

    this.previewCanvas.nativeElement.width = this.previewWidth;
    this.previewCanvas.nativeElement.height = this.previewHeight;

    this.previewCtx = this.previewCanvas.nativeElement.getContext('2d');
    this.previewCtx.lineWidth = 1;
  }

  selectProfile(profile) {
    this.selectedProfile = profile;
    this.draw();
  }

  selectColor(color) {
    this.selectedColor = color;
    this.profiles = this.colors[color].profiles;
    this.selectedProfile = this.profiles[0];
    this.draw();
  }

  logoIsApplied() {
    let logoIsApplied = false;
    this.profiles.forEach(profile => {
      if (profile.images.length > 0) {
        logoIsApplied = true;
      }
    });
    return logoIsApplied;
  }

  removeImage(imageObj) {
    this.selectedProfile.images = this.selectedProfile.images.filter(img => {
      return img !== imageObj;
    });
    this.draw();
  }

  private setState(state, s3Uris) {
    state.profiles.forEach(profile => (profile.background = this.getImage(profile.s3Uri)));
    this.colors = state.colors;
    this.selectedColor = state.selectedColor;
    this.profiles = state.profiles;
    this.selectedProfile = this.profiles[0];
    this.profiles.forEach(profile => {
      profile.images.forEach(img => {
        if (!!img.text) {
          img.img = this.generatePreviewImage(img);
        } else {
          if (s3Uris && s3Uris[img.id]) {
            img.img = this.getImage(s3Uris[img.id]);
            img.s3Uri = s3Uris[img.id];
          }
        }
      });
    });
  }

  generateNewPreviewImage(imageObj) {
    imageObj.img = this.generatePreviewImage(imageObj);
  }

  setSelected(image) {
    this.selectedProfile.images.forEach(img => {
      img.selected = false;
    });
    image.selected = true;
    this.draw();
  }

  addText() {
    if (this.textInput) {
      const imageObj = {
        text: this.textInput,
        font: this.selectedFont,
        color: this.textColor,
        fontSize: 50.0,
        img: null,
        x: 300,
        y: 300,
        isDragging: false,
        selected: false,
        resize: false,
        width: 100,
        height: null,
        id: this.imageId,
        rotate: false,
        angle: 0.0
      };
      this.imageId++;
      imageObj.img = this.generatePreviewImage(imageObj);
      this.selectedProfile.images.unshift(imageObj);
      this.textInput = '';
      this.draw();
    }
  }

  private getFontForContext(imageObj) {
    return imageObj.fontSize + 'px ' + imageObj.font;
  }

  private generatePreviewImage(imageObj) {
    this.previewCtx.clearRect(0, 0, this.width, this.height);

    // First see how large text is i order to resize correctly
    this.previewCtx.font = this.getFontForContext(imageObj);
    const testMetrics = this.previewCtx.measureText(imageObj.text);
    let tempFontSize;
    if (testMetrics.width > this.textHeight(testMetrics)) {
      tempFontSize = (imageObj.fontSize * (this.previewWidth - 40)) / testMetrics.width;
    } else {
      tempFontSize = (imageObj.fontSize * (this.previewHeight - 40)) / this.textHeight(testMetrics);
    }

    // Get new resized text
    this.previewCtx.font = tempFontSize + 'px ' + imageObj.font;
    const textMetrics = this.previewCtx.measureText(imageObj.text);
    this.previewCtx.fillStyle = imageObj.color;
    this.previewCtx.fillText(
      imageObj.text,
      (this.previewWidth - textMetrics.width) / 2,
      (this.previewHeight + textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent) / 2
    );
    const img = new Image();
    img.src = this.previewCanvas.nativeElement.toDataURL('image/png');
    return img;
  }

  private textHeight(textMetrics) {
    return textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
  }

  mouseLeave() {
    this.selectedProfile.images.forEach(img => {
      img.resize = false;
      img.rotate = false;
    });

    this.dragOk = false;
  }

  mouseDown(e) {
    e.preventDefault();
    e.stopPropagation();

    // To ensure that offsets are correct when trying to select on the canvas. Without this
    // the users needs to scroll or resize the window to be able to select.
    this.setOffsets();

    this.isSaved = false;
    this.dragOk = false;

    // get the current mouse position
    const mouseX = e.clientX - this.offsetX;
    const mouseY = e.clientY - this.offsetY;

    let imageFound = false;

    this.selectedProfile.images.forEach(imageObj => {
      if (imageObj.selected) {
        if (this.isInResizeBox(mouseX, mouseY, imageObj)) {
          imageObj.resize = true;
          this.startX = mouseX;
          this.startY = mouseY;
          imageFound = true;
          return;
        }
        if (this.isInRemoveBox(mouseX, mouseY, imageObj)) {
          this.removeImage(imageObj);
          imageFound = true;
        }
        if (this.isInRotateBox(mouseX, mouseY, imageObj)) {
          imageObj.rotate = true;
          this.startX = mouseX;
          this.startY = mouseY;
          imageFound = true;
          return;
        }
      }
      imageObj.selected = false;
    });

    if (!imageFound) {
      this.findClickedImage(mouseX, mouseY);
    }

    // save the current mouse position
    this.startX = mouseX;
    this.startY = mouseY;
    this.draw();
  }

  private findClickedImage(mouseX, mouseY) {
    let found = false;
    this.selectedProfile.images.forEach(imageObj => {
      if (this.isInImage(mouseX, mouseY, imageObj)) {
        this.markImageAsFound(imageObj);
        found = true;
        // break;
        return;
      }
    });
  }

  private markImageAsFound(imageObj) {
    this.dragOk = true;
    imageObj.isDragging = true;
    this.setSelected(imageObj);
  }

  private isInImage(mouseX, mouseY, imageObj) {
    const selectedBox = this.getSelectedBox(imageObj);
    return (
      mouseX > selectedBox.x &&
      mouseX < selectedBox.x + selectedBox.length &&
      mouseY > selectedBox.y &&
      mouseY < selectedBox.y + selectedBox.length
    );
  }

  private isInResizeBox(mouseX, mouseY, imageObj) {
    const selectedBox = this.getSelectedBox(imageObj);
    return (
      mouseX > selectedBox.x + selectedBox.length &&
      mouseX < selectedBox.x + selectedBox.length + 30 &&
      mouseY < selectedBox.y &&
      mouseY > selectedBox.y - 30
    );
  }

  private isInRemoveBox(mouseX, mouseY, imageObj) {
    const selectedBox = this.getSelectedBox(imageObj);
    return (
      mouseX > selectedBox.x - 30 &&
      mouseX < selectedBox.x &&
      mouseY < selectedBox.y + selectedBox.length + 30 &&
      mouseY > selectedBox.y + selectedBox.length
    );
  }

  private isInRotateBox(mouseX, mouseY, imageObj) {
    const selectedBox = this.getSelectedBox(imageObj);
    return (
      mouseX > selectedBox.x - 30 && mouseX < selectedBox.x && mouseY < selectedBox.y && mouseY > selectedBox.y - 30
    );
  }

  mouseUp(e) {
    e.preventDefault();
    e.stopPropagation();
    this.isSaved = false;

    // clear all the dragging flags
    this.dragOk = false;

    this.selectedProfile.images.forEach(imageObj => {
      imageObj.isDragging = false;
      imageObj.resize = false;
      imageObj.rotate = false;
    });
  }

  // handle mouse moves
  mouseMove(e) {
    // tell the browser we're handling this mouse event
    e.preventDefault();
    e.stopPropagation();

    // get the current mouse position
    const mouseX = e.clientX - this.offsetX;
    const mouseY = e.clientY - this.offsetY;

    // calculate the distance the mouse has moved
    // since the last mousemove
    const dx = mouseX - this.startX;
    const dy = mouseY - this.startY;

    if (this.dragOk) {
      this.selectedProfile.images.forEach(imageObj => {
        if (imageObj.isDragging) {
          imageObj.x += dx;
          imageObj.y += dy;
        }
      });

      this.draw();
    }

    this.selectedProfile.images.forEach(imageObj => {
      if (imageObj.resize) {
        if (imageObj.width + dx > 40) {
          this.rescale(imageObj, dx);
          this.draw();
        }
      } else if (imageObj.rotate) {
        imageObj.angle += this.calculateAngle(imageObj, mouseX, mouseY);
        this.draw();
      }
    });

    this.startX = mouseX;
    this.startY = mouseY;
  }

  private calculateAngle(imageObj, mouseX, mouseY) {
    // Calculates the angle using the law of cosines
    const center = this.getCenter(imageObj);
    const a = Math.hypot(mouseX - this.startX, mouseY - this.startY);
    const b = Math.hypot(mouseX - center.x, mouseY - center.y);
    const c = Math.hypot(this.startX - center.x, this.startY - center.y);

    const currentMouseAngle = Math.atan2(mouseX - center.x, -(mouseY - center.y));

    const angle = Math.acos((Math.pow(b, 2) + Math.pow(c, 2) - Math.pow(a, 2)) / (2 * b * c));

    if (
      (this.isAbove(currentMouseAngle) && mouseX < this.startX) ||
      (this.isToTheRightOf(currentMouseAngle) && mouseY < this.startY) ||
      (this.isBelow(currentMouseAngle) && mouseX > this.startX) ||
      (this.isToTheLeftOf(currentMouseAngle) && mouseY > this.startY)
    ) {
      return -angle;
    }

    return angle;
  }

  private isAbove(currentMouseAngle) {
    return currentMouseAngle > -Math.PI / 4 && currentMouseAngle <= Math.PI / 4;
  }

  private isToTheRightOf(currentMouseAngle) {
    return currentMouseAngle > Math.PI / 4 && currentMouseAngle <= (Math.PI * 3) / 4;
  }

  private isBelow(currentMouseAngle) {
    return (
      (currentMouseAngle > (Math.PI * 3) / 4 && currentMouseAngle <= Math.PI) ||
      (currentMouseAngle > -Math.PI && currentMouseAngle <= (-Math.PI * 3) / 4)
    );
  }

  private isToTheLeftOf(currentMouseAngle) {
    return currentMouseAngle > (-Math.PI * 3) / 4 && currentMouseAngle <= -Math.PI / 4;
  }

  private rescale(imageObj, dx) {
    // Should probably use a transform in here instead, which should allow for easier rescaling (I think)
    let oldWidth;
    if (imageObj.text == null) {
      oldWidth = imageObj.width;
      imageObj.width += dx;
      imageObj.height = ((imageObj.width + 0.0) / oldWidth) * imageObj.height;
    } else {
      this.ctx.font = this.getFontForContext(imageObj);
      const textMetrics = this.ctx.measureText(imageObj.text);
      imageObj.fontSize += (dx * imageObj.fontSize) / textMetrics.width;
    }
  }

  // Add image, set width to 100 and scale height
  private addImageScale(img, x, y) {
    img.onload = () => {
      img.height = (100.0 * img.height) / img.width;
      img.width = 100;
      this.selectedProfile.images.unshift({
        img,
        x,
        y,
        safeImageUrl: this.sanitizer.bypassSecurityTrustUrl(img.src),
        isDragging: false,
        selected: false,
        resize: false,
        width: img.width,
        height: img.height,
        id: this.imageId,
        rotate: false,
        angle: 0.0
      });

      this.imageId++;

      this.draw();
    };
    img.onerror = this.failed;
  }

  private getImage(src) {
    const image = new Image();
    image.crossOrigin = 'anonymous';
    image.src = this.gungImageUrlService.getUrl(src);
    image.onload = () => {
      this.draw();
    };
    return image;
  }

  private getCenter(imageObj) {
    if (imageObj.text != null) {
      this.ctx.font = this.getFontForContext(imageObj);
      const textMetrics = this.ctx.measureText(imageObj.text);
      return {
        x: imageObj.x + textMetrics.width / 2,
        y: imageObj.y - (textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent) / 2
      };
    } else {
      return { x: imageObj.x + imageObj.width / 2, y: imageObj.y + imageObj.height / 2 };
    }
  }

  private rotateimageObj(imageObj) {
    const center = this.getCenter(imageObj);

    // translate and rotate
    this.ctx.translate(center.x, center.y);
    this.ctx.rotate(imageObj.angle);
    this.ctx.translate(-center.x, -center.y);

    this.drawImageObj(imageObj);

    // translate and rotate
    this.ctx.translate(center.x, center.y);
    this.ctx.rotate(-imageObj.angle);
    this.ctx.translate(-center.x, -center.y);
  }

  private getSelectedBox(imageObj) {
    let diagonal;
    let x;
    let y;
    if (imageObj.text == null) {
      diagonal = Math.hypot(imageObj.width, imageObj.height);
      x = imageObj.x - (diagonal - imageObj.width) / 2;
      y = imageObj.y - (diagonal - imageObj.height) / 2;
    } else if (imageObj.text != null) {
      this.ctx.font = this.getFontForContext(imageObj);
      const textMetrics = this.ctx.measureText(imageObj.text);
      diagonal = Math.hypot(textMetrics.width, this.textHeight(textMetrics));
      x = imageObj.x - (diagonal - textMetrics.width) / 2;
      y = imageObj.y - (diagonal + textMetrics.actualBoundingBoxAscent - textMetrics.actualBoundingBoxDescent) / 2;
    }

    return { x, y, length: diagonal };
  }

  draw() {
    if (!this.ctx) {
      return;
    }
    // clear the canvas
    this.ctx.clearRect(0, 0, this.width, this.height);

    this.ctx.drawImage(this.selectedProfile.background, 0, 0, this.width, this.height);

    let selectedImage = null;

    for (let i = this.selectedProfile.images.length - 1; i >= 0; i--) {
      const imageObj = this.selectedProfile.images[i];
      if (imageObj.angle !== 0) {
        this.rotateimageObj(imageObj);
      } else {
        this.drawImageObj(imageObj);
      }

      if (imageObj.selected) {
        // Draw square around image if it is selected
        selectedImage = imageObj;
      }
    }

    if (selectedImage) {
      this.drawSelectedImage(selectedImage);
    }
  }

  private drawImageObj(imageObj) {
    if (imageObj.text == null) {
      this.ctx.drawImage(imageObj.img, imageObj.x, imageObj.y, imageObj.width, imageObj.height);
    } else if (imageObj.text != null) {
      this.ctx.font = this.getFontForContext(imageObj);
      this.ctx.fillStyle = imageObj.color;
      this.ctx.fillText(imageObj.text, imageObj.x, imageObj.y);
    }
  }

  private drawSelectedImage(imageObj) {
    const selectedBox = this.getSelectedBox(imageObj);

    this.ctx.strokeRect(selectedBox.x, selectedBox.y, selectedBox.length, selectedBox.length);
    this.ctx.fillStyle = '#000000';
    this.ctx.font = '900 20px "Font Awesome 5 Pro"';
    // resize
    this.ctx.fillText('\uf424', selectedBox.x + selectedBox.length + 10, selectedBox.y - 10);
    // delete
    this.ctx.fillText('\uf00d', selectedBox.x - 20, selectedBox.y + selectedBox.length + 20);
    // rotate
    this.ctx.fillText('\uf01e', selectedBox.x - 20, selectedBox.y - 10);
  }

  private failed() {
    console.error("The provided file couldn't be loaded as an Image media");
  }

  saveCanvas() {
    this.saving = true;
    const canvasImages = [];
    let imagesToUpload = [];
    const currentProfile = this.selectedProfile;
    this.profiles.forEach(profile => {
      if (profile.images.length > 0) {
        profile.images.forEach(imageObj => {
          imageObj.selected = false;
          imageObj.img.onload = () => {
            console.log('LOADED'); // Dummy, onload needs to be defined in order to prevent duplicates
          };
        });
        this.selectedProfile = profile;
        this.draw();
        canvasImages.push(this.canvas.nativeElement.toDataURL('image/png'));
        profile.images.forEach(img => {
          if (!!!img.text && !!!img.s3Uri) {
            imagesToUpload.push(img);
          }
        });
      }
    });

    this.selectedProfile = currentProfile;
    this.draw();
    imagesToUpload = imagesToUpload.map(img => {
      return {
        id: img.id,
        file: img.img.file
      };
    });

    const logoApplicationSave: LogoApplicationSave = {
      canvasImages,
      imagesToUpload,
      customerId: null,
      productId: this.productId,
      profiles: this.profiles,
      name: this.nameInput,
      applicationId: this.applicationId,
      isEdit: this.isEdit,
      colors: this.colors,
      selectedColor: this.selectedColor
    };

    this.logoApplicatorService
      .saveApplication(logoApplicationSave)
      .pipe(
        first(),
        map(_ => {
          this.saving = false;
          this.isSaved = true;
        })
      )
      .subscribe();
  }
}
