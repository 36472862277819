<div class="row">
  <div class="col pb-2 text-right border-bottom">
    <button type="button" class="btn btn-outline-primary" (click)="openModalUploadImages()" translate>
      UPLOAD_MULTIPLE_IMAGES
    </button>
  </div>
</div>
<div class="pim-product-images-container" cdkDropList (cdkDropListDropped)="drop($event)">
  <div *ngFor="let image of pimProduct?.images; let idx = index" cdkDrag>
    <div class="row my-2 pb-2 border-bottom" style="background-color: white">
      <div class="col-12 col-md-2">
        <img
          class="image-card mx-auto d-block"
          [src]="image.s3Uri | gungImageUrl
            :'type:list'
            :'etag:'+image.s3ETag
          "
          alt="{{ image.description }}"
        />
      </div>
      <div class="col-12 col-md-10">
        <div class="row">
          <div class="col-8">
            <lib-gung-text-input-horizontal
              gungTranslate="FILENAME"
              [renderStatic]="true"
              [ngModel]="image.s3Uri.substring(image.s3Uri.lastIndexOf('/') + 1)"
            >
            </lib-gung-text-input-horizontal>
          </div>
          <div class="col-4 d-flex justify-content-end">
            <div class="btn-group" data-toggle="buttons">
              <button
                type="button"
                class="btn btn-primary"
                [disabled]="idx === 0"
                (click)="moveElement(idx, pimProduct.images, 'primary')"
                translate
              >
                PRIMARY
              </button>
              <button type="button" class="btn btn-light" [disabled]="idx === 0" (click)="moveElement(idx, pimProduct.images, 'up')"><i class="fa-solid fa-arrow-up"></i></button>
              <button type="button" class="btn btn-light" [disabled]="idx === (pimProduct.images.length - 1)" (click)="moveElement(idx, pimProduct.images, 'down')"><i class="fa-solid fa-arrow-down"></i></button>
              <button
                type="button"
                class="btn btn-danger"
                (click)="moveElement(idx, pimProduct.images, 'remove')"
                translate
              >
                DELETE
              </button>
            </div>
          </div>
        </div>

        <div class="form-group">
          <label for="colors" class="control-label" translate>COLORS</label>
          <div class="row" *ngIf="pimProduct.dimensions && pimProduct.dimensions.colors">
            <div class="col-12">
              <button
                *ngFor="let pimDimension of pimProduct.dimensions.colors"
                type="button"
                class="btn btn-sm btn-outline-secondary mb-1 mr-1"
                [ngClass]="{ active: image.pimDimensionIds.includes(pimDimension.id) }"
                (click)="toggleArray(pimDimension.id, image.pimDimensionIds)"
              >
                {{ pimDimension.dimensionId + ' - ' + pimDimension.name }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <div class="form-group" *ngIf="categoryOptions">
              <label for="imageCategory" class="control-label" translate>IMAGE_CATEGORY</label>
              <div class="row">
                <div class="col-12">
                  <button
                    *ngFor="let imageCategory of categoryOptions"
                    type="button"
                    class="btn btn-sm btn-outline-secondary mb-1 mr-1"
                    [ngClass]="{ active: image.category === imageCategory }"
                    (click)="image.category = imageCategory"
                  >
                    {{ imageCategory }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group" *ngIf="showInButtons">
              <label for="imageCategory" class="control-label" translate>SEGMENT</label>
              <div class="row">
                <div class="col-12">
                  <button
                    *ngFor="let showIn of showInOptions"
                    type="button"
                    class="btn btn-sm btn-outline-secondary mb-1 mr-1"
                    [ngClass]="{ active: image.extra[showIn] }"
                    (click)="image.extra[showIn] = !image.extra[showIn]"
                  >
                    {{ showIn.toUpperCase() | translate }}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="extraOptions">
          <div class="form-group" *ngFor="let extraOption of extraOptions | keyvalue">
            <label for="imageCategory" class="control-label" translate>{{ extraOption.key }}</label>
            <div class="row">
              <div class="col-12">
                <button
                  *ngFor="let option of extraOption.value"
                  type="button"
                  class="btn btn-sm btn-outline-secondary mb-1 mr-1"
                  [ngClass]="{ active: image.extra[extraOption.key] === option }"
                  (click)="toggleOption(image, extraOption.key, option)"
                >
                  {{ option }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
