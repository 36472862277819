import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { GungGridItem, Whiteboard, WhiteboardAction } from '../types';
import { WhiteboardService } from './whiteboard.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WhiteboardSaveModalComponent } from '../components/whiteboard-save-modal/whiteboard-save-modal.component';
import { NavbarConfigService } from '../../services/navbar-config/navbar-config.service';
import { CartService } from '../../services/cart/cart.service';
import { WhiteboardGridConfigService } from './whiteboard-grid-config.service';
import { WhiteboardManagementModalComponent } from '../components/whiteboard-management-modal/whiteboard-management-modal.component';
import { WhiteboardCreateModalComponent } from '../components/whiteboard-create-modal/whiteboard-create-modal.component';

@Injectable({
  providedIn: 'root'
})
export class WhiteboardActionsConfigService {
  constructor(
    protected whiteboardService: WhiteboardService,
    protected whiteboardConfigService: WhiteboardGridConfigService,
    protected modalService: NgbModal,
    protected navbarConfigService: NavbarConfigService,
    protected cartService: CartService
  ) {}

  public getActions(): Observable<WhiteboardAction[]> {
    const result: WhiteboardAction[] = [];

    result.push({
      label: 'WHITEBOARD_MANAGEMENT',
      class: 'btn btn-primary btn-sm mr-2',
      action: () => {
        const modal = this.modalService.open(WhiteboardManagementModalComponent, { size: 'xl' });
        return of();
      }
    });

    result.push({
      label: 'CREATE_NEW',
      class: 'btn btn-primary btn-sm mr-2',
      action: () => {
        const modal = this.modalService.open(WhiteboardCreateModalComponent);
        modal.componentInstance.isUpdate = false;

        return of();
      }
    });

    result.push({
      label: 'SAVE_TO_EXISTING',
      class: 'btn btn-primary btn-sm mr-2',
      action: () => {
        const whiteboard = {} as Whiteboard;
        whiteboard.sheets = this.whiteboardConfigService.sheets;

        const modal = this.modalService.open(WhiteboardSaveModalComponent);
        modal.componentInstance.whiteboard = whiteboard;
        return of();
      }
    });

    result.push({
      label: 'TOGGLE_FULLSCREEN',
      class: 'btn btn-primary btn-sm mr-2',
      action: grid => {
        this.navbarConfigService.hideNavbar = !this.navbarConfigService.hideNavbar;
        return of();
      }
    });

    /*
    result.push({
      label: 'ZOOM_OUT',
      class: 'btn btn-primary btn-sm mr-2',
      action: () => {
        return of();
      }
    });

    result.push({
      label: 'ZOOM_IN',
      class: 'btn btn-primary btn-sm mr-2',
      action: () => {
        return of();
      }
    });
     */
    return of(result);
  }
}
