import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, of, ReplaySubject } from 'rxjs';
import { tap } from 'rxjs';
import { CustomerContact } from '../../models/customerContact';

@Injectable({
  providedIn: 'root'
})
export class CustomerContactsGungService {
  private contactsSubjects: { [customerId: string]: Subject<CustomerContact[]> } = {};

  constructor(protected http: HttpClient) {}

  /**
   * Gets all contacts from every customer.
   */
  getAllContacts(): Observable<CustomerContact[]> {
    const url = `json/contacts`;
    return this.http.get<CustomerContact[]>(url);
  }

  /**
   * Gets all contacts for a specific customer.
   * @param customerId - The customer which contacts we want to get.
   */
  getCustomerContacts(customerId: string): Observable<CustomerContact[]> {
    if (!this.contactsSubjects[customerId]) {
      this.contactsSubjects[customerId] = new ReplaySubject<CustomerContact[]>();
      this.updateCustomerContactsSubject(customerId);
    }
    return this.contactsSubjects[customerId].asObservable();
  }

  /**
   *  Gets all contacts for a specific customer.
   *   @param customerId - The customer which contacts we want to get.
   */
  protected internalGetCustomerContactsFromUrl(customerId: string): Observable<CustomerContact[]> {
    const url = `json/contacts/customer/${customerId}`;
    const headers = {
      maxAge: '-1'
    };
    return this.http.get<CustomerContact[]>(url, { headers });
  }
  /**
   * Gets a specific contact from a customer.
   * @param customerId - The id of the customer we want to find a contact from.
   * @param contactId  - The id of the contact we want to find.
   */
  getCustomerContact(customerId: string, contactId: string): Observable<CustomerContact> {
    if (!contactId) {
      return of(null);
    }
    const url = `json/contacts/${contactId}`;
    return this.http.get<CustomerContact>(url);
  }

  updateCustomerContact(customerId: string, contact: CustomerContact): Observable<CustomerContact[]> {
    const url = `json/contacts`;
    return this.http.post<CustomerContact[]>(url, contact).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  addCustomerContact(customerId: string, contact: CustomerContact): Observable<CustomerContact> {
    const url = `json/contacts`;
    return this.http.post<CustomerContact>(url, contact).pipe(
      tap(
        // Log the result or error
        data => this.updateCustomerContactsSubject(customerId),
        error => console.log('ERROR', error)
      )
    );
  }

  updateCustomerContactsSubject(customerId: string): void {
    if (!this.contactsSubjects[customerId]) {
      this.contactsSubjects[customerId] = new ReplaySubject<CustomerContact[]>();
    }

    console.log('inside update');
    this.internalGetCustomerContactsFromUrl(customerId).subscribe((cc: CustomerContact[]) =>
      this.contactsSubjects[customerId].next(cc)
    );
  }

  getCustomerContactsFromSubject(customerId: string): Observable<CustomerContact[]> {
    return this.getCustomerContacts(customerId);
  }

  deleteCustomerContact(customerId: string, contactId: string): Observable<CustomerContact[]> {
    const url = `json/contacts/${contactId}`;
    return this.http.delete<CustomerContact[]>(url).pipe(
      tap(
        // Log the result or error
        data => {
          console.log('inside delete');
          this.updateCustomerContactsSubject(customerId);
        },
        error => console.log('ERROR', error)
      )
    );
  }
}
