<div class="pt-3 row">
  <div class="col-12">
    <h6>{{ 'EDIT_USER_GROUPS_DESCRIPTION' | translate }}</h6>
  </div>
  <div class="col-12 col-md-2" *ngIf="userGroups && userGroups.length > 0">
    <div
      class="form-check pt-1 d-flex align-items-center justify-content-between"
      *ngFor="let group of getUserGroups()"
    >
      <label class="form-check-label" [for]="'remove_' + group.key">
        {{ group.key | translate | titlecase }}
      </label>
      <button
        [id]="'remove_' + group.key"
        type="button"
        class="btn btn-link p-1"
        (click)="toogleGroup(group.key, true)"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <hr *ngIf="getUserGroups().length > 0" />
    <div
      class="form-check pt-1 mb-2 d-flex align-items-center justify-content-between"
      *ngFor="let group of userGroups"
      [class.d-none]="group.selected"
    >
      <label class="form-check-label" [for]="group.key">
        {{ group.key | translate | titlecase }}
      </label>
      <input
        type="checkbox"
        class="scale18"
        (change)="toogleGroup(group.key, group.selected)"
        [id]="group.key"
        [checked]="group.selected"
      />
    </div>
  </div>
</div>
