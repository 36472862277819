import { Component, OnInit } from '@angular/core';
import { ActuatorService } from '../../../services/actuator/actuator.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';

@Component({
  selector: 'lib-config-restart',
  templateUrl: './config-restart.component.html',
  styleUrls: ['./config-restart.component.css']
})
export class ConfigRestartComponent implements OnInit {
  error;
  baseUrl;
  constructor(
    protected actuatorService: ActuatorService,
    protected modalService: GungModalService,
    protected backendInterceptor: BackendInterceptor
  ) {}

  ngOnInit(): void {
    this.baseUrl = this.backendInterceptor.getBaseUrl() || '';
    if (this.baseUrl && this.baseUrl.indexOf('https://') > -1) {
      this.baseUrl = this.baseUrl.slice(8).slice(0, -1);
    }
  }

  reloadConfig() {
    this.actuatorService.reloadConfig().subscribe(
      data => {
        this.error = null;
        this.modalService.openBasicModal(this.baseUrl + ' says', JSON.stringify(data)).finally(() => {});
      },
      error => (this.error = error)
    );
  }

  restartApp() {
    this.actuatorService.restartApp().subscribe(
      data => {
        this.error = null;
        this.modalService.openBasicModal(this.baseUrl + ' says', JSON.stringify(data)).finally(() => {});
      },
      error => (this.error = error)
    );
  }
}
