<div class="row">
  <div *ngFor="let digitalAsset of data" class="col-12 col-sm-6 col-md-4 mb-2">
    <div
      class="card border h-100 cursor-pointer"
      (click)="openDigitalAsset(digitalAsset)"
      [class.border-selected]="selectButton.isSelected"
    >
      <img
        *ngIf="digitalAsset.images && digitalAsset.images[0] as image; else noimage"
        class="card-img-top img-fluid mx-auto d-block"
        [src]="image.s3Uri | gungImageUrl
          :'type:list'
          :'etag:'+image.s3ETag
        "
        alt="{{ image.description }}"
      />
      <ng-template #noimage>
        <img
          class="card-img-top img-fluid w-100"
          [src]="'images/no-image.jpg' | gungImageUrl"
          alt="NO IMAGE"
          class="img-fluid mx-auto d-block"
        />
      </ng-template>
      <div class="card-body">
        <h5 class="card-title">{{ digitalAsset.name }}</h5>
        <div class="row">
          <div class="col-12 col-md-6">
            <lib-item-selection-button [item]="digitalAsset" class="" #selectButton [id]="digitalAsset.id">
            </lib-item-selection-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
