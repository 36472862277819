<div class="card mb-2">
  <!-- [ngClass]="{'card-body-border-offer': data.extra.OGA.OTY=='0', 'card-body-border-delivered': data.extra.OGA.LEF == '5', 'card-body-border-partial' : data.extra.OGA.LEF == '4'}" -->
  <div class="card-body">
    <div class="card-title row">
      <div class="col">
        <a [routerLink]="'/orders/' + data.id">
          <i class="fas fa-eye pr-1" style="font-size: larger"></i>
          <h5>{{ data.id }} - {{ data.extra.gme.displayName }}</h5>
        </a>
      </div>
      <div class="col-auto ml-auto">
         <lib-action-btn [item]="data" [config]="configService"></lib-action-btn>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'ORDER_STATUS'"
            [renderStatic]="true"
            [ngModel]="data.extra.gme.status | translate"
          ></lib-gung-text-input>
        </div>
      </div>
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          @if (data.extra.gme.orderDate) {
            <lib-gung-date-output [gungTranslate]="'ORDER_DATE'" [value]="data.extra.gme.orderDate" />
          }
        </div>        
      </div>
      @if (data.extra.gme.deliveryDate) {
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          
            <lib-gung-date-output [gungTranslate]="'DELIVERY_DATE'" [value]="data.extra.gme.deliveryDate" />
         
        </div>
      </div>
      }
      <div class="col-xs-12 col-md-4">
        <div class="pb-1">
          <lib-gung-text-input
            [gungTranslate]="'TOTAL'"
            [renderStatic]="true"
            [ngModel]="
              priceConfigService.getPrice(
                data.extra.gme.totalPriceExcludingVat,
                undefined,
                undefined,
                data.extra.gme.currencyCode
              )
            "
          />

        </div>
      </div>
      <div class="col col-xs-6 col-md-4">
        <div class="pb-1">
          @if (data.extra.gme.billingAddress?.countryCode) {
            <lib-gung-text-input
              [gungTranslate]="'COUNTRY'"
              [renderStatic]="true"
              [ngModel]="data.extra.gme.billingAddress.countryCode"
            />
          }
        </div>
      </div>
    </div>
  </div>
</div>
