import { ChangeDetectorRef, Component, ComponentFactoryResolver, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InlineSearchComponent } from '../inline-search/inline-search.component';

@Component({
  selector: 'lib-inline-search-navbar[configService]',
  templateUrl: './inline-search-navbar.component.html',
  styleUrls: ['./inline-search-navbar.component.css']
})
export class InlineSearchNavbarComponent<T> extends InlineSearchComponent<T> {
  constructor(
    protected resolver: ComponentFactoryResolver,
    protected detector: ChangeDetectorRef,
    protected eRef: ElementRef,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(resolver, detector, eRef, router, route);
  }
}
