import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { filter, first } from 'rxjs';
import { BaseViewConfigService, ProductViewType } from '../../services/base-view-config/base-view-config.service';
import { ProductAssortmentParentService } from '../../services/product-assortment-parent/product-assortment-parent.service';
import { ProductInlineSearchListConfigService } from '../../services/product-inline-search-list-config/product-inline-search-list-config.service';
import { ProductConcept } from '../concept-detail-v2/concept-detail-v2.component';
import { ProductFastsearchCardComponent } from '../product-fastsearch-card/product-fastsearch-card.component';

@Component({
  selector: 'lib-product-inline-search-card',
  templateUrl: './product-inline-search-card.component.html',
  styleUrls: ['./product-inline-search-card.component.css']
})
export class ProductInlineSearchCardComponent extends ProductFastsearchCardComponent {
  constructor(
    protected router: Router,
    protected productInlineSearchListConfigService: ProductInlineSearchListConfigService,
    protected baseViewConfigService: BaseViewConfigService,
    protected productAssortmentParentService: ProductAssortmentParentService
  ) {
    super();
  }

  goToDetail(productId) {
    if (this.baseViewConfigService.productViewType === ProductViewType.assortmentTreeView) {
      productId = this.data?.id;
      if (this.data?.productType === 'concept') {
        const productConcept = (this.data as ProductConcept);
        if (productConcept.subProductIds?.length > 0) {
          productId = productConcept.subProductIds[0];
        }
      }
      this.productAssortmentParentService.postProductAssortmentParentByProductIds([productId]).pipe(
        filter(assortmentParent => !!assortmentParent),
        first()
      ).subscribe(data => {
        if (data?.[productId]) {
          const path = data[productId];
          path.shift();
          const urlPath = path.map(path => path.id);
          this.router.navigate(['/articles', ...urlPath]);
        } else {

          this.router.navigate([
            this.data?.productType === 'concept' ? 
              this.productInlineSearchListConfigService.productDetailRoute : this.productInlineSearchListConfigService.productDetailRoute,
              productId
          ]);
        }
      });
      return;
    }
    if (this.data?.productType === 'concept') {
      this.router.navigate([this.productInlineSearchListConfigService.conceptDetailRoute, productId]);
    } else {
      this.router.navigate([this.productInlineSearchListConfigService.productDetailRoute, productId]);
    }
  }
}
