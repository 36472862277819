import { Component, OnInit, ViewChild } from '@angular/core';
import { ProductPaginationListConfigService } from '../../services/product-pagination-list-config/product-pagination-list-config.service';
import { PaginationListComponent, ListItemRendererComponent } from 'gung-list';
import { Product } from '../../models';

@Component({
  selector: 'lib-product-pagination-list',
  template: '<lib-pagination-list #productList [configService]="configService"></lib-pagination-list>',
  styleUrls: ['./product-pagination-list.component.css']
})
export class ProductPaginationListComponent {
  constructor(public configService: ProductPaginationListConfigService) {}
}
