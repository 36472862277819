import { Action } from '@ngrx/store';
import { GungFlowState } from './types';
import { GungFlowActionTypes, FlowSelected } from './actions';

const initialState: GungFlowState = {
  selectedFlow: undefined,
  doneLoading: false
};

export function reducers(state: GungFlowState = initialState, action: Action): GungFlowState {
  switch (action.type) {
    case GungFlowActionTypes.FlowSelected: {
      const { payload } = action as FlowSelected;
      return {
        ...state,
        selectedFlow: payload,
        doneLoading: true
      };
    }
    case GungFlowActionTypes.ClearSelectedFlow: {
      return {
        ...state,
        selectedFlow: null
      };
    }
    default: {
      return {
        ...state
      };
    }
  }
}
