import { Injectable } from '@angular/core';
import { Availability, SimplifiedAvailability } from '../models/availability';
import { Observable, filter, first, of } from 'rxjs';
import { AuthService } from './auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService } from 'gung-common';
import { parse } from 'date-fns';
import { Product } from '../models';
import { GungAnonymousConfigService } from './gung-anonymous-config/gung-anonymous-config.service';
import { MetadataService } from './metadata/metadata.service';

export interface AvailabilityDisplay {
  value: string;
  class: { [className: string]: boolean };
}

export enum STOCK_STATUS {
  IN_STOCK,
  INCOMING,
  OUT_OF_STOCK
}

@Injectable({
  providedIn: 'root'
})
export class AvailabilityHelperService {
  protected roles: string[];

  protected matrixAvailabilityDisplayLimit = 10;

  public showAnonymousAvailabilities = this.gungAnonymousConfigService.showAnonymousAvailabilities;
  public isAnonymous: boolean = true;

  constructor(
    protected authService: AuthService,
    protected translateService: TranslateService,
    protected dateUtilService: DateUtilService,
    protected gungAnonymousConfigService: GungAnonymousConfigService,
    protected metadataService: MetadataService
  ) {
    authService.getRoles().subscribe(d => (this.roles = d));
    authService.getCurrentUser().pipe(filter(user => !!user), first()).subscribe(user => {
      this.isAnonymous = user.roles.filter(role => role.toUpperCase() === 'ANONYMOUS').length > 0;
    });
  }

  protected getValueFromStatus(status: STOCK_STATUS, isSales: boolean, availability: Availability, product?: Product): string {
    if (!availability) {
      return '';
    }
    let value: string;
    let productUnit = product?.extra.gme?.unitOfMeasureCode;
    if (productUnit?.trim()) {
      productUnit = this.metadataService.getMetadataValue('unitsOfMeasure', 'name', productUnit) || productUnit;
    }

    if (isSales) {
      if (status === STOCK_STATUS.IN_STOCK) {
        value = `${this.translateService.instant('IN_STOCK')} (${availability.currentAvailability}<span class="unit">${productUnit ? (' ' + productUnit) : ''}</span>)`;
      } else if (status === STOCK_STATUS.INCOMING) {
        const firstDate = Object.keys(availability.extra.availabilities).filter(key => {
          return availability.extra.availabilities[key] > 0;
        })[0];
        value = this.translateService.instant('EXPECTED_DATE', { date: firstDate });
        // value = this.translateService.instant('EXPECTED_DATE', { date: (this.dateUtilService.changeDateFormat(firstDate, 'yyMMdd'))});
      }
    } else {
      if (status === STOCK_STATUS.IN_STOCK) {
        const stock: string = availability.currentAvailability > 50 ? '50+' : String(availability.currentAvailability);
        value = this.translateService.instant('IN_STOCK_VALUE', { value: stock + (productUnit ? (' ' + productUnit) : '') });
      } else if (status === STOCK_STATUS.INCOMING) {
        value = this.translateService.instant('INCOMING');
      }
    }
    if (status === STOCK_STATUS.OUT_OF_STOCK) {
      value = this.translateService.instant('OUT_OF_STOCK');
    }
    return value;
  }

  public getAvailabilityDisplay(availability: Availability, product: Product = null): Observable<AvailabilityDisplay> {
    if (!availability || (this.isAnonymous && !this.showAnonymousAvailabilities)) {
      return of({
        value: '',
        class: {
          'd-none': true
        }
      });
    }
    const sales = this.roles.indexOf('SALES') > -1;
    const status: STOCK_STATUS =
      availability.currentAvailability > 0
        ? STOCK_STATUS.IN_STOCK
        : availability.maxFutureStock > 0
          ? STOCK_STATUS.INCOMING
          : STOCK_STATUS.OUT_OF_STOCK;

    const value = this.getValueFromStatus(status, sales, availability, product);

    const classVal = this.classSelector(status);

    return of({
      value,
      class: classVal
    });
  }

  classSelector(classStatus: STOCK_STATUS): { [className: string]: boolean } {
    switch (classStatus) {
      case STOCK_STATUS.IN_STOCK:
        return { available: true };
      case STOCK_STATUS.OUT_OF_STOCK:
        return { unavailable: true };
      default:
        return { warn: true };
    }
  }

  public getMatrixAvailabilityDisplay(
    availability: Availability | SimplifiedAvailability,
    productId: string,
    product: Product
  ): Observable<AvailabilityDisplay> {
    if (!availability) {
      return of({
        value: '',
        class: { 'd-none': true }
      });
    }
    const status: STOCK_STATUS =
      availability.currentAvailability > 0
        ? STOCK_STATUS.IN_STOCK
        : availability.maxFutureStock > 0
          ? STOCK_STATUS.INCOMING
          : STOCK_STATUS.OUT_OF_STOCK;

    const value = this.getMatrixValueFromStatus(status, availability);
    const classVal = this.matrixClassSelector(status);

    return of({
      value,
      class: classVal
    });
  }

  protected getMatrixValueFromStatus(
    status: STOCK_STATUS,
    availability: Availability | SimplifiedAvailability
  ): string {
    if (!availability) {
      return '';
    }

    const nextFutureStock = this.getFutureStockQty(availability);

    const isUser = this.roles.indexOf('USER') > -1 && this.roles.length === 1;
    const isSales = this.roles.indexOf('SALES') > -1;
    const hasCurrenStock = availability.currentAvailability > 0;
    const currentAvailability =
      isUser && availability.currentAvailability > this.matrixAvailabilityDisplayLimit
        ? this.matrixAvailabilityDisplayLimit + '+'
        : availability.currentAvailability.toString();
    const nextFutureStockDisplay =
      isUser && nextFutureStock > this.matrixAvailabilityDisplayLimit
        ? this.matrixAvailabilityDisplayLimit + '+'
        : nextFutureStock.toString();
    const hasFutureStock = availability.maxFutureStock > 0;
    const futureStockDate = availability.maxFutureStock > 0 ? this.getFutureStockDate(availability) : null;
    let value = '';

    if (status === STOCK_STATUS.IN_STOCK) {
      // Current stock
      value = currentAvailability;
    } else if (status === STOCK_STATUS.INCOMING) {
      // No Current Stock and Future Stock
      if (isSales) {
        value = nextFutureStockDisplay + (futureStockDate ? ' / ' : '');
      }
      if (futureStockDate) {
        value += this.dateUtilService.changeDateFormat(futureStockDate);
      }
      value = !value.trim() ? '-' : value;
    } else if (status === STOCK_STATUS.OUT_OF_STOCK) {
      // No Current Stock and No Future Stock
      if (isSales) {
        value = nextFutureStockDisplay + (futureStockDate ? ' / ' : '');
      }
      if (futureStockDate) {
        value += this.dateUtilService.changeDateFormat(futureStockDate);
      }
      value = !value.trim() ? '-' : value;
    }

    return value;
  }

  protected getFutureStockDate(availability: Availability | SimplifiedAvailability): Date {
    const av = availability as Availability;
    if (Object.keys(av).includes('extra')) {
      const keys = Object.keys(av.extra.availabilities).filter(entry => av.extra.availabilities[entry] > 0);
      if (keys.length > 0) {
        return parse(keys[0], 'yyMMdd', new Date());
      }
    }
    return null;
  }

  protected getFutureStockQty(availability: Availability | SimplifiedAvailability): number {
    // if (!this.showAvailability) {
    //   return null;
    // }

    if (Object.keys(availability).includes('extra')) {
      const extraKey = 'extra';
      const availabilitiesKey = 'availabilities';

      const keys = availability[extraKey][availabilitiesKey] && Object.keys(availability[extraKey][availabilitiesKey]).filter(
        entry => availability[extraKey][availabilitiesKey][entry] > 0
      );
      if (keys?.length > 0) {
        return availability[extraKey][availabilitiesKey][keys[0]];
      }
    }

    return availability.maxFutureStock;
  }

  protected matrixClassSelector(classStatus: STOCK_STATUS): { [className: string]: boolean } {
    switch (classStatus) {
      case STOCK_STATUS.IN_STOCK:
        return { 'badge-green': true };
      case STOCK_STATUS.OUT_OF_STOCK:
        return { 'badge-red': true };
      default:
        return { 'badge-yellow': true };
    }
  }

  public getMatrixEnableOpenModalAvailabilty(
    productId: string,
    isSales: boolean,
    modalRestrictedViewAllowed: boolean
  ): boolean {
    return !!productId && (!!isSales || (!isSales && !!modalRestrictedViewAllowed));
  }
}
