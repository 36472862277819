<div class="row">
  <div class="col-12"></div>
</div>
<div class="row">
  <div class="col-12 table-responsive gung-responsive">
    <table class="table table-striped">
      <thead>
        <tr>
          <th translate>NUMBER</th>
          <th translate>LINK</th>
          <th translate>IMAGE</th>
          <th></th>
          <th translate>REMOVE</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let row of curentGungFlow.extra.slider; let index = index">
          <td [attr.data-label]="'ID' | translate">
            {{ index }}
            <button
              type="button"
              class="btn btn-info"
              [disabled]="index === 0 ? true : undefined"
              (click)="moveElement(curentGungFlow.extra.slider, index, index - 1)"
            >
              <i class="fas fa-arrow-up"></i>
            </button>
            <button
              type="button"
              class="btn btn-info"
              [disabled]="index === curentGungFlow.extra.slider.length - 1 ? true : undefined"
              (click)="moveElement(curentGungFlow.extra.slider, index, index + 1)"
            >
              <i class="fas fa-arrow-down"></i>
            </button>
          </td>
          <td [attr.data-label]="'LINK' | translate">
            <lib-gung-text-input suppressLabel="true" [(ngModel)]="row.link"></lib-gung-text-input>
          </td>
          <td [attr.data-label]="'IMAGE' | translate">
            <div class="form-group">
              <!-- <label class="w-100" for="file-upload" translate>BACKGROUND_IMAGE</label> -->
              <div class="input-group">
                <div class="flex-fill">
                  <input [(ngModel)]="row.background" class="form-control" type="text" />
                  <input
                    #inputFile
                    type="file"
                    class="_custom-file-input form-control d-none"
                    placeholder="File Upload"
                    id="file-upload"
                    aria-label="File Upload"
                    aria-describedby="file-upload"
                    accept=".jpg, .jpeg, .png"
                    [multiple]="false"
                    (change)="onFileChange($event, row, uploadButton)"
                  />
                </div>
                <div class="input-group-append" (click)="!uploading ? inputFile.click() : undefined">
                  <span class="input-group-text" id="file-upload" #uploadButton><i class="fas fa-folder"></i></span>
                </div>
              </div>
            </div>
          </td>
          <td>
            <img style="max-width: 500px" class="img-responsive" [src]="row.background" />
          </td>
          <td [attr.data-label]="'REMOVE' | translate">
            <button
              type="button"
              class="btn btn-danger"
              translate
              (click)="removeElement(curentGungFlow.extra.slider, index)"
            >
              <i class="fas fa-times"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <button type="button" class="btn btn-secondary float-right" (click)="addRow()" translate>
      ADD <i class="fas fa-plus"></i>
    </button>
  </div>
</div>
<div class="alert alert-secondary" role="alert">
  <h4 class="alert-heading">Tip:</h4>
  <hr />
  <ul class="list-group list-group-flush mb-0">
    <li class="list-group-item bg-transparent">Make sure that all images have the same height and width.</li>
    <li class="list-group-item bg-transparent">1138px is the minimun image width for best user experience.</li>
  </ul>
</div>
