import { Injectable } from '@angular/core';
import {
  ConfigService,
  ListLayout,
  ConfigBaseFilter,
  ListSortOption,
  SimpleConfigBaseFilter,
  ListLayoutSingleComponent,
  ListLayoutMultipleComponent
} from 'gung-list';
import { Observable, of } from 'rxjs';
import { Invoice } from '../../models/invoice';
import { InvoiceService } from '../invoices/invoice.service';
import { mergeMap, first, switchMap } from 'rxjs';
import { SelectedCustomerService } from '../selected-customer/selected-customer.service';
import { InvoiceCardListComponent } from '../../components/invoice-card-list/invoice-card-list.component';
import { ActivatedRoute } from '@angular/router';
import { resolve } from 'url';
import { reject } from 'q';
import { InvoiceTableComponent } from '../../components/invoice-table/invoice-table.component';

export class InvoiceIdFilter extends SimpleConfigBaseFilter<Invoice> {
  getName(): string {
    return 'Invoice name filter';
  }

  getOptionIds(item: Invoice): string[] {
    return [item.id];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class InvoiceListConfigService implements ConfigService<Invoice> {
  /*
   This variable and setter is called in the invoice-list-component to set
   the customerId to the id in the route params. It did not work to get the routeparams
   from this service.
   */
  customerId: string;
  setCustomerId(customerId: string) {
    this.customerId = customerId;
  }

  constructor(protected invoiceService: InvoiceService, protected selectedCustomerService: SelectedCustomerService) {}

  getItems(): Observable<Invoice[]> {
    if (this.customerId == null) {
      return this.selectedCustomerService.getSelectedCustomer().pipe(
        first(),
        switchMap(cust => this.invoiceService.getInvoices(cust.id))
      );
    }
    return this.invoiceService.getInvoices(this.customerId);
  }

  getFilters(): ConfigBaseFilter<Invoice>[] {
    return [new InvoiceIdFilter()];
  }

  getSortOptions(): ListSortOption<Invoice>[] {
    // TODO: implement
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<Invoice>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => InvoiceTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'Invoice grid'
      }
    ];
  }

  getSearchTerms(item: Invoice): string[] {
    return [item.id];
  }

  getItemId(item: Invoice): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
