import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { differenceInDays } from 'date-fns';
import { Observable } from 'rxjs';
import {
  ListLayoutMultipleComponent,
  ConfigBaseFilter,
  ConfigService,
  ListLayout,
  ListSortOption,
  SimpleConfigBaseFilter
} from 'gung-list';
import { News } from '../../../models/news';
import { NewsService } from '../news.service';
import { NewsEditorTableComponent } from '../../../components/news/news-editor-table/news-editor-table.component';

export class NewsDateFilter extends SimpleConfigBaseFilter<News> {
  constructor(protected translateService: TranslateService) {
    super();
  }

  getName(): string {
    return 'DATE';
  }

  getOptionIds(item: News): string[] {
    const dayDifferense = differenceInDays(new Date(), new Date(item.extra.timestamp));

    if (dayDifferense < 1) {
      return ['TODAY'];
    }
    if (dayDifferense < 7) {
      return ['THIS_WEEK'];
    }
    if (dayDifferense < 30) {
      return ['THIS_MONTH'];
    }
    if (dayDifferense < 90) {
      return ['LAST_THREE_MONTHS'];
    }
    return ['OVER_THREE_MONTHS'];
  }

  getOptionName(key: string): string {
    return this.translateService.instant(key);
  }
}

export class NewsStatusFilter extends SimpleConfigBaseFilter<News> {
  constructor() {
    super();
  }

  getName(): string {
    return 'ACTIVE';
  }

  getOptionIds(item: News): string[] {
    return [item.active ? 'YES' : 'NO'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

export class NewsCategoryFilter extends SimpleConfigBaseFilter<News> {
  constructor() {
    super();
  }

  getName(): string {
    return 'CATEGORY';
  }

  getOptionIds(item: News): string[] {
    return [item.extra?.category || 'UNDEFINED'];
  }

  getOptionName(key: string): string {
    return key;
  }
}

@Injectable({
  providedIn: 'root'
})
export class NewsEditorConfigListService implements ConfigService<News> {
  constructor(protected newsService: NewsService, protected translateService: TranslateService) {}

  getItems(): Observable<News[]> {
    return this.newsService.getAllNews();
  }

  getFilters(): ConfigBaseFilter<News>[] {
    return [new NewsDateFilter(this.translateService), new NewsStatusFilter(), new NewsCategoryFilter()];
  }

  getSortOptions(): ListSortOption<News>[] {
    return [];
  }

  getBatchSizes(): number[] {
    return [24];
  }

  getLayouts(): ListLayout<News>[] {
    return [
      {
        getIconClass: () => '',
        getListItemComponent: () => NewsEditorTableComponent,
        getListLayoutComponent: () => ListLayoutMultipleComponent,
        getName: () => 'NewsTable'
      }
    ];
  }

  getSearchTerms(item: News): string[] {
    return [item.id, item.name];
  }

  getItemId(item: News): string {
    return item.id;
  }

  getNothingFoundTranslateTag() {
    return 'NOTHING_FOUND';
  }
}
