import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, AbstractControl, FormControl, Validators } from '@angular/forms';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { DateUtilService, S3UploadService } from 'gung-common';
import { SearchRequest, SelectionAction } from 'gung-list';
import {
  forkJoin,
  first,
  of,
  switchMap,
  Observable,
  map,
  takeUntil,
  Subject,
  OperatorFunction,
  debounceTime,
  distinctUntilChanged,
  filter,
  catchError
} from 'rxjs';
import { Customer, Product } from '../../../models';
import { S3Item } from '../../../models/s3';
import { AuthService } from '../../../services/auth/auth.service';
import { BackendInterceptor } from '../../../services/backend-interceptor/backend-interceptor.service';
import { CustomerService } from '../../../services/customers/customer.service';
import { GungModalService } from '../../../services/gung-modal/gung-modal.service';
import {
  OrderServicesRow,
  OrderServicesService,
  OrderServicesTicket
} from '../../../services/order-services/order-services.service';
import { SelectedCustomerService } from '../../../services/selected-customer/selected-customer.service';
import { Order } from '../../../models/order';
import { OrderRow } from '../../../models/orderRow';
import { OrderSubmitService } from '../../../services/order-submit.service';
import { CheckoutObject, CheckoutRow } from '../../../models/checkout';
import { GungFlowService } from '../../../services/gung-flow/gung-flow.service';
import { ProductListConfigService } from '../../../services/product-list-config/product-list-config.service';
import { ProductService } from '../../../services/products/product.service';
import { TableRecord } from '../../../state/metadata/types';
import { MetadataService } from '../../../services/metadata/metadata.service';
import { UsersService } from '../../../services/users/users.service';
import { User } from '../../../state/auth/types';

export interface WarrantySelectionAction<T> extends SelectionAction<T> {
  disabled?: boolean;
  btnClasses?: string;
}

@Component({
  selector: 'lib-return-warranty-create-view-modal',
  templateUrl: './return-warranty-create-view-modal.component.html',
  styleUrls: ['./return-warranty-create-view-modal.component.css']
})
export class ReturnWarrantyCreateViewModalComponent implements OnInit, OnDestroy {
  @Input() create: boolean = false;
  @Input() data: OrderServicesTicket;

  isLoaded: boolean = false;

  editHeader: boolean = false;
  // editRows: boolean = false;

  comment;
  actions;
  isAdmin = false;
  isSales = false;
  isUser = false;
  protected unsubscribe: Subject<void> = new Subject();

  public dropdownIsOpen: boolean = false;
  public searchString: string = '';
  public customerList: Customer[] = [];
  public customerFilteredList: Customer[] = [];
  selectedCustomerPlaceholder = '';

  submitForm: FormGroup;
  filesToSubmit: File[] = [];
  protected currentCustomer: Customer;
  currentUser: User;

  public multipleFiles = true;
  public disabled = false;

  today = new Date();

  get rows() {
    return this.submitForm.get('rows') as FormArray;
  }

  get comments() {
    return this.submitForm.get('comments') as FormArray;
  }

  public editRow: { [rowIdx: string]: boolean } = {};
  products;
  claimStatusMetadata: [TableRecord];

  resultFormatterItemId = (item: any) =>
    (item?.name || item?.description || item) + ' - ' + item?.id || item?.code || item;
  itemIdSearchTypeahead: OperatorFunction<string, readonly Product[]> = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(term => {
        const searchRequest: SearchRequest = {
          terms: [term],
          skip: 0,
          limit: 10,
          assortment: this.currentUser.assortment
        };
        return forkJoin({
          term: of(term),
          products: this.productService.getPagedProducts(searchRequest).pipe(first())
        });
      }),
      map(data => {
        return data.term.length < 1 ? [] : data.products.items;
      })
    );

  formatterCustomer = item => {
    return item.id;
  };
  formatterViewCustomer = item => {
    return item.id + ' ' + item.name;
  };

  users: string[] = [];

  constructor(
    protected formBuilder: FormBuilder,
    public activeModal: NgbActiveModal,
    protected orderServicesService: OrderServicesService,
    protected authService: AuthService,
    protected selectedCustomerService: SelectedCustomerService,
    protected s3UploadService: S3UploadService,
    protected customerService: CustomerService,
    protected gungModalService: GungModalService,
    protected translationService: TranslateService,
    protected backendInterceptor: BackendInterceptor,
    public dateUtilService: DateUtilService,
    protected orderSubmitService: OrderSubmitService,
    protected flowService: GungFlowService,
    protected metadataService: MetadataService,
    protected productListConfig: ProductListConfigService,
    protected productService: ProductService,
    protected usersService: UsersService
  ) {
    this.editRow = {};
    this.claimStatusMetadata = this.metadataService.getMetadataTableList('CLAIMSTATUS');
  }

  ngOnInit() {
    forkJoin([
      this.authService.getCurrentUser().pipe(first()),
      this.selectedCustomerService.getSelectedCustomer().pipe(first()),
      // this.customerService.getAllCustomersSubject().pipe(first()),
      this.customerService.getCustomers().pipe(
        filter(customers => !!customers),
        first()
      ),
      this.usersService.getUsersIdByRoles('ACTIVE_FOR_CLAIMS_WARRANTY').pipe(
        first(),
        catchError(err => of([]))
      )
    ]).subscribe(([user, customer, customers, users]) => {
      this.currentUser = user;
      this.currentCustomer = customer;
      this.customerList = customers;
      this.isSales = user.roles.indexOf('SALES') > -1;
      this.isAdmin = user.roles.indexOf('ADMIN') > -1;
      this.isUser =
        user.roles.indexOf('USER') > -1 && user.roles.indexOf('SALES') === -1 && user.roles.indexOf('ADMIN') === -1;
      this.users = users;
      if (this.create) {
        this.initForm(user, customer);
      } else {
        this.initForm(user, customer, this.data);
      }
      this.actions = this.getSelectionActions();
      this.isLoaded = true;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getSelectionActions(): Observable<WarrantySelectionAction<string>[]> {
    const actions: WarrantySelectionAction<string>[] = [];

    for (const status of this.claimStatusMetadata || []) {
      actions.push({
        label: this.translationService.instant('STATUS') + ' ' + status.name,
        performAction: (selection: any) => {
          this.data.status = status.name;
          this.orderServicesService.postOrderServices(this.data).subscribe(data => {
            this.data = data;
            this.submitForm.get('status').setValue(data.status);
          });
          return of();
        }
      });
    }

    actions.push({
      label: 'DELETE',
      performAction: (selection: any) => {
        this.gungModalService
          .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ` ${this.data.id}?`, {
            size: 'sm'
          })
          .then(result => {
            if (result) {
              this.orderServicesService.deleteOrderServices(this.data.id).subscribe();
              this.activeModal.close();
            }
          });
        return of();
      }
    });

    if (this.isAdmin) {
      actions.push({
        label: 'EDIT_HEADER',
        performAction: (selection: any) => {
          this.selectedCustomerPlaceholder = `${this.data.customerId} - ${this.data.customerName}`;
          this.editHeader = true;
          this.setSearch('');
          // this.actions = this.getSelectionActions();
          return of();
        }
      });
    }

    if (this.isAdmin || this.isSales) {
      actions.push({
        label: 'CREATE_RETURN_ORDER_IN_ERP',
        performAction: (selection: any) => this.sendToErp()
      });
    }
    return of(actions);
  }

  mapWarrantyToReturnOrderInput(input: OrderServicesTicket): CheckoutObject {
    const result = {} as CheckoutObject;
    result.deliveryCustomerId = input.customerId;
    result.extra = {
      _orderServiceId: input.id
    };
    result.rows = input.rows.map(r => {
      return {
        id: r.id,
        quantity: r.quantity,
        extra: {
          gungSalesOrderLines: {
            ...r?.extra?.gungSalesOrderLines,
            returnReasonCode: r.reasonCode[0]
          }
        },
        targetStockId: this.currentUser.managedMultistockIds[0]
      } as unknown as CheckoutRow;
    });

    return result;
  }

  initForm(user: User, customer: Customer, data?: OrderServicesTicket) {
    this.submitForm = this.formBuilder.group({
      status: this.formBuilder.control(data?.status || undefined),
      date: this.formBuilder.control(data?.date || undefined),
      customerId: this.formBuilder.control(data?.customerId || this.currentCustomer.id || undefined),
      customerName: this.formBuilder.control(data?.customerName || undefined),
      customer: this.formBuilder.control(data?.customerName || customer.name),
      customerResponsible: this.formBuilder.control(data?.customerResponsible || user.username),
      orderId: this.formBuilder.control(data?.orderId || ''),
      comments: this.formBuilder.array(data?.comments || []),
      comment: this.formBuilder.control(''),
      rows: this.formBuilder.array([], [Validators.required, Validators.minLength(1)]),
      assignTo: this.formBuilder.control(data?.assignTo || ''),
      approvedBy: this.formBuilder.control(data?.approvedBy || '')
    });

    if (data?.rows) {
      for (const [index, row] of data.rows.entries()) {
        this.addRow(row, index);
      }
    }

    if (this.isUser && this.create) {
      this.submitForm.patchValue({
        customerId: customer.id,
        customerName: customer.name,
        status: this.claimStatusMetadata[0].name,
        date: this.today
      });
    }

    this.submitForm?.get('customerId')
      .valueChanges.pipe(takeUntil(this.unsubscribe))
      .subscribe(customerId => {
        const customer = this.customerList.find(c => c.id === customerId);
        if (customer) {
          this.setSelectedCustomer(customer);
        }
      });
  }

  public onFileSelect(files: FileList, index?: number) {
    if (index !== undefined) {
      // files for attachments
      const rows = this.submitForm.get('rows') as FormArray;
      const attachments = rows.at(index).get('attachments') as FormArray;
      if (attachments.length === 0) {
        attachments.clear();
        attachments.setValue([]);
      }
      for (let idx = attachments.length - 1; idx >= 0; idx--) {
        const attachment = attachments.at(idx);
        // Remove previous selected file
        if (!attachment || attachment?.value?.constructor?.name === 'File') {
          attachments.removeAt(idx);
        }
      }
      for (let file = 0; file < files.length; file++) {
        attachments.push(this.formBuilder.control(files.item(file)));
      }
    } else {
      // File for warranty
      this.filesToSubmit = [];
      for (let file = 0; file < files.length; file++) {
        this.filesToSubmit.push(files.item(file));
      }
    }
  }

  downloadFile(attachment) {
    const url = this.backendInterceptor.getBaseUrl() + this.orderServicesService.getDocumentDownloadUrl(attachment);
    const win = window.open(url, '_blank');
    win.focus();
  }

  onSave() {
    this.onSaveObservable().pipe(first()).subscribe(ticket => {
      if (!!ticket) {
        this.disabled = false;
        this.activeModal.close(JSON.stringify(ticket));
      } else {
        this.disabled = false;
      }
    });
  }

  onSaveGetFormRawValue(): any {
    return this.submitForm.getRawValue();
  }

  protected onSaveObservable(): Observable<OrderServicesTicket> {
    if (this.submitForm.invalid) {
      this.submitForm.markAllAsTouched();
      return;
    }
    this.disabled = true;
    const formRaw = this.onSaveGetFormRawValue();

    const timestamp = new Date();
    let ticket = {
      ...this.data,
      ...formRaw
    };
    let approvedByChanged = false;
    if (!this.create && this.data?.approvedBy !== formRaw.approvedBy) {
      approvedByChanged = true;
    }
    let assignToChanged = false;
    if (!this.create && this.data?.assignTo !== formRaw.assignTo) {
      assignToChanged = true;
    }
    if (this.create) {
      ticket = {
        ...formRaw,
        userName: this.currentUser.name,
        userEmail: this.currentUser.email,
        id: undefined,
        status: 'Registered',
        date: timestamp,
        comments: [
          {
            user: this.currentUser.username,
            date: timestamp,
            attachments: [],
            comment: formRaw.comment
          }
        ]
      };
    }

    if (!this.create && (ticket.comment || this.filesToSubmit?.length > 0)) {
      ticket.comments = [
        ...ticket.comments,
        {
          user: this.currentUser.username,
          date: timestamp,
          attachments: [],
          comment: ticket.comment
        }
      ];
    }
    ticket.comment = undefined;

    // History about assignToChanged
    if (assignToChanged) {
      ticket.comments = [
        ...ticket.comments,
        {
          user: this.currentUser.username,
          date: timestamp,
          attachments: [],
          comment: this.translationService.instant('ASSIGN_TO') + ' :' + formRaw.assignTo
        }
      ];
    }
    // History about approvedByChanged
    if (approvedByChanged) {
      ticket.comments = [
        ...ticket.comments,
        {
          user: this.currentUser.username,
          date: timestamp,
          attachments: [],
          comment: this.translationService.instant('APPROVED_BY') + ' :' + formRaw.approvedBy
        }
      ];
    }

    const observables: Observable<S3Item>[] = [];
    if (this.filesToSubmit?.length > 0) {
      // Upload files for comments
      for (const file of this.filesToSubmit) {
        const newFile = new File([file], file.name.replace(/[ \r\n?!:;\-(){}\[\]\\'"=@><&%\/#]+/g, '_'), { type: file.type, lastModified: file.lastModified });
        observables.push(this.s3UploadService.postImage(newFile).pipe(first()));
      }
    }

    if (this.rows.length > 0) {
      // Upload files for attachments
      for (let rowIdx = 0; rowIdx < this.rows.length; rowIdx++) {
        const row = this.rows.at(rowIdx);
        const attachments = row.get('attachments') as FormArray;
        for (let attachmentIdx = 0; attachmentIdx < attachments.length; attachmentIdx++) {
          const attachment = attachments.at(attachmentIdx) as FormControl;
          if (attachment?.value?.constructor?.name === 'File') {
            observables.push(this.s3UploadService.postImage(attachment.value as File).pipe(first()));
          }
        }
      }
    }

    return (observables.length > 0 ? forkJoin(observables) : of([]))
      .pipe(
        switchMap((documents: S3Item[]) => {
          // Create returnWarranty
          for (const file of this.filesToSubmit) {
            const name = file.name.replace(/[ \r\n?!:;\-(){}\[\]\\'"=@><&%\/#]+/g, '_');
            const uploadFile = documents.findIndex(
              d =>
                d.s3Uri.includes(name) ||
                d.s3Uri.includes(name?.replace(/\s+/g, '')) ||
                d.s3Uri.includes(name?.split(' ').join('_BLANK_'))
            );
            if (uploadFile > -1) {
              ticket.comments[ticket.comments.length - 1].attachments.push(documents[uploadFile].s3Uri);
              documents.splice(uploadFile, 1);
            }
          }
          for (const row of ticket.rows) {
            for (let attachmentIdx = 0; attachmentIdx < row.attachments.length; attachmentIdx++) {
              const attachment = row.attachments[attachmentIdx];
              const name = attachment.name;
              const uploadFile = documents.findIndex(
                d =>
                  d.s3Uri.includes(name) ||
                  d.s3Uri.includes(name?.replace(/\s+/g, '')) ||
                  d.s3Uri.includes(name?.split(' ').join('_BLANK_'))
              );
              if (uploadFile > -1) {
                row.attachments.splice(attachmentIdx, 1, documents[uploadFile].s3Uri);
                documents.splice(uploadFile, 1);
              }
            }
            row.attachments = row.attachments.filter(attachment => typeof attachment === 'string');
          }
          return this.orderServicesService.postOrderServices(ticket);
        }),
        catchError((err, caught) => of(undefined)),
        switchMap(ticket => {
          return of(ticket);
        })
      );
  }

  deleteRow(i) {
    this.rows.removeAt(i);
  }

  addRow(row?: OrderServicesRow, index?: number) {
    this.rows.push(
      this.formBuilder.group({
        id: this.formBuilder.control(row?.id || '', [Validators.required]),
        quantity: this.formBuilder.control(row?.quantity || 0),
        reasonCode: this.formBuilder.array(
          row?.reasonCode || [this.formBuilder.control('')].map(control => control.value)
        ),
        attachments: this.formBuilder.array(row?.attachments || [], [Validators.required, Validators.minLength(1)]),
        note: this.formBuilder.control(row?.note || ''),
        date: this.formBuilder.control(row?.date || '')
      })
    );
  }

  rowsIdx(index: number): AbstractControl {
    // return (this.submitForm.get('rows') as FormArray).at(index).get('reasonCode') as FormArray;
    return (this.submitForm.get('rows') as FormArray).at(index);
  }

  addReasonCode(index: number) {
    ((this.submitForm.get('rows') as FormArray).at(index).get('reasonCode') as FormArray).push(
      this.formBuilder.control('')
    );
  }

  handleDropdownOpen(isOpen: boolean) {
    this.dropdownIsOpen = isOpen;
  }

  setSearch(search: string) {
    this.searchString = search;
    if (!search || search.trim() === '') {
      this.customerFilteredList = this.customerList;
      return;
    }

    this.customerFilteredList = this.filterBySearchTerm(search, this.customerList);
  }

  filterBySearchTerm(searchTerm: string, items: any[]): any[] {
    return items.filter(item => {
      let hasHitAllTerms = true;
      const queryTerms = searchTerm.split(' ');
      const terms = [`${item.id} - ${item.name}]`];
      queryTerms.forEach(queryTerm => {
        const locatedTerm = terms.find(term => {
          if (term === null || term === undefined) {
            return false;
          }
          return term.toUpperCase().indexOf(queryTerm.toUpperCase()) >= 0;
        });

        hasHitAllTerms = hasHitAllTerms && !!locatedTerm;
      });

      return hasHitAllTerms;
    });
  }

  setSelectedCustomer(customer: Customer) {
    this.selectedCustomerPlaceholder = `${customer.id} - ${customer.name}`;
    this.submitForm.get('customerName').setValue(customer.name);
    this.submitForm.get('customer').setValue(customer.name);
  }

  public selectProduct(event, row: FormControl) {
    row.get('id').setValue(event.id);
  }

  public removeAttachement(rowIdx: number, attachmentIdx: number) {
    this.gungModalService
      .openConfirmYesNoModal(undefined, this.translationService.instant('REMOVE') + ` ${this.data.id}?`, {
        size: 'sm'
      })
      .then(result => {
        if (result) {
          const attachments = this.rows.at(rowIdx).get('attachments') as FormArray;
          attachments.removeAt(attachmentIdx);
        }
      });
  }

  public selectItem({ item, preventDefault, target }, rowIdx: number, reasonIdx?: number) {
    if (reasonIdx > -1) {
      // reason code selecte
      const reasonCode = this.rows.at(rowIdx).get('reasonCode') as FormArray;
      reasonCode.at(reasonIdx).setValue(item?.description || null);
    } else {
      // product id selected
      this.rows
        .at(rowIdx)
        .get('id')
        .setValue(item?.id || null);
    }
  }

  protected sendToErp(): Observable<any> {
    this.onSave();
    const toPost = this.mapWarrantyToReturnOrderInput(this.data);
    const url = 'json/order/create/GUNG_WEB_RETURN';
    this.flowService
      .getSelectedFlow()
      .pipe(
        first(),
        switchMap(flow => {
          toPost.extra._flow = flow;
          return this.orderSubmitService.postOrder(url, toPost).pipe(first());
        })
      )
      .subscribe({
        next: res => {
          // SUCCESS //
          this.data.orderId = res.erpOrderIds[0];
          this.data.status = this.claimStatusMetadata[this.claimStatusMetadata.length - 2].name;
          this.data.approvedBy = this.currentUser.username;
          this.data.comments = [
            ...this.data.comments,
            {
              user: this.currentUser.username,
              date: '' + new Date(),
              attachments: [],
              comment: this.translationService.instant('APPROVED_BY') + ' :' + this.data.approvedBy
            }
          ];
          this.orderServicesService.postOrderServices(this.data).subscribe(data => {
            this.data = data;
            this.submitForm.get('orderId').setValue(data.orderId);
          });
          this.gungModalService
            .openBasicModal(
              this.translationService.instant('SUCCESS'),
              this.translationService.instant('RETURN_WARRANTY_CREATED_SUCCESS') +
              '. ' +
              this.translationService.instant('ORDER_ID') +
              ': ' +
              res.erpOrderIds[0]
            )
            .then(
              result => { },
              reason => { }
            );
        },
        error: err => {
          // ERROR //
          this.gungModalService
            .openBasicModal(
              this.translationService.instant('ERROR'),
              this.translationService.instant('RETURN_WARRANTY_CREATED_ERROR')
            )
            .then(
              result => { },
              reason => { }
            );
        }
      });
    return of();
  }
}
