import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'lib-gung-cookie-consent',
  templateUrl: './gung-cookie-consent.component.html',
  styleUrls: ['./gung-cookie-consent.component.css']
})
export class GungCookieConsentComponent implements OnInit {
  ngOnInit(): void {
    if (localStorage.getItem('cookiesAccepted') === null) {
      localStorage.setItem('cookiesAccepted', 'false');
    }
  }
  showBanner = !this.cookieSelectionCompleted();

  cookieSelectionCompleted(): boolean {
    return localStorage.getItem('cookieSelectionCompleted') === 'true';
  }

  acceptCookies(): void {
    localStorage.setItem('cookiesAccepted', 'true');
    localStorage.setItem('cookieSelectionCompleted', 'true');
    this.showBanner = false;
  }
  declineCookies(): void {
    localStorage.setItem('cookiesAccepted', 'false');
    localStorage.setItem('cookieSelectionCompleted', 'true');
    this.showBanner = false;
  }
}
